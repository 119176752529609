import { Injectable }       from '@angular/core';
import { StoreState }       from '@redux/store';
import { Store }            from '@ngrx/store';
import { selectUserScopes } from '@redux/auth/auth.selectors';
import { AuthScope }        from '@enums/auth-scope.enum';
import { Observable }       from 'rxjs';
import { containsAll }      from '@rxjs/contains-all.operator';
import { Scope }            from '@models/entity/scope.model';

@Injectable({
  providedIn: 'root',
})
export class ScopeService {
  static adminScopes = [AuthScope.PaymentRead, AuthScope.CompanyConfigurationRead,
                        AuthScope.CompanyConfigurationWrite, AuthScope.IntegrationZapierRead,
                        AuthScope.IntegrationZapierWrite];

  static basicScopes = [AuthScope.UserRead,
                        AuthScope.UserRoleRead,
                        AuthScope.UserConfigurationRead,
                        AuthScope.UserConfigurationWrite,
                        AuthScope.CompanyRead,
                        AuthScope.NumberRead,
                        AuthScope.ServiceRead,
                        AuthScope.ReportRead,
                        AuthScope.TaskRead,
                        AuthScope.TaskWrite,
  ];

  static isDefaultScope(scope: Scope): boolean {
    return ScopeService.basicScopes.includes(scope?.identifier);
  }

  static isAdminScope(scope: Scope): boolean {
    return ScopeService.adminScopes.includes(scope?.identifier);
  }

  constructor(private store: Store<StoreState>) { }

  hasScopes$(...scopes: AuthScope[]): Observable<boolean> {
    return this.store.select(selectUserScopes)
      .pipe(containsAll(...scopes));
  }

  getScopes$(): Observable<AuthScope[]> {
    return this.store.select(selectUserScopes);
  }
}
