import { Injectable }                     from '@angular/core';
import { MatDialog }                      from '@angular/material/dialog';
import { CallingProfile }                 from '@models/entity/calling-profile.model';
import { ProfileMigrationModalComponent } from '@dialog/profile-migration-modal/profile-migration-modal.component';
import { MigrateProfilesRequest }         from '@models/api/migrate-profiles-request.model';
import { MigrateProfilesRequestAction }   from '@redux/service/service.actions';
import { StoreState }                     from '@redux/store';
import { Store }                          from '@ngrx/store';
import { ConfirmModalComponent }          from '@dialog/general/confirm-modal/confirm-modal.component';
import { RouterService }                  from '@services/router.service';

@Injectable({
  providedIn: 'root',
})
export class CallingProfileActionService {

  constructor(private dialog: MatDialog,
              private routerService: RouterService,
              private store: Store<StoreState>) { }

  openConfigureCallingProfile(profile: CallingProfile, serviceId: string): Promise<boolean> {
    return this.routerService.navigate(['/services', 'microsoft-teams', serviceId, 'user-persona', 'create', profile?.id || 'new']);
  }

  openMigrationTool(data: { serviceId: string, fromProfile?: CallingProfile }): void {
    this.dialog.open(ProfileMigrationModalComponent, {
      panelClass: 'cr-dialog',
      maxWidth:   '600px',
      minWidth:   '50vw',
      data,
      autoFocus:  false,
    })
      .afterClosed()
      .subscribe((value: MigrateProfilesRequest) => {
        if (!value) {
          return;
        }
        return this.dialog.open(ConfirmModalComponent, {
          panelClass: 'cr-dialog',
          maxWidth:   '700px',
          data:       {
            title:          `Are you sure?`,
            content:        `<p>This action will assign all users to the new user persona. Do you want to proceed?</p>`,
            confirmBtnText: 'Confirm',
            showCancel:     true,
          },
        })
          .afterClosed()
          .subscribe((confirm: boolean) => {
            if (!confirm) {
              return;
            }
            return this.store.dispatch(MigrateProfilesRequestAction(value));
          });
      });
  }
}
