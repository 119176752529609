import { OperatorFunction } from 'rxjs';
import { map }              from 'rxjs/operators';

/**
 * Uses rxjs/map to assert that all items exist in the array emitted via the Observable stream.
 * @returns OperatorFunction boolean to indicate whether they all exist
 *
 * @example
 * ```ts
 *  this.hasScopes$ = scopes$.pipe(
 *              containsAll(UserWrite,
 *                          UserRead))
 * ```
 * @param list
 */
export function containsAll<T extends Array<U>, U>(...list: Array<U>): OperatorFunction<T, boolean> {
  return map<T, boolean>((arr: T) => list?.every(item => arr?.includes(item)));
}
