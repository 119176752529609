import { Injectable }                                                                  from '@angular/core';
import {
  ConditionExpression,
}                                                                                      from '@models/entity/condition-expression.model';
import {
  QueryExpression,
}                                                                                      from '@models/entity/query-expression.model';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  Field,
}                                                                                      from '@models/entity/field.model';
import {
  ConditionOperator,
}                                                                                      from '@enums/condition-operator.enum';
import { FieldType }                                                                   from '@enums/field-type.enum';
import {
  KeyValueCollection,
}                                                                                      from '@models/entity/key-value-expression.model';
import {
  FieldTypeOperators,
}                                                                                      from '@models/entity/field-type-operators';
import {
  OptionValue,
}                                                                                      from '@models/entity/option-value.model';
import {
  LogicalOperator,
}                                                                                      from '@enums/logical-operator.enum';
import {
  KeyValuePair,
}                                                                                      from '@models/entity/key-value-pair.model';
import {
  ArrayValidator,
}                                                                                      from '@validators/array.validator';
import {
  ADGroup,
}                                                                                      from '@models/entity/ad-group.model';

@Injectable({
  providedIn: 'root',
})
export class ExpressionService {
  static conditionLabels                                   = new Map<ConditionOperator, string>([
    [ConditionOperator.Equals, 'Equals'],
    [ConditionOperator.NotEquals, 'Not equals'],
    [ConditionOperator.GreaterThan, 'Greater than'],
    [ConditionOperator.GreaterEqual, 'Greater than or equal to'],
    [ConditionOperator.LessThan, 'Less than'],
    [ConditionOperator.LessEqual, 'Less than or equal to'],
    [ConditionOperator.Contains, 'Contains'],
    [ConditionOperator.StartsWith, 'Starts with'],
    [ConditionOperator.EndsWith, 'Ends with'],
    [ConditionOperator.InValues, 'In values'],
  ]);
  private typeOperators: KeyValueCollection<OptionValue[]> = new KeyValueCollection<OptionValue[]>();
  private _types: KeyValueCollection<FieldTypeOperators>;
  private _fields: KeyValueCollection<Field>;

  get fields(): KeyValueCollection<Field> {
    return this._fields;
  }

  get types(): KeyValueCollection<FieldTypeOperators> {

    if (this._types) {
      return this._types;
    }

    this.initTypes();
    return this._types;
  }

  constructor(private fb: FormBuilder) { }

  fieldByLabel(fieldLabel: string): Field {

    if (!this._fields || !fieldLabel) {
      return null;
    }

    let item: Field = null;

    const items         = this._fields.getItems();
    const filteredItems = items.filter((i: KeyValuePair<Field>) =>
      i.value.name.toLowerCase() === fieldLabel.toLowerCase());

    if (filteredItems.length > 0) {
      item = filteredItems[0].value;
    }

    return item;
  }

  fieldLabel(field: string): string {

    if (this._fields) {
      const options = this.fieldOptions(field);

      if (options) {
        return options.name;
      }
    }

    return '';
  }

  fieldOptions(field: string): Field {

    if (this._fields) {
      return this._fields.value(field);
    }

    return null;
  }

  operatorsByType(type: FieldType): OptionValue[] {

    if (this.typeOperators.hasKey(type)) {
      return this.typeOperators.value(type);
    }

    const values: OptionValue[] = [];
    const typeInfo              = this.types.value(type);

    if (typeInfo) {

      typeInfo.operators.forEach(item => {
        values.push({
          value: item,
          label: ExpressionService.conditionLabels.get(item),
        });
      });

      this.typeOperators.add(type, values);

    }

    return values;
  }

  setFields(fields: Field[]): void {

    this._fields = new KeyValueCollection<Field>();

    if (fields) {
      fields.forEach(field => {
        this._fields.add(field.name, field);
      });
    }

  }

  toFormGroup(expression: QueryExpression): FormGroup {

    if (!expression) {
      return null;
    }

    const group: FormGroup = this.createGroup(expression.type);
    const conditions       = group.get('conditions') as FormArray;

    if (conditions) {
      for (let i = 0; i < expression.conditions.length; i++) {

        const item = expression.conditions[i];

        if (this.isCondition(item)) {
          const c = item as ConditionExpression;
          conditions.push(this.createCondition(c.property, c.type, c.value || c.values));
        } else if (this.isGroup(item)) {

          const g = item as QueryExpression;
          conditions.push(this.toFormGroup(g));

        }

      }
    } else {
      const g = expression.condition as QueryExpression;
      group.setControl('condition', this.toFormGroup(g));
    }

    return group;

  }

  normaliseExpression(expression: QueryExpression): QueryExpression {

    const conditions = expression.conditions;

    if (conditions) {
      for (let i = 0; i < expression.conditions.length; i++) {

        const item = expression.conditions[i];

        if (this.isCondition(item)) {
          const c = item as ConditionExpression;
          if (c.values && c.values.every(value => !!(value as any)?.guid)) {
            c.values = c.values.map(value => (value as any).guid);
          }
        } else if (this.isGroup(item)) {
          this.normaliseExpression(item as QueryExpression);
        }

      }
    } else {
      const g = expression.condition;
      this.normaliseExpression(g);
    }

    return expression;

  }

  extractAdGroupIds(expression: QueryExpression): string[] {

    const conditions  = expression.conditions;
    let ids: string[] = [];

    if (conditions) {
      for (let i = 0; i < expression.conditions.length; i++) {

        const item = expression.conditions[i];

        if (this.isCondition(item)) {
          const c = item as ConditionExpression;

          if (c.values && c.property === 'adGroups') {
            ids.push(...c.values);
          }

        } else if (this.isGroup(item)) {
          ids.push(...this.extractAdGroupIds(item as QueryExpression));
        }

      }
    } else {
      const g = expression.condition;
      ids.push(...this.extractAdGroupIds(g));
    }

    return ids;

  }

  mergeAdGroups(expression: QueryExpression, adGroups: ADGroup[]): QueryExpression {

    const conditions = expression.conditions;

    if (conditions) {
      for (let i = 0; i < expression.conditions.length; i++) {

        const item = expression.conditions[i];

        if (this.isCondition(item)) {
          const c = item as ConditionExpression;
          if (c.values && c.property === 'adGroups') {
            c.values = c.values.map(value => {
              if (typeof value === 'string') {
                const adGroup = adGroups.find(group => group.guid === value);
                return {
                  id:          adGroup?.id,
                  guid:        adGroup?.guid,
                  name:        adGroup?.name,
                  description: adGroup?.description,
                };
              }
              return value;
            }) as any[];
          }
        } else if (this.isGroup(item)) {
          this.mergeAdGroups(item as QueryExpression, adGroups);
        }

      }
    } else {
      const g = expression.condition;
      this.mergeAdGroups(g, adGroups);
    }

    return expression;

  }

  validate(expression: QueryExpression): boolean {

    if (!expression) {
      return false;
    }

    if (!expression.type) {
      return false;
    }

    if (!expression.conditions) {
      return false;
    }

    for (let i = 0; i < expression.conditions.length; i++) {

      const item = expression.conditions[i];

      if (this.isCondition(item)) {
        const c = item as ConditionExpression;
        if (!c.property || !c.type) {
          return false;
        }
      } else if (this.isGroup(item)) {

        const g = item as QueryExpression;
        if (!g.type || !g.conditions) {
          return false;
        }
        this.validate(g);
      } else {
        return false;
      }

    }

    return true;
  }

  validatorsByType(type: FieldType): ValidatorFn[] {

    const fieldType               = this.types.value(type);
    let validators: ValidatorFn[] = null;

    if (fieldType) {
      validators = fieldType.validators;

      if (validators && validators.length === 0) {
        validators = null;
      }

    }

    return validators;
  }

  public createGroup(operator: LogicalOperator): FormGroup {
    if ([LogicalOperator.And, LogicalOperator.Or].includes(operator)) {
      return this.fb.group({
        type:       [operator],
        conditions: this.fb.array([], ArrayValidator.minLength(1)),
      });
    }
    return this.fb.group({
      type:      [operator],
      condition: this.fb.group({}),
    });
  }

  public createCondition(field?: string, condition?: ConditionOperator, value?: string | number | string[]): FormGroup {
    if (Array.isArray(value)) {
      return this.fb.group({
        property: [field, [Validators.required, this.validateField.bind(this)]],
        type:     [{ value: condition, disabled: true }, [Validators.required]],
        values:   this.fb.array(value, [Validators.required]),
      });
    }
    return this.fb.group({
      property: [field, [Validators.required, this.validateField.bind(this)]],
      type:     [{ value: condition, disabled: true }, [Validators.required]],
      value:    [value, [Validators.required]],
    });
  }

  private initTypes(): void {

    this._types = new KeyValueCollection<FieldTypeOperators>();

    this._types.add(FieldType.Bool, {
      type:       FieldType.Bool,
      operators:  [
        ConditionOperator.Equals,
        ConditionOperator.NotEquals,
      ],
      validators: [Validators.required, Validators.pattern('^(true|false|1|0)$')],
    });

    this._types.add(FieldType.Number, {
      type:       FieldType.Number,
      operators:  [
        ConditionOperator.Equals,
        ConditionOperator.GreaterEqual,
        ConditionOperator.GreaterThan,
        ConditionOperator.LessEqual,
        ConditionOperator.LessThan,
        ConditionOperator.NotEquals,
        ConditionOperator.Contains,
        ConditionOperator.StartsWith,
        ConditionOperator.EndsWith,
      ],
      validators: [Validators.required, Validators.pattern('^((\-?)([0-9]*)|(\-?)(([0-9]*)\.([0-9]*)))$')],
    });

    this._types.add(FieldType.Str, {
      type:       FieldType.Str,
      operators:  [
        ConditionOperator.Equals,
        ConditionOperator.NotEquals,
        ConditionOperator.Contains,
        ConditionOperator.StartsWith,
        ConditionOperator.EndsWith,
      ],
      validators: [Validators.required],
    });

    this._types.add(FieldType.Array, {
      type:       FieldType.Array,
      operators:  [
        ConditionOperator.InValues,
      ],
      validators: [Validators.required],
    });

    this._types.add(FieldType.AdGroupLookup, {
      type:       FieldType.Array,
      operators:  [
        ConditionOperator.InValues,
      ],
      validators: [Validators.required],
    });

  }

  public isCondition(value: ConditionExpression | QueryExpression): boolean {

    if (!value) {
      return false;
    }

    const item = value as ConditionExpression;
    return item.hasOwnProperty('property') && item.hasOwnProperty('type');
  }

  public isGroup(value: ConditionExpression | QueryExpression): boolean {

    if (!value) {
      return false;
    }

    const item = value as QueryExpression;
    return item.hasOwnProperty('type') && item.hasOwnProperty('conditions');
  }

  validateField(control: AbstractControl): boolean {
    const value       = control && control.value ? control.value : '';
    let result: Field = null;

    if (value && this.fields) {
      result = this.fields.value(value);
    }

    return result != null;
  }

}
