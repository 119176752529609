<ngx-modal-head [header]="modalData.title" [headerIcon]="modalData.icon || 'cr-warning'"
                [subheader]="modalData.subheader"
                [closeable]="true"></ngx-modal-head>
<ngx-modal-content class="mat-typography">
  <p class="m-0 text-align-center mat-body-1" [innerHTML]="modalData.content"></p>
  <ng-container *ngIf="modalData.typeConfirm">
    <mat-form-field appearance="outline" class="w-100 mb-16 mt-24">
      <mat-label>Type {{modalData.typeConfirmText}} to confirm</mat-label>
      <input matInput type="text" [formControl]="typeTextCtrl" data-cy="form-type-text"
             autocomplete="off" />
    </mat-form-field>
  </ng-container>
  <mat-checkbox color="primary" class="ml-12 mt-16" [formControl]="checkbox"
                *ngIf="modalData.canForce"
                [checked]="false">
    <span class="mat-body-1">
    {{modalData.forceCheckboxText}}
    </span>
  </mat-checkbox>
</ngx-modal-content>
<br>
<ngx-modal-actions>
  <button class="button primary" #modalAction type="button" data-cy="submit"
          [disabled]="(modalData.typeConfirm && typeTextCtrl?.value !== modalData.typeConfirmText) ||
          modalData.canForce && !checkbox.value"
          [mat-dialog-close]="true"
          *ngIf="!modalData.linkUrl">{{modalData.confirmBtnText}}</button>
  <a class="button primary"
     [href]="modalData.linkUrl"
     target="_blank"
     #modalAction
     rel="nofollow noopener"
     [mat-dialog-close]="true"
     *ngIf="modalData.linkUrl">{{modalData.confirmBtnText}}</a>
  <button class="button secondary" #modalAction type="button" data-cy="close" [mat-dialog-close]="false"
          *ngIf="modalData.showCancel">{{modalData.cancelBtnText}}
  </button>
</ngx-modal-actions>
