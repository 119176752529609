import * as NumberAction from './number.actions';

import { Alert }                                                  from '@models/entity/alert.model';
import { StateTask }                                              from '@models/entity/state-task.model';
import { BaseResponse, BaseState, requestReduce, responseReduce } from '../helpers/reducer.helper';
import { Action, createReducer, on }                              from '@ngrx/store';
import {
  ADD_NUMBER_RANGE_REQUEST,
  ASSIGN_INBOUND_NUMBER_REQUEST,
  BULK_NUMBER_UPDATE_REQUEST,
  BULK_PROVISION_REQUEST,
  DELETE_LOCATION_REQUEST,
  DELETE_NUMBER_RANGE_REQUEST,
  DELETE_NUMBER_REQUEST,
  DELETE_NUMBER_TAG_REQUEST,
  EXPORT_NUMBERS_REQUEST,
  FETCH_ALL_NUMBER_TAGS_REQUEST,
  FETCH_BULK_COUNT_BY_ASSIGNED_REQUEST,
  FETCH_INVENTORY_AREA_LIST_REQUEST,
  FETCH_INVENTORY_PROVIDER_LIST_REQUEST,
  FETCH_LOCATION_NAME_AVAILABILITY_REQUEST,
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATIONS_REQUEST,
  FETCH_NUMBER_COMPATIBILITY_REQUEST,
  FETCH_NUMBER_COUNTS_REQUEST,
  FETCH_NUMBER_LIST_REQUEST,
  FETCH_NUMBER_RANGE_LIST_REQUEST,
  FETCH_NUMBER_RANGE_REQUEST,
  FETCH_NUMBER_REQUEST,
  FETCH_NUMBER_TAGS_REQUEST,
  FETCH_NUMBERS_SERVICE_METADATA_REQUEST,
  FETCH_RANGE_COUNT_BY_EXHAUSTION_STATUS_REQUEST,
  IMPORT_NUMBERS_REQUEST,
  NUMBER_LOCATION_REMOVE_REQUEST,
  NUMBER_RESERVATION_REMOVE_REQUEST,
  PATCH_LOCATION_REQUEST,
  PATCH_NUMBER_RANGE_REQUEST,
  PATCH_NUMBER_REQUEST,
  PATCH_NUMBER_TAG_REQUEST,
  POST_LOCATION_REQUEST,
  SET_NUMBER_RANGE_ROUTES_REQUEST,
}                                                                 from './number.types';
import * as AuthAction                                            from '../auth/auth.actions';
import * as UIAction                                              from '../ui/ui.actions';
import * as ServiceAction                                         from '../service/service.actions';
import { NumberQueryParams }                                      from '@models/form/number-query-params.model';
import { createEntityAdapter, EntityAdapter }                     from '@ngrx/entity';
import { NumberItem }                                             from '@models/entity/number-item.model';
import { InventoryProvider }                                      from '@models/entity/inventory-provider.model';
import { BulkProvisionResponse }                                  from '@models/api/bulk-provision-response.model';
import { NumberTag }                                              from '@models/entity/number-tag.model';
import { NumberServiceStatus }                                    from '@enums/number-service-status.enum';
import { NumberAssignedCounts }                                   from '@models/entity/number-counts.model';
import { NumberRange }                                            from '@models/entity/number-range.model';
import { NumberRangeQueryParams }                                 from '@models/form/number-range-query-params.model';
import { AddNumberRangeResponse }                                 from '@models/api/add-number-range-response.model';
import {
  SetNumberRangeRoutesResponse,
}                                                                 from '@models/api/set-number-range-routes-response.model';
import { DeleteNumberRangeResponse }                              from '@models/api/delete-number-range-response.model';
import { DeleteNumberResponse }                                   from '@models/api/delete-number-response.model';
import { DeleteNumberTagResponse }                                from '@models/api/delete-number-tag-response.model';
import { NumberTagQueryParams }                                   from '@models/form/number-tag-query-params.model';
import { NumberRangeStatus }                                      from '@models/entity/number-status.model';
import { RouteRule }                                              from '@models/entity/route-rule.model';
import { FETCH_SERVICE_USER_LIST_REQUEST }                        from '@redux/service/service.types';
import { Location }                                               from '@models/entity/location.model';
import { LocationQueryParams }                                    from '@models/form/location-query-params.model';
import {
  DeleteLocationResponse,
}                                                                 from '@models/api/number/delete-location-response.model';
import {
  PostLocationResponse,
}                                                                 from '@models/api/number/post-location-response.model';
import { Availability }                                           from '@enums/availability.enum';
import { NumberServiceMetadata }                                  from '@models/entity/number-service-metadata.model';
import { InventoryArea }                                          from '@models/entity/inventory-area.model';
import {
  RangeExhaustionStatusCount,
}                                                                 from '@models/entity/range-exhaustion-status-count.model';

export interface NumberState extends BaseState<NumberItem> {
  error: Alert;
  message: Alert;
  pending: boolean;
  pendingTasks: StateTask[];
  selectedNumber: NumberItem;
  numbers: NumberItem[];
  tags: NumberTag[];
  numberTagsQueryParams: NumberTagQueryParams;
  numberQueryParams: NumberQueryParams;
  inventoryAreas: InventoryArea[];
  providers: InventoryProvider[];
  provisionResponse: BulkProvisionResponse;
  numberCounts: NumberAssignedCounts;
  numberRanges: NumberRange[];
  numberRangeQueryParams: NumberRangeQueryParams;
  selectedNumberRange: NumberRange;
  allNumberTags: NumberTag[];
  locations: Location[];
  locationQueryParams: LocationQueryParams;
  selectedLocation: Location;
  locationNameAvailability: Availability;
  bulkCountByAssigned: { [id: string]: NumberAssignedCounts },
  numberServiceMetadata: NumberServiceMetadata[];
  compatibleNumbers: { numbers: NumberItem[], requestId: string };
  rangeExhaustionStatusCount: RangeExhaustionStatusCount;
}

export function selectNumberId(a: NumberItem): string {
  return a.id;
}

export const adapter: EntityAdapter<NumberItem> = createEntityAdapter<NumberItem>({
  selectId: selectNumberId,
});

const initialState: NumberState = adapter.getInitialState({
  error:                      null,
  message:                    null,
  pending:                    false,
  pendingTasks:               [],
  tags:                       null,
  numberTagsQueryParams:      null,
  numbers:                    null,
  selectedNumber:             null,
  numberQueryParams:          null,
  numberFilters:              null,
  totalCount:                 null,
  providers:                  null,
  provisionResponse:          null,
  numberCounts:               {
    ASSIGNED:   0,
    UNASSIGNED: 0,
    RESERVED:   0,
    TOTAL:      0,
  },
  numberRanges:               null,
  numberRangeQueryParams:     null,
  selectedNumberRange:        null,
  inventoryAreas:             null,
  allNumberTags:              null,
  locations:                  null,
  locationQueryParams:        null,
  selectedLocation:           null,
  locationNameAvailability:   null,
  bulkCountByAssigned:        null,
  numberServiceMetadata:      null,
  compatibleNumbers:          null,
  rangeExhaustionStatusCount: null,
  responses:                  {},
});

function markNumberAsProcessing(state: NumberState, numberId: string): NumberState {
  return {
    ...state,
    numbers: state.numbers?.map(n => {
      return n.id === numberId ? new NumberItem({
        ...n,
        isProcessing:      true,
        status:            NumberServiceStatus.Configuring,
        statusColor:       'yellow',
        statusDescription: 'Configuring',
      }) : n;
    }) || state.numbers,
  };
}

function deleteNumberRange(res: DeleteNumberRangeResponse, state: NumberState): Partial<NumberState> {
  if (res.error || !res.id) {
    return {};
  }
  return {
    numberRanges:        state.numberRanges?.length ? state.numberRanges.map(range => {
      if (range.id === res.id) {
        return new NumberRange({ ...range, status: NumberRangeStatus.Deleted });
      }
      return range;
    }) : [],
    selectedNumberRange: state.selectedNumberRange?.id === res.id ? new NumberRange({
      ...state.selectedNumberRange,
      status: NumberRangeStatus.Deleted,
    }) : state.selectedNumberRange,
  };
}

function deleteNumberTag(res: DeleteNumberTagResponse, state: NumberState): Partial<NumberState> {
  if (res.error || !res.id) {
    return {};
  }
  return {
    tags: state.tags?.length ? state.tags.filter(tag => tag.id !== res.id) : [],
  };
}

function deleteLocation(res: DeleteLocationResponse, state: NumberState): Partial<NumberState> {
  if (res.error || !res.id) {
    return {};
  }
  return {
    locations:        state.locations?.length ? state.locations.filter(tag => tag.id !== res.id) : [],
    selectedLocation: state.selectedLocation?.id === res.id ? null : state.selectedLocation,
  };
}

function addNumberRange(res: AddNumberRangeResponse, state: NumberState): Partial<NumberState> {
  if (!res.error && (!state.numberRanges?.length || state.numberRanges.length < state.numberRangeQueryParams.pageSize)) {
    if (!state.numberRanges?.length) {
      return {
        numberRanges:        [res.data],
        selectedNumberRange: res.data,
      };
    }
    if (state.numberRanges?.some(range => range.id === res.data.id)) {
      return {
        numberRanges:        state.numberRanges.map(range => {
          if (range.id === res.data.id) {
            return res.data;
          }
          return range;
        }),
        selectedNumberRange: res.data,
      };
    }
    return {
      numberRanges:        state.numberRanges.concat(res.data),
      selectedNumberRange: res.data,
    };
  }

  return {
    selectedNumberRange: res.data,
  };
}


function addLocation(res: PostLocationResponse, state: NumberState): Partial<NumberState> {
  if (!res.error && (!state.locations?.length || state.locations.length < state.locationQueryParams.pageSize)) {
    if (!state.numberRanges?.length) {
      return {
        locations:        [res.data],
        selectedLocation: res.data,
      };
    }
    if (state.numberRanges?.some(range => range.id === res.data.id)) {
      return {
        locations:        state.locations.map(range => {
          if (range.id === res.data.id) {
            return res.data;
          }
          return range;
        }),
        selectedLocation: res.data,
      };
    }
    return {
      locations:        state.locations.concat(res.data),
      selectedLocation: res.data,
    };
  }

  return {
    selectedLocation: res.data,
  };
}

function updateNumberRangeRoutes(res: SetNumberRangeRoutesResponse, state: NumberState): Partial<NumberState> {
  if (!res.error) {
    return {
      numberRanges:        state.numberRanges?.some(range => range.id === res.data.id) ? state.numberRanges.map(range => {
        if (range.id !== res.data.id) {
          return range;
        }
        return new NumberRange({
          ...range,
          inboundRoute:  res.data.inboundRoute,
          outboundRoute: res.data.outboundRoute,
        });
      }) : state.numberRanges,
      selectedNumberRange: state.selectedNumberRange && state.selectedNumberRange.id === res.data.id ? new NumberRange({
        ...state.selectedNumberRange,
        requestId:     res.data.requestId,
        inboundRoute:  res.data.inboundRoute,
        outboundRoute: res.data.outboundRoute,
      }) : res.data,
    };
  }
  return {};
}

function deleteNumber(state: NumberState, res: DeleteNumberResponse): Partial<NumberState> {
  if (res.error || !res.id) {
    return {};
  }

  return {
    numbers: !res.error ? state.numbers?.filter(item => item.id !== res.id) || [] : state.numbers,
  };
}

export function patchLocation(state: NumberState, error: Alert, item: Location): Partial<NumberState> {
  return {
    locations:        error ? state.locations : state.locations ?
      (state.locations?.map(s => {
        if (s.id === item.id) {
          return item;
        }
        return s;
      }) || []) : state.locations,
    selectedLocation: error ? state.selectedLocation : item,
  };
}

const _numberReducer = createReducer(initialState,
  on(NumberAction.FetchNumberRangeRequestAction, requestReduce),
  on(NumberAction.FetchNumberRangeResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NUMBER_RANGE_REQUEST, res => ({
      selectedNumberRange: res.error ? state.selectedNumberRange : res.range,
    }))),
  on(NumberAction.AddNumberRangeRequestAction, requestReduce),
  on(NumberAction.AddNumberRangeResponseAction, (state, action) =>
    responseReduce(state, action, ADD_NUMBER_RANGE_REQUEST,
      res => addNumberRange(res, state))),
  on(NumberAction.PostLocationRequestAction, requestReduce),
  on(NumberAction.PostLocationResponseAction, (state, action) =>
    responseReduce(state, action, POST_LOCATION_REQUEST,
      res => addLocation(res, state))),
  on(NumberAction.PatchLocationRequestAction, requestReduce),
  on(NumberAction.PatchLocationResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_LOCATION_REQUEST, res => patchLocation(state, res.error, res.data))),
  on(NumberAction.FetchNumberRangeListRequestAction, requestReduce),
  on(NumberAction.FetchNumberRangeListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NUMBER_RANGE_LIST_REQUEST, res => ({
      numberRanges:           res.error ? state.numberRanges : res.models,
      numberRangeQueryParams: res.error ? state.numberRangeQueryParams : res.searchParams,
    }))),
  on(NumberAction.DeleteNumberRangeRequestAction, requestReduce),
  on(NumberAction.DeleteNumberRangeResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_NUMBER_RANGE_REQUEST, (res) => deleteNumberRange(res, state))),
  on(NumberAction.DeleteLocationRequestAction, requestReduce),
  on(NumberAction.DeleteLocationResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_LOCATION_REQUEST, (res) => deleteLocation(res, state))),
  on(NumberAction.DeleteNumberTagRequestAction, requestReduce),
  on(NumberAction.DeleteNumberTagResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_NUMBER_TAG_REQUEST, (res) => deleteNumberTag(res, state))),
  on(NumberAction.NumberReservationRemoveRequestAction, requestReduce),
  on(NumberAction.NumberReservationRemoveResponseAction, (state, action) =>
    responseReduce(state, action, NUMBER_RESERVATION_REMOVE_REQUEST, (res) => {
      return {
        numbers: res.error ? state.numbers : state.numbers.map(num => {
          if (num.id === res.id) {
            return new NumberItem({ ...num, reservation: null });
          }
          return num;
        }),
      };
    })),
  on(NumberAction.FetchNumberListRequestAction, requestReduce),
  on(NumberAction.FetchNumberListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NUMBER_LIST_REQUEST, res => ({
      numbers:           res.error ? state.numbers : res.models,
      numberQueryParams: res.error ? state.numberQueryParams : res.searchParams,
    }))),
  on(ServiceAction.FetchServiceUserListRequestAction, requestReduce),
  on(ServiceAction.FetchServiceUserListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SERVICE_USER_LIST_REQUEST, res => {
      return {
        numbers: res.models?.length && state.numbers?.length ? state.numbers.map(num => {
          if (!num.destinations?.some(dest => dest.serviceUser)) {
            return num;
          }
          return new NumberItem({
            ...num,
            destinations: num.destinations.map(dest => {
              if (!dest.serviceUser) {
                return dest;
              }
              const updatedServiceUser = res.models.find(user => user.id === dest.serviceUser?.id);
              if (!updatedServiceUser) {
                return dest;
              }
              return new RouteRule({ ...dest, serviceUser: updatedServiceUser });
            }),
          });
        }) : state.numbers,
      };
    })),
  on(NumberAction.FetchNumberRequestAction, requestReduce),
  on(NumberAction.FetchNumberResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NUMBER_REQUEST, res => ({
      selectedNumber: res?.number,
    }))),
  on(NumberAction.ExportNumbersRequestAction, requestReduce),
  on(NumberAction.ExportNumbersResponseAction, (state, action) =>
    responseReduce(state, action, EXPORT_NUMBERS_REQUEST, () => ({}))),
  on(NumberAction.ImportNumbersRequestAction, requestReduce),
  on(NumberAction.ImportNumbersResponseAction, (state, action) =>
    responseReduce(state, action, IMPORT_NUMBERS_REQUEST, res => {
      const response: { [key: string]: BaseResponse } = {};
      response[res.requestId]                         = res;
      return {
        responses: { ...state.responses, ...response },
      };
    })),
  on(NumberAction.FetchNumberCompatibilityRequestAction, requestReduce),
  on(NumberAction.FetchNumberCompatibilityResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NUMBER_COMPATIBILITY_REQUEST, res => {
      return {
        compatibleNumbers: res.error ? null : { numbers: res.numbers, requestId: res.requestId },
      };
    })),
  on(NumberAction.FetchLocationsRequestAction, requestReduce),
  on(NumberAction.FetchLocationsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LOCATIONS_REQUEST, res => ({
      locations:           res.error ? state.locations : res.models,
      locationQueryParams: res.error ? state.locationQueryParams : res.searchParams,
    }))),
  on(NumberAction.FetchLocationRequestAction, requestReduce),
  on(NumberAction.FetchLocationResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LOCATION_REQUEST, res => ({
      selectedLocation: res.error ? state.selectedLocation : res.data,
    }))),
  on(NumberAction.FetchLocationNameAvailabilityRequestAction, (state, action) =>
    requestReduce(state, action, () => ({
      locationNameAvailability: Availability.Checking,
    }))),
  on(NumberAction.FetchLocationNameAvailabilityResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LOCATION_NAME_AVAILABILITY_REQUEST, (res) =>
      ({ locationNameAvailability: res.availability }))),
  on(NumberAction.FetchNumberTagsRequestAction, requestReduce),
  on(NumberAction.FetchNumberTagsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NUMBER_TAGS_REQUEST, res => ({
      tags:                  res.error ? state.tags : res.tags,
      numberTagsQueryParams: res.error ? state.numberTagsQueryParams : res.searchParams,
    }))),
  on(NumberAction.FetchAllNumberTagsRequestAction, requestReduce),
  on(NumberAction.FetchAllNumberTagsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_ALL_NUMBER_TAGS_REQUEST, res => ({
      allNumberTags: res.error ? state.allNumberTags : res.tags,
    }))),
  on(NumberAction.PatchNumberTagRequestAction, requestReduce),
  on(NumberAction.PatchNumberTagResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_NUMBER_TAG_REQUEST, res => ({
      tags: res.error ? state.tags : state.tags.map(n => {
        return n.id === res.tag.id ? res.tag : n;
      }),
    }))),
  on(NumberAction.FetchRangeCountByExhaustionStatusRequestAction, requestReduce),
  on(NumberAction.FetchRangeCountByExhaustionStatusResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_RANGE_COUNT_BY_EXHAUSTION_STATUS_REQUEST, res => ({
      rangeExhaustionStatusCount: res.error ? state.rangeExhaustionStatusCount : res.data,
    }))),
  on(NumberAction.NumberLocationRemoveRequestAction, requestReduce),
  on(NumberAction.NumberLocationRemoveResponseAction, (state, action) =>
    responseReduce(state, action, NUMBER_LOCATION_REMOVE_REQUEST, res => ({
      numbers: res.error ? state.numbers : state.numbers.map(n => {
        return n.id === res.number.id ? new NumberItem({
          ...n,
          description: res.number.description,
          tags:        res.number.tags,
          locationId:  res.number.locationId,
          location:    res.number.location,
          requestId:   res.number.requestId,
        }) : n;
      }),
    }))),
  on(NumberAction.PatchNumberRequestAction, requestReduce),
  on(NumberAction.PatchNumberResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_NUMBER_REQUEST, res => ({
      numbers: res.error ? state.numbers : state.numbers.map(n => {
        return n.id === res.number.id ? new NumberItem({
          ...n,
          description: res.number.description,
          tags:        res.number.tags,
          locationId:  res.number.locationId,
          location:    res.number.location,
          requestId:   res.number.requestId,
        }) : n;
      }),
    }))),
  on(NumberAction.FetchInventoryAreaListRequestAction, requestReduce),
  on(NumberAction.FetchInventoryAreaListResponseAction, (state, action) => {
    return responseReduce(state, action, FETCH_INVENTORY_AREA_LIST_REQUEST, res => ({
      inventoryAreas: res.error ? state.inventoryAreas : res.data,
    }));
  }),
  on(NumberAction.PatchNumberRangeRequestAction, requestReduce),
  on(NumberAction.PatchNumberRangeResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_NUMBER_RANGE_REQUEST, res => ({
      numberRanges:        res.error ? state.numberRanges : state.numberRanges?.map(n => {
        return n.id === res.numberRange.id ? new NumberRange({
          ...n,
          description:    res.numberRange.description,
          name:           res.numberRange.name,
          operator:       res.numberRange.operator,
          requestId:      res.numberRange.requestId,
          outboundRoute:  res.numberRange.outboundRoute,
          usageThreshold: res.numberRange.usageThreshold,
        }) : n;
      }),
      selectedNumberRange: res.error || !state.selectedNumberRange ? state.selectedNumberRange : new NumberRange({
        ...(state.selectedNumberRange || {}),
        description:    res.numberRange.description,
        name:           res.numberRange.name,
        operator:       res.numberRange.operator,
        requestId:      res.numberRange.requestId,
        outboundRoute:  res.numberRange.outboundRoute,
        usageThreshold: res.numberRange.usageThreshold,
      } as NumberRange),
    }))),
  on(NumberAction.BulkNumberUpdateRequestAction, requestReduce),
  on(NumberAction.BulkNumberUpdateResponseAction, (state, action) =>
    responseReduce(state, action, BULK_NUMBER_UPDATE_REQUEST)),
  on(NumberAction.FetchNumbersServiceMetadataRequestAction, requestReduce),
  on(NumberAction.FetchNumbersServiceMetadataResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NUMBERS_SERVICE_METADATA_REQUEST, (res) => ({
      numberServiceMetadata: res.error ? state.numberServiceMetadata : res.data,
    }))),
  on(NumberAction.SetNumberRangeRoutesRequestAction, requestReduce),
  on(NumberAction.SetNumberRangeRoutesResponseAction, (state, action) =>
    responseReduce(state, action, SET_NUMBER_RANGE_ROUTES_REQUEST, res => updateNumberRangeRoutes(res, state))),
  on(NumberAction.AssignInboundNumberRequestAction, (state, action) =>
    requestReduce(state, action, (req) => req.confirm ? {} : markNumberAsProcessing(state, req.numberId))),
  on(NumberAction.AssignInboundNumberResponseAction, (state, action) =>
    responseReduce(state, action, ASSIGN_INBOUND_NUMBER_REQUEST, res => ({
      message: res.message,
    }))),
  on(NumberAction.FetchNumberCountsRequestAction, requestReduce),
  on(NumberAction.FetchNumberCountsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NUMBER_COUNTS_REQUEST,
      (res) => ({ numberCounts: res.error ? state.numberCounts : res.counts }))),
  on(NumberAction.DeleteNumberRequestAction, requestReduce),
  on(NumberAction.DeleteNumberResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_NUMBER_REQUEST, res => deleteNumber(state, res))),
  on(NumberAction.FetchBulkCountByAssignedRequestAction, requestReduce),
  on(NumberAction.FetchBulkCountByAssignedResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_BULK_COUNT_BY_ASSIGNED_REQUEST,
      (res) => ({ bulkCountByAssigned: res.error ? state.bulkCountByAssigned : res.data }))),
  on(NumberAction.FetchInventoryProviderRequestAction, requestReduce),
  on(NumberAction.FetchInventoryProviderResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_INVENTORY_PROVIDER_LIST_REQUEST, (res) => ({ providers: res.providers }))),
  on(NumberAction.BulkProvisionRequestAction, requestReduce),
  on(NumberAction.BulkProvisionResponseAction, (state, action) =>
    responseReduce(state, action, BULK_PROVISION_REQUEST, (res) => ({ provisionResponse: res }))),
  on(NumberAction.ClearSelectedNumberAction, (state) => ({ ...state, selectedNumber: null })),
  on(UIAction.DismissErrorAction, (state) =>
    ({ ...state, error: null })),
  on(UIAction.DismissMessageAction, (state) =>
    ({ ...state, message: null })),
  on(AuthAction.LogoutAction, () =>
    ({ ...initialState })),
);

export function numberReducer(state: NumberState, action: Action): NumberState {
  return _numberReducer(state, action);
}
