import { Injectable }                  from '@angular/core';
import { MicrosoftToken }              from '@enums/microsoft-token.enum';
import { TokenStatus }                 from '@enums/token-status.enum';
import { ProvisionStep }               from '@enums/provisioning-step.enum';
import { Observable, of }              from 'rxjs';
import { catchError, map }             from 'rxjs/operators';
import { HttpErrorResponse }           from '@angular/common/http';
import { Alert }                       from '@models/entity/alert.model';
import { ApiService }                  from './api.service';
import { environment }                 from '../../environments/environment';
import { ServiceItem }                 from '@models/entity/service-item.model';
import { StatusItem }                  from '@models/entity/status-item.model';
import { ProvisionAction }             from '@enums/provision-action.enum';
import { MicrosoftTeams }              from '@models/entity/microsoft-teams.model';
import { ServiceUIStatus }             from '@enums/service-ui-status';
import { Token }                       from '@models/entity/token-state.model';
import { ConfigureServiceResponseRaw } from '@models/api/configure-service-response-raw.model';
import { ConfigureServiceRequest }     from '@models/api/configure-service-request.model';
import { ConfigureServiceResponse }    from '@models/api/configure-service-response.model';
import { ProvisioningChecklistItem }   from '@models/api/provisioning-checklist-item.model';

@Injectable({
  providedIn: 'root',
})
export class ProvisioningService {

  static createProvisioningChecklist(
    service: ServiceItem,
    tokens: Token[],
    syncTaskIncomplete: boolean): ProvisioningChecklistItem[] {
    const lyncAuthStatus = tokens.find(token => token?.id === MicrosoftToken.MicrosoftLync)?.status;
    const expiredTokens  = ProvisioningService.getExpiredTokens(tokens);

    if (!service?.id) {
      return [];
    }
    if (ServiceItem.isMicrosoft(service)) {
      const configureIsCurrent = !service.isActive() &&
        service.getInitialAuthPassed(tokens) &&
        StatusItem.isSuccess(service.requirementStatus.status) &&
        StatusItem.isIncomplete(service.provisionStatus.status);

      const item = new MicrosoftTeams(service).updateProvisioningStatus(
        lyncAuthStatus,
        service.provisionStatus.status === ServiceUIStatus.Failed &&
        (service.provisionStatus.description || 'There was a problem configuring the service.'),
        ProvisionStep.Configure,
        configureIsCurrent);

      const serviceIsActive  = item.isActive();
      const healthState      = item.healthStatus;
      const requirementState = item.requirementStatus;
      const syncState        = item.initialSyncStatus;
      const checkList        = item.getChecklistItems(expiredTokens);
      const syncProcessing   = StatusItem.isProcessing(syncState.status) || syncTaskIncomplete;

      return [
        serviceIsActive ? null : {
          id:          item.id,
          label:       `Authorise ${ item.isOrto ? 'Orto' : 'Callroute' } App`,
          type:        ProvisionStep.Authorise,
          subItems:    [],
          expiredTokens,
          serviceType: item.serviceType,
          action:      ProvisionAction.Authorise,
          state:       item.authoriseStatus,
        },
        {
          id:          item.id,
          label:       serviceIsActive ? 'Health check' : 'Run readiness check',
          serviceType: item.serviceType,
          subItems:    checkList,
          expiredTokens,
          type:        serviceIsActive ? ProvisionStep.HealthCheck : ProvisionStep.RequirementsCheck,
          action:      ProvisionAction.Check,
          state:       serviceIsActive ? healthState : requirementState,
        },
        serviceIsActive ? null : {
          id:          item.id,
          label:       `Integrate ${ item.isOrto ? 'Orto' : 'Callroute' } App`,
          serviceType: item.serviceType,
          subItems:    [],
          state:       item.provisionStatus,
          type:        ProvisionStep.Configure,
          action:      ProvisionAction.Configure,
        },
        serviceIsActive ? null : {
          id:          item.id,
          label:       'Synchronise users',
          serviceType: item.serviceType,
          subItems:    [],
          state:       syncState,
          type:        ProvisionStep.Sync,
          action:      !syncProcessing && ProvisionAction.Sync,
        },
      ].filter(step => !!step);
    }

    let label = 'Service provisioned';
    if (!service.isActive()) {
      label = StatusItem.isFailed(service.provisionStatus.status) ? 'Setup failed' : 'Setup in progress';
    }

    return [
      {
        label,
        serviceType: service.serviceType,
        id:          service.id,
        subItems:    [],
        state:       !service.isActive() ? {
          ...service.provisionStatus,
          status: ServiceUIStatus.Pending,
        } : service.provisionStatus,
        type:        ProvisionStep.Configure,
        action:      null,
      },
    ];
  }

  static getExpiredTokens(tokens: Token[]): MicrosoftToken[] {
    // if both MS tokens needs reauth,then first is always MICROSOFT_AZURE
    const noAuth = !tokens?.length || tokens.every(token => token?.status !== TokenStatus.Pending && token?.status !== TokenStatus.Active);
    return noAuth ? [MicrosoftToken.MicrosoftAzure, MicrosoftToken.MicrosoftLync] :
      tokens?.filter(token => token?.status !== TokenStatus.Active)
        .map(token => token?.id);
  }

  static setProvisioningError(serviceItem: MicrosoftTeams, errorMessage: string): ServiceItem {
    if (!serviceItem) {
      return null;
    }
    return new MicrosoftTeams(serviceItem).updateProvisioningStatus(TokenStatus.Active, errorMessage, ProvisionStep.Configure, true);
  }

  private baseUrl: string = environment.api.serviceBaseUrl;

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  constructor(private apiService: ApiService) {

  }

  provisionService$(req: ConfigureServiceRequest): Observable<ConfigureServiceResponse> {
    return this.apiService.apiPost$<{ data: ConfigureServiceResponseRaw }>(
      this.buildUri(`services/${ req.id }/provision`),
      {},
      { authRequired: true, disableRetry: true })
      .pipe(
        map((res: { data: ConfigureServiceResponseRaw }): ConfigureServiceResponse => {
          return { ...res, serviceId: req.id, error: null };
        }),
        catchError((err: HttpErrorResponse): Observable<ConfigureServiceResponse> => {
          return of({
            serviceId: req.id,
            error:     new Alert().fromApiError(err, null, false),
          });
        }),
      );
  }

}
