import { BaseRequest } from '@redux/helpers/reducer.helper';

export class PatchNumberRangeRequest extends BaseRequest {
  id: string;
  name?: string;
  description?: string;
  usageThreshold?: number;
  serviceId?: string;
  operator?: string;

  constructor(private data?: PatchNumberRangeRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): { name: string, description: string, service_id: string, operator: string, usage_threshold: number } {
    return {
      name:            this.name,
      description:     this.description,
      service_id:      this.serviceId,
      operator:        this.operator,
      usage_threshold: this.usageThreshold !== undefined ? (this.usageThreshold !== null ? this.usageThreshold / 100 : null) : undefined,
    };
  }
}
