import { ServiceItem }    from '@models/entity/service-item.model';
import { getServiceIcon } from '@models/api/service.model';
import { ServiceType }    from '@enums/service-type.enum';
import { SIPPhoneRaw }    from '@models/api/sip-phone-raw.model';
import { NumberItem }     from '@models/entity/number-item.model';

export class SIPPhone extends ServiceItem {
  icon        = getServiceIcon(ServiceType.SIPPhone, false);
  colorIcon   = getServiceIcon(ServiceType.SIPPhone, true);
  serviceType = ServiceType.SIPPhone;
  fqdn: string;
  protocol: 'TLS' | 'UDP';
  username: string;
  password: string;
  deviceCertificate: {
    certificatePem: string;
    chainPem: string;
    privateKeyPem: string;
  };
  deviceCertificateExpiryDate: string;
  numberId: string;
  number?: NumberItem;
  isGatewayUp: boolean;

  constructor(protected data?: SIPPhone) {
    super();
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  toApiData(): SIPPhoneRaw {
    return {
      type:               this.serviceType,
      gateway_address:    this.fqdn,
      gateway_protocol:   this.protocol,
      label:              this.label,
      outbound_number_id: this.numberId,
    } as SIPPhoneRaw;
  }

  fromApiData?(apiData: SIPPhoneRaw): SIPPhone {
    super.fromApiData(apiData);
    this.fqdn                        = apiData.gateway_address;
    this.protocol                    = apiData.gateway_protocol;
    this.username                    = apiData.subscriber_username;
    this.password                    = apiData.subscriber_password;
    this.deviceCertificate           = apiData.device_certificate && {
      certificatePem: apiData.device_certificate.certificate_pem,
      chainPem:       apiData.device_certificate.chain_pem,
      privateKeyPem:  apiData.device_certificate.private_key_pem,
    };
    this.deviceCertificateExpiryDate = apiData.device_certificate_expiry_date;
    this.createdDate                 = apiData.created_date;
    this.label                       = apiData.label;
    this.numberId                    = apiData.outbound_number_id;
    this.isGatewayUp                 = apiData.is_gateway_up;

    return this;
  }
}
