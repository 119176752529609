import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name:       'plural',
  standalone: true,
})
export class PluralPipe implements PipeTransform {

  transform(value: string, amount: number): string {
    if (!value) {
      return '';
    }
    const singular = amount === 1;
    if (value.endsWith('s')) {
      return singular ? value.slice(0, value.length - 1) : value;
    }
    return singular ? value : value.concat('s');
  }

}
