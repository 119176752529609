import { Component, Input, OnDestroy }                   from '@angular/core';
import { AbstractControl, UntypedFormGroup }             from '@angular/forms';
import { BehaviorSubject, Subject }                      from 'rxjs';
import { FieldCheckType }                                from '@enums/field-check-type.enum';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { StoreState }                                    from '@redux/store';
import { Store }                                         from '@ngrx/store';
import { NumberQueryParams }                             from '@models/form/number-query-params.model';
import { MatAutocompleteSelectedEvent }                  from '@angular/material/autocomplete';
import { NumberItem }                                    from '@models/entity/number-item.model';
import { NumberState }                                   from '@redux/number/number.reducer';
import { FetchNumberListRequestAction }                  from '@redux/number/number.actions';
import { NumberRange }                                   from '@models/entity/number-range.model';
import { AssetsService }                                 from '@services/assets.service';
import { CarrierType }                                   from '@enums/carrier-type.enum';

@Component({
  selector:    'ngx-sip-phone-form',
  templateUrl: './sip-phone-form.component.html',
  styleUrls:   ['./sip-phone-form.component.scss'],
})
export class SipPhoneFormComponent implements OnDestroy {
  @Input() set editMode(value: boolean) {
    this._editMode = value;
    if (value) {
      this.formGroup.get('numberQuery')
        .valueChanges
        .pipe(
          takeUntil(this.destroy$),
          debounceTime(1_000))
        .subscribe(query => {
          if (!query) {
            this.resetNumberId();
          }
          this.fetchNumberList(query);
        });
    }
  }

  get editMode(): boolean {
    return this._editMode;
  }

  @Input() formGroup: UntypedFormGroup;
  @Input() existingRange: NumberRange;
  private _editMode = false;
  @Input() dialogMode: boolean;
  @Input() numberReducer: NumberState;

  get existingNumber(): NumberItem {
    return this.formGroup?.get('number')?.value;
  }

  protocols        = ['UDP'];
  FieldCheckType   = FieldCheckType;
  viewUsername     = new BehaviorSubject<boolean>(false);
  viewUsername$    = this.viewUsername.asObservable();
  viewPassword     = new BehaviorSubject<boolean>(false);
  viewPassword$    = this.viewPassword.asObservable();
  certCopyText     = new BehaviorSubject('Copy');
  certCopyText$    = this.certCopyText.asObservable()
    .pipe(distinctUntilChanged());
  privateCopyText  = new BehaviorSubject('Copy');
  privateCopyText$ = this.privateCopyText.asObservable()
    .pipe(distinctUntilChanged());
  bothCopyText     = new BehaviorSubject('Copy');
  bothCopyText$    = this.bothCopyText.asObservable()
    .pipe(distinctUntilChanged());
  CarrierType      = CarrierType;
  microsoftLogo    = AssetsService.getIcon('microsoft.png');
  callrouteLogo    = AssetsService.getFavicon('favicon-32x32.png');
  infoMessage      = `
  Note: you will need to raise a <a class="link" href="https://support.sipsynergy.co.uk" target="_blank">support ticket</a>
   requesting your public IP address to be whitelisted through our firewall. Your phone will not register until this has been completed.`;
  private destroy  = new Subject<void>();
  private destroy$ = this.destroy.asObservable();

  constructor(private store: Store<StoreState>) {
  }

  private resetNumberId(): void {
    const numberId = this.formGroup?.get('numberId')?.value;
    if (numberId) {
      this.formGroup.get('numberId')
        .setValue(null);
      this.formGroup.get('numberId')
        .updateValueAndValidity();
    }
  }

  get combinedKeys(): string {
    if (!this.formGroup?.get('privateKeyPem')?.value) {
      return null;
    }
    return this.formGroup.get('certificatePem')
      .value
      .concat('\n', this.formGroup.get('privateKeyPem').value);
  }

  fieldInvalid(control: AbstractControl, bSubmitted: boolean): boolean {
    return control.value && !control.valid && (bSubmitted || control.dirty);
  }

  removeExistingRange(ranges: Array<NumberRange>): Array<NumberRange> {
    if (!this.existingRange) {
      return ranges;
    } else {
      return ranges?.filter(n => n.id !== this.existingRange.id);
    }
  }

  getCarrierLogo(existingRange: NumberRange): string {
    if (existingRange?.provider?.carrierType === CarrierType.Held) {
      return 'https://cr-web-assets.s3.eu-west-2.amazonaws.com/carrier/sipsynergy.png';
    }
    if (!existingRange?.outboundRoute?.rules?.length) {
      return null;
    }
    return existingRange.outboundRoute.rules.find(rule => rule.priority === 1)?.carrierLogo;
  }

  doneCertCopy(): void {
    this.certCopyText.next('Copied');
  }

  donePrivateCopy(): void {
    this.privateCopyText.next('Copied');
  }

  doneBothCopy(): void {
    this.bothCopyText.next('Copied');
  }

  fetchNumberList(query?: string): void {
    const q: NumberQueryParams = new NumberQueryParams();

    q.pageSize   = 50;
    q.pageNumber = 1;
    q.sort       = 'number_e164';

    if (((query as string) || '').length >= 3) {
      q.search = query;
    }
    q.status = ['active'];

    if (this.formGroup.get('rangeId').value) {
      q.rangeId = [this.formGroup.get('rangeId').value];
    }

    this.store.dispatch(FetchNumberListRequestAction({ queryParams: q }));
  }

  setNumberId(e: MatAutocompleteSelectedEvent): void {
    if (e.option && e.option.value && (e.option.value as NumberItem).id) {
      this.formGroup.get('numberId')
        .setValue((e.option.value as NumberItem).id);
      this.formGroup.get('numberId')
        .updateValueAndValidity();
    }
  }

  getCountryIcon(countryCode: string): string {
    return AssetsService.getFlag(countryCode + '.svg');
  }

  setRangeId(e: MatAutocompleteSelectedEvent): void {
    const rangeId = (e?.option?.value as NumberRange)?.id;
    if (rangeId) {
      this.formGroup.get('rangeId')
        .setValue(rangeId);
      this.formGroup.get('rangeId')
        .updateValueAndValidity();
    }
  }

  getRangeName(rangeItem: NumberRange): string {
    if (!rangeItem?.provider) {
      return rangeItem?.name || '';
    }
    return `${ (rangeItem?.name || '') } - ${ rangeItem.provider?.name }`;
  }

  getNumberName(numberItem: NumberItem): string {
    return numberItem ? numberItem.didNumber : '';
  }

  isInvalid(control: AbstractControl, bSubmitted: boolean): boolean {
    if (!this.editMode) {
      return false;
    }
    return !control.valid && (bSubmitted || control.touched);
  }

  removeExistingNumber(numbers: Array<NumberItem>): Array<NumberItem> {
    if (!this.existingNumber) {
      return numbers;
    } else {
      return numbers?.filter(n => n.didNumber !== this.existingNumber.didNumber);
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }


}
