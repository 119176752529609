import { ApprovalRaw }    from '@models/api/identity/approval-raw.model';
import { ApprovalStatus } from '@enums/approval-status.enum';

export class Approval {
  id: string;
  createdDate: string;
  updatedDate: string;
  approvalMessage: string;
  approvalEmail: string;
  approverMessage: string;
  approverEmail: string;
  status: ApprovalStatus;

  constructor(private data?: Approval) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: ApprovalRaw): Approval {
    this.id              = data.id;
    this.createdDate     = data.created_date;
    this.updatedDate     = data.updated_date;
    this.approverMessage = data.approver_message;
    this.approverEmail   = data.approver_user?.email;
    this.approvalEmail   = data.approval_user?.email;
    this.approvalMessage = data.approval_message;
    this.status          = data.status;
    return this;
  }

  getStatusColor?(): string {
    switch (this.status) {
      case ApprovalStatus.Approved:
        return 'green';
      case ApprovalStatus.Pending:
        return 'orange';
      case ApprovalStatus.Rejected:
        return 'red';
    }
  }
}
