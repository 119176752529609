<ng-container *ngIf="serviceReducer$ | async; let serviceReducer">
  <ngx-modal-head *ngIf="dialogRef"
                  header="Webex Calling"
                  headerIcon="{{webexIcon}}"
                  [subheader]="subheader"
  ></ngx-modal-head>
  <ngx-modal-content [background]="!!dialogRef" [info]="dialogRef && serviceReducer.message"
                     [noPadding]="!dialogRef"
                     [overflow]="dialogRef ? 'auto' : 'visible'"
                     [error]="dialogRef && serviceReducer.error">
    <ngx-webex-form [formGroup]="formGroup"
                    [hiddenPassword]="hiddenPassword"
                    [hideUsername]="editMode$ | async"
                    [editMode]="editMode$ | async"
                    (submitted)="submitForm()"
                    [hiddenUsername]="hiddenUsername"></ngx-webex-form>
  </ngx-modal-content>
  <ngx-modal-actions>
    <button type="button"
            class="button primary"
            data-cy="submit"
            #modalAction
            (keydown.enter)="submitForm()"
            [disabled]="submitDisabled$ | async"
            *ngIf="(editMode$ | async) || isSetup()"
            (click)="submitForm()"
            matRipple>
      <ng-container *ngIf="!(isPending$ | async); else loading">
        <span>{{service ? 'Save' : 'Add service'}}</span>
      </ng-container>
    </button>
    <ngx-cr-button *ngIf="!isSetup()" text="{{(editMode$ | async) ? 'Cancel' : 'Modify'}}"
                   [type]="(editMode$ | async) ? ButtonType.Secondary : ButtonType.Primary"
                   data-cy="action"
                   #modalAction
                   [disabled]="btnDisabled$ | async"
                   [matTooltipDisabled]="!(btnDisabled$ | async)"
                   matTooltip="You do not have the required access to configure a service. Please contact your systems administrator for more information."
                   (onClick)="editClick()"></ngx-cr-button>
  </ngx-modal-actions>
</ng-container>
<ng-template #loading>
  <mat-spinner strokeWidth="3" diameter="26"></mat-spinner>
</ng-template>
