import { createAction, props }                               from '@ngrx/store';
import {
  CLEAR_CHECK_STATE,
  DELETE_CALL_QUEUE_GROUP_REQUEST,
  DELETE_CALL_QUEUE_GROUP_RESPONSE,
  DELETE_LICENSE_GROUP_REQUEST,
  DELETE_LICENSE_GROUP_RESPONSE,
  DELETE_TEAMS_GROUP_REQUEST,
  DELETE_TEAMS_GROUP_RESPONSE,
  FETCH_CALL_QUEUE_GROUP_LIST_REQUEST,
  FETCH_CALL_QUEUE_GROUP_LIST_RESPONSE,
  FETCH_CALL_QUEUE_GROUP_NAME_AVAILABILITY_REQUEST,
  FETCH_CALL_QUEUE_GROUP_NAME_AVAILABILITY_RESPONSE,
  FETCH_CALL_QUEUE_GROUP_REQUEST,
  FETCH_CALL_QUEUE_GROUP_RESPONSE,
  FETCH_CALL_QUEUES_REQUEST,
  FETCH_CALL_QUEUES_RESPONSE,
  FETCH_LICENSE_GROUP_LIST_REQUEST,
  FETCH_LICENSE_GROUP_LIST_RESPONSE,
  FETCH_LICENSE_GROUP_NAME_AVAILABILITY_REQUEST,
  FETCH_LICENSE_GROUP_NAME_AVAILABILITY_RESPONSE,
  FETCH_LICENSE_GROUP_REQUEST,
  FETCH_LICENSE_GROUP_RESPONSE,
  FETCH_LICENSES_REQUEST,
  FETCH_LICENSES_RESPONSE,
  FETCH_TEAM_GROUP_LIST_REQUEST,
  FETCH_TEAM_GROUP_LIST_RESPONSE,
  FETCH_TEAM_GROUP_NAME_AVAILABILITY_REQUEST,
  FETCH_TEAM_GROUP_NAME_AVAILABILITY_RESPONSE,
  FETCH_TEAM_GROUP_REQUEST,
  FETCH_TEAM_GROUP_RESPONSE,
  FETCH_TEAMS_REQUEST,
  FETCH_TEAMS_RESPONSE,
  HEALTH_CHECK_REQUEST,
  HEALTH_CHECK_RESPONSE,
  PATCH_CALL_QUEUE_GROUP_REQUEST,
  PATCH_CALL_QUEUE_GROUP_RESPONSE,
  PATCH_LICENSE_GROUP_REQUEST,
  PATCH_LICENSE_GROUP_RESPONSE,
  PATCH_TEAM_GROUP_REQUEST,
  PATCH_TEAM_GROUP_RESPONSE,
  POST_CALL_QUEUE_GROUP_REQUEST,
  POST_CALL_QUEUE_GROUP_RESPONSE,
  POST_LICENSE_GROUP_REQUEST,
  POST_LICENSE_GROUP_RESPONSE,
  POST_TEAM_GROUP_REQUEST,
  POST_TEAM_GROUP_RESPONSE,
  REQUIREMENT_CHECK_REQUEST,
  REQUIREMENT_CHECK_RESPONSE,
}                                                            from './microsoft-teams.types';
import { HealthCheckResponse }                               from '@models/api/health-check-response.model';
import { RequirementCheckResponse }                          from '@models/api/requirement-check-response.model';
import { HealthCheckRequest }                                from '@models/api/health-check-request.model';
import { FetchLicenseGroupListRequest }                      from '@models/api/fetch-license-group-list-request.model';
import { FetchLicenseGroupListResponse }                     from '@models/api/fetch-license-group-list-response.model';
import { FetchLicenseGroupRequest }                          from '@models/api/fetch-license-group-request.model';
import { FetchLicenseGroupResponse }                         from '@models/api/fetch-license-group-response.model';
import { DeleteLicenseGroupRequest }                         from '@models/api/delete-license-group-request.model';
import { DeleteLicenseGroupResponse }                        from '@models/api/delete-license-group-response.model';
import { PostLicenseGroupRequest }                           from '@models/api/post-license-group-request.model';
import { PostLicenseGroupResponse }                          from '@models/api/post-license-group-response.model';
import { PatchLicenseGroupRequest }                          from '@models/api/patch-license-group-request.model';
import { PatchLicenseGroupResponse }                         from '@models/api/patch-license-group-response.model';
import { FetchLicensesRequest }                              from '@models/api/fetch-licenses-request.model';
import { FetchLicensesResponse }                             from '@models/api/fetch-licenses-response.model';
import {
  FetchLicenseGroupNameAvailabilityRequest,
}                                                            from '@models/api/fetch-license-group-name-availability-request.model';
import {
  FetchLicenseGroupNameAvailabilityResponse,
}                                                            from '@models/api/fetch-license-group-name-availability-response.model';
import {
  FetchTeamGroupListRequest,
}                                                            from '@models/api/microsoft-teams/fetch-teams-group-list-request.model';
import {
  FetchTeamGroupListResponse,
}                                                            from '@models/api/microsoft-teams/fetch-teams-group-list-response.model';
import {
  FetchTeamsRequest,
}                                                            from '@models/api/microsoft-teams/fetch-teams-request.model';
import {
  FetchTeamsResponse,
}                                                            from '@models/api/microsoft-teams/fetch-teams-response.model';
import {
  DeleteTeamGroupRequest,
}                                                            from '@models/api/microsoft-teams/delete-teams-group-request.model';
import {
  DeleteTeamGroupResponse,
}                                                            from '@models/api/microsoft-teams/delete-teams-group-response.model';
import {
  PostTeamGroupRequest,
}                                                            from '@models/api/microsoft-teams/post-teams-group-request.model';
import {
  PostTeamGroupResponse,
}                                                            from '@models/api/microsoft-teams/post-teams-group-response.model';
import {
  PatchTeamGroupRequest,
}                                                            from '@models/api/microsoft-teams/patch-teams-group-request.model';
import {
  PatchTeamGroupResponse,
}                                                            from '@models/api/microsoft-teams/patch-teams-group-response.model';
import {
  FetchTeamGroupNameAvailabilityRequest,
}                                                            from '@models/api/microsoft-teams/fetch-team-group-name-availability-request.model';
import {
  FetchTeamGroupNameAvailabilityResponse,
}                                                            from '@models/api/microsoft-teams/fetch-team-group-name-availability-response.model';
import {
  FetchTeamGroupRequest,
}                                                            from '@models/api/microsoft-teams/fetch-team-group-request.model';
import {
  FetchTeamGroupResponse,
}                                                            from '@models/api/microsoft-teams/fetch-team-group-response.model';
import {
  FetchCallQueueGroupRequest,
}                                                            from '@models/api/microsoft-teams/fetch-call-queue-group-request.model';
import {
  FetchCallQueuesResponse,
}                                                            from '@models/api/microsoft-teams/fetch-call-queues-response.model';
import {
  FetchCallQueueGroupResponse,
}                                                            from '@models/api/microsoft-teams/fetch-call-queue-group-response.model';
import {
  FetchCallQueuesRequest,
}                                                            from '@models/api/microsoft-teams/fetch-call-queues-request.model';
import {
  FetchCallQueueGroupListRequest,
}                                                            from '@models/api/microsoft-teams/fetch-call-queue-group-list-request.model';
import {
  FetchCallQueueGroupListResponse,
}                                                            from '@models/api/microsoft-teams/fetch-call-queue-group-list-response.model';
import {
  DeleteCallQueueGroupRequest,
}                                                            from '@models/api/microsoft-teams/delete-call-queue-group-request.model';
import {
  DeleteCallQueueGroupResponse,
}                                                            from '@models/api/microsoft-teams/delete-call-queue-group-response.model';
import {
  FetchCallQueueGroupNameAvailabilityRequest,
}                                                            from '@models/api/microsoft-teams/fetch-call-queue-group-name-availability-request.model';
import {
  FetchCallQueueGroupNameAvailabilityResponse,
}                                                            from '@models/api/microsoft-teams/fetch-call-queue-group-name-availability-response.model';
import {
  PostCallQueueGroupRequest,
}                                                            from '@models/api/microsoft-teams/post-call-queue-group-request.model';
import {
  PostCallQueueGroupResponse,
}                                                            from '@models/api/microsoft-teams/post-call-queue-group-response.model';
import {
  PatchCallQueueGroupRequest,
}                                                            from '@models/api/microsoft-teams/patch-call-queue-group-request.model';
import {
  PatchCallQueueGroupResponse,
}                                                            from '@models/api/microsoft-teams/patch-call-queue-group-response.model';
import { FETCH_AD_GROUPS_REQUEST, FETCH_AD_GROUPS_RESPONSE } from '@redux/microsoft-teams/microsoft-teams.types';
import { FetchAdGroupsRequest }                              from '@models/api/service/fetch-ad-groups-request.model';
import { FetchAdGroupsResponse }                             from '@models/api/service/fetch-ad-groups-response.model';
import { RequirementCheckRequest }                           from '@models/api/requirement-check-request.model';

export const HealthCheckRequestAction  = createAction(HEALTH_CHECK_REQUEST, props<HealthCheckRequest>());
export const HealthCheckResponseAction = createAction(HEALTH_CHECK_RESPONSE, props<HealthCheckResponse>());

export const RequirementCheckRequestAction  = createAction(REQUIREMENT_CHECK_REQUEST, props<RequirementCheckRequest>());
export const RequirementCheckResponseAction = createAction(REQUIREMENT_CHECK_RESPONSE, props<RequirementCheckResponse>());

export const ClearCheckState = createAction(CLEAR_CHECK_STATE);

export const FetchLicenseGroupListRequestAction  = createAction(FETCH_LICENSE_GROUP_LIST_REQUEST, props<FetchLicenseGroupListRequest>());
export const FetchLicenseGroupListResponseAction = createAction(FETCH_LICENSE_GROUP_LIST_RESPONSE, props<FetchLicenseGroupListResponse>());

export const FetchLicenseGroupRequestAction  = createAction(FETCH_LICENSE_GROUP_REQUEST, props<FetchLicenseGroupRequest>());
export const FetchLicenseGroupResponseAction = createAction(FETCH_LICENSE_GROUP_RESPONSE, props<FetchLicenseGroupResponse>());

export const FetchLicensesRequestAction  = createAction(FETCH_LICENSES_REQUEST, props<FetchLicensesRequest>());
export const FetchLicensesResponseAction = createAction(FETCH_LICENSES_RESPONSE, props<FetchLicensesResponse>());

export const DeleteLicenseGroupRequestAction  = createAction(DELETE_LICENSE_GROUP_REQUEST, props<DeleteLicenseGroupRequest>());
export const DeleteLicenseGroupResponseAction = createAction(DELETE_LICENSE_GROUP_RESPONSE, props<DeleteLicenseGroupResponse>());

export const PostLicenseGroupRequestAction  = createAction(POST_LICENSE_GROUP_REQUEST, props<PostLicenseGroupRequest>());
export const PostLicenseGroupResponseAction = createAction(POST_LICENSE_GROUP_RESPONSE, props<PostLicenseGroupResponse>());

export const PatchLicenseGroupRequestAction  = createAction(PATCH_LICENSE_GROUP_REQUEST, props<PatchLicenseGroupRequest>());
export const PatchLicenseGroupResponseAction = createAction(PATCH_LICENSE_GROUP_RESPONSE, props<PatchLicenseGroupResponse>());

export const PostTeamGroupRequestAction  = createAction(POST_TEAM_GROUP_REQUEST, props<PostTeamGroupRequest>());
export const PostTeamGroupResponseAction = createAction(POST_TEAM_GROUP_RESPONSE, props<PostTeamGroupResponse>());

export const PatchTeamGroupRequestAction  = createAction(PATCH_TEAM_GROUP_REQUEST, props<PatchTeamGroupRequest>());
export const PatchTeamGroupResponseAction = createAction(PATCH_TEAM_GROUP_RESPONSE, props<PatchTeamGroupResponse>());

export const FetchLicenseGroupNameAvailabilityRequestAction  = createAction(FETCH_LICENSE_GROUP_NAME_AVAILABILITY_REQUEST, props<FetchLicenseGroupNameAvailabilityRequest>());
export const FetchLicenseGroupNameAvailabilityResponseAction = createAction(FETCH_LICENSE_GROUP_NAME_AVAILABILITY_RESPONSE, props<FetchLicenseGroupNameAvailabilityResponse>());

export const FetchTeamGroupNameAvailabilityRequestAction  = createAction(FETCH_TEAM_GROUP_NAME_AVAILABILITY_REQUEST, props<FetchTeamGroupNameAvailabilityRequest>());
export const FetchTeamGroupNameAvailabilityResponseAction = createAction(FETCH_TEAM_GROUP_NAME_AVAILABILITY_RESPONSE, props<FetchTeamGroupNameAvailabilityResponse>());

export const FetchTeamGroupListRequestAction  = createAction(FETCH_TEAM_GROUP_LIST_REQUEST, props<FetchTeamGroupListRequest>());
export const FetchTeamGroupListResponseAction = createAction(FETCH_TEAM_GROUP_LIST_RESPONSE, props<FetchTeamGroupListResponse>());

export const FetchTeamsRequestAction  = createAction(FETCH_TEAMS_REQUEST, props<FetchTeamsRequest>());
export const FetchTeamsResponseAction = createAction(FETCH_TEAMS_RESPONSE, props<FetchTeamsResponse>());

export const FetchTeamGroupRequestAction  = createAction(FETCH_TEAM_GROUP_REQUEST, props<FetchTeamGroupRequest>());
export const FetchTeamGroupResponseAction = createAction(FETCH_TEAM_GROUP_RESPONSE, props<FetchTeamGroupResponse>());

export const DeleteTeamGroupRequestAction  = createAction(DELETE_TEAMS_GROUP_REQUEST, props<DeleteTeamGroupRequest>());
export const DeleteTeamGroupResponseAction = createAction(DELETE_TEAMS_GROUP_RESPONSE, props<DeleteTeamGroupResponse>());

export const FetchCallQueueGroupRequestAction  = createAction(FETCH_CALL_QUEUE_GROUP_REQUEST, props<FetchCallQueueGroupRequest>());
export const FetchCallQueueGroupResponseAction = createAction(FETCH_CALL_QUEUE_GROUP_RESPONSE, props<FetchCallQueueGroupResponse>());

export const FetchCallQueueGroupListRequestAction  = createAction(FETCH_CALL_QUEUE_GROUP_LIST_REQUEST, props<FetchCallQueueGroupListRequest>());
export const FetchCallQueueGroupListResponseAction = createAction(FETCH_CALL_QUEUE_GROUP_LIST_RESPONSE, props<FetchCallQueueGroupListResponse>());

export const FetchCallQueuesRequestAction  = createAction(FETCH_CALL_QUEUES_REQUEST, props<FetchCallQueuesRequest>());
export const FetchCallQueuesResponseAction = createAction(FETCH_CALL_QUEUES_RESPONSE, props<FetchCallQueuesResponse>());

export const DeleteCallQueueGroupRequestAction  = createAction(DELETE_CALL_QUEUE_GROUP_REQUEST, props<DeleteCallQueueGroupRequest>());
export const DeleteCallQueueGroupResponseAction = createAction(DELETE_CALL_QUEUE_GROUP_RESPONSE, props<DeleteCallQueueGroupResponse>());

export const FetchCallQueueGroupNameAvailabilityRequestAction  = createAction(FETCH_CALL_QUEUE_GROUP_NAME_AVAILABILITY_REQUEST, props<FetchCallQueueGroupNameAvailabilityRequest>());
export const FetchCallQueueGroupNameAvailabilityResponseAction = createAction(FETCH_CALL_QUEUE_GROUP_NAME_AVAILABILITY_RESPONSE, props<FetchCallQueueGroupNameAvailabilityResponse>());

export const PostCallQueueGroupRequestAction  = createAction(POST_CALL_QUEUE_GROUP_REQUEST, props<PostCallQueueGroupRequest>());
export const PostCallQueueGroupResponseAction = createAction(POST_CALL_QUEUE_GROUP_RESPONSE, props<PostCallQueueGroupResponse>());

export const PatchCallQueueGroupRequestAction  = createAction(PATCH_CALL_QUEUE_GROUP_REQUEST, props<PatchCallQueueGroupRequest>());
export const PatchCallQueueGroupResponseAction = createAction(PATCH_CALL_QUEUE_GROUP_RESPONSE, props<PatchCallQueueGroupResponse>());

export const FetchAdGroupsRequestAction  = createAction(FETCH_AD_GROUPS_REQUEST, props<FetchAdGroupsRequest>());
export const FetchAdGroupsResponseAction = createAction(FETCH_AD_GROUPS_RESPONSE, props<FetchAdGroupsResponse>());
