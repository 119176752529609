export enum ServiceName {
  None                    = 'None',
  MicrosoftTeams          = 'Microsoft Teams',
  CallrouteMicrosoftTeams = 'Callroute for Microsoft Teams',
  Orto                    = 'Orto for Microsoft Teams',
  WebexCalling            = 'Webex Calling',
  SIPTrunk                = 'SIP Trunk',
  SIPPhone                = 'SIP Phone',
  CallForwarding          = 'Call Forward',
  Carrier                 = 'Carrier'
}
