import { Observable, of }                from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ApiService }                           from './api.service';
import { ListService }                          from './list.service';
import { environment }                          from '../../environments/environment';
import { FetchServiceUserListRequest }          from '@models/api/fetch-service-user-list-request.model';
import { AssignServiceUserNumberResponseRaw }   from '@models/api/assign-service-user-number-response-raw.model';
import { FetchServiceUserListResponse }         from '@models/api/fetch-service-user-list-response.model';
import { CallingProfileRaw }                    from '@models/api/calling-profile-raw.model';
import { Store }                                from '@ngrx/store';
import { MicrosoftTeamsRaw }                    from '@models/api/microsoftTeamsRaw.model';
import { PutCallingProfileRequest }             from '@models/api/put-calling-profile-request.model';
import { TeamsUserSearchParamsFactory }         from '@models/factory/teams-user-params.factory';
import { GatewayStatusEvent }                   from '@models/entity/gateway-status-event.model';
import { GatewayStatusQueryParams }             from '@models/form/gateway-status-query-params.model';
import { FetchServiceCountsResponse }           from '@models/api/fetch-service-counts-response.model';
import { FetchPolicyListResponse }              from '@models/api/fetch-policy-list-response.model';
import { SIPPhone }                             from '@models/entity/sip-phone.model';
import { ListResponseMetadata }                 from '@models/api/list-response-metadata.model';
import { FetchCallingProfilesRequest }          from '@models/api/fetch-calling-profiles-request.model';
import { ServiceType }                          from '@enums/service-type.enum';
import { SendCustomCarrierResponse }            from '@models/api/send-custom-carrier-response.model';
import { ServiceCarrierRaw }                    from '@models/api/carrier-service-raw.model';
import { AssignServiceUserNumberResponse }      from '@models/api/assign-service-user-number-response.model';
import { SIPTrunk }                             from '@models/entity/sip-trunk.model';
import { Availability }                         from '@enums/availability.enum';
import { AvailabilityCheckRaw }                 from '@models/api/availability-check-raw.model';
import { FetchCallingProfilesResponse }         from '@models/api/fetch-calling-profiles-response.model';
import { FetchGatewayStatusEventsRequest }      from '@models/api/fetch-gateway-status-events-request.model';
import { ServiceItemRaw }                       from '@models/api/service-api-response.model';
import { CallingProfileParamsFactory }          from '@models/factory/calling-profile-params.factory';
import { SyncServiceUserListResponse }          from '@models/api/sync-service-user-list-response.model';
import { CallingProfileQueryParams }            from '@models/form/calling-profile-query-params.model';
import { CallForwardingRaw }                    from '@models/api/call-forwarding-raw.model';
import { ConfigureServiceResponse }             from '@models/api/configure-service-response.model';
import { SIPPhoneRaw }                          from '@models/api/sip-phone-raw.model';
import { ServiceUserStatusCounts }              from '@models/api/service-user-status-counts';
import { FetchServiceListRequest }              from '@models/api/fetch-service-list-request.model';
import { ProvisioningService }                  from '@services/provisioning.service';
import { FetchServiceResponse }                 from '@models/api/fetch-service-response.model';
import { selectTokens }                         from '@redux/access-broker/access-broker.selectors';
import { ServiceItem }                          from '@models/entity/service-item.model';
import { MicrosoftTeams }                       from '@models/entity/microsoft-teams.model';
import { MicrosoftTeamsUserProfileFactory }     from '@models/factory/microsoft-teams-user-profile.factory';
import { CallForwarding }                       from '@models/entity/call-forwarding.model';
import { FetchPolicyListRequest }               from '@models/api/fetch-policy-list-request.model';
import { SyncPoliciesResponse }                 from '@models/api/sync-policies-response.model';
import { DeleteCallingProfileResponse }         from '@models/api/delete-calling-profile-response.model';
import { WebexCallingRaw }                      from '@models/api/webex-calling-raw.model';
import { FetchGatewayStatusEventsResponse }     from '@models/api/fetch-gateway-status-events-response.model';
import { TeamsUserQueryParams }                 from '@models/form/teams-user-query-params.model';
import { AssignServiceUserNumberRequest }       from '@models/api/assign-service-user-number-request.model';
import { DeleteServiceRequest }                 from '@models/api/delete-service-request.model';
import { CallingProfileFactory }                from '@models/factory/calling-profile.factory';
import { StoreState }                           from '@redux/store';
import { SyncServiceUserListRequest }           from '@models/api/sync-service-user-list-request.model';
import { MicrosoftTeamsUser }                   from '@models/entity/microsoft-teams-user.model';
import { SendCustomCarrierRequest }             from '@models/api/send-custom-carrier-request.model';
import { SyncPoliciesRequest }                  from '@models/api/sync-policies-request.model';
import { DeleteServiceResponse }                from '@models/api/delete-service-response.model';
import { TokenStatus }                          from '@enums/token-status.enum';
import { PolicyList }                           from '@models/entity/policy-list.model';
import { HttpErrorResponse }                    from '@angular/common/http';
import { PutCallingProfileResponse }            from '@models/api/put-calling-profile-response.model';
import { CallingProfile }                       from '@models/entity/calling-profile.model';
import { FetchServiceListResponse }             from '@models/api/fetch-service-list-response.model';
import { SIPTrunkRaw }                          from '@models/api/sip-trunk-raw.model';
import { FetchServiceRequest }                  from '@models/api/fetch-service-request.model';
import { FetchPersonaNameAvailabilityRequest }  from '@models/api/fetch-persona-name-availability-request.model';
import { WebexCalling }                         from '@models/entity/webex-calling.model';
import { ServiceQueryParams }                   from '@models/form/service-query-params.model';
import { ListModelResponse }                    from '@models/api/list-response.model';
import { DeleteCallingProfileRequest }          from '@models/api/delete-calling-profile-request.model';
import { ServiceCountsRaw }                     from '@models/api/service-counts-raw.model';
import { PolicyGroup }                          from '@models/api/policy-group.model';
import { ServiceCarrier }                       from '@models/entity/carrier-service.model';
import { FetchPersonaNameAvailabilityResponse } from '@models/api/fetch-persona-name-availability-response.model';
import { Alert }                                from '@models/entity/alert.model';
import { ConfigureServiceRequest }              from '@models/api/configure-service-request.model';
import { MicrosoftToken }                       from '@enums/microsoft-token.enum';
import { MigrateProfilesRequest }               from '@models/api/migrate-profiles-request.model';
import { MigrateProfilesResponse }              from '@models/api/migrate-profiles.response.model';
import { UserConfigureError }                   from '@models/entity/user-configure-error.model';
import { FetchServiceSchemaResponse }           from '@models/api/fetch-service-schema-response.model';
import { FetchServiceSchemaRequest }            from '@models/api/fetch-service-schema-request.model';
import { ServiceSchema }                        from '@models/entity/service-schema.model';
import { PostAutomationRequest }                from '@models/api/post-automation-request.model';
import { PostAutomationResponse }               from '@models/api/post-automation-response.model';
import { AutomationRaw }                        from '@models/api/automation-raw.model';
import { Automation }                           from '@models/entity/automation.model';
import { PatchAutomationRequest }               from '@models/api/patch-automation-request.model';
import { PatchAutomationResponse }              from '@models/api/patch-automation-response.model';
import { AutomationQueryParams }                from '@models/form/automation-query-params.model';
import { FetchAutomationListResponse }          from '@models/api/fetch-automation-list-response.model';
import { FetchAutomationListRequest }           from '@models/api/fetch-automation-list-request.model';
import { AutomationFactory }                    from '@models/factory/automation.factory';
import { AutomationParamsFactory }              from '@models/factory/automation-params.factory';
import { FetchAutomationRequest }               from '@models/api/fetch-automation-request.model';
import { FetchAutomationResponse }              from '@models/api/fetch-automation-response.model';
import { DeleteAutomationRequest }              from '@models/api/delete-automation-request.model';
import { DeleteAutomationResponse }             from '@models/api/delete-automation-response.model';
import { UserSyncVersion }                      from '@enums/user-sync-version.enum';
import { AssignLicenseGroupRequest }            from '@models/api/assign-license-group-request.model';
import { AssignLicenseGroupResponse }           from '@models/api/assign-license-group-response.model';
import { AssignLicenseGroupRaw }                from '@models/api/assign-license-group-raw.model';
import { SyncNumbersRequest }                   from '@models/api/sync-numbers-request.model';
import { SyncNumbersResponse }                  from '@models/api/sync-numbers-response.model';
import { AssignTeamGroupRequest }               from '@models/api/service/assign-team-group-request.model';
import { AssignTeamGroupResponse }              from '@models/api/service/assign-team-group-response.model';
import { AssignTeamGroupRaw }                   from '@models/api/service/assign-team-group-raw.model';
import { FetchCallingProfileRequest }           from '@models/api/service/fetch-calling-profile-request.model';
import { FetchCallingProfileResponse }          from '@models/api/service/fetch-calling-profile-response.model';
import { SyncTeamsRequest }                     from '@models/api/service/sync-teams-request.model';
import { SyncTeamsResponse }                    from '@models/api/service/sync-teams-response.model';
import { SyncLicensesRequest }                  from '@models/api/microsoft-teams/sync-licenses-request.model';
import { SyncLicensesResponse }                 from '@models/api/microsoft-teams/sync-licenses-response.model';
import { SyncCallQueuesRequest }                from '@models/api/service/sync-call-queues-request.model';
import { SyncCallQueuesResponse }               from '@models/api/service/sync-call-queues-response.model';
import { TeamGroupRole }                        from '@enums/team-group-role.enum';
import { AssignCallQueueGroupResponse }         from '@models/api/service/assign-call-queue-group-response.model';
import { AssignCallQueueGroupRequest }          from '@models/api/service/assign-call-queue-group-request.model';
import { AssignCallQueueGroupRaw }              from '@models/api/service/assign-call-queue-group-raw.model';
import { FetchServiceUserRequest }              from '@models/api/service/fetch-service-user-request.model';
import { FetchServiceUserResponse }             from '@models/api/service/fetch-service-user-response.model';
import { MicrosoftTeamsUserRaw }                from '@models/api/microsoft-teams-raw.model';
import {
  FetchServiceUserCountsByVoiceTypeResponse,
}                                               from '@models/api/service/fetch-service-user-counts-by-voice-type-response.model';
import {
  FetchServiceUserCountsByVoiceTypeRequest,
}                                               from '@models/api/service/fetch-service-user-counts-by-voice-type-request.model';
import {
  FetchAutomationNameAvailabilityRequest,
}                                               from '@models/api/service/fetch-automation-name-availability-request.model';
import {
  FetchAutomationNameAvailabilityResponse,
}                                               from '@models/api/service/fetch-automation-name-availability-response.model';
import { SyncAdGroupsRequest }                  from '@models/api/service/sync-ad-groups-request.model';
import { SyncAdGroupsResponse }                 from '@models/api/service/sync-ad-groups-response.model';
import {
  SyncAllMicrosoftAssetsRequest,
}                                               from '@models/api/service/sync-all-microsoft-assets-request.model';
import {
  SyncAllMicrosoftAssetsResponse,
}                                               from '@models/api/service/sync-all-microsoft-assets-response.model';
import { SyncDomainsRequest }                   from '@models/api/service/sync-domains-request.model';
import { SyncDomainsResponse }                  from '@models/api/service/sync-domains-response.model';
import { ExportServiceUsersResponse }           from '@models/api/service/export-service-users-response.model';
import { ExportServiceUsersRequest }            from '@models/api/service/export-service-users-request.model';
import {
  FetchEmergencyLocationListRequest,
}                                               from '@models/api/service/fetch-emergency-location-list-request.model';
import {
  FetchEmergencyLocationListResponse,
}                                               from '@models/api/service/fetch-emergency-location-list-response.model';
import { EmergencyLocation }                    from '@models/entity/emergency-location.model';
import { EmergencyLocationFactory }             from '@models/factory/emergency-location.factory';
import { EmergencyLocationQueryParams }         from '@models/form/emergency-location-query-params.model';
import { EmergencyLocationQueryParamsFactory }  from '@models/factory/emergency-location-query-params.factory';
import { FetchEmergencyLocationRequest }        from '@models/api/service/fetch-emergency-location-request.model';
import {
  FetchEmergencyLocationResponse,
}                                               from '@models/api/service/fetch-emergency-location-response.model';
import { EmergencyLocationRaw }                 from '@models/api/service/emergency-location-raw.model';


@Injectable({
  providedIn: 'root',
})
export class ServiceService {

  private baseUrl: string = environment.api.serviceBaseUrl;
  private userFactory: MicrosoftTeamsUserProfileFactory;
  private userParamFactory: TeamsUserSearchParamsFactory;
  private callingProfileFactory: CallingProfileFactory;
  private callingProfileParamsFactory: CallingProfileParamsFactory;
  private automationFactory: AutomationFactory;
  private automationParamsFactory: AutomationParamsFactory;
  private emergencyLocationFactory: EmergencyLocationFactory;
  private emergencyLocationQueryParamsFactory: EmergencyLocationQueryParamsFactory;

  constructor(
    private apiService: ApiService,
    private store: Store<StoreState>,
    private provisioningService: ProvisioningService,
    private userListService: ListService<MicrosoftTeamsUser, MicrosoftTeamsUserProfileFactory,
      TeamsUserQueryParams, TeamsUserSearchParamsFactory>,
    private callingProfileListService: ListService<CallingProfile, CallingProfileFactory,
      CallingProfileQueryParams, CallingProfileParamsFactory>,
    private automationListService: ListService<Automation, AutomationFactory, AutomationQueryParams, AutomationParamsFactory>,
    private emergencyLocationListService: ListService<EmergencyLocation, EmergencyLocationFactory, EmergencyLocationQueryParams, EmergencyLocationQueryParamsFactory>,
  ) {
    this.userFactory                         = new MicrosoftTeamsUserProfileFactory();
    this.userParamFactory                    = new TeamsUserSearchParamsFactory();
    this.callingProfileFactory               = new CallingProfileFactory();
    this.callingProfileParamsFactory         = new CallingProfileParamsFactory();
    this.automationFactory                   = new AutomationFactory();
    this.automationParamsFactory             = new AutomationParamsFactory();
    this.emergencyLocationFactory            = new EmergencyLocationFactory();
    this.emergencyLocationQueryParamsFactory = new EmergencyLocationQueryParamsFactory();
  }

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  private buildUserSyncUri(serviceId: string, version: UserSyncVersion): string {
    return this.buildUri(`services/${ serviceId }/synchronise-users${ version === 'v1' ? '' : '-v2' }`);
  }

  private buildServiceCountUri(): string {
    return this.buildUri(`services/count`);
  }

  private buildServiceListUri(queryParams?: ServiceQueryParams): string {
    return this.buildUri(`services${ ServiceQueryParams.constructQueryString(queryParams) }`);
  }

  private buildPersonaListUri(serviceId: string, queryParams: CallingProfileQueryParams): string {
    return this.buildUri(`services/${ serviceId }/profiles${ CallingProfileQueryParams.constructQueryString(queryParams) }`);
  }

  private buildAutomationUri(serviceId: string, id?: string): string {
    return this.buildUri(`services/${ serviceId }/rules${ id ? `/${ id }` : '' }`);
  }

  private buildSyncPoliciesUri(serviceId: string): string {
    return this.buildUri(`services/${ serviceId }/synchronise-policies`);
  }

  private buildSyncDomainsUri(serviceId: string): string {
    return this.buildUri(`services/${ serviceId }/synchronise-domains`);
  }

  private buildSyncTeamsUri(serviceId: string): string {
    return this.buildUri(`services/${ serviceId }/synchronise-teams`);
  }

  private buildSyncAdGroupsUri(serviceId: string): string {
    return this.buildUri(`services/${ serviceId }/synchronise-ad-groups`);
  }

  private buildSyncMicrosoftAssetsUri(serviceId: string): string {
    return this.buildUri(`services/${ serviceId }/synchronise-microsoft-assets`);
  }

  private buildSyncCallQueuesUri(serviceId: string): string {
    return this.buildUri(`services/${ serviceId }/synchronise-call-queues`);
  }

  private buildSyncLicensesUri(serviceId: string): string {
    return this.buildUri(`services/${ serviceId }/synchronise-licenses`);
  }

  private buildAutomationListUri(serviceId: string, queryParams: AutomationQueryParams): string {
    return this.buildUri(`services/${ serviceId }/rules${ AutomationQueryParams.constructQueryString(queryParams) }`);
  }

  private buildEmergencyLocationListUri(serviceId: string, queryParams: EmergencyLocationQueryParams): string {
    return this.buildUri(`services/${ serviceId }/emergency-locations${ EmergencyLocationQueryParams.constructQueryString(queryParams) }`);
  }

  private buildEmergencyLocationUri(serviceId: string, id: string): string {
    return this.buildUri(`services/${ serviceId }/emergency-locations/${ id }`);
  }

  private buildServiceSchemaUri(serviceId: string): string {
    return this.buildUri(`services/${ serviceId }/schema`);
  }

  private buildCustomCarrierUri(): string {
    return this.buildUri(`services/carriers/custom`);
  }

  private buildServiceUserListUri(serviceId: string, queryParams: TeamsUserQueryParams): string {
    return this.buildUri(`services/${ serviceId }/users${ TeamsUserQueryParams.constructQueryString(queryParams) }`);
  }

  private buildServiceUserCountsUri(serviceId: string, isResourceAccount: boolean): string {
    return this.buildUri(`services/${ serviceId }/users/count-by-status${ isResourceAccount != null ? '?filter[is_resource_account]=' + isResourceAccount : '' }`);
  }

  private buildServiceUserLicenseGroupsUri(serviceId: string, userId: string): string {
    return this.buildUri(`services/${ serviceId }/users/${ userId }/license-groups`);
  }

  private buildServiceUserTeamGroupsUri(serviceId: string, userId: string): string {
    return this.buildUri(`services/${ serviceId }/users/${ userId }/team-groups`);
  }

  private buildServiceUserCallQueueGroupsUri(serviceId: string, userId: string): string {
    return this.buildUri(`services/${ serviceId }/users/${ userId }/call-queue-groups`);
  }


  private buildUserConfigureBulkUri(serviceId: string): string {
    return this.buildUri(`services/${ serviceId }/users-configure/bulk`);
  }

  private buildPersonaAvailabilityUri(serviceId: string, name: string): string {
    return this.buildUri(`services/${ serviceId }/profiles/availability?name=${ name }`);
  }

  private buildAutomationAvailabilityUri(serviceId: string, name: string): string {
    return this.buildUri(`services/${ serviceId }/rules/availability/${ name }`);
  }

  private buildPersonaMigrateUri(serviceId: string, fromProfileId: string): string {
    return this.buildUri(`services/${ serviceId }/profiles/${ fromProfileId }/migrations`);
  }

  private buildPersonaUri(serviceId: string, id?: string): string {
    return this.buildUri(`services/${ serviceId }/profiles${ id ? `/${ id }` : '' }`);
  }

  private buildDeletePersonaUri(serviceId: string, id: string, migrateTo: string): string {
    return this.buildUri(`services/${ serviceId }/profiles/${ id }${ migrateTo ? ('?to_profile_id=' + migrateTo) : '' }`);
  }

  private buildGatewayEventsUri(serviceId: string, queryParams: GatewayStatusQueryParams): string {
    return this.buildUri(`services/${ serviceId }/gateway-events${ GatewayStatusQueryParams.constructQueryString(queryParams) }`);
  }

  private buildProvisioningListUri(): string {
    return this.buildUri('services/provisioning-list');
  }

  private buildServiceUri(serviceId: string): string {
    return this.buildUri(`services/${ serviceId }`);
  }

  private buildServiceUserUri(serviceId: string, id: string): string {
    return this.buildUri(`services/${ serviceId }/users/${ id }?include[]=license_group_ids&include[]=team_group_ids&include[]=call_queue_group_ids`);
  }

  private buildExportServiceUsersUri(serviceId: string): string {
    return this.buildUri(`services/${ serviceId }/user-export`);
  }

  private buildDeleteServiceUri(id: string, force: boolean): string {
    return this.buildUri(`services/${ id }${ force ? '?forceDelete=true' : '' }`);
  }

  private buildSyncNumbersUri(serviceId: string): string {
    return this.buildUri(`/services/${ serviceId }/synchronise-numbers`);
  }

  private static transformService(s: ServiceItemRaw): ServiceItem {
    switch (s.type) {
      case ServiceType.MicrosoftTeams:
        return new MicrosoftTeams().fromApiData(s as MicrosoftTeamsRaw);
      case ServiceType.WebexCalling:
        return new WebexCalling().fromApiData(s as WebexCallingRaw);
      case ServiceType.SIPTrunk:
        return new SIPTrunk().fromApiData(s as SIPTrunkRaw);
      case ServiceType.SIPPhone:
        return new SIPPhone().fromApiData(s as SIPPhoneRaw);
      case ServiceType.Carrier:
        return new ServiceCarrier().fromApiData(s as ServiceCarrierRaw);
      case ServiceType.CallForwarding:
        return new CallForwarding().fromApiData(s as CallForwardingRaw);
      default:
        return new ServiceItem().fromApiData(s);
    }
  }

  fetchServiceCounts$(): Observable<FetchServiceCountsResponse> {
    return this.apiService.apiGet$<ServiceCountsRaw>(
      this.buildServiceCountUri(),
      { authRequired: true })
      .pipe(
        map((res: ServiceCountsRaw): FetchServiceCountsResponse => {
          return {
            error:  null,
            counts: res.data,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchServiceCountsResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            counts: null,
          });
        }),
      );
  }

  fetchServiceList$(req: FetchServiceListRequest): Observable<FetchServiceListResponse> {
    return this.apiService.apiGet$<{ data: ServiceItemRaw[], meta: ListResponseMetadata }>(
      this.buildServiceListUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: ServiceItemRaw[], meta: ListResponseMetadata }): FetchServiceListResponse => {
          return {
            searchParams: new ServiceQueryParams().constructParams(res.meta),
            serviceItems: res.data.map(d => ServiceService.transformService(d)),
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchServiceListResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            searchParams: null,
            serviceItems: null,
          });
        }),
      );
  }

  fetchPolicyList$(req: FetchPolicyListRequest): Observable<FetchPolicyListResponse> {
    return this.apiService.apiGet$<{ data: PolicyGroup[] }>(
      this.buildUri(`services/${ req.serviceId }/policies`),
      { authRequired: true })
      .pipe(
        map((res: { data: PolicyGroup[] }): FetchPolicyListResponse => {
          return {
            policies: new PolicyList().fromApiData(res.data),
            error:    null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchPolicyListResponse> => {
          return of({
            error:    err.status !== 404 && new Alert().fromApiError(err),
            policies: null,
          });
        }),
      );
  }

  syncPolicies$(req: SyncPoliciesRequest): Observable<SyncPoliciesResponse> {
    return this.apiService.apiPost$(
      this.buildSyncPoliciesUri(req.serviceId),
      {},
      { authRequired: true })
      .pipe(
        map((): SyncPoliciesResponse => {
          return {
            error:     null,
            serviceId: req.serviceId,
            message:   new Alert().fromApiMessage({ message: 'We are currently syncing your policies, please check again later.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SyncPoliciesResponse> => {
          return of({
            serviceId: req.serviceId,
            error:     new Alert().fromApiError(err),
          });
        }),
      );
  }

  syncDomains$(req: SyncDomainsRequest): Observable<SyncDomainsResponse> {
    return this.apiService.apiPost$(
      this.buildSyncDomainsUri(req.serviceId),
      {},
      { authRequired: true })
      .pipe(
        map((): SyncDomainsResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: 'We are currently syncing your domains, please check again later.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SyncDomainsResponse> => {
          return of({
            serviceId: req.serviceId,
            error:     new Alert().fromApiError(err),
          });
        }),
      );
  }

  syncTeams$(req: SyncTeamsRequest): Observable<SyncTeamsResponse> {
    return this.apiService.apiPost$(
      this.buildSyncTeamsUri(req.serviceId),
      {},
      { authRequired: true })
      .pipe(
        map((): SyncTeamsResponse => {
          return {
            error:     null,
            serviceId: req.serviceId,
            message:   new Alert().fromApiMessage({ message: 'We are currently syncing your teams, please check again later.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SyncTeamsResponse> => {
          return of({
            serviceId: req.serviceId,
            error:     new Alert().fromApiError(err),
          });
        }),
      );
  }

  syncADGroups$(req: SyncAdGroupsRequest): Observable<SyncAdGroupsResponse> {
    return this.apiService.apiPost$(
      this.buildSyncAdGroupsUri(req.serviceId),
      {},
      { authRequired: true })
      .pipe(
        map((): SyncAdGroupsResponse => {
          return {
            error:     null,
            serviceId: req.serviceId,
            message:   new Alert().fromApiMessage({ message: 'We are currently syncing your Entra ID groups, please check again later.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SyncAdGroupsResponse> => {
          return of({
            serviceId: req.serviceId,
            error:     new Alert().fromApiError(err),
          });
        }),
      );
  }

  syncAllMicrosoftAssets$(req: SyncAllMicrosoftAssetsRequest): Observable<SyncAllMicrosoftAssetsResponse> {
    return this.apiService.apiPost$(
      this.buildSyncMicrosoftAssetsUri(req.serviceId),
      {},
      { authRequired: true })
      .pipe(
        map((): SyncAllMicrosoftAssetsResponse => {
          return {
            error:     null,
            serviceId: req.serviceId,
            message:   new Alert().fromApiMessage({ message: 'We are currently syncing your Microsoft assets, please check again later.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SyncAllMicrosoftAssetsResponse> => {
          return of({
            serviceId: req.serviceId,
            error:     new Alert().fromApiError(err),
          });
        }),
      );
  }

  syncCallQueues$(req: SyncCallQueuesRequest): Observable<SyncCallQueuesResponse> {
    return this.apiService.apiPost$(
      this.buildSyncCallQueuesUri(req.serviceId),
      {},
      { authRequired: true })
      .pipe(
        map((): SyncCallQueuesResponse => {
          return {
            error:     null,
            serviceId: req.serviceId,
            message:   new Alert().fromApiMessage({ message: 'We are currently syncing your call queues, please check again later.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SyncCallQueuesResponse> => {
          return of({
            serviceId: req.serviceId,
            error:     new Alert().fromApiError(err),
          });
        }),
      );
  }

  syncLicenses$(req: SyncLicensesRequest): Observable<SyncLicensesResponse> {
    return this.apiService.apiPost$(
      this.buildSyncLicensesUri(req.serviceId),
      {},
      { authRequired: true })
      .pipe(
        map((): SyncLicensesResponse => {
          return {
            error:     null,
            serviceId: req.serviceId,
            message:   new Alert().fromApiMessage({ message: 'We are currently syncing your licenses, please check again later.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SyncLicensesResponse> => {
          return of({
            serviceId: req.serviceId,
            error:     new Alert().fromApiError(err),
          });
        }),
      );
  }

  syncNumbers$(req: SyncNumbersRequest): Observable<SyncNumbersResponse> {
    return this.apiService.apiPost$<{ data: { task_id: string } }>(
      this.buildSyncNumbersUri(req.serviceId),
      {},
      { authRequired: true })
      .pipe(
        map((res: { data: { task_id: string } }): SyncNumbersResponse => {
          return {
            error:     null,
            serviceId: req.serviceId,
            taskId:    res.data?.task_id,
            message:   new Alert().fromApiMessage({ message: 'We are currently syncing your numbers, please check again later.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SyncNumbersResponse> => {
          return of({
            serviceId: req.serviceId,
            error:     new Alert().fromApiError(err),
          });
        }),
      );
  }

  fetchCallingProfile$(req: FetchCallingProfileRequest): Observable<FetchCallingProfileResponse> {
    return this.apiService.apiGet$<{ data: CallingProfileRaw }>(
      this.buildPersonaUri(req.serviceId, req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: CallingProfileRaw }): FetchCallingProfileResponse => {
          return {
            data:    new CallingProfile().fromApiData(res.data),
            storeAs: req.storeAs,
            error:   null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallingProfileResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchCallingProfiles$(req: FetchCallingProfilesRequest): Observable<FetchCallingProfilesResponse> {
    return this.callingProfileListService.fetchListModel$(
      this.buildPersonaListUri(req.serviceId, req.queryParams),
      this.callingProfileFactory,
      this.callingProfileParamsFactory)
      .pipe(
        map((res: ListModelResponse<CallingProfile, CallingProfileQueryParams>): FetchCallingProfilesResponse => {
          return {
            data:         res.models,
            searchParams: res.searchParams,
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallingProfilesResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchAutomation$(req: FetchAutomationRequest): Observable<FetchAutomationResponse> {
    return this.apiService.apiGet$<{ data: AutomationRaw }>(
      this.buildAutomationUri(req.serviceId, req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: AutomationRaw }): FetchAutomationResponse => {
          return {
            data:  new Automation().fromApiData(res.data),
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAutomationResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  deleteAutomation$(req: DeleteAutomationRequest): Observable<DeleteAutomationResponse> {
    return this.apiService.apiDelete$(
      this.buildAutomationUri(req.serviceId, req.id),
      { authRequired: true })
      .pipe(
        map((): DeleteAutomationResponse => {
          return {
            id:           req.id,
            error:        null,
            isLastOnPage: req.isLastOnPage,
            serviceId:    req.serviceId,
            message:      new Alert().fromApiMessage({ message: `Successfully deleted ${ req.name }!` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteAutomationResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            id:           req.id,
            isLastOnPage: req.isLastOnPage,
            serviceId:    req.serviceId,
          });
        }),
      );
  }

  fetchAutomationList$(req: FetchAutomationListRequest): Observable<FetchAutomationListResponse> {
    return this.automationListService.fetchListModel$(
      this.buildAutomationListUri(req.serviceId, req.queryParams),
      this.automationFactory,
      this.automationParamsFactory)
      .pipe(
        map((res: ListModelResponse<Automation, AutomationQueryParams>): FetchAutomationListResponse => {
          return {
            data:         res.models,
            searchParams: res.searchParams,
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAutomationListResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchServiceSchema$(req: FetchServiceSchemaRequest): Observable<FetchServiceSchemaResponse> {
    return this.apiService.apiGet$<{ data: ServiceSchema }>(
      this.buildServiceSchemaUri(req.serviceId),
      { authRequired: true },
    )
      .pipe(
        map((res: { data: ServiceSchema }): FetchServiceSchemaResponse => {
          return {
            data:  res.data,
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchServiceSchemaResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }));
  }

  migrateProfiles$(req: MigrateProfilesRequest): Observable<MigrateProfilesResponse> {
    return this.apiService.apiPost$(
      this.buildPersonaMigrateUri(req.serviceId, req.fromProfileId),
      { to_profile_id: req.toProfileId },
      { authRequired: true },
    )
      .pipe(
        map(() => {
          return {
            error:     null,
            message:   new Alert().fromApiMessage({ message: 'Your migration request is being processed..' }),
            serviceId: req.serviceId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<MigrateProfilesResponse> => {
          return of({
            serviceId: req.serviceId,
            error:     new Alert().fromApiError(err),
          });
        }));
  }

  putCallingProfile$(req: PutCallingProfileRequest): Observable<PutCallingProfileResponse> {
    const isPut = req.id && req.withUpdate;
    return (isPut ?
      this.apiService.apiPut$<{ data: CallingProfileRaw }>(
        this.buildPersonaUri(req.serviceId, req.id),
        new PutCallingProfileRequest(req).toApiData(),
        { authRequired: true }) :
      this.apiService.apiPost$<{ data: CallingProfileRaw }>(
        this.buildPersonaUri(req.serviceId),
        new PutCallingProfileRequest(req).toApiData(),
        { authRequired: true }))
      .pipe(
        map((res: { data: CallingProfileRaw }): PutCallingProfileResponse => {
          return {
            data:    new CallingProfile().fromApiData(res.data),
            message: new Alert().fromApiMessage({ message: `Successfully ${ req.id ? 'saved' : 'created' } ${ res.data.name }!` }),
            isPut,
            error:   null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PutCallingProfileResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            isPut,
            data:  null,
          });
        }),
      );
  }

  deleteCallingProfile$(req: DeleteCallingProfileRequest, migrateTo: string): Observable<DeleteCallingProfileResponse> {
    return this.apiService.apiDelete$(
      this.buildDeletePersonaUri(req.serviceId, req.id, migrateTo),
      { authRequired: true })
      .pipe(
        map((): DeleteCallingProfileResponse => {
          return {
            id:           req.id,
            isLastOnPage: req.isLastOnPage,
            error:        null,
            message:      new Alert().fromApiMessage({ message: `Successfully deleted ${ req.name }!` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteCallingProfileResponse> => {
          return of({
            isLastOnPage: null,
            error:        new Alert().fromApiError(err),
            id:           req.id,
          });
        }),
      );
  }

  postAutomation$(req: PostAutomationRequest): Observable<PostAutomationResponse> {
    return this.apiService.apiPost$<{ data: AutomationRaw }>(
      this.buildAutomationUri(req.serviceId),
      { ...new PostAutomationRequest(req).toApiData() },
      { authRequired: true })
      .pipe(
        map((res: { data: AutomationRaw }): PostAutomationResponse => {
          return {
            data:    new Automation().fromApiData({ ...res.data, requestId: req.requestId }),
            message: new Alert().fromApiMessage({ message: `Successfully created ${ res.data.name }!` }),
            error:   null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostAutomationResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  patchAutomation$(req: PatchAutomationRequest): Observable<PatchAutomationResponse> {
    return this.apiService.apiPatch$<{ data: AutomationRaw }>(
      this.buildAutomationUri(req.serviceId, req.id),
      { ...new PatchAutomationRequest(req).toApiData() },
      { authRequired: true })
      .pipe(
        map((res: { data: AutomationRaw }): PatchAutomationResponse => {
          return {
            data:            new Automation().fromApiData({ ...res.data, requestId: req.requestId }),
            message:         req.name && new Alert().fromApiMessage({ message: `Successfully updated ${ res.data.name }!` }),
            error:           null,
            shouldFetchList: !req.name && req.priority !== undefined,
            serviceId:       req.serviceId,
            updatedList:     req.updatedList,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchAutomationResponse> => {
          return of({
            error:           new Alert().fromApiError(err),
            data:            null,
            shouldFetchList: false,
            serviceId:       req.serviceId,
          });
        }),
      );
  }

  fetchGatewayStatusEvents$(req: FetchGatewayStatusEventsRequest): Observable<FetchGatewayStatusEventsResponse> {
    return this.apiService.apiGet$<{ data: GatewayStatusEvent[], meta: ListResponseMetadata }>(
      this.buildGatewayEventsUri(req.serviceId, req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: GatewayStatusEvent[], meta: ListResponseMetadata }): FetchGatewayStatusEventsResponse => {
          return {
            searchParams: new GatewayStatusQueryParams().constructParams(res.meta),
            events:       res.data,
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchGatewayStatusEventsResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            searchParams: null,
            events:       null,
          });
        }),
      );
  }

  fetchProvisioningServices$(): Observable<FetchServiceListResponse> {
    return this.apiService.apiGet$<{
      data: (WebexCallingRaw | SIPTrunkRaw | ServiceItemRaw)[],
      meta: ListResponseMetadata
    }>(
      this.buildProvisioningListUri(),
      { authRequired: true })
      .pipe(
        map((res: {
          data: (WebexCallingRaw | SIPTrunkRaw | ServiceItemRaw)[],
          meta: ListResponseMetadata
        }): FetchServiceListResponse => {
          return {
            searchParams: new ServiceQueryParams().constructParams(res.meta),
            serviceItems: res.data.map(d => ServiceService.transformService(d)),
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchServiceListResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            searchParams: null,
            serviceItems: null,
          });
        }),
      );
  }

  fetchService$(req: FetchServiceRequest): Observable<FetchServiceResponse> {
    return this.apiService.apiGet$<{ data: (WebexCallingRaw | SIPTrunkRaw | SIPPhoneRaw | ServiceItemRaw) }>(
      this.buildServiceUri(req.serviceId),
      { authRequired: true })
      .pipe(
        map((res: { data: (WebexCallingRaw | SIPTrunkRaw | SIPPhoneRaw | ServiceItemRaw) }): FetchServiceResponse => {
          return {
            error:       null,
            serviceItem: ServiceService.transformService(res.data),
          } as FetchServiceResponse;
        }),
        catchError((err: HttpErrorResponse): Observable<FetchServiceResponse> => {
          return of({
            error:       new Alert().fromApiError(err, null, true, req.serviceId),
            serviceItem: null,
          });
        }),
      );
  }

  fetchCallingProfileById$(profileId: string, serviceId: string): Observable<CallingProfile> {
    return this.apiService.apiGet$<{ data: CallingProfileRaw }>(
      this.buildPersonaUri(serviceId, profileId),
      { authRequired: true })
      .pipe(
        map((res: { data: CallingProfileRaw }): CallingProfile => {
          return new CallingProfile().fromApiData(res.data);
        }),
        catchError((): Observable<CallingProfile> => {
          return of(null);
        }),
      );
  }

  deleteService$(req: DeleteServiceRequest): Observable<DeleteServiceResponse> {
    return this.apiService.apiDelete$(
      this.buildDeleteServiceUri(req.id, req.force),
      { authRequired: true })
      .pipe(
        map((): DeleteServiceResponse => {
          return {
            error:       null,
            id:          req.id,
            serviceType: req.serviceType,
            cancelled:   false,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteServiceResponse> => {
          return of({
            error:       new Alert().withContext('Error deleting the service')
                           .fromApiError(err),
            id:          req.id,
            serviceType: req.serviceType,
            cancelled:   false,
          });
        }),
      );
  }

  configureService$(req: ConfigureServiceRequest): Observable<ConfigureServiceResponse> {
    return this.provisioningService.provisionService$(req);
  }

  fetchServiceUserCounts$(req: FetchServiceUserCountsByVoiceTypeRequest): Observable<FetchServiceUserCountsByVoiceTypeResponse> {
    return this.apiService.apiGet$<{
      data: ServiceUserStatusCounts
    }>(this.buildServiceUserCountsUri(req.serviceId, req.isResourceAccount), { authRequired: true })
      .pipe(
        map((res: { data: ServiceUserStatusCounts }): FetchServiceUserCountsByVoiceTypeResponse => {
          const {
                  CALLING_PLAN_ENABLED        = 0,
                  LICENSED_NON_PHONE          = 0,
                  DIRECT_ROUTING_ENABLED      = 0,
                  OPERATOR_CONNECT_ENABLED    = 0,
                  PHONE_DISABLED              = 0,
                  PHONE_DISABLED_CALLING_PLAN = 0,
                  PSTN_ENABLED                = 0,
                  UNLICENSED                  = 0,
                } = res.data.statuses || {};

          const statusCounts: ServiceUserStatusCounts = {
            statuses: {
              CALLING_PLAN_ENABLED,
              LICENSED_NON_PHONE,
              DIRECT_ROUTING_ENABLED,
              OPERATOR_CONNECT_ENABLED,
              PHONE_DISABLED,
              PHONE_DISABLED_CALLING_PLAN,
              PSTN_ENABLED,
              UNLICENSED,
            },
            managed:  res.data.managed || { is_managed: 0 },
          };

          statusCounts.statuses.TOTAL = Object.values(statusCounts.statuses)
            .reduce((a, b) => a + b) || 0;

          const promptUser = CALLING_PLAN_ENABLED +
            DIRECT_ROUTING_ENABLED +
            OPERATOR_CONNECT_ENABLED +
            PHONE_DISABLED +
            PHONE_DISABLED_CALLING_PLAN +
            LICENSED_NON_PHONE +
            PSTN_ENABLED +
            UNLICENSED === 0;

          return {
            serviceId: req.serviceId,
            data:      statusCounts,
            promptUser,
            error:     null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchServiceUserCountsByVoiceTypeResponse> => {
          return of({
            serviceId:  req.serviceId,
            data:       null,
            error:      new Alert().fromApiError(err),
            promptUser: false,
          });
        }),
      );
  }

  fetchServiceUserList$(req: FetchServiceUserListRequest): Observable<FetchServiceUserListResponse> {
    return this.userListService.fetchListModel$(
      this.buildServiceUserListUri(req.serviceId, req.queryParams),
      this.userFactory,
      this.userParamFactory,
    )
      .pipe(
        map((res: ListModelResponse<MicrosoftTeamsUser, TeamsUserQueryParams>): FetchServiceUserListResponse => {
            return {
              ...res,
              models:    res.models?.map(m => m.withServiceId(req.serviceId)) || [],
              serviceId: !res.error ? req.serviceId : null,
            };
          },
        ),
      );
  }

  fetchServiceUser$(req: FetchServiceUserRequest): Observable<FetchServiceUserResponse> {
    return this.apiService.apiGet$<{ data: MicrosoftTeamsUserRaw }>(
      this.buildServiceUserUri(req.serviceId, req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: MicrosoftTeamsUserRaw }): FetchServiceUserResponse => {
          return {
            error: null,
            data:  new MicrosoftTeamsUser().withServiceId(req.serviceId)
                     .fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchServiceUserResponse> => {
          return of({
            error: new Alert().fromApiError(err, null, true, req.serviceId),
            data:  null,
          });
        }),
      );
  }

  exportServiceUsers$(req: ExportServiceUsersRequest): Observable<ExportServiceUsersResponse> {
    return this.apiService.apiGet$<{ data: { task_id: string } }>(
      this.buildExportServiceUsersUri(req.serviceId),
      { authRequired: true })
      .pipe(
        map((res: { data: { task_id: string } }): ExportServiceUsersResponse => {
          return {
            error:  null,
            taskId: res.data.task_id,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<ExportServiceUsersResponse> => {
          return of({
            error:  new Alert().fromApiError(err, null, true),
            taskId: null,
          });
        }),
      );
  }

  sendCustomCarrierRequest$(req: SendCustomCarrierRequest): Observable<SendCustomCarrierResponse> {
    return this.apiService.apiPost$(
      this.buildCustomCarrierUri(),
      new SendCustomCarrierRequest(req).toApiData(),
      { authRequired: true })
      .pipe(
        map((): SendCustomCarrierResponse => {
          return {
            error:     null,
            message:   null,
            requestId: req.requestId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SendCustomCarrierResponse> => {
          return of({
            error:     new Alert().fromApiError(err),
            message:   null,
            requestId: req.requestId,
          });
        }),
      );
  }

  syncServiceUserList$(req: SyncServiceUserListRequest): Observable<SyncServiceUserListResponse> {
    return this.apiService.apiPost$<{ data: { task_ids: string[] } }>(
      this.buildUserSyncUri(req.serviceId, req.version),
      new SyncServiceUserListRequest(req).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: { task_ids: string[] } }): SyncServiceUserListResponse => {
          return {
            error:      null,
            serviceId:  req.serviceId,
            identities: req.identities,
            taskId:     res.data?.task_ids?.[0],
            message:    new Alert().fromApiMessage({ message: `We are currently syncing ${ req.identities?.length === 1 ? 'this user' : 'your users' }, please check again later.` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SyncServiceUserListResponse> => {
          return of({
            error:      new Alert()
                          .withCustomErrorMessages({
                            409: 'A sync is already in progress.',
                          })
                          .fromApiError(err),
            identities: req.identities,
            taskId:     null,
            serviceId:  null,
            message:    null,
          });
        }),
      );
  }

  assignServiceUserNumber$(req: AssignServiceUserNumberRequest): Observable<AssignServiceUserNumberResponse> {
    return this.apiService.apiPost$<AssignServiceUserNumberResponseRaw>(
      this.buildUserConfigureBulkUri(req.serviceId),
      new AssignServiceUserNumberRequest(req).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: AssignServiceUserNumberResponseRaw): AssignServiceUserNumberResponse => {
          const patchedUserArray = res.data?.map(u =>
            new MicrosoftTeamsUser().withServiceId(req.serviceId)
              .fromApiData(u),
          ) || [];
          return {
            error:     null,
            message:   new Alert().fromApiMessage({ message: 'Your request is being processed' }),
            userId:    req.userId,
            serviceId: req.serviceId,
            userArray: patchedUserArray ? patchedUserArray : [],
          };
        }),
        catchError((err: HttpErrorResponse): Observable<AssignServiceUserNumberResponse> => {
          return of({
            error:     new UserConfigureError().fromApiError(err),
            message:   null,
            userId:    req.userId,
            serviceId: null,
            userArray: null,
          });
        }),
      );
  }

  fetchPersonaNameAvailable$(req: FetchPersonaNameAvailabilityRequest): Observable<FetchPersonaNameAvailabilityResponse> {
    if (!req.name || req.name.length < 2) {
      return of({
        error:        null,
        availability: null,
      });
    }
    return this.apiService.apiGet$<{ data: AvailabilityCheckRaw }>(
      this.buildPersonaAvailabilityUri(req.serviceId, req.name),
      { authRequired: false })
      .pipe(
        map((res: { data: AvailabilityCheckRaw }): FetchPersonaNameAvailabilityResponse => {
          return {
            error:        null,
            availability: res?.data?.is_available ? Availability.Available : Availability.Unavailable,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchPersonaNameAvailabilityResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            availability: null,
          });
        }),
      );
  }

  fetchAutomationNameAvailable$(req: FetchAutomationNameAvailabilityRequest): Observable<FetchAutomationNameAvailabilityResponse> {
    if (!req.name || req.name.length < 2) {
      return of({
        error:        null,
        availability: null,
      });
    }
    return this.apiService.apiGet$<AvailabilityCheckRaw>(
      this.buildAutomationAvailabilityUri(req.serviceId, req.name),
      { authRequired: false })
      .pipe(
        map((res: AvailabilityCheckRaw): FetchAutomationNameAvailabilityResponse => {
          return {
            error:        null,
            availability: res?.is_available ? Availability.Available : Availability.Unavailable,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAutomationNameAvailabilityResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            availability: null,
          });
        }),
      );
  }

  fetchEmergencyLocation$(req: FetchEmergencyLocationRequest): Observable<FetchEmergencyLocationResponse> {
    return this.apiService.apiGet$<{ data: EmergencyLocationRaw }>(
      this.buildEmergencyLocationUri(req.serviceId, req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: EmergencyLocationRaw }): FetchEmergencyLocationResponse => {
          return {
            data:  new EmergencyLocation().fromApiData(res.data),
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchEmergencyLocationResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchEmergencyLocations$(req: FetchEmergencyLocationListRequest): Observable<FetchEmergencyLocationListResponse> {
    return this.emergencyLocationListService.fetchListModel$(
      this.buildEmergencyLocationListUri(req.serviceId, req.queryParams),
      this.emergencyLocationFactory,
      this.emergencyLocationQueryParamsFactory)
      .pipe(
        map((res: ListModelResponse<EmergencyLocation, EmergencyLocationQueryParams>): FetchEmergencyLocationListResponse => {
          return {
            data:        res.models,
            queryParams: res.searchParams,
            error:       null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchEmergencyLocationListResponse> => {
          return of({
            error:       new Alert().fromApiError(err),
            data:        null,
            queryParams: null,
          });
        }),
      );
  }

  waitUntilAuthed(serviceId: string, waitFor?: MicrosoftToken): Promise<boolean> {
    return this.store.select(selectTokens)
      .pipe(
        map(tokens => {
          if (waitFor) {
            const token = tokens[serviceId]?.find(t => t.id === waitFor);
            if (token?.status === TokenStatus.Active) {
              return true;
            } else if (token?.status === TokenStatus.Error) {
              return false;
            }
            return null;
          }
          if (tokens[serviceId]?.every(token => token.status === TokenStatus.Active)) {
            return true;
          } else if (tokens[serviceId]?.some(token => token.status === TokenStatus.Error)) {
            return false;
          }
          return null;
        }),
        filter((authed: boolean) => authed !== null),
        take(1))
      .toPromise();
  }

  waitForProcessing(serviceId: string, waitFor?: MicrosoftToken): Promise<boolean> {
    return this.store.select(selectTokens)
      .pipe(
        map(tokens => {
          if (!waitFor) {
            return tokens[serviceId]?.every(token => token.status === TokenStatus.Processing);
          }
          return tokens[serviceId]?.find(t => t.id === waitFor)?.status === TokenStatus.Processing;
        }),
        filter((processing: boolean) => !!processing),
        take(1))
      .toPromise();
  }

  waitForFetched(serviceId: string, waitFor?: MicrosoftToken): Promise<boolean> {
    return this.store.select(selectTokens)
      .pipe(
        map(tokens => {
          if (!waitFor) {
            return tokens[serviceId]?.every(token => [TokenStatus.Active, TokenStatus.Inactive, TokenStatus.Error].includes(token.status));
          }
          return [TokenStatus.Active, TokenStatus.Inactive, TokenStatus.Error].includes(tokens[serviceId]?.find(t => t.id === waitFor)?.status);
        }),
        filter((fetched: boolean) => !!fetched),
        take(1))
      .toPromise();
  }

  mergeEmergencyLocations$<T extends {
    emergencyLocationId: string,
    emergencyLocation: EmergencyLocation
  }>(serviceId: string, items: T[]): Observable<T[]> {
    if (!items?.length) {
      return of(items);
    }
    const emergencyLocationIds = Array.from(new Set(items.map(item => item.emergencyLocationId)
      .filter(id => !!id)));
    if (!emergencyLocationIds.length) {
      return of(items);
    }
    const emergencyLocations$ = this.fetchEmergencyLocations$({
      serviceId,
      queryParams: { id: emergencyLocationIds, pageNumber: 1, pageSize: emergencyLocationIds.length },
    });
    return emergencyLocations$.pipe(map(emergencyLocations => {
      return items.map(item => {
        item.emergencyLocation   = emergencyLocations.data?.find(d => d.identifier === item.emergencyLocationId);
        item.emergencyLocationId = item.emergencyLocation?.identifier;
        return item;
      });
    }));
  }

  assignLicenseGroup$(req: AssignLicenseGroupRequest): Observable<AssignLicenseGroupResponse> {
    return this.apiService.apiPut$<{ data: AssignLicenseGroupRaw }>(
      this.buildServiceUserLicenseGroupsUri(req.serviceId, req.userId),
      new AssignLicenseGroupRequest(req).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: AssignLicenseGroupRaw }): AssignLicenseGroupResponse => {
          const licenseGroupIds = req.licenseGroupIds.concat(res.data.assignments)
            .filter(id => !res.data.unassignments.includes(id));
          return {
            licenseGroupIds,
            userId:        req.userId,
            message:       new Alert().fromApiMessage({ message: `We are processing your request. Check task manager for more details.` }),
            requestId:     req.requestId,
            usageLocation: req.usageLocation,
            error:         null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<AssignLicenseGroupResponse> => {
          return of({
            licenseGroupIds: null,
            error:           new Alert().fromApiError(err),
            data:            null,
            userId:          req.userId,
            requestId:       req.requestId,
            usageLocation:   null,
          });
        }),
      );
  }

  assignTeamGroup$(req: AssignTeamGroupRequest): Observable<AssignTeamGroupResponse> {
    return this.apiService.apiPut$<{ data: AssignTeamGroupRequest }>(
      this.buildServiceUserTeamGroupsUri(req.serviceId, req.userId),
      new AssignTeamGroupRequest(req).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: AssignTeamGroupRaw }): AssignTeamGroupResponse => {

          const teamGroupIds = [...new Set(req.teamGroupIds.concat(
            !res.data.assignments ?
              [] :
              Object.keys(res.data.assignments))
            .filter(id => !res.data.unassignments.includes(id)))];

          const teamGroupRoles: { [teamGroupIds: string]: TeamGroupRole } = !res.data.assignments ?
            req.teamGroupRoles :
            { ...req.teamGroupRoles, ...res.data.assignments };

          for (const id of res.data.unassignments) {
            delete teamGroupRoles[id];
          }

          return {
            teamGroupIds,
            teamGroupRoles,
            userId:    req.userId,
            message:   new Alert().fromApiMessage({ message: `We are processing your request. Check task manager for more details.` }),
            requestId: req.requestId,
            error:     null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<AssignTeamGroupResponse> => {
          return of({
            teamGroupIds:   null,
            teamGroupRoles: null,
            error:          new Alert().fromApiError(err),
            data:           null,
            userId:         req.userId,
            requestId:      req.requestId,
          });
        }),
      );
  }

  assignCallQueueGroup$(req: AssignCallQueueGroupRequest): Observable<AssignCallQueueGroupResponse> {
    return this.apiService.apiPut$<{ data: AssignCallQueueGroupRaw }>(
      this.buildServiceUserCallQueueGroupsUri(req.serviceId, req.userId),
      new AssignCallQueueGroupRequest(req).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: AssignCallQueueGroupRaw }): AssignCallQueueGroupResponse => {
          const callQueueGroupIds = req.callQueueGroupIds.concat(res.data.assignments)
            .filter(id => !res.data.unassignments.includes(id));
          return {
            callQueueGroupIds,
            userId:    req.userId,
            message:   new Alert().fromApiMessage({ message: `We are processing your request. Check task manager for more details.` }),
            requestId: req.requestId,
            error:     null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<AssignCallQueueGroupResponse> => {
          return of({
            callQueueGroupIds: null,
            error:             new Alert().fromApiError(err),
            data:              null,
            userId:            req.userId,
            requestId:         req.requestId,
          });
        }),
      );
  }


}
