import { CountryCode, CountryCodes } from './country-code.model';
import { AddressRaw }                from '../api/address-raw.model';
import { AddressFormData }           from '../form/address-form.model';

export class Address {
  addressLine1: string;
  addressLine2: string;
  town: string;
  country: string;
  countryCode: keyof CountryCodes;
  postcode: string;

  static fromFormData(data: AddressFormData, billing = false): Address {
    const a: Address = new Address();

    a.addressLine1 = billing ? data.billingAddressLine1 : data.addressLine1;
    a.addressLine2 = billing ? data.billingAddressLine2 : data.addressLine2;
    a.town         = billing ? data.billingTown : data.town;
    a.country      = billing ? data.billingCountry : data.country;
    a.countryCode  = billing ? data.billingCode : data.countryCode;
    a.postcode     = billing ? data.billingPostcode : data.postcode;

    return a;
  }

  static fromApiData(apiData: AddressRaw): Address {
    const a: Address      = new Address();
    const cc: CountryCode = new CountryCode();

    a.addressLine1 = apiData.line_1;
    a.addressLine2 = apiData.line_2;
    a.town         = apiData.city;
    a.country      = cc.getNameFromISO3Code(apiData.country_code);
    a.countryCode  = apiData.country_code;
    a.postcode     = apiData.postcode;

    return a;
  }

  static toApiData(a: Address): AddressRaw {
    return {
      line_1:       a.addressLine1,
      line_2:       a.addressLine2,
      city:         a.town,
      country_code: a.countryCode,
      postcode:     a.postcode
    };
  }

}
