import { Component }                                             from '@angular/core';
import { AssetsService }                                         from '@services/assets.service';
import { Store }                                                 from '@ngrx/store';
import { StoreState }                                            from '@redux/store';
import { AuthoriseMicrosoftSSORequestAction, LoginWithRedirect } from '@redux/auth/auth.actions';
import { ButtonType }                                            from '@enums/button-type.enum';

@Component({
  selector:    'ngx-login-page',
  templateUrl: './login-page.component.html',
  styleUrls:   ['./login-page.component.scss'],
})
export class LoginPageComponent {
  microsoftLogo = AssetsService.getIcon('microsoft-logo.png');
  callrouteLogo = AssetsService.getFavicon('favicon-32x32.png');
  ButtonType    = ButtonType;

  constructor(private store: Store<StoreState>) { }

  requestCallrouteAuth(): void {
    this.store.dispatch(LoginWithRedirect());
  }

  requestMicrosoftAuth(): void {
    this.store.dispatch(AuthoriseMicrosoftSSORequestAction({}));
  }

}
