import { TrackByFunction } from '@angular/core';

export function trackBy<T>(prop?: keyof T): TrackByFunction<Pick<T, keyof T>> {
  return function(_: number, item: T): T[keyof T] | T {
    if (typeof item === 'string' || typeof item === 'number') {
      return item;
    }
    return item[prop || 'id' as keyof T];
  };
}
