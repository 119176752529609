import { NgModule }                    from '@angular/core';
import { AlertComponent }              from '@core/components/general/alert/alert.component';
import { MatIconModule }               from '@angular/material/icon';
import { MatListModule }               from '@angular/material/list';
import { BannerNotificationComponent } from '../notifications/banner-notification/banner-notification.component';
import { MatButtonModule }             from '@angular/material/button';
import { MatTooltipModule }            from '@angular/material/tooltip';
import { FieldNotificationComponent }  from '@core/components/general/field-notification/field-notification.component';
import { MatProgressSpinnerModule }    from '@angular/material/progress-spinner';
import { MatSnackBarModule }           from '@angular/material/snack-bar';
import { AlertSnackBarComponent }      from './alert-snack-bar/alert-snack-bar.component';
import { AsyncPipe, NgFor, NgIf }      from '@angular/common';
import { FlexLayoutModule }            from '@angular/flex-layout';


@NgModule({
  declarations: [
    AlertComponent,
    BannerNotificationComponent,
    FieldNotificationComponent,
    AlertSnackBarComponent,
  ],
  imports:      [
    NgIf,
    NgFor,
    FlexLayoutModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    AsyncPipe,
  ],
  exports:      [
    AlertComponent,
    BannerNotificationComponent,
    FieldNotificationComponent,
  ],
})
export class NotificationSharedModule {}
