import { createAction, props } from '@ngrx/store';

import {
  CLEAR_COMPANY_ERROR_AND_MESSAGE,
  DELETE_USER_REQUEST,
  DELETE_USER_RESPONSE,
  FETCH_COMPANY_NAME_AVAILABILITY_REQUEST,
  FETCH_COMPANY_NAME_AVAILABILITY_RESPONSE,
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_RESPONSE,
  FETCH_COMPANY_SUBORDINATE_LIST_REQUEST,
  FETCH_COMPANY_SUBORDINATE_LIST_RESPONSE,
  FETCH_COMPANY_USER_LIST_REQUEST,
  FETCH_COMPANY_USER_LIST_RESPONSE,
  FETCH_COMPANY_USERS_COUNT_REQUEST,
  FETCH_COMPANY_USERS_COUNT_RESPONSE,
  FETCH_ROLE_USERS_REQUEST,
  FETCH_ROLE_USERS_RESPONSE,
  FETCH_SECURITY_GROUP_USERS_LIST_REQUEST,
  FETCH_SECURITY_GROUP_USERS_LIST_RESPONSE,
  PATCH_COMPANY_REQUEST,
  PATCH_COMPANY_RESPONSE,
  POST_COMPANY_REQUEST,
  POST_COMPANY_RESPONSE,
  POST_COMPANY_USER_REQUEST,
  POST_COMPANY_USER_RESPONSE,
}                                               from './company.types';
import { FetchCompanyNameAvailabilityRequest }  from '@models/api/fetch-company-name-availability-request.model';
import { FetchCompanyNameAvailabilityResponse } from '@models/api/fetch-company-name-availability-response.model';
import { PostCompanyUserRequest }               from '@models/api/post-company-user.request';
import { PostCompanyUserResponse }              from '@models/api/post-company-user.response';
import { FetchCompanyUserListResponse }         from '@models/api/fetch-company-user-list.response';
import { FetchCompanyResponse }                 from '@models/api/fetch-company.response';
import { FetchCompanySubordinateListResponse }  from '@models/api/fetch-company-subordinate-list.response';
import { FetchCompanySubordinateListRequest }   from '@models/api/fetch-company-subordinate-list.request';
import { FetchCompanyUserListRequest }          from '@models/api/fetch-company-user-list.request';
import { FetchCompanyRequest }                  from '@models/api/fetch-company.request';
import { PostCompanyResponse }                  from '@models/api/post-company.response';
import { PostCompanyRequest }                   from '@models/api/post-company.request';
import { Update }                               from '@ngrx/entity';
import { Company }                              from '@models/entity/company.model';
import { FetchRoleUsersRequest }                from '@models/api/fetch-role-users-request.model';
import { FetchRoleUsersResponse }               from '@models/api/fetch-role-users-response.model';
import { DeleteUserRequest }                    from '@models/api/delete-user-request.model';
import { DeleteUserResponse }                   from '@models/api/delete-user-response.model';
import { PatchCompanyResponse }                 from '@models/api/patch-company-response.model';
import { PatchCompanyRequest }                  from '@models/api/patch-company-request.model';
import { FetchCompanyUserCountsResponse }       from '@models/api/fetch-company-user-counts-response.model';
import {
  FetchSecurityGroupUsersListRequest,
}                                               from '@models/api/identity/fetch-security-group-users-list-request.model';
import {
  FetchSecurityGroupUsersListResponse,
}                                               from '@models/api/identity/fetch-security-group-users-list-response.model';

export const PostCompanyRequestAction  = createAction(POST_COMPANY_REQUEST, props<PostCompanyRequest>());
export const PostCompanyResponseAction = createAction(POST_COMPANY_RESPONSE, props<PostCompanyResponse>());

export const PatchCompanyRequestAction  = createAction(PATCH_COMPANY_REQUEST, props<PatchCompanyRequest>());
export const PatchCompanyResponseAction = createAction(PATCH_COMPANY_RESPONSE, props<PatchCompanyResponse>());

export const FetchCompanyRequestAction  = createAction(FETCH_COMPANY_REQUEST, props<FetchCompanyRequest>());
export const FetchCompanyResponseAction = createAction(FETCH_COMPANY_RESPONSE, props<FetchCompanyResponse>());

export const FetchRoleUsersListRequestAction  = createAction(FETCH_ROLE_USERS_REQUEST, props<FetchRoleUsersRequest>());
export const FetchRoleUsersListResponseAction = createAction(FETCH_ROLE_USERS_RESPONSE, props<FetchRoleUsersResponse>());

export const FetchCompanyUserListRequestAction  = createAction(FETCH_COMPANY_USER_LIST_REQUEST, props<FetchCompanyUserListRequest>());
export const FetchCompanyUserListResponseAction = createAction(FETCH_COMPANY_USER_LIST_RESPONSE, props<FetchCompanyUserListResponse>());

export const PostCompanyUserRequestAction  = createAction(POST_COMPANY_USER_REQUEST, props<PostCompanyUserRequest>());
export const PostCompanyUserResponseAction = createAction(POST_COMPANY_USER_RESPONSE, props<PostCompanyUserResponse>());

export const ClearCompanyErrorAndMessageAction = createAction(CLEAR_COMPANY_ERROR_AND_MESSAGE);

export const FetchCompanySubordinateListRequestAction  = createAction(FETCH_COMPANY_SUBORDINATE_LIST_REQUEST, props<FetchCompanySubordinateListRequest>());
export const FetchCompanySubordinateListResponseAction = createAction(FETCH_COMPANY_SUBORDINATE_LIST_RESPONSE, props<FetchCompanySubordinateListResponse>());

export const FetchCompanyNameAvailabilityRequestAction  = createAction(FETCH_COMPANY_NAME_AVAILABILITY_REQUEST, props<FetchCompanyNameAvailabilityRequest>());
export const FetchCompanyNameAvailabilityResponseAction = createAction(FETCH_COMPANY_NAME_AVAILABILITY_RESPONSE, props<FetchCompanyNameAvailabilityResponse>());

export const FetchCompanyUsersCountRequestAction  = createAction(FETCH_COMPANY_USERS_COUNT_REQUEST);
export const FetchCompanyUsersCountResponseAction = createAction(FETCH_COMPANY_USERS_COUNT_RESPONSE, props<FetchCompanyUserCountsResponse>());

export const DeleteUserRequestAction  = createAction(DELETE_USER_REQUEST, props<DeleteUserRequest>());
export const DeleteUserResponseAction = createAction(DELETE_USER_RESPONSE, props<DeleteUserResponse>());

export const UpdateCompany       = createAction('[Company/API] Update One', props<{ update: Update<Company> }>());
export const AddCompany          = createAction('[Company/API] Add One', props<{ item: Company }>());
export const AddManyCompanies    = createAction('[Company/API] Add Many', props<{ items: Company[] }>());
export const SetAllCompanies     = createAction('[Company/API] Set All', props<{ updates: Company[] }>());
export const UpdateManyCompanies = createAction('[Company/API] Update Many', props<{ updates: Update<Company>[] }>());

export const FetchSecurityGroupUsersListRequestAction  = createAction(FETCH_SECURITY_GROUP_USERS_LIST_REQUEST, props<FetchSecurityGroupUsersListRequest>());
export const FetchSecurityGroupUsersListResponseAction = createAction(FETCH_SECURITY_GROUP_USERS_LIST_RESPONSE, props<FetchSecurityGroupUsersListResponse>());
