export const AUTHORISE_REDIRECT_REQUEST            = 'AUTHORISE_REDIRECT_REQUEST';
export const AUTHORISE_REDIRECT_RESPONSE           = 'AUTHORISE_REDIRECT_RESPONSE';
export const MS_OAUTH_CALLBACK                     = 'MS_OAUTH_CALLBACK';
export const FETCH_TOKEN_STATUS_REQUEST            = 'FETCH_TOKEN_STATUS_REQUEST';
export const FETCH_TOKEN_STATUS_RESPONSE           = 'FETCH_TOKEN_STATUS_RESPONSE';
export const FETCH_COMBINED_TOKENS_STATUS_REQUEST  = 'FETCH_COMBINED_TOKENS_STATUS_REQUEST';
export const FETCH_COMBINED_TOKENS_STATUS_RESPONSE = 'FETCH_COMBINED_TOKENS_STATUS_RESPONSE';
export const REFRESH_AUTH_STATUS                   = 'REFRESH_AUTH_STATUS';
export const SET_COOKIE                            = 'SET_COOKIE';
export const TOGGLE_AUTH_FAILED_MODAL              = 'TOGGLE_AUTH_FAILED_MODAL';

