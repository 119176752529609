import { Inject, Injectable }                                                                from '@angular/core';
import { Store }                                                                             from '@ngrx/store';
import { Actions, createEffect, ofType }                                                     from '@ngrx/effects';
import { concatMap, filter, map, pluck, switchMap, take, tap, throttleTime, withLatestFrom } from 'rxjs/operators';
import {
  AuthService,
}                                                                                            from '@services/auth.service';
import {
  RouterService,
}                                                                                            from '@services/router.service';
import {
  EnableOTPConfirmRequest,
}                                                                                            from '@models/api/enable-otp-confirm-request.model';
import {
  AcceptCompanyInviteRequestAction,
  AcceptCompanyInviteResponseAction,
  AssignUserRoleRequestAction,
  AssignUserRoleResponseAction,
  AssignUserSecurityGroupRequestAction,
  AssignUserSecurityGroupResponseAction,
  AuthoriseMicrosoftSSORequestAction,
  AuthoriseMicrosoftSSOResponseAction,
  AuthoriseResponseAction,
  ClearRedirectURL,
  ClearScratchCodeAction,
  CloseSessionExpiryModal,
  CreateAPIKeyRequestAction,
  CreateAPIKeyResponseAction,
  CreateRoleRequestAction,
  CreateRoleResponseAction,
  DeleteAPIKeyRequestAction,
  DeleteAPIKeyResponseAction,
  DeleteRoleRequestAction,
  DeleteRoleResponseAction,
  DeleteSecurityGroupRequestAction,
  DeleteSecurityGroupResponseAction,
  DisableOTPRequestAction,
  DisableOTPResponseAction,
  EnableOTPConfirmRequestAction,
  EnableOTPConfirmResponseAction,
  EnableOTPRequestAction,
  EnableOTPResponseAction,
  FetchAPIKeyRequestAction,
  FetchAPIKeyResponseAction,
  FetchAPIKeysRequestAction,
  FetchAPIKeysResponseAction,
  FetchApprovalListRequestAction,
  FetchApprovalListResponseAction,
  FetchCompanyDomainListRequestAction,
  FetchCompanyDomainListResponseAction,
  FetchCompanyInviteDescriptionRequestAction,
  FetchCompanyInviteDescriptionResponseAction,
  FetchRoleAssignmentCountRequestAction,
  FetchRoleAssignmentCountResponseAction,
  FetchRolesRequestAction,
  FetchRolesResponseAction,
  FetchScopesRequestAction,
  FetchScopesResponseAction,
  FetchSecurityGroupListRequestAction,
  FetchSecurityGroupListResponseAction,
  FetchSecurityGroupRequestAction,
  FetchSecurityGroupResponseAction,
  FetchTokenIntrospectRequestAction,
  FetchTokenIntrospectResponseAction,
  FetchUserCountsRequestAction,
  FetchUserCountsResponseAction,
  FetchUserIDPIdentitiesRequestAction,
  FetchUserIDPIdentitiesResponseAction,
  FetchUserProfileRequestAction,
  FetchUserProfileResponseAction,
  LoginWithRedirect,
  LogoutAction,
  MarkSessionExpiryModalOpen,
  OAuth2Callback,
  PatchAPIKeyRequestAction,
  PatchAPIKeyResponseAction,
  PatchSecurityGroupRequestAction,
  PatchSecurityGroupResponseAction,
  PatchUserProfileRequestAction,
  PatchUserProfileResponseAction,
  PostSecurityGroupRequestAction,
  PostSecurityGroupResponseAction,
  PutRoleRequestAction,
  PutRoleResponseAction,
  RefreshAccessTokenRequestAction,
  RefreshSessionDataRequest,
  RefreshSessionDataResponse,
  RequestResetPasswordRequestAction,
  RequestResetPasswordResponseAction,
  ResendUserInviteRequestAction,
  ResendUserInviteResponseAction,
  ResetPasswordRequestAction,
  ResetPasswordResponseAction,
  SetRedirectURL,
  ShowSessionExpiryModal,
  UpdateCompanyDomainRequestAction,
  UpdateCompanyDomainResponseAction,
  UserApprovalRequestAction,
  UserApprovalResponseAction,
  UsersBulkRequestAction,
  UsersBulkResponseAction,
  ValidateTokenRequestAction,
  ValidateTokenResponseAction,
}                                                                                            from '@redux/auth/auth.actions';
import {
  ScratchCodeModalComponent,
}                                                                                            from '@dialog/scratch-code-modal/scratch-code-modal.component';
import {
  selectApprovalQueryParams,
  selectAuth,
  selectDomainQueryParams,
  selectRedirectUrl,
  selectRolesQuery,
  selectSecurityGroupQueryParams,
  selectTokenIntrospectMetadata,
  selectUserScopes,
}                                                                                            from '@redux/auth/auth.selectors';
import {
  withScopes,
}                                                                                            from '@rxjs/with-scopes.operator';
import {
  MatDialog,
}                                                                                            from '@angular/material/dialog';
import {
  AssignUserRoleResponse,
}                                                                                            from '@models/api/assign-user-role-response.model';
import {
  ResetPasswordResponse,
}                                                                                            from '@models/api/reset-password-response.model';
import { DOCUMENT }                                                                          from '@angular/common';
import {
  FetchUserProfileResponse,
}                                                                                            from '@models/api/fetch-user-profile-response.model';
import {
  EnableOTPRequest,
}                                                                                            from '@models/api/enable-otp-request.model';
import { generateCodeChallengeFromVerifier, generateRandomString }                           from '@util/crypto';
import {
  FetchScopesResponse,
}                                                                                            from '@models/api/fetch-scopes-response.model';
import {
  withThrottle,
}                                                                                            from '@rxjs/action-throttle.operator';
import {
  CreateRoleRequest,
}                                                                                            from '@models/api/create-role-request.model';
import {
  FetchCompanyRequestAction,
  FetchCompanyUserListRequestAction,
}                                                                                            from '@redux/company/company.actions';
import {
  ConfirmModalComponent,
}                                                                                            from '@dialog/general/confirm-modal/confirm-modal.component';
import {
  FetchRoleAssignmentCountResponse,
}                                                                                            from '@models/api/fetch-role-assignment-count-response.model';
import {
  AuthScope,
}                                                                                            from '@enums/auth-scope.enum';
import {
  FetchRoleAssignmentCountRequest,
}                                                                                            from '@models/api/fetch-role-assignment-count-request.model';
import {
  ValidateTokenResponse,
}                                                                                            from '@models/api/validate-token-response.model';
import {
  AuthoriseResponse,
}                                                                                            from '@models/api/authorise-response.model';
import { StoreState }                                                                        from '@redux/store';
import {
  PutRoleRequest,
}                                                                                            from '@models/api/put-role-request.model';
import {
  AssignUserRoleRequest,
}                                                                                            from '@models/api/assign-user-role-request.model';
import {
  FetchCompanyInviteDescriptionRequest,
}                                                                                            from '@models/api/fetch-company-invite-description-request.model';
import {
  ResetPasswordRequest,
}                                                                                            from '@models/api/reset-password-request.model';
import {
  Oauth2Payload,
}                                                                                            from '@models/api/oauth2-payload.model';
import {
  SessionExpiryModalComponent,
}                                                                                            from '@dialog/session-expiry-modal/session-expiry-modal.component';
import {
  EnableOTPConfirmResponse,
}                                                                                            from '@models/api/enable-otp-confirm-response.model';
import {
  PatchUserProfileResponse,
}                                                                                            from '@models/api/patch-user-profile-response.model';
import { of }                                                                                from 'rxjs';
import {
  DisableOTPResponse,
}                                                                                            from '@models/api/disable-otp-response.model';
import {
  AcceptCompanyInviteResponse,
}                                                                                            from '@models/api/accept-company-invite-response.model';
import {
  DeleteRoleRequest,
}                                                                                            from '@models/api/delete-role-request.model';
import {
  FetchCompanyInviteDescriptionResponse,
}                                                                                            from '@models/api/fetch-company-invite-description-response.model';
import {
  FetchRolesRequest,
}                                                                                            from '@models/api/fetch-roles-request.model';
import {
  RolesQueryParams,
}                                                                                            from '@models/form/roles-query-params.model';
import {
  PatchUserProfileRequest,
}                                                                                            from '@models/api/patch-user-profile-request.model';
import { splitURLByPath }                                                                    from '@util/url.helper';
import {
  AcceptCompanyInviteRequest,
}                                                                                            from '@models/api/accept-company-invite-request.model';
import {
  DeleteRoleResponse,
}                                                                                            from '@models/api/delete-role-response.model';
import {
  RequestResetPasswordResponse,
}                                                                                            from '@models/api/request-reset-password-response.model';
import {
  RequestResetPasswordRequest,
}                                                                                            from '@models/api/request-reset-password-request.model';
import {
  AuthState,
}                                                                                            from '@redux/auth/auth.reducer';
import {
  TokenService,
}                                                                                            from '@services/token.service';
import {
  FetchAPIKeysResponse,
}                                                                                            from '@models/api/identity/fetch-api-keys-response.model';
import {
  FetchAPIKeysRequest,
}                                                                                            from '@models/api/identity/fetch-api-keys-request.model';
import {
  CreateApiKeyRequest,
}                                                                                            from '@models/api/identity/create-api-key-request.model';
import {
  CreateApiKeyResponse,
}                                                                                            from '@models/api/identity/create-api-key-response.model';
import {
  FetchAPIKeyRequest,
}                                                                                            from '@models/api/identity/fetch-api-key-request.model';
import {
  FetchAPIKeyResponse,
}                                                                                            from '@models/api/identity/fetch-api-key-response.model';
import {
  PatchApiKeyRequest,
}                                                                                            from '@models/api/identity/patch-api-key-request.model';
import {
  PatchApiKeyResponse,
}                                                                                            from '@models/api/identity/patch-api-key-response.model';
import {
  DeleteApiKeyResponse,
}                                                                                            from '@models/api/identity/delete-api-key-response.model';
import {
  DeleteApiKeyRequest,
}                                                                                            from '@models/api/identity/delete-api-key-request.model';
import {
  AuthoriseMicrosoftSSOResponse,
}                                                                                            from '@models/api/identity/authorise-microsoft-sso-response.model';
import {
  environment,
}                                                                                            from '../../../environments/environment';
import {
  TaskManagerService,
}                                                                                            from '@services/task-manager.service';
import {
  FetchCompanyDomainListResponse,
}                                                                                            from '@models/api/identity/fetch-company-domain-list-response.model';
import {
  UpdateCompanyDomainRequest,
}                                                                                            from '@models/api/identity/update-company-domain-request.model';
import {
  UpdateCompanyDomainResponse,
}                                                                                            from '@models/api/identity/update-company-domain-response.model';
import {
  AuthoriseMicrosoftSSORequest,
}                                                                                            from '@models/api/identity/authorise-microsoft-sso-request.model';
import {
  AuthToken,
}                                                                                            from '@enums/auth-token.enum';
import {
  FetchCompanyDomainListRequest,
}                                                                                            from '@models/api/identity/fetch-company-domain-list-request.model';
import {
  DomainQueryParams,
}                                                                                            from '@models/form/domain-query-params.model';
import {
  FetchApprovalListRequest,
}                                                                                            from '@models/api/identity/fetch-approval-list-request.model';
import {
  ApprovalQueryParams,
}                                                                                            from '@models/form/approval-query-params.model';
import {
  FetchApprovalListResponse,
}                                                                                            from '@models/api/identity/fetch-approval-list-response.model';
import {
  UserApprovalRequest,
}                                                                                            from '@models/api/identity/user-approval-request.model';
import {
  UserApprovalResponse,
}                                                                                            from '@models/api/identity/user-approval-response.model';
import {
  FetchUserCountsResponse,
}                                                                                            from '@models/api/identity/fetch-user-counts-response.model';
import {
  UsersBulkRequest,
}                                                                                            from '@models/api/identity/users-bulk-request.model';
import {
  UsersBulkResponse,
}                                                                                            from '@models/api/identity/users-bulk-response.model';
import {
  FetchTokenIntrospectResponse,
}                                                                                            from '@models/api/identity/fetch-token-introspect-response.model';
import {
  ResendUserInviteRequest,
}                                                                                            from '@models/api/identity/resend-user-invite-request.model';
import {
  ResendUserInviteResponse,
}                                                                                            from '@models/api/identity/resend-user-invite-response.model';
import {
  TokenData,
}                                                                                            from '@services/api.service';
import {
  FetchSecurityGroupRequest,
}                                                                                            from '@models/api/identity/fetch-security-group-request.model';
import {
  FetchSecurityGroupListResponse,
}                                                                                            from '@models/api/identity/fetch-security-group-list-response.model';
import {
  FetchSecurityGroupResponse,
}                                                                                            from '@models/api/identity/fetch-security-group-response.model';
import {
  FetchSecurityGroupListRequest,
}                                                                                            from '@models/api/identity/fetch-security-group-list-request.model';
import {
  PostSecurityGroupRequest,
}                                                                                            from '@models/api/identity/post-security-group-request.model';
import {
  PostSecurityGroupResponse,
}                                                                                            from '@models/api/identity/post-security-group-response.model';
import {
  PatchSecurityGroupRequest,
}                                                                                            from '@models/api/identity/patch-security-group-request.model';
import {
  PatchSecurityGroupResponse,
}                                                                                            from '@models/api/identity/patch-security-group-response.model';
import {
  ConfirmModalData,
}                                                                                            from '@models/ui/confirm-modal-data.model';
import {
  DeleteSecurityGroupRequest,
}                                                                                            from '@models/api/identity/delete-security-group-request.model';
import {
  DeleteSecurityGroupResponse,
}                                                                                            from '@models/api/identity/delete-security-group-response.model';
import {
  SecurityGroupQueryParams,
}                                                                                            from '@models/form/identity/security-group-query-params.model';
import {
  AssignUserSecurityGroupRequest,
}                                                                                            from '@models/api/identity/assign-user-security-group-request.model';
import {
  AssignUserSecurityGroupResponse,
}                                                                                            from '@models/api/identity/assign-user-security-group-response.model';
import {
  SessionManagerService,
}                                                                                            from '@services/session-manager.service';

@Injectable()
export class AuthEffects {
  private static isInvalidRedirect(queryParams: { [p: string]: string }, auth: AuthState): boolean {
    return queryParams.email && queryParams.email !== auth.user?.email;
  }

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: RouterService,
    private store: Store<StoreState>,
    private dialog: MatDialog,
    private taskManagerService: TaskManagerService,
    private sessionManagerService: SessionManagerService,
    @Inject(DOCUMENT) private _document: Document,
  ) {}

  loginWithRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(LoginWithRedirect),
    withLatestFrom(this.store.select(selectRedirectUrl)),
    tap(async ([_, redirectUrl]) => {
      if (redirectUrl) { // only persist redirectUrl if there is one set in state
        TokenService.setToken('redirectUrl', redirectUrl);
      } else {
        TokenService.removeToken('redirectUrl');
      }
      const state = generateRandomString();
      TokenService.setToken('state', state);
      const codeVerifier = generateRandomString();
      TokenService.setToken('code_verifier', codeVerifier);
      const codeChallenge                      = await generateCodeChallengeFromVerifier(codeVerifier);
      this._document.defaultView.location.href = AuthService.buildAuthorisationServerUrl(state, codeChallenge);
    }),
  ), { dispatch: false });

  oauth2Callback$ = createEffect(() => this.actions$.pipe(
    ofType(OAuth2Callback),
    switchMap((payload: Oauth2Payload) => {
      const state                            = TokenService.getToken('state');
      const codeVerifier                     = TokenService.getToken('code_verifier') as string;
      const client_id                        = AuthService.getClientId(payload.referrer);
      const accessToken: { expires: string } = TokenService.getToken(AuthToken.Access) as TokenData;
      const hasToken                         = !!accessToken;
      const isCallrouteSSO                   = client_id === environment.auth.clientId;
      if (isCallrouteSSO && !hasToken && state !== payload.state) {
        console.warn(`State mismatch - expected: ${ state }, received: ${ payload.state }`);
        return of(LoginWithRedirect());
      }
      return this.authService.authorise$({
        redirect_uri:  isCallrouteSSO ? AuthService.getOAuthRedirectUri() : undefined,
        code:          payload.code,
        code_verifier: isCallrouteSSO ? codeVerifier : undefined,
        client_id,
        state:         payload.state,
        grant_type:    isCallrouteSSO ? 'authorization_code' : undefined,
        connectMode:   !!hasToken,
      })
        .pipe(
          map((res: AuthoriseResponse) => AuthoriseResponseAction(res)),
        );
    })));

  loginResponse$ = createEffect(() => this.actions$.pipe(
    ofType(AuthoriseResponseAction),
    tap((res: AuthoriseResponse) => {
      if (res?.error) {
        return;
      }

      if (res.connectMode) {
        return this.router.navigate(['/admin', 'access-control'], undefined, {
          fragment: 'microsoft-sso',
        });
      }

      return this.store.select(selectAuth)
        .pipe(
          filter(auth => !!auth.user),
          take(1))
        .subscribe(auth => {
          const redirectUrl = TokenService.getToken('redirectUrl') as string;
          if (!auth.user.scopes?.length && auth.user.companyId) {
            return this.router.navigate(['/access-restricted']);
          }
          if (!redirectUrl) {
            return this.router.navigate(['/overview'])
              .then(() => null);
          }

          const { path, queryParams, fragment } = splitURLByPath(redirectUrl);
          if (AuthEffects.isInvalidRedirect(queryParams, auth)) {
            return this.router.navigate(['/overview'])
              .then(() => this.store.dispatch(ClearRedirectURL()));
          }
          return this.router.navigate([path], queryParams, { fragment })
            .then(() => this.store.dispatch(ClearRedirectURL()));
        });
    }),
    map(() => ValidateTokenRequestAction()),
  ));

  authorizationRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthoriseMicrosoftSSORequestAction),
    switchMap((req: AuthoriseMicrosoftSSORequest) => this.authService.authorisationRedirect$(req)),
    map((res: AuthoriseMicrosoftSSOResponse) =>
      AuthoriseMicrosoftSSOResponseAction(res))),
  );

  openMicrosoftAuthWindow$ = createEffect(() => this.actions$.pipe(
    ofType(AuthoriseMicrosoftSSOResponseAction),
    tap((res: AuthoriseMicrosoftSSOResponse) => {
      if (res.redirectUri) {
        this.authService.setRedirectLocation(res.redirectUri);
      }
    }),
  ), { dispatch: false });

  checkAndValidateToken$ = createEffect(() => this.actions$.pipe(
      ofType(ValidateTokenRequestAction),
      tap(() => this.store.dispatch(FetchTokenIntrospectRequestAction({}))),
      withLatestFrom(this.store.select(selectTokenIntrospectMetadata)),
      switchMap(([_, metadata]) =>
        this.authService.checkAndValidateToken$(metadata?.clientId)
          .pipe(
            map((res: ValidateTokenResponse) => ValidateTokenResponseAction(res)),
          ),
      ),
    ),
  );

  checkAndValidateTokenResponse$ = createEffect(() => this.actions$.pipe(
      ofType(ValidateTokenResponseAction),
      filter((req: ValidateTokenResponse) => req.token !== null),
      map(() => FetchUserProfileRequestAction({})),
    ),
  );

  fetchUserProfile$ = createEffect(() => this.actions$.pipe(
    ofType(FetchUserProfileRequestAction),
    withThrottle(),
    switchMap(() =>
      this.authService.fetchUserProfile$()
        .pipe(
          map((res: FetchUserProfileResponse) => FetchUserProfileResponseAction(res)),
        ),
    ),
  ));

  fetchUserIdpIdentities$ = createEffect(() => this.actions$.pipe(
    ofType(FetchUserIDPIdentitiesRequestAction),
    withThrottle(),
    switchMap(() =>
      this.authService.fetchUserSelf$()
        .pipe(
          map((res: FetchUserProfileResponse) =>
            FetchUserIDPIdentitiesResponseAction({ idpIdentities: res.user?.idpIdentities || [] })),
        ),
    ),
  ));

  fetchUserProfileResponse$ = createEffect(() => this.actions$.pipe(
    ofType(FetchUserProfileResponseAction),
    filter((req: FetchUserProfileResponse) => req?.user?.companyId !== null),
    switchMap(() => this.authService.getCompanyContext$()),
    map((companyId: string) =>
      FetchCompanyRequestAction({ companyId })),
  ));

  patchUserProfile$ = createEffect(() => this.actions$.pipe(
    ofType(PatchUserProfileRequestAction),
    switchMap((req: PatchUserProfileRequest) =>
      this.authService.patchUserProfile$(req)
        .pipe(
          map((res: PatchUserProfileResponse) => PatchUserProfileResponseAction(res)),
        ),
    ),
    tap(() => this.store.dispatch(FetchCompanyUserListRequestAction({ emitEvent: false }))),
  ));

  patchUserProfileResponse$ = createEffect(() => this.actions$.pipe(
      ofType(PatchUserProfileResponseAction),
      filter((req: PatchUserProfileResponse) => req.checkTokenAfterPatchRedirect !== null),
      map(() => RefreshSessionDataRequest()),
    ),
  );

  requestResetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(RequestResetPasswordRequestAction),
    switchMap((req: RequestResetPasswordRequest) =>
      this.authService.requestResetPassword$(req)
        .pipe(
          map((res: RequestResetPasswordResponse) => RequestResetPasswordResponseAction(res)),
        ),
    ),
  ));

  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(ResetPasswordRequestAction),
    switchMap((req: ResetPasswordRequest) =>
      this.authService.resetPassword$(req)
        .pipe(
          map((res: ResetPasswordResponse) => ResetPasswordResponseAction(res)),
        ),
    ),
  ));

  fetchCompanyInviteDescription$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCompanyInviteDescriptionRequestAction),
    switchMap((req: FetchCompanyInviteDescriptionRequest) =>
      this.authService.fetchCompanyInviteDescription$(req)
        .pipe(
          map((res: FetchCompanyInviteDescriptionResponse) => FetchCompanyInviteDescriptionResponseAction(res)),
        ),
    ),
  ));

  acceptCompanyInvite$ = createEffect(() => this.actions$.pipe(
    ofType(AcceptCompanyInviteRequestAction),
    switchMap((req: AcceptCompanyInviteRequest) =>
      this.authService.acceptCompanyInvite$(req)
        .pipe(
          map((res: AcceptCompanyInviteResponse) => AcceptCompanyInviteResponseAction(res)),
        ),
    ),
  ));

  acceptCompanyInviteResponse$ = createEffect(() => this.actions$.pipe(
    ofType(AcceptCompanyInviteResponseAction),
    map((req: AcceptCompanyInviteResponse) => {
      if (!req.error) {
        window.location.href = '/overview';
      }
    }),
  ), { dispatch: false });

  refreshSessionData$ = createEffect(() => this.actions$.pipe(
    ofType(RefreshSessionDataRequest),
    map(() => RefreshSessionDataResponse()),
  ));

  refreshAccessToken$ = createEffect(() => this.actions$.pipe(
    ofType(RefreshAccessTokenRequestAction),
    withLatestFrom(this.store.select(selectTokenIntrospectMetadata)),
    throttleTime(5_000, undefined, { leading: true, trailing: false }),
    switchMap(([_, metadata]) => this.authService.refreshToken$(metadata?.clientId)),
    tap((response: ValidateTokenResponse) => {
      if (response.error) {
        this.store.dispatch(LogoutAction({}));
      }
    }),
  ), { dispatch: false });

  showSessionExpiryModal$ = createEffect(() => this.actions$.pipe(
    ofType(ShowSessionExpiryModal),
    withLatestFrom(this.store.select(selectAuth)
      .pipe(pluck('sessionExpiryModalOpen'))),
    tap(([_, open]) => {
      if (open) {
        return;
      }
      this.store.dispatch(MarkSessionExpiryModalOpen());
      return this.dialog.open(SessionExpiryModalComponent, {
        disableClose: true,
        panelClass:   'cr-dialog',
        maxWidth:     '560px',
        width:        '100%',
      })
        .afterClosed()
        .toPromise()
        .then(() => this.store.dispatch(CloseSessionExpiryModal()));
    }),
  ), { dispatch: false });

  closeSessionExpiry$ = createEffect(() => this.actions$.pipe(
    ofType(CloseSessionExpiryModal),
    map(() => this.store.dispatch(RefreshAccessTokenRequestAction({}))),
  ), { dispatch: false });

  enableOTP$ = createEffect(() => this.actions$.pipe(
    ofType(EnableOTPRequestAction),
    switchMap((req: EnableOTPRequest) => this.authService.enableOTP$(req)),
    map(resp => EnableOTPResponseAction(resp))),
  );

  enableOTPConfirm$ = createEffect(() => this.actions$.pipe(
    ofType(EnableOTPConfirmRequestAction),
    switchMap((req: EnableOTPConfirmRequest) => this.authService.enableOTPConfirm$(req)),
    map(resp => EnableOTPConfirmResponseAction(resp))),
  );

  openScratchCodeModal$ = createEffect(() => this.actions$.pipe(
    ofType(EnableOTPConfirmResponseAction),
    switchMap((res: EnableOTPConfirmResponse) => {
      if (!res.error && res.otpScratch) {
        return this.dialog.open(ScratchCodeModalComponent, {
          disableClose: true,
          panelClass:   'cr-dialog',
          maxWidth:     '560px',
          width:        '100%',
          data:         res.otpScratch,
        })
          .afterClosed()
          .pipe(map(() => ClearScratchCodeAction()));
      }
      return of(ClearScratchCodeAction());
    }),
  ));

  disableOTP$ = createEffect(() => this.actions$.pipe(
    ofType(DisableOTPRequestAction),
    switchMap(() => {
      return this.dialog.open(ConfirmModalComponent, {
        panelClass: 'cr-dialog',
        minWidth:   '40vw',
        data:       {
          title:          `Disable Multi-Factor Authentication`,
          content:        'Are you sure? Using Multi-Factor Authentication enhances the security of your account.',
          confirmBtnText: 'Disable',
          showCancel:     false,
        },
      })
        .afterClosed()
        .pipe(
          concatMap(confirm => {
            if (!confirm) {
              return of(DisableOTPResponseAction({ cancelled: true }));
            }
            return this.authService.disableOTP$()
              .pipe(
                map((res: DisableOTPResponse) => DisableOTPResponseAction(res)));
          }),
        );
    })),
  );

  fetchRoles$ = createEffect(() => this.actions$.pipe(
    ofType(FetchRolesRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.RoleRead]),
    withLatestFrom(this.store.select(selectRolesQuery)),
    switchMap(([req, storedQuery]: [FetchRolesRequest, RolesQueryParams]) => {
      const queryParams: RolesQueryParams = {
        ...(req.queryParams || storedQuery || {}) as RolesQueryParams,
        include: ['rules.scope', 'rules.id', 'rules.context'],
      };
      return this.authService.fetchRoles$({ ...req, queryParams })
        .pipe(map(res => FetchRolesResponseAction(res)));
    })));

  createRole$ = createEffect(() => this.actions$.pipe(
    ofType(CreateRoleRequestAction),
    switchMap((req: CreateRoleRequest) => {
      return this.authService.createRole$(req)
        .pipe(map(res => CreateRoleResponseAction(res)));
    }),
    tap(() => this.store.dispatch(FetchRolesRequestAction(null))),
  ));

  patchAPIKey$ = createEffect(() => this.actions$.pipe(
    ofType(PatchAPIKeyRequestAction),
    switchMap((req: PatchApiKeyRequest) => {
      return this.authService.patchAPIKey$(req);
    }),
    map((res: PatchApiKeyResponse) => PatchAPIKeyResponseAction(res)),
  ));

  deleteAPIKey$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteAPIKeyRequestAction),
    switchMap((req: DeleteApiKeyRequest) => {
      return this.dialog.open(ConfirmModalComponent, {
        panelClass: 'cr-dialog',
        minWidth:   '30vw',
        data:       {
          title:          `Deleting API key`,
          content:        `You are about to delete an API key.`,
          confirmBtnText: 'Delete',
          typeConfirm:    true,
          showCancel:     false,
        },
      })
        .afterClosed()
        .pipe(
          concatMap(confirm => {
            if (!confirm) {
              return of(DeleteAPIKeyResponseAction(null));
            }
            return this.authService.deleteAPIKey$(req)
              .pipe(
                map((res: DeleteApiKeyResponse) =>
                  DeleteAPIKeyResponseAction(res)));
          }),
        );
    }),
  ));

  deleteRole$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteRoleRequestAction),
    switchMap((req: DeleteRoleRequest) => {
      return this.dialog.open(ConfirmModalComponent, {
        panelClass: 'cr-dialog',
        minWidth:   '30vw',
        data:       {
          title:          `Deleting ${ req.name }`,
          content:        req.recordCount ?
                            `You are about to delete a role that is assigned to ${ req.recordCount } users. Those users will be unassigned from the role and may have limited access as a result.` :
                            'There are no users currently assigned to this role.',
          confirmBtnText: 'Delete',
          typeConfirm:    true,
          showCancel:     false,
        },
      })
        .afterClosed()
        .pipe(
          concatMap(confirm => {
            if (!confirm) {
              return of(DeleteRoleResponseAction(null));
            }
            return this.authService.deleteRole$(req)
              .pipe(
                map((res: DeleteRoleResponse) =>
                  DeleteRoleResponseAction(res)));
          }),
        );
    }),
    tap(() => this.store.dispatch(FetchRolesRequestAction(null))),
  ));

  assignRoleUsers$ = createEffect(() => this.actions$.pipe(
    ofType(AssignUserRoleRequestAction),
    switchMap((req: AssignUserRoleRequest) => {
      return this.authService.assignUserRole$(req)
        .pipe(map((res: AssignUserRoleResponse) => AssignUserRoleResponseAction(res)));
    }),
  ));

  fetchRoleAssignmentCount$ = createEffect(() => this.actions$.pipe(
    ofType(FetchRoleAssignmentCountRequestAction),
    switchMap((req: FetchRoleAssignmentCountRequest) => {
      return this.authService.fetchRoleAssignmentCount$(req)
        .pipe(
          map((res: FetchRoleAssignmentCountResponse) => FetchRoleAssignmentCountResponseAction(res)));
    }),
  ));

  fetchScopes$ = createEffect(() => this.actions$.pipe(
    ofType(FetchScopesRequestAction),
    switchMap(() => {
      return this.authService.fetchScopes$()
        .pipe(
          map((res: FetchScopesResponse) => FetchScopesResponseAction(res)));
    }),
  ));

  putRole$ = createEffect(() => this.actions$.pipe(
    ofType(PutRoleRequestAction),
    switchMap((req: PutRoleRequest) => {
      return this.authService.putRole$(req)
        .pipe(map(res => PutRoleResponseAction(res)));
    }),
    tap(() => this.store.dispatch(FetchRolesRequestAction(null))),
  ));

  fetchAPIKeys$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAPIKeysRequestAction),
    switchMap((req: FetchAPIKeysRequest) => this.authService.fetchAPIKeys$(req)),
    map((res: FetchAPIKeysResponse) => FetchAPIKeysResponseAction(res)),
  ));

  fetchAPIKey$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAPIKeyRequestAction),
    switchMap((req: FetchAPIKeyRequest) => this.authService.fetchAPIKey$(req)),
    map((res: FetchAPIKeyResponse) => FetchAPIKeyResponseAction(res)),
  ));

  fetchSecurityGroupList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchSecurityGroupListRequestAction),
    withLatestFrom(this.store.select(selectSecurityGroupQueryParams)),
    switchMap(([req, queryParams]: [FetchSecurityGroupListRequest, SecurityGroupQueryParams]) => {
      return this.authService.fetchSecurityGroupList$({ ...req, queryParams: req.queryParams || queryParams });
    }),
    map((res: FetchSecurityGroupListResponse) => FetchSecurityGroupListResponseAction(res)),
  ));

  fetchSecurityGroup$ = createEffect(() => this.actions$.pipe(
    ofType(FetchSecurityGroupRequestAction),
    switchMap((req: FetchSecurityGroupRequest) => this.authService.fetchSecurityGroup$(req)),
    map((res: FetchSecurityGroupResponse) => FetchSecurityGroupResponseAction(res)),
  ));

  postSecurityGroup$ = createEffect(() => this.actions$.pipe(
    ofType(PostSecurityGroupRequestAction),
    switchMap((req: PostSecurityGroupRequest) => this.authService.postSecurityGroup$(req)),
    map((res: PostSecurityGroupResponse) => PostSecurityGroupResponseAction(res)),
  ));

  patchSecurityGroup$ = createEffect(() => this.actions$.pipe(
    ofType(PatchSecurityGroupRequestAction),
    switchMap((req: PatchSecurityGroupRequest) => this.authService.patchSecurityGroup$(req)),
    map((res: PatchSecurityGroupResponse) => PatchSecurityGroupResponseAction(res)),
  ));

  assignSecurityGroupUsers$ = createEffect(() => this.actions$.pipe(
    ofType(AssignUserSecurityGroupRequestAction),
    switchMap((req: AssignUserSecurityGroupRequest) => {
      return this.authService.assignUserRole$(req)
        .pipe(map((res: AssignUserSecurityGroupResponse) => AssignUserSecurityGroupResponseAction(res)));
    }),
  ));

  deleteSecurityGroup$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteSecurityGroupRequestAction),
    switchMap((req: DeleteSecurityGroupRequest) => {
        return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
          data:       {
            title:          `Delete ${ req.name }`,
            content:        `<p>You are about to delete a Security Group. Click 'delete' to continue with the deletion.</p>`,
            confirmBtnText: 'Delete',
            showCancel:     true,
            typeConfirm:    true,
          },
          panelClass: 'cr-dialog',
          maxWidth:   '640px',
          maxHeight:  'calc(100vh - 140px)',
          width:      '100%',
        })
          .afterClosed()
          .pipe(concatMap((confirmed: boolean) => {
            if (!confirmed) {
              return of(DeleteSecurityGroupResponseAction({
                cancelled:    true,
                error:        null,
                id:           req.id,
                isLastOnPage: false,
              }));
            }
            return this.authService.deleteSecurityGroup$(req)
              .pipe(map((res: DeleteSecurityGroupResponse) => DeleteSecurityGroupResponseAction(res)));
          }));
      },
    )));

  deleteSecurityGroupRefresh$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteSecurityGroupResponseAction),
    withLatestFrom(this.store.select(selectSecurityGroupQueryParams)),
    tap(([res, queryParams]) => {
      const pageNumber = res.isLastOnPage && queryParams?.pageNumber > 1 ?
        queryParams.pageNumber - 1 :
        (queryParams?.pageNumber || 1);
      this.store.dispatch(FetchSecurityGroupListRequestAction({ queryParams: { ...queryParams, pageNumber } }));
    }),
  ), { dispatch: false });

  createAPIKey$ = createEffect(() => this.actions$.pipe(
    ofType(CreateAPIKeyRequestAction),
    switchMap((req: CreateApiKeyRequest) => this.authService.createAPIKey$(req)),
    map((res: CreateApiKeyResponse) => CreateAPIKeyResponseAction(res)),
  ));

  fetchCompanyDomainList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCompanyDomainListRequestAction),
    withLatestFrom(this.store.select(selectDomainQueryParams)),
    switchMap(([req, queryParams]: [FetchCompanyDomainListRequest, DomainQueryParams]) => this.authService.fetchCompanyDomainList$({ queryParams, ...req })),
    map((res: FetchCompanyDomainListResponse) => FetchCompanyDomainListResponseAction(res)),
  ));

  fetchApprovalList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchApprovalListRequestAction),
    withLatestFrom(this.store.select(selectApprovalQueryParams)),
    switchMap(([req, queryParams]: [FetchApprovalListRequest, ApprovalQueryParams]) => {
      return this.authService.fetchApprovalList$({ queryParams, ...req });
    }),
    map((res: FetchApprovalListResponse) => FetchApprovalListResponseAction(res)),
  ));

  updateCompanyDomain$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateCompanyDomainRequestAction),
    switchMap((req: UpdateCompanyDomainRequest) => this.authService.updateCompanyDomain$(req)),
    map((res: UpdateCompanyDomainResponse) => UpdateCompanyDomainResponseAction(res)),
  ));

  userApproval$ = createEffect(() => this.actions$.pipe(
    ofType(UserApprovalRequestAction),
    switchMap((req: UserApprovalRequest) => this.authService.userApproval$(req)),
    map((res: UserApprovalResponse) => UserApprovalResponseAction(res)),
  ));

  fetchUserCounts$ = createEffect(() => this.actions$.pipe(
    ofType(FetchUserCountsRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.UserRead]),
    switchMap(() => this.authService.fetchUserCounts$()),
    map((res: FetchUserCountsResponse) => FetchUserCountsResponseAction(res)),
  ));

  usersBulk$ = createEffect(() => this.actions$.pipe(
    ofType(UsersBulkRequestAction),
    switchMap((req: UsersBulkRequest) => this.authService.usersBulk$(req)),
    map((res: UsersBulkResponse) => UsersBulkResponseAction(res)),
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(LogoutAction),
    withThrottle(2_000, { leading: true, trailing: false }),
    tap(req => {
      this.sessionManagerService.broadcastLogoutEvent();
      const redirectUrl = req.redirectUrl;
      if (!redirectUrl) {
        return;
      }
      this.store.dispatch(SetRedirectURL({ redirectUrl }));
      this.taskManagerService.toggle.next(false);
    }),
    map(req => this.authService.logout(req.skipNavigation)),
  ), { dispatch: false });

  fetchTokenIntrospection$ = createEffect(() => this.actions$.pipe(
      ofType(FetchTokenIntrospectRequestAction),
      switchMap(() => this.authService.fetchTokenIntrospect$()),
      map((res: FetchTokenIntrospectResponse) => FetchTokenIntrospectResponseAction(res)),
    ),
  );

  resendUserInvite$ = createEffect(() => this.actions$.pipe(
      ofType(ResendUserInviteRequestAction),
      switchMap((req: ResendUserInviteRequest) => this.authService.resendUserInvite$(req)),
      map((res: ResendUserInviteResponse) => ResendUserInviteResponseAction(res)),
    ),
  );

}
