import { Observable, of, zip }                                                      from 'rxjs';
import { catchError, defaultIfEmpty, filter, map, pluck, switchMap, take, timeout } from 'rxjs/operators';
import { RouterService }                                                            from './router.service';
import { DOCUMENT, Location as AngularLocation }                                    from '@angular/common';

import { Inject, Injectable } from '@angular/core';

import { ApiService, TokenData } from './api.service';
import { environment }           from '../../environments/environment';

import { MatDialog } from '@angular/material/dialog';

import { Alert }                                                      from '@models/entity/alert.model';
import { HttpErrorResponse }                                          from '@angular/common/http';
import { AuthStatus }                                                 from '@enums/auth-status.enum';
import { AuthToken }                                                  from '@enums/auth-token.enum';
import { LogoutAction, ManualUpdateTokenData }                        from '@redux/auth/auth.actions';
import { StoreState }                                                 from '@redux/store';
import { Store }                                                      from '@ngrx/store';
import { TokenService }                                               from './token.service';
import { AuthState }                                                  from '@redux/auth/auth.reducer';
import { selectAuth, selectCompanyId, selectTokenIntrospectMetadata } from '@redux/auth/auth.selectors';
import { User }                                                       from '@models/entity/user.model';
import { UserRaw }                                                    from '@models/api/user-raw.model';
import { AuthoriseResponseRaw }                                       from '@models/api/authorise-response-raw.model';
import { AuthoriseResponse }                                          from '@models/api/authorise-response.model';
import { AccountInvitationRaw }                                       from '@models/api/account-invitation-raw.model';
import { AccountInvitation }                                          from '@models/entity/account-invitation.model';
import { ValidateTokenResponse }                                      from '@models/api/validate-token-response.model';
import {
  FetchUserProfileResponseRaw,
}                                                                     from '@models/api/fetch-user-profile-response-raw.model';
import {
  FetchUserProfileResponse,
}                                                                     from '@models/api/fetch-user-profile-response.model';
import {
  PatchUserProfileRequest,
}                                                                     from '@models/api/patch-user-profile-request.model';
import {
  PatchUserProfileResponse,
}                                                                     from '@models/api/patch-user-profile-response.model';
import {
  RequestResetPasswordRequest,
}                                                                     from '@models/api/request-reset-password-request.model';
import {
  RequestResetPasswordResponse,
}                                                                     from '@models/api/request-reset-password-response.model';
import { ResetPasswordRequest }                                       from '@models/api/reset-password-request.model';
import { ResetPasswordResponse }                                      from '@models/api/reset-password-response.model';
import {
  FetchCompanyInviteDescriptionRequest,
}                                                                     from '@models/api/fetch-company-invite-description-request.model';
import {
  FetchCompanyInviteDescriptionResponse,
}                                                                     from '@models/api/fetch-company-invite-description-response.model';
import {
  AcceptCompanyInviteRequest,
}                                                                     from '@models/api/accept-company-invite-request.model';
import {
  AcceptCompanyInviteResponse,
}                                                                     from '@models/api/accept-company-invite-response.model';
import { splitURLByPath }                                             from '@util/url.helper';
import { Scope }                                                      from '@models/entity/scope.model';
import {
  FetchRoleAssignmentCountRequest,
}                                                                     from '@models/api/fetch-role-assignment-count-request.model';
import {
  EnableOTPConfirmRequest,
}                                                                     from '@models/api/enable-otp-confirm-request.model';
import { CreateRoleResponse }                                         from '@models/api/create-role-response.model';
import { PutRoleRequest }                                             from '@models/api/put-role-request.model';
import { PutRoleResponse }                                            from '@models/api/put-role-response.model';
import { ListService }                                                from '@services/list.service';
import { AssignUserRoleRequest }                                      from '@models/api/assign-user-role-request.model';
import { Oauth2Payload }                                              from '@models/api/oauth2-payload.model';
import {
  EnableOTPConfirmResponse,
}                                                                     from '@models/api/enable-otp-confirm-response.model';
import {
  AssignUserRoleResponse,
}                                                                     from '@models/api/assign-user-role-response.model';
import { EnableOTPResponse }                                          from '@models/api/enable-otp-response.model';
import {
  RolesSearchParamsFactory,
}                                                                     from '@models/factory/roles-search-params.factory';
import { FetchRolesResponse }                                         from '@models/api/fetch-roles-response.model';
import { EnableOTPRequest }                                           from '@models/api/enable-otp-request.model';
import { DisableOTPResponse }                                         from '@models/api/disable-otp-response.model';
import { FetchScopesResponse }                                        from '@models/api/fetch-scopes-response.model';
import { RoleFactory }                                                from '@models/factory/role.factory';
import { DeleteRoleRequest }                                          from '@models/api/delete-role-request.model';
import { RolesQueryParams }                                           from '@models/form/roles-query-params.model';
import { FetchRolesRequest }                                          from '@models/api/fetch-roles-request.model';
import {
  AssignUsersResponseRaw,
}                                                                     from '@models/api/assign-user-role-response-raw.model';
import { DeleteRoleResponse }                                         from '@models/api/delete-role-response.model';
import { Role }                                                       from '@models/entity/role.model';
import { EnableOtpResponseRaw }                                       from '@models/api/enable-otp-response-raw.model';
import { CreateRoleRequest }                                          from '@models/api/create-role-request.model';
import {
  FetchRoleAssignmentCountResponse,
}                                                                     from '@models/api/fetch-role-assignment-count-response.model';
import { RoleRaw }                                                    from '@models/api/role-raw.model';
import {
  EnableOtpConfirmResponseRaw,
}                                                                     from '@models/api/enable-otp-confirm-response-raw.model';
import {
  FetchAPIKeysResponse,
}                                                                     from '@models/api/identity/fetch-api-keys-response.model';
import { APIKey }                                                     from '@models/entity/api-key.model';
import {
  FetchAPIKeysRequest,
}                                                                     from '@models/api/identity/fetch-api-keys-request.model';
import { APIKeyFactory }                                              from '@models/factory/api-key.factory';
import { APIKeyParamsFactory }                                        from '@models/factory/api-key-params.factory';
import { APIKeyQueryParams }                                          from '@models/form/api-key-params.model';
import { ListModelResponse }                                          from '@models/api/list-response.model';
import {
  CreateApiKeyRequest,
}                                                                     from '@models/api/identity/create-api-key-request.model';
import {
  CreateApiKeyResponse,
}                                                                     from '@models/api/identity/create-api-key-response.model';
import { APIKeyRaw }                                                  from '@models/api/identity/api-key-raw.model';
import {
  FetchAPIKeyRequest,
}                                                                     from '@models/api/identity/fetch-api-key-request.model';
import {
  FetchAPIKeyResponse,
}                                                                     from '@models/api/identity/fetch-api-key-response.model';
import {
  PatchApiKeyRequest,
}                                                                     from '@models/api/identity/patch-api-key-request.model';
import {
  PatchApiKeyResponse,
}                                                                     from '@models/api/identity/patch-api-key-response.model';
import {
  DeleteApiKeyRequest,
}                                                                     from '@models/api/identity/delete-api-key-request.model';
import {
  DeleteApiKeyResponse,
}                                                                     from '@models/api/identity/delete-api-key-response.model';
import {
  ValidateTokenResponseRaw,
}                                                                     from '@models/api/validate-token-response-raw.model';
import {
  AuthorisationRedirectResponseRaw,
}                                                                     from '@models/api/authorisation-redirect-response-raw.model';
import {
  AuthoriseMicrosoftSSOResponse,
}                                                                     from '@models/api/identity/authorise-microsoft-sso-response.model';
import {
  CompanyDomainRaw,
}                                                                     from '@models/api/identity/company-domain-raw.model';
import { CompanyDomain }                                              from '@models/entity/company-domain.model';
import {
  FetchCompanyDomainListResponse,
}                                                                     from '@models/api/identity/fetch-company-domain-list-response.model';
import {
  UpdateCompanyDomainResponse,
}                                                                     from '@models/api/identity/update-company-domain-response.model';
import {
  UpdateCompanyDomainRequest,
}                                                                     from '@models/api/identity/update-company-domain-request.model';
import {
  AuthoriseMicrosoftSSORequest,
}                                                                     from '@models/api/identity/authorise-microsoft-sso-request.model';
import {
  FetchCompanyDomainListRequest,
}                                                                     from '@models/api/identity/fetch-company-domain-list-request.model';
import { DomainQueryParams }                                          from '@models/form/domain-query-params.model';
import { ListResponseMetadata }                                       from '@models/api/list-response-metadata.model';
import {
  FetchApprovalListRequest,
}                                                                     from '@models/api/identity/fetch-approval-list-request.model';
import {
  FetchApprovalListResponse,
}                                                                     from '@models/api/identity/fetch-approval-list-response.model';
import { ApprovalRaw }                                                from '@models/api/identity/approval-raw.model';
import { ApprovalQueryParams }                                        from '@models/form/approval-query-params.model';
import { Approval }                                                   from '@models/entity/approval.model';
import {
  UserApprovalRequest,
}                                                                     from '@models/api/identity/user-approval-request.model';
import {
  UserApprovalResponse,
}                                                                     from '@models/api/identity/user-approval-response.model';
import { ApprovalAction }                                             from '@enums/approval-action.enum';
import { UserCounts }                                                 from '@models/api/identity/user-counts.model';
import {
  FetchUserCountsResponse,
}                                                                     from '@models/api/identity/fetch-user-counts-response.model';
import {
  UsersBulkRequest,
}                                                                     from '@models/api/identity/users-bulk-request.model';
import {
  UsersBulkResponse,
}                                                                     from '@models/api/identity/users-bulk-response.model';
import {
  FetchTokenIntrospectResponse,
}                                                                     from '@models/api/identity/fetch-token-introspect-response.model';
import {
  TokenIntrospectMetadata,
}                                                                     from '@models/entity/token-introspect-metadata.model';
import {
  TokenIntrospectMetadataRaw,
}                                                                     from '@models/api/identity/token-introspect-metadata-raw.model';
import {
  ResendUserInviteRequest,
}                                                                     from '@models/api/identity/resend-user-invite-request.model';
import {
  ResendUserInviteResponse,
}                                                                     from '@models/api/identity/resend-user-invite-response.model';
import {
  FetchSecurityGroupListRequest,
}                                                                     from '@models/api/identity/fetch-security-group-list-request.model';
import {
  SecurityGroupQueryParams,
}                                                                     from '@models/form/identity/security-group-query-params.model';
import {
  FetchSecurityGroupListResponse,
}                                                                     from '@models/api/identity/fetch-security-group-list-response.model';
import {
  SecurityGroupFactory,
}                                                                     from '@models/factory/identity/security-group.factory';
import {
  SecurityGroupQueryParamsFactory,
}                                                                     from '@models/factory/identity/security-group-query-params.factory';
import { SecurityGroup }                                              from '@models/entity/security-group.model';
import {
  FetchSecurityGroupRequest,
}                                                                     from '@models/api/identity/fetch-security-group-request.model';
import {
  FetchSecurityGroupResponse,
}                                                                     from '@models/api/identity/fetch-security-group-response.model';
import {
  SecurityGroupRaw,
}                                                                     from '@models/api/identity/security-group-raw.model';
import {
  PostSecurityGroupRequest,
}                                                                     from '@models/api/identity/post-security-group-request.model';
import {
  PostSecurityGroupResponse,
}                                                                     from '@models/api/identity/post-security-group-response.model';
import {
  PatchSecurityGroupRequest,
}                                                                     from '@models/api/identity/patch-security-group-request.model';
import {
  PatchSecurityGroupResponse,
}                                                                     from '@models/api/identity/patch-security-group-response.model';
import {
  DeleteSecurityGroupRequest,
}                                                                     from '@models/api/identity/delete-security-group-request.model';
import {
  DeleteSecurityGroupResponse,
}                                                                     from '@models/api/identity/delete-security-group-response.model';
import {
  AssignUserSecurityGroupRequest,
}                                                                     from '@models/api/identity/assign-user-security-group-request.model';
import {
  AssignUserSecurityGroupResponse,
}                                                                     from '@models/api/identity/assign-user-security-group-response.model';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  static getOAuthRedirectUri(): string {
    return window.location.hostname.includes('localhost') ?
      'http://localhost:4200/oauth2/callback' :
      environment.auth.redirectUri;
  }

  static getClientId(referrer: string): string {
    if (referrer.includes('microsoft')) {
      return 'oauth-microsoft';
    }
    return environment.auth.clientId;
  }

  static buildAuthorisationServerUrl(state: string, codeChallenge: string): string {
    return `\
${ environment.auth.authorisationUrl }?\
response_type=code&\
client_id=${ environment.auth.clientId }&\
redirect_uri=${ AuthService.getOAuthRedirectUri() }&\
state=${ state }&\
code_challenge=${ codeChallenge }&\
code_challenge_method=S256`;
  }

  private baseUrl: string = environment.api.identityBaseUrl;
  private authReducer$: Observable<AuthState>;
  private readonly roleFactory: RoleFactory;
  private readonly rolesSearchParamsFactory: RolesSearchParamsFactory;
  private readonly APIKeyFactory: APIKeyFactory;
  private readonly APIKeySearchParamsFactory: APIKeyParamsFactory;
  private readonly securityGroupFactory: SecurityGroupFactory;
  private readonly securityGroupQueryParamsFactory: SecurityGroupQueryParamsFactory;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private apiService: ApiService,
    private router: RouterService,
    private dialog: MatDialog,
    private location: AngularLocation,
    private store: Store<StoreState>,
    private roleListService: ListService<Role, RoleFactory,
      RolesQueryParams, RolesSearchParamsFactory>,
    private apiKeyListService: ListService<APIKey, APIKeyFactory, APIKeyQueryParams, APIKeyParamsFactory>,
    private securityGroupListService: ListService<SecurityGroup, SecurityGroupFactory, SecurityGroupQueryParams, SecurityGroupQueryParamsFactory>,
  ) {
    this.authReducer$                    = this.store.select(selectAuth);
    this.roleFactory                     = new RoleFactory();
    this.rolesSearchParamsFactory        = new RolesSearchParamsFactory();
    this.APIKeyFactory                   = new APIKeyFactory();
    this.APIKeySearchParamsFactory       = new APIKeyParamsFactory();
    this.securityGroupFactory            = new SecurityGroupFactory();
    this.securityGroupQueryParamsFactory = new SecurityGroupQueryParamsFactory();
  }

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  private buildAuthTokenUri(isCallrouteSSO: boolean, connectMode: boolean): string {
    return this.buildUri(`oauth2/${ isCallrouteSSO && !connectMode ? '' : 'sso/' }${ connectMode ? 'connect' : 'token' }`);
  }

  private buildRoleUsersUri(id: string): string {
    return this.buildUri(`rbac/roles/${ id }/users`);
  }

  private buildRoleUri(id?: string): string {
    return this.buildUri(`rbac/roles${ id ? `/${ id }` : '' }`);
  }

  private buildOtpDisableUri(): string {
    return this.buildUri(`otp/disable`);
  }

  private buildOtpEnableConfirmUri(): string {
    return this.buildUri(`otp/enable-confirm`);
  }

  private buildScopesUri(): string {
    return this.buildUri(`rbac/scopes`);
  }

  private buildRolesListUri(queryParams: RolesQueryParams): string {
    return this.buildUri(`rbac/roles${ RolesQueryParams.constructQueryString(queryParams) }`);
  }

  private buildOtpEnableUri(): string {
    return this.buildUri(`otp/enable`);
  }

  private buildAcceptCompanyInviteUri(): string {
    return this.buildUri(`users/company-invites/validate`);
  }

  private buildDescribeCompanyInviteUri(): string {
    return this.buildUri(`users/company-invites/describe`);
  }

  private buildResendCompanyInvite(userId: string): string {
    return this.buildUri(`users/company-invites/resend/${ userId }`);
  }

  private buildResetPasswordUri(): string {
    return this.buildUri(`auth/password-reset/validate`);
  }

  private buildRequestResetPasswordUri(): string {
    return this.buildUri(`auth/password-reset/dispatch`);
  }

  private buildUserSelfUri(): string {
    return this.buildUri(`users/self?include[]=idp_identities`);
  }

  private buildTokenIntrospectUri(): string {
    return this.buildUri(`oauth2/introspect`);
  }

  private buildUserUri(id?: string): string {
    return this.buildUri(`users/${ id || 'self' }`);
  }

  private buildIdentityUri(): string {
    return this.buildUri(`identity`);
  }

  private buildAPIKeysUri(queryParams: APIKeyQueryParams): string {
    return this.buildUri(`personal-access-tokens${ APIKeyQueryParams.constructQueryString(queryParams) }`);
  }

  private buildSecurityGroupsUri(queryParams: SecurityGroupQueryParams): string {
    return this.buildUri(`security-groups${ SecurityGroupQueryParams.constructQueryString(queryParams) }`);
  }

  private buildSecurityGroupUri(id?: string): string {
    return this.buildUri(`security-groups${ id ? ('/' + id) : '' }`);
  }

  private buildSecurityGroupUsersUri(id: string): string {
    return this.buildUri(`security-groups/${ id }/users`);
  }

  private buildAPIKeyUri(id?: string): string {
    return this.buildUri(`personal-access-tokens${ id ? ('/' + id) : '' }`);
  }

  private buildCompanyDomainListUri(queryParams: DomainQueryParams): string {
    return this.buildUri(`companies/self/domains${ DomainQueryParams.constructQueryString(queryParams) }`);
  }

  private buildApprovalsUri(queryParams: ApprovalQueryParams): string {
    return this.buildUri(`approvals${ ApprovalQueryParams.constructQueryString(queryParams) }`);
  }

  private buildCompanyDomainUri(id: string): string {
    return this.buildUri(`companies/self/domains/${ id }`);
  }

  private buildApprovalActionUri(id: string, approvalAction: ApprovalAction): string {
    return this.buildUri(`approvals/${ id }/${ approvalAction }`);
  }

  private buildUserCountsUri(): string {
    return this.buildUri(`users/counts-v2`);
  }

  private buildUsersBulkUri(): string {
    return this.buildUri(`users/bulk`);
  }

  private buildMicrosoftAuthUri(): string {
    return this.buildUri(`oauth2/sso/authorise`);
  }

  private static buildRefreshUrl(): string {
    return `\
${ environment.api.identityBaseUrl }oauth2/token`;
  }

  private connectIgnoreMessages: string[] = [
    'The user account is pending approval by an administrator.',
    'Login is not permitted for the authorisation domain.',
  ];

  setRedirectLocation(url: string): void {
    this._document.location.href = url;
  }

  authorisationRedirect$(req: AuthoriseMicrosoftSSORequest): Observable<AuthoriseMicrosoftSSOResponse> {
    return this.apiService.apiPost$<{ data: AuthorisationRedirectResponseRaw }>(
      this.buildMicrosoftAuthUri(),
      { provider: `MICROSOFT${ req.connectMode ? '_CONNECT' : '' }` },
      { authRequired: !!req.connectMode })
      .pipe(
        map((res: { data: AuthorisationRedirectResponseRaw }): AuthoriseMicrosoftSSOResponse => {
          return {
            error:       null,
            message:     null,
            redirectUri: res.data.redirect_uri,
            cookie:      res.data.session_cookie,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<AuthoriseMicrosoftSSOResponse> => {
          return of({
            error:       new Alert().fromApiError(err),
            redirectUri: null,
            cookie:      null,
          });
        }),
      );
  }

  authorise$(req: Oauth2Payload): Observable<AuthoriseResponse> {
    const isCallrouteSSO = req.client_id === environment.auth.clientId;
    return this.apiService.apiPost$<AuthoriseResponseRaw>(
      this.buildAuthTokenUri(isCallrouteSSO, req.connectMode), {
        code:          req.code,
        state:         req.state,
        redirect_uri:  req.redirect_uri,
        code_verifier: req.code_verifier,
        client_id:     req.client_id,
        grant_type:    req.grant_type,
        referrer:      req.referrer,
      }, {
        authRequired: !!req.connectMode,
      })
      .pipe(
        map((res: AuthoriseResponseRaw): AuthoriseResponse => {
          if (res && !req.connectMode) {
            this.apiService.setAuthTokens(res);
          }

          return {
            error:       null,
            token:       res?.access_token || null,
            connectMode: req.connectMode,
            message:     req.connectMode ? new Alert().fromApiMessage({ message: 'Successfully connected to Microsoft SSO.' }) : null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<AuthoriseResponse> => {
          return of({
            error:       this.connectIgnoreMessages.includes(err.message) ? null : new Alert().fromApiError(err),
            connectMode: req.connectMode,
            token:       null,
          });
        }),
      );
  }

  async hasAuth(): Promise<boolean> {
    const status = ApiService.determineAuthStatus();
    switch (status) {
      case AuthStatus.Validate:
        return true;
      case AuthStatus.Refresh:
        const metadata = await this.store.select(selectTokenIntrospectMetadata)
          .pipe(
            take(1))
          .toPromise();
        const token    = await this.checkAndValidateToken$(metadata?.clientId)
          .pipe(take(1))
          .toPromise();
        if (!token.error) {
          return true;
        }
    }
    return false;
  }

  async hasRegistered(waitForUser: boolean): Promise<boolean> {
    return this.authReducer$
      .pipe(
        pluck('user'),
        filter(user => waitForUser ? !!user : true),
        pluck('companyId'),
        take(1),
        map(companyId => !!companyId))
      .toPromise();
  }

  refreshToken$(clientId: string): Observable<ValidateTokenResponse> {
    const refreshToken = TokenService.getToken(AuthToken.Refresh) as TokenData;

    return this.apiService.apiPost$<ValidateTokenResponseRaw>(AuthService.buildRefreshUrl(), {
      grant_type:    'refresh_token',
      refresh_token: refreshToken.token,
      client_id:     clientId,
    }, { authRequired: false, disableRetry: true })
      .pipe(
        map((res: ValidateTokenResponseRaw): ValidateTokenResponse => {
          // set reference to provided token
          let responseToken = null;

          // Store references to both the access_token and refresh_token
          if (res) {
            this.apiService.setAuthTokens(res, refreshToken.email);
            responseToken = res.access_token;
          }
          const response: ValidateTokenResponse = {
            error: null,
            token: responseToken,
          };
          this.store.dispatch(ManualUpdateTokenData(response));

          return response;
        }),
        catchError((err: HttpErrorResponse): Observable<ValidateTokenResponse> => {
          this.store.dispatch(LogoutAction({}));
          return of({
            error:       new Alert().fromApiError(err),
            token:       null,
            email:       null,
            redirectURL: null,
          });
        }),
      );
  }

  validateToken$(): Observable<ValidateTokenResponse> {
    const accessToken = TokenService.getToken(AuthToken.Access) as TokenData;

    return this.apiService.apiGet$(`${ environment.api.identityBaseUrl }identity`, {
      authRequired: false,
      authToken:    accessToken.token,
    })
      .pipe(
        map((): ValidateTokenResponse => {
          const response: ValidateTokenResponse = {
            error: null,
            token: accessToken.token,
          };
          this.store.dispatch(ManualUpdateTokenData(response));

          return response;
        }),
        catchError((err: HttpErrorResponse): Observable<ValidateTokenResponse> => {
          return of({
            error:       new Alert().fromApiError(err),
            redirectURL: null,
            token:       null,
          });
        }),
      );
  }

  checkAndValidateToken$(clientId: string): Observable<ValidateTokenResponse> {
    const status: AuthStatus = ApiService.determineAuthStatus();
    switch (status) {
      case AuthStatus.Validate:
        return this.validateToken$();
      case AuthStatus.Refresh:
        if (!clientId) {
          return this.store.select(selectTokenIntrospectMetadata)
            .pipe(
              filter(metadata => !!metadata),
              timeout(5_000),
              take(1),
              switchMap((metadata: TokenIntrospectMetadata) => this.refreshToken$(metadata?.clientId)),
              catchError(() => this.noAuthRedirect$()));
        }
        return this.refreshToken$(clientId);
      default:
        return this.noAuthRedirect$();
    }

  }

  private noAuthRedirect$(): Observable<ValidateTokenResponse> {
    this.store.dispatch(LogoutAction({ skipNavigation: true }));
    return of({
      error: null,
      token: null,
      user:  null,
      email: null,
    });
  }

  getCompanyContext$(): Observable<string> {
    return this.store.select(selectCompanyId)
      .pipe(filter(companyId => {
        const viewingCompanyId = splitURLByPath(this.location.path()).queryParams?.company;
        return !viewingCompanyId || companyId === viewingCompanyId;
      }));
  }

  fetchUserProfile$(): Observable<FetchUserProfileResponse> {
    const accessToken: TokenData = TokenService.getToken(AuthToken.Access) as TokenData;
    if (!accessToken) {
      return of({
        error: null,
        user:  null,
      });
    }
    return this.apiService.apiGet$<{ data: FetchUserProfileResponseRaw }>(
      this.buildIdentityUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: FetchUserProfileResponseRaw }): FetchUserProfileResponse => {
          const user = res.data && res.data.user ?
            new User({ scopes: res.data.scopes }).fromApiData(res.data.user, res.data.company) : null;
          // Set partner storage key if user is partner
          if (user?.isPartner) {
            TokenService.setToken(AuthToken.Partner, '1');
          } else {
            TokenService.removeToken(AuthToken.Partner);
          }

          return {
            error: null,
            user,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchUserProfileResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            user:  null,
          });
        }),
      );
  }

  fetchTokenIntrospect$(): Observable<FetchTokenIntrospectResponse> {
    const token = (TokenService.getToken(AuthToken.Access) as TokenData)?.token;
    if (!token) {
      return of({
        data:  null,
        error: null,
      });
    }
    return this.apiService.apiPost$<TokenIntrospectMetadataRaw>(
      this.buildTokenIntrospectUri(),
      { token },
      { authRequired: true, authToken: token })
      .pipe(
        map((res: TokenIntrospectMetadataRaw): FetchTokenIntrospectResponse => {
          return {
            data:  res ? new TokenIntrospectMetadata().fromApiData(res) : null,
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchTokenIntrospectResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }),
      );
  }

  fetchUserSelf$(): Observable<FetchUserProfileResponse> {
    return this.apiService.apiGet$<{ data: UserRaw }>(
      this.buildUserSelfUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: UserRaw }): FetchUserProfileResponse => {
          const user = res.data ?
            new User().fromApiData(res.data) : null;
          return {
            error: null,
            user,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchUserProfileResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            user:  null,
          });
        }),
      );
  }

  fetchAPIKeys$(req: FetchAPIKeysRequest): Observable<FetchAPIKeysResponse> {
    return this.apiKeyListService.fetchListModel$(
      this.buildAPIKeysUri(req.queryParams),
      this.APIKeyFactory,
      this.APIKeySearchParamsFactory,
      { authRequired: true, withUserCompanyContext: true },
    )
      .pipe(
        map((res: ListModelResponse<APIKey, APIKeyQueryParams>): FetchAPIKeysResponse => {
          return {
            data:         res.models,
            searchParams: res.searchParams,
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAPIKeysResponse> => {
          return of({
            data:         null,
            searchParams: null,
            error:        new Alert().fromApiError(err),
          });
        }),
      );
  }

  fetchAPIKey$(req: FetchAPIKeyRequest): Observable<FetchAPIKeyResponse> {
    return this.apiService.apiGet$<{ data: APIKeyRaw }>(
      this.buildAPIKeyUri(req.id),
      { authRequired: true, withUserCompanyContext: true })
      .pipe(
        map((res: { data: APIKeyRaw }): FetchAPIKeyResponse => {
          return {
            data:  new APIKey().fromApiData(res.data),
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAPIKeyResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }));
  }

  createAPIKey$(req: CreateApiKeyRequest): Observable<CreateApiKeyResponse> {
    return this.apiService.apiPost$<{ data: APIKeyRaw }>(this.buildAPIKeyUri(), req.data.toApiData(), {
      authRequired:           true,
      withUserCompanyContext: true,
    })
      .pipe(
        map((res: { data: APIKeyRaw }): CreateApiKeyResponse => {
          return {
            data:  new APIKey().fromApiData(res.data, req.requestId),
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<CreateApiKeyResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }));
  }

  fetchCompanyDomainList$(req: FetchCompanyDomainListRequest): Observable<FetchCompanyDomainListResponse> {
    return this.apiService.apiGet$<{
      data: CompanyDomainRaw[],
      meta: ListResponseMetadata
    }>(this.buildCompanyDomainListUri(req.queryParams), {
      authRequired: true,
    })
      .pipe(
        map((res: { data: CompanyDomainRaw[], meta: ListResponseMetadata }): FetchCompanyDomainListResponse => {
          return {
            data:         res.data.map(d => new CompanyDomain().fromApiData(d)),
            searchParams: new DomainQueryParams().constructParams(res?.meta),
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCompanyDomainListResponse> => {
          return of({
            data:         null,
            error:        new Alert().fromApiError(err),
            searchParams: null,
          });
        }));
  }

  fetchApprovalList$(req: FetchApprovalListRequest): Observable<FetchApprovalListResponse> {
    return this.apiService.apiGet$<{
      data: ApprovalRaw[],
      meta: ListResponseMetadata
    }>(this.buildApprovalsUri(req.queryParams), {
      authRequired: true,
    })
      .pipe(
        map((res: { data: ApprovalRaw[], meta: ListResponseMetadata }): FetchApprovalListResponse => {
          return {
            data:         res.data.map(d => new Approval().fromApiData(d)),
            searchParams: new ApprovalQueryParams().constructParams(res?.meta),
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchApprovalListResponse> => {
          return of({
            data:         null,
            error:        new Alert().fromApiError(err),
            searchParams: null,
          });
        }));
  }

  fetchSecurityGroup$(req: FetchSecurityGroupRequest): Observable<FetchSecurityGroupResponse> {
    return this.apiService.apiGet$<{ data: SecurityGroupRaw }>(
      this.buildSecurityGroupUri(req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: SecurityGroupRaw }): FetchSecurityGroupResponse => {
          return {
            data:  new SecurityGroup().fromApiData(res.data),
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchSecurityGroupResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }));
  }

  fetchSecurityGroupList$(req: FetchSecurityGroupListRequest): Observable<FetchSecurityGroupListResponse> {
    return this.securityGroupListService.fetchListModel$(
      this.buildSecurityGroupsUri(req.queryParams),
      this.securityGroupFactory,
      this.securityGroupQueryParamsFactory,
      { authRequired: true },
    )
      .pipe(
        map((res: ListModelResponse<SecurityGroup, SecurityGroupQueryParams>): FetchSecurityGroupListResponse => {
          return {
            data:        res.models,
            queryParams: res.searchParams,
            error:       null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchSecurityGroupListResponse> => {
          return of({
            data:        null,
            queryParams: null,
            error:       new Alert().fromApiError(err),
          });
        }),
      );
  }

  postSecurityGroup$(req: PostSecurityGroupRequest): Observable<PostSecurityGroupResponse> {
    return this.apiService.apiPost$<{ data: SecurityGroupRaw }>(
      this.buildSecurityGroupUri(),
      new PostSecurityGroupRequest(req).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: SecurityGroupRaw }): PostSecurityGroupResponse => {
          return {
            data:    new SecurityGroup().fromApiData(res.data, req.requestId),
            message: new Alert().fromApiMessage({ message: `Successfully created ${ res.data.name }!` }),
            error:   null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostSecurityGroupResponse> => {
          return of({
            error: new Alert().fromApiError(err, req.formFields),
            data:  null,
          });
        }),
      );
  }

  patchSecurityGroup$(req: PatchSecurityGroupRequest): Observable<PatchSecurityGroupResponse> {
    return this.apiService.apiPatch$<{ data: SecurityGroupRaw }>(
      this.buildSecurityGroupUri(req.id),
      { ...new PatchSecurityGroupRequest(req).toApiData() },
      { authRequired: true })
      .pipe(
        map((res: { data: SecurityGroupRaw }): PatchSecurityGroupResponse => {
          return {
            data:    new SecurityGroup().fromApiData(res.data, req.requestId),
            message: req.name && new Alert().fromApiMessage({ message: `Successfully updated ${ res.data.name }!` }),
            error:   null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchSecurityGroupResponse> => {
          return of({
            error: new Alert().fromApiError(err, req.formFields),
            data:  null,
          });
        }),
      );
  }

  deleteSecurityGroup$(req: DeleteSecurityGroupRequest): Observable<DeleteSecurityGroupResponse> {
    return this.apiService.apiDelete$(
      this.buildSecurityGroupUri(req.id),
      { authRequired: true })
      .pipe(
        map((): DeleteSecurityGroupResponse => {
          return {
            error:        null,
            id:           req.id,
            isLastOnPage: req.isLastOnPage,
            storeAs:      req.storeAs,
            message:      new Alert().fromApiMessage({ message: `Successfully deleted ${ req.name }!` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteSecurityGroupResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            id:           req.id,
            storeAs:      req.storeAs,
            isLastOnPage: null,
          });
        }),
      );
  }

  updateCompanyDomain$(req: UpdateCompanyDomainRequest): Observable<UpdateCompanyDomainResponse> {
    return this.apiService.apiPatch$<{ data: CompanyDomainRaw }>(
      this.buildCompanyDomainUri(req.data.id),
      req.data.toApiData(),
      {
        authRequired: true,
      })
      .pipe(
        map((res: { data: CompanyDomainRaw }): UpdateCompanyDomainResponse => {
          return {
            data:    new CompanyDomain().fromApiData(res.data),
            error:   null,
            message: new Alert().fromApiMessage({ message: `Successfully ${ res.data.is_login_enabled ? 'enabled' : 'disabled' } ${ res.data.domain } for SSO.` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<UpdateCompanyDomainResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }));
  }

  userApproval$(req: UserApprovalRequest): Observable<UserApprovalResponse> {
    return this.apiService.apiPost$<{ data: ApprovalRaw }>(
      this.buildApprovalActionUri(req.id, req.action),
      {
        ...{
          role_ids: req.action === ApprovalAction.Approve && req.roleIds || undefined,
          message:  req.message,
        },
      },
      {
        authRequired: true,
      })
      .pipe(
        map((res: { data: ApprovalRaw }): UserApprovalResponse => {
          return {
            data:    new Approval().fromApiData(res.data),
            error:   null,
            message: new Alert().fromApiMessage({ message: `Successfully ${ req.action === ApprovalAction.Approve ? 'approved' : 'denied' } ${ req.email }.` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<UserApprovalResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }));
  }

  fetchUserCounts$(): Observable<FetchUserCountsResponse> {
    return this.apiService.apiGet$<{ data: UserCounts }>(
      this.buildUserCountsUri(),
      {
        authRequired: true,
      })
      .pipe(map((res: { data: UserCounts }): FetchUserCountsResponse => {
          return {
            data:  res.data,
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchUserCountsResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }));
  }

  usersBulk$(req: UsersBulkRequest): Observable<UsersBulkResponse> {
    return this.apiService.apiPatch$(
      this.buildUsersBulkUri(),
      {
        is_local_login: req.isLocalLogin,
      },
      {
        authRequired: true,
      })
      .pipe(
        map((): UsersBulkResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: `Successfully updated all users sign-in preferences.` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<UsersBulkResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }));
  }

  patchAPIKey$(req: PatchApiKeyRequest): Observable<PatchApiKeyResponse> {
    return this.apiService.apiPatch$<{ data: APIKeyRaw }>(this.buildAPIKeyUri(req.data.id),
      { ...req.data.toApiData() },
      { authRequired: true, withUserCompanyContext: true })
      .pipe(
        map((res: { data: APIKeyRaw }): PatchApiKeyResponse => {
          return {
            data:    new APIKey().fromApiData(res.data, req.requestId),
            error:   null,
            message: new Alert().fromApiMessage({ message: `Successfully ${ req.data.isActive ? 'activated' : 'deactivated' } API key!` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchApiKeyResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }));
  }

  patchUserProfile$(req: PatchUserProfileRequest): Observable<PatchUserProfileResponse> {
    return this.apiService.apiPatch$<{ data: UserRaw }>(
      this.buildUserUri(!req.isSelf ? req.id : null),
      new PatchUserProfileRequest(req).toApiData(),
      { authRequired: true, withUserCompanyContext: req.isSelf })
      .pipe(
        map((res: { data: UserRaw }): PatchUserProfileResponse => {
          return {
            error:                        null,
            user:                         new User().fromApiData(res.data, null),
            checkTokenAfterPatchRedirect: req.checkTokenAfterPatchRedirect,
            message:                      new Alert().fromApiMessage({ message: 'Successfully updated user.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchUserProfileResponse> => {
          return of({
            error:                        new Alert().fromApiError(err),
            user:                         null,
            checkTokenAfterPatchRedirect: null,
          });
        }),
      );
  }

  requestResetPassword$(req: RequestResetPasswordRequest): Observable<RequestResetPasswordResponse> {
    return this.apiService.apiPost$(
      this.buildRequestResetPasswordUri(),
      req,
      { authRequired: false })
      .pipe(
        map((): RequestResetPasswordResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage(
              { message: 'An email has been sent to your account, please follow the instructions in this email to reset your password.' },
            ),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<RequestResetPasswordResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
          });
        }),
      );
  }

  resetPassword$(req: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    return this.apiService.apiPost$(
      this.buildResetPasswordUri(),
      req,
      { authRequired: false })
      .pipe(
        map((): ResetPasswordResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: 'Password reset successfully.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<ResetPasswordResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
          });
        }),
      );
  }

  fetchCompanyInviteDescription$(req: FetchCompanyInviteDescriptionRequest): Observable<FetchCompanyInviteDescriptionResponse> {
    return this.apiService.apiPost$<{ data: AccountInvitationRaw }>(
      this.buildDescribeCompanyInviteUri(),
      req,
      { authRequired: false })
      .pipe(
        map((res: { data: AccountInvitationRaw }): FetchCompanyInviteDescriptionResponse => {
          return {
            error:      null,
            invitation: res.data ? AccountInvitation.fromApiData(res.data) : null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCompanyInviteDescriptionResponse> => {
          return of({
            error:      new Alert().fromApiError(err),
            invitation: null,
          });
        }),
      );
  }

  acceptCompanyInvite$(req: AcceptCompanyInviteRequest): Observable<AcceptCompanyInviteResponse> {
    return this.apiService.apiPost$(
      this.buildAcceptCompanyInviteUri(),
      req,
      { authRequired: false })
      .pipe(
        map((): AcceptCompanyInviteResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: 'You have accepted the invitation to join this company.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<AcceptCompanyInviteResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
          });
        }),
      );
  }

  resendUserInvite$(req: ResendUserInviteRequest): Observable<ResendUserInviteResponse> {
    return this.apiService.apiPost$(
      this.buildResendCompanyInvite(req.userId),
      req,
      { authRequired: false })
      .pipe(
        map((): ResendUserInviteResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: 'Successfully re-sent user invite.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<ResendUserInviteResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
          });
        }),
      );
  }

  enableOTP$(req: EnableOTPRequest): Observable<EnableOTPResponse> {
    return this.apiService.apiPost$<EnableOtpResponseRaw>(
      this.buildOtpEnableUri(),
      req,
      { authRequired: true })
      .pipe(
        map((res: EnableOtpResponseRaw): EnableOTPResponse => {
          return {
            error:     null,
            otpSecret: res.data.otp_secret,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<EnableOTPResponse> => {
          return of({
            error:     new Alert().fromApiError(err),
            message:   null,
            otpSecret: null,
          });
        }));
  }

  fetchRoles$(req: FetchRolesRequest): Observable<FetchRolesResponse> {
    return this.roleListService.fetchListModel$(
      this.buildRolesListUri(req.queryParams),
      this.roleFactory,
      this.rolesSearchParamsFactory,
    )
      .pipe(
        switchMap((res: FetchRolesResponse) => {
          const roleIds = res.models?.map(m => m.id) || [];
          return zip(...roleIds.map(id => this.fetchRoleAssignmentCount$({ id })))
            .pipe(
              defaultIfEmpty([]),
              map((counts: FetchRoleAssignmentCountResponse[]): FetchRolesResponse => {
                return {
                  ...res,
                  models: (res.models?.map((m: Role) => {
                    const recordCount: number = counts?.find(c => c.id === m.id)?.recordCount || 0;
                    return {
                      ...m,
                      recordCount,
                    } as Role;
                  }) || []) as Role[],
                } as FetchRolesResponse;
              }));
        }));
  }

  fetchScopes$(): Observable<FetchScopesResponse> {
    return this.apiService.apiGet$<{ data: Scope[] }>(
      this.buildScopesUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: Scope[] }): FetchScopesResponse => {
          return {
            error: null,
            data:  res.data,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchScopesResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  enableOTPConfirm$(req: EnableOTPConfirmRequest): Observable<EnableOTPConfirmResponse> {
    return this.apiService.apiPost$<EnableOtpConfirmResponseRaw>(
      this.buildOtpEnableConfirmUri(),
      { otp_code: req.otp_code },
      { authRequired: true })
      .pipe(
        map((response: EnableOtpConfirmResponseRaw): EnableOTPConfirmResponse => {
          return {
            error:      null,
            otpScratch: response.data.otp_scratch,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<EnableOTPConfirmResponse> => {
          return of({
            error:      new Alert().fromApiError(err),
            otpScratch: null,
            message:    null,
          });
        }));
  }

  assignUserRole$(req: AssignUserRoleRequest): Observable<AssignUserRoleResponse> {
    return this.apiService.apiPatch$<AssignUsersResponseRaw>(
      this.buildRoleUsersUri(req.id),
      { assignments: req.assignments, unassignments: req.unassignments },
      { authRequired: true })
      .pipe(
        map((res: AssignUsersResponseRaw): AssignUserRoleResponse => {
          return {
            error:     null,
            message:   new Alert().fromApiMessage({ message: `Successfully assigned users to ${ req.name }.` }),
            data:      {
              assignments:   res.data?.assignments.map(a => ({
                userId:  a.user_id,
                message: a.message,
                success: a.success,
              })) || [],
              unassignments: res.data?.unassignments.map(a => ({
                userId:  a.user_id,
                message: a.message,
                success: a.success,
              })) || [],
            },
            meta:      {
              successCount: res.meta?.success_count,
              failureCount: res.meta?.failure_count,
              totalCount:   res.meta?.total_count,
            },
            requestId: req.requestId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<AssignUserRoleResponse> => {
          return of({
            error: new Alert().fromApiError(err, null, true, req.requestId),
            data:  null,
            meta:  null,
          });
        }),
      );
  }

  assignUserSecurityGroup$(req: AssignUserSecurityGroupRequest): Observable<AssignUserSecurityGroupResponse> {
    return this.apiService.apiPatch$<AssignUsersResponseRaw>(
      this.buildSecurityGroupUsersUri(req.id),
      { assignments: req.assignments, unassignments: req.unassignments },
      { authRequired: true })
      .pipe(
        map((res: AssignUsersResponseRaw): AssignUserSecurityGroupResponse => {
          return {
            error:     null,
            message:   new Alert().fromApiMessage({ message: `Successfully assigned users to ${ req.name }.` }),
            data:      {
              assignments:   res.data?.assignments.map(a => ({
                userId:  a.user_id,
                message: a.message,
                success: a.success,
              })) || [],
              unassignments: res.data?.unassignments.map(a => ({
                userId:  a.user_id,
                message: a.message,
                success: a.success,
              })) || [],
            },
            meta:      {
              successCount: res.meta?.success_count,
              failureCount: res.meta?.failure_count,
              totalCount:   res.meta?.total_count,
            },
            requestId: req.requestId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<AssignUserSecurityGroupResponse> => {
          return of({
            error: new Alert().fromApiError(err, null, true, req.requestId),
            data:  null,
            meta:  null,
          });
        }),
      );
  }

  disableOTP$(): Observable<DisableOTPResponse> {
    return this.apiService.apiPost$(
      this.buildOtpDisableUri(),
      {},
      { authRequired: true })
      .pipe(
        map((): DisableOTPResponse => {
          return {
            error:     null,
            cancelled: false,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DisableOTPResponse> => {
          return of({
            error:     new Alert().fromApiError(err),
            cancelled: false,
            message:   null,
          });
        }),
      );
  }

  fetchRoleAssignmentCount$(req: FetchRoleAssignmentCountRequest): Observable<FetchRoleAssignmentCountResponse> {
    return this.apiService.apiGet$<{ meta: { record_count: number } }>(
      this.buildRoleUsersUri(req.id),
      { authRequired: true })
      .pipe(
        map((res: { meta: { record_count: number } }): FetchRoleAssignmentCountResponse => {
          return {
            error:       null,
            id:          req.id,
            recordCount: res.meta.record_count,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchRoleAssignmentCountResponse> => {
          return of({
            error:       new Alert().fromApiError(err),
            id:          req.id,
            recordCount: 0,
          });
        }),
      );
  }

  createRole$(req: CreateRoleRequest): Observable<CreateRoleResponse> {
    return this.apiService.apiPost$<{ data: RoleRaw }>(
      this.buildRoleUri(),
      req.data,
      { authRequired: true })
      .pipe(
        map((res: { data: RoleRaw }): CreateRoleResponse => {

          return {
            error:   null,
            data:    new Role().fromApiData(res.data),
            message: new Alert().fromApiMessage({ message: `Successfully updated ${ res.data.name }!` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<CreateRoleResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  putRole$(req: PutRoleRequest): Observable<PutRoleResponse> {
    return this.apiService.apiPut$<{ data: RoleRaw }>(
      this.buildRoleUri(req.data.id),
      req.data,
      { authRequired: true })
      .pipe(
        map((res: { data: RoleRaw }): PutRoleResponse => {
          return {
            error:   null,
            data:    new Role().fromApiData(res.data),
            message: new Alert().fromApiMessage({ message: `Successfully updated ${ res.data.name }!` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PutRoleResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  deleteRole$(req: DeleteRoleRequest): Observable<DeleteRoleResponse> {
    return this.apiService.apiDelete$(
      this.buildRoleUri(req.id),
      { authRequired: true })
      .pipe(
        map((): DeleteRoleResponse => {
          return {
            error:   null,
            id:      req.id,
            message: new Alert().fromApiMessage({ message: `Successfully deleted ${ req.name }!` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteRoleResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            id:    req.id,
          });
        }),
      );
  }

  deleteAPIKey$(req: DeleteApiKeyRequest): Observable<DeleteApiKeyResponse> {
    return this.apiService.apiDelete$<{ data: APIKeyRaw }>(
      this.buildAPIKeyUri(req.id),
      { authRequired: true, withUserCompanyContext: true })
      .pipe(
        map((res: { data: APIKeyRaw }): DeleteApiKeyResponse => {
          return {
            error:   null,
            id:      req.id,
            storeAs: req.storeAs,
            data:    new APIKey().fromApiData(res.data),
            message: new Alert().fromApiMessage({ message: 'Successfully deleted API key!' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteApiKeyResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            data:    null,
            id:      req.id,
            storeAs: req.storeAs,
          });
        }),
      );
  }

  async logout(skipNavigation: boolean): Promise<boolean> {
    TokenService.removeAllTokens('redirectUrl');
    this.dialog.closeAll();
    if (skipNavigation) {
      return;
    }
    const email = splitURLByPath(this.location.path()).queryParams?.email;
    return this.router.navigate(['/logout'],
      { company: null, email }, { queryParamsHandling: 'merge' });
  }

}
