export default {
  'cr-plus':                        'add-outline.svg',
  'cr-visibility-off':              'visibility_off.svg',
  'cr-sip-phone':                   'dialer_sip.svg',
  'cr-mark-read':                   'mark_email_read.svg',
  'cr-copy':                        'copy.svg',
  'cr-business':                    'business.svg',
  'cr-people':                      'people.svg',
  'cr-list-timeline':               'list-timeline.svg',
  'cr-upload':                      'upload.svg',
  'cr-list':                        'list.svg',
  'cr-recycle':                     'recycle.svg',
  'cr-user-lock':                   'user-lock.svg',
  'cr-eye':                         'eye.svg',
  'cr-microphone':                  'microphone.svg',
  'cr-user-clock':                  'user-clock.svg',
  'cr-dash':                        'dash.svg',
  'cr-code':                        'code.svg',
  'cr-globe':                       'globe.svg',
  'cr-circle-outline':              'circle-outline.svg',
  'cr-alarm-exclamation':           'alarm-exclamation.svg',
  'cr-octagon-exclamation':         'octagon-exclamation.svg',
  'cr-shield-x':                    'shield-x.svg',
  'cr-qr-code':                     'qr_code.svg',
  'cr-bars':                        'bars.svg',
  'cr-microsoft-teams-two-tone':    'microsoft-teams-two-tone.svg',
  'cr-circle-close':                'circle-close.svg',
  'cr-developer':                   'curly-brackets.svg',
  'cr-arrow-progress':              'arrow-progress.svg',
  'cr-arrow-to-dot':                'arrow-to-dot.svg',
  'cr-conference':                  'conference.svg',
  'cr-license':                     'license.svg',
  'cr-arrows-repeat':               'arrows-repeat.svg',
  'cr-circle-phone':                'circle-phone.svg',
  'cr-team':                        'team.svg',
  'cr-wrench':                      'wrench.svg',
  'cr-mobile':                      'mobile.svg',
  'cr-ticket':                      'ticket.svg',
  'cr-ban':                         'ban.svg',
  'cr-move':                        'move.svg',
  'cr-lambda':                      'lambda.svg',
  'cr-warning-no-color':            'warning-no-color.svg',
  'cr-satellite':                   'satellite.svg',
  'cr-file-export':                 'file-export.svg',
  'cr-file-import':                 'file-import.svg',
  'cr-file-edit':                   'file-edit.svg',
  'cr-message-pen':                 'message-pen.svg',
  'cr-calendar-clock':              'calendar-clock.svg',
  'cr-calendar-star':               'calendar-star.svg',
  'cr-router':                      'router.svg',
  'cr-id-card':                     'id-card.svg',
  'cr-downloading':                 'downloading.svg',
  'cr-play':                        'play.svg',
  'cr-toggle-on':                   'toggle-on.svg',
  'cr-up-down-arrow':               'up-down-arrow.svg',
  'cr-toggle-off':                  'toggle-off.svg',
  'cr-location-dot':                'location-dot.svg',
  'cr-file-lines':                  'file-lines.svg',
  'cr-money-check-dollar':          'money-check-dollar.svg',
  'cr-receipt':                     'receipt.svg',
  'cr-clock':                       'clock.svg',
  'cr-towercell':                   'towercell.svg',
  'cr-heart-warning':               'heart-warning.svg',
  'cr-circle-exclamation':          'circle-exclamation.svg',
  'cr-membership':                  'membership.svg',
  'cr-bolt':                        'bolt.svg',
  'cr-security':                    'security.svg',
  'cr-asterisk':                    'asterisk.svg',
  'cr-spinner':                     'spinner.svg',
  'cr-line-chart':                  'line-chart.svg',
  'cr-person-add':                  'person_add.svg',
  'cr-verified-user':               'verified_user.svg',
  'cr-school':                      'school.svg',
  'cr-group':                       'group.svg',
  'cr-search':                      'search.svg',
  'cr-add-call':                    'add-call.svg',
  'cr-calendar':                    'calendar.svg',
  'cr-features':                    'features.svg',
  'cr-webex-color':                 'webex.png',
  'cr-view':                        'view.svg',
  'cr-label':                       'label.svg',
  'cr-id-badge':                    'id-badge.svg',
  'cr-search-icon':                 'search-icon.svg',
  'cr-happy':                       'happy.svg',
  'cr-unhappy':                     'unhappy.svg',
  'cr-neutral':                     'neutral.svg',
  'cr-call-forwarding':             'phone_forwarded.svg',
  'cr-user-group':                  'user-group.svg',
  'cr-contact-phone':               'contact-phone.svg',
  'cr-settings-voice':              'settings-voice.svg',
  'cr-route':                       'route.svg',
  'cr-queue':                       'queue.svg',
  'cr-device-hub':                  'device-hub.svg',
  'cr-location':                    'location.svg',
  'cr-lock':                        'lock.svg',
  'cr-lock-open':                   'lock_open.svg',
  'cr-shield-keyhole':              'shield-keyhole.svg',
  'cr-tune':                        'tune.svg',
  'cr-agent':                       'agent.svg',
  'cr-fax':                         'fax.svg',
  'cr-phone-settings':              'phone-settings.svg',
  'cr-settings-suggest':            'settings-suggest.svg',
  'cr-exclamation':                 'exclamation.svg',
  'cr-account-circle-filled':       'account-circle-filled.svg',
  'cr-notifications':               'notifications.svg',
  'cr-money':                       'money.svg',
  'cr-time':                        'time.svg',
  'cr-stopwatch':                   'stopwatch.svg',
  'cr-bin':                         'trash.svg',
  'cr-timer':                       'timer.svg',
  'cr-list-check':                  'list-check.svg',
  'cr-cancel-filled':               'cancel-filled.svg',
  'cr-health-filled':               'medkit.svg',
  'cr-checkmark':                   'checkmark-outline.svg',
  'cr-minus':                       'minus.svg',
  'cr-admin':                       'admin-settings.svg',
  'cr-overview':                    'overview.svg',
  'cr-logout':                      'log-out-outline.svg',
  'cr-heart-pulse':                 'heart-pulse.svg',
  'cr-settings':                    'settings-filled.svg',
  'cr-numbers':                     'numbers.svg',
  'cr-phone':                       'phone.svg',
  'cr-binocular':                   'binocular.svg',
  'cr-transact':                    'transact.svg',
  'cr-service':                     'service.svg',
  'cr-arrows-left-right':           'arrows-left-right.svg',
  'cr-bell-exclamation':            'bell-exclamation.svg',
  'cr-reporting':                   'reporting.svg',
  'cr-billing':                     'billing.svg',
  'cr-albums':                      'albums.svg',
  'cr-bell':                        'notification-bell.svg',
  'cr-cart':                        'cart-outline.svg',
  'cr-info-circle-mat':             'info-circle-mat.svg',
  'cr-arrow-back':                  'arrow-back-outline.svg',
  'cr-arrow-forward':               'arrow-forward.svg',
  'warning-white.svg':              'warning-white.svg',
  'warning.svg':                    'warning.svg',
  'cr-warning':                     'warning.svg',
  'cr-circle':                      'circle.svg',
  'cr-arrow-down':                  'arrow-down.svg',
  'cr-supervised-user-circle':      'supervised-user-circle.svg',
  'cr-supervised-user-circle-fill': 'supervised-user-circle-fill.svg',
  'cr-circle-up':                   'circle-up.svg',
  'cr-circle-down':                 'circle-down.svg',
  'cr-chevron-down':                'chevron-down-outline.svg',
  'cr-chevron-right':               'chevron-right.svg',
  'cr-chevron-up':                  'chevron-up-outline.svg',
  'cr-tower-cell':                  'tower-cell.svg',
  'cr-close':                       'close.svg',
  'cr-chevron-back':                'chevron-back-outline.svg',
  'cr-chevron-forward':             'chevron-forward-outline.svg',
  'cr-person-rounded':              'person-rounded.svg',
  'cr-webex-calling':               'webex-calling.svg',
  'cr-microsoft-teams':             'microsoft-teams.svg',
  'cr-email-unread':                'mark-email-unread.svg',
  'cr-email':                       'markunread.svg',
  'cr-account-circle':              'account-circle-svg',
  'cr-tick':                        'tick.svg',
  'cr-tick-circle':                 'tick-circle.svg',
  'cr-filter-sort':                 'filter-sort.svg',
  'cr-filter-list':                 'filter-list.svg',
  'cr-running-errors':              'running-errors.svg',
  'cr-incomplete':                  'incomplete.svg',
  'cr-edit':                        'edit.svg',
  'cr-authenticator':               'authenticator.svg',
  'cr-open-new':                    'open-new.svg',
  'cr-account-balance':             'account-balance.svg',
  'cr-info-outline':                'info-outline.svg',
  'cr-sync':                        'sync.svg',
  'cr-health':                      'medkit-outline.svg',
  'cr-ms-teams-two-tone':           'microsoft-teams-two-tone.svg',
  'cr-ellipse-outline':             'ellipse-outline.svg',
  'cr-download':                    'download.svg',
  'cr-help-circle':                 'help-circle.svg',
  'cr-help-outline':                'help_outline.svg',
  'cr-pstn':                        'pstn.svg',
  'cr-home':                        'home.svg',
  'cr-customers':                   'customers.svg',
  'cr-admin-filled':                'admin-filled.svg',
  'cr-quote':                       'quote.svg',
  'cr-bank':                        'bank.svg',
  'cr-analytics':                   'analytics.svg',
  'cr-phone-talk':                  'phone-talk.svg',
  'cr-call':                        'call.svg',
  'cr-timelapse':                   'timelapse.svg',
  'cr-hourglass':                   'hourglass.svg',
  'cr-dialpad':                     'dialpad.svg',
  'cr-phone-outbound':              'phone-outbound.svg',
  'cr-phone-inbound':               'phone-inbound.svg',
  'cr-phone-missed':                'phone-missed.svg',
  'cr-play-arrow':                  'play-arrow.svg',
  'cr-upgrade':                     'upgrade.svg',
  'cr-price-tag':                   'price-tag.svg',
  'cr-info-circle':                 'info-circle.svg',
  'cr-arrow-rotate-right':          'arrow-rotate-right.svg',
  'cr-shopping-basket':             'shopping-basket.svg',
  'cr-dns':                         'dns.svg',
  'cr-info':                        'info.svg',
  'cr-person':                      'person.svg',
  'cr-checkmark-done':              'checkmark-done.svg',
  'cr-pause':                       'pause.svg',
  'cr-key-outline':                 'key-outline.svg',
  'cr-volume':                      'volume.svg',
  'cr-volume-mute':                 'volume-mute.svg',
};
