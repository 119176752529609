import { AssignServiceUserNumberRequestRaw } from '@models/api/assign-service-user-number-request-raw.model';
import { ServiceUserConfigureAction }        from '@enums/service-user-configure-action.enum';

export class AssignServiceUserNumberRequest {
  serviceId: string;
  userId: string;
  numberId: string;
  profileId: string;
  cli: string;
  extension: string;
  assignInbound: boolean;
  isNonCallrouteVoiceProfile: boolean;
  emergencyLocationId: string;
  hasBYONFeature: boolean;

  constructor(private data?: AssignServiceUserNumberRequest) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): AssignServiceUserNumberRequestRaw {
    return {
      users: [
        {
          service_user_id: this.userId,
          number_id:       this.numberId,
          profile_id:      this.profileId,
          cli:             this.cli,
          extension:       this.extension,
          location_id:     this.emergencyLocationId || null,
          actions:         {
            inbound: this.getInboundAction(),
            voice:   this.getVoiceAction(),
            profile: this.getProfileAction(),
          },
        },
      ],
    };
  }

  getInboundAction?(): ServiceUserConfigureAction {
    return this.assignInbound ? ServiceUserConfigureAction.Set : ServiceUserConfigureAction.Skip;
  }

  getVoiceAction?(): ServiceUserConfigureAction {
    if ((this.isNonCallrouteVoiceProfile && !this.hasBYONFeature) || (this.cli && !this.numberId)) {
      return ServiceUserConfigureAction.Skip;
    }
    if (!this.cli || !this.numberId) {
      return ServiceUserConfigureAction.Unset;
    }
    return ServiceUserConfigureAction.Set;
  }

  getProfileAction?(): ServiceUserConfigureAction {
    return ServiceUserConfigureAction.Set;
  }
}
