// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from '@models/environment.model';

export const environment: Environment = {
  production: false,
  api:        {
    identityBaseUrl:      'https://identity.staging.callroute.io/',
    numberBaseUrl:        'https://number.app.staging.callroute.io/',
    reportBaseUrl:        'https://reporting.app.staging.callroute.io/',
    serviceBaseUrl:       'https://service.app.staging.callroute.io/',
    accessBrokerUrl:      'https://access-broker.app.staging.callroute.io/',
    microsoftTeamsUrl:    'https://microsoft-teams.app.staging.callroute.io/',
    accountingBaseUrl:    'https://accounting.app.staging.callroute.io/',
    notificationBaseUrl:  'https://notification.app.staging.callroute.io/',
    configurationBaseUrl: 'https://configuration.app.staging.callroute.io/',
    auditBaseUrl:         'https://audit.app.staging.callroute.io',
  },
  auth:       {
    domain:           'staging.callroute.io',
    clientId:         'callroute-spa',
    authorisationUrl: 'https://identity.staging.callroute.io/oauth2/authorise',
    redirectUri:      'https://staging.callroute.io/oauth2/callback',
  },
  assetsUrl:  'https://cr-web-assets.s3.eu-west-2.amazonaws.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
