import { TeamGroupRaw } from '@models/api/microsoft-teams/teams-group-raw.model';
import { Team }          from '@models/entity/team.model';

export class TeamGroup {
  id: string;
  name: string;
  description: string;
  updatedDate: string;
  createdDate: string;
  teamIds: string[];
  teams: Team[];
  requestId?: string;

  constructor(private data?: TeamGroup) {
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  fromApiData?(data: TeamGroupRaw & { requestId?: string }): TeamGroup {
    this.id          = data.id;
    this.name        = data.name;
    this.description = data.description;
    this.updatedDate = data.updated_date;
    this.createdDate = data.created_date;
    this.teamIds     = data.team_ids;
    this.requestId   = data.requestId;

    return this;
  }
}
