export enum TaskSchema {
  AdGroupSync                    = 'AdGroupSynchronise',
  CallQueueGroupAssignment       = 'CallQueueGroupAssign',
  CallQueueGroupUpdated          = 'CallQueueGroupUpdated',
  CallQueueSync                  = 'CallQueueSynchronise',
  CdrExport                      = 'CdrExport',
  CompanyCreate                  = 'CompanyCreate',
  CompanyDelete                  = 'CompanyDelete',
  DomainSync                     = 'DomainSynchronise',
  LicenseGroupAssignment         = 'LicenseGroupAssign',
  LicenseGroupUpdated            = 'LicenseGroupUpdated',
  LicenseSync                    = 'LicenseSynchronise',
  MicrosoftAssetSync             = 'MicrosoftAssetSynchronise',
  NumberExport                   = 'NumberExport',
  NumberImport                   = 'NumberImport',
  NumberSync                     = 'NumberSynchronise',
  NumberUpsert                   = 'NumberUpsert',
  PolicySync                     = 'PolicySynchronise',
  ProvisionMicrosoftTeamsService = 'ProvisionMicrosoftTeamsService',
  ServiceUserExport              = 'ServiceUserExport',
  TeamGroupAssignment            = 'TeamGroupAssign',
  TeamGroupUpdated               = 'TeamGroupUpdated',
  TeamSync                       = 'TeamSynchronise',
  TenantConnect                  = 'TenantConnect',
  UpdateBySearchToken            = 'UpdateBySearchToken',
  UserConfigure                  = 'UserConfigure',
  UserSync                       = 'UserSynchronise',
}
