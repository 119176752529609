import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class APIKeyQueryParams extends ListParams implements ListParamModel<APIKeyQueryParams> {
  search?: string;
  include?: string[];

  static constructQueryString(searchParams: APIKeyQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      search:  'filter[search]',
      include: 'include[]',
    });
  }

  static queryChanged(newQuery: APIKeyQueryParams, currentQuery: APIKeyQueryParams): boolean {

    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery) ||
      newQuery.search !== currentQuery.search;

  }

  constructParams?(metaData: ListResponseMetadata): APIKeyQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter?.search) {
      this.search = metaData.filter.search;
    }
    if (metaData.include) {
      this.include = metaData.include;
    }

    return this;
  }
}
