import { FetchFrequentCallsRequest } from '@models/api/fetch-frequent-calls-request.model';
import { createAction, props }       from '@ngrx/store';
import { DateRange }                 from 'src/app/models/form/date-range.model';

import {
  CLEAR_CALL_LOG_PARAMS,
  DELETE_REPORT_REQUEST,
  DELETE_REPORT_RESPONSE,
  EXPORT_CDR_TO_CSV_REQUEST,
  EXPORT_CDR_TO_CSV_RESPONSE,
  FETCH_ASSIGNED_NUMBERS_BY_COUNTRY_REQUEST,
  FETCH_ASSIGNED_NUMBERS_BY_COUNTRY_RESPONSE,
  FETCH_ASSIGNED_NUMBERS_BY_LOCATION_REQUEST,
  FETCH_ASSIGNED_NUMBERS_BY_LOCATION_RESPONSE,
  FETCH_ASSIGNED_NUMBERS_BY_RANGE_REQUEST,
  FETCH_ASSIGNED_NUMBERS_BY_RANGE_RESPONSE,
  FETCH_ASSIGNED_NUMBERS_BY_TAG_REQUEST,
  FETCH_ASSIGNED_NUMBERS_BY_TAG_RESPONSE,
  FETCH_ASSIGNED_NUMBERS_TOTAL_REQUEST,
  FETCH_ASSIGNED_NUMBERS_TOTAL_RESPONSE,
  FETCH_CALL_COST_BY_DIRECTION_REQUEST,
  FETCH_CALL_COST_BY_DIRECTION_RESPONSE,
  FETCH_CALL_COST_SUMMARY_BY_BAND_REQUEST,
  FETCH_CALL_COST_SUMMARY_BY_BAND_RESPONSE,
  FETCH_CALL_LOG_RECORDING_REQUEST,
  FETCH_CALL_LOG_RECORDING_RESPONSE,
  FETCH_CALL_LOG_REQUEST,
  FETCH_CALL_LOG_RESPONSE,
  FETCH_CALL_LOG_SUMMARY_REQUEST,
  FETCH_CALL_LOG_SUMMARY_RESPONSE,
  FETCH_CALL_MINUTES_BY_DIRECTION_REQUEST,
  FETCH_CALL_MINUTES_BY_DIRECTION_RESPONSE,
  FETCH_CALL_RECORDING_TRANSCRIPT_REQUEST,
  FETCH_CALL_RECORDING_TRANSCRIPT_RESPONSE,
  FETCH_CALLS_BY_SIP_CODE_REQUEST,
  FETCH_CALLS_BY_SIP_CODE_RESPONSE,
  FETCH_CALLS_BY_SIP_TYPE_REQUEST,
  FETCH_CALLS_BY_SIP_TYPE_RESPONSE,
  FETCH_CHANNEL_USAGE_REQUEST,
  FETCH_CHANNEL_USAGE_RESPONSE,
  FETCH_COUNT_BY_CAPABILITY_REQUEST,
  FETCH_COUNT_BY_CAPABILITY_RESPONSE,
  FETCH_DESTINATION_SUMMARY_REQUEST,
  FETCH_DESTINATION_SUMMARY_RESPONSE,
  FETCH_EXPENSIVE_CALLS_REQUEST,
  FETCH_EXPENSIVE_CALLS_RESPONSE,
  FETCH_FREQUENT_CALLS_REQUEST,
  FETCH_FREQUENT_CALLS_RESPONSE,
  FETCH_LOCATION_COST_REQUEST,
  FETCH_LOCATION_COST_RESPONSE,
  FETCH_RANGE_EXHAUSTION_REQUEST,
  FETCH_RANGE_EXHAUSTION_RESPONSE,
  FETCH_RANGE_USAGE_TOP_REQUEST,
  FETCH_RANGE_USAGE_TOP_RESPONSE,
  FETCH_REPORT_LIST_REQUEST,
  FETCH_REPORT_LIST_RESPONSE,
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_RESPONSE,
  FETCH_REPORTING_OVERVIEW_REQUEST,
  FETCH_REPORTING_OVERVIEW_RESPONSE,
  FETCH_SIP_RESPONSE_TYPES_REQUEST,
  FETCH_SIP_RESPONSE_TYPES_RESPONSE,
  FETCH_SUMMARY_BY_COUNTRY_REQUEST,
  FETCH_SUMMARY_BY_COUNTRY_RESPONSE,
  FETCH_SUMMARY_BY_DIRECTION_REQUEST,
  FETCH_SUMMARY_BY_DIRECTION_RESPONSE,
  FETCH_TOTAL_NUMBERS_PER_COUNTRY_REQUEST,
  FETCH_TOTAL_NUMBERS_PER_COUNTRY_RESPONSE,
  FETCH_TOTAL_NUMBERS_PER_TYPE_REQUEST,
  FETCH_TOTAL_NUMBERS_PER_TYPE_RESPONSE,
  FETCH_USAGE_BY_DIRECTION_REQUEST,
  FETCH_USAGE_BY_DIRECTION_RESPONSE,
  SET_DATE_RANGE,
}                                               from './report.types';
import { FetchLocationCostRequest }             from '@models/api/fetch-location-cost-request.model';
import { FetchCallLogRequest }                  from '@models/api/fetch-call-log-request.model';
import { FetchCallLogRecordingResponse }        from '@models/api/fetch-call-log-recording-response.model';
import { FetchCallLogRecordingRequest }         from '@models/api/fetch-call-log-recording-request.model';
import { FetchExpensiveCallsResponse }          from '@models/api/fetch-expensive-calls-response.model';
import { FetchFrequentCallsResponse }           from '@models/api/fetch-frequent-calls-response.model';
import { FetchUsageByDirectionResponse }        from '@models/api/fetch-usage-by-direction-response.model';
import { FetchSummaryByDirectionRequest }       from '@models/api/fetch-summary-by-direction-request.model';
import { FetchUsageByDirectionRequest }         from '@models/api/fetch-usage-by-direction-request.model';
import { FetchCallLogSummaryRequest }           from '@models/api/fetch-call-log-summary-request.model';
import { FetchCountByCapabilityResponse }       from '@models/api/fetch-count-by-capability-response.model';
import { FetchSummaryByCountryRequest }         from '@models/api/fetch-summary-by-country-request.model';
import { FetchDestinationsSummaryRequest }      from '@models/api/fetch-destinations-summary-request.model';
import { FetchCallsBySipCodeResponse }          from '@models/api/fetch-calls-by-sip-code-response.model';
import { FetchExpensiveCallsRequest }           from '@models/api/fetch-expensive-calls-request.model';
import { FetchSummaryByDirectionResponse }      from '@models/api/fetch-summary-by-direction-response.model';
import { FetchCallRecordingTranscriptResponse } from '@models/api/fetch-call-recording-transcript-response.model';
import { FetchChannelUsageResponse }            from '@models/api/fetch-channel-usage-response.model';
import { FetchCallLogSummaryResponse }          from '@models/api/fetch-call-log-summary-response.model';
import { FetchCallsBySipCodeRequest }           from '@models/api/fetch-calls-by-sip-code-request.model';
import { FetchReportingOverviewRequest }        from '@models/api/fetch-reporting-overview-request.model';
import { FetchLocationCostResponse }            from '@models/api/fetch-location-cost-response.model';
import { FetchDestinationsSummaryResponse }     from '@models/api/fetch-destinations-summary-response.model';
import { FetchReportRequest }                   from '@models/api/reporting/fetch-report-request.model';
import { FetchCallLogResponse }                 from '@models/api/fetch-call-log-response.model';
import { FetchCountByCapabilityRequest }        from '@models/api/fetch-count-by-capability-request.model';
import { FetchCallRecordingTranscriptRequest }  from '@models/api/fetch-call-recording-transcript-request.model';
import { ExportCDRtoCSVRequest }                from '@models/api/export-cdr-to-csv-request.model';
import { FetchReportListRequest }               from '@models/api/fetch-report-list-request.model';
import { FetchReportListResponse }              from '@models/api/fetch-report-list-response.model';
import { FetchCallMinutesByDirectionRequest }   from '@models/api/fetch-call-minutes-by-direction-request.model';
import { FetchReportingOverviewResponse }       from '@models/api/fetch-reporting-overview-response.model';
import { FetchReportResponse }                  from '@models/api/reporting/fetch-report-response.model';
import { FetchSummaryByCountryResponse }        from '@models/api/fetch-summary-by-country-response.model';
import { FetchChannelUsageRequest }             from '@models/api/fetch-channel-usage-request.model';
import { ExportCDRtoCSVResponse }               from '@models/api/export-cdr-to-csv-response.model';
import { FetchCallMinutesByDirectionResponse }  from '@models/api/fetch-call-minutes-by-direction-response.model';
import { DeleteReportRequest }                  from '@models/api/reporting/delete-report-request.model';
import { DeleteReportResponse }                 from '@models/api/reporting/delete-report-response.model';
import {
  FetchTotalNumbersPerCountryRequest,
}                                               from '@models/api/reporting/fetch-total-numbers-per-country-request.model';
import {
  FetchTotalNumbersPerCountryResponse,
}                                               from '@models/api/reporting/fetch-total-numbers-per-country-response.model';
import {
  FetchAssignedNumbersTotalRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-total-request.model';
import {
  FetchAssignedNumbersTotalResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-total-response.model';
import {
  FetchAssignedNumbersByTagRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-tag-request.model';
import {
  FetchAssignedNumbersByTagResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-tag-response.model';
import {
  FetchAssignedNumbersByLocationRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-location-request.model';
import {
  FetchAssignedNumbersByLocationResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-location-response.model';
import {
  FetchAssignedNumbersByRangeRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-range-request.model';
import {
  FetchAssignedNumbersByRangeResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-range-response.model';
import {
  FetchAssignedNumbersByCountryRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-country-request.model';
import {
  FetchAssignedNumbersByCountryResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-country-response.model';
import {
  FetchTotalNumbersPerTypeRequest,
}                                               from '@models/api/reporting/fetch-total-numbers-per-type-request.model';
import {
  FetchTotalNumbersPerTypeResponse,
}                                               from '@models/api/reporting/fetch-total-numbers-per-type-response.model';
import { FetchRangeUsageTopRequest }            from '@models/api/reporting/fetch-range-usage-top-request.model';
import { FetchRangeUsageTopResponse }           from '@models/api/reporting/fetch-range-usage-top-response.model';
import { FetchRangeExhaustionRequest }          from '@models/api/reporting/fetch-range-exhaustion-request.model';
import { FetchRangeExhaustionResponse }         from '@models/api/reporting/fetch-range-exhaustion-response.model';
import { BaseRequest }                          from '@redux/helpers/reducer.helper';
import { FetchSipResponseTypesResponse }        from '@models/api/reporting/fetch-sip-response-types-response.model';
import { FetchCallsBySipTypeRequest }           from '@models/api/reporting/fetch-calls-by-sip-type-request.model';
import { FetchCallsBySipTypeResponse }          from '@models/api/reporting/fetch-calls-by-sip-type-response.model';
import { FetchCallCostByDirectionRequest }      from '@models/api/number/fetch-call-cost-by-direction-request.model';
import {
  FetchCallCostByDirectionResponse,
}                                               from '@models/api/number/fetch-call-cost-by-direction-response.model';
import { FetchCallCostSummaryByBandRequest }    from '@models/api/number/fetch-call-cost-summary-by-band-request.model';
import {
  FetchCallCostSummaryByBandResponse,
}                                               from '@models/api/number/fetch-call-cost-summary-by-band-response.model';

export const FetchFrequentCallsRequestAction  = createAction(FETCH_FREQUENT_CALLS_REQUEST, props<FetchFrequentCallsRequest>());
export const FetchFrequentCallsResponseAction = createAction(FETCH_FREQUENT_CALLS_RESPONSE, props<FetchFrequentCallsResponse>());

export const FetchExpensiveCallsRequestAction  = createAction(FETCH_EXPENSIVE_CALLS_REQUEST, props<FetchExpensiveCallsRequest>());
export const FetchExpensiveCallsResponseAction = createAction(FETCH_EXPENSIVE_CALLS_RESPONSE, props<FetchExpensiveCallsResponse>());

export const FetchLocationCostRequestAction  = createAction(FETCH_LOCATION_COST_REQUEST, props<FetchLocationCostRequest>());
export const FetchLocationCostResponseAction = createAction(FETCH_LOCATION_COST_RESPONSE, props<FetchLocationCostResponse>());

export const FetchDestinationSummaryRequestAction  = createAction(FETCH_DESTINATION_SUMMARY_REQUEST, props<FetchDestinationsSummaryRequest>());
export const FetchDestinationSummaryResponseAction = createAction(FETCH_DESTINATION_SUMMARY_RESPONSE, props<FetchDestinationsSummaryResponse>());

export const FetchReportingOverviewRequestAction  = createAction(FETCH_REPORTING_OVERVIEW_REQUEST, props<FetchReportingOverviewRequest>());
export const FetchReportingOverviewResponseAction = createAction(FETCH_REPORTING_OVERVIEW_RESPONSE, props<FetchReportingOverviewResponse>());

export const FetchUsageByDirectionRequestAction  = createAction(FETCH_USAGE_BY_DIRECTION_REQUEST, props<FetchUsageByDirectionRequest>());
export const FetchUsageByDirectionResponseAction = createAction(FETCH_USAGE_BY_DIRECTION_RESPONSE, props<FetchUsageByDirectionResponse>());

export const FetchSummaryByDirectionRequestAction  = createAction(FETCH_SUMMARY_BY_DIRECTION_REQUEST, props<FetchSummaryByDirectionRequest>());
export const FetchSummaryByDirectionResponseAction = createAction(FETCH_SUMMARY_BY_DIRECTION_RESPONSE, props<FetchSummaryByDirectionResponse>());

export const FetchSummaryByCountryRequestAction  = createAction(FETCH_SUMMARY_BY_COUNTRY_REQUEST, props<FetchSummaryByCountryRequest>());
export const FetchSummaryByCountryResponseAction = createAction(FETCH_SUMMARY_BY_COUNTRY_RESPONSE, props<FetchSummaryByCountryResponse>());

export const FetchChannelUsageRequestAction  = createAction(FETCH_CHANNEL_USAGE_REQUEST, props<FetchChannelUsageRequest>());
export const FetchChannelUsageResponseAction = createAction(FETCH_CHANNEL_USAGE_RESPONSE, props<FetchChannelUsageResponse>());

export const ClearCallLogParams = createAction(CLEAR_CALL_LOG_PARAMS);

export const FetchCallLogRequestAction  = createAction(FETCH_CALL_LOG_REQUEST, props<FetchCallLogRequest>());
export const FetchCallLogResponseAction = createAction(FETCH_CALL_LOG_RESPONSE, props<FetchCallLogResponse>());

export const FetchCallLogSummaryRequestAction  = createAction(FETCH_CALL_LOG_SUMMARY_REQUEST, props<FetchCallLogSummaryRequest>());
export const FetchCallLogSummaryResponseAction = createAction(FETCH_CALL_LOG_SUMMARY_RESPONSE, props<FetchCallLogSummaryResponse>());

export const FetchCallLogRecordingRequestAction  = createAction(FETCH_CALL_LOG_RECORDING_REQUEST, props<FetchCallLogRecordingRequest>());
export const FetchCallLogRecordingResponseAction = createAction(FETCH_CALL_LOG_RECORDING_RESPONSE, props<FetchCallLogRecordingResponse>());

export const ExportCDRtoCSVRequestAction  = createAction(EXPORT_CDR_TO_CSV_REQUEST, props<ExportCDRtoCSVRequest>());
export const ExportCDRtoCSVResponseAction = createAction(EXPORT_CDR_TO_CSV_RESPONSE, props<ExportCDRtoCSVResponse>());

export const SetReportingDateRange = createAction(SET_DATE_RANGE, props<DateRange>());

export const FetchCallMinutesByDirectionRequestAction  = createAction(FETCH_CALL_MINUTES_BY_DIRECTION_REQUEST, props<FetchCallMinutesByDirectionRequest>());
export const FetchCallMinutesByDirectionResponseAction = createAction(FETCH_CALL_MINUTES_BY_DIRECTION_RESPONSE, props<FetchCallMinutesByDirectionResponse>());

export const FetchCallsBySIPCodeRequestAction  = createAction(FETCH_CALLS_BY_SIP_CODE_REQUEST, props<FetchCallsBySipCodeRequest>());
export const FetchCallsBySIPCodeResponseAction = createAction(FETCH_CALLS_BY_SIP_CODE_RESPONSE, props<FetchCallsBySipCodeResponse>());

export const FetchCallsBySIPTypeRequestAction  = createAction(FETCH_CALLS_BY_SIP_TYPE_REQUEST, props<FetchCallsBySipTypeRequest>());
export const FetchCallsBySIPTypeResponseAction = createAction(FETCH_CALLS_BY_SIP_TYPE_RESPONSE, props<FetchCallsBySipTypeResponse>());

export const FetchCallRecordingTranscriptRequestAction  = createAction(FETCH_CALL_RECORDING_TRANSCRIPT_REQUEST, props<FetchCallRecordingTranscriptRequest>());
export const FetchCallRecordingTranscriptResponseAction = createAction(FETCH_CALL_RECORDING_TRANSCRIPT_RESPONSE, props<FetchCallRecordingTranscriptResponse>());

export const FetchCountByCapabilityRequestAction  = createAction(FETCH_COUNT_BY_CAPABILITY_REQUEST, props<FetchCountByCapabilityRequest>());
export const FetchCountByCapabilityResponseAction = createAction(FETCH_COUNT_BY_CAPABILITY_RESPONSE, props<FetchCountByCapabilityResponse>());

export const FetchReportListRequestAction  = createAction(FETCH_REPORT_LIST_REQUEST, props<FetchReportListRequest>());
export const FetchReportListResponseAction = createAction(FETCH_REPORT_LIST_RESPONSE, props<FetchReportListResponse>());

export const FetchReportRequestAction  = createAction(FETCH_REPORT_REQUEST, props<FetchReportRequest>());
export const FetchReportResponseAction = createAction(FETCH_REPORT_RESPONSE, props<FetchReportResponse>());

export const DeleteReportRequestAction  = createAction(DELETE_REPORT_REQUEST, props<DeleteReportRequest>());
export const DeleteReportResponseAction = createAction(DELETE_REPORT_RESPONSE, props<DeleteReportResponse>());

export const FetchTotalNumbersPerCountryRequestAction  = createAction(FETCH_TOTAL_NUMBERS_PER_COUNTRY_REQUEST, props<FetchTotalNumbersPerCountryRequest>());
export const FetchTotalNumbersPerCountryResponseAction = createAction(FETCH_TOTAL_NUMBERS_PER_COUNTRY_RESPONSE, props<FetchTotalNumbersPerCountryResponse>());

export const FetchAssignedNumbersTotalRequestAction  = createAction(FETCH_ASSIGNED_NUMBERS_TOTAL_REQUEST, props<FetchAssignedNumbersTotalRequest>());
export const FetchAssignedNumbersTotalResponseAction = createAction(FETCH_ASSIGNED_NUMBERS_TOTAL_RESPONSE, props<FetchAssignedNumbersTotalResponse>());

export const FetchAssignedNumbersByTagRequestAction  = createAction(FETCH_ASSIGNED_NUMBERS_BY_TAG_REQUEST, props<FetchAssignedNumbersByTagRequest>());
export const FetchAssignedNumbersByTagResponseAction = createAction(FETCH_ASSIGNED_NUMBERS_BY_TAG_RESPONSE, props<FetchAssignedNumbersByTagResponse>());

export const FetchAssignedNumbersByLocationRequestAction  = createAction(FETCH_ASSIGNED_NUMBERS_BY_LOCATION_REQUEST, props<FetchAssignedNumbersByLocationRequest>());
export const FetchAssignedNumbersByLocationResponseAction = createAction(FETCH_ASSIGNED_NUMBERS_BY_LOCATION_RESPONSE, props<FetchAssignedNumbersByLocationResponse>());

export const FetchAssignedNumbersByRangeRequestAction  = createAction(FETCH_ASSIGNED_NUMBERS_BY_RANGE_REQUEST, props<FetchAssignedNumbersByRangeRequest>());
export const FetchAssignedNumbersByRangeResponseAction = createAction(FETCH_ASSIGNED_NUMBERS_BY_RANGE_RESPONSE, props<FetchAssignedNumbersByRangeResponse>());

export const FetchAssignedNumbersByCountryRequestAction  = createAction(FETCH_ASSIGNED_NUMBERS_BY_COUNTRY_REQUEST, props<FetchAssignedNumbersByCountryRequest>());
export const FetchAssignedNumbersByCountryResponseAction = createAction(FETCH_ASSIGNED_NUMBERS_BY_COUNTRY_RESPONSE, props<FetchAssignedNumbersByCountryResponse>());

export const FetchTotalNumbersPerTypeRequestAction  = createAction(FETCH_TOTAL_NUMBERS_PER_TYPE_REQUEST, props<FetchTotalNumbersPerTypeRequest>());
export const FetchTotalNumbersPerTypeResponseAction = createAction(FETCH_TOTAL_NUMBERS_PER_TYPE_RESPONSE, props<FetchTotalNumbersPerTypeResponse>());

export const FetchRangeUsageTopRequestAction  = createAction(FETCH_RANGE_USAGE_TOP_REQUEST, props<FetchRangeUsageTopRequest>());
export const FetchRangeUsageTopResponseAction = createAction(FETCH_RANGE_USAGE_TOP_RESPONSE, props<FetchRangeUsageTopResponse>());

export const FetchRangeExhaustionRequestAction  = createAction(FETCH_RANGE_EXHAUSTION_REQUEST, props<FetchRangeExhaustionRequest>());
export const FetchRangeExhaustionResponseAction = createAction(FETCH_RANGE_EXHAUSTION_RESPONSE, props<FetchRangeExhaustionResponse>());

export const FetchSIPResponseTypesRequestAction  = createAction(FETCH_SIP_RESPONSE_TYPES_REQUEST, props<BaseRequest>());
export const FetchSIPResponseTypesResponseAction = createAction(FETCH_SIP_RESPONSE_TYPES_RESPONSE, props<FetchSipResponseTypesResponse>());

export const FetchCallCostByDirectionRequestAction  = createAction(FETCH_CALL_COST_BY_DIRECTION_REQUEST, props<FetchCallCostByDirectionRequest>());
export const FetchCallCostByDirectionResponseAction = createAction(FETCH_CALL_COST_BY_DIRECTION_RESPONSE, props<FetchCallCostByDirectionResponse>());

export const FetchCallCostSummaryByBandRequestAction  = createAction(FETCH_CALL_COST_SUMMARY_BY_BAND_REQUEST, props<FetchCallCostSummaryByBandRequest>());
export const FetchCallCostSummaryByBandResponseAction = createAction(FETCH_CALL_COST_SUMMARY_BY_BAND_RESPONSE, props<FetchCallCostSummaryByBandResponse>());
