import { Component, Inject }             from '@angular/core';
import { FormControl }                   from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector:    'ngx-accept-terms-conditions',
  templateUrl: './accept-terms-conditions.component.html',
  styleUrls:   ['./accept-terms-conditions.component.scss'],
})
export class AcceptTermsConditionsComponent {
  public termsControl = new FormControl<boolean>(false);

  constructor(@Inject(MAT_DIALOG_DATA) public url: string,
              private dialogRef: MatDialogRef<AcceptTermsConditionsComponent>) {
  }

  accept(): void {
    this.dialogRef.close({ accept: true });
  }
}
