import { ListParamKeys }        from './list-param-keys.model';
import { ListResponseMetadata } from '../api/list-response-metadata.model';
import { ListParamQuery }       from '../entity/list.model';
import { ListParamModel }       from './list-param-model';
import { AuthScope }            from '@enums/auth-scope.enum';
import { RestrictedInclude }    from '@models/entity/restricted-params.model';

export class ListParams implements ListParamModel<ListParams> {
  pageSize: number;
  pageNumber: number;
  pageCount?: number;
  recordCount?: number;
  sort?: string;
  sortAsc?: boolean;
  include?: string[];

  static constructQueryString(listParams: ListParamQuery,
                              paramKeys: ListParamKeys,
                              restrictedInclude?: RestrictedInclude,
                              scopes?: AuthScope[]): string {
    if (!listParams) {
      return '';
    }

    const keys: ListParamKeys = {
      ...paramKeys,
      pageSize:   'page_size',
      pageNumber: 'page_number',
      sort:       'sort',
    };

    const isRestrictedInclude = (key: string, arrayItem: string): boolean => {
      if (key !== 'include[]') {
        return false;
      }
      if (restrictedInclude) {

        const scopesRequired: AuthScope[] = restrictedInclude[arrayItem];
        if (scopesRequired?.length && !scopesRequired.every(required => scopes.includes(required))) {
          return true;
        }
      }
      return false;
    };

    const params: string[] = [];

    for (const key of Object.keys(keys)) {
      const filterVal = listParams[key as keyof ListParamQuery] as (string | string[]);

      if (filterVal !== undefined && filterVal !== null && !!keys[key as keyof ListParamKeys]) {
        const filterKey = keys[key as keyof ListParamKeys];
        if (Array.isArray(filterVal) && !filterVal.length) {
          continue;
        }
        if (!filterKey.includes('[]')) {
          params.push(`${ filterKey }=${ filterVal as string }`);
          continue;
        }
        // build queryString from array syntax
        for (const item of filterVal as string[]) {
          if (isRestrictedInclude(filterKey, item) || item === undefined) {
            continue;
          }
          params.push(`${ filterKey }=${ item }`);
        }
      }
    }

    return '?'.concat(params.join('&'));
  }

  static queryChanged<T extends ListParams>(newQ: T, curQ: T): boolean {
    return newQ?.sort !== curQ?.sort || newQ?.pageNumber !== curQ?.pageNumber || newQ?.pageSize !== curQ?.pageSize;
  }

  static arrayChanged(newArray: Array<string> = [], currentArray: Array<string>): boolean {
    if (!currentArray?.length || (newArray.length !== currentArray.length)) {
      return true;
    }
    for (const type of newArray) {
      if (!currentArray.some(t => t === type)) {
        return true;
      }
    }

    return false;
  }

  static resolveGroupFilterToArray<EnumType extends string>(filter: { [key in EnumType]: boolean }, enumType: any): EnumType[] {
    if (!filter) {
      return undefined;
    }
    const exhaustionStatus = (Object.keys(filter) as EnumType[])
      .filter((k: EnumType) => !!(filter)[k]);

    // if all are set, then drop the filter entirely
    return !(Object.values(enumType) as EnumType[])
      .every(val => exhaustionStatus.includes(val)) ?
      exhaustionStatus : undefined;
  }

  constructParams?(metaData: ListResponseMetadata): ListParams {
    this.pageSize    = metaData?.page_size || 10;
    this.pageNumber  = metaData?.page_number || 1;
    this.pageCount   = metaData?.page_count || 10;
    this.recordCount = metaData?.record_count || 0;
    if (!metaData) {
      return this;
    }
    if (metaData.sort) {
      this.sort    = metaData.sort || '';
      this.sortAsc = this.sort.indexOf('-', 0) === -1;
    }

    return this;
  }
}
