import { BaseRequest }       from '@redux/helpers/reducer.helper';
import { CallQueueGroupRaw } from '@models/api/microsoft-teams/call-queue-group-raw.model';

export class PostCallQueueGroupRequest extends BaseRequest {
  serviceId: string;
  name: string;
  description: string;
  callQueueIds: string[];

  constructor(private data?: PostCallQueueGroupRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): CallQueueGroupRaw {
    return {
      name:           this.name,
      call_queue_ids: this.callQueueIds,
      description:    this.description,
    };
  }
}
