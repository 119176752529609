<form data-cy="form" [formGroup]="formGroup" novalidate #ngForm="ngForm" *ngIf="formGroup"
      autocomplete="off"
      class="sip-form">
  <div class="sip-form__group" fxLayout="row wrap" fxLayoutAlign="space-between">
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else labelView">
        <mat-form-field appearance="outline">
          <mat-label>Label</mat-label>
          <input matInput
                 type="text"
                 formControlName="label"
                 data-cy="form-label"
                 maxLength="30"
                 [hidden]="!editMode"
                 [class.field-invalid]="fieldInvalid(formGroup.get('label'), ngForm.submitted)"
                 autocomplete="off" />
          <ngx-field-notification
            *ngIf="editMode && formGroup.get('label').value && formGroup.get('label').invalid"
            [type]="FieldCheckType.Invalid"
            class="mb-12"
            message="Label is not valid"></ngx-field-notification>
        </mat-form-field>
      </ng-container>
      <ng-template #labelView>
        <label class="field-label">Label</label>
        <p class="color-black" [hidden]="editMode">{{formGroup.get('label').value}}</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         *ngIf="!dialogMode"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else gatewayAddressView">
        <mat-form-field appearance="outline">
          <mat-label>Callroute gateway address</mat-label>
          <input matInput
                 type="text"
                 formControlName="callrouteGatewayAddress"
                 data-cy="form-callroute-gateway-address"
                 maxLength="30"
                 [hidden]="!editMode"
                 autocomplete="off" />
        </mat-form-field>
      </ng-container>
      <ng-template #gatewayAddressView>
        <label class="field-label">Callroute gateway address</label>
        <p class="color-black" [hidden]="editMode">{{formGroup.get('callrouteGatewayAddress').value}}</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else protoView">
        <mat-form-field appearance="outline">
          <mat-label>Gateway protocol</mat-label>
          <mat-select data-cy="form-gateway-protocol" name="gateway-protocol" [hidden]="!editMode"
                      formControlName="gatewayProtocol"
                      [class.text-light-gray]="!formGroup.get('gatewayProtocol').value"
                      id="gateway-protocol">
            <mat-option [value]="null" disabled selected>- Choose -</mat-option>
            <mat-option [value]="proto" *ngFor="let proto of protocols">{{proto}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-template #protoView>
        <label class="field-label">Gateway protocol</label>
        <p class="color-black" [hidden]="editMode">{{formGroup.get('gatewayProtocol').value}}</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else addressView">
        <mat-form-field appearance="outline">
          <mat-label>Gateway address (e.g. 192.168.100.1)</mat-label>
          <input matInput
                 type="text"
                 formControlName="gatewayAddress"
                 data-cy="form-gateway-address"
                 maxLength="128"
                 [hidden]="!editMode"
                 [class.field-invalid]="fieldInvalid(formGroup.get('gatewayAddress'), ngForm.submitted)"
                 autocomplete="off" />
          <ngx-field-notification
            *ngIf="formGroup.get('gatewayAddress').value && formGroup.get('gatewayAddress').invalid"
            [type]="FieldCheckType.Invalid"
            class="mb-12"
            message="Gateway address is not a valid IP/domain"></ngx-field-notification>
        </mat-form-field>
      </ng-container>
      <ng-template #addressView>
        <label class="field-label">Gateway address (e.g. 192.168.100.1)</label>
        <p class="color-black" [hidden]="editMode">{{formGroup.get('gatewayAddress').value}}</p>
      </ng-template>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between" fxFlex="0 0 100">
      <div class="input-field-container" fxFlex="0 0 48"
           [ngClass]="!editMode && 'border-bottom-1-gray'">
        <ng-container *ngIf="editMode; else portView">
          <mat-form-field appearance="outline">
            <mat-label>Gateway port (e.g. 1-65535)</mat-label>
            <input matInput
                   type="number"
                   formControlName="gatewayPort"
                   data-cy="form-gateway-port"
                   [min]="1"
                   maxLength="128"
                   [hidden]="!editMode"
                   [class.field-invalid]="fieldInvalid(formGroup.get('gatewayPort'), ngForm.submitted)"
                   autocomplete="off" />
          </mat-form-field>
        </ng-container>
        <ng-template #portView>
          <label class="field-label">Gateway port (e.g. 1-65535)</label>
          <p class="color-black" [hidden]="editMode">{{formGroup.get('gatewayPort').value}}</p>
        </ng-template>
      </div>
      <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100">
        <label class="field-label">Does the Gateway support SIP options?</label>
        <mat-slide-toggle data-cy="form-gateway-sip-options"
                          class="mt-6"
                          color="primary"
                          formControlName="gatewayHasSipOptions">
        </mat-slide-toggle>
      </div>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100">
      <label class="field-label">Address spaces (e.g. 192.168.0.0/8)</label>
      <ng-container *ngIf="editMode; else addressSpaceView">
        <ng-container *ngFor="let addrCtrl of addressSpacesCtrls; let i = index;">
          <div fxLayout="row" fxLayoutAlign="start center" class="mb-12 relative">
            <mat-form-field appearance="outline" class="w-100">
              <input matInput type="text"
                     data-cy="form-address-spaces"
                     maxLength="64"
                     fxFlex="0 0 100"
                     (ngModelChange)="currentAddressSpace.next($event)"
                     [formControl]="getAddressSpaceCtrl(i)"
                     [hidden]="!editMode"
                     [class.field-invalid]="fieldInvalid(getAddressSpaceCtrl(i), ngForm.submitted)"
                     autocomplete="off" />
              <mat-icon matSuffix class="cursor-pointer" (click)="removeAddressSpace(i)"
                        *ngIf="i > 0 && editMode" svgIcon="cr-bin"></mat-icon>
            </mat-form-field>
          </div>
          <ngx-field-notification
            *ngIf="editMode && fieldInvalid(getAddressSpaceCtrl(i), ngForm.submitted)"
            [type]="FieldCheckType.Invalid"
            class="mb-12"
            message="Address space is not a valid CIDR address"></ngx-field-notification>
        </ng-container>
      </ng-container>
      <ng-template #addressSpaceView>
        <ng-container *ngFor="let addrCtrl of addressSpacesCtrls; let i = index;">
          <div fxLayout="row" fxLayoutAlign="start center" class="mb-12 relative">
            <p class="color-black" [hidden]="editMode">{{addrCtrl.value}}</p>
          </div>
        </ng-container>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48"
         *ngIf="!(containsDuplicates$ | async) && !addressSpacesFormArray.invalid && editMode && addressSpacesCtrls?.length < 5"
         fxLayout="row" fxLayoutAlign="start end">
      <button mat-icon-button
              data-cy="form-add"
              (click)="addAddressSpace()" class="add-btn mb-14">
        <mat-icon svgIcon="cr-plus"></mat-icon>
      </button>
    </div>
  </div>

</form>
