import { ListModel }                        from './list.model';
import { ServiceUserStatus }                from '@enums/service-user-status.enum';
import { MicrosoftTeamsUserFactoryService } from '@services/microsoft-teams-user-factory.service';
import { MicrosoftTeamsUserRaw }            from '@models/api/microsoft-teams-raw.model';
import { Token }                            from '@models/entity/token-state.model';
import { ProcessingStatus }                 from '@enums/processing-status.enum';
import { CallingProfile }                   from '@models/entity/calling-profile.model';
import { LicenseGroup }                     from '@models/entity/license-group.model';
import { CarrierType }                      from '@enums/carrier-type.enum';
import { MicrosoftTeamsCapability }         from '@enums/microsoft-teams-capability.enum';
import { NumberProvider }                   from '@models/entity/number-provider.model';
import { ProviderIdentifier }               from '@enums/provider-identifier.enum';
import { TeamGroup }                        from '@models/entity/teams-group.model';
import { EmergencyLocation }                from '@models/entity/emergency-location.model';
import { NumberRange }                      from '@models/entity/number-range.model';
import { ServiceUserCapability }            from '@enums/service-user-capability.enum';
import { TeamGroupRole }                    from '@enums/team-group-role.enum';
import { CallQueueGroup }                   from '@models/entity/call-queue-group.model';
import { StatusIndicator }                  from '@models/ui/status-indicator.model';
import { UserDataSource }                   from '@models/api/service/user-data-source.model';
import { CountryCodes }                     from '@models/entity/country-code.model';
import { AssetsService }                    from '@services/assets.service';
import { getServiceIcon }                   from '@models/api/service.model';
import { ServiceType }                      from '@enums/service-type.enum';
import { Location }                         from '@models/entity/location.model';


export class MicrosoftTeamsUser implements ListModel<MicrosoftTeamsUser> {
  displayName: string;
  extension: string;
  externalId: string;
  firstName: string;
  id: string;
  serviceId: string;
  lastName: string;
  cli: string;
  lineUri: string;
  numberId: string;
  numberTags: string[];
  range: NumberRange;
  numberLocation: Location;
  numberLocationId: string;
  profileId: string;
  profile: CallingProfile;
  isRoutable: boolean;
  status: ServiceUserStatus;
  isResourceAccount: boolean;
  statusDescription?: string;
  statusColor?: string;
  upn: string;
  isProcessing: boolean;
  isSyncing: boolean;
  isFailed: boolean;
  failureMessage: string;
  licenseGroupIds: string[];
  licenseGroups: LicenseGroup[];
  teamGroupIds: string[];
  teamGroups: TeamGroup[];
  teamGroupRoles: { [groupId: string]: TeamGroupRole };
  callQueueGroupIds: string[];
  callQueueGroups: CallQueueGroup[];
  requestId?: string;
  numberProvider?: NumberProvider;
  emergencyLocationId: string;
  emergencyLocation: EmergencyLocation;
  capabilities: ServiceUserCapability[];
  usageLocation: keyof CountryCodes;
  updatedDate: string;
  userDataSource: UserDataSource;
  isManaged: boolean;
  private capabilityMap?: Map<ServiceUserCapability, { name: string, icon: string }> = new Map<ServiceUserCapability, {
    name: string;
    icon: string
  }>([
    [ServiceUserCapability.DirectRouting, {
      name: 'Direct Routing',
      icon: AssetsService.getIcon('arrow-to-dot.svg'),
    }],
    [ServiceUserCapability.MicrosoftCallingPlan, {
      name: 'Calling Plan',
      icon: AssetsService.getIcon('microsoft.png'),
    }],
    [ServiceUserCapability.MicrosoftOperatorConnect, {
      name: 'Operator Connect',
      icon: AssetsService.getIcon('cloud_phone.svg'),
    }],
    [ServiceUserCapability.Teams, {
      name: 'Teams enabled',
      icon: getServiceIcon(ServiceType.MicrosoftTeams, true),
    }],
  ]);

  constructor(protected data?: MicrosoftTeamsUser) {
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  withServiceId?(serviceId: string): MicrosoftTeamsUser {
    this.serviceId = serviceId;
    return this;
  }

  fromApiData?(apiData: MicrosoftTeamsUserRaw): MicrosoftTeamsUser {
    this.displayName         = apiData.display_name;
    this.extension           = apiData.extension;
    this.externalId          = apiData.external_id;
    this.firstName           = apiData.first_name;
    this.id                  = apiData.id;
    this.lastName            = apiData.last_name;
    this.isResourceAccount   = apiData.is_resource_account;
    this.isRoutable          = apiData.is_routable;
    this.cli                 = apiData.cli;
    this.numberId            = apiData.cli_number_id;
    this.status              = apiData.status;
    this.upn                 = apiData.upn;
    this.lineUri             = apiData.line_uri;
    this.isProcessing        = apiData.processing_status !== ProcessingStatus.None;
    this.profileId           = apiData.profile_id;
    this.licenseGroupIds     = apiData.license_group_ids || [];
    this.licenseGroups       = [];
    this.teamGroupIds        = apiData.team_group_ids ? Object.keys(apiData.team_group_ids) : [];
    this.teamGroupRoles      = apiData.team_group_ids || {};
    this.teamGroups          = [];
    this.callQueueGroupIds   = apiData.call_queue_group_ids || [];
    this.callQueueGroups     = [];
    this.emergencyLocationId = apiData.location_id;
    this.capabilities        = apiData.capabilities;
    this.usageLocation       = apiData.usage_location;
    this.updatedDate         = apiData.updated_date;
    this.userDataSource      = apiData.user_data_source;
    this.isManaged           = apiData.is_managed;

    switch (apiData.status) {
      case ServiceUserStatus.PSTNEnabled:
        this.statusColor       = 'green';
        this.statusDescription = 'Callroute PSTN';
        break;
      case ServiceUserStatus.DirectRouting:
        this.statusColor       = 'pink';
        this.statusDescription = 'Direct Routing';
        break;
      case ServiceUserStatus.CallingPlan:
        this.statusColor       = 'pink';
        this.statusDescription = 'Calling Plan';
        break;
      case ServiceUserStatus.OperatorConnect:
        this.statusColor       = 'pink';
        this.statusDescription = 'Operator Connect';
        break;
      case ServiceUserStatus.PhoneDisabled:
        this.statusColor       = 'purple';
        this.statusDescription = 'Phone Capable';
        break;
      case ServiceUserStatus.LicensedNonPhone:
        this.statusColor       = 'orange';
        this.statusDescription = 'Licensed (Non-Phone)';
        break;
      case ServiceUserStatus.Unlicensed:
        this.statusColor       = 'light-gray';
        this.statusDescription = 'Unlicensed';
        break;
      default:
        this.statusColor       = 'light-gray';
        this.statusDescription = '';
    }

    return this;
  }

  getLineURI?(): string {
    // Using this instead of lineURI because lineURI doesn't have a + prefix
    return this.cli ? this.cli.concat(this.extension ?
      (';ext=' + this.extension) : '') : this.hasVoiceLicense() ? '' : '-';
  }

  getHasMismatchPolicies?(): boolean {
    if (this.isResourceAccount) {
      return false;
    }
    return this.profile && Object.entries(this.profile.policies)
      .some(([name, value]) => {
        const personaValue = value;
        const actualValue  = this.userDataSource?.[name];
        if (personaValue === 'Global') {
          return actualValue !== null;
        }
        return personaValue && personaValue !== actualValue;
      });
  }

  getStatus?(): StatusIndicator {
    if (this.isFailed) {
      return {
        text:    'Failed',
        loading: false,
        color:   'red',
        tooltip: this.failureMessage,
        link:    'https://help.callroute.com/space/CKB/721191006/Teams+User+Will+Not+Activate',
      };
    }

    return {
      text:    this.isProcessing ?
                 MicrosoftTeamsUserFactoryService.getProcessingModeText(this.isSyncing) :
                 this.statusDescription,
      subText: this.range?.operator || '',
      loading: this.isProcessing,
      color:   this.isProcessing ?
                 'yellow' :
                 this.statusColor || 'light-gray',
      tooltip: this.isProcessing && !this.isSyncing ?
                 'This could take a few minutes to process. You may continue browsing Callroute in the meantime.' :
                 '',
    };
  }

  isConfigureDisabled?(tokensPending: boolean, serviceIsHealthy: boolean, hasServiceWrite: boolean): boolean {
    return this.isProcessing || tokensPending || !serviceIsHealthy || !hasServiceWrite;
  }

  getConfigureTooltip?(serviceIsHealthy: boolean, tokens: Token[], hasServiceWrite: boolean): string {
    if (!hasServiceWrite) {
      return 'You do not have the required access to configure. Please contact your systems administrator for more information.';
    }
    if (serviceIsHealthy) {
      return MicrosoftTeamsUserFactoryService.authTooltip(tokens);
    }

    return 'Service is not healthy. Please see the health checker for more information.';
  }

  isSyncDisabled?(tokensPending: boolean, serviceIsHealthy: boolean, hasServiceWrite: boolean): boolean {
    return this.isProcessing || tokensPending || !serviceIsHealthy || !hasServiceWrite;
  }

  isRoutingDisabled?(carrierType: CarrierType, capabilities: MicrosoftTeamsCapability[], providerIdentifier: ProviderIdentifier): boolean {
    if (this.isProcessing) {
      return true;
    }
    if (carrierType !== CarrierType.BYON || providerIdentifier === ProviderIdentifier.DirectRouting) {
      return false;
    }
    return (this.isResourceAccount && !capabilities?.includes(MicrosoftTeamsCapability.VoiceApplicationAssignment)) ||
      (!this.isResourceAccount && !capabilities?.includes(MicrosoftTeamsCapability.UserAssignment));
  }

  getSyncTooltip?(serviceIsHealthy: boolean, tokens: Token[], hasServiceWrite: boolean): string {
    if (!hasServiceWrite) {
      return 'You do not have the required access to sync. Please contact your systems administrator for more information.';
    }
    if (serviceIsHealthy) {
      return MicrosoftTeamsUserFactoryService.authTooltip(tokens) || 'Sync user';
    }

    return 'Service is not healthy. Please see the health checker for more information.';
  }

  hasVoiceLicense?(): boolean {
    return ![
      ServiceUserStatus.LicensedNonPhone,
      ServiceUserStatus.Unlicensed,
    ].includes(this.status);
  }

  hasVoiceCapability?(capability: ServiceUserCapability): boolean {
    return this.capabilities?.includes(capability);
  }

  isCallingPlan?(): boolean {
    return this.hasVoiceCapability(ServiceUserCapability.MicrosoftCallingPlan) &&
      !this.hasVoiceCapability(ServiceUserCapability.MicrosoftOperatorConnect);
  }

  isOperatorConnect?(): boolean {
    return this.hasVoiceCapability(ServiceUserCapability.MicrosoftOperatorConnect) &&
      !this.hasVoiceCapability(ServiceUserCapability.MicrosoftCallingPlan);
  }

  getCapabilities?(): { name: string, icon: string }[] {
    if (!this.capabilities?.length) {
      return [];
    }
    const capabilities: { name: string, icon: string }[] = [];

    for (const capability of this.capabilities) {
      if (!this.capabilityMap.has(capability)) {
        continue;
      }
      capabilities.push(this.capabilityMap.get(capability));
    }

    return capabilities;
  }
}
