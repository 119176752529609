import { Observable, ObservedValueOf, of, timer } from 'rxjs';
import { throttle }                               from 'rxjs/operators';

/**
 * Uses rxjs/throttleTime
 * But only IF the check condition returns true for each emitted value
 * @returns (source: Observable<T>) => Observable<ObservedValueOf<Observable<T>>>
 *
 * @example
 * ```ts
 *  action$.pipe(throttleIf(1000, req => req.withThrottle)).subscribe(() => doSomethingEvery1000());
 * ```
 * @param time
 * @param config
 * @param check function
 */
export function throttleIf<T>(time: number,
                              config: { leading: boolean, trailing: boolean } = {leading: true, trailing: true},
                              check: (val: T) => boolean): (source: Observable<T>) => Observable<ObservedValueOf<Observable<T>>> {
  return (source: Observable<T>) => source.pipe(
    throttle(value => {
      if (check(value)) {
        return timer(time);
      }
      return of(null);
    }, config));
}
