<ng-container *ngIf="!(user$ | async)">
  <ngx-anon-page *ngIf="hasError">
    <div fxLayout="column" fxLayoutAlign="space-between" class="callback-page">
      <h1 class="fw-500 mb-24">{{errorHeader}}</h1>
      <p class="mat-body-1 mb-24">{{errorMessage}}</p>
      <a [routerLink]="['/login']">
        <ngx-cr-button text="Back to login"></ngx-cr-button>
      </a>
    </div>
  </ngx-anon-page>
  <ngx-anon-page *ngIf="noCompanyError">
    <div fxLayout="column" fxLayoutAlign="space-between" class="callback-page">
      <h1 class="fw-500 mb-24">No company found</h1>
      <p class="mat-body-1 mb-24">Please <a href="https://callroute.com/contact" target="_blank">contact support</a> on
        <a
          href="tel:+442070966799">+442070966799</a> or by <a
          href="mailto:support@callroute.com">email.</a></p>
      <a [routerLink]="['/login']">
        <ngx-cr-button text="Back to login"></ngx-cr-button>
      </a>
    </div>
  </ngx-anon-page>
</ng-container>

