import { StateTask }         from '@models/entity/state-task.model';
import { Alert }             from '@models/entity/alert.model';
import { TypedAction }       from '@ngrx/store/src/models';
import { EntityState }       from '@ngrx/entity';
import { AuthScope }         from '@enums/auth-scope.enum';
import { RestrictedInclude } from '@models/entity/restricted-params.model';

export interface BaseState<T> extends EntityState<T> {
  pending: boolean,
  pendingTasks: StateTask[],
  error: Alert;
  message: Alert;
  responses?: { [id: string]: BaseResponse }
}

type APIFieldName = string;
type UserFieldName = string;

export class BaseRequest {
  emitEvent?: boolean;
  force?: boolean;
  clearState?: boolean;
  clearError?: boolean;
  clearMessage?: boolean;
  disableThrottle?: boolean;
  requestId?: string;
  formFields?: Map<APIFieldName, UserFieldName>;
  requiredScopes?: AuthScope[];
  restrictedInclude?: RestrictedInclude;
  scopes?: AuthScope[];
  storeAs?: string;
}

export class BaseResponse {
  error?: Alert;
  message?: Alert;
  storeAs?: string;
  requestId?: string;
  cancelled?: boolean;
}

function camelize(str: string) {
  return str.toLowerCase()
    .replace(/[^a-zA-Z\d]+(.)/g, (_: string, chr: string) => chr.toUpperCase());
}

export function snakeToCamelCase<T, K>(obj: K): T {
  if (!obj) {
    return null;
  }
  const newObj: Partial<T> = {};
  for (const key of Object.keys(obj)) {
    if (key.includes('_')) {
      newObj[camelize(key) as keyof T] = (obj[key as keyof K] as any);
    } else {
      newObj[key as keyof T] = (obj[key as keyof K] as any);
    }
  }
  return newObj as T;
}

export type BaseAction<A extends string> = TypedAction<A> & BaseRequest;

export function requestReduce<Entity, T extends BaseState<Entity>, A extends string, K extends BaseAction<A>>(state: T, action: K, mapRequest?: (req: K) => Partial<T>): T {
  const updatedPendingTasks = addPendingTask(state, action);

  return {
    ...state,
    error:        action.clearError ? null : state.error,
    message:      action.clearMessage ? null : state.message,
    pending:      !!updatedPendingTasks?.length,
    pendingTasks: updatedPendingTasks,
    ...(mapRequest?.(action) || {}),
  };
}

export function responseReduce<Entity, T extends BaseState<Entity>, A extends string, K extends TypedAction<A> & BaseResponse>(state: T, response: K, reqType: string, mapResponse?: (res: K) => Partial<T>): T {
  const updatedPendingTasks = removePendingTask(state, reqType);
  
  const s = {
    ...state,
    ...(mapResponse?.(response) || {}),
    pending:      !!updatedPendingTasks.length,
    pendingTasks: updatedPendingTasks,
    error:        response.error,
    message:      response.message,
    responses:    { ...(state.responses || {}) },
  };

  if (response.storeAs && s.responses) {
    s.responses[response.storeAs] = response;
  }

  return s;
}

export function addPendingTask<Entity, T extends BaseState<Entity>, A extends string, K extends BaseAction<A>>(state: T, action: K): StateTask[] {
  if (action.emitEvent === false || state.pendingTasks?.some(task => task.id === action.type)) {
    return state.pendingTasks || [];
  }
  return (state.pendingTasks || []).concat({ id: action.type });
}

export function removePendingTask<Entity, T extends BaseState<Entity>>(state: T, reqType: string): StateTask[] {
  if (!state.pendingTasks?.length || !state.pendingTasks.some(task => task.id === reqType)) {
    return [];
  }
  return state.pendingTasks.filter(s => s.id !== reqType);
}
