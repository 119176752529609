import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { CompanyState }        from '@redux/company/company.reducer';
import { slide, SlideOptions } from '@animations/slide';

@Component({
  selector:    'ngx-view-customer-header',
  templateUrl: './view-customer-header.component.html',
  styleUrls:   ['./view-customer-header.component.scss'],
  animations:  [
    slide(),
  ],
})
export class ViewCustomerHeaderComponent implements OnInit {
  @HostBinding('class.hide-bar') hideClass   = false;
  @HostBinding('@slide') slide: SlideOptions = {
    value: true, params: {
      translate: 'translateY', from: '-100%', to: '0', delay: '0.2s',
    },
  };

  @Input() companyData: CompanyState;
  @Input() isSipAdmin: boolean;

  get companyType(): string {
    if (this.companyData.ownCompanyData.isDistributor) {
      return 'Distributor';
    }
    if (this.companyData.ownCompanyData.isPartner) {
      return 'Partner';
    }
    return 'Customer';
  }

  private lastScrollDownPosition = 0;

  ngOnInit(): void {
    window.onscroll = (() => {
      if (window.scrollY !== null) {
        if (this.lastScrollDownPosition > window.scrollY) {
          this.lastScrollDownPosition = window.scrollY;
          this.hideClass              = false;
        } else if (window.scrollY > 50 && this.lastScrollDownPosition <= window.scrollY) {
          this.lastScrollDownPosition = window.scrollY;
          this.hideClass              = true;
        }
      }
    });
  }

}
