import { NgModule }                                       from '@angular/core';
import {
  ButtonTabsComponent,
}                                                         from '../../core/components/general/button-tabs/button-tabs.component';
import {
  OverviewActionButtonComponent,
}                                                         from '@core/components/general/number-list-button/overview-action-button.component';
import { TopUpBtnComponent }                              from '../billing/top-up-btn/top-up-btn.component';
import { MatTooltipModule }                               from '@angular/material/tooltip';
import { MatRippleModule }                                from '@angular/material/core';
import { MatIconModule }                                  from '@angular/material/icon';
import { RouterModule }                                   from '@angular/router';
import { MatTabsModule }                                  from '@angular/material/tabs';
import { CrButtonComponent }                              from './cr-button/cr-button.component';
import { MatProgressSpinnerModule }                       from '@angular/material/progress-spinner';
import { AsyncPipe, LowerCasePipe, NgClass, NgFor, NgIf } from '@angular/common';
import { FlexLayoutModule }                               from '@angular/flex-layout';


@NgModule({
  declarations: [
    ButtonTabsComponent,
    OverviewActionButtonComponent,
    TopUpBtnComponent,
    CrButtonComponent,
  ],
  imports:      [
    NgClass,
    AsyncPipe,
    NgIf,
    NgFor,
    FlexLayoutModule,
    MatTooltipModule,
    MatRippleModule,
    MatIconModule,
    RouterModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    LowerCasePipe,
  ],
  exports:      [
    ButtonTabsComponent,
    OverviewActionButtonComponent,
    TopUpBtnComponent,
    CrButtonComponent,
  ],
})
export class ButtonModule {}
