import { ServiceType }                 from '@enums/service-type.enum';
import { ServiceStatus }               from '@enums/service-status.enum';
import { ServiceSlug }                 from '@enums/service-slug.enum';
import { ServiceHealthCheckName }      from '@enums/health-check.enum';
import { ServiceUIStatus }             from '@enums/service-ui-status';
import { ServiceName }                 from '@enums/service-name.enum';
import { ServiceRequirementCheckName } from '@enums/service-requirement.enum';
import { TokenStatus }                 from '@enums/token-status.enum';
import { Status }                      from '@models/entity/status.model';
import { PackageCapabilities }         from '@models/entity/package-capabilities.model';
import { ServiceCapabilityIdentifier } from '@enums/service-capability-identifier.enum';
import { ServiceCapability }           from '@models/entity/service-capability.model';

export const getServiceName = (serviceType: ServiceType, capabilities?: ServiceCapability[]): ServiceName => {
  switch (serviceType) {
    case ServiceType.MicrosoftTeams:
      if (!capabilities) {
        return ServiceName.MicrosoftTeams;
      }
      if (capabilities.map(c => c.identifier)
        .includes(ServiceCapabilityIdentifier.Voice)) {
        return ServiceName.CallrouteMicrosoftTeams;
      }
      return ServiceName.Orto;
    case ServiceType.WebexCalling:
      return ServiceName.WebexCalling;
    case ServiceType.SIPTrunk:
      return ServiceName.SIPTrunk;
    case ServiceType.SIPPhone:
      return ServiceName.SIPPhone;
    case ServiceType.CallForwarding:
      return ServiceName.CallForwarding;
    case ServiceType.Carrier:
      return ServiceName.Carrier;
    default:
      return null;
  }
};

export const getServiceIcon = (serviceType: ServiceType, isColor: boolean, isOrto?: boolean): string => {
  switch (serviceType) {
    case ServiceType.MicrosoftTeams:
      if (isOrto) {
        return '/assets/images/orto/orto_icon.svg';
      }
      return isColor ? 'cr-ms-teams-two-tone' : 'cr-microsoft-teams';
    case ServiceType.WebexCalling:
      return isColor ? '/assets/icons/webex.png' : 'cr-webex-calling';
    case ServiceType.SIPTrunk:
      return 'cr-dns';
    case ServiceType.SIPPhone:
      return 'cr-sip-phone';
    case ServiceType.CallForwarding:
      return 'cr-call-forwarding';
    case ServiceType.Carrier:
      return 'cr-tower-cell';
    default:
      return null;
  }
};

export const getServiceSlug = (serviceType: ServiceType): string => {
  switch (serviceType) {
    case ServiceType.MicrosoftTeams:
      return ServiceSlug.MicrosoftTeams;
    case ServiceType.WebexCalling:
      return ServiceSlug.WebexCalling;
    case ServiceType.SIPTrunk:
      return ServiceSlug.SIPTrunk;
    case ServiceType.SIPPhone:
      return ServiceSlug.SIPPhone;
    case ServiceType.CallForwarding:
      return ServiceSlug.CallForwarding;
    case ServiceType.Carrier:
      return ServiceSlug.Carrier;
    default:
      return null;
  }
};

export const getServiceTypeBySlug = (slug: ServiceSlug): ServiceType => {
  switch (slug) {
    case ServiceSlug.MicrosoftTeams:
      return ServiceType.MicrosoftTeams;
    case ServiceSlug.WebexCalling:
      return ServiceType.WebexCalling;
    case ServiceSlug.SIPTrunk:
      return ServiceType.SIPTrunk;
    case ServiceSlug.SIPPhone:
      return ServiceType.SIPPhone;
    case ServiceSlug.CallForwarding:
      return ServiceType.CallForwarding;
    case ServiceSlug.Carrier:
      return ServiceType.Carrier;
    default:
      return null;
  }
};

export const getServiceTypeByName = (slug: ServiceName): ServiceType => {
  switch (slug) {
    case ServiceName.CallrouteMicrosoftTeams:
    case ServiceName.Orto:
    case ServiceName.MicrosoftTeams:
      return ServiceType.MicrosoftTeams;
    case ServiceName.WebexCalling:
      return ServiceType.WebexCalling;
    case ServiceName.SIPTrunk:
      return ServiceType.SIPTrunk;
    case ServiceName.SIPPhone:
      return ServiceType.SIPPhone;
    case ServiceName.CallForwarding:
      return ServiceType.CallForwarding;
    case ServiceName.Carrier:
      return ServiceType.Carrier;
    default:
      return null;
  }
};

export function getMaximumServicesCount(packageCapabilities: PackageCapabilities): number {
  const max = packageCapabilities?.attributes?.[`SERVICE_COUNT_MAXIMUM`];
  if (max === null || max === undefined) {
    return 100;
  }
  return max;
}

export function getMaximumServiceTypeCount(serviceType: ServiceType, packageCapabilities: PackageCapabilities): number {
  const max = (packageCapabilities?.attributes as any)?.[`SERVICE_${ serviceType }_COUNT_MAXIMUM`];
  if (max === null || max === undefined) {
    return 100;
  }
  return max;
}

export function canCreateService(serviceType: ServiceType, totalCount: number, serviceTypeCount: number, packageCapabilities: PackageCapabilities): boolean {
  const maxServices    = getMaximumServicesCount(packageCapabilities);
  const maxServiceType = getMaximumServiceTypeCount(serviceType, packageCapabilities);

  return totalCount < maxServices && serviceTypeCount < maxServiceType;
}

export const SERVICES: ServiceType[]       = [ServiceType.MicrosoftTeams, ServiceType.WebexCalling, ServiceType.SIPTrunk, ServiceType.SIPPhone, ServiceType.CallForwarding];
export const SUCCESS_STATUSES: Status[]    = [ServiceUIStatus.Success, ServiceStatus.Active, TokenStatus.Active];
export const WARNING_STATUSES: Status[]    = [ServiceUIStatus.Failed, ServiceUIStatus.Warning, ServiceUIStatus.NeedsAction, TokenStatus.Error];
export const FAILED_STATUSES: Status[]     = [ServiceUIStatus.Failed];
export const INCOMPLETE_STATUSES: Status[] = [ServiceUIStatus.Failed, ServiceUIStatus.Processing, ServiceUIStatus.Pending, ServiceStatus.Provisioning, ServiceUIStatus.NeedsAction, ServiceStatus.Inactive, ServiceStatus.Unavailable];
export const PROCESSING_STATUSES: Status[] = [ServiceUIStatus.Processing, ServiceStatus.Provisioning, TokenStatus.Processing];

export type HealthState = Map<ServiceHealthCheckName, boolean>;
export type RequirementsState = Map<ServiceRequirementCheckName, boolean>;

