import { ChartMultiSeries }        from '@models/chart/chart-multi-series.model';
import { ChartSeries }             from '@models/chart/chart-series.model';
import { ReportSeriesData }        from '@models/entity/report-series-data.model';
import { TotalNumbersPerXDataRaw } from '@models/entity/total-numbers-per-x-data-raw.model';
import { NumberReportViewMode }    from '@enums/number-report-view-mode.enum';

export class TotalNumbersPerTypeData {
  series: { id: string, name: string }[];
  points: {
    date: string;
    name: string;
    count: number;
  }[];

  constructor(private data?: TotalNumbersPerTypeData) {
    Object.assign(this, { ...this.data || {} });
    delete this['data'];
  }

  fromApiData?(apiData: TotalNumbersPerXDataRaw): TotalNumbersPerTypeData {
    this.series = (apiData?.groups ? Object.entries(apiData.groups)
      .map(([id, name]) => ({ id, name })) : apiData.series)?.map(p => ReportSeriesData.fromApiData(p)) || [];

    this.points = [];

    for (const [date, val] of Object.entries(apiData.points)) {
      for (const [group, data] of Object.entries(val)) {
        this.points.push({
          name:  this.series?.find(s => s.id === group)?.name,
          count: +data.count,
          date,
        });
      }
    }

    return this;
  }

  toChartMultiSeries?(viewMode?: NumberReportViewMode): (ChartMultiSeries | ChartSeries)[] {
    const points = this.points;
    const series = this.series;

    if (viewMode === NumberReportViewMode.Overview) {
      return series?.map(s => {
        const point = points.find(p => p.name === s.name);
        return {
          name:  point.name,
          value: point.count,
          extra: {
            code: point.name,
          },
        } as ChartSeries;
      })
        ?.sort((a, b) => {
          const aSum = a.value;
          const bSum = b.value;
          return bSum - aSum;
        }) || [];
    }

    return this.series?.map(s => {
        return {
          name:   s.name,
          series: points?.length ? points.filter(p => p.name === s.name)
            .map(d => {
              return {
                name:  d.date,
                value: d.count,
                extra: {
                  code: d.name,
                },
              } as ChartSeries;
            }) : [],
        };
      },
    ) || [];
  }
}
