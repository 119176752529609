import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA }   from '@angular/material/dialog';
import { HealthInfo }        from '@models/api/health-info.model';

@Component({
  selector:    'ngx-ms-teams-extra-info-modal',
  templateUrl: './ms-teams-extra-info-modal.component.html',
  styleUrls:   ['./ms-teams-extra-info-modal.component.scss'],
})
export class MsTeamsExtraInfoModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: HealthInfo) { }

}
