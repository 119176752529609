export enum ServiceUIStatus {
  Failed      = 'FAILED',
  Pending     = 'PENDING',
  Processing  = 'PROCESSING',
  Warning     = 'WARNING',
  Success     = 'SUCCESS',
  Suspended   = 'SUSPENDED',
  NeedsAction = 'NEEDS_ACTION',
  Deleted     = 'DELETED'
}
