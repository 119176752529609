import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  animate,
  animateChild,
  query,
  stagger,
  style,
  transition,
  trigger,
}                                                                                              from '@angular/animations';
import {
  TaskManagerService,
}                                                                                              from '@services/task-manager.service';
import {
  RouterService,
}                                                                                              from '@services/router.service';
import {
  Company,
}                                                                                              from '@models/entity/company.model';
import { trackBy }                                                                             from '@util/trackBy';
import {
  MatMenuTrigger,
}                                                                                              from '@angular/material/menu';
import {
  User,
}                                                                                              from '@models/entity/user.model';
import {
  NotificationItem,
}                                                                                              from '@models/entity/notification.model';
import { DOCUMENT }                                                                            from '@angular/common';
import {
  ServiceItem,
}                                                                                              from '@models/entity/service-item.model';
import {
  StateTask,
}                                                                                              from '@models/entity/state-task.model';
import {
  AssetsService,
}                                                                                              from '@services/assets.service';
import {
  getServiceSlug,
}                                                                                              from '@models/api/service.model';
import {
  Task,
}                                                                                              from '@models/entity/task.model';
import { ToastrService }                                                                       from 'ngx-toastr';
import {
  TextNotificationComponent,
}                                                                                              from '../../../../modules/notifications/text-notification/text-notification.component';

@Component({
  selector:    'ngx-page-header',
  templateUrl: './page-header.component.html',
  styleUrls:   ['./page-header.component.scss'],
  animations:  [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('0.2s ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
    trigger('list', [
      transition('void => *', [
        query('@*',
          stagger(500, animateChild()),
          { optional: true },
        ),
      ]),
    ]),
  ],
})
export class PageHeaderComponent implements OnChanges {
  @ViewChild('notiMenuTrigger') notiMenuTrigger: MatMenuTrigger;

  @Input() userProfile: User;
  @Input() pageTitle: string;
  @Input() pageTitleSuffix: string;
  @Input() pageTooltip: string;
  @Input() ownCompany: Company;
  @Input() isViewingCompany: boolean;
  @Input() provisioningServices: ServiceItem[];
  @Input() viewingCompanyId: string;
  @Input() logoUrl: string;
  @Input() borderColor: string;
  @Input() notificationCount: number;
  @Input() newAlertNotifications: Array<NotificationItem>;
  @Input() newBannerNotifications: Array<NotificationItem>;
  @Input() notificationPendingTasks: Array<StateTask>;
  @Input() tasks: Task[];
  @Input() incompleteTaskCount: number;
  @Input() isSipAdmin: boolean;
  @Input() hasNotificationRead: boolean;
  @Input() hasTaskRead: boolean;
  @Output() openNotificationPage = new EventEmitter();
  @Output() closeNotification    = new EventEmitter();
  @Output() signOut              = new EventEmitter();
  private shownIds: string[]     = [];

  avatarUrl      = AssetsService.getIcon('avatar-profile.svg');
  trackBy        = trackBy<NotificationItem>();
  trackServiceBy = trackBy<ServiceItem>();

  constructor(
    private router: RouterService,
    private taskManagerService: TaskManagerService,
    private toastr: ToastrService,
    @Inject(DOCUMENT) private _document: Document,
  ) { }

  goToService(service: ServiceItem): Promise<boolean | void> {
    if (this.viewingCompanyId) {
      return this.router.navigate(['/services', getServiceSlug(service.serviceType), service.id], undefined, {
        fragment:            'configure',
        queryParamsHandling: 'preserve',
      });
    }
    this._document.defaultView.open(`/services/${ getServiceSlug(service.serviceType) }/${ service.id }?company=${ this.ownCompany.id }#configure`);
  }

  ngOnChanges(c: SimpleChanges): void {
    const notifications: NotificationItem[] = c?.newAlertNotifications?.currentValue;
    if (!notifications?.length) {
      return;
    }
    for (const notification of notifications.filter(notify => !this.shownIds.includes(notify.id))
      .slice(0, 3)) {
      const toast = this.toastr.info(
        '',
        '',
        { timeOut: 10_000, toastComponent: TextNotificationComponent },
      );
      this.shownIds.push(notification.id);
      toast.toastRef.componentInstance.notification = notification;
      toast.toastRef.componentInstance.toastId      = toast.toastId;
    }
  }

  navigateToPage(url: string, fragment?: string): Promise<boolean> {
    return this.router.navigate([url], undefined, { fragment });
  }

  isPending(notification: NotificationItem): boolean {
    return this.notificationPendingTasks.some(task => task.id === 'MARK_AS_READ_'.concat(notification.id));
  }

  getServiceSlug(service: ServiceItem): string {
    return getServiceSlug(service.serviceType);
  }

  toggleTaskManager(): void {
    this.taskManagerService.toggle.next(!this.taskManagerService.toggle.value);
  }

}
