<ngx-modal-head header="Persona migration tool" headerIcon="cr-arrows-left-right"
                [closeable]="true"
                subheader="Migrate all users from one user persona to another."></ngx-modal-head>
<ngx-modal-content class="mat-typography">
  <form [formGroup]="formGroup">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <mat-form-field appearance="outline" fxFlex="0 0 45">
        <mat-label>From</mat-label>
        <input matInput type="text"
               formControlName="fromProfile"
               autocomplete="off"
               data-cy="form-profile-from"
               placeholder="- Select -"
               [matAutocomplete]="profileComplete" />
      </mat-form-field>
      <mat-autocomplete autoActiveFirstOption
                        #profileComplete="matAutocomplete"
                        class="option-line-height-1"
                        [displayWith]="getProfileName">
        <mat-option *ngFor="let profile of fromProfiles$ | async"
                    [value]="profile">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div>
              <p class="m-0 color-black">{{profile.name}}</p>
            </div>
          </div>
        </mat-option>
        <mat-option
          *ngIf="!(profiles$ | async)?.length"
          [value]="null">
          <p class="m-0 color-black">No results found</p>
        </mat-option>
      </mat-autocomplete>
      <mat-icon svgIcon="cr-arrow-forward"></mat-icon>
      <mat-form-field appearance="outline" fxFlex="0 0 45">
        <mat-label>To</mat-label>
        <input matInput type="text"
               formControlName="toProfile"
               autocomplete="off"
               data-cy="form-profile-search"
               placeholder="- Select -"
               [matAutocomplete]="profileComplete1" />
      </mat-form-field>
      <mat-autocomplete autoActiveFirstOption
                        #profileComplete1="matAutocomplete"
                        class="option-line-height-1"
                        [displayWith]="getProfileName">
        <mat-option *ngFor="let profile of toProfiles$ | async"
                    [value]="profile">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div>
              <p class="m-0 color-black">{{profile.name}}</p>
            </div>
          </div>
        </mat-option>
        <mat-option
          *ngIf="!(profiles$ | async)?.length"
          [value]="null">
          <p class="m-0 color-black">No results found</p>
        </mat-option>
      </mat-autocomplete>
    </div>
  </form>
</ngx-modal-content>
<br>
<ngx-modal-actions>
  <button class="button primary" #modalAction type="button" data-cy="submit"
          [disabled]="isInvalid$ | async"
          (click)="submit()">Migrate
  </button>
  <button class="button secondary" #modalAction type="button" data-cy="close" [mat-dialog-close]="false">Cancel
  </button>
</ngx-modal-actions>
