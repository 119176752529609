<ng-container *ngIf="!isMaintenanceMode">
  <ng-container *ngIf="combinedReducers$ | async as combinedReducers">
    <ng-container *ngIf="(viewInit$ | async)">
      <div class="page-container" #pageContainer [class.minimize]="showAuthComponents$ | async">
        <ng-container *ngIf="showAuthComponents$ | async">
          <ng-container *ngIf="combinedReducers.branding">
            <ngx-view-customer-header *ngIf="viewingCompanyUUID$ | async"
                                      class="shadow-lg"
                                      [isSipAdmin]="isSipAdmin$ | async"
                                      [style.background-color]="combinedReducers.branding?.style_data?.colours?.primary ||
                                      defaultColours.primary"
                                      [companyData]="combinedReducers.company"></ngx-view-customer-header>
            <ngx-trial-expiry-header
              fxHide.lt-md
              [backgroundColor]="trialBackgroundColor$ | async"
              [trialLicenseExpiry]="trialLicenseExpiry$ | async"
              [freeCredit]="freeCredit$ | async"
              [isFreePackage]="isFreePackage$ | async"
              [isViewingCompany]="!!(viewingCompanyUUID$ | async)"
            >
            </ngx-trial-expiry-header>
            <ngx-no-local-login-banner
              fxHide.lt-md
              *ngIf="noLocalUsers$ | async"
              [isViewingCompany]="!!(viewingCompanyUUID$ | async)"
            >
            </ngx-no-local-login-banner>
            <ngx-global-banner
              fxHide.lt-md
              *ngIf="(showGlobalBanner$ | async) && (globalBannerText$ | async)"
              [isViewingCompany]="!!(viewingCompanyUUID$ | async)"
              [text]="globalBannerText$ | async"
            >
            </ngx-global-banner>
            <ngx-main-menu #mainMenu [navItems]="navItems$ | async"
                           class="shadow-lg"
                           [style.background]="combinedReducers.branding?.style_data?.colours?.menu || defaultColours.menu"
                           [primaryColor]="combinedReducers.branding?.style_data?.colours?.primary ||
                            defaultColours.primary"></ngx-main-menu>
          </ng-container>
          <ngx-page-header class="header-row display-flex"
                           [class.has-company-header]="viewingCompanyUUID$ | async"
                           [logoUrl]="headerLogoUrl$ | async"
                           [userProfile]="combinedReducers.auth.user"
                           [isSipAdmin]="isSipAdmin$ | async"
                           [tasks]="tasks$ | async"
                           [incompleteTaskCount]="incompleteTaskCount$ | async"
                           [isViewingCompany]="!!(viewingCompanyUUID$ | async)"
                           [pageTitle]="combinedReducers.auth.pageTitle"
                           [pageTitleSuffix]="combinedReducers.auth.pageTitleSuffix"
                           [pageTooltip]="combinedReducers.auth.pageTooltip"
                           [borderColor]="combinedReducers.branding?.style_data?.colours?.logo_border"
                           [viewingCompanyId]="combinedReducers.auth.viewCompanyUUID"
                           [ownCompany]="combinedReducers.company.ownCompanyData"
                           [provisioningServices]="provisioningServices$ | async"
                           [notificationCount]="unreadCount$ | async"
                           [newAlertNotifications]="newAlertNotifications$ | async"
                           [newBannerNotifications]="newBannerNotifications$ | async"
                           [notificationPendingTasks]="combinedReducers.notification.pendingTasks"
                           [hasNotificationRead]="hasNotificationRead$ | async"
                           [hasTaskRead]="hasTaskRead$ | async"
                           (openNotificationPage)="openNotificationPage()"
                           (closeNotification)="closeNotification($event)"
                           (signOut)="signOut()"></ngx-page-header>
        </ng-container>
        <div class="page-view" data-cy="page-content" [class.minimize]="showAuthComponents$ | async"
             [class.background]="showBackground$ | async">
          <ng-container *ngIf="!(showAuthComponents$ | async) && (routeLoaded$ | async)">
            <div class="logo-container" *ngIf="!(isRedirect$ | async)">
              <a href="/"><img
                src="{{logoUrl}}"
                alt="Callroute logo" /></a>
            </div>
          </ng-container>
          <router-outlet (activate)="routeLoaded.next(true)"
                         (deactivate)="routeLoaded.next(false)"></router-outlet>
          <ng-container *ngIf="showAuthComponents$ | async">
            <ngx-task-manager [tasks]="tasks$ | async"
                              [selectedTask]="selectedTask$ | async"
                              [incompleteCount]="incompleteTaskCount$ | async"
                              [taskQueryParams]="taskQueryParams$ | async"
                              [isSipAdmin]="isSipAdmin$ | async"
                              [userId]="(user$ | async)?.id"
                              [hasAdminAudit]="hasAdminAudit$ | async"
                              [hasReportRead]="hasReportRead$ | async"
                              [counts]="taskCounts$ | async"></ngx-task-manager>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<ng-container *ngIf="isMaintenanceMode">
  <ngx-maintenance-page></ngx-maintenance-page>
</ng-container>
