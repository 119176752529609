import { Injectable }                        from '@angular/core';
import { FilterOption }                      from '@models/form/filter-option.model';
import { ServiceUserStatusForm }             from '@models/form/status-form.model';
import { ListFilter }                        from '@models/form/list-filter.model';
import { UntypedFormGroup }                  from '@angular/forms';
import { BehaviorSubject }                   from 'rxjs';
import { StoreState }                        from '@redux/store';
import { Store }                             from '@ngrx/store';
import { QueryService }                      from '@services/abstract/query-service.class';
import { TeamsUserQueryParams }              from '@models/form/teams-user-query-params.model';
import { FetchServiceUserListRequestAction } from '@redux/service/service.actions';
import { PaginationFormModel }               from '@models/form/pagination-form.model';
import { ServiceUserStatus }                 from '@enums/service-user-status.enum';
import { ServiceUserCapability }             from '@enums/service-user-capability.enum';
import { FormService }                       from '@services/form.service';
import { AssetsService }                     from '@services/assets.service';
import { CallingProfile }                    from '@models/entity/calling-profile.model';

@Injectable({
  providedIn: 'root',
})
export class ServiceUserQueryService implements QueryService<TeamsUserQueryParams> {
  static getStatusFilterOptions(isOrto: boolean): FilterOption[] {
    return [
      !isOrto && { label: 'Callroute PSTN', key: ServiceUserStatus.PSTNEnabled, value: true, color: 'green' },
      { label: 'Calling Plan', key: ServiceUserStatus.CallingPlan, value: true, color: 'pink' },
      { label: 'Operator Connect', key: ServiceUserStatus.OperatorConnect, value: true, color: 'pink' },
      { label: 'Direct Routing', key: ServiceUserStatus.DirectRouting, value: true, color: 'pink' },
      { label: 'Phone Capable', key: ServiceUserStatus.PhoneDisabled, value: true, color: 'purple' },
      { label: 'Licensed (Non-Phone)', key: ServiceUserStatus.LicensedNonPhone, value: true, color: 'orange' },
      { label: 'Unlicensed', key: ServiceUserStatus.Unlicensed, value: false, color: 'light-gray' },
    ].filter(i => !!i);
  }

  static getCapabilitiesFilterOptions(): FilterOption<ServiceUserCapability>[] {
    return [
      {
        label: 'Teams enabled',
        icon:  AssetsService.getIcon('microsoft-teams-two-tone.svg'),
        key:   ServiceUserCapability.Teams,
        value: true,
      },
      {
        label: 'Direct Routing',
        icon:  AssetsService.getIcon('arrow-to-dot.svg'),
        key:   ServiceUserCapability.DirectRouting,
        value: true,
      },
      {
        label: 'Operator Connect',
        icon:  AssetsService.getIcon('cloud_phone.svg'),
        key:   ServiceUserCapability.MicrosoftOperatorConnect,
        value: true,
      },
      {
        label: 'Calling Plan',
        icon:  AssetsService.getIcon('microsoft.png'),
        key:   ServiceUserCapability.MicrosoftCallingPlan,
        value: true,
      },
    ];
  }

  static getManagementFilterOptions(): FilterOption[] {
    return [
      {
        label:     'Managed',
        icon:      'cr-tick-circle',
        iconClass: 'color-green',
        key:       'isManaged',
        value:     true,
      },
      {
        label:     'Unmanaged',
        icon:      'cr-dash',
        iconClass: 'color-near-black',
        key:       'notManaged',
        value:     true,
      },
    ];
  }

  sortFilters: ListFilter[] = [
    new ListFilter({
      id:           'is_resource_account',
      value:        false,
      label:        'Type',
      directionAsc: false,
      canSort:      true,
      hideSm:       true,
      filterClass:  'accountType',
    }),
    new ListFilter({
      id:           'display_name',
      value:        false,
      label:        'Name',
      directionAsc: false,
      canSort:      true,
      filterClass:  'displayName',
    }),
    new ListFilter({
      id:           'cli',
      value:        false,
      label:        'LineURI',
      directionAsc: false,
      canSort:      true,
      filterClass:  'cli',
      link:         'https://help.callroute.com/space/CKB/721092743/What+Does+LineURI+Mean%3F',
    }),
    new ListFilter({
      id:           'capabilities',
      value:        false,
      label:        'Capabilities',
      directionAsc: false,
      canSort:      false,
      filterClass:  'capabilities',
      tooltip:      'The users licensed capabilities.',
      link:         'https://help.callroute.com/space/CKB/723353613/User+Account+Statuses+and+Capabilities#User-Capabilities',
    }),
    new ListFilter({
      id:           'status',
      value:        false,
      label:        'Status',
      directionAsc: false,
      canSort:      true,
      filterClass:  'status',
      link:         'https://help.callroute.com/space/CKB/723353613/User+Account+Statuses',
    }),
    new ListFilter({
      id:           'is_managed',
      value:        false,
      label:        'Orto Managed',
      directionAsc: false,
      canSort:      true,
      filterClass:  'is_managed',
    }),
  ];

  hasFiltered  = new BehaviorSubject<boolean>(false);
  hasFiltered$ = this.hasFiltered.asObservable();

  constructor(private store: Store<StoreState>) { }

  updateFilter(filterForm: UntypedFormGroup, curParams: TeamsUserQueryParams, serviceId: string): void {
    this.hasFiltered.next(true);
    this.fetchList(
      this.getQueryParams(filterForm, curParams ? curParams.pageSize : 25, 1, curParams ? curParams.sort : null),
      true,
      serviceId,
    );
  }

  updatePageQuery(filterForm: UntypedFormGroup, params: PaginationFormModel, curParams: TeamsUserQueryParams, serviceId: string): void {
    this.fetchList(
      this.getQueryParams(filterForm, params.pageSize, params.pageNumber, curParams.sort),
      true,
      serviceId,
    );
  }

  updateSort(filterForm: UntypedFormGroup, sortId: string, canSort: boolean, curParams: TeamsUserQueryParams, serviceId: string): void {
    if (!canSort) {
      return;
    }
    this.fetchList(
      this.getQueryParams(filterForm, curParams.pageSize, 1, sortId),
      true,
      serviceId,
    );
  }

  getQueryParams(filterForm: UntypedFormGroup, pageSize: number, pageNumber: number, sort: string): TeamsUserQueryParams {
    const s: TeamsUserQueryParams = new TeamsUserQueryParams();

    s.pageSize   = pageSize;
    s.pageNumber = pageNumber;
    s.sort       = sort || 'upn';

    if (filterForm.get('search').value && (filterForm.get('search').value as string).length >= 3) {
      s.search = filterForm.get('search').value as string;
    }

    if (filterForm.get('tags')?.value && (filterForm.get('tags').value as string[]).length > 0) {
      s.numberTag = filterForm.get('tags').value;
    }

    const isManaged    = filterForm.get('management')
      .get('isManaged').value;
    const isNotManaged = filterForm.get('management')
      .get('notManaged').value;

    if (isManaged !== isNotManaged) {
      s.isManaged = isManaged;
    }

    s.status = FormService.generateArrayFromControlGroup<ServiceUserStatusForm>(filterForm.get('status').value);

    s.capabilities = FormService.generateArrayFromControlGroup(filterForm.get('capabilities').value);

    s.isResourceAccount = TeamsUserQueryParams.getIsResourceAccount(filterForm.value);

    s.profileIds = filterForm.get('userPersonas')
      ?.value
      .map((persona: CallingProfile) => persona.id);

    return s;
  }

  fetchList(queryParams: TeamsUserQueryParams, emitEvent = true, serviceId: string): void {
    this.store.dispatch(FetchServiceUserListRequestAction({
      serviceId,
      emitEvent,
      queryParams,
    }));
  }
}
