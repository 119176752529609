import { Component, Inject, Input, OnChanges, OnDestroy, Optional, SimpleChanges }    from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }                                              from '@angular/material/dialog';
import {
  Carrier,
}                                                                                     from '@models/entity/carrier.model';
import { AuthScope }                                                                  from '@enums/auth-scope.enum';
import { debounceTime, filter, map, pluck, startWith, take, takeUntil, tap, timeout } from 'rxjs/operators';
import { ScopeService }                                                               from '@services/scope.service';
import { Store }                                                                      from '@ngrx/store';
import { StoreState }                                                                 from '@redux/store';
import { BehaviorSubject, combineLatest, Observable, Subject }                        from 'rxjs';
import {
  ServiceState,
}                                                                                     from '@redux/service/service.reducer';
import { UntypedFormArray, UntypedFormGroup, Validators }                             from '@angular/forms';
import {
  selectService,
}                                                                                     from '@redux/service/service.selectors';
import {
  PATCH_SERVICE_REQUEST,
  POST_SERVICE_REQUEST,
}                                                                                     from '@redux/service/service.types';
import { DismissErrorAction, DismissMessageAction }                                   from '@redux/ui/ui.actions';
import {
  ServiceItemRaw,
}                                                                                     from '@models/api/service-api-response.model';
import {
  PatchServiceRequestAction,
  PostServiceRequestAction,
}                                                                                     from '@redux/service/service.actions';
import { ButtonType }                                                                 from '@enums/button-type.enum';
import { FormService }                                                                from '@services/form.service';
import {
  ServiceCarrier,
}                                                                                     from '@models/entity/carrier-service.model';
import { CarrierService }                                                             from '@services/carrier.service';
import {
  ServiceCarrierRaw,
} from '@models/api/carrier-service-raw.model';
import {
  NumberQueryParams,
} from '@models/form/number-query-params.model';

import { NumberState }                  from '@redux/number/number.reducer';
import { selectNumber }                 from '@redux/number/number.selectors';
import { ServiceItem }                  from '@models/entity/service-item.model';
import { NumberItem }                   from '@models/entity/number-item.model';
import { ConfigurableCarrierInput }     from '@enums/configurable-carrier-input.enum';
import { CarrierActionService }         from '@services/carrier-action.service';
import { ContactType }                  from '@enums/contact-type.enum';
import { FetchNumberListRequestAction } from '@redux/number/number.actions';

@Component({
  selector:    'ngx-configure-carrier-modal',
  templateUrl: './configure-carrier-modal.component.html',
  styleUrls:   ['./configure-carrier-modal.component.scss'],
})
export class ConfigureCarrierModalComponent implements OnChanges, OnDestroy {
  @Input() service: ServiceCarrier;
  serviceReducer$: Observable<ServiceState>;
  formGroup: UntypedFormGroup;
  editMode           = new BehaviorSubject(false);
  editMode$: Observable<boolean>;
  destroy            = new Subject<void>();
  destroy$           = this.destroy.asObservable();
  isPending$: Observable<boolean>;
  submitDisabled$: Observable<boolean>;
  btnDisabled$: Observable<boolean>;
  formValueChanged$: Observable<boolean>;
  numberReducer$: Observable<NumberState>;
  ButtonType         = ButtonType;
  carrierServiceData: ServiceCarrier;
  containsDupes      = new BehaviorSubject(false);
  containsDupes$     = this.containsDupes.asObservable();
  private formFields = new Map<keyof ServiceCarrierRaw | string, string>([
    ['label', 'Label'],
    ['address_spaces', 'Address spaces'],
    ['registration', 'Registration'],
    ['registration.username', 'Username'],
    ['registration.password', 'Password'],
    ['is_sip_options_enabled', 'Is SIP options enabled'],
  ]);

  get contactUri(): string {
    if (!this.service?.carrierData?.contacts?.length) {
      return null;
    }
    return this.service.carrierData.contacts.find(contact => contact.type === ContactType.Sales && contact.uri)?.uri;
  }

  get getHeader(): string {
    if (this.service?.carrierData?.identifier === 'byod-international') {
      return 'Connect a Session Border Controller (SBC)';
    }

    return `Configure ${ this.service.label || this.service.name || 'custom carrier' }`;
  }

  get saveBtnText(): string {
    if (this.service?.id) {
      return 'Save';
    }
    return this.service?.carrierData?.identifier === 'byod-international' ? 'Add SBC' : 'Add carrier';
  }

  get showContactInfo(): boolean {
    return !['custom', 'byod-international'].includes(this.service?.carrierData?.identifier);
  }

  get subheader(): string {
    if (this.service?.carrierData?.identifier === 'byod-international') {
      return 'For more information about connecting an SBC please refer to the ' +
        '<a href="https://help.callroute.com/space/CKB/697565223/Bring+Your+Own+SBC" ' +
        'target="_blank">knowledge base article</a>.';
    }
    return 'For more information about connecting a carrier please refer to the ' +
      '<a href="https://help.callroute.com/space/CKB/698941467/Bring+Your+Own+Carrier" ' +
      'target="_blank">knowledge base article</a>.';
  }

  constructor(
    private store: Store<StoreState>,
    private carrierService: CarrierService,
    private scopeService: ScopeService,
    private carrierActionService: CarrierActionService,
    @Optional() public dialogRef: MatDialogRef<ConfigureCarrierModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: { service: Carrier }) {
    this.btnDisabled$   = this.scopeService.hasScopes$(AuthScope.ServiceWrite)
      .pipe(map(hasScope => !hasScope));
    this.numberReducer$ = this.store.select(selectNumber);
    if (this.dialogRef) {
      this.initForm(this.data.service);
    }
  }

  private initForm(carrier: Carrier): void {
    if (!this.service) {
      this.service = new ServiceCarrier({
        label:                  carrier.name,
        gateways:               carrier.gateways,
        isSipOptionsEnabled:    !!carrier?.isSipOptionsEnabled,
        isRegistrationRequired: !!carrier?.isRegistrationRequired,
        carrierData:            carrier,
      } as ServiceCarrier);
    }
    this.serviceReducer$ = this.store.select(selectService);

    this.isPending$ = this.serviceReducer$.pipe(map(reducer =>
      reducer.pendingTasks.some(task => [POST_SERVICE_REQUEST, PATCH_SERVICE_REQUEST].includes(task.id))));

    this.carrierServiceData = this.service as ServiceCarrier;

    this.formGroup = this.carrierService.getNewForm(this.carrierServiceData);

    if (this.carrierServiceData.carrierData.identifier === 'custom') {
      this.formGroup.markAsTouched();
    }

    this.setupValidators();

    this.editMode$ = this.editMode.asObservable()
      .pipe(
        map(edit => edit || this.isSetup()),
        tap(edit => {
          ['isSipOptionsEnabled', 'useDNSSRV', 'isRegistrationRequired'].forEach(key => {
            if (!this.formGroup.get(key)) {
              return;
            }
            this.formGroup.get(key)[edit ? 'enable' : 'disable']();
          });
        }));

    this.setupRegistrationListener();

    this.fetchNumberList();

    this.formGroup.get('numberQuery')
      .valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(1_000))
      .subscribe(query => {
        if (!this.editMode.value && !this.isSetup()) {
          return;
        }
        if (!query) {
          this.resetNumberId();
        }
        this.fetchNumberList(query);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.service?.currentValue && !changes.service?.previousValue) {
      this.initForm(changes.service.currentValue);
    }
  }

  editClick(): void {
    if (this.editMode.value) {
      this.revertForm();
    }
    this.editMode.next(!this.editMode.value);
  }

  revertForm(): void {
    const data                   = this.carrierServiceData;
    const isRegistrationRequired = !!this.formGroup.get('isRegistrationRequired')?.value;
    const adapter                = this.carrierServiceData.carrierData.adapter;
    const registrarString        = adapter === 'bt-wholesale' ? data.getNormalisedPilotNumber() : data.registrarString;

    this.formGroup.reset({
      id:                  data.id,
      serviceType:         data.serviceType,
      label:               data.label,
      carrierData:         data.carrierData,
      carrier:             data.carrierData.identifier,
      isRegistrationRequired,
      username:            data.username,
      password:            data.password,
      realm:               data.realm,
      registrarAddress:    data.registrarAddress,
      numberId:            data.numberId,
      number:              data?.numberData,
      numberQuery:         data.numberData,
      registrarString,
      gateways:            data.gateways,
      isSipOptionsEnabled: !!data.isSipOptionsEnabled,
      addressSpaces:       [],
    });
    if (!this.formGroup.get('addressSpaces')) {
      return;
    }
    (this.formGroup.get('addressSpaces') as UntypedFormArray)
      .clear();
    let i = 0;
    for (const addressSpace of (data.addressSpaces || [])) {
      (this.formGroup.get('addressSpaces') as UntypedFormArray).insert(i, this.carrierService.newCIDRAddrCtrl(addressSpace));
      i++;
    }
  }

  isSetup(): boolean {
    if (!this.carrierServiceData?.id) {
      return true;
    }
    return Object.values(this.carrierServiceData)
      .every(val => !val);
  }

  async submitForm(): Promise<void> {
    if (this.formGroup.valid && !this.containsDupes.value) {
      this.store.dispatch(DismissErrorAction());
      this.store.dispatch(DismissMessageAction());

      const value      = this.formGroup.value;
      const formFields = this.formFields as unknown as Map<keyof ServiceItemRaw, string>;

      if (this.service?.id) {
        const requestId = Date.now()
          .toString();
        this.store.dispatch(PatchServiceRequestAction({
          serviceItem: { ...value },
          requestId,
          formFields,
        }));
        try {
          await this.serviceReducer$.pipe(
            pluck('message'),
            filter(message => !!message?.isSuccess && message.id === requestId),
            take(1),
            timeout(5_000),
            takeUntil(this.destroy$))
            .toPromise();
          this.formGroup.get('number')
            .setValue(value.numberQuery);

          this.setInitialData(value);

          this.setupValidators();
          this.formGroup.markAsUntouched();
          this.editClick();
        } catch {

        }

      } else {
        this.store.dispatch(PostServiceRequestAction({
          serviceItem: { ...value },
          formFields,
        }));
        await this.serviceReducer$.pipe(
          pluck('message'),
          filter(message => !!message?.isSuccess),
          take(1),
          takeUntil(this.destroy$))
          .toPromise();
        const newService = await this.serviceReducer$.pipe(
          pluck('selectedServiceItem'),
          filter(item => ServiceItem.isCarrier(item) && item.label === value.label),
          take(1),
          takeUntil(this.destroy$))
          .toPromise();
        this.dialogRef?.close(newService);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  openInformation(): Promise<void> {
    return this.carrierActionService.openConfigInformationModal(this.service);
  }

  private setupValidators(): void {
    const configurableKeys = new Map<ConfigurableCarrierInput, keyof ServiceCarrier>([
      [ConfigurableCarrierInput.Gateways, 'gateways'],
      [ConfigurableCarrierInput.IsSipOptionsEnabled, 'isSipOptionsEnabled'],
      [ConfigurableCarrierInput.Username, 'username'],
      [ConfigurableCarrierInput.Password, 'password'],
      [ConfigurableCarrierInput.AddressSpaces, 'addressSpaces'],
      [ConfigurableCarrierInput.Realm, 'realm'],
      [ConfigurableCarrierInput.RegistrarString, 'registrarString'],
      [ConfigurableCarrierInput.RegistrarAddress, 'registrarAddress'],
    ]);

    const compareKeys: (keyof ServiceCarrier)[] = [
      'label',
      'numberId',
    ];

    for (const editable of this.service.carrierData.inputs) {
      if (editable === ConfigurableCarrierInput.Username) {
        compareKeys.push('isRegistrationRequired');
      }
      compareKeys.push(configurableKeys.get(editable));
    }

    this.formValueChanged$ = FormService.formValueChanged$(
      this.formGroup,
      this.carrierServiceData,
      compareKeys);

    this.submitDisabled$ = combineLatest([
      this.containsDupes$,
      this.isPending$,
      FormService.isValid$(this.formGroup),
      this.formGroup.valueChanges.pipe(startWith(this.formGroup.value)),
      this.formValueChanged$])
      .pipe(
        map(([containsDupes, isPending, valid, value, changed]) => {
          if (value.carrierData.identifier !== 'custom') {
            return containsDupes || isPending || !valid || (this.carrierServiceData.id && !changed);
          }
          return containsDupes || isPending || !valid || this.formGroup.pristine || !changed;
        }));

  }

  fetchNumberList(query?: string): void {
    const q: NumberQueryParams = new NumberQueryParams();

    q.pageSize   = 50;
    q.pageNumber = 1;
    q.sort       = 'number_e164';

    if (((query as string) || '').length >= 3) {
      q.search = query;
    }
    q.status = ['active'];

    this.store.dispatch(FetchNumberListRequestAction({ queryParams: q }));
  }

  private resetNumberId(): void {
    const numberId = this.formGroup?.get('numberId')?.value;
    if (numberId) {
      this.formGroup.get('numberId')
        .setValue(null);
      this.formGroup.get('numberId')
        .updateValueAndValidity();
    }
  }

  private setInitialData(value: ServiceCarrier & { numberQuery: NumberItem }): void {
    this.carrierServiceData = new ServiceCarrier({
      ...this.carrierServiceData,
      carrierData:            value.carrierData,
      label:                  value.label,
      isRegistrationRequired: value.isRegistrationRequired,
      realm:                  value.realm,
      numberId:               value.numberId,
      numberData:             value.numberQuery,
      registrarAddress:       value.registrarAddress,
      registrarString:        value.registrarString,
      gateways:               value.gateways,
      isSipOptionsEnabled:    value.isSipOptionsEnabled,
      addressSpaces:          value.addressSpaces,
    } as ServiceCarrier);
  }

  private setupRegistrationListener(): void {
    if (!this.formGroup.contains('isRegistrationRequired')) {
      return;
    }

    const registrationRequiredFields = ['username', 'password', 'realm'];

    this.formGroup.get('isRegistrationRequired')
      .valueChanges
      .pipe(
        startWith(this.formGroup.get('isRegistrationRequired').value),
        takeUntil(this.destroy$),
      )
      .subscribe(req => {
        for (const field of registrationRequiredFields) {

          if (!this.formGroup.contains(field)) {
            return;
          }
          const isPatch             = !this.isSetup();
          const isRegistrationPatch = ['username', 'password'].includes(field) && isPatch;

          if (req && !isRegistrationPatch) {
            this.formGroup.get(field)
              .setValidators(Validators.required);
          } else {
            this.formGroup.get(field)
              .clearValidators();
          }
          this.formGroup.get(field)
            .updateValueAndValidity();
        }
      });
  }
}
