import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class AuditQueryParams extends ListParams implements ListParamModel<AuditQueryParams> {
  context?: string[];
  objectType?: string[];
  objectId?: string[];
  startDate?: string;
  endDate?: string;

  static constructQueryString(searchParams: AuditQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      context:    'filter[context][]',
      objectType: 'filter[object.type][]',
      objectId:   'filter[object.id][]',
      startDate:  'filter[audit_date][since]',
      endDate:    'filter[audit_date][until]',
    });
  }

  constructParams?(metaData: ListResponseMetadata): AuditQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.start_date) {
        this.startDate = metaData.filter.start_date;
      }
      if (metaData.filter.end_date) {
        this.endDate = metaData.filter.end_date;
      }
      if (metaData.filter.context) {
        this.context = metaData.filter.context;
      }
      if (metaData.filter.object_type) {
        this.objectType = metaData.filter.object_type;
      }
      if (metaData.filter.object_id) {
        this.objectId = metaData.filter.object_id;
      }
    }

    return this;
  }
}
