import { CompanyDomainRaw } from '@models/api/identity/company-domain-raw.model';

export class CompanyDomain {
  id: string;
  domain?: string;
  isLoginEnabled: boolean;
  provider?: string;

  constructor(private data?: CompanyDomain) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: CompanyDomainRaw): CompanyDomain {
    this.id             = data.id;
    this.domain         = data.domain;
    this.isLoginEnabled = data.is_login_enabled;
    this.provider       = data.provider;

    return this;
  }

  toApiData?(): Partial<CompanyDomainRaw> {
    return {
      is_login_enabled: this.isLoginEnabled,
    };
  }
}
