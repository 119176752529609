import { ListResponseMetadata }  from '@models/api/list-response-metadata.model';
import { ListParamModel }        from '@models/form/list-param-model';
import { Alert }                 from '@models/entity/alert.model';
import { ListParamModelFactory } from '@models/factory/list-param.factory';
import { ListParams }            from '@models/form/list-params.model';
import { DateService }           from '@services/date.service';
import { BaseRequest }           from '@redux/helpers/reducer.helper';
import { NotificationType }      from '@models/entity/notification-type.model';
import { NotificationDataRaw }   from '@models/api/identity/notification-item-raw.model';

export class NotificationItem {
  id: string;
  message: string;
  createdDate: string;
  createdTime: string;
  createdDatetime: number;
  statusColor: string;
  read: boolean;
  type: NotificationType;
  isNew: boolean;
  data: { context: string, help_uri: string };

  constructor(private item?: NotificationItem) {
    Object.assign(this, { ...(this.item || {}) });
    delete this['item'];
  }

  fromApiData?(apiData: NotificationDataRaw, dateService: DateService): NotificationItem {
    this.id              = apiData.id;
    this.message         = apiData.message;
    this.createdDatetime = Date.parse(apiData.created_date);
    this.createdDate     = dateService.UTCtoDate(apiData.created_date);
    this.createdTime     = dateService.UTCtoTime(apiData.created_date);
    this.read            = apiData.is_read;
    this.type            = apiData.type;
    this.statusColor     = this.read ? '' : 'blue';
    this.data            = apiData.data;

    return this;
  }
}

export class NotificationDataFactory implements ListParamModelFactory<NotificationItem> {
  create(): NotificationItem {
    return new NotificationItem();
  }
}

export class NotificationSearchParams extends ListParams implements ListParamModel<NotificationSearchParams> {
  id?: string;
  showAll?: boolean;

  static constructQueryString(searchParams: NotificationSearchParams): string {
    return ListParams.constructQueryString(searchParams, {
      showAll: 'filter[show_all]',
    });
  }

  static queryChanged(newQuery: NotificationSearchParams, prevQuery: NotificationSearchParams): boolean {
    return !prevQuery || ListParams.queryChanged(newQuery, prevQuery);
  }

  constructParams?(metaData: ListResponseMetadata): NotificationSearchParams {
    super.constructParams(metaData);
    if (metaData?.filter?.show_all) {
      this.showAll = metaData.filter.show_all;
    }
    return this;
  }
}

export class NotificationSearchParamsFactory implements ListParamModelFactory<NotificationSearchParams> {
  create(): NotificationSearchParams {
    return new NotificationSearchParams();
  }
}

export interface FetchNotificationCountResponse {
  error: Alert;
  unreadCount: number;
}

export interface FetchNotificationListRequest extends BaseRequest {
  queryParams?: NotificationSearchParams;
}

export interface FetchNotificationListResponse {
  error: Alert;
  models: Array<NotificationItem>;
  searchParams: NotificationSearchParams;
}


export interface PollNotificationListResponse {
  error: Alert;
  models: Array<NotificationItem>;
}

export interface MarkAsReadRequest extends BaseRequest {
  notificationId: string;
  notificationType: string;
}

export interface MarkAsReadResponse {
  error: Alert;
  notificationId: string;
}

