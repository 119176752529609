import { PolicyGroup } from '@models/api/policy-group.model';
import { Policy }      from '@models/entity/policy.model';

export class PolicyList {
  groups: PolicyGroup[];

  constructor(private data?: PolicyList) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  isCallrouteVoice?(profileRoutingPolicy: string): boolean {
    const routingPolicy: Policy = this.groups?.find(group => group.identifier === 'voice_policies')
      ?.policies
      ?.onlineVoiceRoutingPolicy
      .find(policy => policy.identifier === profileRoutingPolicy);
    return routingPolicy?.is_callroute_voice;
  }

  fromApiData?(data: PolicyGroup[]): PolicyList {
    this.groups = data;
    return this;
  }
}
