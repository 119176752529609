import { NgModule }                     from '@angular/core';
import { ModalHeadComponent }           from '@dialog/modal-head/modal-head.component';
import { ModalContentComponent }        from '@dialog/modal-content/modal-content.component';
import { ModalActionsComponent }        from '@dialog/modal-actions/modal-actions.component';
import { ConfirmModalComponent }        from '@dialog/general/confirm-modal/confirm-modal.component';
import { NoticeModalComponent }         from '@dialog/notice-modal/notice-modal.component';
import { TextInputModalComponent }      from '@dialog/general/text-input-modal/text-input-modal.component';
import { VideoModalComponent }          from '@dialog/video-modal/video-modal.component';
import { SubmitFeedbackModalComponent } from '@dialog/submit-feedback-modal/submit-feedback-modal.component';
import { UpgradeModalComponent }        from '@dialog/upgrade-modal/upgrade-modal.component';
import {
  AcceptTermsConditionsComponent,
}                                       from '@core/components/account/accept-terms-conditions/accept-terms-conditions.component';
import { SessionExpiryModalComponent }  from '@dialog/session-expiry-modal/session-expiry-modal.component';
import { MatDialogModule }              from '@angular/material/dialog';
import { ButtonModule }                 from '../button/button.module';
import { MatFormFieldModule }           from '@angular/material/form-field';
import { NotificationSharedModule }     from '../notification-shared/notification-shared.module';
import { StarRatingModule }             from 'angular-star-rating';
import { MatInputModule }               from '@angular/material/input';
import { MatIconModule }                from '@angular/material/icon';
import { MatCheckboxModule }            from '@angular/material/checkbox';
import { MatButtonModule }              from '@angular/material/button';
import { SafePipe }                     from '@pipes/safe.pipe';
import { SafeHtmlPipe }                 from '@pipes/safe-html.pipe';
import { AsyncPipe, NgIf, NgStyle }     from '@angular/common';
import { ReactiveFormsModule }          from '@angular/forms';
import { FlexLayoutModule }             from '@angular/flex-layout';


@NgModule({
  declarations: [
    ModalHeadComponent,
    ModalContentComponent,
    ModalActionsComponent,
    ConfirmModalComponent,
    NoticeModalComponent,
    TextInputModalComponent,
    VideoModalComponent,
    SubmitFeedbackModalComponent,
    UpgradeModalComponent,
    AcceptTermsConditionsComponent,
    SessionExpiryModalComponent,
  ],
  imports:      [
    NgIf,
    NgStyle,
    FlexLayoutModule,
    MatDialogModule,
    ReactiveFormsModule,
    ButtonModule,
    MatFormFieldModule,
    NotificationSharedModule,
    StarRatingModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatButtonModule,
    SafePipe,
    SafeHtmlPipe,
    AsyncPipe,
  ],
  exports:      [
    ModalHeadComponent,
    ModalContentComponent,
    ModalActionsComponent,
  ],
})
export class ModalModule {}
