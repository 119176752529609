import { Action, createReducer, on }          from '@ngrx/store';
import * as UIAction                          from './ui.actions';
import { BaseState }                          from '../helpers/reducer.helper';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

export const adapter: EntityAdapter<never> = createEntityAdapter<never>();

const initialState: BaseState<never> = adapter.getInitialState({
  ids:          [],
  entities:     {},
  pending:      false,
  pendingTasks: [],
  error:        null,
  message:      null
});

const _uiReducer = createReducer(initialState,
  on(UIAction.DismissErrorAction, (state) =>
    ({...state, error: null})),
  on(UIAction.DismissMessageAction, (state) =>
    ({...state, message: null})));

export function uiReducer(state: BaseState<never>, action: Action): BaseState<never> {
  return _uiReducer(state, action);
}
