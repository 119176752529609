import { Injectable }                                                   from '@angular/core';
import { Store }                                                        from '@ngrx/store';
import { Actions, createEffect, ofType }                                from '@ngrx/effects';
import { concatMap, map, switchMap, tap, throttleTime, withLatestFrom } from 'rxjs/operators';

import { ReportService } from '@services/report.service';

import * as ActionTypes                         from './report.actions';
import {
  DeleteReportRequestAction,
  DeleteReportResponseAction,
  ExportCDRtoCSVRequestAction,
  ExportCDRtoCSVResponseAction,
  FetchAssignedNumbersByLocationRequestAction,
  FetchAssignedNumbersByRangeRequestAction,
  FetchAssignedNumbersByTagRequestAction,
  FetchAssignedNumbersTotalRequestAction,
  FetchCallCostByDirectionRequestAction,
  FetchCallCostSummaryByBandRequestAction,
  FetchCallLogRecordingRequestAction,
  FetchCallLogRecordingResponseAction,
  FetchCallLogRequestAction,
  FetchCallLogSummaryRequestAction,
  FetchCallMinutesByDirectionRequestAction,
  FetchCallRecordingTranscriptRequestAction,
  FetchCallRecordingTranscriptResponseAction,
  FetchCallsBySIPCodeRequestAction,
  FetchCallsBySIPCodeResponseAction,
  FetchCallsBySIPTypeRequestAction,
  FetchCallsBySIPTypeResponseAction,
  FetchChannelUsageRequestAction,
  FetchCountByCapabilityRequestAction,
  FetchCountByCapabilityResponseAction,
  FetchDestinationSummaryRequestAction,
  FetchExpensiveCallsRequestAction,
  FetchFrequentCallsRequestAction,
  FetchLocationCostRequestAction,
  FetchRangeExhaustionRequestAction,
  FetchRangeExhaustionResponseAction,
  FetchRangeUsageTopRequestAction,
  FetchReportingOverviewRequestAction,
  FetchReportListRequestAction,
  FetchReportRequestAction,
  FetchSIPResponseTypesRequestAction,
  FetchSummaryByCountryRequestAction,
  FetchSummaryByDirectionRequestAction,
  FetchTotalNumbersPerCountryRequestAction,
  FetchTotalNumbersPerTypeRequestAction,
  FetchUsageByDirectionRequestAction,
}                                               from './report.actions';
import { StoreState }                           from '../store';
import { withThrottle }                         from '@rxjs/action-throttle.operator';
import { FetchLocationCostRequest }             from '@models/api/fetch-location-cost-request.model';
import { FetchCallLogRequest }                  from '@models/api/fetch-call-log-request.model';
import { FetchCallLogRecordingResponse }        from '@models/api/fetch-call-log-recording-response.model';
import { FetchCallLogRecordingRequest }         from '@models/api/fetch-call-log-recording-request.model';
import { FetchExpensiveCallsResponse }          from '@models/api/fetch-expensive-calls-response.model';
import { FetchFrequentCallsRequest }            from '@models/api/fetch-frequent-calls-request.model';
import { FetchFrequentCallsResponse }           from '@models/api/fetch-frequent-calls-response.model';
import { FetchUsageByDirectionResponse }        from '@models/api/fetch-usage-by-direction-response.model';
import { FetchSummaryByDirectionRequest }       from '@models/api/fetch-summary-by-direction-request.model';
import { CallLogItem }                          from '@models/entity/call-log-item.model';
import { FetchUsageByDirectionRequest }         from '@models/api/fetch-usage-by-direction-request.model';
import { FetchCallLogSummaryRequest }           from '@models/api/fetch-call-log-summary-request.model';
import { FetchSummaryByCountryRequest }         from '@models/api/fetch-summary-by-country-request.model';
import { FetchDestinationsSummaryRequest }      from '@models/api/fetch-destinations-summary-request.model';
import { FetchExpensiveCallsRequest }           from '@models/api/fetch-expensive-calls-request.model';
import { FetchSummaryByDirectionResponse }      from '@models/api/fetch-summary-by-direction-response.model';
import { FetchChannelUsageResponse }            from '@models/api/fetch-channel-usage-response.model';
import { FetchCallLogSummaryResponse }          from '@models/api/fetch-call-log-summary-response.model';
import { FetchReportingOverviewRequest }        from '@models/api/fetch-reporting-overview-request.model';
import { FetchLocationCostResponse }            from '@models/api/fetch-location-cost-response.model';
import { FetchDestinationsSummaryResponse }     from '@models/api/fetch-destinations-summary-response.model';
import { FetchCallLogResponse }                 from '@models/api/fetch-call-log-response.model';
import { ExportCDRtoCSVRequest }                from '@models/api/export-cdr-to-csv-request.model';
import { FetchReportingOverviewResponse }       from '@models/api/fetch-reporting-overview-response.model';
import { FetchSummaryByCountryResponse }        from '@models/api/fetch-summary-by-country-response.model';
import { FetchChannelUsageRequest }             from '@models/api/fetch-channel-usage-request.model';
import { ExportCDRtoCSVResponse }               from '@models/api/export-cdr-to-csv-response.model';
import { selectUserScopes }                     from '@redux/auth/auth.selectors';
import { AuthScope }                            from '@enums/auth-scope.enum';
import { withScopes }                           from '@rxjs/with-scopes.operator';
import { whenConfig }                           from '@rxjs/when-config.operator';
import { selectReportingConfig }                from '@redux/configuration/configuration.selectors';
import { ReportingConfig }                      from '@models/entity/reporting-config.model';
import { FetchCallMinutesByDirectionRequest }   from '@models/api/fetch-call-minutes-by-direction-request.model';
import { FetchCallMinutesByDirectionResponse }  from '@models/api/fetch-call-minutes-by-direction-response.model';
import { FetchCallsBySipCodeRequest }           from '@models/api/fetch-calls-by-sip-code-request.model';
import { FetchCallsBySipCodeResponse }          from '@models/api/fetch-calls-by-sip-code-response.model';
import { FetchCallRecordingTranscriptRequest }  from '@models/api/fetch-call-recording-transcript-request.model';
import { FetchCallRecordingTranscriptResponse } from '@models/api/fetch-call-recording-transcript-response.model';
import { FetchCountByCapabilityResponse }       from '@models/api/fetch-count-by-capability-response.model';
import { FetchReportListRequest }               from '@models/api/fetch-report-list-request.model';
import { FetchReportListResponse }              from '@models/api/fetch-report-list-response.model';
import { FetchReportRequest }                   from '@models/api/reporting/fetch-report-request.model';
import { FetchReportResponse }                  from '@models/api/reporting/fetch-report-response.model';
import { ConfirmModalComponent }                from '@dialog/general/confirm-modal/confirm-modal.component';
import { ConfirmModalData }                     from '@models/ui/confirm-modal-data.model';
import { of }                                   from 'rxjs';
import { DeleteReportRequest }                  from '@models/api/reporting/delete-report-request.model';
import { DeleteReportResponse }                 from '@models/api/reporting/delete-report-response.model';
import { MatDialog }                            from '@angular/material/dialog';
import {
  FetchTotalNumbersPerCountryRequest,
}                                               from '@models/api/reporting/fetch-total-numbers-per-country-request.model';
import {
  FetchTotalNumbersPerCountryResponse,
}                                               from '@models/api/reporting/fetch-total-numbers-per-country-response.model';
import {
  FetchAssignedNumbersTotalRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-total-request.model';
import {
  FetchAssignedNumbersTotalResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-total-response.model';
import {
  FetchAssignedNumbersByLocationRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-location-request.model';
import {
  FetchAssignedNumbersByLocationResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-location-response.model';
import {
  FetchAssignedNumbersByRangeRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-range-request.model';
import {
  FetchAssignedNumbersByRangeResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-range-response.model';
import {
  FetchAssignedNumbersByTagResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-tag-response.model';
import {
  FetchAssignedNumbersByTagRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-tag-request.model';
import {
  FetchTotalNumbersPerTypeRequest,
}                                               from '@models/api/reporting/fetch-total-numbers-per-type-request.model';
import {
  FetchTotalNumbersPerTypeResponse,
}                                               from '@models/api/reporting/fetch-total-numbers-per-type-response.model';
import { FetchRangeUsageTopRequest }            from '@models/api/reporting/fetch-range-usage-top-request.model';
import { FetchRangeUsageTopResponse }           from '@models/api/reporting/fetch-range-usage-top-response.model';
import { FetchRangeExhaustionResponse }         from '@models/api/reporting/fetch-range-exhaustion-response.model';
import { FetchRangeExhaustionRequest }          from '@models/api/reporting/fetch-range-exhaustion-request.model';
import { FetchTaskRequestAction }               from '@redux/audit/audit.actions';
import { FetchSipResponseTypesResponse }        from '@models/api/reporting/fetch-sip-response-types-response.model';
import { FetchCallsBySipTypeResponse }          from '@models/api/reporting/fetch-calls-by-sip-type-response.model';
import { FetchCallsBySipTypeRequest }           from '@models/api/reporting/fetch-calls-by-sip-type-request.model';
import { FetchCallCostByDirectionRequest }      from '@models/api/number/fetch-call-cost-by-direction-request.model';
import {
  FetchCallCostByDirectionResponse,
}                                               from '@models/api/number/fetch-call-cost-by-direction-response.model';
import {
  FetchCallCostSummaryByBandRequest,
}                                               from '@models/api/number/fetch-call-cost-summary-by-band-request.model';
import {
  FetchCallCostSummaryByBandResponse,
}                                               from '@models/api/number/fetch-call-cost-summary-by-band-response.model';


@Injectable()
export class ReportEffects {
  constructor(
    private actions$: Actions,
    private reportService: ReportService,
    private store: Store<StoreState>,
    private dialog: MatDialog,
  ) {}

  fetchReportingOverview$ = createEffect(() => this.actions$.pipe(
    ofType(FetchReportingOverviewRequestAction),
    withThrottle(),
    switchMap((req: FetchReportingOverviewRequest) =>
      this.reportService.fetchReportingOverview$(req)
        .pipe(
          map((res: FetchReportingOverviewResponse) =>
            ActionTypes.FetchReportingOverviewResponseAction(res),
          ),
        ),
    ),
  ));

  fetchCallMinutesByDirection$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallMinutesByDirectionRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchCallMinutesByDirectionRequest) =>
      this.reportService.fetchCallMinutesByDirection$(req)
        .pipe(
          map((res: FetchCallMinutesByDirectionResponse) =>
            ActionTypes.FetchCallMinutesByDirectionResponseAction(res),
          ),
        ),
    ),
  ));

  fetchCallCostByDirection$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallCostByDirectionRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchCallCostByDirectionRequest) =>
      this.reportService.fetchCallCostByDirection$(req)
        .pipe(
          map((res: FetchCallCostByDirectionResponse) =>
            ActionTypes.FetchCallCostByDirectionResponseAction(res),
          ),
        ),
    ),
  ));

  fetchCallCostSummaryByBand$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallCostSummaryByBandRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchCallCostSummaryByBandRequest) =>
      this.reportService.fetchCallCostSummaryByBand$(req)
        .pipe(
          map((res: FetchCallCostSummaryByBandResponse) =>
            ActionTypes.FetchCallCostSummaryByBandResponseAction(res),
          ),
        ),
    ),
  ));

  fetchReportingFrequentCallsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(FetchFrequentCallsRequestAction),
    whenConfig<FetchFrequentCallsRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'frequentNumbers'),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchFrequentCallsRequest) =>
      this.reportService.fetchFrequentCalls$(req)
        .pipe(
          map((res: FetchFrequentCallsResponse) =>
            ActionTypes.FetchFrequentCallsResponseAction(res),
          ),
        ),
    ),
  ));

  fetchReportingExpensiveCallsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(FetchExpensiveCallsRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchExpensiveCallsRequest) =>
      this.reportService.fetchExpensiveCalls$(req)
        .pipe(
          map((res: FetchExpensiveCallsResponse) =>
            ActionTypes.FetchExpensiveCallsResponseAction(res),
          ),
        ),
    ),
  ));

  fetchReportingLocationCostsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLocationCostRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchLocationCostRequest) =>
      this.reportService.fetchLocationCost$(req)
        .pipe(
          map((res: FetchLocationCostResponse) =>
            ActionTypes.FetchLocationCostResponseAction(res),
          ),
        ),
    ),
  ));

  fetchReportingDestinationSummaryRequest$ = createEffect(() => this.actions$.pipe(
    ofType(FetchDestinationSummaryRequestAction),
    whenConfig<FetchDestinationsSummaryRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'frequentLocations'),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchDestinationsSummaryRequest) =>
      this.reportService.fetchDestinationSummary$(req)
        .pipe(
          map((res: FetchDestinationsSummaryResponse) =>
            ActionTypes.FetchDestinationSummaryResponseAction(res),
          ),
        ),
    ),
  ));

  fetchUsageByDirection$ = createEffect(() => this.actions$.pipe(
    ofType(FetchUsageByDirectionRequestAction),
    whenConfig<FetchUsageByDirectionRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'usageByDirection'),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchUsageByDirectionRequest) =>
      this.reportService.fetchUsageByDirection$(req)
        .pipe(
          map((res: FetchUsageByDirectionResponse) =>
            ActionTypes.FetchUsageByDirectionResponseAction(res),
          ),
        ),
    ),
  ));

  fetchSummaryByDirection$ = createEffect(() => this.actions$.pipe(
    ofType(FetchSummaryByDirectionRequestAction),
    whenConfig<FetchSummaryByDirectionRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'summaryByDirection'),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchSummaryByDirectionRequest) =>
      this.reportService.fetchSummaryByDirection$(req)
        .pipe(
          map((res: FetchSummaryByDirectionResponse) =>
            ActionTypes.FetchSummaryByDirectionResponseAction(res),
          ),
        ),
    ),
  ));

  fetchSummaryByCountry$ = createEffect(() => this.actions$.pipe(
    ofType(FetchSummaryByCountryRequestAction),
    whenConfig<FetchSummaryByCountryRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'summaryByCountry'),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchSummaryByCountryRequest) =>
      this.reportService.fetchSummaryByCountry$(req)
        .pipe(
          map((res: FetchSummaryByCountryResponse) =>
            ActionTypes.FetchSummaryByCountryResponseAction(res),
          ),
        ),
    ),
  ));

  fetchChannelUsage$ = createEffect(() => this.actions$.pipe(
    ofType(FetchChannelUsageRequestAction),
    whenConfig<FetchChannelUsageRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'channelUsage'),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchChannelUsageRequest) =>
      this.reportService.fetchChannelUsage$(req)
        .pipe(
          map((res: FetchChannelUsageResponse) =>
            ActionTypes.FetchChannelUsageResponseAction(res),
          ),
        ),
    ),
  ));

  fetchSIPResponseTypes$ = createEffect(() => this.actions$.pipe(
    ofType(FetchSIPResponseTypesRequestAction),
    withThrottle(),
    switchMap(() =>
      this.reportService.fetchSIPResponseTypes$()
        .pipe(
          map((res: FetchSipResponseTypesResponse) =>
            ActionTypes.FetchSIPResponseTypesResponseAction(res),
          ),
        ),
    ),
  ));

  fetchTotalNumbersPerCountryData$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTotalNumbersPerCountryRequestAction),
    whenConfig<FetchTotalNumbersPerCountryRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'totalNumbersPerCountry'),
    withThrottle(1_000),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchTotalNumbersPerCountryRequest) =>
      this.reportService.fetchTotalNumbersPerCountry$(req)
        .pipe(
          map((res: FetchTotalNumbersPerCountryResponse) =>
            ActionTypes.FetchTotalNumbersPerCountryResponseAction(res),
          ),
        ),
    ),
  ));

  fetchAssignedNumbersTotal$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAssignedNumbersTotalRequestAction),
    whenConfig<FetchAssignedNumbersTotalRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'totalNumbers'),
    withThrottle(1_000),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchAssignedNumbersTotalRequest) =>
      this.reportService.fetchAssignedNumbersTotal$(req)
        .pipe(
          map((res: FetchAssignedNumbersTotalResponse) =>
            ActionTypes.FetchAssignedNumbersTotalResponseAction(res),
          ),
        ),
    ),
  ));

  fetchAssignedNumbersByLocation$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAssignedNumbersByLocationRequestAction),
    whenConfig<FetchAssignedNumbersByLocationRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'assignedNumbersByLocation'),
    withThrottle(1_000),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchAssignedNumbersByLocationRequest) =>
      this.reportService.fetchAssignedNumbersByLocation$(req)
        .pipe(
          map((res: FetchAssignedNumbersByLocationResponse) =>
            ActionTypes.FetchAssignedNumbersByLocationResponseAction(res),
          ),
        ),
    ),
  ));

  fetchAssignedNumbersByRange$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAssignedNumbersByRangeRequestAction),
    whenConfig<FetchAssignedNumbersByRangeRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'assignedNumbersByRange'),
    withThrottle(1_000),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchAssignedNumbersByRangeRequest) =>
      this.reportService.fetchAssignedNumbersByRange$(req)
        .pipe(
          map((res: FetchAssignedNumbersByRangeResponse) =>
            ActionTypes.FetchAssignedNumbersByRangeResponseAction(res),
          ),
        ),
    ),
  ));

  fetchAssignedNumbersByTag$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAssignedNumbersByTagRequestAction),
    whenConfig<FetchAssignedNumbersByTagRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'assignedNumbersByTag'),
    withThrottle(1_000),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchAssignedNumbersByTagRequest) =>
      this.reportService.fetchAssignedNumbersByTag$(req)
        .pipe(
          map((res: FetchAssignedNumbersByTagResponse) =>
            ActionTypes.FetchAssignedNumbersByTagResponseAction(res),
          ),
        ),
    ),
  ));

  fetchTotalNumbersPerTypeData$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTotalNumbersPerTypeRequestAction),
    whenConfig<FetchTotalNumbersPerTypeRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'totalNumbersPerType'),
    withThrottle(1_000),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchTotalNumbersPerTypeRequest) =>
      this.reportService.fetchTotalNumbersPerType$(req)
        .pipe(
          map((res: FetchTotalNumbersPerTypeResponse) =>
            ActionTypes.FetchTotalNumbersPerTypeResponseAction(res),
          ),
        ),
    ),
  ));

  fetchRangeUsageTop$ = createEffect(() => this.actions$.pipe(
    ofType(FetchRangeUsageTopRequestAction),
    whenConfig<FetchRangeUsageTopRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'rangeUsageTop'),
    withThrottle(1_000),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchRangeUsageTopRequest) =>
      this.reportService.fetchRangeUsageTop$(req)
        .pipe(
          map((res: FetchRangeUsageTopResponse) =>
            ActionTypes.FetchRangeUsageTopResponseAction(res),
          ),
        ),
    ),
  ));

  fetchCallLog$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallLogRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchCallLogRequest) =>
      this.reportService.fetchCallLog$(req)
        .pipe(
          map((res: FetchCallLogResponse) =>
            ActionTypes.FetchCallLogResponseAction(res),
          ),
        ),
    ),
  ));

  fetchReportList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchReportListRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchReportListRequest) =>
      this.reportService.fetchReportList$(req)
        .pipe(
          map((res: FetchReportListResponse) =>
            ActionTypes.FetchReportListResponseAction(res),
          ),
        ),
    ),
  ));

  fetchCallLogSummary$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallLogSummaryRequestAction),
    whenConfig<FetchCallLogSummaryRequest, ReportingConfig>(this.store.select(selectReportingConfig), 'cdrHistory'),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchCallLogSummaryRequest) =>
      this.reportService.fetchCallLogSummary$(req)
        .pipe(
          map((res: FetchCallLogSummaryResponse) =>
            ActionTypes.FetchCallLogSummaryResponseAction(res),
          ),
        ),
    ),
  ));

  fetchCallLogRecording$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallLogRecordingRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.RecordingRead]),
    switchMap((req: FetchCallLogRecordingRequest) =>
      this.reportService.fetchCallLogRecording$(req)
        .pipe(
          map((res: FetchCallLogRecordingResponse) =>
            ActionTypes.FetchCallLogRecordingResponseAction(res),
          ),
        ),
    ),
  ));

  deleteReport$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteReportRequestAction),
    switchMap((req: DeleteReportRequest) => {
        return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
          data:       {
            title:          `Delete ${ req.name }`,
            content:        `<p>You are about to delete an exported report. Click 'delete' to continue with the deletion.</p>`,
            confirmBtnText: 'Delete',
            showCancel:     true,
            typeConfirm:    true,
          },
          panelClass: 'cr-dialog',
          maxWidth:   '640px',
          maxHeight:  'calc(100vh - 140px)',
          width:      '100%',
        })
          .afterClosed()
          .pipe(concatMap((confirmed: boolean) => {
            if (!confirmed) {
              return of(ActionTypes.DeleteReportResponseAction({
                cancelled:    true,
                error:        null,
                id:           req.id,
                isLastOnPage: false,
              }));
            }
            return this.reportService.deleteReport$(req)
              .pipe(map((res: DeleteReportResponse) => DeleteReportResponseAction(res)));
          }));
      },
    )));

  fetchNumberExport$ = createEffect(() => this.actions$.pipe(
    ofType(FetchReportRequestAction),
    withThrottle(),
    switchMap((req: FetchReportRequest) => this.reportService.fetchReport$(req)),
    map((res: FetchReportResponse) => ActionTypes.FetchReportResponseAction(res)),
  ));

  fetchCallLogRecordingResponse$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallLogRecordingResponseAction),
    withThrottle(),
    withLatestFrom(this.store),
    map(([req, _]: [FetchCallLogRecordingResponse, StoreState]): void => {
      if (req && !req.noOpen) {
        if (req.error) {
          this.reportService.openErrorModal(
            'Recording not found',
            'It was not possible to fetch this recording, please try again later.',
            'Close',
          );
        } else if (req.url && req.logData) {
          this.reportService.openAudioClip({ ...req.logData, recordingUri: req.url } as CallLogItem);
        }
      }
    }),
  ), { dispatch: false });

  exportCDRtoCSV$ = createEffect(() => this.actions$.pipe(
    ofType(ExportCDRtoCSVRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportExport]),
    withThrottle(),
    switchMap((req: ExportCDRtoCSVRequest) => {
      return this.reportService.exportCDRtoCSV$(req);
    }),
    tap((res: ExportCDRtoCSVResponse) => {
      if (res.taskId) {
        this.store.dispatch(FetchTaskRequestAction({ id: res.taskId }));
      }
    }),
    map((res: ExportCDRtoCSVResponse) => ExportCDRtoCSVResponseAction(res))));

  fetchCallsBySIPCode$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallsBySIPCodeRequestAction),
    throttleTime(2_000, undefined, { leading: true, trailing: true }),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchCallsBySipCodeRequest) => {
      return this.reportService.fetchCallsBySIPCode$(req);
    }),
    map((res: FetchCallsBySipCodeResponse) => FetchCallsBySIPCodeResponseAction(res)),
  ));

  fetchCallsBySIPType$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallsBySIPTypeRequestAction),
    throttleTime(2_000, undefined, { leading: true, trailing: true }),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchCallsBySipTypeRequest) => {
      return this.reportService.fetchCallsBySIPType$(req);
    }),
    map((res: FetchCallsBySipTypeResponse) => FetchCallsBySIPTypeResponseAction(res)),
  ));

  fetchTranscript$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallRecordingTranscriptRequestAction),
    switchMap((req: FetchCallRecordingTranscriptRequest) => {
      return this.reportService.fetchTranscript$(req);
    }),
    map((res: FetchCallRecordingTranscriptResponse) => FetchCallRecordingTranscriptResponseAction(res)),
  ));

  fetchCountByCapability$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCountByCapabilityRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    switchMap(() => this.reportService.fetchCountByCapability$()),
    map((res: FetchCountByCapabilityResponse) => FetchCountByCapabilityResponseAction(res)),
  ));

  fetchRangeExhaustion$ = createEffect(() => this.actions$.pipe(
    ofType(FetchRangeExhaustionRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ReportRead]),
    switchMap((req: FetchRangeExhaustionRequest) => this.reportService.fetchRangeExhaustion$(req)),
    map((res: FetchRangeExhaustionResponse) => FetchRangeExhaustionResponseAction(res)),
  ));
}
