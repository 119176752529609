import { BaseRequest }        from '@redux/helpers/reducer.helper';
import { NumberImportForm }   from '@models/form/number-import-form.model';
import { FormGroup }          from '@angular/forms';
import { ProviderIdentifier } from '@enums/provider-identifier.enum';
import { NumberImportMode }   from '@enums/number-import-mode.enum';
import { NumberImportFormat } from '@enums/number/number-import-format.enum';

export class ImportNumbersRequest extends BaseRequest {
  mode: NumberImportMode;
  provider: ProviderIdentifier;
  carrierServiceId: string;
  operator: string;
  inboundServiceId: string;
  payload: File;
  format: NumberImportFormat;

  constructor(private data?: ImportNumbersRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromFormData?(data: FormGroup<NumberImportForm>, requestId: string): ImportNumbersRequest {
    this.mode             = data.get('mode').value;
    this.provider         = data.get('provider').value;
    this.carrierServiceId = data.get('carrierServiceId').value;
    this.operator         = data.get('operator').value;
    this.inboundServiceId = data.get('inboundServiceId').value;
    this.payload          = data.get('payload').value;
    this.format           = data.get('importFormat').value;
    this.requestId        = requestId;

    return this;
  }

  toApiData?(): FormData {
    const formData = new FormData();
    formData.set('format', this.format);
    formData.set('mode', this.mode);
    formData.set('payload', this.payload);
    if (this.mode !== NumberImportMode.Update) {
      if (this.carrierServiceId) {
        formData.set('carrier_service_id', this.carrierServiceId);
      }
      if (this.operator) {
        formData.set('operator', this.operator);
      }
      if (this.inboundServiceId) {
        formData.set('inbound_service_id', this.inboundServiceId);
      }
    }
    return formData;
  }
}
