import { LicenseStatus } from '@enums/license-status.enum';
import { LicenseRaw }    from '@models/api/license-raw.model';

export class License {
  id: string;
  name: string;
  skuId: string;
  skuPart: string;
  status: LicenseStatus;
  createdDate: string;
  updatedDate: string;
  availableUnits: number;
  consumedUnits: number;
  enabledUnits: number;
  suspendedUnits: number;
  warningUnits: number;

  constructor(private data?: License) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: LicenseRaw): License {
    return {
      id:             data.id,
      name:           data.name,
      skuId:          data.sku_id,
      skuPart:        data.sku_part,
      status:         data.status,
      createdDate:    data.created_date,
      updatedDate:    data.updated_date,
      availableUnits: data.available_units,
      consumedUnits:  data.consumed_units,
      enabledUnits:   data.enabled_units,
      suspendedUnits: data.suspended_units,
      warningUnits:   data.warning_units,
    };
  }
}
