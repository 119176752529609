import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { SipResponse }          from '@enums/sip-response.enum';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class CDRQueryParams extends ListParams implements ListParamModel<CDRQueryParams> {
  source?: string;
  destination?: string;
  direction?: string;
  since?: string;
  until?: string;
  sipResponseType?: string;
  sipResponseCode?: number;

  static constructQueryString(searchParams: CDRQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      source:          'filter[source]',
      destination:     'filter[destination]',
      since:           'filter[date][since]',
      until:           'filter[date][until]',
      sipResponseType: 'filter[sip_response_type]',
      sipResponseCode: 'filter[sip_response_code]',
      direction:       'filter[direction]',
    });
  }

  constructParams?(metaData: ListResponseMetadata): CDRQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.source) {
        this.source = metaData.filter.source;
      }
      if (metaData.filter.date.since) {
        this.since = metaData.filter.date.since;
      }
      if (metaData.filter.date.until) {
        this.until = metaData.filter.date.until;
      }
      if (metaData.filter.destination) {
        this.destination = metaData.filter.destination;
      }
      if (metaData.filter.response_type) {
        this.sipResponseType = metaData.filter.sip_response_type as SipResponse;
      }
      if (metaData.filter.response_code) {
        this.sipResponseCode = metaData.filter.sip_response_code;
      }
      if (metaData.filter.direction) {
        this.direction = metaData.filter.direction;
      }
    }

    return this;
  }
}

export class CDRDynamoQueryParams extends ListParams implements ListParamModel<CDRQueryParams> {
  source?: string;
  destination?: string;
  direction?: string;
  sipResponseType?: string;
  sipResponseCode?: number;
  since?: string;
  until?: string;

  static constructQueryString(searchParams: CDRQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      source:          'filter[source_e164]',
      destination:     'filter[destination_e164]',
      since:           'filter[date][since]',
      until:           'filter[date][until]',
      sipResponseType: 'filter[response_type]',
      sipResponseCode: 'filter[response_code]',
      direction:       'filter[direction]',
    });
  }

  constructParams?(metaData: ListResponseMetadata): CDRQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.source) {
        this.source = metaData.filter.source;
      }
      if (metaData.filter.destination) {
        this.destination = metaData.filter.destination;
      }
      if (metaData.filter.response_type) {
        this.sipResponseType = metaData.filter.response_type as SipResponse;
      }
      if (metaData.filter.response_code) {
        this.sipResponseCode = metaData.filter.response_code;
      }
      if (metaData.filter.direction) {
        this.direction = metaData.filter.direction;
      }
    }

    return this;
  }
}
