import { ReportSeriesData }      from '@models/entity/report-series-data.model';
import { AssignedNumbersByXRaw } from '@models/entity/assigned-numbers-by-x-raw.model';
import { ChartMultiSeries }      from '@models/chart/chart-multi-series.model';
import { ChartSeries }           from '@models/chart/chart-series.model';
import { NumberReportViewMode }  from '@enums/number-report-view-mode.enum';

export class AssignedNumbersByXData {
  series: ReportSeriesData[];
  points: {
    date: string;
    name: string;
    group_id: string;
    assigned_count: number;
    unassigned_count: number;
    reserved_count: number;
  }[];
  private _groups: ReportSeriesData[];
  private _series: ReportSeriesData[];

  constructor(private data?: AssignedNumbersByXData) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(apiData: AssignedNumbersByXRaw): AssignedNumbersByXData {

    this._series = apiData?.series?.map(p => ReportSeriesData.fromApiData(p)) || [];
    this._groups = apiData.groups ? Object.entries(apiData.groups)
      .map(([id, name]) => ({ id, name }))
      ?.map(p => ReportSeriesData.fromApiData(p)) : [];

    this.series = (apiData?.groups ? this._groups : apiData.series)?.map(p => ReportSeriesData.fromApiData(p)) || [];
    this.points = [];

    for (const [date, val] of Object.entries(apiData.points)) {
      for (const [group, data] of Object.entries(val)) {
        this.points.push({
          name:             this.series?.find(s => s.id === group)?.name,
          group_id:         group !== 'default' ? group : null,
          assigned_count:   +data.assigned_count,
          unassigned_count: +data.unassigned_count,
          reserved_count:   +data.reserved_count,
          date,
        });
      }
    }

    return this;
  }

  toChartMultiSeries?(viewMode?: NumberReportViewMode): ChartMultiSeries[] {
    const points = this.points;
    const series = this.series;

    if (viewMode === NumberReportViewMode.Overview) {
      if (!points?.length) {
        return [];
      }
      return (!this._groups?.length ? [{
        name: 'Total',
        id:   'total',
      }] : series)?.map(s => {
          return {
            name:   s.name,
            series: this._series
                      ?.map(d => {
                        const point = points.find(p => !p.group_id || (p.group_id === s.id));
                        return {
                          name:  d.name,
                          value: (point as any)?.[d.id] || 0,
                          extra: {
                            code:    d.name || s.name,
                            groupId: d.id,
                          },
                        } as ChartSeries;
                      }) || [],
          };
        },
      )
        ?.sort((a, b) => {
          const aSum = a.series.map(s => s.value)
            .reduce((sum, next) => sum + next);
          const bSum = b.series.map(s => s.value)
            .reduce((sum, next) => sum + next);
          return bSum - aSum;
        }) || [];
    }
    return series?.map(s => {
        return {
          name:   s.name,
          series: points?.length ? points.filter(p => !p.group_id || (p.group_id === s.id))
            .map(d => {
              return {
                name:  d.date,
                value: d.group_id ? (d.assigned_count + d.reserved_count + d.unassigned_count) : (d as any)[s.id],
                extra: {
                  code:    d.name || s.name,
                  groupId: d.group_id,
                },
              } as ChartSeries;
            }) : [],
        };
      },
    ) || [];
  }

  toChartSeries?(viewDate: string, viewGroupId: string): ChartSeries[] {
    const points = this.points?.filter(point => point.date === viewDate && point.group_id === viewGroupId) || [];
    if (!points?.length || !this._series?.length) {
      return [];
    }
    return this._series.map(s => {
        const point = points?.[0];
        return {
          name:  s.name,
          value: (point as any)[s.id],
          extra: {
            code: point.name || s.name,
          },
        };
      },
    );
  }
}
