import { Component, Input } from '@angular/core';
import { FieldCheckType }   from '@enums/field-check-type.enum';
import { SafeHtml }         from '@angular/platform-browser';

@Component({
  selector:    'ngx-field-notification',
  templateUrl: './field-notification.component.html',
  styleUrls:   ['./field-notification.component.scss'],
})
export class FieldNotificationComponent {
  @Input() message: SafeHtml;
  @Input() type: FieldCheckType;
  FieldCheckType = FieldCheckType;

  get icon(): string {
    switch (this.type) {
      case FieldCheckType.Invalid:
        return 'warning.svg';
      case FieldCheckType.Warn:
        return 'cr-warning-no-color';
      case FieldCheckType.Valid:
        return 'cr-tick-circle';
      case FieldCheckType.Info:
        return 'cr-info-circle';
      default:
        return null;
    }
  }

  get isValid(): boolean {
    return this.type === FieldCheckType.Valid;
  }

  get isChecking(): boolean {
    return this.type === FieldCheckType.Checking;
  }

}
