import { QueryExpression }  from '@models/entity/query-expression.model';
import { CallingProfile }   from '@models/entity/calling-profile.model';
import { AutomationRaw }    from '@models/api/automation-raw.model';
import { LicenseGroup }     from '@models/entity/license-group.model';
import { TeamGroup }        from '@models/entity/teams-group.model';
import { TeamGroupRole }    from '@enums/team-group-role.enum';
import { CallQueueGroup }   from '@models/entity/call-queue-group.model';
import { CountryCodes }     from '@models/entity/country-code.model';
import { AutomationStatus } from '@enums/automation-status.enum';

export class Automation {
  id: string;
  name: string;
  status: AutomationStatus;
  description: string;
  updatedDate: string;
  createdDate: string;
  criteria: QueryExpression;
  profile: CallingProfile;
  profileId: string;
  priority: number;
  requestId?: string;
  licenseGroups: LicenseGroup[];
  licenseGroupIds: string[];
  teamGroups: TeamGroup[];
  teamGroupRoles: { [teamGroupId: string]: TeamGroupRole };
  teamGroupIds: string[];
  callQueueGroups: CallQueueGroup[];
  callQueueGroupIds: string[];
  isProfileAmendmentAllowed: boolean;
  usageLocation: keyof CountryCodes;
  isStackable: boolean;

  constructor(private data?: Automation) {
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  fromApiData?(data: AutomationRaw & { requestId?: string }): Automation {
    this.id          = data.id;
    this.name        = data.name;
    this.status      = data.status;
    this.description = data.description;
    try {
      this.criteria = JSON.parse(data.criteria);
    } catch {
      console.warn(`Couldn't parse criteria JSON`);
    }
    this.updatedDate               = data.updated_date;
    this.createdDate               = data.created_date;
    this.priority                  = data.priority;
    this.profileId                 = data.profile_id;
    this.licenseGroupIds           = data.license_group_ids;
    this.teamGroupIds              = data.team_group_ids ? Object.keys(data.team_group_ids) : [];
    this.teamGroupRoles            = data.team_group_ids || {};
    this.teamGroups                = [];
    this.callQueueGroupIds         = data.call_queue_group_ids;
    this.callQueueGroups           = [];
    this.requestId                 = data.requestId;
    this.isProfileAmendmentAllowed = data.is_profile_amendment_allowed;
    this.usageLocation             = data.usage_location;
    this.isStackable               = data.is_stackable;

    return this;
  }

  getStatusColor?(): string {
    switch (this.status) {
      case AutomationStatus.Active:
        return 'green';
      case AutomationStatus.Inactive:
        return 'gray';
    }
  }

  getStatusText?(): string {
    switch (this.status) {
      case AutomationStatus.Active:
        return 'Active';
      case AutomationStatus.Inactive:
        return 'Inactive';
    }
  }
}
