export enum SipResponse {
  Unclassified  = 'Unclassified',
  QuickDropped  = 'Quick Dropped',
  Successful    = 'Successful',
  Failed        = 'Failed',
  CallDeclined  = 'Call Declined',
  MissedCall    = 'Missed',
  Busy          = 'Busy',
  InvalidNumber = 'Invalid'
}
