<ngx-anon-page [reducedPadding]="true">
  <div fxLayout="column" fxLayoutAlign="space-between" class="login-page">
    <h1 class="fw-500 mb-24">Welcome to Callroute</h1>
    <p class="mat-body-1 mb-48">Please login with either your Callroute or Microsoft 365 admin account</p>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px">
      <ngx-cr-button text="Sign in with Callroute"
                     [type]="ButtonType.Dark"
                     (onClick)="requestCallrouteAuth()"
                     icon="{{callrouteLogo}}"></ngx-cr-button>
      <button mat-button class="border-gray" (click)="requestMicrosoftAuth()">
        <span class="mr-8">Sign in with Microsoft</span>
        <img src="{{microsoftLogo}}" height="24px" />
      </button>
    </div>
  </div>
  <div class="footer">
    <p fxLayout="row" fxLayoutAlign="center center">
      <a class="link font-size-14 color-primary fs-12-sm" href="https://callroute.com/privacy-policy/" target="_blank"
         rel="nofollow noopener">Privacy policy
      </a>
      &nbsp;·&nbsp;
      <a class="link font-size-14 color-primary fs-12-sm" href="https://callroute.com/terms-conditions/" target="_blank"
         rel="nofollow noopener">Terms and conditions
      </a>
    </p>
  </div>
</ngx-anon-page>
