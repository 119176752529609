import { Component }     from '@angular/core';
import { AssetsService } from '@services/assets.service';

@Component({
  selector:    'ngx-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls:   ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent {
  logoUrl: string = AssetsService.getIcon('callroute-logo.svg');

  constructor() {

  }
}

