import { StoreState }        from '../store';
import { NotificationState } from './notification.reducer';
import { createSelector }    from '@ngrx/store';

export const selectNotification             = (state: StoreState): NotificationState => state.notificationReducer;
export const selectNotificationList         = createSelector(selectNotification, state => state.notifications);
export const selectNotificationQuery        = createSelector(selectNotification, notificationState => notificationState.notificationQuery);
export const selectNotificationPendingTasks = createSelector(selectNotification, notificationState => notificationState.pendingTasks);
export const selectNewNotifications         = createSelector(selectNotificationList, list => list?.filter(l => l.isNew) || []);
export const selectUnreadCount              = createSelector(selectNotification, state => state.unreadCount || 0);
export const selectNotificationMessage      = createSelector(selectNotification, state => state.message);
