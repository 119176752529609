import { BaseRequest }   from '@redux/helpers/reducer.helper';
import { TeamGroupRaw } from '@models/api/microsoft-teams/teams-group-raw.model';

export class PatchTeamGroupRequest extends BaseRequest {
  id: string;
  serviceId: string;
  name: string;
  description: string;
  teamIds: string[];
  confirm?: boolean;

  constructor(private data?: PatchTeamGroupRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): TeamGroupRaw {
    return {
      name:        this.name,
      description: this.description,
      team_ids:    this.teamIds,
    };
  }
}
