import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ButtonType }                                          from '@enums/button-type.enum';
import { clickBtn }                                            from '@animations/click-btn';

@Component({
  selector:    'ngx-cr-button',
  templateUrl: './cr-button.component.html',
  styleUrls:   ['./cr-button.component.scss'],
  animations:  [clickBtn()],
})
export class CrButtonComponent {
  @Input() disabled: boolean;
  @Input() type: ButtonType                            = ButtonType.Primary;
  @Input() icon: string;
  @Input() btnClass: string;
  @Input() fullWidth: boolean;
  @Input() text: string;
  @Input() loadingIcon: boolean;
  @Input() loadingDirection: 'clockwise' | 'anti-clockwise';
  @Input() showLoadingText: boolean;
  @Input() buttonType: 'button' | 'submit'             = 'button';
  @Input() align: 'start' | 'space-between' | 'center' = 'center';
  @Output() onClick                                    = new EventEmitter<void>();
  @Input() hideTextSm: boolean;
  @Input() size: 'sm' | 'md'                           = 'md';
  @HostBinding('attr.disabled') isDisabled: boolean;
  @Input() loading: boolean;
  clickState: 'active' | 'inactive'                    = 'inactive';

  get isImg(): boolean {
    return !this.icon?.startsWith('cr-');
  }

  get loadingClockwise(): boolean {
    return this.loading && this.loadingIcon && this.loadingDirection !== 'anti-clockwise';
  }

  get loadingAntiClockwise(): boolean {
    return this.loading && this.loadingIcon && this.loadingDirection === 'anti-clockwise';
  }
}
