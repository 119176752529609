<ngx-modal-head header="Sync Microsoft Teams numbers"
                headerIcon="cr-sync">
</ngx-modal-head>
<ngx-modal-content overflow="visible">
  <form [formGroup]="formGroup" class="sync-service-form" *ngIf="{tokens: tokens$ | async} as data">
    <mat-form-field appearance="outline" class="w-100" tabindex="-1" floatLabel="always">
      <mat-label>Select a service</mat-label>
      <mat-select formControlName="serviceId">
        <mat-option class="service-option" *ngFor="let service of services$ | async; trackBy: trackBy"
                    [disabled]="getIsInactive(service) || getNeedsAuth(service, data.tokens)"
                    [matTooltip]="getNeedsAuth(service, data.tokens) ? 'This Microsoft Teams service requires re-authentication.' : null"
                    [value]="service.id">
          <div fxLayout="column" fxLayoutAlign="center start" class="service-option__inner">
              <span
                class="mat-body-1--smaller m-0 service-option__inner__name">
                {{ service.label || service.name }} {{ getIsInactive(service) ? '(Inactive)' : '' }}
              </span>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</ngx-modal-content>
<ngx-modal-actions>
  <ngx-cr-button text="Sync"
                 data-cy="submit"
                 #modalAction
                 (keydown.enter)="submit()"
                 [disabled]="submitDisabled$ | async"
                 (onClick)="submit()">
  </ngx-cr-button>
</ngx-modal-actions>
