import { Injectable }      from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer }    from '@angular/platform-browser';
import icons               from '../config/icons';
import { AssetsService }   from '@services/assets.service';

@Injectable({
  providedIn: 'root',
})
export class IconService {

  constructor(private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer) { }

  registerIcons(): void {
    for (const [icon, fileName] of Object.entries(icons)) {
      this.iconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(AssetsService.getIcon(fileName)),
      );
    }
  }

}
