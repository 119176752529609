import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';
import { ServiceType }          from '@enums/service-type.enum';

export class ServiceQueryParams extends ListParams implements ListParamModel<ServiceQueryParams> {
  type?: ServiceType;
  search?: string;
  id?: string[];

  constructor(private data?: ServiceQueryParams) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  static constructQueryString(searchParams: ServiceQueryParams): string {
    const params: ServiceQueryParams = { ...(searchParams || {}) as ServiceQueryParams };

    return ListParams.constructQueryString(params, {
      type:   'filter[type]',
      search: 'filter[search]',
      id:     'filter[id][]',
    });
  }

  static queryChanged(newQuery: ServiceQueryParams, currentQuery: ServiceQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery);
  }

  constructParams?(metaData: ListResponseMetadata): ServiceQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.type) {
        this.type = metaData.filter.type as unknown as ServiceType;
      }
      if (metaData.filter.label) {
        this.search = metaData.filter.search;
      }
      if (metaData.filter.id) {
        this.id = metaData.filter.id;
      }
    }

    return this;
  }
}
