import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class LicenseQueryParams extends ListParams implements ListParamModel<LicenseQueryParams> {
  search?: string;
  id?: string[];

  static constructQueryString(searchParams: LicenseQueryParams): string {
    const params: LicenseQueryParams = { ...(searchParams || {}) as LicenseQueryParams };

    return ListParams.constructQueryString(params, {
      search: 'filter[search]',
      id:     'filter[id][]',
    });
  }

  constructParams?(metaData: ListResponseMetadata): LicenseQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.search) {
        this.search = metaData.filter.search;
      }
      if (metaData.filter.id) {
        this.id = metaData.filter.id;
      }
    }

    return this;
  }
}
