<button class="button cr-button" matTooltip="{{text}}" [matTooltipDisabled]="!hideTextSm"
        (click)="clickState = 'active'; onClick.emit(); $event.stopPropagation()"
        [disabled]="disabled"
        matRipple
        [type]="buttonType"
        (touchstart)="clickState = 'active'"
        (touchend)="clickState = 'inactive'"
        [@clickBtn]="clickState"
        (@clickBtn.done)="clickState = 'inactive'"
        [ngStyle]="{minWidth: fullWidth ? '100%' : (!text && '100px')}"
        [ngStyle.lt-md]="{minWidth: fullWidth ? '100%' : ((!text || hideTextSm) && '36px')}"
        [ngClass]="type + ' ' + size + ' ' + btnClass" fxLayout="row" [fxLayoutAlign]="align + ' center'">
  <ng-container *ngIf="!loading || showLoadingText; else isLoading">
        <span class="mr-8" *ngIf="loading && !loadingIcon">
            <ng-container *ngIf="true; then isLoading"></ng-container>
        </span>
    <span [fxHide.lt-md]="hideTextSm" *ngIf="text">{{text}}</span>
    <img src="{{icon}}" *ngIf="icon && isImg"
         width="24px"
         [ngStyle]="{marginLeft: text && '8px'}"
         [ngClass.lt-md]="hideTextSm && 'm-0'" />
    <mat-icon *ngIf="icon && !isImg"
              [class.rotate]="loadingClockwise"
              [class.rotate-anti]="loadingAntiClockwise"
              class="cr-button__icon"
              [ngStyle]="{marginLeft: text && '8px'}"
              [ngClass.lt-md]="hideTextSm && 'm-0'"
              svgIcon="{{icon}}"></mat-icon>
    <ng-content></ng-content>
  </ng-container>
  <ng-template #isLoading>
    <mat-spinner strokeWidth="3"
                 diameter="26"></mat-spinner>
  </ng-template>
</button>
