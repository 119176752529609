import { Directive, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core';
import { DOCUMENT }                                                     from '@angular/common';


@Directive({
  selector: '[ngxCopy]',
})
export class NgxCopyDirective {
  @Input() copyText = '';
  @Output() copied  = new EventEmitter<void>();

  @HostListener('click') openBar(): void {
    this.copy();
  }


  constructor(@Inject(DOCUMENT) private _document: Document) {}

  copy(): void {
    const textarea = this.create();
    this.position(textarea);
    this.insert(textarea);
    this.selectText(textarea);
    this._document.execCommand('copy');
    this.cleanup(textarea);
    this.copied.emit();
  }

  selectText(textarea: HTMLTextAreaElement): void {
    textarea.value = this.copyText;
    textarea.focus();
    textarea.select();
  }

  cleanup(textarea: HTMLTextAreaElement): void {
    this._document.body.removeChild(textarea);
  }

  insert(textarea: HTMLTextAreaElement): void {
    this._document.body.appendChild(textarea);
  }

  create(): HTMLTextAreaElement {
    return this._document.createElement('textarea');
  }

  position(textarea: HTMLTextAreaElement): void {
    textarea.style.position = 'fixed';
    textarea.style.left     = '-400%';
    textarea.style.top      = '0';
  }
}
