import { Injectable }                                       from '@angular/core';
import { CarrierFactory }                                   from '@models/factory/carrier.factory';
import { ListService }                                      from '@services/list.service';
import { Carrier }                                          from '@models/entity/carrier.model';
import { environment }                                      from '../../environments/environment';
import { FetchAvailableCarriersRequest }                    from '@models/api/fetch-available-carriers-request.model';
import { FetchAvailableCarriersResponse }                   from '@models/api/fetch-available-carriers-response.model';
import { concatMap, Observable, of, switchMap }             from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PostServiceRequest }                               from '@models/api/post-service-request.model';
import { PostServiceResponse }                              from '@models/api/post-service-response.model';
import { ServiceItemRaw }                                   from '@models/api/service-api-response.model';
import { catchError, map }                                  from 'rxjs/operators';
import { Alert }                                            from '@models/entity/alert.model';
import { HttpErrorResponse }                                from '@angular/common/http';
import { PatchServiceRequest }                              from '@models/api/patch-service-request.model';
import { PatchServiceResponse }                             from '@models/api/patch-service-response.model';
import { ServiceCarrier }                                   from '@models/entity/carrier-service.model';
import { ApiService }                                       from '@services/api.service';
import { ServiceCarrierRaw }                                from '@models/api/carrier-service-raw.model';
import { CarrierRaw }                                       from '@models/api/carrier-raw.model';
import { NumberItem }                                       from '@models/entity/number-item.model';
import { FetchNumberRequest }                               from '@models/api/fetch-number-request.model';
import { FetchNumberResponse }                              from '@models/api/fetch-number-response.model';
import { NumberItemRaw }                                    from '@models/api/number-item-raw.model';
import { CarrierQueryParams }                               from '@models/form/carrier-query-params.model';
import { CarrierParamsFactory }                             from '@models/factory/carrier-params.factory';
import { BtWholesaleAdapter }                               from '../adapter/bt-wholesale.adapter';
import { GenericAdapter }                                   from '../adapter/generic.adapter';
import { IPValidator }                                      from '@validators/ip.validator';
import { BYODAdapter }                                      from '../adapter/byod.adapter';

@Injectable({
  providedIn: 'root',
})
export class CarrierService {
  private baseUrl: string                           = environment.api.serviceBaseUrl;
  private readonly carrierFactory: CarrierFactory;
  private readonly carrierParamFactory: CarrierParamsFactory;
  private numberCache: { [id: string]: NumberItem } = {};
  private carrierCache: { [id: string]: Carrier }   = {};

  newCIDRAddrCtrl = (data: string): UntypedFormControl =>
    new UntypedFormControl(data, [Validators.required, Validators.maxLength(128), IPValidator.validCIDR]);

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  constructor(private listService: ListService<Carrier, CarrierFactory,
                CarrierQueryParams, CarrierParamsFactory>,
              private apiService: ApiService) {
    this.carrierFactory      = new CarrierFactory();
    this.carrierParamFactory = new CarrierParamsFactory();
  }

  fetchAvailableCarriers$(req: FetchAvailableCarriersRequest): Observable<FetchAvailableCarriersResponse> {
    return this.listService.fetchListModel$(
      this.buildUri(`carriers${ CarrierQueryParams.constructQueryString(req.queryParams) }`),
      this.carrierFactory,
      this.carrierParamFactory,
    );
  }

  getNewForm(data: ServiceCarrier): UntypedFormGroup {

    switch (data.carrierData.adapter) {
      case 'generic':
        return new GenericAdapter(data).getForm();
      case 'bt-wholesale':
        return new BtWholesaleAdapter(data).getForm();
      case 'byod-international':
        return new BYODAdapter(data).getForm();
      default:
        return new GenericAdapter(data).getForm();
    }

  }

  postCarrier$(req: PostServiceRequest<ServiceCarrier>): Observable<PostServiceResponse<ServiceCarrier>> {
    return this.apiService.apiPost$<{ data: ServiceItemRaw }>(
      this.buildUri(`services`),
      new ServiceCarrier(req.serviceItem).toApiData(),
      { authRequired: true })
      .pipe(
        switchMap((res: { data: ServiceItemRaw }): Observable<PostServiceResponse<ServiceCarrier>> => {
          return this.resolveCarrier$(new ServiceCarrier().fromApiData(res.data as ServiceCarrierRaw))
            .pipe(
              map((serviceItem: ServiceCarrier) => {
                return {
                  error:   null,
                  serviceItem,
                  message: new Alert().fromApiMessage({
                    message:   'Carrier service updated successfully.',
                    color:     'green',
                    url:       null,
                    isSuccess: true,
                  }),
                };
              }));
        }),
        catchError((err: HttpErrorResponse): Observable<PostServiceResponse<ServiceCarrier>> => {
          return of({
            error:       new Alert().fromApiError(err, req.formFields),
            serviceItem: null,
            message:     null,
          });
        }),
      );
  }

  patchCarrier$(req: PatchServiceRequest<ServiceCarrier>): Observable<PatchServiceResponse<ServiceCarrier>> {
    return this.apiService.apiPatch$<{ data: ServiceItemRaw }>(
      this.buildUri(`services/${ req.serviceItem.id }`),
      new ServiceCarrier(req.serviceItem).toApiData(),
      { authRequired: true })
      .pipe(
        switchMap((res: { data: ServiceItemRaw }): Observable<PatchServiceResponse<ServiceCarrier>> => {
          return this.resolveCarrier$(new ServiceCarrier().fromApiData(res.data as ServiceCarrierRaw))
            .pipe(
              map((serviceItem: ServiceCarrier) => {
                return {
                  error:   null,
                  serviceItem,
                  message: new Alert().fromApiMessage({
                    message:   'Carrier service updated successfully.',
                    color:     'green',
                    url:       null,
                    isSuccess: true,
                    id:        req.requestId,
                  }),
                };
              }));
        }),
        catchError((err: HttpErrorResponse): Observable<PatchServiceResponse<ServiceCarrier>> => {
          return of({
            error:       new Alert().fromApiError(err, req.formFields),
            serviceItem: null,
            message:     null,
          });
        }),
      );
  }


  fetchCarrier$(id: string): Observable<Carrier> {
    return this.apiService.apiGet$<{ data: CarrierRaw }>(
      this.buildUri(`carriers/${ id }`),
      { authRequired: true })
      .pipe(
        map((res: { data: CarrierRaw }): Carrier => {
          return new Carrier().fromApiData(res.data as CarrierRaw);
        }),
        catchError((): Observable<Carrier> => {
          return null;
        }),
      );

  }

  resolveCarrier$(s: ServiceCarrier): Observable<ServiceCarrier> {
    return this.resolveCarrierForService$(s)
      .pipe(concatMap((carrierService: ServiceCarrier) => {
        return this.resolveNumberForService$(s)
          .pipe(map((carrierServiceWithNum: ServiceCarrier) => {
            carrierService.numberData = carrierServiceWithNum.numberData;
            return carrierService;
          }));
      }));
  }

  resolveCarrierForService$(serviceCarrier: ServiceCarrier): Observable<ServiceCarrier> {
    if (!serviceCarrier?.carrierId) {
      return of(serviceCarrier);
    }

    let carrier$: Observable<Carrier>;
    if (!this.carrierCache[serviceCarrier.carrierId]) {
      carrier$ = this.fetchCarrier$(serviceCarrier.carrierId)
        .pipe(map(res => {
          this.carrierCache[serviceCarrier.carrierId] = res; // so we don't fetch the same number from network multiple times
          return res;
        }));
    } else {
      carrier$ = of(this.carrierCache[serviceCarrier.carrierId]);
    }

    return carrier$.pipe(map(carrier => {
      serviceCarrier.carrierData = carrier;
      return serviceCarrier;
    }));

  }

  private resolveNumberForService$(serviceCarrier: ServiceCarrier): Observable<ServiceCarrier> {
    if (!serviceCarrier?.numberId) {
      return of(serviceCarrier);
    }

    let number$: Observable<NumberItem>;
    if (!this.numberCache[serviceCarrier.numberId]) {
      number$ = this.fetchNumber$({ id: serviceCarrier.numberId })
        .pipe(map(res => {
          this.numberCache[serviceCarrier.numberId] = res.number; // so we don't fetch the same number from network multiple times
          return res.number;
        }));
    } else {
      number$ = of(this.numberCache[serviceCarrier.numberId]);
    }

    return number$.pipe(map(num => {
      serviceCarrier.numberData = num;
      return serviceCarrier;
    }));
  }

  private fetchNumber$(req: FetchNumberRequest): Observable<FetchNumberResponse> {
    return this.apiService.apiGet$<{ data: NumberItemRaw }>(
      `${ environment.api.numberBaseUrl }numbers/${ req.id }`,
      { authRequired: true })
      .pipe(
        map((res: { data: NumberItemRaw }): FetchNumberResponse => {
          return {
            error:  null,
            number: new NumberItem().fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchNumberResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            number: null,
          });
        }),
      );
  }
}
