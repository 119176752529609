import { HttpErrorResponse }    from '@angular/common/http';
import { AlertType }            from '@enums/alert-type.enum';
import { FromApiMessageParams } from '@models/entity/from-api-message-params.model';
import { APIError }             from '@models/entity/api-error.model';

export class Alert {
  id?: string                        = Date.now()
    .toString();
  icon: string;
  message: string;
  backgroundColor: string;
  formErrors?: Map<string, string[]> = new Map<string, string[]>([]);
  linkUrl?: string;
  isError?: boolean;
  show?                              = true;
  disableTimeout?: boolean;
  isSuccess?: boolean;
  type: AlertType;
  code?: number;
  context?: string;
  customErrorMap?: {
    [code: number]: string;
  };

  constructor(private data?: Alert) {
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  withContext?(context: string): Alert {
    this.context = context;

    return this;
  }

  withCustomErrorMessages?(errorMap: { [code: number]: string }): Alert {
    this.customErrorMap = errorMap;

    return this;
  }

  fromApiError?<RawItem>(apiError: APIError & HttpErrorResponse,
                         formFieldMap?: Map<keyof RawItem, string>,
                         show       = true,
                         id: string = Date.now()
                           .toString()): Alert {
    const fallbackError = 'There was a problem communicating with Callroute servers. Please try later.';
    this.id             = id;
    this.icon           = 'warning-white.svg';
    this.isError        = true;
    this.type           = AlertType.Error;
    this.code           = (apiError as HttpErrorResponse)?.status;
    this.show           = show;
    if (this.code === 404) {
      this.show = false;
    }
    const contextPrefix = this.context ? `${ this.context }: ` : '';

    if ((apiError as APIError)?.error?.detail) {
      this.message         = `${ contextPrefix }${ (apiError as APIError).error.detail }`;
      this.backgroundColor = 'red';
    } else if (this.customErrorMap?.[this.code]) {
      this.message         = this.customErrorMap[this.code];
      this.backgroundColor = 'orange';
    } else {
      this.message         = `${ contextPrefix }${ fallbackError }`;
      this.backgroundColor = 'orange';
    }
    const errorMessages = (apiError as APIError)?.error?.input_messages || (apiError as APIError)?.error?.input_errors;
    if (errorMessages) {
      for (const key of Object.keys(errorMessages)) {

        const k = !formFieldMap?.has(key as keyof RawItem) && key.includes('.') ? key.split('.')[0] : key;

        const itemIndex: number = !formFieldMap?.has(key as keyof RawItem) && key.includes('.') ? +key.split('.')[1] : NaN;

        const name: string                   = formFieldMap?.get(k as keyof RawItem) as string || k;
        const inputErrors: string | string[] = errorMessages[key];

        const setError = (error: string) => formFieldMap?.has(k as keyof RawItem) ?
          this.formErrors.set(name, (this.formErrors.get(name) || []).concat(`${ !isNaN(itemIndex) ? (itemIndex + 1) + ': ' : '' }${ error }`)) :
          this.formErrors.set('Errors', (this.formErrors.get('Errors') || []).concat(error));

        if (Array.isArray(inputErrors)) {
          inputErrors.forEach(error => setError(error));
        } else {
          setError(inputErrors);
        }
      }
    }
    return this;
  }

  fromApiMessage?({
                    message,
                    color = 'green',
                    url = null,
                    isSuccess = false,
                    show = true,
                    id = Date.now()
                      .toString(),
                  }: FromApiMessageParams): Alert {

    this.message         = message;
    this.linkUrl         = url ? url : null;
    this.backgroundColor = color ? color : 'green';
    this.icon            = 'warning-white.svg';
    this.isSuccess       = isSuccess;
    this.show            = show;
    this.type            = AlertType.Success;
    this.id              = id;
    return this;
  }
}
