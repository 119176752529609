<ng-container *ngIf="serviceReducer$ | async as serviceReducer">
  <ngx-modal-head *ngIf="dialogRef"
                  header="{{getHeader}}"
                  headerIcon="{{service.carrierData?.logoUri}}"
                  [subheader]="subheader"
  >
  </ngx-modal-head>
  <ngx-modal-content [background]="!!dialogRef" [info]="dialogRef && serviceReducer.message"
                     [noPadding]="!dialogRef"
                     [overflow]="dialogRef ? 'auto' : 'visible'"
                     [error]="dialogRef && serviceReducer.error">
    <div fxLayout="row" fxLayoutAlign="end center" class="mb-24">
      <button mat-button
              *ngIf="service.information"
              (click)="openInformation()"
              fxLayout="row"
              fxLayoutAlign="start center">
        <span class="mr-8">View configuration</span>
        <mat-icon svgIcon="cr-open-new"></mat-icon>
      </button>
    </div>
    <ngx-carrier-form [formGroup]="formGroup"
                      [class.p-20]="!!dialogRef"
                      [dialogMode]="!!dialogRef"
                      [adapter]="service.carrierData.adapter"
                      [numberReducer]="numberReducer$ | async"
                      (addressSpacesHasDuplicateChange)="containsDupes.next($event);"
                      [editMode]="editMode$ | async">
    </ngx-carrier-form>
    <div fxLayout="row" fxLayoutAlign="start center" class="mb-16 mt-24" [class.plr-20]="!!dialogRef"
         *ngIf="showContactInfo">
      <mat-icon svgIcon="cr-info-outline" class="mr-8"></mat-icon>
      <p class="mat-body-1--smaller mb-0 mt-2">Not a {{service.carrierData.name}} customer?
        Contact {{service.carrierData.name}}
        <ng-container *ngIf="contactUri; else noLink"><a href="{{contactUri}}" target="_blank" class="link">to set up an
          account.</a></ng-container>
        <ng-template #noLink>to set up an account.</ng-template>
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" class="mb-16" [class.plr-20]="!!dialogRef"
         *ngIf="service.carrierData.helpUri">
      <mat-icon svgIcon="cr-help-outline" class="mr-8"></mat-icon>
      <p class="mat-body-1--smaller mb-0 mt-2">Need help? Read our
        <a href="{{service.carrierData.helpUri}}" target="_blank" class="link">knowledgebase article</a>.
      </p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" [class.plr-20]="!!dialogRef"
         *ngIf="service.carrierData.identifier === 'byod-international'">
      <mat-icon svgIcon="cr-info-outline" class="mr-8"></mat-icon>
      <p class="mat-body-1--smaller mb-0 mt-2">For this current release, your SBC IP address will require whitelisting.
        This can take up to 24 hours.
      </p>
    </div>
  </ngx-modal-content>
  <ngx-modal-actions>
    <button type="button"
            class="button primary"
            data-cy="submit"
            #modalAction
            (keydown.enter)="submitForm()"
            [disabled]="submitDisabled$ | async"
            *ngIf="(editMode$ | async) || isSetup()"
            (click)="submitForm()"
            matRipple>
      <span *ngIf="!(isPending$ | async); else loading">{{saveBtnText}}</span>
    </button>
    <ngx-cr-button *ngIf="!isSetup()"
                   text="{{(editMode$ | async) ? 'Cancel' : 'Modify'}}"
                   [type]="(editMode$ | async) ? ButtonType.Secondary : ButtonType.Primary"
                   data-cy="action"
                   #modalAction
                   [disabled]="btnDisabled$ | async"
                   [matTooltipDisabled]="!(btnDisabled$ | async)"
                   matTooltip="You do not have the required access to configure a carrier.
                   Please contact your systems administrator for more information."
                   (onClick)="editClick()"></ngx-cr-button>
  </ngx-modal-actions>
</ng-container>
<ng-template #loading>
  <mat-spinner strokeWidth="3" diameter="26"></mat-spinner>
</ng-template>
