import { CarrierRaw }               from '@models/api/carrier-raw.model';
import { ConfigurableCarrierInput } from '@enums/configurable-carrier-input.enum';
import { Contact }                  from './contact.model';

export class Carrier {
  id: string;
  createdDate: string;
  updatedDate: string;
  identifier: string;
  countryCode: string;
  logoUri: string;
  name: string;
  description: string;
  inputs: ConfigurableCarrierInput[];
  gateways: {
    priority: number;
    address: string;
    protocol: string;
    port: number;
  }[];
  isSipOptionsEnabled?: boolean;
  isRegistrationRequired?: boolean;
  contacts: Contact[];
  adapter: string;
  helpUri: string;
  isEnabled: boolean;

  constructor(private d?: Carrier) {
    Object.assign(this, { ...this.d });
    delete this['d'];
  }

  fromApiData?(data?: CarrierRaw): Carrier {
    this.id                     = data.id;
    this.createdDate            = data.created_date;
    this.updatedDate            = data.updated_date;
    this.identifier             = data.identifier;
    this.countryCode            = data.country_code;
    this.name                   = data.name;
    this.description            = data.description;
    this.contacts               = data.contacts;
    this.logoUri                = data.logo_uri;
    this.gateways               = data.gateways;
    this.isSipOptionsEnabled    = data.is_sip_options_enabled;
    this.isRegistrationRequired = data.is_registration_required;
    this.inputs                 = data.inputs;
    this.adapter                = data.adapter;
    this.helpUri                = data.help_uri;
    this.isEnabled              = data.is_enabled;

    return this;
  }

}
