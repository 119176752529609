import { BaseRequest } from '@redux/helpers/reducer.helper';

export class AssignCallQueueGroupRequest extends BaseRequest {
  serviceId: string;
  userId: string;
  callQueueGroupIds: string[];
  assignments: string[];
  unassignments: string[];
  confirm?: boolean;

  constructor(private data?: AssignCallQueueGroupRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): { assignments: string[], unassignments: string[] } {
    return {
      assignments:   this.assignments,
      unassignments: this.unassignments,
    };
  }

}
