import { ServiceType }          from '@enums/service-type.enum';
import { MicrosoftTeamsUser }   from '@models/entity/microsoft-teams-user.model';
import { RouteRuleRaw }         from '@models/api/route-rule-raw.model';
import { formatPhoneNumber }    from '@util/formatPhoneNumber';
import { DestinationFormValue } from '@models/form/destination-form-value.model';
import { EmergencyLocation }    from '@models/entity/emergency-location.model';
import { Status }               from '@models/entity/status.model';

export class RouteRule {
  priority: number;
  numberE164?: string;
  serviceType?: ServiceType;
  serviceId: string;
  serviceLabel?: string;
  serviceName?: string;
  serviceIcon?: string;
  serviceStatus?: Status;
  carrierLogo?: string;
  serviceUser?: MicrosoftTeamsUser;
  description?: string;
  implicitlyActivateUser: boolean;
  profileId: string;
  emergencyLocationId: string;
  emergencyLocation: EmergencyLocation;

  constructor(private data?: RouteRule) {
    Object.assign(this, { ...data });
    delete this['data'];
  }

  fromFormData?(dest: DestinationFormValue, numberE164?: string, isBYON?: boolean): RouteRule {
    if (!dest.serviceId) {
      return null;
    }
    if (dest.userId) {
      this.serviceUser = new MicrosoftTeamsUser({ id: dest.userId, ...dest.userQuery as MicrosoftTeamsUser });
    }
    const serviceUser = dest.userQuery as MicrosoftTeamsUser;

    this.serviceId = dest.serviceId;
    this.priority  = dest.priority;
    if (dest.serviceType === ServiceType.CallForwarding) {
      this.numberE164 = dest.numberE164;
    } else if (isBYON) {
      this.numberE164 = numberE164;
    } else {
      this.numberE164 =
        numberE164 && ((serviceUser?.cli && dest.implicitlyActivateUser) || !serviceUser?.cli) ?
          numberE164 :
          serviceUser?.lineUri || null;
    }
    this.implicitlyActivateUser = dest.implicitlyActivateUser;
    this.profileId              = dest.profileId;
    this.emergencyLocationId    = dest.emergencyLocationId;

    return this;
  }

  fromApiData?(apiData: RouteRuleRaw): RouteRule {
    let user = null;
    if (apiData.service_user_id) {
      user    = new MicrosoftTeamsUser();
      user.id = apiData.service_user_id;
    }
    return {
      priority:    apiData.priority,
      serviceId:   apiData.service_id,
      serviceUser: user,
      description: apiData.description,
      numberE164:  formatPhoneNumber(true, apiData.number_e164),
    } as RouteRule;
  }

  toApiData?(): RouteRuleRaw {
    return {
      priority:                 this.priority,
      service_id:               this.serviceId,
      service_user_id:          this.serviceUser?.id || null,
      number_e164:              this.numberE164 || null,
      implicitly_activate_user: this.implicitlyActivateUser,
      profile_id:               this.profileId,
      location_id:              this.emergencyLocationId,
    };
  }

  isValid?(): boolean {
    if (isNaN(this.priority)) {
      return false;
    }
    switch (this.serviceType) {
      case ServiceType.MicrosoftTeams:
        return !!(this.serviceId && this.serviceUser?.id);
      case ServiceType.WebexCalling:
      case ServiceType.SIPTrunk:
      case ServiceType.SIPPhone:
        return !!(this.serviceId);
      case ServiceType.CallForwarding:
        return !!(this.serviceId && this.numberE164);
      default:
        return !!(this.serviceId);
    }
  }
}
