import { Inject, Injectable, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DatePipe }                                           from '@angular/common';
import { MomentService }                                      from '@services/moment.service';
import { TIMEZONE }                                           from '../tokens/timezone.token';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name:       'localeDate',
  standalone: true,
})
export class LocaleDatePipe extends DatePipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private localeID: string,
              @Inject(TIMEZONE) private timeZone: string,
              private momentService: MomentService) {
    super(localeID);
  }

  transform(value: Date | string | number, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: string | number | Date, format?: string, timezone: string = this.timeZone, locale: string = this.localeID): string {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      try {
        if (!value.includes('/')) {
          const timeZoneUTC = this.momentService.moment(value, 'YYYY-MM-DD[T]HH:mm:ssZZZZ')
            .tz(this.timeZone);
          if (!timeZoneUTC.isValid()) {
            return value;
          }
          return super.transform(timeZoneUTC.format('YYYY-MM-DD[T]HH:mm:ss'), format, timezone, locale);
        }
        const [day, month, year] = value.split('/');
        const fullYear           = year?.length === 2 ? this.momentService.moment.parseTwoDigitYear(year) : +year;
        return super.transform(new Date(fullYear, +month - 1, +day), format, timezone, locale);
      } catch {
        return '';
      }
    } else if (typeof value === 'object' || typeof value === 'number') {
      return super.transform(value, format, timezone, locale);
    }
    return '';
  }

}
