import { filter }                   from 'rxjs/operators';
import { MonoTypeOperatorFunction } from 'rxjs';

/**
 * Uses rxjs/filter to perform a truthy check, only emitting if the condition is truthy or falsy
 * Specify whether you are asserting truthy or falsy using the @param val
 * @returns MonoTypeOperatorFunction (the value that passes the truthy check)
 *
 * @example
 * ```ts
 *    this.store.select(selectCompanyId)
 *            .pipe(
 *               takeUntil(this.destroy$),
 *               truthy()).subscribe(() => this.fetchData()); // will fetch data when companyId is truthy
 * ```
 */
export function truthy<T extends unknown>(val = true, pluck?: keyof T): MonoTypeOperatorFunction<T> {
  return filter<T>(value => !!(pluck ? !!value?.[pluck] : value) === val);
}
