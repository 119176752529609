import { Component } from '@angular/core';

@Component({
  selector:    'ngx-redirecting',
  templateUrl: './redirecting.component.html',
  styleUrls:   ['./redirecting.component.scss']
})
export class RedirectingComponent {

}
