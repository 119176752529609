import { Alert }             from './alert.model';
import {
  SubscriptionPackageAttributeItemRaw,
  SubscriptionPackageFeatureItemRaw,
  SubscriptionPackageItemRaw,
  SubscriptionProductCostItemRaw,
  SubscriptionProductItemRaw,
  UpgradePackageAmountItemRaw,
  UpgradePackageItemRaw,
}                            from '@models/api/subscription-api-response.model';
import { PackageType }       from '@enums/package-type.enum';
import { DateService }       from '@services/date.service';
import { ProductIdentifier } from '@enums/product-identifier.enum';

export type UpgradeStepName = 'CURRENT' | 'UPGRADE' | 'COMPLETE';

export interface UpgradeStep {
  name: UpgradeStepName;
  nextPackage: boolean;
}

export class SubscriptionPackageAttribute {
  description: string;
  identifier: string;
  name: string;
  value: number;

  static fromApiData(apiData: SubscriptionPackageAttributeItemRaw): SubscriptionPackageAttribute {
    const a: SubscriptionPackageAttribute = new SubscriptionPackageAttribute();

    a.description = apiData.description;
    a.identifier  = apiData.identifier;
    a.name        = apiData.name;
    a.value       = apiData.value ? +apiData.value : null;

    return a;
  }
}

export class SubscriptionPackageFeature {
  order: number;
  identifier: string;
  name: string;

  static fromApiData(apiData: SubscriptionPackageFeatureItemRaw): SubscriptionPackageFeature {
    const f: SubscriptionPackageFeature = new SubscriptionPackageFeature();

    f.order      = apiData.order;
    f.identifier = apiData.identifier;
    f.name       = apiData.name;

    return f;
  }
}

export class SubscriptionProductCost {
  currencyCode: string;
  id: string;
  interval: string;
  name: string;
  nrcValue: number;
  nrcSymbol: string;
  nrcFormatted: string;
  vrcValue: number;
  vrcSymbol: string;
  vrcFormatted: string;

  static fromApiData(apiData: SubscriptionProductCostItemRaw): SubscriptionProductCost {
    const c: SubscriptionProductCost = new SubscriptionProductCost();

    c.currencyCode = apiData.currency_code;
    c.id           = apiData.id;
    c.interval     = apiData.period;
    c.name         = apiData.name;
    c.nrcValue     = apiData.nrc_currency ? parseFloat(apiData.nrc_currency.decimal) : null;
    c.nrcSymbol    = apiData.nrc_currency ? apiData.nrc_currency.symbol : null;
    c.nrcFormatted = apiData.nrc_currency ? apiData.nrc_currency.formatted : null;
    c.vrcValue     = apiData.vrc_currency ? parseFloat(apiData.vrc_currency.decimal) : null;
    c.vrcSymbol    = apiData.vrc_currency ? apiData.vrc_currency.symbol : null;
    c.vrcFormatted = apiData.vrc_currency ? apiData.vrc_currency.formatted : null;

    return c;
  }
}

export class SubscriptionProduct {
  id: string;
  description: string;
  identifier: ProductIdentifier;
  order: number;
  name: string;
  type: string;
  packageType: PackageType;
  attributes: Array<SubscriptionPackageAttribute>;
  features: Array<SubscriptionPackageFeature>;
  costs: Array<SubscriptionProductCost>;
  catalogue: Array<string>;
  catalogueKeys: { [id: string]: boolean };

  splitNameArray: Array<string>;

  static fromApiData(apiData: SubscriptionProductItemRaw): SubscriptionProduct {
    const p: SubscriptionProduct = new SubscriptionProduct();

    p.description = apiData.description;
    p.id          = apiData.id;
    p.identifier  = apiData.identifier;
    p.order       = apiData.order;
    p.name        = apiData.name;
    p.type        = apiData.type;
    p.packageType = PackageType[apiData.name.replace('Callroute ', '') as keyof typeof PackageType];
    p.features    = apiData.features ? apiData.features.map((f: SubscriptionPackageFeatureItemRaw) => SubscriptionPackageFeature.fromApiData(f)) : null;
    p.attributes  = apiData.attributes ? apiData.attributes.map((f: SubscriptionPackageAttributeItemRaw) => SubscriptionPackageAttribute.fromApiData(f)) : null;
    p.costs       = apiData.costs ? apiData.costs.map((c: SubscriptionProductCostItemRaw) => SubscriptionProductCost.fromApiData(c)) : null;
    p.catalogue   = apiData.catalogue;

    if (apiData.catalogue) {
      p.catalogueKeys = {};
      for (const key of apiData.catalogue) {
        p.catalogueKeys[key] = true;
      }
    }

    p.splitNameArray = apiData.name ? apiData.name.split(' ') : [];

    return p;
  }
}

export class SubscriptionPackageData {
  id: string;
  createdDate: string;
  isAutoRenewed: boolean;
  startDate: string;
  endDate: string;
  quantity: number;
  status: string;
  product: SubscriptionProduct;
  cost: SubscriptionProductCost;

  static fromApiData(apiData: SubscriptionPackageItemRaw, dateService: DateService): SubscriptionPackageData {
    const p: SubscriptionPackageData = new SubscriptionPackageData();

    p.id            = apiData.id;
    p.createdDate   = dateService.UTCtoDate(apiData.created_date, 'mediumDate');
    p.isAutoRenewed = apiData.is_auto_renewed;
    p.quantity      = apiData.quantity;
    p.status        = apiData.status;
    p.startDate     = apiData.start_date;
    p.endDate       = apiData.end_date;
    p.product       = SubscriptionProduct.fromApiData(apiData.product);
    p.cost          = SubscriptionProductCost.fromApiData(apiData.cost);

    return p;
  }
}

/**
 * Component models and responses
 */
export interface SubscriptionUpgradeFormResult {
  productId: string;
  period: string;
  quantity: number;
}

export interface SubscriptionUpgradeSummary {
  cost: string;
  currency: string;
  currentBalance: string;
  immediateCharge: string;
  remainingBalance: string;
  refundAmount: string;
}

/**
 * API models
 */

export interface FetchActivePackageResponse {
  error: Alert;
  package: SubscriptionPackageData;
}

export interface FetchPackageListResponse {
  error: Alert;
  productList: Array<SubscriptionProduct>;
  featureList: Array<SubscriptionPackageFeature>;
}

interface ChangePackageRequestRaw {
  product_id: string;
  product_cost_id: string;
  quantity: number;
}

export class ChangePackageRequest {
  productId: string;
  period: string;
  quantity: number;

  static toApiData(formData: SubscriptionUpgradeFormResult): ChangePackageRequestRaw {
    return {
      product_id:      formData.productId,
      product_cost_id: formData.period,
      quantity:        formData.quantity,
    };
  }
}

export interface CalculatePackageCostRequest {
  'product_id': string;
  'product_cost_id': string;
  'quantity': number;
}

export interface CalculatePackageCostResponseRaw {
  package_new: UpgradePackageItemRaw;
  package_current: UpgradePackageItemRaw;
  amount_balance: UpgradePackageAmountItemRaw;
  amount_charge_ongoing: UpgradePackageAmountItemRaw;
  amount_charge_immediate: UpgradePackageAmountItemRaw;
  amount_refund: UpgradePackageAmountItemRaw;
}

export interface CalculatePackageCostResponse {
  error: Alert;
  cost: CalculatePackageCostResponseRaw;
}

export interface ChangePackageResponse {
  error: Alert;
  package: SubscriptionPackageData;
}

