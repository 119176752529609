import { Observable, of }  from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ApiService }                      from './api.service';
import { ListService }                     from './list.service';
import { environment }                     from '../../environments/environment';
import { AccountingQueryParams }           from '@models/form/accounting-query-params.model';
import { FetchSpendReportResponse }        from '@models/api/fetch-spend-report-response.model';
import { LastPaymentDataFactory }          from '@models/factory/last-payment.factory';
import { AccountingPaymentMethods }        from '@models/api/accounting-payment-methods.model';
import { FetchReportByDateRequest }        from '@models/api/fetch-report-by-date-request.model';
import { FetchPaymentMethodsResponse }     from '@models/api/fetch-payment-methods-response.model';
import { FetchLastPaymentSummaryResponse } from '@models/api/fetch-last-payment-summary-response.model';
import { FetchSpendReportResponseRaw }     from '@models/api/fetch-spend-report-response-raw.model';
import { TopupResponse }                   from '@models/api/topup-response.model';
import { FetchLastPaymentListResponse }    from '@models/api/fetch-last-payment-list-response.model';
import { FeatureInventoryResponseRaw }     from '@models/api/feature-inventory-response-raw.model';
import { FetchLastPaymentListRequest }     from '@models/api/fetch-last-payment-list-request.model';
import { FetchCreditReportResponse }       from '@models/api/fetch-credit-report-response.model';
import { HttpErrorResponse }               from '@angular/common/http';
import { TopupResponseRaw }                from '@models/api/topup-response-raw.model';
import { FetchCreditReportResponseRaw }    from '@models/api/fetch-credit-report-response-raw.model';
import { LastPaymentData }                 from '@models/entity/last-payment-data.model';
import { BillingGraphData }                from '@models/chart/billing-graph.model';
import { AccountingSearchParamsFactory }   from '@models/factory/accounting-search-params.factory';
import { DateService }                     from '@services/date.service';
import { FetchBalanceDataResponse }        from '@models/api/fetch-balance-data-response.model';
import { FeatureInventoryResponse }        from '@models/api/feature-inventory-response.model';
import { TopupRequest }                    from '@models/api/topup-request.model';
import { Alert }                           from '@models/entity/alert.model';
import { Balance }                         from '@models/entity/balance.model';
import { ExtendFreeTrialRequest }          from '@models/api/accounting/extend-free-trial-request.model';
import { ExtendFreeTrialResponse }         from '@models/api/accounting/extend-free-trial-response.model';


@Injectable({
  providedIn: 'root',
})
export class AccountingService {

  private baseUrl: string = environment.api.accountingBaseUrl;

  private readonly lastPaymentDataFactory: LastPaymentDataFactory;
  private readonly accountingParamFactory: AccountingSearchParamsFactory;

  constructor(
    private apiService: ApiService,
    private lastPaymentService: ListService<LastPaymentData, LastPaymentDataFactory,
      AccountingQueryParams, AccountingSearchParamsFactory>,
    private dateService: DateService,
  ) {
    this.lastPaymentDataFactory = new LastPaymentDataFactory();
    this.accountingParamFactory = new AccountingSearchParamsFactory();
  }

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  private buildPaymentListUri(queryParams: AccountingQueryParams, lastQuery: AccountingQueryParams): string {
    return this.buildUri(`payment/transactions${ AccountingQueryParams.constructQueryString(queryParams || lastQuery) }`);
  }

  private buildCreditReportUri(queryParams: AccountingQueryParams): string {
    return this.buildUri(`report/monthly-credit${ AccountingQueryParams.constructQueryString(queryParams) }`);
  }

  private buildSpendReportUri(queryParams: AccountingQueryParams): string {
    return this.buildUri(`report/monthly-spend${ AccountingQueryParams.constructQueryString(queryParams) }`);
  }

  private buildPaymentTransactionsUri(): string {
    return this.buildUri('payment/transactions');
  }

  private buildExtendFreeTrialUri(): string {
    return this.buildUri(`inventory/extend-trial`);
  }

  private buildBalancesUri(): string {
    return this.buildUri('balances');
  }

  private buildPaymentMethodsUri(): string {
    return this.buildUri('payment/methods');
  }

  private buildInventoryFeaturesUri(): string {
    return this.buildUri('inventory/features');
  }

  fetchFeatureInventory$(): Observable<FeatureInventoryResponse> {
    return this.apiService.apiGet$<FeatureInventoryResponseRaw>(
      this.buildInventoryFeaturesUri(),
      { authRequired: true })
      .pipe(
        map((res: FeatureInventoryResponseRaw): FeatureInventoryResponse => {
          return {
            error:    null,
            features: res.data,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FeatureInventoryResponse> => {
          return of({
            error:    new Alert().fromApiError(err),
            features: null,
          });
        }),
      );
  }

  fetchPaymentMethods$(): Observable<FetchPaymentMethodsResponse> {
    return this.apiService.apiGet$<AccountingPaymentMethods>(
      this.buildPaymentMethodsUri(),
      { authRequired: true })
      .pipe(
        map((res: AccountingPaymentMethods): FetchPaymentMethodsResponse => {
          return {
            error:   null,
            methods: res.data?.length ? res.data.map(r => r.identifier) : [],
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchPaymentMethodsResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            methods: [],
          });
        }),
      );
  }

  fetchBalanceData$(): Observable<FetchBalanceDataResponse> {
    return this.apiService.apiGet$<{ data: Array<{ type: 'MONETARY' | 'SECONDS', value: string }> }>(
      this.buildBalancesUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: Array<{ type: 'MONETARY' | 'SECONDS', value: string }> }): FetchBalanceDataResponse => {
          return {
            error: null,
            data:  new Balance().fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchBalanceDataResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  topupBalance$(req: TopupRequest): Observable<TopupResponse> {
    const { payType: type, ...other } = req; // ngrx doesn't let you pass a 'type' field
    return this.apiService.apiPost$<{ data: TopupResponseRaw }>(
      this.buildPaymentTransactionsUri(), { ...other, type },
      { authRequired: true })
      .pipe(
        map((res: { data: TopupResponseRaw }): TopupResponse => {
          return {
            error:   null,
            success: res.data.amount === req.amount,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<TopupResponse> => {
          return of({
            error:   new Alert().fromApiError(err, req.formFields),
            success: false,
          });
        }),
      );
  }

  extendFreeTrial$(req: ExtendFreeTrialRequest): Observable<ExtendFreeTrialResponse> {
    return this.apiService.apiPost$(
      this.buildExtendFreeTrialUri(), {
        end_date: req.date,
      },
      { authRequired: true })
      .pipe(
        map((): ExtendFreeTrialResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: 'Successfully extended free trial.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<ExtendFreeTrialResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            success: false,
          });
        }),
      );
  }

  fetchSpendReport$(req: FetchReportByDateRequest): Observable<FetchSpendReportResponse> {
    return this.apiService.apiGet$<FetchSpendReportResponseRaw>(
      this.buildSpendReportUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: FetchSpendReportResponseRaw): FetchSpendReportResponse => {
          return {
            error: null,
            data:  new BillingGraphData().fromApiData(res.data, this.dateService),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchSpendReportResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchCreditReport$(req: FetchReportByDateRequest): Observable<FetchCreditReportResponse> {
    return this.apiService.apiGet$<FetchCreditReportResponseRaw>(
      this.buildCreditReportUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: FetchCreditReportResponseRaw): FetchCreditReportResponse => {
          return {
            error: null,
            data:  new BillingGraphData().fromApiData(res.data, this.dateService),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCreditReportResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchLastPaymentList$(req: FetchLastPaymentListRequest, lastQuery?: AccountingQueryParams): Observable<FetchLastPaymentListResponse> {
    return this.lastPaymentService.fetchListModel$(
      this.buildPaymentListUri(req.queryParams, lastQuery),
      this.lastPaymentDataFactory,
      this.accountingParamFactory,
    );
  }

  fetchLastPaymentSummary$(req: FetchLastPaymentListRequest, lastQuery?: AccountingQueryParams): Observable<FetchLastPaymentSummaryResponse> {
    return this.fetchLastPaymentList$({
      ...req,
      queryParams: {
        ...req.queryParams || lastQuery,
        pageSize: 5,
        sort:     '-date',
      },
    })
      .pipe(
        map(res => {
          return {
            models: res.error ? [] : res.models,
            error:  res.error,
          };
        }),
      );
  }

}
