import { CallingProfileRaw } from '@models/api/calling-profile-raw.model';
import { EmergencyLocation } from '@models/entity/emergency-location.model';
import { NumberRange }       from '@models/entity/number-range.model';
import { NumberTag }         from '@models/entity/number-tag.model';
import { Location }          from '@models/entity/location.model';
import { ServiceStatus }     from '@enums/service-status.enum';

export class CallingProfile {
  id: string;
  status: ServiceStatus;
  createdDate: string;
  updatedDate: string;
  name: string;
  description: string;
  emergencyLocationId: string;
  emergencyLocation: EmergencyLocation;
  policies: {
    [policy: string]: string;
  };
  isSystem?: boolean;
  userCount?: number;
  numberRangeIds: string[];
  numberTagIds: string[];
  locationIds: string[];
  numberRanges?: NumberRange[];
  numberTags?: NumberTag[];
  locations?: Location[];

  constructor(private data?: CallingProfile) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: CallingProfileRaw): CallingProfile {
    this.id                  = data.id;
    this.status              = data.status;
    this.createdDate         = data.created_date;
    this.updatedDate         = data.updated_date;
    this.name                = data.name;
    this.description         = data.description;
    this.policies            = data.policies;
    this.emergencyLocationId = data.emergency_location;
    this.isSystem            = data.is_system;
    this.userCount           = data.user_count;
    this.numberRangeIds      = data.number_range_ids;
    this.numberTagIds        = data.number_tag_ids;
    this.locationIds         = data.number_location_ids;

    return this;
  }
}
