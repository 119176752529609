export class ReportSeriesData {
  id: string;
  name: string;

  static fromApiData(apiData: { id: string; name: string; }): ReportSeriesData {
    const s: ReportSeriesData = new ReportSeriesData();

    s.id   = apiData.id;
    s.name = apiData.name;

    return s;
  }
}
