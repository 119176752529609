import { Component, Inject }             from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup }        from '@angular/forms';
import { AuthScope }                     from '@enums/auth-scope.enum';
import { MicrosoftTeamsSettings }        from '@models/entity/microsoft-teams-settings.model';
import { ScopeService }                  from '@services/scope.service';
import { Observable }                    from 'rxjs';
import { map }                           from 'rxjs/operators';
import { TeamsSettingsForm }             from '@models/form/teams-settings-form.model';
import { SyncMode }                from '@enums/sync-mode.enum';
import { TeamsSettingsDialogData } from '@models/ui/teams-settings-dialog.model';

@Component({
  selector:    'ngx-teams-settings-modal',
  templateUrl: './teams-settings-modal.component.html',
  styleUrls:   ['./teams-settings-modal.component.scss'],
})
export class TeamsSettingsModalComponent {
  formGroup: FormGroup<TeamsSettingsForm>;
  hasCompanyConfigurationWrite$: Observable<boolean>;
  saveTooltip$: Observable<string>;
  modeOptions: { label: string, value: SyncMode }[] = [
    { label: 'All Entra ID users', value: SyncMode.All },
    { label: 'All Teams users', value: SyncMode.TeamsOnly },
    { label: 'Teams voice users', value: SyncMode.VoiceOnly },
  ];

  SyncMode = SyncMode;
  hasAutoProvisioning: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: TeamsSettingsDialogData,
              private matDialog: MatDialogRef<TeamsSettingsModalComponent>,
              private scopeService: ScopeService) {
    this.hasAutoProvisioning = !!this.data.packageFeatures?.includes('AUTO_PROVISIONING');
    this.formGroup           = new FormGroup<TeamsSettingsForm>(
      {
        mode:               new FormControl<SyncMode>(this.data.settings.sync.mode),
        autoProvision:      new FormControl<boolean>(this.data.settings.sync.autoProvision),
        fullSync:           new FormControl<boolean>(this.data.settings.sync.fullSync),
        includeUnavailable: new FormControl<boolean>(this.data.settings.sync.includeUnavailable),
        inboundAssignment:  new FormControl<boolean>(this.data.settings.sync.inboundAssignment),
        magicExtension:     new FormControl<boolean>(this.data.settings.configure.magicExtension),
      },
    );
    this.modeOptions         = this.modeOptions.filter(opt => this.hasAutoProvisioning || opt.value !== SyncMode.All);
    if (!this.hasAutoProvisioning) {
      this.formGroup.get('autoProvision')
        .disable();
    }
    this.hasCompanyConfigurationWrite$ = this.scopeService.hasScopes$(AuthScope.CompanyConfigurationWrite);

    this.saveTooltip$ = this.hasCompanyConfigurationWrite$.pipe(map(hasWrite => {
      return !hasWrite && 'You do not have the required access to save sync settings. Please contact your systems administrator for more information.';
    }));

  }

  done(): void {
    this.matDialog.close(new MicrosoftTeamsSettings().fromFormData(this.formGroup.value));
  }

}
