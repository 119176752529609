import { Component, Input } from '@angular/core';

@Component({
  selector:    'ngx-list-item-text',
  templateUrl: './list-item-text.component.html',
  styleUrls:   ['./list-item-text.component.scss'],
})
export class ListItemTextComponent {
  @Input() text: string;
  @Input() textClass: string;
  @Input() textMargin: string;
  @Input() textAlign: 'left' | 'center' | 'right'            = 'left';
  @Input() colorMode: 'dark' | 'light' | 'normal'            = 'normal';
  @Input() textWhiteSpace: 'nowrap' | 'normal' | 'pre-wrap'  = 'normal';
  @Input() textWhiteSpaceSm: 'nowrap' | 'normal'             = 'nowrap';
  @Input() textWordBreak: 'break-word' | 'break-all'         = 'break-word';
  @Input() textFontWeight: 300 | 400 | 500 | 600             = 400;
  @Input() textFontSize: '13px' | '14px' | '15px' | '18px'   = '15px';
  @Input() textFontSizeSm: '12px' | '13px' | '14px' | '16px' = '13px';
  @Input() textOverflow: 'ellipsis' | 'normal'               = 'ellipsis';
  @Input() hoverDark: boolean;
  @Input() textTooltip: string;

  get ngClass(): string {
    return [this.colorMode, this.textClass].filter(item => !!item)
      .join(' ');
  }
}
