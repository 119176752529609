import { environment } from '../../../environments/environment';
import { AuthScope }   from '@enums/auth-scope.enum';

export class SideNav {
  static getUserNav(viewingCompany: boolean,
                    viewingPartner: boolean,
                    isSipAdmin: boolean,
                    scopes: AuthScope[],
                    reportsDisabled: boolean): Array<NavItem> {
    const navItems: NavItem[] = [];
    if (viewingPartner) {
      navItems.push(...SideNav.getPartnerNav());
    }
    navItems.push(...[
      {
        label:    'Overview',
        icon:     'cr-home',
        link:     ['/overview'],
        hasScope: true,
      },
      {
        label:    'Carriers',
        icon:     'cr-towercell',
        link:     ['/carriers'],
        hasScope: scopes.includes(AuthScope.ServiceRead),
      },
      {
        label:    'Services',
        icon:     'cr-phone-talk',
        link:     ['/services'],
        class:    'service',
        hasScope: scopes.includes(AuthScope.ServiceRead),
      },
      {
        label:    'Numbers',
        icon:     'cr-dialpad',
        link:     ['/numbers'],
        hasScope: scopes.includes(AuthScope.NumberRead),
      },
      {
        label:    'Reporting',
        icon:     'cr-analytics',
        link:     ['/reporting'],
        hasScope: scopes.includes(AuthScope.ReportRead) && !reportsDisabled,
      },
      {
        label:    'Billing',
        icon:     'cr-quote',
        link:     ['/billing'],
        class:    '',
        hasScope: viewingCompany && isSipAdmin,
      },
    ].filter(item => {
      return !(environment.production && ['Features', 'Carrier'].includes(item.label));
    }));
    return navItems;
  }

  static getPartnerNav(): Array<NavItem> {
    return [
      {
        label:    'Customers',
        icon:     'cr-customers',
        link:     ['/customers'],
        hasScope: true,
      },
    ];
  }
}

export class NavItem {
  label: string;
  icon?: string;
  iconClass?: string;
  link?: string[];
  class?: string;
  action?: () => void;
  tooltip?: string;
  hasScope: boolean;
  fragment?: string;
}
