import { UserRaw } from './user-raw.model';

export class PatchUserProfileRequest {
  id: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  isLocalLogin?: boolean;
  checkTokenAfterPatchRedirect?: string;
  isSelf: boolean;

  constructor(private data?: PatchUserProfileRequest) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): Partial<UserRaw> {
    return {
      first_name:     this.firstName,
      last_name:      this.lastName,
      phone_number:   this.phoneNumber,
      is_local_login: this.isLocalLogin,
    };
  }
}
