import { Component, HostBinding }                              from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef }                                        from '@angular/material/dialog';
import { ButtonType }                                          from '@enums/button-type.enum';
import { Observable }                                          from 'rxjs';
import { FieldCheckType }                                      from '@enums/field-check-type.enum';
import { map, startWith }                                      from 'rxjs/operators';

@Component({
  selector:    'ngx-submit-feedback-modal',
  templateUrl: './submit-feedback-modal.component.html',
  styleUrls:   ['./submit-feedback-modal.component.scss'],
})
export class SubmitFeedbackModalComponent {
  @HostBinding('attr.data-cy') dataCy = 'feedback-modal';
  formGroup: FormGroup;
  ButtonType                          = ButtonType;
  improvementsErrorMessage$: Observable<string>;
  highlightErrorMessage$: Observable<string>;
  FieldCheckType                      = FieldCheckType;

  constructor(private dialog: MatDialogRef<SubmitFeedbackModalComponent>) {
    this.formGroup = new FormGroup({
      improvements: new FormControl<string>('', [Validators.required, Validators.minLength(10), Validators.maxLength(5000)]),
      highlights:   new FormControl<string>('', [Validators.required, Validators.minLength(10), Validators.maxLength(5000)]),
      rating:       new FormControl<number>(5, [Validators.required, Validators.min(1), Validators.max(5)]),
    });

    this.improvementsErrorMessage$ = this.formGroup.get('improvements')
      .valueChanges
      .pipe(
        startWith(this.formGroup.get('improvements').value),
        map(value => {
          const len = value?.length || 0;
          if (len < 10) {
            return 'Must be at least 10 characters.';
          }
          if (len > 5000) {
            return 'Must be less than 5000 characters.';
          }
          return 'Field is required.';
        }));

    this.highlightErrorMessage$ = this.formGroup.get('highlights')
      .valueChanges
      .pipe(
        startWith(this.formGroup.get('highlights').value),
        map(value => {
          const len = value?.length || 0;
          if (len < 10) {
            return 'Must be at least 10 characters.';
          }
          if (len > 5000) {
            return 'Must be less than 5000 characters.';
          }
          return 'Field is required.';
        }));
  }

  isInvalid(control: AbstractControl, bSubmitted: boolean): boolean {
    return (!control.valid) && (bSubmitted || control.touched);
  }

  submit(): void {
    if (this.formGroup.invalid) {
      return;
    }
    this.dialog.close(this.formGroup.value);
  }

  close(): void {
    this.dialog.close();
  }
}
