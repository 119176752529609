import { Component, HostBinding, Inject, Input } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef }    from '@angular/material/snack-bar';
import { Alert }                                 from '@models/entity/alert.model';

@Component({
  selector:    'ngx-alert-snack-bar',
  templateUrl: './alert-snack-bar.component.html',
  styleUrls:   ['./alert-snack-bar.component.scss'],
})
export class AlertSnackBarComponent {
  @HostBinding('attr.data-cy') dataCy = 'alert';
  @Input() outline                    = true;

  get message(): string {
    if (!this.alert?.message) {
      return '';
    }
    if (this.alert.message.length > this.messageLimit) {
      return this.alert.message.slice(0, this.messageLimit);
    }
    return this.alert.message;
  }

  get isShowingLess(): boolean {
    return this.messageLimit === this.defaultMessageLimit;
  }

  readonly defaultMessageLimit = 200;
  messageLimit                 = this.defaultMessageLimit;

  get formErrorKeys(): string[] {
    if (!this.alert?.formErrors?.size || (this.alert.formErrors.size === 1 && this.alert.formErrors.has('Errors'))) {
      return null;
    }
    return [...this.alert.formErrors.keys()];
  }

  constructor(@Inject(MAT_SNACK_BAR_DATA) public alert: Alert,
              private snackBarRef: MatSnackBarRef<AlertSnackBarComponent>) { }

  readMore(): void {
    this.messageLimit = this.alert.message.length;
  }

  readLess(): void {
    this.messageLimit = this.defaultMessageLimit;
  }

  dismissAction(): void {
    this.snackBarRef.dismiss();
  }
}
