import { CountryCode }          from '@models/entity/country-code.model';
import { ReportCountryDataRaw } from '@models/api/report-country-data-raw.model';

export class ReportCountryData {
  name: string;
  code: string; // ISO3
  flagName: string;
  count: number;
  color: string;

  static fromApiData(apiData: ReportCountryDataRaw, index: number): ReportCountryData {
    const c: ReportCountryData = new ReportCountryData();
    const cc: CountryCode      = new CountryCode();

    c.name     = apiData.country_name;
    c.code     = apiData.country_code;
    c.flagName = apiData.country_code ? cc.getCountryISO2(apiData.country_code) : 'unknown';
    c.count    = apiData.count;


    if (apiData.country_code === 'GBR') {
      c.name = 'United Kingdom';
    }

    switch (index) {
      case 0:
        c.color = '#5b4cff';
        break;
      case 1:
        c.color = '#f0bb00';
        break;
      case 2:
        c.color = '#00c68d';
        break;
      case 3:
        c.color = '#eb0e46';
        break;

      default:
        c.color = '#eb0e46';
        break;
    }

    return c;
  }
}
