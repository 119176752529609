export function splitURLByPath(path: string): { queryParams: { [param: string]: string }, path: string, fragment: string } {

  let url                                        = decodeURIComponent(path);
  let fragment;
  const queryParams: { [param: string]: string } = {};

  if (url.includes('#')) {
    const arr = url.split('#');
    fragment  = arr.pop();
    url       = arr.join('');
  }

  if (url?.includes('?')) {
    const splitURL = url
      .split('?');
    url            = splitURL[0];
    new URLSearchParams(`?${ splitURL[1] }`).forEach((value, key) =>
      queryParams[key] = value.replace(' ', '+'));
  }
  return { queryParams, path: url, fragment };

}
