import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class CarrierQueryParams extends ListParams implements ListParamModel<CarrierQueryParams> {
  search?: string;
  show?: string;

  constructor(private data?: CarrierQueryParams) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  static constructQueryString(searchParams: CarrierQueryParams): string {
    const params: CarrierQueryParams = { ...(searchParams || {}) as CarrierQueryParams };
    return ListParams.constructQueryString(params, {
      search: 'filter[search]',
      show:   'filter[show]',
    });
  }

  static queryChanged(newQuery: CarrierQueryParams, currentQuery: CarrierQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery);
  }

  constructParams?(metaData: ListResponseMetadata): CarrierQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.search) {
        this.search = metaData.filter.search;
      }
      if (metaData.filter.show) {
        this.show = metaData.filter.show;
      }
    }

    return this;
  }
}
