import { CountByCapabilityRaw } from '@models/api/count-by-capability-raw.model';

export class CountByCapability {
  conferenceAssignmentCount: number;
  mobileAssignmentCount: number;
  userAssignmentCount: number;
  voiceAssignmentCount: number;

  constructor(private data?: CountByCapability) {
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  fromApiData?(data: CountByCapabilityRaw): CountByCapability {
    this.conferenceAssignmentCount = data.conference_assignment_count;
    this.mobileAssignmentCount     = data.mobile_assignment_count;
    this.userAssignmentCount       = data.user_assignment_count;
    this.voiceAssignmentCount      = data.voice_assignment_count;
    return this;
  }

}
