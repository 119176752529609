import { ChangeDetectorRef, Component, Inject, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';

import { UntypedFormGroup, Validators } from '@angular/forms';

import { Store }                                      from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith, tap }                        from 'rxjs/operators';

import { ServiceState }                                                           from '@redux/service/service.reducer';
import { StoreState }                                                             from '@redux/store';
import {
  WebexCalling,
}                                                                                 from '@models/entity/webex-calling.model';
import { ServiceType }                                                            from '@enums/service-type.enum';
import { CONFIGURE_SERVICE_REQUEST, PATCH_SERVICE_REQUEST, POST_SERVICE_REQUEST } from '@redux/service/service.types';
import { MAT_DIALOG_DATA, MatDialogRef }                                          from '@angular/material/dialog';
import { DismissErrorAction, DismissMessageAction }                               from '@redux/ui/ui.actions';
import {
  selectService,
}                                                                                 from '@redux/service/service.selectors';
import { WebexService }                                                           from '@services/webex.service';
import { PatchServiceRequestAction, PostServiceRequestAction }                    from '@redux/service/service.actions';
import {
  ServiceItemRaw,
}                                                                                 from '@models/api/service-api-response.model';
import {
  WebexCallingRaw,
}                                                                                 from '@models/api/webex-calling-raw.model';
import { FormService }                                                            from '@services/form.service';
import { AssetsService }                                                          from '@services/assets.service';
import { ScopeService }                                                           from '@services/scope.service';
import { AuthScope }                                                              from '@enums/auth-scope.enum';
import { ButtonType }                                                             from '@enums/button-type.enum';

@Component({
  selector:    'ngx-webex-service-modal',
  templateUrl: './webex-service-modal.component.html',
  styleUrls:   ['./webex-service-modal.component.scss'],
})
export class WebexServiceModalComponent implements OnChanges {
  @Input() service: WebexCalling;
  serviceReducer$: Observable<ServiceState>;
  formGroup: UntypedFormGroup;
  hiddenUsername: string;
  hiddenPassword: string;
  editMode   = new BehaviorSubject(false);
  editMode$: Observable<boolean>;
  isPending$: Observable<boolean>;
  submitDisabled$: Observable<boolean>;
  btnDisabled$: Observable<boolean>;
  webexIcon  = AssetsService.getIcon('webex.png');
  ButtonType = ButtonType;
  subheader  = 'For more information about adding the Webex Calling service please refer to the ' +
    '<a href="https://help.callroute.com/space/CKB/699006999/Webex+Calling" ' +
    'target="_blank">knowledge base article</a>.';

  private webexData: WebexCalling;
  private formFields = new Map<keyof WebexCallingRaw, string>([
    ['trunk_group_otg_dtg', 'Trunk group'],
    ['outbound_proxy_address', 'Outbound proxy address'],
    ['registrar_domain', 'Registrar domain'],
    ['line_port', 'Line/port'],
    ['label', 'Label'],
    ['username', 'Username'],
    ['password', 'Password'],
  ]);
  private formValueChanged$: Observable<boolean>;

  constructor(
    private store: Store<StoreState>,
    private changeDetectorRef: ChangeDetectorRef,
    private webexService: WebexService,
    private scopeService: ScopeService,
    @Optional() public dialogRef: MatDialogRef<WebexServiceModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { service: WebexCalling },
  ) {
    if (this.dialogRef) {
      this.initForm(this.data.service);
    }
  }

  private initForm(data: WebexCalling): void {
    if (!this.service) {
      this.service = data;
    }
    this.serviceReducer$ = this.store.select(selectService);

    this.isPending$ = this.serviceReducer$.pipe(map(service =>
      service.pendingTasks.some(task => [CONFIGURE_SERVICE_REQUEST, POST_SERVICE_REQUEST, PATCH_SERVICE_REQUEST].includes(task.id))));

    this.webexData = this.service as WebexCalling;
    if (this.webexData) {
      const hiddenSuffix  = Date.now()
        .toString(); // prevents conflicts with user-inputted values
      this.hiddenUsername = 'U_' + hiddenSuffix;
      this.hiddenPassword = 'P_' + hiddenSuffix;
    } else {
      this.hiddenUsername = '';
      this.hiddenPassword = '';
    }
    this.formGroup = this.webexService.newForm(this.webexData, this.hiddenUsername, this.hiddenPassword);
    if (this.webexData) {
      this.setEditCredsValidators();
    }

    this.editMode$ = this.editMode.asObservable()
      .pipe(map(edit => edit || this.isSetup()), tap(edit => {
        if (edit) {
          this.formGroup?.enable();
          this.changeDetectorRef.detectChanges();
          return;
        }
        this.formGroup?.disable();
        this.changeDetectorRef.detectChanges();
      }));

    this.setupValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.service?.currentValue && !changes.service?.previousValue) {
      this.initForm(changes.service.currentValue);
    }
  }

  isSetup(): boolean {
    if (!this.webexData) {
      return true;
    }
    return Object.values(this.webexData)
      .every(val => !val);
  }

  editClick(): void {
    if (this.editMode.value) {
      this.revertForm();
    }
    this.editMode.next(!this.editMode.value);
  }

  revertForm(): void {
    this.formGroup.reset({
      type:                 ServiceType.WebexCalling,
      username:             this.hiddenUsername,
      password:             this.hiddenPassword,
      registrarDomain:      this.webexData.registrarDomain,
      trunkGroup:           this.webexData.trunkGroup,
      outboundProxyAddress: this.webexData.outboundProxyAddress,
      linePort:             this.webexData.linePort,
      label:                this.webexData.label,
    });
  }

  setEditCredsValidators(): void {
    this.formGroup.get('username')
      .setValidators([Validators.maxLength(64), Validators.pattern(/^\S+$/)]);
    this.formGroup.get('password')
      .setValidators([Validators.maxLength(64), Validators.pattern(/^\S+$/)]);
  }

  submitForm(): void {
    if (this.formGroup.valid) {
      this.store.dispatch(DismissErrorAction());
      this.store.dispatch(DismissMessageAction());
      const { username, password, ...others } = this.formGroup.value;
      const usernameUpdated                   = username && username !== this.hiddenUsername;
      const passwordUpdated                   = password && password !== this.hiddenPassword;
      let formValue                           = usernameUpdated && passwordUpdated ? {
        username,
        password, ...others,
      } : others;
      if (usernameUpdated && !passwordUpdated) {
        formValue = { username, ...others };
      } else if (passwordUpdated && !usernameUpdated) {
        formValue = { password, ...others };
      }
      const formFields = this.formFields as unknown as Map<keyof ServiceItemRaw, string>;
      if (this.service) {
        this.store.dispatch(PatchServiceRequestAction({
          serviceItem: { ...formValue, id: this.service.id },
          formFields,
        }));
        this.webexData = new WebexCalling({
          ...this.webexData,
          label:                formValue.label,
          trunkGroup:           formValue.trunkGroup,
          outboundProxyAddress: formValue.outboundProxyAddress,
          registrarDomain:      formValue.registrarDomain,
          linePort:             formValue.linePort,
        } as WebexCalling);
        this.setupValidators();
        this.formGroup.markAsUntouched();
        this.editMode.next(false);
        this.formGroup.patchValue({ username: this.hiddenUsername, password: this.hiddenPassword });
        this.setEditCredsValidators();
      } else {
        this.store.dispatch(PostServiceRequestAction({
          serviceItem: formValue, formFields,
        }));
        this.dialogRef?.close();
      }
    }
  }

  private setupValidators(): void {
    this.formValueChanged$ = FormService.formValueChanged$(
      this.formGroup,
      this.webexData,
      [
        'label',
        'linePort',
        'registrarDomain',
        'outboundProxyAddress',
        'trunkGroup',
      ])
      .pipe(map(changed => {
        if (changed) {
          return true;
        }
        // need to detect these changes manually because they are populated with random values
        const username        = this.formGroup.get('username').value;
        const usernameChanged = username && username !== this.hiddenUsername;
        const password        = this.formGroup.get('password').value;
        const passwordChanged = password && password !== this.hiddenPassword;
        return passwordChanged || usernameChanged;
      }));

    this.btnDisabled$ = this.scopeService.hasScopes$(AuthScope.ServiceWrite)
      .pipe(map(hasScope => !hasScope));

    this.submitDisabled$ = combineLatest([
      this.isPending$,
      FormService.isValid$(this.formGroup),
      this.formGroup.valueChanges.pipe(startWith(this.formGroup.value)),
      this.formValueChanged$])
      .pipe(map(([isPending, valid, _, changed]) => {
        return isPending || !valid || this.formGroup.pristine || !changed;
      }));
  }
}
