import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef }                   from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DismissErrorAction, DismissMessageAction }        from '../../../../redux/ui/ui.actions';
import { StoreState }                                      from '../../../../redux/store';
import { Store }                                           from '@ngrx/store';

interface TextInputData {
  title: string;
  fieldLabel: string;
  existingData: string;
  confirmText: string;
  maxLength: number;
}

@Component({
  selector:    'ngx-text-input-modal',
  templateUrl: './text-input-modal.component.html',
  styleUrls:   ['./text-input-modal.component.scss'],
})
export class TextInputModalComponent {

  public formGroup: FormGroup<{ input: FormControl<string> }>;

  constructor(
    private store: Store<StoreState>,
    @Inject(MAT_DIALOG_DATA) public inputData: TextInputData,
    private fb: FormBuilder,
    private dialog: MatDialogRef<TextInputModalComponent>,
  ) {
    this.formGroup = this.fb.group({
      input: [this.inputData.existingData ? this.inputData.existingData : '',
              Validators.compose([Validators.maxLength(this.inputData.maxLength ? this.inputData.maxLength : 64)])],
    });
  }

  submit(): void {
    this.store.dispatch(DismissErrorAction());
    this.store.dispatch(DismissMessageAction());
    this.dialog.close(this.formGroup.get('input').value);
  }
}
