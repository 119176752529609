export const HEALTH_CHECK_REQUEST  = 'HEALTH_CHECK_REQUEST';
export const HEALTH_CHECK_RESPONSE = 'HEALTH_CHECK_RESPONSE';

export const REQUIREMENT_CHECK_REQUEST  = 'REQUIREMENT_CHECK_REQUEST';
export const REQUIREMENT_CHECK_RESPONSE = 'REQUIREMENT_CHECK_RESPONSE';

export const CLEAR_CHECK_STATE = 'CLEAR_CHECK_STATE';

export const FETCH_LICENSE_GROUP_LIST_REQUEST  = 'FETCH_LICENSE_GROUP_LIST_REQUEST';
export const FETCH_LICENSE_GROUP_LIST_RESPONSE = 'FETCH_LICENSE_GROUP_LIST_RESPONSE';

export const FETCH_LICENSE_GROUP_REQUEST  = 'FETCH_LICENSE_GROUP_REQUEST';
export const FETCH_LICENSE_GROUP_RESPONSE = 'FETCH_LICENSE_GROUP_RESPONSE';

export const FETCH_LICENSES_REQUEST  = 'FETCH_LICENSES_REQUEST';
export const FETCH_LICENSES_RESPONSE = 'FETCH_LICENSES_RESPONSE';

export const FETCH_TEAMS_REQUEST  = 'FETCH_TEAMS_REQUEST';
export const FETCH_TEAMS_RESPONSE = 'FETCH_TEAMS_RESPONSE';

export const FETCH_TEAM_GROUP_REQUEST  = 'FETCH_TEAM_GROUP_REQUEST';
export const FETCH_TEAM_GROUP_RESPONSE = 'FETCH_TEAM_GROUP_RESPONSE';

export const DELETE_TEAMS_GROUP_REQUEST  = 'DELETE_TEAMS_GROUP_REQUEST';
export const DELETE_TEAMS_GROUP_RESPONSE = 'DELETE_TEAMS_GROUP_RESPONSE';

export const DELETE_LICENSE_GROUP_REQUEST  = 'DELETE_LICENSE_GROUP_REQUEST';
export const DELETE_LICENSE_GROUP_RESPONSE = 'DELETE_LICENSE_GROUP_RESPONSE';

export const POST_LICENSE_GROUP_REQUEST  = 'POST_LICENSE_GROUP_REQUEST';
export const POST_LICENSE_GROUP_RESPONSE = 'POST_LICENSE_GROUP_RESPONSE';

export const PATCH_LICENSE_GROUP_REQUEST  = 'PATCH_LICENSE_GROUP_REQUEST';
export const PATCH_LICENSE_GROUP_RESPONSE = 'PATCH_LICENSE_GROUP_RESPONSE';

export const POST_TEAM_GROUP_REQUEST  = 'POST_TEAM_GROUP_REQUEST';
export const POST_TEAM_GROUP_RESPONSE = 'POST_TEAM_GROUP_RESPONSE';

export const PATCH_TEAM_GROUP_REQUEST  = 'PATCH_TEAM_GROUP_REQUEST';
export const PATCH_TEAM_GROUP_RESPONSE = 'PATCH_TEAM_GROUP_RESPONSE';

export const FETCH_LICENSE_GROUP_NAME_AVAILABILITY_REQUEST  = 'FETCH_LICENSE_GROUP_NAME_AVAILABILITY_REQUEST';
export const FETCH_LICENSE_GROUP_NAME_AVAILABILITY_RESPONSE = 'FETCH_LICENSE_GROUP_NAME_AVAILABILITY_RESPONSE';

export const FETCH_TEAM_GROUP_NAME_AVAILABILITY_REQUEST  = 'FETCH_TEAM_GROUP_NAME_AVAILABILITY_REQUEST';
export const FETCH_TEAM_GROUP_NAME_AVAILABILITY_RESPONSE = 'FETCH_TEAM_GROUP_NAME_AVAILABILITY_RESPONSE';

export const FETCH_TEAM_GROUP_LIST_REQUEST  = 'FETCH_TEAM_GROUP_LIST_REQUEST';
export const FETCH_TEAM_GROUP_LIST_RESPONSE = 'FETCH_TEAM_GROUP_LIST_RESPONSE';

export const FETCH_CALL_QUEUE_GROUP_LIST_REQUEST  = 'FETCH_CALL_QUEUE_GROUP_LIST_REQUEST';
export const FETCH_CALL_QUEUE_GROUP_LIST_RESPONSE = 'FETCH_CALL_QUEUE_GROUP_LIST_RESPONSE';

export const FETCH_CALL_QUEUE_GROUP_REQUEST  = 'FETCH_CALL_QUEUE_GROUP_REQUEST';
export const FETCH_CALL_QUEUE_GROUP_RESPONSE = 'FETCH_CALL_QUEUE_GROUP_RESPONSE';

export const FETCH_CALL_QUEUES_REQUEST  = 'FETCH_CALL_QUEUES_REQUEST';
export const FETCH_CALL_QUEUES_RESPONSE = 'FETCH_CALL_QUEUES_RESPONSE';

export const DELETE_CALL_QUEUE_GROUP_REQUEST  = 'DELETE_CALL_QUEUE_GROUP_REQUEST';
export const DELETE_CALL_QUEUE_GROUP_RESPONSE = 'DELETE_CALL_QUEUE_GROUP_RESPONSE';

export const FETCH_CALL_QUEUE_GROUP_NAME_AVAILABILITY_REQUEST  = 'FETCH_CALL_QUEUE_GROUP_NAME_AVAILABILITY_REQUEST';
export const FETCH_CALL_QUEUE_GROUP_NAME_AVAILABILITY_RESPONSE = 'FETCH_CALL_QUEUE_GROUP_NAME_AVAILABILITY_RESPONSE';

export const POST_CALL_QUEUE_GROUP_REQUEST  = 'POST_CALL_QUEUE_GROUP_REQUEST';
export const POST_CALL_QUEUE_GROUP_RESPONSE = 'POST_CALL_QUEUE_GROUP_RESPONSE';

export const PATCH_CALL_QUEUE_GROUP_REQUEST  = 'PATCH_CALL_QUEUE_GROUP_REQUEST';
export const PATCH_CALL_QUEUE_GROUP_RESPONSE = 'PATCH_CALL_QUEUE_GROUP_RESPONSE';

export const FETCH_AD_GROUPS_REQUEST  = 'FETCH_AD_GROUPS_REQUEST';
export const FETCH_AD_GROUPS_RESPONSE = 'FETCH_AD_GROUPS_RESPONSE';
