import { Observable, of }  from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import {
  environment,
}                     from '../../environments/environment';
import {
  CalculatePackageCostRequest,
  CalculatePackageCostResponse,
  CalculatePackageCostResponseRaw,
  ChangePackageRequest,
  ChangePackageResponse,
  FetchActivePackageResponse,
  FetchPackageListResponse,
  SubscriptionPackageData,
  SubscriptionPackageFeature,
  SubscriptionProduct,
}                     from '@models/entity/subscription.model';
import {
  SubscriptionPackageFeatureItemRaw,
  SubscriptionPackageItemRaw,
  SubscriptionProductItemRaw,
}                     from '@models/api/subscription-api-response.model';
import {
  DateService,
}                     from '@services/date.service';
import {
  BasePaginationItemRaw,
}                     from '@models/api/pagination-api-response.model';
import {
  Alert,
}                     from '@models/entity/alert.model';
import {
  HttpErrorResponse,
}                     from '@angular/common/http';
import {
  FetchActiveLicenseResponse,
}                     from '@models/api/fetch-active-license-response.model';
import {
  PackageCapabilities,
}                     from '@models/entity/package-capabilities.model';
import {
  FetchPackageCapabilitiesResponse,
}                     from '@models/api/fetch-package-capabilities-response.model';
import {
  ProductIdentifier,
}                     from '@enums/product-identifier.enum';


@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private baseUrl: string = environment.api.accountingBaseUrl;

  constructor(
    private apiService: ApiService,
    private dateService: DateService,
  ) {}

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  private buildPackageChangeUri(): string {
    return this.buildUri(`packages/change`);
  }

  private buildPackageChangeCostUri(): string {
    return this.buildUri(`packages/change/cost`);
  }

  private buildPackagesUri(): string {
    return this.buildUri('packages');
  }

  private buildCapabilitiesUri(): string {
    return this.buildUri(`capabilities`);
  }

  private buildActiveLicensesUri(): string {
    return this.buildUri(`inventory?filter[product_type]=LICENSE&filter[show_expired]=true`);
  }

  private buildActivePackageUri(): string {
    return this.buildUri(`inventory?filter[product_type]=PACKAGE&include=product.attributes,product.catalogue`);
  }

  fetchActivePackage$(): Observable<FetchActivePackageResponse> {
    return this.apiService.apiGet$<{ data: SubscriptionPackageItemRaw[], meta: BasePaginationItemRaw }>(
      this.buildActivePackageUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: SubscriptionPackageItemRaw[], meta: BasePaginationItemRaw }): FetchActivePackageResponse => {
          return {
            error:   null,
            package: res && res.data && res.data[0] ? SubscriptionPackageData.fromApiData(res.data[0], this.dateService) : null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchActivePackageResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            package: null,
          });
        }),
      );
  }

  fetchActiveLicenses$(): Observable<FetchActiveLicenseResponse> {
    return this.apiService.apiGet$<{ data: SubscriptionPackageItemRaw[], meta: BasePaginationItemRaw }>(
      this.buildActiveLicensesUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: SubscriptionPackageItemRaw[], meta: BasePaginationItemRaw }): FetchActiveLicenseResponse => {
          return {
            error:    null,
            licenses: res.data.map(license => SubscriptionPackageData.fromApiData(license, this.dateService)),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchActiveLicenseResponse> => {
          return of({
            error:    new Alert().fromApiError(err),
            licenses: null,
          });
        }),
      );
  }

  fetchPackageCapabilities$(): Observable<FetchPackageCapabilitiesResponse> {
    return this.apiService.apiGet$<{ data: PackageCapabilities }>(
      this.buildCapabilitiesUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: PackageCapabilities }): FetchPackageCapabilitiesResponse => {
          return {
            error: null,
            data:  res.data,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchPackageCapabilitiesResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchPackageList$(): Observable<FetchPackageListResponse> {
    return this.apiService.apiGet$<{
      data: Array<SubscriptionProductItemRaw>,
      meta: { catalogue: Array<SubscriptionPackageFeatureItemRaw> }
    }>(
      this.buildPackagesUri(),
      { authRequired: true })
      .pipe(
        map((res: {
          data: Array<SubscriptionProductItemRaw>,
          meta: { catalogue: Array<SubscriptionPackageFeatureItemRaw> }
        }): FetchPackageListResponse => {
          return {
            error:       null,
            productList: res && res.data ? res.data.filter(p => p.identifier !== ProductIdentifier.PackageEnterprise)
              .map(r => SubscriptionProduct.fromApiData(r))
              .sort((a, b) =>
                a.order - b.order,
              ) : null,
            featureList: res && res.data && res.meta.catalogue ?
                           res.meta.catalogue.map(r => SubscriptionPackageFeature.fromApiData(r))
                             .sort((a, b) =>
                               a.order - b.order,
                             ) : null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchPackageListResponse> => {
          return of({
            error:       new Alert().fromApiError(err),
            productList: null,
            featureList: null,
          });
        }),
      );
  }

  calculatePackageChangeCost$(req: CalculatePackageCostRequest): Observable<CalculatePackageCostResponse> {
    return this.apiService.apiPost$<{ data: CalculatePackageCostResponseRaw }>(
      this.buildPackageChangeCostUri(),
      req,
      { authRequired: true })
      .pipe(
        map((res: { data: CalculatePackageCostResponseRaw }): CalculatePackageCostResponse => {
          return {
            error: null,
            cost:  res.data,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<CalculatePackageCostResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            cost:  null,
          });
        }),
      );
  }

  changePackage(req: ChangePackageRequest): Observable<ChangePackageResponse> {
    return this.apiService.apiPost$<{ data: SubscriptionPackageItemRaw }>(
      this.buildPackageChangeUri(),
      ChangePackageRequest.toApiData(req),
      { authRequired: true })
      .pipe(
        map((res: { data: SubscriptionPackageItemRaw }): ChangePackageResponse => {
          return {
            error:   null,
            package: res && res.data ? SubscriptionPackageData.fromApiData(res.data, this.dateService) : null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<ChangePackageResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            package: null,
          });
        }),
      );
  }


}
