import * as AccountingAction from './accounting.actions';
import * as AuthAction       from '../auth/auth.actions';

import { Alert }                                    from '@models/entity/alert.model';
import { StateTask }                                from '@models/entity/state-task.model';
import { BaseState, requestReduce, responseReduce } from '../helpers/reducer.helper';
import { Action, createReducer, on }                from '@ngrx/store';
import {
  EXTEND_FREE_TRIAL_REQUEST,
  FETCH_BALANCE_DATA_REQUEST,
  FETCH_CREDIT_REPORT_REQUEST,
  FETCH_FEATURE_INVENTORY_REQUEST,
  FETCH_LAST_PAYMENT_LIST_REQUEST,
  FETCH_LAST_PAYMENT_SUMMARY_REQUEST,
  FETCH_PAYMENT_METHODS_REQUEST,
  FETCH_SPEND_REPORT_REQUEST,
  TOPUP_BALANCE_REQUEST,
}                                                   from './accounting.types';
import { LastPaymentData }                          from '@models/entity/last-payment-data.model';
import { AccountingQueryParams }                    from '@models/form/accounting-query-params.model';
import { Balance }                                  from '@models/entity/balance.model';
import { BillingGraphData }                         from '@models/chart/billing-graph.model';
import * as UIAction                                from '../ui/ui.actions';
import { DateRange }                                from '@models/form/date-range.model';
import { AccountingFeature }                        from '@models/entity/accounting-feature.model';
import { createEntityAdapter, EntityAdapter }       from '@ngrx/entity';

export interface AccountingState extends BaseState<AccountingFeature> {
  error: Alert;
  message: Alert;
  pending: boolean;
  dateRange: DateRange;
  pendingTasks: Array<StateTask>;
  balanceData: Balance;
  spendGraphData: BillingGraphData;
  paymentGraphData: BillingGraphData;
  lastPaymentArray: LastPaymentData[];
  lastPaymentSummary: LastPaymentData[];
  lastPaymentQueryParams: AccountingQueryParams;
  paymentMethods: any[],
  paymentSuccessful: boolean;
  features: AccountingFeature[]
}

export function selectFeatureId(a: AccountingFeature): string {
  return a.identifier;
}

export const adapter: EntityAdapter<AccountingFeature> = createEntityAdapter<AccountingFeature>({
  selectId: selectFeatureId,
});

const initialState: AccountingState = adapter.getInitialState({
  error:                  null,
  message:                null,
  pending:                false,
  pendingTasks:           [],
  dateRange:              null,
  balanceData:            null,
  spendGraphData:         null,
  paymentGraphData:       null,
  lastPaymentArray:       null,
  lastPaymentSummary:     null,
  lastPaymentQueryParams: null,
  paymentMethods:         [],
  paymentSuccessful:      null,
  features:               null,
});

const _accountingReducer = createReducer(initialState,
  on(AccountingAction.TopupRequestAction, requestReduce),
  on(AccountingAction.TopupResponseAction, (state, action) =>
    responseReduce(state, action, TOPUP_BALANCE_REQUEST, res => ({
      paymentSuccessful: res.success,
    }))),
  on(AccountingAction.NewTopupSession, (state) => ({
    ...state,
    paymentSuccessful: null,
  })),
  on(AccountingAction.FetchPaymentMethodsRequestAction, requestReduce),
  on(AccountingAction.FetchPaymentMethodsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_PAYMENT_METHODS_REQUEST, res => ({
      paymentMethods: res.methods,
    }))),
  on(AccountingAction.FetchBalanceDataRequestAction, requestReduce),
  on(AccountingAction.FetchBalanceDataResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_BALANCE_DATA_REQUEST, res => ({
      balanceData: res.data,
    }))),
  on(AccountingAction.FetchSpendReportRequestAction, requestReduce),
  on(AccountingAction.FetchSpendReportResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SPEND_REPORT_REQUEST, res => ({
      spendGraphData: res.data,
    }))),
  on(AccountingAction.FetchCreditReportRequestAction, requestReduce),
  on(AccountingAction.FetchCreditReportResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CREDIT_REPORT_REQUEST, res => ({
      paymentGraphData: res.data,
    }))),
  on(AccountingAction.FetchLastPaymentListRequestAction, requestReduce),
  on(AccountingAction.FetchLastPaymentListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LAST_PAYMENT_LIST_REQUEST, res => ({
      lastPaymentArray:       res.models,
      lastPaymentQueryParams: res.error ? state.lastPaymentQueryParams : res.searchParams,
    }))),
  on(AccountingAction.FetchLastPaymentSummaryRequestAction, requestReduce),
  on(AccountingAction.FetchLastPaymentSummaryResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LAST_PAYMENT_SUMMARY_REQUEST, res => ({
      lastPaymentSummary: res.models,
    }))),
  on(AccountingAction.ExtendFreeTrialRequestAction, requestReduce),
  on(AccountingAction.ExtendFreeTrialResponseAction, (state, action) =>
    responseReduce(state, action, EXTEND_FREE_TRIAL_REQUEST)),
  on(AccountingAction.FetchFeatureInventoryRequestAction, requestReduce),
  on(AccountingAction.FetchFeatureInventoryResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_FEATURE_INVENTORY_REQUEST, res => ({
      features: res.features,
    }))),
  on(AccountingAction.SetAccountingDateRange, (state, action) => ({
    ...state,
    dateRange: { startDate: action.startDate, endDate: action.endDate },
  })),
  on(UIAction.DismissErrorAction, (state) =>
    ({ ...state, error: null })),
  on(UIAction.DismissMessageAction, (state) =>
    ({ ...state, message: null })),
  on(AuthAction.LogoutAction, () =>
    ({ ...initialState })),
);


export function accountingReducer(state: AccountingState, action: Action): AccountingState {
  return _accountingReducer(state, action);
}
