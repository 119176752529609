import { CallQueue }         from '@models/entity/call-queue.model';
import { CallQueueGroupRaw } from '@models/api/microsoft-teams/call-queue-group-raw.model';

export class CallQueueGroup {
  id: string;
  name: string;
  description: string;
  updatedDate: string;
  createdDate: string;
  callQueueIds: string[];
  callQueues: CallQueue[];
  requestId?: string;

  constructor(private data?: CallQueueGroup) {
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  fromApiData?(data: CallQueueGroupRaw & { requestId?: string }): CallQueueGroup {
    this.id           = data.id;
    this.name         = data.name;
    this.description  = data.description;
    this.updatedDate  = data.updated_date;
    this.createdDate  = data.created_date;
    this.callQueueIds = data.call_queue_ids;
    this.requestId    = data.requestId;

    return this;
  }
}
