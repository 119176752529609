import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class GatewayStatusQueryParams extends ListParams implements ListParamModel<GatewayStatusQueryParams> {
  identifier?: string;
  status?: string;

  static constructQueryString(searchParams: GatewayStatusQueryParams): string {
    const params: GatewayStatusQueryParams = { ...(searchParams || {}) as GatewayStatusQueryParams };

    return ListParams.constructQueryString(params, {
      identifier: 'filter[identifier]',
      status:     'filter[status]',
    });
  }

  static queryChanged(newQuery: GatewayStatusQueryParams, currentQuery: GatewayStatusQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery);
  }

  constructParams?(metaData: ListResponseMetadata): GatewayStatusQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.identifier) {
        this.identifier = metaData.filter.identifier;
      }
      if (metaData.filter.status) {
        this.status = metaData.filter.status as string;
      }
    }

    return this;
  }
}
