<form data-cy="form" [formGroup]="formGroup" novalidate #ngForm="ngForm" (keydown.enter)="submitted.emit()"
      *ngIf="formGroup" class="webex-form">
  <div class="webex-form__group mt-24" fxLayout="row wrap" fxLayoutAlign="space-between">
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else viewLabel">
        <mat-form-field appearance="outline">
          <mat-label>Label</mat-label>
          <input matInput
                 type="text"
                 formControlName="label"
                 data-cy="form-label"
                 maxLength="30"
                 [hidden]="!editMode"
                 [class.field-invalid]="determineFieldValid(formGroup.get('label'), ngForm.submitted)"
                 autocomplete="off" />
          <ngx-field-notification
            *ngIf="editMode && formGroup.get('label').value && formGroup.get('label').invalid"
            [type]="FieldCheckType.Invalid"
            class="mb-12"
            message="Label is not valid"></ngx-field-notification>
        </mat-form-field>
      </ng-container>
      <ng-template #viewLabel>
        <label class="field-label">Label</label>
        <p class="color-black" [hidden]="editMode">{{ formGroup.get('label').value }}</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else viewTrunkGroup">
        <mat-form-field appearance="outline">
          <mat-label>Trunk group OTG/DTG</mat-label>
          <input matInput
                 type="text"
                 formControlName="trunkGroup"
                 data-cy="form-trunk-group"
                 maxLength="128"
                 [hidden]="!editMode"
                 [class.field-invalid]="determineFieldValid(formGroup.get('trunkGroup'), ngForm.submitted)"
                 autocomplete="off" />
          <ngx-field-notification
            *ngIf="editMode && formGroup.get('trunkGroup').value && formGroup.get('trunkGroup').invalid"
            [type]="FieldCheckType.Invalid"
            class="mb-12"
            message="Trunk group is not valid"></ngx-field-notification>
        </mat-form-field>
      </ng-container>
      <ng-template #viewTrunkGroup>
        <label class="field-label">Trunk group OTG/DTG</label>
        <p class="color-black" [hidden]="editMode">{{ formGroup.get('trunkGroup').value }}</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else viewPort">
        <mat-form-field appearance="outline">
          <mat-label>Line/port</mat-label>
          <input matInput
                 type="text"
                 formControlName="linePort"
                 data-cy="form-line-port"
                 maxLength="128"
                 [hidden]="!editMode"
                 [class.field-invalid]="determineFieldValid(formGroup.get('linePort'), ngForm.submitted)"
                 autocomplete="off" />
          <ngx-field-notification
            *ngIf="editMode && formGroup.get('linePort').value && formGroup.get('linePort').invalid"
            [type]="FieldCheckType.Invalid"
            class="mb-12"
            message="Line port is not valid (e.g. name@domain.com:1234)"></ngx-field-notification>
        </mat-form-field>
      </ng-container>
      <ng-template #viewPort>
        <label class="field-label">Line/port</label>
        <p class="color-black" [hidden]="editMode">{{ formGroup.get('linePort').value }}</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else viewOutboundProxy">
        <mat-form-field appearance="outline">
          <mat-label>Outbound proxy address</mat-label>
          <input matInput
                 type="text"
                 data-cy="form-outbound-proxy-address"
                 formControlName="outboundProxyAddress"
                 maxLength="128"
                 [hidden]="!editMode"
                 [class.field-invalid]="determineFieldValid(formGroup.get('outboundProxyAddress'), ngForm.submitted)"
                 autocomplete="off" />
          <ngx-field-notification
            *ngIf="editMode && formGroup.get('outboundProxyAddress').value && formGroup.get('outboundProxyAddress').invalid"
            [type]="FieldCheckType.Invalid"
            class="mb-12"
            message="Outbound proxy address is not a valid domain"></ngx-field-notification>
        </mat-form-field>
      </ng-container>
      <ng-template #viewOutboundProxy>
        <label class="field-label">Outbound proxy address</label>
        <p class="color-black" [hidden]="editMode">{{ formGroup.get('outboundProxyAddress').value }}</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else viewUsername">
        <mat-form-field appearance="outline">
          <mat-label>Username</mat-label>
          <input matInput type="password"
                 data-cy="form-username"
                 #usernameInput
                 (focus)="usernameInput.type = 'text'; usernameFocused()"
                 formControlName="username" maxLength="64"
                 [hidden]="!editMode"
                 [class.field-invalid]="determineFieldValid(formGroup.get('username'), ngForm.submitted)"
                 autocomplete="off" />
        </mat-form-field>
      </ng-container>
      <ng-template #viewUsername>
        <label class="field-label">Username</label>
        <p class="color-black" [hidden]="editMode">•••••••••••</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else viewRegistrar">
        <mat-form-field appearance="outline">
          <mat-label>Registrar domain</mat-label>
          <input matInput
                 type="text"
                 data-cy="form-registrar-domain"
                 formControlName="registrarDomain"
                 maxLength="128"
                 [hidden]="!editMode"
                 [class.field-invalid]="determineFieldValid(formGroup.get('registrarDomain'), ngForm.submitted)"
                 autocomplete="off" />
          <ngx-field-notification
            *ngIf="editMode && formGroup.get('registrarDomain').value && formGroup.get('registrarDomain').invalid"
            [type]="FieldCheckType.Invalid"
            class="mb-12"
            message="Registrar domain is not a valid domain"></ngx-field-notification>
        </mat-form-field>
      </ng-container>
      <ng-template #viewRegistrar>
        <label class="field-label">Registrar domain</label>
        <p class="color-black" [hidden]="editMode">{{ formGroup.get('registrarDomain').value }}</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else viewPassword">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput
                 type="password"
                 (focus)="passFocused();"
                 formControlName="password"
                 data-cy="form-password"
                 [hidden]="!editMode"
                 maxLength="64"
                 [class.field-invalid]="determineFieldValid(formGroup.get('password'), ngForm.submitted)"
                 autocomplete="off" />
        </mat-form-field>
      </ng-container>
      <ng-template #viewPassword>
        <label class="field-label">Password</label>
        <p class="color-black" [hidden]="editMode">•••••••••••</p>
      </ng-template>
    </div>
  </div>
</form>
