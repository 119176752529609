import { Injectable }      from '@angular/core';
import { SupportedLocale } from '@enums/supported-locale.enum';
import * as momentTz       from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {

  constructor() { }

  getLocale(): SupportedLocale {
    const timeZone = this.getTimeZone();
    const locale   = momentTz.tz(timeZone)
      .locale();
    if (locale === 'en') {
      return SupportedLocale.EnUS;
    }
    return locale as SupportedLocale;
  }

  getTimeZone(): string {
    const timeZone = momentTz.tz.guess();
    try {
      momentTz.tz.setDefault(timeZone);
    } catch {

    }
    return timeZone;
  }
}
