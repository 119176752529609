import { RouteRuleRaw } from '@models/api/route-rule-raw.model';
import { BaseRequest }  from '@redux/helpers/reducer.helper';
import { RouteRule }    from '@models/entity/route-rule.model';
import { ServiceType }  from '@enums/service-type.enum';
import { RouteMode }    from '@enums/route-mode.enum';

export class AssignInboundNumberRequest extends BaseRequest {
  numberId: string;
  num: string;
  value: RouteRule[] | string;
  confirm?: boolean;
  mode: RouteMode;

  static toApiData(data: AssignInboundNumberRequest): { rules?: RouteRuleRaw[], reservation?: string } {
    if (data.mode === RouteMode.Reservation) {
      return {
        reservation: data.value as string,
      };
    }
    return {
      rules:
        (data?.value as RouteRule[])
          ?.filter(dest => {
            if (isNaN(dest.priority)) {
              return false;
            }
            switch (dest.serviceType) {
              case ServiceType.MicrosoftTeams:
                return dest.serviceId && dest.serviceUser?.id;
              case ServiceType.WebexCalling:
              case ServiceType.SIPTrunk:
              case ServiceType.SIPPhone:
                return dest.serviceId;
              case ServiceType.CallForwarding:
                return dest.serviceId && dest.numberE164;
              default:
                return dest.serviceId;
            }
          })
          ?.map(dest => {
            return {
              priority:                 dest.priority,
              service_id:               dest.serviceId,
              service_user_id:          dest.serviceUser?.id || null,
              number_e164:              dest.numberE164 || null,
              implicitly_activate_user: dest.implicitlyActivateUser,
              profile_id:               dest.profileId,
              location_id:              dest.emergencyLocationId || null,
            };
          }) || [],
    };
  }
}
