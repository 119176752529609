import { StoreState }          from '../store';
import { MicrosoftTeamsState } from './microsoft-teams.reducer';
import { createSelector }      from '@ngrx/store';

export const selectMicrosoftTeams                 = (state: StoreState): MicrosoftTeamsState => state.microsoftTeamsReducer;
export const selectMicrosoftTeamsError            = createSelector(selectMicrosoftTeams, state => state.error);
export const selectMicrosoftTeamsMessage          = createSelector(selectMicrosoftTeams, state => state.message);
export const selectMicrosoftTeamsPendingTasks     = createSelector(selectMicrosoftTeams, state => state.pendingTasks);
export const selectConnectedUpn                   = createSelector(selectMicrosoftTeams, state => state.connectedUpn);
export const selectHealthInfo                     = createSelector(selectMicrosoftTeams, state => state.healthInfo);
export const selectHealthState                    = createSelector(selectMicrosoftTeams, state => state.healthState);
export const selectLicenseGroupList               = createSelector(selectMicrosoftTeams, state => state.licenseGroupList);
export const selectLicenseGroupQueryParams        = createSelector(selectMicrosoftTeams, state => state.licenseGroupQueryParams);
export const selectCallQueueGroupList             = createSelector(selectMicrosoftTeams, state => state.callQueueGroupList);
export const selectCallQueueGroupQueryParams      = createSelector(selectMicrosoftTeams, state => state.callQueueGroupQueryParams);
export const selectLicenseGroupNameAvailability   = createSelector(selectMicrosoftTeams, state => state.licenseGroupNameAvailability);
export const selectTeamGroupNameAvailability      = createSelector(selectMicrosoftTeams, state => state.teamGroupNameAvailability);
export const selectLicenses                       = createSelector(selectMicrosoftTeams, state => state.licenses);
export const selectCallQueues                     = createSelector(selectMicrosoftTeams, state => state.callQueues);
export const selectCallQueueQueryParams           = createSelector(selectMicrosoftTeams, state => state.callQueueQueryParams);
export const selectTeamGroupList                  = createSelector(selectMicrosoftTeams, state => state.teamGroupList);
export const selectTeamGroupQueryParams           = createSelector(selectMicrosoftTeams, state => state.teamGroupQueryParams);
export const selectTeams                          = createSelector(selectMicrosoftTeams, state => state.teams);
export const selectLicenseQueryParams             = createSelector(selectMicrosoftTeams, state => state.licenseQueryParams);
export const selectLicenseGroup                   = createSelector(selectMicrosoftTeams, state => state.selectedLicenseGroup);
export const selectTeamsQueryParams               = createSelector(selectMicrosoftTeams, state => state.teamsQueryParams);
export const selectTeamGroup                      = createSelector(selectMicrosoftTeams, state => state.selectedTeamGroup);
export const selectCallQueueGroup                 = createSelector(selectMicrosoftTeams, state => state.selectedCallQueueGroup);
export const selectCallQueueGroupNameAvailability = createSelector(selectMicrosoftTeams, state => state.callQueueGroupNameAvailability);
export const selectADGroups                       = createSelector(selectMicrosoftTeams, state => state.adGroups);
export const selectADGroupQueryParams             = createSelector(selectMicrosoftTeams, state => state.adGroupQueryParams);
