import { NumberItemRaw }                               from '@models/api/number-item-raw.model';
import { formatPhoneNumber }                           from '@util/formatPhoneNumber';
import { RouteRule }                                   from '@models/entity/route-rule.model';
import { RouteRuleRaw }                                from '@models/api/route-rule-raw.model';
import { NumberServiceStatus }                         from '@enums/number-service-status.enum';
import { CarrierType }                                 from '@enums/carrier-type.enum';
import { NumberRange }                                 from '@models/entity/number-range.model';
import { NumberStatus }                                from '@enums/number-status.enum';
import { MicrosoftTeamsCapability }                    from '@enums/microsoft-teams-capability.enum';
import { AssetsService }                               from '@services/assets.service';
import { Location }                                    from '@models/entity/location.model';
import { NumberReservation }                           from '@models/entity/number-reservation.model';
import { BYON_ORTO_CONFIGURE_NUMBER_DISABLED_TOOLTIP } from '@consts/text/number.const';

export class NumberItem {
  id: string;
  created: string;
  checked: boolean;
  description: string;
  didNumber: string;
  rangeId: string;
  isProcessing: boolean;
  destinations: RouteRule[];
  status: NumberServiceStatus;
  isAssigned: boolean;
  statusColor: string;
  didDescription: string;
  statusDescription: string;
  tags: string[];
  capabilities: MicrosoftTeamsCapability[];
  isShared: boolean;
  range?: NumberRange;
  locationId: string;
  location?: Location;
  requestId?: string;
  reservationLabel: string;
  reservation?: NumberReservation;

  hasCapabilities?(): boolean {
    return this.range.hasCapabilities();
  }

  getCountryFlagUrl?(): string {
    if (!this.range?.country) {
      return null;
    }
    return AssetsService.getFlag(this.range.country.code + '.svg');
  }

  constructor(private data?: NumberItem) {
    Object.assign(this, { ...this.data || {} });
    delete this['data'];
  }

  getStatusColourString?(): string {
    if (this.status === NumberServiceStatus.Suspended) {
      return 'red';
    }
    if (this.status === NumberServiceStatus.Inactive) {
      return 'gray';
    }
    if (this.isProcessing) {
      return 'yellow';
    }

    return this.destinations?.length ? 'green' : 'purple';
  }

  quickReserveDisabled?(hasAccess: boolean): boolean {
    return !!this.reservationLabel ||
      !!this.reservation ||
      !!this.destinations?.length ||
      this.configureDisabled(hasAccess);
  }

  quickReserveTooltip?(hasAccess: boolean, hasCorrectLicense: boolean): string {
    if (this.configureDisabled(hasAccess)) {
      return this.configureTooltip(hasAccess, hasCorrectLicense);
    }
    if (this.reservationLabel || this.reservation || this.destinations?.length) {
      return 'Number cannot be reserved.';
    }
    return 'Quick reserve';
  }

  unassignDisabled?(hasAccess: boolean): boolean {
    return (!this.destinations?.length && !this.reservationLabel && !this.reservation) || this.configureDisabled(hasAccess);
  }

  unassignTooltip?(hasAccess: boolean, hasCorrectLicense: boolean): string {
    if (this.configureDisabled(hasAccess)) {
      return this.configureTooltip(hasAccess, hasCorrectLicense);
    }
    if ((!this.destinations?.length && !this.reservationLabel && !this.reservation)) {
      return 'Number cannot be unassigned.';
    }
    return this.reservation ? 'Remove reservation' : 'Unassign';
  }

  configureDisabled?(hasAccess: boolean): boolean {
    if (!hasAccess || this.isProcessing || this.status === NumberServiceStatus.Suspended) {
      return true;
    }

    return this.range?.provider?.carrierType !== CarrierType.Held && (this.range && !this.range.outboundRoute);
  }

  configureTooltip?(hasAccess: boolean, hasCorrectLicense: boolean): string {
    if (this.status === NumberServiceStatus.Suspended) {
      return 'This number is suspended.';
    }
    if (!hasCorrectLicense) {
      return BYON_ORTO_CONFIGURE_NUMBER_DISABLED_TOOLTIP;
    }
    if (!hasAccess) {
      return 'You do not have the required access to manage numbers. Please contact your systems administrator for more information.';
    }
    if (this.range?.provider?.carrierType !== CarrierType.Held && (this.range && !this.range.outboundRoute)) {
      return `This number range does not have a ${ this.range.provider.isDirectRouting ? 'service' : 'carrier' } configured and cannot be routed or configured. In order to assign routing rules, first assign the range to a ${ this.range.provider.isDirectRouting ? 'service' : 'carrier' }.`;
    }
    return 'Configure';
  }

  fromApiData?(apiData: NumberItemRaw, requestId?: string): NumberItem {
    this.id               = apiData.id;
    this.created          = apiData.created_date;
    this.didNumber        = formatPhoneNumber(true, apiData.number_e164);
    this.isAssigned       = apiData.is_assigned;
    this.capabilities     = apiData.capabilities || [];
    this.isShared         = apiData.is_shared;
    this.destinations     = apiData.route?.rules?.map((dest: RouteRuleRaw) => new RouteRule().fromApiData(dest)) || [];
    this.reservationLabel = apiData.route?.reservation;
    this.reservation      = apiData.reservation ?
      new NumberReservation().fromApiData(apiData.reservation) : null;
    this.locationId       = apiData.location_id;
    this.requestId        = requestId;

    if (apiData.is_processing) {
      this.status = NumberServiceStatus.Configuring;
    } else if (apiData.status === NumberStatus.Suspended) {
      this.status = NumberServiceStatus.Suspended;
    } else if (apiData.status === NumberStatus.Inactive) {
      this.status = NumberServiceStatus.Inactive;
    } else {
      this.status = this.isAssigned ? NumberServiceStatus.Assigned : NumberServiceStatus.Unassigned;
    }

    this.didDescription    = apiData.type_description;
    this.statusDescription = this.status as string;
    this.description       = apiData.description;
    this.isProcessing      = apiData.is_processing;
    this.statusColor       = this.getStatusColourString();
    this.rangeId           = apiData.range_id;
    this.tags              = apiData.tags || [];
    if (apiData.range) {
      this.range = new NumberRange().fromApiData(apiData.range);
    }
    return this;
  }
}
