import { ListResponseMetadata } from '../api/list-response-metadata.model';
import { ListParams }           from './list-params.model';
import { ListParamModel }       from './list-param-model';

export class CustomerQueryParams extends ListParams implements ListParamModel<CustomerQueryParams> {
  search?: string;
  isPartner?: string;
  isConsumer?: string;

  static constructQueryString(searchParams: CustomerQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      search:     'filter[search]',
      isPartner:  'filter[is_partner]',
      isConsumer: 'filter[is_consumer]',
    });
  }

  static queryChanged(newQuery: CustomerQueryParams, currentQuery: CustomerQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery) ||
      newQuery.search !== currentQuery.search;
  }

  constructParams?(metaData: ListResponseMetadata): CustomerQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    this.search     = metaData?.filter?.search;
    this.isConsumer = metaData?.filter?.is_consumer;
    this.isPartner  = metaData?.filter?.is_partner;
    return this;
  }
}
