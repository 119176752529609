import { Injectable }                                       from '@angular/core';
import { environment }                                      from '../../environments/environment';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService }                                       from '@services/api.service';
import { ServiceType }                                      from '@enums/service-type.enum';
import { Observable, of }                                   from 'rxjs';
import { ServiceItemRaw }                                   from '@models/api/service-api-response.model';
import { catchError, map }                                  from 'rxjs/operators';
import { Alert }                                            from '@models/entity/alert.model';
import { HttpErrorResponse }                                from '@angular/common/http';
import { SIPPhone }                                         from '@models/entity/sip-phone.model';
import { PostServiceRequest }                               from '@models/api/post-service-request.model';
import { SIPPhoneRaw }                                      from '@models/api/sip-phone-raw.model';
import { PostServiceResponse }                              from '@models/api/post-service-response.model';
import { PatchServiceRequest }                              from '@models/api/patch-service-request.model';
import { PatchServiceResponse }                             from '@models/api/patch-service-response.model';

@Injectable({
  providedIn: 'root',
})
export class SipPhoneService {

  private baseUrl: string = environment.api.serviceBaseUrl;

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  constructor(private apiService: ApiService) { }

  getNewForm(data: SIPPhone): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        serviceType:                 new UntypedFormControl(ServiceType.SIPPhone),
        protocol:                    new UntypedFormControl(data?.protocol, Validators.required),
        fqdn:                        new UntypedFormControl(data?.fqdn, Validators.required),
        username:                    new UntypedFormControl(data?.username, Validators.required),
        password:                    new UntypedFormControl(data?.password, Validators.required),
        certificatePem:              new UntypedFormControl(data?.deviceCertificate?.certificatePem),
        privateKeyPem:               new UntypedFormControl(data?.deviceCertificate?.privateKeyPem),
        deviceCertificateExpiryDate: new UntypedFormControl(data?.deviceCertificateExpiryDate),
        numberId:                    new UntypedFormControl(data?.numberId),
        number:                      new UntypedFormControl(data?.number),
        numberQuery:                 new UntypedFormControl(data?.number),
        rangeQuery:                  new UntypedFormControl(data?.number?.range),
        rangeId:                     new UntypedFormControl(data?.number?.range?.id),
        label:                       new UntypedFormControl(data?.label || '', [Validators.maxLength(30)]),
      },
    );
  }

  postSipPhone$(req: PostServiceRequest<SIPPhone>): Observable<PostServiceResponse<SIPPhone>> {
    return this.apiService.apiPost$<{ data: ServiceItemRaw }>(
      this.buildUri(`services`),
      new SIPPhone(req.serviceItem).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: ServiceItemRaw }): PostServiceResponse<SIPPhone> => {
          return {
            error:       null,
            serviceItem: new SIPPhone().fromApiData(res.data as SIPPhoneRaw),
            message:     new Alert().fromApiMessage({
              message:   'SIP phone service created successfully.',
              color:     'green',
              url:       null,
              isSuccess: true,
            }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostServiceResponse<SIPPhone>> => {
          return of({
            error:       new Alert().fromApiError(err, req.formFields),
            serviceItem: null,
            message:     null,
          });
        }),
      );
  }

  patchSipPhone$(req: PatchServiceRequest<SIPPhone>): Observable<PatchServiceResponse<SIPPhone>> {

    return this.apiService.apiPatch$<{ data: ServiceItemRaw }>(
      this.buildUri(`services/${ req.serviceItem.id }`),
      new SIPPhone(req.serviceItem).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: ServiceItemRaw }): PatchServiceResponse<SIPPhone> => {
          return {
            error:       null,
            serviceItem: new SIPPhone().fromApiData(res.data as SIPPhoneRaw),
            message:     new Alert().fromApiMessage({
              message:   'SIP phone service updated successfully.',
              color:     'green',
              url:       null,
              isSuccess: true,
            }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchServiceResponse<SIPPhone>> => {
          return of({
            error:       new Alert().fromApiError(err, req.formFields),
            serviceItem: null,
            message:     null,
          });
        }),
      );
  }
}
