import { Component, EventEmitter, Input, Output }             from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FieldCheckType }                                     from '@enums/field-check-type.enum';
import { BehaviorSubject }                                    from 'rxjs';
import { map, tap }                                           from 'rxjs/operators';
import { SipTrunkService }                                    from '@services/sip-trunk.service';

@Component({
  selector:    'ngx-sip-trunk-form',
  templateUrl: './sip-trunk-form.component.html',
  styleUrls:   ['./sip-trunk-form.component.scss'],
})
export class SipTrunkFormComponent {
  @Input() formGroup: UntypedFormGroup;
  @Input() editMode                  = false;
  @Input() dialogMode: boolean;
  @Output() addressSpacesValidChange = new EventEmitter<boolean>();
  protocols                          = ['TLS', 'UDP'];
  FieldCheckType                     = FieldCheckType;
  currentAddressSpace                = new BehaviorSubject<string>(null);
  currentAddressSpace$               = this.currentAddressSpace.asObservable();
  containsDuplicates$                = this.currentAddressSpace$.pipe(map(addressSpace => {
    const arr = (this.addressSpacesFormArray.value as string[]).slice(0, this.addressSpacesFormArray.length - 1)
      .concat(addressSpace);
    return new Set([...arr]).size !== arr.length;
  }), tap(dupes => this.addressSpacesValidChange.emit(dupes)));

  constructor(private sipTrunkService: SipTrunkService) {}

  get addressSpacesFormArray(): UntypedFormArray {
    return (this.formGroup?.get('addressSpaces') as UntypedFormArray);
  }

  get addressSpacesCtrls(): AbstractControl[] {
    return this.addressSpacesFormArray?.controls || [];
  }

  fieldInvalid(control: AbstractControl, bSubmitted: boolean): boolean {
    return control.value && !control.valid && (bSubmitted || control.dirty);
  }

  async addAddressSpace(): Promise<void> {
    this.addressSpacesFormArray.push(this.sipTrunkService.newCIDRAddrCtrl(null));
  }

  getAddressSpaceCtrl(i: number): UntypedFormControl {
    return this.addressSpacesFormArray.at(i) as UntypedFormControl;
  }

  removeAddressSpace(i: number): void {
    this.addressSpacesFormArray.removeAt(i);
    this.currentAddressSpace.next(null);
    this.formGroup.markAsDirty();
    this.formGroup.updateValueAndValidity();
  }
}
