import { createAction, props } from '@ngrx/store';

import {
  FetchNotificationCountResponse,
  FetchNotificationListRequest,
  FetchNotificationListResponse,
  MarkAsReadRequest,
  MarkAsReadResponse,
  PollNotificationListResponse,
}                                 from '@models/entity/notification.model';
import {
  FETCH_NOTIFICATION_COUNT_REQUEST,
  FETCH_NOTIFICATION_COUNT_RESPONSE,
  FETCH_NOTIFICATION_LIST_REQUEST,
  FETCH_NOTIFICATION_LIST_RESPONSE,
  MARK_ALL_AS_READ_REQUEST,
  MARK_ALL_AS_READ_RESPONSE,
  MARK_AS_READ_REQUEST,
  MARK_AS_READ_RESPONSE,
  OPEN_FEEDBACK_REQUEST,
  POLL_NOTIFICATION_LIST_REQUEST,
  POLL_NOTIFICATION_LIST_RESPONSE,
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_RESPONSE,
}                                    from './notification.types';
import { BaseRequest, BaseResponse } from '@redux/helpers/reducer.helper';
import { SubmitFeedbackRequest }     from '@models/api/submit-feedback-request.model';
import { SubmitFeedbackResponse } from '@models/api/submit-feedback-response.model';

export const FetchNotificationCountRequestAction  = createAction(FETCH_NOTIFICATION_COUNT_REQUEST, props<BaseRequest>());
export const FetchNotificationCountResponseAction = createAction(FETCH_NOTIFICATION_COUNT_RESPONSE, props<FetchNotificationCountResponse>());
export const FetchNotificationListRequestAction   = createAction(FETCH_NOTIFICATION_LIST_REQUEST, props<FetchNotificationListRequest>());
export const FetchNotificationListResponseAction  = createAction(FETCH_NOTIFICATION_LIST_RESPONSE, props<FetchNotificationListResponse>());
export const PollNotificationListRequestAction    = createAction(POLL_NOTIFICATION_LIST_REQUEST, props<BaseRequest>());
export const PollNotificationListResponseAction   = createAction(POLL_NOTIFICATION_LIST_RESPONSE, props<PollNotificationListResponse>());
export const MarkAsReadRequestAction              = createAction(MARK_AS_READ_REQUEST, props<MarkAsReadRequest>());
export const MarkAsReadResponseAction             = createAction(MARK_AS_READ_RESPONSE, props<MarkAsReadResponse>());
export const MarkAllAsReadRequestAction           = createAction(MARK_ALL_AS_READ_REQUEST, props<BaseRequest>());
export const MarkAllAsReadResponseAction          = createAction(MARK_ALL_AS_READ_RESPONSE, props<BaseResponse>());
export const OpenFeedbackRequestAction            = createAction(OPEN_FEEDBACK_REQUEST);
export const SubmitFeedbackRequestAction          = createAction(SUBMIT_FEEDBACK_REQUEST, props<SubmitFeedbackRequest>());
export const SubmitFeedbackResponseAction         = createAction(SUBMIT_FEEDBACK_RESPONSE, props<SubmitFeedbackResponse>());
