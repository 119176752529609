import { Injectable }                    from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap }                from 'rxjs/operators';

import { RegistrationService } from '@services/registration.service';

import * as ActionTypes from './registration.actions';
import {
  ActivateAccountRequestAction,
  RegisterRequestAction,
  ResendActivationRequestAction
}                       from './registration.actions';

import {
  ActivateAccountRequest,
  ActivateAccountResponse,
  RegisterRequest,
  RegisterResponse,
  ResendActivationRequest,
  ResendActivationResponse
} from '@models/entity/registration.model';

@Injectable()
export class RegistrationEffects {
  constructor(
    private actions$: Actions,
    private registrationService: RegistrationService
  ) {}

  /**
   * For a RegisterRequestAction, call registrationService::register() and dispatch a new
   * RegisterResponseAction with the response. Also store the user's ID if the
   * response was valid.
   */
  registerRequest$ = createEffect(() => this.actions$.pipe(
    ofType(RegisterRequestAction),
    switchMap((req: RegisterRequest) =>
      this.registrationService.register$(req)
        .pipe(
          map((res: RegisterResponse) =>
            ActionTypes.RegisterResponseAction(res))
        )
    )
  ));

  resendActivation$ = createEffect(() => this.actions$.pipe(
    ofType(ResendActivationRequestAction),
    switchMap((req: ResendActivationRequest) =>
      this.registrationService.resendActivation$(req)
        .pipe(
          map((res: ResendActivationResponse) => ActionTypes.ResendActivationResponseAction(res))
        )
    )
  ));

  activateAccount$ = createEffect(() => this.actions$.pipe(
    ofType(ActivateAccountRequestAction),
    switchMap((req: ActivateAccountRequest) =>
      this.registrationService.activateAccount$(req)
        .pipe(
          map((res: ActivateAccountResponse) =>
            ActionTypes.ActivateAccountResponseAction(res))
        )
    )
  ));
}
