import { Directive, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { Action }                                                          from '@ngrx/store';
import { StoreState }                                                      from '../../redux/store';
import { DownloadService }                                                 from '@services/download.service';

@Directive({
  selector: '[ngxSignedDownload]'
})
export class SignedDownloadDirective {
  @Output() done = new EventEmitter<void>();
  @Input() fetchSignedURLAction: Action;
  @Input() fileName: string;
  @Input() reducer: keyof StoreState;
  @Input() waitForVal: string;

  @HostListener('click')
  async onClick(): Promise<void> {
    return this.downloadService.downloadS3(
      this.fetchSignedURLAction,
      this.renderer2,
      this.fileName,
      this.reducer,
      this.waitForVal);
  }

  constructor(private downloadService: DownloadService,
              private renderer2: Renderer2) { }

}
