<form data-cy="form" [formGroup]="formGroup" novalidate #ngForm="ngForm" *ngIf="formGroup"
      autocomplete="off"
      class="carrier-form">
  <div fxLayout="column">
    <div class="carrier-form__group" fxLayout="row wrap" fxLayoutAlign="space-between">
      <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
           [ngClass]="!editMode && 'border-bottom-1-gray'">
        <ng-container *ngIf="editMode; else labelView">
          <mat-form-field appearance="outline">
            <mat-label>Label</mat-label>
            <input matInput
                   type="text"
                   formControlName="label"
                   data-cy="form-label"
                   maxLength="30"
                   [hidden]="!editMode"
                   [class.field-invalid]="fieldInvalid(formGroup.get('label'), ngForm.submitted)"
                   autocomplete="off" />
            <ngx-field-notification
              *ngIf="editMode && formGroup.get('label').value && formGroup.get('label').invalid"
              [type]="FieldCheckType.Invalid"
              class="mb-12"
              message="Label is not valid"></ngx-field-notification>
          </mat-form-field>
        </ng-container>
        <ng-template #labelView>
          <label class="field-label">Label</label>
          <p class="color-black" [hidden]="editMode">{{formGroup.get('label').value}}</p>
        </ng-template>
      </div>
      <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
           *ngIf="formGroup.get('useDNSSRV')">
        <label class="field-label">Use DNS SRV?</label>
        <mat-slide-toggle data-cy="form-dns-srv"
                          class="mt-6"
                          color="primary"
                          formControlName="useDNSSRV">
        </mat-slide-toggle>
      </div>
      <div fxLayout="row wrap" fxLayoutGap.gt-sm="4%" fxFlex="0 0 100" *ngIf="formGroup.get('gateways')">
        <div fxLayout="column" fxFlex="0 0 48" fxFlex.lt-md="0 0 100">
          <div class="input-field-container"
               [ngClass]="!editMode && 'border-bottom-1-gray'">
            <ng-container *ngIf="editMode; else protoView">
              <mat-form-field appearance="outline">
                <mat-label>{{primaryGatewayProtocolLabel}}</mat-label>
                <mat-select data-cy="form-primary-gateway-protocol" name="primary-gateway-protocol" [hidden]="!editMode"
                            [formControl]="gatewayCtrl(1, 'protocol')"
                            [class.text-light-gray]="!gatewayCtrl(1, 'address')?.value"
                            id="gateway-protocol">
                  <mat-option [value]="null" disabled selected>- Choose -</mat-option>
                  <mat-option [value]="proto" *ngFor="let proto of protocols">{{proto}}</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-template #protoView>
              <label class="field-label">{{primaryGatewayProtocolLabel}}</label>
              <p class="color-black" [hidden]="editMode">{{gatewayCtrl(1, 'protocol')?.value || '&nbsp;'}}</p>
            </ng-template>
          </div>
          <div class="input-field-container"
               [ngClass]="!editMode && 'border-bottom-1-gray'">
            <ng-container *ngIf="editMode; else addressView">
              <mat-form-field appearance="outline">
                <mat-label>{{primaryGatewayAddressLabel}}</mat-label>
                <input matInput
                       type="text"
                       [formControl]="gatewayCtrl(1, 'address')"
                       data-cy="form-primary-gateway-address"
                       maxLength="128"
                       [hidden]="!editMode"
                       [class.field-invalid]="fieldInvalid(gatewayCtrl(1, 'address'), ngForm.submitted)"
                       autocomplete="off" />
                <ngx-field-notification
                  *ngIf="gatewayCtrl(1, 'address')?.value && gatewayCtrl(1, 'address').invalid"
                  [type]="FieldCheckType.Invalid"
                  class="mb-12"
                  message="{{primaryGatewayAddressLabel}} is not a valid IP/domain"></ngx-field-notification>
              </mat-form-field>
            </ng-container>
            <ng-template #addressView>
              <label class="field-label">{{primaryGatewayAddressLabel}}</label>
              <p class="color-black" [hidden]="editMode">{{gatewayCtrl(1, 'address')?.value || '&nbsp;'}}</p>
            </ng-template>
          </div>
          <div class="input-field-container"
               [ngClass]="!editMode && 'border-bottom-1-gray'">
            <ng-container *ngIf="editMode; else portView">
              <mat-form-field appearance="outline">
                <mat-label>{{primaryGatewayPort}}</mat-label>
                <input matInput
                       type="number"
                       [formControl]="gatewayCtrl(1, 'port')"
                       data-cy="form-primary-gateway-port"
                       [min]="1"
                       maxLength="128"
                       [hidden]="!editMode"
                       [class.field-invalid]="fieldInvalid(gatewayCtrl(1, 'port'), ngForm.submitted)"
                       autocomplete="off" />
              </mat-form-field>
            </ng-container>
            <ng-template #portView>
              <label class="field-label">{{primaryGatewayPort}}</label>
              <p class="color-black" [hidden]="editMode">{{gatewayCtrl(1, 'port')?.value || '&nbsp;'}}</p>
            </ng-template>
          </div>
        </div>

        <div fxLayout="column" fxFlex="0 0 48" fxFlex.lt-md="0 0 100" *ngIf="showSecondaryGateway">
          <div class="input-field-container"
               [ngClass]="!editMode && 'border-bottom-1-gray'">
            <ng-container *ngIf="editMode; else secondaryProtoView">
              <mat-form-field appearance="outline">
                <mat-label>Secondary gateway protocol</mat-label>
                <mat-select data-cy="form-secondary-gateway-protocol" name="secondary-gateway-protocol"
                            [hidden]="!editMode"
                            [formControl]="gatewayCtrl(2, 'protocol')"
                            [class.text-light-gray]="!gatewayCtrl(2, 'protocol')?.value"
                            id="secondary-gateway-protocol">
                  <mat-option [value]="null" disabled selected>- Choose -</mat-option>
                  <mat-option [value]="proto" *ngFor="let proto of protocols">{{proto}}</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-template #secondaryProtoView>
              <label class="field-label">Secondary gateway protocol</label>
              <p class="color-black" [hidden]="editMode">{{gatewayCtrl(2, 'protocol')?.value || '&nbsp;'}}</p>
            </ng-template>
          </div>
          <div class="input-field-container"
               [ngClass]="!editMode && 'border-bottom-1-gray'">
            <ng-container *ngIf="editMode; else secondaryAddressView">
              <mat-form-field appearance="outline">
                <mat-label>Secondary gateway address (e.g. 192.168.100.1)</mat-label>
                <input matInput
                       type="text"
                       [formControl]="gatewayCtrl(2, 'address')"
                       data-cy="form-secondary-gateway-address"
                       maxLength="128"
                       [hidden]="!editMode"
                       [class.field-invalid]="fieldInvalid(gatewayCtrl(2, 'address'), ngForm.submitted)"
                       autocomplete="off" />
                <ngx-field-notification
                  *ngIf="gatewayCtrl(2, 'address')?.value && gatewayCtrl(2, 'address')?.invalid"
                  [type]="FieldCheckType.Invalid"
                  class="mb-12"
                  message="Gateway address is not a valid IP/domain"></ngx-field-notification>
              </mat-form-field>
            </ng-container>
            <ng-template #secondaryAddressView>
              <label class="field-label">Secondary gateway address (e.g. 192.168.100.1)</label>
              <p class="color-black" [hidden]="editMode">{{gatewayCtrl(2, 'address')?.value || '&nbsp;'}}</p>
            </ng-template>
          </div>
          <div class="input-field-container"
               [ngClass]="!editMode && 'border-bottom-1-gray'">
            <ng-container *ngIf="editMode; else secondaryPortView">
              <mat-form-field appearance="outline">
                <mat-label>Secondary gateway port (e.g. 1-65535)</mat-label>
                <input matInput
                       type="number"
                       [formControl]="gatewayCtrl(2, 'port')"
                       data-cy="form-secondary-gateway-port"
                       [min]="1"
                       maxLength="128"
                       [hidden]="!editMode"
                       [class.field-invalid]="fieldInvalid(gatewayCtrl(2, 'port'), ngForm.submitted)"
                       autocomplete="off" />
              </mat-form-field>
            </ng-container>
            <ng-template #secondaryPortView>
              <label class="field-label">Secondary gateway port (e.g. 1-65535)</label>
              <p class="color-black" [hidden]="editMode">{{gatewayCtrl(2, 'port').value || '&nbsp;'}}</p>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
           *ngIf="false"
           [ngClass]="!editMode && 'border-bottom-1-gray'">
        <ng-container *ngIf="editMode; else viewOutbound">
          <mat-form-field appearance="outline">
            <mat-label>
              Caller ID (Outbound CLI)
            </mat-label>
            <input matInput type="text"
                   formControlName="numberQuery"
                   autocomplete="number-id"
                   data-cy="form-number-search"
                   placeholder="- Select -"
                   [matAutocomplete]="numberComplete" />
            <mat-spinner matSuffix strokeWidth="3" diameter="26" class="ml-16"
                         *ngIf="numberReducer.pending"></mat-spinner>
            <mat-autocomplete autoActiveFirstOption
                              #numberComplete="matAutocomplete"
                              class="option-line-height-1"
                              [displayWith]="getNumberName"
                              (optionSelected)="setNumberId($event)">
              <mat-option [value]="existingNumber" *ngIf="existingNumber">
                <p class="m-0 color-black">{{existingNumber.didNumber}}</p>
                <small>(Current)</small>
              </mat-option>
              <mat-option *ngFor="let number of removeExistingNumber(numberReducer.numbers)"
                          [value]="number">
                <p class="m-0 color-black">{{number.didNumber}}</p>
                <small *ngIf="number.description">({{number.description ? number.description : 'No description'}}
                  )</small>
              </mat-option>
              <mat-option
                *ngIf="!numberReducer.numbers || !numberReducer.numbers.length && !numberReducer.pending"
                [value]="null">
                <p class="m-0 color-black">No results found</p>
              </mat-option>
            </mat-autocomplete>
            <ngx-field-notification [type]="FieldCheckType.Invalid"
                                    message="Please select a number from the list"
                                    *ngIf="fieldInvalid(formGroup.get('numberId'), ngForm.submitted)">
            </ngx-field-notification>
          </mat-form-field>
        </ng-container>
        <ng-template #viewOutbound>
          <label class="field-label">
            <span class="mr-6">Caller ID (Outbound CLI)</span>
            <a href="https://help.callroute.com/space/CKB/721092743/What+Does+Outbound+CLI+Mean" target="_blank"
               rel="nofollow noopener">
              <mat-icon class="icon color-light-gray" svgIcon="cr-help-circle"></mat-icon>
            </a>
          </label>
          <p class="color-black" [hidden]="editMode">{{existingNumber?.didNumber || ''}}</p>
        </ng-template>
      </div>
      <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
           *ngIf="formGroup.get('isSipOptionsEnabled')">
        <label class="field-label">{{sipOptionsLabel}}</label>
        <mat-slide-toggle data-cy="form-is-sip-options-enabled"
                          class="mt-6"
                          color="primary"
                          formControlName="isSipOptionsEnabled">
        </mat-slide-toggle>
      </div>
      <div fxLayout="row" fxFlex="0 0 100" *ngIf="formGroup.get('addressSpaces')" fxLayoutGap="4%">
        <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100">
          <label class="field-label">{{addressSpacesLabel}}</label>
          <ng-container *ngIf="editMode; else addressSpaceView">
            <ng-container *ngFor="let addrCtrl of addressSpacesCtrls; let i = index;">
              <div fxLayout="row" fxLayoutAlign="start center" class="mb-12 relative">
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput type="text"
                         data-cy="form-address-spaces"
                         maxLength="64"
                         fxFlex="0 0 100"
                         (ngModelChange)="currentAddressSpace.next($event)"
                         [formControl]="getAddressSpaceCtrl(i)"
                         [hidden]="!editMode"
                         [class.field-invalid]="fieldInvalid(getAddressSpaceCtrl(i), ngForm.submitted)"
                         autocomplete="off" />
                  <mat-icon matSuffix class="cursor-pointer" (click)="removeAddressSpace(i)"
                            *ngIf="i > 0 && editMode" svgIcon="cr-bin"></mat-icon>
                </mat-form-field>
              </div>
              <ngx-field-notification
                *ngIf="editMode && fieldInvalid(getAddressSpaceCtrl(i), ngForm.submitted)"
                [type]="FieldCheckType.Invalid"
                class="mb-12"
                message="{{addressSpacesLabel}} is not a valid CIDR address"></ngx-field-notification>
            </ng-container>
          </ng-container>
          <ng-template #addressSpaceView>
            <ng-container *ngFor="let addrCtrl of addressSpacesCtrls; let i = index;">
              <div fxLayout="row" fxLayoutAlign="start center" class="mb-12 relative">
                <p class="color-black" [hidden]="editMode">{{addrCtrl.value}}</p>
              </div>
            </ng-container>
          </ng-template>
        </div>
        <div class="input-field-container" fxFlex="0 0 48"
             *ngIf="!(containsDuplicates$ | async) && !addressSpacesFormArray.invalid && editMode && addressSpacesCtrls?.length < 5"
             fxLayout="row" fxLayoutAlign="start end">
          <button mat-icon-button
                  data-cy="form-add"
                  (click)="addAddressSpace()" class="add-btn mb-14">
            <mat-icon svgIcon="cr-plus"></mat-icon>
          </button>
        </div>
      </div>
      <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
           *ngIf="formGroup.get('isRegistrationRequired') && formGroup.get('carrier').value === 'custom'">
        <label class="field-label">Is registration required?</label>
        <mat-slide-toggle data-cy="form-registration-required"
                          class="mt-6"
                          color="primary"
                          formControlName="isRegistrationRequired">
        </mat-slide-toggle>
      </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between" fxFlex="0 0 100"
         *ngIf="formGroup.get('isRegistrationRequired')?.value">
      <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
           *ngIf="formGroup.get('username')"
           [ngClass]="!editMode && 'border-bottom-1-gray'">
        <ng-container *ngIf="editMode; else usernameView">
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput data-cy="form-username"
                   #usernameInput
                   data-lpignore="true"
                   formControlName="username" maxLength="64"
                   [hidden]="!editMode"
                   [class.field-invalid]="fieldInvalid(formGroup.get('username'), ngForm.submitted)"
                   autocomplete="off" />
          </mat-form-field>
        </ng-container>
        <ng-template #usernameView>
          <label class="field-label">Username</label>
          <p class="color-black" [hidden]="editMode">•••••••••••</p>
        </ng-template>
      </div>
      <div class="input-field-container mb-16" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
           *ngIf="formGroup.get('password')"
           [ngClass]="!editMode && 'border-bottom-1-gray'">
        <ng-container *ngIf="editMode; else passwordView">
          <mat-form-field appearance="outline" fxLayout="row">
            <mat-label>Password</mat-label>
            <input matInput
                   type="password"
                   class="width-100"
                   formControlName="password"
                   data-cy="form-password"
                   #passwordCtrl
                   data-lpignore="true"
                   [hidden]="!editMode"
                   maxLength="64"
                   [class.field-invalid]="fieldInvalid(formGroup.get('password'), ngForm.submitted)"
                   autocomplete="off" />
            <mat-icon class="cursor-pointer"
                      *ngIf="passwordCtrl.value"
                      matTooltip="{{passwordCtrl.type === 'password' ? 'View' : 'Hide' }}"
                      svgIcon="{{passwordCtrl.type === 'password' ? 'cr-view' : 'cr-visibility-off'}}"
                      matSuffix
                      (click)="togglePasswordVisibility(passwordCtrl)"></mat-icon>
          </mat-form-field>
        </ng-container>
        <ng-template #passwordView>
          <label class="field-label">Password</label>
          <p class="color-black" [hidden]="editMode">•••••••••••</p>
        </ng-template>
      </div>
      <div class="input-field-container" fxFlex="0 0 48"
           *ngIf="formGroup.get('realm')"
           [ngClass]="!editMode && 'border-bottom-1-gray'">
        <ng-container *ngIf="editMode; else realmView">
          <mat-form-field appearance="outline">
            <mat-label>Domain/Realm</mat-label>
            <input matInput
                   type="text"
                   formControlName="realm"
                   data-cy="form-realm"
                   maxLength="128"
                   [hidden]="!editMode"
                   [class.field-invalid]="fieldInvalid(formGroup.get('realm'), ngForm.submitted)"
                   autocomplete="off" />
          </mat-form-field>
        </ng-container>
        <ng-template #realmView>
          <label class="field-label">Domain/Realm</label>
          <p class="color-black" [hidden]="editMode">{{formGroup.get('realm').value}}</p>
        </ng-template>
        <ngx-field-notification
          *ngIf="editMode && fieldInvalid(formGroup.get('realm'), ngForm.submitted)"
          [type]="FieldCheckType.Invalid"
          class="mb-12"
          message="Realm is not a valid domain/IP address"></ngx-field-notification>
      </div>
      <div class="input-field-container" fxFlex="0 0 48"
           *ngIf="formGroup.get('registrarAddress')"
           [ngClass]="!editMode && 'border-bottom-1-gray'">
        <ng-container *ngIf="editMode; else registrarView">
          <mat-form-field appearance="outline">
            <mat-label>Registrar address</mat-label>
            <input matInput
                   type="text"
                   formControlName="registrarAddress"
                   data-cy="form-registrar"
                   maxLength="128"
                   [hidden]="!editMode"
                   [class.field-invalid]="fieldInvalid(formGroup.get('registrarAddress'), ngForm.submitted)"
                   autocomplete="off" />
          </mat-form-field>
        </ng-container>
        <ng-template #registrarView>
          <label class="field-label">Registrar address</label>
          <p class="color-black" [hidden]="editMode">{{formGroup.get('registrarAddress').value}}</p>
        </ng-template>
      </div>
      <div class="input-field-container" fxFlex="0 0 48"
           *ngIf="formGroup.get('registrarString')"
           [ngClass]="!editMode && 'border-bottom-1-gray'">
        <ng-container *ngIf="editMode; else registrarStringView">
          <ng-container *ngIf="adapter === 'bt-wholesale'; else genericRegistrarString">
            <mat-form-field appearance="outline" id="registrar-string-form-field" floatLabel="never"
                            [class.not-dialog]="!dialogMode">
              <mat-label>Pilot/Trunk number</mat-label>
              <div matPrefix class="mat-prefix">
                <img src="{{gbIcon}}" height="12px" alt="">
                44
              </div>
              <input matInput
                     type="text"
                     formControlName="registrarString"
                     data-cy="form-registrar-string"
                     id="registrar-string"
                     maxLength="128"
                     [hidden]="!editMode"
                     [class.field-invalid]="fieldInvalid(formGroup.get('registrarString'), ngForm.submitted)"
                     autocomplete="off" />
            </mat-form-field>
          </ng-container>
          <ng-template #genericRegistrarString>
            <mat-form-field appearance="outline">
              <mat-label>Registrar string</mat-label>
              <input matInput
                     type="text"
                     formControlName="registrarString"
                     data-cy="form-registrar-string"
                     maxLength="128"
                     [hidden]="!editMode"
                     [class.field-invalid]="fieldInvalid(formGroup.get('registrarString'), ngForm.submitted)"
                     autocomplete="off" />
            </mat-form-field>
          </ng-template>
        </ng-container>
        <ng-template #registrarStringView>
          <label
            class="field-label">
            {{adapter === 'bt-wholesale' ? 'Pilot/Trunk number' : 'Registrar string'}}</label>
          <p class="color-black" [hidden]="editMode">
            <span class="mat-prefix mt-1">
              <img src="{{gbIcon}}" height="12px" alt="">
              44
            </span>
            {{formGroup.get('registrarString').value}}
          </p>
        </ng-template>
        <ngx-field-notification
          *ngIf="editMode && adapter === 'bt-wholesale' && fieldInvalid(formGroup.get('registrarString'), ngForm.submitted)"
          [type]="FieldCheckType.Invalid"
          class="mb-12"
          message="Pilot/Trunk number is not a valid phone number"></ngx-field-notification>
      </div>
    </div>
  </div>
</form>

<ng-template #hiddenText>•••••••••••</ng-template>

