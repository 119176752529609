import { ReportingConfigRaw } from '@models/api/reporting-config-raw.model';

export class ReportingConfig {
  usageByDirection: boolean;
  summaryByDirection: boolean;
  channelUsage: boolean;
  summaryByCountry: boolean;
  frequentNumbers: boolean;
  frequentLocations: boolean;
  cdrHistory: boolean;
  callMinutes: boolean;
  callCost: boolean;
  callsBySIPCode: boolean;
  countByCapability: boolean;
  totalNumbers: boolean;
  totalNumbersPerCountry: boolean;
  totalNumbersPerType: boolean;
  assignedNumbers: boolean;
  assignedNumbersByTag: boolean;
  assignedNumbersByLocation: boolean;
  assignedNumbersByRange: boolean;
  rangeUsageTop: boolean;
  rangeExhaustion: boolean;

  constructor(private data?: ReportingConfig) {
    Object.assign(this, data);
    delete this['data'];
  }

  fromApiData?(data: ReportingConfigRaw): ReportingConfig {
    this.usageByDirection          = data.usage_by_direction;
    this.summaryByDirection        = data.summary_by_direction;
    this.channelUsage              = data.channel_usage;
    this.summaryByCountry          = data.summary_by_country;
    this.frequentNumbers           = data.frequent_numbers;
    this.frequentLocations         = data.frequent_locations;
    this.cdrHistory                = data.cdr_history;
    this.callMinutes               = data.call_minutes;
    this.callsBySIPCode            = data.calls_by_sip_code;
    this.callCost                  = data.call_cost;
    this.countByCapability         = data.count_by_capability;
    this.totalNumbers              = data.total_numbers;
    this.totalNumbersPerCountry    = data.total_numbers_per_country;
    this.totalNumbersPerType       = data.total_numbers_per_type;
    this.assignedNumbers           = data.assigned_numbers;
    this.assignedNumbersByTag      = data.assigned_numbers_by_tag;
    this.assignedNumbersByLocation = data.assigned_numbers_by_location;
    this.assignedNumbersByRange    = data.assigned_numbers_by_range;
    this.rangeUsageTop             = data.range_usage_top;
    this.rangeExhaustion           = data.range_exhaustion;
    return this;
  }

  toApiData?(): ReportingConfigRaw {
    return {
      usage_by_direction:           this.usageByDirection,
      summary_by_direction:         this.summaryByDirection,
      channel_usage:                this.channelUsage,
      summary_by_country:           this.summaryByCountry,
      frequent_numbers:             this.frequentNumbers,
      frequent_locations:           this.frequentLocations,
      cdr_history:                  this.cdrHistory,
      call_minutes:                 this.callMinutes,
      call_cost:                    this.callCost,
      calls_by_sip_code:            this.callsBySIPCode,
      count_by_capability:          this.countByCapability,
      total_numbers:                this.totalNumbers,
      total_numbers_per_country:    this.totalNumbersPerCountry,
      total_numbers_per_type:       this.totalNumbersPerType,
      assigned_numbers:             this.assignedNumbers,
      assigned_numbers_by_tag:      this.assignedNumbersByTag,
      assigned_numbers_by_range:    this.assignedNumbersByRange,
      assigned_numbers_by_location: this.assignedNumbersByLocation,
      range_usage_top:              this.rangeUsageTop,
      range_exhaustion:             this.rangeExhaustion,
    };
  }
}
