export function formatPhoneNumber(prefix: boolean, num: string): string {
  if (!num) {
    return '';
  }
  if (prefix) {
    return num.startsWith('+') ? num : '+'.concat(num);
  }

  return num.startsWith('+') ? num.slice(1) : num;
}
