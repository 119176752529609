import { NgModule }                        from '@angular/core';
import { TableFilterSheetComponent }       from '@dialog/general/table-filter-sheet/table-filter-sheet.component';
import { TableSortSheetComponent }         from '@dialog/general/table-sort-sheet/table-sort-sheet.component';
import { MatCheckboxModule }               from '@angular/material/checkbox';
import { ButtonModule }                    from '../button/button.module';
import { MatButtonToggleModule }           from '@angular/material/button-toggle';
import { MatIconModule }                   from '@angular/material/icon';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ReactiveFormsModule }             from '@angular/forms';
import { FlexLayoutModule }                from '@angular/flex-layout';


@NgModule({
  declarations: [
    TableFilterSheetComponent,
    TableSortSheetComponent,
  ],
  imports:      [
    NgIf,
    NgFor,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    ButtonModule,
    NgClass,
    AsyncPipe,
    MatButtonToggleModule,
    MatIconModule,
  ],
})
export class SheetModule {}
