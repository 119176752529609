import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';

import { MomentDateAdapter }  from '@angular/material-moment-adapter';
import { registerLocaleData } from '@angular/common';
import localeEnGB             from '@angular/common/locales/en-GB';
import { MAT_DATE_LOCALE }    from '@angular/material/core';

registerLocaleData(localeEnGB);

@Injectable()
export class CustomMomentDateAdapter extends MomentDateAdapter {
  // @ts-ignore
  constructor(@Inject(LOCALE_ID) private localeID: string,
              // @ts-ignore
              @Optional() @Inject(MAT_DATE_LOCALE) private matDateLocale: string) {
    super(localeID);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}

export const CR_DATE_FORMATS = {
  parse:   {
    dateInput: 'LL',
  },
  display: {
    dateInput:          'D MMM YY',
    monthYearLabel:     'MMM YYYY',
    dateA11yLabel:      'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
