import { Injectable }                               from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree }    from '@angular/router';
import { AuthService }                              from '@services/auth.service';
import { AuthStatus }                               from '@enums/auth-status.enum';
import { splitURLByPath }                           from '@util/url.helper';
import { Location }                                 from '@angular/common';
import { StoreState }                               from '@redux/store';
import { Store }                                    from '@ngrx/store';
import { LogoutAction, ValidateTokenRequestAction } from '@redux/auth/auth.actions';
import { ApiService }                               from '@services/api.service';
import { TokenService }                             from '@services/token.service';
import { AuthToken }                                from '@enums/auth-token.enum';

@Injectable({
  providedIn: 'root',
})
export class AnonGuard implements CanActivate, CanLoad {
  constructor(private router: Router,
              private store: Store<StoreState>,
              private location: Location,
              private authService: AuthService) {}

  canActivate(): Promise<true | UrlTree> {
    return this.canAccess();
  }

  canLoad(): Promise<true | UrlTree> {
    return this.canAccess();
  }

  private async canAccess(): Promise<true | UrlTree> {
    const status = ApiService.determineAuthStatus();

    if (status === AuthStatus.None || !await this.authService.hasRegistered(true)) {
      return true;
    }
    const path = this.location.path();
    if (path.includes('token=')) {
      this.store.dispatch(LogoutAction({ skipNavigation: true }));
      return true;
    }
    this.store.dispatch(ValidateTokenRequestAction());

    const queryString = path
      .includes('company=') ?
      `?company=${ splitURLByPath(path).queryParams?.company }` : '';

    if (TokenService.getToken(AuthToken.Access)) {
      if (TokenService.getToken(AuthToken.Partner)) {
        return this.router.parseUrl(`/customers${ queryString }`);
      }
      return this.router.parseUrl(`/overview${ queryString }`);
    }

    return this.router.parseUrl(`/overview${ queryString }`);
  }

}
