import { Action, createReducer, on }                           from '@ngrx/store';
import { BaseState, requestReduce, responseReduce }            from '../helpers/reducer.helper';
import { HealthState, RequirementsState }                      from '@models/api/service.model';
import * as MicrosoftTeamsAction                               from './microsoft-teams.actions';
import {
  DELETE_CALL_QUEUE_GROUP_REQUEST,
  DELETE_LICENSE_GROUP_REQUEST,
  DELETE_TEAMS_GROUP_REQUEST,
  FETCH_AD_GROUPS_REQUEST,
  FETCH_CALL_QUEUE_GROUP_LIST_REQUEST,
  FETCH_CALL_QUEUE_GROUP_NAME_AVAILABILITY_REQUEST,
  FETCH_CALL_QUEUE_GROUP_REQUEST,
  FETCH_LICENSE_GROUP_LIST_REQUEST,
  FETCH_LICENSE_GROUP_NAME_AVAILABILITY_REQUEST,
  FETCH_LICENSE_GROUP_REQUEST,
  FETCH_LICENSES_REQUEST,
  FETCH_TEAM_GROUP_LIST_REQUEST,
  FETCH_TEAM_GROUP_NAME_AVAILABILITY_REQUEST,
  FETCH_TEAM_GROUP_REQUEST,
  FETCH_TEAMS_REQUEST,
  HEALTH_CHECK_REQUEST,
  PATCH_CALL_QUEUE_GROUP_REQUEST,
  PATCH_LICENSE_GROUP_REQUEST,
  PATCH_TEAM_GROUP_REQUEST,
  POST_CALL_QUEUE_GROUP_REQUEST,
  POST_LICENSE_GROUP_REQUEST,
  POST_TEAM_GROUP_REQUEST,
  REQUIREMENT_CHECK_REQUEST,
}                                                              from './microsoft-teams.types';
import * as AuthAction                                         from '../auth/auth.actions';
import * as UIAction                                           from '../ui/ui.actions';
import { createEntityAdapter, EntityAdapter }                  from '@ngrx/entity';
import { RequirementInfo }                                     from '@models/api/requirement-info.model';
import { HealthInfo }                                          from '@models/api/health-info.model';
import { LicenseGroup }                                        from '@models/entity/license-group.model';
import { LicenseGroupQueryParams }                             from '@models/form/license-group-query-params.model';
import { addItemToList, removeItemFromList, updateItemInList } from '@redux/helpers';
import { Availability }                                        from '@enums/availability.enum';
import { License }                                             from '@models/entity/license.model';
import { TeamGroup }                                           from '@models/entity/teams-group.model';
import { TeamGroupQueryParams }                                from '@models/form/teams-group-query-params.model';
import { Team }                                                from '@models/entity/team.model';
import { LicenseQueryParams }                                  from '@models/form/license-query-params.model';
import { TeamsQueryParams }                                    from '@models/form/teams-query-params.model';
import { CallQueueGroup }                                      from '@models/entity/call-queue-group.model';
import { CallQueueGroupQueryParams }                           from '@models/form/call-queue-group-query-params.model';
import { CallQueue }                                           from '@models/entity/call-queue.model';
import { CallQueueQueryParams }                                from '@models/form/call-queue-query-params.model';
import { ADGroup }                                             from '@models/entity/ad-group.model';
import { ADGroupQueryParams }                                  from '@models/form/ad-group-query-params.model';


export interface MicrosoftTeamsState extends BaseState<never> {
  healthState: { [serviceId: string]: HealthState };
  healthInfo: { [serviceId: string]: HealthInfo };
  requirementState: RequirementsState;
  requirementInfo: RequirementInfo;
  connectedUpn: string;
  licenseGroupList: LicenseGroup[];
  licenseGroupQueryParams: LicenseGroupQueryParams;
  licenseGroupNameAvailability: Availability;
  teamGroupNameAvailability: Availability;
  callQueueGroupNameAvailability: Availability;
  selectedLicenseGroup: LicenseGroup;
  teamGroupList: TeamGroup[];
  teamGroupQueryParams: TeamGroupQueryParams;
  selectedTeamGroup: TeamGroup;
  licenses: License[];
  teams: Team[];
  licenseQueryParams: LicenseQueryParams;
  teamsQueryParams: TeamsQueryParams;
  callQueueGroupList: CallQueueGroup[];
  callQueueGroupQueryParams: CallQueueGroupQueryParams;
  selectedCallQueueGroup: CallQueueGroup;
  callQueues: CallQueue[];
  callQueueQueryParams: CallQueueQueryParams;
  adGroups: ADGroup[];
  adGroupQueryParams: ADGroupQueryParams;
}

export const adapter: EntityAdapter<never> = createEntityAdapter<never>();

const initialState: MicrosoftTeamsState = adapter.getInitialState({
  healthState:                    null,
  healthInfo:                     null,
  requirementState:               null,
  requirementInfo:                null,
  connectedUpn:                   null,
  pending:                        false,
  pendingTasks:                   [],
  error:                          null,
  message:                        null,
  licenseGroupList:               null,
  licenseGroupQueryParams:        null,
  licenseGroupNameAvailability:   null,
  teamGroupNameAvailability:      null,
  callQueueGroupNameAvailability: null,
  selectedLicenseGroup:           null,
  licenses:                       null,
  teams:                          null,
  teamGroupList:                  null,
  teamGroupQueryParams:           null,
  selectedTeamGroup:              null,
  licenseQueryParams:             null,
  teamsQueryParams:               null,
  callQueueGroupList:             null,
  callQueueGroupQueryParams:      null,
  selectedCallQueueGroup:         null,
  callQueues:                     null,
  callQueueQueryParams:           null,
  adGroups:                       null,
  adGroupQueryParams:             null,
});

const _microsoftTeamsReducer = createReducer(initialState,
  on(MicrosoftTeamsAction.HealthCheckRequestAction, (state, action) =>
    requestReduce(state, action, (req) => {
      const healthState          = { ...(state.healthState || {}) };
      healthState[req.serviceId] = null;
      const healthInfo           = { ...(state.healthInfo || {}) };
      healthInfo[req.serviceId]  = null;
      return {
        healthState,
        healthInfo,
      };
    })),
  on(MicrosoftTeamsAction.HealthCheckResponseAction, (state, action) =>
    responseReduce(state, action, HEALTH_CHECK_REQUEST, res => {
      const healthState          = { ...(state.healthState || {}) };
      healthState[res.serviceId] = res.data?.state || state.healthState[res.serviceId];
      const healthInfo           = { ...(state.healthInfo || {}) };
      healthInfo[res.serviceId]  = res.data?.values || state.healthInfo[res.serviceId];
      return {
        healthState,
        healthInfo,
        connectedUpn: res.data?.connectedUpn || state.connectedUpn,
      };
    })),
  on(MicrosoftTeamsAction.RequirementCheckRequestAction, (state, action) =>
    requestReduce(state, action, () => ({
      requirementState: null,
      requirementInfo:  null,
    }))),
  on(MicrosoftTeamsAction.FetchCallQueueGroupListRequestAction, requestReduce),
  on(MicrosoftTeamsAction.FetchCallQueueGroupListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_QUEUE_GROUP_LIST_REQUEST, res => {
      return {
        callQueueGroupList:        res.error ? state.callQueueGroupList : res.data,
        selectedCallQueueGroup:    res.data?.find(item => item.id === state.selectedCallQueueGroup?.id) || state.selectedCallQueueGroup,
        callQueueGroupQueryParams: res.searchParams,
      };
    })),
  on(MicrosoftTeamsAction.FetchCallQueueGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.FetchCallQueueGroupResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_QUEUE_GROUP_REQUEST, res => ({
      selectedCallQueueGroup: res.error ? state.selectedCallQueueGroup : res.data,
    })),
  ),
  on(MicrosoftTeamsAction.DeleteCallQueueGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.DeleteCallQueueGroupResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_CALL_QUEUE_GROUP_REQUEST, res => ({
      selectedCallQueueGroup: state.selectedCallQueueGroup?.id === res.id ? null : state.selectedCallQueueGroup,
      callQueueGroupList:     removeItemFromList(state.callQueueGroupList, res),
    })),
  ),
  on(MicrosoftTeamsAction.FetchLicenseGroupListRequestAction, requestReduce),
  on(MicrosoftTeamsAction.FetchLicenseGroupListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LICENSE_GROUP_LIST_REQUEST, res => ({
      licenseGroupList:        res.error ? state.licenseGroupList : res.data,
      selectedLicenseGroup:    res.data?.find(item => item.id === state.selectedLicenseGroup?.id) || state.selectedLicenseGroup,
      licenseGroupQueryParams: res.searchParams,
    }))),
  on(MicrosoftTeamsAction.FetchTeamGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.FetchTeamGroupResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TEAM_GROUP_REQUEST, res => ({
      selectedTeamGroup: res.error ? state.selectedTeamGroup : res.data,
    })),
  ),
  on(MicrosoftTeamsAction.FetchTeamGroupListRequestAction, requestReduce),
  on(MicrosoftTeamsAction.FetchTeamGroupListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TEAM_GROUP_LIST_REQUEST, res => ({
      teamGroupList:        res.error ? state.teamGroupList : res.data,
      teamGroupQueryParams: res.searchParams,
    }))),
  on(MicrosoftTeamsAction.FetchAdGroupsRequestAction, requestReduce),
  on(MicrosoftTeamsAction.FetchAdGroupsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_AD_GROUPS_REQUEST, res => ({
      adGroups:           res.error ? state.adGroups : res.data,
      adGroupQueryParams: res.searchParams,
    }))),
  on(MicrosoftTeamsAction.FetchLicenseGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.FetchLicenseGroupResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LICENSE_GROUP_REQUEST, res => ({
      selectedLicenseGroup: res.error ? state.selectedLicenseGroup : res.data,
      licenseGroupList:     updateItemInList(state.licenseGroupList, res.data),
    }))),
  on(MicrosoftTeamsAction.PostLicenseGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.PostLicenseGroupResponseAction, (state, action) =>
    responseReduce(state, action, POST_LICENSE_GROUP_REQUEST, res => ({
      licenseGroupList:     addItemToList(state.licenseGroupList, res.data, state.licenseGroupQueryParams?.pageSize, res.error),
      selectedLicenseGroup: res.error ? state.selectedLicenseGroup : res.data,
    }))),
  on(MicrosoftTeamsAction.PatchLicenseGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.PatchLicenseGroupResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_LICENSE_GROUP_REQUEST, res => ({
      licenseGroupList:     updateItemInList(state.licenseGroupList, res.data, res.cancelled),
      selectedLicenseGroup: res.error || res.cancelled ? state.selectedLicenseGroup : res.data,
    }))),
  on(MicrosoftTeamsAction.DeleteLicenseGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.DeleteLicenseGroupResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_LICENSE_GROUP_REQUEST, res => ({
      selectedLicenseGroup: state.selectedLicenseGroup?.id === res.id ? null : state.selectedLicenseGroup,
      licenseGroupList:     removeItemFromList(state.licenseGroupList, res),
    })),
  ),
  on(MicrosoftTeamsAction.PostTeamGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.PostTeamGroupResponseAction, (state, action) =>
    responseReduce(state, action, POST_TEAM_GROUP_REQUEST, res => ({
      teamGroupList:     addItemToList(state.teamGroupList, res.data, state.teamGroupQueryParams?.pageSize, res.error),
      selectedTeamGroup: res.error ? state.selectedTeamGroup : res.data,
    }))),
  on(MicrosoftTeamsAction.PostCallQueueGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.PostCallQueueGroupResponseAction, (state, action) =>
    responseReduce(state, action, POST_CALL_QUEUE_GROUP_REQUEST, res => ({
      callQueueGroupList:     addItemToList(state.callQueueGroupList, res.data, state.callQueueGroupQueryParams?.pageSize, res.error),
      selectedCallQueueGroup: res.error ? state.selectedCallQueueGroup : res.data,
    }))),
  on(MicrosoftTeamsAction.PatchTeamGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.PatchTeamGroupResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_TEAM_GROUP_REQUEST, res => ({
      teamGroupList:     updateItemInList(state.teamGroupList, res.data, res.cancelled),
      selectedTeamGroup: res.error || res.cancelled ? state.selectedTeamGroup : res.data,
    }))),
  on(MicrosoftTeamsAction.PatchCallQueueGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.PatchCallQueueGroupResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_CALL_QUEUE_GROUP_REQUEST, res => ({
      callQueueGroupList:     updateItemInList(state.callQueueGroupList, res.data, res.cancelled),
      selectedCallQueueGroup: res.error || res.cancelled ? state.selectedCallQueueGroup : res.data,
    }))),
  on(MicrosoftTeamsAction.DeleteTeamGroupRequestAction, requestReduce),
  on(MicrosoftTeamsAction.DeleteTeamGroupResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_TEAMS_GROUP_REQUEST, res => ({
      selectedTeamGroup: state.selectedTeamGroup?.id === res.id ? null : state.selectedTeamGroup,
      teamGroupList:     removeItemFromList(state.teamGroupList, res),
    })),
  ),
  on(MicrosoftTeamsAction.FetchLicenseGroupNameAvailabilityRequestAction, (state, action) =>
    requestReduce(state, action, () => ({
      licenseGroupNameAvailability: Availability.Checking,
    }))),
  on(MicrosoftTeamsAction.FetchLicenseGroupNameAvailabilityResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LICENSE_GROUP_NAME_AVAILABILITY_REQUEST, (res) =>
      ({ licenseGroupNameAvailability: res.availability }))),
  on(MicrosoftTeamsAction.FetchTeamGroupNameAvailabilityRequestAction, (state, action) =>
    requestReduce(state, action, () => ({
      teamGroupNameAvailability: Availability.Checking,
    }))),
  on(MicrosoftTeamsAction.FetchTeamGroupNameAvailabilityResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TEAM_GROUP_NAME_AVAILABILITY_REQUEST, (res) =>
      ({ teamGroupNameAvailability: res.availability }))),
  on(MicrosoftTeamsAction.FetchCallQueueGroupNameAvailabilityRequestAction, (state, action) =>
    requestReduce(state, action, () => ({
      callQueueGroupNameAvailability: Availability.Checking,
    }))),
  on(MicrosoftTeamsAction.FetchCallQueueGroupNameAvailabilityResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_QUEUE_GROUP_NAME_AVAILABILITY_REQUEST, (res) =>
      ({ callQueueGroupNameAvailability: res.availability }))),
  on(MicrosoftTeamsAction.RequirementCheckResponseAction, (state, action) =>
    responseReduce(state, action, REQUIREMENT_CHECK_REQUEST, res => ({
      requirementState: res.data?.state,
      requirementInfo:  res.data?.values,
    }))),
  on(MicrosoftTeamsAction.FetchLicensesRequestAction, requestReduce),
  on(MicrosoftTeamsAction.FetchLicensesResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LICENSES_REQUEST, res => ({
      licenses:           res.error ? state.licenses : res.data,
      licenseQueryParams: res.error ? state.licenseQueryParams : res.searchParams,
    }))),
  on(MicrosoftTeamsAction.FetchTeamsRequestAction, requestReduce),
  on(MicrosoftTeamsAction.FetchTeamsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TEAMS_REQUEST, res => ({
      teams:            res.error ? state.teams : res.data,
      teamsQueryParams: res.error ? state.teamsQueryParams : res.searchParams,
    }))),
  on(MicrosoftTeamsAction.FetchCallQueuesRequestAction, requestReduce),
  on(MicrosoftTeamsAction.FetchCallQueuesResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TEAMS_REQUEST, res => ({
      callQueues:           res.error ? state.callQueues : res.data,
      callQueueQueryParams: res.error ? state.callQueueQueryParams : res.searchParams,
    }))),
  on(MicrosoftTeamsAction.ClearCheckState, (state) =>
    ({ ...state, requirementState: null, requirementInfo: null, healthInfo: {}, healthState: {} })),
  on(UIAction.DismissErrorAction, (state) =>
    ({ ...state, error: null })),
  on(UIAction.DismissMessageAction, (state) =>
    ({ ...state, message: null })),
  on(AuthAction.LogoutAction, () => ({ ...initialState })));

export function microsoftTeamsReducer(state: MicrosoftTeamsState, action: Action): MicrosoftTeamsState {
  return _microsoftTeamsReducer(state, action);
}
