import { InventoryAreaRaw } from '@models/api/number/inventory-area-raw.model';
import { CountryCode }      from '@models/entity/country-code.model';

export class InventoryArea {
  id: string;
  countryName: string;
  areaName: string;
  countryCode: string;
  countryPhoneCode: string;
  areaPhoneCode: string;

  static fromApiData(apiData: InventoryAreaRaw): InventoryArea {
    const i: InventoryArea = new InventoryArea();
    const cc: CountryCode  = new CountryCode();

    i.id               = apiData.id ? apiData.id : null;
    i.areaName         = apiData.name ? apiData.name : null;
    i.areaPhoneCode    = apiData.phone_code ? apiData.phone_code : null;
    i.countryName      = apiData.country && apiData.country.name ? apiData.country.name : null;
    i.countryCode      = apiData.country && apiData.country.country_code ?
      cc.getCountryISO2(apiData.country.country_code) : null;
    i.countryPhoneCode = apiData.country && apiData.country.phone_code ? apiData.country.phone_code : null;

    return i;
  }
}
