import { Store }                                     from '@ngrx/store';
import * as ActionTypes                              from './configuration.actions';
import {
  AcceptTermsConditionsRequestAction,
  FetchCallRecordingAllowedRequestAction,
  FetchCallRecordingAnalysisEnabledRequestAction,
  FetchCallRecordingEnabledRequestAction,
  FetchCarriersConfigRequestAction,
  FetchCarriersConfigResponseAction,
  FetchCurrencyCodeRequestAction,
  FetchCustomerCostsToggleRequestAction,
  FetchCustomerCostsToggleResponseAction,
  FetchDemoModeRequestAction,
  FetchGlobalUIBannerRequestAction,
  FetchGlobalUIBannerResponseAction,
  FetchGlobalUIBannerToggleRequestAction,
  FetchGlobalUIBannerToggleResponseAction,
  FetchLowBalanceThresholdDefaultRequestAction,
  FetchLowBalanceThresholdRequestAction,
  FetchNumberReportsToggleRequestAction,
  FetchNumberReportsToggleResponseAction,
  FetchNumberTombstoneDurationRequestAction,
  FetchNumberTombstoneDurationResponseAction,
  FetchPendingTermsConditionsRequestAction,
  FetchRangeUsageThresholdRequestAction,
  FetchRangeUsageThresholdResponseAction,
  FetchRangeUsageToggleRequestAction,
  FetchRangeUsageToggleResponseAction,
  FetchReportingConfigRequestAction,
  FetchReportingConfigResponseAction,
  FetchServicesConfigRequestAction,
  FetchServicesConfigResponseAction,
  FetchSSOAllowIdentityCreationRequestAction,
  FetchSSOAllowIdentityCreationResponseAction,
  FetchSSOAllowPasswordResetRequestAction,
  FetchSSOAllowPasswordResetResponseAction,
  FetchTeamsSettingsRequestAction,
  FetchTeamsSettingsResponseAction,
  FetchTopUpEnabledRequestAction,
  FetchTrialLicenseIdentifierRequestAction,
  FetchUserSyncCronEnabledRequestAction,
  FetchUserSyncCronEnabledResponseAction,
  FetchUserSyncVersionRequestAction,
  FetchUserSyncVersionResponseAction,
  SetCarriersConfigRequestAction,
  SetCarriersConfigResponseAction,
  SetCustomerCostsToggleRequestAction,
  SetCustomerCostsToggleResponseAction,
  SetDemoModeRequestAction,
  SetGlobalUIBannerRequestAction,
  SetGlobalUIBannerResponseAction,
  SetGlobalUIBannerToggleRequestAction,
  SetGlobalUIBannerToggleResponseAction,
  SetLowBalanceThresholdRequestAction,
  SetMicrosoftTeamsSettingsRequestAction,
  SetMicrosoftTeamsSettingsResponseAction,
  SetNumberTombstoneDurationRequestAction,
  SetNumberTombstoneDurationResponseAction,
  SetRangeUsageThresholdRequestAction,
  SetRangeUsageThresholdResponseAction,
  SetRangeUsageToggleRequestAction,
  SetRangeUsageToggleResponseAction,
  SetReportingConfigRequestAction,
  SetReportingConfigResponseAction,
  SetServicesConfigRequestAction,
  SetServicesConfigResponseAction,
  SetSSOAllowIdentityCreationRequestAction,
  SetSSOAllowIdentityCreationResponseAction,
  SetSSOAllowPasswordResetRequestAction,
  SetSSOAllowPasswordResetResponseAction,
  ToggleCallRecordingAnalysisRequestAction,
  ToggleCallRecordingRequestAction,
}                                                    from './configuration.actions';
import { Actions, createEffect, ofType }             from '@ngrx/effects';
import { of }                                        from 'rxjs';
import { catchError, filter, map, switchMap, tap }   from 'rxjs/operators';
import { Injectable }                                from '@angular/core';
import { ConfigurationService }                      from '@services/configuration.service';
import { MatDialog }                                 from '@angular/material/dialog';
import {
  AcceptTermsConditionsComponent,
}                                                    from '@core/components/account/accept-terms-conditions/accept-terms-conditions.component';
import { StoreState }                                from '../store';
import { GetPendingTermsConditionsResponse }         from '@models/api/get-pending-terms-conditions-response.model';
import { AcceptTermsConditionsReq }                  from '@models/api/accept-terms-conditions-req.model';
import { FetchTopupEnabledRequest }                  from '@models/api/fetch-topup-enabled-request.model';
import { FetchCurrencyCodeRequest }                  from '@models/api/fetch-currency-code-request.model';
import { FetchCurrencyCodeResponse }                 from '@models/api/fetch-currency-code-response.model';
import { FetchTopupEnabledResponse }                 from '@models/api/fetch-topup-enabled-response.model';
import { ToggleCallRecordingRequest }                from '@models/api/toggle-call-recording-request.model';
import { FetchCallRecordingEnabledRequest }          from '@models/api/fetch-call-recording-enabled-request.model';
import { ToggleCallRecordingResponse }               from '@models/api/toggle-call-recording-response.model';
import { FetchCallRecordingAllowedResponse }         from '@models/api/fetch-call-recording-allowed-response.model';
import { FetchCallRecordingAllowedRequest }          from '@models/api/fetch-call-recording-allowed-request.model';
import { FetchCallRecordingEnabledResponse }         from '@models/api/fetch-call-recording-enabled-response.model';
import { FetchLowBalanceThresholdResponse }          from '@models/api/fetch-low-balance-threshold-response.model';
import { SetLowBalanceThresholdRequest }             from '@models/api/set-low-balance-threshold-request.model';
import { SetLowBalanceThresholdResponse }            from '@models/api/set-low-balance-threshold-response.model';
import { withThrottle }                              from '@rxjs/action-throttle.operator';
import { withScopes }                                from '@rxjs/with-scopes.operator';
import { selectUserScopes }                          from '@redux/auth/auth.selectors';
import { AuthScope }                                 from '@enums/auth-scope.enum';
import { SetMicrosoftTeamsSettingsRequest }          from '@models/api/set-microsoft-teams-settings-request.model';
import { SetReportingConfigRequest }                 from '@models/api/set-reporting-config-request.model';
import { FetchDemoModeResponse }                     from '@models/api/fetch-demo-mode-response.model';
import { SetDemoModeRequest }                        from '@models/api/set-demo-mode-request.model';
import { SetDemoModeResponse }                       from '@models/api/set-demo-mode-response.model';
import { SetCarriersConfigRequest }                  from '@models/api/set-carriers-config-request.model';
import { FetchTrialLicenseIdentifierRequest }        from '@models/api/fetch-trial-license-identifier-request.model';
import { FetchTrialLicenseIdentifierResponse }       from '@models/api/fetch-trial-license-identifier-response.model';
import {
  FetchCallRecordingAnalysisEnabledRequest,
}                                                    from '@models/api/fetch-call-recording-analysis-enabled-request.model';
import { FetchCallRecordingAnalysisEnabledResponse } from '@models/api/fetch-call-recording-analysis-response.model';
import { ToggleCallRecordingAnalysisResponse }       from '@models/api/toggle-call-recording-analysis-response.model';
import { ToggleCallRecordingAnalysisRequest }        from '@models/api/toggle-call-recording-analysis-request.model';
import { FetchUserSyncVersionResponse }              from '@models/api/fetch-user-sync-version-response.model';
import {
  FetchUserSyncCronEnabledRequest,
}                                                    from '@models/api/configuration/fetch-user-sync-cron-enabled-request.model';
import {
  FetchUserSyncCronEnabledResponse,
}                                                    from '@models/api/configuration/fetch-user-sync-cron-enabled-response.model';
import {
  SetNumberTombstoneDurationRequest,
}                                                    from '@models/api/configuration/set-number-tombstone-duration-request.model';
import {
  SetNumberTombstoneDurationResponse,
}                                                    from '@models/api/configuration/set-number-tombstone-duration-response.model';
import {
  FetchNumberTombstoneDurationResponse,
}                                                    from '@models/api/configuration/fetch-number-tombstone-duration-response.model';
import {
  FetchSSOIdentityCreationResponse,
}                                                    from '@models/api/configuration/fetch-sso-identity-creation-response.model';
import {
  SetSSOIdentityCreationResponse,
}                                                    from '@models/api/configuration/set-sso-identity-creation-response.model';
import {
  SetSSOIdentityCreationRequest,
}                                                    from '@models/api/configuration/set-sso-identity-creation-request.model';
import {
  FetchSSOAllowPasswordResetResponse,
}                                                    from '@models/api/configuration/fetch-sso-allow-password-reset-response.model';
import {
  SetSSOAllowPasswordResetRequest,
}                                                    from '@models/api/configuration/set-sso-allow-password-reset-request.model';
import {
  FetchRangeUsageThresholdResponse,
}                                                    from '@models/api/configuration/fetch-range-usage-threshold-response.model';
import {
  SetRangeUsageThresholdRequest,
}                                                    from '@models/api/configuration/set-range-usage-threshold-request.model';
import {
  SetRangeUsageThresholdResponse,
}                                                    from '@models/api/configuration/set-range-usage-threshold-response.model';
import {
  SetCustomerCostsToggleRequest,
}                                                    from '@models/api/configuration/set-customer-costs-toggle-request.model';
import {
  FetchCustomerCostsToggleResponse,
}                                                    from '@models/api/configuration/fetch-customer-costs-toggle-response.model';
import {
  SetCustomerCostsToggleResponse,
}                                                    from '@models/api/configuration/set-customer-costs-toggle-response.model';
import {
  FetchNumberReportsToggleResponse,
}                                                    from '@models/api/configuration/fetch-number-reports-toggle-response.model';
import {
  SetRangeUsageToggleRequest,
}                                                    from '@models/api/configuration/set-range-usage-toggle-request.model';
import {
  SetRangeUsageToggleResponse,
}                                                    from '@models/api/configuration/set-range-usage-toggle-response.model';
import {
  FetchRangeUsageToggleResponse,
}                                                    from '@models/api/configuration/fetch-range-usage-toggle-response.model';
import {
  FetchGlobalUIBannerResponse,
}                                                    from '@models/api/configuration/fetch-global-ui-banner-response.model';
import {
  SetGlobalUIBannerRequest,
}                                                    from '@models/api/configuration/set-global-ui-banner-request.model';
import {
  SetGlobalUIBannerResponse,
}                                                    from '@models/api/configuration/set-global-ui-banner-response.model';
import {
  FetchGlobalUIBannerToggleResponse,
}                                                    from '@models/api/configuration/fetch-global-ui-banner-toggle-response.model';
import {
  SetGlobalUIBannerToggleRequest,
}                                                    from '@models/api/configuration/set-global-ui-banner-toggle-request.model';
import {
  SetGlobalUIBannerToggleResponse,
}                                                    from '@models/api/configuration/set-global-ui-banner-toggle-response.model';

@Injectable()
export class ConfigurationEffects {
  constructor(
    private actions$: Actions,
    private configurationService: ConfigurationService,
    private store: Store<StoreState>,
    private matDialog: MatDialog,
  ) {}

  fetchTopupEnabled$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTopUpEnabledRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap((req: FetchTopupEnabledRequest) =>
      this.configurationService.fetchTopupEnabled$(req)
        .pipe(
          map((res: FetchTopupEnabledResponse) => {
            return ActionTypes.FetchTopUpEnabledResponseAction(res);
          }),
        ),
    )));

  fetchTrialLicenseIdentifier$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTrialLicenseIdentifierRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap((req: FetchTrialLicenseIdentifierRequest) =>
      this.configurationService.fetchTrialLicenseIdentifier$(req)
        .pipe(
          map((res: FetchTrialLicenseIdentifierResponse) => {
            return ActionTypes.FetchTrialLicenseIdentifierResponseAction(res);
          }),
        ),
    )));

  fetchLowBalanceThresholdDefault$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLowBalanceThresholdDefaultRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap(() =>
      this.configurationService.fetchLowBalanceDefault$()
        .pipe(
          map((res: FetchLowBalanceThresholdResponse) => {
            return ActionTypes.FetchLowBalanceThresholdDefaultResponseAction(res);
          }),
        ),
    )));

  fetchLowBalanceThreshold$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLowBalanceThresholdRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap(() =>
      this.configurationService.fetchLowBalanceThreshold$()
        .pipe(
          map((res: FetchLowBalanceThresholdResponse) => {
            return ActionTypes.FetchLowBalanceThresholdResponseAction(res);
          }),
        ),
    )));

  fetchDemoMode$ = createEffect(() => this.actions$.pipe(
    ofType(FetchDemoModeRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap(() =>
      this.configurationService.fetchDemoMode$()
        .pipe(
          map((res: FetchDemoModeResponse) => {
            return ActionTypes.FetchDemoModeResponseAction(res);
          }),
        ),
    )));

  setDemoMode$ = createEffect(() => this.actions$.pipe(
    ofType(SetDemoModeRequestAction),
    withThrottle(),
    switchMap((req: SetDemoModeRequest) =>
      this.configurationService.setDemoMode$(req)
        .pipe(
          map((res: SetDemoModeResponse) => {
            return ActionTypes.SetDemoModeResponseAction(res);
          }),
        ),
    )));

  fetchUserSyncVersion$ = createEffect(() => this.actions$.pipe(
    ofType(FetchUserSyncVersionRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap(() => this.configurationService.fetchUserSyncVersion$()
      .pipe(
        map((res: FetchUserSyncVersionResponse) => FetchUserSyncVersionResponseAction(res))),
    ),
  ));

  setLowBalanceThreshold$ = createEffect(() => this.actions$.pipe(
    ofType(SetLowBalanceThresholdRequestAction),
    switchMap((req: SetLowBalanceThresholdRequest) =>
      this.configurationService.setLowBalanceThreshold$(req)
        .pipe(
          map((res: SetLowBalanceThresholdResponse) => {
            return ActionTypes.SetLowBalanceThresholdResponseAction(res);
          }),
        ),
    )));

  fetchCurrencyCode$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCurrencyCodeRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap((req: FetchCurrencyCodeRequest) =>
      this.configurationService.fetchCurrencyCode$(req)
        .pipe(
          map((res: FetchCurrencyCodeResponse) => {
            return ActionTypes.FetchCurrencyCodeResponseAction(res);
          }),
        ),
    ),
  ));

  fetchCallRecordingAllowed$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallRecordingAllowedRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap((req: FetchCallRecordingAllowedRequest) =>
      this.configurationService.fetchCallRecordingAllowed$(req)
        .pipe(
          map((res: FetchCallRecordingAllowedResponse) =>
            ActionTypes.FetchCallRecordingAllowedResponseAction(res)),
        ),
    ),
  ));

  fetchCallRecordingEnabled$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallRecordingEnabledRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap((req: FetchCallRecordingEnabledRequest) =>
      this.configurationService.fetchCallRecordingEnabled$(req)
        .pipe(
          map((res: FetchCallRecordingEnabledResponse) =>
            ActionTypes.FetchCallRecordingEnabledResponseAction(res)),
        ))),
  );

  fetchCallRecordingAnalysisEnabled$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallRecordingAnalysisEnabledRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap((req: FetchCallRecordingAnalysisEnabledRequest) =>
      this.configurationService.fetchCallRecordingAnalysisEnabled$(req)
        .pipe(
          map((res: FetchCallRecordingAnalysisEnabledResponse) =>
            ActionTypes.FetchCallRecordingAnalysisEnabledResponseAction(res)),
        ))),
  );

  toggleCallRecording$ = createEffect(() => this.actions$.pipe(
    ofType(ToggleCallRecordingRequestAction),
    switchMap((req: ToggleCallRecordingRequest) =>
      this.configurationService.toggleCallRecording$(req)
        .pipe(
          map((res: ToggleCallRecordingResponse) =>
            ActionTypes.ToggleCallRecordingResponseAction(res)),
        ))),
  );

  toggleCallRecordingAnalysis$ = createEffect(() => this.actions$.pipe(
    ofType(ToggleCallRecordingAnalysisRequestAction),
    switchMap((req: ToggleCallRecordingAnalysisRequest) =>
      this.configurationService.toggleCallRecordingAnalysis$(req)
        .pipe(
          map((res: ToggleCallRecordingAnalysisResponse) =>
            ActionTypes.ToggleCallRecordingAnalysisResponseAction(res)),
        ))),
  );

  getPendingTermsConditionsVersion$ = createEffect(() => this.actions$.pipe(
    ofType(FetchPendingTermsConditionsRequestAction),
    withThrottle(),
    switchMap(() =>
      this.configurationService.pendingTermsVersion$()
        .pipe(
          tap(response => response?.pendingVersion && this.matDialog.open(AcceptTermsConditionsComponent, {
            panelClass:   'cr-dialog',
            maxWidth:     '847px',
            maxHeight:    'calc(100vh - 140px)',
            width:        '100%',
            disableClose: true,
            position:     {
              top: '70px',
            },
            data:         response.uriLatest,
          })
            .afterClosed()
            .toPromise()
            .then(accept => accept && this.store.dispatch(AcceptTermsConditionsRequestAction({ version_accepted: response.pendingVersion })))),
          map((res: GetPendingTermsConditionsResponse) => ActionTypes.FetchPendingTermsConditionsResponseAction(res))))),
  );

  acceptLatestTermsConditions$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.AcceptTermsConditionsRequestAction),
    switchMap((req: AcceptTermsConditionsReq) =>
      this.configurationService.acceptNewTermsConditions$(req)
        .pipe(
          map(() => of(true)),
          catchError(() => of(false)),
          filter(success => !!success),
          map(() => ActionTypes.AcceptTermsConditionsResponseAction())))),
  );

  setMicrosoftTeamsSettings$ = createEffect(() => this.actions$.pipe(
    ofType(SetMicrosoftTeamsSettingsRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationWrite]),
    switchMap((req: SetMicrosoftTeamsSettingsRequest) =>
      this.configurationService.setMicrosoftTeamsSettings$(req)),
    map(res => SetMicrosoftTeamsSettingsResponseAction(res)),
  ));

  fetchMicrosoftTeamsSettings$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTeamsSettingsRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap(() =>
      this.configurationService.fetchTeamsSettings$()),
    map(res => FetchTeamsSettingsResponseAction(res)),
  ));

  fetchReportingConfig$ = createEffect(() => this.actions$.pipe(
    ofType(FetchReportingConfigRequestAction),
    switchMap(() =>
      this.configurationService.fetchReportingConfig$()),
    map(res => FetchReportingConfigResponseAction(res)),
  ));

  fetchCarriersConfig$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCarriersConfigRequestAction),
    switchMap(() =>
      this.configurationService.fetchCarriersConfig$()),
    map(res => FetchCarriersConfigResponseAction(res)),
  ));

  setCarriersConfig$ = createEffect(() => this.actions$.pipe(
    ofType(SetCarriersConfigRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap((req: SetCarriersConfigRequest) =>
      this.configurationService.setCarriersConfig$(req)),
    map(res => SetCarriersConfigResponseAction(res)),
  ));

  fetchServiceConfig$ = createEffect(() => this.actions$.pipe(
    ofType(FetchServicesConfigRequestAction),
    switchMap(() =>
      this.configurationService.fetchServiceConfig$()),
    map(res => FetchServicesConfigResponseAction(res)),
  ));

  setServiceConfig$ = createEffect(() => this.actions$.pipe(
    ofType(SetServicesConfigRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap((req: SetCarriersConfigRequest) =>
      this.configurationService.setServiceConfig$(req)),
    map(res => SetServicesConfigResponseAction(res)),
  ));

  setReportingConfig$ = createEffect(() => this.actions$.pipe(
    ofType(SetReportingConfigRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyConfigurationRead]),
    switchMap((req: SetReportingConfigRequest) =>
      this.configurationService.setReportingConfig$(req)),
    map(res => SetReportingConfigResponseAction(res)),
  ));

  fetchUserSyncCronEnabled$ = createEffect(() => this.actions$.pipe(
    ofType(FetchUserSyncCronEnabledRequestAction),
    switchMap((req: FetchUserSyncCronEnabledRequest) => this.configurationService.fetchUserSyncCronEnabled$(req)),
    map((res: FetchUserSyncCronEnabledResponse) => FetchUserSyncCronEnabledResponseAction(res)),
  ));

  fetchNumberTombstoneDuration$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNumberTombstoneDurationRequestAction),
    switchMap(() => this.configurationService.fetchNumberTombstoneDuration$()),
    map((res: FetchNumberTombstoneDurationResponse) => FetchNumberTombstoneDurationResponseAction(res)),
  ));

  setNumberTombstoneDuration$ = createEffect(() => this.actions$.pipe(
    ofType(SetNumberTombstoneDurationRequestAction),
    switchMap((req: SetNumberTombstoneDurationRequest) => this.configurationService.setNumberTombstoneDuration$(req)),
    map((res: SetNumberTombstoneDurationResponse) => SetNumberTombstoneDurationResponseAction(res)),
  ));

  fetchSSOIdentityCreation$ = createEffect(() => this.actions$.pipe(
    ofType(FetchSSOAllowIdentityCreationRequestAction),
    switchMap(() => this.configurationService.fetchSSOIdentityCreation$()),
    map((res: FetchSSOIdentityCreationResponse) => FetchSSOAllowIdentityCreationResponseAction(res)),
  ));

  setSSOIdentityCreation$ = createEffect(() => this.actions$.pipe(
    ofType(SetSSOAllowIdentityCreationRequestAction),
    switchMap((req: SetSSOIdentityCreationRequest) => this.configurationService.setSSOIdentityCreation$(req)),
    map((res: SetSSOIdentityCreationResponse) => SetSSOAllowIdentityCreationResponseAction(res)),
  ));

  fetchSSOAllowPasswordReset$ = createEffect(() => this.actions$.pipe(
    ofType(FetchSSOAllowPasswordResetRequestAction),
    switchMap(() => this.configurationService.fetchSSOAllowPasswordReset$()),
    map((res: FetchSSOAllowPasswordResetResponse) => FetchSSOAllowPasswordResetResponseAction(res)),
  ));

  setSSOAllowPasswordReset$ = createEffect(() => this.actions$.pipe(
    ofType(SetSSOAllowPasswordResetRequestAction),
    switchMap((req: SetSSOAllowPasswordResetRequest) => this.configurationService.setSSOAllowPasswordReset$(req)),
    map((res: SetSSOAllowPasswordResetRequest) => SetSSOAllowPasswordResetResponseAction(res)),
  ));

  fetchRangeUsageThreshold$ = createEffect(() => this.actions$.pipe(
    ofType(FetchRangeUsageThresholdRequestAction),
    switchMap(() => this.configurationService.fetchRangeUsageThreshold$()),
    map((res: FetchRangeUsageThresholdResponse) => FetchRangeUsageThresholdResponseAction(res)),
  ));

  setRangeUsageThreshold$ = createEffect(() => this.actions$.pipe(
    ofType(SetRangeUsageThresholdRequestAction),
    withThrottle(2_000, { leading: false, trailing: true }),
    switchMap((req: SetRangeUsageThresholdRequest) => this.configurationService.setRangeUsageThreshold$(req)),
    map((res: SetRangeUsageThresholdResponse) => SetRangeUsageThresholdResponseAction(res)),
  ));

  fetchRangeUsageToggle$ = createEffect(() => this.actions$.pipe(
    ofType(FetchRangeUsageToggleRequestAction),
    switchMap(() => this.configurationService.fetchRangeUsageToggle$()),
    map((res: FetchRangeUsageToggleResponse) => FetchRangeUsageToggleResponseAction(res)),
  ));

  setRangeUsageToggle$ = createEffect(() => this.actions$.pipe(
    ofType(SetRangeUsageToggleRequestAction),
    switchMap((req: SetRangeUsageToggleRequest) => this.configurationService.setRangeUsageToggle$(req)),
    map((res: SetRangeUsageToggleResponse) => SetRangeUsageToggleResponseAction(res)),
  ));

  fetchGlobalUIBanner$ = createEffect(() => this.actions$.pipe(
    ofType(FetchGlobalUIBannerRequestAction),
    switchMap(() => this.configurationService.fetchGlobalUIBanner$()),
    map((res: FetchGlobalUIBannerResponse) => FetchGlobalUIBannerResponseAction(res)),
  ));

  setGlobalUIBanner$ = createEffect(() => this.actions$.pipe(
    ofType(SetGlobalUIBannerRequestAction),
    withThrottle(2_000, { trailing: true, leading: false }),
    switchMap((req: SetGlobalUIBannerRequest) => this.configurationService.setGlobalUIBanner$(req)),
    map((res: SetGlobalUIBannerResponse) => SetGlobalUIBannerResponseAction(res)),
  ));

  fetchGlobalUIBannerToggle$ = createEffect(() => this.actions$.pipe(
    ofType(FetchGlobalUIBannerToggleRequestAction),
    switchMap(() => this.configurationService.fetchGlobalUIBannerToggle$()),
    map((res: FetchGlobalUIBannerToggleResponse) => FetchGlobalUIBannerToggleResponseAction(res)),
  ));

  setGlobalUIBannerToggle$ = createEffect(() => this.actions$.pipe(
    ofType(SetGlobalUIBannerToggleRequestAction),
    switchMap((req: SetGlobalUIBannerToggleRequest) => this.configurationService.setGlobalUIBannerToggle$(req)),
    map((res: SetGlobalUIBannerToggleResponse) => SetGlobalUIBannerToggleResponseAction(res)),
  ));

  fetchCustomerCostsToggle$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCustomerCostsToggleRequestAction),
    switchMap(() => this.configurationService.fetchCustomerCostsToggle$()),
    map((res: FetchCustomerCostsToggleResponse) => FetchCustomerCostsToggleResponseAction(res)),
  ));

  setCustomerCostsToggle$ = createEffect(() => this.actions$.pipe(
    ofType(SetCustomerCostsToggleRequestAction),
    switchMap((req: SetCustomerCostsToggleRequest) => this.configurationService.setCustomerCostsToggle$(req)),
    map((res: SetCustomerCostsToggleResponse) => SetCustomerCostsToggleResponseAction(res)),
  ));

  fetchNumberReportsToggle$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNumberReportsToggleRequestAction),
    switchMap(() => this.configurationService.fetchNumberReportsToggle$()),
    map((res: FetchNumberReportsToggleResponse) => FetchNumberReportsToggleResponseAction(res)),
  ));
}
