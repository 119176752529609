import * as AuthAction from './auth.actions';

import { Alert }                                    from '@models/entity/alert.model';
import { Action, createReducer, on }                from '@ngrx/store';
import { BaseState, requestReduce, responseReduce } from '../helpers/reducer.helper';
import {
  ACCEPT_COMPANY_INVITE_REQUEST,
  ASSIGN_USER_ROLE_REQUEST,
  ASSIGN_USER_SECURITY_GROUP_REQUEST,
  AUTHORISE_MICROSOFT_SSO_REQUEST,
  CREATE_API_KEY_REQUEST,
  CREATE_ROLE_REQUEST,
  DELETE_API_KEY_REQUEST,
  DELETE_ROLE_REQUEST,
  DELETE_SECURITY_GROUP_REQUEST,
  DISABLE_OTP_REQUEST,
  ENABLE_OTP_CONFIRM_REQUEST,
  ENABLE_OTP_REQUEST,
  FETCH_API_KEY_REQUEST,
  FETCH_API_KEYS_REQUEST,
  FETCH_APPROVAL_LIST_REQUEST,
  FETCH_COMPANY_DOMAIN_LIST_REQUEST,
  FETCH_COMPANY_INVITE_DESCRIPTION_REQUEST,
  FETCH_ROLES_REQUEST,
  FETCH_SCOPES_REQUEST,
  FETCH_SECURITY_GROUP_LIST_REQUEST,
  FETCH_SECURITY_GROUP_REQUEST,
  FETCH_TOKEN_INTROSPECT_REQUEST,
  FETCH_USER_COUNTS_REQUEST,
  FETCH_USER_IDP_IDENTITIES_REQUEST,
  FETCH_USER_PROFILE_REQUEST,
  LOGIN_WITH_REDIRECT,
  MANUAL_UPDATE_TOKEN_DATA,
  PATCH_API_KEY_REQUEST,
  PATCH_SECURITY_GROUP_REQUEST,
  PATCH_USER_PROFILE_REQUEST,
  POST_SECURITY_GROUP_REQUEST,
  PUT_ROLE_REQUEST,
  REFRESH_SESSION_DATA_REQUEST,
  REQUEST_RESET_PASSWORD_REQUEST,
  RESEND_USER_INVITE_REQUEST,
  RESET_PASSWORD_REQUEST,
  SET_COMPANY_HEADER,
  SET_PAGE_TITLE,
  UPDATE_COMPANY_DOMAIN_REQUEST,
  USER_APPROVAL_REQUEST,
  USERS_BULK_REQUEST,
  VALIDATE_TOKEN_REQUEST,
}                                                   from './auth.types';
import * as UIAction                                from '../ui/ui.actions';
import { User }                                     from '@models/entity/user.model';
import { AccountInvitation }                        from '@models/entity/account-invitation.model';
import { createEntityAdapter, EntityAdapter }       from '@ngrx/entity';
import { Role }                                     from '@models/entity/role.model';
import { RolesQueryParams }                         from '@models/form/roles-query-params.model';
import { Scope }                                    from '@models/entity/scope.model';
import { AssignUsersResult }                        from '@models/entity/assign-role-users-result.model';
import { PutRoleResponse }                          from '@models/api/put-role-response.model';
import { CreateRoleResponse }                       from '@models/api/create-role-response.model';
import { DeleteRoleResponse }                       from '@models/api/delete-role-response.model';
import { APIKey }                                   from '@models/entity/api-key.model';
import { APIKeyQueryParams }                        from '@models/form/api-key-params.model';
import { CompanyDomain }                            from '@models/entity/company-domain.model';
import { IdpIdentityRaw }                           from '@models/api/identity/idp-identity-raw.model';
import { DomainQueryParams }                        from '@models/form/domain-query-params.model';
import { ApprovalQueryParams }                      from '@models/form/approval-query-params.model';
import { Approval }                                 from '@models/entity/approval.model';
import { UserCounts }                               from '@models/api/identity/user-counts.model';
import { TokenIntrospectMetadata }                  from '@models/entity/token-introspect-metadata.model';
import { SecurityGroup }                            from '@models/entity/security-group.model';
import { SecurityGroupQueryParams }                 from '@models/form/identity/security-group-query-params.model';
import { deleteItem, patchItem, postItem }          from '@redux/helpers';

export interface AuthState extends BaseState<User> {
  token: string;
  viewCompanyUUID: string;
  tokenChecked: boolean;
  user: User;
  error: Alert;
  message: Alert;
  pending: boolean;
  pageTitle: string;
  pageTitleSuffix: string;
  assignRoleUsersResult: AssignUsersResult;
  pageTooltip: string;
  refreshSessionData: boolean;
  accountInvitation: AccountInvitation;
  roles: Role[];
  scopes: Scope[];
  rolesQueryParams: RolesQueryParams;
  redirectUrl: string;
  passwordReset: boolean;
  sessionExpiryModalOpen: boolean;
  otpSecret: string;
  otpScratch: string;
  otpEnabled: boolean;
  APIKeys: APIKey[];
  APIKeyQueryParams: APIKeyQueryParams;
  securityGroups: SecurityGroup[];
  securityGroupQueryParams: SecurityGroupQueryParams;
  selectedSecurityGroup: SecurityGroup;
  assignUsersSecurityGroupResult: AssignUsersResult;
  selectedAPIKey: APIKey;
  companyDomains: CompanyDomain[];
  idpIdentities: IdpIdentityRaw[];
  domainQueryParams: DomainQueryParams;
  approvals: Approval[];
  approvalQueryParams: ApprovalQueryParams;
  userCounts: UserCounts;
  tokenIntrospectMetadata: TokenIntrospectMetadata;
}

export function selectUserId(a: User): string {
  return a.id;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: selectUserId,
});

const defaultState: AuthState = adapter.getInitialState({
  token:                          null,
  viewCompanyUUID:                null,
  tokenChecked:                   false,
  user:                           null,
  error:                          null,
  message:                        null,
  pendingTasks:                   [],
  pending:                        false,
  activationError:                false,
  pageTitle:                      null,
  pageTitleSuffix:                null,
  pageTooltip:                    null,
  refreshSessionData:             false,
  accountInvitation:              null,
  scopes:                         null,
  assignRoleUsersResult:          null,
  roles:                          null,
  selectedRole:                   null,
  rolesQueryParams:               null,
  redirectUrl:                    null,
  passwordReset:                  false,
  sessionExpiryModalOpen:         false,
  otpSecret:                      null,
  otpScratch:                     null,
  otpEnabled:                     null,
  APIKeys:                        null,
  APIKeyQueryParams:              null,
  selectedAPIKey:                 null,
  securityGroups:                 null,
  securityGroupQueryParams:       null,
  selectedSecurityGroup:          null,
  assignUsersSecurityGroupResult: null,
  companyDomains:                 null,
  idpIdentities:                  null,
  domainQueryParams:              null,
  approvals:                      null,
  approvalQueryParams:            null,
  userCounts:                     null,
  tokenIntrospectMetadata:        null,
});

export function createRole(res: CreateRoleResponse, state: AuthState): Partial<AuthState> {
  if (res.error) {
    return {
      roles: state.roles,
    };
  }

  if (!Array.isArray(state.roles)) {
    return {
      roles: [res.data],
    };
  }

  return {
    roles: state.rolesQueryParams.recordCount === state.roles.length ?
             state.roles.concat(res.data) :
             state.roles,
  };
}

export function putRole(res: PutRoleResponse, state: AuthState): Partial<AuthState> {
  if (res.error || !state.roles?.length) {
    return {
      roles: state.roles,
    };
  }
  return {
    roles: state.roles.map(s => {
      if (s.id === res.data.id) {
        return res.data;
      }
      return s;
    }),
  };
}

export function deleteRole(res: DeleteRoleResponse, state: AuthState): Partial<AuthState> {
  if (!res || res.error) {
    return {
      roles: state.roles,
    };
  }
  return {
    roles: state.roles?.length ? state.roles.filter(s => {
      return s.id !== res.id;
    }) : state.roles,
  };
}

const _authReducer = createReducer(
  defaultState,
  on(AuthAction.LoginWithRedirect, (state, action) =>
    requestReduce(state, action, () => ({
      token:         null,
      passwordReset: false,
    }))),
  on(AuthAction.AuthoriseResponseAction, (state, action) =>
    responseReduce(state, action, LOGIN_WITH_REDIRECT, res => ({
      token:        res.connectMode ? state.token : res.token,
      tokenChecked: true,
    }))),
  on(AuthAction.ValidateTokenRequestAction, (state, action) => requestReduce(state, action, () => ({
    token: null,
  }))),
  on(AuthAction.ValidateTokenResponseAction, (state, action) =>
    responseReduce(state, action, VALIDATE_TOKEN_REQUEST, res => ({
      token:        res.token,
      tokenChecked: true,
    }))),
  on(AuthAction.FetchUserProfileRequestAction, requestReduce),
  on(AuthAction.FetchUserProfileResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_USER_PROFILE_REQUEST, res => ({
      user:       res.user,
      otpEnabled: !!res.user?.isOtpEnabled,
    }))),
  on(AuthAction.FetchAPIKeysRequestAction, requestReduce),
  on(AuthAction.FetchAPIKeysResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_API_KEYS_REQUEST, res => ({
      APIKeys:           res.error ? state.APIKeys : res.data,
      APIKeyQueryParams: res.error ? state.APIKeyQueryParams : res.searchParams,
    }))),
  on(AuthAction.FetchAPIKeyRequestAction, requestReduce),
  on(AuthAction.FetchAPIKeyResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_API_KEY_REQUEST, res => ({
      selectedAPIKey: res.error ? state.selectedAPIKey : res.data,
    }))),
  on(AuthAction.FetchSecurityGroupListRequestAction, requestReduce),
  on(AuthAction.FetchSecurityGroupListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SECURITY_GROUP_LIST_REQUEST, res => ({
      securityGroups:           res.error ? state.securityGroups : res.data,
      securityGroupQueryParams: res.error ? state.securityGroupQueryParams : res.queryParams,
    }))),
  on(AuthAction.FetchSecurityGroupRequestAction, requestReduce),
  on(AuthAction.FetchSecurityGroupResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SECURITY_GROUP_REQUEST, res => ({
      selectedSecurityGroup: res.error ? state.selectedSecurityGroup : res.data,
    }))),
  on(AuthAction.PostSecurityGroupRequestAction, requestReduce),
  on(AuthAction.PostSecurityGroupResponseAction, (state, action) =>
    responseReduce(state, action, POST_SECURITY_GROUP_REQUEST, res =>
      postItem(state, res.data, res.error, 'securityGroups', 'selectedSecurityGroup', 'securityGroupQueryParams'))),
  on(AuthAction.PatchSecurityGroupRequestAction, requestReduce),
  on(AuthAction.PatchSecurityGroupResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_SECURITY_GROUP_REQUEST, res =>
      patchItem(state, res.data, res.error, res.cancelled, 'securityGroups', 'selectedSecurityGroup'))),
  on(AuthAction.DeleteSecurityGroupRequestAction, requestReduce),
  on(AuthAction.DeleteSecurityGroupResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_SECURITY_GROUP_REQUEST, res =>
      deleteItem(state, res, 'securityGroups', 'selectedSecurityGroup'))),
  on(AuthAction.AssignUserSecurityGroupRequestAction, (state, action) =>
    requestReduce(state, action, () => ({
      assignUsersSecurityGroupResult: null,
    }))),
  on(AuthAction.AssignUserSecurityGroupResponseAction, (state, action) =>
    responseReduce(state, action, ASSIGN_USER_SECURITY_GROUP_REQUEST, res => ({
      assignUsersSecurityGroupResult: res.error ? state.assignUsersSecurityGroupResult : {
        assignments:   res.data.assignments,
        unassignments: res.data.unassignments,
        requestId:     res.requestId,
        meta:          res.meta,
      },
    }))),
  on(AuthAction.CreateAPIKeyRequestAction, requestReduce),
  on(AuthAction.CreateAPIKeyResponseAction, (state, action) =>
    responseReduce(state, action, CREATE_API_KEY_REQUEST, res => ({
      selectedAPIKey: res.error ? state.selectedAPIKey : res.data,
    }))),
  on(AuthAction.PatchAPIKeyRequestAction, requestReduce),
  on(AuthAction.PatchAPIKeyResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_API_KEY_REQUEST, res => ({
      selectedAPIKey: res.error ? state.selectedAPIKey : res.data,
      APIKeys:        res.error ? state.APIKeys : state.APIKeys?.map(key => {
        if (key.id !== res.data.id) {
          return key;
        }
        return res.data;
      }) || [],
    }))),
  on(AuthAction.FetchCompanyDomainListRequestAction, requestReduce),
  on(AuthAction.FetchCompanyDomainListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_COMPANY_DOMAIN_LIST_REQUEST, res => ({
      companyDomains:    res.error ? state.companyDomains : res.data,
      domainQueryParams: res.error ? state.domainQueryParams : res.searchParams,
    }))),
  on(AuthAction.FetchApprovalListRequestAction, requestReduce),
  on(AuthAction.FetchApprovalListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_APPROVAL_LIST_REQUEST, res => ({
      approvals:           res.error ? state.approvals : res.data,
      approvalQueryParams: res.error ? state.approvalQueryParams : res.searchParams,
    }))),
  on(AuthAction.UpdateCompanyDomainRequestAction, requestReduce),
  on(AuthAction.UpdateCompanyDomainResponseAction, (state, action) =>
    responseReduce(state, action, UPDATE_COMPANY_DOMAIN_REQUEST, res => ({
      companyDomains: res.error ? state.companyDomains : (state.companyDomains?.map(domain => {
        if (domain.id !== res.data.id) {
          return domain;
        }
        return res.data;
      }) || []),
    }))),
  on(AuthAction.DeleteAPIKeyRequestAction, requestReduce),
  on(AuthAction.DeleteAPIKeyResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_API_KEY_REQUEST, res => ({
      selectedAPIKey: res.error ? state.selectedAPIKey : res.data,
      APIKeys:        res.error ? state.APIKeys : state.APIKeys?.map(key => {
        if (key.id !== res.id) {
          return key;
        }
        return res.data;
      }) || [],
    }))),
  on(AuthAction.PatchUserProfileRequestAction, requestReduce),
  on(AuthAction.PatchUserProfileResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_USER_PROFILE_REQUEST, res => {
      if (res.error) {
        return {};
      }
      if (res.user.id === state.user?.id) {
        return {
          user: new User({
            ...state.user,
            ...res.user,
            companyId:    state.user.companyId,
            companyName:  state.user.companyName,
            roles:        state.user.roles,
            isOtpEnabled: state.user.isOtpEnabled,
          }),
        };
      }
      return {};
    })),
  on(AuthAction.FetchUserIDPIdentitiesRequestAction, requestReduce),
  on(AuthAction.FetchUserIDPIdentitiesResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_USER_IDP_IDENTITIES_REQUEST, res => {
      return {
        idpIdentities: res.error ? state.idpIdentities : res.idpIdentities,
      };
    })),
  on(AuthAction.RequestResetPasswordRequestAction, (state, action) =>
    requestReduce(state, action, () => ({ passwordReset: false }))),
  on(AuthAction.RequestResetPasswordResponseAction, (state, action) =>
    responseReduce(state, action, REQUEST_RESET_PASSWORD_REQUEST)),
  on(AuthAction.ResetPasswordRequestAction, requestReduce),
  on(AuthAction.ResetPasswordResponseAction, (state, action) =>
    responseReduce(state, action, RESET_PASSWORD_REQUEST, res => ({ passwordReset: !res.error }))),
  on(AuthAction.FetchCompanyInviteDescriptionRequestAction, (state, action) =>
    requestReduce(state, action, () => ({ accountInvitation: null }))),
  on(AuthAction.FetchCompanyInviteDescriptionResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_COMPANY_INVITE_DESCRIPTION_REQUEST, res => ({ accountInvitation: res.invitation }))),
  on(AuthAction.AcceptCompanyInviteRequestAction, requestReduce),
  on(AuthAction.AcceptCompanyInviteResponseAction, (state, action) =>
    responseReduce(state, action, ACCEPT_COMPANY_INVITE_REQUEST)),
  on(AuthAction.SetCompanyHeaderAction, (state, action) =>
    responseReduce(state, action, SET_COMPANY_HEADER, res => ({ viewCompanyUUID: res.companyUUID }))),
  on(AuthAction.RefreshSessionDataRequest, (state, action) =>
    requestReduce(state, action, () => ({ refreshSessionData: true }))),
  on(AuthAction.RefreshSessionDataResponse, (state, action) =>
    responseReduce(state, action, REFRESH_SESSION_DATA_REQUEST, () => ({ refreshSessionData: false }))),
  on(AuthAction.ManualUpdateTokenData, (state, action) =>
    responseReduce(state, action, MANUAL_UPDATE_TOKEN_DATA, res => ({
      token:        res.token,
      tokenChecked: true,
    }))),
  on(AuthAction.SetPageTitle,
    (state, action) =>
      responseReduce(state, action, SET_PAGE_TITLE, res => ({
        pageTitle:       res.title,
        pageTitleSuffix: res.titleSuffix,
        pageTooltip:     res.tooltip,
      }))),
  on(AuthAction.FetchScopesRequestAction, requestReduce),
  on(AuthAction.FetchScopesResponseAction, (state, action) => responseReduce(state, action, FETCH_SCOPES_REQUEST, res => ({
    scopes: res.error ? state.scopes : res.data,
  }))),
  on(AuthAction.AssignUserRoleRequestAction, (state, action) => requestReduce(state, action, () => ({
    assignRoleUsersResult: null,
  }))),
  on(AuthAction.AssignUserRoleResponseAction, (state, action) => responseReduce(state, action, ASSIGN_USER_ROLE_REQUEST, res => ({
    assignRoleUsersResult: res.error ? state.assignRoleUsersResult : {
      assignments:   res.data.assignments,
      unassignments: res.data.unassignments,
      requestId:     res.requestId,
      meta:          res.meta,
    },
  }))),
  on(AuthAction.FetchRolesRequestAction, requestReduce),
  on(AuthAction.FetchRolesResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_ROLES_REQUEST, res => ({
      roles:            res.error ? state.roles : res.models,
      rolesQueryParams: res.error ? state.rolesQueryParams : res.searchParams,
    }))),
  on(AuthAction.CreateRoleRequestAction, requestReduce),
  on(AuthAction.CreateRoleResponseAction, (state, action) =>
    responseReduce(state, action, CREATE_ROLE_REQUEST, res => createRole(res, state))),
  on(AuthAction.PutRoleRequestAction, requestReduce),
  on(AuthAction.PutRoleResponseAction, (state, action) =>
    responseReduce(state, action, PUT_ROLE_REQUEST, res => putRole(res, state))),
  on(AuthAction.DeleteRoleRequestAction, requestReduce),
  on(AuthAction.DeleteRoleResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_ROLE_REQUEST, res => deleteRole(res, state))),
  on(AuthAction.MarkSessionExpiryModalOpen, (state) => ({ ...state, sessionExpiryModalOpen: true })),
  on(AuthAction.CloseSessionExpiryModal, (state) => ({ ...state, sessionExpiryModalOpen: false })),
  on(AuthAction.SetRedirectURL, (state, action) => ({ ...state, redirectUrl: action.redirectUrl })),
  on(AuthAction.ClearRedirectURL,
    (state) => ({ ...state, redirectUrl: null })),
  on(AuthAction.EnableOTPRequestAction, requestReduce),
  on(AuthAction.EnableOTPResponseAction, (state, action) =>
    responseReduce(state, action, ENABLE_OTP_REQUEST, res => ({
      otpSecret: res.otpSecret,
    }))),
  on(AuthAction.EnableOTPConfirmRequestAction, requestReduce),
  on(AuthAction.EnableOTPConfirmResponseAction, (state, action) =>
    responseReduce(state, action, ENABLE_OTP_CONFIRM_REQUEST, res => ({
      user:       res.error ? state.user : new User({ ...state.user, isOtpEnabled: true }),
      otpScratch: res.otpScratch,
      otpEnabled: !res.error,
    }))),
  on(AuthAction.DisableOTPRequestAction, requestReduce),
  on(AuthAction.DisableOTPResponseAction, (state, action) =>
    responseReduce(state, action, DISABLE_OTP_REQUEST, res => {
      if (res.cancelled || res.error) {
        return {};
      }
      return {
        user:       res.error ? state.user : new User({ ...state.user, isOtpEnabled: false }),
        otpEnabled: false,
      };
    })),
  on(AuthAction.FetchTokenIntrospectRequestAction, requestReduce),
  on(AuthAction.FetchTokenIntrospectResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TOKEN_INTROSPECT_REQUEST, (res) => ({
      tokenIntrospectMetadata: res.error ? state.tokenIntrospectMetadata : res.data,
    }))),
  on(AuthAction.UserApprovalRequestAction, requestReduce),
  on(AuthAction.UserApprovalResponseAction, (state, action) => responseReduce(state, action, USER_APPROVAL_REQUEST, res => {
    return {
      approvals: res.error ? state.approvals : state.approvals?.map(approval => {
        if (approval.id !== res.data.id) {
          return approval;
        }
        return res.data;
      }),
    };
  })),
  on(AuthAction.ClearScratchCodeAction, (state) => ({ ...state, otpScratch: null, otpSecret: null })),
  on(AuthAction.AuthoriseMicrosoftSSORequestAction, requestReduce),
  on(AuthAction.AuthoriseMicrosoftSSOResponseAction, (state, action) =>
    responseReduce(state, action, AUTHORISE_MICROSOFT_SSO_REQUEST)),
  on(AuthAction.FetchUserCountsRequestAction, requestReduce),
  on(AuthAction.FetchUserCountsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_USER_COUNTS_REQUEST, res => ({
      userCounts: res.error ? state.userCounts : res.data,
    }))),
  on(AuthAction.UsersBulkRequestAction, requestReduce),
  on(AuthAction.UsersBulkResponseAction, (state, action) =>
    responseReduce(state, action, USERS_BULK_REQUEST)),
  on(AuthAction.ResendUserInviteRequestAction, requestReduce),
  on(AuthAction.ResendUserInviteResponseAction, (state, action) =>
    responseReduce(state, action, RESEND_USER_INVITE_REQUEST)),
  on(UIAction.DismissErrorAction, (state) =>
    ({ ...state, error: null })),
  on(UIAction.DismissMessageAction, (state) =>
    ({ ...state, message: null })),
  on(AuthAction.LogoutAction,
    (_, action) => ({ ...defaultState, redirectUrl: action.redirectUrl, tokenChecked: true })),
);

export function authReducer(state: AuthState, action: Action): AuthState {
  return _authReducer(state, action);
}
