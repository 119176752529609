import { Observable, of }  from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ApiService }  from './api.service';
import { environment } from '../../environments/environment';


import {
  ActivateAccountRequest,
  ActivateAccountResponse,
  RegisterRequest,
  RegisterResponse,
  ResendActivationRequest,
  ResendActivationResponse,
} from '@models/entity/registration.model';

import { Alert }             from '@models/entity/alert.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {

  private baseUrl: string = environment.api.identityBaseUrl;

  constructor(
    private apiService: ApiService,
  ) {
  }

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  private buildUsersUri(): string {
    return this.buildUri('users');
  }

  private buildResendActivationUri(): string {
    return this.buildUri('auth/verify-email/dispatch');
  }

  private buildVerifyEmailUri(): string {
    return this.buildUri(`auth/verify-email/validate`);
  }

  register$(req: RegisterRequest): Observable<RegisterResponse> {
    return this.apiService.apiPost$<{ user_id: number }>(
      this.buildUsersUri(),
      RegisterRequest.toApiData(req),
      { authRequired: false })
      .pipe(
        map((res: { user_id: number }): RegisterResponse => {

          return {
            error:  null,
            userId: res.user_id,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<RegisterResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            userId: null,
          });
        }),
      );
  }

  resendActivation$(req: ResendActivationRequest): Observable<ResendActivationResponse> {
    return this.apiService.apiPost$(
      this.buildResendActivationUri(),
      req,
      { authRequired: false })
      .pipe(
        map((): ResendActivationResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: 'Email sent successfully.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<ResendActivationResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
          });
        }),
      );
  }

  activateAccount$(req: ActivateAccountRequest): Observable<ActivateAccountResponse> {
    return this.apiService.apiPost$(
      this.buildVerifyEmailUri(),
      req,
      { authRequired: false })
      .pipe(
        map((): ActivateAccountResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: 'Your email address has been confirmed.', show: false }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<ActivateAccountResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
          });
        }),
      );
  }

}
