<mat-sidenav-container autosize class="task-manager" #container>
  <mat-sidenav position="end"
               class="task-manager__inner shadow-base"
               #drawer
               mode="side"
               [fixedInViewport]="true"
               [fixedTopGap]="128">
    <div class="task-manager__header" fxLayout="row">
      <div class="task-manager__header__close" fxFlex="0 0 100px">
        <button mat-button (click)="close()" class="task-manager__header__close__btn">
          <mat-icon svgIcon="cr-close"></mat-icon>
        </button>
      </div>
      <div class="task-manager__header__title" fxLayout="column" fxLayoutAlign="center start">
        <h3 class="mb-8" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon svgIcon="cr-list-check" class="mr-16"></mat-icon>
          <span>Task manager</span>
        </h3>
        <div fxLayout="row" fxLayoutAlign="start center">
          <ng-container *ngIf="incompleteCount; else doneIcon">
            <mat-spinner strokeWidth="3" diameter="24"></mat-spinner>
          </ng-container>
          <ng-template #doneIcon>
            <mat-icon svgIcon="cr-tick-circle" class="color-green"></mat-icon>
          </ng-template>
          <p class="mb-0 ml-16 mat-body-1--smaller text-light-gray">
            {{incompleteCount || 0}} {{'task' | plural:incompleteCount}} running
          </p>
        </div>
      </div>
    </div>
    <mat-divider class="mb-24 task-manager__divider"></mat-divider>
    <mat-list class="task-manager__content" #list>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px"
           class="task-manager__content__schemas">
        <button mat-button class="task-manager__content__schemas__btn"
                [class.task-manager__content__schemas__btn--active]="(selectedSchema$ | async) === null"
                (click)="formGroup.get('schema').setValue(null)">
          All
        </button>
        <button mat-button class="task-manager__content__schemas__btn"
                [matBadge]="getCount(identifier, counts)"
                [matBadgeHidden]="!getCount(identifier, counts)"
                [class.task-manager__content__schemas__btn--active]="(selectedSchema$ | async) === identifier"
                *ngFor="let identifier of schemaIdentifiers"
                (click)="formGroup.get('schema').setValue(identifier)">
          {{schemas[identifier]}}
        </button>
      </div>
      <ng-container *ngFor="let task of tasks; trackBy: trackBy">
        <ngx-task [task]="task"
                  [showDebug]="isSipAdmin"
                  [userId]="userId"
                  (cancel)="cancel($event)"
                  (close)="close()"
                  [hasAdminAudit]="hasAdminAudit"
                  [hasReportRead]="hasReportRead"
                  [cancelPending]="cancelPending$ | async"></ngx-task>
        <mat-divider class="task-manager__content__divider"></mat-divider>
      </ng-container>
      <ngx-paginator
        fxFlexAlign="center"
        *ngIf="(taskQueryParams?.recordCount || 0) > 5"
        class="paginator display-block text-align-center width-100"
        [recordCount]="taskQueryParams.recordCount"
        [pageSize]="taskQueryParams.pageSize"
        [pageCount]="taskQueryParams.pageCount"
        [pageNumber]="taskQueryParams.pageNumber"
        [pageSizeOptions]="[5, 10, 25]"
        (paginationChange)="updatePageQuery($event.params)"></ngx-paginator>
    </mat-list>
  </mat-sidenav>

</mat-sidenav-container>
