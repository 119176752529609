import { combineLatest, Observable, OperatorFunction } from 'rxjs';
import { map, startWith }                              from 'rxjs/operators';

/**
 * Uses combineLatest to replay the last emitted value when the notifier observable emits
 * @returns OperatorFunction T (the last emitted value)
 */
export function repeatLatestWhen<T>(notifier$: Observable<unknown>): OperatorFunction<T, T> {
  return (source: Observable<T>) =>
    combineLatest([
      source,
      notifier$.pipe(startWith(null))
    ])
      .pipe(
        map(([val]) => val)
      );
}
