import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class CallQueueGroupQueryParams extends ListParams implements ListParamModel<CallQueueGroupQueryParams> {
  search?: string;
  id?: string[];

  static constructQueryString(searchParams: CallQueueGroupQueryParams): string {
    const params: CallQueueGroupQueryParams = { ...(searchParams || {}) as CallQueueGroupQueryParams };

    return ListParams.constructQueryString(params, {
      search: 'filter[search]',
      id:     'filter[id][]',
    });
  }

  static queryChanged(newQuery: CallQueueGroupQueryParams, currentQuery: CallQueueGroupQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery);
  }

  constructParams?(metaData: ListResponseMetadata): CallQueueGroupQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.search) {
        this.search = metaData.filter.search;
      }
      if (metaData.filter.id) {
        this.id = metaData.filter.id;
      }
    }

    return this;
  }
}
