import { RefreshAuthData }                               from '@models/api/refresh-auth-data.model';
import { MSOAuthPayload }                                from '@models/api/ms-oauth-payload.model';
import { AuthorisationRedirectRequest }                  from '@models/api/authorisation-redirect-request.model';
import { AuthorisationRedirectResponse }                 from '@models/api/authorisation-redirect-response.model';
import { HttpCookie }                                    from '@models/api/http-cookie.model';
import { ToggleAuthModal }                               from '@models/api/toggle-auth-modal.model';
import { DeleteTokensRequest }                           from '@models/api/delete-tokens-request.model';
import { DeleteTokensResponse }                          from '@models/api/delete-tokens-response.model';
import {
  AUTHORISE_REDIRECT_REQUEST,
  AUTHORISE_REDIRECT_RESPONSE,
  FETCH_COMBINED_TOKENS_STATUS_REQUEST,
  FETCH_COMBINED_TOKENS_STATUS_RESPONSE,
  MS_OAUTH_CALLBACK,
  REFRESH_AUTH_STATUS,
  SET_COOKIE,
  TOGGLE_AUTH_FAILED_MODAL,
}                                                        from '@redux/access-broker/access-broker.types';
import { createAction, props }                           from '@ngrx/store';
import { DELETE_TOKENS_REQUEST, DELETE_TOKENS_RESPONSE } from '@redux/service/service.types';
import { FetchCombinedTokensStatusResponse }             from '@models/api/fetch-combined-tokens-status-response.model';
import { FetchCombinedTokensStatusRequest }              from '@models/api/fetch-combined-tokens-status-request.model';

export const AuthoriseRedirectRequest                = createAction(AUTHORISE_REDIRECT_REQUEST, props<AuthorisationRedirectRequest>());
export const AuthoriseRedirectResponse               = createAction(AUTHORISE_REDIRECT_RESPONSE, props<AuthorisationRedirectResponse>());
export const MSOAuthCallback                         = createAction(MS_OAUTH_CALLBACK, props<MSOAuthPayload>());
export const RefreshAuthStatus                       = createAction(REFRESH_AUTH_STATUS, props<RefreshAuthData>());
export const FetchCombinedTokensStatusRequestAction  = createAction(FETCH_COMBINED_TOKENS_STATUS_REQUEST, props<FetchCombinedTokensStatusRequest>());
export const FetchCombinedTokensStatusResponseAction = createAction(FETCH_COMBINED_TOKENS_STATUS_RESPONSE, props<FetchCombinedTokensStatusResponse>());
export const SetCookie                               = createAction(SET_COOKIE, props<HttpCookie>());
export const DeleteTokensRequestAction               = createAction(DELETE_TOKENS_REQUEST, props<DeleteTokensRequest>());
export const DeleteTokensResponseAction              = createAction(DELETE_TOKENS_RESPONSE, props<DeleteTokensResponse>());
export const ToggleAuthFailedModal                   = createAction(TOGGLE_AUTH_FAILED_MODAL, props<ToggleAuthModal>());
