import { LicenseGroupRaw } from '@models/api/license-group-raw.model';
import { License }         from '@models/entity/license.model';

export class LicenseGroup {
  id: string;
  name: string;
  description: string;
  updatedDate: string;
  createdDate: string;
  licenseIds: string[];
  licenses: License[];
  requestId?: string;

  constructor(private data?: LicenseGroup) {
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  fromApiData?(data: LicenseGroupRaw & { requestId?: string }): LicenseGroup {
    this.id          = data.id;
    this.name        = data.name;
    this.description = data.description;
    this.updatedDate = data.updated_date;
    this.createdDate = data.created_date;
    this.licenseIds  = data.license_ids;
    this.requestId   = data.requestId;

    return this;
  }
}
