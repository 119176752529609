import { Component }    from '@angular/core';
import { StoreState }   from '@redux/store';
import { Store }        from '@ngrx/store';
import { LogoutAction } from '@redux/auth/auth.actions';

@Component({
  selector:    'ngx-access-restricted-page',
  templateUrl: './access-restricted-page.component.html',
  styleUrls:   ['./access-restricted-page.component.scss'],
})
export class AccessRestrictedPageComponent {

  constructor(private store: Store<StoreState>) { }

  logout(): void {
    this.store.dispatch(LogoutAction({}));
  }
}
