<div class="dialog-head display-flex">
    <h2 mat-dialog-title class="m-0">No assignable users</h2>
    <button class="button link" type="button" mat-dialog-close>
        <img src="{{closeIconUrl}}"/>
    </button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="confirm-content">
        <p class="m-0">
            <span>We could not find any users with the required Microsoft licenses assigned.</span>
            <a href="https://help.callroute.com/space/CKB/721125464/Microsoft+Licensing+Requirements"
               target="_blank" rel="noopener nofollow">
                Click here
            </a>
            <span>for more information on what Microsoft licenses are required for Callroute</span>
        </p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button class="button primary" type="button" mat-dialog-close>Close</button>
</mat-dialog-actions>

