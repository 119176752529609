<form data-cy="form" [formGroup]="formGroup" novalidate #ngForm="ngForm" *ngIf="formGroup && numberReducer"
      autocomplete="off"
      class="carrier-form">
  <div class="sip-form__group mt-24" fxLayout="row wrap" fxLayoutAlign="space-between">
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else viewLabel">
        <mat-form-field appearance="outline">
          <mat-label>Label</mat-label>
          <input matInput
                 type="text"
                 formControlName="label"
                 data-cy="form-label"
                 maxLength="30"
                 [hidden]="!editMode"
                 [class.field-invalid]="fieldInvalid(formGroup.get('label'), ngForm.submitted)"
                 autocomplete="off" />
          <ngx-field-notification
            *ngIf="editMode && formGroup.get('label').value && formGroup.get('label').invalid"
            [type]="FieldCheckType.Invalid"
            class="mb-12"
            message="Label is not valid"></ngx-field-notification>
        </mat-form-field>
      </ng-container>
      <ng-template #viewLabel>
        <label class="field-label">Label</label>
        <p class="color-black" [hidden]="editMode">{{formGroup.get('label').value}}</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else viewProtocol">
        <mat-form-field appearance="outline">
          <mat-label>Protocol</mat-label>
          <mat-select data-cy="form-protocol" [hidden]="!editMode"
                      formControlName="protocol"
                      [class.text-light-gray]="!formGroup.get('protocol').value"
                      id="protocol">
            <mat-option [value]="null" disabled>- Choose -</mat-option>
            <mat-option [value]="proto" *ngFor="let proto of protocols">{{proto}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-template #viewProtocol>
        <label class="field-label">Protocol</label>
        <p class="color-black" [hidden]="editMode">{{formGroup.get('protocol').value}}</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         *ngIf="formGroup.get('fqdn').value"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <label class="field-label">Fully qualified domain name</label>
      <p class="color-black">{{formGroup.get('fqdn').value}}</p>
    </div>
    <div fxFlex="0 0 48" *ngIf="!dialogMode"></div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <mat-form-field appearance="outline" *ngIf="editMode; else viewRange">
        <mat-label>Select a number range</mat-label>
        <input matInput type="text"
               formControlName="rangeQuery"
               autocomplete="off"
               data-cy="form-range-search"
               placeholder="- Select -"
               [matAutocomplete]="rangeComplete" />
        <mat-spinner matSuffix strokeWidth="3" diameter="26"
                     *ngIf="numberReducer.pending"></mat-spinner>
        <ngx-field-notification [type]="FieldCheckType.Invalid"
                                message="Please select a range from the list"
                                *ngIf="fieldInvalid(formGroup.get('rangeId'), ngForm.submitted)">
        </ngx-field-notification>
      </mat-form-field>
      <ng-template #viewRange>
        <label class="field-label">
          <span class="mr-6">Number range</span>
        </label>
        <p class="color-black" [hidden]="editMode">{{formGroup.get('rangeQuery').value?.name || ''}}</p>
      </ng-template>
      <mat-autocomplete autoActiveFirstOption
                        #rangeComplete="matAutocomplete"
                        class="option-line-height-1"
                        [displayWith]="getRangeName"
                        (optionSelected)="setRangeId($event)">
        <mat-option [value]="existingRange" *ngIf="existingRange">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxLayout="row" fxLayoutAlign="start center">
              <img width="32" [src]="getCountryIcon(existingRange.country.code)" />
            </div>
            <div>
              <p class="m-0 color-black">{{existingRange.name}}</p>
              <small>(Current)</small>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="getCarrierLogo(existingRange) as logo">
              <img src="{{logo}}" height="24px">
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <ng-container *ngTemplateOutlet="carrierTypeLogo; context: {range: existingRange}"></ng-container>
            </div>
          </div>
        </mat-option>
        <mat-option *ngFor="let range of removeExistingRange(numberReducer.numberRanges)"
                    class="mat-option"
                    [value]="range">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" class="mat-option__inner">
            <div fxLayout="row" fxLayoutAlign="start center">
              <img width="32" [src]="getCountryIcon(range.country.code)" />
            </div>
            <div fxLayout="column">
              <p class="m-0 color-black mat-option__inner__name">{{range.name}}</p>
              <p
                class="m-0 color-light-gray mat-body-1--smaller mat-option__inner__description">
                {{range.provider.name}}
                <ng-container *ngIf="range.operator as operator">
                  &nbsp;&middot;&nbsp;
                  {{range.operator}}
                </ng-container>
              </p>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="getCarrierLogo(range) as logo">
              <img src="{{logo}}" height="24px">
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <ng-container *ngTemplateOutlet="carrierTypeLogo; context: {range: range}"></ng-container>
            </div>
          </div>
        </mat-option>
        <mat-option
          *ngIf="!numberReducer.numberRanges?.length && !numberReducer.pending"
          [value]="null">
          <p class="m-0 color-black">No results found</p>
        </mat-option>
      </mat-autocomplete>
    </div>
    <ng-template #carrierTypeLogo let-range="range">
      <img
        src="{{range.provider.carrierType === CarrierType.BYON ? microsoftLogo : callrouteLogo}}"
        height="20px"
        width="20px"
        alt="{{range.provider.carrierType === CarrierType.BYON ? 'Microsoft' : 'Callroute'}}"
        class="mr-6">
    </ng-template>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <ng-container *ngIf="editMode; else viewOutbound">
        <mat-form-field appearance="outline">
          <mat-label>
            Caller ID (Outbound CLI)
          </mat-label>
          <input matInput type="text"
                 formControlName="numberQuery"
                 autocomplete="number-id"
                 data-cy="form-number-search"
                 placeholder="- Select -"
                 [matAutocomplete]="numberComplete" />
          <mat-spinner matSuffix strokeWidth="3" diameter="26" class="ml-16"
                       *ngIf="numberReducer.pending"></mat-spinner>
          <mat-autocomplete autoActiveFirstOption
                            #numberComplete="matAutocomplete"
                            class="option-line-height-1"
                            [displayWith]="getNumberName"
                            (optionSelected)="setNumberId($event)">
            <mat-option [value]="existingNumber" *ngIf="existingNumber">
              <p class="m-0 color-black">{{existingNumber.didNumber}}</p>
              <small>(Current)</small>
            </mat-option>
            <mat-option *ngFor="let number of removeExistingNumber(numberReducer.numbers)"
                        [value]="number">
              <p class="m-0 color-black">{{number.didNumber}}</p>
              <small *ngIf="number.description">({{number.description ? number.description : 'No description'}}
                )</small>
            </mat-option>
            <mat-option
              *ngIf="!numberReducer.numbers || !numberReducer.numbers.length && !numberReducer.pending"
              [value]="null">
              <p class="m-0 color-black">No results found</p>
            </mat-option>
          </mat-autocomplete>
          <ngx-field-notification [type]="FieldCheckType.Invalid"
                                  message="Please select a number from the list"
                                  *ngIf="isInvalid(formGroup.get('numberId'), ngForm.submitted)">
          </ngx-field-notification>
        </mat-form-field>
      </ng-container>
      <ng-template #viewOutbound>
        <label class="field-label">
          <span class="mr-6">Caller ID (Outbound CLI)</span>
          <a href="https://help.callroute.com/space/CKB/721092743/What+Does+Outbound+CLI+Mean" target="_blank"
             rel="nofollow noopener">
            <mat-icon class="icon color-light-gray" svgIcon="cr-help-circle"></mat-icon>
          </a>
        </label>
        <p class="color-black" [hidden]="editMode">{{existingNumber?.didNumber || ''}}</p>
      </ng-template>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         *ngIf="formGroup.get('username').value"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <label class="field-label">Username</label>
      <p class="color-black" fxLayout="row" fxLayoutAlign="start center">
                <span class="mr-16">
                    <ng-container *ngIf="viewUsername$ | async; else hiddenText">
                        {{formGroup.get('username').value}}
                    </ng-container>
                </span>
        <button mat-icon-button (click)="viewUsername.next(!viewUsername.value)"
                matTooltip="{{(viewUsername$ | async) ? 'Hide' : 'Show'}}">
          <mat-icon svgIcon="{{(viewUsername$ | async) ? 'cr-visibility-off' : 'cr-view'}}"></mat-icon>
        </button>
      </p>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         *ngIf="formGroup.get('password').value"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <label class="field-label">Password</label>
      <p class="color-black" fxLayout="row" fxLayoutAlign="start center">
                <span class="mr-16">
                    <ng-container *ngIf="viewPassword$ | async; else hiddenText">
                        {{formGroup.get('password').value}}
                    </ng-container>
                </span>
        <button mat-icon-button matTooltip="{{(viewPassword$ | async) ? 'Hide' : 'Show'}}"
                (click)="viewPassword.next(!viewPassword.value)">
          <mat-icon svgIcon="{{(viewPassword$ | async) ? 'cr-visibility-off' : 'cr-view'}}"></mat-icon>
        </button>
      </p>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         *ngIf="formGroup.get('deviceCertificateExpiryDate').value"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <label class="field-label">Device certificate expiry date</label>
      <p class="color-black">{{formGroup.get('deviceCertificateExpiryDate').value | localeDate:'medium'}}</p>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         *ngIf="formGroup.get('certificatePem')?.value"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <label class="field-label">Device certificate (PEM)</label>
      <p class="color-black" fxLayout="row" fxLayoutAlign="start center">
                <span class="mr-16">
                    <ng-container *ngIf="true; then hiddenText">
                    </ng-container>
                </span>
        <button mat-icon-button matTooltip="Copy to clipboard"
                [copyText]="formGroup.get('certificatePem').value"
                ngxCopy (copied)="doneCertCopy()">
          <mat-icon svgIcon="cr-copy"></mat-icon>
        </button>
        <ng-container *ngIf="certCopyText$ | async as t">
          <ng-container *ngIf="t === 'Copied'">
            <span class="mr-6 fw-500 fs-14">Copied</span>
            <mat-icon class="color-green jello-once sub-list__item__sub-icon"
                      svgIcon="cr-checkmark-done"></mat-icon>
          </ng-container>
        </ng-container>
      </p>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         *ngIf="formGroup.get('privateKeyPem')?.value"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <label class="field-label">Device private key (PEM)</label>
      <p class="color-black" fxLayout="row" fxLayoutAlign="start center">
                 <span class="mr-16">
                    <ng-container *ngIf="true; then hiddenText">
                    </ng-container>
                </span>
        <button mat-icon-button matTooltip="Copy to clipboard"
                [copyText]="formGroup.get('privateKeyPem').value"
                ngxCopy (copied)="donePrivateCopy()">
          <mat-icon svgIcon="cr-copy"></mat-icon>
        </button>
        <ng-container *ngIf="privateCopyText$ | async as t">
          <ng-container *ngIf="t === 'Copied'">
            <span class="mr-6 fw-500 fs-14">Copied</span>
            <mat-icon class="color-green jello-once sub-list__item__sub-icon"
                      svgIcon="cr-checkmark-done"></mat-icon>
          </ng-container>
        </ng-container>
      </p>
    </div>
    <div class="input-field-container" fxFlex="0 0 48" fxFlex.lt-md="0 0 100"
         *ngIf="formGroup.get('privateKeyPem')?.value"
         [ngClass]="!editMode && 'border-bottom-1-gray'">
      <label class="field-label">Combined device certificate & private key</label>
      <p class="color-black" fxLayout="row" fxLayoutAlign="start center">
                 <span class="mr-16">
                    <ng-container *ngIf="true; then hiddenText">
                    </ng-container>
                </span>
        <button mat-icon-button matTooltip="Copy to clipboard"
                [copyText]="combinedKeys"
                ngxCopy (copied)="doneBothCopy()">
          <mat-icon svgIcon="cr-copy"></mat-icon>
        </button>
        <ng-container *ngIf="bothCopyText$ | async as t">
          <ng-container *ngIf="t === 'Copied'">
            <span class="mr-6 fw-500 fs-14">Copied</span>
            <mat-icon class="color-green jello-once sub-list__item__sub-icon"
                      svgIcon="cr-checkmark-done"></mat-icon>
          </ng-container>
        </ng-container>
      </p>
    </div>
  </div>
  <ngx-field-notification
    [type]="FieldCheckType.Info"
    message="{{infoMessage}}">
  </ngx-field-notification>
</form>

<ng-template #hiddenText>•••••••••••</ng-template>
