import { Injectable, OnDestroy }                                                   from '@angular/core';
import { Actions, createEffect, ofType }                                           from '@ngrx/effects';
import { concatMap, filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import {
  AccessBrokerService,
}                                                                                  from '@services/access-broker.service';
import {
  AuthoriseRedirectRequest,
  AuthoriseRedirectResponse,
  DeleteTokensRequestAction,
  DeleteTokensResponseAction,
  FetchCombinedTokensStatusRequestAction,
  FetchCombinedTokensStatusResponseAction,
  MSOAuthCallback,
  RefreshAuthStatus,
  SetCookie,
  ToggleAuthFailedModal,
}                                                                                  from './access-broker.actions';
import { CookieService }                                                           from 'ngx-cookie-service';
import { StoreState }                                                              from '../store';
import { Store }                                                                   from '@ngrx/store';
import { MicrosoftToken }                                                          from '@enums/microsoft-token.enum';
import {
  AuthNoticeComponent,
}                                                                                  from '../../modules/auth-notice/auth-notice.component';
import { MatDialog, MatDialogRef }                                                 from '@angular/material/dialog';
import { TokenStatus }                                                             from '@enums/token-status.enum';
import { interval, Observable, of, Subject, timer }                                from 'rxjs';
import { selectTokens }                                                            from './access-broker.selectors';
import {
  RefreshAuthData,
}                                                                                  from '@models/api/refresh-auth-data.model';
import {
  Token,
}                                                                                  from '@models/entity/token-state.model';
import {
  AuthorisationRedirectRequest,
}                                                                                  from '@models/api/authorisation-redirect-request.model';
import { HttpCookie }                                                              from '@models/api/http-cookie.model';
import {
  AuthorisationRedirectResponse,
}                                                                                  from '@models/api/authorisation-redirect-response.model';
import {
  withThrottle,
}                                                                                  from '@rxjs/action-throttle.operator';
import {
  DeleteTokensRequest,
}                                                                                  from '@models/api/delete-tokens-request.model';
import {
  DeleteTokensResponse,
}                                                                                  from '@models/api/delete-tokens-response.model';
import {
  ClearCheckState, HealthCheckRequestAction,
  RequirementCheckRequestAction,
}                                                                                  from '@redux/microsoft-teams/microsoft-teams.actions';
import {
  selectServiceItem,
}                                                                                  from '@redux/service/service.selectors';
import {
  StatusItem,
}                                                                                  from '@models/entity/status-item.model';
import {
  ServiceItem,
}                                                                                  from '@models/entity/service-item.model';
import {
  MSOAuthPayload,
}                                                                                  from '@models/api/ms-oauth-payload.model';
import {
  FetchCombinedTokensStatusResponse,
}                                                                                  from '@models/api/fetch-combined-tokens-status-response.model';
import {
  FetchCombinedTokensStatusRequest,
}                                                                                  from '@models/api/fetch-combined-tokens-status-request.model';
import {
  MicrosoftTeams,
}                                                                                  from '@models/entity/microsoft-teams.model';
import {
  ServiceCapabilityIdentifier,
}                                                                                  from '@enums/service-capability-identifier.enum';
import {
  ToggleAuthModal,
}                                                                                  from '@models/api/toggle-auth-modal.model';
import {
  ConfirmModalComponent,
}                                                                                  from '@dialog/general/confirm-modal/confirm-modal.component';
import {
  ConfirmModalData,
}                                                                                  from '@models/ui/confirm-modal-data.model';

@Injectable()
export class AccessBrokerEffects implements OnDestroy {
  private authModal: MatDialogRef<AuthNoticeComponent>;
  private destroyAuthCloseListener                       = new Subject<void>();
  private destroyAuthCloseListener$: Observable<unknown> = this.destroyAuthCloseListener.asObservable();

  constructor(
    private actions$: Actions,
    private accessBrokerService: AccessBrokerService,
    private cookieService: CookieService,
    private store: Store<StoreState>,
    private dialog: MatDialog,
  ) {
  }

  authorizationRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(AuthoriseRedirectRequest),
    withLatestFrom(this.store.select(selectServiceItem)),
    switchMap(([req, serviceItem]: [AuthorisationRedirectRequest, ServiceItem]) => {
      this.accessBrokerService.openRedirectPopup();
      interval(500)
        .pipe(takeUntil(this.destroyAuthCloseListener$))
        .subscribe(() => {
          if (this.accessBrokerService.isRedirectClosed()) {
            this.store.dispatch(MSOAuthCallback({
              context:   'authorisation',
              status:    'error',
              serviceId: serviceItem.id,
            }));
            this.destroyAuthCloseListener.next();
          }
        });

      return this.accessBrokerService.authorisationRedirect$(req, serviceItem.id, (serviceItem as MicrosoftTeams)?.capabilities?.some(c => c.identifier === ServiceCapabilityIdentifier.Voice))
        .pipe(
          tap((res: AuthorisationRedirectResponse) => {
            this.store.dispatch(SetCookie(res.cookie));
          }),
          map((res: AuthorisationRedirectResponse) =>
            AuthoriseRedirectResponse(res)));
    }),
  ));

  setCookie$ = createEffect(() => this.actions$.pipe(
    ofType(SetCookie),
    tap((req: HttpCookie) =>
      this.cookieService.set(req.name, req.value, req.expireDays, req.path, req.domain, req.secure)),
  ), { dispatch: false });

  openAuthorisationWindow$ = createEffect(() => this.actions$.pipe(
    ofType(AuthoriseRedirectResponse),
    tap((res: AuthorisationRedirectResponse) => {
      if (res.redirectURI && res.cookie) {
        if (!this.cookieService.check('cr-session')) {
          this.cookieService.set(res.cookie.name, res.cookie.value, undefined, res.cookie.path, res.cookie.domain, res.cookie.http_only);
        }
        if (this.accessBrokerService.getRedirectLocation()) {
          this.accessBrokerService.setRedirectLocation(res.redirectURI);
        }
      }
    }),
  ), { dispatch: false });

  oAuthCallback$ = createEffect(() => this.actions$.pipe(
    ofType(MSOAuthCallback),
    withLatestFrom(this.store.select(selectTokens), this.store.select(selectServiceItem)),
    switchMap(([req, tokens, serviceItem]: [MSOAuthPayload, { [serviceId: string]: Token[] }, ServiceItem]): Observable<RefreshAuthData> => {
      return timer(500)
        .pipe(
          take(1),
          tap(() => {
            this.destroyAuthCloseListener.next();
            this.accessBrokerService.closeRedirectPopup();

            const lastToken  = tokens[req.serviceId]?.find(token => token.lastAuth);
            const otherToken = tokens[req.serviceId]?.find(token => token.id !== lastToken.id);

            if (req.status === 'error') {
              return this.store.dispatch(ToggleAuthFailedModal({
                serviceId: req.serviceId,
                open:      true,
                error:     req.errorMessage,
                token:     lastToken?.id,
              }));
            }

            this.store.select(selectServiceItem)
              .pipe(
                filter((service: ServiceItem) => ServiceItem.isMicrosoft(service)),
                take(1))
              .subscribe((serviceItem: ServiceItem) => {
                const item: MicrosoftTeams = serviceItem as MicrosoftTeams;

                const shouldClearHealth = item?.isActive() &&
                  StatusItem.isWarning(item.healthStatus.status) &&
                  req.status === 'success' &&
                  otherToken.status === TokenStatus.Active;

                const shouldRunRequirements = !item?.isActive() &&
                  lastToken?.id === MicrosoftToken.MicrosoftAzure;

                if (shouldClearHealth) {
                  this.store.dispatch(ClearCheckState());
                } else if (shouldRunRequirements) {
                  this.store.dispatch(RequirementCheckRequestAction({ serviceId: item.id }));
                }
              });
          }),
          map(() => ({
            serviceId: serviceItem.id,
            status:    req.status === 'success' ? TokenStatus.Active : TokenStatus.Error,
          }) as RefreshAuthData));
    }),
    map((data: RefreshAuthData) => RefreshAuthStatus(data))));

  authFailed$ = createEffect(() => this.actions$.pipe(
    ofType(ToggleAuthFailedModal),
    tap((req: ToggleAuthModal) => {
      if (req.open) {
        if (!this.authModal) {
          this.authModal = this.dialog.open(AuthNoticeComponent, {
            panelClass: 'cr-dialog',
            maxWidth:   '640px',
            maxHeight:  'calc(100vh - 140px)',
            width:      '100%',
            data:       req.error,
          });
          return this.authModal.afterClosed()
            .subscribe((retry: boolean) => {
              this.authModal = null;
              return retry && this.store.dispatch(AuthoriseRedirectRequest({
                serviceId: req.serviceId,
                token:     req.token,
              }));
            });
        }
        return;
      }
      this.authModal?.close();
      this.authModal = null;
    })), { dispatch: false });

  refreshAuthStatus$ = createEffect(() => this.actions$.pipe(
    ofType(RefreshAuthStatus),
    withThrottle(),
    tap(async (req: RefreshAuthData) => {
      this.store.dispatch(FetchCombinedTokensStatusRequestAction({ serviceId: req.serviceId }));
    })), { dispatch: false });

  fetchCombinedTokensStatus$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCombinedTokensStatusRequestAction),
    switchMap((req: FetchCombinedTokensStatusRequest) => {
      return this.accessBrokerService.fetchCombinedTokensStatus$(req);
    }),
    map((res: FetchCombinedTokensStatusResponse) => FetchCombinedTokensStatusResponseAction(res)),
  ));

  rerunHealthCheck$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCombinedTokensStatusResponseAction),
    withLatestFrom(this.store.select(selectServiceItem)),
    tap(([res, serviceItem]: [FetchCombinedTokensStatusResponse, ServiceItem]) => {
      if (!ServiceItem.isMicrosoft(serviceItem) || serviceItem.id !== res.serviceId || !serviceItem.isActive()) {
        return;
      }
      this.store.dispatch(HealthCheckRequestAction({ serviceId: res.serviceId, tokens: res.tokens }));
    }),
  ), { dispatch: false });

  deleteTokens$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteTokensRequestAction),
    switchMap((req: DeleteTokensRequest) => {
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:          `Delete auth tokens`,
          content:        `You are about to delete your Microsoft authorisation tokens and will need to reauthorise. Click 'delete' to continue with the deletion.`,
          confirmBtnText: 'Delete',
          showCancel:     true,
          typeConfirm:    true,
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(concatMap((confirmed: boolean) => {
          if (!confirmed) {
            return of(DeleteTokensResponseAction({
              serviceId: req.serviceId,
              cancelled: true,
              error:     null,
            }));
          }
          return this.accessBrokerService.deleteTokens$(req)
            .pipe(map((res: DeleteTokensResponse) => {
              return DeleteTokensResponseAction(res);
            }));
        }));
    }),
  ));

  clearChecks$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteTokensResponseAction),
    tap((res: DeleteTokensResponse) => {
      if (res.error || res.cancelled) {
        return;
      }
      this.store.dispatch(ClearCheckState());
    }),
  ), { dispatch: false });

  ngOnDestroy(): void {
    this.destroyAuthCloseListener.next();
  }
}
