import { Component, Inject, OnDestroy }       from '@angular/core';
import { RedirectCallback }                   from '@enums/redirectCallback.enum';
import { DOCUMENT }                           from '@angular/common';
import { RouterService }                      from '@services/router.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil }            from 'rxjs/operators';
import { StoreState }                         from '@redux/store';
import { Store }                              from '@ngrx/store';
import { selectAuthError, selectUser }        from '@redux/auth/auth.selectors';
import { User }                               from '@models/entity/user.model';
import { AuthToken }                          from '@enums/auth-token.enum';
import { TokenService }                       from '@services/token.service';

@Component({
  selector:    'ngx-callback-handler',
  templateUrl: './callback-handler.component.html',
  styleUrls:   ['./callback-handler.component.scss'],
})
export class CallbackHandlerComponent implements OnDestroy {

  destroy  = new Subject<void>();
  destroy$ = this.destroy.asObservable();
  hasError: boolean;
  noCompanyError: boolean;
  errorHeader: string;
  errorMessage: string;
  user$: Observable<User>;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private routerService: RouterService,
    private store: Store<StoreState>,
  ) {
    this.user$ = this.store.select(selectUser);
    combineLatest([this.routerService.params$, this.routerService.path$])
      .pipe(
        takeUntil(this.destroy$),
        filter(([params, path]) => !!params && path?.includes('callback')),
        take(1),
      )
      .subscribe(([params, path]) => {
          if (params.context) {
            this.hasError = params.status === 'error';

            if (params.context === 'service.provision' && (this._document.defaultView.opener as unknown as any)[RedirectCallback.AddService]) {
              (this._document.defaultView.opener as unknown as any)[RedirectCallback.AddService](params);
            }
            if (params.context === 'authorisation' && (this._document.defaultView.opener as unknown as any)[RedirectCallback.MSOAuth]) {
              (this._document.defaultView.opener as unknown as any)[RedirectCallback.MSOAuth](params);
            }
          } else {
            if (path.includes('oauth2/callback')) {
              if (!!params.error_subcode && TokenService.getToken(AuthToken.Access)) {
                return this.routerService.navigate(['admin', 'access-control'], undefined, { fragment: 'microsoft-sso' });
              }
              params.referrer = this._document.referrer;
              (this._document.defaultView as unknown as any)[RedirectCallback.OAuth2](params);
              this.store.select(selectAuthError)
                .pipe(
                  takeUntil(this.destroy$),
                  filter(error => !!error),
                )
                .subscribe(error => {
                  switch (error.message) {
                    case 'No company exists for the login domain.':
                      this.noCompanyError = true;
                      return;
                    case 'The user account is pending approval by an administrator.':
                      this.errorHeader = 'Pending approval';
                      break;
                    default:
                      this.errorHeader = 'An error occurred';
                  }
                  this.errorMessage = error.message;
                  this.hasError     = true;
                });

            }
          }
        },
      );
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

}
