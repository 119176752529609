export enum ConditionOperator {
  Equals       = '=',
  NotEquals    = '!=',
  GreaterThan  = '>',
  GreaterEqual = '>=',
  LessThan     = '<',
  LessEqual    = '<=',
  Contains     = 'contains',
  StartsWith   = 'starts-with',
  EndsWith     = 'ends-with',
  InValues     = 'in-values'
}
