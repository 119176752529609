import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';
import { ProviderIdentifier }   from '@enums/provider-identifier.enum';

export class NumberCountByAssignedQueryParams extends ListParams implements ListParamModel<NumberCountByAssignedQueryParams> {
  provider?: ProviderIdentifier[];
  hasLocation?: boolean;
  token?: string;

  static constructQueryString(searchParams: NumberCountByAssignedQueryParams): string {
    const params: NumberCountByAssignedQueryParams = { ...(searchParams || {}) as NumberCountByAssignedQueryParams };

    return ListParams.constructQueryString(params, {
      provider:    'filter[provider][]',
      hasLocation: 'filter[has_location]',
      token:       'filter[search_token]',
    });
  }

  static queryChanged(newQuery: NumberCountByAssignedQueryParams, currentQuery: NumberCountByAssignedQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery);
  }

  constructParams?(metaData: ListResponseMetadata): NumberCountByAssignedQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.provider) {
        this.provider = metaData.filter.provider;
      }
      if (metaData.filter.has_location) {
        this.hasLocation = metaData.filter.has_location;
      }
      if (metaData.filter.token) {
        this.token = metaData.filter.token;
      }
    }

    return this;
  }
}
