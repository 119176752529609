import { NgModule }                from '@angular/core';
import { SignedDownloadDirective } from './signed-download.directive';
import { BuyNumberBtnDirective }   from './buy-number-btn.directive';
import { IfScopeDirective }        from './if-scope.directive';
import { IfSipAdminDirective }     from './if-sip-admin.directive';
import { NgxCopyDirective }        from './copy.directive';
import { DragDropDirective }       from './drag-drop.directive';
import { MaxRangeDirective }       from './max-range.directive';


@NgModule({
  declarations: [
    SignedDownloadDirective,
    BuyNumberBtnDirective,
    IfScopeDirective,
    IfSipAdminDirective,
    NgxCopyDirective,
    DragDropDirective,
    MaxRangeDirective,
  ],
  exports:      [
    SignedDownloadDirective,
    BuyNumberBtnDirective,
    IfScopeDirective,
    IfSipAdminDirective,
    NgxCopyDirective,
    DragDropDirective,
    MaxRangeDirective,
  ],
})
export class DirectiveModule {}
