import { Injectable }                                                      from '@angular/core';
import { Actions, createEffect, ofType }                                   from '@ngrx/effects';
import {
  CancelTaskRequestAction,
  CancelTaskResponseAction,
  FetchAuditContextListRequestAction,
  FetchAuditContextListResponseAction,
  FetchAuditListRequestAction,
  FetchAuditListResponseAction,
  FetchAuditObjectListRequestAction,
  FetchAuditObjectListResponseAction,
  FetchTaskCountRequestAction,
  FetchTaskCountResponseAction,
  FetchTaskListRequestAction,
  FetchTaskListResponseAction,
  FetchTaskRequestAction,
  FetchTaskResponseAction,
}                                                                          from '@redux/audit/audit.actions';
import { concatMap, map, mergeMap, switchMap, take, tap }                  from 'rxjs/operators';
import { AuditService }                                                    from '@services/audit.service';
import {
  FetchTaskListRequest,
}                                                                          from '@models/api/fetch-task-list-request.model';
import {
  FetchServiceUserCountsByVoiceTypeRequestAction,
  FetchServiceUserListRequestAction,
  FetchServiceUserRequestAction,
}                                                                          from '@redux/service/service.actions';
import { FetchNumberListRequestAction, FetchNumberRangeListRequestAction } from '@redux/number/number.actions';
import { StoreState }                                                      from '@redux/store';
import { Action, Store }                                                   from '@ngrx/store';
import { TaskStatus }                                                      from '@enums/task-status.enum';
import { selectServiceItem, selectServiceUserQueryParams }                 from '@redux/service/service.selectors';
import { selectNumberRangeQueryParams }                                    from '@redux/number/number.selectors';
import { ServiceItem }                                                     from '@models/entity/service-item.model';
import {
  FetchTaskResponse,
}                                                                          from '@models/api/audit/fetch-task-response.model';
import {
  FetchTaskRequest,
}                                                                          from '@models/api/audit/fetch-task-request.model';
import {
  FetchTaskCountResponse,
}                                                                          from '@models/api/audit/fetch-task-count-response.model';
import {
  CancelTaskRequest,
}                                                                          from '@models/api/audit/cancel-task-request.model';
import {
  CancelTaskResponse,
}                                                                          from '@models/api/audit/cancel-task-response.model';
import {
  ConfirmModalComponent,
}                                                                          from '@dialog/general/confirm-modal/confirm-modal.component';
import { ConfirmModalData }                                                from '@models/ui/confirm-modal-data.model';
import { of }                                                              from 'rxjs';
import { MatDialog }                                                       from '@angular/material/dialog';
import {
  FetchAuditListRequest,
}                                                                          from '@models/api/fetch-audit-list-request.model';
import {
  FetchAuditListResponse,
}                                                                          from '@models/api/fetch-audit-list-response.model';
import {
  FetchAuditContextListResponse,
}                                                                          from '@models/api/audit/fetch-audit-context-list-response.model';
import {
  FetchAuditObjectListResponse,
}                                                                          from '@models/api/audit/fetch-audit-object-list-response.model';
import {
  FetchAdGroupsRequestAction,
}                                                                          from '@redux/microsoft-teams/microsoft-teams.actions';
import {
  selectADGroupQueryParams,
}                                                                          from '@redux/microsoft-teams/microsoft-teams.selectors';
import { withScopes }                                                      from '@rxjs/with-scopes.operator';
import { selectUserScopes }                                                from '@redux/auth/auth.selectors';
import { AuthScope }                                                       from '@enums/auth-scope.enum';
import { TaskSchema }                                                      from '@enums/task-schema.enum';
import { FetchReportListRequestAction }                                    from '@redux/report/report.actions';
import { selectReportListQueryParams }                                     from '@redux/report/report.selectors';
import moment                                                              from 'moment';

@Injectable()
export class AuditEffects {
  constructor(
    private actions$: Actions,
    private auditService: AuditService,
    private store: Store<StoreState>,
    private dialog: MatDialog,
  ) {}

  cancelTask$ = createEffect(() => this.actions$.pipe(
    ofType(CancelTaskRequestAction),
    switchMap((req: CancelTaskRequest) => {
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:           `Cancel ${ req.name }`,
          content:         `<p>You are about to cancel a task. Click 'Confirm' to continue with the cancellation.</p>`,
          confirmBtnText:  'Confirm',
          showCancel:      true,
          cancelBtnText:   'Close',
          typeConfirm:     true,
          typeConfirmText: 'CANCEL',
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(concatMap((confirmed: boolean) => {
          if (!confirmed) {
            return of(CancelTaskResponseAction({
              cancelled: true,
              error:     null,
              id:        req.id,
              data:      null,
            }));
          }
          return this.auditService.cancelTask$(req)
            .pipe(map((res: CancelTaskResponse) => CancelTaskResponseAction(res)));
        }));
    }),
  ));

  fetchTaskCount$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTaskCountRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.TaskRead]),
    switchMap(() => this.auditService.fetchTaskCount$()),
    map((res: FetchTaskCountResponse) => FetchTaskCountResponseAction(res)),
  ));

  fetchTask$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTaskRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.TaskRead]),
    switchMap((req: FetchTaskRequest) => this.auditService.fetchTask$(req)),
    map((res: FetchTaskResponse) => FetchTaskResponseAction(res))),
  );

  fetchAuditList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAuditListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.AuditRead]),
    switchMap((req: FetchAuditListRequest) => this.auditService.fetchAuditList$(req)),
    map((res: FetchAuditListResponse) => FetchAuditListResponseAction(res)),
  ));

  fetchAuditContexts$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAuditContextListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.AuditRead]),
    switchMap(() => this.auditService.fetchAuditContexts$()),
    map((res: FetchAuditContextListResponse) => FetchAuditContextListResponseAction(res)),
  ));

  fetchAuditObjects$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAuditObjectListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.AuditRead]),
    switchMap(() => this.auditService.fetchAuditObjects$()),
    map((res: FetchAuditObjectListResponse) => FetchAuditObjectListResponseAction(res)),
  ));

  fetchTaskList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTaskListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.TaskRead]),
    switchMap((req: FetchTaskListRequest) => this.auditService.fetchTaskList$(req)
      .pipe(concatMap(res => {
        return this.store.select(selectServiceUserQueryParams)
          .pipe(
            take(1),
            mergeMap(serviceUserQueryParams => {
              return this.store.select(selectNumberRangeQueryParams)
                .pipe(
                  take(1),
                  mergeMap(numberRangeQueryParams => {
                    return this.store.select(selectServiceItem)
                      .pipe(
                        take(1),
                        mergeMap(serviceItem => this.store.select(selectADGroupQueryParams)
                          .pipe(
                            take(1),
                            mergeMap(adGroupQueryParams => this.store.select(selectReportListQueryParams)
                              .pipe(
                                map(reportListQueryParams => {
                                  return {
                                    res,
                                    serviceUserQueryParams,
                                    numberRangeQueryParams,
                                    adGroupQueryParams,
                                    reportListQueryParams,
                                    serviceItem,
                                  };
                                }))))));
                  }));
            }));
      }))),
    tap(({
           res,
           serviceUserQueryParams,
           numberRangeQueryParams,
           adGroupQueryParams,
           reportListQueryParams,
           serviceItem,
         }) => {
      if (!res.models?.length) {
        return;
      }

      const actions: Action[] = [];

      const hasIncompleteTask = (...schemas: TaskSchema[]): boolean =>
        res.models.some(m => m.status !== TaskStatus.Complete && schemas.includes(m.schema.identifier));

      const hasJustFinishedTask = (...schemas: TaskSchema[]): boolean => {
        return res.models.some(m => {
          const isComplete          = m.status === TaskStatus.Complete;
          const secondsSince        = moment()
            .diff(moment(m.endDate), 'seconds');
          const isLessThan10Seconds = secondsSince < 10;
          const hasSchemaMatch      = schemas.includes(m.schema.identifier);

          return isComplete && isLessThan10Seconds && hasSchemaMatch;
        });
      };

      if ((hasIncompleteTask(TaskSchema.UserSync) || hasJustFinishedTask(TaskSchema.UserSync)) && serviceUserQueryParams) {
        const serviceId = res.models.find(m => m.schema.identifier === TaskSchema.UserSync)?.audit.object?.id;
        if (serviceId) {
          actions.push(
            FetchServiceUserListRequestAction({ emitEvent: false, serviceId }),
            FetchServiceUserCountsByVoiceTypeRequestAction({
              serviceId,
              isResourceAccount: serviceUserQueryParams.isResourceAccount,
            }),
          );
        }
      }

      const assignmentSchemas: TaskSchema[] = [
        TaskSchema.LicenseGroupAssignment,
        TaskSchema.TeamGroupAssignment,
        TaskSchema.CallQueueGroupAssignment,
      ];

      if (hasIncompleteTask(...assignmentSchemas) && ServiceItem.isMicrosoft(serviceItem)) {
        const id = res.models.find(m => assignmentSchemas.includes(m.schema.identifier))?.audit.object.id;
        actions.push(FetchServiceUserRequestAction({ emitEvent: false, serviceId: serviceItem.id, id }));
      }

      if (hasIncompleteTask(TaskSchema.NumberSync) && numberRangeQueryParams) {
        actions.push(FetchNumberRangeListRequestAction({ emitEvent: false }));
      }

      if ((hasIncompleteTask(TaskSchema.NumberUpsert, TaskSchema.UpdateBySearchToken) ||
        hasJustFinishedTask(TaskSchema.NumberUpsert, TaskSchema.UpdateBySearchToken)) && numberRangeQueryParams) {
        actions.push(FetchNumberListRequestAction({ emitEvent: false }));
      }

      if (hasIncompleteTask(TaskSchema.AdGroupSync) && adGroupQueryParams && ServiceItem.isMicrosoft(serviceItem)) {
        actions.push(FetchAdGroupsRequestAction({ serviceId: serviceItem.id, queryParams: adGroupQueryParams }));
      }

      if (hasIncompleteTask(TaskSchema.NumberExport) && reportListQueryParams) {
        actions.push(FetchReportListRequestAction({ queryParams: reportListQueryParams }));
      }

      setTimeout(() => {
        actions.forEach(action => this.store.dispatch(action));
      }, 10_000);
    }),
    map(({ res }) => FetchTaskListResponseAction(res))));
}
