<form data-cy="paginator" [formGroup]="formGroup" novalidate>
  <div class="page-size display-inline-block">
    <span>Per page:</span>&nbsp;
    <select formControlName="pageSize" data-cy="form-page-size" class="cr-select m-0"
            (change)="changeCount()">
      <option *ngFor="let size of pageSizeOptions" [value]="size">{{size}}</option>
    </select>
  </div>
  <div class="page-controls display-inline-block">
    <span fxHide.lt-sm>{{(pageSize * pageNumber) - pageSize + 1}} - {{(pageSize * pageNumber) > recordCount ?
      recordCount : (pageSize * pageNumber)}} of {{recordCount | number}}</span>
    <button type="button"
            class="back"
            data-cy="back"
            mat-icon-button
            matTooltip="Previous page"
            [disabled]="pageNumber <= 1"
            (click)="changePage(-1)">
      <mat-icon svgIcon="cr-chevron-back"></mat-icon>
    </button>
    <span fxHide.gt-xs>{{(pageSize * pageNumber) - pageSize + 1}} - {{(pageSize * pageNumber) > recordCount ?
      recordCount : (pageSize * pageNumber)}} of {{recordCount | number}}</span>
    <button type="button"
            class="next"
            data-cy="next"
            mat-icon-button
            matTooltip="Next page"
            [disabled]="pageNumber >= pageCount"
            (click)="changePage(1)">
      <mat-icon svgIcon="cr-chevron-forward"></mat-icon>
    </button>
  </div>
</form>
