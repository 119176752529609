import { BaseRequest }   from '@redux/helpers/reducer.helper';
import { TeamGroupRaw } from '@models/api/microsoft-teams/teams-group-raw.model';

export class PostTeamGroupRequest extends BaseRequest {
  serviceId: string;
  name: string;
  description: string;
  teamIds: string[];

  constructor(private data?: PostTeamGroupRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): TeamGroupRaw {
    return {
      name:        this.name,
      team_ids:    this.teamIds,
      description: this.description,
    };
  }
}
