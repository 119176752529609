import { Injectable }                                         from '@angular/core';
import { CanActivate, CanLoad, UrlTree }                      from '@angular/router';
import { Observable, of }                                     from 'rxjs';
import { StoreState }                                         from '@redux/store';
import { Store }                                              from '@ngrx/store';
import { catchError, filter, map, pluck, take, tap, timeout } from 'rxjs/operators';
import { SipsynergyUUID }                                     from '@consts/sipsynergy.const';
import { AuthScope }                                          from '@enums/auth-scope.enum';
import { RouterService }                                      from '@services/router.service';
import { selectAuth }                                         from '@redux/auth/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class SipsynergyAdminGuard implements CanActivate, CanLoad {
  constructor(private store: Store<StoreState>,
              private router: RouterService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectAuth)
      .pipe(
        pluck('user'),
        filter(user => !!user),
        map(user => user?.companyId === SipsynergyUUID && [AuthScope.AdminNumber, AuthScope.NumberWrite]
          .every(scope => user?.scopes?.includes(scope))),
        timeout(5_000),
        catchError(() => of(null)),
        take(1),
        tap(canAccess => !canAccess && this.router.navigate(['/'])));
  }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate();
  }

}
