import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private static readonly baseUrl = '/assets';
  static readonly iconsUrl        = `${ AssetsService.baseUrl }/icons`;
  static readonly imagesUrl       = `${ AssetsService.baseUrl }/images`;
  static readonly serviceIconsUrl = `${ AssetsService.baseUrl }/service`;
  static readonly flagsUrl        = `${ AssetsService.baseUrl }/flags`;
  static readonly faviconUrl      = `${ AssetsService.baseUrl }/favicons`;
  static readonly companyUrl      = `${ AssetsService.baseUrl }/company`;

  constructor() { }

  static getIcon(icon: string): string {
    return `${ AssetsService.iconsUrl }/${ icon }`;
  }

  static getServiceIcon(icon: string): string {
    return `${ AssetsService.serviceIconsUrl }/${ icon }`;
  }

  static getFavicon16(): string {
    return `${ AssetsService.faviconUrl }/favicon-16x16.png`;
  }

  static getFavicon32(): string {
    return `${ AssetsService.faviconUrl }/favicon-32x32.png`;
  }

  static getLargeLogo(): string {
    return AssetsService.getFavicon('android-chrome-192x192.png');
  }

  static getAppleTouch(): string {
    return `${ AssetsService.faviconUrl }/apple-touch-icon.png`;
  }

  static getCompanyLogo(companyId: string): string {
    return `${ AssetsService.companyUrl }/${ companyId }/logo.png`;
  }

  static getCompanyFavicon(companyId: string): string {
    return `${ AssetsService.companyUrl }/${ companyId }/favicon.ico`;
  }

  static getCompanyFavicon16(companyId: string): string {
    return `${ AssetsService.companyUrl }/${ companyId }/favicon-16x16.png`;
  }

  static getCompanyFavicon32(companyId: string): string {
    return `${ AssetsService.companyUrl }/${ companyId }/favicon-32x32.png`;
  }

  static getCompanyLargeLogo(companyId: string): string {
    return `${ AssetsService.companyUrl }/${ companyId }/android-chrome-192x192.png`;
  }

  static getCompanyAppleTouch(companyId: string): string {
    return `${ AssetsService.companyUrl }/${ companyId }/apple-touch-icon.png`;
  }

  static getFlag(flag: string): string {
    return `${ AssetsService.flagsUrl }/${ flag }`;
  }

  static getFavicon(favicon: string = 'favicon-32x32.png'): string {
    return `${ AssetsService.faviconUrl }/${ favicon }`;
  }

  static getImage(image: string): string {
    return `${ AssetsService.imagesUrl }/${ image }`;
  }
}
