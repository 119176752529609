import { Component, Input } from '@angular/core';

@Component({
  selector:    'ngx-trial-expiry-header',
  templateUrl: './trial-expiry-header.component.html',
  styleUrls:   ['./trial-expiry-header.component.scss'],
})
export class TrialExpiryHeaderComponent {
  @Input() trialLicenseExpiry: string | false;
  @Input() isFreePackage: boolean;
  @Input() freeCredit: string;
  @Input() isViewingCompany: boolean;
  @Input() backgroundColor: string;
}
