import { TaskType }     from '@enums/task-type.enum';
import { TaskStatus }   from '@enums/task-status.enum';
import { TaskOutcome }  from '@enums/task-outcome.enum';
import { TaskRaw }      from '@models/api/task-raw.model';
import { Audit }        from '@models/entity/audit.model';
import { TaskMessage }  from '@models/entity/task-message.model';
import { ThemePalette } from '@angular/material/core';
import { TaskSchema }   from '@enums/task-schema.enum';

export class Task {
  id: string;
  auditId: string;
  createdDate: string;
  updatedDate: string;
  activityDate: string;
  type: TaskType;
  status: TaskStatus;
  outcome: TaskOutcome;
  schema: { identifier: TaskSchema };
  startDate: string;
  endDate: string;
  summary: {
    name: string;
    sequence: number;
    description: string;
    totalCount: number;
    processedCount: number;
    isInternal: boolean;
  }[];
  messages?: TaskMessage[];
  audit: Audit;

  constructor(private data?: Task) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: TaskRaw): Task {
    this.id          = data.id;
    this.auditId     = data.audit_id;
    this.createdDate = data.created_date;
    this.updatedDate = data.updated_date;
    this.type        = data.type;
    this.status      = data.status;
    this.outcome     = data.outcome;
    this.audit       = data.audit ? new Audit().fromApiData(data.audit) : null;
    this.startDate   = data.start_date;
    this.endDate     = data.end_date;
    this.messages    = data.messages;
    this.schema      = data.schema;
    this.summary     = data.summary?.map(s => ({
      name:           s.name,
      sequence:       s.sequence,
      description:    s.description,
      processedCount: s.processed_count,
      totalCount:     s.total_count,
      isInternal:     s.is_internal,
    }))
      .filter(s => !s.isInternal)
      .sort((a, b) => a.sequence - b.sequence) || [];
    return this;
  }

  getBarColor?(): ThemePalette {
    switch (this.status) {
      case TaskStatus.Complete:
        switch (this.outcome) {
          case TaskOutcome.Success:
          case TaskOutcome.Partial:
            return 'accent';
          default:
            return 'warn';
        }
      case TaskStatus.Pending:
      case TaskStatus.Processing:
        return 'primary';
    }
  }

  getOutcome?(): string {
    switch (this.outcome) {
      case TaskOutcome.Cancelled:
        return 'Canceled';
      case TaskOutcome.Partial:
        return 'Partial Success';
      case TaskOutcome.Timeout:
        return 'Timeout';
      case TaskOutcome.Success:
        return 'Success';
      default:
        return 'Failed';
    }
  }

  getName?(): string {
    switch (this.schema?.identifier) {
      case TaskSchema.UpdateBySearchToken:
        return 'Updating numbers';
      case TaskSchema.UserSync:
        return 'Syncing Microsoft Teams users';
      case TaskSchema.NumberSync:
        return 'Syncing Microsoft Teams numbers';
      case TaskSchema.TeamGroupAssignment:
        return 'Assigning Microsoft Teams team groups';
      case TaskSchema.TeamGroupUpdated:
        return 'Reassigning Microsoft Teams teams';
      case TaskSchema.LicenseGroupAssignment:
        return 'Assigning Microsoft Teams license groups';
      case TaskSchema.LicenseGroupUpdated:
        return 'Reassigning Microsoft Teams licenses';
      case TaskSchema.CallQueueGroupAssignment:
        return 'Assigning Microsoft Teams call queue groups';
      case TaskSchema.CallQueueGroupUpdated:
        return 'Reassigning Microsoft Teams call queues';
      case TaskSchema.UserConfigure:
        return 'Configuring Microsoft Teams user';
      case TaskSchema.NumberExport:
        return 'Exporting numbers';
      case TaskSchema.CallQueueSync:
        return 'Syncing Microsoft Teams call queues';
      case TaskSchema.TeamSync:
        return 'Syncing Microsoft Teams teams';
      case TaskSchema.LicenseSync:
        return 'Syncing Microsoft Teams licenses';
      case TaskSchema.AdGroupSync:
        return 'Syncing Microsoft tenant Entra ID groups';
      case TaskSchema.MicrosoftAssetSync:
        return 'Syncing Microsoft tenant assets';
      case TaskSchema.PolicySync:
        return 'Syncing Microsoft tenant policies';
      case TaskSchema.TenantConnect:
        return 'Connecting to Microsoft tenant';
      case TaskSchema.DomainSync:
        return 'Syncing Microsoft domains';
      case TaskSchema.NumberImport:
        return 'Importing numbers';
      case TaskSchema.CdrExport:
        return 'Exporting CDRs';
      case TaskSchema.ServiceUserExport:
        return 'Exporting Microsoft service users';
      case TaskSchema.ProvisionMicrosoftTeamsService:
        return 'Provisioning Microsoft Teams service';
      case TaskSchema.NumberUpsert:
        return 'Updating numbers';
      case TaskSchema.CompanyCreate:
        return 'Creating company';
      case TaskSchema.CompanyDelete:
        return 'Deleting company';
      default:
        return '';
    }
  }
}
