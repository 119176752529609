import { ServiceHealthCheckName } from '@enums/health-check.enum';
import {
  ProvisionAction,
}                                 from '@enums/provision-action.enum';
import {
  RequirementCheckItem,
  RequirementChecklist,
}                                 from '@models/ui/requirement-check.model';
import {
  getServiceIcon,
  HealthState,
  PROCESSING_STATUSES,
  RequirementsState,
  SUCCESS_STATUSES,
}                                 from '@models/api/service.model';
import {
  HealthCheckItem,
  HealthCheckList,
}                                 from '@models/entity/health-check.model';
import {
  Token,
}                                 from '@models/entity/token-state.model';
import {
  ProvisionStep,
}                                 from '@enums/provisioning-step.enum';
import {
  ServiceType,
}                                 from '@enums/service-type.enum';
import {
  TokenStatus,
}                                 from '@enums/token-status.enum';
import {
  ServiceItem,
}                                 from '@models/entity/service-item.model';
import {
  MicrosoftToken,
}                                 from '@enums/microsoft-token.enum';
import {
  ServiceRequirementCheckName,
}                                 from '@enums/service-requirement.enum';
import {
  StatusItem,
}                                 from '@models/entity/status-item.model';
import {
  MicrosoftTeamsRaw,
}                                 from '@models/api/microsoftTeamsRaw.model';
import {
  RequirementInfo,
}                                 from '@models/api/requirement-info.model';
import {
  HealthInfo,
}                                 from '@models/api/health-info.model';
import {
  MicrosoftTeamsUserFactoryService,
}                                 from '@services/microsoft-teams-user-factory.service';
import moment                     from 'moment';
import {
  enumToArray,
}                                 from '@util/enumToArray';
import {
  PolicyList,
}                                 from '@models/entity/policy-list.model';
import {
  ServiceSchema,
}                                 from '@models/entity/service-schema.model';
import {
  ServiceUIStatus,
}                                 from '@enums/service-ui-status';
import {
  ServiceCapabilityIdentifier,
}                                 from '@enums/service-capability-identifier.enum';
import {
  ServiceUserStatusCounts,
}                                 from '@models/api/service-user-status-counts';


export class MicrosoftTeams extends ServiceItem {

  icon                               = getServiceIcon(ServiceType.MicrosoftTeams, false);
  colorIcon                          = getServiceIcon(ServiceType.MicrosoftTeams, true);
  serviceType                        = ServiceType.MicrosoftTeams;
  health: HealthCheckList            = new Map<ServiceHealthCheckName, HealthCheckItem>([]);
  requirements: RequirementChecklist = new Map<ServiceRequirementCheckName, RequirementCheckItem>([]);
  requirementStatus: StatusItem;
  authoriseStatus: StatusItem;
  healthStatus: StatusItem;
  initialSyncStatus: StatusItem;
  isProcessing: boolean;
  lastUserSyncDate: string;
  nextUserSyncDate: string;
  userCount: number;
  userStatusCounts?: ServiceUserStatusCounts;
  tokens?: Token[];

  ortoHealthFields: ServiceHealthCheckName[]           = [
    ServiceHealthCheckName.Auth,
    ServiceHealthCheckName.AccessTokenValidity,
    ServiceHealthCheckName.HasAdminRole,
    ServiceHealthCheckName.HasRecentSync,
  ];
  ortoRequirementFields: ServiceRequirementCheckName[] = [
    ServiceRequirementCheckName.AccessTokenValidity,
    ServiceRequirementCheckName.HasAdminRole,
    ServiceRequirementCheckName.TenantUnique,
  ];

  getIcon?(): string {
    return this.isOrto ? '/assets/images/orto/orto_icon.svg' : this.colorIcon;
  }

  get isOrto(): boolean {
    return !this.capabilities || !this.capabilities.some(capability => capability.identifier === ServiceCapabilityIdentifier.Voice);
  }

  static getLicenseTooltip<T>(checks: T, checkName: 'Phone license' | 'User license'): string {
    const field: keyof T = checkName === 'Phone license' ?
      'phoneLicenseMessages' as keyof T : 'userLicenseMessages' as keyof T;

    if (!(checks?.[field] as unknown as string[])?.length) {
      return '';
    }

    const bullets = (checks[field] as unknown as string[]).map(detail => '•  '.concat(detail));

    return bullets.reduce((a, b) => a.concat('\n', b));
  }

  static getPhoneLicenseAdvisoryTooltip(): string {
    return 'No users have a phone system license assigned and are therefore unavailable. ' +
      'To enable users to use Callroute, please assign a phone system license to the relevant users in your Microsoft tenant.';
  }

  static getTokenValidityTooltip(hasAdminRole: boolean,
                                 isRequirementChecker: boolean,
                                 tokenDataMatch: boolean,
                                 tokenDataMessage: string): string {
    if (!isRequirementChecker && !tokenDataMatch) {
      return tokenDataMessage ||
        'You have authorised with Microsoft using two different accounts. Please delete the tokens and reauthorise using the same Microsoft account.';
    }
    if (hasAdminRole) {
      return 'The tokens used to connect to Microsoft will take between 1-2 hours to refresh against the new user role. ' +
        'Administration of the Teams service will be unavailable during this time. To restore service immediately you need to ' +
        (isRequirementChecker ? 'restart the integration and reauthorise the service.' : 'delete the tokens and reauthorise the service');
    }
    return 'The tokens used to authorise Callroute against Microsoft require re-authorisation. ' +
      (isRequirementChecker ? 'Please restart the integration.' : 'Please delete the tokens and reauthorise.');
  }

  static getTenantUniqueTooltip(): string {
    return 'The Microsoft tenant you are trying to add is already associated with another Callroute account, multiple associations are not permitted. ' +
      'Please restart the integration to reauthorise with a unique tenant.';
  }

  static getAdminRoleTooltip(): string {
    return 'The connected user needs to have global administrator or a combination of other administrator roles. ' +
      'More information can be found on our knowledge base.';
  }

  constructor(protected data?: MicrosoftTeams) {
    super();

    for (const check of enumToArray<ServiceHealthCheckName>(ServiceHealthCheckName)) {
      this.health.set(check, {
        status:        ServiceUIStatus.Pending,
        description:   '',
        tooltip:       '',
        action:        check === ServiceHealthCheckName.Auth ? ProvisionAction.Authorise : null,
        expiredTokens: null,
      });
    }

    for (const check of enumToArray<ServiceRequirementCheckName>(ServiceRequirementCheckName)) {
      this.requirements.set(check, {
        status:      ServiceUIStatus.Pending,
        description: '',
        tooltip:     '',
        action:      null,
      });
    }

    Object.assign(this, { ...(data || {}) });

    delete this['data'];
  }

  fromApiData?(apiData: MicrosoftTeamsRaw): MicrosoftTeams {
    super.fromApiData(apiData);

    this.capabilities     = apiData.capabilities;
    this.lastUserSyncDate = apiData.last_user_sync_date ? moment.utc(apiData.last_user_sync_date)
      .fromNow() : null;
    if (this.lastUserSyncDate === 'a few seconds ago') {
      this.lastUserSyncDate = 'just now';
    }
    if (apiData.last_user_sync_date) {
      const plus1Hour = moment.utc(apiData.last_user_sync_date)
        .add(moment.duration(60, 'minutes'));
      if (plus1Hour.isAfter(moment.utc()
        .subtract(moment.duration(60, 'minutes')))) {
        if (plus1Hour.isBefore(moment.utc())) {
          this.nextUserSyncDate = 'Due now';
        } else {
          this.nextUserSyncDate = plus1Hour.format();
        }
      }
    }
    this.isProcessing = apiData.is_processing;

    return this.setStatus(apiData)
      .resetRequirements();
  }

  private setStatus?(data: MicrosoftTeamsRaw): MicrosoftTeams {
    this.authoriseStatus = new StatusItem(ServiceUIStatus.Pending, ProvisionStep.Authorise, data.type);
    this.healthStatus    = new StatusItem(ServiceUIStatus.Pending, ProvisionStep.HealthCheck, data.type);

    let initialSyncStatus = this.lastUserSyncDate && StatusItem.isSuccess(this.provisionStatus.status) ?
      ServiceUIStatus.Success :
      ServiceUIStatus.Pending;

    if (!this.lastUserSyncDate && data.is_processing) {
      initialSyncStatus = ServiceUIStatus.Processing;
    }

    this.initialSyncStatus = new StatusItem(initialSyncStatus, ProvisionStep.Sync, data.type);
    return this;
  }

  isChecklistProcessing(): boolean {
    return [this.requirementStatus?.status, this.healthStatus?.status].includes(ServiceUIStatus.Processing);
  }

  isChecklistPending(): boolean {
    return (this.isActive() ? this.healthStatus?.status : this.requirementStatus?.status) === ServiceUIStatus.Pending;
  }

  getChecklistItems(expiredTokens: MicrosoftToken[]): HealthCheckItem[] {
    const checkList: [ServiceHealthCheckName | ServiceRequirementCheckName, HealthCheckItem | RequirementCheckItem][] = [];
    if (this.isProvisioning() || this.isChecklistPending() || this.isChecklistProcessing()) {
      return [];
    }

    if (this.isActive()) {
      checkList.push(...this.health.entries());
    } else {
      checkList.push(...this.requirements.entries());
    }

    return checkList.map(([name, values]: [ServiceHealthCheckName | ServiceRequirementCheckName, HealthCheckItem | RequirementCheckItem]) => ({
      name,
      status:           values.status,
      action:           values.action,
      showAuthProgress: values.showAuthProgress,
      description:      values.description,
      tooltip:          values.tooltip,
      expiredTokens,
    }));
  }

  isHealthy?(): boolean {
    if (!this?.healthStatus?.status) {
      return false;
    }
    return StatusItem.isSuccess(this.healthStatus.status);
  }

  isActive(): boolean {
    return [this.initialSyncStatus.status, this.provisionStatus.status].every(status => SUCCESS_STATUSES.includes(status));
  }

  isProvisioning?(): boolean {
    return [this.initialSyncStatus?.status, this.provisionStatus.status].some(status => PROCESSING_STATUSES.includes(status));
  }

  private resetRequirements?(): MicrosoftTeams {
    for (const check of (this.requirements?.entries() || [])) {
      const [key, val] = check;
      this.requirements.set(key, {
        status:      ServiceUIStatus.Pending,
        action:      val.action,
        isCopyable:  false,
        description: '',
        tooltip:     '',
      });
    }
    if (this.isProvisioning()) {
      this.requirementStatus = new StatusItem(ServiceUIStatus.Success, ProvisionStep.RequirementsCheck, ServiceType.MicrosoftTeams);
    } else {
      this.requirementStatus = new StatusItem(ServiceUIStatus.Pending, ProvisionStep.RequirementsCheck, ServiceType.MicrosoftTeams);
    }
    return this;
  }

  private getRequirementStatus?(): ServiceUIStatus {
    // if provisioning is processing OR provisioning was successful, but the user sync hasn't finished
    if (this.isProvisioning() || (StatusItem.isSuccess(this.provisionStatus.status) && !this.lastUserSyncDate)) {
      return ServiceUIStatus.Success;
    } else if (!this.requirements) {
      return ServiceUIStatus.Pending;
    } else if ([...this.requirements.values()].every(val => [ServiceUIStatus.Success, ServiceUIStatus.Warning].includes(val.status))) {
      return ServiceUIStatus.Success;
    }
    return ServiceUIStatus.Failed;
  }

  getSyncTooltip?(tokens: Token[], canWrite: boolean, syncTaskIncomplete?: boolean): string {
    if (this?.isProcessing || syncTaskIncomplete) {
      return 'Sync is in progress';
    }
    if (!canWrite) {
      return 'You do not have the required access to sync. Please contact your systems administrator for more information.';
    }
    if (!this.isHealthy()) {
      return 'Service is not healthy. Please see the health checker for more information.';
    }
    return MicrosoftTeamsUserFactoryService.authTooltip(tokens);
  }

  isSyncDisabled?(tokensPending: boolean, canWrite: boolean, syncTaskIncomplete?: boolean): boolean {
    return this.isProcessing || tokensPending || !this.isHealthy() || !canWrite || syncTaskIncomplete;
  }

  isCreatePersonaDisabled?(hasServiceWrite: boolean, policies: PolicyList): boolean {
    return !hasServiceWrite || !policies;
  }

  isCreateAutomationDisabled?(hasServiceWrite: boolean, schema: ServiceSchema): boolean {
    return !hasServiceWrite || !schema?.length;
  }

  getCreatePersonaTooltip?(hasWrite: boolean, policies: PolicyList): string {
    if (!hasWrite) {
      return 'You do not have the required access to create user personas. Please contact your systems administrator for more information.';
    }
    if (!policies) {
      return 'You must sync your policies before creating a user persona.';
    }
    return null;
  }

  getCreateAutomationTooltip?(hasServiceWrite: boolean, schema: ServiceSchema): string {
    if (!hasServiceWrite) {
      return 'You do not have the required access to create automations. Please contact your systems administrator for more information.';
    }
    if (!schema?.length) {
      return 'You must sync your users before creating an automation.';
    }
    return null;

  }

  updateRequirements?(requirementState: RequirementsState,
                      requirementChecks: RequirementInfo,
                      requirementsProcessing: boolean): MicrosoftTeams {
    if (requirementsProcessing) {
      this.requirementStatus = new StatusItem(ServiceUIStatus.Processing, ProvisionStep.RequirementsCheck, ServiceType.MicrosoftTeams);
      return this;
    }
    if (!requirementState || !this.requirements) {
      if (!requirementState) {
        return this.resetRequirements();
      }
      return this;
    }
    for (const key of (requirementState?.keys() || []) as ServiceRequirementCheckName[]) {
      if (this.isOrto && !this.ortoRequirementFields.includes(key)) {
        this.requirements.delete(key);
        continue;
      }
      let description;
      let tooltip;
      let isCopyable = false;
      let action: ProvisionAction;
      let status     = requirementState.get(key) ? ServiceUIStatus.Success : ServiceUIStatus.Failed;

      switch (key) {
        case ServiceRequirementCheckName.PhoneLicense:
          status      = status === ServiceUIStatus.Failed ? ServiceUIStatus.Warning : status;
          tooltip     = (StatusItem.isWarning(status) && MicrosoftTeams.getPhoneLicenseAdvisoryTooltip()) || '';
          description = '';
          break;
        case ServiceRequirementCheckName.UserLicense:
          status      = requirementChecks.hasUserLicenseWarning && requirementChecks.hasUserLicense ?
            ServiceUIStatus.Warning :
            status;
          tooltip     = (StatusItem.isWarning(status) && MicrosoftTeams.getLicenseTooltip<RequirementInfo>(requirementChecks, key)) || '';
          description = '';
          isCopyable  = true;
          if (StatusItem.isWarning(status)) {
            description = StatusItem.isFailed(status) ? 'Warning: You do not have an available Microsoft user license, ' +
              'please refer to our <a href="https://help.callroute.com/space/CKB/721125464/Microsoft+Licensing+Requirements" class="link">knowledge base article</a> for more details.' :
              'Warning: Your Microsoft trial licenses may have expired or you have other issues with your Microsoft licensing.';
          }
          break;
        case ServiceRequirementCheckName.HasAdminRole:
          tooltip     = (StatusItem.isWarning(status) && MicrosoftTeams.getAdminRoleTooltip()) || '';
          description = StatusItem.isWarning(status) ? `<p class="color-near-black">
      Learn more about administrator roles. 
      <a href="https://help.callroute.com/space/CKB/698843188/Pre-requisites" target="_blank">Visit our knowledge base.</a>
    </p>` : null;
          break;
        case ServiceRequirementCheckName.AccessTokenValidity:
          tooltip     = (StatusItem.isWarning(status) && MicrosoftTeams.getTokenValidityTooltip(requirementChecks.hasAdminRole, true, null, null)) || '';
          action      = ProvisionAction.Delete;
          description = '';
          break;
        case ServiceRequirementCheckName.TenantUnique:
          tooltip     = (StatusItem.isWarning(status) && MicrosoftTeams.getTenantUniqueTooltip()) || '';
          action      = ProvisionAction.Delete;
          description = '';
          break;
        default:
          tooltip     = '';
          description = '';
      }

      this.requirements.set(key, {
        status,
        action,
        isCopyable,
        description,
        tooltip,
      });
    }
    const requirementStatus = this.getRequirementStatus();
    this.requirementStatus  = new StatusItem(requirementStatus, ProvisionStep.RequirementsCheck, ServiceType.MicrosoftTeams);
    return this;
  }

  updateAuthStatus?(tokens: Token[]): MicrosoftTeams {
    let authFailed;
    if (this.isActive()) {
      authFailed = tokens?.some(token => token?.status === TokenStatus.Error);
    } else {
      authFailed = tokens?.find(token => token?.id === MicrosoftToken.MicrosoftAzure)?.status === TokenStatus.Error;
    }
    if (authFailed) {
      this.authoriseStatus = new StatusItem(ServiceUIStatus.Failed, ProvisionStep.Authorise, ServiceType.MicrosoftTeams);
      return this;
    }
    const initialAuthPassed = this.getInitialAuthPassed(tokens);

    const authChecked = tokens?.every(token => token?.status !== TokenStatus.Pending);
    if (authChecked) {
      this.authoriseStatus = initialAuthPassed ?
        new StatusItem(ServiceUIStatus.Success, ProvisionStep.Authorise, ServiceType.MicrosoftTeams) :
        new StatusItem(ServiceUIStatus.NeedsAction, ProvisionStep.Authorise, ServiceType.MicrosoftTeams);
    } else if (!initialAuthPassed) {
      this.authoriseStatus = new StatusItem(ServiceUIStatus.Pending, ProvisionStep.Authorise, ServiceType.MicrosoftTeams);
    }

    return this;
  }

  getInitialAuthPassed?(tokens: Token[]): boolean {
    let initialAuthPassed = tokens?.every(token => token?.status === TokenStatus.Active);
    const hasAzureAuth    = tokens?.find(token => token?.id === MicrosoftToken.MicrosoftAzure)?.status === TokenStatus.Active;

    const isHealthChecker = this.isActive();
    if (!isHealthChecker && hasAzureAuth) {
      initialAuthPassed = true; // if configuration mode + has AZURE token, then LYNC auth will be part of configure step
    }
    return initialAuthPassed;
  }

  toApiData?(): MicrosoftTeamsRaw {
    return {
      label: this.label,
    } as MicrosoftTeamsRaw;
  }

  updateProvisioningStatus?(lyncAuthStatus: TokenStatus, errorMessage: string, step: ProvisionStep, isCurrentStep: boolean): MicrosoftTeams {
    if ((this.isActive() || StatusItem.isDeleted(this.provisionStatus.status)) && !errorMessage) {
      return this;
    }

    if (StatusItem.isWarning(lyncAuthStatus)) {
      this.provisionStatus             = new StatusItem(ServiceUIStatus.Failed, step, ServiceType.MicrosoftTeams);
      this.provisionStatus.description = 'Reauthorisation is required to configure the service';
      return this;
    }

    if (errorMessage) {
      if (errorMessage.includes('Please ensure you sign in with the same Microsoft account')) {
        this.provisionStatus             = new StatusItem(ServiceUIStatus.Failed, step, ServiceType.MicrosoftTeams);
        this.provisionStatus.description = errorMessage;
      } else {
        this.provisionStatus             = new StatusItem(ServiceUIStatus.Failed, step, ServiceType.MicrosoftTeams);
        this.provisionStatus.description = 'There was a problem configuring the service.';
      }
      return this;
    }

    if (!this.isActive()) {

      if (this.isProvisioning() || StatusItem.isProcessing(lyncAuthStatus)) {
        if (StatusItem.isProcessing(this.initialSyncStatus.status)) {
          return this;
        }
        this.provisionStatus             = new StatusItem(ServiceUIStatus.Processing, step, ServiceType.MicrosoftTeams);
        this.provisionStatus.description = '';
        return this;
      }

      if (!this.isProvisioning() && !StatusItem.isSuccess(lyncAuthStatus) && isCurrentStep) {
        this.provisionStatus             = new StatusItem(ServiceUIStatus.Pending, step, ServiceType.MicrosoftTeams);
        this.provisionStatus.description = 'Your Microsoft account is ready for integration. ' +
          'Please log in again to grant Callroute read/write access in order to configure the service.';
      }

    }
    return this;
  }

  updateHealth?(expiredTokens: MicrosoftToken[],
                healthState: HealthState,
                healthChecks: HealthInfo,
                lastTokenAuth: MicrosoftToken,
                healthProcessing: boolean): MicrosoftTeams {
    if (healthProcessing) {
      this.healthStatus = new StatusItem(ServiceUIStatus.Processing, ProvisionStep.HealthCheck, ServiceType.MicrosoftTeams);
      return this;
    }
    if (!healthState || !this.health) {
      if (!healthState) {
        return this.resetHealth(expiredTokens);
      }
      return this;
    }
    const needsAuth = !!expiredTokens.length;
    for (const [key, val] of (this.health?.entries() || [])) {
      if (this.isOrto && !this.ortoHealthFields.includes(key)) {
        this.health.delete(key);
        continue;
      }
      let description: string;
      let tooltip: string;
      let action: ProvisionAction = val.action;
      let isCopyable              = false;

      const status = MicrosoftTeams.determineCheckItemState(healthState, healthChecks, key, needsAuth, this.isProcessing, this.lastUserSyncDate);
      switch (key) {
        case ServiceHealthCheckName.Auth:
          if (status === ServiceUIStatus.NeedsAction) {
            description = `<a target="_blank" href="https://help.callroute.com/space/CKB/868581416/Callroute+Health+Checker+-+Reauthorisation+Request">
Learn more</a> about why you need to reauthorise with your Microsoft tenant.`;
          }
          break;
        case ServiceHealthCheckName.PhoneLicense:
          tooltip     = (StatusItem.isWarning(status) && MicrosoftTeams.getPhoneLicenseAdvisoryTooltip()) || '';
          description = '';
          break;
        case ServiceHealthCheckName.UserLicense:
          tooltip     = (StatusItem.isWarning(status) && MicrosoftTeams.getLicenseTooltip<HealthInfo>(healthChecks, key)) || '';
          isCopyable  = true;
          description = StatusItem.isWarning(status) && 'Warning: Your Microsoft trial licenses may have expired or you have other issues with your Microsoft licensing.' || '';
          break;
        case ServiceHealthCheckName.AccessTokenValidity:
          tooltip     = (StatusItem.isWarning(status) && MicrosoftTeams.getTokenValidityTooltip(
              healthChecks.hasAdminRole,
              false,
              healthChecks.tokenDataMatch,
              healthChecks.tokenDataMessage)
          ) || '';
          action      = ProvisionAction.Delete;
          description = '';
          break;
        case ServiceHealthCheckName.HasRecentSync:
          tooltip     = (StatusItem.isWarning(status) && healthChecks.syncWarning) || '';
          action      = ProvisionAction.Sync;
          description = `
<strong class="color-black">Last sync: ${ this.lastUserSyncDate }</strong>
<p class="mt-8">We recommend you sync against your Microsoft account in the following circumstances:</p>
<ul>
  <li class="mb-4">Users in your Microsoft tenant have been added or deleted</li>
  <li class="mb-4">Any changes to Microsoft licensing and/or association with users</li>
  <li class="mb-4">Any other Teams Admin related changes</li>
</ul>
`;
          break;
        case ServiceHealthCheckName.HasAdminRole:
          tooltip     = (StatusItem.isWarning(status) && MicrosoftTeams.getAdminRoleTooltip()) || '';
          description = StatusItem.isWarning(status) ? `<p class="color-near-black">
      Learn more about administrator roles. 
      <a href="https://help.callroute.com/space/CKB/698843188/Pre-requisites" target="_blank">Visit our knowledge base.</a>
    </p>` : null;
          break;
        default:
          description = val.description || '';
          tooltip     = val.tooltip || '';
      }
      this.health.set(key, {
        name:             val.name,
        action,
        description,
        tooltip,
        showAuthProgress: !!lastTokenAuth,
        isCopyable,
        expiredTokens,
        status,
      });
    }

    const healthStatus: ServiceUIStatus = this.determineHealthStatus();
    const warningsCount                 = [...this.health.values()]
      .filter(item => StatusItem.isWarning(item.status) && !StatusItem.isFailed(item.status))?.length;

    this.healthStatus = new StatusItem(
      healthStatus,
      ProvisionStep.HealthCheck,
      ServiceType.MicrosoftTeams,
      true,
      warningsCount);
    return this;
  }

  private determineHealthStatus?(): ServiceUIStatus {
    const healthValues = [...this.health.values()];
    if (healthValues.every(val => StatusItem.isSuccess(val.status) || (StatusItem.isWarning(val.status) && !StatusItem.isFailed(val.status)))) {
      return ServiceUIStatus.Success;
    }
    if (healthValues.some(val => [ServiceUIStatus.Failed].includes(val.status))) {
      return ServiceUIStatus.Failed;
    }
    return healthValues.some(val => [ServiceUIStatus.NeedsAction].includes(val.status)) ? ServiceUIStatus.NeedsAction : ServiceUIStatus.Processing;
  }

  private static determineCheckItemState(healthState: HealthState, healthChecks: HealthInfo, key: ServiceHealthCheckName, needsAuth: boolean, userSyncInProgress: boolean, lastSyncDate: string): ServiceUIStatus {
    const success = healthState.get(key);
    if (needsAuth && key !== ServiceHealthCheckName.Auth) {
      return ServiceUIStatus.Pending;
    }
    switch (key) {
      case ServiceHealthCheckName.Auth:
        return success ? ServiceUIStatus.Success : ServiceUIStatus.NeedsAction;
      case ServiceHealthCheckName.PhoneLicense:
        return success ? ServiceUIStatus.Success : ServiceUIStatus.Warning;
      case ServiceHealthCheckName.UserLicense:
        return healthChecks.hasUserLicenseWarning ? ServiceUIStatus.Warning : ServiceUIStatus.Success;
      case ServiceHealthCheckName.HasRecentSync:
        if (success || userSyncInProgress || lastSyncDate === 'just now') {
          return ServiceUIStatus.Success;
        }
        return ServiceUIStatus.Warning;
      default:
        return success ? ServiceUIStatus.Success : ServiceUIStatus.Failed;
    }
  }

  private resetHealth?(expiredTokens: MicrosoftToken[]): MicrosoftTeams {
    for (const check of (this.health?.entries() || [])) {
      const [key, val] = check;
      this.health.set(key, {
        status:           ServiceUIStatus.Pending,
        action:           val.action,
        description:      '',
        tooltip:          '',
        expiredTokens,
        showAuthProgress: false,
      });
    }
    this.healthStatus = new StatusItem(ServiceUIStatus.Pending, ProvisionStep.HealthCheck, ServiceType.MicrosoftTeams);

    return this;
  }


}
