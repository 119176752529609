import { ListResponseMetadata } from '../api/list-response-metadata.model';
import { ListParams }           from './list-params.model';
import { ListParamModel }       from './list-param-model';

export class AccountingQueryParams extends ListParams implements ListParamModel<AccountingQueryParams> {
  since?: string;
  until?: string;

  static constructQueryString(searchParams: AccountingQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      since: 'filter[date][since]',
      until: 'filter[date][until]',
    });
  }

  constructParams?(metaData: ListResponseMetadata): AccountingQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.date) {
        this.since = metaData.filter.date.since;
        this.until = metaData.filter.date.until;
      }
    }

    return this;
  }
}
