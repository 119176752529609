export const POST_COMPANY_REQUEST  = 'POST_COMPANY_REQUEST';
export const POST_COMPANY_RESPONSE = 'POST_COMPANY_RESPONSE';

export const PATCH_COMPANY_REQUEST  = 'PATCH_COMPANY_REQUEST';
export const PATCH_COMPANY_RESPONSE = 'PATCH_COMPANY_RESPONSE';

export const FETCH_COMPANY_REQUEST  = 'FETCH_COMPANY_REQUEST';
export const FETCH_COMPANY_RESPONSE = 'FETCH_COMPANY_RESPONSE';

export const FETCH_ROLE_USERS_REQUEST  = 'FETCH_ROLE_USERS_REQUEST';
export const FETCH_ROLE_USERS_RESPONSE = 'FETCH_ROLE_USERS_RESPONSE';

export const FETCH_COMPANY_USER_LIST_REQUEST  = 'FETCH_COMPANY_USER_LIST_REQUEST';
export const FETCH_COMPANY_USER_LIST_RESPONSE = 'FETCH_COMPANY_USER_LIST_RESPONSE';

export const POST_COMPANY_USER_REQUEST  = 'POST_COMPANY_USER_REQUEST';
export const POST_COMPANY_USER_RESPONSE = 'POST_COMPANY_USER_RESPONSE';

export const DELETE_USER_REQUEST  = 'DELETE_USER_REQUEST';
export const DELETE_USER_RESPONSE = 'DELETE_USER_RESPONSE';

export const CLEAR_COMPANY_ERROR_AND_MESSAGE = 'CLEAR_COMPANY_ERROR_AND_MESSAGE';

export const FETCH_COMPANY_SUBORDINATE_LIST_REQUEST  = 'FETCH_COMPANY_SUBORDINATE_LIST_REQUEST';
export const FETCH_COMPANY_SUBORDINATE_LIST_RESPONSE = 'FETCH_COMPANY_SUBORDINATE_LIST_RESPONSE';

export const FETCH_COMPANY_NAME_AVAILABILITY_REQUEST  = 'FETCH_COMPANY_NAME_AVAILABILITY_REQUEST';
export const FETCH_COMPANY_NAME_AVAILABILITY_RESPONSE = 'FETCH_COMPANY_NAME_AVAILABILITY_RESPONSE';

export const FETCH_COMPANY_USERS_COUNT_REQUEST  = 'FETCH_COMPANY_USERS_COUNT_REQUEST';
export const FETCH_COMPANY_USERS_COUNT_RESPONSE = 'FETCH_COMPANY_USERS_COUNT_RESPONSE';

export const FETCH_SECURITY_GROUP_USERS_LIST_REQUEST  = 'FETCH_SECURITY_GROUP_USERS_REQUEST';
export const FETCH_SECURITY_GROUP_USERS_LIST_RESPONSE = 'FETCH_SECURITY_GROUP_USERS_RESPONSE';
