import { NgModule }                 from '@angular/core';
import { AnonFooterComponent }      from '../login/anon-footer/anon-footer.component';
import { AnonPageComponent }        from '../layout/anon-page/anon-page.component';
import { RouterModule }             from '@angular/router';
import { CRFormsModule }            from '@cr-forms/cr-forms.module';
import { MatDividerModule }         from '@angular/material/divider';
import { VerifyAccountComponent }   from '@core/components/account/verify-account/verify-account.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRippleModule }          from '@angular/material/core';
import { NotificationSharedModule } from '../notification-shared/notification-shared.module';
import { ReactiveFormsModule }      from '@angular/forms';
import { FlexLayoutModule }         from '@angular/flex-layout';


@NgModule({
  declarations: [
    AnonFooterComponent,
    AnonPageComponent,
    VerifyAccountComponent,
  ],
  imports:      [
    RouterModule,
    FlexLayoutModule,
    CRFormsModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    NotificationSharedModule,
  ],
  exports:      [
    ReactiveFormsModule,
    AnonPageComponent,
    AnonFooterComponent,
    VerifyAccountComponent,
  ],
})
export class PublicSharedModule {}
