import { BaseRequest }               from '@redux/helpers/reducer.helper';
import { PostCompanyUserRequestRaw } from './post-company-user-request.raw';

export class PostCompanyUserRequest extends BaseRequest {
  email: string;
  firstName: string;
  lastName: string;
  roleId: string;

  static toApiData(req: PostCompanyUserRequest): PostCompanyUserRequestRaw {
    return {
      email:      req.email,
      first_name: req.firstName,
      last_name:  req.lastName,
      role_id:    req.roleId
    };
  }
}
