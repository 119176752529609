import { BaseRequest } from '@redux/helpers/reducer.helper';

export class SendCustomCarrierRequest extends BaseRequest {
  carrierName: string;
  countryCode: string;
  information: string;
  dataSharingConsent: boolean;

  constructor(private data?: SendCustomCarrierRequest) {
    super();
    Object.assign(this, { ...this.data });
    delete this['data'];
  }

  toApiData?(): {
    carrier_name: string;
    country_code: string;
    information: string;
    data_sharing_consent: boolean
  } {
    return {
      carrier_name:         this.carrierName,
      country_code:         this.countryCode,
      information:          this.information,
      data_sharing_consent: this.dataSharingConsent,
    };
  }
}
