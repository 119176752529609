import { StoreState }      from '../store';
import { AccountingState } from './accounting.reducer';
import { createSelector }  from '@ngrx/store';

export const selectAccounting = (state: StoreState): AccountingState => state.accountingReducer;

export const selectAccountingFeatures     = createSelector(selectAccounting, state => state.features);
export const selectLastPayments           = createSelector(selectAccounting, state => state.lastPaymentArray);
export const selectBalanceData            = createSelector(selectAccounting, state => state.balanceData);
export const selectAccountingPendingTasks = createSelector(selectAccounting, state => state.pendingTasks);
