import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationItem }                       from '@models/entity/notification.model';

@Component({
  selector:    'ngx-banner-notification',
  templateUrl: './banner-notification.component.html',
  styleUrls:   ['./banner-notification.component.scss'],
})
export class BannerNotificationComponent {
  @Input() pending: boolean;
  @Input() notification: NotificationItem;
  @Input() isViewingCompany: boolean;
  @Output() closeNotification = new EventEmitter<void>();

  constructor() {
  }

  handleCloseNotification(e: MouseEvent): void {
    e.stopPropagation();
    this.closeNotification.emit();
  }

}
