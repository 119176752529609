export const FETCH_AUDIT_LIST_REQUEST  = 'FETCH_AUDIT_LIST_REQUEST';
export const FETCH_AUDIT_LIST_RESPONSE = 'FETCH_AUDIT_LIST_RESPONSE';

export const FETCH_AUDIT_CONTEXT_LIST_REQUEST  = 'FETCH_AUDIT_CONTEXT_LIST_REQUEST';
export const FETCH_AUDIT_CONTEXT_LIST_RESPONSE = 'FETCH_AUDIT_CONTEXT_LIST_RESPONSE';

export const FETCH_AUDIT_OBJECT_LIST_REQUEST  = 'FETCH_AUDIT_OBJECT_LIST_REQUEST';
export const FETCH_AUDIT_OBJECT_LIST_RESPONSE = 'FETCH_AUDIT_OBJECT_LIST_RESPONSE';

export const FETCH_TASK_LIST_REQUEST  = 'FETCH_TASK_LIST_REQUEST';
export const FETCH_TASK_LIST_RESPONSE = 'FETCH_TASK_LIST_RESPONSE';

export const FETCH_TASK_REQUEST  = 'FETCH_TASK_REQUEST';
export const FETCH_TASK_RESPONSE = 'FETCH_TASK_RESPONSE';

export const FETCH_TASK_COUNT_REQUEST  = 'FETCH_TASK_COUNT_REQUEST';
export const FETCH_TASK_COUNT_RESPONSE = 'FETCH_TASK_COUNT_RESPONSE';

export const CANCEL_TASK_REQUEST  = 'CANCEL_TASK_REQUEST';
export const CANCEL_TASK_RESPONSE = 'CANCEL_TASK_RESPONSE';
