import { Injectable }                        from '@angular/core';
import { Actions, createEffect, ofType }     from '@ngrx/effects';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';

import { SubscriptionService } from '@services/subscription.service';

import * as ActionTypes from './subscription.actions';
import {
  CalculatePackageCostRequestAction,
  ChangePackageRequestAction,
  ChangePackageResponseAction,
  FetchActiveLicenseRequestAction,
  FetchActivePackageRequestAction, FetchPackageCapabilitiesRequestAction, FetchPackageCapabilitiesResponseAction,
  FetchPackageListRequestAction,
}                       from './subscription.actions';

import {
  CalculatePackageCostRequest,
  CalculatePackageCostResponse,
  ChangePackageRequest,
  ChangePackageResponse,
  FetchActivePackageResponse,
  FetchPackageListResponse,
}                                             from '@models/entity/subscription.model';
import { withThrottle }                       from '@rxjs/action-throttle.operator';
import { StoreState }                         from '@redux/store';
import { Store }                              from '@ngrx/store';
import { FetchFeatureInventoryRequestAction } from '@redux/accounting/accounting.actions';
import { FetchActiveLicenseResponse }         from '@models/api/fetch-active-license-response.model';
import { FetchPackageCapabilitiesResponse }   from '@models/api/fetch-package-capabilities-response.model';
import { withScopes }                         from '@rxjs/with-scopes.operator';
import { selectUserScopes }                   from '@redux/auth/auth.selectors';
import { AuthScope }                          from '@enums/auth-scope.enum';

@Injectable()
export class SubscriptionEffects {
  constructor(
    private actions$: Actions,
    private subscriptionService: SubscriptionService,
    private store: Store<StoreState>,
  ) {}

  fetchActivePackage$ = createEffect(() => this.actions$.pipe(
    ofType(FetchActivePackageRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyRead]),
    withThrottle(),
    switchMap(() =>
      this.subscriptionService.fetchActivePackage$()
        .pipe(
          map((res: FetchActivePackageResponse) => {
            return ActionTypes.FetchActivePackageResponseAction(res);
          }),
        ),
    ),
  ));

  fetchPackageCapabilities$ = createEffect(() => this.actions$.pipe(
    ofType(FetchPackageCapabilitiesRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyRead]),
    withThrottle(),
    switchMap(() =>
      this.subscriptionService.fetchPackageCapabilities$(),
    ),
    map((res: FetchPackageCapabilitiesResponse) => FetchPackageCapabilitiesResponseAction(res)),
  ));

  fetchActiveLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(FetchActiveLicenseRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyRead]),
    withThrottle(),
    switchMap(() => this.subscriptionService.fetchActiveLicenses$()),
    map((res: FetchActiveLicenseResponse) => {
      return ActionTypes.FetchActiveLicenseResponseAction(res);
    }),
  ));

  calculatePackageCost$ = createEffect(() => this.actions$.pipe(
    ofType(CalculatePackageCostRequestAction),
    withThrottle(),
    switchMap((req: CalculatePackageCostRequest) =>
      this.subscriptionService.calculatePackageChangeCost$(req)
        .pipe(
          map((res: CalculatePackageCostResponse) => {
            return ActionTypes.CalculatePackageCostResponseAction(res);
          }),
        ),
    ),
  ));

  fetchPackageList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchPackageListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyRead]),
    withThrottle(),
    switchMap(() =>
      this.subscriptionService.fetchPackageList$()
        .pipe(
          map((res: FetchPackageListResponse) => ActionTypes.FetchPackageListResponseAction(res)),
        ),
    ),
  ));

  changePackage$ = createEffect(() => this.actions$.pipe(
    ofType(ChangePackageRequestAction),
    switchMap((req: ChangePackageRequest) =>
      this.subscriptionService.changePackage(req)
        .pipe(
          map((res: ChangePackageResponse) => ActionTypes.ChangePackageResponseAction(res)),
        ),
    ),
  ));

  refetchActivePackage$ = createEffect(() => this.actions$.pipe(
    ofType(ChangePackageResponseAction),
    debounceTime(2_000),
    tap(res => {
      if (res.error) {
        return;
      }
      this.store.dispatch(FetchActivePackageRequestAction({}));
      this.store.dispatch(FetchFeatureInventoryRequestAction({}));
      this.store.dispatch(FetchPackageCapabilitiesRequestAction({}));
    }),
  ), { dispatch: false });
}
