<ngx-modal-head header="Configure a custom carrier"
                subheader="Please provide us with as much detail as possible about the SIP trunk you are looking to connect."
                headerIcon="{{customIcon}}">
</ngx-modal-head>
<ngx-modal-content [background]="true">
  <form [formGroup]="formGroup" [ngClass.gt-sm]="'w-60 m-0-auto'" *ngIf="!(submitted$ | async); else hasSubmitted">
    <mat-form-field class="w-100 mb-16 mt-16" appearance="outline">
      <mat-label class="field-label">Carrier name</mat-label>
      <input matInput type="text"
             [maxLength]="64"
             formControlName="carrierName"
             data-cy="form-name" />
    </mat-form-field>
    <mat-form-field class="w-100 mb-8" appearance="outline">
      <mat-label class="field-label">Carrier country</mat-label>
      <input matInput type="text"
             formControlName="country"
             [maxLength]="64"
             (focus)="inputAutoComplete.openPanel()"
             data-cy="form-country"
             autocomplete="country"
             [matAutocomplete]="country" />
      <mat-autocomplete autoActiveFirstOption #country="matAutocomplete" (optionSelected)="setCountryCode($event)">
        <mat-option *ngFor="let country of filteredCountries"
                    [value]="country.name">
          {{country.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <ngx-field-notification [type]="FieldCheckType.Invalid"
                            class="mb-16"
                            message="Please select a country from the list"
                            *ngIf="formGroup.get('country').touched && formGroup.get('countryCode').invalid">
    </ngx-field-notification>
    <mat-form-field class="w-100 mb-16" appearance="outline">
      <mat-label class="field-label">Further information</mat-label>
      <textarea matInput
                type="text"
                formControlName="information"
                data-cy="form-information"></textarea>
    </mat-form-field>
    <mat-checkbox formControlName="dataSharingConsent"
                  data-cy="form-consent"
                  class="mar-right-16 mb-16"
                  color="primary">
      I give consent to share my information with the relevant carrier
    </mat-checkbox>
  </form>
  <ng-template #hasSubmitted>
    <div fxLayout="column" fxLayoutAlign="start center" class="carrier-information">
      <mat-icon svgIcon="cr-tick-circle" class="done-icon color-green"></mat-icon>
      <h4 class="mat-subheading-2">Custom carrier request</h4>
      <p class="text-align-center mat-body-1">
        Thank you. We have received your request for a custom BYOC connection. Our engineers will review the
        request and will be in touch with you soon.
      </p>
    </div>
  </ng-template>
</ngx-modal-content>
<ngx-modal-actions>
  <ng-container *ngIf="(submitted$ | async); else notSubmitted">
    <ngx-cr-button text="Close" (onClick)="close()"></ngx-cr-button>
  </ng-container>
  <ng-template #notSubmitted>
    <ngx-cr-button text="Submit" (onClick)="submit()" [loading]="submitPending$ | async"
                   [disabled]="formGroup.invalid"></ngx-cr-button>
  </ng-template>
</ngx-modal-actions>
