import { createAction, props }                       from '@ngrx/store';
import {
  ACCEPT_TERMS_CONDITIONS_REQUEST,
  ACCEPT_TERMS_CONDITIONS_RESPONSE,
  FETCH_CALL_RECORDING_ALLOWED_REQUEST,
  FETCH_CALL_RECORDING_ALLOWED_RESPONSE,
  FETCH_CALL_RECORDING_ANALYSIS_ENABLED_REQUEST,
  FETCH_CALL_RECORDING_ANALYSIS_ENABLED_RESPONSE,
  FETCH_CALL_RECORDING_ENABLED_REQUEST,
  FETCH_CALL_RECORDING_ENABLED_RESPONSE,
  FETCH_CARRIERS_CONFIG_REQUEST,
  FETCH_CARRIERS_CONFIG_RESPONSE,
  FETCH_CURRENCY_CODE_REQUEST,
  FETCH_CURRENCY_CODE_RESPONSE,
  FETCH_CUSTOMER_COSTS_TOGGLE_REQUEST,
  FETCH_CUSTOMER_COSTS_TOGGLE_RESPONSE,
  FETCH_DEMO_MODE_REQUEST,
  FETCH_DEMO_MODE_RESPONSE,
  FETCH_GLOBAL_UI_BANNER_REQUEST,
  FETCH_GLOBAL_UI_BANNER_RESPONSE,
  FETCH_GLOBAL_UI_BANNER_TOGGLE_REQUEST,
  FETCH_GLOBAL_UI_BANNER_TOGGLE_RESPONSE,
  FETCH_LOW_BALANCE_THRESHOLD_DEFAULT_REQUEST,
  FETCH_LOW_BALANCE_THRESHOLD_DEFAULT_RESPONSE,
  FETCH_LOW_BALANCE_THRESHOLD_REQUEST,
  FETCH_LOW_BALANCE_THRESHOLD_RESPONSE,
  FETCH_NUMBER_REPORTS_TOGGLE_REQUEST,
  FETCH_NUMBER_REPORTS_TOGGLE_RESPONSE,
  FETCH_NUMBER_TOMBSTONE_DURATION_REQUEST,
  FETCH_NUMBER_TOMBSTONE_DURATION_RESPONSE,
  FETCH_PENDING_TERMS_CONDITIONS_REQUEST,
  FETCH_PENDING_TERMS_CONDITIONS_RESPONSE,
  FETCH_RANGE_USAGE_THRESHOLD_REQUEST,
  FETCH_RANGE_USAGE_THRESHOLD_RESPONSE,
  FETCH_RANGE_USAGE_TOGGLE_REQUEST,
  FETCH_RANGE_USAGE_TOGGLE_RESPONSE,
  FETCH_REPORTING_CONFIG_REQUEST,
  FETCH_REPORTING_CONFIG_RESPONSE,
  FETCH_SERVICES_CONFIG_REQUEST,
  FETCH_SERVICES_CONFIG_RESPONSE,
  FETCH_SSO_ALLOW_IDENTITY_CREATION_REQUEST,
  FETCH_SSO_ALLOW_IDENTITY_CREATION_RESPONSE,
  FETCH_SSO_ALLOW_PASSWORD_RESET_REQUEST,
  FETCH_SSO_ALLOW_PASSWORD_RESET_RESPONSE,
  FETCH_TEAMS_SETTINGS_REQUEST,
  FETCH_TEAMS_SETTINGS_RESPONSE,
  FETCH_TOPUP_ENABLED_REQUEST,
  FETCH_TOPUP_ENABLED_RESPONSE,
  FETCH_TRIAL_IDENTIFIER_REQUEST,
  FETCH_TRIAL_IDENTIFIER_RESPONSE,
  FETCH_USER_SYNC_CRON_ENABLED_REQUEST,
  FETCH_USER_SYNC_CRON_ENABLED_RESPONSE,
  FETCH_USER_SYNC_VERSION_REQUEST,
  FETCH_USER_SYNC_VERSION_RESPONSE,
  SET_CARRIERS_CONFIG_REQUEST,
  SET_CARRIERS_CONFIG_RESPONSE,
  SET_CUSTOMER_COSTS_TOGGLE_REQUEST,
  SET_CUSTOMER_COSTS_TOGGLE_RESPONSE,
  SET_DEMO_MODE_REQUEST,
  SET_DEMO_MODE_RESPONSE,
  SET_GLOBAL_UI_BANNER_REQUEST,
  SET_GLOBAL_UI_BANNER_RESPONSE,
  SET_GLOBAL_UI_BANNER_TOGGLE_REQUEST,
  SET_GLOBAL_UI_BANNER_TOGGLE_RESPONSE,
  SET_LOW_BALANCE_THRESHOLD_REQUEST,
  SET_LOW_BALANCE_THRESHOLD_RESPONSE,
  SET_MICROSOFT_TEAMS_SETTINGS_REQUEST,
  SET_MICROSOFT_TEAMS_SETTINGS_RESPONSE,
  SET_NUMBER_TOMBSTONE_DURATION_REQUEST,
  SET_NUMBER_TOMBSTONE_DURATION_RESPONSE,
  SET_RANGE_USAGE_THRESHOLD_REQUEST,
  SET_RANGE_USAGE_THRESHOLD_RESPONSE,
  SET_RANGE_USAGE_TOGGLE_REQUEST,
  SET_RANGE_USAGE_TOGGLE_RESPONSE,
  SET_REPORTING_CONFIG_REQUEST,
  SET_REPORTING_CONFIG_RESPONSE,
  SET_SERVICES_CONFIG_REQUEST,
  SET_SERVICES_CONFIG_RESPONSE,
  SET_SSO_ALLOW_IDENTITY_CREATION_REQUEST,
  SET_SSO_ALLOW_IDENTITY_CREATION_RESPONSE,
  SET_SSO_ALLOW_PASSWORD_RESET_REQUEST,
  SET_SSO_ALLOW_PASSWORD_RESET_RESPONSE,
  TOGGLE_CALL_RECORDING_ANALYSIS_REQUEST,
  TOGGLE_CALL_RECORDING_ANALYSIS_RESPONSE,
  TOGGLE_CALL_RECORDING_REQUEST,
  TOGGLE_CALL_RECORDING_RESPONSE,
}                                                    from './configuration.types';
import { GetPendingTermsConditionsResponse }         from '@models/api/get-pending-terms-conditions-response.model';
import { AcceptTermsConditionsReq }                  from '@models/api/accept-terms-conditions-req.model';
import { FetchTopupEnabledRequest }                  from '@models/api/fetch-topup-enabled-request.model';
import { FetchCurrencyCodeRequest }                  from '@models/api/fetch-currency-code-request.model';
import { FetchCurrencyCodeResponse }                 from '@models/api/fetch-currency-code-response.model';
import { FetchTopupEnabledResponse }                 from '@models/api/fetch-topup-enabled-response.model';
import { ToggleCallRecordingRequest }                from '@models/api/toggle-call-recording-request.model';
import { FetchCallRecordingEnabledRequest }          from '@models/api/fetch-call-recording-enabled-request.model';
import { ToggleCallRecordingResponse }               from '@models/api/toggle-call-recording-response.model';
import { FetchCallRecordingAllowedResponse }         from '@models/api/fetch-call-recording-allowed-response.model';
import { FetchCallRecordingAllowedRequest }          from '@models/api/fetch-call-recording-allowed-request.model';
import { FetchCallRecordingEnabledResponse }         from '@models/api/fetch-call-recording-enabled-response.model';
import { FetchLowBalanceThresholdResponse }          from '@models/api/fetch-low-balance-threshold-response.model';
import { SetLowBalanceThresholdRequest }             from '@models/api/set-low-balance-threshold-request.model';
import { SetLowBalanceThresholdResponse }            from '@models/api/set-low-balance-threshold-response.model';
import { FetchLowBalanceThresholdRequest }           from '@models/api/fetch-low-balance-threshold-request.model';
import {
  FetchLowBalanceThresholdDefaultResponse,
}                                                    from '@models/api/fetch-low-balance-threshold-default-response.model';
import { SetMicrosoftTeamsSettingsRequest }          from '@models/api/set-microsoft-teams-settings-request.model';
import { SetMicrosoftTeamsSettingsResponse }         from '@models/api/set-microsoft-teams-settings-response.model';
import { FetchMicrosoftTeamsSettingsRequest }        from '@models/api/fetch-microsoft-teams-settings-request.model';
import { FetchMicrosoftTeamsSettingsResponse }       from '@models/api/fetch-sync-settings-response.model';
import { SetReportingConfigRequest }                 from '@models/api/set-reporting-config-request.model';
import { SetReportingConfigResponse }                from '@models/api/set-reporting-config-response.model';
import { FetchReportingConfigRequest }               from '@models/api/fetch-reporting-config-request.model';
import { FetchReportingConfigResponse }              from '@models/api/fetch-reporting-config-response.model';
import { BaseRequest }                               from '@redux/helpers/reducer.helper';
import { FetchDemoModeRequest }                      from '@models/api/fetch-demo-mode-request.model';
import { FetchDemoModeResponse }                     from '@models/api/fetch-demo-mode-response.model';
import { SetDemoModeRequest }                        from '@models/api/set-demo-mode-request.model';
import { SetDemoModeResponse }                       from '@models/api/set-demo-mode-response.model';
import { FetchCarriersConfigRequest }                from '@models/api/fetch-carriers-config-request.model';
import { FetchCarriersConfigResponse }               from '@models/api/fetch-carriers-config-response.model';
import { SetCarriersConfigRequest }                  from '@models/api/set-carriers-config-request.model';
import { SetCarriersConfigResponse }                 from '@models/api/set-carriers-config-response.model';
import { FetchServiceConfigRequest }                 from '@models/api/fetch-service-config-request.model';
import { FetchServiceConfigResponse }                from '@models/api/fetch-service-config-response.model';
import { SetServiceConfigRequest }                   from '@models/api/set-service-config-request.model';
import { SetServiceConfigResponse }                  from '@models/api/set-service-config-response.model';
import { FetchTrialLicenseIdentifierRequest }        from '@models/api/fetch-trial-license-identifier-request.model';
import { FetchTrialLicenseIdentifierResponse }       from '@models/api/fetch-trial-license-identifier-response.model';
import {
  FetchCallRecordingAnalysisEnabledRequest,
}                                                    from '@models/api/fetch-call-recording-analysis-enabled-request.model';
import { FetchCallRecordingAnalysisEnabledResponse } from '@models/api/fetch-call-recording-analysis-response.model';
import { ToggleCallRecordingAnalysisResponse }       from '@models/api/toggle-call-recording-analysis-response.model';
import { ToggleCallRecordingAnalysisRequest }        from '@models/api/toggle-call-recording-analysis-request.model';
import { FetchUserSyncVersionRequest }               from '@models/api/fetch-user-sync-version-request.model';
import { FetchUserSyncVersionResponse }              from '@models/api/fetch-user-sync-version-response.model';
import {
  FetchUserSyncCronEnabledRequest,
}                                                    from '@models/api/configuration/fetch-user-sync-cron-enabled-request.model';
import {
  FetchUserSyncCronEnabledResponse,
}                                                    from '@models/api/configuration/fetch-user-sync-cron-enabled-response.model';
import {
  SetNumberTombstoneDurationRequest,
}                                                    from '@models/api/configuration/set-number-tombstone-duration-request.model';
import {
  SetNumberTombstoneDurationResponse,
}                                                    from '@models/api/configuration/set-number-tombstone-duration-response.model';
import {
  FetchNumberTombstoneDurationResponse,
}                                                    from '@models/api/configuration/fetch-number-tombstone-duration-response.model';
import {
  FetchNumberTombstoneDurationRequest,
}                                                    from '@models/api/configuration/fetch-number-tombstone-duration-request.model';
import {
  SetSSOIdentityCreationRequest,
}                                                    from '@models/api/configuration/set-sso-identity-creation-request.model';
import {
  SetSSOIdentityCreationResponse,
}                                                    from '@models/api/configuration/set-sso-identity-creation-response.model';
import {
  FetchSSOIdentityCreationRequest,
}                                                    from '@models/api/configuration/fetch-sso-identity-creation-request.model';
import {
  FetchSSOIdentityCreationResponse,
}                                                    from '@models/api/configuration/fetch-sso-identity-creation-response.model';
import {
  SetSSOAllowPasswordResetRequest,
}                                                    from '@models/api/configuration/set-sso-allow-password-reset-request.model';
import {
  SetSSOAllowPasswordResetResponse,
}                                                    from '@models/api/configuration/set-sso-allow-password-reset-response.model';
import {
  FetchSSOAllowPasswordResetRequest,
}                                                    from '@models/api/configuration/fetch-sso-allow-password-reset-request.model';
import {
  FetchSSOAllowPasswordResetResponse,
}                                                    from '@models/api/configuration/fetch-sso-allow-password-reset-response.model';
import {
  FetchRangeUsageThresholdRequest,
}                                                    from '@models/api/configuration/fetch-range-usage-threshold-request.model';
import {
  FetchRangeUsageThresholdResponse,
}                                                    from '@models/api/configuration/fetch-range-usage-threshold-response.model';
import {
  SetRangeUsageThresholdRequest,
}                                                    from '@models/api/configuration/set-range-usage-threshold-request.model';
import {
  SetRangeUsageThresholdResponse,
}                                                    from '@models/api/configuration/set-range-usage-threshold-response.model';
import {
  SetCustomerCostsToggleRequest,
}                                                    from '@models/api/configuration/set-customer-costs-toggle-request.model';
import {
  SetCustomerCostsToggleResponse,
}                                                    from '@models/api/configuration/set-customer-costs-toggle-response.model';
import {
  FetchCustomerCostsToggleRequest,
}                                                    from '@models/api/configuration/fetch-customer-costs-toggle-request.model';
import {
  FetchCustomerCostsToggleResponse,
}                                                    from '@models/api/configuration/fetch-customer-costs-toggle-response.model';
import {
  FetchNumberReportsToggleRequest,
}                                                    from '@models/api/configuration/fetch-number-reports-toggle-request.model';
import {
  FetchNumberReportsToggleResponse,
}                                                    from '@models/api/configuration/fetch-number-reports-toggle-response.model';
import {
  FetchRangeUsageToggleResponse,
}                                                    from '@models/api/configuration/fetch-range-usage-toggle-response.model';
import {
  SetRangeUsageToggleRequest,
}                                                    from '@models/api/configuration/set-range-usage-toggle-request.model';
import {
  SetRangeUsageToggleResponse,
}                                                    from '@models/api/configuration/set-range-usage-toggle-response.model';
import {
  FetchGlobalUIBannerRequest,
}                                                    from '@models/api/configuration/fetch-global-ui-banner-request.model';
import {
  FetchGlobalUIBannerResponse,
}                                                    from '@models/api/configuration/fetch-global-ui-banner-response.model';
import {
  SetGlobalUIBannerRequest,
}                                                    from '@models/api/configuration/set-global-ui-banner-request.model';
import {
  SetGlobalUIBannerResponse,
}                                                    from '@models/api/configuration/set-global-ui-banner-response.model';
import {
  FetchGlobalUIBannerToggleRequest,
}                                                    from '@models/api/configuration/fetch-global-ui-banner-toggle-request.model';
import {
  FetchGlobalUIBannerToggleResponse,
}                                                    from '@models/api/configuration/fetch-global-ui-banner-toggle-response.model';
import {
  SetGlobalUIBannerToggleRequest,
}                                                    from '@models/api/configuration/set-global-ui-banner-toggle-request.model';
import {
  SetGlobalUIBannerToggleResponse,
}                                                    from '@models/api/configuration/set-global-ui-banner-toggle-response.model';
import {
  FetchRangeUsageToggleRequest,
}                                                    from '@models/api/configuration/fetch-range-usage-toggle-request.model';

export const FetchCurrencyCodeRequestAction  = createAction(FETCH_CURRENCY_CODE_REQUEST, props<FetchCurrencyCodeRequest>());
export const FetchCurrencyCodeResponseAction = createAction(FETCH_CURRENCY_CODE_RESPONSE, props<FetchCurrencyCodeResponse>());

export const FetchTrialLicenseIdentifierRequestAction  = createAction(FETCH_TRIAL_IDENTIFIER_REQUEST, props<FetchTrialLicenseIdentifierRequest>());
export const FetchTrialLicenseIdentifierResponseAction = createAction(FETCH_TRIAL_IDENTIFIER_RESPONSE, props<FetchTrialLicenseIdentifierResponse>());

export const FetchTopUpEnabledRequestAction  = createAction(FETCH_TOPUP_ENABLED_REQUEST, props<FetchTopupEnabledRequest>());
export const FetchTopUpEnabledResponseAction = createAction(FETCH_TOPUP_ENABLED_RESPONSE, props<FetchTopupEnabledResponse>());

export const FetchCallRecordingAllowedRequestAction  = createAction(FETCH_CALL_RECORDING_ALLOWED_REQUEST, props<FetchCallRecordingAllowedRequest>());
export const FetchCallRecordingAllowedResponseAction = createAction(FETCH_CALL_RECORDING_ALLOWED_RESPONSE, props<FetchCallRecordingAllowedResponse>());

export const FetchCallRecordingEnabledRequestAction  = createAction(FETCH_CALL_RECORDING_ENABLED_REQUEST, props<FetchCallRecordingEnabledRequest>());
export const FetchCallRecordingEnabledResponseAction = createAction(FETCH_CALL_RECORDING_ENABLED_RESPONSE, props<FetchCallRecordingEnabledResponse>());

export const FetchCallRecordingAnalysisEnabledRequestAction  = createAction(FETCH_CALL_RECORDING_ANALYSIS_ENABLED_REQUEST, props<FetchCallRecordingAnalysisEnabledRequest>());
export const FetchCallRecordingAnalysisEnabledResponseAction = createAction(FETCH_CALL_RECORDING_ANALYSIS_ENABLED_RESPONSE, props<FetchCallRecordingAnalysisEnabledResponse>());

export const ToggleCallRecordingRequestAction  = createAction(TOGGLE_CALL_RECORDING_REQUEST, props<ToggleCallRecordingRequest>());
export const ToggleCallRecordingResponseAction = createAction(TOGGLE_CALL_RECORDING_RESPONSE, props<ToggleCallRecordingResponse>());

export const ToggleCallRecordingAnalysisRequestAction  = createAction(TOGGLE_CALL_RECORDING_ANALYSIS_REQUEST, props<ToggleCallRecordingAnalysisRequest>());
export const ToggleCallRecordingAnalysisResponseAction = createAction(TOGGLE_CALL_RECORDING_ANALYSIS_RESPONSE, props<ToggleCallRecordingAnalysisResponse>());

export const FetchLowBalanceThresholdDefaultRequestAction  = createAction(FETCH_LOW_BALANCE_THRESHOLD_DEFAULT_REQUEST, props<BaseRequest>());
export const FetchLowBalanceThresholdDefaultResponseAction = createAction(FETCH_LOW_BALANCE_THRESHOLD_DEFAULT_RESPONSE, props<FetchLowBalanceThresholdDefaultResponse>());

export const FetchLowBalanceThresholdRequestAction  = createAction(FETCH_LOW_BALANCE_THRESHOLD_REQUEST, props<FetchLowBalanceThresholdRequest>());
export const FetchLowBalanceThresholdResponseAction = createAction(FETCH_LOW_BALANCE_THRESHOLD_RESPONSE, props<FetchLowBalanceThresholdResponse>());

export const SetLowBalanceThresholdRequestAction  = createAction(SET_LOW_BALANCE_THRESHOLD_REQUEST, props<SetLowBalanceThresholdRequest>());
export const SetLowBalanceThresholdResponseAction = createAction(SET_LOW_BALANCE_THRESHOLD_RESPONSE, props<SetLowBalanceThresholdResponse>());

export const FetchDemoModeRequestAction  = createAction(FETCH_DEMO_MODE_REQUEST, props<FetchDemoModeRequest>());
export const FetchDemoModeResponseAction = createAction(FETCH_DEMO_MODE_RESPONSE, props<FetchDemoModeResponse>());

export const SetDemoModeRequestAction  = createAction(SET_DEMO_MODE_REQUEST, props<SetDemoModeRequest>());
export const SetDemoModeResponseAction = createAction(SET_DEMO_MODE_RESPONSE, props<SetDemoModeResponse>());

export const FetchPendingTermsConditionsRequestAction  = createAction(FETCH_PENDING_TERMS_CONDITIONS_REQUEST);
export const FetchPendingTermsConditionsResponseAction = createAction(FETCH_PENDING_TERMS_CONDITIONS_RESPONSE, props<GetPendingTermsConditionsResponse>());

export const AcceptTermsConditionsRequestAction  = createAction(ACCEPT_TERMS_CONDITIONS_REQUEST, props<AcceptTermsConditionsReq>());
export const AcceptTermsConditionsResponseAction = createAction(ACCEPT_TERMS_CONDITIONS_RESPONSE);

export const FetchTeamsSettingsRequestAction  = createAction(FETCH_TEAMS_SETTINGS_REQUEST, props<FetchMicrosoftTeamsSettingsRequest>());
export const FetchTeamsSettingsResponseAction = createAction(FETCH_TEAMS_SETTINGS_RESPONSE, props<FetchMicrosoftTeamsSettingsResponse>());

export const SetMicrosoftTeamsSettingsRequestAction  = createAction(SET_MICROSOFT_TEAMS_SETTINGS_REQUEST, props<SetMicrosoftTeamsSettingsRequest>());
export const SetMicrosoftTeamsSettingsResponseAction = createAction(SET_MICROSOFT_TEAMS_SETTINGS_RESPONSE, props<SetMicrosoftTeamsSettingsResponse>());

export const SetReportingConfigRequestAction  = createAction(SET_REPORTING_CONFIG_REQUEST, props<SetReportingConfigRequest>());
export const SetReportingConfigResponseAction = createAction(SET_REPORTING_CONFIG_RESPONSE, props<SetReportingConfigResponse>());

export const FetchReportingConfigRequestAction  = createAction(FETCH_REPORTING_CONFIG_REQUEST, props<FetchReportingConfigRequest>());
export const FetchReportingConfigResponseAction = createAction(FETCH_REPORTING_CONFIG_RESPONSE, props<FetchReportingConfigResponse>());

export const FetchCarriersConfigRequestAction  = createAction(FETCH_CARRIERS_CONFIG_REQUEST, props<FetchCarriersConfigRequest>());
export const FetchCarriersConfigResponseAction = createAction(FETCH_CARRIERS_CONFIG_RESPONSE, props<FetchCarriersConfigResponse>());

export const SetCarriersConfigRequestAction  = createAction(SET_CARRIERS_CONFIG_REQUEST, props<SetCarriersConfigRequest>());
export const SetCarriersConfigResponseAction = createAction(SET_CARRIERS_CONFIG_RESPONSE, props<SetCarriersConfigResponse>());

export const FetchServicesConfigRequestAction  = createAction(FETCH_SERVICES_CONFIG_REQUEST, props<FetchServiceConfigRequest>());
export const FetchServicesConfigResponseAction = createAction(FETCH_SERVICES_CONFIG_RESPONSE, props<FetchServiceConfigResponse>());

export const SetServicesConfigRequestAction  = createAction(SET_SERVICES_CONFIG_REQUEST, props<SetServiceConfigRequest>());
export const SetServicesConfigResponseAction = createAction(SET_SERVICES_CONFIG_RESPONSE, props<SetServiceConfigResponse>());

export const FetchUserSyncVersionRequestAction  = createAction(FETCH_USER_SYNC_VERSION_REQUEST, props<FetchUserSyncVersionRequest>());
export const FetchUserSyncVersionResponseAction = createAction(FETCH_USER_SYNC_VERSION_RESPONSE, props<FetchUserSyncVersionResponse>());

export const FetchUserSyncCronEnabledRequestAction  = createAction(FETCH_USER_SYNC_CRON_ENABLED_REQUEST, props<FetchUserSyncCronEnabledRequest>());
export const FetchUserSyncCronEnabledResponseAction = createAction(FETCH_USER_SYNC_CRON_ENABLED_RESPONSE, props<FetchUserSyncCronEnabledResponse>());

export const SetNumberTombstoneDurationRequestAction  = createAction(SET_NUMBER_TOMBSTONE_DURATION_REQUEST, props<SetNumberTombstoneDurationRequest>());
export const SetNumberTombstoneDurationResponseAction = createAction(SET_NUMBER_TOMBSTONE_DURATION_RESPONSE, props<SetNumberTombstoneDurationResponse>());

export const FetchNumberTombstoneDurationRequestAction  = createAction(FETCH_NUMBER_TOMBSTONE_DURATION_REQUEST, props<FetchNumberTombstoneDurationRequest>());
export const FetchNumberTombstoneDurationResponseAction = createAction(FETCH_NUMBER_TOMBSTONE_DURATION_RESPONSE, props<FetchNumberTombstoneDurationResponse>());

export const SetSSOAllowIdentityCreationRequestAction  = createAction(SET_SSO_ALLOW_IDENTITY_CREATION_REQUEST, props<SetSSOIdentityCreationRequest>());
export const SetSSOAllowIdentityCreationResponseAction = createAction(SET_SSO_ALLOW_IDENTITY_CREATION_RESPONSE, props<SetSSOIdentityCreationResponse>());

export const FetchSSOAllowIdentityCreationRequestAction  = createAction(FETCH_SSO_ALLOW_IDENTITY_CREATION_REQUEST, props<FetchSSOIdentityCreationRequest>());
export const FetchSSOAllowIdentityCreationResponseAction = createAction(FETCH_SSO_ALLOW_IDENTITY_CREATION_RESPONSE, props<FetchSSOIdentityCreationResponse>());

export const SetSSOAllowPasswordResetRequestAction  = createAction(SET_SSO_ALLOW_PASSWORD_RESET_REQUEST, props<SetSSOAllowPasswordResetRequest>());
export const SetSSOAllowPasswordResetResponseAction = createAction(SET_SSO_ALLOW_PASSWORD_RESET_RESPONSE, props<SetSSOAllowPasswordResetResponse>());

export const FetchSSOAllowPasswordResetRequestAction  = createAction(FETCH_SSO_ALLOW_PASSWORD_RESET_REQUEST, props<FetchSSOAllowPasswordResetRequest>());
export const FetchSSOAllowPasswordResetResponseAction = createAction(FETCH_SSO_ALLOW_PASSWORD_RESET_RESPONSE, props<FetchSSOAllowPasswordResetResponse>());

export const FetchRangeUsageThresholdRequestAction  = createAction(FETCH_RANGE_USAGE_THRESHOLD_REQUEST, props<FetchRangeUsageThresholdRequest>());
export const FetchRangeUsageThresholdResponseAction = createAction(FETCH_RANGE_USAGE_THRESHOLD_RESPONSE, props<FetchRangeUsageThresholdResponse>());

export const SetRangeUsageThresholdRequestAction  = createAction(SET_RANGE_USAGE_THRESHOLD_REQUEST, props<SetRangeUsageThresholdRequest>());
export const SetRangeUsageThresholdResponseAction = createAction(SET_RANGE_USAGE_THRESHOLD_RESPONSE, props<SetRangeUsageThresholdResponse>());

export const SetCustomerCostsToggleRequestAction  = createAction(SET_CUSTOMER_COSTS_TOGGLE_REQUEST, props<SetCustomerCostsToggleRequest>());
export const SetCustomerCostsToggleResponseAction = createAction(SET_CUSTOMER_COSTS_TOGGLE_RESPONSE, props<SetCustomerCostsToggleResponse>());

export const FetchCustomerCostsToggleRequestAction  = createAction(FETCH_CUSTOMER_COSTS_TOGGLE_REQUEST, props<FetchCustomerCostsToggleRequest>());
export const FetchCustomerCostsToggleResponseAction = createAction(FETCH_CUSTOMER_COSTS_TOGGLE_RESPONSE, props<FetchCustomerCostsToggleResponse>());

export const FetchNumberReportsToggleRequestAction  = createAction(FETCH_NUMBER_REPORTS_TOGGLE_REQUEST, props<FetchNumberReportsToggleRequest>());
export const FetchNumberReportsToggleResponseAction = createAction(FETCH_NUMBER_REPORTS_TOGGLE_RESPONSE, props<FetchNumberReportsToggleResponse>());

export const FetchRangeUsageToggleRequestAction  = createAction(FETCH_RANGE_USAGE_TOGGLE_REQUEST, props<FetchRangeUsageToggleRequest>());
export const FetchRangeUsageToggleResponseAction = createAction(FETCH_RANGE_USAGE_TOGGLE_RESPONSE, props<FetchRangeUsageToggleResponse>());

export const SetRangeUsageToggleRequestAction  = createAction(SET_RANGE_USAGE_TOGGLE_REQUEST, props<SetRangeUsageToggleRequest>());
export const SetRangeUsageToggleResponseAction = createAction(SET_RANGE_USAGE_TOGGLE_RESPONSE, props<SetRangeUsageToggleResponse>());

export const FetchGlobalUIBannerRequestAction  = createAction(FETCH_GLOBAL_UI_BANNER_REQUEST, props<FetchGlobalUIBannerRequest>());
export const FetchGlobalUIBannerResponseAction = createAction(FETCH_GLOBAL_UI_BANNER_RESPONSE, props<FetchGlobalUIBannerResponse>());

export const SetGlobalUIBannerRequestAction  = createAction(SET_GLOBAL_UI_BANNER_REQUEST, props<SetGlobalUIBannerRequest>());
export const SetGlobalUIBannerResponseAction = createAction(SET_GLOBAL_UI_BANNER_RESPONSE, props<SetGlobalUIBannerResponse>());

export const FetchGlobalUIBannerToggleRequestAction  = createAction(FETCH_GLOBAL_UI_BANNER_TOGGLE_REQUEST, props<FetchGlobalUIBannerToggleRequest>());
export const FetchGlobalUIBannerToggleResponseAction = createAction(FETCH_GLOBAL_UI_BANNER_TOGGLE_RESPONSE, props<FetchGlobalUIBannerToggleResponse>());

export const SetGlobalUIBannerToggleRequestAction  = createAction(SET_GLOBAL_UI_BANNER_TOGGLE_REQUEST, props<SetGlobalUIBannerToggleRequest>());
export const SetGlobalUIBannerToggleResponseAction = createAction(SET_GLOBAL_UI_BANNER_TOGGLE_RESPONSE, props<SetGlobalUIBannerToggleResponse>());
