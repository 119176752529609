import { ServiceItem }       from '@models/entity/service-item.model';
import { ServiceType }       from '@enums/service-type.enum';
import { CallForwardingRaw } from '@models/api/call-forwarding-raw.model';
import { getServiceIcon }    from '@models/api/service.model';

export class CallForwarding extends ServiceItem {
  type = ServiceType.CallForwarding;
  icon = getServiceIcon(ServiceType.CallForwarding, false);

  constructor(protected data?: CallForwarding) {
    super();
    Object.assign(this, {...(data || {})});
    delete this['data'];
  }

  toApiData(): CallForwardingRaw {
    return {
      type:  this.type,
      label: this.label
    } as CallForwardingRaw;
  }

  fromApiData?(apiData: CallForwardingRaw): CallForwarding {
    super.fromApiData(apiData);
    this.type        = apiData.type;
    this.createdDate = apiData.created_date;
    this.label       = apiData.label;
    return this;
  }

}
