import { ListResponseMetadata }    from '../api/list-response-metadata.model';
import { ListParams }              from './list-params.model';
import { ListParamModel }          from './list-param-model';
import { ServiceUserStatusCounts } from '../api/service-user-status-counts';
import { ServiceUserCapability }   from '@enums/service-user-capability.enum';

export class TeamsUserQueryParams extends ListParams implements ListParamModel<TeamsUserQueryParams> {
  status?: string[];
  search?: string;
  hasCli?: boolean;
  isResourceAccount?: boolean;
  id?: string[];
  statusCount?: ServiceUserStatusCounts;
  numberTag?: string[];
  capabilities?: ServiceUserCapability[];
  isManaged?: boolean;
  profileIds?: string[];

  constructor(private data?: TeamsUserQueryParams) {
    super();
    Object.assign(this, { ...(this.data || {}) });
  }

  static getIsResourceAccount(formValue: { resourceAccount: boolean, userAccount: boolean }): boolean {
    const { resourceAccount, userAccount } = formValue;
    if (resourceAccount === userAccount) {
      return null;
    }
    return formValue.resourceAccount;
  }

  static constructQueryString(searchParams: TeamsUserQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      status:            'filter[status][]',
      search:            'filter[search]',
      hasCli:            'filter[has_cli]',
      isResourceAccount: 'filter[is_resource_account]',
      id:                'filter[id][]',
      numberTag:         'filter[number_tag][]',
      capabilities:      'filter[capabilities][]',
      isManaged:         'filter[is_managed]',
      profileIds:        'filter[profile_ids][]',
      include:           'include[]',
    });
  }

  constructParams?(metaData: ListResponseMetadata): TeamsUserQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData?.filter?.status) {
      this.status = metaData.filter.status as string[];
    }
    if (metaData?.filter?.search) {
      this.search = metaData.filter.search;
    }
    if (metaData?.filter?.has_cli) {
      this.hasCli = metaData.filter.has_cli;
    }
    if (metaData?.filter?.id) {
      this.id = metaData.filter.id;
    }
    if (metaData?.filter?.is_resource_account) {
      this.isResourceAccount = metaData.filter.is_resource_account;
    }
    if (metaData?.status_count) {
      this.statusCount = metaData.status_count;
    }
    if (metaData?.filter?.number_tag) {
      this.numberTag = metaData.filter.number_tag;
    }
    if (metaData?.filter?.capabilities) {
      this.capabilities = metaData.filter.capabilities as unknown as ServiceUserCapability[];
    }
    if (metaData?.filter?.is_managed) {
      this.isManaged = metaData.filter.is_managed;
    }
    if (metaData?.filter?.profile_ids) {
      this.profileIds = metaData.filter.profile_ids;
    }
    return this;
  }
}
