import { BaseRequest } from '@redux/helpers/reducer.helper';

export class PutCallingProfileRequest extends BaseRequest {
  id: string;
  serviceId: string;
  name: string;
  description: string;
  numberRangeIds: string[];
  numberTagIds: string[];
  locationIds: string[];
  emergencyLocationId: string;
  policies: { [key: string]: string };
  withUpdate: boolean;

  constructor(private data?: PutCallingProfileRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): {
    id: string,
    name: string,
    description: string,
    policies: { [key: string]: string },
    emergency_location: string,
    number_range_ids: string[],
    number_tag_ids: string[],
    number_location_ids: string[]
  } {
    return {
      id:                  this.id,
      name:                this.name,
      description:         this.description,
      policies:            { ...this.policies },
      emergency_location:  this.emergencyLocationId,
      number_range_ids:    this.numberRangeIds,
      number_tag_ids:      this.numberTagIds,
      number_location_ids: this.locationIds,
    };
  }
}
