export enum ServiceTab {
  Users           = 'users',
  Settings        = 'settings',
  Configure       = 'configure',
  Health          = 'health',
  Events          = 'events',
  Profiles        = 'profiles',
  Automation      = 'automation',
  LicenseGroups   = 'license_groups',
  TeamGroups      = 'team_groups',
  CallQueueGroups = 'call_queue_groups',
  Audit           = 'audit',
}
