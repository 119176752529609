import { LastPaymentDataRaw } from '@models/api/last-payment-data-raw.model';
import { DateService }        from '@services/date.service';


export class LastPaymentData {
  id: string;
  amount: string;
  category: string;
  description: string;
  method: string;
  type: string;
  createdDate: string;
  statusColor: string;

  fromApiData?(apiData: LastPaymentDataRaw, dateService: DateService): LastPaymentData {
    this.id          = apiData.id;
    this.amount      = apiData.currency && apiData.currency.formatted ? apiData.currency.formatted : apiData.amount;
    this.category    = apiData.category_description;
    this.description = apiData.user_description;
    this.method      = apiData.method_description;
    this.type        = apiData.type_description;
    this.createdDate = apiData.created_date ? dateService.UTCtoDate(apiData.created_date) : null;

    return this;
  }
}
