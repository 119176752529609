import { ReportSeriesData }        from '@models/entity/report-series-data.model';
import { NumberReportViewMode }    from '@enums/number-report-view-mode.enum';
import { ChartMultiSeries }        from '@models/chart/chart-multi-series.model';
import { ChartSeries }             from '@models/chart/chart-series.model';
import { RangeExhaustionRawData }  from '@models/entity/range-exhaustion-raw-data.model';
import { RangeExhaustionDataItem } from '@models/form/range-exhaustion-data-item.model';

export class RangeExhaustionData {
  series: { id: string, name: string }[];
  points: {
    date: string;
    name: string;
    code: string;
    usage_fraction: number;
    usage_threshold: number;
  }[];

  constructor(private data?: RangeExhaustionData) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(apiData: RangeExhaustionRawData): RangeExhaustionData {
    this.series = (apiData?.groups ? Object.entries(apiData.groups)
      .map(([id, name]) => ({ id, name })) : apiData.series)?.map(p => ReportSeriesData.fromApiData(p)) || [];

    this.points = [];

    for (const [date, val] of Object.entries(apiData.points)) {
      for (const [group, data] of Object.entries(val)) {
        this.points.push({
          name:            this.series?.find(s => s.id === group)?.name,
          code:            group,
          usage_fraction:  +data.usage_fraction,
          usage_threshold: +data.usage_threshold,
          date,
        });
      }
    }

    return this;
  }

  toDataItems?(): RangeExhaustionDataItem[] {
    const points = this.points;
    return points?.map(point => ({
      id:                  point.code,
      name:                point.name,
      usagePercentage:     +parseFloat((point.usage_fraction * 100).toString())
        .toFixed(1),
      thresholdPercentage: +parseFloat((point.usage_threshold * 100).toString())
        .toFixed(1),
    })) || [];
  }

  toChartMultiSeries?(viewMode?: NumberReportViewMode): (ChartMultiSeries | ChartSeries)[] {
    const points = this.points;
    const series = this.series;

    if (viewMode === NumberReportViewMode.Overview) {
      return series?.map(s => {
        const point = points.find(p => p.name === s.name);
        return {
          name:  point.name,
          value: point.usage_fraction,
          extra: {
            code: point.code,
          },
        } as ChartSeries;
      });
    }

    return this.series?.map(s => ({
      name:   s.name,
      series: points?.length ? points.filter(p => p.name === s.name)
        .map(d => {
          return {
            name:  d.date,
            value: d.usage_fraction,
            extra: {
              code: d.code,
            },
          } as ChartSeries;
        }) : [],
    })) || [];
  }
}
