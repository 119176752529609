<ngx-anon-page [reducedPadding]="true" class="new-user-page">
  <div fxLayout="column" fxLayoutAlign="space-between">
    <h1 class="fw-500 mb-24">Approval needed</h1>
    <p class="mat-body-1">You will require approval from the company associated with your account to use
      Callroute.</p>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-24">
      <ngx-cr-button text="Back to login" (onClick)="logout()"></ngx-cr-button>
    </div>
  </div>
</ngx-anon-page>
