import { ADGroupRaw } from '@models/api/microsoft-teams/ad-group-raw.model';

export class ADGroup {
  id: string;
  guid: string;
  name: string;
  description: string;

  constructor(private data?: ADGroup) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: ADGroupRaw): ADGroup {
    this.id          = data.id;
    this.guid        = data.guid;
    this.name        = data.name;
    this.description = data.description;
    return this;
  }

}
