import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

export function clickBtn(inMs = 100, outMs = 100): AnimationTriggerMetadata {
  return trigger('clickBtn', [
    state('inactive',
      style({transform: 'scale(1)', boxShadow: '0 4px 20px rgba(0,0,0,0.02)'})),
    state('active',
      style({transform: 'scale(0.95)', boxShadow: '0 2px 12px rgba(0,0,0,0.05)'})),
    transition('* => inactive', [animate(`${ outMs }ms`)]),
    transition('* => active', [animate(`${ inMs }ms`)])
  ]);
}
