import { Observable, of }  from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ApiService, TokenData } from './api.service';
import { ListService }           from './list.service';
import { environment }           from '../../environments/environment';


import {
  FetchNotificationCountResponse,
  FetchNotificationListRequest,
  FetchNotificationListResponse,
  MarkAsReadRequest,
  MarkAsReadResponse,
  NotificationDataFactory,
  NotificationItem,
  NotificationSearchParams,
  NotificationSearchParamsFactory,
  PollNotificationListResponse,
} from '@models/entity/notification.model';

import { Alert }                  from '@models/entity/alert.model';
import { HttpErrorResponse }      from '@angular/common/http';
import { AuthToken }              from '@enums/auth-token.enum';
import { TokenService }           from './token.service';
import { MomentService }          from './moment.service';
import { DateService }            from './date.service';
import { BaseResponse }           from '@redux/helpers/reducer.helper';
import { SubmitFeedbackResponse } from '@models/api/submit-feedback-response.model';
import { SubmitFeedbackRequest }  from '@models/api/submit-feedback-request.model';
import { NotificationDataRaw }    from '@models/api/identity/notification-item-raw.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  private baseUrl: string = environment.api.notificationBaseUrl;

  private readonly notificationFactory: NotificationDataFactory;
  private readonly notificationParamFactory: NotificationSearchParamsFactory;

  private buildNotificationUri(notificationId: string): string {
    return this.buildUri(`notifications/${ notificationId }`);
  }

  private buildNotificationReadAllUri(): string {
    return this.buildUri(`notifications/read-all`);
  }

  private buildNotificationPollUri(): string {
    return this.buildUri('notifications/poll');
  }

  private buildNotificationListUri(queryParams: NotificationSearchParams): string {
    return this.buildUri(`notifications${ NotificationSearchParams.constructQueryString(queryParams) }`);
  }

  private buildNotificationCountsUri(): string {
    return this.buildUri('notifications/counts');
  }

  private buildFeedbackUri(): string {
    return this.buildUri('feedback');
  }

  constructor(
    private apiService: ApiService,
    private notificationListService: ListService<NotificationItem, NotificationDataFactory,
      NotificationSearchParams, NotificationSearchParamsFactory>,
    private momentService: MomentService,
    private dateService: DateService,
  ) {
    this.notificationFactory      = new NotificationDataFactory();
    this.notificationParamFactory = new NotificationSearchParamsFactory();
  }

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  submitFeedback$(req: SubmitFeedbackRequest): Observable<SubmitFeedbackResponse> {
    return this.apiService.apiPost$(
      this.buildFeedbackUri(),
      new SubmitFeedbackRequest(req).toApiData(),
      { authRequired: true },
    )
      .pipe(
        map((): SubmitFeedbackResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: 'Thank you for your feedback!' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SubmitFeedbackResponse> => {
          return of({
            error: new Alert().fromApiError(err),
          });
        }),
      );
  }

  fetchNotificationCount$(): Observable<FetchNotificationCountResponse> {
    return this.apiService.apiGet$<{ data: { UNREAD: number } }>(
      this.buildNotificationCountsUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: { UNREAD: number } }): FetchNotificationCountResponse => {
          return {
            error:       null,
            unreadCount: res.data.UNREAD,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchNotificationCountResponse> => {
          return of({
            error:       new Alert().fromApiError(err),
            unreadCount: null,
          });
        }),
      );
  }

  fetchNotificationList$(req: FetchNotificationListRequest): Observable<FetchNotificationListResponse> {
    return this.notificationListService.fetchListModel$(
      this.buildNotificationListUri(req.queryParams),
      this.notificationFactory,
      this.notificationParamFactory,
    );
  }

  pollNotificationList$(): Observable<PollNotificationListResponse> {
    const accessToken: TokenData = TokenService.getToken(AuthToken.Access) as TokenData;

    if (accessToken && this.momentService.moment.utc()
      .isBefore(this.momentService.moment.utc(accessToken.expires))) {
      return this.apiService.apiPoll$<{ data: NotificationDataRaw[] }>(this.buildNotificationPollUri(), { authRequired: true })
        .pipe(
          map((res: { data: NotificationDataRaw[] }): PollNotificationListResponse => {
            return {
              error:  null,
              models: res?.data?.map((x: NotificationDataRaw) => this.notificationFactory.create()
                .fromApiData(x, this.dateService)) || [],
            };
          }),
          catchError((err: HttpErrorResponse): Observable<PollNotificationListResponse> => {
            return of({
              error:  new Alert().fromApiError(err),
              models: [],
            });
          }),
        );
    } else {
      return of({
        error:  null,
        models: [],
      });
    }
  }

  markAllAsRead$(): Observable<BaseResponse> {
    return this.apiService.apiPost$(
      this.buildNotificationReadAllUri(),
      {},
      { authRequired: true })
      .pipe(
        map((): BaseResponse => {
          return {
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<BaseResponse> => {
          return of({
            error: new Alert().fromApiError(err),
          });
        }),
      );
  }

  markAsRead$(req: MarkAsReadRequest): Observable<MarkAsReadResponse> {
    return this.apiService.apiPatch$(
      this.buildNotificationUri(req.notificationId),
      { is_read: true },
      { authRequired: true })
      .pipe(
        map((): MarkAsReadResponse => {
          return {
            error:          null,
            notificationId: req.notificationId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<MarkAsReadResponse> => {
          return of({
            error:          new Alert().fromApiError(err),
            notificationId: req.notificationId,
          });
        }),
      );
  }


}
