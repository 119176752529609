import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class LicenseGroupQueryParams extends ListParams implements ListParamModel<LicenseGroupQueryParams> {
  search?: string;
  id?: string[];

  static constructQueryString(searchParams: LicenseGroupQueryParams): string {
    const params: LicenseGroupQueryParams = { ...(searchParams || {}) as LicenseGroupQueryParams };

    return ListParams.constructQueryString(params, {
      search: 'filter[search]',
      id:     'filter[id][]',
    });
  }

  static queryChanged(newQuery: LicenseGroupQueryParams, currentQuery: LicenseGroupQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery);
  }

  constructParams?(metaData: ListResponseMetadata): LicenseGroupQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.search) {
        this.search = metaData.filter.search;
      }
      if (metaData.filter.id) {
        this.id = metaData.filter.id;
      }
    }

    return this;
  }
}
