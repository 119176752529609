import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class DomainQueryParams extends ListParams implements ListParamModel<DomainQueryParams> {
  search?: string;

  static constructQueryString(searchParams: DomainQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      search: 'filter[search]',
    });
  }

  static queryChanged(newQuery: DomainQueryParams, currentQuery: DomainQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery);
  }

  constructParams?(metaData: ListResponseMetadata): DomainQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.search) {
        this.search = metaData.filter.search;
      }
      if (metaData.include) {
        this.include = metaData.include;
      }
    }

    return this;
  }
}
