import { Component, Inject }                        from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TypedAction }                              from '@ngrx/store/src/models';
import { ButtonType }                               from 'src/app/enums/button-type.enum';
import { FetchCallLogRecordingRequest }             from '@models/api/fetch-call-log-recording-request.model';
import { CallLogItem }                              from '@models/entity/call-log-item.model';

@Component({
  selector:    'ngx-call-log-sheet',
  templateUrl: './call-log-sheet.component.html',
  styleUrls:   ['./call-log-sheet.component.scss']
})
export class CallLogSheetComponent {
  ButtonType = ButtonType;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { fileName: string, downloadAction: FetchCallLogRecordingRequest & TypedAction<'FETCH_CALL_LOG_RECORDING_REQUEST'>, logData: CallLogItem },
    private sheetRef: MatBottomSheetRef<CallLogSheetComponent>
  ) { }

  openRecordingModal(): void {
    this.sheetRef.dismiss('RECO');
  }

  close(): void {
    this.sheetRef.dismiss();
  }

}
