import { Component }     from '@angular/core';
import { AssetsService } from '@services/assets.service';

@Component({
  selector:    'ngx-no-active-user-modal',
  templateUrl: './no-active-user-modal.component.html',
  styleUrls:   ['./no-active-user-modal.component.scss'],
})
export class NoActiveUserModalComponent {

  closeIconUrl: string = AssetsService.getIcon('close.svg');

  constructor() {}
}
