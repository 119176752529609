<div fxLayout="row" fxLayoutAlign="start center" class="modal-head__header mb-16">
  <div class="modal-head__header__icon-container" *ngIf="headerIcon">
    <ng-container *ngIf="headerIcon.startsWith('cr'); else asImg">
      <mat-icon svgIcon="{{headerIcon}}"></mat-icon>
    </ng-container>
    <ng-template #asImg>
      <img src="{{headerIcon}}" alt="{{header}}" />
    </ng-template>
  </div>
  <div fxLayout="column" class="modal-head__header__titles" fxLayoutAlign="center">
    <h2 mat-dialog-title data-cy="modal-title" class="m-0 fw-500" [class.mb-8]="subheader">{{ header }}</h2>
    <p class="mat-body-1--smaller mb-0" *ngIf="subheader" [innerHTML]="subheader | safe:'html'"></p>
  </div>
</div>
<button mat-icon-button *ngIf="closeable" tabindex="-1" class="button modal-head__close" type="button" data-cy="close"
        [mat-dialog-close]="false">
  <mat-icon svgIcon="cr-close" class="color-near-black"></mat-icon>
</button>
