import { Inject, Injectable, NgZone } from '@angular/core';
import { RedirectCallback }           from '@enums/redirectCallback.enum';
import { Params }                     from '@angular/router';
import { StoreState }                 from '@redux/store';
import { Store }                      from '@ngrx/store';
import { DOCUMENT }                   from '@angular/common';
import { MSOAuthCallback }            from '@redux/access-broker/access-broker.actions';
import { MSOAuthPayload }             from '@models/api/ms-oauth-payload.model';
import { OAuth2Callback }             from '@redux/auth/auth.actions';
import { Oauth2Payload }              from '@models/api/oauth2-payload.model';
import { ServiceQueryService }        from '@services/service-query.service';
import { selectServiceItem }          from '@redux/service/service.selectors';
import { filter, take }               from 'rxjs';


@Injectable({
  providedIn: 'root',
})

export class CallbackService {

  constructor(@Inject(DOCUMENT) private _document: Document,
              private ngZone: NgZone,
              private serviceQueryService: ServiceQueryService,
              private store: Store<StoreState>) { }

  registerCallbackListeners(): void {
    (this._document.defaultView as unknown as any)[RedirectCallback.MSOAuth] = (params: Params) => {
      return this.ngZone.run(async () => {
        const service = await this.store.select(selectServiceItem)
          .pipe(filter(service => !!service), take(1))
          .toPromise();
        this.store.dispatch(MSOAuthCallback({
          ...(params || {}),
          errorMessage: params?.error,
          serviceId:    service.id,
        } as MSOAuthPayload));
        this.serviceQueryService.fetchList(true, null);
      });
    };

    (this._document.defaultView as unknown as any)[RedirectCallback.OAuth2] = (params: Params) => {
      return this.ngZone.run(async () => {
        this.store.dispatch(OAuth2Callback(params as Oauth2Payload));
      });
    };

  }
}
