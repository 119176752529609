<ngx-modal-head header="Recovery code" headerIcon="cr-key-outline"
                [closeable]="false"></ngx-modal-head>
<ngx-modal-content class="mat-typography">
  <p class="mat-body-1--smaller text-align-center mb-16">This recovery code can be used in place of two-factor
    authentication
    in the event you cannot access your two-factor authentication device. It is limited to a one-time use.
    Be sure to download or copy the code using the buttons below and keep it secure,
    as this is the only time it will appear:</p>
  <p class="code mat-body-1 m-0 text-align-center fw-600">{{ data }}</p>
  <div fxLayout="row" fxLayoutAlign="center center" class="mt-24" fxLayoutGap="16px">
    <button mat-icon-button matTooltip="Copy to clipboard" ngxCopy copyText="{{data}}">
      <mat-icon svgIcon="cr-copy"></mat-icon>
    </button>
    <button mat-icon-button matTooltip="Download as file" (click)="download()">
      <mat-icon svgIcon="cr-download"></mat-icon>
    </button>
  </div>
</ngx-modal-content>
<br>
<ngx-modal-actions>
  <button class="button primary" #modalAction type="button" data-cy="submit" [mat-dialog-close]="true">
    Got it
  </button>
</ngx-modal-actions>
