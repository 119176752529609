import { Component, ContentChildren, QueryList } from '@angular/core';

@Component({
  selector:    'ngx-modal-actions',
  templateUrl: './modal-actions.component.html',
  styleUrls:   ['./modal-actions.component.scss']
})
export class ModalActionsComponent {
  @ContentChildren('modalAction') modalActions: QueryList<unknown>;
}
