import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector:    'ngx-modal-head',
  templateUrl: './modal-head.component.html',
  styleUrls:   ['./modal-head.component.scss'],
})
export class ModalHeadComponent {
  @Input() header: string;
  @Input() subheader: string;
  @Input() closeable             = true;
  @Input() headerIcon: string;
  @HostBinding('data-cy') dataCy = 'modal-head';

}
