import { Inject, Injectable }                   from '@angular/core';
import { Observable, of }                       from 'rxjs';
import { catchError, map }                      from 'rxjs/operators';
import { HttpErrorResponse }                    from '@angular/common/http';
import { Alert }                                from '@models/entity/alert.model';
import { ApiService }                           from './api.service';
import { environment }                          from '../../environments/environment';
import { AuthorisationRedirectRequest }         from '@models/api/authorisation-redirect-request.model';
import { AuthorisationRedirectResponse }        from '@models/api/authorisation-redirect-response.model';
import { AuthorisationRedirectResponseRaw }     from '@models/api/authorisation-redirect-response-raw.model';
import { DeleteTokensRequest }                  from '@models/api/delete-tokens-request.model';
import { DeleteTokensResponse }                 from '@models/api/delete-tokens-response.model';
import { DOCUMENT }                             from '@angular/common';
import { FetchCombinedTokensStatusResponse }    from '@models/api/fetch-combined-tokens-status-response.model';
import { FetchCombinedTokensStatusResponseRaw } from '@models/api/fetch-combined-tokens-status-response-raw.model';
import { MicrosoftToken }                       from '@enums/microsoft-token.enum';
import { FetchCombinedTokensStatusRequest }     from '@models/api/fetch-combined-tokens-status-request.model';
import { Token }                                from '@models/entity/token-state.model';
import { TokenStatus }                          from '@enums/token-status.enum';

@Injectable({
  providedIn: 'root',
})
export class AccessBrokerService {
  private baseUrl: string = environment.api.accessBrokerUrl;
  private callbackWindow: Window;

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  private buildTeamsTokensUri(serviceId: string): string {
    return this.buildUri(`tokens/${ serviceId }/microsoft-teams`);
  }

  private buildCombinedTokensStatusUri(serviceId: string): string {
    return this.buildUri(`tokens/${ serviceId }/microsoft-teams/status`);
  }

  private buildAuthRedirectUri(token: MicrosoftToken, serviceId: string, withVoice: boolean): string {
    // CR-6012 add default 'Orto' capabilities to allow _all_ Orto actions; future ticket may follow to narrow this down further in certain circumstances
    const capabilitiesOrtoDefault = 'capabilities[]=ORTO&capabilities[]=LICENSING&capabilities[]=CALL_QUEUES&capabilities[]=TEAM_MEMBERS';
    const capabilities = withVoice ? capabilitiesOrtoDefault + '&capabilities[]=VOICE' : capabilitiesOrtoDefault;
    return this.buildUri(`authorisation/authorise/${ serviceId }/${ token }?mode=json&${ capabilities }`);
  }

  constructor(@Inject(DOCUMENT) private _document: Document,
              private apiService: ApiService) { }

  authorisationRedirect$(req: AuthorisationRedirectRequest, serviceId: string, withVoice: boolean): Observable<AuthorisationRedirectResponse> {
    return this.apiService.apiGet$<{ data: AuthorisationRedirectResponseRaw }>(
      this.buildAuthRedirectUri(req.token, serviceId, withVoice),
      { authRequired: true })
      .pipe(
        map((res: { data: AuthorisationRedirectResponseRaw }): AuthorisationRedirectResponse => {

          return {
            error:       null,
            message:     null,
            redirectURI: res.data.redirect_uri,
            cookie:      res.data.session_cookie,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<AuthorisationRedirectResponse> => {
          return of({
            error:       new Alert().fromApiError(err),
            message:     new Alert().fromApiMessage({ message: err.message }),
            redirectURI: null,
            cookie:      null,
          });
        }),
      );
  }

  deleteTokens$(req: DeleteTokensRequest): Observable<DeleteTokensResponse> {
    return this.apiService.apiDelete$(
      this.buildTeamsTokensUri(req.serviceId),
      { authRequired: true })
      .pipe(
        map((): DeleteTokensResponse => {
          return {
            error:     null,
            serviceId: req.serviceId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteTokensResponse> => {
          return of({
            error:     new Alert().fromApiError(err),
            serviceId: req.serviceId,
          });
        }),
      );
  }

  fetchCombinedTokensStatus$(req: FetchCombinedTokensStatusRequest): Observable<FetchCombinedTokensStatusResponse> {
    return this.apiService.apiGet$<FetchCombinedTokensStatusResponseRaw>(
      this.buildCombinedTokensStatusUri(req.serviceId),
      { authRequired: true })
      .pipe(
        map((res: FetchCombinedTokensStatusResponseRaw): FetchCombinedTokensStatusResponse => {
          return {
            error:     null,
            message:   null,
            serviceId: req.serviceId,
            tokens:    res.data && Object.keys(res.data)
              .map((key: string) => ({
                message:   res.data[key].message,
                id:        key as MicrosoftToken,
                lastAuth:  null,
                status:    res.data[key].is_active ? TokenStatus.Active : TokenStatus.Inactive,
                serviceId: req.serviceId,
              } as Token)),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCombinedTokensStatusResponse> => {
          return of({
            error:     new Alert().fromApiError(err),
            message:   new Alert().fromApiMessage({ message: err.message }),
            serviceId: req.serviceId,
            tokens:    null,
          });
        }),
      );
  }

  openRedirectPopup(): void {
    if (this.callbackWindow) {
      return;
    }
    this.callbackWindow =
      this._document.defaultView.open(`https://callroute.io/redirect`, 'callbackWindow', 'width=450, height=500, opener=true');
  }

  closeRedirectPopup(): void {
    this.callbackWindow?.close();
    this.callbackWindow = null;
  }

  isRedirectClosed(): boolean {
    return !this.callbackWindow || this.callbackWindow.closed;
  }

  getRedirectLocation(): Location {
    return this.callbackWindow?.location;
  }

  setRedirectLocation(url: string): void {
    this.callbackWindow.location.href = url;
  }

}
