import { ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, Renderer2 } from '@angular/core';

@Component({
  selector:    'ngx-shrink-progress-bar',
  templateUrl: './shrink-progress-bar.component.html',
  styleUrls:   ['./shrink-progress-bar.component.scss']
})
export class ShrinkProgressBarComponent implements OnChanges {
  @Input() progress: number;
  @Input() color: 'red' | 'green' | 'orange' | 'blue' | 'gray' = 'blue';
  @HostBinding('style.transform') transform                    = 'scale3d(1, 0, 1)';
  private backgroundSet                                        = false;

  constructor(private elementRef: ElementRef, private renderer2: Renderer2,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnChanges(): void {
    if (this.progress > 100) {
      return;
    }
    const scaleY   = (100 - this.progress) / 100;
    this.transform = `scale3d(1, ${ scaleY }, 1)`;
    if (!this.backgroundSet) {
      this.setColor();
    }
    this.changeDetectorRef.detectChanges();
  }

  setColor(): void {
    if (!this.color) {
      return;
    }
    this.renderer2.addClass(this.elementRef.nativeElement, `background-${ this.color }`);
    this.backgroundSet = true;
  }
}
