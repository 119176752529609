import { BaseRequest }     from '@redux/helpers/reducer.helper';
import { LicenseGroupRaw } from '@models/api/license-group-raw.model';

export class PostLicenseGroupRequest extends BaseRequest {
  serviceId: string;
  name: string;
  description: string;
  licenseIds: string[];

  constructor(private data?: PostLicenseGroupRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): LicenseGroupRaw {
    return {
      name:        this.name,
      license_ids: this.licenseIds,
      description: this.description,
    };
  }
}
