import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { NavItem }                                  from '@models/ui/nav.model';

import { RouterService }                   from '@services/router.service';
import { slide, SlideOptions }             from '@animations/slide';
import { ViewportService }                 from '@services/viewport.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject }          from 'rxjs';
import { trackBy }                         from '@util/trackBy';
import { StoreState }                      from '@redux/store';
import { Store }                           from '@ngrx/store';
import { OpenFeedbackRequestAction }       from '@redux/notification/notification.actions';

@Component({
  selector:    'ngx-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls:   ['./main-menu.component.scss'],
  animations:  [
    slide(),
  ],
})
export class MainMenuComponent implements OnDestroy {
  @Input() navItems: NavItem[];
  @Input() primaryColor: string;
  @HostBinding('attr.data-cy') dataCy        = 'side-menu';
  @HostBinding('@slide') slide: SlideOptions = null;
  destroy                                    = new Subject<void>();
  destroy$                                   = this.destroy.asObservable();
  trackBy                                    = trackBy<NavItem>('label');

  constructor(private router: RouterService,
              private viewportService: ViewportService,
              private store: Store<StoreState>) {
    combineLatest([this.viewportService.ltXs$, this.viewportService.ltSmH$])
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(([ltSmWidth, ltSmHeight]) => {
        this.slide = {
          value: true, params: {
            translate: ltSmWidth || ltSmHeight ? 'translateY' : 'translateX',
            from:      ltSmWidth || ltSmHeight ? '100%' : '-100%',
            to:        '0',
          },
        };
      });
  }

  scrollTop(): void {
    window.scroll(0, 0);
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  navigateToPage(e: MouseEvent, url: string[]): Promise<void> {
    e.stopPropagation();
    return this.router.navigate(url)
      .then(() => this.scrollTop());
  }

  openFeedbackModal(): void {
    this.store.dispatch(OpenFeedbackRequestAction());
  }

}
