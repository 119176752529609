<div class="notification shadow-md" fxLayout="row" fxLayoutAlign="start center" (click)="$event.stopPropagation()">
  <ngx-shrink-progress-bar [progress]="timeProgress" [color]="getColor()"></ngx-shrink-progress-bar>
  <mat-icon svgIcon="cr-info-circle" class="notification__icon"
            [ngClass]="'color-' + getColor()"></mat-icon>
  <div class="notification__message" fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="column" [class.cursor-pointer]="link">
      <p class="mat-body-1--smaller fw-500">{{notification?.message}}</p>
      <p class="mat-body-1--smaller"
         *ngIf="link"
         [routerLink]="link"
         (click)="dismiss()"
         fxLayout="row" fxLayoutAlign="start center"
         queryParamsHandling="preserve">
        <span class="mr-6">{{linkText}}</span>
        <mat-icon svgIcon="cr-open-new"></mat-icon>
      </p>
    </div>
    <a href="{{notification.data.help_uri}}" target="_blank" *ngIf="notification.data?.help_uri"
       class="ml-16 flex-item__suffix">
      <button mat-icon-button>
        <mat-icon svgIcon="cr-help-outline"></mat-icon>
      </button>
    </a>
  </div>
  <div class="notification__action" fxLayout="row" fxLayoutAlign="center center">
    <button mat-icon-button class="button" type="button"
            (click)="dismiss()">
      <mat-icon svgIcon="cr-close" class="color-near-black"></mat-icon>
    </button>
  </div>
</div>
