import { BaseRequest }        from '@redux/helpers/reducer.helper';
import { ProviderIdentifier } from '@enums/provider-identifier.enum';
import { CarrierType }        from '@enums/carrier-type.enum';

export class AddNumberRangeRequest extends BaseRequest {
  numbers: string[];
  provider: ProviderIdentifier;
  operator: string;
  serviceId: string;
  tags: string[];
  _id: string;

  constructor(private data?: AddNumberRangeRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): {
    provider: ProviderIdentifier;
    service_id: string;
    numbers: string[];
    type: CarrierType;
    tags: string[],
    operator: string
  } {
    return {
      numbers:    this.numbers,
      service_id: this.serviceId,
      type:       this.provider === ProviderIdentifier.DirectRouting ? CarrierType.BYON : CarrierType.BYOC,
      provider:   this.provider,
      operator:   this.provider === ProviderIdentifier.DirectRouting ? this.operator : null,
      tags:       this.tags,
    };
  }
}
