import { Injectable }                                                         from '@angular/core';
import { ApiService }                                                         from './api.service';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of }                                                     from 'rxjs';
import {
  ServiceItemRaw,
}                                                                             from '@models/api/service-api-response.model';
import { catchError, map }                                                    from 'rxjs/operators';
import { HttpErrorResponse }                                                  from '@angular/common/http';
import { Alert }                                                              from '@models/entity/alert.model';
import { environment }                                                        from '../../environments/environment';
import { ServiceType }                                                        from '@enums/service-type.enum';
import { SIPTrunk }                                                           from '@models/entity/sip-trunk.model';
import { IPValidator }                                                        from '@validators/ip.validator';
import { SIPTrunkRaw }                                                        from '@models/api/sip-trunk-raw.model';
import {
  PostServiceRequest,
}                                                                             from '@models/api/post-service-request.model';
import {
  PostServiceResponse,
}                                                                             from '@models/api/post-service-response.model';
import {
  PatchServiceRequest,
}                                                                             from '@models/api/patch-service-request.model';
import {
  PatchServiceResponse,
}                                                                             from '@models/api/patch-service-response.model';

@Injectable({
  providedIn: 'root',
})
export class SipTrunkService {

  private baseUrl: string = environment.api.serviceBaseUrl;

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  newIPAddrCtrl   = (data: string, allowDomain?: boolean): UntypedFormControl =>
    new UntypedFormControl(data, [Validators.required, Validators.maxLength(128), allowDomain ? IPValidator.validAddr : IPValidator.validIP]);
  newCIDRAddrCtrl = (data: string): UntypedFormControl =>
    new UntypedFormControl(data, [Validators.required, Validators.maxLength(128), IPValidator.validCIDR]);

  constructor(private apiService: ApiService) { }

  getNewForm(data: SIPTrunk): UntypedFormGroup {
    const addressSpaces = data?.addressSpaces?.map((addr: string) => this.newCIDRAddrCtrl(addr));
    return new UntypedFormGroup(
      {
        serviceType:             new UntypedFormControl(ServiceType.SIPTrunk),
        gatewayAddress:          this.newIPAddrCtrl(data?.gatewayAddress, true),
        gatewayProtocol:         new UntypedFormControl(data?.gatewayProtocol, Validators.required),
        gatewayHasSipOptions:    new UntypedFormControl(data?.gatewayHasSipOptions || false),
        callrouteGatewayAddress: new UntypedFormControl({ value: data?.callrouteGatewayAddress, disabled: true }),
        gatewayPort:             new UntypedFormControl(data?.gatewayPort, [Validators.required, Validators.min(1), Validators.max(65535)]),
        addressSpaces:           new UntypedFormArray(addressSpaces || [this.newCIDRAddrCtrl(null)]),
        label:                   new UntypedFormControl(data?.label || '', [Validators.maxLength(30)]),
      },
    );
  }

  postSipTrunk$(req: PostServiceRequest<SIPTrunk>): Observable<PostServiceResponse<SIPTrunk>> {
    return this.apiService.apiPost$<{ data: ServiceItemRaw }>(
      this.buildUri(`services`),
      new SIPTrunk(req.serviceItem).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: ServiceItemRaw }): PostServiceResponse<SIPTrunk> => {
          return {
            error:       null,
            serviceItem: new SIPTrunk().fromApiData(res.data as SIPTrunkRaw),
            message:     new Alert().fromApiMessage({
              message:   'SIP trunk service updated successfully.',
              color:     'green',
              url:       null,
              isSuccess: true,
            }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostServiceResponse<SIPTrunk>> => {
          return of({
            error:       new Alert().fromApiError(err, req.formFields),
            serviceItem: null,
            message:     null,
          });
        }),
      );
  }

  patchSipTrunk$(req: PatchServiceRequest<SIPTrunk>): Observable<PatchServiceResponse<SIPTrunk>> {
    return this.apiService.apiPatch$<{ data: ServiceItemRaw }>(
      this.buildUri(`services/${ req.serviceItem.id }`),
      new SIPTrunk(req.serviceItem).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: ServiceItemRaw }): PatchServiceResponse<SIPTrunk> => {
          return {
            error:       null,
            serviceItem: new SIPTrunk().fromApiData(res.data as SIPTrunkRaw),
            message:     new Alert().fromApiMessage({
              message:   'SIP trunk service updated successfully.',
              color:     'green',
              url:       null,
              isSuccess: true,
            }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchServiceResponse<SIPTrunk>> => {
          return of({
            error:       new Alert().fromApiError(err, req.formFields),
            serviceItem: null,
            message:     null,
          });
        }),
      );
  }


}
