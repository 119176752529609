export const FETCH_BALANCE_DATA_REQUEST  = 'FETCH_BALANCE_DATA_REQUEST';
export const FETCH_BALANCE_DATA_RESPONSE = 'FETCH_BALANCE_DATA_RESPONSE';

export const FETCH_PAYMENT_METHODS_REQUEST  = 'FETCH_PAYMENT_METHODS_REQUEST';
export const FETCH_PAYMENT_METHODS_RESPONSE = 'FETCH_PAYMENT_METHODS_RESPONSE';

export const TOPUP_BALANCE_REQUEST  = 'TOPUP_BALANCE_REQUEST';
export const TOPUP_BALANCE_RESPONSE = 'TOPUP_BALANCE_RESPONSE';

export const NEW_TOPUP_SESSION = 'NEW_TOPUP_SESSION';

export const FETCH_SPEND_REPORT_REQUEST  = 'FETCH_SPEND_REPORT_REQUEST';
export const FETCH_SPEND_REPORT_RESPONSE = 'FETCH_SPEND_REPORT_RESPONSE';

export const FETCH_CREDIT_REPORT_REQUEST  = 'FETCH_CREDIT_REPORT_REQUEST';
export const FETCH_CREDIT_REPORT_RESPONSE = 'FETCH_CREDIT_REPORT_RESPONSE';

export const DETERMINE_FETCH_LAST_PAYMENT_LIST_REQUEST = 'DETERMINE_FETCH_LAST_PAYMENT_LIST_REQUEST';

export const FETCH_LAST_PAYMENT_LIST_REQUEST  = 'FETCH_LAST_PAYMENT_LIST_REQUEST';
export const FETCH_LAST_PAYMENT_LIST_RESPONSE = 'FETCH_LAST_PAYMENT_LIST_RESPONSE';

export const FETCH_LAST_PAYMENT_SUMMARY_REQUEST  = 'FETCH_LAST_PAYMENT_SUMMARY_REQUEST';
export const FETCH_LAST_PAYMENT_SUMMARY_RESPONSE = 'FETCH_LAST_PAYMENT_SUMMARY_RESPONSE';

export const FETCH_FEATURE_INVENTORY_REQUEST  = 'FETCH_FEATURE_INVENTORY_REQUEST';
export const FETCH_FEATURE_INVENTORY_RESPONSE = 'FETCH_FEATURE_INVENTORY_RESPONSE';

export const SET_DATE_RANGE = 'SET_DATE_RANGE';

export const EXTEND_FREE_TRIAL_REQUEST  = 'EXTEND_FREE_TRIAL_REQUEST';
export const EXTEND_FREE_TRIAL_RESPONSE = 'EXTEND_FREE_TRIAL_RESPONSE';
