<ngx-modal-head header="Your session is about to expire due to inactivity"></ngx-modal-head>
<ngx-modal-content [background]="false"
                   icon="cr-time"
                   iconColor="#0096FF"
                   iconBgColor="rgba(0, 150, 255, 0.1)">
    <p class="m-0">You will be logged out in <strong class="color-near-black">{{timeout$ | async}}</strong> seconds.</p>
</ngx-modal-content>
<ngx-modal-actions>
    <button class="button primary" #modalAction (click)="stay();">Stay</button>
    <button class="button secondary" #modalAction (click)="logout();">Sign out</button>
</ngx-modal-actions>
