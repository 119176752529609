import { EventEmitter }                                                                  from '@angular/core';
import { ChangeDetectorRef, Component, ElementRef, Input, Output, Renderer2, ViewChild } from '@angular/core';

import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FieldCheckType }             from '@enums/field-check-type.enum';

@Component({
  selector:    'ngx-webex-form',
  templateUrl: './webex-form.component.html',
  styleUrls:   ['./webex-form.component.scss']
})
export class WebexFormComponent {
  @Input() formGroup: UntypedFormGroup;
  @Input() hiddenUsername: string;
  @Input() hiddenPassword: string;
  @Input() editMode   = false;
  @Output() submitted = new EventEmitter<void>();
  FieldCheckType      = FieldCheckType;

  @Input() set hideUsername(_: boolean) {
    if (!this.usernameInput) {
      return;
    }
    this.renderer2.setProperty(this.usernameInput.nativeElement, 'type', 'password');
    this.changeDetectorRef?.detectChanges();
  }

  @ViewChild('usernameInput', {read: ElementRef, static: false}) usernameInput: ElementRef;

  constructor(private renderer2: Renderer2, private changeDetectorRef: ChangeDetectorRef) {}

  determineFieldValid(control: AbstractControl, bSubmitted: boolean): boolean {
    return !control.valid && (bSubmitted || control.touched);
  }

  usernameFocused(): void {
    if (this.formGroup.get('username').value !== this.hiddenUsername) {
      return;
    }
    this.formGroup.patchValue({username: ''});
    this.formGroup.get('username')
      .markAsUntouched();
  }

  passFocused(): void {
    if (this.formGroup.get('password').value !== this.hiddenPassword) {
      return;
    }
    this.formGroup.patchValue({password: ''});
    this.formGroup.get('password')
      .markAsUntouched();
  }
}
