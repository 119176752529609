import { authReducer, AuthState }                     from './auth/auth.reducer';
import { registrationReducer, RegistrationState }     from './registration/registration.reducer';
import { companyReducer, CompanyState }               from './company/company.reducer';
import { numberReducer, NumberState }                 from './number/number.reducer';
import { reportReducer, ReportState }                 from './report/report.reducer';
import { serviceReducer, ServiceState }               from './service/service.reducer';
import { accountingReducer, AccountingState }         from './accounting/accounting.reducer';
import { notificationReducer, NotificationState }     from './notification/notification.reducer';
import { subscriptionReducer, SubscriptionState }     from './subscription/subscription.reducer';
import { configurationReducer, ConfigurationState }   from './configuration/configuration.reducer';
import { BaseState }                                  from './helpers/reducer.helper';
import { accessBrokerReducer, AccessBrokerState }     from './access-broker/access-broker.reducer';
import { microsoftTeamsReducer, MicrosoftTeamsState } from './microsoft-teams/microsoft-teams.reducer';
import { uiReducer }                                  from '@redux/ui/ui.reducer';
import { auditReducer, AuditState }                   from '@redux/audit/audit.reducer';

export interface StoreState {
  registrationReducer: RegistrationState;
  notificationReducer: NotificationState;
  accountingReducer: AccountingState;
  configurationReducer: ConfigurationState;
  reportReducer: ReportState;
  serviceReducer: ServiceState;
  subscriptionReducer: SubscriptionState;
  authReducer: AuthState;
  numberReducer: NumberState;
  companyReducer: CompanyState;
  accessBrokerReducer: AccessBrokerState;
  microsoftTeamsReducer: MicrosoftTeamsState;
  uiReducer: BaseState<never>;
  auditReducer: AuditState;
}

export const store = {
  authReducer,
  registrationReducer,
  companyReducer,
  numberReducer,
  reportReducer,
  serviceReducer,
  accountingReducer,
  notificationReducer,
  subscriptionReducer,
  configurationReducer,
  accessBrokerReducer,
  microsoftTeamsReducer,
  uiReducer,
  auditReducer,
};
