import * as ReportAction from './report.actions';
import * as AuthAction   from '../auth/auth.actions';

import { StateTask }                                from '@models/entity/state-task.model';
import { Alert }                                    from '@models/entity/alert.model';
import { BaseState, requestReduce, responseReduce } from '../helpers/reducer.helper';
import { Action, createReducer, on }                from '@ngrx/store';
import { DateRange }                                from '@models/form/date-range.model';
import {
  DELETE_REPORT_REQUEST,
  EXPORT_CDR_TO_CSV_REQUEST,
  FETCH_ASSIGNED_NUMBERS_BY_COUNTRY_REQUEST,
  FETCH_ASSIGNED_NUMBERS_BY_LOCATION_REQUEST,
  FETCH_ASSIGNED_NUMBERS_BY_RANGE_REQUEST,
  FETCH_ASSIGNED_NUMBERS_BY_TAG_REQUEST,
  FETCH_CALL_COST_BY_DIRECTION_REQUEST,
  FETCH_CALL_LOG_REQUEST,
  FETCH_CALL_LOG_SUMMARY_REQUEST,
  FETCH_CALL_MINUTES_BY_DIRECTION_REQUEST,
  FETCH_CALL_RECORDING_TRANSCRIPT_REQUEST,
  FETCH_CALLS_BY_SIP_CODE_REQUEST,
  FETCH_CALLS_BY_SIP_TYPE_REQUEST,
  FETCH_CHANNEL_USAGE_REQUEST,
  FETCH_COUNT_BY_CAPABILITY_REQUEST,
  FETCH_DESTINATION_SUMMARY_REQUEST,
  FETCH_EXPENSIVE_CALLS_REQUEST,
  FETCH_FREQUENT_CALLS_REQUEST,
  FETCH_LOCATION_COST_REQUEST,
  FETCH_RANGE_EXHAUSTION_REQUEST,
  FETCH_RANGE_USAGE_TOP_REQUEST,
  FETCH_REPORT_LIST_REQUEST,
  FETCH_REPORT_REQUEST,
  FETCH_REPORTING_OVERVIEW_REQUEST,
  FETCH_SIP_RESPONSE_TYPES_REQUEST,
  FETCH_SUMMARY_BY_COUNTRY_REQUEST,
  FETCH_SUMMARY_BY_DIRECTION_REQUEST,
  FETCH_TOTAL_NUMBERS_PER_COUNTRY_REQUEST,
  FETCH_TOTAL_NUMBERS_PER_TYPE_REQUEST,
  FETCH_USAGE_BY_DIRECTION_REQUEST,
}                                                   from '@redux/report/report.types';
import * as UIAction                                from '@redux/ui/ui.actions';
import { createEntityAdapter, EntityAdapter }       from '@ngrx/entity';
import { UsageByDirectionData }                     from '@models/entity/usage-by-direction-data.model';
import { SummaryByDirectionData }                   from '@models/entity/summary-by-direction-data.model';
import { DestinationSummary }                       from '@models/api/destination-summary.model';
import { ExpensiveCallsItem }                       from '@models/entity/expensive-calls-item.model';
import { ReportOverviewData }                       from '@models/entity/report-overview-data.model';
import { CallLogItem }                              from '@models/entity/call-log-item.model';
import { FetchChannelUsageResponse }                from '@models/api/fetch-channel-usage-response.model';
import { ReportCountryData }                        from '@models/entity/report-country-data.model';
import { FrequentCallsItem }                        from '@models/entity/frequent-calls-item.model';
import { LocationCostDataItem }                     from '@models/entity/location-cost-data-item.model';
import { ReportQueryParams }                        from '@models/form/report-query-params.model';
import { ChartData }                                from '@models/entity/chart-data.model';
import { CDRsByDirection }                          from '@models/entity/cdrs-by-direction.model';
import { SummaryBySipCode }                         from '@models/api/summary-by-sip-code.model';
import { CountByCapability }                        from '@models/entity/count-by-capability.model';
import { Report }                                   from '@models/entity/report.model';
import { ReportListQueryParams }                    from '@models/entity/report-list-query-params.model';
import { removeItemFromList }                       from '@redux/helpers';
import {
  FetchTotalNumbersPerCountryResponse,
}                                                   from '@models/api/reporting/fetch-total-numbers-per-country-response.model';
import { TotalNumbersPerCountryData }               from '@models/entity/total-numbers-per-country-data.model';
import {
  FetchTotalNumbersPerTypeResponse,
}                                                   from '@models/api/reporting/fetch-total-numbers-per-type-response.model';
import { TotalNumbersPerTypeData }                  from '@models/entity/total-numbers-per-type-data.model';
import { AssignedNumbersByXData }                   from '@models/entity/assigned-numbers-by-x.model';
import {
  FetchAssignedNumbersTotalResponse,
}                                                   from '@models/api/reporting/fetch-assigned-numbers-total-response.model';
import {
  FetchAssignedNumbersByRangeResponse,
}                                                   from '@models/api/reporting/fetch-assigned-numbers-by-range-response.model';
import {
  FetchAssignedNumbersByCountryResponse,
}                                                   from '@models/api/reporting/fetch-assigned-numbers-by-country-response.model';
import {
  FetchAssignedNumbersByTagResponse,
}                                                   from '@models/api/reporting/fetch-assigned-numbers-by-tag-response.model';
import {
  FetchAssignedNumbersByLocationResponse,
}                                                   from '@models/api/reporting/fetch-assigned-numbers-by-location-response.model';
import { FetchRangeUsageTopResponse }               from '@models/api/reporting/fetch-range-usage-top-response.model';
import { RangeUsageTop }                            from '@models/entity/range-usage-top.model';
import { RangeExhaustionData }                      from '@models/entity/range-exhaustion-data.model';
import { SipResponseType }                          from '@models/entity/sip-response-type.model';
import { SummaryBySipType }                         from '@models/api/reporting/summary-by-sip-response.model';
import {
  FetchCallsByDirectionResponseRaw,
}                                                   from '@models/api/fetch-call-minutes-by-direction-response-raw.model';
import { CallCostSummaryByBandData }                from '@models/entity/call-cost-summary-by-band.model';

export interface ReportState extends BaseState<never> {
  error: Alert;
  message: Alert;
  pending: boolean;
  pendingTasks: Array<StateTask>;
  dateRange: DateRange;
  overviewData: ReportOverviewData;
  directionData: UsageByDirectionData;
  summaryData: SummaryByDirectionData;
  countryData: Array<ReportCountryData>;
  reportDirectionQuery: ReportQueryParams;
  reportSummaryQuery: ReportQueryParams;
  channelUsageData: UsageByDirectionData;
  channelUsageQuery: ReportQueryParams;
  callMinutesData: CDRsByDirection;
  callMinutesDataRaw: ChartData;
  callMinutesQuery: ReportQueryParams;
  callLogArray: Array<CallLogItem>;
  callLogQueryParams: ReportQueryParams;
  callLogSummaryArray: CallLogItem[];
  frequentCallsData: Array<FrequentCallsItem>;
  expensiveCallsData: Array<ExpensiveCallsItem>;
  locationCostData: Array<LocationCostDataItem>;
  destinationSummaryData: Array<DestinationSummary>;
  callsBySIPCodeData: SummaryBySipCode;
  callsBySIPTypeData: SummaryBySipType;
  countByCapability: CountByCapability;
  recordingUri: string;
  numberExportUri: string;
  transcriptData: string;
  reports: Report[];
  reportListQueryParams: ReportListQueryParams;
  selectedReport: Report;
  reportUri: string;
  totalNumbersPerCountry: TotalNumbersPerCountryData;
  totalNumbersPerCountryQueryParams: ReportQueryParams;
  totalNumbersPerType: TotalNumbersPerTypeData;
  totalNumbersPerTypeQueryParams: ReportQueryParams;
  assignedNumbersByRange: AssignedNumbersByXData;
  assignedNumbersByCountry: AssignedNumbersByXData;
  assignedNumbersByLocation: AssignedNumbersByXData;
  assignedNumbersByTag: AssignedNumbersByXData;
  assignedNumbersTotal: AssignedNumbersByXData;
  assignedNumbersByRangeQueryParams: ReportQueryParams;
  assignedNumbersByCountryQueryParams: ReportQueryParams;
  assignedNumbersByLocationQueryParams: ReportQueryParams;
  assignedNumbersByTagQueryParams: ReportQueryParams;
  assignedNumbersTotalQueryParams: ReportQueryParams;
  rangeUsageTop: RangeUsageTop;
  rangeUsageTopQueryParams: ReportQueryParams;
  rangeExhaustionData: RangeExhaustionData;
  sipResponseTypes: SipResponseType[];
  callCostData: CDRsByDirection,
  callCostDataRaw: FetchCallsByDirectionResponseRaw,
  callCostQuery: ReportQueryParams,
  callCostSummaryByBandData: CallCostSummaryByBandData,
  callCostSummaryByBandQueryParams: ReportQueryParams;
}

export const adapter: EntityAdapter<never> = createEntityAdapter<never>();

const defaultState: ReportState = adapter.getInitialState({
  error:                                null,
  message:                              null,
  pending:                              false,
  pendingTasks:                         [],
  overviewData:                         null,
  directionData:                        null,
  summaryData:                          null,
  countryData:                          null,
  reportDirectionQuery:                 null,
  reportSummaryQuery:                   null,
  channelUsageData:                     null,
  channelUsageQuery:                    null,
  callMinutesData:                      null,
  callMinutesDataRaw:                   null,
  callMinutesQuery:                     null,
  dateRange:                            null,
  callLogArray:                         null,
  callLogQueryParams:                   null,
  callLogSummaryArray:                  null,
  callsBySIPCodeData:                   null,
  callsBySIPTypeData:                   null,
  frequentCallsData:                    null,
  expensiveCallsData:                   null,
  locationCostData:                     null,
  destinationSummaryData:               null,
  recordingUri:                         null,
  numberExportUri:                      null,
  transcriptData:                       null,
  countByCapability:                    null,
  reports:                              null,
  reportListQueryParams:                null,
  selectedReport:                       null,
  reportUri:                            null,
  totalNumbersPerCountry:               null,
  totalNumbersPerCountryQueryParams:    null,
  totalNumbersPerType:                  null,
  totalNumbersPerTypeQueryParams:       null,
  assignedNumbersByRange:               null,
  assignedNumbersByRangeQueryParams:    null,
  assignedNumbersByCountry:             null,
  assignedNumbersByCountryQueryParams:  null,
  assignedNumbersByLocation:            null,
  assignedNumbersByLocationQueryParams: null,
  assignedNumbersByTag:                 null,
  assignedNumbersByTagQueryParams:      null,
  assignedNumbersTotal:                 null,
  assignedNumbersTotalQueryParams:      null,
  rangeUsageTop:                        null,
  rangeUsageTopQueryParams:             null,
  rangeExhaustionData:                  null,
  sipResponseTypes:                     null,
  callCostData:                         null,
  callCostDataRaw:                      null,
  callCostQuery:                        null,
  callCostSummaryByBandData:            null,
  callCostSummaryByBandQueryParams:     null,
});

const _reportReducer = createReducer(defaultState,
  on(ReportAction.FetchFrequentCallsRequestAction, requestReduce),
  on(ReportAction.FetchFrequentCallsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_FREQUENT_CALLS_REQUEST, res => ({
      frequentCallsData: res.data,
    }))),
  on(ReportAction.FetchCallRecordingTranscriptRequestAction, requestReduce),
  on(ReportAction.FetchCallRecordingTranscriptResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_RECORDING_TRANSCRIPT_REQUEST, res => ({
      transcriptData: res.data,
    }))),
  on(ReportAction.FetchExpensiveCallsRequestAction, requestReduce),
  on(ReportAction.FetchExpensiveCallsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_EXPENSIVE_CALLS_REQUEST, res => ({
      expensiveCallsData: res.data,
    }))),
  on(ReportAction.FetchLocationCostRequestAction, requestReduce),
  on(ReportAction.FetchLocationCostResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LOCATION_COST_REQUEST, res => ({
      locationCostData: res.data,
    }))),
  on(ReportAction.FetchDestinationSummaryRequestAction, requestReduce),
  on(ReportAction.FetchDestinationSummaryResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_DESTINATION_SUMMARY_REQUEST, res => ({
      destinationSummaryData: res.data,
    }))),
  on(ReportAction.FetchReportingOverviewRequestAction, requestReduce),
  on(ReportAction.FetchReportingOverviewResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_REPORTING_OVERVIEW_REQUEST, res => ({
      overviewData: res?.overviewData,
    }))),
  on(ReportAction.FetchUsageByDirectionRequestAction, requestReduce),
  on(ReportAction.FetchUsageByDirectionResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_USAGE_BY_DIRECTION_REQUEST, res => ({
      directionData:        res.directionData,
      reportDirectionQuery: res.error ? state.reportDirectionQuery : res.searchParams,
    }))),
  on(ReportAction.FetchCallMinutesByDirectionRequestAction, requestReduce),
  on(ReportAction.FetchCallMinutesByDirectionResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_MINUTES_BY_DIRECTION_REQUEST, res => ({
      callMinutesData:    res.callMinutesData,
      callMinutesDataRaw: res.callMinutesDataRaw,
      callMinutesQuery:   res.error ? state.callMinutesQuery : res.searchParams,
    }))),
  on(ReportAction.FetchCallCostByDirectionRequestAction, requestReduce),
  on(ReportAction.FetchCallCostByDirectionResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_COST_BY_DIRECTION_REQUEST, res => ({
      callCostData:    res.callCostData,
      callCostDataRaw: res.callCostDataRaw,
      callCostQuery:   res.error ? state.callCostQuery : res.searchParams,
    }))),
  on(ReportAction.FetchCallCostSummaryByBandRequestAction, requestReduce),
  on(ReportAction.FetchCallCostSummaryByBandResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_COST_BY_DIRECTION_REQUEST, res => ({
      callCostSummaryByBandData:        res.data,
      callCostSummaryByBandQueryParams: res.error ? state.callCostSummaryByBandQueryParams : res.searchParams,
    }))),
  on(ReportAction.FetchSummaryByDirectionRequestAction, requestReduce),
  on(ReportAction.FetchSummaryByDirectionResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SUMMARY_BY_DIRECTION_REQUEST, res => ({
      summaryData:        res.summaryData,
      reportSummaryQuery: res.error ? state.reportSummaryQuery : res.searchParams,
    }))),
  on(ReportAction.FetchSummaryByCountryRequestAction, requestReduce),
  on(ReportAction.FetchSummaryByCountryResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SUMMARY_BY_COUNTRY_REQUEST, res => ({
      countryData: res.countryData,
    }))),
  on(ReportAction.FetchReportListRequestAction, requestReduce),
  on(ReportAction.FetchReportListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_REPORT_LIST_REQUEST, res => ({
      reports:               res.error ? state.reports : res.models,
      reportListQueryParams: res.error ? state.reportListQueryParams : res.searchParams,
    }))),
  on(ReportAction.FetchChannelUsageRequestAction, requestReduce),
  on(ReportAction.FetchChannelUsageResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CHANNEL_USAGE_REQUEST, (res: FetchChannelUsageResponse) => ({
      channelUsageData:  res.channelData,
      channelUsageQuery: res.error ? state.channelUsageQuery : res.searchParams,
    }))),
  on(ReportAction.FetchTotalNumbersPerCountryRequestAction, requestReduce),
  on(ReportAction.FetchTotalNumbersPerCountryResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TOTAL_NUMBERS_PER_COUNTRY_REQUEST, (res: FetchTotalNumbersPerCountryResponse) => ({
      totalNumbersPerCountry:            res.data,
      totalNumbersPerCountryQueryParams: res.error ? state.totalNumbersPerCountryQueryParams : res.searchParams,
    }))),
  on(ReportAction.FetchTotalNumbersPerTypeRequestAction, requestReduce),
  on(ReportAction.FetchTotalNumbersPerTypeResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TOTAL_NUMBERS_PER_TYPE_REQUEST, (res: FetchTotalNumbersPerTypeResponse) => ({
      totalNumbersPerType:            res.data,
      totalNumbersPerTypeQueryParams: res.error ? state.totalNumbersPerTypeQueryParams : res.searchParams,
    }))),
  on(ReportAction.FetchRangeUsageTopRequestAction, requestReduce),
  on(ReportAction.FetchRangeUsageTopResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_RANGE_USAGE_TOP_REQUEST, (res: FetchRangeUsageTopResponse) => ({
      rangeUsageTop:            res.data,
      rangeUsageTopQueryParams: res.error ? state.rangeUsageTopQueryParams : res.searchParams,
    }))),
  on(ReportAction.FetchAssignedNumbersTotalRequestAction, requestReduce),
  on(ReportAction.FetchAssignedNumbersTotalResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TOTAL_NUMBERS_PER_TYPE_REQUEST, (res: FetchAssignedNumbersTotalResponse) => ({
      assignedNumbersTotal:            res.data,
      assignedNumbersTotalQueryParams: res.error ? state.assignedNumbersTotalQueryParams : res.searchParams,
    }))),
  on(ReportAction.FetchAssignedNumbersByRangeRequestAction, requestReduce),
  on(ReportAction.FetchAssignedNumbersByRangeResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_ASSIGNED_NUMBERS_BY_RANGE_REQUEST, (res: FetchAssignedNumbersByRangeResponse) => ({
      assignedNumbersByRange:            res.data,
      assignedNumbersByRangeQueryParams: res.error ? state.assignedNumbersByRangeQueryParams : res.searchParams,
    }))),
  on(ReportAction.FetchAssignedNumbersByCountryRequestAction, requestReduce),
  on(ReportAction.FetchAssignedNumbersByCountryResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_ASSIGNED_NUMBERS_BY_COUNTRY_REQUEST, (res: FetchAssignedNumbersByCountryResponse) => ({
      assignedNumbersByCountry:            res.data,
      assignedNumbersByCountryQueryParams: res.error ? state.assignedNumbersByCountryQueryParams : res.searchParams,
    }))),
  on(ReportAction.FetchAssignedNumbersByTagRequestAction, requestReduce),
  on(ReportAction.FetchAssignedNumbersByTagResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_ASSIGNED_NUMBERS_BY_TAG_REQUEST, (res: FetchAssignedNumbersByTagResponse) => ({
      assignedNumbersByTag:            res.data,
      assignedNumbersByTagQueryParams: res.error ? state.assignedNumbersByTagQueryParams : res.searchParams,
    }))),
  on(ReportAction.FetchAssignedNumbersByLocationRequestAction, requestReduce),
  on(ReportAction.FetchAssignedNumbersByLocationResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_ASSIGNED_NUMBERS_BY_LOCATION_REQUEST, (res: FetchAssignedNumbersByLocationResponse) => ({
      assignedNumbersByLocation:            res.data,
      assignedNumbersByLocationQueryParams: res.error ? state.assignedNumbersByLocationQueryParams : res.searchParams,
    }))),
  on(ReportAction.FetchCallLogRequestAction, requestReduce),
  on(ReportAction.FetchCallLogResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_LOG_REQUEST, res => ({
      callLogArray:       !res.error ? res.models : [],
      callLogQueryParams: res.error ? state.callLogQueryParams : res.searchParams,
    }))),
  on(ReportAction.FetchCallLogSummaryRequestAction, requestReduce),
  on(ReportAction.FetchCallLogSummaryResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_LOG_SUMMARY_REQUEST, res => ({
      callLogSummaryArray: res.models,
    }))),
  on(ReportAction.FetchCallLogRecordingRequestAction, (state, action) =>
    requestReduce(state, {
      ...action,
      type: `CL_${ action.logData.id }`,
    })),
  on(ReportAction.FetchCallLogRecordingResponseAction, (state, action) =>
    responseReduce(state, action, `CL_${ action.logData.id }`, (res) => {
      return { recordingUri: res.url };
    })),
  on(ReportAction.FetchReportRequestAction, (state, action) =>
    requestReduce(state, action, () => ({
      reportUri: null,
    }))),
  on(ReportAction.FetchReportResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_REPORT_REQUEST, (res) => {
      return {
        selectedReport: res.error ? state.selectedReport : res.data,
        reportUri:      res.error ? state.reportUri : res.data.uri,
      };
    })),
  on(ReportAction.DeleteReportRequestAction, requestReduce),
  on(ReportAction.DeleteReportResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_REPORT_REQUEST, res => ({
      selectedReport: !res.error && state.selectedReport?.id === res.id ? null : state.selectedReport,
      reports:        removeItemFromList(state.reports, res),
    }))),
  on(ReportAction.ExportCDRtoCSVRequestAction, requestReduce),
  on(ReportAction.ExportCDRtoCSVResponseAction, (state, action) =>
    responseReduce(state, action, EXPORT_CDR_TO_CSV_REQUEST)),
  on(ReportAction.SetReportingDateRange, (state, action) => ({
    ...state,
    dateRange: { startDate: action.startDate, endDate: action.endDate },
  })),
  on(ReportAction.FetchCallsBySIPCodeRequestAction, requestReduce),
  on(ReportAction.FetchCallsBySIPCodeResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALLS_BY_SIP_CODE_REQUEST, (res) => {
      return {
        callsBySIPCodeData: !res.error ? res.data : state.callsBySIPCodeData,
      };
    }),
  ),
  on(ReportAction.FetchCallsBySIPTypeRequestAction, requestReduce),
  on(ReportAction.FetchCallsBySIPTypeResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALLS_BY_SIP_TYPE_REQUEST, (res) => {
      return {
        callsBySIPTypeData: !res.error ? res.data : state.callsBySIPTypeData,
      };
    }),
  ),
  on(ReportAction.FetchCountByCapabilityRequestAction, requestReduce),
  on(ReportAction.FetchCountByCapabilityResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_COUNT_BY_CAPABILITY_REQUEST, (res) => ({ countByCapability: res.data }))),
  on(ReportAction.FetchRangeExhaustionRequestAction, requestReduce),
  on(ReportAction.FetchRangeExhaustionResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_RANGE_EXHAUSTION_REQUEST, (res) => ({ rangeExhaustionData: res.data }))),
  on(ReportAction.FetchSIPResponseTypesRequestAction, requestReduce),
  on(ReportAction.FetchSIPResponseTypesResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SIP_RESPONSE_TYPES_REQUEST, res => ({
      sipResponseTypes: res.error ? state.sipResponseTypes : res.data,
    })),
  ),
  on(ReportAction.ClearCallLogParams, (state) => ({ ...state, callLogQueryParams: null })),
  on(ReportAction.ClearCallLogParams, (state) => ({ ...state, callLogQueryParams: null })),
  on(UIAction.DismissErrorAction, (state) =>
    ({ ...state, error: null })),
  on(UIAction.DismissMessageAction, (state) =>
    ({ ...state, message: null })),
  on(AuthAction.LogoutAction, () => ({ ...defaultState })),
);

export function reportReducer(state: ReportState, action: Action): ReportState {
  return _reportReducer(state, action);
}
