import { BrowserModule }           from '@angular/platform-browser';
import { LOCALE_ID, NgModule }     from '@angular/core';
import { HttpClientModule }        from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule }        from './app-routing.module';
import { AppComponent }            from './app.component';

import { StoreModule }   from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';

import { CR_DATE_FORMATS, CustomMomentDateAdapter } from '@models/adapter/date-adapter.model';

import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CallbackHandlerComponent }        from '@core/pages/callback-handler/callback-handler.component';
import { MaintenancePageComponent }        from '@core/pages/maintenance-page/maintenance-page.component';
import { MicrosoftTeamsEffects }           from '@redux/microsoft-teams/microsoft-teams.effects';
import { AccessBrokerEffects }             from '@redux/access-broker/access-broker.effects';
import { PageHeaderComponent }             from '@core/components/general/page-header/page-header.component';
import { PageNotFoundComponent }           from '@core/pages/page-not-found/page-not-found.component';
import { TIMEZONE }                        from './tokens/timezone.token';
import { ConfigurationEffects }            from '@redux/configuration/configuration.effects';
import {
  ViewCustomerHeaderComponent,
}                                          from '@core/components/general/view-customer-header/view-customer-header.component';
import { LocaleService }                   from '@services/locale.service';
import { MainMenuComponent }               from '@core/components/general/main-menu/main-menu.component';
import {
  ShrinkProgressBarComponent,
}                                          from '@core/components/general/shrink-progress-bar/shrink-progress-bar.component';
import { TextNotificationComponent }       from './modules/notifications/text-notification/text-notification.component';
import { NumberEffects }                   from '@redux/number/number.effects';
import { ReportEffects }                   from '@redux/report/report.effects';
import { AccountingEffects }               from '@redux/accounting/accounting.effects';
import { RegistrationEffects }             from '@redux/registration/registration.effects';
import { CompanyEffects }                  from '@redux/company/company.effects';
import { SubscriptionEffects }             from '@redux/subscription/subscription.effects';
import { NotificationEffects }             from '@redux/notification/notification.effects';
import { store }                           from '@redux/store';
import { ServiceEffects }                  from '@redux/service/service.effects';
import { AuthEffects }                     from '@redux/auth/auth.effects';
import { RedirectingComponent }            from '@core/pages/redirecting/redirecting.component';
import { LogoutPageComponent }             from '@core/pages/logout/logout-page.component';
import { AccessRestrictedPageComponent }   from '@core/pages/access-restricted-page/access-restricted-page.component';
import { LayoutModule }                    from './modules/layout/layout.module';
import {
  TrialExpiryHeaderComponent,
}                                          from '@core/components/general/trial-expiry-header/trial-expiry-header.component';
import { StarRatingModule }                from 'angular-star-rating';
import { TaskNotificationComponent }       from '@core/components/tasks/task-notification/task-notification.component';
import { TaskManagerComponent }            from '@core/components/tasks/task-manager/task-manager.component';
import { TaskComponent }                   from '@core/components/tasks/task/task.component';
import { MatSelectModule }                 from '@angular/material/select';
import { MatIconModule }                   from '@angular/material/icon';
import { NgxDaterangepickerMd }            from 'ngx-daterangepicker-material';
import { MatProgressSpinnerModule }        from '@angular/material/progress-spinner';
import { MatMenuModule }                   from '@angular/material/menu';
import { MatBadgeModule }                  from '@angular/material/badge';
import { DirectiveModule }                 from './modules/directive/directive.module';
import { PublicSharedModule }              from './modules/public-shared/public-shared.module';
import { AuditEffects }                    from '@redux/audit/audit.effects';
import { NotificationSharedModule }        from './modules/notification-shared/notification-shared.module';
import { MatBottomSheetModule }            from '@angular/material/bottom-sheet';
import { MatDividerModule }                from '@angular/material/divider';
import { MatTooltipModule }                from '@angular/material/tooltip';
import { MatButtonModule }                 from '@angular/material/button';
import { SheetModule }                     from './modules/sheet/sheet.module';
import { ToastrModule }                    from 'ngx-toastr';
import { MatListModule }                   from '@angular/material/list';
import { MatProgressBarModule }            from '@angular/material/progress-bar';
import { MatSidenavModule }                from '@angular/material/sidenav';
import { LoginPageComponent }              from './core/pages/login-page/login-page.component';
import { ButtonModule }                    from './modules/button/button.module';
import { NewUserPageComponent }            from './core/pages/new-user-page/new-user-page.component';
import {
  NoLocalLoginBannerComponent,
}                                          from './core/components/general/no-local-login-banner/no-local-login-banner.component';
import { GlobalBannerComponent }           from './core/components/general/global-banner/global-banner.component';
import { PluralPipe }                      from '@pipes/plural.pipe';
import { LocaleDatePipe }                  from '@pipes/locale-date.pipe';
import { FlexLayoutModule }                from '@angular/flex-layout';
import { NgClass, NgIf, NgStyle }          from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    CallbackHandlerComponent,
    MaintenancePageComponent,
    PageHeaderComponent,
    MainMenuComponent,
    ViewCustomerHeaderComponent,
    TextNotificationComponent,
    ShrinkProgressBarComponent,
    RedirectingComponent,
    LogoutPageComponent,
    AccessRestrictedPageComponent,
    TrialExpiryHeaderComponent,
    TaskManagerComponent,
    TaskComponent,
    TaskNotificationComponent,
    LoginPageComponent,
    NewUserPageComponent,
    NoLocalLoginBannerComponent,
    GlobalBannerComponent,
  ],
  imports:      [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(store),
    EffectsModule.forRoot([
      AuthEffects,
      RegistrationEffects,
      CompanyEffects,
      NumberEffects,
      ReportEffects,
      ServiceEffects,
      AccountingEffects,
      NotificationEffects,
      SubscriptionEffects,
      ConfigurationEffects,
      AccessBrokerEffects,
      MicrosoftTeamsEffects,
      AuditEffects,
    ]),
    FlexLayoutModule,
    NgIf,
    NgClass,
    NgStyle,
    StarRatingModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    LayoutModule,
    NotificationSharedModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    SheetModule,
    MatSelectModule,
    MatBottomSheetModule,
    PublicSharedModule,
    MatMenuModule,
    MatBadgeModule,
    DirectiveModule,
    MatDividerModule,
    ToastrModule.forRoot({
      maxOpened: 4,
      timeOut:   6_000,
    }),
    MatListModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    ButtonModule,
    PluralPipe,
    LocaleDatePipe,
  ],
  providers:    [
    {
      provide:  DateAdapter,
      useClass: CustomMomentDateAdapter,
      deps:     [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide:    LOCALE_ID,
      useFactory: (localeService: LocaleService) => localeService.getLocale(),
      deps:       [LocaleService],
    },
    {
      provide:    TIMEZONE,
      useFactory: (localeService: LocaleService) => localeService.getTimeZone(),
      deps:       [LocaleService],
    },
    { provide: MAT_DATE_FORMATS, useValue: CR_DATE_FORMATS },
  ],
  bootstrap:    [AppComponent],
})
export class AppModule {
}
