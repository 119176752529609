import { SecurityGroupRaw } from '@models/api/identity/security-group-raw.model';

export class SecurityGroup {
  id: string;
  name: string;
  description: string;
  requestId: string;

  constructor(private data?: SecurityGroup) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: SecurityGroupRaw, requestId?: string): SecurityGroup {
    this.id          = data.id;
    this.name        = data.name;
    this.description = data.description;
    this.requestId   = requestId;

    return this;
  }
}
