import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';
import { ProviderIdentifier }   from '@enums/provider-identifier.enum';
import { ExhaustionStatus }     from '@enums/exhaustion-status.enum';

export class NumberRangeQueryParams extends ListParams implements ListParamModel<NumberRangeQueryParams> {
  search?: string;
  provider?: ProviderIdentifier[];
  exhaustionStatus?: ExhaustionStatus[];
  include?: string[] = ['country'];
  sort?              = 'Country.name';

  static constructQueryString(searchParams: NumberRangeQueryParams): string {
    const params: NumberRangeQueryParams = { ...(searchParams || {}) as NumberRangeQueryParams };

    return ListParams.constructQueryString(params, {
      search:           'filter[search]',
      provider:         'filter[provider][]',
      exhaustionStatus: 'filter[exhaustion_status][]',
      include:          'include[]',
    });
  }

  static queryChanged(newQuery: NumberRangeQueryParams, currentQuery: NumberRangeQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery) || (newQuery.search !== currentQuery.search);
  }

  constructParams?(metaData: ListResponseMetadata): NumberRangeQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.search) {
        this.search = metaData.filter.search;
      }
      if (metaData.filter.provider) {
        this.provider = metaData.filter.provider as ProviderIdentifier[];
      }
      if (metaData.filter.exhaustion_status) {
        this.exhaustionStatus = metaData.filter.exhaustion_status;
      }
      if (metaData.include) {
        this.include = metaData.include;
      }
    }

    return this;
  }
}
