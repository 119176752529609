import { RouteRule }      from '@models/entity/route-rule.model';
import { NumberRouteRaw } from '@models/api/number-route-raw.model';

export class NumberRoute {
  id: string;
  reservation?: string;
  rules?: RouteRule[];

  fromApiData?(apiData: NumberRouteRaw): NumberRoute {
    if (!apiData) {
      return null;
    }
    this.id    = apiData.id;
    this.rules = apiData.rules?.map(rule => new RouteRule().fromApiData(rule)) || [];

    return this;
  }

}
