import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IPValidator }                                                        from '@validators/ip.validator';
import {
  ServiceCarrier,
}                                                                             from '@models/entity/carrier-service.model';
import { ServiceType }                                                        from '@enums/service-type.enum';
import {
  ConfigurableCarrierInput,
}                                                                             from '@enums/configurable-carrier-input.enum';

export class CarrierAdapter {
  protected newIPAddrCtrl = (data: string, allowDomain?: boolean, required?: boolean): UntypedFormControl => {
    const validators = required ?
      [Validators.required, Validators.maxLength(128), allowDomain ? IPValidator.validAddr : IPValidator.validIP] :
      [Validators.maxLength(128), allowDomain ? IPValidator.validAddr : IPValidator.validIP];

    return new UntypedFormControl(data, validators);
  };

  protected newCIDRAddrCtrl = (data: string): UntypedFormControl =>
    new UntypedFormControl(data, [Validators.required, Validators.maxLength(128), IPValidator.validCIDR]);

  constructor(protected data: ServiceCarrier) {

  }

  getForm?(): UntypedFormGroup {
    const data = this.data;

    const form = new UntypedFormGroup(
      {
        id:          new UntypedFormControl(data?.id),
        label:       new UntypedFormControl(data?.label || '', [Validators.maxLength(30)]),
        carrier:     new UntypedFormControl(data?.carrierData?.identifier),
        serviceType: new UntypedFormControl(ServiceType.Carrier),
        numberId:    new UntypedFormControl(data?.numberId),
        number:      new UntypedFormControl(data?.numberData),
        numberQuery: new UntypedFormControl(data?.numberData),
        carrierData: new UntypedFormControl(data?.carrierData),
      },
    );

    if (!data.carrierData.inputs?.length) {
      return form;
    }

    const addressSpaces = data?.addressSpaces?.map((addr: string) => this.newCIDRAddrCtrl(addr));

    if (data.carrierData.inputs.includes(ConfigurableCarrierInput.AddressSpaces)) {
      form.addControl('addressSpaces', new UntypedFormArray(
        (data.carrierData.identifier !== 'custom' || data.id) && addressSpaces?.length ?
          addressSpaces :
          [this.newCIDRAddrCtrl(null)]));
    }

    if (data.carrierData.inputs.includes(ConfigurableCarrierInput.IsSipOptionsEnabled)) {
      form.addControl('isSipOptionsEnabled', new UntypedFormControl(!!data?.isSipOptionsEnabled));
    }

    if (data.carrierData.inputs.includes(ConfigurableCarrierInput.Realm)) {
      form.addControl('realm', this.newIPAddrCtrl(data.realm, true));
    }

    if (data.carrierData.inputs.includes(ConfigurableCarrierInput.RegistrarAddress)) {
      form.addControl('registrarAddress', new UntypedFormControl(data.registrarAddress));
    }

    if (data.carrierData.inputs.includes(ConfigurableCarrierInput.Username)) {
      form.addControl('isRegistrationRequired', new UntypedFormControl(data.carrierData.identifier !== 'custom'));
      form.addControl('username', new UntypedFormControl(null, [Validators.max(1), Validators.max(64)]));
    }
    if (data.carrierData.inputs.includes(ConfigurableCarrierInput.Password)) {
      form.addControl('password', new UntypedFormControl(null, [Validators.min(1), Validators.max(64)]));
    }

    return form;
  }
}
