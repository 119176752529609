import { AuthScope }        from '@enums/auth-scope.enum';
import { UserRaw }          from '../api/user-raw.model';
import { Role }             from '@models/entity/role.model';
import { UserStatus }       from '@enums/user-status.enum';
import { CompanyRaw }       from '@models/api/company-raw.model';
import { IdpIdentityRaw }   from '@models/api/identity/idp-identity-raw.model';
import { ApprovalStatus }   from '@enums/approval-status.enum';
import { AssetsService }    from '@services/assets.service';
import { IdpIdentifier }    from '@enums/IdpIdentifier.enum';
import { SecurityGroup }    from '@models/entity/security-group.model';
import { SecurityGroupRaw } from '@models/api/identity/security-group-raw.model';

export class User {
  email?: string;
  id?: string;
  firstName?: string;
  lastName?: string;
  companyId?: string;
  companyName?: string;
  phoneNumber?: string;
  status?: UserStatus;
  statusDescription?: string;
  statusColor?: string;
  avatar?: string;
  currencyCode?: string;
  roles?: Role[];
  securityGroups?: SecurityGroup[];
  isOtpEnabled?: boolean;
  isPartner?: boolean;
  scopes?: AuthScope[];
  loginDate?: string;
  isLocalLogin?: boolean;
  idpIdentities?: IdpIdentityRaw[];
  approvalStatus?: ApprovalStatus;

  constructor(private data?: User) {
    Object.assign(this, data);
    delete this['data'];
  }

  fromApiData?(user: UserRaw, company?: CompanyRaw): User {
    this.id                = user.id;
    this.email             = user.email;
    this.firstName         = user.first_name;
    this.lastName          = user.last_name;
    this.companyId         = user.company?.id;
    this.phoneNumber       = user.phone_number;
    this.status            = user.status;
    this.statusDescription = this.getStatusDescription();
    this.isOtpEnabled      = user.is_otp_enabled;
    this.isPartner         = !!company?.is_partner;
    this.companyName       = user.company?.name;
    this.loginDate         = user.login_date;
    this.isLocalLogin      = user.is_local_login;
    this.idpIdentities     = user.idp_identities || [];
    this.roles             = user.roles?.map(({ name, id, description }) =>
      new Role().fromApiData({ name, id, description })) || [];
    this.securityGroups    = user.security_groups?.map((group: SecurityGroupRaw) =>
      new SecurityGroup().fromApiData(group)) || [];
    switch (user.status) {
      case UserStatus.Pending:
        this.statusColor = 'orange';
        break;
      case UserStatus.Deleted:
        this.statusColor = 'red';
        break;
      case UserStatus.Active:
        this.statusColor = 'green';
        break;
      default:
        this.statusColor = 'light-gray';
        break;
    }

    return this;
  }

  getStatusDescription?(): string {
    switch (this.status) {
      case UserStatus.Active:
        return 'Active';
      case UserStatus.Inactive:
        return 'Inactive';
      case UserStatus.Deleted:
        return 'Deleted';
      case UserStatus.Pending:
        return 'Pending';
    }
  }

  getLoginCapabilities?(): { name: string, icon: string }[] {
    const capabilities = [];
    if (this.idpIdentities?.some(identity => identity.provider === IdpIdentifier.Microsoft)) {
      capabilities.push({
        name: 'Microsoft SSO',
        icon: AssetsService.getIcon('microsoft-logo.png'),
      });
    }
    if (this.isLocalLogin) {
      capabilities.push({
        name: 'Callroute',
        icon: AssetsService.getFavicon('favicon-32x32.png'),
      });
    }
    return capabilities;
  }

}
