import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ServiceCarrier,
}                                                                             from '@models/entity/carrier-service.model';
import { CarrierAdapter }                                                     from './carrier.adapter';
import {
  ConfigurableCarrierInput,
}                                                                             from '@enums/configurable-carrier-input.enum';

export class GenericAdapter extends CarrierAdapter {

  constructor(protected data: ServiceCarrier) {
    super(data);
  }

  getForm?(): UntypedFormGroup {

    const form = super.getForm();
    const data = this.data;

    if (data.carrierData.inputs.includes(ConfigurableCarrierInput.Gateways)) {

      form.addControl('gateways', new UntypedFormArray([
        new UntypedFormGroup({
          priority: new UntypedFormControl(1),
          address:  this.newIPAddrCtrl(data.carrierData.identifier !== 'custom' || data.id ? data?.gateways?.[0]?.address : null, true, true),
          protocol: new UntypedFormControl(data.carrierData.identifier !== 'custom' || data.id ? data?.gateways?.[0]?.protocol : null, Validators.required),
          port:     new UntypedFormControl(data.carrierData.identifier !== 'custom' || data.id ? data?.gateways?.[0]?.port : null, [Validators.required, Validators.min(1), Validators.max(65535)]),
        }),
        new UntypedFormGroup({
          priority: new UntypedFormControl(2),
          address:  this.newIPAddrCtrl(data.carrierData.identifier !== 'custom' || data.id ? data?.gateways?.[1]?.address : null, true),
          protocol: new UntypedFormControl(data.carrierData.identifier !== 'custom' || data.id ? data?.gateways?.[1]?.protocol : null),
          port:     new UntypedFormControl(data.carrierData.identifier !== 'custom' || data.id ? data?.gateways?.[1]?.port : null, [Validators.min(1), Validators.max(65535)]),
        })]));

      form.addControl('useDNSSRV', new UntypedFormControl(!!data?.useDNSSRV));
    }

    if (data.carrierData.inputs.includes(ConfigurableCarrierInput.RegistrarString)) {
      form.addControl('registrarString', new UntypedFormControl(data.registrarString));
    }

    return form;
  }
}
