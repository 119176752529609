import { CallQueueRaw } from '@models/api/microsoft-teams/call-queue-raw.model';

export class CallQueue {
  id: string;
  name: string;
  description: string;
  createdDate: string;
  updatedDate: string;

  constructor(private data?: CallQueue) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: CallQueueRaw): CallQueue {
    this.id          = data.id;
    this.name        = data.name;
    this.description = data.description;
    this.createdDate = data.created_date;
    this.updatedDate = data.updated_date;
    return this;
  }
}
