import { createAction, props } from '@ngrx/store';

import {
  ActivateAccountRequest,
  ActivateAccountResponse,
  RegisterRequest,
  RegisterResponse,
  ResendActivationRequest,
  ResendActivationResponse
} from '../../models/entity/registration.model';
import {
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_RESPONSE,
  CLEAR_REGISTRATION_REDUCER,
  REGISTER_REQUEST,
  REGISTER_RESPONSE,
  RESEND_ACTIVATION_REQUEST,
  RESEND_ACTIVATION_RESPONSE
} from './registration.types';

export const RegisterRequestAction          = createAction(REGISTER_REQUEST, props<RegisterRequest>());
export const RegisterResponseAction         = createAction(REGISTER_RESPONSE, props<RegisterResponse>());
export const ResendActivationRequestAction  = createAction(RESEND_ACTIVATION_REQUEST, props<ResendActivationRequest>());
export const ResendActivationResponseAction = createAction(RESEND_ACTIVATION_RESPONSE, props<ResendActivationResponse>());
export const ActivateAccountRequestAction   = createAction(ACTIVATE_ACCOUNT_REQUEST, props<ActivateAccountRequest>());
export const ActivateAccountResponseAction  = createAction(ACTIVATE_ACCOUNT_RESPONSE, props<ActivateAccountResponse>());
export const ClearRegistrationReducerAction = createAction(CLEAR_REGISTRATION_REDUCER);
