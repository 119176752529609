<ngx-modal-head header="Authorisation failed"></ngx-modal-head>
<ngx-modal-content [background]="false"
                   icon="cr-key-outline"
                   iconColor="#464775"
                   [noPadding]="true"
                   iconBgColor="rgba(70, 71, 117, 0.1)">
  <p class="mat-body-1 text-align-center mb-16" *ngIf="errorMessage">
    Error: {{errorMessage}}
  </p>
  <p class="mat-body-1 text-align-center">Before adding your Microsoft Teams service, be sure to check our
    <a href="https://help.callroute.com/space/CKB/698843188/Pre-requisites"
       target="_blank">Knowledgebase
      article</a> to ensure you have everything
    you need to add the service successfully.</p>
  <br>
</ngx-modal-content>
<ngx-modal-actions>
  <button type="button"
          #modalAction
          data-cy="submit"
          (click)="authorise()"
          class="button primary"
          matRipple>
    Authorise
  </button>
</ngx-modal-actions>
