export class SpendSeries {
  id: string;
  name: string;

  static fromApiData(apiData: SpendSeries): SpendSeries {
    const s: SpendSeries = new SpendSeries();

    s.id   = apiData.id;
    s.name = apiData.name;

    return s;
  }
}
