import { Injectable }                                 from '@angular/core';
import { AccountingService }                          from '@services/accounting.service';
import { Store }                                      from '@ngrx/store';
import { StoreState }                                 from '../store';
import { Actions, createEffect, ofType }              from '@ngrx/effects';
import {
  DetermineFetchLastPaymentListRequestAction,
  ExtendFreeTrialRequestAction,
  ExtendFreeTrialResponseAction,
  FetchBalanceDataRequestAction,
  FetchBalanceDataResponseAction,
  FetchCreditReportRequestAction,
  FetchCreditReportResponseAction,
  FetchFeatureInventoryRequestAction,
  FetchFeatureInventoryResponseAction,
  FetchLastPaymentListRequestAction,
  FetchLastPaymentListResponseAction,
  FetchLastPaymentSummaryRequestAction,
  FetchLastPaymentSummaryResponseAction,
  FetchPaymentMethodsRequestAction,
  FetchPaymentMethodsResponseAction,
  FetchSpendReportRequestAction,
  FetchSpendReportResponseAction,
  TopupRequestAction,
  TopupResponseAction,
}                                                     from './accounting.actions';
import { map, pluck, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FetchPaymentMethodsResponse }                from '@models/api/fetch-payment-methods-response.model';
import { TopupRequest }                               from '@models/api/topup-request.model';
import { TopupResponse }                              from '@models/api/topup-response.model';
import { FetchReportByDateRequest }                   from '@models/api/fetch-report-by-date-request.model';
import { FetchSpendReportResponse }                   from '@models/api/fetch-spend-report-response.model';
import { FetchLastPaymentListResponse }               from '@models/api/fetch-last-payment-list-response.model';
import { FeatureInventoryResponse }                   from '@models/api/feature-inventory-response.model';
import { AccountingQueryParams }                      from '@models/form/accounting-query-params.model';
import { FetchBalanceDataResponse }                   from '@models/api/fetch-balance-data-response.model';
import { FetchLastPaymentListRequest }                from '@models/api/fetch-last-payment-list-request.model';
import { FetchCreditReportResponse }                  from '@models/api/fetch-credit-report-response.model';
import { FetchLastPaymentSummaryResponse }            from '@models/api/fetch-last-payment-summary-response.model';
import { DateRange }                                  from '@models/form/date-range.model';
import { selectAccounting }                           from './accounting.selectors';
import { withThrottle }                               from '@rxjs/action-throttle.operator';
import { ExtendFreeTrialRequest }                     from '@models/api/accounting/extend-free-trial-request.model';
import { ExtendFreeTrialResponse }                    from '@models/api/accounting/extend-free-trial-response.model';
import { FetchActiveLicenseRequestAction }            from '@redux/subscription/subscription.actions';
import { withScopes }                                 from '@rxjs/with-scopes.operator';
import { selectUserScopes }                           from '@redux/auth/auth.selectors';
import { AuthScope }                                  from '@enums/auth-scope.enum';

@Injectable()
export class AccountingEffects {
  constructor(
    private actions$: Actions,
    private accountingService: AccountingService,
    private store: Store<StoreState>,
  ) {}

  fetchBalanceData$ = createEffect(() => this.actions$.pipe(
    ofType(FetchBalanceDataRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.PaymentRead]),
    withThrottle(),
    switchMap(() =>
      this.accountingService.fetchBalanceData$()
        .pipe(
          map((res: FetchBalanceDataResponse) => FetchBalanceDataResponseAction(res)),
        ),
    ),
  ));

  fetchFeaturesInventory$ = createEffect(() => this.actions$.pipe(
    ofType(FetchFeatureInventoryRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyRead]),
    withThrottle(),
    switchMap(() =>
      this.accountingService.fetchFeatureInventory$()
        .pipe(
          map((res: FeatureInventoryResponse) => FetchFeatureInventoryResponseAction(res)))),
  ));

  fetchPaymentMethods$ = createEffect(() => this.actions$.pipe(
    ofType(FetchPaymentMethodsRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.PaymentRead]),
    withThrottle(),
    switchMap(() =>
      this.accountingService.fetchPaymentMethods$()
        .pipe(
          map((res: FetchPaymentMethodsResponse) => FetchPaymentMethodsResponseAction(res)),
        ),
    ),
  ));

  topupBalance$ = createEffect(() => this.actions$.pipe(
    ofType(TopupRequestAction),
    switchMap((req: TopupRequest) =>
      this.accountingService.topupBalance$(req)
        .pipe(
          map((res: TopupResponse) => TopupResponseAction(res)),
        ),
    ),
  ));

  topupBalanceRefresh$ = createEffect(() => this.actions$.pipe(
    ofType(TopupResponseAction),
    tap(() => this.store.dispatch(FetchLastPaymentSummaryRequestAction(null))),
    tap(() => this.store.dispatch(FetchBalanceDataRequestAction({}))),
    tap(() => this.store.dispatch(FetchSpendReportRequestAction(null))),
    tap(() => this.store.dispatch(FetchCreditReportRequestAction(null))),
  ), { dispatch: false });

  fetchSpendReport$ = createEffect(() => this.actions$.pipe(
    ofType(FetchSpendReportRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.PaymentRead]),
    withLatestFrom(this.store.select(selectAccounting)
      .pipe(pluck('dateRange'))),
    switchMap(([req, dateRange]: [FetchReportByDateRequest, DateRange]) => {
      return this.accountingService.fetchSpendReport$({
          ...req,
          queryParams: {
            ...req.queryParams,
            since: req.queryParams.since || dateRange.startDate,
            until: req.queryParams.until || dateRange.endDate,
          },
        },
      );
    }),
    map((res: FetchSpendReportResponse) => FetchSpendReportResponseAction(res)),
  ));

  fetchCreditReport$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCreditReportRequestAction),
    withThrottle(),
    withLatestFrom(this.store.select(selectAccounting)
      .pipe(pluck('dateRange'))),
    switchMap(([req, dateRange]: [FetchReportByDateRequest, DateRange]) => {
      return this.accountingService.fetchCreditReport$({
        ...req,
        queryParams: {
          ...req.queryParams,
          since: req.queryParams.since || dateRange.startDate,
          until: req.queryParams.until || dateRange.endDate,
        },
      });
    }),
    map((res: FetchCreditReportResponse) => FetchCreditReportResponseAction(res)),
  ));

  determineFetchLastPaymentList$ = createEffect(() => this.actions$.pipe(
    ofType(DetermineFetchLastPaymentListRequestAction),
    withThrottle(),
    withLatestFrom(this.store),
    map(([req, storeState]: [FetchLastPaymentListRequest, StoreState]): void => {
      if (storeState.accountingReducer &&
        AccountingQueryParams.queryChanged(req.queryParams,
          storeState.accountingReducer.lastPaymentQueryParams)) {
        this.store.dispatch(FetchLastPaymentListRequestAction(req));
      }
    }),
  ), { dispatch: false });

  fetchLastPaymentList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLastPaymentListRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.PaymentRead]),
    withLatestFrom(this.store.select(selectAccounting)
      .pipe(pluck('lastPaymentQueryParams'))),
    switchMap(([req, lastQuery]: [FetchLastPaymentListRequest, AccountingQueryParams]) =>
      this.accountingService.fetchLastPaymentList$(req, lastQuery)
        .pipe(
          map((res: FetchLastPaymentListResponse) => FetchLastPaymentListResponseAction(res)),
        ),
    ),
  ));

  fetchLastPaymentSummary$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLastPaymentSummaryRequestAction),
    withThrottle(),
    withLatestFrom(this.store.select(selectAccounting)
      .pipe(pluck('lastPaymentQueryParams'))),
    switchMap(([req, lastQuery]: [FetchLastPaymentListRequest, AccountingQueryParams]) =>
      this.accountingService.fetchLastPaymentSummary$(req, lastQuery)
        .pipe(
          map((res: FetchLastPaymentSummaryResponse) => FetchLastPaymentSummaryResponseAction(res)),
        ),
    ),
  ));

  extendFreeTrial$ = createEffect(() => this.actions$.pipe(
    ofType(ExtendFreeTrialRequestAction),
    switchMap((req: ExtendFreeTrialRequest) => this.accountingService.extendFreeTrial$(req)),
    map((res: ExtendFreeTrialResponse) => ExtendFreeTrialResponseAction(res)),
    tap(() => this.store.dispatch(FetchActiveLicenseRequestAction({}))),
  ));
}
