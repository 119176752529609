import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  RouterStateSnapshot,
  UrlTree,
}                     from '@angular/router';
import {
  LogoutAction,
}                     from '@redux/auth/auth.actions';
import {
  StoreState,
}                     from '@redux/store';
import {
  Store,
}                     from '@ngrx/store';
import {
  Location,
}                     from '@angular/common';
import {
  AuthService,
}                     from '@services/auth.service';
import {
  splitURLByPath,
}                     from '@util/url.helper';
import {
  selectUser,
}                     from '@redux/auth/auth.selectors';
import {
  filter,
  take,
}                     from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router,
              private location: Location,
              private store: Store<StoreState>,
              private authService: AuthService) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.canAccess(state.url);
  }

  canActivateChild(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.canAccess(state.url);
  }

  canLoad(): Promise<boolean | UrlTree> {
    return this.canAccess(this.router.getCurrentNavigation()
      .extractedUrl
      .toString());
  }

  private async canAccess(url: string): Promise<boolean | UrlTree> {
    if (await this.authService.hasAuth()) {
      const hasRegistered = await this.authService.hasRegistered(true);
      if (!hasRegistered) {
        return this.router.parseUrl('/complete-registration');
      }
      const user = await this.store.select(selectUser)
        .pipe(
          filter(user => !!user),
          take(1),
        )
        .toPromise();
      if (!user.scopes?.length) {
        return this.router.parseUrl('/user-approval');
      }
      return true;
    }
    this.store.dispatch(LogoutAction({ redirectUrl: url }));
    const email = splitURLByPath(this.location.path()).queryParams?.email;
    const u     = `/${ email ? `?email=${ encodeURIComponent(email) }` : '' }`;
    return this.router.parseUrl(u);
  }
}
