import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
}                                                                        from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject }           from 'rxjs';
import { ServiceState }                                                  from '@redux/service/service.reducer';
import { UntypedFormArray, UntypedFormGroup }                            from '@angular/forms';
import { Store }                                                         from '@ngrx/store';
import { StoreState }                                                    from '@redux/store';
import { MAT_DIALOG_DATA, MatDialogRef }                                 from '@angular/material/dialog';
import { filter, map, pairwise, pluck, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { SipTrunkService }                                               from '@services/sip-trunk.service';
import { SIPTrunk }                                                      from '@models/entity/sip-trunk.model';
import { DismissErrorAction, DismissMessageAction }                      from '@redux/ui/ui.actions';
import { selectService }                                                 from '@redux/service/service.selectors';
import { SIPTrunkRaw }                                                   from '@models/api/sip-trunk-raw.model';
import { PATCH_SERVICE_REQUEST, POST_SERVICE_REQUEST }                   from '@redux/service/service.types';
import { PatchServiceRequestAction, PostServiceRequestAction }           from '@redux/service/service.actions';
import { ServiceItemRaw }                                                from '@models/api/service-api-response.model';
import { FormService }                                                   from '@services/form.service';
import { AuthScope }                                                     from '@enums/auth-scope.enum';
import { ScopeService }                                                  from '@services/scope.service';
import { ButtonType }                                                    from '@enums/button-type.enum';

@Component({
  selector:    'ngx-sip-trunk-modal',
  templateUrl: './sip-trunk-modal.component.html',
  styleUrls:   ['./sip-trunk-modal.component.scss'],
})
export class SipTrunkModalComponent implements OnDestroy, OnChanges {

  @Input() service: SIPTrunk;
  serviceReducer$: Observable<ServiceState>;
  formGroup: UntypedFormGroup;
  editMode       = new BehaviorSubject(false);
  editMode$: Observable<boolean>;
  destroy        = new Subject<void>();
  destroy$       = this.destroy.asObservable();
  containsDupes  = new BehaviorSubject(false);
  containsDupes$ = this.containsDupes.asObservable();
  isPending$: Observable<boolean>;
  submitDisabled$: Observable<boolean>;
  btnDisabled$: Observable<boolean>;
  formValueChanged$: Observable<boolean>;
  ButtonType     = ButtonType;

  get subheader(): string {
    return `Create a SIP trunk to connect Callroute to a 3rd party endpoint such as a PBX, ATA (Analogue Telephony Adapter) or Contact Centre. 
For more information, please refer to the <a href="https://help.callroute.com/space/CKB/699072569/Connect+Your+PBX" target="_blank">knowledge base article</a>.`;
  }

  private sipTrunkData: SIPTrunk;
  private formFields = new Map<keyof SIPTrunkRaw, string>([
    ['gateway_port', 'Gateway port'],
    ['gateway_protocol', 'Gateway protocol'],
    ['gateway_address', 'Gateway address'],
    ['label', 'Label'],
    ['address_spaces', 'Address spaces'],
  ]);

  constructor(
    private store: Store<StoreState>,
    private changeDetectorRef: ChangeDetectorRef,
    private sipTrunkService: SipTrunkService,
    private scopeService: ScopeService,
    @Optional() public dialogRef: MatDialogRef<SipTrunkModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { service: SIPTrunk, label: string, icon: string },
  ) {
    this.btnDisabled$ = this.scopeService.hasScopes$(AuthScope.ServiceWrite)
      .pipe(map(hasScope => !hasScope));

    if (this.dialogRef) {
      this.initForm(this.data.service);
    }
  }

  private initForm(data: SIPTrunk): void {
    if (!this.service) {
      this.service = data;
    }
    this.serviceReducer$ = this.store.select(selectService);

    this.isPending$ = this.serviceReducer$.pipe(map(reducer =>
      reducer.pendingTasks.some(task => [POST_SERVICE_REQUEST, PATCH_SERVICE_REQUEST].includes(task.id))));

    this.sipTrunkData = this.service as SIPTrunk;

    this.formGroup = this.sipTrunkService.getNewForm(this.sipTrunkData);

    this.editMode$ = this.editMode.asObservable()
      .pipe(
        map(edit => edit || this.isSetup()),
        tap(edit => {
          if (edit) {
            this.formGroup?.enable();
            this.formGroup.get('callrouteGatewayAddress')
              .disable();
            this.changeDetectorRef.detectChanges();
            return;
          }
          this.formGroup?.disable();
          this.changeDetectorRef.detectChanges();
        }));

    this.setupValidators();

    this.setupAutoPortFiller();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.service?.currentValue && !changes.service?.previousValue) {
      this.initForm(changes.service.currentValue);
    }
  }

  isSetup(): boolean {
    if (!this.sipTrunkData) {
      return true;
    }
    return Object.values(this.sipTrunkData)
      .every(val => !val);
  }

  editClick(): void {
    if (this.editMode.value) {
      this.revertForm();
    }
    this.editMode.next(!this.editMode.value);
  }

  revertForm(): void {
    const data = this.sipTrunkData;
    this.formGroup.reset({
      serviceType:             data?.serviceType,
      label:                   data?.label,
      gatewayAddress:          data?.gatewayAddress,
      gatewayProtocol:         data?.gatewayProtocol,
      gatewayHasSipOptions:    !!data?.gatewayHasSipOptions,
      gatewayPort:             data?.gatewayPort,
      addressSpaces:           data?.addressSpaces || [],
      callrouteGatewayAddress: data?.callrouteGatewayAddress,
    });
    (this.formGroup.get('addressSpaces') as UntypedFormArray)
      .clear();
    let i = 0;
    for (const addressSpace of data.addressSpaces) {
      (this.formGroup.get('addressSpaces') as UntypedFormArray).insert(i, this.sipTrunkService.newCIDRAddrCtrl(addressSpace));
      i++;
    }
  }

  async submitForm(): Promise<void> {
    if (this.formGroup.valid && !this.containsDupes.value) {
      this.store.dispatch(DismissErrorAction());
      this.store.dispatch(DismissMessageAction());
      const value      = this.formGroup.value;
      const formFields = this.formFields as unknown as Map<keyof ServiceItemRaw, string>;
      if (this.service) {
        this.store.dispatch(PatchServiceRequestAction({
          serviceItem: { ...value, id: this.service.id },
          formFields,
        }));
        this.sipTrunkData = new SIPTrunk({
          ...this.sipTrunkData,
          label:                value.label,
          gatewayAddress:       value.gatewayAddress,
          gatewayProtocol:      value.gatewayProtocol,
          gatewayHasSipOptions: value.gatewayHasSipOptions,
          gatewayPort:          value.gatewayPort,
          addressSpaces:        value.addressSpaces,
        } as SIPTrunk);
        this.setupValidators();
        this.formGroup.markAsUntouched();
        this.editMode.next(false);
      } else {
        this.store.dispatch(PostServiceRequestAction({
          serviceItem: value,
          formFields,
        }));
        await this.serviceReducer$.pipe(
          pluck('message'),
          filter(message => !!message?.isSuccess),
          take(1),
          takeUntil(this.destroy$))
          .toPromise();
        this.dialogRef?.close();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  private setupAutoPortFiller(): void {
    const protoChanges$ = this.formGroup.get('gatewayProtocol').valueChanges;
    const portChanges$  = this.formGroup.get('gatewayPort')
      .valueChanges
      .pipe(
        startWith(this.formGroup.get('gatewayPort').value));

    combineLatest([
      protoChanges$,
      portChanges$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        pairwise(),
        filter(([[prevProto, prevPort], [currProto, currPort]]) => {
          const emptyPort       = !prevPort && !currPort;
          const changedProtocol = prevProto !== currProto;
          const isStandardPort  = (currProto === 'UDP' && currPort === 5061) || (currProto === 'TLS' && currPort === 5060);
          return (emptyPort && currProto) || (changedProtocol && isStandardPort);
        }),
        map(([[], [currProto, _]]) => currProto))
      .subscribe(proto => {
        this.formGroup.patchValue({ gatewayPort: proto === 'UDP' ? 5060 : 5061 });
      });
  }

  private setupValidators(): void {
    this.formValueChanged$ = FormService.formValueChanged$(
      this.formGroup,
      this.sipTrunkData,
      [
        'label',
        'gatewayAddress',
        'gatewayProtocol',
        'gatewayHasSipOptions',
        'gatewayPort',
        'addressSpaces',
      ]);

    this.submitDisabled$ = combineLatest([
      this.containsDupes$,
      this.isPending$,
      FormService.isValid$(this.formGroup),
      this.formGroup.valueChanges.pipe(startWith(this.formGroup.value)),
      this.formValueChanged$])
      .pipe(map(([containsDupes, isPending, valid, _, changed]) => {
        return isPending || containsDupes || !valid || this.formGroup.pristine || !changed;
      }));
  }
}
