import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

import { ListModel }             from '@models/entity/list.model';
import { Observable, of }        from 'rxjs';
import { DateService }           from '@services/date.service';
import { catchError, map }       from 'rxjs/operators';
import { Alert }                 from '@models/entity/alert.model';
import { APIError }              from '@models/entity/api-error.model';
import { HttpErrorResponse }     from '@angular/common/http';
import { ListModelResponseRaw }  from '@models/api/list-response-raw.model';
import { ListParamModel }        from '@models/form/list-param-model';
import { ListParamModelFactory } from '@models/factory/list-param.factory';
import { ListModelFactory }      from '@models/factory/list.factory';
import { ListModelResponse }     from '@models/api/list-response.model';
import { APIOpts }               from '@models/api/api-opts.model';

@Injectable({
  providedIn: 'root',
})
export class ListService<M extends ListModel<M>, F extends ListModelFactory<M>,
  P extends ListParamModel<P>, S extends ListParamModelFactory<P>> {

  constructor(
    private apiService: ApiService,
    private dateService: DateService,
  ) {
  }

  fetchListModel$(endpoint: string, modelFactory: F, paramFactory: S, opts?: APIOpts): Observable<ListModelResponse<M, P>> {
    return this.apiService.apiGet$<ListModelResponseRaw<M>>(endpoint, { authRequired: true, ...(opts || {}) })
      .pipe(
        map((res: ListModelResponseRaw<M>): ListModelResponse<M, P> => ({
          error:        null,
          message:      null,
          models:       res && res.data?.length ? res.data.map((x: ListModel<M>) => modelFactory.create()
            .fromApiData(x, this.dateService)) : [],
          searchParams: paramFactory.create()
                          .constructParams(res?.meta),
        })),
        catchError((err: HttpErrorResponse & APIError): Observable<ListModelResponse<M, P>> => {
          return of({
            error:        new Alert().fromApiError(err),
            message:      null,
            models:       null,
            searchParams: null,
          });
        }),
      );
  }
}
