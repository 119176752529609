import { Component, Inject, Renderer2 }     from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ModalModule }                      from '../../modal/modal.module';
import { MatIconModule }                    from '@angular/material/icon';
import { MatTooltipModule }                 from '@angular/material/tooltip';
import { FlexLayoutModule }                 from '@angular/flex-layout';
import { DirectiveModule }                  from '../../directive/directive.module';

@Component({
  selector:    'ngx-scratch-code-modal',
  templateUrl: './scratch-code-modal.component.html',
  styleUrls:   ['./scratch-code-modal.component.scss'],
  standalone:  true,
  imports: [
    MatDialogModule,
    FlexLayoutModule,
    ModalModule,
    MatIconModule,
    MatTooltipModule,
    DirectiveModule,
  ],
})
export class ScratchCodeModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: string,
              private renderer2: Renderer2) { }

  download(): void {
    const a    = this.renderer2.createElement('a') as HTMLAnchorElement;
    a.href     = 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.data);
    a.download = 'scratchcode.txt';
    // start download
    a.click();
  }
}
