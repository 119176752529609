import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA }                             from '@angular/material/dialog';
import { ConfirmModalData }                            from '@models/ui/confirm-modal-data.model';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector:    'ngx-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls:   ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  typeTextCtrl = new UntypedFormControl('', Validators.required);
  checkbox     = new FormControl<boolean>(false);

  constructor(@Inject(MAT_DIALOG_DATA) public modalData: ConfirmModalData) {
    this.modalData.typeConfirmText = this.modalData.typeConfirmText || 'DELETE';
    this.modalData.cancelBtnText   = this.modalData.cancelBtnText || 'Cancel';
  }
}
