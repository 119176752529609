import { Component, Inject }             from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AccessBrokerService }           from '@services/access-broker.service';

@Component({
  selector:    'ngx-auth-notice',
  templateUrl: './auth-notice.component.html',
  styleUrls:   ['./auth-notice.component.scss'],
})
export class AuthNoticeComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public errorMessage: string,
    private matDialog: MatDialogRef<AuthNoticeComponent>,
    private accessBrokerService: AccessBrokerService) { }

  authorise(): void {
    this.accessBrokerService.openRedirectPopup();
    this.matDialog.close(true);
  }

}
