<ngx-modal-head header="Tell us how we can improve"
                headerIcon="cr-message-pen"
                [closeable]="true"></ngx-modal-head>
<ngx-modal-content class="mat-typography">
  <p class="mat-body-1 mb-24">
    Your experience and satisfaction with Callroute is important to us. Please let us know how we can improve our
    product and service to make your tasks easier.
  </p>
  <form [formGroup]="formGroup" #ngForm="ngForm">
    <div class="input-field-container mt-16">
      <label class="field-label field-label--bold mb-0" fxLayout="row" fxLayoutAlign="start center">
        <span class="mt-3">Please let us know what we can do to improve our product and service to better support your day to day tasks *</span>
      </label>
    </div>
    <mat-form-field appearance="outline" class="w-100">
      <textarea matInput formControlName="improvements" cdkTextareaAutosize data-cy="improvements-field"></textarea>
    </mat-form-field>
    <ngx-field-notification *ngIf="isInvalid(formGroup.get('improvements'), ngForm.submitted)"
                            class="mb-12"
                            message="{{improvementsErrorMessage$ | async}}"
                            [type]="FieldCheckType.Invalid">
    </ngx-field-notification>
    <div class="input-field-container mt-16">
      <label class="field-label field-label--bold mb-0" fxLayout="row" fxLayoutAlign="start center">
        <span class="mt-3">Please let us know what you like most about using Callroute and why? *</span>
      </label>
    </div>
    <mat-form-field appearance="outline" class="w-100">
      <textarea matInput formControlName="highlights" cdkTextareaAutosize data-cy="highlights-field"></textarea>
    </mat-form-field>
    <ngx-field-notification *ngIf="isInvalid(formGroup.get('highlights'), ngForm.submitted)"
                            class="mb-12"
                            message="{{highlightErrorMessage$ | async}}"
                            [type]="FieldCheckType.Invalid">
    </ngx-field-notification>
    <div class="input-field-container mt-16">
      <label class="field-label field-label--bold mb-0" fxLayout="row" fxLayoutAlign="start center">
        <span class="mt-3">Please rate your overall experience of using Callroute *</span>
      </label>
    </div>
    <star-rating-control [starType]="'svg'" [step]="1"
                         size="large"
                         [showHalfStars]="false"
                         [hoverEnabled]="true"
                         speed="immediately"
                         class="cursor-pointer"
                         formControlName="rating"
                         data-cy="star-rating"></star-rating-control>
  </form>
</ngx-modal-content>
<ngx-modal-actions>
  <ngx-cr-button text="Submit" #modalAction data-cy="submit"
                 (onClick)="submit()">
  </ngx-cr-button>
  <ngx-cr-button text="Close" #modalAction
                 data-cy="close"
                 [type]="ButtonType.Secondary"
                 (onClick)="close()"
  >
  </ngx-cr-button>
</ngx-modal-actions>
