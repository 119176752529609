import { NumberRangeRaw }       from '@models/api/number-range-raw.model';
import { CountryItem }          from '@models/entity/country-code.model';
import { NumberRoute }          from './number-route.model';
import { CarrierType }          from '@enums/carrier-type.enum';
import { NumberRangeStatus }    from '@models/entity/number-status.model';
import { NumberAssignedCounts } from '@models/entity/number-counts.model';
import { NumberProvider }       from '@models/entity/number-provider.model';
import { AssetsService }        from '@services/assets.service';
import { ProviderIdentifier }   from '@enums/provider-identifier.enum';
import { NumberRangeMetadata }  from '@models/entity/number-range-metadata.model';
import { range }                from '@util/range';
import {
  ACCESS_CONFIGURE_RANGE_DISABLED_TOOLTIP,
  BYON_ORTO_CONFIGURE_INBOUND_DISABLED_TOOLTIP,
  BYON_ORTO_CONFIGURE_NUMBER_DISABLED_TOOLTIP,
  BYON_ORTO_CONFIGURE_RANGE_DISABLED_TOOLTIP,
  LICENSE_CONFIGURE_RANGE_DISABLED_TOOLTIP,
}                               from '@consts/text/number.const';
import { ExhaustionStatus }     from '@enums/exhaustion-status.enum';

export class NumberRange {
  id: string;
  createdDate: string;
  updatedDate: string;
  name: string;
  prefix: string;
  description: string;
  usageThreshold: number;
  operator: string;
  inboundRoute: NumberRoute;
  outboundRoute: NumberRoute;
  status: NumberRangeStatus;
  exhaustionStatus: ExhaustionStatus;
  counts: NumberAssignedCounts;
  provider: NumberProvider;
  country?: CountryItem;
  requestId?: string; // internal id to retrieve range from state after creation
  metadata: NumberRangeMetadata;

  getXSuffix?(): string {
    if (!this.metadata.firstNumber?.length || !this.metadata.numberPrefix?.length) {
      return '';
    }
    return range(this.metadata.firstNumber.length - this.metadata.numberPrefix.length)
      .map(() => 'X')
      .join('');
  }

  getExhaustionStatusIcon?(): string {
    switch (this.exhaustionStatus) {
      case ExhaustionStatus.AboveThreshold:
        return 'cr-info-circle';
      case ExhaustionStatus.BelowThreshold:
        return 'cr-tick-circle';
      case ExhaustionStatus.Depleted:
        return 'cr-warning-no-color';
    }
  }

  getExhaustionStatusColor?(): string {
    switch (this.exhaustionStatus) {
      case ExhaustionStatus.AboveThreshold:
        return 'orange';
      case ExhaustionStatus.BelowThreshold:
        return 'green';
      case ExhaustionStatus.Depleted:
        return 'red';
    }
  }

  getExhaustionStatusBackgroundClass?(): string {
    switch (this.exhaustionStatus) {
      case ExhaustionStatus.AboveThreshold:
        return 'background-orange';
      case ExhaustionStatus.BelowThreshold:
        return 'background-green';
      case ExhaustionStatus.Depleted:
        return 'background-red';
    }
  }

  getExhaustionStatusDescription?(): string {
    switch (this.exhaustionStatus) {
      case ExhaustionStatus.AboveThreshold:
        return 'Above threshold';
      case ExhaustionStatus.BelowThreshold:
        return 'Below threshold';
      case ExhaustionStatus.Depleted:
        return 'Depleted';
    }
  }

  hasCapabilities?(): boolean {
    return this.provider.carrierType === CarrierType.BYON && this.provider.identifier !== ProviderIdentifier.DirectRouting;
  }

  getCountryFlagUrl?(): string {
    if (!this.country) {
      return null;
    }
    return AssetsService.getFlag(this.country.code + '.svg');
  }

  configureDisabled?(hasAccess: boolean): boolean {
    if (!hasAccess || this.provider.isBYON) {
      return true;
    }
    if (this.provider.carrierType === CarrierType.Held) {
      return false;
    }
    return !this?.outboundRoute;
  }

  configureTooltip?(hasNumberWrite: boolean, hasNumberCreate: boolean, hasCorrectLicense: boolean): string {
    if (this.provider.isBYON) {
      return BYON_ORTO_CONFIGURE_INBOUND_DISABLED_TOOLTIP;
    }
    if (!hasCorrectLicense) {
      return BYON_ORTO_CONFIGURE_NUMBER_DISABLED_TOOLTIP;
    }
    if (!hasNumberCreate) {
      return LICENSE_CONFIGURE_RANGE_DISABLED_TOOLTIP;
    }
    if (!hasNumberWrite) {
      return ACCESS_CONFIGURE_RANGE_DISABLED_TOOLTIP;
    }
    if (!this?.outboundRoute) {
      return `This number range does not have a ${ this.provider.identifier === ProviderIdentifier.DirectRouting ? 'service' : 'carrier' } configured and cannot be routed. In order to assign routing rules, first assign the range to a ${ this.provider.identifier === ProviderIdentifier.DirectRouting ? 'service' : 'carrier' }.`;
    }
    return null;
  }

  deleteDisabled?(isSipAdmin: boolean, hasNumberWrite: boolean): boolean {
    return (this.provider.carrierType === CarrierType.Held && !isSipAdmin) || !hasNumberWrite;
  }

  editCarrierDisabled?(hasAccess: boolean): boolean {
    return !hasAccess || (this.provider.carrierType === CarrierType.Held || (this.provider.carrierType === CarrierType.BYON && !this.provider.isDirectRouting));
  }

  editCarrierTooltip?(hasAccess: boolean, hasCorrectLicense: boolean): string {
    if (!hasCorrectLicense) {
      return BYON_ORTO_CONFIGURE_RANGE_DISABLED_TOOLTIP;
    }
    return !hasAccess ? `You do not have the required access to edit ${ this.provider.isDirectRouting ? 'service' : 'carrier' }. Please contact your systems administrator for more information.` : null;
  }

  showOutboundRoute?(): boolean {
    return (this.outboundRoute && this.provider.carrierType !== CarrierType.BYON) ||
      this.provider.carrierType === CarrierType.Held;
  }

  constructor(private data?: NumberRange) {
    Object.assign(this, { ...data });
    delete this['data'];
  }

  fromApiData?(apiData: NumberRangeRaw, requestId?: string): NumberRange {
    this.id               = apiData.id;
    this.createdDate      = apiData.created_date;
    this.updatedDate      = apiData.updated_date;
    this.name             = apiData.name;
    this.prefix           = apiData.prefix;
    this.description      = apiData.description;
    this.operator         = apiData.operator;
    this.exhaustionStatus = apiData.exhaustion_status;
    this.usageThreshold   = apiData.usage_threshold !== null ? apiData.usage_threshold * 100 : null;
    this.inboundRoute     = new NumberRoute().fromApiData(apiData.inbound_route);
    this.outboundRoute    = new NumberRoute().fromApiData(apiData.outbound_route);
    this.status           = apiData.status;
    this.provider         = new NumberProvider().fromApiData(apiData.provider);
    this.metadata         = apiData.metadata ? {
      firstNumber:  apiData.metadata.first_number_e164,
      lastNumber:   apiData.metadata.last_number_e164,
      numberCount:  apiData.metadata.number_count,
      numberPrefix: apiData.metadata.number_prefix,
    } : null;
    this.requestId        = requestId;

    if (apiData.country) {
      this.country = { name: apiData.country.name, code: apiData.country.iso3166_alpha2_code.toLowerCase() };
    }
    return this;
  }

  getUsagePercentage?(): number {
    if (!this.counts || !this.counts.TOTAL) {
      return 0;
    }
    return Math.round((1 - (this.counts.UNASSIGNED / this.counts.TOTAL)) * 100);
  }

  getPercentageThresholdMet?(defaultThreshold: number): number {
    const a                      = this.getUsagePercentage(); // 40% usage
    const t                      = this.usageThreshold !== null ?
      this.usageThreshold : defaultThreshold; // 80% usage threshold
    const percentageThresholdMet = (a / t) * 100; // would equal 50% of that threshold being met
    return Math.round(percentageThresholdMet);
  }

  getUsageThresholdTooltip?(defaultThreshold: number): string {
    return `Usage threshold: ${ this.usageThreshold !== null ? this.usageThreshold : defaultThreshold }%`;
  }

  getUsageThresholdPercentageClass?(defaultThreshold: number): string {
    const percentageThresholdMet = this.getPercentageThresholdMet(defaultThreshold);

    if (percentageThresholdMet < 90) {
      return 'background-green';
    }
    if (percentageThresholdMet < 100) {
      return 'background-orange';
    }
    return 'background-red';
  }

  getUsageThresholdStatusColor?(defaultThreshold: number): string {
    const percentageThresholdMet = this.getPercentageThresholdMet(defaultThreshold);

    if (percentageThresholdMet < 90) {
      return 'green';
    }
    if (percentageThresholdMet < 100) {
      return 'orange';
    }
    return 'red';
  }

  getUsageThresholdPercentageBorderClass?(defaultThreshold: number): string {
    const percentageThresholdMet = this.getPercentageThresholdMet(defaultThreshold);

    if (percentageThresholdMet < 90) {
      return 'theme-green-outline-chip';
    }
    if (percentageThresholdMet < 100) {
      return 'theme-orange-outline-chip';
    }
    return 'theme-red-outline-chip';
  }

  toApiData?(): NumberRangeRaw {
    return {} as NumberRangeRaw;
  }
}
