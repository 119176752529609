import { Component, Input } from '@angular/core';
import { Alert }            from '@models/entity/alert.model';

@Component({
  selector:    'ngx-anon-page',
  templateUrl: './anon-page.component.html',
  styleUrls:   ['./anon-page.component.scss'],
})
export class AnonPageComponent {
  @Input() error: Alert;
  @Input() message: Alert;
  @Input() reducedPadding: boolean;
  @Input() showDivider: boolean = true;
}
