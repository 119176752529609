<div class="sheet-contents" *ngIf="data">
  <div class="sheet-item border-color-light-gray">
    <small>Direction</small>
    <ngx-list-item-text text="{{data.logData.directionLabel}}"></ngx-list-item-text>
  </div>
  <div class="sheet-item border-color-light-gray">
    <small>Status</small>
    <ngx-list-item-text text="{{data.logData.statusLabel}}"></ngx-list-item-text>
  </div>
  <div class="sheet-item border-color-light-gray">
    <small>Source CLI</small>
    <ngx-list-item-text text="{{data.logData.sourceCli}}"></ngx-list-item-text>
  </div>
  <div class="sheet-item border-color-light-gray">
    <small>Destination CLI</small>
    <ngx-list-item-text text="{{data.logData.destinationCli}}"></ngx-list-item-text>
  </div>
  <div class="sheet-item did border-color-light-gray">
    <small>Date</small>
    <ngx-list-item-text text="{{data.logData.date}} - {{data.logData.time}}"></ngx-list-item-text>
  </div>
  <div class="sheet-item border-color-light-gray">
    <small>Duration</small>
    <ngx-list-item-text text="{{data.logData.duration}}"></ngx-list-item-text>
  </div>
  <div class="recording border-color-light-gray mt-16" fxLayout="row" fxLayoutAlign="space-between center"
       *ngIf="data.logData.recordingUri">
    <ngx-cr-button [type]="ButtonType.Secondary" text="Play" icon="cr-volume"
                   (onClick)="openRecordingModal()"></ngx-cr-button>
    <ngx-cr-button [type]="ButtonType.Secondary"
                   text="Download"
                   icon="cr-download"
                   ngxSignedDownload
                   waitForVal="recordingUri" [fetchSignedURLAction]="data.downloadAction" [fileName]="data.fileName"
                   reducer="reportReducer"></ngx-cr-button>
  </div>
  <ngx-cr-button [type]="ButtonType.Primary" text="Close" class="mt-16 display-block"
                 (onClick)="close()"></ngx-cr-button>
</div>
