export const REGISTER_REQUEST  = 'REGISTER_REQUEST';
export const REGISTER_RESPONSE = 'REGISTER_RESPONSE';

export const RESEND_ACTIVATION_REQUEST  = 'RESEND_ACTIVATION_REQUEST';
export const RESEND_ACTIVATION_RESPONSE = 'RESEND_ACTIVATION_RESPONSE';

export const ACTIVATE_ACCOUNT_REQUEST  = 'ACTIVATE_ACCOUNT_REQUEST';
export const ACTIVATE_ACCOUNT_RESPONSE = 'ACTIVATE_ACCOUNT_RESPONSE';

export const CLEAR_REGISTRATION_REDUCER = 'CLEAR_REGISTRATION_REDUCER';
