import { BaseRequest }      from '@redux/helpers/reducer.helper';
import { SecurityGroupRaw } from '@models/api/identity/security-group-raw.model';

export class PatchSecurityGroupRequest extends BaseRequest {
  id: string;
  name: string;
  description: string;

  constructor(private data?: PatchSecurityGroupRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): Partial<SecurityGroupRaw> {
    return {
      ...{
        name:        this.name,
        description: this.description,
      },
    };
  }
}
