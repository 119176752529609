import {
  FAILED_STATUSES,
  INCOMPLETE_STATUSES,
  PROCESSING_STATUSES,
  SUCCESS_STATUSES,
  WARNING_STATUSES,
}                          from '@models/api/service.model';
import { ServiceStatus }   from '@enums/service-status.enum';
import { ServiceUIStatus } from '@enums/service-ui-status';
import { ProvisionStep }   from '@enums/provisioning-step.enum';
import { Status }          from '@models/entity/status.model';
import { ServiceType }     from '@enums/service-type.enum';


export class StatusItem {
  status: Status;
  name: string;
  step: ProvisionStep;
  description: string;
  color: string;
  warningsCount: number;

  static isSuccess(status: Status): boolean {
    return SUCCESS_STATUSES.includes(status);
  }

  static isWarning(status: Status): boolean {
    return WARNING_STATUSES.includes(status);
  }

  static isDeleted(status: Status): boolean {
    return status === ServiceStatus.Deleted;
  }

  static isFailed(status: Status): boolean {
    return FAILED_STATUSES.includes(status);
  }

  static isIncomplete(status: Status): boolean {
    return INCOMPLETE_STATUSES.includes(status);
  }

  static isPending(status: Status): boolean {
    return status === ServiceUIStatus.Pending;
  }

  static isProcessing(status: Status): boolean {
    return PROCESSING_STATUSES.includes(status);
  }

  constructor(status: Status,
              step: ProvisionStep,
              serviceType: ServiceType,
              showDescription = true,
              warningsCount   = 0) {
    this.status        = status;
    this.step          = step;
    this.name          = this.getStatusName();
    this.description   = showDescription && this.getStatusDescription(serviceType);
    this.color         = this.getStatusColourString();
    this.warningsCount = warningsCount;
  }

  getStatusColourString?(): string {
    switch (this.status) {
      case ServiceUIStatus.Failed:
      case ServiceUIStatus.Deleted:
        return 'red';
      case ServiceUIStatus.Warning:
      case ServiceUIStatus.Pending:
        return 'orange';
      case ServiceUIStatus.Processing:
      case ServiceStatus.Provisioning:
        return 'yellow';
      case ServiceStatus.Inactive:
        return 'orange';
      case ServiceUIStatus.Success:
      case ServiceStatus.Active:
        return 'green';
      default:
        return 'light-gray';
    }
  }

  private getStatusName?(): string {
    switch (this.status) {
      case ServiceStatus.Suspended:
        return 'Suspended';
      case ServiceUIStatus.Deleted:
        return 'Deleting';
      case ServiceUIStatus.Failed:
        return 'Failed';
      case ServiceUIStatus.Warning:
      case ServiceUIStatus.Pending:
        return 'Attention required';
      case ServiceUIStatus.Processing:
        return 'In progress';
      case ServiceStatus.Inactive:
        return 'In progress';
      case ServiceStatus.Provisioning:
        return 'Provisioning';
      case ServiceUIStatus.NeedsAction:
        return this.step === ProvisionStep.Authorise ? `Authorisation is required` : 'Attention required';
      case ServiceUIStatus.Success:
      case ServiceStatus.Active:
        return 'Active';
      case ServiceStatus.Unavailable:
        return 'Service is unavailable';
      default:
        return '';
    }
  }

  getStatusDescription?(serviceType?: ServiceType): string {
    switch (this.status) {
      case ServiceUIStatus.Failed:
        return this.getFailedDescription();
      case ServiceUIStatus.Pending:
        return this.getPendingDescription();
      case ServiceUIStatus.NeedsAction:
        return this.getNeedsActionDescription();
      case ServiceUIStatus.Processing:
      case ServiceStatus.Provisioning:
        return this.getProcessingDescription(serviceType);
      case ServiceUIStatus.Success:
        return this.getSuccessDescription();
      default:
        return null;
    }
  }

  private getFailedDescription?(): string {
    switch (this.step) {
      case ProvisionStep.HealthCheck:
        return `<p class="text-med-gray">There are problems with your Microsoft tenant. See our <a href="https://help.callroute.com/space/CKB/698843188/Pre-requisites" 
target="_blank" class="link">Knowledge Base article</a> for more information, or contact <a
                    href="mailto:support@callroute.com">Callroute support</a>.</p>`;
      case ProvisionStep.Authorise:
        return 'Authorisation failed. Please try again.';
      default:
        return 'There was a problem configuring your service.';
    }
  }

  private getPendingDescription?(): string {
    switch (this.step) {
      case ProvisionStep.Authorise:
        return `Please log in to your Microsoft account so we can run some checks to see if your account has everything we need.`;
      default:
        return '';
    }
  }

  private getNeedsActionDescription?(): string {
    switch (this.step) {
      case ProvisionStep.Authorise:
        return 'Please log in to your Microsoft account so we can run some checks to see if your account has everything we need.';
      default:
        return '';
    }
  }

  private getProcessingDescription?(serviceType: ServiceType): string {
    switch (this.step) {
      case ProvisionStep.Configure:
        if (serviceType !== ServiceType.MicrosoftTeams) {
          return 'We are configuring the service.';
        }
        return 'We are now configuring the Callroute domain in your tenant.';
      default:
        return '';
    }
  }

  private getSuccessDescription?(): string {
    switch (this.step) {
      case ProvisionStep.HealthCheck:
        return 'Healthy';
      default:
        return '';
    }
  }
}
