import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';
import { TaskSchema }           from '@enums/task-schema.enum';

export class TaskQueryParams extends ListParams implements ListParamModel<TaskQueryParams> {
  search?: string;
  include?: string[];
  schema?: TaskSchema;

  static constructQueryString(searchParams: TaskQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      search:  'filter[search]',
      include: 'include[]',
      schema:  'filter[schema]',
    });
  }

  static queryChanged(newQuery: TaskQueryParams, currentQuery: TaskQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery);
  }

  constructParams?(metaData: ListResponseMetadata): TaskQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.search) {
        this.search = metaData.filter.search;
      }
      if (metaData.include) {
        this.include = metaData.include;
      }
      if (metaData.filter.schema) {
        this.schema = metaData.filter.schema;
      }
    }

    return this;
  }
}
