import { Component, Input } from '@angular/core';

@Component({
  selector:    'ngx-global-banner',
  templateUrl: './global-banner.component.html',
  styleUrls:   ['./global-banner.component.scss'],
})
export class GlobalBannerComponent {
  @Input() text: string;
  @Input() isViewingCompany: boolean;
  show = true;

  get helpUri(): string {
    return this.extractMarkdownUrl(this.text);
  }

  get helpAltText(): string {
    return this.extractAltText(this.text);
  }

  get bannerText(): string {
    return this.stripMarkdownUrl(this.text);
  }

  constructor() { }

  private stripMarkdownUrl(text: string): string {
    // Regular expression to match markdown URLs
    const pattern = /\[.*?\]\(.*?\)/g;

    // Replace the markdown URL with an empty string
    const strippedText = text.replace(pattern, '')
      .trim();

    return strippedText;
  }

  private extractMarkdownUrl(text: string): string {
    // Regular expression to capture the URL part of the markdown link
    const pattern = /\[.*?\]\((.*?)\)/;

    // Match the pattern and extract the URL
    const match = text.match(pattern);

    // Return the captured URL or null if no match is found
    return match ? match[1] : null;
  }

  private extractAltText(text: string): string {
    // Regular expression to capture the alt text part of the markdown link
    const pattern = /\[(.*?)\]\(.*?\)/;

    // Match the pattern and extract the alt text
    const match = text.match(pattern);

    // Return the captured alt text or null if no match is found
    return match ? match[1] : null;
  }

  closeBanner(): void {
    this.show = false;
  }

}
