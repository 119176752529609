import { AuditRaw }     from '@models/api/audit/audit-raw.model';
import { AuditContext } from '@enums/audit-context.enum';

export class Audit {
  id: string;
  companyId: string;
  createdDate: string;
  auditDate: string;
  identity: {
    identifier: string;
    type: string;
    id: string;
  };
  type: string;
  service: string;
  context: AuditContext;
  object: {
    type: string;
    id: string;
  };
  userDescription: string;
  extraData: null;

  constructor(private data?: Audit) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: AuditRaw): Audit {
    this.auditDate       = data.audit_date;
    this.extraData       = data.extra_data;
    this.createdDate     = data.created_date;
    this.userDescription = data.user_description;
    this.context         = data.context;
    this.companyId       = data.company_uuid;
    this.id              = data.id;
    this.identity        = data.identity;
    this.object          = data.object;
    this.service         = data.service;
    return this;
  }
}
