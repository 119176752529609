import { BaseRequest }     from '@redux/helpers/reducer.helper';
import { SyncMode }        from '@enums/sync-mode.enum';
import { UserSyncVersion } from '@enums/user-sync-version.enum';

export class SyncServiceUserListRequest extends BaseRequest {
  serviceId: string;
  version: UserSyncVersion;
  isFullSync: boolean;
  includeUnavailable: boolean;
  mode: SyncMode;
  identities?: string[];
  autoProvision: boolean;
  inboundAssignment: boolean;

  constructor(private data?: SyncServiceUserListRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): { include_unavailable: boolean; full_sync: boolean; identities: string[] } |
                {
                  mode: SyncMode;
                  identities: string[];
                  is_full_synchronisation: boolean;
                  is_auto_provision_enabled: boolean;
                  is_inbound_assignment_enabled: boolean;
                } {
    switch (this.version) {
      case 'v1':
        return {
          full_sync:           this.isFullSync,
          include_unavailable: this.includeUnavailable,
          identities:          this.identities,
        };
      case 'v2':
        return {
          mode:                          this.identities?.length ? SyncMode.Identities : this.mode,
          is_full_synchronisation:       this.isFullSync,
          is_auto_provision_enabled:     this.autoProvision,
          identities:                    this.identities,
          is_inbound_assignment_enabled: this.inboundAssignment,
        };
    }
  }
}
