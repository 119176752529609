import { BaseRequest }  from '@redux/helpers/reducer.helper';
import { RouteRule }    from '@models/entity/route-rule.model';
import { RouteRuleRaw } from '@models/api/route-rule-raw.model';

export class SetNumberRangeRoutesRequest extends BaseRequest {
  rangeId: string;
  rules: RouteRule[];
  name: string;

  constructor(private data?: SetNumberRangeRoutesRequest) {
    super();
    Object.assign(this, { ...this.data });
    delete this['data'];
  }

  toApiData?(): { rules: RouteRuleRaw[] } {
    return {
      rules: this.rules.map(rule => rule.toApiData()),
    };
  }
}
