import { ListResponseMetadata } from '../api/list-response-metadata.model';
import { ListParams }           from './list-params.model';
import { ListParamModel }       from './list-param-model';
import { ApprovalStatus }       from '@enums/approval-status.enum';

export class ApprovalQueryParams extends ListParams implements ListParamModel<ApprovalQueryParams> {
  search?: string;
  status?: ApprovalStatus[];
  approvalUserId?: string[];

  static constructQueryString(searchParams: ApprovalQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      search:         'filter[search]',
      status:         'filter[status][]',
      approvalUserId: 'filter[approval_user_id][]',
    });
  }

  static queryChanged(newQuery: ApprovalQueryParams, currentQuery: ApprovalQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery);
  }

  constructParams?(metaData: ListResponseMetadata): ApprovalQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.search) {
        this.search = metaData.filter.search;
      }
      if (metaData.filter.status) {
        this.status = metaData.filter.status as ApprovalStatus[];
      }
      if (metaData.filter.approval_user_id) {
        this.approvalUserId = metaData.filter.approval_user_id;
      }
    }

    return this;
  }
}
