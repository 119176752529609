import { ServiceCarrierRaw } from '@models/api/carrier-service-raw.model';
import { getServiceName }    from '@models/api/service.model';
import { ServiceItem }       from '@models/entity/service-item.model';
import { ServiceType }       from '@enums/service-type.enum';
import { Carrier }    from '@models/entity/carrier.model';
import { NumberItem } from '@models/entity/number-item.model';

export class ServiceCarrier extends ServiceItem {
  icon                     = 'cr-towercell';
  serviceType: ServiceType = ServiceType.Carrier;
  isRegistrationRequired: boolean;
  username?: string;
  password?: string;
  realm: string;
  registrarAddress: string;
  registrarString: string;
  information: string;
  carrierId: string;
  gateways: {
    priority: number;
    address: string;
    protocol: string;
    port: number;
  }[];
  addressSpaces: string[];
  useDNSSRV: boolean;
  isSipOptionsEnabled: boolean;
  carrierData: Carrier;
  numberId: string;
  numberData?: NumberItem;
  isGatewayUp: boolean;

  constructor(protected data?: ServiceCarrier) {
    super();
    Object.assign(this, { ...this.data });
    delete this['data'];
    if (this.carrierData?.adapter === 'bt-wholesale') {
      this.registrarString = this.getNormalisedPilotNumber();
    }
  }

  fromApiData?(apiData: ServiceCarrierRaw): ServiceCarrier {
    super.fromApiData(apiData);
    this.label                  = apiData.label;
    this.name                   = getServiceName(apiData.type);
    this.gateways               = apiData.gateways;
    this.serviceType            = apiData.type;
    this.carrierId              = apiData.carrier_id;
    this.isRegistrationRequired = apiData.is_registration_required;
    this.isSipOptionsEnabled    = apiData.is_sip_options_enabled;
    this.username               = apiData.registration?.username;
    this.password               = apiData.registration?.password;
    this.realm                  = apiData.registration?.realm;
    this.numberId               = apiData.outbound_number_id;
    this.registrarAddress       = apiData.registration?.registrar_address;
    this.registrarString        = apiData.registration?.registrar_string;
    this.information            = apiData.information;
    this.useDNSSRV              = apiData.use_dns_srv;
    this.addressSpaces          = apiData.address_spaces;
    this.isGatewayUp            = apiData.is_gateway_up;

    return this;
  }

  toApiData?(): ServiceCarrierRaw {

    const gateways = this.gateways
      ?.filter(gateway => this.useDNSSRV ? gateway.priority === 1 : true)
      ?.filter(gateway => gateway.protocol && gateway.address && gateway.port);

    const registrationIsSet     = [this.username, this.password, this.realm, this.registrarString, this.registrarAddress].some(val => !!val);
    let registrarString: string = this.registrarString;

    if (this.carrierData.adapter === 'bt-wholesale') {
      registrarString = this.getAPIPilotNumber();
    }

    const data = {
      label:                  this.label,
      carrier:                this.carrierData.identifier,
      gateways,
      is_sip_options_enabled: this.isSipOptionsEnabled,
      type:                   this.serviceType,
      registration:           this.isRegistrationRequired && registrationIsSet ? {
        username:          this.username || undefined,
        password:          this.password || undefined,
        realm:             this.realm,
        registrar_address: this.registrarAddress,
        registrar_string:  registrarString,
      } : undefined,
      address_spaces:         this.addressSpaces,
      use_dns_srv:            this.useDNSSRV,
    } as ServiceCarrierRaw;

    // ignore undefined
    return { ...data };
  }

  getAPIPilotNumber?(): string {
    return '44'.concat(this.registrarString?.startsWith('0') ? this.registrarString.slice(1) : this.registrarString);
  }

  getNormalisedPilotNumber?(): string {
    const stripped = this.registrarString?.startsWith('44') ?
      this.registrarString.slice(2) :
      this.registrarString;
    return stripped?.startsWith('0') ? stripped.slice(1) : stripped;
  }

}
