import { ReportSeriesData } from '@models/entity/report-series-data.model';
import { RangeUsageTopRaw } from '@models/entity/range-usage-top-raw.model';
import { ChartSeries }      from '@models/chart/chart-series.model';

export class RangeUsageTop {
  series: { id: string, name: string }[];
  points: {
    group_id: string;
    name: string;
    count: number;
    usage_percentage: number;
  }[];

  constructor(private data?: RangeUsageTop) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(apiData: RangeUsageTopRaw): RangeUsageTop {
    this.series = Object.entries(apiData.data.groups)
      .map(([id, name]) => ({ id, name }))
      ?.map(p => ReportSeriesData.fromApiData(p)) || [];

    this.points = [];

    const points = apiData.data.points[apiData.meta.constraints.points.first];

    if (!points) {
      return this;
    }

    for (const [group, data] of Object.entries(points)) {
      this.points.push({
        name:             this.series?.find(s => s.id === group)?.name,
        group_id:         group,
        count:            data.assigned_count + data.unassigned_count + data.reserved_count,
        usage_percentage: Math.floor(+data.usage_fraction * 100),
      });
    }

    return this;
  }

  toChartMultiSeries?(): ChartSeries[] {
    const points = this.points;
    const series = this.series;

    return series?.map(s => {
        const point = points.find(p => p.group_id === s.id);

        return {
          name:  s.name,
          value: point.usage_percentage,
          extra: {
            code:  s.name,
            count: point.count,
          },
        };
      },
    )
      ?.sort((a, b) => b.value - a.value) || [];
  }
}
