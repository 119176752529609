export function range(size: number, startAt = 0): number[] {
  return [...Array(size)
    .keys()].map(i => i + startAt);
}

export function rangeAsString(size: number, startAt = '0'): string[] {
  let leadingZeros = '';
  if (startAt.startsWith('0')) {
    for (const str of startAt) {
      if (str !== '0') {
        break;
      }
      leadingZeros = `${ leadingZeros }0`;
    }
  }
  const startNum = +startAt;
  return [...Array(size)
    .keys()].map(i => {
    const prevNum = i > 0 ? (i - 1) + startNum : null;
    const num     = i + startNum;

    const first     = num.toString()[0];
    const prevFirst = prevNum?.toString()[0];

    if (i > 0 && (first !== prevFirst)) {
      leadingZeros = leadingZeros.slice(1);
    }
    return leadingZeros.concat(num.toString());
  });
}
