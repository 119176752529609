import { createAction, props }             from '@ngrx/store';
import {
  DETERMINE_FETCH_LAST_PAYMENT_LIST_REQUEST,
  EXTEND_FREE_TRIAL_REQUEST,
  EXTEND_FREE_TRIAL_RESPONSE,
  FETCH_BALANCE_DATA_REQUEST,
  FETCH_BALANCE_DATA_RESPONSE,
  FETCH_CREDIT_REPORT_REQUEST,
  FETCH_CREDIT_REPORT_RESPONSE,
  FETCH_FEATURE_INVENTORY_REQUEST,
  FETCH_FEATURE_INVENTORY_RESPONSE,
  FETCH_LAST_PAYMENT_LIST_REQUEST,
  FETCH_LAST_PAYMENT_LIST_RESPONSE,
  FETCH_LAST_PAYMENT_SUMMARY_REQUEST,
  FETCH_LAST_PAYMENT_SUMMARY_RESPONSE,
  FETCH_PAYMENT_METHODS_REQUEST,
  FETCH_PAYMENT_METHODS_RESPONSE,
  FETCH_SPEND_REPORT_REQUEST,
  FETCH_SPEND_REPORT_RESPONSE,
  NEW_TOPUP_SESSION,
  SET_DATE_RANGE,
  TOPUP_BALANCE_REQUEST,
  TOPUP_BALANCE_RESPONSE,
}                                          from './accounting.types';
import { FetchPaymentMethodsResponse }     from '@models/api/fetch-payment-methods-response.model';
import { TopupRequest }                    from '@models/api/topup-request.model';
import { TopupResponse }                   from '@models/api/topup-response.model';
import { FetchReportByDateRequest }        from '@models/api/fetch-report-by-date-request.model';
import { FetchSpendReportResponse }        from '@models/api/fetch-spend-report-response.model';
import { FetchLastPaymentListResponse }    from '@models/api/fetch-last-payment-list-response.model';
import { FetchBalanceDataResponse }        from '@models/api/fetch-balance-data-response.model';
import { FetchLastPaymentListRequest }     from '@models/api/fetch-last-payment-list-request.model';
import { FetchCreditReportResponse }       from '@models/api/fetch-credit-report-response.model';
import { FetchLastPaymentSummaryResponse } from '@models/api/fetch-last-payment-summary-response.model';
import { FeatureInventoryResponse }        from '@models/api/feature-inventory-response.model';
import { DateRange }                       from '@models/form/date-range.model';
import { ExtendFreeTrialRequest }          from '@models/api/accounting/extend-free-trial-request.model';
import { ExtendFreeTrialResponse }         from '@models/api/accounting/extend-free-trial-response.model';
import { BaseRequest }                     from '@redux/helpers/reducer.helper';

export const NewTopupSession                            = createAction(NEW_TOPUP_SESSION);
export const TopupRequestAction                         = createAction(TOPUP_BALANCE_REQUEST, props<TopupRequest>());
export const TopupResponseAction                        = createAction(TOPUP_BALANCE_RESPONSE, props<TopupResponse>());
export const FetchPaymentMethodsRequestAction           = createAction(FETCH_PAYMENT_METHODS_REQUEST, props<BaseRequest>());
export const FetchPaymentMethodsResponseAction          = createAction(FETCH_PAYMENT_METHODS_RESPONSE, props<FetchPaymentMethodsResponse>());
export const FetchBalanceDataRequestAction              = createAction(FETCH_BALANCE_DATA_REQUEST, props<BaseRequest>());
export const FetchBalanceDataResponseAction             = createAction(FETCH_BALANCE_DATA_RESPONSE, props<FetchBalanceDataResponse>());
export const FetchSpendReportRequestAction              = createAction(FETCH_SPEND_REPORT_REQUEST, props<FetchReportByDateRequest>());
export const FetchSpendReportResponseAction             = createAction(FETCH_SPEND_REPORT_RESPONSE, props<FetchSpendReportResponse>());
export const FetchCreditReportRequestAction             = createAction(FETCH_CREDIT_REPORT_REQUEST, props<FetchReportByDateRequest>());
export const FetchCreditReportResponseAction            = createAction(FETCH_CREDIT_REPORT_RESPONSE, props<FetchCreditReportResponse>());
export const DetermineFetchLastPaymentListRequestAction = createAction(DETERMINE_FETCH_LAST_PAYMENT_LIST_REQUEST, props<FetchLastPaymentListRequest>());
export const FetchLastPaymentListRequestAction          = createAction(FETCH_LAST_PAYMENT_LIST_REQUEST, props<FetchLastPaymentListRequest>());
export const FetchLastPaymentListResponseAction         = createAction(FETCH_LAST_PAYMENT_LIST_RESPONSE, props<FetchLastPaymentListResponse>());
export const FetchLastPaymentSummaryRequestAction       = createAction(FETCH_LAST_PAYMENT_SUMMARY_REQUEST, props<FetchLastPaymentListRequest>());
export const FetchLastPaymentSummaryResponseAction      = createAction(FETCH_LAST_PAYMENT_SUMMARY_RESPONSE, props<FetchLastPaymentSummaryResponse>());
export const FetchFeatureInventoryRequestAction         = createAction(FETCH_FEATURE_INVENTORY_REQUEST, props<BaseRequest>());
export const FetchFeatureInventoryResponseAction        = createAction(FETCH_FEATURE_INVENTORY_RESPONSE, props<FeatureInventoryResponse>());
export const SetAccountingDateRange                     = createAction(SET_DATE_RANGE, props<DateRange>());
export const ExtendFreeTrialRequestAction               = createAction(EXTEND_FREE_TRIAL_REQUEST, props<ExtendFreeTrialRequest>());
export const ExtendFreeTrialResponseAction              = createAction(EXTEND_FREE_TRIAL_RESPONSE, props<ExtendFreeTrialResponse>());
