export const FETCH_NUMBER_RANGE_LIST_REQUEST  = 'FETCH_NUMBER_RANGE_LIST_REQUEST';
export const FETCH_NUMBER_RANGE_LIST_RESPONSE = 'FETCH_NUMBER_RANGE_LIST_RESPONSE';

export const ADD_NUMBER_RANGE_REQUEST  = 'ADD_NUMBER_RANGE_REQUEST';
export const ADD_NUMBER_RANGE_RESPONSE = 'ADD_NUMBER_RANGE_RESPONSE';

export const SET_NUMBER_RANGE_ROUTES_REQUEST  = 'SET_NUMBER_RANGE_ROUTES_REQUEST';
export const SET_NUMBER_RANGE_ROUTES_RESPONSE = 'SET_NUMBER_RANGE_ROUTES_RESPONSE';

export const FETCH_NUMBER_RANGE_REQUEST  = 'FETCH_NUMBER_RANGE_REQUEST';
export const FETCH_NUMBER_RANGE_RESPONSE = 'FETCH_NUMBER_RANGE_RESPONSE';

export const FETCH_NUMBER_COUNTS_REQUEST  = 'FETCH_NUMBER_COUNTS_REQUEST';
export const FETCH_NUMBER_COUNTS_RESPONSE = 'FETCH_NUMBER_COUNTS_RESPONSE';

export const FETCH_NUMBER_RANGE_COUNTS_REQUEST  = 'FETCH_NUMBER_RANGE_COUNTS_REQUEST';
export const FETCH_NUMBER_RANGE_COUNTS_RESPONSE = 'FETCH_NUMBER_RANGE_COUNTS_RESPONSE';

export const FETCH_NUMBER_REQUEST  = 'FETCH_NUMBER_REQUEST';
export const FETCH_NUMBER_RESPONSE = 'FETCH_NUMBER_RESPONSE';

export const FETCH_NUMBER_LIST_REQUEST  = 'FETCH_NUMBER_LIST_REQUEST';
export const FETCH_NUMBER_LIST_RESPONSE = 'FETCH_NUMBER_LIST_RESPONSE';

export const PATCH_NUMBER_REQUEST  = 'PATCH_NUMBER_REQUEST';
export const PATCH_NUMBER_RESPONSE = 'PATCH_NUMBER_RESPONSE';

export const PATCH_NUMBER_RANGE_REQUEST  = 'PATCH_NUMBER_RANGE_REQUEST';
export const PATCH_NUMBER_RANGE_RESPONSE = 'PATCH_NUMBER_RANGE_RESPONSE';

export const ASSIGN_INBOUND_NUMBER_REQUEST  = 'ASSIGN_INBOUND_NUMBER_REQUEST';
export const ASSIGN_INBOUND_NUMBER_RESPONSE = 'ASSIGN_INBOUND_NUMBER_RESPONSE';

export const FETCH_NUMBER_TAGS_REQUEST  = 'FETCH_NUMBER_TAGS_REQUEST';
export const FETCH_NUMBER_TAGS_RESPONSE = 'FETCH_NUMBER_TAGS_RESPONSE';

export const FETCH_INVENTORY_PROVIDER_LIST_REQUEST  = 'FETCH_INVENTORY_PROVIDER_LIST_REQUEST';
export const FETCH_INVENTORY_PROVIDER_LIST_RESPONSE = 'FETCH_INVENTORY_PROVIDER_LIST_RESPONSE';

export const BULK_PROVISION_REQUEST  = 'BULK_PROVISION_REQUEST';
export const BULK_PROVISION_RESPONSE = 'BULK_PROVISION_RESPONSE';

export const CLEAR_SELECTED_NUMBER = 'CLEAR_SELECTED_NUMBER';

export const DELETE_NUMBER_RANGE_REQUEST  = 'DELETE_NUMBER_RANGE_REQUEST';
export const DELETE_NUMBER_RANGE_RESPONSE = 'DELETE_NUMBER_RANGE_RESPONSE';

export const DELETE_NUMBER_REQUEST  = 'DELETE_NUMBER_REQUEST';
export const DELETE_NUMBER_RESPONSE = 'DELETE_NUMBER_RESPONSE';

export const PATCH_NUMBER_TAG_REQUEST  = 'PATCH_NUMBER_TAG_REQUEST';
export const PATCH_NUMBER_TAG_RESPONSE = 'PATCH_NUMBER_TAG_RESPONSE';

export const DELETE_NUMBER_TAG_REQUEST  = 'DELETE_NUMBER_TAG_REQUEST';
export const DELETE_NUMBER_TAG_RESPONSE = 'DELETE_NUMBER_TAG_RESPONSE';

export const FETCH_ALL_NUMBER_TAGS_REQUEST  = 'FETCH_ALL_NUMBER_TAGS_REQUEST';
export const FETCH_ALL_NUMBER_TAGS_RESPONSE = 'FETCH_ALL_NUMBER_TAGS_RESPONSE';

export const FETCH_INVENTORY_AREA_LIST_REQUEST  = 'FETCH_INVENTORY_AREA_LIST_REQUEST';
export const FETCH_INVENTORY_AREA_LIST_RESPONSE = 'FETCH_INVENTORY_AREA_LIST_RESPONSE';

export const EXPORT_NUMBERS_REQUEST  = 'EXPORT_NUMBERS_REQUEST';
export const EXPORT_NUMBERS_RESPONSE = 'EXPORT_NUMBERS_RESPONSE';

export const FETCH_LOCATIONS_REQUEST  = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_RESPONSE = 'FETCH_LOCATIONS_RESPONSE';

export const FETCH_LOCATION_REQUEST  = 'FETCH_LOCATION_REQUEST';
export const FETCH_LOCATION_RESPONSE = 'FETCH_LOCATION_RESPONSE';

export const DELETE_LOCATION_REQUEST  = 'DELETE_LOCATION_REQUEST';
export const DELETE_LOCATION_RESPONSE = 'DELETE_LOCATION_RESPONSE';

export const POST_LOCATION_REQUEST  = 'POST_LOCATION_REQUEST';
export const POST_LOCATION_RESPONSE = 'POST_LOCATION_RESPONSE';

export const PATCH_LOCATION_REQUEST  = 'PATCH_LOCATION_REQUEST';
export const PATCH_LOCATION_RESPONSE = 'PATCH_LOCATION_RESPONSE';

export const FETCH_LOCATION_NAME_AVAILABILITY_REQUEST  = 'FETCH_LOCATION_NAME_AVAILABILITY_REQUEST';
export const FETCH_LOCATION_NAME_AVAILABILITY_RESPONSE = 'FETCH_LOCATION_NAME_AVAILABILITY_RESPONSE';

export const FETCH_BULK_COUNT_BY_ASSIGNED_REQUEST  = 'FETCH_BULK_COUNT_BY_ASSIGNED_REQUEST';
export const FETCH_BULK_COUNT_BY_ASSIGNED_RESPONSE = 'FETCH_BULK_COUNT_BY_ASSIGNED_RESPONSE';

export const NUMBER_LOCATION_REMOVE_REQUEST  = 'NUMBER_LOCATION_REMOVE_REQUEST';
export const NUMBER_LOCATION_REMOVE_RESPONSE = 'NUMBER_LOCATION_REMOVE_RESPONSE';

export const NUMBER_RESERVATION_REMOVE_REQUEST  = 'NUMBER_RESERVATION_REMOVE_REQUEST';
export const NUMBER_RESERVATION_REMOVE_RESPONSE = 'NUMBER_RESERVATION_REMOVE_RESPONSE';

export const BULK_NUMBER_UPDATE_REQUEST  = 'BULK_NUMBER_UPDATE_REQUEST';
export const BULK_NUMBER_UPDATE_RESPONSE = 'BULK_NUMBER_UPDATE_RESPONSE';

export const FETCH_NUMBERS_SERVICE_METADATA_REQUEST  = 'FETCH_NUMBERS_SERVICE_METADATA_REQUEST';
export const FETCH_NUMBERS_SERVICE_METADATA_RESPONSE = 'FETCH_NUMBERS_SERVICE_METADATA_RESPONSE';

export const IMPORT_NUMBERS_REQUEST  = 'IMPORT_NUMBERS_REQUEST';
export const IMPORT_NUMBERS_RESPONSE = 'IMPORT_NUMBERS_RESPONSE';

export const FETCH_NUMBER_COMPATIBILITY_REQUEST  = 'FETCH_NUMBER_COMPATIBILITY_REQUEST';
export const FETCH_NUMBER_COMPATIBILITY_RESPONSE = 'FETCH_NUMBER_COMPATIBILITY_RESPONSE';

export const FETCH_RANGE_COUNT_BY_EXHAUSTION_STATUS_REQUEST  = 'FETCH_RANGE_COUNT_BY_EXHAUSTION_STATUS_REQUEST';
export const FETCH_RANGE_COUNT_BY_EXHAUSTION_STATUS_RESPONSE = 'FETCH_RANGE_COUNT_BY_EXHAUSTION_STATUS_RESPONSE';
