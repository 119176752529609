<div [class]="'content-inner ' + (outline ? 'border-color-' : 'background-') + alert.backgroundColor"
     [class.has-border]="outline" fxLayout="row" fxLayoutAlign="space-between stretch">
  <div [class]="'icon-box' + (outline ? (' background-' + alert.backgroundColor) : '')">
    <mat-icon [svgIcon]="alert.icon"></mat-icon>
  </div>
  <div class="alert-content" fxLayout="row" fxLayoutAlign="start center">
    <div
      [class]="'content' + (outline ? ' border-color-' + alert.backgroundColor : (alert.linkUrl ? ' border-color-white' : ''))"
      [class.has-border]="alert.linkUrl">
      <p class="m-0 mat-body-1 color-near-black" data-cy="alert-message">{{ message }}
        <a type="button" class="ml-16 cursor-pointer show-more"
           *ngIf="alert.message.length > defaultMessageLimit"
           (click)="isShowingLess ? readMore() : readLess()">
          <span>{{ isShowingLess ? 'Show more' : 'Show less' }}</span>
          <mat-icon class="ml-4" svgIcon="{{isShowingLess ? 'cr-chevron-down' : 'cr-chevron-up'}}"></mat-icon>
        </a>
      </p>
      <mat-list *ngIf="formErrorKeys; else errorList"
                class="alert-content__list mb-6">
        <ng-container *ngFor="let field of formErrorKeys">
          <div mat-subheader class="alert-content__list__field">{{ field }}</div>
          <mat-list-item *ngFor="let err of alert.formErrors.get(field);"
                         class="alert-content__list__item" fxLayout="row">
            &middot;&nbsp;<span class="mat-body-1--smaller">{{ err }}</span>
          </mat-list-item>
        </ng-container>
      </mat-list>
      <ng-template #errorList>
        <ul *ngIf="alert?.formErrors?.has('Errors')">
          <li class="mat-body-1--smaller mb-4 ml--16"
              *ngFor="let err of alert.formErrors.get('Errors')">{{ err }}
          </li>
        </ul>
      </ng-template>

    </div>
    <div class="action-button" fxLayout="row" fxLayoutAlign="end center"
         *ngIf="alert.linkUrl">
      <a [href]="alert.linkUrl" target="_blank" class="link mat-body-1--smaller text-align-right"
         rel="nofollow noopener">Learn
        more</a>
    </div>
  </div>
  <button class="button link" type="button" fxFlex="56px" (click)="dismissAction();"
          fxLayout="row"
          fxLayoutAlign="center center" fxFlexAlign="center">
    <mat-icon svgIcon="cr-close" class="color-black"></mat-icon>
  </button>
</div>
