import { StoreState }        from '../store';
import { SubscriptionState } from './subscription.reducer';
import { createSelector }    from '@ngrx/store';

export const selectSubscription             = (state: StoreState): SubscriptionState => state.subscriptionReducer;
export const selectActivePackage            = createSelector(selectSubscription, state => state.activePackage);
export const selectActiveLicenses           = createSelector(selectSubscription, state => state.activeLicenses);
export const selectSubscriptionPendingTasks = createSelector(selectSubscription, state => state.pendingTasks);
export const selectPackageChangeCost        = createSelector(selectSubscription, state => state.packageChangeCost);
export const selectPackageCapabilities      = createSelector(selectSubscription, state => state.packageCapabilities);
export const selectHasBYONManagement        = createSelector(selectSubscription, state => state.packageCapabilities?.features?.includes('BYO_NUMBER_MANAGEMENT'));
export const selectHasAutoProvisioning      = createSelector(selectSubscription, state => state.packageCapabilities?.features?.includes('AUTO_PROVISIONING'));
export const selectHasGroupManagement       = createSelector(selectSubscription, state => state.packageCapabilities?.features?.includes('MICROSOFT_TEAMS_GROUP_MANAGEMENT'));
