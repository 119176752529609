import { ListParams }               from './list-params.model';
import { ListParamModel }           from './list-param-model';
import { ListResponseMetadata }     from '../api/list-response-metadata.model';
import { AssignedServiceFilter }    from '../assigned-service-filter.type';
import { MicrosoftTeamsCapability } from '@enums/microsoft-teams-capability.enum';
import { ProviderIdentifier }       from '@enums/provider-identifier.enum';
import { ReservationType }          from '@enums/reservation-type.enum';
import { NumberServiceType }        from '@enums/number-service-type.type';

export class NumberQueryParams extends ListParams implements ListParamModel<NumberQueryParams> {
  id?: string[];
  status?: string[];
  search?: string;
  assignedService?: AssignedServiceFilter[];
  tags?: string[];
  typeAhead?: string;
  rangeId?: string[];
  locationId?: string[];
  include?: string[] = ['range', 'reservation'];
  capabilities?: MicrosoftTeamsCapability[];
  provider?: ProviderIdentifier[];
  reservationType?: ReservationType[];
  serviceType?: NumberServiceType[];
  token?: string;

  static constructQueryString(searchParams: NumberQueryParams): string {
    const params: NumberQueryParams = { ...(searchParams || {}) as NumberQueryParams };

    return ListParams.constructQueryString(params, {
      status:          'filter[status][]',
      search:          'filter[search]',
      tags:            'filter[tag][]',
      typeAhead:       'filter[typeahead]',
      assignedService: 'filter[service_type][]',
      serviceType:     'filter[service_type][]',
      rangeId:         'filter[range_id][]',
      locationId:      'filter[location_id][]',
      capabilities:    'filter[capabilities][]',
      id:              'filter[id][]',
      provider:        'filter[provider][]',
      reservationType: 'filter[reservation_type][]',
      include:         'include[]',
    });
  }

  constructParams?(metaData: ListResponseMetadata): NumberQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.status) {
        this.status = metaData.filter.status as string[];
      }
      if (metaData.filter.search) {
        this.search = metaData.filter.search;
      }
      if (metaData.filter.tag) {
        this.tags = metaData.filter.tag;
      }
      if (metaData.filter.range_id) {
        this.rangeId = metaData.filter.range_id as string[];
      }
      if (metaData.filter.location_id) {
        this.locationId = metaData.filter.location_id as string[];
      }
      if (metaData.filter.capabilities) {
        this.capabilities = metaData.filter.capabilities;
      }
      if (metaData.filter.id) {
        this.id = metaData.filter.id;
      }
      if (metaData.filter.provider) {
        this.provider = metaData.filter.provider;
      }
      if (metaData.include) {
        this.include = metaData.include;
      }
      if (metaData.filter.reservation_type) {
        this.reservationType = metaData.filter.reservation_type;
      }
      if (metaData.filter.service_type) {
        this.assignedService = metaData.filter.service_type.filter(type => ['UNASSIGNED', 'ASSIGNED', 'RESERVED'].includes(type)) as AssignedServiceFilter[];
        this.serviceType     = metaData.filter.service_type.filter(type => !['UNASSIGNED', 'ASSIGNED', 'RESERVED'].includes(type)) as NumberServiceType[];
      }
    }
    if (metaData.token) {
      this.token = metaData.token;
    }

    return this;
  }
}
