import { Injectable } from '@angular/core';
import * as momentTz  from 'moment-timezone';
import 'moment/locale/en-gb';

@Injectable({
  providedIn: 'root'
})
export class MomentService {
  moment: typeof momentTz;

  constructor() {
    this.moment = momentTz;
  }
}
