import { Injectable, Renderer2 } from '@angular/core';
import {
  FetchCallLogRecordingRequestAction,
  FetchCallRecordingTranscriptRequestAction,
}                                from '@redux/report/report.actions';
import {
  CallLogSheetComponent,
}                                from '@dialog/reports/call-log-sheet/call-log-sheet.component';
import { StoreState }            from '@redux/store';
import { Store }                 from '@ngrx/store';
import {
  MatBottomSheet,
}                                from '@angular/material/bottom-sheet';
import {
  DownloadService,
}                                from '@services/download.service';
import {
  CallLogItem,
}                                from '@models/entity/call-log-item.model';

@Injectable({
  providedIn: 'root',
})
export class CallLogActionService {

  constructor(private store: Store<StoreState>,
              private bottomSheet: MatBottomSheet,
              private downloadService: DownloadService) { }

  openLogModal(item: CallLogItem): void {
    const fileName       = 'recording-'.concat(item.date, '-', item.time);
    const downloadAction = FetchCallLogRecordingRequestAction({ logData: item, noOpen: true });
    const logSheet       = this.bottomSheet.open(CallLogSheetComponent, {
      data:       { fileName, downloadAction, logData: item },
      panelClass: 'cr-padded-sheet',
    });
    logSheet.afterDismissed()
      .subscribe((res) => {
        if (res === 'RECO') {
          this.openAudioClip(item);
        }
      });
  }

  openAudioClip(item: CallLogItem): void {
    if (item.recordingUri) {
      this.store.dispatch(FetchCallLogRecordingRequestAction({ logData: item }));
    }
  }

  downloadRecording(renderer2: Renderer2, item: CallLogItem): Promise<void> {
    const fileName = 'recording-'.concat(item.date, '-', item.time);
    return this.downloadService.downloadS3(FetchCallLogRecordingRequestAction({
      logData: item,
      noOpen:  true,
    }), renderer2, fileName, 'reportReducer', 'recordingUri');
  }

  downloadTranscript(renderer2: Renderer2, item: CallLogItem): Promise<void> {
    const fileName = 'transcript-'.concat(item.date, '-', item.time);
    return this.downloadService.downloadAPI(FetchCallRecordingTranscriptRequestAction({
      id: item.transcriptId,
    }), renderer2, fileName, 'reportReducer', 'transcriptData');
  }

}
