export const FETCH_NOTIFICATION_COUNT_REQUEST  = 'FETCH_NOTIFICATION_COUNT_REQUEST';
export const FETCH_NOTIFICATION_COUNT_RESPONSE = 'FETCH_NOTIFICATION_COUNT_RESPONSE';

export const FETCH_NOTIFICATION_LIST_REQUEST  = 'FETCH_NOTIFICATION_LIST_REQUEST';
export const FETCH_NOTIFICATION_LIST_RESPONSE = 'FETCH_NOTIFICATION_LIST_RESPONSE';

export const POLL_NOTIFICATION_LIST_REQUEST  = 'POLL_NOTIFICATION_LIST_REQUEST';
export const POLL_NOTIFICATION_LIST_RESPONSE = 'POLL_NOTIFICATION_LIST_RESPONSE';

export const MARK_AS_READ_REQUEST  = 'MARK_AS_READ_REQUEST';
export const MARK_AS_READ_RESPONSE = 'MARK_AS_READ_RESPONSE';

export const MARK_ALL_AS_READ_REQUEST  = 'MARK_ALL_AS_READ_REQUEST';
export const MARK_ALL_AS_READ_RESPONSE = 'MARK_ALL_AS_READ_RESPONSE';

export const OPEN_FEEDBACK_REQUEST = 'OPEN_FEEDBACK_REQUEST';

export const SUBMIT_FEEDBACK_REQUEST  = 'SUBMIT_FEEDBACK_REQUEST';
export const SUBMIT_FEEDBACK_RESPONSE = 'SUBMIT_FEEDBACK_RESPONSE';
