export const FETCH_ACTIVE_PACKAGE_REQUEST  = 'FETCH_ACTIVE_PACKAGE_REQUEST';
export const FETCH_ACTIVE_PACKAGE_RESPONSE = 'FETCH_ACTIVE_PACKAGE_RESPONSE';

export const FETCH_ACTIVE_LICENSE_REQUEST  = 'FETCH_ACTIVE_LICENSE_REQUEST';
export const FETCH_ACTIVE_LICENSE_RESPONSE = 'FETCH_ACTIVE_LICENSE_RESPONSE';

export const FETCH_PACKAGE_CAPABILITIES_REQUEST  = 'FETCH_PACKAGE_CAPABILITIES_REQUEST';
export const FETCH_PACKAGE_CAPABILITIES_RESPONSE = 'FETCH_PACKAGE_CAPABILITIES_RESPONSE';

export const CALCULATE_PACKAGE_COST_REQUEST  = 'CALCULATE_PACKAGE_COST_REQUEST';
export const CALCULATE_PACKAGE_COST_RESPONSE = 'CALCULATE_PACKAGE_COST_RESPONSE';

export const FETCH_PACKAGE_LIST_REQUEST  = 'FETCH_PACKAGE_LIST_REQUEST';
export const FETCH_PACKAGE_LIST_RESPONSE = 'FETCH_PACKAGE_LIST_RESPONSE';

export const CHANGE_PACKAGE_REQUEST  = 'CHANGE_PACKAGE_REQUEST';
export const CHANGE_PACKAGE_RESPONSE = 'CHANGE_PACKAGE_RESPONSE';

export const RESET_PACKAGE_CHANGE_FLAG = 'RESET_PACKAGE_CHANGE_FLAG';
