import { ProviderIdentifier } from '@enums/provider-identifier.enum';
import { CarrierType }        from '@enums/carrier-type.enum';
import { NumberProviderRaw }  from '@models/api/number-provider.raw';
import { AssetsService }      from '@services/assets.service';

export class NumberProvider {
  id: string;
  identifier: ProviderIdentifier;
  name: string;
  carrierType: CarrierType;
  microsoftLogo?     = AssetsService.getIcon('microsoft.png');
  callrouteLogo?     = AssetsService.getFavicon('favicon-32x32.png');
  directRoutingIcon? = AssetsService.getIcon('arrow-to-dot.svg');

  constructor(private data?: NumberProvider) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: NumberProviderRaw): NumberProvider {
    this.id          = data.id;
    this.name        = data.name;
    this.identifier  = data.identifier;
    this.carrierType = data.carrier_type;

    return this;
  }

  get chipClass(): string {
    switch (this.identifier) {
      case ProviderIdentifier.MicrosoftOperatorConnect:
      case ProviderIdentifier.MicrosoftCallingPlan:
      case ProviderIdentifier.DirectRouting:
        return 'background-pink color-white';
      case ProviderIdentifier.Byo:
      case ProviderIdentifier.Sipsynergy:
        return 'background-dark-blue color-white';
    }
  }

  get logo(): string {
    if (this.isDirectRouting) {
      return this.directRoutingIcon;
    }
    if (this.isBYON) {
      return this.microsoftLogo;
    }
    return this.callrouteLogo;
  }

  get isDirectRouting(): boolean {
    return this.identifier === ProviderIdentifier.DirectRouting;
  }

  get isBYON(): boolean {
    return this.carrierType === CarrierType.BYON;
  }
}
