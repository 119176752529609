<ngx-modal-head header="{{data.carrierData.identifier === 'byod-international' ? 'SBC' : 'Carrier'}} configured!"
                headerIcon="cr-towercell"
                [closeable]="true"></ngx-modal-head>
<ngx-modal-content class="mat-typography">
  <div fxLayout="column" fxLayoutAlign="start center" class="carrier-information">
    <mat-icon svgIcon="cr-tick-circle" class="done-icon color-green"></mat-icon>
    <h4 class="mat-subheading-2">{{data.label || data.name}}</h4>
    <p class="text-align-center mat-body-1" fxLayout="row" fxLayoutAlign="start center">
      <ng-container *ngIf="data.information; else preDefined">
        <ng-container *ngIf="data.carrierData.identifier === 'byod-international'; else carrier">
          Please configure your SBC with these details.
          <a href="https://help.callroute.com/space/CKB/697565223/Bring+Your+Own+SBC" class="ml-6">
            <mat-icon svgIcon="cr-help-outline" class="color-near-black"></mat-icon>
          </a>
        </ng-container>
        <ng-template #carrier>
          Please pass the below configuration to your carrier so they can provision the service.
        </ng-template>
      </ng-container>
      <ng-template #preDefined>
        You have successfully configured&nbsp;<ng-container
        *ngIf="data.carrierData.identifier === 'byod-international'; else aCarrier">an SBC
      </ng-container>
        <ng-template #aCarrier>a carrier</ng-template>
        .
      </ng-template>
    </p>
    <ng-container *ngIf="data.information;">
      <pre class="code mat-body-1 m-0 fw-600" [innerHTML]="data.information | safeHtml"></pre>
      <div fxLayout="row" fxLayoutAlign="center center" class="mt-24" fxLayoutGap="16px">
        <button mat-icon-button matTooltip="Copy to clipboard" ngxCopy copyText="{{data.information}}">
          <mat-icon svgIcon="cr-copy"></mat-icon>
        </button>
        <button mat-icon-button matTooltip="Download as file" (click)="download()">
          <mat-icon svgIcon="cr-download"></mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</ngx-modal-content>
<br>
<ngx-modal-actions>
  <ngx-cr-button text="Done" #modalAction data-cy="submit"
                 (onClick)="close()">
  </ngx-cr-button>
</ngx-modal-actions>

