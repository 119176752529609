import { ServiceItem }                     from '@models/entity/service-item.model';
import { Alert }                           from '@models/entity/alert.model';
import { ServiceFactory }                  from '@models/factory/service.factory';
import { ServiceType }                     from '@enums/service-type.enum';
import { ServiceCarrier }                  from '@models/entity/carrier-service.model';
import { CallingProfile }                  from '@models/entity/calling-profile.model';
import { Automation }                      from '@models/entity/automation.model';
import { DeleteServiceResponse }           from '@models/api/delete-service-response.model';
import { ServiceStatus }                   from '@enums/service-status.enum';
import { SyncServiceUserListResponse }     from '@models/api/sync-service-user-list-response.model';
import { MicrosoftTeams }                  from '@models/entity/microsoft-teams.model';
import { MicrosoftTeamsUser }              from '@models/entity/microsoft-teams-user.model';
import { ServiceState }                    from '@redux/service/service.reducer';
import { addItemToList, updateItemInList } from '@redux/helpers';
import { FetchServiceUserListResponse }    from '@models/api/fetch-service-user-list-response.model';
import { BaseResponse }                    from '@redux/helpers/reducer.helper';

export function updateServiceItemFromList(serviceItems: ServiceItem[], id: string, error: Alert, patch: (service: ServiceItem) => Partial<ServiceItem>): ServiceItem[] {
  if (!serviceItems?.length || error) {
    return serviceItems || [];
  }
  return serviceItems.map((service: ServiceItem) => {
    if (service.id !== id) {
      return service;
    }
    return { ...service, ...patch(service) };
  });
}

export function updateSelectedServiceItem(serviceState: ServiceState, error: Alert, patch: (service: ServiceItem) => Partial<ServiceItem>): ServiceItem {
  if (!serviceState.selectedServiceItem || error) {
    return serviceState.selectedServiceItem;
  }
  return { ...serviceState.selectedServiceItem, ...patch(serviceState.selectedServiceItem) };
}

export function postService(state: ServiceState, error: Alert, serviceItem: ServiceItem): Partial<ServiceState> {
  const item = new ServiceFactory().create(serviceItem);
  return {
    carriers:            error || item?.serviceType !== ServiceType.Carrier ? state.carriers :
                           (state.carriers || []).concat(item as ServiceCarrier),
    serviceItems:        error || item?.serviceType === ServiceType.Carrier ? state.serviceItems :
                           (state.serviceItems || []).concat(item),
    selectedServiceItem: error ? state.selectedServiceItem : item,
  };
}

export function patchService(state: ServiceState, error: Alert, serviceItem: ServiceItem): Partial<ServiceState> {
  const item = new ServiceFactory().create(serviceItem);
  return {
    carriers:            item?.serviceType === ServiceType.Carrier && !error ? (state.carriers?.map(s => {
      if (s.id === item.id) {
        return item as ServiceCarrier;
      }
      return s;
    }) || []) : state.carriers,
    serviceItems:        error ? state.serviceItems : state.serviceItems ?
      (state.serviceItems?.map(s => {
        if (s.id === serviceItem.id) {
          return item;
        }
        return s;
      }) || []) : state.serviceItems,
    selectedServiceItem: error || item?.id !== state.selectedServiceItem?.id ? state.selectedServiceItem : item,
  };
}

export function postCallingProfile(state: ServiceState, error: Alert, item: CallingProfile): Partial<ServiceState> {
  return {
    callingProfiles: addItemToList(state.callingProfiles, item, state.callingProfilesQueryParams?.pageSize, error),
  };
}

export function patchCallingProfile(state: ServiceState, error: Alert, item: CallingProfile): Partial<ServiceState> {
  return {
    callingProfiles:        updateItemInList(state.callingProfiles, item),
    selectedCallingProfile: error || item?.id !== state.selectedCallingProfile?.id ? state.selectedCallingProfile : item,
  };
}

export function postAutomation(state: ServiceState, error: Alert, item: Automation): Partial<ServiceState> {
  return {
    automations:        addItemToList(state.automations, item, state.automationQueryParams?.pageSize, error),
    selectedAutomation: !error ? item : state.selectedAutomation,
  };
}

export function patchAutomation(state: ServiceState, error: Alert, item: Automation, updatedList: Automation[]): Partial<ServiceState> {
  return {
    automations:        error ? state.automations : state.automations ?
      ((updatedList || state.automations)?.map(s => {
        if (s.id === item.id) {
          return item;
        }
        return s;
      }) || []) : state.automations,
    selectedAutomation: error || item?.id !== state.selectedAutomation?.id ? state.selectedAutomation : item,
  };
}

export function deleteService(state: ServiceState, res: DeleteServiceResponse): Partial<ServiceState> {
  if (res.error || res.cancelled) {
    return {};
  }

  const disableService = (item: ServiceItem) => {
    return new ServiceFactory().create({
      ...item,
      provisionStatus: { ...item.provisionStatus, status: ServiceStatus.Deleted },
    });
  };

  return {
    carriers:            res.serviceType === ServiceType.Carrier && !res.error ? state.carriers?.map(carrier => {
      if (carrier.id !== res.id) {
        return carrier;
      }
      return disableService(carrier) as ServiceCarrier;
    }) : state.carriers,
    serviceItems:        !res.error ? state.serviceItems
      ?.map(item => {
        if (item.id !== res.id) {
          return item;
        }
        return disableService(item);
      })
      ?.filter(item => !!item) || [] : state.serviceItems,
    selectedServiceItem: !res.error && state.selectedServiceItem ? disableService(state.selectedServiceItem) : state.selectedServiceItem,
  };
}


export function updateUserSyncStatus(state: ServiceState, res: SyncServiceUserListResponse): Partial<ServiceState> {
  const isFullUserSync = !!res.serviceId && !res.identities?.length;
  const update         = (s: ServiceItem) => new MicrosoftTeams({
    ...s,
    isProcessing: isFullUserSync,
  } as MicrosoftTeams);

  return {
    serviceItems:            res.serviceId && state.serviceItems?.length ?
                               state.serviceItems.map(s => {
                                 if (s.id === res.serviceId) {
                                   return update(s);
                                 }
                                 return s;
                               }) : state.serviceItems,
    selectedServiceUserList: res.error || !res.identities?.length ? state.selectedServiceUserList : state.selectedServiceUserList?.map(user => {
      if (res.identities.includes(user.upn)) {
        return new MicrosoftTeamsUser({ ...user, isProcessing: true, isSyncing: true, isFailed: false });
      }
      return user;
    }) || [],
    selectedServiceUser:     res.error || !res.identities?.length || !res.identities.includes(state.selectedServiceUser?.upn) ?
                               state.selectedServiceUser :
                               new MicrosoftTeamsUser({
                                 ...state.selectedServiceUser,
                                 isProcessing: true,
                                 isSyncing:    true,
                                 isFailed:     false,
                               }),
    selectedServiceItem:     state.selectedServiceItem ? update(state.selectedServiceItem) : null,
  };
}

export function markAsProcessing(state: ServiceState, res: BaseResponse & { serviceId?: string }): Partial<ServiceState> {
  if (res.error) {
    return {};
  }
  const update = (s: ServiceItem) => new MicrosoftTeams({
    ...s,
    isProcessing: true,
  } as MicrosoftTeams);

  return {
    serviceItems:        res.serviceId && state.serviceItems?.length ?
                           state.serviceItems.map(s => {
                             if (s.id === res.serviceId) {
                               return update(s);
                             }
                             return s;
                           }) : state.serviceItems,
    selectedServiceItem: state.selectedServiceItem ? update(state.selectedServiceItem) : null,
  };
}

export function updateServiceUserViaList(res: FetchServiceUserListResponse, selectedServiceUser: MicrosoftTeamsUser): MicrosoftTeamsUser {
  if (res.error || !selectedServiceUser) {
    return selectedServiceUser;
  }
  const listItem = res.models.find(m => m.id === selectedServiceUser.id);
  if (!listItem) {
    return selectedServiceUser;
  }
  return new MicrosoftTeamsUser({
    ...listItem,
    userDataSource:    selectedServiceUser.userDataSource,
    licenseGroups:     selectedServiceUser.licenseGroups,
    licenseGroupIds:   selectedServiceUser.licenseGroupIds,
    teamGroups:        selectedServiceUser.teamGroups,
    teamGroupRoles:    selectedServiceUser.teamGroupRoles,
    callQueueGroups:   selectedServiceUser.callQueueGroups,
    callQueueGroupIds: selectedServiceUser.callQueueGroupIds,
    teamGroupIds:      selectedServiceUser.teamGroupIds,
  });
}
