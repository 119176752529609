export const FETCH_FREQUENT_CALLS_REQUEST       = 'FETCH_FREQUENT_CALLS_REQUEST';
export const FETCH_FREQUENT_CALLS_RESPONSE      = 'FETCH_FREQUENT_CALLS_RESPONSE';
export const FETCH_EXPENSIVE_CALLS_REQUEST      = 'FETCH_EXPENSIVE_CALLS_REQUEST';
export const FETCH_EXPENSIVE_CALLS_RESPONSE     = 'FETCH_EXPENSIVE_CALLS_RESPONSE';
export const FETCH_LOCATION_COST_REQUEST        = 'FETCH_LOCATION_COST_REQUEST';
export const FETCH_LOCATION_COST_RESPONSE       = 'FETCH_LOCATION_COST_RESPONSE';
export const FETCH_DESTINATION_SUMMARY_REQUEST  = 'FETCH_DESTINATION_SUMMARY_REQUEST';
export const FETCH_DESTINATION_SUMMARY_RESPONSE = 'FETCH_DESTINATION_SUMMARY_RESPONSE';

export const FETCH_REPORTING_OVERVIEW_REQUEST  = 'FETCH_REPORTING_OVERVIEW_REQUEST';
export const FETCH_REPORTING_OVERVIEW_RESPONSE = 'FETCH_REPORTING_OVERVIEW_RESPONSE';

export const FETCH_USAGE_BY_DIRECTION_REQUEST  = 'FETCH_USAGE_BY_DIRECTION_REQUEST';
export const FETCH_USAGE_BY_DIRECTION_RESPONSE = 'FETCH_USAGE_BY_DIRECTION_RESPONSE';

export const DETERMINE_FETCH_SUMMARY_BY_DIRECTION_REQUEST = 'DETERMINE_FETCH_SUMMARY_BY_DIRECTION_REQUEST';
export const FETCH_SUMMARY_BY_DIRECTION_REQUEST           = 'FETCH_SUMMARY_BY_DIRECTION_REQUEST';
export const FETCH_SUMMARY_BY_DIRECTION_RESPONSE          = 'FETCH_SUMMARY_BY_DIRECTION_RESPONSE';

export const FETCH_SUMMARY_BY_COUNTRY_REQUEST  = 'FETCH_SUMMARY_BY_COUNTRY_REQUEST';
export const FETCH_SUMMARY_BY_COUNTRY_RESPONSE = 'FETCH_SUMMARY_BY_COUNTRY_RESPONSE';

export const DETERMINE_FETCH_CHANNEL_USAGE_REQUEST = 'DETERMINE_FETCH_CHANNEL_USAGE_REQUEST';
export const FETCH_CHANNEL_USAGE_REQUEST           = 'FETCH_CHANNEL_USAGE_REQUEST';
export const FETCH_CHANNEL_USAGE_RESPONSE          = 'FETCH_CHANNEL_USAGE_RESPONSE';

export const DETERMINE_FETCH_CALL_LOG_REQUEST = 'DETERMINE_FETCH_CALL_LOG_REQUEST';
export const CLEAR_CALL_LOG_PARAMS            = 'CLEAR_CALL_LOG_PARAMS';
export const FETCH_CALL_LOG_REQUEST           = 'FETCH_CALL_LOG_REQUEST';
export const FETCH_CALL_LOG_RESPONSE          = 'FETCH_CALL_LOG_RESPONSE';

export const FETCH_CALL_LOG_SUMMARY_REQUEST  = 'FETCH_CALL_LOG_SUMMARY_REQUEST';
export const FETCH_CALL_LOG_SUMMARY_RESPONSE = 'FETCH_CALL_LOG_SUMMARY_RESPONSE';

export const FETCH_CALL_LOG_RECORDING_REQUEST  = 'FETCH_CALL_LOG_RECORDING_REQUEST';
export const FETCH_CALL_LOG_RECORDING_RESPONSE = 'FETCH_CALL_LOG_RECORDING_RESPONSE';

export const EXPORT_CDR_TO_CSV_REQUEST  = 'EXPORT_CDR_TO_CSV_REQUEST';
export const EXPORT_CDR_TO_CSV_RESPONSE = 'EXPORT_CDR_TO_CSV_RESPONSE';

export const FETCH_CALL_MINUTES_BY_DIRECTION_REQUEST  = 'FETCH_CALL_MINUTES_BY_DIRECTION_REQUEST';
export const FETCH_CALL_MINUTES_BY_DIRECTION_RESPONSE = 'FETCH_CALL_MINUTES_BY_DIRECTION_RESPONSE';

export const SET_DATE_RANGE = 'SET_DATE_RANGE';

export const FETCH_CALLS_BY_SIP_CODE_REQUEST  = 'FETCH_CALLS_BY_SIP_CODE_REQUEST';
export const FETCH_CALLS_BY_SIP_CODE_RESPONSE = 'FETCH_CALLS_BY_SIP_CODE_RESPONSE';

export const FETCH_CALLS_BY_SIP_TYPE_REQUEST  = 'FETCH_CALLS_BY_SIP_TYPE_REQUEST';
export const FETCH_CALLS_BY_SIP_TYPE_RESPONSE = 'FETCH_CALLS_BY_SIP_TYPE_RESPONSE';

export const FETCH_CALL_RECORDING_TRANSCRIPT_REQUEST  = 'FETCH_CALL_RECORDING_TRANSCRIPT_REQUEST';
export const FETCH_CALL_RECORDING_TRANSCRIPT_RESPONSE = 'FETCH_CALL_RECORDING_TRANSCRIPT_RESPONSE';

export const FETCH_COUNT_BY_CAPABILITY_REQUEST  = 'FETCH_COUNT_BY_CAPABILITY_REQUEST';
export const FETCH_COUNT_BY_CAPABILITY_RESPONSE = 'FETCH_COUNT_BY_CAPABILITY_RESPONSE';

export const FETCH_REPORT_LIST_REQUEST  = 'FETCH_REPORT_LIST_REQUEST';
export const FETCH_REPORT_LIST_RESPONSE = 'FETCH_REPORT_LIST_RESPONSE';

export const FETCH_REPORT_REQUEST  = 'FETCH_REPORT_REQUEST';
export const FETCH_REPORT_RESPONSE = 'FETCH_REPORT_RESPONSE';

export const DELETE_REPORT_REQUEST  = 'DELETE_REPORT_REQUEST';
export const DELETE_REPORT_RESPONSE = 'DELETE_REPORT_RESPONSE';

export const FETCH_TOTAL_NUMBERS_PER_COUNTRY_REQUEST  = 'FETCH_TOTAL_NUMBERS_PER_COUNTRY_REQUEST';
export const FETCH_TOTAL_NUMBERS_PER_COUNTRY_RESPONSE = 'FETCH_TOTAL_NUMBERS_PER_COUNTRY_RESPONSE';

export const FETCH_ASSIGNED_NUMBERS_TOTAL_REQUEST  = 'FETCH_ASSIGNED_NUMBERS_TOTAL_REQUEST';
export const FETCH_ASSIGNED_NUMBERS_TOTAL_RESPONSE = 'FETCH_ASSIGNED_NUMBERS_TOTAL_RESPONSE';

export const FETCH_ASSIGNED_NUMBERS_BY_LOCATION_REQUEST  = 'FETCH_ASSIGNED_NUMBERS_BY_LOCATION_REQUEST';
export const FETCH_ASSIGNED_NUMBERS_BY_LOCATION_RESPONSE = 'FETCH_ASSIGNED_NUMBERS_BY_LOCATION_RESPONSE';

export const FETCH_ASSIGNED_NUMBERS_BY_RANGE_REQUEST  = 'FETCH_ASSIGNED_NUMBERS_BY_RANGE_REQUEST';
export const FETCH_ASSIGNED_NUMBERS_BY_RANGE_RESPONSE = 'FETCH_ASSIGNED_NUMBERS_BY_RANGE_RESPONSE';

export const FETCH_ASSIGNED_NUMBERS_BY_COUNTRY_REQUEST  = 'FETCH_ASSIGNED_NUMBERS_BY_COUNTRY_REQUEST';
export const FETCH_ASSIGNED_NUMBERS_BY_COUNTRY_RESPONSE = 'FETCH_ASSIGNED_NUMBERS_BY_COUNTRY_RESPONSE';

export const FETCH_ASSIGNED_NUMBERS_BY_TAG_REQUEST  = 'FETCH_ASSIGNED_NUMBERS_BY_TAG_REQUEST';
export const FETCH_ASSIGNED_NUMBERS_BY_TAG_RESPONSE = 'FETCH_ASSIGNED_NUMBERS_BY_TAG_RESPONSE';

export const FETCH_TOTAL_NUMBERS_PER_TYPE_REQUEST  = 'FETCH_TOTAL_NUMBERS_PER_TYPE_REQUEST';
export const FETCH_TOTAL_NUMBERS_PER_TYPE_RESPONSE = 'FETCH_TOTAL_NUMBERS_PER_TYPE_RESPONSE';

export const FETCH_RANGE_USAGE_TOP_REQUEST  = 'FETCH_RANGE_USAGE_TOP_REQUEST';
export const FETCH_RANGE_USAGE_TOP_RESPONSE = 'FETCH_RANGE_USAGE_TOP_RESPONSE';

export const FETCH_RANGE_EXHAUSTION_REQUEST  = 'FETCH_RANGE_EXHAUSTION_REQUEST';
export const FETCH_RANGE_EXHAUSTION_RESPONSE = 'FETCH_RANGE_EXHAUSTION_RESPONSE';

export const FETCH_SIP_RESPONSE_TYPES_REQUEST  = 'FETCH_SIP_RESPONSE_TYPES_REQUEST';
export const FETCH_SIP_RESPONSE_TYPES_RESPONSE = 'FETCH_SIP_RESPONSE_TYPES_RESPONSE';

export const FETCH_CALL_COST_BY_DIRECTION_REQUEST  = 'FETCH_CALL_COST_BY_DIRECTION_REQUEST';
export const FETCH_CALL_COST_BY_DIRECTION_RESPONSE = 'FETCH_CALL_COST_BY_DIRECTION_RESPONSE';

export const FETCH_CALL_COST_SUMMARY_BY_BAND_REQUEST  = 'FETCH_CALL_COST_BY_BAND_REQUEST';
export const FETCH_CALL_COST_SUMMARY_BY_BAND_RESPONSE = 'FETCH_CALL_COST_BY_BAND_RESPONSE';
