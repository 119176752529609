import { Injectable }                          from '@angular/core';
import { environment }                         from '../../environments/environment';
import { Observable, of }                      from 'rxjs';
import { catchError, map, take }               from 'rxjs/operators';
import { Alert }                               from '@models/entity/alert.model';
import { ApiService }                          from './api.service';
import { HttpErrorResponse }                   from '@angular/common/http';
import { APIError }                            from '@models/entity/api-error.model';
import { GetPendingTermsConditionsResponse }   from '@models/api/get-pending-terms-conditions-response.model';
import {
  GetPendingTermsConditionsResponseRaw,
}                                              from '@models/api/get-pending-terms-conditions-response-raw.model';
import { AcceptTermsConditionsReq }            from '@models/api/accept-terms-conditions-req.model';
import { FetchTopupEnabledResponse }           from '@models/api/fetch-topup-enabled-response.model';
import { FetchTopupEnabledRequest }            from '@models/api/fetch-topup-enabled-request.model';
import { ConfigurationTopupEnabledRaw }        from '@models/api/configuration-topup-enabled-raw.model';
import { FetchCurrencyCodeRequest }            from '@models/api/fetch-currency-code-request.model';
import { FetchCurrencyCodeResponse }           from '@models/api/fetch-currency-code-response.model';
import { AuthScope }                           from '@enums/auth-scope.enum';
import { ConfigurationCurrencyCodeRaw }        from '@models/api/configuration-currency-code-raw.model';
import { ToggleCallRecordingRequest }          from '@models/api/toggle-call-recording-request.model';
import { FetchCallRecordingEnabledRequest }    from '@models/api/fetch-call-recording-enabled-request.model';
import { ToggleCallRecordingResponse }         from '@models/api/toggle-call-recording-response.model';
import { FetchCallRecordingAllowedResponse }   from '@models/api/fetch-call-recording-allowed-response.model';
import { FetchCallRecordingAllowedRequest }    from '@models/api/fetch-call-recording-allowed-request.model';
import { FetchCallRecordingEnabledResponse }   from '@models/api/fetch-call-recording-enabled-response.model';
import {
  FetchCallRecordingEnabledResponseRaw,
}                                              from '@models/api/fetch-call-recording-enabled-response-raw.model';
import { FetchLowBalanceThresholdResponse }    from '@models/api/fetch-low-balance-threshold-response.model';
import { LowBalanceThresholdRaw }              from '@models/api/low-balance-threshold-raw.model';
import { SetLowBalanceThresholdRequest }       from '@models/api/set-low-balance-threshold-request.model';
import { SetLowBalanceThresholdResponse }      from '@models/api/set-low-balance-threshold-response.model';
import {
  FetchLowBalanceThresholdDefaultResponse,
}                                              from '@models/api/fetch-low-balance-threshold-default-response.model';
import { FetchMicrosoftTeamsSettingsResponse } from '@models/api/fetch-sync-settings-response.model';
import { MicrosoftTeamsConfigSettingsRaw }     from '@models/api/microsoft-teams-config-settings-raw.model';
import { MicrosoftTeamsSettings }              from '@models/entity/microsoft-teams-settings.model';
import { SetMicrosoftTeamsSettingsRequest }    from '@models/api/set-microsoft-teams-settings-request.model';
import { SetMicrosoftTeamsSettingsResponse }   from '@models/api/set-microsoft-teams-settings-response.model';
import { MicrosoftTeamsSettingsRaw }           from '@models/api/microsoft-teams-settings-raw.model';
import { NumberAssignedCounts }                from '@models/entity/number-counts.model';
import { ReportingConfigResponseRaw }          from '@models/api/reporting-config-response-raw.model';
import { FetchReportingConfigResponse }        from '@models/api/fetch-reporting-config-response.model';
import { ReportingConfig }                     from '@models/entity/reporting-config.model';
import { ReportingConfigRaw }                  from '@models/api/reporting-config-raw.model';
import { SetReportingConfigRequest }           from '@models/api/set-reporting-config-request.model';
import { SetReportingConfigResponse }          from '@models/api/set-reporting-config-response.model';
import { FetchDemoModeResponse }               from '@models/api/fetch-demo-mode-response.model';
import { DemoModeRaw }                         from '@models/api/demo-mode-raw.model';
import { SetDemoModeResponse }                 from '@models/api/set-demo-mode-response.model';
import { SetDemoModeRequest }                  from '@models/api/set-demo-mode-request.model';
import { FetchCarriersConfigResponse }         from '@models/api/fetch-carriers-config-response.model';
import { CarriersConfigResponseRaw }           from '@models/api/carriers-config-response.model';
import { SetCarriersConfigResponse }           from '@models/api/set-carriers-config-response.model';
import { SetCarriersConfigRequest }            from '@models/api/set-carriers-config-request.model';
import { ServiceConfigResponseRaw }            from '@models/api/service-config-response-raw.model';
import { SetServiceConfigRequest }             from '@models/api/set-service-config-request.model';
import { SetServiceConfigResponse }            from '@models/api/set-service-config-response.model';
import { FetchServiceConfigResponse }          from '@models/api/fetch-service-config-response.model';
import {
  FetchTrialLicenseIdentifierRequest,
}                                              from '@models/api/fetch-trial-license-identifier-request.model';
import {
  FetchTrialLicenseIdentifierResponse,
}                                              from '@models/api/fetch-trial-license-identifier-response.model';
import {
  ConfigurationTrialLicenseIdentifierRaw,
}                                              from '@models/api/configuration-trial-license-identifier-raw.model';
import {
  FetchCallRecordingAnalysisEnabledRequest,
}                                              from '@models/api/fetch-call-recording-analysis-enabled-request.model';
import {
  FetchCallRecordingAnalysisEnabledResponse,
}                                              from '@models/api/fetch-call-recording-analysis-response.model';
import {
  FetchCallRecordingAnalysisEnabledResponseRaw,
}                                              from '@models/api/fetch-call-recording-analysis-response-raw.model';
import {
  ToggleCallRecordingAnalysisRequest,
}                                              from '@models/api/toggle-call-recording-analysis-request.model';
import {
  ToggleCallRecordingAnalysisResponse,
}                                              from '@models/api/toggle-call-recording-analysis-response.model';
import { UserSyncVersionRaw }                  from '@models/api/user-sync-version-raw.model';
import { FetchUserSyncVersionResponse }        from '@models/api/fetch-user-sync-version-response.model';
import {
  FetchUserSyncCronEnabledRequest,
}                                              from '@models/api/configuration/fetch-user-sync-cron-enabled-request.model';
import {
  FetchUserSyncCronEnabledResponse,
}                                              from '@models/api/configuration/fetch-user-sync-cron-enabled-response.model';
import {
  FetchUserSyncCronEnabledRaw,
}                                              from '@models/api/configuration/fetch-user-sync-cron-enabled-raw.model';
import {
  SetNumberTombstoneDurationRequest,
}                                              from '@models/api/configuration/set-number-tombstone-duration-request.model';
import {
  SetNumberTombstoneDurationResponse,
}                                              from '@models/api/configuration/set-number-tombstone-duration-response.model';
import {
  NumberTombstoneResponseRaw,
}                                              from '@models/api/configuration/number-tombstone-response-raw.model';
import { NumberSettings }                      from '@models/entity/number-settings.model';
import {
  FetchNumberTombstoneDurationResponse,
}                                              from '@models/api/configuration/fetch-number-tombstone-duration-response.model';
import {
  FetchSSOIdentityCreationResponse,
}                                              from '@models/api/configuration/fetch-sso-identity-creation-response.model';
import {
  SSOIdentityResponseRaw,
}                                              from '@models/api/configuration/sso-identity-response-raw.model';
import {
  SetSSOIdentityCreationResponse,
}                                              from '@models/api/configuration/set-sso-identity-creation-response.model';
import {
  SetSSOIdentityCreationRequest,
}                                              from '@models/api/configuration/set-sso-identity-creation-request.model';
import {
  FetchSSOAllowPasswordResetResponse,
}                                              from '@models/api/configuration/fetch-sso-allow-password-reset-response.model';
import {
  SSOAllowPasswordResetResponseRaw,
}                                              from '@models/api/configuration/sso-allow-password-reset-response-raw.model';
import {
  SetSSOAllowPasswordResetRequest,
}                                              from '@models/api/configuration/set-sso-allow-password-reset-request.model';
import {
  SetSSOAllowPasswordResetResponse,
}                                              from '@models/api/configuration/set-sso-allow-password-reset-response.model';
import {
  SetRangeUsageThresholdRequest,
}                                              from '@models/api/configuration/set-range-usage-threshold-request.model';
import {
  SetRangeUsageThresholdResponse,
}                                              from '@models/api/configuration/set-range-usage-threshold-response.model';
import {
  RangeUsageThresholdResponseRaw,
}                                              from '@models/api/configuration/range-usage-threshold-raw.model';
import {
  FetchRangeUsageThresholdResponse,
}                                              from '@models/api/configuration/fetch-range-usage-threshold-response.model';
import {
  SetCustomerCostsToggleRequest,
}                                              from '@models/api/configuration/set-customer-costs-toggle-request.model';
import {
  SetCustomerCostsToggleResponse,
}                                              from '@models/api/configuration/set-customer-costs-toggle-response.model';
import {
  CustomerCostsToggleResponseRaw,
}                                              from '@models/api/configuration/customer-costs-toggle-response-raw.model';
import {
  FetchCustomerCostsToggleResponse,
}                                              from '@models/api/configuration/fetch-customer-costs-toggle-response.model';
import {
  FetchNumberReportsToggleResponse,
}                                              from '@models/api/configuration/fetch-number-reports-toggle-response.model';
import {
  NumberReportsToggleResponseRaw,
}                                              from '@models/api/configuration/number-reports-toggle-response-raw.model';
import {
  SetRangeUsageToggleRequest,
}                                              from '@models/api/configuration/set-range-usage-toggle-request.model';
import {
  SetRangeUsageToggleResponse,
}                                              from '@models/api/configuration/set-range-usage-toggle-response.model';
import { RangeUsageToggleResponseRaw }         from '@models/api/configuration/range-usage-toggle-raw.model';
import {
  FetchRangeUsageToggleResponse,
}                                              from '@models/api/configuration/fetch-range-usage-toggle-response.model';
import {
  FetchGlobalUIBannerResponse,
}                                              from '@models/api/configuration/fetch-global-ui-banner-response.model';
import {
  FetchGlobalUIBannerToggleResponse,
}                                              from '@models/api/configuration/fetch-global-ui-banner-toggle-response.model';
import {
  GlobalUIBannerResponseRaw,
}                                              from '@models/api/configuration/global-ui-banner-response-raw.model';
import {
  SetGlobalUIBannerToggleRequest,
}                                              from '@models/api/configuration/set-global-ui-banner-toggle-request.model';
import {
  SetGlobalUIBannerResponse,
}                                              from '@models/api/configuration/set-global-ui-banner-response.model';
import {
  SetGlobalUIBannerRequest,
}                                              from '@models/api/configuration/set-global-ui-banner-request.model';
import {
  GlobalUIBannerToggleResponseRaw,
}                                              from '@models/api/configuration/global-ui-banner-toggle-response-raw.model';
import {
  SetGlobalUIBannerToggleResponse,
}                                              from '@models/api/configuration/set-global-ui-banner-toggle-response.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  static serviceOnboarded(hasActiveService: boolean): boolean {
    return hasActiveService;
  }

  static numberOnboarded(numberCount: NumberAssignedCounts): boolean {
    return !!numberCount?.TOTAL;
  }

  static onboardingComplete(numberCounts: NumberAssignedCounts, hasActiveService: boolean): boolean {
    return ConfigurationService.numberOnboarded(numberCounts) && ConfigurationService.serviceOnboarded(hasActiveService);
  }

  private baseUrl: string = environment.api.configurationBaseUrl;

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  private buildCompanyConfigurationUri(key: string): string {
    return this.buildUri(`configuration/company/${ key }`);
  }

  private buildGlobalConfigurationUri(key: string): string {
    return this.buildUri(`configuration/global/${ key }`);
  }

  constructor(private apiService: ApiService) { }

  fetchTopupEnabled$(req: FetchTopupEnabledRequest): Observable<FetchTopupEnabledResponse> {
    return this.fetchCompanyConfiguration$<{ data: ConfigurationTopupEnabledRaw }>(req.key)
      .pipe(
        map((res: { data: ConfigurationTopupEnabledRaw }): FetchTopupEnabledResponse => {
          return {
            error:   null,
            enabled: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchTopupEnabledResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            enabled: false,
          });
        }),
      );
  }

  fetchTrialLicenseIdentifier$(req: FetchTrialLicenseIdentifierRequest): Observable<FetchTrialLicenseIdentifierResponse> {
    return this.fetchCompanyConfiguration$<{ data: ConfigurationTrialLicenseIdentifierRaw }>(req.key)
      .pipe(
        map((res: { data: ConfigurationTrialLicenseIdentifierRaw }): FetchTrialLicenseIdentifierResponse => {
          return {
            error:      null,
            identifier: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchTrialLicenseIdentifierResponse> => {
          return of({
            error:      new Alert().fromApiError(err),
            identifier: null,
          });
        }),
      );
  }

  fetchLowBalanceDefault$(): Observable<FetchLowBalanceThresholdDefaultResponse> {
    return this.fetchGlobalConfiguration$<LowBalanceThresholdRaw>('BALANCE_THRESHOLD_MONETARY_LOW_DEFAULT')
      .pipe(
        map((res: LowBalanceThresholdRaw): FetchLowBalanceThresholdDefaultResponse => {
          return {
            error:  null,
            amount: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchLowBalanceThresholdDefaultResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            amount: null,
          });
        }),
      );
  }

  setMicrosoftTeamsSettings$(req: SetMicrosoftTeamsSettingsRequest): Observable<SetMicrosoftTeamsSettingsResponse> {
    return this.setCompanyConfiguration$<MicrosoftTeamsConfigSettingsRaw, string>('SERVICE_MICROSOFT_TEAMS_SETTINGS',
      JSON.stringify(new MicrosoftTeamsSettings(req.settings).toApiData()))
      .pipe(
        map((res: MicrosoftTeamsConfigSettingsRaw): SetMicrosoftTeamsSettingsResponse => {
          let settings: MicrosoftTeamsSettings = null;
          try {
            const value = JSON.parse(res.data.value) as MicrosoftTeamsSettingsRaw;
            settings    = new MicrosoftTeamsSettings().fromApiData(value);
          } catch {
          }
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: 'Successfully updated Microsoft Teams sync settings.' }),
            settings,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SetMicrosoftTeamsSettingsResponse> => {
          return of({
            error:    new Alert().fromApiError(err),
            settings: null,
          });
        }),
      );
  }

  fetchTeamsSettings$(): Observable<FetchMicrosoftTeamsSettingsResponse> {
    return this.fetchCompanyConfiguration$<MicrosoftTeamsConfigSettingsRaw>('SERVICE_MICROSOFT_TEAMS_SETTINGS')
      .pipe(
        map((res: MicrosoftTeamsConfigSettingsRaw): FetchMicrosoftTeamsSettingsResponse => {
          let settings: MicrosoftTeamsSettings = null;
          try {
            const value = JSON.parse(res.data.value) as MicrosoftTeamsSettingsRaw;
            settings    = new MicrosoftTeamsSettings().fromApiData(value);
          } catch {
          }
          return {
            error: null,
            settings,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchMicrosoftTeamsSettingsResponse> => {
          return of({
            error:    new Alert().fromApiError(err),
            settings: null,
          });
        }),
      );
  }

  fetchReportingConfig$(): Observable<FetchReportingConfigResponse> {
    return this.fetchCompanyConfiguration$<ReportingConfigResponseRaw>('CUSTOMER_REPORT_TOGGLES')
      .pipe(
        map((res: ReportingConfigResponseRaw): FetchReportingConfigResponse => {
          let config: ReportingConfig = null;
          try {
            const value = JSON.parse(res.data.value) as ReportingConfigRaw;
            config      = new ReportingConfig().fromApiData(value);
          } catch {
          }
          return {
            error: null,
            config,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchReportingConfigResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            config: null,
          });
        }),
      );
  }

  setCarriersConfig$(req: SetCarriersConfigRequest): Observable<SetCarriersConfigResponse> {
    return this.setCompanyConfiguration$<CarriersConfigResponseRaw, string>('CUSTOMER_CARRIER_TOGGLES', JSON.stringify(req.config))
      .pipe(
        map((res: CarriersConfigResponseRaw): SetCarriersConfigResponse => {
          let config: { [identifier: string]: boolean } = null;
          try {
            config = JSON.parse(res.data.value) as { [identifier: string]: boolean };
          } catch {
          }
          return {
            error: null,
            config,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SetCarriersConfigResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            config: null,
          });
        }),
      );
  }

  fetchCarriersConfig$(): Observable<FetchCarriersConfigResponse> {
    return this.fetchCompanyConfiguration$<CarriersConfigResponseRaw>('CUSTOMER_CARRIER_TOGGLES')
      .pipe(
        map((res: CarriersConfigResponseRaw): FetchCarriersConfigResponse => {
          let config: { [identifier: string]: boolean } = null;
          try {
            config = JSON.parse(res.data.value) as { [identifier: string]: boolean };
          } catch {
          }
          return {
            error: null,
            config,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCarriersConfigResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            config: null,
          });
        }),
      );
  }

  setServiceConfig$(req: SetServiceConfigRequest): Observable<SetServiceConfigResponse> {
    return this.setCompanyConfiguration$<ServiceConfigResponseRaw, string>('CUSTOMER_SERVICE_TOGGLES', JSON.stringify(req.config))
      .pipe(
        map((res: ServiceConfigResponseRaw): SetServiceConfigResponse => {
          let config: { [name: string]: boolean } = null;
          try {
            config = JSON.parse(res.data.value) as { [name: string]: boolean };
          } catch {
          }
          return {
            error: null,
            config,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SetServiceConfigResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            config: null,
          });
        }),
      );
  }

  fetchServiceConfig$(): Observable<FetchCarriersConfigResponse> {
    return this.fetchCompanyConfiguration$<ServiceConfigResponseRaw>('CUSTOMER_SERVICE_TOGGLES')
      .pipe(
        map((res: ServiceConfigResponseRaw): FetchServiceConfigResponse => {
          let config: { [name: string]: boolean } = null;
          try {
            config = JSON.parse(res.data.value) as { [name: string]: boolean };
          } catch {
          }
          return {
            error: null,
            config,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchServiceConfigResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            config: null,
          });
        }),
      );
  }

  setReportingConfig$(req: SetReportingConfigRequest): Observable<FetchReportingConfigResponse> {
    return this.setCompanyConfiguration$<ReportingConfigResponseRaw, string>('CUSTOMER_REPORT_TOGGLES',
      JSON.stringify(new ReportingConfig(req.config).toApiData()))
      .pipe(
        map((res: ReportingConfigResponseRaw): SetReportingConfigResponse => {
          let config: ReportingConfig = null;
          try {
            const value = JSON.parse(res.data.value) as ReportingConfigRaw;
            config      = new ReportingConfig().fromApiData(value);
          } catch {
          }
          return {
            error: null,
            config,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SetReportingConfigResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            config: null,
          });
        }),
      );
  }

  fetchLowBalanceThreshold$(): Observable<FetchLowBalanceThresholdResponse> {
    return this.fetchCompanyConfiguration$<LowBalanceThresholdRaw>('BALANCE_THRESHOLD_MONETARY_LOW')
      .pipe(
        map((res: LowBalanceThresholdRaw): FetchLowBalanceThresholdResponse => {
          return {
            error:  null,
            amount: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchLowBalanceThresholdResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            amount: null,
          });
        }),
      );
  }

  fetchUserSyncVersion$(): Observable<FetchUserSyncVersionResponse> {
    return this.fetchCompanyConfiguration$<UserSyncVersionRaw>('MICROSOFT_TEAMS_USER_SYNCHRONISE_VERSION')
      .pipe(
        map((res: UserSyncVersionRaw): FetchUserSyncVersionResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchUserSyncVersionResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  fetchDemoMode$(): Observable<FetchDemoModeResponse> {
    return this.fetchCompanyConfiguration$<DemoModeRaw>('DEMO_MODE_TOGGLE')
      .pipe(
        map((res: DemoModeRaw): FetchDemoModeResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchDemoModeResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  setDemoMode$(req: SetDemoModeRequest): Observable<SetDemoModeResponse> {
    return this.setCompanyConfiguration$<DemoModeRaw, boolean>('DEMO_MODE_TOGGLE', req.value)
      .pipe(
        map((res: DemoModeRaw): SetDemoModeResponse => {
          return {
            error:   null,
            value:   res.data.value,
            message: new Alert().fromApiMessage({ message: 'Demo mode updated successfully.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SetDemoModeResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  setLowBalanceThreshold$(req: SetLowBalanceThresholdRequest): Observable<SetLowBalanceThresholdResponse> {
    return this.setCompanyConfiguration$<LowBalanceThresholdRaw, number>('BALANCE_THRESHOLD_MONETARY_LOW', req.amount)
      .pipe(
        map((res: LowBalanceThresholdRaw): SetLowBalanceThresholdResponse => {
          return {
            error:   null,
            amount:  res.data?.value || req.amount,
            message: new Alert().fromApiMessage({ message: 'Low balance threshold updated successfully.' }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<SetLowBalanceThresholdResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            amount: req.amount,
          });
        }),
      );
  }

  fetchCurrencyCode$(req: FetchCurrencyCodeRequest): Observable<FetchCurrencyCodeResponse> {
    return this.fetchCompanyConfiguration$<{ data: ConfigurationCurrencyCodeRaw }>(req.key)
      .pipe(
        map((res: { data: ConfigurationCurrencyCodeRaw }): FetchCurrencyCodeResponse => {
          return {
            error:        null,
            currencyCode: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCurrencyCodeResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            currencyCode: null,
          });
        }),
      );
  }

  fetchCallRecordingAllowed$(req: FetchCallRecordingAllowedRequest): Observable<FetchCallRecordingAllowedResponse> {
    return of({ allowed: req.scopes.includes(AuthScope.CompanyWrite), error: null });
  }

  fetchCallRecordingEnabled$(req: FetchCallRecordingEnabledRequest): Observable<FetchCallRecordingEnabledResponse> {
    return this.fetchCompanyConfiguration$<FetchCallRecordingEnabledResponseRaw>(req.key)
      .pipe(
        map((res: FetchCallRecordingEnabledResponseRaw): FetchCallRecordingEnabledResponse => {
          return {
            error:   null,
            enabled: res.data.value,
          };
        }),
        catchError((err: APIError & HttpErrorResponse): Observable<FetchCallRecordingEnabledResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            enabled: false,
          });
        }),
      );
  }

  fetchCallRecordingAnalysisEnabled$(req: FetchCallRecordingAnalysisEnabledRequest): Observable<FetchCallRecordingAnalysisEnabledResponse> {
    return this.fetchCompanyConfiguration$<FetchCallRecordingAnalysisEnabledResponseRaw>(req.key)
      .pipe(
        map((res: FetchCallRecordingAnalysisEnabledResponseRaw): FetchCallRecordingAnalysisEnabledResponse => {
          return {
            error:   null,
            enabled: res.data.value,
          };
        }),
        catchError((err: APIError & HttpErrorResponse): Observable<FetchCallRecordingAnalysisEnabledResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            enabled: false,
          });
        }),
      );
  }

  toggleCallRecording$(req: ToggleCallRecordingRequest): Observable<ToggleCallRecordingResponse> {
    return this.setCompanyConfiguration$<FetchCallRecordingEnabledResponseRaw, boolean>(req.key, req.enable)
      .pipe(
        map((res: FetchCallRecordingEnabledResponseRaw): ToggleCallRecordingResponse => {
          return {
            error:   null,
            enabled: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<ToggleCallRecordingResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            enabled: false,
          });
        }),
      );
  }

  toggleCallRecordingAnalysis$(req: ToggleCallRecordingAnalysisRequest): Observable<ToggleCallRecordingAnalysisResponse> {
    return this.setCompanyConfiguration$<FetchCallRecordingAnalysisEnabledResponseRaw, boolean>(req.key, req.enable)
      .pipe(
        map((res: FetchCallRecordingAnalysisEnabledResponseRaw): ToggleCallRecordingAnalysisResponse => {
          return {
            error:   null,
            enabled: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<ToggleCallRecordingAnalysisResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            enabled: false,
          });
        }),
      );
  }

  fetchNumberTombstoneDuration$(): Observable<FetchNumberTombstoneDurationResponse> {
    return this.fetchCompanyConfiguration$<NumberTombstoneResponseRaw>('NUMBER_TOMBSTONE_DURATION')
      .pipe(
        map((res: NumberTombstoneResponseRaw): FetchNumberTombstoneDurationResponse => {
          return {
            error: null,
            data:  new NumberSettings().fromApiData({ TTL: res.data.value }),
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<FetchNumberTombstoneDurationResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  setNumberTombstoneDuration$(req: SetNumberTombstoneDurationRequest): Observable<SetNumberTombstoneDurationResponse> {
    if (!req.TTL) {
      return this.deleteCompanyConfiguration$('NUMBER_TOMBSTONE_DURATION')
        .pipe(
          map((): SetNumberTombstoneDurationResponse => {
            return {
              error:   null,
              data:    new NumberSettings().fromApiData({ TTL: null }, req.requestId),
              message: new Alert().fromApiMessage({ message: 'Successfully set default number quarantine duration.' }),
            };
          }),
          catchError((err: HttpErrorResponse & APIError): Observable<SetNumberTombstoneDurationResponse> => {
            return of({
              error: new Alert().fromApiError(err),
              data:  null,
            });
          }),
        );
    }

    return this.setCompanyConfiguration$<NumberTombstoneResponseRaw, string>('NUMBER_TOMBSTONE_DURATION', req.TTL)
      .pipe(
        map((res: NumberTombstoneResponseRaw): SetNumberTombstoneDurationResponse => {
          return {
            error:   null,
            data:    new NumberSettings().fromApiData({ TTL: res.data.value }, req.requestId),
            message: new Alert().fromApiMessage({ message: 'Successfully set default number quarantine duration.' }),
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<SetNumberTombstoneDurationResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchSSOIdentityCreation$(): Observable<FetchSSOIdentityCreationResponse> {
    return this.fetchCompanyConfiguration$<SSOIdentityResponseRaw>('IDENTITY_IDP_USER_ALLOW_CREATE')
      .pipe(
        map((res: SSOIdentityResponseRaw): FetchSSOIdentityCreationResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<FetchSSOIdentityCreationResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  setSSOIdentityCreation$(req: SetSSOIdentityCreationRequest): Observable<SetSSOIdentityCreationResponse> {
    return this.setCompanyConfiguration$<SSOIdentityResponseRaw, boolean>('IDENTITY_IDP_USER_ALLOW_CREATE', req.value)
      .pipe(
        map((res: SSOIdentityResponseRaw): SetSSOIdentityCreationResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<SetSSOIdentityCreationResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  fetchSSOAllowPasswordReset$(): Observable<FetchSSOAllowPasswordResetResponse> {
    return this.fetchCompanyConfiguration$<SSOAllowPasswordResetResponseRaw>('IDENTITY_IDP_USER_ALLOW_PASSWORD')
      .pipe(
        map((res: SSOAllowPasswordResetResponseRaw): FetchSSOAllowPasswordResetResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<FetchSSOAllowPasswordResetResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  setSSOAllowPasswordReset$(req: SetSSOAllowPasswordResetRequest): Observable<SetSSOAllowPasswordResetResponse> {
    return this.setCompanyConfiguration$<SSOAllowPasswordResetResponseRaw, boolean>('IDENTITY_IDP_USER_ALLOW_PASSWORD', req.value)
      .pipe(
        map((res: SSOAllowPasswordResetResponseRaw): SetSSOAllowPasswordResetResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<SetSSOAllowPasswordResetResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  fetchRangeUsageThreshold$(): Observable<FetchRangeUsageThresholdResponse> {
    return this.fetchCompanyConfiguration$<RangeUsageThresholdResponseRaw>('RANGE_USAGE_ALERT_THRESHOLD')
      .pipe(
        map((res: RangeUsageThresholdResponseRaw): FetchRangeUsageThresholdResponse => {
          return {
            error: null,
            value: Math.round(+res.data.value * 100),
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<FetchRangeUsageThresholdResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  fetchCustomerCostsToggle$(): Observable<FetchCustomerCostsToggleResponse> {
    return this.fetchCompanyConfiguration$<CustomerCostsToggleResponseRaw>('CUSTOMER_COSTS_TOGGLE')
      .pipe(
        map((res: CustomerCostsToggleResponseRaw): FetchCustomerCostsToggleResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<FetchCustomerCostsToggleResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  setRangeUsageThreshold$(req: SetRangeUsageThresholdRequest): Observable<SetRangeUsageThresholdResponse> {
    return this.setCompanyConfiguration$<RangeUsageThresholdResponseRaw, string>('RANGE_USAGE_ALERT_THRESHOLD', `${ req.value / 100 }`)
      .pipe(
        map((res: RangeUsageThresholdResponseRaw): SetRangeUsageThresholdResponse => {
          return {
            error:   null,
            value:   Math.round(+res.data.value * 100),
            message: new Alert().fromApiMessage({ message: `Successfully updated range availability warning threshold.` }),
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<SetRangeUsageThresholdResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  setCustomerCostsToggle$(req: SetCustomerCostsToggleRequest): Observable<SetCustomerCostsToggleResponse> {
    return this.setCompanyConfiguration$<CustomerCostsToggleResponseRaw, boolean>('CUSTOMER_COSTS_TOGGLE', req.value)
      .pipe(
        map((res: CustomerCostsToggleResponseRaw): SetCustomerCostsToggleResponse => {
          return {
            error:   null,
            value:   res.data.value,
            message: new Alert().fromApiMessage({ message: 'Successfully set customer costs toggle.' }),
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<SetCustomerCostsToggleResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  setRangeUsageToggle$(req: SetRangeUsageToggleRequest): Observable<SetRangeUsageToggleResponse> {
    return this.setCompanyConfiguration$<RangeUsageToggleResponseRaw, boolean>('RANGE_USAGE_ALERT_TOGGLE', req.value)
      .pipe(
        map((res: RangeUsageToggleResponseRaw): SetRangeUsageToggleResponse => {
          return {
            error:   null,
            value:   res.data.value,
            message: new Alert().fromApiMessage({ message: `Successfully ${ res.data.value ? 'enabled' : 'disabled' } range high usage warning.` }),
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<SetRangeUsageToggleResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  fetchRangeUsageToggle$(): Observable<FetchRangeUsageToggleResponse> {
    return this.fetchCompanyConfiguration$<RangeUsageToggleResponseRaw>('RANGE_USAGE_ALERT_TOGGLE')
      .pipe(
        map((res: RangeUsageToggleResponseRaw): FetchRangeUsageToggleResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<FetchRangeUsageToggleResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  fetchGlobalUIBanner$(): Observable<FetchGlobalUIBannerResponse> {
    return this.fetchGlobalConfiguration$<GlobalUIBannerResponseRaw>('GLOBAL_UI_BANNER')
      .pipe(
        map((res: GlobalUIBannerResponseRaw): FetchGlobalUIBannerResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<FetchGlobalUIBannerResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  setGlobalUIBanner$(req: SetGlobalUIBannerRequest): Observable<SetGlobalUIBannerResponse> {
    return this.setGlobalConfiguration$<GlobalUIBannerResponseRaw, string>('GLOBAL_UI_BANNER', req.value)
      .pipe(
        map((res: GlobalUIBannerResponseRaw): SetGlobalUIBannerResponse => {
          return {
            error:   null,
            value:   res.data.value,
            message: new Alert().fromApiMessage({ message: `Successfully updated global UI banner.` }),
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<SetGlobalUIBannerResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  fetchGlobalUIBannerToggle$(): Observable<FetchGlobalUIBannerToggleResponse> {
    return this.fetchGlobalConfiguration$<GlobalUIBannerToggleResponseRaw>('SHOW_GLOBAL_UI_BANNER')
      .pipe(
        map((res: GlobalUIBannerToggleResponseRaw): FetchGlobalUIBannerToggleResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<FetchGlobalUIBannerToggleResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  setGlobalUIBannerToggle$(req: SetGlobalUIBannerToggleRequest): Observable<SetGlobalUIBannerToggleResponse> {
    return this.setGlobalConfiguration$<GlobalUIBannerToggleResponseRaw, boolean>('SHOW_GLOBAL_UI_BANNER', req.value)
      .pipe(
        map((res: GlobalUIBannerToggleResponseRaw): SetGlobalUIBannerToggleResponse => {
          return {
            error:   null,
            value:   res.data.value,
            message: new Alert().fromApiMessage({ message: `Successfully ${ req.value ? 'enabled' : 'disabled' } global UI banner.` }),
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<SetGlobalUIBannerToggleResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  fetchNumberReportsToggle$(): Observable<FetchNumberReportsToggleResponse> {
    return this.fetchCompanyConfiguration$<NumberReportsToggleResponseRaw>('NUMBER_REPORTS_TOGGLE')
      .pipe(
        map((res: NumberReportsToggleResponseRaw): FetchNumberReportsToggleResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<FetchNumberReportsToggleResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: null,
          });
        }),
      );
  }

  pendingTermsVersion$(): Observable<GetPendingTermsConditionsResponse> {
    return this.apiService.apiGet$<{ data: GetPendingTermsConditionsResponseRaw }>(
      this.buildUri(`terms`),
      { authRequired: true })
      .pipe(
        map((res: { data: GetPendingTermsConditionsResponseRaw }): GetPendingTermsConditionsResponse => {
          return {
            error:          null,
            pendingVersion: !res.data.is_latest_accepted ? res.data.version_latest : null,
            uriLatest:      res.data.uri_latest,
          };
        }),
        catchError((err: HttpErrorResponse & APIError): Observable<GetPendingTermsConditionsResponse> => {
          return of({
            error:          new Alert().fromApiError(err),
            pendingVersion: null,
            uriLatest:      null,
          });
        }),
      );
  }

  fetchUserSyncCronEnabled$(req: FetchUserSyncCronEnabledRequest): Observable<FetchUserSyncCronEnabledResponse> {
    return this.fetchCompanyConfiguration$<FetchUserSyncCronEnabledRaw>(req.key)
      .pipe(
        map((res: FetchUserSyncCronEnabledRaw): FetchUserSyncCronEnabledResponse => {
          return {
            error: null,
            value: res.data.value,
          };
        }),
        catchError((err: APIError & HttpErrorResponse): Observable<FetchUserSyncCronEnabledResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            value: false,
          });
        }),
      );
  }

  acceptNewTermsConditions$(req: AcceptTermsConditionsReq): Observable<unknown> {
    return this.apiService.apiPut$(
      this.buildUri('terms'),
      { version_accepted: req.version_accepted },
      { authRequired: true })
      .pipe(take(1));
  }

  private fetchCompanyConfiguration$<T>(key: string): Observable<T> {
    return this.apiService.apiGet$(
      this.buildCompanyConfigurationUri(key),
      { authRequired: true });
  }

  private setCompanyConfiguration$<T, U>(key: string, value: U): Observable<T> {
    return this.apiService.apiPut$(
      this.buildCompanyConfigurationUri(key),
      { value },
      { authRequired: true });
  }

  private setGlobalConfiguration$<T, U>(key: string, value: U): Observable<T> {
    return this.apiService.apiPut$(
      this.buildGlobalConfigurationUri(key),
      { value },
      { authRequired: true });
  }

  private deleteCompanyConfiguration$<T>(key: string): Observable<T> {
    return this.apiService.apiDelete$(
      this.buildCompanyConfigurationUri(key),
      { authRequired: true });
  }

  private fetchGlobalConfiguration$<T>(key: string): Observable<T> {
    return this.apiService.apiGet$(
      this.buildGlobalConfigurationUri(key),
      { authRequired: true });
  }

}
