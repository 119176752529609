import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { PaginationChangeEvent } from '@models/form/pagination-change-event.model';
import { PaginationFormModel }   from '@models/form/pagination-form.model';

interface PaginationForm {
  pageSize: FormControl<number>;
  pageNumber: FormControl<number>;
}

@Component({
  selector:    'ngx-paginator',
  templateUrl: './paginator.component.html',
  styleUrls:   ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnChanges {
  @Input() pageSize: number;
  @Input() pageNumber: number;
  @Input() pageCount: number;
  @Input() recordCount: number;
  @Input() pageSizeOptions: Array<number>;

  @Output() paginationChange = new EventEmitter<PaginationChangeEvent>();
  public formGroup: FormGroup<PaginationForm>;

  constructor(
    private fb: FormBuilder,
  ) {
    this.formGroup = this.fb.group({
      pageSize:   this.fb.control(this.pageSize ? this.pageSize : 10),
      pageNumber: this.fb.control(this.pageNumber ? this.pageNumber : 1),
    });
  }

  ngOnChanges(c: SimpleChanges): void {
    if (c.pageNumber) {
      this.formGroup.get('pageNumber')
        .setValue(c.pageNumber.currentValue);
    }
    if (c.pageSize) {
      this.formGroup.get('pageSize')
        .setValue(c.pageSize.currentValue);
    }
  }

  changeCount(): void {
    this.formGroup.get('pageNumber')
      .setValue(1);
    this.paginationChange.emit({ params: this.formGroup.value as PaginationFormModel });
  }

  changePage(direction: number): void {
    const newPage = this.formGroup.get('pageNumber').value + direction;

    if (newPage > 0 && newPage <= this.pageCount) {
      this.formGroup.get('pageNumber')
        .setValue(newPage);
      this.paginationChange.emit({ params: this.formGroup.value as PaginationFormModel });
    }
  }
}
