import { Alert } from './alert.model';

export class RegisterRequest {
  firstName:    string;
  lastName:     string;
  email:        string;
  password:     string;
  phoneNumber:  string;

  static toApiData(req: RegisterRequest): any {
    return {
      first_name:   req.firstName,
      last_name:    req.lastName,
      email:        req.email,
      password:     req.password,
      phone_number: req.phoneNumber ? req.phoneNumber : null
    };
  }
}

export interface RegisterResponse {
  error:  Alert;
  userId: number;
}


export interface ResendActivationRequest {
  email:    string;
  // password: string;
}

export interface ResendActivationResponse {
  message:  Alert;
  error:    Alert;
}

export interface ActivateAccountRequest {
  email:  string;
  token:  string;
}

export interface ActivateAccountResponse {
  message:  Alert;
  error:    Alert;
}
