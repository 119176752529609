import { createAction, props } from '@ngrx/store';

import {
  CalculatePackageCostRequest,
  CalculatePackageCostResponse,
  ChangePackageRequest,
  ChangePackageResponse,
  FetchActivePackageResponse,
  FetchPackageListResponse,
}                                           from '@models/entity/subscription.model';
import {
  CALCULATE_PACKAGE_COST_REQUEST,
  CALCULATE_PACKAGE_COST_RESPONSE,
  CHANGE_PACKAGE_REQUEST,
  CHANGE_PACKAGE_RESPONSE,
  FETCH_ACTIVE_LICENSE_REQUEST,
  FETCH_ACTIVE_LICENSE_RESPONSE,
  FETCH_ACTIVE_PACKAGE_REQUEST,
  FETCH_ACTIVE_PACKAGE_RESPONSE,
  FETCH_PACKAGE_CAPABILITIES_REQUEST,
  FETCH_PACKAGE_CAPABILITIES_RESPONSE,
  FETCH_PACKAGE_LIST_REQUEST,
  FETCH_PACKAGE_LIST_RESPONSE,
  RESET_PACKAGE_CHANGE_FLAG,
}                                           from './subscription.types';
import { FetchActiveLicenseRequest }        from '@models/api/fetch-active-license-request.model';
import { FetchActiveLicenseResponse }       from '@models/api/fetch-active-license-response.model';
import { FetchPackageCapabilitiesRequest }  from '@models/api/fetch-package-capabilities-request.model';
import { FetchPackageCapabilitiesResponse } from '@models/api/fetch-package-capabilities-response.model';
import { BaseRequest }                      from '@redux/helpers/reducer.helper';

export const CalculatePackageCostRequestAction      = createAction(CALCULATE_PACKAGE_COST_REQUEST, props<CalculatePackageCostRequest>());
export const CalculatePackageCostResponseAction     = createAction(CALCULATE_PACKAGE_COST_RESPONSE, props<CalculatePackageCostResponse>());
export const FetchActivePackageRequestAction        = createAction(FETCH_ACTIVE_PACKAGE_REQUEST, props<BaseRequest>());
export const FetchActivePackageResponseAction       = createAction(FETCH_ACTIVE_PACKAGE_RESPONSE, props<FetchActivePackageResponse>());
export const FetchActiveLicenseRequestAction        = createAction(FETCH_ACTIVE_LICENSE_REQUEST, props<FetchActiveLicenseRequest>());
export const FetchActiveLicenseResponseAction       = createAction(FETCH_ACTIVE_LICENSE_RESPONSE, props<FetchActiveLicenseResponse>());
export const FetchPackageCapabilitiesRequestAction  = createAction(FETCH_PACKAGE_CAPABILITIES_REQUEST, props<FetchPackageCapabilitiesRequest>());
export const FetchPackageCapabilitiesResponseAction = createAction(FETCH_PACKAGE_CAPABILITIES_RESPONSE, props<FetchPackageCapabilitiesResponse>());
export const FetchPackageListRequestAction          = createAction(FETCH_PACKAGE_LIST_REQUEST, props<BaseRequest>());
export const FetchPackageListResponseAction         = createAction(FETCH_PACKAGE_LIST_RESPONSE, props<FetchPackageListResponse>());
export const ChangePackageRequestAction             = createAction(CHANGE_PACKAGE_REQUEST, props<ChangePackageRequest>());
export const ChangePackageResponseAction            = createAction(CHANGE_PACKAGE_RESPONSE, props<ChangePackageResponse>());
export const ResetPackageChangeFlagAction           = createAction(RESET_PACKAGE_CHANGE_FLAG);
