import { Inject, Injectable }                                       from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Store }                                                    from '@ngrx/store';

import { delay, Observable, of, TimeoutError } from 'rxjs';

import { filter, map, retryWhen, switchMap, take, timeout } from 'rxjs/operators';

import { StoreState } from '@redux/store';

import { LogoutAction, RefreshAccessTokenRequestAction } from '@redux/auth/auth.actions';

import * as moment                  from 'moment';
import { AuthToken }                from '@enums/auth-token.enum';
import { TokenService }             from './token.service';
import { DOCUMENT, Location }       from '@angular/common';
import { AuthState }                from '@redux/auth/auth.reducer';
import { selectAuth }               from '@redux/auth/auth.selectors';
import { User }                     from '@models/entity/user.model';
import { ValidateTokenResponseRaw } from '@models/api/validate-token-response-raw.model';
import { splitURLByPath }           from '@util/url.helper';
import { AuthStatus }               from '@enums/auth-status.enum';
import { APIOpts }                  from '@models/api/api-opts.model';

export interface TokenData {
  email: string;
  expires: string;
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  static determineAuthStatus(): AuthStatus {
    const accessToken: TokenData  = TokenService.getToken(AuthToken.Access) as TokenData;
    const refreshToken: TokenData = TokenService.getToken(AuthToken.Refresh) as TokenData;
    if (!accessToken) {
      return AuthStatus.None;
    }
    const now          = moment.utc();
    const accessExpiry = moment.utc(accessToken.expires);
    if (now.isBefore(accessExpiry)) {
      return AuthStatus.Validate;
    }
    if (!refreshToken) {
      return AuthStatus.None;
    }
    const refreshExpiry = moment.utc(refreshToken.expires);
    return now.isBefore(refreshExpiry) ? AuthStatus.Refresh : AuthStatus.None;
  }

  private static getCompanyUuid(auth: { token: string; viewCompanyUUID: string; user: User }, opts: APIOpts): string {
    if (opts?.withUserCompanyContext) {
      return auth?.user?.companyId;
    }
    return auth?.viewCompanyUUID || auth?.user?.companyId;
  }

  private readonly authReducer$: Observable<AuthState>;
  private apiRetryStrategy = (disableRetry: boolean) => (errors: Observable<HttpErrorResponse>): Observable<boolean> => {
    return errors.pipe(
      delay(500),
      switchMap((err: HttpErrorResponse | TimeoutError, i: number) => {
        if (disableRetry) {
          throw err;
        }
        if (i >= 6) {
          throw Error('Max retry attempts reached.');
        }
        if ((err.name && err.name === 'TimeoutError') || ((err as HttpErrorResponse).statusText === 'Unknown Error')) {
          return of(true);
        } else if (err instanceof HttpErrorResponse && err.status === 401) {

          if (ApiService.determineAuthStatus() !== AuthStatus.None) {
            this.store.dispatch(RefreshAccessTokenRequestAction({}));
          } else {
            this.store.dispatch(LogoutAction({}));
          }
        }
        throw err;
      }),
    );
  };
  private dayInSecs        = 60 * 60 * 24;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient,
    private store: Store<StoreState>,
    private location: Location,
  ) {
    this.authReducer$ = this.store.select(selectAuth);
  }

  private static buildUri(uriSuffix: string): string {
    return `${ uriSuffix }`;
  }

  private static withContextBody<T>(data: T, auth: { token: string; viewCompanyUUID: string; user: User }, options: { headers?: HttpHeaders; withContext?: boolean; responseType?: 'json' | 'blob' | 'arraybuffer' | 'text'; authorisationRequired?: boolean }): T {
    return (options?.withContext ?
      { ...(data as T || {}), context: auth.viewCompanyUUID || auth.user?.companyId } : data) as unknown as T;
  }

  private static getRequestHeaders(loginJwt: string, companyUUID: string): HttpHeaders {
    if (companyUUID) {
      return (new HttpHeaders())
        .set('Authorization', `Bearer ${ loginJwt }`)
        .set('Company-Context', `${ companyUUID }`);
    }

    return (new HttpHeaders())
      .set('Authorization', `Bearer ${ loginJwt }`);
  }

  private static getRequestOptions(loginJwt: string, companyUUID: string = null, withCredentials?: boolean): { headers: HttpHeaders; observe: any, withCredentials?: boolean } {
    return loginJwt ?
      { headers: ApiService.getRequestHeaders(loginJwt, companyUUID), observe: 'response', withCredentials } :
      { headers: new HttpHeaders(), observe: 'response', withCredentials };
  }

  private static observeBody$<T>(req: Observable<HttpResponse<T>>, includeStatus: boolean): Observable<T> {
    return req.pipe(
      map((res) => {
        if (includeStatus) {
          return { ...(res?.body || {}), status: res.status } as T & { status: number };
        }
        return res?.body;
      }));
  }

  private withLatestAuth$<T>(fn: (auth: { token: string, viewCompanyUUID: string, user: User }) => Observable<T>,
                             timeoutMS = 15_000,
                             requiresAuth: boolean,
                             disableRetry: boolean,
                             authTokenOverride?: string): Observable<T> {
    return this.authReducer$
      .pipe(
        map(({ token, viewCompanyUUID, user }) => ({ token, viewCompanyUUID, user })),
        filter(auth => {
          if (!requiresAuth) {
            return true;
          }
          const companyId = splitURLByPath(this.location.path())?.queryParams?.company;
          const token     = auth.token || authTokenOverride;
          return !!token && (!companyId || companyId === auth.viewCompanyUUID);
        }), // require the correct company UUID context if auth is required
        take(1),
        switchMap(fn),
        timeout(timeoutMS),
        retryWhen(this.apiRetryStrategy(disableRetry)),
      );
  }

  apiDelete$<T>(uri: string, opts: APIOpts): Observable<T> {
    return this.withLatestAuth$<T>(auth =>
      ApiService.observeBody$(
        this.http.delete<T>(
          ApiService.buildUri(uri),
          ApiService.getRequestOptions(auth?.token,
            ApiService.getCompanyUuid(auth, opts)),
        ) as Observable<HttpResponse<T>>,
        opts.includeStatus,
      ), 15_000, opts.authRequired, opts.disableRetry, opts.authToken);
  }

  apiGet$<T>(uri: string,
             opts: APIOpts): Observable<T> {
    return this.withLatestAuth$<T>(auth =>
      ApiService.observeBody$(
        (this.http.get<T>(
          ApiService.buildUri(uri),
          {
            ...(opts || {}),
            responseType: opts?.responseType as 'json',
            ...ApiService.getRequestOptions(
              opts.authToken || auth?.token,
              ApiService.getCompanyUuid(auth, opts)),
          }) as Observable<HttpResponse<T>>),
        opts.includeStatus,
      ), opts.timeoutMS, opts.authRequired, opts.disableRetry, opts.authToken);
  }

  apiPatch$<T>(uri: string,
               data: unknown,
               opts: APIOpts): Observable<T> {
    return this.withLatestAuth$<T>(auth =>
      ApiService.observeBody$(
        (this.http.patch<T>(
          ApiService.buildUri(uri),
          data,
          ApiService.getRequestOptions(opts.authToken || auth?.token, ApiService.getCompanyUuid(auth, opts)),
        ) as Observable<HttpResponse<T>>),
        opts.includeStatus,
      ), opts.timeoutMS, opts.authRequired, opts.disableRetry, opts.authToken);
  }

  apiPost$<T>(uri: string,
              data: unknown,
              opts: APIOpts): Observable<T> {
    return this.withLatestAuth$<T>(auth =>
      ApiService.observeBody$(
        (this.http.post<T>(
          ApiService.buildUri(uri),
          ApiService.withContextBody(data, auth, opts),
          ApiService.getRequestOptions(
            opts.authToken || auth?.token,
            ApiService.getCompanyUuid(auth, opts),
            opts.withCredentials),
        ) as Observable<HttpResponse<T>>),
        opts.includeStatus,
      ), opts.timeoutMS, opts.authRequired, opts.disableRetry, opts.authToken);
  }

  apiPut$<T>(uri: string,
             data: unknown,
             opts: APIOpts): Observable<T> {
    return this.withLatestAuth$<T>(auth =>
      ApiService.observeBody$(
        (this.http.put<T>(
          ApiService.buildUri(uri),
          data,
          ApiService.getRequestOptions(
            opts.authToken || auth?.token,
            ApiService.getCompanyUuid(auth, opts))) as Observable<HttpResponse<T>>),
        opts.includeStatus,
      ), opts.timeoutMS, opts.authRequired, opts.disableRetry, opts.authToken);
  }

  apiPoll$<T>(uri: string, opts: APIOpts): Observable<T> {
    return this.withLatestAuth$<T>(auth =>
      ApiService.observeBody$(
        (this.http.get<T>(
          ApiService.buildUri(uri),
          ApiService.getRequestOptions(
            opts.authToken || auth?.token,
            ApiService.getCompanyUuid(auth, opts))) as Observable<HttpResponse<T>>),
        opts.includeStatus,
      ), 15_000, opts.authRequired, opts.disableRetry, opts.authToken);
  }

  storeTokenData(itemName: string, token: string, expiryPeriod: number, email?: string): void {
    this._document.defaultView.localStorage.setItem(itemName, JSON.stringify({
      token,
      email,
      expires: moment.utc()
                 .add(expiryPeriod, 'seconds')
                 .toISOString(),
    }));
  }

  setAuthTokens(res: ValidateTokenResponseRaw, email?: string): void {
    this.storeTokenData(AuthToken.Access, res.access_token, Math.min(res.expires_in, this.dayInSecs), email);
    this.storeTokenData(AuthToken.Refresh, res.refresh_token, Math.min(res.refresh_expires_in, this.dayInSecs), email);
  }

}
