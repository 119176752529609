import { StoreState }     from '../store';
import { createSelector } from '@ngrx/store';
import { AuthState }      from './auth.reducer';
import { AuthScope }      from '@enums/auth-scope.enum';
import { SipsynergyUUID } from '@consts/sipsynergy.const';
import { ScopeService }   from '@services/scope.service';

export const selectAuth                     = (state: StoreState): AuthState => state.authReducer;
export const selectAuthPendingTasks         = createSelector(selectAuth, auth => auth.pendingTasks);
export const selectUser                     = createSelector(selectAuth, auth => auth.user);
export const selectUserEmail                = createSelector(selectAuth, auth => auth.user?.email);
export const selectCompanyId                = createSelector(selectAuth, auth => auth.viewCompanyUUID || auth.user?.companyId);
export const selectUserCompanyId            = createSelector(selectUser, user => user?.companyId);
export const selectOTPEnabled               = createSelector(selectAuth, auth => auth.otpEnabled);
export const selectOTPSecret                = createSelector(selectAuth, auth => auth.otpSecret);
export const selectUserScopes               = createSelector(selectUser, user => user?.scopes);
export const selectRolesQuery               = createSelector(selectAuth, auth => auth.rolesQueryParams);
export const selectIsSipAdmin               = createSelector(selectUser, user => {
  return [AuthScope.AdminNumber, AuthScope.NumberWrite]
    .every(scope => user?.scopes.includes(scope)) && user?.companyId === SipsynergyUUID;
});
export const selectAPIKeys                  = createSelector(selectAuth, state => state.APIKeys);
export const selectAPIKeyQueryParams        = createSelector(selectAuth, state => state.APIKeyQueryParams);
export const selectAllowedScopes            = createSelector(selectAuth, selectIsSipAdmin, (auth, isSipAdmin) => {

  return auth.scopes?.filter(scope => {
    if (isSipAdmin) {
      return auth.user?.scopes?.includes(scope.identifier);
    }
    return !ScopeService.isAdminScope(scope) && auth.user?.scopes?.includes(scope.identifier);
  })
    .map(scope => {
      return { ...scope, isDefault: ScopeService.isDefaultScope(scope) };
    }) || [];
});
export const selectRoles                    = createSelector(selectAuth, auth => auth.roles);
export const selectRedirectUrl              = createSelector(selectAuth, auth => auth.redirectUrl);
export const selectAuthError                = createSelector(selectAuth, auth => auth.error);
export const selectAuthMessage              = createSelector(selectAuth, auth => auth.message);
export const selectAPIKey                   = createSelector(selectAuth, auth => auth.selectedAPIKey);
export const selectCompanyDomains           = createSelector(selectAuth, auth => auth.companyDomains);
export const selectUserIdpIdentities        = createSelector(selectAuth, auth => auth.idpIdentities);
export const selectDomainQueryParams        = createSelector(selectAuth, auth => auth.domainQueryParams);
export const selectApprovals                = createSelector(selectAuth, auth => auth.approvals);
export const selectApprovalQueryParams      = createSelector(selectAuth, auth => auth.approvalQueryParams);
export const selectUserCounts               = createSelector(selectAuth, auth => auth.userCounts);
export const selectTokenIntrospectMetadata  = createSelector(selectAuth, auth => auth.tokenIntrospectMetadata);
export const selectSecurityGroups           = createSelector(selectAuth, state => state.securityGroups);
export const selectSecurityGroupQueryParams = createSelector(selectAuth, state => state.securityGroupQueryParams);
export const selectSecurityGroup            = createSelector(selectAuth, state => state.selectedSecurityGroup);
export const selectAuthResponses            = createSelector(selectAuth, state => state.responses);
