import { createAction, props } from '@ngrx/store';

import {
  ASSIGN_CALL_QUEUE_GROUP_REQUEST,
  ASSIGN_CALL_QUEUE_GROUP_RESPONSE,
  ASSIGN_LICENSE_GROUP_REQUEST,
  ASSIGN_LICENSE_GROUP_RESPONSE,
  ASSIGN_SERVICE_USER_NUMBER_REQUEST,
  ASSIGN_SERVICE_USER_NUMBER_RESPONSE,
  ASSIGN_TEAM_GROUP_REQUEST,
  ASSIGN_TEAM_GROUP_RESPONSE,
  CLEAR_SERVICE_USER_LIST,
  CONFIGURE_SERVICE_REQUEST,
  CONFIGURE_SERVICE_RESPONSE,
  DELETE_AUTOMATION_REQUEST,
  DELETE_AUTOMATION_RESPONSE,
  DELETE_CALLING_PROFILE_REQUEST,
  DELETE_CALLING_PROFILE_RESPONSE,
  DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_RESPONSE,
  EXPORT_SERVICE_USERS_REQUEST,
  EXPORT_SERVICE_USERS_RESPONSE,
  FETCH_AUTOMATION_LIST_REQUEST,
  FETCH_AUTOMATION_LIST_RESPONSE,
  FETCH_AUTOMATION_NAME_AVAILABILITY_REQUEST,
  FETCH_AUTOMATION_NAME_AVAILABILITY_RESPONSE,
  FETCH_AUTOMATION_REQUEST,
  FETCH_AUTOMATION_RESPONSE,
  FETCH_AVAILABLE_CARRIERS_REQUEST,
  FETCH_AVAILABLE_CARRIERS_RESPONSE,
  FETCH_CALLING_PROFILE_REQUEST,
  FETCH_CALLING_PROFILE_RESPONSE,
  FETCH_CALLING_PROFILES_REQUEST,
  FETCH_CALLING_PROFILES_RESPONSE,
  FETCH_CARRIERS_LIST_REQUEST,
  FETCH_CARRIERS_LIST_RESPONSE,
  FETCH_EMERGENCY_LOCATION_REQUEST,
  FETCH_EMERGENCY_LOCATION_RESPONSE,
  FETCH_EMERGENCY_LOCATIONS_LIST_REQUEST,
  FETCH_EMERGENCY_LOCATIONS_LIST_RESPONSE,
  FETCH_GATEWAY_STATUS_EVENTS_REQUEST,
  FETCH_GATEWAY_STATUS_EVENTS_RESPONSE,
  FETCH_PERSONA_NAME_AVAILABILITY_REQUEST,
  FETCH_PERSONA_NAME_AVAILABILITY_RESPONSE,
  FETCH_POLICY_LIST_REQUEST,
  FETCH_POLICY_LIST_RESPONSE,
  FETCH_PROVISIONING_SERVICES_REQUEST,
  FETCH_PROVISIONING_SERVICES_RESPONSE,
  FETCH_PSTN_SERVICE_UUID_REQUEST,
  FETCH_PSTN_SERVICE_UUID_RESPONSE,
  FETCH_SERVICE_COUNTS_REQUEST,
  FETCH_SERVICE_COUNTS_RESPONSE,
  FETCH_SERVICE_LIST_REQUEST,
  FETCH_SERVICE_LIST_RESPONSE,
  FETCH_SERVICE_REQUEST,
  FETCH_SERVICE_RESPONSE,
  FETCH_SERVICE_SCHEMA_REQUEST,
  FETCH_SERVICE_SCHEMA_RESPONSE,
  FETCH_SERVICE_USER_CLI_AVAILABILITY_REQUEST,
  FETCH_SERVICE_USER_CLI_AVAILABILITY_RESPONSE,
  FETCH_SERVICE_USER_COUNTS_BY_VOICE_TYPE_REQUEST,
  FETCH_SERVICE_USER_COUNTS_BY_VOICE_TYPE_RESPONSE,
  FETCH_SERVICE_USER_LIST_REQUEST,
  FETCH_SERVICE_USER_LIST_RESPONSE,
  FETCH_SERVICE_USER_REQUEST,
  FETCH_SERVICE_USER_RESPONSE,
  MIGRATE_PROFILES_REQUEST,
  MIGRATE_PROFILES_RESPONSE,
  PATCH_AUTOMATION_REQUEST,
  PATCH_AUTOMATION_RESPONSE,
  PATCH_SERVICE_REQUEST,
  PATCH_SERVICE_RESPONSE,
  POST_AUTOMATION_REQUEST,
  POST_AUTOMATION_RESPONSE,
  POST_SERVICE_REQUEST,
  POST_SERVICE_RESPONSE,
  PROMPT_SERVICE_USER_ALERT,
  PUT_CALLING_PROFILE_REQUEST,
  PUT_CALLING_PROFILE_RESPONSE,
  REAUTH_REQUEST,
  SEARCH_CALLING_PROFILES_REQUEST,
  SEARCH_CALLING_PROFILES_RESPONSE,
  SEND_CUSTOM_CARRIER_REQUEST,
  SEND_CUSTOM_CARRIER_RESPONSE,
  SYNC_AD_GROUPS_REQUEST,
  SYNC_AD_GROUPS_RESPONSE,
  SYNC_ALL_MICROSOFT_ASSETS_REQUEST,
  SYNC_ALL_MICROSOFT_ASSETS_RESPONSE,
  SYNC_CALL_QUEUES_REQUEST,
  SYNC_CALL_QUEUES_RESPONSE,
  SYNC_DOMAINS_REQUEST,
  SYNC_DOMAINS_RESPONSE,
  SYNC_LICENSES_REQUEST,
  SYNC_LICENSES_RESPONSE,
  SYNC_NUMBERS_REQUEST,
  SYNC_NUMBERS_RESPONSE,
  SYNC_POLICIES_REQUEST,
  SYNC_POLICIES_RESPONSE,
  SYNC_SERVICE_USER_LIST_REQUEST,
  SYNC_SERVICE_USER_LIST_RESPONSE,
  SYNC_TEAMS_REQUEST,
  SYNC_TEAMS_RESPONSE,
}                                               from './service.types';
import { DeleteServiceRequest }                 from '@models/api/delete-service-request.model';
import { DeleteServiceResponse }                from '@models/api/delete-service-response.model';
import { PostServiceRequest }                   from '@models/api/post-service-request.model';
import { PostServiceResponse }                  from '@models/api/post-service-response.model';
import { PatchServiceRequest }                  from '@models/api/patch-service-request.model';
import { PatchServiceResponse }                 from '@models/api/patch-service-response.model';
import { ServiceItem }                          from '@models/entity/service-item.model';
import { FetchServiceListRequest }              from '@models/api/fetch-service-list-request.model';
import { FetchServiceListResponse }             from '@models/api/fetch-service-list-response.model';
import { ConfigureServiceRequest }              from '@models/api/configure-service-request.model';
import { ConfigureServiceResponse }             from '@models/api/configure-service-response.model';
import { FetchServiceUserListRequest }          from '@models/api/fetch-service-user-list-request.model';
import { FetchServiceUserListResponse }         from '@models/api/fetch-service-user-list-response.model';
import { SyncServiceUserListRequest }           from '@models/api/sync-service-user-list-request.model';
import { SyncServiceUserListResponse }          from '@models/api/sync-service-user-list-response.model';
import { AssignServiceUserNumberRequest }       from '@models/api/assign-service-user-number-request.model';
import { AssignServiceUserNumberResponse }      from '@models/api/assign-service-user-number-response.model';
import { FetchServiceRequest }                  from '@models/api/fetch-service-request.model';
import { FetchServiceResponse }                 from '@models/api/fetch-service-response.model';
import { FetchServiceCountsResponse }           from '@models/api/fetch-service-counts-response.model';
import { BaseRequest }                          from '@redux/helpers/reducer.helper';
import { FetchAvailableCarriersRequest }        from '@models/api/fetch-available-carriers-request.model';
import { FetchAvailableCarriersResponse }       from '@models/api/fetch-available-carriers-response.model';
import { SendCustomCarrierRequest }             from '@models/api/send-custom-carrier-request.model';
import { SendCustomCarrierResponse }            from '@models/api/send-custom-carrier-response.model';
import { FetchGatewayStatusEventsRequest }      from '@models/api/fetch-gateway-status-events-request.model';
import { FetchGatewayStatusEventsResponse }     from '@models/api/fetch-gateway-status-events-response.model';
import { FetchCallingProfilesRequest }          from '@models/api/fetch-calling-profiles-request.model';
import { FetchCallingProfilesResponse }         from '@models/api/fetch-calling-profiles-response.model';
import { PutCallingProfileRequest }             from '@models/api/put-calling-profile-request.model';
import { PutCallingProfileResponse }            from '@models/api/put-calling-profile-response.model';
import { DeleteCallingProfileRequest }          from '@models/api/delete-calling-profile-request.model';
import { DeleteCallingProfileResponse }         from '@models/api/delete-calling-profile-response.model';
import { FetchPolicyListRequest }               from '@models/api/fetch-policy-list-request.model';
import { FetchPolicyListResponse }              from '@models/api/fetch-policy-list-response.model';
import { SyncPoliciesRequest }                  from '@models/api/sync-policies-request.model';
import { SyncPoliciesResponse }                 from '@models/api/sync-policies-response.model';
import { FetchPersonaNameAvailabilityRequest }  from '@models/api/fetch-persona-name-availability-request.model';
import { FetchPersonaNameAvailabilityResponse } from '@models/api/fetch-persona-name-availability-response.model';
import { MigrateProfilesRequest }               from '@models/api/migrate-profiles-request.model';
import { MigrateProfilesResponse }              from '@models/api/migrate-profiles.response.model';
import { FetchAutomationListRequest }           from '@models/api/fetch-automation-list-request.model';
import { FetchAutomationListResponse }          from '@models/api/fetch-automation-list-response.model';
import { DeleteAutomationRequest }              from '@models/api/delete-automation-request.model';
import { DeleteAutomationResponse }             from '@models/api/delete-automation-response.model';
import { FetchAutomationRequest }               from '@models/api/fetch-automation-request.model';
import { FetchAutomationResponse }              from '@models/api/fetch-automation-response.model';
import { FetchServiceSchemaRequest }            from '@models/api/fetch-service-schema-request.model';
import { FetchServiceSchemaResponse }           from '@models/api/fetch-service-schema-response.model';
import { PostAutomationRequest }                from '@models/api/post-automation-request.model';
import { PostAutomationResponse }               from '@models/api/post-automation-response.model';
import { PatchAutomationRequest }               from '@models/api/patch-automation-request.model';
import { PatchAutomationResponse }              from '@models/api/patch-automation-response.model';
import { AssignLicenseGroupRequest }            from '@models/api/assign-license-group-request.model';
import { AssignLicenseGroupResponse }           from '@models/api/assign-license-group-response.model';
import { SyncNumbersRequest }                   from '@models/api/sync-numbers-request.model';
import { SyncNumbersResponse }                  from '@models/api/sync-numbers-response.model';
import { AssignTeamGroupRequest }               from '@models/api/service/assign-team-group-request.model';
import { AssignTeamGroupResponse }              from '@models/api/service/assign-team-group-response.model';
import { FetchCallingProfileRequest }           from '@models/api/service/fetch-calling-profile-request.model';
import { FetchCallingProfileResponse }          from '@models/api/service/fetch-calling-profile-response.model';
import { SyncTeamsRequest }                     from '@models/api/service/sync-teams-request.model';
import { SyncTeamsResponse }                    from '@models/api/service/sync-teams-response.model';
import { SyncLicensesRequest }                  from '@models/api/microsoft-teams/sync-licenses-request.model';
import { SyncLicensesResponse }                 from '@models/api/microsoft-teams/sync-licenses-response.model';
import { SyncCallQueuesResponse }               from '@models/api/service/sync-call-queues-response.model';
import { SyncCallQueuesRequest }                from '@models/api/service/sync-call-queues-request.model';
import { AssignCallQueueGroupRequest }          from '@models/api/service/assign-call-queue-group-request.model';
import { AssignCallQueueGroupResponse }         from '@models/api/service/assign-call-queue-group-response.model';
import { FetchServiceUserRequest }              from '@models/api/service/fetch-service-user-request.model';
import { FetchServiceUserResponse }             from '@models/api/service/fetch-service-user-response.model';
import {
  FetchServiceUserCountsByVoiceTypeRequest,
}                                               from '@models/api/service/fetch-service-user-counts-by-voice-type-request.model';
import {
  FetchServiceUserCountsByVoiceTypeResponse,
}                                               from '@models/api/service/fetch-service-user-counts-by-voice-type-response.model';
import {
  FetchAutomationNameAvailabilityRequest,
}                                               from '@models/api/service/fetch-automation-name-availability-request.model';
import {
  FetchAutomationNameAvailabilityResponse,
}                                               from '@models/api/service/fetch-automation-name-availability-response.model';
import {
  FetchServiceUserCliAvailabilityRequest,
}                                               from '@models/api/service/fetch-service-user-cli-availability-request.model';
import {
  FetchServiceUserCliAvailabilityResponse,
}                                               from '@models/api/service/fetch-service-user-cli-availability-response.model';
import { SyncAdGroupsRequest }                  from '@models/api/service/sync-ad-groups-request.model';
import { SyncAdGroupsResponse }                 from '@models/api/service/sync-ad-groups-response.model';
import {
  SyncAllMicrosoftAssetsRequest,
}                                               from '@models/api/service/sync-all-microsoft-assets-request.model';
import {
  SyncAllMicrosoftAssetsResponse,
}                                               from '@models/api/service/sync-all-microsoft-assets-response.model';
import { SyncDomainsRequest }                   from '@models/api/service/sync-domains-request.model';
import { SyncDomainsResponse }                  from '@models/api/service/sync-domains-response.model';
import { ExportServiceUsersRequest }            from '@models/api/service/export-service-users-request.model';
import { ExportServiceUsersResponse }           from '@models/api/service/export-service-users-response.model';
import {
  FetchEmergencyLocationListRequest,
}                                               from '@models/api/service/fetch-emergency-location-list-request.model';
import {
  FetchEmergencyLocationListResponse,
}                                               from '@models/api/service/fetch-emergency-location-list-response.model';
import { FetchEmergencyLocationRequest }        from '@models/api/service/fetch-emergency-location-request.model';
import {
  FetchEmergencyLocationResponse,
}                                               from '@models/api/service/fetch-emergency-location-response.model';


export const FetchAvailableCarriersRequestAction  = createAction(FETCH_AVAILABLE_CARRIERS_REQUEST, props<FetchAvailableCarriersRequest>());
export const FetchAvailableCarriersResponseAction = createAction(FETCH_AVAILABLE_CARRIERS_RESPONSE, props<FetchAvailableCarriersResponse>());

export const FetchCarrierListRequestAction  = createAction(FETCH_CARRIERS_LIST_REQUEST, props<FetchServiceListRequest>());
export const FetchCarrierListResponseAction = createAction(FETCH_CARRIERS_LIST_RESPONSE, props<FetchServiceListResponse>());

export const FetchServiceListRequestAction  = createAction(FETCH_SERVICE_LIST_REQUEST, props<FetchServiceListRequest>());
export const FetchServiceListResponseAction = createAction(FETCH_SERVICE_LIST_RESPONSE, props<FetchServiceListResponse>());

export const FetchPSTNServiceUuidRequestAction  = createAction(FETCH_PSTN_SERVICE_UUID_REQUEST, props<BaseRequest>());
export const FetchPSTNServiceUuidResponseAction = createAction(FETCH_PSTN_SERVICE_UUID_RESPONSE, props<FetchServiceListResponse>());

export const FetchServiceRequestAction  = createAction(FETCH_SERVICE_REQUEST, props<FetchServiceRequest>());
export const FetchServiceResponseAction = createAction(FETCH_SERVICE_RESPONSE, props<FetchServiceResponse>());

export const DeleteServiceRequestAction  = createAction(DELETE_SERVICE_REQUEST, props<DeleteServiceRequest>());
export const DeleteServiceResponseAction = createAction(DELETE_SERVICE_RESPONSE, props<DeleteServiceResponse>());

export const ConfigureServiceRequestAction  = createAction(CONFIGURE_SERVICE_REQUEST, props<ConfigureServiceRequest>());
export const ConfigureServiceResponseAction = createAction(CONFIGURE_SERVICE_RESPONSE, props<ConfigureServiceResponse>());

export const PostServiceRequestAction  = createAction(POST_SERVICE_REQUEST, props<PostServiceRequest<ServiceItem>>());
export const PostServiceResponseAction = createAction(POST_SERVICE_RESPONSE, props<PostServiceResponse<ServiceItem>>());

export const PatchServiceRequestAction  = createAction(PATCH_SERVICE_REQUEST, props<PatchServiceRequest<ServiceItem>>());
export const PatchServiceResponseAction = createAction(PATCH_SERVICE_RESPONSE, props<PatchServiceResponse<ServiceItem>>());

export const FetchServiceUserListRequestAction  = createAction(FETCH_SERVICE_USER_LIST_REQUEST, props<FetchServiceUserListRequest>());
export const FetchServiceUserListResponseAction = createAction(FETCH_SERVICE_USER_LIST_RESPONSE, props<FetchServiceUserListResponse>());

export const FetchServiceCountsRequestAction  = createAction(FETCH_SERVICE_COUNTS_REQUEST, props<BaseRequest>());
export const FetchServiceCountsResponseAction = createAction(FETCH_SERVICE_COUNTS_RESPONSE, props<FetchServiceCountsResponse>());

export const SyncServiceUserListRequestAction  = createAction(SYNC_SERVICE_USER_LIST_REQUEST, props<SyncServiceUserListRequest>());
export const SyncServiceUserListResponseAction = createAction(SYNC_SERVICE_USER_LIST_RESPONSE, props<SyncServiceUserListResponse>());

export const AssignServiceUserNumberRequestAction  = createAction(ASSIGN_SERVICE_USER_NUMBER_REQUEST, props<AssignServiceUserNumberRequest>());
export const AssignServiceUserNumberResponseAction = createAction(ASSIGN_SERVICE_USER_NUMBER_RESPONSE, props<AssignServiceUserNumberResponse>());

export const FetchProvisioningServicesRequestAction  = createAction(FETCH_PROVISIONING_SERVICES_REQUEST, props<BaseRequest>());
export const FetchProvisioningServicesResponseAction = createAction(FETCH_PROVISIONING_SERVICES_RESPONSE, props<FetchServiceListResponse>());

export const FetchPersonaNameAvailabilityRequestAction  = createAction(FETCH_PERSONA_NAME_AVAILABILITY_REQUEST, props<FetchPersonaNameAvailabilityRequest>());
export const FetchPersonaNameAvailabilityResponseAction = createAction(FETCH_PERSONA_NAME_AVAILABILITY_RESPONSE, props<FetchPersonaNameAvailabilityResponse>());

export const FetchAutomationNameAvailabilityRequestAction  = createAction(FETCH_AUTOMATION_NAME_AVAILABILITY_REQUEST, props<FetchAutomationNameAvailabilityRequest>());
export const FetchAutomationNameAvailabilityResponseAction = createAction(FETCH_AUTOMATION_NAME_AVAILABILITY_RESPONSE, props<FetchAutomationNameAvailabilityResponse>());

export const SendCustomCarrierRequestAction  = createAction(SEND_CUSTOM_CARRIER_REQUEST, props<SendCustomCarrierRequest>());
export const SendCustomCarrierResponseAction = createAction(SEND_CUSTOM_CARRIER_RESPONSE, props<SendCustomCarrierResponse>());

export const FetchGatewayStatusEventsRequestAction  = createAction(FETCH_GATEWAY_STATUS_EVENTS_REQUEST, props<FetchGatewayStatusEventsRequest>());
export const FetchGatewayStatusEventsResponseAction = createAction(FETCH_GATEWAY_STATUS_EVENTS_RESPONSE, props<FetchGatewayStatusEventsResponse>());

export const FetchCallingProfilesRequestAction  = createAction(FETCH_CALLING_PROFILES_REQUEST, props<FetchCallingProfilesRequest>());
export const FetchCallingProfilesResponseAction = createAction(FETCH_CALLING_PROFILES_RESPONSE, props<FetchCallingProfilesResponse>());

export const FetchAutomationListRequestAction  = createAction(FETCH_AUTOMATION_LIST_REQUEST, props<FetchAutomationListRequest>());
export const FetchAutomationListResponseAction = createAction(FETCH_AUTOMATION_LIST_RESPONSE, props<FetchAutomationListResponse>());

export const FetchAutomationRequestAction  = createAction(FETCH_AUTOMATION_REQUEST, props<FetchAutomationRequest>());
export const FetchAutomationResponseAction = createAction(FETCH_AUTOMATION_RESPONSE, props<FetchAutomationResponse>());

export const FetchCallingProfileRequestAction  = createAction(FETCH_CALLING_PROFILE_REQUEST, props<FetchCallingProfileRequest>());
export const FetchCallingProfileResponseAction = createAction(FETCH_CALLING_PROFILE_RESPONSE, props<FetchCallingProfileResponse>());

export const SearchCallingProfilesRequestAction  = createAction(SEARCH_CALLING_PROFILES_REQUEST, props<FetchCallingProfilesRequest>());
export const SearchCallingProfilesResponseAction = createAction(SEARCH_CALLING_PROFILES_RESPONSE, props<FetchCallingProfilesResponse>());

export const PutCallingProfileRequestAction  = createAction(PUT_CALLING_PROFILE_REQUEST, props<PutCallingProfileRequest>());
export const PutCallingProfileResponseAction = createAction(PUT_CALLING_PROFILE_RESPONSE, props<PutCallingProfileResponse>());

export const DeleteCallingProfileRequestAction  = createAction(DELETE_CALLING_PROFILE_REQUEST, props<DeleteCallingProfileRequest>());
export const DeleteCallingProfileResponseAction = createAction(DELETE_CALLING_PROFILE_RESPONSE, props<DeleteCallingProfileResponse>());

export const DeleteAutomationRequestAction  = createAction(DELETE_AUTOMATION_REQUEST, props<DeleteAutomationRequest>());
export const DeleteAutomationResponseAction = createAction(DELETE_AUTOMATION_RESPONSE, props<DeleteAutomationResponse>());

export const PostAutomationRequestAction  = createAction(POST_AUTOMATION_REQUEST, props<PostAutomationRequest>());
export const PostAutomationResponseAction = createAction(POST_AUTOMATION_RESPONSE, props<PostAutomationResponse>());

export const PatchAutomationRequestAction  = createAction(PATCH_AUTOMATION_REQUEST, props<PatchAutomationRequest>());
export const PatchAutomationResponseAction = createAction(PATCH_AUTOMATION_RESPONSE, props<PatchAutomationResponse>());

export const FetchPolicyListRequestAction  = createAction(FETCH_POLICY_LIST_REQUEST, props<FetchPolicyListRequest>());
export const FetchPolicyListResponseAction = createAction(FETCH_POLICY_LIST_RESPONSE, props<FetchPolicyListResponse>());

export const SyncPoliciesRequestAction  = createAction(SYNC_POLICIES_REQUEST, props<SyncPoliciesRequest>());
export const SyncPoliciesResponseAction = createAction(SYNC_POLICIES_RESPONSE, props<SyncPoliciesResponse>());

export const SyncLicensesRequestAction  = createAction(SYNC_LICENSES_REQUEST, props<SyncLicensesRequest>());
export const SyncLicensesResponseAction = createAction(SYNC_LICENSES_RESPONSE, props<SyncLicensesResponse>());

export const FetchServiceSchemaRequestAction  = createAction(FETCH_SERVICE_SCHEMA_REQUEST, props<FetchServiceSchemaRequest>());
export const FetchServiceSchemaResponseAction = createAction(FETCH_SERVICE_SCHEMA_RESPONSE, props<FetchServiceSchemaResponse>());

export const MigrateProfilesRequestAction  = createAction(MIGRATE_PROFILES_REQUEST, props<MigrateProfilesRequest>());
export const MigrateProfilesResponseAction = createAction(MIGRATE_PROFILES_RESPONSE, props<MigrateProfilesResponse>());

export const AssignLicenseGroupRequestAction  = createAction(ASSIGN_LICENSE_GROUP_REQUEST, props<AssignLicenseGroupRequest>());
export const AssignLicenseGroupResponseAction = createAction(ASSIGN_LICENSE_GROUP_RESPONSE, props<AssignLicenseGroupResponse>());

export const AssignTeamGroupRequestAction  = createAction(ASSIGN_TEAM_GROUP_REQUEST, props<AssignTeamGroupRequest>());
export const AssignTeamGroupResponseAction = createAction(ASSIGN_TEAM_GROUP_RESPONSE, props<AssignTeamGroupResponse>());

export const AssignCallQueueGroupRequestAction  = createAction(ASSIGN_CALL_QUEUE_GROUP_REQUEST, props<AssignCallQueueGroupRequest>());
export const AssignCallQueueGroupResponseAction = createAction(ASSIGN_CALL_QUEUE_GROUP_RESPONSE, props<AssignCallQueueGroupResponse>());

export const SyncNumbersRequestAction  = createAction(SYNC_NUMBERS_REQUEST, props<SyncNumbersRequest>());
export const SyncNumbersResponseAction = createAction(SYNC_NUMBERS_RESPONSE, props<SyncNumbersResponse>());

export const PromptServiceUserAction    = createAction(PROMPT_SERVICE_USER_ALERT);
export const ClearServiceUserListAction = createAction(CLEAR_SERVICE_USER_LIST);

export const ReauthRequestAction = createAction(REAUTH_REQUEST);

export const SyncTeamsRequestAction  = createAction(SYNC_TEAMS_REQUEST, props<SyncTeamsRequest>());
export const SyncTeamsResponseAction = createAction(SYNC_TEAMS_RESPONSE, props<SyncTeamsResponse>());

export const SyncCallQueuesRequestAction  = createAction(SYNC_CALL_QUEUES_REQUEST, props<SyncCallQueuesRequest>());
export const SyncCallQueuesResponseAction = createAction(SYNC_CALL_QUEUES_RESPONSE, props<SyncCallQueuesResponse>());

export const FetchServiceUserRequestAction  = createAction(FETCH_SERVICE_USER_REQUEST, props<FetchServiceUserRequest>());
export const FetchServiceUserResponseAction = createAction(FETCH_SERVICE_USER_RESPONSE, props<FetchServiceUserResponse>());

export const FetchServiceUserCountsByVoiceTypeRequestAction  = createAction(FETCH_SERVICE_USER_COUNTS_BY_VOICE_TYPE_REQUEST, props<FetchServiceUserCountsByVoiceTypeRequest>());
export const FetchServiceUserCountsByVoiceTypeResponseAction = createAction(FETCH_SERVICE_USER_COUNTS_BY_VOICE_TYPE_RESPONSE, props<FetchServiceUserCountsByVoiceTypeResponse>());

export const FetchServiceUserCLIAvailabilityRequestAction  = createAction(FETCH_SERVICE_USER_CLI_AVAILABILITY_REQUEST, props<FetchServiceUserCliAvailabilityRequest>());
export const FetchServiceUserCLIAvailabilityResponseAction = createAction(FETCH_SERVICE_USER_CLI_AVAILABILITY_RESPONSE, props<FetchServiceUserCliAvailabilityResponse>());

export const SyncAdGroupsRequestAction  = createAction(SYNC_AD_GROUPS_REQUEST, props<SyncAdGroupsRequest>());
export const SyncAdGroupsResponseAction = createAction(SYNC_AD_GROUPS_RESPONSE, props<SyncAdGroupsResponse>());

export const SyncAllMicrosoftAssetsRequestAction  = createAction(SYNC_ALL_MICROSOFT_ASSETS_REQUEST, props<SyncAllMicrosoftAssetsRequest>());
export const SyncAllMicrosoftAssetsResponseAction = createAction(SYNC_ALL_MICROSOFT_ASSETS_RESPONSE, props<SyncAllMicrosoftAssetsResponse>());

export const SyncDomainsRequestAction  = createAction(SYNC_DOMAINS_REQUEST, props<SyncDomainsRequest>());
export const SyncDomainsResponseAction = createAction(SYNC_DOMAINS_RESPONSE, props<SyncDomainsResponse>());

export const ExportServiceUsersRequestAction  = createAction(EXPORT_SERVICE_USERS_REQUEST, props<ExportServiceUsersRequest>());
export const ExportServiceUsersResponseAction = createAction(EXPORT_SERVICE_USERS_RESPONSE, props<ExportServiceUsersResponse>());

export const FetchEmergencyLocationListRequestAction  = createAction(FETCH_EMERGENCY_LOCATIONS_LIST_REQUEST, props<FetchEmergencyLocationListRequest>());
export const FetchEmergencyLocationListResponseAction = createAction(FETCH_EMERGENCY_LOCATIONS_LIST_RESPONSE, props<FetchEmergencyLocationListResponse>());

export const FetchEmergencyLocationRequestAction  = createAction(FETCH_EMERGENCY_LOCATION_REQUEST, props<FetchEmergencyLocationRequest>());
export const FetchEmergencyLocationResponseAction = createAction(FETCH_EMERGENCY_LOCATION_RESPONSE, props<FetchEmergencyLocationResponse>());
