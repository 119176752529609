import { Component, Input } from '@angular/core';

@Component({
  selector:    'ngx-no-local-login-banner',
  templateUrl: './no-local-login-banner.component.html',
  styleUrls:   ['./no-local-login-banner.component.scss'],
})
export class NoLocalLoginBannerComponent {
  @Input() isViewingCompany: boolean;

  constructor() { }

}
