import { Component }     from '@angular/core';
import { StoreState }    from '@redux/store';
import { Store }         from '@ngrx/store';
import { RouterService } from '@services/router.service';
import { filter, take }  from 'rxjs/operators';
import { selectUser }    from '@redux/auth/auth.selectors';

@Component({
  selector:    'ngx-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls:   ['./logout-page.component.scss']
})
export class LogoutPageComponent {

  constructor(private store: Store<StoreState>,
              private routerService: RouterService) {
    this.store.select(selectUser)
      .pipe(
        filter(user => !user),
        take(1))
      .subscribe(() => this.routerService.navigate(['/login']));
  }

}
