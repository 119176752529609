import { Observable, ObservedValueOf }             from 'rxjs';
import { filter, map, skipWhile, switchMap, take } from 'rxjs/operators';
import { BaseRequest }                             from '@redux/helpers/reducer.helper';

/**
 * Uses rxjs/switchMap, filter, map
 * Checks whether a config value is true and only dispatches the effect if it is
 *
 * @returns (source: Observable<T>) => Observable<ObservedValueOf<Observable<T>>>
 *
 * @example
 * ```ts
 *  fetchUsageByLocation$ = createEffect(() => this.actions$.pipe(
 ofType(FetchUsageByLocationRequestAction),
 whenConfig(this.store.select(selectReportingConfig), 'usageByLocation'));
 * ```
 * @param config$
 * @param key
 */

export function whenConfig<T extends BaseRequest, Config>(
  config$: Observable<Config>,
  key: keyof Config): (source: Observable<T>) => Observable<ObservedValueOf<Observable<T>>> {

  return (source: Observable<T>) => source.pipe(
    switchMap((request: T) => config$.pipe(
      skipWhile(config => !config),
      take(1),
      map((config: Config) => ([config, request] as [Config, T]),
      ))),
    filter(([config, _]: [Config, T]) => {
      return !!config[key];
    }),
    map(([_, request]: [Config, T]) => {
      return request as T;
    }));

}
