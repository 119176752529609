import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class NumberTagQueryParams extends ListParams implements ListParamModel<NumberTagQueryParams> {
  typeAhead?: string;
  rangeId?: string[];

  constructor(private data?: NumberTagQueryParams) {
    super();
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  static constructQueryString(searchParams: NumberTagQueryParams): string {
    const params: NumberTagQueryParams = { ...(searchParams || {}) as NumberTagQueryParams };

    return ListParams.constructQueryString(params, {
      typeAhead: 'filter[typeahead]',
      rangeId:   'filter[range_id][]',
    });
  }

  constructParams?(metaData: ListResponseMetadata): NumberTagQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.range_id) {
        this.rangeId = metaData.filter.range_id as string[];
      }
      if (metaData.filter.assigned_service) {
        this.typeAhead = metaData.filter.type_ahead;
      }
    }

    return this;
  }
}
