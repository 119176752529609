import { FilterOption } from '@models/form/filter-option.model';

export class SipResponseType {
  id: string;
  identifier: string;
  name: string;
  codes: string[];

  constructor(private data?: SipResponseType) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toFilterOption?(): FilterOption {
    return {
      key:   this.identifier,
      label: `${ this.name }${ this.codes?.length ? (' (' + this.codes.join(', ') + ')') : '' }`,
    };
  }
}
