import { NgModule }                      from '@angular/core';
import { RouterModule, Routes }          from '@angular/router';
import { CallbackHandlerComponent }      from '@core/pages/callback-handler/callback-handler.component';
import { PageNotFoundComponent }         from '@core/pages/page-not-found/page-not-found.component';
import { AuthGuard }                     from '@guards/auth.guard';
import { PartnerAuthGuard }              from '@guards/partner-auth.guard';
import { AnonGuard }                     from '@guards/anon.guard';
import { ViewCompanyGuard }              from '@guards/view-company.guard';
import { RedirectingComponent }          from '@core/pages/redirecting/redirecting.component';
import { CompanyInviteGuard }            from '@guards/company-invite.guard';
import { SipsynergyAdminGuard }          from '@guards/sipsynergy-admin.guard';
import { LogoutPageComponent }           from '@core/pages/logout/logout-page.component';
import { AccessRestrictedPageComponent } from '@core/pages/access-restricted-page/access-restricted-page.component';
import { LoginPageComponent }            from '@core/pages/login-page/login-page.component';
import { NewUserPageComponent }          from '@core/pages/new-user-page/new-user-page.component';


const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path:         'activate',
    canLoad:      [AnonGuard],
    loadChildren: () => import('./modules/activate/activate.module').then(mod => mod.ActivateModule),
  },
  {
    path:         'overview',
    loadChildren: () => import('./modules/overview/overview.module').then(mod => mod.OverviewModule),
    canLoad:      [AuthGuard],
    canActivate:  [ViewCompanyGuard],
  },
  {
    path:         'billing',
    loadChildren: () => import('./modules/billing/billing.routes').then(mod => mod.BillingRoutes),
    canLoad:      [SipsynergyAdminGuard],
    canActivate:  [ViewCompanyGuard],
  },
  {
    path:         'reporting',
    loadChildren: () => import('./modules/reporting/reporting.module').then(mod => mod.ReportingModule),
    canLoad:      [AuthGuard],
    canActivate:  [ViewCompanyGuard],
  },
  {
    path:         'numbers',
    loadChildren: () => import('./modules/numbers/numbers.module').then(mod => mod.NumbersModule),
    canLoad:      [AuthGuard],
    canActivate:  [ViewCompanyGuard],
  },
  {
    path:         'services',
    loadChildren: () => import('./modules/services/services.module').then(mod => mod.ServicesModule),
    canLoad:      [AuthGuard],
    canActivate:  [ViewCompanyGuard],
  },
  {
    path:         'customers',
    loadChildren: () => import('./modules/customers/customer.routes').then(x => x.CustomerRoutes),
    canLoad:      [PartnerAuthGuard],
  },
  {
    path:         'admin',
    loadChildren: () => import('./modules/admin/admin.routes').then(m => m.AdminRoutes),
    canLoad:      [AuthGuard],
  },
  {
    path:         'profile',
    loadChildren: () => import('./modules/profile/profile.module').then(mod => mod.ProfileModule),
    canLoad:      [AuthGuard],
  },
  {
    path:         'notifications',
    loadChildren: () => import('./modules/notifications/notifications.module').then(mod => mod.NotificationsModule),
    canLoad:      [AuthGuard],
  },
  {
    path:         'company-invite',
    loadChildren: () => import('./modules/company-invite/company-invite.module').then(mod => mod.CompanyInviteModule),
    canLoad:      [CompanyInviteGuard],
  },
  { path: 'callback-handler', component: CallbackHandlerComponent },
  { path: 'oauth2/callback', component: CallbackHandlerComponent },
  { path: 'redirect', component: RedirectingComponent },
  {
    path:         'forgot-password',
    canLoad:      [AnonGuard],
    loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(mod => mod.ForgotPasswordModule),
  },
  {
    path:         'verify-email',
    canLoad:      [AnonGuard],
    loadChildren: () => import('./modules/verify-email/verify-email.module').then(mod => mod.VerifyEmailModule),
  },
  {
    path:         'reset-password',
    canLoad:      [AnonGuard],
    loadChildren: () => import('./modules/reset-password/reset-password.module').then(mod => mod.ResetPasswordModule),
  },
  {
    path:         'carriers',
    canLoad:      [AuthGuard],
    loadChildren: () => import('./modules/carrier/carrier.module').then(mod => mod.CarrierModule),
  },
  {
    path:         'audit',
    canLoad:      [AuthGuard],
    loadChildren: () => import('./modules/audit/audit.module').then(mod => mod.AuditModule),
  },
  {
    path:      'user-approval',
    component: NewUserPageComponent,
  },
  { path: 'login', component: LoginPageComponent, canActivate: [AnonGuard] },
  { path: 'logout', component: LogoutPageComponent },
  { path: 'access-restricted', component: AccessRestrictedPageComponent },
  // Fall-through to LoginPageComponent
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
