import { Component } from '@angular/core';

import { Store }      from '@ngrx/store';
import { Observable } from 'rxjs';

import { AuthState }  from '@redux/auth/auth.reducer';
import { StoreState } from '@redux/store';

import { SetPageTitle } from '@redux/auth/auth.actions';

import { RouterService } from '@services/router.service';
import { selectAuth }    from '@redux/auth/auth.selectors';
import { map, pluck }    from 'rxjs/operators';

@Component({
  selector:    'ngx-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls:   ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {

  public authReducer$: Observable<AuthState>;
  textColor$: Observable<string>;

  constructor(
    private store: Store<StoreState>,
    private router: RouterService,
  ) {
    this.authReducer$ = this.store.select(selectAuth);
    this.textColor$   = this.authReducer$.pipe(pluck('user'), map(user => {
      return !!user ? 'color-near-black' : 'color-white';
    }));
    this.store.dispatch(SetPageTitle({ title: 'Page not found' }));
  }

  navigateToPage(url: string): void {
    this.router.navigate([url]);
  }
}
