import { Component, ElementRef, Inject, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT, Location }                                             from '@angular/common';
import { ActivatedRoute, Params }                                         from '@angular/router';

import { Store } from '@ngrx/store';


import { BehaviorSubject, combineLatest, interval, Observable, of, Subject, timer } from 'rxjs';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom,
}                                                                                   from 'rxjs/operators';

import { NavItem, SideNav } from '@models/ui/nav.model';

import { AuthState }          from '@redux/auth/auth.reducer';
import { CompanyState }       from '@redux/company/company.reducer';
import { NotificationState }  from '@redux/notification/notification.reducer';
import { ConfigurationState } from '@redux/configuration/configuration.reducer';

import { RouterService } from '@services/router.service';

import {
  FetchUserCountsRequestAction,
  FetchUserProfileRequestAction,
  LogoutAction,
  SetCompanyHeaderAction,
  ValidateTokenRequestAction,
} from '@redux/auth/auth.actions';

import { FetchCompanyUserListRequestAction } from '@redux/company/company.actions';

import {
  FetchNumberCountsRequestAction,
  FetchNumbersServiceMetadataRequestAction,
} from '@redux/number/number.actions';

import {
  FetchNotificationCountRequestAction,
  MarkAsReadRequestAction,
  PollNotificationListRequestAction,
}                                                                       from '@redux/notification/notification.actions';
import {
  FetchGlobalUIBannerRequestAction,
  FetchGlobalUIBannerToggleRequestAction,
  FetchPendingTermsConditionsRequestAction,
  FetchRangeUsageThresholdRequestAction,
  FetchRangeUsageToggleRequestAction,
  FetchReportingConfigRequestAction,
  FetchTeamsSettingsRequestAction,
  FetchTrialLicenseIdentifierRequestAction,
}                                                                       from '@redux/configuration/configuration.actions';
import { StoreState }                                                   from '@redux/store';
import { IconService }                                                  from '@services/icon.service';
import { splitURLByPath }                                               from '@util/url.helper';
import { CallbackService }                                              from '@services/callback.service';
import { MomentService }                                                from '@services/moment.service';
import { selectProvisioningServices, selectService, selectServiceItem } from '@redux/service/service.selectors';
import { selectAuth, selectIsSipAdmin, selectUserCounts }               from '@redux/auth/auth.selectors';
import { selectBranding, selectCompany, selectUserCompany }             from '@redux/company/company.selectors';
import {
  selectNewNotifications,
  selectNotification,
  selectUnreadCount,
}                                                                       from '@redux/notification/notification.selectors';
import {
  selectConfiguration,
  selectGlobalUIBanner,
  selectGlobalUIBannerToggle,
  selectReportingConfig,
  selectTrialLicenseIdentifier,
}                                                                       from '@redux/configuration/configuration.selectors';

import {
  FetchActiveLicenseRequestAction,
  FetchActivePackageRequestAction,
  FetchPackageCapabilitiesRequestAction,
}                      from '@redux/subscription/subscription.actions';
import {
  User,
}                      from '@models/entity/user.model';
import {
  FetchServiceCountsRequestAction,
}                      from '@redux/service/service.actions';
import {
  Branding,
}                      from '@models/entity/branding.model';
import {
  NotificationStyle,
}                      from '@enums/notification-style.enum';
import deepEqual       from '@util/deepequal';
import {
  FetchBalanceDataRequestAction,
  FetchFeatureInventoryRequestAction,
}                      from '@redux/accounting/accounting.actions';
import {
  NotificationItem,
}                      from '@models/entity/notification.model';
import {
  ServiceCarrier,
}                      from '@models/entity/carrier-service.model';
import { AuthService } from '@services/auth.service';
import {
  ServiceItem,
}                      from '@models/entity/service-item.model';
import {
  AssetsService,
}                      from '@services/assets.service';
import {
  getServiceIcon,
  getServiceTypeBySlug,
}                      from '@models/api/service.model';
import {
  ServiceSlug,
}                      from '@enums/service-slug.enum';
import {
  ServiceType,
}                      from '@enums/service-type.enum';
import {
  NumberRange,
}                      from '@models/entity/number-range.model';
import {
  selectActiveLicenses,
  selectActivePackage,
  selectSubscription,
}                      from '@redux/subscription/subscription.selectors';
import {
  selectNumber,
  selectNumberRange,
}                      from '@redux/number/number.selectors';
import {
  Company,
}                      from '@models/entity/company.model';
import {
  selectAccounting,
  selectBalanceData,
}                      from '@redux/accounting/accounting.selectors';
import {
  Task,
}                      from '@models/entity/task.model';
import {
  selectAudit,
  selectTask,
  selectTaskCounts,
  selectTaskQueryParams,
  selectTasks,
}                      from '@redux/audit/audit.selectors';
import {
  selectMicrosoftTeams,
}                      from '@redux/microsoft-teams/microsoft-teams.selectors';
import {
  AlertSnackBarComponent,
}                      from './modules/notification-shared/alert-snack-bar/alert-snack-bar.component';
import {
  MatSnackBar,
}                      from '@angular/material/snack-bar';
import {
  selectReport,
}                      from '@redux/report/report.selectors';
import {
  TaskQueryParams,
}                      from '@models/form/task-query-params.model';
import { AuthScope }   from '@enums/auth-scope.enum';
import {
  ScopeService,
}                      from '@services/scope.service';
import {
  MicrosoftTeams,
}                      from '@models/entity/microsoft-teams.model';
import {
  Alert,
}                      from '@models/entity/alert.model';
import {
  UserStatus,
}                      from '@enums/user-status.enum';
import {
  ProductIdentifier,
}                      from '@enums/product-identifier.enum';
import {
  SessionManagerService,
}                      from '@services/session-manager.service';
import {
  TokenService,
}                      from '@services/token.service';
import { AuthToken }   from '@enums/auth-token.enum';
import { TokenData }   from '@services/api.service';


interface CombinedReducers {
  auth: AuthState;
  company: CompanyState;
  notification: NotificationState;
  params: Params;
  branding: Branding;
}

@Component({
  selector:    'ngx-root',
  templateUrl: './app.component.html',
  styleUrls:   ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {

  @ViewChild('mainMenu', { read: ElementRef, static: false }) mainMenu: ElementRef;
  @ViewChild('pageContainer', { read: ElementRef, static: false }) pageContainer: ElementRef;
  combinedReducers$: Observable<CombinedReducers>;
  navItems$: Observable<NavItem[]>;
  isMaintenanceMode                     = false;
  isViewingPartner$: Observable<boolean>;
  isPartner$: Observable<boolean>;
  viewingCompanyUUID$: Observable<string>;
  viewInit$: Observable<boolean>;
  authChanged                           = new Subject<void>();
  authChanged$                          = this.authChanged.asObservable();
  routeLoaded                           = new BehaviorSubject<boolean>(false);
  routeLoaded$: Observable<boolean>     = this.routeLoaded.asObservable();
  showAuthComponents$: Observable<boolean>;
  provisioningServices$: Observable<ServiceItem[]>;
  showBackground$: Observable<boolean>;
  newAlertNotifications$: Observable<NotificationItem[]>;
  newBannerNotifications$: Observable<NotificationItem[]>;
  unreadCount$: Observable<number>;
  isRedirect$: Observable<boolean>;
  logoUrl: string                       = AssetsService.getImage('callroute-logo.svg');
  headerLogoUrl$: Observable<string>;
  trialLicenseExpiry$: Observable<string | false>;
  branding$: Observable<Branding>;
  defaultColours                        = {
    menu:      '#2e2621',
    accent:    '#6b4acc',
    primary:   '#6b4acc',
    secondary: '#17d4f0',
  };
  isFreePackage$: Observable<boolean>;
  trialBackgroundColor$: Observable<string>;
  tasks$: Observable<Task[]>;
  selectedTask$: Observable<Task>;
  taskCounts$: Observable<{ [schema: string]: number }>;
  taskQueryParams$: Observable<TaskQueryParams>;
  incompleteTaskCount$: Observable<number>;
  isSipAdmin$: Observable<boolean>;
  hasAdminAudit$: Observable<boolean>;
  hasReportRead$: Observable<boolean>;
  noLocalUsers$: Observable<boolean>;
  user$: Observable<User>;
  freeCredit$: Observable<string>;
  showGlobalBanner$: Observable<boolean>;
  globalBannerText$: Observable<string>;
  private authReducer$: Observable<AuthState>;
  private companyReducer$: Observable<CompanyState>;
  private notificationReducer$: Observable<NotificationState>;
  private configurationReducer$: Observable<ConfigurationState>;
  private notifyPollTimer               = timer(0, 30_000);
  private destroy                       = new Subject<void>();
  private destroy$: Observable<unknown> = this.destroy.asObservable();
  private notifyPollDestroy             = new Subject<void>();
  private notifyPollDestroy$            = this.notifyPollDestroy.asObservable();
  private currentCompanyUUID$: Observable<string>;
  hasNotificationRead$: Observable<boolean>;
  hasTaskRead$: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<StoreState>,
    private location: Location,
    private router: RouterService,
    private renderer2: Renderer2,
    private route: ActivatedRoute,
    private iconService: IconService,
    private callbackService: CallbackService,
    private momentService: MomentService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private scopeService: ScopeService,
    private sessionManagerService: SessionManagerService,
  ) {
    this.store.dispatch(ValidateTokenRequestAction());
    this.iconService.registerIcons();
    this.callbackService.registerCallbackListeners();

    if ((window as unknown as { Cypress: unknown }).Cypress) {
      (window as unknown as { store: unknown }).store = this.store;
    }

    this.newAlertNotifications$ = this.store.select(selectNewNotifications)
      .pipe(map(notifications => notifications?.filter(n => n.type.style === NotificationStyle.Alert) || []));

    this.newBannerNotifications$ = this.store.select(selectNewNotifications)
      .pipe(map(notifications => notifications?.filter(n => n.type.style === NotificationStyle.Banner) || []));

    this.unreadCount$          = this.store.select(selectUnreadCount);
    this.authReducer$          = this.store.select(selectAuth);
    this.isSipAdmin$           = this.store.select(selectIsSipAdmin);
    this.provisioningServices$ = this.store.select(selectProvisioningServices);
    this.viewInit$             = this.authReducer$.pipe(map(auth => auth.tokenChecked));
    this.companyReducer$       = this.store.select(selectCompany);
    this.notificationReducer$  = this.store.select(selectNotification);
    this.configurationReducer$ = this.store.select(selectConfiguration);
    this.branding$             = this.store.select(selectBranding);
    this.tasks$                = this.store.select(selectTasks);
    this.selectedTask$         = this.store.select(selectTask);
    this.taskQueryParams$      = this.store.select(selectTaskQueryParams);
    this.hasAdminAudit$        = this.scopeService.hasScopes$(AuthScope.AdminAudit);
    this.hasReportRead$        = this.scopeService.hasScopes$(AuthScope.ReportRead);
    this.hasNotificationRead$  = this.scopeService.hasScopes$(AuthScope.NotificationRead);
    this.hasTaskRead$          = this.scopeService.hasScopes$(AuthScope.TaskRead);
    this.showGlobalBanner$     = this.store.select(selectGlobalUIBannerToggle);
    this.globalBannerText$     = this.store.select(selectGlobalUIBanner);
    this.noLocalUsers$         = this.store.select(selectUserCounts)
      .pipe(map(counts => counts && !!counts.status[UserStatus.Active] && !counts.provider['CALLROUTE']));
    this.isFreePackage$        = this.store.select(selectActivePackage)
      .pipe(map(p => p?.product?.identifier === ProductIdentifier.PackageFree));

    this.taskCounts$ = this.store.select(selectTaskCounts);

    this.incompleteTaskCount$ = this.taskCounts$.pipe(
      map(count => {
        if (!count) {
          return 0;
        }
        const values = Object.values(count);
        if (!values.length) {
          return 0;
        }
        return Object.values(count)
          .reduce((a, b) => a + b);
      }),
    );

    this.trialLicenseExpiry$ = this.store.select(selectTrialLicenseIdentifier)
      .pipe(switchMap(identifier => {
        if (!identifier) {
          return of(null);
        }
        return this.store.select(selectActiveLicenses)
          .pipe(
            map(licenses => {
              const endDate = licenses?.find(license => license.product.identifier === identifier)?.endDate;

              if (!endDate) {
                return null;
              }
              const end = this.momentService.moment(endDate);
              const now = this.momentService.moment.now();
              if (end.isBefore(now)) {
                return false;
              }

              const diff = this.momentService.moment.duration(end
                .diff(now));
              const days = Math.floor(diff
                .asDays());
              if (days > 0) {
                return `${ days } day${ days === 1 ? '' : 's' }`;
              }
              const hours = Math.floor(diff.asHours());
              if (hours > 0) {
                return `${ hours } hour${ hours === 1 ? '' : 's' }`;
              }
              const minutes = Math.floor(diff.asMinutes());
              return `${ minutes } minute${ minutes === 1 ? '' : 's' }`;
            }));
      }));

    this.freeCredit$ = this.store.select(selectBalanceData)
      .pipe(map(data => data?.remainingBalanceRaw));

    this.trialBackgroundColor$ = combineLatest([this.trialLicenseExpiry$, this.freeCredit$])
      .pipe(
        map(([expiry, freeCredit]) => {
          return !expiry || freeCredit === '0.00' ? 'rgb(236, 120, 0)' : '#00b97a';
        }));


    this.currentCompanyUUID$ = this.authService.getCompanyContext$();

    this.isRedirect$ = this.router.path$.pipe(map(path => path?.includes('redirect')));

    this.headerLogoUrl$ = combineLatest([
      this.store.select(selectUserCompany),
      this.router.params$.pipe(pluck('service')),
      this.router.params$.pipe(pluck('range')),
      this.store.select(selectServiceItem),
      this.store.select(selectNumberRange),
      this.router.path$])
      .pipe(map(([company, service, range, serviceItem, numberRange, fullPath]: [Company, string, string, ServiceItem, NumberRange, string]) => {
        const { path } = splitURLByPath(fullPath);

        const servicePath: string = path?.includes('/') && path.split('/')
          .find(p => getServiceTypeBySlug(p as ServiceSlug));

        if (servicePath) {
          const serviceType = getServiceTypeBySlug(servicePath as ServiceSlug);
          if (serviceType === ServiceType.Carrier && service && serviceItem) {
            return (serviceItem as ServiceCarrier).carrierData?.logoUri;
          }
          if (serviceType === ServiceType.MicrosoftTeams && (serviceItem as MicrosoftTeams)?.isOrto && fullPath.includes(serviceItem.id)) {
            return '/assets/images/orto/orto_icon.svg';
          }
          return getServiceIcon(serviceType, true);
        }
        if (range && numberRange?.country?.code) {
          return numberRange.getCountryFlagUrl();
        }
        return company?.brand?.logo_uri || AssetsService.getLargeLogo();
      }));

    this.branding$.pipe(distinctUntilChanged(deepEqual), takeUntil(this.destroy$))
      .subscribe(branding => {
        try {
          document.documentElement.style.setProperty('--primary-color', branding?.style_data.colours?.primary || this.defaultColours?.primary);
          document.documentElement.style.setProperty('--menu-color', branding?.style_data.colours?.menu || this.defaultColours?.menu);
        } catch {

        }
      });

    const loaded$ = this.routeLoaded$.pipe(
      concatMap(loaded => !!loaded ?
        this.authReducer$.pipe(map(auth => !!auth.user?.companyId && !!auth.user?.scopes?.length)) : of(false)));

    this.showAuthComponents$ = combineLatest([this.isRedirect$, loaded$])
      .pipe(
        map(([isRedirect, routeLoaded]) => {
          if (isRedirect) {
            return false;
          }
          return routeLoaded;
        }));

    this.showBackground$ = combineLatest([this.isRedirect$, this.routeLoaded$.pipe(
      concatMap(loaded => !!loaded ?
        this.authReducer$.pipe(map(auth => !auth.user?.companyId)) : of(false)))])
      .pipe(
        map(([isRedirect, unAuthed]) => {
          if (isRedirect) {
            return false;
          }
          return unAuthed;
        }));

    this.user$ = this.authReducer$.pipe(
      pluck('user'),
      distinctUntilChanged((x, y) => x?.id === y?.id));

    this.user$.pipe(
      distinctUntilChanged((x, y) => x?.email === y?.email),
      takeUntil(this.destroy$),
      filter(user => !!user?.email))
      .subscribe(() => this.store.dispatch(FetchUserProfileRequestAction({})));

    this.isPartner$          = this.user$.pipe(map(user => user?.isPartner));
    this.isViewingPartner$   = this.companyReducer$.pipe(map(auth => auth.ownCompanyData?.isPartner));
    this.viewingCompanyUUID$ = this.authReducer$.pipe(
      map(auth => auth.viewCompanyUUID),
      distinctUntilChanged());
    this.combinedReducers$   = combineLatest([
      this.authReducer$,
      this.companyReducer$,
      this.notificationReducer$,
      this.route.queryParams,
      this.branding$,
    ])
      .pipe(
        map(([auth, company, notification, params, branding]): CombinedReducers => ({
          auth,
          company,
          notification,
          params,
          branding,
        })),
      );
    this.navItems$           = this.getNavItems$();

    this.setupAcceptTermsConditionsListener();
    this.setupCompanyViewListener();
    this.fetchData();
    this.registerAlertListeners();
    this.setupSessionExpiry();
  }

  private setupSessionExpiry(): void {
    this.authReducer$
      .pipe(
        distinctUntilChanged((x: AuthState, y: AuthState): boolean => x.token === y.token),
        takeUntil(this.destroy$),
      )
      .subscribe((state: AuthState) => {
        if (state.token) {
          const refreshToken = TokenService.getToken(AuthToken.Refresh) as TokenData;
          const accessToken  = TokenService.getToken(AuthToken.Access) as TokenData;

          this.sessionManagerService.startSessionTimer(accessToken, refreshToken);
        }
      });
  }

  openNotificationPage(): Promise<boolean> {
    return this.router.navigate(['/notifications']);
  }

  closeNotification(notificationId: string): void {
    this.store.dispatch(MarkAsReadRequestAction({
      notificationId,
      notificationType: 'READ',
    }));
  }

  signOut(): void {
    this.store.dispatch(LogoutAction({}));
  }

  fetchData(): void {
    combineLatest([
      this.user$.pipe(distinctUntilChanged((a, b) => a?.id === b?.id)),
      this.currentCompanyUUID$.pipe(distinctUntilChanged())],
    )
      .pipe(
        takeUntil(this.destroy$),
        filter(([_, currentCompanyId]) => !!currentCompanyId),
        takeUntil(this.destroy$))
      .subscribe(([user, currentCompanyId]) => {
        if (user && (!user.isPartner || currentCompanyId)) {
          this.setupNotificationPolling();
          this.store.dispatch(FetchServiceCountsRequestAction({}));
          this.store.dispatch(FetchNotificationCountRequestAction({}));
          this.store.dispatch(FetchReportingConfigRequestAction({}));
          this.store.dispatch(FetchTeamsSettingsRequestAction({}));
          this.store.dispatch(FetchNumberCountsRequestAction({}));
          this.store.dispatch(FetchFeatureInventoryRequestAction({}));
          this.store.dispatch(FetchNumbersServiceMetadataRequestAction({}));
          this.store.dispatch(FetchUserCountsRequestAction({}));
          this.store.dispatch(FetchGlobalUIBannerToggleRequestAction({}));
          this.store.dispatch(FetchGlobalUIBannerRequestAction({}));
          this.store.dispatch(FetchRangeUsageThresholdRequestAction({}));
          this.store.dispatch(FetchRangeUsageToggleRequestAction({}));
          this.store.dispatch(FetchCompanyUserListRequestAction({
            queryParams: {
              include:    ['roles', 'idp_identities'],
              pageNumber: 1,
              pageSize:   10,
            },
          }));

          this.store.dispatch(FetchTrialLicenseIdentifierRequestAction({ key: 'TRIAL_LICENSE_IDENTIFIER' }));

          const fetchPackageInfo = () => {
            this.store.dispatch(FetchActivePackageRequestAction({}));
            this.store.dispatch(FetchBalanceDataRequestAction({}));
            this.store.dispatch(FetchActiveLicenseRequestAction({}));
            this.store.dispatch(FetchPackageCapabilitiesRequestAction({}));
          };

          fetchPackageInfo();

          interval(10_000)
            .pipe(
              takeWhile((i) => i < 10),
              withLatestFrom(this.isFreePackage$, this.trialLicenseExpiry$, this.store.select(selectActivePackage)),
              map(([_, isFree, expiry, activePackage]) => (isFree && expiry === null) || !activePackage),
              takeUntil(this.destroy$))
            .subscribe((retryFetch) => {
              if (!retryFetch) {
                return;
              }
              fetchPackageInfo();
            });
        }
      });
  }

  private checkNewTermsConditions(): void {
    combineLatest([this.authReducer$, this.configurationReducer$])
      .pipe(
        map(([login, configuration]) => ({ login, configuration })),
        debounceTime(10_000),
        map(({ login }) => {
          return login?.token && login.user?.companyId;
        }),
        filter(changed => !!changed),
        takeUntil(this.destroy$),
        take(1))
      .subscribe(() => this.store.dispatch(FetchPendingTermsConditionsRequestAction()));
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.sessionManagerService.destroy();
  }

  private setupNotificationPolling(): void {
    this.notifyPollDestroy.next();
    const auth$ = this.authReducer$.pipe(map(state => !!state?.user?.companyId));
    const kill$ = combineLatest([auth$.pipe(map(auth => !auth), startWith(null)), this.notifyPollDestroy$, this.destroy$])
      .pipe(map(([unAuthed, killed, destroyed]) => unAuthed || killed || destroyed));
    this.notifyPollTimer.pipe(takeUntil(kill$))
      .subscribe(() =>
        this.store.dispatch(PollNotificationListRequestAction({})),
      );
  }

  private setupAcceptTermsConditionsListener(): void {
    this.configurationReducer$
      .pipe(
        takeUntil(this.destroy$),
        map(configuration => configuration.pendingTermsConditionsID),
        tap(pendingVersion => {
          if (pendingVersion) {
            this.renderer2.addClass(this.document.scrollingElement, 'overflow-hidden');
            if (this.mainMenu) {
              this.renderer2.addClass(this.mainMenu.nativeElement, 'no-pointer-events');
            }
            if (this.pageContainer) {
              this.renderer2.addClass(this.pageContainer.nativeElement, 'no-pointer-events');
            }
            return;
          }
          this.renderer2.removeClass(this.document.scrollingElement, 'overflow-hidden');
          if (this.mainMenu) {
            this.renderer2.removeClass(this.mainMenu.nativeElement, 'no-pointer-events');
          }
          if (this.pageContainer) {
            this.renderer2.removeClass(this.pageContainer.nativeElement, 'no-pointer-events');
          }
        }));
    this.checkNewTermsConditions();
  }

  private setupCompanyViewListener(): void {
    this.router.navigationEndEvent$.pipe(takeUntil(this.destroy$))
      .subscribe(() =>
        this.store.dispatch(SetCompanyHeaderAction({ companyUUID: splitURLByPath(this.location.path()).queryParams?.company })));
  }

  private getNavItems$(): Observable<NavItem[]> {
    return combineLatest([
      this.user$,
      this.viewingCompanyUUID$,
      this.isViewingPartner$,
      this.store.select(selectIsSipAdmin),
      this.store.select(selectReportingConfig),
    ])
      .pipe(
        distinctUntilChanged(),
        map(([user, viewingCompanyUUID, isViewingPartner, isSipAdmin, reportingConfig]) => {
          if (!user?.companyId) {
            return [];
          }
          if (viewingCompanyUUID || !user.isPartner) {
            return SideNav.getUserNav(
              !!viewingCompanyUUID,
              isViewingPartner,
              isSipAdmin,
              user.scopes,
              !reportingConfig || Object.values(reportingConfig)
                .every(val => !val),
            );
          }
          return SideNav.getPartnerNav();
        }));
  }

  private registerAlertListeners(): void {
    const services: any[] = [
      selectAuth,
      selectService,
      selectMicrosoftTeams,
      selectNotification,
      selectNumber,
      selectCompany,
      selectAudit,
      selectReport,
      selectAccounting,
      selectSubscription,
      selectConfiguration,
    ];
    const alertStates     = ['error', 'message'];
    for (const service of services) {
      for (const state of alertStates) {
        this.store.select(service)
          .pipe(
            takeUntil(this.destroy$),
            pluck(state),
            filter(alert => !!alert),
            distinctUntilChanged((a, b) => a.id === b.id))
          .subscribe((data: Alert) => {
              if (data.code === 401) {
                return;
              }
              this.snackBar.openFromComponent(AlertSnackBarComponent, {
                data,
                duration:   10_000,
                panelClass: ['background-transparent', 'border-none', 'box-shadow-none'],
              });
            },
          );
      }
    }
  }
}
