import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Task }                                                                     from '@models/entity/task.model';
import { trackBy }                                                                  from '@util/trackBy';
import {
  TaskManagerService,
}                                                                                   from '@services/task-manager.service';
import { MatSidenav, MatSidenavContainer }                                          from '@angular/material/sidenav';
import { distinctUntilChanged, shareReplay, startWith, takeUntil }                  from 'rxjs/operators';
import { Observable, Subject, timer }                                               from 'rxjs';
import { TaskStatus }                                                               from '@enums/task-status.enum';
import { ToastrService }                                                            from 'ngx-toastr';
import {
  TaskNotificationComponent,
}                                                                                   from '@core/components/tasks/task-notification/task-notification.component';
import { CancelTaskRequestAction, FetchTaskCountRequestAction }                     from '@redux/audit/audit.actions';
import { StoreState }                                                               from '@redux/store';
import { Store }                                                                    from '@ngrx/store';
import { TaskSchema }                                                               from '@enums/task-schema.enum';
import { selectAuditPendingTasks }                                                  from '@redux/audit/audit.selectors';
import { pluckMap }                                                                 from '@rxjs/pluckMap.operator';
import { containsAny }                                                              from '@rxjs/containsAny.operator';
import { CANCEL_TASK_REQUEST }                                                      from '@redux/audit/audit.types';
import {
  TaskQueryParams,
}                                                                                   from '@models/form/task-query-params.model';
import {
  PaginationFormModel,
}                                                                                   from '@models/form/pagination-form.model';
import { TaskQueryService }                                                         from '@services/task-query.service';
import { FormControl, FormGroup }                                                   from '@angular/forms';

interface TasksForm {
  search: FormControl<string>;
  schema: FormControl<keyof typeof TaskSchema>;
}

@Component({
  selector:    'ngx-task-manager',
  templateUrl: './task-manager.component.html',
  styleUrls:   ['./task-manager.component.scss'],
})
export class TaskManagerComponent implements OnDestroy, OnInit, OnChanges {
  @Input() tasks: Task[];
  @Input() selectedTask: Task;
  @Input() taskQueryParams: TaskQueryParams;
  @Input() incompleteCount: number;
  @Input() counts: { [schema: string]: number };
  @Input() isSipAdmin: boolean;
  @Input() hasAdminAudit: boolean;
  @Input() hasReportRead: boolean;
  @Input() userId: string;
  @ViewChild('drawer', { read: MatSidenav, static: true }) sidenav: MatSidenav;
  @ViewChild('container', { read: MatSidenavContainer, static: true }) sidenavContainer: MatSidenavContainer;
  schemas: { [identifier: string]: string }      = {
    AdGroupSynchronise:             'AD groups sync',
    CallQueueGroupAssignment:       'Call queue group assignment',
    CallQueueGroupUpdated:          'Call queue group update',
    CallQueueSynchronise:           'Call queue sync',
    CdrExport:                      'CDR export',
    DomainSynchronise:              'Sync domains',
    LicenseGroupAssignment:         'License group assignment',
    LicenseGroupUpdated:            'License group update',
    LicenseSynchronise:             'License sync',
    MicrosoftAssetSynchronise:      'Microsoft Teams assets sync',
    NumberExport:                   'Number export',
    NumberImport:                   'Number import',
    NumberSync:                     'Number sync',
    PolicySynchronise:              'Policies sync',
    ProvisionMicrosoftTeamsService: 'Microsoft Teams provisioning',
    ServiceUserExport:              'Service user export',
    TeamGroupAssignment:            'Team group assignment',
    TeamGroupUpdated:               'Team group update',
    TeamSynchronise:                'Team sync',
    TenantConnect:                  'Connect Microsoft tenant',
    UserConfigure:                  'User configure',
    UserSync:                       'User sync',
  };
  schemaIdentifiers: (keyof typeof TaskSchema)[] = Object.keys(this.schemas) as (keyof typeof TaskSchema)[];
  cancelPending$: Observable<boolean>;
  selectedSchema$: Observable<keyof typeof TaskSchema>;
  destroy                                        = new Subject<void>();
  destroy$                                       = this.destroy.asObservable();
  formGroup: FormGroup<TasksForm>;
  private taskPollTimer                          = timer(0, 10_000);
  private taskPollDestroy                        = new Subject<void>();
  private taskPollDestroy$                       = this.taskPollDestroy.asObservable();
  trackBy                                        = trackBy<Task>('id');

  constructor(private taskManagerService: TaskManagerService,
              private toastr: ToastrService,
              private taskQueryService: TaskQueryService,
              private store: Store<StoreState>) {
    this.cancelPending$ = this.store.select(selectAuditPendingTasks)
      .pipe(
        pluckMap('id'),
        containsAny(CANCEL_TASK_REQUEST),
      );

    this.formGroup = new FormGroup<TasksForm>(
      {
        search: new FormControl<string>(null),
        schema: new FormControl<keyof typeof TaskSchema>(null),
      },
    );

    this.selectedSchema$ = this.formGroup.get('schema')
      .valueChanges
      .pipe(
        startWith(this.formGroup.get('schema').value),
        distinctUntilChanged(),
        shareReplay(1));

  }

  ngOnInit(): void {
    this.taskManagerService.toggle$.pipe(takeUntil(this.destroy$))
      .subscribe(async (open: boolean) => {
        await this.sidenav?.toggle(open);
      });
    this.selectedSchema$.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
    )
      .subscribe(() => this.setupTaskPolling());
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.taskPollDestroy.next();
  }

  private setupTaskPolling(): void {
    this.taskPollDestroy.next();

    this.taskPollTimer.pipe(takeUntil(this.taskPollDestroy$))
      .subscribe(() => {
          this.store.dispatch(FetchTaskCountRequestAction({}));
          const schemaChanged = this.taskQueryParams?.schema !== TaskSchema[this.formGroup.get('schema').value];
          return this.taskQueryService.fetchList(this.taskQueryService.getQueryParams(
            this.formGroup,
            this.taskQueryParams?.pageSize && !schemaChanged ? this.taskQueryParams.pageSize : 5,
            this.taskQueryParams?.pageNumber && !schemaChanged ? this.taskQueryParams.pageNumber : 1,
            null),
          );
        },
      );
  }

  close(): void {
    this.taskManagerService.toggle.next(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.tasks?.currentValue && !changes?.selectedTask?.currentValue) {
      return;
    }
    const current: Task[]            = changes.tasks?.currentValue;
    const previous: Task[]           = changes.tasks?.previousValue;
    const currentSelectedTask: Task  = changes.selectedTask?.currentValue;
    const previousSelectedTask: Task = changes.selectedTask?.previousValue;

    if (previous === null && !currentSelectedTask) {
      return;
    }

    // only notify on new tasks (just started) or completed tasks (just finished)
    const notifyTasks: Task[] = current?.filter(task =>
      previous.some(p => p.id === task.id && p.status === TaskStatus.Processing && task.status === TaskStatus.Complete)) || [];

    if (currentSelectedTask && currentSelectedTask?.id !== previousSelectedTask?.id) {
      notifyTasks.push(currentSelectedTask);
    }

    for (const task of notifyTasks) {
      const toast                              = this.toastr.info(
        '',
        '',
        { toastComponent: TaskNotificationComponent },
      );
      toast.toastRef.componentInstance.task    = task;
      toast.toastRef.componentInstance.toastId = toast.toastId;
    }
  }

  getCount(identifier: keyof typeof TaskSchema, counts: { [schema: string]: number }): number {
    return counts?.[TaskSchema[identifier]] || 0;
  }

  cancel(data: { id: string, name: string }): void {
    this.store.dispatch(CancelTaskRequestAction({ id: data.id, name: data.name }));
  }

  updatePageQuery(params: PaginationFormModel): void {
    this.taskQueryService.updatePageQuery(this.formGroup, params, this.taskQueryParams);
    window.document.getElementsByClassName('mat-drawer-inner-container')
      .item(0)
      .scrollTo({ top: 0, behavior: 'smooth' });
  }
}
