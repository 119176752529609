<div class="user-card display-inline-block">
  <div class="display-flex" fxLayout="row" fxLayoutAlign="start center">
    <div class="logo-container shadow-light" [style.border-color]="borderColor"
         [class.no-border]="!borderColor"
         fxLayout="row" fxLayoutAlign="center center">
      <ng-container *ngIf="!logoUrl?.startsWith('cr'); else isIcon">
        <img src="{{logoUrl}}" width="36px" alt="header icon" />
      </ng-container>
      <ng-template #isIcon>
        <mat-icon svgIcon="{{logoUrl}}"></mat-icon>
      </ng-template>
    </div>
    <div class="info">
      <p class="company-name m-0 text-light-gray font-size-14">{{ownCompany?.name || '&nbsp;'}}</p>
      <div id="page-title" fxLayout="row" fxLayoutAlign="start center">
        <h1 class="m-0 fw-500" data-cy="title">
          {{pageTitle}}
          <span class="color-light-gray fw-400 fs-21">{{pageTitleSuffix}}</span>
        </h1>
        <mat-icon class="ml-8" svgIcon="cr-info-outline" *ngIf="pageTooltip" matTooltip="{{pageTooltip}}"
                  matTooltipClass="multi-line"></mat-icon>
      </div>
    </div>
  </div>
</div>
<div class="account-icons text-align-right" fxFlexOffset="auto" fxLayout="row" fxLayoutAlign="start center">
  <button mat-icon-button
          [ngClass]="'mr-12'"
          [ngClass.lt-sm]="'mr-0'"
          id="provisioning-btn"
          [matMenuTriggerFor]="provMenu"
          *ngIf="provisioningServices?.length"
          [matBadge]="provisioningServices?.length"
          matBadgePosition="after"
          [matBadgeOverlap]="true"
          matBadgeColor="warn"
          data-cy="provisioning-btn"
          class="color-yellow"
          matTooltip="{{provisioningServices?.length || 0}} {{'Service' | plural:(provisioningServices?.length || 0)}} provisioning">
    <mat-icon svgIcon="cr-spinner" class="rotate"></mat-icon>
  </button>
  <mat-menu #provMenu="matMenu" classList="provisioning-menu" xPosition="after" class="provisioning-menu"
            [overlapTrigger]="false">
    <ng-container *ngFor="let service of provisioningServices; let i = index; trackBy: trackServiceBy">
      <div mat-menu-item fxLayout="row" class="provisioning-item"
           (click)="goToService(service)"
           fxLayoutAlign="start center">
        <div class="provisioning-item__loading-container mr-24" fxLayout="row" fxLayoutAlign="center center">
          <mat-spinner strokeWidth="4" class="color-yellow" diameter="48"></mat-spinner>
          <mat-icon svgIcon="{{service.icon}}" class="m-0"></mat-icon>
        </div>
        <div class="provisioning-item__message" fxFlex="0 0 70" fxLayout="row" fxLayoutAlign="start center">
          <p class="mat-body-1--smaller m-0"><strong>{{service.label || service.name}}</strong> is provisioning...</p>
        </div>
        <div class="provisioning-item__action">
          <button mat-icon-button>
            <mat-icon svgIcon="cr-arrow-forward"></mat-icon>
          </button>
        </div>
      </div>
      <mat-divider class="provisioning-item__divider w-80 m-0-auto"
                   *ngIf="((provisioningServices?.length || 0) > 1) && (i < provisioningServices.length - 1)"></mat-divider>
    </ng-container>
  </mat-menu>
  <a href="https://help.callroute.com/space/CKB" target="_blank"
     [ngClass]="'mr-12'"
     [ngClass.lt-sm]="'mr-0'"
     fxHide.lt-md>
    <button mat-icon-button
            matTooltip="Help"
            class="text-med-gray">
      <mat-icon svgIcon="cr-help-outline"></mat-icon>
    </button>
  </a>
  <div class="display-inline-block link-container">
    <button mat-icon-button
            id="security-btn"
            data-cy="security-btn"
            matTooltip="{{userProfile.isOtpEnabled ? 'Multi-Factor Authentication enabled' :
                'Multi-Factor Authentication disabled - click to enable now'}}"
            [routerLink]="['/profile']"
            fragment="security"
            queryParamsHandling="preserve"
            [ngClass]="userProfile.isOtpEnabled ? 'color-green' : 'color-orange'">
      <mat-icon svgIcon="{{userProfile.isOtpEnabled ? 'cr-lock' : 'cr-lock-open'}}"></mat-icon>
    </button>
    <button mat-icon-button
            id="tasks-btn"
            data-cy="tasks-btn"
            *ngIf="hasTaskRead"
            matTooltip="{{incompleteTaskCount || 0}} {{'task' | plural:incompleteTaskCount}} running"
            (click)="toggleTaskManager()">
      <mat-icon svgIcon="cr-list-check"
                [matBadge]="incompleteTaskCount"
                [matBadgeHidden]="!incompleteTaskCount"
                matBadgePosition="after"
                matBadgeSize="medium"
                class="text-med-gray"
                [matBadgeOverlap]="false"
                matBadgeColor="warn"></mat-icon>
    </button>
    <button mat-icon-button
            id="audit-btn"
            *ngIf="isSipAdmin"
            data-cy="audit-btn"
            matTooltip="Audit logs"
            [routerLink]="['/audit']"
            queryParamsHandling="preserve"
    >
      <mat-icon svgIcon="cr-file-lines"
                class="text-med-gray"></mat-icon>
    </button>
    <button mat-icon-button
            id="notification-btn"
            data-cy="notification-btn"
            *ngIf="hasNotificationRead"
            matTooltip="Notifications"
            (click)="openNotificationPage.emit()">
      <mat-icon svgIcon="cr-bell"
                [matBadge]="notificationCount"
                [matBadgeHidden]="!notificationCount"
                matBadgePosition="after"
                matBadgeSize="medium"
                class="text-med-gray"
                [matBadgeOverlap]="false"
                matBadgeColor="warn"></mat-icon>
    </button>
    <ng-container *ngFor="let notification of newBannerNotifications; trackBy: trackBy">
      <ngx-banner-notification
        @inOutAnimation
        fxHide.lt-lg
        [notification]="notification"
        [pending]="isPending(notification)"
        [isViewingCompany]="isViewingCompany"
        (closeNotification)="closeNotification.emit(notification.id)">
      </ngx-banner-notification>
    </ng-container>
  </div>
  <div class="account-dropdown display-inline-block">
    <button class="dropdown-trigger" id="dropdown-btn" data-cy="dropdown-menu-toggle" fxLayout="row"
            fxLayoutAlign="start center"
            [matMenuTriggerFor]="menu"
            #t="matMenuTrigger" [class.menu-open]="t.menuOpen">
      <img fxHide.lt-sm [src]="userProfile && userProfile.avatar ? '' : avatarUrl" />
      <mat-icon svgIcon="cr-chevron-down"></mat-icon>
    </button>
    <mat-menu #menu="matMenu" xPosition="before" class="menu-popup dropdown-menu" data-cy="dropdown-menu">
      <div class="profile-container display-flex">
        <img [src]="userProfile && userProfile.avatar ? '' : avatarUrl" />
        <div class="info">
          <p class="user-name m-0 color-black" *ngIf="userProfile">
            <strong>{{userProfile.firstName}} {{userProfile.lastName}}</strong>
          </p>
          <p class="company-name m-0 font-size-14" *ngIf="ownCompany">
            <strong>{{ownCompany.name}}</strong></p>
        </div>
      </div>
      <a href="https://help.callroute.com/space/CKB" target="_blank">
        <button mat-menu-item data-cy="dropdown-item"
                class="dropdown-menu__item"
                fxLayout="row"
                fxLayoutAlign="start center">
          <mat-icon svgIcon="cr-help-outline"></mat-icon>&nbsp;<span>Help</span>
        </button>
      </a>
      <button mat-menu-item data-cy="dropdown-item" (click)="navigateToPage('/profile', 'user')"
              class="dropdown-menu__item color-black"
              fxLayout="row"
              fxLayoutAlign="start center">
        <mat-icon svgIcon="cr-person"></mat-icon>&nbsp;<span>My profile</span>
      </button>
      <button mat-menu-item data-cy="dropdown-item" fxHide.gt-sm (click)="navigateToPage('/admin')"
              class="dropdown-menu__item color-black"
              fxLayout="row"
              fxLayoutAlign="start center">
        <mat-icon svgIcon="cr-admin-filled"></mat-icon>&nbsp;<span>Admin</span>
      </button>
      <button mat-menu-item data-cy="dropdown-item" (click)="signOut.emit()"
              class="dropdown-menu__item text-med-gray" fxLayout="row"
              fxLayoutAlign="start center">
        <mat-icon svgIcon="cr-logout"></mat-icon>&nbsp;<span>Sign out</span>
      </button>
    </mat-menu>
  </div>
</div>
