import { LocationRaw }          from '@models/api/number/location-raw.model';
import { NumberAssignedCounts } from '@models/entity/number-counts.model';

export class Location {
  id: string;
  createdDate: string;
  updatedDate: string;
  name: string;
  description: string;
  metadata: {
    number_count: number;
  };
  counts?: NumberAssignedCounts;
  requestId?: string;

  constructor(private data?: Location) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: LocationRaw, requestId?: string): Location {
    this.id          = data.id;
    this.createdDate = data.created_date;
    this.updatedDate = data.updated_date;
    this.name        = data.name;
    this.description = data.description;
    this.metadata    = data.metadata;
    this.requestId   = requestId;

    return this;
  }

  toApiData?(): { name: string, description?: string } {
    return {
      name:        this.name,
      description: this.description,
    };
  }

  configureTooltip?(hasNumberWrite: boolean): string {
    if (!hasNumberWrite) {
      return 'You do not have the required access to configure a location. Please contact your systems administrator for more information.';
    }
    return null;
  }

  configureDisabled?(hasNumberWrite: boolean): boolean {
    return !hasNumberWrite;
  }

  deleteDisabled?(hasNumberWrite: boolean): boolean {
    return !hasNumberWrite;
  }
}
