import { EmergencyLocationRaw } from '@models/api/service/emergency-location-raw.model';

export class EmergencyLocation {
  city: string;
  country: string;
  latitude: string;
  postcode: string;
  longitude: string;
  identifier: string;
  description: string;
  streetName: string;
  companyName: string;
  houseNumber: string;

  constructor(private data?: EmergencyLocation) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: EmergencyLocationRaw): EmergencyLocation {
    this.city        = data.city;
    this.country     = data.country;
    this.latitude    = data.latitude;
    this.postcode    = data.postcode;
    this.longitude   = data.longitude;
    this.identifier  = data.identifier;
    this.description = data.description;
    this.streetName  = data.street_name;
    this.companyName = data.company_name;
    this.houseNumber = data.house_number;
    
    return this;
  }
}
