<ng-content select=".flex-item__prefix"></ng-content>
<p [ngClass]="ngClass"
   [class.hover-dark]="hoverDark"
   [ngStyle]="{
   wordBreak: textWordBreak,
   margin: textMargin,
   fontWeight: textFontWeight,
   fontSize: textFontSize,
   textAlign: textAlign,
   textOverflow: textOverflow,
   whiteSpace: textWhiteSpace
   }"
   [ngStyle.lt-md]="{
   wordBreak: textWordBreak,
   margin: textMargin,
   fontWeight: textFontWeight,
   textAlign: textAlign,
   textOverflow: textOverflow,
   fontSize: textFontSizeSm,
   whiteSpace: textWhiteSpaceSm}"
   [innerHTML]="text"
   matTooltip="{{textTooltip}}"
   [matTooltipDisabled]="!textTooltip"
   [matTooltipClass]="textTooltip && textTooltip?.includes('\n') && 'multi-line'">
</p>
<ng-content select=".flex-item__suffix"></ng-content>
