<div class="header shadow-soft" *ngIf="isFreePackage && trialLicenseExpiry !== null" fxLayout="column"
     fxLayoutAlign="center center"
     [style.background-color]="backgroundColor"
     [style.height]="trialLicenseExpiry ? '100px' : '64px'"
     [style.top]="isViewingCompany ? '58px' : '16px'">
  <div fxLayout="row" fxLayoutAlign="center center">
    <ng-container *ngIf="trialLicenseExpiry; else hasExpired">
      <mat-icon svgIcon="cr-timer" class="mr-12"></mat-icon>
      <span>Your free trial expires in: <strong>{{trialLicenseExpiry}}</strong></span>
    </ng-container>
    <ng-template #hasExpired>
      <mat-icon svgIcon="cr-circle-exclamation" class="mr-12"></mat-icon>
      <span>Your free trial has <strong>expired</strong>&nbsp;&middot;&nbsp;<ng-container
        *ngIf="true; then outboundDisabled"></ng-container></span>
    </ng-template>
  </div>
  <div>
    <span>
      <ng-container *ngIf="trialLicenseExpiry">
        Available calling credit: £{{freeCredit}}
        <ng-container *ngIf="!freeCredit">&nbsp;&middot;&nbsp;<ng-container
          *ngIf="true; then outboundDisabled"></ng-container></ng-container>
      </ng-container>
    </span>
    <ng-template #outboundDisabled>Outbound calling is disabled</ng-template>
  </div>
  <div *ngIf="!trialLicenseExpiry">
    <ng-container *ngIf="true; then learnMoreLink"></ng-container>
  </div>
</div>
<ng-template #learnMoreLink>
  <a class="color-white fw-500"
     target="_blank"
     href="https://help.callroute.com/space/CKB/864518145/Free+Trial+-+14+day"
     fxLayout="row"
     fxLayoutAlign="start center">
    <span class="mr-6">Learn more</span>
    <mat-icon svgIcon="cr-help-outline"></mat-icon>
  </a>
</ng-template>
