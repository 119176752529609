export const FETCH_AVAILABLE_CARRIERS_REQUEST  = 'FETCH_AVAILABLE_CARRIERS_REQUEST';
export const FETCH_AVAILABLE_CARRIERS_RESPONSE = 'FETCH_AVAILABLE_CARRIERS_RESPONSE';

export const FETCH_CARRIERS_LIST_REQUEST  = 'FETCH_CARRIERS_LIST_REQUEST';
export const FETCH_CARRIERS_LIST_RESPONSE = 'FETCH_CARRIERS_LIST_RESPONSE';

export const FETCH_SERVICE_LIST_REQUEST  = 'FETCH_SERVICE_LIST_REQUEST';
export const FETCH_SERVICE_LIST_RESPONSE = 'FETCH_SERVICE_LIST_RESPONSE';

export const FETCH_PSTN_SERVICE_UUID_REQUEST  = 'FETCH_PSTN_SERVICE_UUID_REQUEST';
export const FETCH_PSTN_SERVICE_UUID_RESPONSE = 'FETCH_PSTN_SERVICE_UUID_RESPONSE';

export const FETCH_SERVICE_REQUEST  = 'FETCH_SERVICE_REQUEST';
export const FETCH_SERVICE_RESPONSE = 'FETCH_SERVICE_RESPONSE';

export const FETCH_SERVICE_COUNTS_REQUEST  = 'FETCH_SERVICE_COUNTS_REQUEST';
export const FETCH_SERVICE_COUNTS_RESPONSE = 'FETCH_SERVICE_COUNTS_RESPONSE';

export const DELETE_SERVICE_REQUEST  = 'DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_RESPONSE = 'DELETE_SERVICE_RESPONSE';

export const CONFIGURE_SERVICE_REQUEST  = 'CONFIGURE_SERVICE_REQUEST';
export const CONFIGURE_SERVICE_RESPONSE = 'CONFIGURE_SERVICE_RESPONSE';

export const POST_SERVICE_REQUEST  = 'POST_SERVICE_REQUEST';
export const POST_SERVICE_RESPONSE = 'POST_SERVICE_RESPONSE';

export const PATCH_SERVICE_REQUEST  = 'PATCH_SERVICE_REQUEST';
export const PATCH_SERVICE_RESPONSE = 'PATCH_SERVICE_RESPONSE';

export const FETCH_SERVICE_USER_LIST_REQUEST  = 'FETCH_SERVICE_USER_LIST_REQUEST';
export const FETCH_SERVICE_USER_LIST_RESPONSE = 'FETCH_SERVICE_USER_LIST_RESPONSE';

export const SYNC_SERVICE_USER_LIST_REQUEST  = 'SYNC_SERVICE_USER_LIST_REQUEST';
export const SYNC_SERVICE_USER_LIST_RESPONSE = 'SYNC_SERVICE_USER_LIST_RESPONSE';

// Outbound
export const ASSIGN_SERVICE_USER_NUMBER_REQUEST  = 'ASSIGN_SERVICE_USER_NUMBER_REQUEST';
export const ASSIGN_SERVICE_USER_NUMBER_RESPONSE = 'ASSIGN_SERVICE_USER_NUMBER_RESPONSE';

export const FETCH_PROVISIONING_SERVICES_REQUEST  = 'FETCH_PROVISIONING_SERVICES_REQUEST';
export const FETCH_PROVISIONING_SERVICES_RESPONSE = 'FETCH_PROVISIONING_SERVICES_RESPONSE';

export const FETCH_PERSONA_NAME_AVAILABILITY_REQUEST  = 'FETCH_PERSONA_NAME_AVAILABILITY_REQUEST';
export const FETCH_PERSONA_NAME_AVAILABILITY_RESPONSE = 'FETCH_PERSONA_NAME_AVAILABILITY_RESPONSE';

export const FETCH_AUTOMATION_NAME_AVAILABILITY_REQUEST  = 'FETCH_AUTOMATION_NAME_AVAILABILITY_REQUEST';
export const FETCH_AUTOMATION_NAME_AVAILABILITY_RESPONSE = 'FETCH_AUTOMATION_NAME_AVAILABILITY_RESPONSE';

export const SEND_CUSTOM_CARRIER_REQUEST  = 'SEND_CUSTOM_CARRIER_REQUEST';
export const SEND_CUSTOM_CARRIER_RESPONSE = 'SEND_CUSTOM_CARRIER_RESPONSE';

export const DELETE_TOKENS_REQUEST  = 'DELETE_TOKENS_REQUEST';
export const DELETE_TOKENS_RESPONSE = 'DELETE_TOKENS_RESPONSE';

export const PROMPT_SERVICE_USER_ALERT = 'PROMPT_SERVICE_USER_ALERT';

export const CLEAR_SERVICE_USER_LIST = 'CLEAR_SERVICE_USER_LIST';

export const REAUTH_REQUEST = 'REAUTH_REQUEST';

export const FETCH_GATEWAY_STATUS_EVENTS_REQUEST  = 'FETCH_GATEWAY_STATUS_EVENTS_REQUEST';
export const FETCH_GATEWAY_STATUS_EVENTS_RESPONSE = 'FETCH_GATEWAY_STATUS_EVENTS_RESPONSE';

export const FETCH_CALLING_PROFILES_REQUEST  = 'FETCH_CALLING_PROFILES_REQUEST';
export const FETCH_CALLING_PROFILES_RESPONSE = 'FETCH_CALLING_PROFILES_RESPONSE';

export const FETCH_AUTOMATION_LIST_REQUEST  = 'FETCH_AUTOMATION_LIST_REQUEST';
export const FETCH_AUTOMATION_LIST_RESPONSE = 'FETCH_AUTOMATION_LIST_RESPONSE';

export const FETCH_AUTOMATION_REQUEST  = 'FETCH_AUTOMATION_REQUEST';
export const FETCH_AUTOMATION_RESPONSE = 'FETCH_AUTOMATION_RESPONSE';

export const FETCH_CALLING_PROFILE_REQUEST  = 'FETCH_CALLING_PROFILE_REQUEST';
export const FETCH_CALLING_PROFILE_RESPONSE = 'FETCH_CALLING_PROFILE_RESPONSE';

export const SEARCH_CALLING_PROFILES_REQUEST  = 'SEARCH_CALLING_PROFILES_REQUEST';
export const SEARCH_CALLING_PROFILES_RESPONSE = 'SEARCH_CALLING_PROFILES_RESPONSE';

export const PUT_CALLING_PROFILE_REQUEST  = 'PUT_CALLING_PROFILE_REQUEST';
export const PUT_CALLING_PROFILE_RESPONSE = 'PUT_CALLING_PROFILE_RESPONSE';

export const FETCH_POLICY_LIST_REQUEST  = 'FETCH_POLICY_LIST_REQUEST';
export const FETCH_POLICY_LIST_RESPONSE = 'FETCH_POLICY_LIST_RESPONSE';

export const SYNC_POLICIES_REQUEST  = 'SYNC_POLICIES_REQUEST';
export const SYNC_POLICIES_RESPONSE = 'SYNC_POLICIES_RESPONSE';

export const SYNC_LICENSES_REQUEST  = 'SYNC_LICENSES_REQUEST';
export const SYNC_LICENSES_RESPONSE = 'SYNC_LICENSES_RESPONSE';

export const FETCH_SERVICE_SCHEMA_REQUEST  = 'FETCH_SERVICE_SCHEMA_REQUEST';
export const FETCH_SERVICE_SCHEMA_RESPONSE = 'FETCH_SERVICE_SCHEMA_RESPONSE';

export const MIGRATE_PROFILES_REQUEST  = 'MIGRATE_PROFILES_REQUEST';
export const MIGRATE_PROFILES_RESPONSE = 'MIGRATE_PROFILES_RESPONSE';

export const DELETE_CALLING_PROFILE_REQUEST  = 'DELETE_CALLING_PROFILE_REQUEST';
export const DELETE_CALLING_PROFILE_RESPONSE = 'DELETE_CALLING_PROFILE_RESPONSE';

export const DELETE_AUTOMATION_REQUEST  = 'DELETE_AUTOMATION_REQUEST';
export const DELETE_AUTOMATION_RESPONSE = 'DELETE_AUTOMATION_RESPONSE';

export const PATCH_AUTOMATION_REQUEST  = 'PATCH_AUTOMATION_REQUEST';
export const PATCH_AUTOMATION_RESPONSE = 'PATCH_AUTOMATION_RESPONSE';

export const POST_AUTOMATION_REQUEST  = 'POST_AUTOMATION_REQUEST';
export const POST_AUTOMATION_RESPONSE = 'POST_AUTOMATION_RESPONSE';

export const ASSIGN_LICENSE_GROUP_REQUEST  = 'ASSIGN_LICENSE_GROUP_REQUEST';
export const ASSIGN_LICENSE_GROUP_RESPONSE = 'ASSIGN_LICENSE_GROUP_RESPONSE';

export const ASSIGN_CALL_QUEUE_GROUP_REQUEST  = 'ASSIGN_CALL_QUEUE_GROUP_REQUEST';
export const ASSIGN_CALL_QUEUE_GROUP_RESPONSE = 'ASSIGN_CALL_QUEUE_GROUP_RESPONSE';

export const ASSIGN_TEAM_GROUP_REQUEST  = 'ASSIGN_TEAM_GROUP_REQUEST';
export const ASSIGN_TEAM_GROUP_RESPONSE = 'ASSIGN_TEAM_GROUP_RESPONSE';

export const SYNC_NUMBERS_REQUEST  = 'SYNC_NUMBERS_REQUEST';
export const SYNC_NUMBERS_RESPONSE = 'SYNC_NUMBERS_RESPONSE';

export const SYNC_TEAMS_REQUEST  = 'SYNC_TEAMS_REQUEST';
export const SYNC_TEAMS_RESPONSE = 'SYNC_TEAMS_RESPONSE';

export const SYNC_CALL_QUEUES_REQUEST  = 'SYNC_CALL_QUEUES_REQUEST';
export const SYNC_CALL_QUEUES_RESPONSE = 'SYNC_CALL_QUEUES_RESPONSE';

export const FETCH_SERVICE_USER_REQUEST  = 'FETCH_SERVICE_USER_REQUEST';
export const FETCH_SERVICE_USER_RESPONSE = 'FETCH_SERVICE_USER_RESPONSE';

export const FETCH_SERVICE_USER_COUNTS_BY_VOICE_TYPE_REQUEST  = 'FETCH_SERVICE_USER_COUNTS_BY_VOICE_TYPE_REQUEST';
export const FETCH_SERVICE_USER_COUNTS_BY_VOICE_TYPE_RESPONSE = 'FETCH_SERVICE_USER_COUNTS_BY_VOICE_TYPE_RESPONSE';

export const FETCH_SERVICE_USER_CLI_AVAILABILITY_REQUEST  = 'FETCH_SERVICE_USER_CLI_AVAILABILITY_REQUEST';
export const FETCH_SERVICE_USER_CLI_AVAILABILITY_RESPONSE = 'FETCH_SERVICE_USER_CLI_AVAILABILITY_RESPONSE';

export const SYNC_AD_GROUPS_REQUEST  = 'SYNC_AD_GROUPS_REQUEST';
export const SYNC_AD_GROUPS_RESPONSE = 'SYNC_AD_GROUPS_RESPONSE';

export const SYNC_ALL_MICROSOFT_ASSETS_REQUEST  = 'SYNC_ALL_MICROSOFT_ASSETS_REQUEST';
export const SYNC_ALL_MICROSOFT_ASSETS_RESPONSE = 'SYNC_ALL_MICROSOFT_ASSETS_RESPONSE';

export const SYNC_DOMAINS_REQUEST  = 'SYNC_DOMAINS_REQUEST';
export const SYNC_DOMAINS_RESPONSE = 'SYNC_DOMAINS_RESPONSE';

export const EXPORT_SERVICE_USERS_REQUEST  = 'EXPORT_SERVICE_USERS_REQUEST';
export const EXPORT_SERVICE_USERS_RESPONSE = 'EXPORT_SERVICE_USERS_RESPONSE';

export const FETCH_EMERGENCY_LOCATIONS_LIST_REQUEST  = 'FETCH_EMERGENCY_LOCATIONS_LIST_REQUEST';
export const FETCH_EMERGENCY_LOCATIONS_LIST_RESPONSE = 'FETCH_EMERGENCY_LOCATIONS_LIST_RESPONSE';

export const FETCH_EMERGENCY_LOCATION_REQUEST  = 'FETCH_EMERGENCY_LOCATION_REQUEST';
export const FETCH_EMERGENCY_LOCATION_RESPONSE = 'FETCH_EMERGENCY_LOCATION_RESPONSE';
