<div class="dialog-head display-flex">
  <h2 mat-dialog-title class="m-0 width-100">
    {{ logDate }} <span class="time"><span class="divider">-</span> {{ logData.time }}</span>
  </h2>
  <button class="button link" type="button" mat-dialog-close><img src="{{closeIconUrl}}" /></button>
</div>
<mat-dialog-content class="mat-typography">
  <div class="call-data-container display-flex">
    <div class="data-col display-flex border-color-lighter-gray">
      <mat-icon class="content-icon color-black" [svgIcon]="logData.iconName"></mat-icon>
      <div class="content-col">
        <p class="m-0"><strong class="color-black">{{ logData.directionLabel }} Call</strong></p>
        <p class="duration m-0 color-black">{{ logData.duration }}</p>
      </div>
    </div>
    <div class="source-col border-color-lighter-gray">
      <p class="m-0 display-flex">
        <strong class="color-black">From: </strong><span>{{ logData.sourceCli ? logData.sourceCli : '-' }}</span>
      </p>
      <p class="m-0 display-flex">
        <strong
          class="color-black">To: </strong><span>{{ logData.destinationCli ? logData.destinationCli : '-' }}</span>
      </p>
    </div>
  </div>

  <div class="waveform-container" [class.loading]="loadingAudio">
    <div id="waveform"></div>
    <mat-spinner strokeWidth="4" diameter="42" *ngIf="loadingAudio"></mat-spinner>
  </div>
  <div class="audio-stats display-flex" [class.loading]="loadingAudio">
    <div class="play-controls display-flex">
      <ngx-cr-button icon="cr-download"
                     class="mr-16"
                     [loadingIcon]="true"
                     [loading]="downloadLoading$ | async"
                     (onClick)="download()">
      </ngx-cr-button>
      <ngx-cr-button [icon]="isPlaying ? 'cr-pause' : 'cr-play-arrow'"
                     (onClick)="togglePlayer()">
      </ngx-cr-button>
    </div>
    <form data-cy="form"
          class="sound-control"
          fxLayout="column"
          fxLayoutAlign="center center"
          [formGroup]="formGroup"
          *ngIf="formGroup">
      <div>
        <p class="m-0 color-black"><small>{{ playerCurrent }} / {{ playerDuration }}</small></p>
      </div>
      <div>
        <mat-icon [svgIcon]="isMuted ? 'cr-volume-mute' : 'cr-volume'" (click)="toggleMute()"></mat-icon>
        <mat-slider color="primary" min="0" max="1" step="0.1" formControlName="volume"></mat-slider>
      </div>
    </form>
  </div>
</mat-dialog-content>
