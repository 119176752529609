import { Injectable } from '@angular/core';
import { AuthToken }  from '@enums/auth-token.enum';
import { TokenData }  from './api.service';
import { OrderToken } from '@enums/order-token.enum';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  static getToken(tokenType: AuthToken | OrderToken | string): TokenData | string {
    const val = window.localStorage.getItem(tokenType);

    try {
      return JSON.parse(val);
    } catch {
      return val;
    }
  }

  static setToken(tokenType: AuthToken | OrderToken | string, value: string): void {
    window.localStorage.setItem(tokenType, value);
  }

  static removeToken(tokenType: AuthToken | OrderToken | string): void {
    window.localStorage.removeItem(tokenType);
  }

  static removeAllTokens(...ignore: (AuthToken | OrderToken | string)[]): void {
    const tokens = Object.keys(AuthToken)
      .concat(Object.keys(OrderToken));
    for (const token of tokens) {
      if (ignore?.length && ignore.includes(token)) {
        continue;
      }
      TokenService.removeToken(AuthToken[token as keyof typeof AuthToken]);
    }
  }
}
