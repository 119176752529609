import { StoreState }           from '../store';
import { ConfigurationState }   from './configuration.reducer';
import { createSelector }       from '@ngrx/store';
import { selectService }        from '@redux/service/service.selectors';
import { selectActiveLicenses } from '@redux/subscription/subscription.selectors';

export const selectConfiguration                = (state: StoreState): ConfigurationState => state.configurationReducer;
export const selectConfigurationError           = createSelector(selectConfiguration, configuration => configuration.error);
export const selectConfigurationMessage         = createSelector(selectConfiguration, configuration => configuration.message);
export const selectConfigurationPendingTasks    = createSelector(selectConfiguration, configuration => configuration.pendingTasks);
export const selectCallRecordingEnabled         = createSelector(selectConfiguration, configuration => configuration.callRecordingEnabled);
export const selectCallRecordingAnalysisEnabled = createSelector(selectConfiguration, configuration => configuration.callRecordingAnalysisEnabled);
export const selectCallRecordingAllowed         = createSelector(selectConfiguration, configuration => configuration.callRecordingCanEdit);
export const selectLowBalanceThreshold          = createSelector(selectConfiguration, configuration => configuration.lowBalanceThreshold);
export const selectDemoMode                     = createSelector(selectConfiguration, configuration => configuration.demoMode);
export const selectDefaultLowBalanceThreshold   = createSelector(selectConfiguration, configuration => configuration.lowBalanceThresholdDefault);
export const selectMicrosoftTeamsSettings       = createSelector(selectConfiguration, configuration => configuration.microsoftTeamsSettings);
export const selectReportingConfig              = createSelector(selectConfiguration, configuration => configuration.reportingConfig);
export const selectTrialLicenseIdentifier       = createSelector(selectConfiguration, configuration => configuration.trialLicenseIdentifier);
export const selectRangeUsageThreshold          = createSelector(selectConfiguration, configuration => configuration.rangeUsageThreshold);
export const selectRangeUsageToggle             = createSelector(selectConfiguration, configuration => configuration.rangeUsageToggle);
export const selectCustomerCostsToggle          = createSelector(selectConfiguration, configuration => configuration.customerCostsToggle);
export const selectNumberReportsToggle          = createSelector(selectConfiguration, configuration => configuration.numberReportsToggle);
export const selectFreeTrialExpiry              = createSelector(selectTrialLicenseIdentifier, selectActiveLicenses, (identifier, activeLicenses) => {
  return activeLicenses?.find(license => license.product.identifier === identifier)?.endDate;
});
export const selectCarriersConfig               = createSelector(selectService, selectConfiguration, (service, configuration) => {
  if (!service.availableCarriers?.length) {
    return;
  }

  const toggles: { [identifier: string]: boolean } = {};

  const configurationKeys = configuration.carriersConfig ? Object.keys(configuration.carriersConfig) : [];

  for (const carrier of service.availableCarriers) {
    toggles[carrier.identifier] = !configurationKeys.includes(carrier.identifier) || configuration.carriersConfig[carrier.identifier];
  }
  return toggles;
});

export const selectServicesConfig                = createSelector(selectService, selectConfiguration, (service, configuration) => {

  if (!service.availableServices?.length) {
    return;
  }

  const toggles: { [label: string]: boolean } = {};

  const configurationKeys = configuration.servicesConfig ? Object.keys(configuration.servicesConfig) : [];

  for (const s of service.availableServices) {
    toggles[s.label] = !configurationKeys.includes(s.label) || configuration.servicesConfig[s.label];
  }

  return toggles;
});
export const selectMicrosoftTeamsUserSyncVersion = createSelector(selectConfiguration, configuration => configuration.userSyncVersion);
export const selectUserSyncCronEnabled           = createSelector(selectConfiguration, configuration => configuration.userSyncCronEnabled);
export const selectNumberSettings                = createSelector(selectConfiguration, configuration => configuration.numberSettings);
export const selectAllowSSOUserCreation          = createSelector(selectConfiguration, configuration => configuration.allowSSOUserCreation);
export const selectAllowSSOPasswordReset         = createSelector(selectConfiguration, configuration => configuration.allowSSOPasswordReset);
export const selectGlobalUIBanner                = createSelector(selectConfiguration, configuration => configuration.globalUIBanner);
export const selectGlobalUIBannerToggle          = createSelector(selectConfiguration, configuration => configuration.globalUIBannerToggle);
