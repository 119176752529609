import { Injectable }                         from '@angular/core';
import { Observable, of }                     from 'rxjs';
import { ServiceItemRaw }                     from '@models/api/service-api-response.model';
import { catchError, map }                    from 'rxjs/operators';
import { HttpErrorResponse }                  from '@angular/common/http';
import { Alert }                              from '@models/entity/alert.model';
import { ApiService }                         from './api.service';
import { environment }                        from '../../environments/environment';
import { WebexCalling }                       from '@models/entity/webex-calling.model';
import { ServiceType }                        from '@enums/service-type.enum';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TrunkGroupValidator }                from '@validators/trunk-group.validator';
import { DomainValidator }                    from '@validators/domain.validator';
import { LinePortValidator }                  from '@validators/line-port.validator';
import { PostServiceRequest }                 from '@models/api/post-service-request.model';
import { PostServiceResponse }                from '@models/api/post-service-response.model';
import { PatchServiceRequest }                from '@models/api/patch-service-request.model';
import { PatchServiceResponse } from '@models/api/patch-service-response.model';
import { WebexCallingRaw }      from '@models/api/webex-calling-raw.model';

@Injectable({
  providedIn: 'root'
})
export class WebexService {

  private baseUrl: string = environment.api.serviceBaseUrl;

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  newForm(data: WebexCalling, hiddenUsername: string, hiddenPassword: string): UntypedFormGroup {
    return this.fb.group({
      serviceType:          [ServiceType.WebexCalling],
      username:             [data?.username || hiddenUsername, Validators.compose(
        [Validators.required, Validators.maxLength(64), Validators.pattern(/^\S+$/)])],
      password:             [data?.password || hiddenPassword, Validators.compose(
        [Validators.required, Validators.maxLength(64), Validators.pattern(/^\S+$/)])],
      trunkGroup:           [data?.trunkGroup || '', Validators.compose(
        [Validators.required, Validators.maxLength(128), TrunkGroupValidator.validTrunkGroup()])],
      outboundProxyAddress: [data?.outboundProxyAddress || '', Validators.compose(
        [Validators.required, Validators.maxLength(128), DomainValidator.validDomain()])],
      registrarDomain:      [data?.registrarDomain || '', Validators.compose(
        [Validators.required, Validators.maxLength(128), DomainValidator.validDomain()])],
      linePort:             [data?.linePort || '', Validators.compose(
        [Validators.required, Validators.maxLength(128), LinePortValidator.validLinePort()])],
      label:                [data?.label || '', Validators.maxLength(30)]
    });

  }

  constructor(private apiService: ApiService,
              private fb: UntypedFormBuilder) {}

  postWebexService$(req: PostServiceRequest<WebexCalling>): Observable<PostServiceResponse<WebexCalling>> {
    return this.apiService.apiPost$<{ data: ServiceItemRaw }>(
      this.buildUri('services'),
      new WebexCalling(req.serviceItem).toApiData(),
      {authRequired: true})
      .pipe(
        map((res: { data: ServiceItemRaw }): PostServiceResponse<WebexCalling> => {
          return {
            error:       null,
            serviceItem: new WebexCalling().fromApiData(res.data as WebexCallingRaw),
            message:     null
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostServiceResponse<WebexCalling>> => {
          return of({
            error:       new Alert().fromApiError(err, req.formFields),
            serviceItem: null,
            message:     null
          });
        })
      );
  }

  patchWebexService$(req: PatchServiceRequest<WebexCalling>): Observable<PatchServiceResponse<WebexCalling>> {
    return this.apiService.apiPatch$<{ data: ServiceItemRaw }>(
      this.buildUri(`services/${ req.serviceItem.id }`),
      new WebexCalling(req.serviceItem).toApiData(true),
      {authRequired: true})
      .pipe(
        map((res: { data: ServiceItemRaw }): PatchServiceResponse<WebexCalling> => {
          return {
            error:       null,
            serviceItem: new WebexCalling().fromApiData(res.data as WebexCallingRaw),
            message:     new Alert().fromApiMessage({message: 'Webex service updated successfully.'})
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchServiceResponse<WebexCalling>> => {
          return of({
            error:       new Alert().fromApiError(err, req.formFields),
            serviceItem: null,
            message:     null
          });
        })
      );
  }

}
