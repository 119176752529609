import { Component, Inject, OnDestroy }                       from '@angular/core';
import { FormControl, Validators }                            from '@angular/forms';
import { MAT_DIALOG_DATA }                                    from '@angular/material/dialog';
import { CallingProfile }                                     from '@models/entity/calling-profile.model';
import { Observable, Subject }                                from 'rxjs';
import { Store }                                              from '@ngrx/store';
import { StoreState }                                         from '@redux/store';
import { selectCallingProfilesSearchResults }                 from '@redux/service/service.selectors';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { SearchCallingProfilesRequestAction }                 from '@redux/service/service.actions';
import { CallingProfileQueryParams }                          from '@models/form/calling-profile-query-params.model';

@Component({
  selector:    'ngx-delete-profile-modal',
  templateUrl: './delete-profile-modal.component.html',
  styleUrls:   ['./delete-profile-modal.component.scss'],
})
export class DeleteProfileModalComponent implements OnDestroy {
  typeTextCtrl  = new FormControl<string>('', Validators.required);
  migrateToCtrl = new FormControl<CallingProfile>(null);
  profiles$: Observable<CallingProfile[]>;
  destroy       = new Subject<void>();
  destroy$      = this.destroy.asObservable();

  constructor(@Inject(MAT_DIALOG_DATA) public modalData: { name: string, id: string, serviceId: string }, private store: Store<StoreState>) {
    this.profiles$ = this.store.select(selectCallingProfilesSearchResults)
      .pipe(map(profiles => profiles?.filter(p => p.id !== this.modalData.id)));

    this.migrateToCtrl
      .valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        debounceTime(500))
      .subscribe(value => {
        if (typeof value !== 'string') {
          return;
        }
        const q: CallingProfileQueryParams = new CallingProfileQueryParams();

        q.pageSize   = 50;
        q.pageNumber = 1;

        if (value && (value as string).length >= 3) {
          q.search = value;
        }

        q.sort = '-is_system,name';
        this.store.dispatch(SearchCallingProfilesRequestAction({
          serviceId:   this.modalData.serviceId,
          queryParams: q,
        }));
      });
  }

  getProfileName(profile: CallingProfile): string {
    return profile?.name || '';
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
