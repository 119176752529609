import { BaseState, requestReduce, responseReduce } from '@redux/helpers/reducer.helper';
import { Action, createReducer, on }                from '@ngrx/store';
import { Task }                                     from '@models/entity/task.model';
import { createEntityAdapter, EntityAdapter }       from '@ngrx/entity';
import * as AuditActions                            from '@redux/audit/audit.actions';
import {
  CANCEL_TASK_REQUEST,
  FETCH_AUDIT_CONTEXT_LIST_REQUEST,
  FETCH_AUDIT_LIST_REQUEST,
  FETCH_AUDIT_OBJECT_LIST_REQUEST,
  FETCH_TASK_COUNT_REQUEST,
  FETCH_TASK_LIST_REQUEST,
  FETCH_TASK_REQUEST,
}                                                   from '@redux/audit/audit.types';
import { TaskQueryParams }                          from '@models/form/task-query-params.model';
import { CancelTaskResponse }                       from '@models/api/audit/cancel-task-response.model';
import { Audit }                                    from '@models/entity/audit.model';
import { AuditQueryParams }                         from '@models/form/audit-query-params.model';

function cancelTask(state: AuditState, res: CancelTaskResponse): Partial<AuditState> {
  if (res.error || res.cancelled) {
    return {};
  }
  return {
    selectedTask: state.selectedTask?.id === res.id ? new Task({
      ...state.selectedTask,
      outcome: res.data.outcome,
      status:  res.data.status,
    }) : state.selectedTask,
    tasks:        state.tasks?.length ? state.tasks.map(task => {
      if (task.id !== res.id) {
        return task;
      }
      return new Task({ ...task, outcome: res.data.outcome, status: res.data.status });
    }) : [],
  };
}

export interface AuditState extends BaseState<Task> {
  tasks: Task[];
  selectedTask: Task;
  taskQueryParams: TaskQueryParams;
  auditList: Audit[];
  auditQueryParams: AuditQueryParams;
  counts: { [schema: string]: number };
  objects: { identifier: string, description: string }[];
  contexts: { identifier: string, description: string }[];
}

export const adapter: EntityAdapter<Task> = createEntityAdapter<Task>();

const initialState: AuditState = adapter.getInitialState({
  error:            null,
  message:          null,
  pending:          false,
  pendingTasks:     [],
  tasks:            null,
  taskQueryParams:  null,
  selectedTask:     null,
  counts:           null,
  auditList:        null,
  auditQueryParams: null,
  objects:          null,
  contexts:         null,
});

const _auditReducer = createReducer(initialState,
  on(AuditActions.FetchTaskListRequestAction, requestReduce),
  on(AuditActions.FetchTaskListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TASK_LIST_REQUEST, (res) => ({
      tasks:           res.error ? state.tasks : res.models,
      taskQueryParams: res.error ? state.taskQueryParams : res.searchParams,
    }))),
  on(AuditActions.FetchAuditListRequestAction, requestReduce),
  on(AuditActions.FetchAuditListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_AUDIT_LIST_REQUEST, (res) => ({
      auditList:        res.error ? state.auditList : res.models,
      auditQueryParams: res.error ? state.auditQueryParams : res.searchParams,
    }))),
  on(AuditActions.FetchAuditContextListRequestAction, requestReduce),
  on(AuditActions.FetchAuditContextListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_AUDIT_CONTEXT_LIST_REQUEST, (res) => ({
      contexts: res.error ? state.contexts : res.data,
    }))),
  on(AuditActions.FetchAuditObjectListRequestAction, requestReduce),
  on(AuditActions.FetchAuditObjectListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_AUDIT_OBJECT_LIST_REQUEST, (res) => ({
      objects: res.error ? state.objects : res.data,
    }))),
  on(AuditActions.FetchTaskRequestAction, requestReduce),
  on(AuditActions.FetchTaskResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TASK_REQUEST, (res) => ({
      selectedTask: res.error ? state.selectedTask : res.data,
    }))),
  on(AuditActions.FetchTaskCountRequestAction, requestReduce),
  on(AuditActions.FetchTaskCountResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TASK_COUNT_REQUEST, (res) => ({
      counts: res.error ? state.counts : res.data,
    }))),
  on(AuditActions.CancelTaskRequestAction, requestReduce),
  on(AuditActions.CancelTaskResponseAction, (state, action) =>
    responseReduce(state, action, CANCEL_TASK_REQUEST, res => cancelTask(state, res)),
  ),
);

export function auditReducer(state: AuditState, action: Action): AuditState {
  return _auditReducer(state, action);
}
