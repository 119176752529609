import moment               from 'moment';
import { ReportSeriesData } from '@models/entity/report-series-data.model';
import { ChartData }        from '@models/entity/chart-data.model';
import { ChartMultiSeries } from '@models/chart/chart-multi-series.model';
import { UsagePeriod }      from '@enums/usage-period.enum';
import { ChartSeries }      from '@models/chart/chart-series.model';
import { LocaleDatePipe }   from '@pipes/locale-date.pipe';

export class UsageByDirectionData {
  series: Array<ReportSeriesData>;
  points: Array<{ [id: string]: string | number }>;

  constructor(private data?: UsageByDirectionData) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  static fromApiData(apiData: ChartData, interval: string, startDate: string, endDate: string): UsageByDirectionData {
    const d: UsageByDirectionData = new UsageByDirectionData();

    d.series = apiData.series.map(p => ReportSeriesData.fromApiData(p));
    d.points = UsageByDirectionData.constructPointArray(apiData, interval, startDate, endDate);

    d.points = d.points.map(p => ({
      ...p,
      formatDate: UsageByDirectionData.getFormatDate(p.name as string, interval),
    }));

    return d;
  }

  static constructPointArray(apiData: { series?: { id: string; name: string; }[]; points?: any[]; }, interval: string, startDate: moment.MomentInput, endDate: moment.MomentInput): Array<any> {
    const start = moment(startDate);
    const end   = moment(endDate);

    if (!apiData.points || !apiData.points.length) {
      return apiData.points;
    }

    let inc: moment.unitOfTime.StartOf                  = null;
    let duration: moment.unitOfTime.DurationConstructor = null;
    let fmt: string                                     = null;
    switch (interval) {
      case 'day':
        inc      = 'day';
        duration = 'day';
        fmt      = 'YYYY-MM-DD';
        break;
      case 'month':
        inc      = 'month';
        duration = 'month';
        fmt      = 'YYYY-MM';
        break;
      case 'week':
        inc      = 'isoWeek';
        duration = 'week';
        fmt      = 'YYYY-WW';
        break;
    }

    let currentDate = start
      .startOf(inc);
    let finalDate   = end
      .startOf(inc);

    const datePeriods = [];
    while (currentDate.isSameOrBefore(finalDate)) {
      datePeriods.push(currentDate.format(fmt));
      currentDate = moment(currentDate)
        .add(1, duration);
    }

    return datePeriods
      .map((date: string) => {
        const matchingDate: { in_count: number, name: string, out_count: number, on_count: number } = apiData.points.find((p: { name: string }) => p.name === date);
        return matchingDate ? matchingDate : {
          in_count:  0,
          name:      date,
          out_count: 0,
          on_count:  0,
        };
      })
      .filter(v => moment(v.name, fmt)
        .isValid());
  }

  static getFormatDate(date: string, interval: string): string {
    switch (interval) {
      case 'day':
        return date;
      case 'week':
        return moment(date, 'YYYY-WW')
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
      case 'month':
        return moment(date, 'YYYY-MM')
          .startOf('month')
          .format('YYYY-MM-DD');
    }
  }

  toChartMultiSeries?(fragment: UsagePeriod, datePipe: LocaleDatePipe): ChartMultiSeries[] {
    const points = this.points;
    const series = this.series;
    let dateFormat: string;
    switch (fragment) {
      case UsagePeriod.Daily:
        dateFormat = 'mediumDate';
        break;
      case UsagePeriod.Monthly:
        dateFormat = 'MMM';
        break;
      case UsagePeriod.Weekly:
        dateFormat = 'dd MMM';
        break;
    }

    return series?.map(s => {
        return {
          name:   s.name,
          series: points?.length ? points.map(d => {
            return {
              name:  datePipe.transform(Date.parse(d.formatDate as string), dateFormat),
              value: +d[s.id],
            } as ChartSeries;
          }) : [{
            name:  datePipe.transform(Date.now(), 'MMM'),
            value: 0,
          }],
        };
      },
    ) || [];
  }

  static getTotals(chartSeries: ChartMultiSeries[]): { id: string; name: string; totalCount: number; }[] {
    {
      const series = [
        {
          id:         'in_count',
          name:       'Inbound',
          totalCount: 0,
        },
        {
          id:         'out_count',
          name:       'Outbound',
          totalCount: 0,
        },
        {
          id:         'on_count',
          name:       'Internal',
          totalCount: 0,
        },
      ];
      for (const s of chartSeries) {
        const total: number = s.series?.length ? s.series.map(item => item.value)
          .reduce((a, b) => a + b) : 0;

        series[series.findIndex(item => item.name === s.name)].totalCount = total;
      }
      return series;
    }
  }
}
