import { ElementRef, Injectable }            from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent }                 from '@angular/material/chips';
import { MatAutocompleteSelectedEvent }      from '@angular/material/autocomplete';
import { map, startWith }                    from 'rxjs/operators';
import { Observable }                        from 'rxjs';
import deepEqual                             from '@util/deepequal';

@Injectable({
  providedIn: 'root',
})
export class FormService {

  static setError(formGroup: UntypedFormGroup, name: string, hasError: boolean): void {
    const currentErrors = { ...formGroup.errors };
    if (!hasError) {
      if (!currentErrors) {
        return;
      }
      if (Object.keys(currentErrors)
        .includes(name)) {
        delete currentErrors[name];
      }
      if (!Object.keys(currentErrors)?.length) {
        FormService.setFormErrors(formGroup, null);
      } else {
        FormService.setFormErrors(formGroup, currentErrors);
      }
      return;
    }
    (currentErrors || {})[name] = true;
    FormService.setFormErrors(formGroup, currentErrors);
  }

  private static setFormErrors(formGroup: UntypedFormGroup, errors: any): void {
    formGroup.setErrors(errors);
  }

  static addChipArrayItem(event: MatChipInputEvent, formCtrl: AbstractControl, inputCtrl?: AbstractControl): void {
    const value = (event.value || '').trim()
      .toLowerCase();

    if (value) {
      const curr = (formCtrl.value || []) as string[];
      if (curr.includes(value)) {
        return;
      }
      formCtrl.setValue(curr.concat(value));
      formCtrl.markAsDirty();
    }

    event.chipInput?.clear();

    inputCtrl?.setValue(null);
  }

  static removeArrayItem<T>(value: T, formCtrl: AbstractControl): void {
    const curr = (formCtrl.value || []) as T[];
    if (typeof value === 'string') {
      const t = value.toLowerCase();
      formCtrl.setValue(curr.filter(c => (c as string).toLowerCase() !== t));
    } else {
      formCtrl.setValue(curr.filter(c => (c as any).id !== (value as any).id));
    }
    formCtrl.markAsDirty();
    formCtrl.updateValueAndValidity();
  }

  static selectedAutoCompleteItem(event: MatAutocompleteSelectedEvent, formCtrl: AbstractControl, inputCtrl: AbstractControl, inputEl: ElementRef | HTMLInputElement): void {
    const curr = (formCtrl.value || []) as string[];
    formCtrl.setValue(curr.concat(event.option.value));
    if (inputEl instanceof ElementRef) {
      (inputEl.nativeElement as HTMLInputElement).value = '';
    } else {
      inputEl.value = '';
    }
    inputCtrl.setValue(null);
    formCtrl.markAsDirty();
  }

  static resetErrors(fg: UntypedFormGroup): void {
    Object.keys(fg.controls)
      .forEach(key => fg.controls[key].setErrors(null));
  }

  static isValid$(fg: UntypedFormGroup): Observable<boolean> {
    return fg.statusChanges.pipe(
      startWith(fg.status),
      map(() => fg.status === 'VALID'));
  }

  static formValueChanged$<T>(fg: UntypedFormGroup, initialData: T, compareKeys: (keyof T)[]): Observable<boolean> {
    return fg.valueChanges.pipe(
      startWith(fg.value),
      map(value => {
        const extractValues = (data: T) => {
          const d: T = (data || {}) as T; // e.g. POST /services when there is no initial data

          const extracted: Partial<T> = {};
          for (const key of compareKeys) {
            extracted[key] = d[key];
          }
          return extracted;
        };

        const formValue    = extractValues(value);
        const initialValue = extractValues(initialData);

        return !deepEqual(formValue, initialValue);
      }));
  }

  static generateArrayFromControlGroup<T, K = string>(form: T): K[] {
    if (Object.values(form)
      .every(val => !!val)) { // if every value is checked then drop the filter
      return undefined;
    }
    return (Object.keys(form) as Array<keyof T>)
      .filter((k: keyof T) => !!form[k]) as K[];
  }

  constructor() { }
}
