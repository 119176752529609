import { Observable, of }  from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ApiService }  from './api.service';
import { ListService } from './list.service';
import { environment } from '../../environments/environment';

import { Alert }                                from '@models/entity/alert.model';
import { HttpErrorResponse }                    from '@angular/common/http';
import { FetchCompanyNameAvailabilityRequest }  from '@models/api/fetch-company-name-availability-request.model';
import { FetchCompanyNameAvailabilityResponse } from '@models/api/fetch-company-name-availability-response.model';
import { AvailabilityCheckRaw }                 from '@models/api/availability-check-raw.model';
import { Availability }                         from '@enums/availability.enum';
import { User }                                 from '@models/entity/user.model';
import { UserProfileFactory }                   from '@models/factory/user-profile.factory';
import { UserRaw }                              from '@models/api/user-raw.model';
import { Company }                              from '@models/entity/company.model';
import { PostCompanyUserRequest }               from '@models/api/post-company-user.request';
import { PostCompanyUserResponse }              from '@models/api/post-company-user.response';
import { FetchCompanyUserListResponse }         from '@models/api/fetch-company-user-list.response';
import { FetchCompanyResponse }                 from '@models/api/fetch-company.response';
import { FetchCompanySubordinateListResponse }  from '@models/api/fetch-company-subordinate-list.response';
import { FetchCompanySubordinateListRequest }   from '@models/api/fetch-company-subordinate-list.request';
import { FetchCompanyUserListRequest }          from '@models/api/fetch-company-user-list.request';
import { FetchCompanyRequest }                  from '@models/api/fetch-company.request';
import { PostCompanyResponse }                  from '@models/api/post-company.response';
import { PostCompanyRequest }                   from '@models/api/post-company.request';
import { CompanyRaw }                           from '@models/api/company-raw.model';
import { CustomerQueryParams }                  from '@models/form/customer-query-params.model';
import { CustomerSearchParamsFactory }          from '@models/factory/customer-search-params.factory';
import { UserSearchParamsFactory }              from '@models/factory/user-search-params.factory';
import { UserQueryParams }                      from '@models/form/user-query-params.model';
import { CompanyFactory }                       from '@models/factory/company.factory';
import { DeleteUserRequest }                    from '@models/api/delete-user-request.model';
import { DeleteUserResponse }                   from '@models/api/delete-user-response.model';
import { PatchCompanyResponse }                 from '@models/api/patch-company-response.model';
import { PatchCompanyRequest }                  from '@models/api/patch-company-request.model';
import { FetchCompanyUserCountsResponse }       from '@models/api/fetch-company-user-counts-response.model';
import { UserStatusCounts }                     from '@models/entity/user-status-counts.model';
import { AuthScope }                            from '@enums/auth-scope.enum';
import { RestrictedInclude }                    from '@models/entity/restricted-params.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {

  private baseUrl: string = environment.api.identityBaseUrl;

  private readonly companyUserFactory: UserProfileFactory;
  private readonly companyUserParamFactory: UserSearchParamsFactory;

  private readonly customerFactory: CompanyFactory;
  private readonly customerSearchParamsFactory: CustomerSearchParamsFactory;

  constructor(
    private apiService: ApiService,
    private companyUserListService: ListService<User, UserProfileFactory,
      UserQueryParams, UserSearchParamsFactory>,
    private customerListService: ListService<Company, CompanyFactory,
      CustomerQueryParams, CustomerSearchParamsFactory>,
  ) {

    this.companyUserFactory          = new UserProfileFactory();
    this.companyUserParamFactory     = new UserSearchParamsFactory();
    this.customerFactory             = new CompanyFactory();
    this.customerSearchParamsFactory = new CustomerSearchParamsFactory();
  }

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  private buildCompanyChildrenListUri(queryParams: CustomerQueryParams): string {
    return this.buildUri(`companies/children${ CustomerQueryParams.constructQueryString(queryParams) }`);
  }

  private buildUserUri(id: string): string {
    return this.buildUri(`users/${ id }`);
  }

  private buildInviteUserUri(): string {
    return this.buildUri(`users/company-invites/dispatch`);
  }

  private buildUserListUri(queryParams: UserQueryParams, restrictedInclude: RestrictedInclude, scopes: AuthScope[]): string {
    return this.buildUri(`users${ UserQueryParams.constructQueryString(
      queryParams,
      null,
      restrictedInclude,
      scopes) }`);
  }

  private buildUserCountsUri(): string {
    return this.buildUri(`users/counts`);
  }

  private buildCompanyUri(companyId: string, includeBranding?: boolean): string {
    return this.buildUri(`companies/${ companyId }${ includeBranding ? '?include[]=brand' : '' }`);
  }

  private buildCompaniesUri(): string {
    return this.buildUri(`companies`);
  }

  private buildCompanyAvailabilityUri(name: string): string {
    return this.buildUri(`companies/availability/${ name }`);
  }

  fetchCompanyNameAvailable$(req: FetchCompanyNameAvailabilityRequest): Observable<FetchCompanyNameAvailabilityResponse> {
    if (!req.name || req.name.length < 4) {
      return of({
        error:        null,
        availability: null,
      });
    }
    return this.apiService.apiGet$<AvailabilityCheckRaw>(
      this.buildCompanyAvailabilityUri(req.name),
      { authRequired: false })
      .pipe(
        map((res: AvailabilityCheckRaw): FetchCompanyNameAvailabilityResponse => {
          return {
            error:        null,
            availability: res?.is_available ? Availability.Available : Availability.Unavailable,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCompanyNameAvailabilityResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            availability: null,
          });
        }),
      );
  }

  postCompany$(req: PostCompanyRequest): Observable<PostCompanyResponse> {
    return this.apiService.apiPost$<{ data: CompanyRaw }>(
      this.buildCompaniesUri(),
      new Company().toApiData(req.companyData),
      { authRequired: true })
      .pipe(
        map((res: { data: CompanyRaw }): PostCompanyResponse => {
          return {
            error:          null,
            companyData:    res.data && res.data ? new Company().fromApiData(res.data) : null,
            message:        new Alert().fromApiMessage({ message: `Successfully created ${ req.companyData.name }!` }),
            shouldNavigate: req.shouldNavigate,
            email:          req.email,
            sessionID:      req.sessionID,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostCompanyResponse> => {
          return of({
            error:          new Alert().fromApiError(err),
            companyData:    null,
            message:        null,
            email:          null,
            shouldNavigate: req.shouldNavigate,
            sessionID:      req.sessionID,
          });
        }),
      );
  }

  patchCompany$(req: PatchCompanyRequest): Observable<PatchCompanyResponse> {
    return this.apiService.apiPatch$<{ data: CompanyRaw }>(
      this.buildCompanyUri(req.data.id),
      new Company().toApiData(req.data),
      { authRequired: true })
      .pipe(
        map((res: { data: CompanyRaw }): PatchCompanyResponse => {
          return {
            error:       null,
            companyData: res.data ? new Company().fromApiData(res.data) : null,
            message:     new Alert().fromApiMessage({ message: `Successfully updated ${ req.data.name }!` }),
            requestId:   req.requestId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchCompanyResponse> => {
          return of({
            error:       new Alert().fromApiError(err),
            companyData: null,
          });
        }),
      );
  }

  fetchCompany$(req: FetchCompanyRequest): Observable<FetchCompanyResponse> {
    return this.apiService.apiGet$<{ data: CompanyRaw }>(
      this.buildCompanyUri(req.companyId, true),
      { authRequired: true })
      .pipe(
        map((res: { data: CompanyRaw }): FetchCompanyResponse => {
          return {
            error:       null,
            companyData: new Company().fromApiData(res.data),
            companyId:   req.companyId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCompanyResponse> => {
          return of({
            error:       new Alert().fromApiError(err),
            companyData: null,
            companyId:   req.companyId,
          });
        }),
      );
  }

  fetchCompanyUserCounts$(): Observable<FetchCompanyUserCountsResponse> {
    return this.apiService.apiGet$<{ data: UserStatusCounts }>(
      this.buildUserCountsUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: UserStatusCounts }): FetchCompanyUserCountsResponse => {
          const { ACTIVE, PENDING } = res.data;
          return {
            error:  null,
            counts: { ACTIVE, PENDING, TOTAL: { count: ACTIVE.count + PENDING.count, description: 'Total users' } },
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCompanyUserCountsResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            counts: null,
          });
        }),
      );
  }

  fetchCompanyUserList$(req: FetchCompanyUserListRequest): Observable<FetchCompanyUserListResponse> {
    return this.companyUserListService.fetchListModel$(
      this.buildUserListUri(req.queryParams, req.restrictedInclude, req.scopes),
      this.companyUserFactory,
      this.companyUserParamFactory,
    );
  }

  postCompanyUser$(req: PostCompanyUserRequest): Observable<PostCompanyUserResponse> {
    return this.apiService.apiPost$<{ data: UserRaw }>(
      this.buildInviteUserUri(),
      PostCompanyUserRequest.toApiData(req),
      { authRequired: true })
      .pipe(
        map((res: { data: UserRaw }): PostCompanyUserResponse => {
          return {
            error: null,
            user:  res.data ? new User().fromApiData(res.data, null) : null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostCompanyUserResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            user:  null,
          });
        }),
      );
  }

  deleteUser$(req: DeleteUserRequest): Observable<DeleteUserResponse> {
    return this.apiService.apiDelete$(
      this.buildUserUri(req.id),
      { authRequired: true })
      .pipe(
        map((): DeleteUserResponse => {
          return {
            error:   null,
            id:      req.id,
            message: new Alert().fromApiMessage({ message: `Successfully deleted ${ req.name }!` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteUserResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            id:    req.id,
          });
        }),
      );
  }

  fetchCompanySubordinateList$(req: FetchCompanySubordinateListRequest): Observable<FetchCompanySubordinateListResponse> {
    return this.customerListService.fetchListModel$(
      this.buildCompanyChildrenListUri(req.queryParams),
      this.customerFactory,
      this.customerSearchParamsFactory,
    );
  }

}
