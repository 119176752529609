import { AbstractControl } from '@angular/forms';
import ipRegex             from 'ip-regex';
import * as cidrRegex      from 'is-cidr';
import { DomainValidator } from './domain.validator';

export class IPValidator {
  static validIP(control: AbstractControl): { [id: string]: boolean } {
    const invalidIPv4 = IPValidator.invalidIPv4(control);
    if (invalidIPv4) {
      return invalidIPv4;
    }
    return null;
  }

  static validAddr(control: AbstractControl): { [id: string]: boolean } {
    const invalidIPv4 = IPValidator.invalidIPv4(control);
    const invalidAddr = DomainValidator.validDomain()(control);
    if (invalidIPv4 && invalidAddr) {
      return invalidIPv4 || invalidAddr;
    }
    return null;
  }

  static validCIDR(control: AbstractControl): { [id: string]: boolean } {
    return cidrRegex(control.value) ? null : { 'cidr-invalid': true };
  }

  static invalidIPv4(control: AbstractControl): { [id: string]: boolean } {
    return ipRegex.v4({ exact: true })
      .test(control.value) ? null : { 'ip-invalid': true };
  }

}
