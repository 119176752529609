<mat-dialog-content fxLayout="column" fxLayoutAlign="start center" fxFlexOffset="24px"
                    [class.background-tint-dark-blue]="background"
                    [ngStyle]="{overflow: overflow}"
                    class="position-relative border-radius-10">
    <div class="dialog-content-container" fxLayout="column"
         [class.p-0]="noPadding"
         [class.p-24]="!noPadding"
         fxLayoutAlign="start stretch">
        <ng-container *ngIf="icon">
            <div class="icon-container" fxLayout="row" fxLayoutAlign="center center"
                 [ngStyle]="{color: iconColor, backgroundColor: iconBgColor}">
                <mat-icon svgIcon="{{icon}}"></mat-icon>
            </div>
        </ng-container>
        <ng-content></ng-content>
    </div>
</mat-dialog-content>
