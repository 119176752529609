import { ServiceType }    from '@enums/service-type.enum';
import { MicrosoftTeams } from '@models/entity/microsoft-teams.model';
import { SIPTrunk }       from '@models/entity/sip-trunk.model';
import { SIPPhone }       from '@models/entity/sip-phone.model';
import { WebexCalling }   from '@models/entity/webex-calling.model';
import { CallForwarding } from '@models/entity/call-forwarding.model';
import { ServiceItem }    from '@models/entity/service-item.model';
import { ServiceCarrier } from '@models/entity/carrier-service.model';

export class ServiceFactory {
  create(serviceItem: ServiceItem): ServiceItem {
    switch (serviceItem?.serviceType) {
      case ServiceType.MicrosoftTeams:
        return new MicrosoftTeams(serviceItem as unknown as MicrosoftTeams);
      case ServiceType.SIPTrunk:
        return new SIPTrunk(serviceItem as unknown as SIPTrunk);
      case ServiceType.SIPPhone:
        return new SIPPhone(serviceItem as unknown as SIPPhone);
      case ServiceType.WebexCalling:
        return new WebexCalling(serviceItem as unknown as WebexCalling);
      case ServiceType.CallForwarding:
        return new CallForwarding(serviceItem as unknown as CallForwarding);
      case ServiceType.Carrier:
        return new ServiceCarrier(serviceItem as unknown as ServiceCarrier);
      default:
        return null;
    }
  }
}
