import { Component, HostBinding, Input, OnDestroy } from '@angular/core';

import { Subject, timer }          from 'rxjs';
import { NotificationItem }        from '@models/entity/notification.model';
import { takeUntil }               from 'rxjs/operators';
import { NotificationIdentifier }  from '@enums/notification-type.enum';
import { ToastrService }           from 'ngx-toastr';
import { fadeIn }                  from '@animations/fadeIn';
import { MarkAsReadRequestAction } from '@redux/notification/notification.actions';
import { StoreState }              from '@redux/store';
import { Store }                   from '@ngrx/store';

@Component({
  selector:    'ngx-text-notification',
  templateUrl: './text-notification.component.html',
  styleUrls:   ['./text-notification.component.scss'],
  animations:  [fadeIn(300, 200)],
})
export class TextNotificationComponent implements OnDestroy {
  @Input() notification: NotificationItem;
  @Input() toastId: number;
  @HostBinding('@fadeIn') fadeIn = true;
  timeoutMS                      = 20_000;
  timeProgress                   = 0;
  private timeElapsedMS          = 0;
  private destroy                = new Subject<void>();
  private destroy$               = this.destroy.asObservable();

  get link(): string[] {
    switch (this.notification.data?.context) {
      case 'Report.Published':
        return ['/reporting', 'reports'];
      default:
        return null;
    }
  }

  get linkText(): string {
    switch (this.notification.data?.context) {
      case 'Report.Published':
        return 'Click to view reports';
      default:
        return null;
    }
  }

  constructor(private toastrService: ToastrService,
              private store: Store<StoreState>) {
    timer(0, 200)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.timeElapsedMS += 200;
        this.timeProgress = (this.timeElapsedMS / this.timeoutMS) * 100;
        if (this.timeProgress === 100) {
          this.toastrService.remove(this.toastId);
        }
      });
  }

  dismiss(): void {
    this.toastrService.remove(this.toastId);
    this.store.dispatch(MarkAsReadRequestAction({
      notificationId:   this.notification.id,
      notificationType: 'READ',
    }));
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  getColor(): 'red' | 'orange' | 'green' | 'blue' | 'gray' {
    if (!this.notification?.type?.identifier) {
      return 'gray';
    }
    // TODO: Determine appropriate color p/ type
    switch (this.notification.type.identifier) {
      case NotificationIdentifier.AlertBroadcast:
        return 'blue';
      case NotificationIdentifier.AlertCompany:
        return 'blue';
      default:
        return 'blue';
    }
  }
}
