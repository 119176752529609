import { OperatorFunction } from 'rxjs';
import { map }              from 'rxjs/operators';

/**
 * Uses rxjs/map to pluck a value from each item in the array emitted via the stream
 * @returns OperatorFunction array of values taken from each item
 *
 * @example
 * ```ts
 *  const services$ = of([{name: 'MicrosoftTeams', users: 10}, {name: 'WebexCalling', users: 3}, {name: 'SIPTrunk', users: 2}]);
 *  this.userCounts$ = services$.pipe(pluckMap('users')); // [10, 3, 2]
 * ```
 */
export function pluckMap<T extends Array<U>, U = T[keyof T]>(pluck: keyof U): OperatorFunction<T, U[keyof U][]> {
  return map<T, U[keyof U][]>((arr: T) => arr?.map((value: U) => value?.[pluck] as U[keyof U]));
}
