export enum ConfigurableCarrierInput {
  Gateways            = 'gateways',
  IsSipOptionsEnabled = 'is_sip_options_enabled',
  Username            = 'registration.username',
  Password            = 'registration.password',
  AddressSpaces       = 'address_spaces',
  Realm               = 'registration.realm',
  RegistrarString     = 'registration.registrar_string',
  RegistrarAddress    = 'registration.registrar_address',
}
