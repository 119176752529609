import { BaseRequest }      from '@redux/helpers/reducer.helper';
import { QueryExpression }  from '@models/entity/query-expression.model';
import { AutomationRaw }    from '@models/api/automation-raw.model';
import { TeamGroupRole }    from '@enums/team-group-role.enum';
import { CountryCodes }     from '@models/entity/country-code.model';
import { AutomationStatus } from '@enums/automation-status.enum';

export class PostAutomationRequest extends BaseRequest {
  name: string;
  description: string;
  status: AutomationStatus;
  criteria: QueryExpression;
  priority: number;
  serviceId: string;
  profileId: string;
  licenseGroupIds: string[];
  teamGroupIds: { [teamGroupId: string]: TeamGroupRole };
  callQueueGroupIds: string[];
  isProfileAmendmentAllowed: boolean;
  usageLocation: keyof CountryCodes;
  isStackable: boolean;

  constructor(private data?: PostAutomationRequest) {
    super();
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  toApiData?(): AutomationRaw {
    return {
      name:                         this.name,
      status:                       this.status,
      description:                  this.description,
      criteria:                     JSON.stringify(this.criteria),
      priority:                     this.priority,
      profile_id:                   this.profileId,
      license_group_ids:            this.licenseGroupIds,
      team_group_ids:               this.teamGroupIds,
      call_queue_group_ids:         this.callQueueGroupIds,
      is_profile_amendment_allowed: this.isProfileAmendmentAllowed,
      usage_location:               this.usageLocation,
      is_stackable:                 this.isStackable,
    };
  }
}
