import { BaseRequest }   from '@redux/helpers/reducer.helper';
import { TeamGroupRole } from '@enums/team-group-role.enum';

export class AssignTeamGroupRequest extends BaseRequest {
  serviceId: string;
  userId: string;
  teamGroupIds: string[];
  teamGroupRoles: { [teamGroupId: string]: TeamGroupRole };
  assignments: { [teamGroupId: string]: TeamGroupRole };
  unassignments: string[];
  confirm?: boolean;

  constructor(private data?: AssignTeamGroupRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): { assignments: { [teamGroupId: string]: TeamGroupRole }, unassignments: string[] } {
    return {
      assignments:   this.assignments,
      unassignments: this.unassignments,
    };
  }

}
