import { Observable, of }  from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable }  from '@angular/core';
import { MatDialog }   from '@angular/material/dialog';
import { ApiService }  from './api.service';
import { ListService } from './list.service';
import { environment } from '../../environments/environment';

import { Alert } from '@models/entity/alert.model';

import { ConfirmModalComponent }                from '@dialog/general/confirm-modal/confirm-modal.component';
import {
  AudioPlaybackModalComponent,
}                                               from '@dialog/general/audio-playback-modal/audio-playback-modal.component';
import { BaseResponse, snakeToCamelCase }       from '@redux/helpers/reducer.helper';
import { HttpErrorResponse }                    from '@angular/common/http';
import { CallLogDataFactory }                   from '@models/factory/call-log-data.factory';
import { ReportSearchParamsFactory }            from '@models/factory/report-search-params.factory';
import { CallLogItem }                          from '@models/entity/call-log-item.model';
import { ReportQueryParams }                    from '@models/form/report-query-params.model';
import { FetchCallLogRequest }                  from '@models/api/fetch-call-log-request.model';
import { FetchCallLogRecordingResponse }        from '@models/api/fetch-call-log-recording-response.model';
import { FetchCallLogRecordingRequest }         from '@models/api/fetch-call-log-recording-request.model';
import { FetchExpensiveCallsResponse }          from '@models/api/fetch-expensive-calls-response.model';
import { FetchFrequentCallsResponse }           from '@models/api/fetch-frequent-calls-response.model';
import { FetchSummaryByCountryResponseRaw }     from '@models/api/fetch-summary-by-country-response-raw.model';
import { FetchUsageByDirectionResponse }        from '@models/api/fetch-usage-by-direction-response.model';
import { FetchSummaryByDirectionRequest }       from '@models/api/fetch-summary-by-direction-request.model';
import { SummaryByDirectionDataRaw }            from '@models/api/summary-by-direction-data-raw.model';
import { ChannelUsageData }                     from '@models/entity/channel-usage-data.model';
import { FetchUsageByDirectionRequest }         from '@models/api/fetch-usage-by-direction-request.model';
import { FetchCallLogSummaryRequest }           from '@models/api/fetch-call-log-summary-request.model';
import { FetchSummaryByCountryRequest }         from '@models/api/fetch-summary-by-country-request.model';
import { FetchSummaryByDirectionResponse }      from '@models/api/fetch-summary-by-direction-response.model';
import { FetchChannelUsageResponse }            from '@models/api/fetch-channel-usage-response.model';
import { FetchCallLogSummaryResponse }          from '@models/api/fetch-call-log-summary-response.model';
import { FetchReportingOverviewRequest }        from '@models/api/fetch-reporting-overview-request.model';
import { FetchUsageByDirectionResponseRaw }     from '@models/api/fetch-usage-by-direction-response-raw.model';
import { FetchLocationCostResponse }            from '@models/api/fetch-location-cost-response.model';
import { FetchDestinationsSummaryResponse }     from '@models/api/fetch-destinations-summary-response.model';
import { FetchCallLogResponse }                 from '@models/api/fetch-call-log-response.model';
import { ReportOverviewDataRaw }                from '@models/api/report-overview-data-raw.model';
import { FetchCallLogRecordingResponseRaw }     from '@models/api/fetch-call-log-recording-response-raw.model';
import { FetchChannelUsageResponseRaw }         from '@models/api/fetch-channel-usage-response-raw.model';
import { UsageByDirectionData }                 from '@models/entity/usage-by-direction-data.model';
import { FetchAnalyticsRequest }                from '@models/api/fetch-analytics-request.model';
import { ExportCDRtoCSVRequest }                from '@models/api/export-cdr-to-csv-request.model';
import { FetchReportingOverviewResponse }       from '@models/api/fetch-reporting-overview-response.model';
import { SummaryByDirectionData }               from '@models/entity/summary-by-direction-data.model';
import { FetchSummaryByCountryResponse }        from '@models/api/fetch-summary-by-country-response.model';
import { ReportOverviewData }                   from '@models/entity/report-overview-data.model';
import { FetchChannelUsageRequest }             from '@models/api/fetch-channel-usage-request.model';
import { ReportCountryDataRaw }                 from '@models/api/report-country-data-raw.model';
import { ExportCDRtoCSVResponse }               from '@models/api/export-cdr-to-csv-response.model';
import { ReportCountryData }                    from '@models/entity/report-country-data.model';
import { DestinationSummaryItemRaw }            from '@models/api/destination-summary-item-raw.model';
import { ExpensiveCallsItemRaw }                from '@models/api/expensive-calls-item-raw.model';
import { LocationCostItemRaw }                  from '@models/api/location-cost-item-raw.model';
import { FrequentCallsItemRaw }                 from '@models/api/frequent-calls-item-raw.model';
import { FetchCallMinutesByDirectionRequest }   from '@models/api/fetch-call-minutes-by-direction-request.model';
import { FetchCallMinutesByDirectionResponse }  from '@models/api/fetch-call-minutes-by-direction-response.model';
import { FetchCallsByDirectionResponseRaw }     from '@models/api/fetch-call-minutes-by-direction-response-raw.model';
import { FetchCallsBySipCodeRequest }           from '@models/api/fetch-calls-by-sip-code-request.model';
import { FetchCallsBySipCodeResponse }          from '@models/api/fetch-calls-by-sip-code-response.model';
import { SummaryBySipCode }                     from '@models/api/summary-by-sip-code.model';
import { FetchCallRecordingTranscriptResponse } from '@models/api/fetch-call-recording-transcript-response.model';
import { FetchCallRecordingTranscriptRequest }  from '@models/api/fetch-call-recording-transcript-request.model';
import { FetchCountByCapabilityResponse }       from '@models/api/fetch-count-by-capability-response.model';
import { CountByCapabilityRaw }                 from '@models/api/count-by-capability-raw.model';
import { CountByCapability }                    from '@models/entity/count-by-capability.model';
import { Report }                               from '@models/entity/report.model';
import { ReportFactory }                        from '@models/factory/report.factory';
import { ReportListQueryParams }                from '@models/entity/report-list-query-params.model';
import { ReportListQueryParamsFactory }         from '@models/factory/report-list-query-params.model';
import { FetchReportListRequest }               from '@models/api/fetch-report-list-request.model';
import { FetchReportListResponse }              from '@models/api/fetch-report-list-response.model';
import { FetchReportRequest }                   from '@models/api/reporting/fetch-report-request.model';
import { FetchReportResponse }                  from '@models/api/reporting/fetch-report-response.model';
import { ReportRaw }                            from '@models/api/report-raw.model';
import { DeleteReportResponse }                 from '@models/api/reporting/delete-report-response.model';
import {
  FetchTotalNumbersPerCountryRequest,
}                                               from '@models/api/reporting/fetch-total-numbers-per-country-request.model';
import {
  FetchTotalNumbersPerCountryResponse,
}                                               from '@models/api/reporting/fetch-total-numbers-per-country-response.model';
import { TotalNumbersPerXDataRaw }              from '@models/entity/total-numbers-per-x-data-raw.model';
import { TotalNumbersPerCountryData }           from '@models/entity/total-numbers-per-country-data.model';
import {
  FetchAssignedNumbersTotalRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-total-request.model';
import {
  FetchAssignedNumbersTotalResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-total-response.model';
import { AssignedNumbersByXData }               from '@models/entity/assigned-numbers-by-x.model';
import { AssignedNumbersByXRaw }                from '@models/entity/assigned-numbers-by-x-raw.model';
import {
  FetchAssignedNumbersByRangeRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-range-request.model';
import {
  FetchAssignedNumbersByRangeResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-range-response.model';
import {
  FetchAssignedNumbersByTagRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-tag-request.model';
import {
  FetchAssignedNumbersByTagResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-tag-response.model';
import {
  FetchAssignedNumbersByCountryRequest,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-country-request.model';
import {
  FetchAssignedNumbersByCountryResponse,
}                                               from '@models/api/reporting/fetch-assigned-numbers-by-country-response.model';
import {
  FetchTotalNumbersPerTypeRequest,
}                                               from '@models/api/reporting/fetch-total-numbers-per-type-request.model';
import {
  FetchTotalNumbersPerTypeResponse,
}                                               from '@models/api/reporting/fetch-total-numbers-per-type-response.model';
import { TotalNumbersPerTypeData }              from '@models/entity/total-numbers-per-type-data.model';
import { FetchRangeUsageTopRequest }            from '@models/api/reporting/fetch-range-usage-top-request.model';
import { FetchRangeUsageTopResponse }           from '@models/api/reporting/fetch-range-usage-top-response.model';
import { RangeUsageTopRaw }                     from '@models/entity/range-usage-top-raw.model';
import { RangeUsageTop }                        from '@models/entity/range-usage-top.model';
import { ListResponseMetadata }                 from '@models/api/list-response-metadata.model';
import { RangeExhaustionRawData }               from '@models/entity/range-exhaustion-raw-data.model';
import { RangeExhaustionData }                  from '@models/entity/range-exhaustion-data.model';
import { FetchRangeExhaustionResponse }         from '@models/api/reporting/fetch-range-exhaustion-response.model';
import { FetchRangeExhaustionRequest }          from '@models/api/reporting/fetch-range-exhaustion-request.model';
import { CDRExportFormat }                      from '@enums/cdr-export-format.enum';
import { FetchSipResponseTypesResponse }        from '@models/api/reporting/fetch-sip-response-types-response.model';
import { SipResponseType }                      from '@models/entity/sip-response-type.model';
import { CDRQueryParams }                       from '@models/form/cdr-query-params.model';
import { FetchCallsBySipTypeResponse }          from '@models/api/reporting/fetch-calls-by-sip-type-response.model';
import { SummaryBySipType }                     from '@models/api/reporting/summary-by-sip-response.model';
import { FetchCallsBySipTypeRequest }           from '@models/api/reporting/fetch-calls-by-sip-type-request.model';
import { CDRsByDirection }                      from '@models/entity/cdrs-by-direction.model';
import { FetchCallCostByDirectionRequest }      from '@models/api/number/fetch-call-cost-by-direction-request.model';
import { FetchCallCostByDirectionResponse }     from '@models/api/number/fetch-call-cost-by-direction-response.model';
import {
  FetchCallCostSummaryByBandRequest,
}                                               from '@models/api/number/fetch-call-cost-summary-by-band-request.model';
import {
  FetchCallCostSummaryByBandResponse,
}                                               from '@models/api/number/fetch-call-cost-summary-by-band-response.model';
import { CallCostSummaryByBandRaw }             from '@models/api/reporting/call-cost-summary-by-band-raw.model';
import { CallCostSummaryByBandData }            from '@models/entity/call-cost-summary-by-band.model';
import { FetchLocationCostRequest }             from '@models/api/fetch-location-cost-request.model';
import { FetchExpensiveCallsRequest }           from '@models/api/fetch-expensive-calls-request.model';
import { FetchDestinationsSummaryRequest }      from '@models/api/fetch-destinations-summary-request.model';
import { FetchFrequentCallsRequest }            from '@models/api/fetch-frequent-calls-request.model';


@Injectable({
  providedIn: 'root',
})
export class ReportService {

  // Base url for API calls
  private baseUrl: string = environment.api.reportBaseUrl;

  private readonly callLogDataFactory: CallLogDataFactory;
  private readonly reportSearchParamsFactory: ReportSearchParamsFactory;
  private readonly reportFactory: ReportFactory;
  private readonly reportListQueryParamsFactory: ReportListQueryParamsFactory;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private calLogService: ListService<CallLogItem, CallLogDataFactory,
      ReportQueryParams, ReportSearchParamsFactory>,
    private reportListService: ListService<Report, ReportFactory, ReportListQueryParams, ReportListQueryParamsFactory>,
  ) {
    this.callLogDataFactory           = new CallLogDataFactory();
    this.reportSearchParamsFactory    = new ReportSearchParamsFactory();
    this.reportFactory                = new ReportFactory();
    this.reportListQueryParamsFactory = new ReportListQueryParamsFactory();
  }

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  private buildNumberUri(uriSuffix: string): string {
    return `${ environment.api.numberBaseUrl }/${ uriSuffix }`;
  }

  private buildAnalyticsUri(metric: string, queryParams: ReportQueryParams): string {
    return this.buildUri(`analytics/${ metric }?${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildCdrHistoryUri(s: CDRQueryParams): string {
    return this.buildUri(`cdr/history${ CDRQueryParams.constructQueryString(s) }`);
  }

  private buildReportsUri(s: ReportQueryParams): string {
    return this.buildUri(`reports${ ReportListQueryParams.constructQueryString(s) }`);
  }

  private buildReportUri(id: string): string {
    return this.buildUri(`reports/${ id }`);
  }

  private buildChannelUsageUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`channel-usage${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildSipResponseTypesUri(): string {
    return this.buildUri(`sip-response-types`);
  }

  private buildTotalNumberPerCountryUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`series/number-count-by-country${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildAssignedNumberByRangeUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`series/assignment-count-by-range${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildAssignedNumberByTagUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`series/assignment-count-by-tag${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildAssignedNumberByCountryUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`series/assignment-count-by-country${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildAssignedNumberTotalUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`series/assignment-count${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildAssignedNumberByLocationUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`series/assignment-count-by-location${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildTotalNumberPerTypeUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`series/number-count-by-type${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildRangeExhaustionUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`series/range-exhaustion${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildRangeUsageTopUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`series/range-usage-high${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildSummaryByCountryUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`cdr/summary-by-country${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildSummaryByDirectionUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`cdr/summary-by-direction${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildSummaryBySipCodeUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`cdr/summary-by-sip-code${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildSummaryBySipTypeUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`cdr/summary-by-sip-type${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildTranscriptUri(id: string): string {
    return this.buildUri(`transcript/${ id }`);
  }

  private buildCountByCapabilityUri(): string {
    return this.buildNumberUri(`numbers/count-by-capability`);
  }

  private buildUsageByDirectionUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`cdr/usage-by-direction${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildCallsByDirectionUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`cdr/usage-by-number${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildCallsByBandUri(queryParams: ReportQueryParams): string {
    return this.buildUri(`cdr/cost-by-band${ ReportQueryParams.constructQueryString(queryParams) }`);
  }

  private buildCdrCsvUri(startDate: string, endDate: string, format: CDRExportFormat): string {
    return this.buildUri(`cdr/csv?start_date=${ startDate }&end_date=${ endDate }&format=${ format }`);
  }

  private buildOverviewUri(startDate: string, endDate: string): string {
    return this.buildUri(`cdr/overview?filter[date][since]=${ startDate }&filter[date][until]=${ endDate }`);
  }

  openErrorModal(title: string, content: string, confirmBtnText: string): void {
    this.dialog.open(ConfirmModalComponent, {
      panelClass: 'cr-dialog',
      data:       { title, content, confirmBtnText, showCancel: false },
    });
  }

  openAudioClip(item: CallLogItem): void {
    this.dialog.open(AudioPlaybackModalComponent, {
      data:       item,
      panelClass: 'cr-dialog',
      maxWidth:   '560px',
      width:      '100%',
    });
  }

  /**
   *  Fetch the reporting overview data
   */
  fetchReportingOverview$(req: FetchReportingOverviewRequest): Observable<FetchReportingOverviewResponse> {
    return this.apiService.apiGet$<{ data: ReportOverviewDataRaw }>(
      this.buildOverviewUri(req.startDate, req.endDate),
      { authRequired: true })
      .pipe(
        map((res: { data: ReportOverviewDataRaw }): FetchReportingOverviewResponse => {
          return {
            error:        null,
            overviewData: ReportOverviewData.fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchReportingOverviewResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            overviewData: null,
          });
        }),
      );
  }

  exportCDRtoCSV$(req: ExportCDRtoCSVRequest): Observable<ExportCDRtoCSVResponse> {
    return this.apiService.apiGet$<{ data: { task_id: string } }>(
      this.buildCdrCsvUri(req.startDate, req.endDate, req.format),
      { authRequired: true })
      .pipe(
        map((res: { data: { task_id: string } }): ExportCDRtoCSVResponse => {
          return {
            error:  null,
            taskId: res.data.task_id,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<ExportCDRtoCSVResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            taskId: null,
          });
        }),
      );
  }

  fetchCallMinutesByDirection$(req: FetchCallMinutesByDirectionRequest): Observable<FetchCallMinutesByDirectionResponse> {
    return this.apiService.apiGet$<{ data: FetchCallsByDirectionResponseRaw }>(
      this.buildCallsByDirectionUri(req.queryParams),
      { authRequired: true },
    )
      .pipe(
        map((res: { data: FetchCallsByDirectionResponseRaw }): FetchCallMinutesByDirectionResponse => {
          return {
            error:              null,
            callMinutesData:    CDRsByDirection.fromApiData(
              res.data, req.queryParams.aggregateBy, 'duration_sum', req.queryParams.since, req.queryParams.until),
            callMinutesDataRaw: res.data,
            searchParams:       req.queryParams,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallMinutesByDirectionResponse> => {

          return of({
            error:              new Alert().fromApiError(err),
            callMinutesData:    null,
            callMinutesDataRaw: null,
            searchParams:       null,
          });
        }),
      );
  }

  fetchCallCostByDirection$(req: FetchCallCostByDirectionRequest): Observable<FetchCallCostByDirectionResponse> {
    return this.apiService.apiGet$<{ data: FetchCallsByDirectionResponseRaw }>(
      this.buildCallsByDirectionUri(req.queryParams),
      { authRequired: true },
    )
      .pipe(
        map((res: { data: FetchCallsByDirectionResponseRaw }): FetchCallCostByDirectionResponse => {
          return {
            error:           null,
            callCostData:    CDRsByDirection.fromApiData(
              res.data, req.queryParams.aggregateBy, 'cost_sum', req.queryParams.since, req.queryParams.until),
            callCostDataRaw: res.data,
            searchParams:    req.queryParams,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallCostByDirectionResponse> => {

          return of({
            error:           new Alert().fromApiError(err),
            callCostData:    null,
            callCostDataRaw: null,
            searchParams:    null,
          });
        }),
      );
  }

  fetchCallCostSummaryByBand$(req: FetchCallCostSummaryByBandRequest): Observable<FetchCallCostSummaryByBandResponse> {
    return this.apiService.apiGet$<{ data: CallCostSummaryByBandRaw }>(
      this.buildCallsByBandUri(req.queryParams),
      { authRequired: true },
    )
      .pipe(
        map((res: { data: CallCostSummaryByBandRaw }): FetchCallCostSummaryByBandResponse => {
          return {
            error:        null,
            data:         new CallCostSummaryByBandData().fromApiData(
              res.data),
            searchParams: req.queryParams,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallCostSummaryByBandResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchUsageByDirection$(req: FetchUsageByDirectionRequest): Observable<FetchUsageByDirectionResponse> {
    return this.apiService.apiGet$<{ data: FetchUsageByDirectionResponseRaw }>(
      this.buildUsageByDirectionUri(req.queryParams),
      { authRequired: true },
    )
      .pipe(
        map((res: { data: FetchUsageByDirectionResponseRaw }): FetchUsageByDirectionResponse => {
          return {
            error:         null,
            directionData: UsageByDirectionData.fromApiData(
              res.data, req.queryParams.aggregateBy, req.queryParams.since, req.queryParams.until),
            searchParams:  req.queryParams,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchUsageByDirectionResponse> => {
          return of({
            error:         new Alert().fromApiError(err),
            directionData: null,
            searchParams:  null,
          });
        }),
      );
  }

  fetchTranscript$(req: FetchCallRecordingTranscriptRequest): Observable<FetchCallRecordingTranscriptResponse> {
    return this.apiService.apiGet$<{ data: { text: string } }>(
      this.buildTranscriptUri(req.id),
      { authRequired: true },
    )
      .pipe(
        map((res: { data: { text: string } }): FetchCallRecordingTranscriptResponse => {
          return {
            error: null,
            data:  res.data?.text,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallRecordingTranscriptResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchCountByCapability$(): Observable<FetchCountByCapabilityResponse> {
    return this.apiService.apiGet$<{ data: CountByCapabilityRaw }>(
      this.buildCountByCapabilityUri(),
      { authRequired: true },
    )
      .pipe(
        map((res: { data: CountByCapabilityRaw }): FetchCountByCapabilityResponse => {
          return {
            error: null,
            data:  new CountByCapability().fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCountByCapabilityResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchRangeExhaustion$(req: FetchRangeExhaustionRequest): Observable<FetchRangeExhaustionResponse> {
    return this.apiService.apiGet$<{ data: RangeExhaustionRawData }>(
      this.buildRangeExhaustionUri(req.queryParams),
      { authRequired: true },
    )
      .pipe(
        map((res: { data: RangeExhaustionRawData }): FetchRangeExhaustionResponse => {
          return {
            error: null,
            data:  new RangeExhaustionData().fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchRangeExhaustionResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchCallsBySIPCode$(req: FetchCallsBySipCodeRequest): Observable<FetchCallsBySipCodeResponse> {
    return this.apiService.apiGet$<{ data: SummaryBySipCode }>(
      this.buildSummaryBySipCodeUri(req.queryParams),
      { authRequired: true },
    )
      .pipe(
        map((res: { data: SummaryBySipCode }): FetchCallsBySipCodeResponse => {
          return {
            error: null,
            data:  res.data,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallsBySipCodeResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchCallsBySIPType$(req: FetchCallsBySipTypeRequest): Observable<FetchCallsBySipTypeResponse> {
    return this.apiService.apiGet$<{ data: SummaryBySipType }>(
      this.buildSummaryBySipTypeUri(req.queryParams),
      { authRequired: true },
    )
      .pipe(
        map((res: { data: SummaryBySipType }): FetchCallsBySipTypeResponse => {
          return {
            error: null,
            data:  res.data,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallsBySipTypeResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchSummaryByDirection$(req: FetchSummaryByDirectionRequest): Observable<FetchSummaryByDirectionResponse> {
    return this.apiService.apiGet$<{ data: SummaryByDirectionDataRaw }>(
      this.buildSummaryByDirectionUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: SummaryByDirectionDataRaw }): FetchSummaryByDirectionResponse => {
          return {
            error:        null,
            summaryData:  SummaryByDirectionData.fromApiData(res.data),
            searchParams: req.queryParams,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchSummaryByDirectionResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            summaryData:  null,
            searchParams: null,
          });
        }),
      );
  }

  fetchSummaryByCountry$(req: FetchSummaryByCountryRequest): Observable<FetchSummaryByCountryResponse> {
    return this.apiService.apiGet$<{ data: FetchSummaryByCountryResponseRaw }>(
      this.buildSummaryByCountryUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: FetchSummaryByCountryResponseRaw }): FetchSummaryByCountryResponse => {
          return {
            error:       null,
            countryData: res.data
                           .filter((_: ReportCountryDataRaw, index: number) => index <= 7)
                           .map((r: ReportCountryDataRaw, index: number) => ReportCountryData.fromApiData(r, index)),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchSummaryByCountryResponse> => {
          return of({
            error:       new Alert().fromApiError(err),
            countryData: null,
          });
        }),
      );
  }

  fetchChannelUsage$(req: FetchChannelUsageRequest): Observable<FetchChannelUsageResponse> {
    return this.apiService.apiGet$<{ data: FetchChannelUsageResponseRaw }>(
      this.buildChannelUsageUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: FetchChannelUsageResponseRaw }): FetchChannelUsageResponse => {
          return {
            error:        null,
            channelData:  ChannelUsageData.fromApiData(
              res.data, req.queryParams.aggregateBy),
            searchParams: req.queryParams,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchChannelUsageResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            channelData:  null,
            searchParams: null,
          });
        }),
      );
  }

  fetchSIPResponseTypes$(): Observable<FetchSipResponseTypesResponse> {
    return this.apiService.apiGet$<{ data: SipResponseType[] }>(
      this.buildSipResponseTypesUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: SipResponseType[] }): FetchSipResponseTypesResponse => {
          return {
            error: null,
            data:  res.data.map(d => new SipResponseType(d)),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchSipResponseTypesResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchTotalNumbersPerCountry$(req: FetchTotalNumbersPerCountryRequest): Observable<FetchTotalNumbersPerCountryResponse> {
    return this.apiService.apiGet$<{ data: TotalNumbersPerXDataRaw, meta: ListResponseMetadata }>(
      this.buildTotalNumberPerCountryUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: {
          data: TotalNumbersPerXDataRaw,
          meta: ListResponseMetadata
        }): FetchTotalNumbersPerCountryResponse => {
          return {
            error:        null,
            data:         new TotalNumbersPerCountryData().fromApiData(
              res.data),
            searchParams: new ReportQueryParams().constructParams(res.meta),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchTotalNumbersPerCountryResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchTotalNumbersPerType$(req: FetchTotalNumbersPerTypeRequest): Observable<FetchTotalNumbersPerTypeResponse> {
    return this.apiService.apiGet$<{ data: TotalNumbersPerXDataRaw, meta: ListResponseMetadata }>(
      this.buildTotalNumberPerTypeUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: TotalNumbersPerXDataRaw, meta: ListResponseMetadata }): FetchTotalNumbersPerTypeResponse => {
          return {
            error:        null,
            data:         new TotalNumbersPerTypeData().fromApiData(res.data),
            searchParams: new ReportQueryParams().constructParams(res.meta),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchTotalNumbersPerTypeResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchRangeUsageTop$(req: FetchRangeUsageTopRequest): Observable<FetchRangeUsageTopResponse> {
    return this.apiService.apiGet$<RangeUsageTopRaw>(
      this.buildRangeUsageTopUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: RangeUsageTopRaw): FetchRangeUsageTopResponse => {
          return {
            error:        null,
            data:         new RangeUsageTop().fromApiData(res),
            searchParams: req.queryParams,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchRangeUsageTopResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchAssignedNumbersTotal$(req: FetchAssignedNumbersTotalRequest): Observable<FetchAssignedNumbersTotalResponse> {
    return this.apiService.apiGet$<{ data: AssignedNumbersByXRaw, meta: ListResponseMetadata }>(
      this.buildAssignedNumberTotalUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: AssignedNumbersByXRaw, meta: ListResponseMetadata }): FetchAssignedNumbersTotalResponse => {
          return {
            error:        null,
            data:         new AssignedNumbersByXData().fromApiData(res.data),
            searchParams: new ReportQueryParams().constructParams(res.meta),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAssignedNumbersTotalResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchAssignedNumbersByLocation$(req: FetchAssignedNumbersTotalRequest): Observable<FetchAssignedNumbersTotalResponse> {
    return this.apiService.apiGet$<{ data: AssignedNumbersByXRaw, meta: ListResponseMetadata }>(
      this.buildAssignedNumberByLocationUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: AssignedNumbersByXRaw, meta: ListResponseMetadata }): FetchAssignedNumbersTotalResponse => {
          return {
            error:        null,
            data:         new AssignedNumbersByXData().fromApiData(res.data),
            searchParams: new ReportQueryParams().constructParams(res.meta),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAssignedNumbersTotalResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchAssignedNumbersByRange$(req: FetchAssignedNumbersByRangeRequest): Observable<FetchAssignedNumbersByRangeResponse> {
    return this.apiService.apiGet$<{ data: AssignedNumbersByXRaw, meta: ListResponseMetadata }>(
      this.buildAssignedNumberByRangeUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: AssignedNumbersByXRaw, meta: ListResponseMetadata }): FetchAssignedNumbersByRangeResponse => {
          return {
            error:        null,
            data:         new AssignedNumbersByXData().fromApiData(res.data),
            searchParams: new ReportQueryParams().constructParams(res.meta),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAssignedNumbersByRangeResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchAssignedNumbersByTag$(req: FetchAssignedNumbersByTagRequest): Observable<FetchAssignedNumbersByTagResponse> {
    return this.apiService.apiGet$<{ data: AssignedNumbersByXRaw, meta: ListResponseMetadata }>(
      this.buildAssignedNumberByTagUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: AssignedNumbersByXRaw, meta: ListResponseMetadata }): FetchAssignedNumbersByTagResponse => {
          return {
            error:        null,
            data:         new AssignedNumbersByXData().fromApiData(res.data),
            searchParams: new ReportQueryParams().constructParams(res.meta),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAssignedNumbersByTagResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchAssignedNumbersByCountry$(req: FetchAssignedNumbersByCountryRequest): Observable<FetchAssignedNumbersByCountryResponse> {
    return this.apiService.apiGet$<{ data: AssignedNumbersByXRaw }>(
      this.buildAssignedNumberByCountryUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: AssignedNumbersByXRaw }): FetchAssignedNumbersByCountryResponse => {
          return {
            error:        null,
            data:         new AssignedNumbersByXData().fromApiData(res.data),
            searchParams: req.queryParams,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAssignedNumbersByCountryResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchFrequentCalls$(req: FetchFrequentCallsRequest): Observable<FetchFrequentCallsResponse> {
    return this.fetchAnalytics$<FetchFrequentCallsResponse, FrequentCallsItemRaw>({ metric: 'frequent-calls' }, req.queryParams);
  }

  fetchExpensiveCalls$(req: FetchExpensiveCallsRequest): Observable<FetchExpensiveCallsResponse> {
    return this.fetchAnalytics$<FetchExpensiveCallsResponse, ExpensiveCallsItemRaw>({ metric: 'expensive-calls' }, req.queryParams);
  }

  fetchLocationCost$(req: FetchLocationCostRequest): Observable<FetchLocationCostResponse> {
    return this.fetchAnalytics$<FetchLocationCostResponse, LocationCostItemRaw>({ metric: 'location-cost' }, req.queryParams);
  }

  fetchDestinationSummary$(req: FetchDestinationsSummaryRequest): Observable<FetchDestinationsSummaryResponse> {
    return this.fetchAnalytics$<FetchDestinationsSummaryResponse, DestinationSummaryItemRaw>({ metric: 'destination-summary' }, req.queryParams);
  }

  fetchCallLog$(req: FetchCallLogRequest): Observable<FetchCallLogResponse> {
    return this.calLogService.fetchListModel$(
      this.buildCdrHistoryUri(req.queryParams),
      this.callLogDataFactory,
      this.reportSearchParamsFactory,
    );
  }

  fetchReportList$(req: FetchReportListRequest): Observable<FetchReportListResponse> {
    return this.reportListService.fetchListModel$(
      this.buildReportsUri(req.queryParams),
      this.reportFactory,
      this.reportListQueryParamsFactory,
    );
  }

  fetchCallLogSummary$(req: FetchCallLogSummaryRequest): Observable<FetchCallLogSummaryResponse> {
    const s: CDRQueryParams = new CDRQueryParams();
    s.pageSize              = 5;
    s.sort                  = '-date';
    s.since                 = req.since;
    s.until                 = req.until;

    return this.calLogService.fetchListModel$(
      this.buildCdrHistoryUri(s),
      this.callLogDataFactory,
      this.reportSearchParamsFactory,
    )
      .pipe(
        map(res => {
          return {
            models: res.error ? [] : res.models,
            error:  res.error,
          };
        }),
      );
  }

  fetchCallLogRecording$(req: FetchCallLogRecordingRequest): Observable<FetchCallLogRecordingResponse> {
    return this.apiService.apiGet$<{ data: FetchCallLogRecordingResponseRaw }>(
      req.logData.recordingUri,
      { authRequired: true })
      .pipe(
        map((res: { data: FetchCallLogRecordingResponseRaw }): FetchCallLogRecordingResponse => {
          return {
            error:   null,
            url:     res.data.recording_uri,
            logData: req.logData,
            noOpen:  req.noOpen,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallLogRecordingResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            url:     null,
            logData: req.logData,
            noOpen:  req.noOpen,
          });
        }),
      );
  }

  fetchReport$(req: FetchReportRequest): Observable<FetchReportResponse> {
    return this.apiService.apiGet$<{ data: ReportRaw }>(
      this.buildReportUri(req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: ReportRaw }): FetchReportResponse => {
          return {
            error: null,
            data:  new Report().fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchReportResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  deleteReport$(req: DeleteReportResponse): Observable<DeleteReportResponse> {
    return this.apiService.apiDelete$(
      this.buildReportUri(req.id),
      { authRequired: true })
      .pipe(
        map((): DeleteReportResponse => {
          return {
            error:        null,
            isLastOnPage: req.isLastOnPage,
            id:           req.id,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteReportResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            isLastOnPage: req.isLastOnPage,
            id:           req.id,
          });
        }),
      );
  }

  private fetchAnalytics$<T extends BaseResponse, U>(req: FetchAnalyticsRequest, queryParams: ReportQueryParams): Observable<T> {
    return this.apiService.apiGet$<{ data: U[] }>(
      this.buildAnalyticsUri(req.metric, queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: U[] }): T => {
          return {
            error: null,
            data:  (res?.data.map(item => snakeToCamelCase(item)) || null),
          } as unknown as T;
        }),
        catchError((err: HttpErrorResponse): Observable<T> => {
          return of({
            error: new Alert().fromApiError(err),
          }) as Observable<T>;
        }),
      );
  }

}
