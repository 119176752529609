import { Injectable }      from '@angular/core';
import { MomentService }   from './moment.service';
import { UsagePeriod }     from '@enums/usage-period.enum';
import { SupportedLocale } from '@enums/supported-locale.enum';
import { LocaleDatePipe }  from '@pipes/locale-date.pipe';
import { LocaleService }   from './locale.service';
import moment              from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  ranges: any = {
    'Last 7 Days':  [moment()
                       .subtract(6, 'days'), moment()],
    'Last 30 Days': [moment()
                       .subtract(29, 'days'), moment()],
    'This Month':   [moment()
                       .startOf('month'), moment()
                       .endOf('month')],
    'Last Month':   [moment()
                       .subtract(1, 'month')
                       .startOf('month'), moment()
                       .subtract(1, 'month')
                       .endOf('month')],
  };

  invalidDates: moment.Moment[] = [moment()
                                     .add(2, 'days'), moment()
                                     .add(3, 'days'), moment()
                                     .add(5, 'days')];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'));
  };

  constructor(private momentService: MomentService,
              private datePipe: LocaleDatePipe,
              private localeService: LocaleService) {}

  formatDateByPeriod(date: string, interval: UsagePeriod): string {
    const format = this.localeService.getLocale() === SupportedLocale.EnUS ? 'YYYY-DD-MM' : 'YYYY-MM-DD';
    switch (interval) {
      case 'day':
        return date;
      case 'week':
        return this.momentService.moment(date, 'YYYY-WW')
          .startOf('isoWeek')
          .format(format);
      case 'month':
        return this.momentService.moment(date, 'YYYY-MM')
          .startOf('month')
          .format(format);
    }
  }

  getNDaysAgo(n: number, toDate: number = Date.now(), format = 'yyyy-MM-dd'): string {
    return this.datePipe.transform(new Date(toDate - n * 24 * 60 * 60 * 1000), format);
  }

  getNWeeksAgo(n: number, toDate: string | number | Date = new Date(), format = 'yyyy-MM-DD'): string {
    return moment(toDate)
      .subtract(n, 'weeks')
      .format(format);
  }

  getNMonthAgo(n: number, toDate: string | number | Date = new Date(), format = 'yyyy-MM-DD'): string {
    return moment(toDate)
      .subtract(n, 'months')
      .format(format);
  }

  UTCtoDate(date: string, format = 'mediumDate'): string {
    return this.datePipe.transform(date, format);
  }

  UTCtoTime(date: string): string {
    return this.datePipe.transform(date, 'HH:mm:ss');
  }
}
