import { Injectable, OnDestroy } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationEnd,
  NavigationExtras,
  NavigationStart,
  Params,
  ResolveEnd,
  Router,
}                                from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  Subject,
}                                from 'rxjs';
import {
  Location,
}                                from '@angular/common';
import {
  splitURLByPath,
}                                from '../util/url.helper';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  takeUntil,
}                                from 'rxjs/operators';
import * as cookies              from 'browser-cookies';
import {
  repeatLatestWhen,
}                                from '@rxjs/repeatLatestWhen.operator';

@Injectable({
  providedIn: 'root',
})
export class RouterService implements OnDestroy {
  navigationEndEvent$: Observable<NavigationEnd | ResolveEnd>;
  path$: Observable<string>;
  fragment$: Observable<string>;
  params: BehaviorSubject<Params>;
  params$: Observable<Params>;
  popState$: Observable<boolean>;
  private destroy                       = new Subject<void>();
  private destroy$: Observable<unknown> = this.destroy.asObservable();

  private static paramsAreDifferent(
    currentParams: Params,
    nextParams: Params,
  ): boolean {

    const currentKeys = Object.keys(currentParams);
    const nextKeys    = Object.keys(nextParams);

    if (currentKeys?.length !== nextKeys?.length) {

      return true;

    }

    for (let i = 0, length = currentKeys.length; i < length; i++) {

      const key = currentKeys[i];
      if (currentParams[key] !== nextParams[key]) {

        return true;

      }

    }

    return false;

  }

  constructor(
    private router: Router,
    private location: Location,
  ) {

    const pathOnLoad = this.location.path(true);

    this.navigationEndEvent$ = this.router.events.pipe(
      startWith(new NavigationEnd(999, pathOnLoad, pathOnLoad)),
      filter((event: any) => event instanceof NavigationEnd || event instanceof ResolveEnd),
      shareReplay(1)) as Observable<NavigationEnd | ResolveEnd>;

    this.popState$ = this.router.events.pipe(
      filter((event: any) => (event as NavigationStart)?.navigationTrigger === 'popstate'));

    this.path$ = this.navigationEndEvent$
      .pipe(
        startWith(new NavigationEnd(999, pathOnLoad, pathOnLoad)),
        map(() => this.location.path()),
        distinctUntilChanged(),
        shareReplay(1));

    this.fragment$ = this.navigationEndEvent$
      .pipe(
        startWith(new NavigationEnd(999, pathOnLoad, pathOnLoad)),
        map((event: NavigationEnd | ResolveEnd) => {
          const path = event?.urlAfterRedirects || '';
          if (path.includes('#')) {
            return path.split('#')[1];
          }
          return '';
        }),
        distinctUntilChanged(),
        shareReplay(1));

    this.params$ = this.navigationEndEvent$.pipe(
      startWith(new NavigationEnd(999, pathOnLoad, pathOnLoad)),
      map(() => this.getUpdatedParams()),
      distinctUntilChanged((x, y) => !RouterService.paramsAreDifferent(x, y)),
      repeatLatestWhen(this.popState$));

    this.params$
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
          // Remove the microsoft token once a success or error response has been returned
          if (params.success || params.error || params.info) {
            const pCookies = cookies.all();
            for (const key of Object.keys(pCookies)) {
              if (key === 'cr-session') {
                cookies.erase(key, {
                  domain: '.callroute.io',
                });
              }
            }
          }
        },
      );
  }

  private getUpdatedParams(): Params {
    const snapshot = this.router.routerState.snapshot.root;

    return this.collectParams(snapshot);
  }

  private collectParams(root: ActivatedRouteSnapshot): Params {

    let params: Params = {};

    (function mergeParamsFromSnapshot(snapshot: ActivatedRouteSnapshot) {

      params = { ...snapshot.params, ...snapshot.queryParams };

      snapshot.children.forEach(mergeParamsFromSnapshot);

    })(root);

    return (params);

  }

  async navigate<T>(pathArray: Array<string>,
                    params?: T,
                    navigationExtras?: NavigationExtras): Promise<boolean> {

    const companyParam = (params as unknown as { company: string })?.company;
    const company      = companyParam !== undefined ?
      companyParam :
      splitURLByPath(this.location.path()).queryParams?.company;
    const queryParams  = { ...(params || {}), company };
    return this.router.navigate(pathArray, {
      queryParams,
      ...navigationExtras,
    });

  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

}
