import { MicrosoftTeamsSettingsRaw } from '@models/api/microsoft-teams-settings-raw.model';
import { SyncMode }                  from '@enums/sync-mode.enum';

export class MicrosoftTeamsSettings {
  sync: {
    fullSync: boolean;
    includeUnavailable: boolean;
    autoProvision: boolean;
    inboundAssignment: boolean;
    mode: SyncMode;
  };
  configure: {
    magicExtension: boolean;
  };

  constructor(private data?: MicrosoftTeamsSettings) {
    Object.assign(this, data);
    delete this['data'];
  }

  fromFormData?(data: Partial<{
    fullSync: boolean,
    includeUnavailable: boolean,
    magicExtension: boolean,
    autoProvision: boolean,
    mode: SyncMode,
    inboundAssignment: boolean
  }>): MicrosoftTeamsSettings {
    this.sync      = {
      fullSync:           data.fullSync,
      includeUnavailable: data.includeUnavailable,
      mode:               data.mode,
      autoProvision:      data.autoProvision,
      inboundAssignment:  data.inboundAssignment,
    };
    this.configure = {
      magicExtension: data.magicExtension,
    };

    return this;
  }

  fromApiData?(data: MicrosoftTeamsSettingsRaw): MicrosoftTeamsSettings {
    this.sync      = {
      fullSync:           !!data.sync?.full_sync,
      includeUnavailable: !!data.sync?.include_unavailable,
      autoProvision:      !!data.sync?.auto_provision,
      inboundAssignment:  !!data.sync?.is_inbound_assignment_enabled,
      mode:               data.sync?.mode || SyncMode.VoiceOnly,
    };
    this.configure = { magicExtension: !!data.configure?.magic_extension };
    return this;
  }

  toApiData?(): MicrosoftTeamsSettingsRaw {
    return {
      sync:      {
        full_sync:                     this.sync.fullSync,
        include_unavailable:           this.sync.includeUnavailable,
        mode:                          this.sync.mode,
        auto_provision:                this.sync.autoProvision,
        is_inbound_assignment_enabled: this.sync.inboundAssignment,
      },
      configure: {
        magic_extension: this.configure.magicExtension,
      },
    };
  }
}
