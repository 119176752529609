import { Component, ElementRef, Inject, OnDestroy, ViewChild }       from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }                             from '@angular/material/dialog';
import { Carrier }                                                   from '@models/entity/carrier.model';
import { FormControl, FormGroup, UntypedFormGroup, Validators }      from '@angular/forms';
import { filter, take, takeUntil }                                   from 'rxjs/operators';
import { CountryItem }                                               from '@models/entity/country-code.model';
import { FieldCheckType }                                            from '@enums/field-check-type.enum';
import { BehaviorSubject, Observable, Subject }                      from 'rxjs';
import { StoreState }                                                from '@redux/store';
import { Store }                                                     from '@ngrx/store';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger }     from '@angular/material/autocomplete';
import { SendCustomCarrierRequestAction }                            from '@redux/service/service.actions';
import { selectCustomCarrierResult, selectServicePendingTasks }      from '@redux/service/service.selectors';
import { pluckMap }                                                  from '@rxjs/pluckMap.operator';
import { containsAny }                                               from '@rxjs/containsAny.operator';
import { SEND_CUSTOM_CARRIER_REQUEST, SEND_CUSTOM_CARRIER_RESPONSE } from '@redux/service/service.types';
import { CountryService }                                            from '@services/country.service';
import { CarrierContactForm }                                        from '@models/form/carrier-contact-form.model';

@Component({
  selector:    'ngx-configure-custom-carrier-modal',
  templateUrl: './configure-custom-carrier-modal.component.html',
  styleUrls:   ['./configure-custom-carrier-modal.component.scss'],
})
export class ConfigureCustomCarrierModalComponent implements OnDestroy {
  @ViewChild('submitAnchor', { read: ElementRef }) anchor: ElementRef;
  customIcon: string;
  formGroup: UntypedFormGroup;
  filteredCountries: Array<CountryItem>;
  FieldCheckType = FieldCheckType;
  submitted      = new BehaviorSubject<boolean>(false);
  submitted$     = this.submitted.asObservable();
  destroy        = new Subject<void>();
  destroy$       = this.destroy.asObservable();
  submitPending$: Observable<boolean>;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

  constructor(@Inject(MAT_DIALOG_DATA) private data: Carrier,
              private store: Store<StoreState>,
              private dialog: MatDialogRef<ConfigureCustomCarrierModalComponent>) {

    this.submitPending$ = this.store.select(selectServicePendingTasks)
      .pipe(pluckMap('id'), containsAny(SEND_CUSTOM_CARRIER_REQUEST, SEND_CUSTOM_CARRIER_RESPONSE));

    this.customIcon = this.data.logoUri;

    this.formGroup = new FormGroup<CarrierContactForm>({
      carrierName:        new FormControl('', Validators.required),
      country:            new FormControl(null, Validators.required),
      countryCode:        new FormControl(null, Validators.required),
      information:        new FormControl('', Validators.required),
      dataSharingConsent: new FormControl(false, Validators.requiredTrue),
    });

    this.filteredCountries = CountryService.countryList;

    this.formGroup.get('country')
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.filteredCountries = CountryService.filterCountries(x);
      });
  }

  async submit(): Promise<void> {
    const { carrierName, countryCode, information, dataSharingConsent } = this.formGroup.value;

    const requestId = Date.now()
      .toString();

    this.store.dispatch(SendCustomCarrierRequestAction({
      carrierName,
      countryCode,
      information,
      dataSharingConsent,
      requestId,
    }));

    const result = await this.store.select(selectCustomCarrierResult)
      .pipe(filter(res => res?.id === requestId), take(1))
      .toPromise();

    if (result.success) {
      this.submitted.next(true);
    }
  }

  close(): void {
    this.dialog.close();
  }

  setCountryCode(e: MatAutocompleteSelectedEvent): void {
    const selectedCountry = e.option ? CountryService.countryList.find(c => c.name === e.option.value) : null;
    this.formGroup.get('countryCode')
      .setValue(selectedCountry.code);
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

}
