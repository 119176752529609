import { Alert }             from '@models/entity/alert.model';
import { AlertType }         from '@enums/alert-type.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { APIError }          from '@models/entity/api-error.model';

export class UserConfigureError extends Alert {
  fromApiError?(apiError: APIError & HttpErrorResponse): UserConfigureError {
    const fallbackError = 'There was a problem communicating with Callroute servers. Please try later.';
    this.icon           = 'warning-white.svg';
    this.isError        = true;
    this.type           = AlertType.Error;
    this.code           = (apiError as HttpErrorResponse)?.status;
    const contextPrefix = this.context ? `${ this.context }: ` : '';
    if (this.code === 404) {
      this.show = false;
    }
    if ((apiError as APIError)?.error?.detail) {
      this.message         = `${ contextPrefix }${ (apiError as APIError).error.detail }`;
      this.backgroundColor = 'red';
    } else {
      this.message         = `${ contextPrefix }${ fallbackError }`;
      this.backgroundColor = 'orange';
    }

    const userConfigureMessages = (apiError as APIError).error.messages;

    if (userConfigureMessages) {
      this.message = this.message.concat(': ', Object.values(userConfigureMessages)[0]);
    }

    return this;
  }
}
