<div class="company-header" fxLayout="row" fxLayoutAlign="start center"
     *ngIf="companyData && companyData.ownCompanyData">
  <mat-icon svgIcon="cr-business" class="color-white-80"></mat-icon>
  <span fxHide.lt-md>You are currently viewing</span><span fxHide.gt-sm>Viewing</span>
  &nbsp;<span>{{companyData.ownCompanyData.name}}&nbsp;&nbsp;&middot;&nbsp;&nbsp;{{companyData.ownCompanyData.reference}}
  <ng-container *ngIf="isSipAdmin">
    &nbsp;&nbsp;&middot;&nbsp;&nbsp;{{companyType}}
  </ng-container>
  </span>
</div>
