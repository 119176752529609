import { Component, Inject, NgZone, OnDestroy, Renderer2 } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef }                            from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';


import WaveSurfer                   from 'wavesurfer.js';
import * as moment                  from 'moment';
import { Observable, Subject }      from 'rxjs';
import { takeUntil }                from 'rxjs/operators';
import { AssetsService }            from '@services/assets.service';
import { CallLogItem }              from '@models/entity/call-log-item.model';
import { CallLogActionService }     from '@services/call-log-action.service';
import { selectReportPendingTasks } from '@redux/report/report.selectors';
import { StoreState }               from '@redux/store';
import { Store }                    from '@ngrx/store';
import { containsAny }              from '@rxjs/containsAny.operator';
import { pluckMap }                 from '@rxjs/pluckMap.operator';

@Component({
  selector:    'ngx-audio-playback-modal',
  templateUrl: './audio-playback-modal.component.html',
  styleUrls:   ['./audio-playback-modal.component.scss'],
})
export class AudioPlaybackModalComponent implements OnDestroy {

  waveSurfer: WaveSurfer;
  waveSurferModule: typeof WaveSurfer;
  isPlaying            = false;
  logDate: string;
  playerCurrent        = '00:00';
  playerDuration       = '00:00';
  isMuted              = false;
  formGroup: UntypedFormGroup;
  loadingAudio         = true;
  closeIconUrl: string = AssetsService.getIcon('close.svg');
  downloadLoading$: Observable<boolean>;
  private destroy      = new Subject<void>();
  private destroy$     = this.destroy.asObservable();

  constructor(
    @Inject(MAT_DIALOG_DATA) public logData: CallLogItem,
    private dialogRef: MatDialogRef<AudioPlaybackModalComponent>,
    private zone: NgZone,
    private fb: UntypedFormBuilder,
    private callLogActionService: CallLogActionService,
    private renderer2: Renderer2,
    private store: Store<StoreState>,
  ) {

    this.downloadLoading$ = this.store.select(selectReportPendingTasks)
      .pipe(pluckMap('id'), containsAny('CL_' + this.logData.id));
    this.logDate          = logData.date;
    this.playerDuration   = this.getTimestamp(moment.duration(logData.duration)
      .asSeconds());

    this.formGroup = this.fb.group({
      volume: new UntypedFormControl({ value: 1, disabled: false }),
    });

    import('wavesurfer.js').then(mod => {
      this.waveSurferModule = mod.default;
      /* eslint-disable */
      this.waveSurfer = this.waveSurferModule.create({
        container:     '#waveform',
        waveColor:     '#e8e8e8',
        cursorColor:   '#c8c8c8',
        progressColor: '#0096FF',
        barWidth:      2,
        responsive:    true,
        height:        72,
      });
      /* eslint-enable */

      this.formGroup.get('volume')
        .valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(val => {
          this.waveSurfer.setVolume(val); // eslint-disable-line
        });

      if (this.dialogRef.beforeClosed) {
        this.dialogRef.beforeClosed()
          .subscribe(() => {
            this.waveSurfer.stop(); // eslint-disable-line
            this.waveSurfer.destroy(); // eslint-disable-line
          });
      }

      if (this.logData && this.logData.recordingUri) {
        this.waveSurfer.load(this.logData.recordingUri); // eslint-disable-line
      }
      /* eslint-disable */
      this.waveSurfer.on('finish', (() => {
        this.zone.run(() => {
          this.isPlaying = false;
        });
      }));
      this.waveSurfer.on('audioprocess', (() => {
        this.zone.run(() => {
          this.playerCurrent = this.getTimestamp(this.waveSurfer.getCurrentTime());
        });
      }));
      this.waveSurfer.on('seek', (() => {
        this.zone.run(() => {
          this.playerCurrent = this.getTimestamp(this.waveSurfer.getCurrentTime());
        });
      }));
      this.waveSurfer.on('ready', (() => {
        this.zone.run(() => {
          this.loadingAudio = false;
        });
      }));
      /* eslint-enable */
    });
  }

  togglePlayer(): void {
    this.waveSurfer.playPause(); // eslint-disable-line
    this.setPlayerState();
  }

  setPlayerState(): void {
    this.isPlaying = this.waveSurfer.isPlaying(); // eslint-disable-line
  }

  toggleMute(): void {
    this.isMuted = !this.isMuted;

    if (this.isMuted) {
      this.formGroup.get('volume')
        .disable();
    } else {
      this.formGroup.get('volume')
        .enable();
    }
    this.waveSurfer.setVolume(this.isMuted ? 0 : this.formGroup.get('volume').value); // eslint-disable-line
  }

  getTimestamp(duration: number): string {
    if (!duration) {
      return '00:00';
    }

    const min = Math.floor(duration / 60);
    const sec = Math.ceil(duration) % 60;

    return (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec;
  }

  download(): void {
    this.callLogActionService.downloadRecording(this.renderer2, this.logData);
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
