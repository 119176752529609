export class ListFilter {
  id: string;
  value: boolean;
  label? = '';
  directionAsc: boolean;
  canSort: boolean;
  filterClass?: string;
  tooltip?: string;
  link?: string;
  width?: string;
  widthSm?: string;
  hideSm?: boolean;
  hideMd?: boolean;

  constructor(private data?: ListFilter) {
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  static updateListFilters(sortArray: Array<ListFilter>, sort: string): Array<ListFilter> {
    return sortArray.map(s => {
      const match = ListFilter.determineSortKeyMatch(s, sort);

      return new ListFilter({
        ...s,
        value:        match?.value,
        directionAsc: match?.asc,
      });
    });
  }

  static determineSortKeyMatch(sortFilter: ListFilter, key: string): { value: boolean, asc: boolean } {
    const sortKeys = key?.split(',') || [];

    const foundAsc  = sortKeys
      .includes(sortFilter.id);
    const foundDesc = sortKeys
      .includes(`-${ sortFilter.id }`);

    return { value: foundAsc || foundDesc, asc: foundAsc };
  }

}
