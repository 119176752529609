import { Component }    from '@angular/core';
import { Store }        from '@ngrx/store';
import { StoreState }   from '@redux/store';
import { LogoutAction } from '@redux/auth/auth.actions';

@Component({
  selector:    'ngx-new-user-page',
  templateUrl: './new-user-page.component.html',
  styleUrls:   ['./new-user-page.component.scss'],
})
export class NewUserPageComponent {

  constructor(private store: Store<StoreState>) { }

  logout(): void {
    this.store.dispatch(LogoutAction({}));
  }

}
