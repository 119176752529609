import * as countryList from 'country-list';

export interface CountryItem {
  name: string;
  code: string;
}

export interface CountryCodes {
  NZL: string;
  FJI: string;
  PNG: string;
  GLP: string;
  STP: string;
  MHL: string;
  WLF: string;
  CUB: string;
  SDN: string;
  GMB: string;
  CUW: string;
  MYS: string;
  MYT: string;
  TWN: string;
  POL: string;
  OMN: string;
  SUR: string;
  ARE: string;
  KEN: string;
  ARG: string;
  GNB: string;
  ARM: string;
  UZB: string;
  BTN: string;
  SEN: string;
  TGO: string;
  IRL: string;
  FLK: string;
  IRN: string;
  QAT: string;
  BDI: string;
  NLD: string;
  IRQ: string;
  SVK: string;
  SVN: string;
  GNQ: string;
  THA: string;
  ABW: string;
  ASM: string;
  SWE: string;
  ISL: string;
  MKD: string;
  BEL: string;
  ISR: string;
  KWT: string;
  LIE: string;
  DZA: string;
  BEN: string;
  ATA: string;
  BES: string;
  RUS: string;
  ATF: string;
  ATG: string;
  ITA: string;
  SWZ: string;
  TZA: string;
  PAK: string;
  BFA: string;
  CXR: string;
  PAN: string;
  SGP: string;
  UKR: string;
  SGS: string;
  JEY: string;
  KGZ: string;
  BVT: string;
  CHE: string;
  DJI: string;
  REU: string;
  CHL: string;
  PRI: string;
  CHN: string;
  PRK: string;
  SXM: string;
  MLI: string;
  BWA: string;
  HRV: string;
  KHM: string;
  IDN: string;
  PRT: string;
  MLT: string;
  TJK: string;
  VNM: string;
  CYM: string;
  PRY: string;
  SHN: string;
  CYP: string;
  SYC: string;
  RWA: string;
  BGD: string;
  AUS: string;
  AUT: string;
  PSE: string;
  LKA: string;
  GAB: string;
  ZWE: string;
  BGR: string;
  SYR: string;
  CZE: string;
  NOR: string;
  UMI: string;
  CIV: string;
  MMR: string;
  TKL: string;
  KIR: string;
  TKM: string;
  GRD: string;
  GRC: string;
  PCN: string;
  HTI: string;
  GRL: string;
  YEM: string;
  AFG: string;
  MNE: string;
  MNG: string;
  NPL: string;
  BHS: string;
  BHR: string;
  MNP: string;
  GBR: string;
  SJM: string;
  DMA: string;
  TLS: string;
  BIH: string;
  HUN: string;
  AGO: string;
  WSM: string;
  FRA: string;
  MOZ: string;
  NAM: string;
  PER: string;
  DNK: string;
  GTM: string;
  FRO: string;
  VAT: string;
  SLB: string;
  SLE: string;
  NRU: string;
  AIA: string;
  GUF: string;
  SLV: string;
  GUM: string;
  FSM: string;
  DOM: string;
  CMR: string;
  GUY: string;
  AZE: string;
  MAC: string;
  GEO: string;
  TON: string;
  MAF: string;
  NCL: string;
  SMR: string;
  ERI: string;
  MAR: string;
  KNA: string;
  BLM: string;
  VCT: string;
  BLR: string;
  MRT: string;
  BLZ: string;
  PHL: string;
  COD: string;
  COG: string;
  ESH: string;
  PYF: string;
  URY: string;
  COK: string;
  COM: string;
  COL: string;
  USA: string;
  ESP: string;
  EST: string;
  BMU: string;
  MSR: string;
  ZMB: string;
  KOR: string;
  SOM: string;
  VUT: string;
  ALA: string;
  ECU: string;
  ALB: string;
  ETH: string;
  GGY: string;
  MCO: string;
  NER: string;
  LAO: string;
  VEN: string;
  GHA: string;
  CPV: string;
  MTQ: string;
  MDA: string;
  MDG: string;
  SPM: string;
  NFK: string;
  LBN: string;
  LBR: string;
  BOL: string;
  MDV: string;
  GIB: string;
  LBY: string;
  HKG: string;
  CAF: string;
  LSO: string;
  NGA: string;
  MUS: string;
  IMN: string;
  LCA: string;
  JOR: string;
  GIN: string;
  VGB: string;
  CAN: string;
  TCA: string;
  TCD: string;
  AND: string;
  ROU: string;
  CRI: string;
  IND: string;
  MEX: string;
  SRB: string;
  XKX: string;
  KAZ: string;
  SAU: string;
  ANT: string;
  JPN: string;
  LTU: string;
  TTO: string;
  PLW: string;
  HMD: string;
  MWI: string;
  SSD: string;
  NIC: string;
  CCK: string;
  FIN: string;
  TUN: string;
  LUX: string;
  UGA: string;
  IOT: string;
  BRA: string;
  TUR: string;
  BRB: string;
  TUV: string;
  DEU: string;
  EGY: string;
  LVA: string;
  JAM: string;
  NIU: string;
  ZAF: string;
  VIR: string;
  BRN: string;
  HND: string;
}

export class CountryCode {
  countryISOMapping: CountryCodes = {
    GBR: 'GB',
    IRL: 'IE',
    USA: 'US',
    DEU: 'DE',
    AFG: 'AF',
    ALA: 'AX',
    ALB: 'AL',
    DZA: 'DZ',
    ASM: 'AS',
    AND: 'AD',
    AGO: 'AO',
    AIA: 'AI',
    ATA: 'AQ',
    ATG: 'AG',
    ARG: 'AR',
    ARM: 'AM',
    ABW: 'AW',
    AUS: 'AU',
    AUT: 'AT',
    AZE: 'AZ',
    BES: 'BQ',
    BHS: 'BS',
    BHR: 'BH',
    BGD: 'BD',
    BRB: 'BB',
    BLR: 'BY',
    BEL: 'BE',
    BLZ: 'BZ',
    BEN: 'BJ',
    BMU: 'BM',
    BTN: 'BT',
    BOL: 'BO',
    BIH: 'BA',
    BWA: 'BW',
    BVT: 'BV',
    BRA: 'BR',
    VGB: 'VG',
    IOT: 'IO',
    BRN: 'BN',
    BGR: 'BG',
    BFA: 'BF',
    BDI: 'BI',
    KHM: 'KH',
    CMR: 'CM',
    CAN: 'CA',
    CPV: 'CV',
    CYM: 'KY',
    CAF: 'CF',
    TCD: 'TD',
    CHL: 'CL',
    CHN: 'CN',
    HKG: 'HK',
    MAC: 'MO',
    CXR: 'CX',
    CCK: 'CC',
    COL: 'CO',
    COM: 'KM',
    COG: 'CG',
    COD: 'CD',
    COK: 'CK',
    CRI: 'CR',
    CIV: 'CI',
    HRV: 'HR',
    CUB: 'CU',
    CUW: 'CW',
    CYP: 'CY',
    CZE: 'CZ',
    DNK: 'DK',
    DJI: 'DJ',
    DMA: 'DM',
    DOM: 'DO',
    ECU: 'EC',
    EGY: 'EG',
    SLV: 'SV',
    GNQ: 'GQ',
    ERI: 'ER',
    EST: 'EE',
    ETH: 'ET',
    FLK: 'FK',
    FRO: 'FO',
    FJI: 'FJ',
    FIN: 'FI',
    FRA: 'FR',
    GUF: 'GF',
    PYF: 'PF',
    ATF: 'TF',
    GAB: 'GA',
    GMB: 'GM',
    GEO: 'GE',
    GHA: 'GH',
    GIB: 'GI',
    GRC: 'GR',
    GRL: 'GL',
    GRD: 'GD',
    GLP: 'GP',
    GUM: 'GU',
    GTM: 'GT',
    GGY: 'GG',
    GIN: 'GN',
    GNB: 'GW',
    GUY: 'GY',
    HTI: 'HT',
    HMD: 'HM',
    VAT: 'VA',
    HND: 'HN',
    HUN: 'HU',
    ISL: 'IS',
    IND: 'IN',
    IDN: 'ID',
    IRN: 'IR',
    IRQ: 'IQ',
    IMN: 'IM',
    ISR: 'IL',
    ITA: 'IT',
    JAM: 'JM',
    JPN: 'JP',
    JEY: 'JE',
    JOR: 'JO',
    KAZ: 'KZ',
    KEN: 'KE',
    KIR: 'KI',
    PRK: 'KP',
    KOR: 'KR',
    KWT: 'KW',
    KGZ: 'KG',
    LAO: 'LA',
    LVA: 'LV',
    LBN: 'LB',
    LSO: 'LS',
    LBR: 'LR',
    LBY: 'LY',
    LIE: 'LI',
    LTU: 'LT',
    LUX: 'LU',
    MKD: 'MK',
    MDG: 'MG',
    MWI: 'MW',
    MYS: 'MY',
    MDV: 'MV',
    MLI: 'ML',
    MLT: 'MT',
    MHL: 'MH',
    MTQ: 'MQ',
    MRT: 'MR',
    MUS: 'MU',
    MYT: 'YT',
    MEX: 'MX',
    FSM: 'FM',
    MDA: 'MD',
    MCO: 'MC',
    MNG: 'MN',
    MNE: 'ME',
    MSR: 'MS',
    MAR: 'MA',
    MOZ: 'MZ',
    MMR: 'MM',
    NAM: 'NA',
    NRU: 'NR',
    NPL: 'NP',
    NLD: 'NL',
    ANT: 'AN',
    NCL: 'NC',
    NZL: 'NZ',
    NIC: 'NI',
    NER: 'NE',
    NGA: 'NG',
    NIU: 'NU',
    NFK: 'NF',
    MNP: 'MP',
    NOR: 'NO',
    OMN: 'OM',
    PAK: 'PK',
    PLW: 'PW',
    PSE: 'PS',
    PAN: 'PA',
    PNG: 'PG',
    PRY: 'PY',
    PER: 'PE',
    PHL: 'PH',
    PCN: 'PN',
    POL: 'PL',
    PRT: 'PT',
    PRI: 'PR',
    QAT: 'QA',
    REU: 'RE',
    ROU: 'RO',
    RUS: 'RU',
    RWA: 'RW',
    BLM: 'BL',
    SHN: 'SH',
    KNA: 'KN',
    LCA: 'LC',
    MAF: 'MF',
    SPM: 'PM',
    VCT: 'VC',
    WSM: 'WS',
    SMR: 'SM',
    STP: 'ST',
    SAU: 'SA',
    SEN: 'SN',
    SRB: 'RS',
    SYC: 'SC',
    SLE: 'SL',
    SGP: 'SG',
    SVK: 'SK',
    SVN: 'SI',
    SLB: 'SB',
    SOM: 'SO',
    SXM: 'SX',
    ZAF: 'ZA',
    SGS: 'GS',
    SSD: 'SS',
    ESP: 'ES',
    LKA: 'LK',
    SDN: 'SD',
    SUR: 'SR',
    SJM: 'SJ',
    SWZ: 'SZ',
    SWE: 'SE',
    CHE: 'CH',
    SYR: 'SY',
    TWN: 'TW',
    TJK: 'TJ',
    TZA: 'TZ',
    THA: 'TH',
    TLS: 'TL',
    TGO: 'TG',
    TKL: 'TK',
    TON: 'TO',
    TTO: 'TT',
    TUN: 'TN',
    TUR: 'TR',
    TKM: 'TM',
    TCA: 'TC',
    TUV: 'TV',
    UGA: 'UG',
    UKR: 'UA',
    ARE: 'AE',
    UMI: 'UM',
    URY: 'UY',
    UZB: 'UZ',
    VUT: 'VU',
    VEN: 'VE',
    VNM: 'VN',
    VIR: 'VI',
    WLF: 'WF',
    ESH: 'EH',
    YEM: 'YE',
    ZMB: 'ZM',
    ZWE: 'ZW',
    XKX: 'XK',
  };


  public getCountryISO2(countryCode: keyof CountryCodes): string {
    return this.countryISOMapping[countryCode] ? this.countryISOMapping[countryCode].toLowerCase() : countryCode.toLowerCase();
  }

  public getCountryISO3(countryCode: string): string {
    return Object.keys(this.countryISOMapping)
      .find((key: string) => this.countryISOMapping[key as keyof CountryCodes] === countryCode);
  }

  public getCountryNameAndISO3Array(): Array<CountryItem> {
    const data = countryList.getData();
    return Object.values(this.countryISOMapping)
      .map((c: string) => {
        const found = data.find(d => d.code === c);
        if (!found) {
          return null;
        }
        return {
          name: found.name,
          code: this.getCountryISO3(found.code),
        };
      })
      .filter(item => !!item);
  }

  public getNameFromISO3Code(code: keyof CountryCodes): string {
    const ISO2Code = this.getCountryISO2(code);
    return (ISO2Code ? countryList.getName(ISO2Code) : null);
  }
}

