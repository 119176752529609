import { ListParams }           from './list-params.model';
import { ListParamModel }       from './list-param-model';
import { ListResponseMetadata } from '../api/list-response-metadata.model';

export class EmergencyLocationQueryParams extends ListParams implements ListParamModel<EmergencyLocationQueryParams> {
  id?: string[];
  search?: string;
  include?: string[] = [];

  static constructQueryString(searchParams: EmergencyLocationQueryParams): string {
    const params: EmergencyLocationQueryParams = { ...(searchParams || {}) as EmergencyLocationQueryParams };

    return ListParams.constructQueryString(params, {
      id:      'filter[identifier][]',
      search:  'filter[search]',
      include: 'include[]',
    });
  }

  constructParams?(metaData: ListResponseMetadata): EmergencyLocationQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.id) {
        this.id = metaData.filter.id;
      }
      if (metaData.filter.search) {
        this.search = metaData.filter.search;
      }
    }
    if (metaData.include) {
      this.include = metaData.include;
    }

    return this;
  }
}
