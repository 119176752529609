import { Component, OnDestroy }                                from '@angular/core';
import { Observable, Subject }                                 from 'rxjs';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldCheckType }                                      from '@enums/field-check-type.enum';
import { MicrosoftTeams }                                      from '@models/entity/microsoft-teams.model';
import { trackBy }                                             from '@util/trackBy';
import { MatDialogRef }                                        from '@angular/material/dialog';
import { Store }                                               from '@ngrx/store';
import { StoreState }                                          from '@redux/store';
import { selectServiceItems, selectServiceMessage }            from '@redux/service/service.selectors';
import { ServiceItem }                                         from '@models/entity/service-item.model';
import { filter, map, take, timeout }                          from 'rxjs/operators';
import { StatusItem }                                          from '@models/entity/status-item.model';
import { SyncNumbersRequestAction }                            from '@redux/service/service.actions';
import { Token }                                               from '@models/entity/token-state.model';
import { TokenStatus }                                         from '@enums/token-status.enum';
import { selectTokens }                                        from '@redux/access-broker/access-broker.selectors';

@Component({
  selector:    'ngx-sync-numbers-modal',
  templateUrl: './sync-numbers-modal.component.html',
  styleUrls:   ['./sync-numbers-modal.component.scss'],
})
export class SyncNumbersModalComponent implements OnDestroy {
  services$: Observable<MicrosoftTeams[]>;
  destroy        = new Subject<void>();
  destroy$       = this.destroy.asObservable();
  submitDisabled$: Observable<boolean>;
  trackBy        = trackBy<MicrosoftTeams>();
  FieldCheckType = FieldCheckType;
  formGroup: FormGroup<{ serviceId: FormControl<string> }>;
  tokens$: Observable<{ [id: string]: Token[] }>;

  constructor(private dialog: MatDialogRef<SyncNumbersModalComponent>,
              private store: Store<StoreState>) {

    this.services$ = this.store.select(selectServiceItems)
      .pipe(map(items => (items?.filter(item => ServiceItem.isMicrosoft(item)) || []) as MicrosoftTeams[]));
    this.tokens$   = this.store.select(selectTokens);
    this.formGroup = new FormGroup<{ serviceId: FormControl<string> }>({
      serviceId: new FormControl<string>(null, Validators.required),
    });
  }

  isInvalid(control: AbstractControl): boolean {
    return !control.valid && control.touched && control.value;
  }

  async submit(): Promise<void> {
    if (this.formGroup.invalid) {
      return;
    }
    this.store.dispatch(SyncNumbersRequestAction({ serviceId: this.formGroup.get('serviceId').value }));
    const message = await this.store.select(selectServiceMessage)
      .pipe(
        filter(message => !!message),
        timeout(15_000),
        take(1))
      .toPromise();
    if (message) {
      this.dialog.close();
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  getIsInactive(service: MicrosoftTeams): boolean {
    return !service || !StatusItem.isSuccess(service.provisionStatus.status);
  }

  getNeedsAuth(service: MicrosoftTeams, tokens: { [id: string]: Token[] }): boolean {
    return !tokens[service.id] || tokens[service.id].some(token => token.status !== TokenStatus.Active);
  }

}
