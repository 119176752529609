<ngx-modal-head header="{{inputData && inputData.title ? inputData.title : 'Set the value'}}"></ngx-modal-head>
<form data-cy="form" [formGroup]="formGroup" class="input-form" (ngSubmit)="submit()">
  <ngx-modal-content>
    <div class="input-field-container m-0">
      <label class="field-label" *ngIf="inputData && inputData.fieldLabel">{{inputData.fieldLabel}}</label>
      <input type="text"
             formControlName="input"
             data-cy="form-input"
             [maxlength]="inputData.maxLength ? inputData.maxLength : 64"
             name="input-field"
             autocomplete="on" />
    </div>
  </ngx-modal-content>
  <ngx-modal-actions>
    <button class="button primary" type="submit"
            [disabled]="!formGroup.valid">
      {{inputData && inputData.confirmText ? inputData.confirmText : 'Save'}}
    </button>
  </ngx-modal-actions>
</form>
