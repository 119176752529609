import { BaseRequest }  from '@redux/helpers/reducer.helper';
import { CountryCodes } from '@models/entity/country-code.model';

export class AssignLicenseGroupRequest extends BaseRequest {
  serviceId: string;
  userId: string;
  licenseGroupIds: string[];
  assignments: string[];
  unassignments: string[];
  usageLocation: keyof CountryCodes;
  confirm?: boolean;

  constructor(private data?: AssignLicenseGroupRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): { assignments: string[], unassignments: string[], usage_location: keyof CountryCodes } {
    return {
      assignments:    this.assignments,
      unassignments:  this.unassignments,
      usage_location: this.usageLocation,
    };
  }

}
