export class NumberTag {
  id: string;
  name: string;
  colour: string;
  metadata: {
    number_count: number;
  };

  static findNumberTagByName(name: string, allTags: NumberTag[]): NumberTag {
    if (!allTags) {
      return null;
    }
    const numberTag = allTags.find(t => t.name === name);
    if (!numberTag) {
      return {
        id:       null,
        name,
        colour:   '#0096FF',
        metadata: {
          number_count: 0,
        },
      };
    }
    if (numberTag.colour) {
      return numberTag || null;
    }
    return { ...numberTag, colour: '#0096FF' };
  }

  constructor(private data?: NumberTag) {
    Object.assign(this, { ...this.data });
    delete this['data'];
  }
}
