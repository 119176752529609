import { NumberServiceMetadataRaw } from '@models/entity/number-service-metadata-raw.model';

export class NumberServiceMetadata {
  parentAssignedCount: number;
  numberAssignedCount: number;
  serviceId: string;
  isDeletable: boolean;

  constructor(private data?: NumberServiceMetadata) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: NumberServiceMetadataRaw): NumberServiceMetadata {
    this.parentAssignedCount = data.parent_assigned_count;
    this.numberAssignedCount = data.number_assigned_count;
    this.serviceId           = data.service_id;
    this.isDeletable         = data.is_deletable;

    return this;
  }
}
