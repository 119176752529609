import * as SubscriptionAction                      from './subscription.actions';
import * as AuthAction                              from '../auth/auth.actions';
import {
  CalculatePackageCostResponseRaw,
  SubscriptionPackageData,
  SubscriptionPackageFeature,
  SubscriptionProduct,
}                                                   from '@models/entity/subscription.model';
import { BaseState, requestReduce, responseReduce } from '../helpers/reducer.helper';
import { Action, createReducer, on }                from '@ngrx/store';
import {
  CALCULATE_PACKAGE_COST_REQUEST,
  CHANGE_PACKAGE_REQUEST,
  FETCH_ACTIVE_LICENSE_REQUEST,
  FETCH_ACTIVE_PACKAGE_REQUEST,
  FETCH_PACKAGE_CAPABILITIES_REQUEST,
  FETCH_PACKAGE_LIST_REQUEST,
}                                                   from './subscription.types';
import * as UIAction                                from '../ui/ui.actions';
import { createEntityAdapter, EntityAdapter }       from '@ngrx/entity';
import { PackageCapabilities }                      from '@models/entity/package-capabilities.model';

interface StateTask {
  id: string;
}

export interface SubscriptionState extends BaseState<SubscriptionProduct> {
  pendingTasks: Array<StateTask>;
  activePackage: SubscriptionPackageData;
  activeLicenses: SubscriptionPackageData[];
  availablePackageList: Array<SubscriptionProduct>;
  packageFeatureList: Array<SubscriptionPackageFeature>;
  packageUpgraded: boolean;
  packageChangeCost: CalculatePackageCostResponseRaw;
  packageCapabilities: PackageCapabilities;
}

export function selectServiceId(a: SubscriptionProduct): string {
  return a.id;
}

export const adapter: EntityAdapter<SubscriptionProduct> = createEntityAdapter<SubscriptionProduct>({
  selectId: selectServiceId,
});

const defaultState: SubscriptionState = adapter.getInitialState({
  error:                null,
  message:              null,
  pending:              false,
  pendingTasks:         [],
  activePackage:        null,
  availablePackageList: [],
  packageFeatureList:   [],
  packageUpgraded:      false,
  packageChangeCost:    null,
  activeLicenses:       null,
  packageCapabilities:  null,
});

const _subscriptionReducer = createReducer(defaultState,
  on(SubscriptionAction.FetchActivePackageRequestAction, requestReduce),
  on(SubscriptionAction.FetchActivePackageResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_ACTIVE_PACKAGE_REQUEST, res => ({
      activePackage:   res.package,
      packageUpgraded: false,
    }))),
  on(SubscriptionAction.FetchActiveLicenseRequestAction, requestReduce),
  on(SubscriptionAction.FetchActiveLicenseResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_ACTIVE_LICENSE_REQUEST, res => ({
      activeLicenses: res.licenses,
    }))),
  on(SubscriptionAction.FetchPackageCapabilitiesRequestAction, requestReduce),
  on(SubscriptionAction.FetchPackageCapabilitiesResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_PACKAGE_CAPABILITIES_REQUEST, res => ({
      packageCapabilities: res.data,
    }))),
  on(SubscriptionAction.CalculatePackageCostRequestAction, requestReduce),
  on(SubscriptionAction.CalculatePackageCostResponseAction, (state, action) =>
    responseReduce(state, action, CALCULATE_PACKAGE_COST_REQUEST, res => ({
      packageChangeCost: res.cost,
    }))),
  on(SubscriptionAction.FetchPackageListRequestAction, requestReduce),
  on(SubscriptionAction.FetchPackageListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_PACKAGE_LIST_REQUEST, res => ({
      availablePackageList: res.productList || [],
      packageFeatureList:   res.featureList || [],
    }))),
  on(SubscriptionAction.ChangePackageRequestAction, requestReduce),
  on(SubscriptionAction.ChangePackageResponseAction, (state, action) =>
    responseReduce(state, action, CHANGE_PACKAGE_REQUEST, res => ({
      activePackage:   res.error ? state.activePackage : res.package,
      packageUpgraded: !res.error,
    }))),
  on(SubscriptionAction.ResetPackageChangeFlagAction, (state) => ({
    ...state,
    packageUpgraded: false,
  })),
  on(UIAction.DismissErrorAction, (state) =>
    ({ ...state, error: null })),
  on(UIAction.DismissMessageAction, (state) =>
    ({ ...state, message: null })),
  on(AuthAction.LogoutAction, () => ({
    ...defaultState,
  })),
);


export function subscriptionReducer(state: SubscriptionState, action: Action): SubscriptionState {
  return _subscriptionReducer(state, action);
}
