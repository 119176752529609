import { Component, Inject }             from '@angular/core';
import { FormControl, FormGroup }        from '@angular/forms';
import { Observable }                    from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MicrosoftTeamsSettings }        from '@models/entity/microsoft-teams-settings.model';
import { AuthScope }                     from '@enums/auth-scope.enum';
import { ScopeService }                  from '@services/scope.service';
import { map }                           from 'rxjs/operators';
import { ProvisioningSettingsForm }      from '@models/form/provisioning-settings-form.model';

@Component({
  selector:    'ngx-provisioning-settings-modal',
  templateUrl: './provisioning-settings-modal.component.html',
  styleUrls:   ['./provisioning-settings-modal.component.scss'],
})
export class ProvisioningSettingsModalComponent {
  formGroup: FormGroup<ProvisioningSettingsForm>;
  hasCompanyConfigurationWrite$: Observable<boolean>;
  saveTooltip$: Observable<string>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { settings: MicrosoftTeamsSettings },
              private matDialog: MatDialogRef<ProvisioningSettingsForm>,
              private scopeService: ScopeService) {
    this.formGroup                     = new FormGroup<ProvisioningSettingsForm>(
      {
        autoProvision: new FormControl<boolean>(this.data.settings?.sync?.autoProvision),
      },
    );
    this.hasCompanyConfigurationWrite$ = this.scopeService.hasScopes$(AuthScope.CompanyConfigurationWrite);

    this.saveTooltip$ = this.hasCompanyConfigurationWrite$.pipe(map(hasWrite => {
      return !hasWrite && 'You do not have the required access to save provisioning settings. Please contact your systems administrator for more information.';
    }));

  }

  done(): void {
    this.matDialog.close(new MicrosoftTeamsSettings().fromFormData({
      fullSync:           this.data.settings.sync.fullSync,
      includeUnavailable: this.data.settings.sync.includeUnavailable,
      magicExtension:     this.data.settings.configure.magicExtension,
      autoProvision:      this.data.settings.sync.autoProvision,
      mode:               this.data.settings.sync.mode,
      inboundAssignment:  this.data.settings.configure.magicExtension,
      ...this.formGroup.value,
    }));
  }

}
