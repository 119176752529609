import { Injectable, OnDestroy }         from '@angular/core';
import { Store }                         from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  concatMap,
  debounceTime,
  defaultIfEmpty,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  throttleTime,
  timeout,
  withLatestFrom,
}                                        from 'rxjs/operators';

import { ServiceService } from '@services/service.service';

import * as ActionTypes from './service.actions';
import {
  AssignCallQueueGroupRequestAction,
  AssignCallQueueGroupResponseAction,
  AssignLicenseGroupRequestAction,
  AssignLicenseGroupResponseAction,
  AssignServiceUserNumberRequestAction,
  AssignTeamGroupRequestAction,
  AssignTeamGroupResponseAction,
  ConfigureServiceRequestAction,
  ConfigureServiceResponseAction,
  DeleteAutomationRequestAction,
  DeleteAutomationResponseAction,
  DeleteCallingProfileRequestAction,
  DeleteCallingProfileResponseAction,
  DeleteServiceRequestAction,
  DeleteServiceResponseAction,
  ExportServiceUsersRequestAction,
  ExportServiceUsersResponseAction,
  FetchAutomationListRequestAction,
  FetchAutomationListResponseAction,
  FetchAutomationNameAvailabilityRequestAction,
  FetchAutomationRequestAction,
  FetchAutomationResponseAction,
  FetchAvailableCarriersRequestAction,
  FetchAvailableCarriersResponseAction,
  FetchCallingProfileRequestAction,
  FetchCallingProfileResponseAction,
  FetchCallingProfilesRequestAction,
  FetchCallingProfilesResponseAction,
  FetchCarrierListRequestAction,
  FetchCarrierListResponseAction,
  FetchEmergencyLocationListRequestAction,
  FetchEmergencyLocationListResponseAction,
  FetchEmergencyLocationRequestAction,
  FetchEmergencyLocationResponseAction,
  FetchGatewayStatusEventsRequestAction,
  FetchGatewayStatusEventsResponseAction,
  FetchPersonaNameAvailabilityRequestAction,
  FetchPolicyListRequestAction,
  FetchPolicyListResponseAction,
  FetchProvisioningServicesRequestAction,
  FetchProvisioningServicesResponseAction,
  FetchPSTNServiceUuidRequestAction,
  FetchPSTNServiceUuidResponseAction,
  FetchServiceCountsRequestAction,
  FetchServiceListRequestAction,
  FetchServiceRequestAction,
  FetchServiceSchemaRequestAction,
  FetchServiceSchemaResponseAction,
  FetchServiceUserCLIAvailabilityRequestAction,
  FetchServiceUserCLIAvailabilityResponseAction,
  FetchServiceUserCountsByVoiceTypeRequestAction,
  FetchServiceUserCountsByVoiceTypeResponseAction,
  FetchServiceUserListRequestAction,
  FetchServiceUserRequestAction,
  FetchServiceUserResponseAction,
  MigrateProfilesRequestAction,
  MigrateProfilesResponseAction,
  PatchAutomationRequestAction,
  PatchAutomationResponseAction,
  PatchServiceRequestAction,
  PatchServiceResponseAction,
  PostAutomationRequestAction,
  PostAutomationResponseAction,
  PostServiceRequestAction,
  PostServiceResponseAction,
  PromptServiceUserAction,
  PutCallingProfileRequestAction,
  PutCallingProfileResponseAction,
  ReauthRequestAction,
  SearchCallingProfilesRequestAction,
  SearchCallingProfilesResponseAction,
  SendCustomCarrierRequestAction,
  SendCustomCarrierResponseAction,
  SyncAdGroupsRequestAction,
  SyncAdGroupsResponseAction,
  SyncAllMicrosoftAssetsRequestAction,
  SyncAllMicrosoftAssetsResponseAction,
  SyncCallQueuesRequestAction,
  SyncCallQueuesResponseAction,
  SyncDomainsRequestAction,
  SyncDomainsResponseAction,
  SyncLicensesRequestAction,
  SyncLicensesResponseAction,
  SyncNumbersRequestAction,
  SyncNumbersResponseAction,
  SyncPoliciesRequestAction,
  SyncPoliciesResponseAction,
  SyncServiceUserListRequestAction,
  SyncTeamsRequestAction,
  SyncTeamsResponseAction,
}                       from './service.actions';

import { StoreState }                                                    from '../store';
import { forkJoin, from, interval, Observable, of, Subject, timer, zip } from 'rxjs';
import { Location as AngularLocation }                                   from '@angular/common';
import { SipTrunkService }                                               from '@services/sip-trunk.service';
import { WebexService }                                                  from '@services/webex.service';
import { MatDialog, MatDialogRef }                                       from '@angular/material/dialog';
import { ServiceItem }                                                   from '@models/entity/service-item.model';
import {
  DeleteServiceRequest,
}                                                                        from '@models/api/delete-service-request.model';
import {
  DeleteServiceResponse,
}                                                                        from '@models/api/delete-service-response.model';
import { ConfirmModalData }                                              from '@models/ui/confirm-modal-data.model';
import { MicrosoftTeamsService }                                         from '@services/microsoft-teams.service';
import { ServiceActionService }                                          from '@services/service-action.service';
import { CONFIGURE_SERVICE_REQUEST, CONFIGURE_SERVICE_RESPONSE }         from './service.types';
import {
  selectAutomationQueryParams,
  selectCallingProfileParams,
  selectCallingProfiles,
  selectCarriersQueryParams,
  selectService,
  selectServiceItem,
  selectServiceItems,
  selectServiceUser,
  selectServiceUserList,
  selectServiceUserQueryParams,
}                                                                        from './service.selectors';
import { ServiceType }                                                   from '@enums/service-type.enum';
import { MicrosoftToken }                                                from '@enums/microsoft-token.enum';
import { AuthoriseRedirectRequest }                                      from '../access-broker/access-broker.actions';
import { SipPhoneService }                                               from '@services/sip-phone.service';
import { withThrottle }                                                  from '@rxjs/action-throttle.operator';
import { Alert }                                                         from '@models/entity/alert.model';
import { PatchServiceRequest }                                           from '@models/api/patch-service-request.model';
import { ServiceState }                                                  from '@redux/service/service.reducer';
import { WebexCalling }                                                  from '@models/entity/webex-calling.model';
import { SIPPhone }                                                      from '@models/entity/sip-phone.model';
import { StatusItem }                                                    from '@models/entity/status-item.model';
import { TokenStatus }                                                   from '@enums/token-status.enum';
import {
  selectTokens,
}                                                                        from '@redux/access-broker/access-broker.selectors';
import { MicrosoftTeams }                                                from '@models/entity/microsoft-teams.model';
import { SIPTrunk }                                                      from '@models/entity/sip-trunk.model';
import { PostServiceResponse }                                           from '@models/api/post-service-response.model';
import { PostServiceRequest }                                            from '@models/api/post-service-request.model';
import { Token }                                                         from '@models/entity/token-state.model';
import {
  FetchServiceListResponse,
}                                                                        from '@models/api/fetch-service-list-response.model';
import {
  ConfigureServiceRequest,
}                                                                        from '@models/api/configure-service-request.model';
import {
  ConfigureServiceResponse,
}                                                                        from '@models/api/configure-service-response.model';
import { CallForwardingService }                                         from '@services/call-forwarding.service';
import {
  FetchServiceUserListRequest,
}                                                                        from '@models/api/fetch-service-user-list-request.model';
import {
  FetchServiceUserListResponse,
}                                                                        from '@models/api/fetch-service-user-list-response.model';
import {
  AssignServiceUserNumberRequest,
}                                                                        from '@models/api/assign-service-user-number-request.model';
import {
  SyncServiceUserListResponse,
}                                                                        from '@models/api/sync-service-user-list-response.model';
import {
  SyncServiceUserListRequest,
}                                                                        from '@models/api/sync-service-user-list-request.model';
import {
  FetchServiceCountsResponse,
}                                                                        from '@models/api/fetch-service-counts-response.model';
import {
  MicrosoftTeamsUser,
}                                                                        from '@models/entity/microsoft-teams-user.model';
import {
  FetchServiceListRequest,
}                                                                        from '@models/api/fetch-service-list-request.model';
import {
  AssignServiceUserNumberResponse,
}                                                                        from '@models/api/assign-service-user-number-response.model';
import { CallForwarding }                                                from '@models/entity/call-forwarding.model';
import { ServiceQueryParams }                                            from '@models/form/service-query-params.model';
import { FetchServiceRequest }                                           from '@models/api/fetch-service-request.model';
import {
  FetchServiceResponse,
}                                                                        from '@models/api/fetch-service-response.model';
import {
  ConfirmModalComponent,
}                                                                        from '@dialog/general/confirm-modal/confirm-modal.component';
import { RouterService }                                                 from '@services/router.service';
import { withScopes }                                                    from '@rxjs/with-scopes.operator';
import { selectUserScopes }                                              from '@redux/auth/auth.selectors';
import { AuthScope }                                                     from '@enums/auth-scope.enum';
import { ServiceQueryService }                                           from '@services/service-query.service';
import {
  FetchAvailableCarriersRequest,
}                                                                        from '@models/api/fetch-available-carriers-request.model';
import {
  FetchAvailableCarriersResponse,
}                                                                        from '@models/api/fetch-available-carriers-response.model';
import { CarrierService }                                                from '@services/carrier.service';
import { ServiceCarrier }                                                from '@models/entity/carrier-service.model';
import { NumberItem }                                                    from '@models/entity/number-item.model';
import { NumberService }                                                 from '@services/number.service';
import {
  PatchServiceResponse,
}                                                                        from '@models/api/patch-service-response.model';
import {
  SendCustomCarrierRequest,
}                                                                        from '@models/api/send-custom-carrier-request.model';
import {
  SendCustomCarrierResponse,
}                                                                        from '@models/api/send-custom-carrier-response.model';
import { selectNumbersServiceMetadata }                                  from '@redux/number/number.selectors';
import {
  FetchGatewayStatusEventsRequest,
}                                                                        from '@models/api/fetch-gateway-status-events-request.model';
import {
  FetchGatewayStatusEventsResponse,
}                                                                        from '@models/api/fetch-gateway-status-events-response.model';
import {
  FetchCallingProfilesRequest,
}                                                                        from '@models/api/fetch-calling-profiles-request.model';
import {
  FetchCallingProfilesResponse,
}                                                                        from '@models/api/fetch-calling-profiles-response.model';
import {
  PutCallingProfileRequest,
}                                                                        from '@models/api/put-calling-profile-request.model';
import {
  PutCallingProfileResponse,
}                                                                        from '@models/api/put-calling-profile-response.model';
import {
  DeleteCallingProfileRequest,
}                                                                        from '@models/api/delete-calling-profile-request.model';
import {
  DeleteCallingProfileResponse,
}                                                                        from '@models/api/delete-calling-profile-response.model';
import {
  FetchPolicyListResponse,
}                                                                        from '@models/api/fetch-policy-list-response.model';
import {
  SyncPoliciesResponse,
}                                                                        from '@models/api/sync-policies-response.model';
import {
  FetchPolicyListRequest,
}                                                                        from '@models/api/fetch-policy-list-request.model';
import { SyncPoliciesRequest }                                           from '@models/api/sync-policies-request.model';
import {
  DeleteProfileModalComponent,
}                                                                        from '@dialog/delete-profile-modal/delete-profile-modal.component';
import { CallingProfile }                                                from '@models/entity/calling-profile.model';
import {
  TeamsUserQueryParams,
}                                                                        from '@models/form/teams-user-query-params.model';
import {
  FetchPersonaNameAvailabilityRequest,
}                                                                        from '@models/api/fetch-persona-name-availability-request.model';
import {
  FetchPersonaNameAvailabilityResponse,
}                                                                        from '@models/api/fetch-persona-name-availability-response.model';
import {
  MigrateProfilesRequest,
}                                                                        from '@models/api/migrate-profiles-request.model';
import {
  MigrateProfilesResponse,
}                                                                        from '@models/api/migrate-profiles.response.model';
import {
  CallingProfileQueryParams,
}                                                                        from '@models/form/calling-profile-query-params.model';
import {
  FetchServiceSchemaRequest,
}                                                                        from '@models/api/fetch-service-schema-request.model';
import {
  FetchServiceSchemaResponse,
}                                                                        from '@models/api/fetch-service-schema-response.model';
import {
  PostAutomationRequest,
}                                                                        from '@models/api/post-automation-request.model';
import {
  PostAutomationResponse,
}                                                                        from '@models/api/post-automation-response.model';
import { Automation }                                                    from '@models/entity/automation.model';
import {
  PatchAutomationRequest,
}                                                                        from '@models/api/patch-automation-request.model';
import {
  PatchAutomationResponse,
}                                                                        from '@models/api/patch-automation-response.model';
import {
  FetchAutomationListRequest,
}                                                                        from '@models/api/fetch-automation-list-request.model';
import {
  AutomationQueryParams,
}                                                                        from '@models/form/automation-query-params.model';
import {
  FetchAutomationListResponse,
}                                                                        from '@models/api/fetch-automation-list-response.model';
import {
  FetchAutomationRequest,
}                                                                        from '@models/api/fetch-automation-request.model';
import {
  FetchAutomationResponse,
}                                                                        from '@models/api/fetch-automation-response.model';
import {
  DeleteAutomationRequest,
}                                                                        from '@models/api/delete-automation-request.model';
import {
  DeleteAutomationResponse,
}                                                                        from '@models/api/delete-automation-response.model';
import {
  AssignLicenseGroupRequest,
}                                                                        from '@models/api/assign-license-group-request.model';
import {
  AssignLicenseGroupResponse,
}                                                                        from '@models/api/assign-license-group-response.model';
import { LicenseGroup }                                                  from '@models/entity/license-group.model';
import {
  FetchLicenseGroupResponse,
}                                                                        from '@models/api/fetch-license-group-response.model';
import { SyncNumbersResponse }                                           from '@models/api/sync-numbers-response.model';
import { SyncNumbersRequest }                                            from '@models/api/sync-numbers-request.model';
import { CarrierQueryService }                                           from '@services/carrier-query.service';
import { ServiceUserQueryService }                                       from '@services/service-user-query.service';
import {
  AssignTeamGroupResponse,
}                                                                        from '@models/api/service/assign-team-group-response.model';
import {
  AssignTeamGroupRequest,
}                                                                        from '@models/api/service/assign-team-group-request.model';
import { TeamGroup }                                                     from '@models/entity/teams-group.model';
import {
  FetchCallingProfileRequest,
}                                                                        from '@models/api/service/fetch-calling-profile-request.model';
import {
  FetchCallingProfileResponse,
}                                                                        from '@models/api/service/fetch-calling-profile-response.model';
import {
  SyncTeamsResponse,
}                                                                        from '@models/api/service/sync-teams-response.model';
import {
  SyncLicensesResponse,
}                                                                        from '@models/api/microsoft-teams/sync-licenses-response.model';
import {
  SyncLicensesRequest,
}                                                                        from '@models/api/microsoft-teams/sync-licenses-request.model';
import {
  FetchAdGroupsRequestAction,
  FetchCallQueuesRequestAction,
  FetchLicensesRequestAction,
  FetchTeamsRequestAction,
}                                                                        from '@redux/microsoft-teams/microsoft-teams.actions';
import {
  SyncTeamsRequest,
}                                                                        from '@models/api/service/sync-teams-request.model';
import {
  SyncCallQueuesRequest,
}                                                                        from '@models/api/service/sync-call-queues-request.model';
import {
  SyncCallQueuesResponse,
}                                                                        from '@models/api/service/sync-call-queues-response.model';
import {
  AssignCallQueueGroupRequest,
}                                                                        from '@models/api/service/assign-call-queue-group-request.model';
import {
  AssignCallQueueGroupResponse,
}                                                                        from '@models/api/service/assign-call-queue-group-response.model';
import { CallQueueGroup }                                                from '@models/entity/call-queue-group.model';
import {
  FetchCallQueueGroupListResponse,
}                                                                        from '@models/api/microsoft-teams/fetch-call-queue-group-list-response.model';
import {
  FetchLicenseGroupListResponse,
}                                                                        from '@models/api/fetch-license-group-list-response.model';
import {
  FetchTeamGroupListResponse,
}                                                                        from '@models/api/microsoft-teams/fetch-teams-group-list-response.model';
import {
  FetchServiceUserCountsByVoiceTypeRequest,
}                                                                        from '@models/api/service/fetch-service-user-counts-by-voice-type-request.model';
import {
  FetchServiceUserCountsByVoiceTypeResponse,
}                                                                        from '@models/api/service/fetch-service-user-counts-by-voice-type-response.model';
import {
  FetchServiceUserRequest,
}                                                                        from '@models/api/service/fetch-service-user-request.model';
import {
  FetchServiceUserResponse,
}                                                                        from '@models/api/service/fetch-service-user-response.model';
import {
  FetchAutomationNameAvailabilityRequest,
}                                                                        from '@models/api/service/fetch-automation-name-availability-request.model';
import {
  FetchAutomationNameAvailabilityResponse,
}                                                                        from '@models/api/service/fetch-automation-name-availability-response.model';
import { FetchTaskCountRequestAction, FetchTaskRequestAction }           from '@redux/audit/audit.actions';
import {
  FetchServiceUserCliAvailabilityRequest,
}                                                                        from '@models/api/service/fetch-service-user-cli-availability-request.model';
import {
  FetchServiceUserCliAvailabilityResponse,
}                                                                        from '@models/api/service/fetch-service-user-cli-availability-response.model';
import { ExpressionService }                                             from '@services/expression.service';
import { ADGroup }                                                       from '@models/entity/ad-group.model';
import {
  FetchAdGroupsResponse,
}                                                                        from '@models/api/service/fetch-ad-groups-response.model';
import {
  SyncAdGroupsRequest,
}                                                                        from '@models/api/service/sync-ad-groups-request.model';
import {
  SyncAdGroupsResponse,
}                                                                        from '@models/api/service/sync-ad-groups-response.model';
import {
  SyncAllMicrosoftAssetsRequest,
}                                                                        from '@models/api/service/sync-all-microsoft-assets-request.model';
import {
  SyncAllMicrosoftAssetsResponse,
}                                                                        from '@models/api/service/sync-all-microsoft-assets-response.model';
import {
  SyncDomainsRequest,
}                                                                        from '@models/api/service/sync-domains-request.model';
import { FetchCompanyDomainListRequestAction }                           from '@redux/auth/auth.actions';
import {
  SyncDomainsResponse,
}                                                                        from '@models/api/service/sync-domains-response.model';
import {
  NumberServiceMetadata,
}                                                                        from '@models/entity/number-service-metadata.model';
import { FetchNumbersServiceMetadataRequestAction }                      from '@redux/number/number.actions';
import {
  ExportServiceUsersResponse,
}                                                                        from '@models/api/service/export-service-users-response.model';
import {
  ExportServiceUsersRequest,
}                                                                        from '@models/api/service/export-service-users-request.model';
import {
  FetchEmergencyLocationListRequest,
}                                                                        from '@models/api/service/fetch-emergency-location-list-request.model';
import {
  FetchEmergencyLocationListResponse,
}                                                                        from '@models/api/service/fetch-emergency-location-list-response.model';
import {
  FetchEmergencyLocationRequest,
}                                                                        from '@models/api/service/fetch-emergency-location-request.model';
import {
  FetchEmergencyLocationResponse,
}                                                                        from '@models/api/service/fetch-emergency-location-response.model';

@Injectable()
export class ServiceEffects implements OnDestroy {

  killServiceListPoll  = new Subject<void>();
  killServiceListPoll$ = this.killServiceListPoll.asObservable();
  killCarrierListPoll  = new Subject<void>();
  killCarrierListPoll$ = this.killCarrierListPoll.asObservable();
  killServicePoll      = new Subject<void>();
  killServicePoll$     = this.killServicePoll.asObservable();
  killServiceUserPoll  = new Subject<void>();
  killServiceUserPoll$ = this.killServiceUserPoll.asObservable();
  killSyncPoll         = new Subject<void>();
  killSyncPoll$        = this.killSyncPoll.asObservable();
  killNumberSyncPoll   = new Subject<void>();
  killNumberSyncPoll$  = this.killNumberSyncPoll.asObservable();
  destroy              = new Subject<void>();
  destroy$             = this.destroy.asObservable();

  private numberCache: { [id: string]: NumberItem } = {};

  private static shouldPollServiceItems(...serviceItems: Array<ServiceItem>): boolean {
    if (!serviceItems?.length || serviceItems?.every(item => !item)) {
      return false;
    }

    return serviceItems?.some(item => {

      const isConfiguringProcessing = item.isProvisioning() || (item as MicrosoftTeams).isProcessing;
      const waitingForInitialSync   = ServiceItem.isMicrosoft(item) && !item.lastUserSyncDate && StatusItem.isSuccess(item.provisionStatus.status);
      const isDeleting              = StatusItem.isDeleted(item.provisionStatus.status);

      return isConfiguringProcessing || waitingForInitialSync || isDeleting;
    });
  }

  private static shouldPollServiceUserItems(serviceUserItems: Array<MicrosoftTeamsUser>): boolean {
    return serviceUserItems?.some(user => user.isProcessing);
  }

  constructor(
    private actions$: Actions,
    private serviceService: ServiceService,
    private store: Store<StoreState>,
    private location: AngularLocation,
    private sipTrunkService: SipTrunkService,
    private sipPhoneService: SipPhoneService,
    private microsoftTeamsService: MicrosoftTeamsService,
    private dialog: MatDialog,
    private webexService: WebexService,
    private callForwardingService: CallForwardingService,
    private serviceActionService: ServiceActionService,
    private serviceQueryService: ServiceQueryService,
    private carrierQueryService: CarrierQueryService,
    private serviceUserQueryService: ServiceUserQueryService,
    private router: RouterService,
    private carrierService: CarrierService,
    private numberService: NumberService,
    private expressionService: ExpressionService,
  ) {
    this.pollProvisioningServices();
  }

  fetchCarriersList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCarrierListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ServiceRead]),
    withLatestFrom(this.store.select(selectCarriersQueryParams)),
    tap(() => this.killCarrierListPoll.next()),
    switchMap(([req, storedQuery]: [FetchServiceListRequest, ServiceQueryParams]) => {
      const queryParams = { ...(req.queryParams || storedQuery), type: ServiceType.Carrier };

      return this.serviceService.fetchServiceList$({
        ...req,
        queryParams,
      })
        .pipe(
          switchMap((res: FetchServiceListResponse) => {
            const serviceItems$: Observable<ServiceItem>[] = res.serviceItems?.map(s => this.resolveServiceData$(s)) || [];

            return zip(...serviceItems$)
              .pipe(
                defaultIfEmpty([]),
                map(serviceItems => {
                  return {
                    ...res,
                    serviceItems,
                  };
                }));
          }),
          tap((res: FetchServiceListResponse) => {
            if (ServiceEffects.shouldPollServiceItems(...(res?.serviceItems as ServiceItem[]) || [])) {
              return this.pollCarrierServiceItems(queryParams);
            }
          }));
    }),
    map((res: FetchServiceListResponse) => FetchCarrierListResponseAction(res)),
  ));

  fetchAvailableCarriers$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAvailableCarriersRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ServiceRead]),
    switchMap((req: FetchAvailableCarriersRequest) => this.carrierService.fetchAvailableCarriers$(req)),
    map((res: FetchAvailableCarriersResponse) => FetchAvailableCarriersResponseAction(res)),
  ));

  fetchPSTNServiceUuid$ = createEffect(() => this.actions$.pipe(
    ofType(FetchPSTNServiceUuidRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ServiceRead]),
    switchMap(() => this.serviceService.fetchServiceList$({
      queryParams: {
        pageSize:   10,
        pageNumber: 1,
        type:       ServiceType.CallForwarding,
      },
    })),
    map((res: FetchServiceListResponse) => FetchPSTNServiceUuidResponseAction(res)),
  ));

  fetchServiceCounts$ = createEffect(() => this.actions$.pipe(
    ofType(FetchServiceCountsRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ServiceRead]),
    withThrottle(500, { leading: false, trailing: true }),
    switchMap(() =>
      this.serviceService.fetchServiceCounts$()
        .pipe(
          map((res: FetchServiceCountsResponse) =>
            ActionTypes.FetchServiceCountsResponseAction(res),
          ),
        ),
    ),
  ));

  fetchService$ = createEffect(() => this.actions$.pipe(
    ofType(FetchServiceRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ServiceRead]),
    tap(() => this.killServicePoll.next()),
    switchMap((req: FetchServiceRequest) =>
      this.serviceService.fetchService$(req)
        .pipe(
          switchMap((res: FetchServiceResponse) => {
            return this.resolveServiceData$(res.serviceItem)
              .pipe(map(serviceItem => {
                return {
                  ...res,
                  serviceItem,
                };
              }));
          }),
          tap((res: FetchServiceResponse) => {
            if (ServiceEffects.shouldPollServiceItems(res?.serviceItem)) {
              return this.pollServiceItem(req.serviceId);
            }
          }),
          map((res: FetchServiceResponse) =>
            ActionTypes.FetchServiceResponseAction(res),
          ),
        ),
    ),
  ));

  fetchServiceList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchServiceListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ServiceRead]),
    withThrottle(),
    withLatestFrom(this.store.select(selectService)),
    filter(([_, serviceState]) =>
      !serviceState.pendingTasks.some(task => [CONFIGURE_SERVICE_REQUEST, CONFIGURE_SERVICE_RESPONSE].includes(task.id))),
    tap(() => this.killServiceListPoll.next()),
    switchMap(([req, state]: [FetchServiceListRequest, ServiceState]) => {
        const queryParams = req.queryParams || state.serviceQueryParams; // if queryParams passed are null then use previous query
        return this.serviceService.fetchServiceList$({ ...req, queryParams })
          .pipe(
            switchMap((res: FetchServiceListResponse) => {
              const serviceItems$: Observable<ServiceItem>[] = res.serviceItems?.map(s => this.resolveServiceData$(s)) || [];

              return zip(...serviceItems$)
                .pipe(
                  defaultIfEmpty([]),
                  map(serviceItems => {
                    return {
                      ...res,
                      serviceItems,
                    };
                  }));
            }),
            tap(res => {
              if (ServiceEffects.shouldPollServiceItems(...res?.serviceItems || [])) {
                return this.pollServiceItems(queryParams);
              }
            }),
            map((res: FetchServiceListResponse) =>
              ActionTypes.FetchServiceListResponseAction(res),
            ),
          );
      },
    ),
  ));

  deleteService$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteServiceRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ServiceDelete]),
    withLatestFrom(this.store.select(selectNumbersServiceMetadata)),
    switchMap(
      ([req, counts]: [DeleteServiceRequest, NumberServiceMetadata[]]) => {
        const deleteService = (force?: boolean) => {
          let modalText = `<p>You are about to delete a service. Click 'delete' to continue with the deletion.</p>`;
          if (req.serviceType === ServiceType.MicrosoftTeams) {
            modalText = `
<p>
    You are about to delete the Microsoft Teams integration. 
    Please note that this will unassign all phone numbers from users in your Microsoft Teams tenant relating to the Callroute 
    service as well as deleting all settings relating to Callroute.
</p>`;
          }
          return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
            data:       {
              title:          `Delete ${ req.serviceName }`,
              content:        modalText,
              confirmBtnText: 'Delete',
              showCancel:     true,
              typeConfirm:    true,
            },
            panelClass: 'cr-dialog',
            maxWidth:   '640px',
            maxHeight:  'calc(100vh - 140px)',
            width:      '100%',
          })
            .afterClosed()
            .pipe(concatMap((confirmed: boolean) => {
                  if (!confirmed) {
                    return of(ActionTypes.DeleteServiceResponseAction({
                      cancelled:   true,
                      error:       null,
                      id:          req.id,
                      serviceType: req.serviceType,
                    }));
                  }

                  return this.serviceService.deleteService$({ ...req, force })
                    .pipe(
                      tap(() => {
                        setTimeout(() => {
                          this.serviceQueryService.fetchList(false, null);
                          this.store.dispatch(FetchServiceCountsRequestAction({}));
                          this.store.dispatch(FetchCarrierListRequestAction(null));
                        }, 2_000);
                      }),
                      tap(() => {
                        if (req.serviceType === ServiceType.MicrosoftTeams) {
                          this.router.navigate(['/services']);
                        }
                      }),
                      map((res: DeleteServiceResponse) =>
                        ActionTypes.DeleteServiceResponseAction(res),
                      ),
                    );
                },
              ),
            );
        };
        const metadata      = counts?.find(metadata => metadata.serviceId === req.id);
        const count         = metadata?.numberAssignedCount || metadata?.parentAssignedCount || 0;
        if (metadata && !metadata.isDeletable) {
          const canForce = req.scopes.includes(AuthScope.AdminService);

          return this.dialog.open(ConfirmModalComponent, {
            panelClass: 'cr-dialog',
            maxWidth:   '700px',
            data:       {
                          title:             `Unable to delete ${ req.serviceName }`,
                          content:           req.serviceType === ServiceType.Carrier ?
                                               `<p>The carrier service is associated with a number range. 
Please delete the associated number range to proceed with deletion of the carrier service${ canForce ? ' or force delete the service and all assignments by using the checkbox below' : '' }.</p>` :
                                               `<p>
The service is associated with numbers. 
Please remove the assignment${ count === 1 ? '' : 's' } to proceed with deletion of the service${ canForce ? ' or force delete the service and all assignments by using the checkbox below' : '' }.
</p>`,
                          confirmBtnText:    canForce ? 'Force' : 'Close',
                          showCancel:        canForce,
                          canForce,
                          forceCheckboxText: 'Force delete the service',
                        } as ConfirmModalData,
          })
            .afterClosed()
            .pipe(
              concatMap((confirm: boolean) => {
                if (canForce && confirm) {
                  return deleteService(true);
                }
                return of(ActionTypes.DeleteServiceResponseAction({
                  cancelled:   true,
                  error:       null,
                  id:          req.id,
                  serviceType: req.serviceType,
                }));
              }));
        }
        return deleteService();
      }),
  ));

  fetchServiceSchema$ = createEffect(() => this.actions$.pipe(
    ofType(FetchServiceSchemaRequestAction),
    switchMap((req: FetchServiceSchemaRequest) => {
      return this.serviceService.fetchServiceSchema$(req);
    }),
    map((res: FetchServiceSchemaResponse) => {
      return FetchServiceSchemaResponseAction(res);
    }),
  ));

  migrateProfiles$ = createEffect(() => this.actions$.pipe(
    ofType(MigrateProfilesRequestAction),
    switchMap((req: MigrateProfilesRequest) => {
      return this.serviceService.migrateProfiles$(req);
    }),
    map((res: MigrateProfilesResponse) => MigrateProfilesResponseAction(res)),
  ));

  migrateProfileRefresh$ = createEffect(() => this.actions$.pipe(
    ofType(MigrateProfilesResponseAction),
    tap((res: MigrateProfilesResponse) => {
      this.store.dispatch(FetchServiceUserListRequestAction({ serviceId: res.serviceId }));
      this.store.dispatch(FetchCallingProfilesRequestAction({ serviceId: res.serviceId }));
    }),
  ), { dispatch: false });

  deleteCallingProfile$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteCallingProfileRequestAction),
    withLatestFrom(this.store.select(selectCallingProfiles)),
    switchMap(([req, profiles]: [DeleteCallingProfileRequest, CallingProfile[]]) => {
      return this.dialog.open(DeleteProfileModalComponent, {
        panelClass: 'cr-dialog',
        maxWidth:   '700px',
        minWidth:   '40vw',
        data:       {
          name:      req.name,
          id:        req.id,
          serviceId: req.serviceId,
          profiles,
        },
      })
        .afterClosed()
        .pipe(concatMap((result: { confirm: boolean, migrateTo: string }) => {
          if (!result?.confirm) {
            return of({ error: null, cancelled: true, isLastOnPage: null, message: null, id: null });
          }
          return this.serviceService.deleteCallingProfile$(req, result.migrateTo);
        }));
    }),
    map((res: DeleteCallingProfileResponse) => DeleteCallingProfileResponseAction(res)),
  ));

  deleteCallingProfileRefresh$ = createEffect(() => this.actions$.pipe(
      ofType(DeleteCallingProfileResponseAction),
      debounceTime(1_000),
      withLatestFrom(this.store.select(selectCallingProfileParams), this.store.select(selectServiceItem)),
      tap(([res, queryParams, serviceItem]) => {
        if (res.cancelled) {
          return;
        }
        this.store.dispatch(FetchCallingProfilesRequestAction({
          serviceId:   serviceItem.id,
          queryParams: queryParams ?
                         {
                           ...queryParams,
                           pageNumber: res.isLastOnPage && queryParams.pageNumber !== 1 ?
                                         queryParams.pageNumber - 1 :
                                         queryParams.pageNumber,
                         } : null,
        }));
        this.store.dispatch(FetchServiceUserListRequestAction({ serviceId: serviceItem.id }));
      }),
    ),
    { dispatch: false });

  deleteServiceCleanup$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteServiceResponseAction),
    tap((res: DeleteServiceResponse) => {
      if (res.cancelled || res.error) {
        return;
      }
      this.store.dispatch(FetchNumbersServiceMetadataRequestAction({}));
      this.noServicesOfTypeRedirect(res.id, res.serviceType);
    }),
  ), { dispatch: false });

  configureServiceRequest$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigureServiceRequestAction),
    withThrottle(1_000, { leading: true, trailing: false }),
    switchMap((req: ConfigureServiceRequest) =>
      from(new Promise<{ authed: boolean, req: ConfigureServiceRequest }>(async resolve => {
        const tokens        = await this.store.select(selectTokens)
          .pipe(take(1))
          .toPromise();
        let authed: boolean = this.lyncTokenActive(tokens[req.id] || []);
        if (req.serviceType === ServiceType.MicrosoftTeams && !authed) {
          this.authoriseRedirect(req.id, MicrosoftToken.MicrosoftLync);
          authed = await this.serviceService.waitUntilAuthed(req.id);
        }

        resolve({ authed, req });
      }))
        .pipe(take(1)),
    ),
    concatMap(({
                 authed,
                 req,
               }: { authed: boolean, req: ConfigureServiceRequest }) => {
      if (!authed) {
        return of({
          error:      new Alert().fromApiMessage({
            message:   'Authorisation failed',
            color:     'red',
            url:       null,
            isSuccess: false,
            show:      false,
          }),
          authFailed: true,
        }) as Observable<ConfigureServiceResponse>;
      }
      if (!req.confirm) {
        return this.serviceService.configureService$(req);
      }
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:           `Re-deploy Microsoft Teams`,
          content:         `<p>You are about to re-deploy your Microsoft Teams direct routing configuration. Click 'Confirm' to continue.</p>`,
          confirmBtnText:  'Confirm',
          showCancel:      true,
          typeConfirmText: 'CONFIRM',
          typeConfirm:     true,
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(concatMap((confirmed: boolean) => {
          if (!confirmed) {
            return of(ActionTypes.ConfigureServiceResponseAction({
              cancelled: true,
              serviceId: req.id,
              error:     null,
            }));
          }
          return this.serviceService.configureService$(req);
        }));
    }),
    map((res: ConfigureServiceResponse) => ConfigureServiceResponseAction(res))));

  configureServiceResponse$ = createEffect(() => this.actions$.pipe(
    ofType(ConfigureServiceResponseAction),
    map((res: ConfigureServiceResponse) => {
      if (res.error) {

        const message = res.error.message.includes('Please ensure you sign in with the same Microsoft account') ?
          res.error.message :
          'We were unable to configure this service. Please try again later.';

        const configErrorModal = () =>
          this.openWarningModal(
            'Unable to configure',
            message, 'Close');

        if (res.authFailed) {
          return;
        }

        return configErrorModal();
      }

      this.serviceQueryService.fetchItem(res.serviceId);
      this.serviceQueryService.fetchList(true, null);
    }),
  ), { dispatch: false });

  postService$ = createEffect(() => this.actions$.pipe(
    ofType(PostServiceRequestAction),
    switchMap((req: PostServiceRequest<ServiceItem>) => {
      switch (req.serviceItem.serviceType) {
        case ServiceType.MicrosoftTeams:
          return this.microsoftTeamsService.postMicrosoftTeams$(req as unknown as PostServiceRequest<MicrosoftTeams>);
        case ServiceType.SIPPhone:
          return this.sipPhoneService.postSipPhone$(req as unknown as PostServiceRequest<SIPPhone>);
        case ServiceType.WebexCalling:
          return this.webexService.postWebexService$(req as unknown as PostServiceRequest<WebexCalling>);
        case ServiceType.Carrier:
          return this.carrierService.postCarrier$(req as unknown as PostServiceRequest<ServiceCarrier>);
        case ServiceType.SIPTrunk:
          return this.sipTrunkService.postSipTrunk$(req as unknown as PostServiceRequest<SIPTrunk>);
        case ServiceType.CallForwarding:
          return this.callForwardingService.postCallForwarding$(req as unknown as PatchServiceRequest<CallForwarding>);
        default:
          return of(null);
      }
    }),
    map(res => PostServiceResponseAction(res)),
  ));

  patchService$ = createEffect(() => this.actions$.pipe(
    ofType(PatchServiceRequestAction),
    switchMap((req: PatchServiceRequest<ServiceItem>) => {
      switch (req.serviceItem.serviceType) {
        case ServiceType.MicrosoftTeams:
          return this.microsoftTeamsService.patchMSTeams$(req as unknown as PatchServiceRequest<MicrosoftTeams>);
        case ServiceType.SIPPhone:
          return this.sipPhoneService.patchSipPhone$(req as unknown as PatchServiceRequest<SIPPhone>);
        case ServiceType.WebexCalling:
          return this.webexService.patchWebexService$(req as unknown as PatchServiceRequest<WebexCalling>);
        case ServiceType.Carrier:
          return this.carrierService.patchCarrier$(req as unknown as PatchServiceRequest<ServiceCarrier>);
        case ServiceType.SIPTrunk:
          return this.sipTrunkService.patchSipTrunk$(req as unknown as PatchServiceRequest<SIPTrunk>);
        case ServiceType.CallForwarding:
          return this.callForwardingService.patchCallForwarding$(req as unknown as PatchServiceRequest<CallForwarding>);
        default:
          return of(null);
      }
    }),
    map(res => PatchServiceResponseAction(res)),
  ));

  patchServicePoll$ = createEffect(() => this.actions$.pipe(
    ofType(PatchServiceResponseAction),
    tap((res: PatchServiceResponse<ServiceItem>) => {
      if (res.error) {
        return;
      }
      this.pollServiceItem(res.serviceItem.id);
    }),
  ), { dispatch: false });

  postServiceRefresh$ = createEffect(() => this.actions$.pipe(
      ofType(PostServiceResponseAction),
      tap((res: PostServiceResponse<ServiceItem>) => {
        if (ServiceItem.isCarrier(res.serviceItem)) {
          return;
        }
        return this.serviceActionService.goToNewService(res.serviceItem);
      }),
      tap(res => {
        if (res.serviceItem?.serviceType === ServiceType.Carrier) {
          this.store.dispatch(FetchCarrierListRequestAction(null));
        } else {
          this.store.dispatch(FetchServiceListRequestAction(null));
        }
        this.store.dispatch(FetchServiceCountsRequestAction(null));
        this.store.dispatch(FetchNumbersServiceMetadataRequestAction({}));
      }),
    ), { dispatch: false },
  );

  checkPersonaNameAvailable$ = createEffect(() => this.actions$.pipe(
    ofType(FetchPersonaNameAvailabilityRequestAction),
    withThrottle(),
    switchMap(
      (req: FetchPersonaNameAvailabilityRequest) =>
        this.serviceService.fetchPersonaNameAvailable$(req)
          .pipe(
            map((res: FetchPersonaNameAvailabilityResponse) => {
              return ActionTypes.FetchPersonaNameAvailabilityResponseAction(res);
            }),
          ),
    ),
  ));

  checkAutomationNameAvailable$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAutomationNameAvailabilityRequestAction),
    withThrottle(),
    switchMap(
      (req: FetchAutomationNameAvailabilityRequest) =>
        this.serviceService.fetchAutomationNameAvailable$(req)
          .pipe(
            map((res: FetchAutomationNameAvailabilityResponse) => {
              return ActionTypes.FetchAutomationNameAvailabilityResponseAction(res);
            }),
          ),
    ),
  ));

  fetchServiceUserCounts$ = createEffect(() => this.actions$.pipe(
    ofType(FetchServiceUserCountsByVoiceTypeRequestAction),
    switchMap((req: FetchServiceUserCountsByVoiceTypeRequest) => this.serviceService.fetchServiceUserCounts$(req)),
    map((res: FetchServiceUserCountsByVoiceTypeResponse) => FetchServiceUserCountsByVoiceTypeResponseAction(res)),
  ));

  fetchServiceUserList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchServiceUserListRequestAction),
    throttleTime(1_000, undefined, { leading: true, trailing: true }),
    filter(req => !!req.serviceId),
    withLatestFrom(this.store.select(selectService)),
    tap(() => this.killServiceUserPoll.next()),
    switchMap(([req, serviceState]: [FetchServiceUserListRequest, ServiceState]) => {
        return this.serviceService.fetchServiceUserList$({
          queryParams: (serviceState.selectedServiceUserQueryParams || { pageSize: 25 }) as TeamsUserQueryParams,
          ...req,
        })
          .pipe(
            switchMap((response: FetchServiceUserListResponse) => {
              if (!response?.models?.length) {
                return of(response);
              }
              const uniqueProfiles: string[] = Array.from(new Set(response.models.map(model => model.profileId)
                .filter(p => !!p)));

              const uniqueNumbers: string[] = Array.from(new Set(response.models.map(model => model.numberId)
                .filter(p => !!p)));

              const batchSize = 50;

              const batchedProfiles$: Observable<CallingProfile[]> = uniqueProfiles.length
                ? forkJoin(
                  Array.from({ length: Math.ceil(uniqueProfiles.length / batchSize) }, (_, index) => {
                    const batch = uniqueProfiles.slice(index * batchSize, (index + 1) * batchSize);
                    return this.serviceService.fetchCallingProfiles$({
                      serviceId:   req.serviceId,
                      queryParams: {
                        id:         batch,
                        pageSize:   batch.length,
                        pageNumber: 1,
                      },
                    })
                      .pipe(map(res => res?.data || []));
                  }),
                )
                  .pipe(map(batches => batches.reduce((acc, batch) => acc.concat(batch), []))) // Combine results from all batches into a single array
                : of([]);

              const batchedNumbers$: Observable<NumberItem[]> = uniqueNumbers.length
                ? forkJoin(
                  Array.from({ length: Math.ceil(uniqueNumbers.length / batchSize) }, (_, index) => {
                    const batch = uniqueNumbers.slice(index * batchSize, (index + 1) * batchSize);
                    return this.numberService.fetchNumberList$({
                      queryParams: {
                        id:         batch,
                        pageSize:   batch.length,
                        pageNumber: 1,
                      },
                    })
                      .pipe(map(res => res?.models || []));
                  }),
                )
                  .pipe(map(batches => batches.reduce((acc, batch) => acc.concat(batch), []))) // Combine results from all batches into a single array
                : of([]);

              return zip([batchedProfiles$, batchedNumbers$])
                .pipe(
                  map(([profiles, numbers]) => {
                      return response.models
                        .map(model => this.mergeServiceUserNumber(numbers, model))
                        .map(m => this.mergeServiceUserProfile(profiles, m));
                    },
                  ),
                  concatMap(users => {
                    return this.serviceService.mergeEmergencyLocations$(req.serviceId, users);
                  }),
                  defaultIfEmpty([]),
                  map(models => {
                    return { ...response, models } as FetchServiceUserListResponse;
                  }),
                );
            }),
            tap((res: FetchServiceUserListResponse) => {
              if (ServiceEffects.shouldPollServiceUserItems(res.models)) {
                this.pollServiceUserItems(res.serviceId, !!serviceState.selectedServiceItem);
              }
            }),
            map((res: FetchServiceUserListResponse) =>
              ActionTypes.FetchServiceUserListResponseAction(res),
            ),
          );
      },
    ),
  ));

  fetchServiceUserCountResponse$ = createEffect(() => this.actions$.pipe(
    ofType(FetchServiceUserCountsByVoiceTypeResponseAction),
    debounceTime(1000),
    withLatestFrom(this.store.select(selectService)),
    map(([response, serviceState]: [FetchServiceUserCountsByVoiceTypeResponse, ServiceState]): void => {
      if (response.promptUser && !serviceState.serviceUserAlerted && this.location.path(true)
        .includes('#users')) {
        this.store.dispatch(ActionTypes.PromptServiceUserAction());
      }
    }),
  ), { dispatch: false });

  fetchServiceUser$ = createEffect(() => this.actions$.pipe(
    ofType(FetchServiceUserRequestAction),
    withLatestFrom(this.store.select(selectServiceItem)),
    switchMap(([req, serviceItem]: [FetchServiceUserRequest, ServiceItem]) =>
      this.serviceService.fetchServiceUser$(req)
        .pipe(
          concatMap((res: FetchServiceUserResponse) => {
            if (!res.data) {
              return of(res);
            }
            return this.resolveProfileData$(res.data, req.serviceId)
              .pipe(
                concatMap(m => this.resolveLicenseGroups$(m, req.serviceId)),
                concatMap(m => this.resolveTeamGroups$(m, req.serviceId)),
                concatMap(m => this.resolveCallQueueGroups$(m, req.serviceId)),
                concatMap(m => {
                    if (!m.numberId) {
                      return of(m);
                    }
                    return this.numberService.fetchNumber$({ id: m.numberId })
                      .pipe(
                        map(res => {
                          if (!res.number) {
                            return m;
                          }
                          return this.mergeServiceUserNumber([res.number], m);
                        }));
                  },
                ),
                concatMap(user => {
                  return this.serviceService.mergeEmergencyLocations$(req.serviceId, [user])
                    .pipe(map(users => users[0]));
                }),
                map(m => ({ ...res, data: m }) as FetchServiceUserResponse),
              );
          }),
          tap(res => {
            if (!res.data) {
              return;
            }
            if (ServiceEffects.shouldPollServiceUserItems([res.data])) {
              this.pollServiceUserItems(req.serviceId, !!serviceItem);
            }
          }))),
    map((res: FetchServiceUserResponse) => FetchServiceUserResponseAction(res)),
  ));

  reauth$ = createEffect(() => this.actions$.pipe(
    ofType(ReauthRequestAction),
    withLatestFrom(this.store.select(selectTokens), this.store.select(selectServiceItem)),
    switchMap(([_, tokens, serviceItem]: [unknown, { [serviceId: string]: Token[] }, ServiceItem]) => {
      return from(this.authCheck(serviceItem.id, tokens[serviceItem.id] || [])());
    }),
  ), { dispatch: false });

  syncServiceUserList$ = createEffect(() => this.actions$.pipe(
    ofType(SyncServiceUserListRequestAction),
    tap(() => this.killSyncPoll.next()),
    withLatestFrom(this.store.select(selectTokens)),
    switchMap(([req, tokens]: [SyncServiceUserListRequest, { [serviceId: string]: Token[] }]) => {
        return from(this.authCheck(req.serviceId, tokens[req.serviceId] || [])())
          .pipe(
            concatMap((authed: boolean) => {
              // if there were multiple tokens to reauth, then return (needs next token)
              if (tokens[req.serviceId]?.every(t => t.status !== TokenStatus.Active)) {
                return of(ActionTypes.SyncServiceUserListResponseAction({
                  error:     null,
                  serviceId: null,
                  message:   null,
                  taskId:    null,
                }));
              }
              if (!authed) {
                return of(ActionTypes.SyncServiceUserListResponseAction({
                  error:     new Alert().fromApiMessage({
                    message:   'Re-authorisation is required to synchronise users',
                    color:     'orange',
                    url:       undefined,
                    isSuccess: false,
                  }),
                  serviceId: null,
                  message:   null,
                  taskId:    null,
                }));
              }

              return this.serviceService.syncServiceUserList$(req)
                .pipe(
                  tap((res: SyncServiceUserListResponse) => {
                    if (res.taskId) {
                      this.store.dispatch(FetchTaskRequestAction({ id: res.taskId }));
                    }
                    setTimeout(() => {
                      this.store.select(selectServiceUser)
                        .pipe(take(1))
                        .subscribe(user => {
                          this.store.dispatch(FetchServiceUserListRequestAction({
                            emitEvent: false,
                            serviceId: req.serviceId,
                          }));
                          this.store.dispatch(FetchServiceRequestAction({ serviceId: req.serviceId }));
                          if (!user) {
                            return;
                          }
                          this.store.dispatch(FetchServiceUserRequestAction({
                            serviceId: req.serviceId,
                            id:        user.id,
                          }));
                        });
                    }, 10_000);
                  }),
                  map((res: SyncServiceUserListResponse) =>
                    ActionTypes.SyncServiceUserListResponseAction(res),
                  ),
                );
            }));
      },
    ),
  ));

  assignServiceUserNumber$ = createEffect(() => this.actions$.pipe(
    ofType(AssignServiceUserNumberRequestAction),
    withLatestFrom(this.store.select(selectService)),
    switchMap(([req, serviceState]: [AssignServiceUserNumberRequest, ServiceState]) =>
      this.serviceService.assignServiceUserNumber$(req)
        .pipe(
          tap(() => {
            this.store.dispatch(FetchServiceUserListRequestAction({
              serviceId:   req.serviceId,
              emitEvent:   false,
              queryParams: serviceState.selectedServiceUserQueryParams,
            }));
            this.store.dispatch(FetchServiceUserRequestAction({ serviceId: req.serviceId, id: req.userId }));
          }),
          map((res: AssignServiceUserNumberResponse) =>
            ActionTypes.AssignServiceUserNumberResponseAction(res),
          ),
        ),
    ),
  ));

  promptServiceUser$ = createEffect(() => this.actions$.pipe(
    ofType(PromptServiceUserAction),
    map(() => {
      this.serviceActionService.openServiceUserPrompt();
    }),
  ), { dispatch: false });

  fetchProvisioningServices$ = createEffect(() => this.actions$.pipe(
    ofType(FetchProvisioningServicesRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.ServiceRead]),
    switchMap(() => this.serviceService.fetchProvisioningServices$()),
    map((res: FetchServiceListResponse) => FetchProvisioningServicesResponseAction(res)),
  ));

  sendCustomCarrierRequest$ = createEffect(() => this.actions$.pipe(
    ofType(SendCustomCarrierRequestAction),
    switchMap((req: SendCustomCarrierRequest) => this.serviceService.sendCustomCarrierRequest$(req)),
    map((res: SendCustomCarrierResponse) => SendCustomCarrierResponseAction(res)),
  ));

  fetchGatewayStatusEvents$ = createEffect(() => this.actions$.pipe(
    ofType(FetchGatewayStatusEventsRequestAction),
    switchMap((req: FetchGatewayStatusEventsRequest) => this.serviceService.fetchGatewayStatusEvents$(req)),
    map((res: FetchGatewayStatusEventsResponse) => FetchGatewayStatusEventsResponseAction(res)),
  ));

  fetchEmergencyLocation$ = createEffect(() => this.actions$.pipe(
    ofType(FetchEmergencyLocationRequestAction),
    switchMap((req: FetchEmergencyLocationRequest) => this.serviceService.fetchEmergencyLocation$(req)),
    map((res: FetchEmergencyLocationResponse) => FetchEmergencyLocationResponseAction(res)),
  ));

  fetchEmergencyLocationList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchEmergencyLocationListRequestAction),
    switchMap((req: FetchEmergencyLocationListRequest) => this.serviceService.fetchEmergencyLocations$(req)),
    map((res: FetchEmergencyLocationListResponse) => FetchEmergencyLocationListResponseAction(res)),
  ));

  fetchCallingProfile$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallingProfileRequestAction),
    throttleTime(1_000, undefined, { trailing: true, leading: false }),
    switchMap((req: FetchCallingProfileRequest) =>
      this.serviceService.fetchCallingProfile$(req)
        .pipe(
          concatMap((res: FetchCallingProfileResponse) =>
            this.resolveCallingProfileData$(res.data, req.serviceId)
              .pipe(
                map(profile => {
                    return { ...res, data: profile };
                  },
                ))),
        ),
    ),
    map((res: FetchCallingProfileResponse) => FetchCallingProfileResponseAction(res)),
  ));

  fetchCallingProfiles$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallingProfilesRequestAction),
    withLatestFrom(this.store.select(selectCallingProfileParams)),
    switchMap(([req, queryParams]: [FetchCallingProfilesRequest, CallingProfileQueryParams]) =>
      this.serviceService.fetchCallingProfiles$({ queryParams, ...req })
        .pipe(switchMap(res => {

          const profileItems$: Observable<CallingProfile>[] =
                  res.data?.map(s => this.resolveCallingProfileData$(s, req.serviceId)) || [];

          return zip(...profileItems$)
            .pipe(
              defaultIfEmpty([]),
              map(serviceItems => {
                return {
                  ...res,
                  serviceItems,
                };
              }));
        }))),
    map((res: FetchCallingProfilesResponse) => FetchCallingProfilesResponseAction(res)),
  ));

  fetchAutomation$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAutomationRequestAction),
    switchMap((req: FetchAutomationRequest) => {
      return this.serviceService.fetchAutomation$(req)
        .pipe(concatMap(res => {
          const adGroupIds = this.expressionService.extractAdGroupIds(res.data.criteria);
          return this.resolveProfileData$(res.data, req.serviceId)
            .pipe(
              concatMap(automation => this.resolveLicenseGroups$(automation, req.serviceId)),
              concatMap(automation => this.resolveAdGroups$(automation, req.serviceId, adGroupIds)),
              concatMap(automation => this.resolveTeamGroups$(automation, req.serviceId)),
              concatMap(automation => this.resolveCallQueueGroups$(automation, req.serviceId)),
              map(automation => {
                return {
                  ...res,
                  data: automation,
                };
              }));
        }));
    }),
    map((res: FetchAutomationResponse) => FetchAutomationResponseAction(res)),
  ));

  deleteAutomation$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteAutomationRequestAction),
    switchMap((req: DeleteAutomationRequest) => {
        return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
          data:       {
            title:          `Delete ${ req.name }`,
            content:        `<p>You are about to delete an automation. Click 'delete' to continue with the deletion.</p>`,
            confirmBtnText: 'Delete',
            showCancel:     true,
            typeConfirm:    true,
          },
          panelClass: 'cr-dialog',
          maxWidth:   '640px',
          maxHeight:  'calc(100vh - 140px)',
          width:      '100%',
        })
          .afterClosed()
          .pipe(concatMap((confirmed: boolean) => {
            if (!confirmed) {
              return of(ActionTypes.DeleteAutomationResponseAction({
                cancelled:    true,
                error:        null,
                id:           req.id,
                serviceId:    req.serviceId,
                isLastOnPage: false,
              }));
            }
            return this.serviceService.deleteAutomation$(req)
              .pipe(map((res: DeleteAutomationResponse) => DeleteAutomationResponseAction(res)));
          }));
      },
    )));

  deleteAutomationRefresh$ = createEffect(() => this.actions$.pipe(
      ofType(DeleteAutomationResponseAction),
      debounceTime(1_000),
      withLatestFrom(this.store.select(selectAutomationQueryParams)),
      tap(([res, queryParams]: [DeleteAutomationResponse, AutomationQueryParams]) => {
        if (res.cancelled) {
          return;
        }
        this.store.dispatch(FetchAutomationListRequestAction({
          serviceId:   res.serviceId,
          emitEvent:   false,
          queryParams: queryParams ?
                         {
                           ...queryParams,
                           pageNumber: res.isLastOnPage && queryParams.pageNumber !== 1 ?
                                         queryParams.pageNumber - 1 :
                                         queryParams.pageNumber,
                         } : null,
        }));
      }),
    ),
    { dispatch: false });

  fetchAutomationList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAutomationListRequestAction),
    withLatestFrom(this.store.select(selectAutomationQueryParams)),
    switchMap(([req, queryParams]: [FetchAutomationListRequest, AutomationQueryParams]) =>
      this.serviceService.fetchAutomationList$({ queryParams, ...req })
        .pipe(switchMap(res => {
          const automationItems$: Observable<Automation>[] = res.data?.map(s => {
              const adGroupIds = this.expressionService.extractAdGroupIds(s.criteria);
              return this.resolveProfileData$(s, req.serviceId)
                .pipe(
                  concatMap(automation => this.resolveAdGroups$(automation, req.serviceId, adGroupIds)),
                  concatMap(automation => this.resolveLicenseGroups$(automation, req.serviceId)),
                  concatMap(automation => this.resolveTeamGroups$(automation, req.serviceId)),
                  concatMap(automation => this.resolveCallQueueGroups$(automation, req.serviceId)),
                );
            },
          ) || [];

          return zip(...automationItems$)
            .pipe(
              defaultIfEmpty([]),
              map(serviceItems => {
                return {
                  ...res,
                  serviceItems,
                };
              }));
        }))),
    map((res: FetchAutomationListResponse) => FetchAutomationListResponseAction(res)),
  ));

  searchCallingProfiles$ = createEffect(() => this.actions$.pipe(
    ofType(SearchCallingProfilesRequestAction),
    switchMap((req: FetchCallingProfilesRequest) => this.serviceService.fetchCallingProfiles$(req)
      .pipe(switchMap(res => {

        const profileItems$: Observable<CallingProfile>[] =
                res.data?.map(s =>
                  this.resolveCallingProfileData$(
                    s,
                    req.serviceId)) || [];

        return zip(...profileItems$)
          .pipe(
            defaultIfEmpty([]),
            map(serviceItems => {
              return {
                ...res,
                serviceItems,
              };
            }));
      }))),
    map((res: FetchCallingProfilesResponse) => SearchCallingProfilesResponseAction(res)),
  ));

  putCallingProfile$ = createEffect(() => this.actions$.pipe(
    ofType(PutCallingProfileRequestAction),
    switchMap((req: PutCallingProfileRequest) => this.serviceService.putCallingProfile$(req)
      .pipe(concatMap(res => {
        if (res.error) {
          return of(res);
        }
        return this.resolveCallingProfileData$(
          res.data,
          req.serviceId)
          .pipe(map(profile => {
            return { ...res, data: profile };
          }));
      }))),
    map((res: PutCallingProfileResponse) => PutCallingProfileResponseAction(res)),
  ));

  postAutomation$ = createEffect(() => this.actions$.pipe(
    ofType(PostAutomationRequestAction),
    switchMap((req: PostAutomationRequest) => this.serviceService.postAutomation$(req)
      .pipe(concatMap((res: PostAutomationResponse) => {
        if (res.error) {
          return of(res);
        }
        const adGroupIds = this.expressionService.extractAdGroupIds(res.data.criteria);
        return this.resolveProfileData$(res.data, req.serviceId)
          .pipe(
            concatMap(automation => this.resolveAdGroups$(automation, req.serviceId, adGroupIds)),
            concatMap(automation => this.resolveLicenseGroups$(automation, req.serviceId)),
            concatMap(automation => this.resolveTeamGroups$(automation, req.serviceId)),
            concatMap(automation => this.resolveCallQueueGroups$(automation, req.serviceId)),
            map((automation: Automation) => {
              return { ...res, data: automation };
            }));
      }))),
    map((res: PostAutomationResponse) => PostAutomationResponseAction(res)),
  ));

  patchAutomation$ = createEffect(() => this.actions$.pipe(
    ofType(PatchAutomationRequestAction),
    switchMap((req: PatchAutomationRequest) => this.serviceService.patchAutomation$(req)
      .pipe(concatMap((res: PatchAutomationResponse) => {
        if (res.error) {
          return of(res);
        }
        const adGroupIds = this.expressionService.extractAdGroupIds(res.data.criteria);
        return this.resolveProfileData$(res.data, req.serviceId)
          .pipe(
            concatMap(automation => this.resolveAdGroups$(automation, req.serviceId, adGroupIds)),
            concatMap(automation => this.resolveLicenseGroups$(automation, req.serviceId)),
            concatMap(automation => this.resolveTeamGroups$(automation, req.serviceId)),
            concatMap(automation => this.resolveCallQueueGroups$(automation, req.serviceId)),
            map((automation: Automation) => {
              return { ...res, data: automation };
            }));
      }))),
    map((res: PatchAutomationResponse) => PatchAutomationResponseAction(res)),
  ));

  changedPriority$ = createEffect(() => this.actions$.pipe(
    ofType(PatchAutomationResponseAction),
    tap((res: PatchAutomationResponse) => {
      if (!res.shouldFetchList) {
        return;
      }
      return this.store.dispatch(FetchAutomationListRequestAction({ serviceId: res.serviceId, emitEvent: false }));
    })), { dispatch: false },
  );

  fetchPolicyList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchPolicyListRequestAction),
    switchMap((req: FetchPolicyListRequest) => this.serviceService.fetchPolicyList$(req)),
    map((res: FetchPolicyListResponse) => FetchPolicyListResponseAction(res)),
  ));

  syncPolicies$ = createEffect(() => this.actions$.pipe(
    ofType(SyncPoliciesRequestAction),
    tap(() => this.killSyncPoll.next()),
    withLatestFrom(this.store.select(selectTokens)),
    switchMap(([req, tokens]: [SyncPoliciesRequest, { [serviceId: string]: Token[] }]) => {
      return from(this.authCheck(req.serviceId, tokens[req.serviceId] || [])())
        .pipe(
          concatMap((authed: boolean) => {
            // if there were multiple tokens to reauth, then return (needs next token)
            if (tokens[req.serviceId]?.every(t => t.status !== TokenStatus.Active)) {
              return of(ActionTypes.SyncPoliciesResponseAction({
                error:     null,
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            if (!authed) {
              return of(ActionTypes.SyncPoliciesResponseAction({
                error:     new Alert().fromApiMessage({
                  message:   'Re-authorisation is required to synchronise policies',
                  color:     'orange',
                  url:       undefined,
                  isSuccess: false,
                }),
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            return this.serviceService.syncPolicies$(req)
              .pipe(
                tap(() => {
                  return interval(10_000)
                    .pipe(
                      withLatestFrom(this.store.select(selectServiceItem)),
                      map(([_, serviceItem]) => serviceItem),
                      takeWhile((serviceItem: ServiceItem) => (serviceItem as MicrosoftTeams).isProcessing),
                      takeUntil(this.killSyncPoll$))
                    .subscribe(
                      () => this.serviceQueryService.fetchItem(req.serviceId),
                      () => null,
                      () => this.serviceQueryService.fetchPolicyList(req.serviceId, false),
                    );
                }));
          }));
    }),
    map((res: SyncPoliciesResponse) => SyncPoliciesResponseAction(res)),
  ));

  syncDomains$ = createEffect(() => this.actions$.pipe(
    ofType(SyncDomainsRequestAction),
    tap(() => this.killSyncPoll.next()),
    withLatestFrom(this.store.select(selectTokens)),
    switchMap(([req, tokens]: [SyncDomainsRequest, { [serviceId: string]: Token[] }]) => {
      return from(this.authCheck(req.serviceId, tokens[req.serviceId] || [])())
        .pipe(
          concatMap((authed: boolean) => {
            // if there were multiple tokens to reauth, then return (needs next token)
            if (tokens[req.serviceId]?.every(t => t.status !== TokenStatus.Active)) {
              return of(ActionTypes.SyncDomainsResponseAction({
                error:   null,
                message: null,
              }));
            }
            if (!authed) {
              return of(ActionTypes.SyncDomainsResponseAction({
                error:   new Alert().fromApiMessage({
                  message:   'Re-authorisation is required to synchronise domains',
                  color:     'orange',
                  url:       undefined,
                  isSuccess: false,
                }),
                message: null,
              }));
            }
            return this.serviceService.syncDomains$(req)
              .pipe(
                tap(() => {
                  return interval(10_000)
                    .pipe(
                      withLatestFrom(this.store.select(selectServiceItem)),
                      map(([_, serviceItem]) => serviceItem),
                      takeWhile((serviceItem: ServiceItem) => (serviceItem as MicrosoftTeams).isProcessing),
                      takeUntil(this.killSyncPoll$))
                    .subscribe(
                      () => this.serviceQueryService.fetchItem(req.serviceId),
                      () => null,
                      () => this.store.dispatch(FetchCompanyDomainListRequestAction({})),
                    );
                }));
          }));
    }),
    map((res: SyncDomainsResponse) => SyncDomainsResponseAction(res)),
  ));

  syncLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(SyncLicensesRequestAction),
    tap(() => this.killSyncPoll.next()),
    withLatestFrom(this.store.select(selectTokens)),
    switchMap(([req, tokens]: [SyncLicensesRequest, { [serviceId: string]: Token[] }]) => {
      return from(this.authCheck(req.serviceId, tokens[req.serviceId] || [])())
        .pipe(
          concatMap((authed: boolean) => {
            // if there were multiple tokens to reauth, then return (needs next token)
            if (tokens[req.serviceId]?.every(t => t.status !== TokenStatus.Active)) {
              return of(ActionTypes.SyncLicensesResponseAction({
                error:     null,
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            if (!authed) {
              return of(ActionTypes.SyncLicensesResponseAction({
                error:     new Alert().fromApiMessage({
                  message:   'Re-authorisation is required to synchronise policies',
                  color:     'orange',
                  url:       undefined,
                  isSuccess: false,
                }),
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            return this.serviceService.syncLicenses$(req)
              .pipe(
                tap(() => {
                  return interval(10_000)
                    .pipe(
                      withLatestFrom(this.store.select(selectServiceItem)),
                      map(([_, serviceItem]) => serviceItem),
                      takeWhile((serviceItem: ServiceItem) => (serviceItem as MicrosoftTeams).isProcessing),
                      takeUntil(this.killSyncPoll$))
                    .subscribe(
                      () => this.serviceQueryService.fetchItem(req.serviceId),
                      () => null,
                      () => this.store.dispatch(FetchLicensesRequestAction({ serviceId: req.serviceId })),
                    );
                }));
          }));
    }),
    map((res: SyncLicensesResponse) => SyncLicensesResponseAction(res)),
  ));

  syncNumbers$ = createEffect(() => this.actions$.pipe(
    ofType(SyncNumbersRequestAction),
    tap(() => this.killNumberSyncPoll.next()),
    withLatestFrom(this.store.select(selectTokens)),
    switchMap(([req, tokens]: [SyncNumbersRequest, { [serviceId: string]: Token[] }]) => {
      return from(this.authCheck(req.serviceId, tokens[req.serviceId] || [])())
        .pipe(
          concatMap((authed: boolean) => {
            // if there were multiple tokens to reauth, then return (needs next token)
            if (tokens[req.serviceId]?.every(t => t.status !== TokenStatus.Active)) {
              return of(ActionTypes.SyncNumbersResponseAction({
                error:     null,
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            if (!authed) {
              return of(ActionTypes.SyncNumbersResponseAction({
                error:     new Alert().fromApiMessage({
                  message:   'Re-authorisation is required to synchronise numbers',
                  color:     'orange',
                  url:       undefined,
                  isSuccess: false,
                }),
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            return this.serviceService.syncNumbers$(req);
          }));
    }),
    tap((res: SyncNumbersResponse) => {
      if (res.error || !res.taskId) {
        return;
      }
      this.store.dispatch(FetchTaskRequestAction({ id: res.taskId }));
      this.store.dispatch(FetchTaskCountRequestAction({}));
    }),
    map((res: SyncNumbersResponse) => SyncNumbersResponseAction(res)),
  ));

  assignLicenseGroup$ = createEffect(() => this.actions$.pipe(
    ofType(AssignLicenseGroupRequestAction),
    concatMap((req: AssignLicenseGroupRequest): Observable<AssignLicenseGroupRequest> => {
      if (!req.confirm) {
        return of(req);
      }
      const modalText = `<p>You are about to remove an assignment for a license group. Click 'confirm' to continue with the unassignment.</p>`;
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:           `Remove license group assignment`,
          content:         modalText,
          confirmBtnText:  'Confirm',
          showCancel:      true,
          typeConfirm:     true,
          typeConfirmText: 'CONFIRM',
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(map((confirmed: boolean) => {
          if (!confirmed) {
            return null;
          }
          return req;
        }));
    }),
    filter(req => !!req),
    switchMap((req: AssignLicenseGroupRequest) => this.serviceService.assignLicenseGroup$(req)
      .pipe(
        concatMap((res: AssignLicenseGroupResponse) => {
          if (res.error) {
            return of(res);
          }
          const licenseGroups: Observable<LicenseGroup>[] = res.licenseGroupIds?.map(id => this.microsoftTeamsService.fetchLicenseGroup$({
              id,
              serviceId: req.serviceId,
            })
              .pipe(
                map((r: FetchLicenseGroupResponse) => r.data),
              ),
          ) || [];

          return zip(...licenseGroups)
            .pipe(
              defaultIfEmpty([]),
              map(groups => {
                return { ...res, licenseGroups: groups, requestId: res.requestId };
              }),
            );
        }))),
    map((res: AssignLicenseGroupResponse) => AssignLicenseGroupResponseAction(res)),
  ));

  assignTeamGroup$ = createEffect(() => this.actions$.pipe(
    ofType(AssignTeamGroupRequestAction),
    concatMap((req: AssignTeamGroupRequest): Observable<AssignTeamGroupRequest> => {
      if (!req.confirm) {
        return of(req);
      }
      const modalText = `<p>You are about to remove an assignment for a team group. Click 'confirm' to continue with the unassignment.</p>`;
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:           `Remove team group assignment`,
          content:         modalText,
          confirmBtnText:  'Confirm',
          showCancel:      true,
          typeConfirm:     true,
          typeConfirmText: 'CONFIRM',
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(map((confirmed: boolean) => {
          if (!confirmed) {
            return null;
          }
          return req;
        }));
    }),
    filter(req => !!req),
    switchMap((req: AssignTeamGroupRequest) =>
      this.serviceService.assignTeamGroup$(req)
        .pipe(
          concatMap((res: AssignTeamGroupResponse) => {
            if (res.error) {
              return of(res);
            }
            if (!res.teamGroupIds?.length) {
              return of({ ...res, teamGroups: [], requestId: res.requestId });
            }

            return this.resolveTeamGroups$(res, req.serviceId)
              .pipe(
                map(groups => {
                  return { ...res, teamGroups: groups.teamGroups, requestId: res.requestId };
                }),
              );
          })),
    ),
    map((res: AssignTeamGroupResponse) => AssignTeamGroupResponseAction(res)),
  ));

  assignCallQueueGroup$ = createEffect(() => this.actions$.pipe(
    ofType(AssignCallQueueGroupRequestAction),
    concatMap((req: AssignCallQueueGroupRequest): Observable<AssignCallQueueGroupRequest> => {
      if (!req.confirm) {
        return of(req);
      }
      const modalText = `<p>You are about to remove an assignment for a call queue group. Click 'confirm' to continue with the unassignment.</p>`;
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:           `Remove call queue group assignment`,
          content:         modalText,
          confirmBtnText:  'Confirm',
          showCancel:      true,
          typeConfirm:     true,
          typeConfirmText: 'CONFIRM',
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(map((confirmed: boolean) => {
          if (!confirmed) {
            return null;
          }
          return req;
        }));
    }),
    filter(req => !!req),
    switchMap((req: AssignCallQueueGroupRequest) =>
      this.serviceService.assignCallQueueGroup$(req)
        .pipe(
          concatMap((res: AssignCallQueueGroupResponse) => {
            if (res.error) {
              return of(res);
            }
            if (!res.callQueueGroupIds?.length) {
              return of({ ...res, callQueueGroups: [], requestId: res.requestId });
            }

            const callQueueGroups$: Observable<CallQueueGroup[]> = this.microsoftTeamsService.fetchCallQueueGroupList$({
              serviceId:   req.serviceId,
              queryParams: {
                pageSize:   100,
                pageNumber: 1,
                id:         res.callQueueGroupIds,
              },
            })
              .pipe(
                map((r: FetchCallQueueGroupListResponse) => r.data || []),
              );

            return callQueueGroups$
              .pipe(
                defaultIfEmpty([]),
                map(groups => {
                  return { ...res, callQueueGroups: groups, requestId: res.requestId };
                }),
              );
          })),
    ),
    map((res: AssignCallQueueGroupResponse) => AssignCallQueueGroupResponseAction(res)),
  ));

  syncTeams$ = createEffect(() => this.actions$.pipe(
    ofType(SyncTeamsRequestAction),
    tap(() => this.killSyncPoll.next()),
    withLatestFrom(this.store.select(selectTokens)),
    switchMap(([req, tokens]: [SyncTeamsRequest, { [serviceId: string]: Token[] }]) => {
      return from(this.authCheck(req.serviceId, tokens[req.serviceId] || [])())
        .pipe(
          concatMap((authed: boolean) => {
            // if there were multiple tokens to reauth, then return (needs next token)
            if (tokens[req.serviceId]?.every(t => t.status !== TokenStatus.Active)) {
              return of(ActionTypes.SyncTeamsResponseAction({
                error:     null,
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            if (!authed) {
              return of(ActionTypes.SyncTeamsResponseAction({
                error:     new Alert().fromApiMessage({
                  message:   'Re-authorisation is required to synchronise teams',
                  color:     'orange',
                  url:       undefined,
                  isSuccess: false,
                }),
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            return this.serviceService.syncTeams$(req)
              .pipe(
                tap(() => {
                  return interval(10_000)
                    .pipe(
                      withLatestFrom(this.store.select(selectServiceItem)),
                      map(([_, serviceItem]) => serviceItem),
                      takeWhile((serviceItem: ServiceItem) => (serviceItem as MicrosoftTeams).isProcessing),
                      takeUntil(this.killSyncPoll$))
                    .subscribe(
                      () => this.serviceQueryService.fetchItem(req.serviceId),
                      () => null,
                      () => this.store.dispatch(FetchTeamsRequestAction({ serviceId: req.serviceId })),
                    );
                }));
          }));
    }),
    map((res: SyncTeamsResponse) => SyncTeamsResponseAction(res)),
  ));

  syncAdGroups$ = createEffect(() => this.actions$.pipe(
    ofType(SyncAdGroupsRequestAction),
    withLatestFrom(this.store.select(selectTokens)),
    switchMap(([req, tokens]: [SyncAdGroupsRequest, { [serviceId: string]: Token[] }]) => {
      return from(this.authCheck(req.serviceId, tokens[req.serviceId] || [])())
        .pipe(
          concatMap((authed: boolean) => {
            // if there were multiple tokens to reauth, then return (needs next token)
            if (tokens[req.serviceId]?.every(t => t.status !== TokenStatus.Active)) {
              return of(ActionTypes.SyncAdGroupsResponseAction({
                error:     null,
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            if (!authed) {
              return of(ActionTypes.SyncAdGroupsResponseAction({
                error:     new Alert().fromApiMessage({
                  message:   'Re-authorisation is required to synchronise Entra ID groups',
                  color:     'orange',
                  url:       undefined,
                  isSuccess: false,
                }),
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            return this.serviceService.syncADGroups$(req)
              .pipe(tap(() => {
                return interval(10_000)
                  .pipe(
                    withLatestFrom(this.store.select(selectServiceItem)),
                    map(([_, serviceItem]) => serviceItem),
                    takeWhile((serviceItem: ServiceItem) => (serviceItem as MicrosoftTeams).isProcessing),
                    takeUntil(this.killSyncPoll$))
                  .subscribe(
                    () => this.serviceQueryService.fetchItem(req.serviceId),
                    () => null,
                    () => this.store.dispatch(FetchAdGroupsRequestAction({ serviceId: req.serviceId })),
                  );
              }));
          }));
    }),
    map((res: SyncAdGroupsResponse) => SyncAdGroupsResponseAction(res)),
  ));

  syncAllMicrosoftAssets$ = createEffect(() => this.actions$.pipe(
    ofType(SyncAllMicrosoftAssetsRequestAction),
    withLatestFrom(this.store.select(selectTokens)),
    switchMap(([req, tokens]: [SyncAllMicrosoftAssetsRequest, { [serviceId: string]: Token[] }]) => {
      return from(this.authCheck(req.serviceId, tokens[req.serviceId] || [])())
        .pipe(
          concatMap((authed: boolean) => {
            // if there were multiple tokens to reauth, then return (needs next token)
            if (tokens[req.serviceId]?.every(t => t.status !== TokenStatus.Active)) {
              return of(ActionTypes.SyncAllMicrosoftAssetsResponseAction({
                error:     null,
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            if (!authed) {
              return of(ActionTypes.SyncAllMicrosoftAssetsResponseAction({
                error:     new Alert().fromApiMessage({
                  message:   'Re-authorisation is required to synchronise Microsoft assets',
                  color:     'orange',
                  url:       undefined,
                  isSuccess: false,
                }),
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            return this.serviceService.syncAllMicrosoftAssets$(req)
              .pipe(tap(() => {
                return interval(10_000)
                  .pipe(
                    withLatestFrom(this.store.select(selectServiceItem)),
                    map(([_, serviceItem]) => serviceItem),
                    takeWhile((serviceItem: ServiceItem) => (serviceItem as MicrosoftTeams).isProcessing),
                    takeUntil(this.killSyncPoll$))
                  .subscribe(
                    () => this.serviceQueryService.fetchItem(req.serviceId),
                    () => null,
                    () => {
                      this.store.dispatch(FetchAdGroupsRequestAction({ serviceId: req.serviceId }));
                      this.store.dispatch(FetchTeamsRequestAction({ serviceId: req.serviceId }));
                      this.store.dispatch(FetchLicensesRequestAction({ serviceId: req.serviceId }));
                      this.store.dispatch(FetchCallQueuesRequestAction({ serviceId: req.serviceId }));
                    },
                  );
              }));
          }));
    }),
    map((res: SyncAllMicrosoftAssetsResponse) => SyncAllMicrosoftAssetsResponseAction(res)),
  ));


  syncCallQueues$ = createEffect(() => this.actions$.pipe(
    ofType(SyncCallQueuesRequestAction),
    tap(() => this.killSyncPoll.next()),
    withLatestFrom(this.store.select(selectTokens)),
    switchMap(([req, tokens]: [SyncCallQueuesRequest, { [serviceId: string]: Token[] }]) => {
      return from(this.authCheck(req.serviceId, tokens[req.serviceId])())
        .pipe(
          concatMap((authed: boolean) => {
            // if there were multiple tokens to reauth, then return (needs next token)
            if (tokens[req.serviceId]?.every(t => t.status !== TokenStatus.Active)) {
              return of(ActionTypes.SyncCallQueuesResponseAction({
                error:     null,
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            if (!authed) {
              return of(ActionTypes.SyncCallQueuesResponseAction({
                error:     new Alert().fromApiMessage({
                  message:   'Re-authorisation is required to synchronise call queues.',
                  color:     'orange',
                  url:       undefined,
                  isSuccess: false,
                }),
                message:   null,
                serviceId: req.serviceId,
              }));
            }
            return this.serviceService.syncCallQueues$(req)
              .pipe(
                tap(() => {
                  return interval(10_000)
                    .pipe(
                      withLatestFrom(this.store.select(selectServiceItem)),
                      map(([_, serviceItem]) => serviceItem),
                      takeWhile((serviceItem: ServiceItem) => (serviceItem as MicrosoftTeams).isProcessing),
                      takeUntil(this.killSyncPoll$))
                    .subscribe(
                      () => this.serviceQueryService.fetchItem(req.serviceId),
                      () => null,
                      () => this.store.dispatch(FetchCallQueuesRequestAction({ serviceId: req.serviceId })),
                    );
                }));
          }));
    }),
    map((res: SyncCallQueuesResponse) => SyncCallQueuesResponseAction(res)),
  ));

  fetchServiceUserCLIAvailability$ = createEffect(() => this.actions$.pipe(
    ofType(FetchServiceUserCLIAvailabilityRequestAction),
    switchMap((req: FetchServiceUserCliAvailabilityRequest) => {
      return this.serviceService.fetchServiceUserList$({
        serviceId:   req.serviceId,
        queryParams: { pageSize: 100, pageNumber: 1, search: req.cli },
      })
        .pipe(map((res: FetchServiceUserListResponse) => {
          const cli                    = req.cli;
          const extension              = req.extension;
          const hasExtensionAssignment = res.models?.some(m => m.cli && m.cli === req.cli && !!m.extension);
          const hasDirectAssignment    = res.models?.some(m => m.cli && m.cli === req.cli && !m.extension);
          const hasExactMatch          = res.models?.some(m => m.cli && m.cli === req.cli && (m.extension || '') === (req.extension || ''));
          const exactMatchDisplayName  = res.models?.find(m => m.cli && m.cli === req.cli && (m.extension || '') === (req.extension || ''))?.displayName;

          return {
            cli,
            extension,
            hasExtensionAssignment,
            hasDirectAssignment,
            hasExactMatch,
            exactMatchDisplayName,
          };
        }));
    }),
    map((res: FetchServiceUserCliAvailabilityResponse) => FetchServiceUserCLIAvailabilityResponseAction(res)),
  ));

  exportServiceUsers$ = createEffect(() => this.actions$.pipe(
    ofType(ExportServiceUsersRequestAction),
    switchMap((req: ExportServiceUsersRequest) => this.serviceService.exportServiceUsers$(req)),
    tap((res: ExportServiceUsersResponse) => {
      if (res.taskId) {
        this.store.dispatch(FetchTaskRequestAction({ id: res.taskId }));
      }
    }),
    map((res: ExportServiceUsersResponse) => ExportServiceUsersResponseAction(res)),
  ));


  ngOnDestroy(): void {
    this.killSyncPoll.next();
    this.killServiceListPoll.next();
    this.killServicePoll.next();
    this.killServiceUserPoll.next();
    this.destroy.next();
  }

  private openWarningModal(title: string, content: string, confirmBtnText: string, showCancel = false): MatDialogRef<ConfirmModalComponent> {
    return this.dialog.open(ConfirmModalComponent, {
      panelClass: 'cr-dialog',
      maxWidth:   '600px',
      data:       { title, content, confirmBtnText, showCancel },
    });
  }

  private authCheck(serviceId: string, tokens: Token[]): () => Promise<boolean> {
    let auth: () => Promise<boolean> = () => Promise.resolve(true);
    if (tokens.find(t => t?.status !== TokenStatus.Active)) {
      const inactiveToken = tokens.find(t => t?.status !== TokenStatus.Active).id;

      auth = async (): Promise<boolean> => {
        this.authoriseRedirect(serviceId, inactiveToken);
        await this.serviceService.waitForProcessing(serviceId, inactiveToken);
        return this.serviceService.waitUntilAuthed(serviceId, inactiveToken);
      };
    }
    return auth;
  }

  private authoriseRedirect(serviceId: string, token: MicrosoftToken): void {
    this.store.dispatch(AuthoriseRedirectRequest({ serviceId, token }));
  }

  private lyncTokenActive(tokens: Token[]): boolean {
    return tokens.find(token => token.id === MicrosoftToken.MicrosoftLync)?.status === TokenStatus.Active;
  }

  private pollServiceUserItems(serviceId: string, shouldUpdateService?: boolean): void {
    timer(10_000)
      .pipe(
        takeUntil(this.killServiceUserPoll$),
        withLatestFrom(
          this.store.select(selectServiceUserList),
          this.store.select(selectServiceUser),
          this.store.select(selectServiceUserQueryParams),
        ),
        map(([_, userList, serviceUser, searchParams]: [number, MicrosoftTeamsUser[], MicrosoftTeamsUser, TeamsUserQueryParams]) =>
          ([userList, serviceUser, searchParams] as [MicrosoftTeamsUser[], MicrosoftTeamsUser, TeamsUserQueryParams])),
        takeWhile(([userList, serviceUser, _]: [MicrosoftTeamsUser[], MicrosoftTeamsUser, TeamsUserQueryParams]) => userList?.some(user => user.isProcessing) || !!serviceUser?.isProcessing),
        map(([_, __, serviceUserStoredQuery]) => serviceUserStoredQuery),
        withLatestFrom(this.store.select(selectServiceUser), this.store.select(selectServiceUserList), this.store.select(selectServiceItem)))
      .subscribe(([serviceUserStoredQuery, serviceUser, serviceUserList, serviceItem]: [TeamsUserQueryParams, MicrosoftTeamsUser, MicrosoftTeamsUser[], ServiceItem]) => {
        if (serviceItem?.id !== serviceId) {
          return;
        }
        if (serviceUserList?.some(user => user.isProcessing)) {
          this.serviceUserQueryService.fetchList(serviceUserStoredQuery, false, serviceId);
        }
        if (serviceUser?.isProcessing) {
          this.store.dispatch(FetchServiceUserRequestAction({ serviceId, id: serviceUser.id }));
        }
        if (!shouldUpdateService) {
          return;
        }
        this.serviceQueryService.fetchItem(serviceId);
      });
  }

  private pollServiceItems(queryParams: ServiceQueryParams): void {
    interval(10_000)
      .pipe(takeUntil(this.killServiceListPoll$))
      .subscribe(() => {
        this.serviceQueryService.fetchList(false, queryParams);
        this.serviceQueryService.fetchCounts();
      });
  }

  private pollCarrierServiceItems(queryParams: ServiceQueryParams): void {
    interval(10_000)
      .pipe(takeUntil(this.killCarrierListPoll$))
      .subscribe(() => {
        this.carrierQueryService.fetchList(false, queryParams);
        this.serviceQueryService.fetchCounts();
      });
  }

  private pollProvisioningServices(): void {
    interval(10_000)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.store.dispatch(FetchProvisioningServicesRequestAction({})));
  }

  private pollServiceItem(serviceId: string): void {
    interval(10_000)
      .pipe(takeUntil(this.killServicePoll$), withLatestFrom(this.store.select(selectServiceItem)))
      .subscribe(([_, serviceItem]: [number, ServiceItem]) => {
        if (serviceItem?.id !== serviceId) {
          return;
        }
        this.store.dispatch(FetchServiceRequestAction({ serviceId }));
        this.store.dispatch(FetchServiceCountsRequestAction({}));
      });
  }

  private noServicesOfTypeRedirect(id: string, serviceType: ServiceType): void {
    this.store.select(selectServiceItems)
      .pipe(
        filter(items => !items?.length || items.every(item => item.id !== id)),
        timeout(10_000),
        catchError(() => of(null)),
        take(1))
      .subscribe((items: ServiceItem[] | null) => {
        if (!items || serviceType === ServiceType.Carrier || items?.some(item => item.serviceType === serviceType)) {
          return;
        }
        return this.router.navigate(['/services']);
      });
  }

  mergeServiceUserNumber(models: NumberItem[], user: MicrosoftTeamsUser): MicrosoftTeamsUser {
    if (!user?.numberId) {
      return user;
    }

    const numberItem = models.find(m => m.id === user.numberId);
    if (!numberItem) {
      return user;
    }
    user.numberId         = numberItem.id;
    user.numberTags       = numberItem.tags;
    user.numberProvider   = numberItem.range.provider;
    user.range            = numberItem.range;
    user.numberLocationId = numberItem.locationId;
    user.numberLocation   = numberItem.location;

    return user;
  }

  resolveProfileData$<T extends {
    profile: CallingProfile,
    profileId: string
  }>(item: T, serviceId: string): Observable<T> {
    if (!item?.profileId) {
      return of(item);
    }
    return this.serviceService.fetchCallingProfileById$(item.profileId, serviceId)
      .pipe(map(profile => {
        item.profile = profile;
        return item;
      }));
  }

  mergeServiceUserProfile(profiles: CallingProfile[], user: MicrosoftTeamsUser): MicrosoftTeamsUser {
    if (!user.profileId) {
      return user;
    }
    user.profile = profiles.find(profile => profile.id === user.profileId);
    return user;
  }

  resolveTeamGroups$<T extends {
    teamGroupIds: string[],
    teamGroups?: TeamGroup[]
  }>(item: T, serviceId: string): Observable<T> {
    if (!item.teamGroupIds?.length) {
      item.teamGroups = [];
      return of(item);
    }

    const teamGroups$: Observable<TeamGroup[]> = this.microsoftTeamsService.fetchTeamGroupList$({
      serviceId,
      queryParams: {
        pageSize:   100,
        pageNumber: 1,
        id:         item.teamGroupIds,
      },
    })
      .pipe(
        map((r: FetchTeamGroupListResponse) => r.data),
      );

    return teamGroups$
      .pipe(
        defaultIfEmpty([]),
        map(groups => {
          item.teamGroups = groups.filter(group => !!group);
          return item;
        }),
      );
  }

  resolveLicenseGroups$<T extends {
    licenseGroupIds: string[],
    licenseGroups: LicenseGroup[]
  }>(item: T, serviceId: string): Observable<T> {
    if (!item.licenseGroupIds?.length) {
      return of(item);
    }

    const licenseGroups$: Observable<LicenseGroup[]> = this.microsoftTeamsService.fetchLicenseGroupList$({
      serviceId,
      queryParams: {
        pageSize:   100,
        pageNumber: 1,
        id:         item.licenseGroupIds,
      },
    })
      .pipe(
        map((r: FetchLicenseGroupListResponse) => r.data),
      );

    return licenseGroups$
      .pipe(
        defaultIfEmpty([]),
        map(groups => {
          item.licenseGroups = groups;
          return item;
        }),
      );
  }

  resolveAdGroups$(item: Automation, serviceId: string, ids: string[]): Observable<Automation> {
    if (!ids?.length) {
      return of(item);
    }

    const adGroups$: Observable<ADGroup[]> = this.microsoftTeamsService.fetchADGroupList$({
      serviceId,
      queryParams: {
        pageSize:   100,
        pageNumber: 1,
        guid:       Array.from(new Set(ids)),
      },
    })
      .pipe(
        map((r: FetchAdGroupsResponse) => r.data),
      );

    return adGroups$
      .pipe(
        defaultIfEmpty([]),
        map(groups => {
          item.criteria = this.expressionService.mergeAdGroups(item.criteria, groups);
          return item;
        }),
      );
  }

  resolveCallQueueGroups$<T extends {
    callQueueGroupIds: string[],
    callQueueGroups: CallQueueGroup[]
  }>(item: T, serviceId: string): Observable<T> {
    if (!item.callQueueGroupIds?.length) {
      item.callQueueGroups = [];
      return of(item);
    }

    const callQueueGroups$: Observable<CallQueueGroup[]> = this.microsoftTeamsService.fetchCallQueueGroupList$({
      serviceId,
      queryParams: {
        pageSize:   100,
        pageNumber: 1,
        id:         item.callQueueGroupIds,
      },
    })
      .pipe(
        map((r: FetchCallQueueGroupListResponse) => r.data),
      );

    return callQueueGroups$
      .pipe(
        defaultIfEmpty([]),
        map(groups => {
          item.callQueueGroups = groups;
          return item;
        }),
      );
  }

  private resolveServiceData$(s: ServiceItem): Observable<ServiceItem> {
    switch (s?.serviceType) {
      case ServiceType.SIPPhone:
        return this.resolveSIPPhoneWithNum$(s as SIPPhone);
      case ServiceType.Carrier:
        return this.carrierService.resolveCarrier$(s as ServiceCarrier);
      case ServiceType.MicrosoftTeams:
        return this.resolveServiceUserCount$(s as MicrosoftTeams);
      default:
        return of(s);
    }
  }

  private resolveCallingProfileData$(p: CallingProfile, serviceId: string): Observable<CallingProfile> {
    return this.numberService.resolveTagsForProfile$(p)
      .pipe(
        concatMap(profile => this.numberService.resolveRangesForProfile$(profile)),
        concatMap(profile => this.numberService.resolveLocationsForProfile$(profile)),
        concatMap(profile => this.serviceService.mergeEmergencyLocations$(serviceId, [profile])
          .pipe(map(profiles => profiles[0]))),
      );
  }

  private resolveServiceUserCount$(teamsService: MicrosoftTeams): Observable<MicrosoftTeams> {
    return this.serviceService.fetchServiceUserCounts$({ serviceId: teamsService.id })
      .pipe(map(res => {
        teamsService.userCount = res.data?.statuses?.TOTAL || 0;
        return teamsService;
      }));
  }

  private resolveSIPPhoneWithNum$(sipPhone: SIPPhone): Observable<SIPPhone> {
    if (!sipPhone.numberId) {
      // we don't need to fetch a number
      return of(sipPhone);
    }

    let number$: Observable<NumberItem>;
    if (!this.numberCache[sipPhone.numberId]) {
      number$ = this.numberService.fetchNumber$({ id: sipPhone.numberId })
        .pipe(map(res => {
          this.numberCache[sipPhone.numberId] = res?.number; // so we don't fetch the same number from network multiple times
          return res?.number;
        }));
    } else {
      number$ = of(this.numberCache[sipPhone.numberId]);
    }

    return number$.pipe(map(num => {
      sipPhone.number = num;
      return sipPhone;
    }));
  }
}
