import moment                    from 'moment';
import { ReportOverviewDataRaw } from '@models/api/report-overview-data-raw.model';

export class ReportOverviewData {
  totalCalls: number;
  successfulCalls: number;
  successfulCallsPercent: string;
  unsuccessfulCalls: number;
  unsuccessfulCallsPercent: string;
  averageDuration: string;

  static fromApiData(apiData: ReportOverviewDataRaw): ReportOverviewData {
    const o: ReportOverviewData = new ReportOverviewData();
    const totalCalls            = apiData.successful_count + apiData.unsuccessful_count;
    const s                     = apiData.successful_count ? (apiData.successful_count / totalCalls) * 100 : 0;
    const u                     = apiData.unsuccessful_count ? (apiData.unsuccessful_count / totalCalls) * 100 : 0;
    const duration              = moment.duration({ seconds: apiData.seconds_average });

    o.totalCalls               = totalCalls;
    o.successfulCalls          = apiData.successful_count;
    o.successfulCallsPercent   = s.toFixed(s === Math.round(s) ? 0 : 2) + '%';
    o.unsuccessfulCalls        = apiData.unsuccessful_count;
    o.unsuccessfulCallsPercent = u.toFixed(u === Math.round(u) ? 0 : 2) + '%';
    o.averageDuration          = duration.get('minutes') + ':' + (duration.get('seconds') > 9 ?
      duration.get('seconds') : ('0' + duration.get('seconds')));

    return o;
  }
}
