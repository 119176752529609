import { ServiceItemRaw }                                        from '@models/api/service-api-response.model';
import { getServiceName, PROCESSING_STATUSES, SUCCESS_STATUSES } from '@models/api/service.model';
import { HealthCheckItem }                                       from '@models/entity/health-check.model';
import { ServiceUIStatus }                                       from '@enums/service-ui-status';
import { WebexCalling }                                          from '@models/entity/webex-calling.model';
import { ProvisionStep }                                         from '@enums/provisioning-step.enum';
import { StatusItem }                                            from '@models/entity/status-item.model';
import { ProvisioningStatus }                                    from '@enums/provisioning-status.enum';
import { ServiceType }                                           from '@enums/service-type.enum';
import { ServiceStatus }                                         from '@enums/service-status.enum';
import { MicrosoftTeams }                                        from '@models/entity/microsoft-teams.model';
import { SIPTrunk }                                              from '@models/entity/sip-trunk.model';
import { SIPPhone }                                              from '@models/entity/sip-phone.model';
import { ProvisioningChecklistItem }                             from '@models/api/provisioning-checklist-item.model';
import { ServiceCarrier }                                        from '@models/entity/carrier-service.model';
import { ServiceCapability }                                     from '@models/entity/service-capability.model';


export class ServiceItem {
  id: string;
  label: string;
  icon: string;
  serviceType: ServiceType;
  name: string;
  provisionStatus: StatusItem;
  createdDate: string;
  updatedDate: string;
  capabilities?: ServiceCapability[];
  _provisioningStatus: ProvisioningStatus;

  getIcon?(): string {
    return this.icon;
  }

  getStatusHeader?(isPending: boolean, progress: number): string {
    if (isPending) {
      return '';
    }
    const isRedeploying = ServiceItem.isMicrosoft(this) && this.isRedeploying();
    if (isRedeploying) {
      return 'Re-deploying';
    }
    if (progress < 100) {
      if (ServiceItem.isMicrosoft(this)) {
        return this.provisionStatus.status === ServiceUIStatus.Processing ||
        (this as MicrosoftTeams).initialSyncStatus.status === ServiceUIStatus.Processing ? 'Processing' : 'Attention required';
      }
      return this.provisionStatus.status === ServiceUIStatus.Failed ? 'Failed' : 'Processing';
    }
    if (!this.isActive) {
      return 'Inactive';
    }
    return ServiceItem.isMicrosoft(this) ? 'Healthy' : 'Complete';
  }

  getProgress?(steps: ProvisioningChecklistItem[]): number {
    if (steps.length === 1 && ServiceItem.isMicrosoft(this)) {
      return steps[0].subItems.filter((item: HealthCheckItem) =>
        [ServiceUIStatus.Success, ServiceUIStatus.Warning].includes(item.status)).length / steps[0].subItems.length * 100;
    }
    let currentIndex = steps.findIndex(step => StatusItem.isIncomplete(step?.state.status));
    currentIndex     = currentIndex > -1 ? currentIndex : steps.length;
    return (currentIndex / steps.length) * 100;
  }

  getHaloClass?(progress: number): string {
    const isRedeploying = ServiceItem.isMicrosoft(this) && this.isRedeploying();
    if (this.provisionStatus.status === ServiceUIStatus.Failed) {
      return 'color-red';
    }
    if (progress < 100 || isRedeploying) {
      if (isRedeploying || this.provisionStatus.status === ServiceUIStatus.Processing || !ServiceItem.isMicrosoft(this)) {
        return 'color-yellow';
      }
      return 'color-orange';
    }
    return ServiceItem.isMicrosoft(this) && (this as MicrosoftTeams).isOrto ? 'color-orto-blue' : 'color-green';
  }

  static currentProvisioningStep(steps: ProvisioningChecklistItem[]): ProvisionStep {
    return steps.find(step => StatusItem.isIncomplete(step?.state?.status))?.type || steps[steps.length - 1]?.type;
  }

  static isWebex(service: ServiceItem): service is WebexCalling {
    return service?.serviceType === ServiceType.WebexCalling;
  }

  static isSipTrunk(service: ServiceItem): service is SIPTrunk {
    return service?.serviceType === ServiceType.SIPTrunk;
  }

  static isSipPhone(service: ServiceItem): service is SIPPhone {
    return service?.serviceType === ServiceType.SIPPhone;
  }

  static isMicrosoft(service: ServiceItem): service is MicrosoftTeams {
    return service?.serviceType === ServiceType.MicrosoftTeams;
  }

  static isCarrier(service: ServiceItem): service is ServiceCarrier {
    return service?.serviceType === ServiceType.Carrier;
  }

  constructor(protected data?: ServiceItem) {
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  isActive?(): boolean {
    return SUCCESS_STATUSES.includes(this.provisionStatus.status);
  }

  isRedeploying?(): boolean {
    return this.isActive() && [ProvisioningStatus.Processing, ProvisioningStatus.Pending].includes(this._provisioningStatus);
  }


  fromApiData?(apiData: ServiceItemRaw): ServiceItem {
    this.id                  = apiData.id;
    this.serviceType         = apiData.type;
    this.capabilities        = apiData.capabilities;
    this.name                = getServiceName(apiData.type, apiData.capabilities);
    this.label               = apiData.label;
    this.createdDate         = apiData.created_date;
    this.updatedDate         = apiData.updated_date;
    this._provisioningStatus = apiData.provisioning_status;

    return this.setProvisioningStatus(apiData);
  }

  private setProvisioningStatus?(statusData: ServiceItemRaw): ServiceItem {
    const status = statusData.status;
    let provisionStatus: ServiceUIStatus;
    switch (status) {
      case ServiceStatus.Active:
        provisionStatus = statusData.provisioning_status === ProvisioningStatus.Processing ?
          ServiceUIStatus.Processing :
          ServiceUIStatus.Success;
        break;
      case ServiceStatus.Suspended:
        provisionStatus = ServiceUIStatus.Suspended;
        break;
      case ServiceStatus.Provisioning:
        switch (statusData.provisioning_status) {
          case ProvisioningStatus.Failed:
            provisionStatus = ServiceUIStatus.Failed;
            break;
          case ProvisioningStatus.Pending:
          case ProvisioningStatus.Processing:
            provisionStatus = ServiceUIStatus.Processing;
            break;
          case ProvisioningStatus.Success:
            provisionStatus = ServiceUIStatus.Success;
            break;
          default:
            provisionStatus = ServiceUIStatus.Pending;
        }
        break;
      case ServiceStatus.Failed:
        provisionStatus = ServiceUIStatus.Failed;
        break;
      case ServiceStatus.Deleted:
        provisionStatus = ServiceUIStatus.Deleted;
        break;
      default:
        if (statusData.provisioning_status === ProvisioningStatus.Failed) {
          provisionStatus = ServiceUIStatus.Failed;
          break;
        }
        provisionStatus = (status as unknown as ServiceUIStatus) === ServiceUIStatus.Processing ? ServiceUIStatus.Processing : ServiceUIStatus.Pending;
    }

    this.provisionStatus = new StatusItem(provisionStatus, ProvisionStep.Configure, statusData.type);

    return this;
  }

  isProvisioning?(): boolean {
    return PROCESSING_STATUSES.includes(this.provisionStatus.status);
  }

  isSuspended?(): boolean {
    return this.provisionStatus.status === ServiceStatus.Suspended;
  }

}
