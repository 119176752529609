import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class ReportListQueryParams extends ListParams implements ListParamModel<ReportListQueryParams> {
  search?: string;
  createdDate?: string;

  static constructQueryString(searchParams: ReportListQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      search:      'filter[search]',
      createdDate: 'filter[created_date][since]',
    });
  }

  static queryChanged(newQuery: ReportListQueryParams, currentQuery: ReportListQueryParams): boolean {
    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery);
  }

  constructParams?(metaData: ListResponseMetadata): ReportListQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      this.search      = metaData.filter?.search;
      this.createdDate = metaData.filter?.created_date?.since;
    }

    return this;
  }
}
