<div mat-ripple fxLayout="row" fxLayoutAlign="start center" class="shadow-airbnb item cursor-pointer"
     (click)="dismiss(true)">
  <mat-icon svgIcon="cr-microsoft-teams"></mat-icon>
  <div fxLayout="column" fxLayoutAlign="center start" class="ml-16">
    <span class="mat-body-1--smaller fw-500"
          [ngClass]="textClass">TASK {{task.status === TaskStatus.Complete ? task.getOutcome().toUpperCase() : 'STARTED'}}</span>
    <p class="mat-body-1--smaller fw-500 mb-4">{{task.getName()}}</p>
    <span class="mat-body-1--smaller color-light-gray" *ngIf="task.status !== TaskStatus.Complete">Click to track progress</span>
  </div>
  <mat-progress-bar [value]="100" class="item__progress" [color]="task.getBarColor()"></mat-progress-bar>
  <button mat-icon-button (click)="dismiss()" class="item__close">
    <mat-icon svgIcon="cr-close"></mat-icon>
  </button>
</div>
