export enum ServiceStatus {
  Active       = 'ACTIVE',
  Inactive     = 'INACTIVE',
  Provisioning = 'PROVISIONING',
  Failed       = 'FAILED',
  Suspended    = 'SUSPENDED',
  Unavailable  = 'UNAVAILABLE',
  Disabled     = 'DISABLED',
  Deleted      = 'DELETED'
}
