export class Balance {
  secondsLeft: number;
  minutesLeft: number;
  remainingBalance: number;
  remainingBalanceRaw: string;

  fromApiData?(apiData: Array<{ type: 'MONETARY' | 'SECONDS', value: string }>): Balance {
    const secondData   = apiData.find(a => a.type === 'SECONDS');
    const monetaryData = apiData.find(a => a.type === 'MONETARY');

    if (secondData) {
      this.secondsLeft = Math.floor(parseInt(secondData.value, 10));
      this.minutesLeft = Math.floor(this.secondsLeft / 60);
    }
    if (monetaryData) {
      this.remainingBalance    = +monetaryData.value;
      this.remainingBalanceRaw = monetaryData.value;
    }

    return this;
  }
}
