import { ListResponseMetadata } from '../api/list-response-metadata.model';
import { ListParams }           from './list-params.model';
import { ListParamModel }       from './list-param-model';

export class RolesQueryParams extends ListParams implements ListParamModel<RolesQueryParams> {
  search?: string;
  include?: string[];

  static constructQueryString(searchParams: RolesQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      search:  'filter[search]',
      include: 'include[]'
    });
  }

  static queryChanged(newQuery: RolesQueryParams, currentQuery: RolesQueryParams): boolean {

    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery) ||
      newQuery.search !== currentQuery.search;

  }

  constructParams?(metaData: ListResponseMetadata): RolesQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter?.search) {
      this.search = metaData.filter.search;
    }
    if (metaData.include) {
      this.include = metaData.include;
    }

    return this;
  }
}
