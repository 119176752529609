<div class="page-nav text-align-center">
  <ng-container *ngFor="let nav of navItems; trackBy: trackBy">
    <button [class]="'nav-item ' + nav.class" [name]="nav.label"
            [routerLink]="nav.link"
            *ngIf="nav.hasScope"
            data-cy="menu-item"
            routerLinkActive="primary-bg"
            (click)="navigateToPage($event, nav.link)" matRipple>
      <mat-icon svgIcon="{{nav.icon}}" class="color-white"></mat-icon>
      <p class="m-0"><small class="color-white">{{nav.label}}</small></p>
    </button>
  </ng-container>
  <div class="nav-item__admin-container">
    <button class="nav-item"
            fxHide.lt-md
            [matMenuTriggerFor]="helpMenu"
            data-cy="menu-item" matRipple>
      <mat-icon svgIcon="cr-help-outline" class="color-white"></mat-icon>
      <p class="m-0"><small class="color-white">Help</small></p>
    </button>
    <mat-menu #helpMenu="matMenu" xPosition="after" class="help-menu" [overlapTrigger]="false">
      <a href="https://help.callroute.com/space/CKB/698777623/Release+Notes" target="_blank">
        <button mat-menu-item fxLayout="row" fxLayoutAlign="start center">
          <mat-icon svgIcon="cr-file-lines" class="mr-10"></mat-icon>
          <span class="mt-1">Release notes</span>
        </button>
      </a>
      <a href="https://help.callroute.com/space/CKB" target="_blank">
        <button mat-menu-item fxLayout="row" fxLayoutAlign="start center">
          <mat-icon svgIcon="cr-help-circle" class="mr-10"></mat-icon>
          <span class="mt-1">Knowledge base</span>
        </button>
      </a>
    </mat-menu>
    <button class="nav-item nav-item__admin" routerLinkActive="primary-bg"
            data-cy="menu-item" name="admin"
            routerLink="/admin"
            (click)="navigateToPage($event, ['/admin'])" matRipple>
      <mat-icon svgIcon="cr-admin-filled" class="color-white"></mat-icon>
      <p class="m-0"><small class="color-white">Admin</small></p>
    </button>
  </div>
</div>


