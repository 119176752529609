import { Injectable }                 from '@angular/core';
import { FormGroup }                  from '@angular/forms';
import { PaginationFormModel }        from '@models/form/pagination-form.model';
import { QueryService }               from '@services/abstract/query-service.class';
import { TaskQueryParams }            from '@models/form/task-query-params.model';
import { StoreState }                 from '@redux/store';
import { Store }                      from '@ngrx/store';
import { FetchTaskListRequestAction } from '@redux/audit/audit.actions';
import { TaskSchema }                 from '@enums/task-schema.enum';

@Injectable({
  providedIn: 'root',
})
export class TaskQueryService implements QueryService<TaskQueryParams> {

  constructor(private store: Store<StoreState>) { }

  fetchList(queryParams: TaskQueryParams): void {
    this.store.dispatch(FetchTaskListRequestAction({
      queryParams,
    }));
  }

  updateSort(filterForm: FormGroup, sortId: string, canSort: boolean, curParams: TaskQueryParams): void {
    if (!canSort) {
      return;
    }
    this.fetchList(
      this.getQueryParams(filterForm, curParams.pageSize, 1, sortId),
    );
  }

  updatePageQuery(filterForm: FormGroup, params: PaginationFormModel, curParams: TaskQueryParams): void {
    this.fetchList(
      this.getQueryParams(filterForm, params.pageSize, params.pageNumber, curParams.sort),
    );
  }

  getQueryParams(filterForm: FormGroup, pageSize: number, pageNumber: number, sort?: string): TaskQueryParams {
    const s: TaskQueryParams = new TaskQueryParams();

    s.pageSize   = pageSize;
    s.pageNumber = pageNumber;
    s.sort       = sort;
    s.include    = ['activity_date'];
    s.search     = filterForm.get('search').value;
    const schema = filterForm.get('schema').value;
    s.schema     = schema ?
      (
        Object.keys(TaskSchema)
          .includes(schema) ?
          TaskSchema[filterForm.get('schema').value as keyof typeof TaskSchema] :
          schema
      ) :
      undefined;
    s.include    = ['audit', 'schema', 'summary', 'messages'];


    return s;
  }

}
