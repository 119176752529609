import { KeyValuePair } from './key-value-pair.model';

export class KeyValueCollection<T> {

  private items: KeyValuePair<T>[] = [];

  add(key: string, value: T): void {
    (this as any)[key] = value;
    this.items.push({ key, value });
  }

  getItems(): KeyValuePair<T>[] {
    return this.items;
  }

  hasKey(key: string): boolean {
    return Object.hasOwnProperty.call(this, key);
  }

  value(key: string): T {
    let value = null;

    if (key) {
      value = (this as any)[key];
    }

    return value;
  }

}
