import {
  ACCEPT_COMPANY_INVITE_REQUEST,
  ACCEPT_COMPANY_INVITE_RESPONSE,
  ASSIGN_USER_ROLE_REQUEST,
  ASSIGN_USER_ROLE_RESPONSE,
  ASSIGN_USER_SECURITY_GROUP_REQUEST,
  ASSIGN_USER_SECURITY_GROUP_RESPONSE,
  AUTHORISE_MICROSOFT_SSO_REQUEST,
  AUTHORISE_MICROSOFT_SSO_RESPONSE,
  AUTHORISE_RESPONSE,
  CLEAR_REDIRECT_URL,
  CLEAR_SCRATCH_CODE,
  CLOSE_SESSION_EXPIRY_MODAL,
  CREATE_API_KEY_REQUEST,
  CREATE_API_KEY_RESPONSE,
  CREATE_ROLE_REQUEST,
  CREATE_ROLE_RESPONSE,
  DELETE_API_KEY_REQUEST,
  DELETE_API_KEY_RESPONSE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_RESPONSE,
  DELETE_SECURITY_GROUP_REQUEST,
  DELETE_SECURITY_GROUP_RESPONSE,
  DISABLE_OTP_REQUEST,
  DISABLE_OTP_RESPONSE,
  ENABLE_OTP_CONFIRM_REQUEST,
  ENABLE_OTP_CONFIRM_RESPONSE,
  ENABLE_OTP_REQUEST,
  ENABLE_OTP_RESPONSE,
  FETCH_API_KEY_REQUEST,
  FETCH_API_KEY_RESPONSE,
  FETCH_API_KEYS_REQUEST,
  FETCH_API_KEYS_RESPONSE,
  FETCH_APPROVAL_LIST_REQUEST,
  FETCH_APPROVAL_LIST_RESPONSE,
  FETCH_COMPANY_DOMAIN_LIST_REQUEST,
  FETCH_COMPANY_DOMAIN_LIST_RESPONSE,
  FETCH_COMPANY_INVITE_DESCRIPTION_REQUEST,
  FETCH_COMPANY_INVITE_DESCRIPTION_RESPONSE,
  FETCH_ROLE_ASSIGNMENT_COUNT_REQUEST,
  FETCH_ROLE_ASSIGNMENT_COUNT_RESPONSE,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_RESPONSE,
  FETCH_SCOPES_REQUEST,
  FETCH_SCOPES_RESPONSE,
  FETCH_SECURITY_GROUP_LIST_REQUEST,
  FETCH_SECURITY_GROUP_LIST_RESPONSE,
  FETCH_SECURITY_GROUP_REQUEST,
  FETCH_SECURITY_GROUP_RESPONSE,
  FETCH_TOKEN_INTROSPECT_REQUEST,
  FETCH_TOKEN_INTROSPECT_RESPONSE,
  FETCH_USER_COUNTS_REQUEST,
  FETCH_USER_COUNTS_RESPONSE,
  FETCH_USER_IDP_IDENTITIES_REQUEST,
  FETCH_USER_IDP_IDENTITIES_RESPONSE,
  FETCH_USER_PROFILE_REQUEST,
  FETCH_USER_PROFILE_RESPONSE,
  LOGIN_WITH_REDIRECT,
  LOGOUT,
  MANUAL_UPDATE_TOKEN_DATA,
  MARK_SESSION_EXPIRY_MODAL_OPEN,
  OAUTH2_CALLBACK,
  PATCH_API_KEY_REQUEST,
  PATCH_API_KEY_RESPONSE,
  PATCH_SECURITY_GROUP_REQUEST,
  PATCH_SECURITY_GROUP_RESPONSE,
  PATCH_USER_PROFILE_REQUEST,
  PATCH_USER_PROFILE_RESPONSE,
  POST_SECURITY_GROUP_REQUEST,
  POST_SECURITY_GROUP_RESPONSE,
  PUT_ROLE_REQUEST,
  PUT_ROLE_RESPONSE,
  REFRESH_ACCESS_TOKEN_REQUEST,
  REFRESH_ACCESS_TOKEN_RESPONSE,
  REFRESH_SESSION_DATA_REQUEST,
  REFRESH_SESSION_DATA_RESPONSE,
  REQUEST_RESET_PASSWORD_REQUEST,
  REQUEST_RESET_PASSWORD_RESPONSE,
  RESEND_USER_INVITE_REQUEST,
  RESEND_USER_INVITE_RESPONSE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_RESPONSE,
  SET_COMPANY_HEADER,
  SET_PAGE_TITLE,
  SET_REDIRECT_URL,
  SHOW_SESSION_EXPIRY_MODAL,
  UPDATE_COMPANY_DOMAIN_REQUEST,
  UPDATE_COMPANY_DOMAIN_RESPONSE,
  USER_APPROVAL_REQUEST,
  USER_APPROVAL_RESPONSE,
  USERS_BULK_REQUEST,
  USERS_BULK_RESPONSE,
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_RESPONSE,
}                                                from '@redux/auth/auth.types';
import { SetCompanyHeader }                      from '@models/api/set-company-header.model';
import { PutRoleResponse }                       from '@models/api/put-role-response.model';
import { FetchUserProfileRequest }               from '@models/api/fetch-user-profile-request.model';
import { AssignUserRoleResponse }                from '@models/api/assign-user-role-response.model';
import { ResetPasswordResponse }                 from '@models/api/reset-password-response.model';
import { FetchRolesResponse }                    from '@models/api/fetch-roles-response.model';
import { FetchUserProfileResponse }              from '@models/api/fetch-user-profile-response.model';
import { FetchScopesResponse }                   from '@models/api/fetch-scopes-response.model';
import { AuthoriseResponse }                     from '@models/api/authorise-response.model';
import { SetPageTitleRequest }                   from '@models/api/set-page-title-request.model';
import { createAction, props }                   from '@ngrx/store';
import { CreateRoleRequest }                     from '@models/api/create-role-request.model';
import { FetchRoleAssignmentCountResponse }      from '@models/api/fetch-role-assignment-count-response.model';
import { BaseRequest, BaseResponse }             from '@redux/helpers/reducer.helper';
import { FetchRoleAssignmentCountRequest }       from '@models/api/fetch-role-assignment-count-request.model';
import { CreateRoleResponse }                    from '@models/api/create-role-response.model';
import { PutRoleRequest }                        from '@models/api/put-role-request.model';
import { AssignUserRoleRequest }                 from '@models/api/assign-user-role-request.model';
import { FetchCompanyInviteDescriptionRequest }  from '@models/api/fetch-company-invite-description-request.model';
import { ResetPasswordRequest }                  from '@models/api/reset-password-request.model';
import { Oauth2Payload }                         from '@models/api/oauth2-payload.model';
import { ValidateTokenResponse }                 from '@models/api/validate-token-response.model';
import { PatchUserProfileResponse }              from '@models/api/patch-user-profile-response.model';
import { AcceptCompanyInviteResponse }           from '@models/api/accept-company-invite-response.model';
import { EnableOTPConfirmRequest }               from '@models/api/enable-otp-confirm-request.model';
import { DeleteRoleRequest }                     from '@models/api/delete-role-request.model';
import { FetchRolesRequest }                     from '@models/api/fetch-roles-request.model';
import { FetchCompanyInviteDescriptionResponse } from '@models/api/fetch-company-invite-description-response.model';
import { DisableOTPRequest }                     from '@models/api/disable-otp-request.model';
import { PatchUserProfileRequest }               from '@models/api/patch-user-profile-request.model';
import { AcceptCompanyInviteRequest }            from '@models/api/accept-company-invite-request.model';
import { DeleteRoleResponse }                    from '@models/api/delete-role-response.model';
import { RequestResetPasswordResponse }          from '@models/api/request-reset-password-response.model';
import { RequestResetPasswordRequest }           from '@models/api/request-reset-password-request.model';
import { EnableOTPConfirmResponse }              from '@models/api/enable-otp-confirm-response.model';
import { EnableOTPResponse }                     from '@models/api/enable-otp-response.model';
import { EnableOTPRequest }                      from '@models/api/enable-otp-request.model';
import { DisableOTPResponse }                    from '@models/api/disable-otp-response.model';
import { FetchAPIKeysRequest }                   from '@models/api/identity/fetch-api-keys-request.model';
import { FetchAPIKeysResponse }                  from '@models/api/identity/fetch-api-keys-response.model';
import { CreateApiKeyRequest }                   from '@models/api/identity/create-api-key-request.model';
import { CreateApiKeyResponse }                  from '@models/api/identity/create-api-key-response.model';
import { FetchAPIKeyRequest }                    from '@models/api/identity/fetch-api-key-request.model';
import { FetchAPIKeyResponse }                   from '@models/api/identity/fetch-api-key-response.model';
import { PatchApiKeyRequest }                    from '@models/api/identity/patch-api-key-request.model';
import { PatchApiKeyResponse }                   from '@models/api/identity/patch-api-key-response.model';
import { DeleteApiKeyRequest }                   from '@models/api/identity/delete-api-key-request.model';
import { DeleteApiKeyResponse }                  from '@models/api/identity/delete-api-key-response.model';
import { AuthoriseMicrosoftSSORequest }          from '@models/api/identity/authorise-microsoft-sso-request.model';
import { AuthoriseMicrosoftSSOResponse }         from '@models/api/identity/authorise-microsoft-sso-response.model';
import { FetchCompanyDomainListRequest }         from '@models/api/identity/fetch-company-domain-list-request.model';
import { FetchCompanyDomainListResponse }        from '@models/api/identity/fetch-company-domain-list-response.model';
import { UpdateCompanyDomainRequest }            from '@models/api/identity/update-company-domain-request.model';
import { UpdateCompanyDomainResponse }           from '@models/api/identity/update-company-domain-response.model';
import { FetchUserIdpIdentitiesRequest }         from '@models/api/identity/fetch-user-idp-identities-request.model';
import { FetchUserIdpIdentitiesResponse }        from '@models/api/identity/fetch-user-idp-identities-response.model';
import { FetchApprovalListRequest }              from '@models/api/identity/fetch-approval-list-request.model';
import { FetchApprovalListResponse }             from '@models/api/identity/fetch-approval-list-response.model';
import { UserApprovalRequest }                   from '@models/api/identity/user-approval-request.model';
import { UserApprovalResponse }                  from '@models/api/identity/user-approval-response.model';

import { FetchUserCountsRequest }          from '@models/api/identity/fetch-user-counts-request.model';
import { FetchUserCountsResponse }         from '@models/api/identity/fetch-user-counts-response.model';
import { UsersBulkRequest }                from '@models/api/identity/users-bulk-request.model';
import { UsersBulkResponse }               from '@models/api/identity/users-bulk-response.model';
import { FetchTokenIntrospectRequest }     from '@models/api/identity/fetch-token-introspect-request.model';
import { FetchTokenIntrospectResponse }    from '@models/api/identity/fetch-token-introspect-response.model';
import { ResendUserInviteRequest }         from '@models/api/identity/resend-user-invite-request.model';
import { ResendUserInviteResponse }        from '@models/api/identity/resend-user-invite-response.model';
import { FetchSecurityGroupListRequest }   from '@models/api/identity/fetch-security-group-list-request.model';
import { FetchSecurityGroupListResponse }  from '@models/api/identity/fetch-security-group-list-response.model';
import { FetchSecurityGroupRequest }       from '@models/api/identity/fetch-security-group-request.model';
import { FetchSecurityGroupResponse }      from '@models/api/identity/fetch-security-group-response.model';
import { PostSecurityGroupRequest }        from '@models/api/identity/post-security-group-request.model';
import { PostSecurityGroupResponse }       from '@models/api/identity/post-security-group-response.model';
import { PatchSecurityGroupRequest }       from '@models/api/identity/patch-security-group-request.model';
import { PatchSecurityGroupResponse }      from '@models/api/identity/patch-security-group-response.model';
import { DeleteSecurityGroupRequest }      from '@models/api/identity/delete-security-group-request.model';
import { DeleteSecurityGroupResponse }     from '@models/api/identity/delete-security-group-response.model';
import { AssignUserSecurityGroupRequest }  from '@models/api/identity/assign-user-security-group-request.model';
import { AssignUserSecurityGroupResponse } from '@models/api/identity/assign-user-security-group-response.model';


export const LoginWithRedirect = createAction(LOGIN_WITH_REDIRECT);

export const OAuth2Callback = createAction(OAUTH2_CALLBACK, props<Oauth2Payload>());

export const AuthoriseResponseAction = createAction(AUTHORISE_RESPONSE, props<AuthoriseResponse>());

export const ValidateTokenRequestAction  = createAction(VALIDATE_TOKEN_REQUEST);
export const ValidateTokenResponseAction = createAction(VALIDATE_TOKEN_RESPONSE, props<ValidateTokenResponse>());

export const CreateRoleRequestAction  = createAction(CREATE_ROLE_REQUEST, props<CreateRoleRequest>());
export const CreateRoleResponseAction = createAction(CREATE_ROLE_RESPONSE, props<CreateRoleResponse>());

export const PutRoleRequestAction  = createAction(PUT_ROLE_REQUEST, props<PutRoleRequest>());
export const PutRoleResponseAction = createAction(PUT_ROLE_RESPONSE, props<PutRoleResponse>());

export const FetchScopesRequestAction  = createAction(FETCH_SCOPES_REQUEST, props<BaseRequest>());
export const FetchScopesResponseAction = createAction(FETCH_SCOPES_RESPONSE, props<FetchScopesResponse>());

export const FetchRolesRequestAction  = createAction(FETCH_ROLES_REQUEST, props<FetchRolesRequest>());
export const FetchRolesResponseAction = createAction(FETCH_ROLES_RESPONSE, props<FetchRolesResponse>());

export const DeleteRoleRequestAction  = createAction(DELETE_ROLE_REQUEST, props<DeleteRoleRequest>());
export const DeleteRoleResponseAction = createAction(DELETE_ROLE_RESPONSE, props<DeleteRoleResponse>());

export const FetchAPIKeysRequestAction  = createAction(FETCH_API_KEYS_REQUEST, props<FetchAPIKeysRequest>());
export const FetchAPIKeysResponseAction = createAction(FETCH_API_KEYS_RESPONSE, props<FetchAPIKeysResponse>());

export const FetchAPIKeyRequestAction  = createAction(FETCH_API_KEY_REQUEST, props<FetchAPIKeyRequest>());
export const FetchAPIKeyResponseAction = createAction(FETCH_API_KEY_RESPONSE, props<FetchAPIKeyResponse>());

export const FetchRoleAssignmentCountRequestAction  = createAction(FETCH_ROLE_ASSIGNMENT_COUNT_REQUEST, props<FetchRoleAssignmentCountRequest>());
export const FetchRoleAssignmentCountResponseAction = createAction(FETCH_ROLE_ASSIGNMENT_COUNT_RESPONSE, props<FetchRoleAssignmentCountResponse>());

export const FetchUserProfileRequestAction  = createAction(FETCH_USER_PROFILE_REQUEST, props<FetchUserProfileRequest>());
export const FetchUserProfileResponseAction = createAction(FETCH_USER_PROFILE_RESPONSE, props<FetchUserProfileResponse>());

export const PatchUserProfileRequestAction  = createAction(PATCH_USER_PROFILE_REQUEST, props<PatchUserProfileRequest>());
export const PatchUserProfileResponseAction = createAction(PATCH_USER_PROFILE_RESPONSE, props<PatchUserProfileResponse>());

export const AssignUserRoleRequestAction  = createAction(ASSIGN_USER_ROLE_REQUEST, props<AssignUserRoleRequest>());
export const AssignUserRoleResponseAction = createAction(ASSIGN_USER_ROLE_RESPONSE, props<AssignUserRoleResponse>());

export const RequestResetPasswordRequestAction  = createAction(REQUEST_RESET_PASSWORD_REQUEST, props<RequestResetPasswordRequest>());
export const RequestResetPasswordResponseAction = createAction(REQUEST_RESET_PASSWORD_RESPONSE, props<RequestResetPasswordResponse>());

export const ResetPasswordRequestAction  = createAction(RESET_PASSWORD_REQUEST, props<ResetPasswordRequest>());
export const ResetPasswordResponseAction = createAction(RESET_PASSWORD_RESPONSE, props<ResetPasswordResponse>());

export const RefreshSessionDataRequest  = createAction(REFRESH_SESSION_DATA_REQUEST);
export const RefreshSessionDataResponse = createAction(REFRESH_SESSION_DATA_RESPONSE);

export const FetchCompanyInviteDescriptionRequestAction  = createAction(FETCH_COMPANY_INVITE_DESCRIPTION_REQUEST, props<FetchCompanyInviteDescriptionRequest>());
export const FetchCompanyInviteDescriptionResponseAction = createAction(FETCH_COMPANY_INVITE_DESCRIPTION_RESPONSE, props<FetchCompanyInviteDescriptionResponse>());

export const EnableOTPRequestAction  = createAction(ENABLE_OTP_REQUEST, props<EnableOTPRequest>());
export const EnableOTPResponseAction = createAction(ENABLE_OTP_RESPONSE, props<EnableOTPResponse>());

export const EnableOTPConfirmRequestAction  = createAction(ENABLE_OTP_CONFIRM_REQUEST, props<EnableOTPConfirmRequest>());
export const EnableOTPConfirmResponseAction = createAction(ENABLE_OTP_CONFIRM_RESPONSE, props<EnableOTPConfirmResponse>());

export const DisableOTPRequestAction  = createAction(DISABLE_OTP_REQUEST, props<DisableOTPRequest>());
export const DisableOTPResponseAction = createAction(DISABLE_OTP_RESPONSE, props<DisableOTPResponse>());

export const ClearScratchCodeAction = createAction(CLEAR_SCRATCH_CODE);

export const AcceptCompanyInviteRequestAction  = createAction(ACCEPT_COMPANY_INVITE_REQUEST, props<AcceptCompanyInviteRequest>());
export const AcceptCompanyInviteResponseAction = createAction(ACCEPT_COMPANY_INVITE_RESPONSE, props<AcceptCompanyInviteResponse>());

export const CreateAPIKeyRequestAction  = createAction(CREATE_API_KEY_REQUEST, props<CreateApiKeyRequest>());
export const CreateAPIKeyResponseAction = createAction(CREATE_API_KEY_RESPONSE, props<CreateApiKeyResponse>());

export const PatchAPIKeyRequestAction  = createAction(PATCH_API_KEY_REQUEST, props<PatchApiKeyRequest>());
export const PatchAPIKeyResponseAction = createAction(PATCH_API_KEY_RESPONSE, props<PatchApiKeyResponse>());

export const DeleteAPIKeyRequestAction  = createAction(DELETE_API_KEY_REQUEST, props<DeleteApiKeyRequest>());
export const DeleteAPIKeyResponseAction = createAction(DELETE_API_KEY_RESPONSE, props<DeleteApiKeyResponse>());

export const SetCompanyHeaderAction              = createAction(SET_COMPANY_HEADER, props<SetCompanyHeader>());
export const ManualUpdateTokenData               = createAction(MANUAL_UPDATE_TOKEN_DATA, props<ValidateTokenResponse>());
export const SetPageTitle                        = createAction(SET_PAGE_TITLE, props<SetPageTitleRequest>());
export const ShowSessionExpiryModal              = createAction(SHOW_SESSION_EXPIRY_MODAL);
export const MarkSessionExpiryModalOpen          = createAction(MARK_SESSION_EXPIRY_MODAL_OPEN);
export const CloseSessionExpiryModal             = createAction(CLOSE_SESSION_EXPIRY_MODAL);
export const ClearRedirectURL                    = createAction(CLEAR_REDIRECT_URL);
export const SetRedirectURL                      = createAction(SET_REDIRECT_URL, props<{ redirectUrl: string }>());
export const RefreshAccessTokenRequestAction     = createAction(REFRESH_ACCESS_TOKEN_REQUEST, props<BaseRequest>());
export const RefreshAccessTokenResponseAction    = createAction(REFRESH_ACCESS_TOKEN_RESPONSE, props<BaseResponse>());
export const LogoutAction                        = createAction(LOGOUT, props<{
  redirectUrl?: string,
  skipNavigation?: boolean
}>());
export const AuthoriseMicrosoftSSORequestAction  = createAction(AUTHORISE_MICROSOFT_SSO_REQUEST, props<AuthoriseMicrosoftSSORequest>());
export const AuthoriseMicrosoftSSOResponseAction = createAction(AUTHORISE_MICROSOFT_SSO_RESPONSE, props<AuthoriseMicrosoftSSOResponse>());

export const FetchCompanyDomainListRequestAction  = createAction(FETCH_COMPANY_DOMAIN_LIST_REQUEST, props<FetchCompanyDomainListRequest>());
export const FetchCompanyDomainListResponseAction = createAction(FETCH_COMPANY_DOMAIN_LIST_RESPONSE, props<FetchCompanyDomainListResponse>());

export const UpdateCompanyDomainRequestAction  = createAction(UPDATE_COMPANY_DOMAIN_REQUEST, props<UpdateCompanyDomainRequest>());
export const UpdateCompanyDomainResponseAction = createAction(UPDATE_COMPANY_DOMAIN_RESPONSE, props<UpdateCompanyDomainResponse>());

export const FetchUserIDPIdentitiesRequestAction  = createAction(FETCH_USER_IDP_IDENTITIES_REQUEST, props<FetchUserIdpIdentitiesRequest>());
export const FetchUserIDPIdentitiesResponseAction = createAction(FETCH_USER_IDP_IDENTITIES_RESPONSE, props<FetchUserIdpIdentitiesResponse>());

export const FetchApprovalListRequestAction  = createAction(FETCH_APPROVAL_LIST_REQUEST, props<FetchApprovalListRequest>());
export const FetchApprovalListResponseAction = createAction(FETCH_APPROVAL_LIST_RESPONSE, props<FetchApprovalListResponse>());

export const UserApprovalRequestAction  = createAction(USER_APPROVAL_REQUEST, props<UserApprovalRequest>());
export const UserApprovalResponseAction = createAction(USER_APPROVAL_RESPONSE, props<UserApprovalResponse>());

export const FetchUserCountsRequestAction  = createAction(FETCH_USER_COUNTS_REQUEST, props<FetchUserCountsRequest>());
export const FetchUserCountsResponseAction = createAction(FETCH_USER_COUNTS_RESPONSE, props<FetchUserCountsResponse>());

export const UsersBulkRequestAction  = createAction(USERS_BULK_REQUEST, props<UsersBulkRequest>());
export const UsersBulkResponseAction = createAction(USERS_BULK_RESPONSE, props<UsersBulkResponse>());

export const FetchTokenIntrospectRequestAction  = createAction(FETCH_TOKEN_INTROSPECT_REQUEST, props<FetchTokenIntrospectRequest>());
export const FetchTokenIntrospectResponseAction = createAction(FETCH_TOKEN_INTROSPECT_RESPONSE, props<FetchTokenIntrospectResponse>());

export const ResendUserInviteRequestAction  = createAction(RESEND_USER_INVITE_REQUEST, props<ResendUserInviteRequest>());
export const ResendUserInviteResponseAction = createAction(RESEND_USER_INVITE_RESPONSE, props<ResendUserInviteResponse>());

export const FetchSecurityGroupListRequestAction  = createAction(FETCH_SECURITY_GROUP_LIST_REQUEST, props<FetchSecurityGroupListRequest>());
export const FetchSecurityGroupListResponseAction = createAction(FETCH_SECURITY_GROUP_LIST_RESPONSE, props<FetchSecurityGroupListResponse>());

export const FetchSecurityGroupRequestAction  = createAction(FETCH_SECURITY_GROUP_REQUEST, props<FetchSecurityGroupRequest>());
export const FetchSecurityGroupResponseAction = createAction(FETCH_SECURITY_GROUP_RESPONSE, props<FetchSecurityGroupResponse>());

export const PostSecurityGroupRequestAction  = createAction(POST_SECURITY_GROUP_REQUEST, props<PostSecurityGroupRequest>());
export const PostSecurityGroupResponseAction = createAction(POST_SECURITY_GROUP_RESPONSE, props<PostSecurityGroupResponse>());

export const PatchSecurityGroupRequestAction  = createAction(PATCH_SECURITY_GROUP_REQUEST, props<PatchSecurityGroupRequest>());
export const PatchSecurityGroupResponseAction = createAction(PATCH_SECURITY_GROUP_RESPONSE, props<PatchSecurityGroupResponse>());

export const DeleteSecurityGroupRequestAction  = createAction(DELETE_SECURITY_GROUP_REQUEST, props<DeleteSecurityGroupRequest>());
export const DeleteSecurityGroupResponseAction = createAction(DELETE_SECURITY_GROUP_RESPONSE, props<DeleteSecurityGroupResponse>());

export const AssignUserSecurityGroupRequestAction  = createAction(ASSIGN_USER_SECURITY_GROUP_REQUEST, props<AssignUserSecurityGroupRequest>());
export const AssignUserSecurityGroupResponseAction = createAction(ASSIGN_USER_SECURITY_GROUP_RESPONSE, props<AssignUserSecurityGroupResponse>());
