import { CarrierAdapter }                                                     from './carrier.adapter';
import {
  ServiceCarrier,
}                                                                             from '@models/entity/carrier-service.model';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  ConfigurableCarrierInput,
}                                                                             from '@enums/configurable-carrier-input.enum';

export class BYODAdapter extends CarrierAdapter {
  constructor(protected data: ServiceCarrier) {
    super(data);
  }

  getForm?(): UntypedFormGroup {

    const form = super.getForm();
    const data = this.data;

    if (data.carrierData.inputs.includes(ConfigurableCarrierInput.Gateways)) {

      form.addControl('gateways', new UntypedFormArray([
        new UntypedFormGroup({
          priority: new UntypedFormControl(1),
          address:  this.newIPAddrCtrl(data.carrierData.identifier !== 'custom' || data.id ? data?.gateways?.[0]?.address : null, true, true),
          protocol: new UntypedFormControl(data.carrierData.identifier !== 'custom' || data.id ? data?.gateways?.[0]?.protocol : null, Validators.required),
          port:     new UntypedFormControl(data.carrierData.identifier !== 'custom' || data.id ? data?.gateways?.[0]?.port : null, [Validators.required, Validators.min(1), Validators.max(65535)]),
        })]));

    }

    return form;
  }
}
