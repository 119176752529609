import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Task }                                   from '@models/entity/task.model';
import { TaskStatus }                             from '@enums/task-status.enum';
import { trackBy }                                from '@util/trackBy';
import { TaskMessageType }                        from '@enums/task-message-type.enum';
import { TaskSchema }                             from '@enums/task-schema.enum';

@Component({
  selector:    'ngx-task',
  templateUrl: './task.component.html',
  styleUrls:   ['./task.component.scss'],
})
export class TaskComponent {
  @Input() task: Task;
  @Input() cancelPending: boolean;
  @Input() showDebug: boolean;
  @Input() hasAdminAudit: boolean;
  @Input() hasReportRead: boolean;
  @Input() userId: string;
  @Output() cancel = new EventEmitter<{ id: string, name: string }>();
  @Output() close  = new EventEmitter<void>();
  TaskSchema       = TaskSchema;

  get hasExportedReport(): boolean {
    return [
      TaskSchema.NumberExport,
      TaskSchema.NumberImport,
      TaskSchema.CdrExport,
      TaskSchema.ServiceUserExport].includes(this.task.schema.identifier);
  }

  get isIncomplete(): boolean {
    if (!this.task) {
      return false;
    }
    return this.task.status !== TaskStatus.Complete;
  }

  get canCancel(): boolean {
    return this.hasAdminAudit || this.task?.audit?.identity?.id === this.userId;
  }

  get status(): string {
    switch (this.task.status) {
      case TaskStatus.Complete:
        return this.task.getOutcome();
      case TaskStatus.Processing:
        return 'Processing';
      case TaskStatus.Pending:
        return 'Pending';
    }
  }

  getProgress(summary: { processedCount: number, totalCount: number }): number {
    if (!summary.totalCount) {
      return 100;
    }
    return Math.min(Math.round((summary.processedCount || 0) / (summary.totalCount || 0) * 100), 100);
  }

  trackBy = trackBy<{
    processedCount: number,
    totalCount: number,
    name: string,
    description: string,
    isInternal: boolean
  }>('name');

  constructor() { }

  getDescription(description: string): string {
    if (!description || !description.includes(';')) {
      return '';
    }
    return description.split('; ')[1];
  }

  getFailures(subTaskName: string): string {

    const messages = this.formatMessages(subTaskName, TaskMessageType.Error);

    if (!messages?.length) {
      return null;
    }

    return 'Failures: \n• '.concat(messages.join('\n• '));
  }

  getInfo(subTaskName: string): string {
    const infoTypes    = [TaskMessageType.Info,
                          TaskMessageType.Notice];
    const infoMessages = this.formatMessages(
      subTaskName,
      ...infoTypes,
    );

    if (!infoMessages?.length) {
      return null;
    }
    return (infoMessages?.length ? ('Info: \n• '.concat(infoMessages.join('\n• '))) : '');
  }

  getWarnings(subTaskName: string): string {
    const warningTypes = [TaskMessageType.Warning];

    const warningMessages = this.formatMessages(
      subTaskName,
      ...warningTypes,
    );

    if (!warningMessages?.length) {
      return null;
    }

    return (warningMessages?.length ? ('Warnings: \n• '.concat(
      warningMessages.join('\n• '))) : '');
  }

  getDebugWarnings(subTaskName: string): string {
    const warningTypes = [TaskMessageType.Debug];

    const messages = this.formatMessages(
      subTaskName,
      ...warningTypes,
    );

    if (!messages?.length) {
      return null;
    }

    return 'Warnings (DEBUG): \n• '.concat(messages.join('\n• '));
  }

  private formatMessages(subTaskName: string, ...messageTypes: TaskMessageType[]): string[] {
    return this.task.messages?.filter(message =>
      message.job.name === subTaskName && messageTypes.includes(message.type))
      .map(message => {
        if (message.identifier) {
          return `${ message.identifier } - ${ message.message }`;
        }
        return message.message;
      });
  }
}
