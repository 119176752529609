import { TeamRaw }    from '@models/api/microsoft-teams/team-raw.model';
import { TeamSource } from '@models/entity/team-source.model';

export class Team {
  id: string;
  name: string;
  description: string;
  createdDate: string;
  updatedDate: string;
  source: TeamSource;

  constructor(private data?: Team) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: TeamRaw): Team {
    this.id          = data.id;
    this.name        = data.name;
    this.description = data.description;
    this.createdDate = data.created_date;
    this.updatedDate = data.updated_date;
    this.source      = data.source;
    return this;
  }
}
