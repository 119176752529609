import { SummaryByDirectionDataRaw } from '@models/api/summary-by-direction-data-raw.model';

export class SummaryByDirectionData {
  inCount: number;
  outCount: number;
  onCount: number;
  totalCount: number;

  static fromApiData(apiData: SummaryByDirectionDataRaw): SummaryByDirectionData {
    const s: SummaryByDirectionData = new SummaryByDirectionData();

    s.inCount    = apiData.in_count;
    s.outCount   = apiData.out_count;
    s.onCount    = apiData.on_count;
    s.totalCount = apiData.in_count + apiData.out_count + apiData.on_count;

    return s;
  }
}
