import { Component, OnDestroy }                  from '@angular/core';
import { interval, Observable, Subject }         from 'rxjs';
import { scan, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { MatDialogRef }                          from '@angular/material/dialog';
import { StoreState }                            from '@redux/store';
import { Store }                                 from '@ngrx/store';
import { LogoutAction }                          from '@redux/auth/auth.actions';
import { TokenService }                          from '@services/token.service';
import { AuthToken }                             from '@enums/auth-token.enum';
import { TokenData }                             from '@services/api.service';
import { MomentService }                         from '@services/moment.service';

@Component({
  selector:    'ngx-session-expiry-modal',
  templateUrl: './session-expiry-modal.component.html',
  styleUrls:   ['./session-expiry-modal.component.scss'],
})
export class SessionExpiryModalComponent implements OnDestroy {

  timeout$: Observable<number>;
  destroy  = new Subject<void>();
  destroy$ = this.destroy.asObservable();

  constructor(private matDialogRef: MatDialogRef<SessionExpiryModalComponent>,
              private store: Store<StoreState>,
              private momentService: MomentService) {
    const initialRefreshToken = TokenService.getToken(AuthToken.Refresh) as TokenData;

    this.timeout$ = interval(1000)
      .pipe(
        startWith(29),
        take(30),
        takeUntil(this.destroy$),
        scan((acc) => acc - 1),
        tap(num => {
          const refreshToken = TokenService.getToken(AuthToken.Refresh) as TokenData;
          const expires      = this.momentService.moment.utc(refreshToken?.expires);
          if (refreshToken && initialRefreshToken?.token !== refreshToken.token) {
            // if there is currently a refresh token but it is not the same one as initialised with then close the modal as refreshing has been handled elsewhere
            this.matDialogRef.close();
            return;
          }
          if (!refreshToken || !num || expires.isBefore(this.momentService.moment.now())) {
            this.logout();
          }
        }));

  }

  logout(): void {
    this.store.dispatch(LogoutAction({}));
  }

  stay(): void {
    this.matDialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
