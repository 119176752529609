import { Injectable }                                                            from '@angular/core';
import { MomentService }                                                         from './moment.service';
import { Store }                                                                 from '@ngrx/store';
import { StoreState }                                                            from '@redux/store';
import { firstValueFrom, fromEvent, Subject, timer }                             from 'rxjs';
import { TokenData }                                                             from '@services/api.service';
import { take, takeUntil }                                                       from 'rxjs/operators';
import { LogoutAction, RefreshAccessTokenRequestAction, ShowSessionExpiryModal } from '@redux/auth/auth.actions';
import { selectUser }                                                            from '@redux/auth/auth.selectors';
import { truthy }                                                                from '@rxjs/truthy.operator';
import { WorkerService }                                                         from '@services/worker.service';

@Injectable({
  providedIn: 'root',
})
export class SessionManagerService {
  private sessionWorker: Worker;
  private sessionChannel: BroadcastChannel;
  private destroy$: Subject<void>     = new Subject<void>();
  private authChanged$: Subject<void> = new Subject<void>();

  constructor(
    private momentService: MomentService,
    private workerService: WorkerService,
    private store: Store<StoreState>,
  ) {
    this.sessionWorker  = this.workerService.newSessionWorker('session');
    this.sessionChannel = new BroadcastChannel('session');

    fromEvent<MessageEvent>(this.sessionChannel, 'message')
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event.data === 'sessionUpdated') {
          this.resetSessionTimer();
        } else if (event.data === 'logout') {
          this.logout();
        }
      });

    fromEvent<StorageEvent>(window, 'storage')
      .pipe(takeUntil(this.destroy$))
      .subscribe(event => {
        if (event.newValue) {
          return;
        }
        if (event.key === 'sessionExpiry') {
          this.resetSessionTimer();
        } else if (event.key === 'logout') {
          this.logout();
        }
      });
  }

  broadcastLogoutEvent(): void {
    // Clear session data
    localStorage.removeItem('sessionExpiry');
    localStorage.setItem('logout', Date.now()
      .toString()); // Triggers storage event
    // Notify other tabs
    this.sessionChannel.postMessage('logout');
  }

  private logout(): void {
    this.store.dispatch(LogoutAction({}));
  }

  /** Starts the session timer and syncs across tabs */
  startSessionTimer(accessToken: TokenData, refreshToken: TokenData): void {
    if (!accessToken || !refreshToken) {
      return;
    }

    const expiry   = this.momentService.moment
      .utc(accessToken.expires)
      .subtract(this.momentService.moment.duration(60, 'seconds'));
    const expiryMS = expiry.diff(this.momentService.moment.utc(), 'millisecond');

    // Store expiry in localStorage
    localStorage.setItem('sessionExpiry', JSON.stringify({ expiryMS, updatedAt: Date.now() }));

    // Notify other tabs
    this.sessionChannel.postMessage('sessionUpdated');

    if (typeof Worker !== 'undefined') {
      this.sessionWorker.postMessage({ type: 'timer', id: 'session', due: expiryMS, action: 'start' });

      this.authChanged$.pipe(takeUntil(this.destroy$), take(1))
        .subscribe(() => {
          this.sessionWorker.postMessage({ type: 'timer', id: 'session', action: 'stop' });
        });

      fromEvent<MessageEvent>(this.sessionWorker, 'message')
        .pipe(takeUntil(this.destroy$), takeUntil(this.authChanged$))
        .subscribe(async (message: MessageEvent) => {
          if (message.data === `[TIMEOUT]-session`) {
            this.handleSessionTimeout();
          }
        });
    } else {
      timer(expiryMS)
        .pipe(takeUntil(this.destroy$), takeUntil(this.authChanged$))
        .subscribe(() => this.store.dispatch(ShowSessionExpiryModal()));
    }
  }

  /** Resets the session timer when a session is updated from another tab */
  private resetSessionTimer(): void {
    const sessionData = localStorage.getItem('sessionExpiry');
    if (sessionData) {
      const { expiryMS } = JSON.parse(sessionData);

      if (typeof Worker !== 'undefined') {
        this.sessionWorker.postMessage({ type: 'timer', id: 'session', due: expiryMS, action: 'start' });
      } else {
        timer(expiryMS)
          .pipe(takeUntil(this.destroy$), takeUntil(this.authChanged$))
          .subscribe(() => this.store.dispatch(ShowSessionExpiryModal()));
      }
    }
  }

  /** Handles session timeout actions */
  private async handleSessionTimeout(): Promise<void> {
    const user       = await firstValueFrom(this.store.select(selectUser)
      .pipe(truthy()));
    const sinceLogin = this.momentService.moment.utc(user.loginDate)
      .diff(this.momentService.moment.utc(), 'hours');

    if (sinceLogin > 23) {
      this.store.dispatch(ShowSessionExpiryModal());
    } else {
      this.store.dispatch(RefreshAccessTokenRequestAction({}));
    }
  }

  /** Cleanup method to destroy observables and workers */
  destroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.sessionWorker.terminate();
    this.sessionChannel.close();
  }
}
