import { SpendSeries } from '@models/entity/spend-series.model';
import { DateService } from '@services/date.service';
import { UsagePeriod } from '@enums/usage-period.enum';

export class BillingGraphData {
  series: Array<SpendSeries>;
  points: Array<{ name: string, credit_total: string, formatDate?: string }>;

  fromApiData?(apiData: { series: SpendSeries[], points: Array<{ name: string, credit_total: string }> }, dateService: DateService): BillingGraphData {

    this.series = apiData.series.map(p => SpendSeries.fromApiData(p));
    this.points = apiData.points.map(p => ({
      ...p,
      formatDate: dateService.formatDateByPeriod(p.name, UsagePeriod.Daily)
    }));

    return this;
  }
}

