import { ListParams }           from '@models/form/list-params.model';
import { ListParamModel }       from '@models/form/list-param-model';
import { UsagePeriod }          from '@enums/usage-period.enum';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';
import { ReportInterval }       from '@enums/report-interval.enum';
import { ConstraintFetchMode }  from '@enums/constraint-fetch-mode.enum';

export class ReportQueryParams extends ListParams implements ListParamModel<ReportQueryParams> {
  interval?: ReportInterval;
  aggregateBy?: UsagePeriod;
  since?: string;
  until?: string;
  groupId?: string[];
  direction?: string;
  constraints?: {
    groups: {
      id: string;
      name: string;
    }[]
  };
  source?: string;
  destination?: string;
  sipResponseType?: string;
  seriesCountMax?: number;
  constraintFetch?: ConstraintFetchMode;

  static constructQueryString(searchParams: ReportQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      source:          'filter[source]',
      destination:     'filter[destination]',
      since:           'filter[date][since]',
      until:           'filter[date][until]',
      direction:       'filter[direction]',
      groupId:         'filter[group_id][]',
      sipResponseType: 'filter[sip_response_type]',
      interval:        'filter[interval]',
      constraintFetch: 'filter[constraint_fetch]',
      seriesCountMax:  'series_count_max',
      aggregateBy:     'filter[aggregate_by]',
    });
  }

  constructParams?(metaData: ListResponseMetadata): ReportQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter) {
      if (metaData.filter.date.since) {
        this.since = metaData.filter.date.since;
      }
      if (metaData.filter.date.until) {
        this.since = metaData.filter.date.until;
      }
      if (metaData.filter.group_id) {
        this.groupId = metaData.filter.group_id;
      }
      if (metaData.filter.interval) {
        this.interval = metaData.filter.interval as ReportInterval;
      }
      if (metaData.filter.direction) {
        this.direction = metaData.filter.direction;
      }
      if (metaData.filter.response_type) {
        this.sipResponseType = metaData.filter.response_type;
      }
      if (metaData.filter.constraint_fetch) {
        this.constraintFetch = metaData.filter?.constraint_fetch;
      }
    }
    if (metaData.series_count_max) {
      this.seriesCountMax = metaData.series_count_max;
    }
    if (metaData.constraints?.group_id) {
      this.constraints = {
        groups: Object.entries(metaData.constraints.group_id)
                  .map(([id, name]) => ({ id, name })),
      };
    }
    return this;
  }
}
