import { Injectable }                    from '@angular/core';
import { environment }                   from '../../environments/environment';
import { Observable, of }                from 'rxjs';
import { ListService }                   from '@services/list.service';
import { Task }                          from '@models/entity/task.model';
import { TaskFactory }                   from '@models/factory/task.factory';
import { TaskQueryParams }               from '@models/form/task-query-params.model';
import { TaskQueryParamsFactory }        from '@models/factory/task-query-params.factory';
import { FetchTaskListRequest }          from '@models/api/fetch-task-list-request.model';
import { FetchTaskListResponse }         from '@models/api/fetch-task-list-response.model';
import { FetchTaskRequest }              from '@models/api/audit/fetch-task-request.model';
import { FetchTaskResponse }             from '@models/api/audit/fetch-task-response.model';
import { catchError, map }               from 'rxjs/operators';
import { HttpErrorResponse }             from '@angular/common/http';
import { Alert }                         from '@models/entity/alert.model';
import { ApiService }                    from '@services/api.service';
import { TaskRaw }                       from '@models/api/task-raw.model';
import { FetchTaskCountResponse }        from '@models/api/audit/fetch-task-count-response.model';
import { CancelTaskRequest }             from '@models/api/audit/cancel-task-request.model';
import { CancelTaskResponse }            from '@models/api/audit/cancel-task-response.model';
import { FetchAuditListRequest }         from '@models/api/fetch-audit-list-request.model';
import { FetchAuditListResponse }        from '@models/api/fetch-audit-list-response.model';
import { AuditQueryParams }              from '@models/form/audit-query-params.model';
import { Audit }                         from '@models/entity/audit.model';
import { AuditFactory }                  from '@models/factory/audit.factory';
import { AuditQueryParamsFactory }       from '@models/factory/audit-query-params.factory';
import { FetchAuditContextListResponse } from '@models/api/audit/fetch-audit-context-list-response.model';
import { FetchAuditObjectListResponse }  from '@models/api/audit/fetch-audit-object-list-response.model';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private baseUrl: string = environment.api.auditBaseUrl;
  taskFactory: TaskFactory;
  taskQueryParamsFactory: TaskQueryParamsFactory;
  auditFactory: AuditFactory;
  auditQueryParamsFactory: AuditQueryParamsFactory;

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  private buildAuditListUri(queryParams: AuditQueryParams): string {
    return this.buildUri(`/audit${ AuditQueryParams.constructQueryString(queryParams) }`);
  }

  private buildTaskListUri(queryParams: TaskQueryParams): string {
    return this.buildUri(`/tasks${ TaskQueryParams.constructQueryString(queryParams) }`);
  }

  private buildTaskUri(id: string): string {
    return this.buildUri(`/tasks/${ id }?include[]=audit&include[]=messages&include[]=schema`);
  }

  private buildAuditContextsUri(): string {
    return this.buildUri('/audit/contexts');
  }

  private buildAuditObjectsUri(): string {
    return this.buildUri('/audit/objects');
  }

  private buildTaskCountUri(): string {
    return this.buildUri(`/tasks/count`);
  }

  constructor(
    private apiService: ApiService,
    private auditListService: ListService<Audit, AuditFactory,
      AuditQueryParams, AuditQueryParamsFactory>,
    private taskListService: ListService<Task, TaskFactory,
      TaskQueryParams, TaskQueryParamsFactory>) {
    this.taskFactory             = new TaskFactory();
    this.taskQueryParamsFactory  = new TaskQueryParamsFactory();
    this.auditFactory            = new AuditFactory();
    this.auditQueryParamsFactory = new AuditQueryParamsFactory();
  }

  fetchTaskList$(req: FetchTaskListRequest): Observable<FetchTaskListResponse> {
    return this.taskListService.fetchListModel$(
      this.buildTaskListUri(req.queryParams),
      this.taskFactory,
      this.taskQueryParamsFactory,
    );
  }

  fetchTaskCount$(): Observable<FetchTaskCountResponse> {
    return this.apiService.apiGet$<{ data: { incomplete_by_schema: { [schema: string]: number } } }>(
      this.buildTaskCountUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: { incomplete_by_schema: { [schema: string]: number } } }): FetchTaskCountResponse => {
          return {
            data:  res.data.incomplete_by_schema && !Array.isArray(res.data.incomplete_by_schema) ?
                     res.data.incomplete_by_schema :
                     {},
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchTaskCountResponse> => {
          return of({
            error: err.status !== 404 && new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchTask$(req: FetchTaskRequest): Observable<FetchTaskResponse> {
    return this.apiService.apiGet$<{ data: TaskRaw }>(
      this.buildTaskUri(req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: TaskRaw }): FetchTaskResponse => {
          return {
            data:  new Task().fromApiData(res.data),
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchTaskResponse> => {
          return of({
            error: err.status !== 404 && new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchAuditList$(req: FetchAuditListRequest): Observable<FetchAuditListResponse> {
    return this.auditListService.fetchListModel$(
      this.buildAuditListUri(req.queryParams),
      this.auditFactory,
      this.auditQueryParamsFactory,
    );
  }

  fetchAuditContexts$(): Observable<FetchAuditContextListResponse> {
    return this.apiService.apiGet$<{
      data: {
        identifier: string;
        description: string;
      }[];
    }>(
      this.buildAuditContextsUri(),
      { authRequired: true })
      .pipe(
        map((res: {
          data: {
            identifier: string;
            description: string;
          }[];
        }): FetchAuditContextListResponse => {
          return {
            data:  res.data,
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAuditContextListResponse> => {
          return of({
            error: err.status !== 404 && new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchAuditObjects$(): Observable<FetchAuditObjectListResponse> {
    return this.apiService.apiGet$<{
      data: {
        identifier: string;
        description: string;
      }[];
    }>(
      this.buildAuditObjectsUri(),
      { authRequired: true })
      .pipe(
        map((res: {
          data: {
            identifier: string;
            description: string;
          }[];
        }): FetchAuditObjectListResponse => {
          return {
            data:  res.data,
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAuditObjectListResponse> => {
          return of({
            error: err.status !== 404 && new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  cancelTask$(req: CancelTaskRequest): Observable<CancelTaskResponse> {
    return this.apiService.apiDelete$<{ data: TaskRaw }>(
      this.buildTaskUri(req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: TaskRaw }): CancelTaskResponse => {
          return {
            error: null,
            id:    req.id,
            data:  new Task().fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<CancelTaskResponse> => {
          return of({
            error: err.status !== 404 && new Alert().fromApiError(err),
            data:  null,
            id:    req.id,
          });
        }),
      );
  }
}
