import { Injectable }     from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlTree,
}                         from '@angular/router';
import { AuthToken }      from '@enums/auth-token.enum';
import { TokenService }   from '@services/token.service';
import { AuthGuard }      from './auth.guard';
import { splitURLByPath } from '@util/url.helper';
import { Location }       from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PartnerAuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private router: Router,
              private authGuard: AuthGuard,
              private location: Location) {}

  canActivate(routeSnap: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<true | UrlTree> {
    return this.canAccess$(undefined, routeSnap, state);
  }

  canActivateChild(routeSnap: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<true | UrlTree> {
    return this.canAccess$(undefined, routeSnap, state);
  }

  canLoad(route: Route): Promise<true | UrlTree> {
    return this.canAccess$(route);
  }

  private async canAccess$(route?: Route, routeSnapshot?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Promise<true | UrlTree> {
    const hasPartnerToken = !!TokenService.getToken(AuthToken.Partner);
    const isAuthed        = await (route ?
      this.authGuard.canLoad() :
      this.authGuard.canActivate(routeSnapshot, state));

    if (isAuthed instanceof UrlTree) {
      return isAuthed;
    }

    const companyContext = splitURLByPath(this.location.path()).queryParams?.company;
    return hasPartnerToken ||
      this.router.parseUrl(`/overview${ companyContext ? ('?company=' + companyContext) : '' }`);
  }
}
