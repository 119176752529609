import { Injectable }                  from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map }                         from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {
  workers  = new BehaviorSubject<Map<string, Worker>>(new Map<string, Worker>([]));
  workers$ = this.workers.asObservable();

  newSessionWorker(id: string): Worker {
    if (typeof Worker !== 'undefined') {
      // can't use a variable as the stringUrl so have to create a function per worker
      const worker = new Worker(new URL('../session.worker', import.meta.url), {type: 'module'});
      this.workers.next(this.workers.value.set(id, worker));
      return worker;
    }
    // not supported by browser
    return null;
  }

  getWorkerByID$(id: string): Observable<Worker> {
    return this.workers$.pipe(map(workers => workers.has(id) ? workers.get(id) : null));
  }
}
