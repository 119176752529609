import { Injectable }          from '@angular/core';
import { ServiceType }         from '@enums/service-type.enum';
import { ServiceQueryParams }  from '@models/form/service-query-params.model';
import { PaginationFormModel } from '@models/form/pagination-form.model';
import {
  FetchPolicyListRequestAction,
  FetchServiceCountsRequestAction,
  FetchServiceListRequestAction,
  FetchServiceRequestAction,
}                              from '@redux/service/service.actions';
import { StoreState }          from '@redux/store';
import { Store }               from '@ngrx/store';
import { ListFilter }          from '@models/form/list-filter.model';

@Injectable({
  providedIn: 'root',
})
export class ServiceQueryService {
  sortFilters: ListFilter[] = [
    new ListFilter({
      id:           'name',
      value:        true,
      label:        'Name',
      directionAsc: true,
      canSort:      false,
      filterClass:  'name',
      width:        '30%',
      widthSm:      '40%',
      hideSm:       true,
    }),
    new ListFilter({
      id:           'status',
      value:        true,
      label:        'Status',
      directionAsc: true,
      canSort:      false,
      filterClass:  'status',
      width:        '20%',
      widthSm:      '40%',
      hideSm:       true,
    }),
  ];

  constructor(private store: Store<StoreState>) { }

  getQueryParams(pageSize: number, pageNumber: number, sort: string, type: ServiceType): ServiceQueryParams {
    const n: ServiceQueryParams = new ServiceQueryParams();

    n.pageSize   = pageSize;
    n.pageNumber = pageNumber;
    n.sort       = sort;
    n.type       = type;

    return n;
  }

  updatePageQuery(params: PaginationFormModel, curParams: ServiceQueryParams): void {
    setTimeout(() => {
      window.scroll({
        top:      0,
        left:     0,
        behavior: 'smooth',
      });
    }, 100);

    this.fetchList(
      true,
      this.getQueryParams(params.pageSize, params.pageNumber, curParams ? curParams.sort : null, curParams.type),
    );
  }

  fetchList(emitEvent = true, queryParams = new ServiceQueryParams({ pageSize: 100, pageNumber: 1 })): void {
    this.store.dispatch(FetchServiceListRequestAction({ queryParams, emitEvent }));
  }

  fetchItem(serviceId: string): void {
    this.store.dispatch(FetchServiceRequestAction({ serviceId }));
  }

  fetchCounts(): void {
    this.store.dispatch(FetchServiceCountsRequestAction({}));
  }

  fetchPolicyList(serviceId: string, emitEvent = true): void {
    this.store.dispatch(FetchPolicyListRequestAction({
      emitEvent,
      serviceId,
    }));
  }
}
