import { ServiceItem }    from './service-item.model';
import { getServiceIcon } from '../api/service.model';
import { ServiceType }    from '@enums/service-type.enum';
import { SIPTrunkRaw }    from '@models/api/sip-trunk-raw.model';

export class SIPTrunk extends ServiceItem {

  icon        = getServiceIcon(ServiceType.SIPTrunk, false);
  colorIcon   = getServiceIcon(ServiceType.SIPTrunk, true);
  serviceType = ServiceType.SIPTrunk;
  gatewayAddress: string;
  gatewayProtocol: string;
  gatewayHasSipOptions: boolean;
  gatewayPort: number;
  addressSpaces: string[];
  callrouteGatewayAddress: string;
  isGatewayUp: boolean;

  constructor(protected data?: SIPTrunk) {
    super();
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  toApiData?(): SIPTrunkRaw {
    return {
      type:                    this.serviceType,
      gateway_address:         this.gatewayAddress,
      gateway_protocol:        this.gatewayProtocol,
      gateway_has_sip_options: this.gatewayHasSipOptions,
      gateway_port:            this.gatewayPort,
      address_spaces:          this.addressSpaces,
      label:                   this.label,
    } as SIPTrunkRaw;
  }

  fromApiData?(apiData: SIPTrunkRaw): SIPTrunk {
    super.fromApiData(apiData);
    this.gatewayAddress          = apiData.gateway_address;
    this.gatewayProtocol         = apiData.gateway_protocol;
    this.gatewayPort             = apiData.gateway_port;
    this.gatewayHasSipOptions    = apiData.gateway_has_sip_options;
    this.callrouteGatewayAddress = apiData.callroute_gateway_address;
    this.createdDate             = apiData.created_date;
    this.addressSpaces           = apiData.address_spaces;
    this.label                   = apiData.label;
    this.isGatewayUp             = apiData.is_gateway_up;

    return this;
  }
}
