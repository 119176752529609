<ngx-modal-head header="Delete {{modalData.name}}" [headerIcon]="'cr-warning'"
                [closeable]="true"></ngx-modal-head>
<ngx-modal-content class="mat-typography">
  <p class="mat-body-1--smaller">You are about to delete a user persona.
    You can optionally migrate all users with this persona to another persona selected below.</p>
  <mat-form-field appearance="outline" class="w-100 mb-16 mt-24">
    <mat-label>Type DELETE to confirm</mat-label>
    <input matInput type="text" [formControl]="typeTextCtrl" data-cy="form-type-text"
           autocomplete="off" />
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-100 mb-16">
    <mat-label>Migrate to another persona</mat-label>
    <input matInput type="text"
           [formControl]="migrateToCtrl"
           autocomplete="off"
           data-cy="form-profile-search"
           placeholder="- Select -"
           [matAutocomplete]="profileComplete" />
  </mat-form-field>
  <mat-autocomplete autoActiveFirstOption
                    #profileComplete="matAutocomplete"
                    class="option-line-height-1"
                    [displayWith]="getProfileName">
    <mat-option *ngFor="let profile of profiles$ | async"
                [value]="profile">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <div>
          <p class="m-0 color-black">{{profile.name}}</p>
        </div>
      </div>
    </mat-option>
    <mat-option
      *ngIf="!(profiles$ | async)?.length"
      [value]="null">
      <p class="m-0 color-black">No results found</p>
    </mat-option>
  </mat-autocomplete>
</ngx-modal-content>
<br>
<ngx-modal-actions>
  <button class="button primary" #modalAction type="button" data-cy="submit"
          [disabled]="typeTextCtrl?.value !== 'DELETE'"
          [mat-dialog-close]="{confirm: true, migrateTo: migrateToCtrl.value?.id}">Confirm
  </button>
  <button class="button secondary" #modalAction type="button" data-cy="close" [mat-dialog-close]="false">Cancel
  </button>
</ngx-modal-actions>
