import { OperatorFunction } from 'rxjs';
import { map }              from 'rxjs/operators';

/**
 * Uses rxjs/map to match any item of an array against the array emitted via the Observable stream.
 * @returns OperatorFunction boolean to indicate whether it found a match or not
 *
 * @example
 * ```ts
 *  this.isPending$ = pendingTasks$.pipe(
 *              containsAny(FETCH_SERVICE_LIST_REQUEST,
 *                          HEALTH_CHECK_REQUEST,
 *                          HEALTH_CHECK_RESPONSE,
 *                          REQUIREMENT_CHECK_REQUEST,
 *                          REQUIREMENT_CHECK_RESPONSE))
 * ```
 * @param list
 */
export function containsAny<T extends Array<U>, U>(...list: Array<U>): OperatorFunction<T, boolean> {
  return map<T, boolean>((arr: T) => arr?.some(item => list?.includes(item)));
}
