import { StoreState } from '../store';
import {
  createSelector,
}                     from '@ngrx/store';
import {
  selectMicrosoftTeams,
}                     from '../microsoft-teams/microsoft-teams.selectors';
import {
  MicrosoftTeamsState,
}                     from '../microsoft-teams/microsoft-teams.reducer';
import {
  ServiceState,
}                     from './service.reducer';
import {
  HEALTH_CHECK_REQUEST,
  HEALTH_CHECK_RESPONSE,
  REQUIREMENT_CHECK_REQUEST,
  REQUIREMENT_CHECK_RESPONSE,
}                     from '../microsoft-teams/microsoft-teams.types';
import {
  ServiceItem,
}                     from '@models/entity/service-item.model';
import {
  MicrosoftTeams,
}                     from '@models/entity/microsoft-teams.model';
import {
  ProvisioningService,
}                     from '@services/provisioning.service';
import {
  Token,
}                     from '@models/entity/token-state.model';
import {
  selectTokens,
}                     from '../access-broker/access-broker.selectors';
import {
  selectSubscription,
}                     from '../subscription/subscription.selectors';
import {
  ServiceType,
}                     from '@enums/service-type.enum';
import {
  canCreateService,
}                     from '@models/api/service.model';
import {
  ServiceHealthCheckName,
}                     from '@enums/health-check.enum';
import {
  HealthInfo,
}                     from '@models/api/health-info.model';
import {
  RequirementInfo,
}                     from '@models/api/requirement-info.model';
import {
  MicrosoftToken,
}                     from '@enums/microsoft-token.enum';
import {
  ServiceRequirementCheckName,
}                     from '@enums/service-requirement.enum';

const _selectService = (state: StoreState): ServiceState => state.serviceReducer;

function updateServiceProvisioning(item: ServiceItem,
                                   requirementsProcessing: boolean,
                                   healthProcessing: boolean,
                                   expiredTokens: MicrosoftToken[],
                                   lastTokenAuth: MicrosoftToken,
                                   requirementState: Map<ServiceRequirementCheckName, boolean>,
                                   requirementChecks: RequirementInfo,
                                   healthState: Map<ServiceHealthCheckName, boolean>,
                                   healthChecks: HealthInfo,
                                   tokens: Token[]): ServiceItem {
  if (!ServiceItem.isMicrosoft(item)) {
    return item;
  }
  return new MicrosoftTeams(item)
    .updateAuthStatus(tokens)
    .updateRequirements(requirementState, requirementChecks, requirementsProcessing)
    .updateHealth(expiredTokens, healthState, healthChecks, lastTokenAuth, healthProcessing);
}

export const selectService                        = createSelector(
  selectMicrosoftTeams,
  selectTokens,
  _selectService,
  (microsoftTeamsState: MicrosoftTeamsState, tokens: { [serviceId: string]: Token[] }, serviceState: ServiceState) => {

    const requirementsProcessing = microsoftTeamsState.pendingTasks.some(task => [REQUIREMENT_CHECK_REQUEST, REQUIREMENT_CHECK_RESPONSE].includes(task?.id));
    const healthProcessing       = microsoftTeamsState.pendingTasks.some(task => [HEALTH_CHECK_REQUEST, HEALTH_CHECK_RESPONSE].includes(task?.id));
    const requirementState       = microsoftTeamsState.requirementState;
    const requirementChecks      = microsoftTeamsState.requirementInfo;
    const healthState            = microsoftTeamsState.healthState;
    const healthChecks           = microsoftTeamsState.healthInfo;

    return {
      ...serviceState,
      serviceItems:        serviceState.serviceItems?.map(item => {
        const lastTokenAuth = tokens[item.id]?.find(token => token?.lastAuth)?.id;
        const expiredTokens = ProvisioningService.getExpiredTokens(tokens[item.id]);
        return updateServiceProvisioning(item, requirementsProcessing, healthProcessing, expiredTokens, lastTokenAuth,
          requirementState, requirementChecks, healthState?.[item.id], healthChecks?.[item.id], tokens[item.id]);
      }) || [],
      selectedServiceItem: updateServiceProvisioning(serviceState.selectedServiceItem, requirementsProcessing, healthProcessing, ProvisioningService.getExpiredTokens(tokens[serviceState.selectedServiceItem?.id]),
        tokens[serviceState.selectedServiceItem?.id]?.find(token => token?.lastAuth)?.id, requirementState, requirementChecks, healthState?.[serviceState.selectedServiceItem?.id], healthChecks?.[serviceState.selectedServiceItem?.id], tokens[serviceState.selectedServiceItem?.id]),
    };
  },
);
export const selectServiceCounts                  = createSelector(selectService, (service) => {
  if (!service.counts) {
    return service.counts;
  }
  const { CARRIER, ...counts } = service.counts;
  return counts;
});
export const selectAllServiceCounts               = createSelector(selectService, service => service.counts);
export const selectTotalServiceCount              = createSelector(selectServiceCounts, counts => {
  return counts ? Object.values(counts)
    .map(c => c.TOTAL)
    .reduce((a, b) => a + b) : 0;
});
export const selectHasActiveService               = createSelector(selectServiceCounts, (counts) => counts && Object.values(counts)
  .some(item => !!item.ACTIVE));
export const selectServiceQueryParams             = createSelector(selectService, (service) => service.serviceQueryParams);
export const selectServiceItem                    = createSelector(selectService, (state) => state.selectedServiceItem);
export const selectServiceItems                   = createSelector(selectService, (service) => service.serviceItems);
export const selectServiceUserCount               = createSelector(selectService, service => service.serviceUserCount);
export const selectServicePendingTasks            = createSelector(selectService, service => service.pendingTasks);
export const selectProvisioningServices           = createSelector(selectService, service => service.provisioningServices);
export const selectAvailableCarriers              = createSelector(selectService, service => service.availableCarriers);
export const selectAvailableCarriersQueryParams   = createSelector(selectService, service => service.availableCarriersQueryParams);
export const selectCarriers                       = createSelector(selectService, service => service.carriers);
export const selectPSTNServiceUuid                = createSelector(selectService, service => service.PSTNServiceUuid);
export const selectCarriersQueryParams            = createSelector(selectService, service => service.carriersQueryParams);
export const selectServiceError                   = createSelector(selectService, service => service.error);
export const selectServiceMessage                 = createSelector(selectService, service => service.message);
export const selectCustomCarrierResult            = createSelector(selectService, service => service.customCarrierResult);
export const selectGatewayStatusEvents            = createSelector(selectService, service => service.gatewayStatusEvents);
export const selectGatewayStatusEventsQueryParams = createSelector(selectService, service => service.gatewayStatusEventsQueryParams);
export const selectServiceUserList                = createSelector(selectService, service => service.selectedServiceUserList);
export const selectServiceUserQueryParams         = createSelector(selectService, service => service.selectedServiceUserQueryParams);
export const selectAvailableServices              = createSelector(selectService, service => service.availableServices);
export const selectPolicyList                     = createSelector(selectService, service => service.policyList);
export const selectReachedCarrierLimit            = createSelector(selectSubscription, selectService, (subscription, state) => {
  const totalCount = state.counts ? Object.values(state.counts)
    .map(c => c.TOTAL)
    .reduce((a, b) => a + b) : 0;
  return !canCreateService(ServiceType.Carrier, totalCount, state.counts?.[ServiceType.Carrier]?.TOTAL || 0, subscription.packageCapabilities);
});
export const selectCallingProfiles                = createSelector(selectService, service => service.callingProfiles);
export const selectCallingProfile                 = createSelector(selectService, service => service.selectedCallingProfile);
export const selectCallingProfilesSearchResults   = createSelector(selectService, service => service.callingProfilesSearchResult);
export const selectCallingProfileParams           = createSelector(selectService, service => service.callingProfilesQueryParams);
export const selectPersonaNameAvailability        = createSelector(selectService, service => service.personaNameAvailability);
export const selectAutomationList                 = createSelector(selectService, service => service.automations);
export const selectAutomation                     = createSelector(selectService, service => service.selectedAutomation);
export const selectAutomationQueryParams          = createSelector(selectService, service => service.automationQueryParams);
export const selectServiceSchema                  = createSelector(selectService, service => service.selectedServiceSchema);
export const selectServiceUser                    = createSelector(selectService, service => service.selectedServiceUser);
export const selectServiceUserCounts              = createSelector(selectService, service => service.serviceUserCount);
export const selectAutomationNameAvailability     = createSelector(selectService, service => service.automationNameAvailability);
export const selectCLIAvailability                = createSelector(selectService, service => service.serviceUserCLIAvailability);
export const selectEmergencyLocationList          = createSelector(selectService, service => service.emergencyLocations);
export const selectEmergencyLocationQueryParams   = createSelector(selectService, service => service.emergencyLocationQueryParams);
export const selectServiceResponses               = createSelector(selectService, service => service.responses);
