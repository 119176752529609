import moment                  from 'moment';
import { ReportSeriesData }    from '@models/entity/report-series-data.model';
import { ChannelUsageDataRaw } from '@models/api/channel-usage-data-raw.model';

export class ChannelUsageData {
  series: Array<ReportSeriesData>;
  points: { formatDate: string; name: string; channel_usage: number; }[];

  static fromApiData(apiData: ChannelUsageDataRaw, aggregateBy: string): ChannelUsageData {
    const c: ChannelUsageData = new ChannelUsageData();

    c.series = apiData.series.map(p => ReportSeriesData.fromApiData(p));

    c.points = apiData.points.map(p => ({
      ...p,
      formatDate: ChannelUsageData.getFormatDate(p.name, aggregateBy),
    }));

    return c;
  }

  static getFormatDate(date: string, aggregateBy: string): string {
    switch (aggregateBy) {
      case 'day':
        return date;
      case 'week':
        return moment(date, 'YYYY-WW')
          .startOf('isoWeek')
          .format('YYYY-MM-DD');
      case 'month':
        return moment(date, 'YYYY-MM')
          .startOf('month')
          .format('YYYY-MM-DD');
    }
  }
}
