import { Injectable }                   from '@angular/core';
import { CountryCode, CountryItem }     from '@models/entity/country-code.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormGroup }                    from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CountryService {

  private static readonly countryCodes            = new CountryCode();
  static readonly countryList: Array<CountryItem> = CountryService.countryCodes.getCountryNameAndISO3Array();

  static filterCountries(valueString: string): Array<CountryItem> {
    if (!valueString) {
      return CountryService.countryList;
    }

    return CountryService.countryList.filter(c => c.name.toLowerCase()
      .includes(valueString.toLowerCase()));
  }

  static setCountryCode(e: MatAutocompleteSelectedEvent, formGroup: FormGroup, fieldName: string, codeFieldName: string): void {
    const selectedCountry = e.option ? CountryService.countryList.find(c => c.name === e.option.value) : null;
    if (selectedCountry) {
      formGroup.get(fieldName)
        .setErrors(null);
      formGroup.get(codeFieldName)
        .setValue(selectedCountry.code);
      formGroup.get(codeFieldName)
        .updateValueAndValidity();
    }
  }

  constructor() { }
}
