import { SipResponse } from '@enums/sip-response.enum';

export default new Map<SipResponse, string>(
  [
    [SipResponse.InvalidNumber, '#EC7800'],
    [SipResponse.MissedCall, '#EB0E46'],
    [SipResponse.QuickDropped, '#a129e3'],
    [SipResponse.CallDeclined, '#5B4CFF'],
    [SipResponse.Successful, '#00C68D'],
    [SipResponse.Busy, '#275e87'],
    [SipResponse.Unclassified, '#575B5E'],
    [SipResponse.Failed, '#F0BB00'],
  ],
);
