export enum AuthScope {
  AdminAudit                = 'Admin.Audit',
  AdminPayment              = 'Admin.Payment',
  AdminNumber               = 'Admin.Number',
  AdminService              = 'Admin.Service',
  AdminIdentity             = 'Admin.Identity',
  ApprovalWrite             = 'Approval.Write',
  AuditRead                 = 'Audit.Read',
  CompanyConfigurationRead  = 'Company.Configuration.Read',
  CompanyConfigurationWrite = 'Company.Configuration.Write',
  CompanyRead               = 'Company.Read',
  CompanyWrite              = 'Company.Write',
  IdentityTokenWrite        = 'Identity.Token.Write',
  IntegrationZapierRead     = 'Integration.Zapier.Read',
  IntegrationZapierWrite    = 'Integration.Zapier.Write',
  NotificationRead          = 'Notification.Read',
  NotificationWrite         = 'Notification.Write',
  NumberAnnotate            = 'Number.Annotate',
  NumberRead                = 'Number.Read',
  NumberWrite               = 'Number.Write',
  PaymentRead               = 'Payment.Read',
  PersonaWrite              = 'Persona.Write',
  RecordingRead             = 'Recording.Read',
  ReportRead                = 'Report.Read',
  ReportExport              = 'Report.Export',
  ReportDelete              = 'Report.Delete',
  RoleRead                  = 'Role.Read',
  RoleWrite                 = 'Role.Write',
  SecurityGroupRead         = 'SecurityGroup.Read',
  SecurityGroupWrite        = 'SecurityGroup.Write',
  ServiceRead               = 'Service.Read',
  ServiceWrite              = 'Service.Write',
  ServiceDelete             = 'Service.Delete',
  SubordinateRead           = 'Subordinate.Read',
  SubordinateWrite          = 'Subordinate.Write',
  TaskRead                  = 'Task.Read',
  TaskWrite                 = 'Task.Write',
  UserConfigurationRead     = 'User.Configuration.Read',
  UserConfigurationWrite    = 'User.Configuration.Write',
  UserRead                  = 'User.Read',
  UserWrite                 = 'User.Write',
  UserRoleRead              = 'UserRole.Read',
  UserRoleWrite             = 'UserRole.Write',
}
