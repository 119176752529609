import { BaseRequest }      from '@redux/helpers/reducer.helper';
import { SecurityGroupRaw } from '@models/api/identity/security-group-raw.model';

export class PostSecurityGroupRequest extends BaseRequest {
  name: string;
  description: string;

  constructor(private data?: PostSecurityGroupRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): Partial<SecurityGroupRaw> {
    return {
      name:        this.name,
      description: this.description,
    };
  }
}
