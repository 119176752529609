import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { Task }                                     from '@models/entity/task.model';
import { fadeIn }                                   from '@animations/fadeIn';
import { TaskManagerService }                       from '@services/task-manager.service';
import { Subject, timer }                           from 'rxjs';
import { takeUntil }                                from 'rxjs/operators';
import { ToastrService }                            from 'ngx-toastr';
import { TaskStatus }                               from '@enums/task-status.enum';
import { TaskOutcome }                              from '@enums/task-outcome.enum';

@Component({
  selector:    'ngx-task-notification',
  templateUrl: './task-notification.component.html',
  styleUrls:   ['./task-notification.component.scss'],
  animations:  [fadeIn(300, 200)],
})
export class TaskNotificationComponent implements OnDestroy {
  @Input() task: Task;
  @Input() toastId: number;
  @HostBinding('@fadeIn') fadeIn = true;

  destroy               = new Subject<void>();
  timeoutMS             = 20_000;
  destroy$              = this.destroy.asObservable();
  timeProgress          = 0;
  TaskStatus            = TaskStatus;
  private timeElapsedMS = 0;

  constructor(private taskManagerService: TaskManagerService,
              private toastrService: ToastrService) {
    timer(0, 200)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.timeElapsedMS += 200;
        this.timeProgress = (this.timeElapsedMS / this.timeoutMS) * 100;
        if (this.timeProgress === 100) {
          this.toastrService.remove(this.toastId);
        }
      });
  }

  get textClass(): string {
    switch (this.task.status) {
      case TaskStatus.Complete:
        switch (this.task.outcome) {
          case TaskOutcome.Cancelled:
            return 'color-gray';
          case TaskOutcome.Success:
          case TaskOutcome.Partial:
            return 'color-green';
          default:
            return 'color-red';
        }
      case TaskStatus.Pending:
      case TaskStatus.Processing:
        return 'color-primary';
    }
  }

  dismiss(toggleSidenav?: boolean): void {
    if (toggleSidenav) {
      this.taskManagerService.toggle.next(true);
    }
    this.toastrService.remove(this.toastId);
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }
}
