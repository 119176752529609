<ng-container *ngIf="authReducer$ | async; let authReducer">
  <ng-container *ngIf="textColor$ | async as textColor">
    <h1 [ngClass]="textColor">Oooops... We couldn't find that page</h1>
    <p [ngClass]="textColor">This is a 404 error, which means you've clicked on a bad link or
      entered an invalid URL</p>
    <a class="button primary" href="/" *ngIf="!authReducer.pending && !authReducer.user">Return to login</a>
    <button class="button primary" type="button" (click)="navigateToPage('/overview')"
            *ngIf="!authReducer.pending && authReducer.user">Return to overview
    </button>
  </ng-container>
</ng-container>
