import * as RegistrationAction from './registration.actions';

import { Alert }                                                                 from '@models/entity/alert.model';
import { BaseState, requestReduce, responseReduce }                              from '../helpers/reducer.helper';
import { StateTask }                                                             from '@models/entity/state-task.model';
import { Action, createReducer, on }                                             from '@ngrx/store';
import { ACTIVATE_ACCOUNT_REQUEST, REGISTER_REQUEST, RESEND_ACTIVATION_REQUEST } from './registration.types';
import * as AuthAction                                                           from '../auth/auth.actions';
import * as UIAction                                                             from '../ui/ui.actions';
import { createEntityAdapter, EntityAdapter }                                    from '@ngrx/entity';

export interface RegistrationState extends BaseState<never> {
  error: Alert;
  message: Alert;
  pending: boolean;
  userId: number;
  pendingTasks: StateTask[];
}

export const adapter: EntityAdapter<never> = createEntityAdapter<never>();

const initialState: RegistrationState = adapter.getInitialState({
  error:        null,
  message:      null,
  pending:      false,
  pendingTasks: [],
  userId:       null
});

const _registrationReducer = createReducer(initialState,
  on(RegistrationAction.RegisterRequestAction, requestReduce),
  on(RegistrationAction.RegisterResponseAction, (state, action) =>
    responseReduce(state, action, REGISTER_REQUEST, res => ({
      userId: res.error ? state.userId : res.userId
    }))),
  on(RegistrationAction.ResendActivationRequestAction, requestReduce),
  on(RegistrationAction.ResendActivationResponseAction, (state, action) =>
    responseReduce(state, action, RESEND_ACTIVATION_REQUEST)),
  on(RegistrationAction.ActivateAccountRequestAction, requestReduce),
  on(RegistrationAction.ActivateAccountResponseAction, (state, action) =>
    responseReduce(state, action, ACTIVATE_ACCOUNT_REQUEST)),
  on(RegistrationAction.ClearRegistrationReducerAction, (state) => ({
    ...state,
    error:   null,
    message: null
  })),
  on(UIAction.DismissErrorAction, (state) =>
    ({...state, error: null})),
  on(UIAction.DismissMessageAction, (state) =>
    ({...state, message: null})),
  on(AuthAction.LogoutAction, () =>
    ({...initialState}))
);

export function registrationReducer(state: RegistrationState, action: Action): RegistrationState {
  return _registrationReducer(state, action);
}
