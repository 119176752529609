import { CompanyRaw }              from '../api/company-raw.model';
import { CompanyMetadata }         from './company.metadata';
import { Address }                 from './address.model';
import { AddressFormData }         from '../form/address-form.model';
import { Branding }                from './branding.model';
import { ProductType }             from '@enums/product-type.enum';
import { InventoryStatus }         from '@enums/inventory-status.enum';
import { CapabilityInventoryItem } from '@models/entity/capability-inventory-item.model';
import { ServiceStatusMetadata }   from '@models/entity/service-status-metadata.model';

export class Company {
  id: string;
  name: string;
  address: Address;
  reference: string;
  billingAddress: Address;
  metadata: CompanyMetadata;
  parentStub: { id: string, name: string };
  brand: Branding;
  isPartner: boolean;
  isDistributor: boolean;
  isConsumer: boolean;
  currencyCode?: string;
  promoCode?: string;
  isActive: boolean;
  createdDate: string;
  requestId?: string;

  constructor(private data?: Company) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromFormData?(companyName: string,
                addressFormData: AddressFormData,
                isConsumer: boolean,
                isPartner: boolean,
                isDistributor: boolean,
                promoCode?: string): Company {
    const c: Company = new Company();

    c.name           = companyName;
    c.address        = Address.fromFormData(addressFormData);
    c.billingAddress = Address.fromFormData(addressFormData, !addressFormData.bUseAsBilling);
    c.promoCode      = promoCode;
    c.isConsumer     = isConsumer;
    c.isPartner      = isPartner;
    c.isDistributor  = isDistributor;

    return c;
  }

  fromApiData?(apiData: CompanyRaw): Company {
    if (!apiData) {
      return null;
    }
    const c: Company = new Company();
    c.id             = apiData.id;
    c.name           = apiData.name;
    if (apiData.office_address) {
      c.address = Address.fromApiData(apiData.office_address);
    }
    if (apiData.billing_address) {
      c.billingAddress = Address.fromApiData(apiData.billing_address);
    }
    c.metadata      = apiData.metadata;
    c.parentStub    = apiData.parent_stub;
    c.isPartner     = apiData.is_partner;
    c.isConsumer    = apiData.is_consumer;
    c.isDistributor = apiData.is_distributor;
    c.reference     = apiData.reference;
    c.brand         = apiData.brand;
    c.createdDate   = apiData.created_date;

    return c;
  }

  toApiData?(a: Company): CompanyRaw {
    return {
      is_distributor:  a.isDistributor,
      is_consumer:     a.isConsumer,
      is_partner:      a.isPartner,
      office_address:  a.address && Address.toApiData(a.address),
      billing_address: a.billingAddress && Address.toApiData(a.billingAddress),
      name:            a.name,
      id:              a.id,
      promo_code:      a.promoCode,
    };
  }

  getCallrouteVoiceCount?(): number {
    if (!this.metadata.voice_counts || Array.isArray(this.metadata.voice_counts)) {
      return 0;
    }
    const { pstn_enabled_user_count, webex_count, call_forward_count, sip_phone_count } = this.metadata.voice_counts;
    return pstn_enabled_user_count + webex_count + call_forward_count + sip_phone_count;
  }

  getCurrentPackageMetadata?(): CapabilityInventoryItem {
    return this.metadata?.capability_inventory && Object.values(this.metadata.capability_inventory)
      .find(capability => capability.product.type === ProductType.Package && capability.status === InventoryStatus.Active);
  }

  getServiceStatusMetadata?(): ServiceStatusMetadata {
    return this.metadata?.service_statuses;
  }
}

