export class NumberSettings {
  TTL: string;
  requestId?: string;

  constructor(private data?: NumberSettings) {
    Object.assign(this, data);
    delete this['data'];
  }

  fromFormData?(data: Partial<{
    TTL: string,
  }>): NumberSettings {
    this.TTL = data.TTL;

    return this;
  }

  fromApiData?(data: { TTL: string }, requestId?: string): NumberSettings {
    this.TTL       = data.TTL;
    this.requestId = requestId;
    return this;
  }

}
