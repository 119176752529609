import { StoreState }     from '@redux/store';
import { AuditState }     from '@redux/audit/audit.reducer';
import { createSelector } from '@ngrx/store';

export const selectAudit             = (state: StoreState): AuditState => state.auditReducer;
export const selectAuditPendingTasks = createSelector(selectAudit, state => state.pendingTasks);
export const selectTasks             = createSelector(selectAudit, state => state.tasks);
export const selectTask              = createSelector(selectAudit, state => state.selectedTask);
export const selectTaskCounts        = createSelector(selectAudit, state => state.counts);
export const selectTaskQueryParams   = createSelector(selectAudit, state => state.taskQueryParams);
export const selectAuditList         = createSelector(selectAudit, state => state.auditList);
export const selectAuditQueryParams  = createSelector(selectAudit, state => state.auditQueryParams);
export const selectAuditContexts     = createSelector(selectAudit, state => state.contexts);
export const selectAuditObjects      = createSelector(selectAudit, state => state.objects);
