import { ListResponseMetadata } from '../api/list-response-metadata.model';
import { ListParams }           from './list-params.model';
import { ListParamModel }       from './list-param-model';
import { RestrictedInclude }    from '@models/entity/restricted-params.model';
import { AuthScope }            from '@enums/auth-scope.enum';
import { ListParamKeys }        from '@models/form/list-param-keys.model';

export class UserQueryParams extends ListParams implements ListParamModel<UserQueryParams> {
  search?: string;
  role?: string;
  securityGroupIds?: string[];
  include?: string[];
  status?: string[];
  id?: string[];

  static constructQueryString(params: UserQueryParams, _: ListParamKeys, restrictedInclude: RestrictedInclude, scopes: AuthScope[]): string {
    const p = { ...params };

    return ListParams.constructQueryString(p, {
      search:           'filter[search]',
      role:             'filter[role]',
      status:           'filter[status][]',
      id:               'filter[id][]',
      securityGroupIds: 'filter[security_group_id][]',
      include:          'include[]',
    }, restrictedInclude, scopes);
  }

  static queryChanged(newQuery: UserQueryParams, currentQuery: UserQueryParams): boolean {

    return !currentQuery || ListParams.queryChanged(newQuery, currentQuery) ||
      newQuery.search !== currentQuery.search;

  }

  constructParams?(metaData: ListResponseMetadata): UserQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData.filter?.search) {
      this.search = metaData.filter.search;
    }
    if (metaData.filter?.status) {
      this.status = metaData.filter.status as string[];
    }
    if (metaData.filter?.role) {
      this.role = metaData.filter.role;
    }
    if (metaData.filter?.id) {
      this.id = metaData.filter.id;
    }
    if (metaData.filter?.security_group_ids) {
      this.securityGroupIds = metaData.filter.security_group_ids;
    }
    if (metaData.include) {
      this.include = metaData.include;
    }

    return this;
  }
}
