import { getServiceIcon }  from '../api/service.model';
import { ServiceItem }     from './service-item.model';
import { ServiceType }     from '@enums/service-type.enum';
import { WebexCallingRaw } from '@models/api/webex-calling-raw.model';

export class WebexCalling extends ServiceItem {

  icon        = getServiceIcon(ServiceType.WebexCalling, false);
  colorIcon   = getServiceIcon(ServiceType.WebexCalling, true);
  serviceType = ServiceType.WebexCalling;
  trunkGroup: string;
  outboundProxyAddress: string;
  registrarDomain: string;
  linePort: string;
  username: string;
  password?: string;
  isGatewayUp: boolean;

  constructor(protected data?: WebexCalling) {
    super(data);
    Object.assign(this, { ...(data || {}) });
    delete this['data'];
  }

  toApiData?(omitEmptyCreds?: boolean): WebexCallingRaw {
    const credentials: Partial<WebexCallingRaw> = {};
    if (!omitEmptyCreds) {
      credentials.username = this.username;
      credentials.password = this.password;
    } else {
      if (this.username) {
        credentials.username = this.username;
      }
      if (this.password) {
        credentials.password = this.password;
      }
    }
    return {
      type:                   this.serviceType,
      trunk_group_otg_dtg:    this.trunkGroup,
      outbound_proxy_address: this.outboundProxyAddress,
      registrar_domain:       this.registrarDomain,
      label:                  this.label,
      line_port:              this.linePort,
      ...credentials,
    } as WebexCallingRaw;
  }

  fromApiData?(apiData: WebexCallingRaw): WebexCalling {
    super.fromApiData(apiData);
    this.trunkGroup           = apiData.trunk_group_otg_dtg;
    this.outboundProxyAddress = apiData.outbound_proxy_address;
    this.registrarDomain      = apiData.registrar_domain;
    this.linePort             = apiData.line_port;
    this.username             = apiData.username;
    this.isGatewayUp          = apiData.is_gateway_up;

    return this;
  }

}
