import { Injectable, OnDestroy }                                      from '@angular/core';
import { ServiceItem }                                                from '@models/entity/service-item.model';
import { ServiceTab }                                                 from '@enums/service-tab.enum';
import { getServiceSlug }                                             from '@models/api/service.model';
import { RouterService }                                              from './router.service';
import {
  TextInputModalComponent,
}                                                                     from '@dialog/general/text-input-modal/text-input-modal.component';
import { ServiceType }                                                from '@enums/service-type.enum';
import {
  DeleteServiceRequestAction,
  PatchServiceRequestAction,
  PostServiceRequestAction,
  SyncNumbersRequestAction,
}                                                                     from '@redux/service/service.actions';
import { WebexCalling }                                               from '@models/entity/webex-calling.model';
import { MicrosoftTeams }                                             from '@models/entity/microsoft-teams.model';
import { SIPTrunk }                                                   from '@models/entity/sip-trunk.model';
import { MatDialog }                                                  from '@angular/material/dialog';
import { StoreState }                                                 from '@redux/store';
import { Store }                                                      from '@ngrx/store';
import {
  NoActiveUserModalComponent,
}                                                                     from '@dialog/service/no-active-user-modal/no-active-user-modal.component';
import {
  WebexServiceModalComponent,
}                                                                     from '@dialog/service/webex-service-modal/webex-service-modal.component';
import {
  SipTrunkModalComponent,
}                                                                     from '@dialog/sip-trunk-modal/sip-trunk-modal.component';
import { ServiceItemRaw }                                             from '@models/api/service-api-response.model';
import { AuthScope }                                                  from '@enums/auth-scope.enum';
import { selectServiceCounts, selectServiceItem, selectServiceItems } from '@redux/service/service.selectors';
import { DeleteTokensRequestAction, RefreshAuthStatus }               from '@redux/access-broker/access-broker.actions';
import { combineLatest, Subject }                                     from 'rxjs';
import { filter, map, take, takeUntil }                               from 'rxjs/operators';
import { SIPPhone }                                                   from '@models/entity/sip-phone.model';
import { StatusItem }                                                 from '@models/entity/status-item.model';
import { selectUserScopes }                                           from '@redux/auth/auth.selectors';
import {
  HealthCheckRequestAction,
}                                                                     from '@redux/microsoft-teams/microsoft-teams.actions';
import { TokenStatus }                                                from '@enums/token-status.enum';
import {
  SipPhoneModalComponent,
}                                                                     from '@dialog/sip-phone-modal/sip-phone-modal.component';
import {
  selectTokens,
}                                                                     from '@redux/access-broker/access-broker.selectors';
import { Token }                                                      from '@models/entity/token-state.model';
import {
  TeamsSettingsModalComponent,
}                                                                     from '@dialog/teams-settings-modal/teams-settings-modal.component';
import {
  MicrosoftTeamsSettings,
}                                                                     from '@models/entity/microsoft-teams-settings.model';
import { SetMicrosoftTeamsSettingsRequestAction }                     from '@redux/configuration/configuration.actions';
import { ServiceAction }                                              from '@services/abstract/service-action.class';
import { ComponentType }                                              from '@angular/cdk/overlay';
import {
  ConfigureCarrierModalComponent,
}                                                                     from '@dialog/configure-carrier-modal/configure-carrier-modal.component';
import { Carrier }                                                    from '@models/entity/carrier.model';
import {
  ConfigureCustomCarrierModalComponent,
}                                                                     from '@dialog/configure-custom-carrier-modal/configure-custom-carrier-modal.component';
import {
  MsTeamsExtraInfoModalComponent,
}                                                                     from '@dialog/ms-teams-extra-info-modal/ms-teams-extra-info-modal.component';
import { HealthInfo }                                                 from '@models/api/health-info.model';
import {
  ConfirmModalComponent,
}                                                                     from '@dialog/general/confirm-modal/confirm-modal.component';
import {
  ProvisioningSettingsModalComponent,
}                                                                     from '@dialog/provisioning-settings/provisioning-settings-modal.component';
import {
  SyncNumbersModalComponent,
}                                                                     from '../modules/numbers/sync-numbers-modal/sync-numbers-modal.component';
import { ServiceCapabilityIdentifier }                                from '@enums/service-capability-identifier.enum';
import { TeamsSettingsDialogData }                                    from '@models/ui/teams-settings-dialog.model';
import { SafeHtml }                                                   from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ServiceActionService implements OnDestroy, ServiceAction {
  private destroy  = new Subject<void>();
  private destroy$ = this.destroy.asObservable();

  constructor(private router: RouterService,
              private dialog: MatDialog,
              private store: Store<StoreState>) { }

  viewList(serviceSlug: string, hasScope: boolean, serviceCount: number): Promise<boolean> {
    if (!hasScope || !serviceCount) {
      return;
    }
    return this.router.navigate(['/services', serviceSlug]);
  }

  view(serviceItem: ServiceItem, scopes: string[], hash?: ServiceTab): Promise<boolean> {
    if (!serviceItem || !scopes?.includes(AuthScope.ServiceRead)) {
      return;
    }
    const isSuccess           = serviceItem.isActive();
    let startHash: ServiceTab = isSuccess ? ServiceTab.Settings : ServiceTab.Configure;
    if (ServiceItem.isMicrosoft(serviceItem)) {
      startHash = isSuccess ? ServiceTab.Users : ServiceTab.Configure;
    }

    return this.router.navigate(['/services', getServiceSlug(serviceItem.serviceType), serviceItem.id], undefined, { fragment: hash || startHash });
  }

  openLabelModal(item: ServiceItem): void {
    const labelDialog = this.dialog.open(TextInputModalComponent, {
      data:       {
        title:        'Edit label',
        fieldLabel:   'Please enter a value',
        existingData: item.label,
        confirmText:  'Save',
        maxLength:    30,
      },
      panelClass: 'cr-dialog',
      maxWidth:   '560px',
      width:      '100%',
    });

    labelDialog.afterClosed()
      .subscribe(result => {
        if (typeof result === 'string') {
          this.store.dispatch(PatchServiceRequestAction({
            serviceItem: { ...item, label: result } as WebexCalling & MicrosoftTeams & SIPTrunk & SIPPhone,
            formFields:  new Map<keyof ServiceItemRaw, keyof ServiceItem>([['label', 'label']]),
          }));
        }
      });
  }

  async initialTokenCheck(): Promise<void> {
    const serviceItem = await this.store.select(selectServiceItem)
      .pipe(
        filter(service => ServiceItem.isMicrosoft(service)),
        take(1),
      )
      .toPromise();
    this.store.dispatch(RefreshAuthStatus({ serviceId: serviceItem.id }));
  }

  runInitialHealthCheck(): void {
    combineLatest([
      this.store.select(selectServiceItems),
      this.store.select(selectServiceItem),
      this.store.select(selectTokens),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(([items, serviceItem, tokens]: [ServiceItem[], ServiceItem, { [serviceId: string]: Token[] }]) => {
          const msService         = ServiceItem.isMicrosoft(serviceItem) && serviceItem;
          const msServiceFromList = items?.find(item => ServiceItem.isMicrosoft(item));

          if ([msService, msServiceFromList].filter(service => !!service)
            .some(service => service.isActive() && StatusItem.isPending((service as MicrosoftTeams).healthStatus.status))) { // only run if health is pending
            return [msService || msServiceFromList, tokens[msService?.id || msServiceFromList?.id]] as [ServiceItem, Token[]];
          }
          return [null, null] as [ServiceItem, Token[]];
        }),
        filter(([item, tokens]) => !!item && tokens?.every(token => token && ![TokenStatus.Processing, TokenStatus.Pending].includes(token?.status))),
        take(1),
      )
      .subscribe(([item, tokens]: [ServiceItem, Token[]]) => {
        this.store.dispatch(HealthCheckRequestAction({
          serviceId: item.id,
          tokens,
        }));
      });
  }

  create<T, U>(serviceType: ServiceType, hasScope: boolean, data: U = null, label?: string, icon?: string, capabilities?: ServiceCapabilityIdentifier[]): Promise<T | void> {
    if (!hasScope) {
      return;
    }
    switch (serviceType) {
      case ServiceType.WebexCalling:
        return this.openCreateModal<WebexServiceModalComponent, T, U>(data, WebexServiceModalComponent);
      case ServiceType.SIPTrunk:
        return this.openCreateModal<SipTrunkModalComponent, T, U>(data, SipTrunkModalComponent, label, icon);
      case ServiceType.SIPPhone:
        return this.openCreateModal<SipPhoneModalComponent, T, U>(data, SipPhoneModalComponent);
      case ServiceType.Carrier:
        if ((data as Carrier)?.identifier === 'custom') {
          return this.openCreateModal<ConfigureCustomCarrierModalComponent, T, U>(data, ConfigureCustomCarrierModalComponent);
        }
        if ((data as Carrier)?.identifier === 'gamma-gbr') {
          return this.openContactUsModal(
            (data as Carrier).logoUri,
            `<p>Adding your Gamma trunk currently requires manual configuration by the Callroute team. It will be automated in a future release.
<br><br>
Please contact <a href="mailto:sales@callroute.com">Callroute</a> to progress this request. Requests are typically turned around within 48 hours.</p>`,
          );
        }
        return this.openCreateModal<ConfigureCarrierModalComponent, T, U>(data, ConfigureCarrierModalComponent);
      case ServiceType.MicrosoftTeams:
        return this.createTeams(capabilities);
      default:
        return;
    }
  }

  private async createTeams(capabilities: ServiceCapabilityIdentifier[]): Promise<void> {
    return this.store.dispatch(PostServiceRequestAction({
      serviceItem: new MicrosoftTeams(),
      capabilities,
    }));
  }

  async openContactUsModal(icon: string, content: SafeHtml, title: string = 'Contact us', subheader?: string): Promise<void> {
    return this.dialog.open(ConfirmModalComponent, {
      panelClass: 'cr-dialog',
      maxWidth:   '700px',
      data:       {
        title,
        subheader,
        icon,
        content,
        confirmBtnText: 'Close',
      },
    })
      .afterClosed()
      .toPromise()
      .then(() => null);
  }

  delete(service: ServiceItem, hasScope: boolean): Promise<boolean> {
    if (!hasScope) {
      return;
    }
    this.store.dispatch(DeleteServiceRequestAction({
      id:          service.id,
      serviceType: service.serviceType,
      serviceName: service.label || service.name,
    }));
  }

  deleteTokens(serviceId: string): void {
    this.store.dispatch(DeleteTokensRequestAction({ serviceId }));
  }

  async goToNewService(serviceItem: ServiceItem): Promise<boolean> {
    if (!serviceItem) {
      return;
    }
    const scopes = await this.store.select(selectUserScopes)
      .pipe(
        filter(arr => Array.isArray(arr)),
        take(1))
      .toPromise();
    return this.view(serviceItem, scopes);
  }

  openServiceUserPrompt(): void {
    this.dialog.open(NoActiveUserModalComponent, {
      panelClass: 'cr-dialog',
      maxWidth:   '620px',
    });
  }

  openTeamsSettings(data: TeamsSettingsDialogData): void {
    this.dialog.open(TeamsSettingsModalComponent, {
      panelClass: 'cr-dialog',
      maxWidth:   '600px',
      minWidth:   '50vw',
      data,
    })
      .afterClosed()
      .subscribe((settings: MicrosoftTeamsSettings) => {
        if (!settings) {
          return;
        }
        this.store.dispatch(SetMicrosoftTeamsSettingsRequestAction({
          settings,
          companyId: data.viewingCompanyId,
        }));
      });
  }

  openProvisioningSettings(data: { settings: MicrosoftTeamsSettings, viewingCompanyId: string }): void {
    this.dialog.open(ProvisioningSettingsModalComponent, {
      panelClass: 'cr-dialog',
      maxWidth:   '600px',
      minWidth:   '40vw',
      data,
    })
      .afterClosed()
      .subscribe((settings: MicrosoftTeamsSettings) => {
        if (!settings) {
          return;
        }
        this.store.dispatch(SetMicrosoftTeamsSettingsRequestAction({
          settings,
          companyId: data.viewingCompanyId,
        }));
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  openExtraInformationModal(data: HealthInfo): void {
    this.dialog.open(MsTeamsExtraInfoModalComponent, {
      panelClass: 'cr-dialog',
      maxWidth:   '600px',
      minWidth:   '40vw',
      data,
    });
  }

  private openCreateModal<T, U, V>(data: V = null, component: ComponentType<T>, label?: string, icon?: string): Promise<U> {
    return this.dialog.open(component, {
      panelClass: 'cr-dialog',
      maxWidth:   '847px',
      maxHeight:  'calc(100vh - 140px)',
      width:      '100%',
      data:       {
        service: data,
        label,
        icon,
      },
      position:   {
        top: '70px',
      },
    })
      .afterClosed()
      .toPromise();
  }

  async syncNumbers(): Promise<void> {
    const services      = await this.store.select(selectServiceItems)
      .pipe(map(items => items.filter(service => ServiceItem.isMicrosoft(service))), take(1))
      .toPromise();
    const serviceCounts = await this.store.select(selectServiceCounts)
      .pipe(take(1))
      .toPromise();
    const teamsCount    = serviceCounts?.MICROSOFT_TEAMS?.TOTAL;
    if (!teamsCount) {
      return;
    }

    if (teamsCount > 1) {
      return this.dialog.open(SyncNumbersModalComponent, {
        panelClass: 'cr-dialog',
        maxWidth:   '600px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
        position:   {
          top: '70px',
        },
      })
        .afterClosed()
        .toPromise();
    }
    this.store.dispatch(SyncNumbersRequestAction({ serviceId: services[0].id }));
  }
}
