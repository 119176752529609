import { createAction, props } from '@ngrx/store';

import {
  ADD_NUMBER_RANGE_REQUEST,
  ADD_NUMBER_RANGE_RESPONSE,
  ASSIGN_INBOUND_NUMBER_REQUEST,
  ASSIGN_INBOUND_NUMBER_RESPONSE,
  BULK_NUMBER_UPDATE_REQUEST,
  BULK_NUMBER_UPDATE_RESPONSE,
  BULK_PROVISION_REQUEST,
  BULK_PROVISION_RESPONSE,
  CLEAR_SELECTED_NUMBER,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_RESPONSE,
  DELETE_NUMBER_RANGE_REQUEST,
  DELETE_NUMBER_RANGE_RESPONSE,
  DELETE_NUMBER_REQUEST,
  DELETE_NUMBER_RESPONSE,
  DELETE_NUMBER_TAG_REQUEST,
  DELETE_NUMBER_TAG_RESPONSE,
  EXPORT_NUMBERS_REQUEST,
  EXPORT_NUMBERS_RESPONSE,
  FETCH_ALL_NUMBER_TAGS_REQUEST,
  FETCH_ALL_NUMBER_TAGS_RESPONSE,
  FETCH_BULK_COUNT_BY_ASSIGNED_REQUEST,
  FETCH_BULK_COUNT_BY_ASSIGNED_RESPONSE,
  FETCH_INVENTORY_AREA_LIST_REQUEST,
  FETCH_INVENTORY_AREA_LIST_RESPONSE,
  FETCH_INVENTORY_PROVIDER_LIST_REQUEST,
  FETCH_INVENTORY_PROVIDER_LIST_RESPONSE,
  FETCH_LOCATION_NAME_AVAILABILITY_REQUEST,
  FETCH_LOCATION_NAME_AVAILABILITY_RESPONSE,
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATION_RESPONSE,
  FETCH_LOCATIONS_REQUEST,
  FETCH_LOCATIONS_RESPONSE,
  FETCH_NUMBER_COMPATIBILITY_REQUEST,
  FETCH_NUMBER_COMPATIBILITY_RESPONSE,
  FETCH_RANGE_COUNT_BY_EXHAUSTION_STATUS_REQUEST,
  FETCH_RANGE_COUNT_BY_EXHAUSTION_STATUS_RESPONSE,
  FETCH_NUMBER_COUNTS_REQUEST,
  FETCH_NUMBER_COUNTS_RESPONSE,
  FETCH_NUMBER_LIST_REQUEST,
  FETCH_NUMBER_LIST_RESPONSE,
  FETCH_NUMBER_RANGE_LIST_REQUEST,
  FETCH_NUMBER_RANGE_LIST_RESPONSE,
  FETCH_NUMBER_RANGE_REQUEST,
  FETCH_NUMBER_RANGE_RESPONSE,
  FETCH_NUMBER_REQUEST,
  FETCH_NUMBER_RESPONSE,
  FETCH_NUMBER_TAGS_REQUEST,
  FETCH_NUMBER_TAGS_RESPONSE,
  FETCH_NUMBERS_SERVICE_METADATA_REQUEST,
  FETCH_NUMBERS_SERVICE_METADATA_RESPONSE,
  IMPORT_NUMBERS_REQUEST,
  IMPORT_NUMBERS_RESPONSE,
  NUMBER_LOCATION_REMOVE_REQUEST,
  NUMBER_LOCATION_REMOVE_RESPONSE,
  NUMBER_RESERVATION_REMOVE_REQUEST,
  NUMBER_RESERVATION_REMOVE_RESPONSE,
  PATCH_LOCATION_REQUEST,
  PATCH_LOCATION_RESPONSE,
  PATCH_NUMBER_RANGE_REQUEST,
  PATCH_NUMBER_RANGE_RESPONSE,
  PATCH_NUMBER_REQUEST,
  PATCH_NUMBER_RESPONSE,
  PATCH_NUMBER_TAG_REQUEST,
  PATCH_NUMBER_TAG_RESPONSE,
  POST_LOCATION_REQUEST,
  POST_LOCATION_RESPONSE,
  SET_NUMBER_RANGE_ROUTES_REQUEST,
  SET_NUMBER_RANGE_ROUTES_RESPONSE,
}                                          from './number.types';
import { FetchNumberListResponse }         from '@models/api/fetch-number-list-response.model';
import { PatchNumberRequest }              from '@models/api/patch-number-request.model';
import { AssignInboundNumberRequest }      from '@models/api/assign-inbound-number-request.model';
import { AssignInboundNumberResponse }     from '@models/api/assign-inbound-number-response.model';
import { BulkProvisionRequest }            from '@models/api/bulk-provision-request.model';
import { BulkProvisionResponse }           from '@models/api/bulk-provision-response.model';
import { PatchNumberResponse }             from '@models/api/patch-number-response.model';
import { FetchNumberTagsRequest }          from '@models/api/fetch-number-tags-request.model';
import { FetchNumberTagsResponse }         from '@models/api/fetch-number-tags-response.model';
import { FetchNumberRequest }              from '@models/api/fetch-number-request.model';
import { FetchNumberResponse }             from '@models/api/fetch-number-response.model';
import { FetchNumberCountsRequest }        from '@models/api/fetch-number-counts-request.model';
import { FetchNumberCountsResponse }       from '@models/api/fetch-number-counts-response.model';
import { FetchNumberRangeListRequest }     from '@models/api/fetch-number-range-list-request.model';
import { FetchNumberRangeListResponse }    from '@models/api/fetch-number-range-list-response.model';
import { AddNumberRangeRequest }           from '@models/api/add-number-range-request.model';
import { AddNumberRangeResponse }          from '@models/api/add-number-range-response.model';
import { SetNumberRangeRoutesRequest }     from '@models/api/set-number-range-routes-request.model';
import { SetNumberRangeRoutesResponse }    from '@models/api/set-number-range-routes-response.model';
import { FetchNumberRangeRequest }         from '@models/api/fetch-number-range-request.model';
import { FetchNumberRangeResponse }        from '@models/api/fetch-number-range-response.model';
import { FetchNumberListRequest }          from '@models/api/fetch-number-list-request';
import { PatchNumberRangeRequest }         from '@models/api/patch-number-range-request.model';
import { PatchNumberRangeResponse }        from '@models/api/patch-number-range-response.model';
import { DeleteNumberRangeRequest }        from '@models/api/delete-number-range-request.model';
import { DeleteNumberRangeResponse }       from '@models/api/delete-number-range-response.model';
import { FetchInventoryProvidersResponse } from '@models/api/fetch-inventory-providers-response.model';
import { DeleteNumberRequest }             from '@models/api/delete-number-request.model';
import { DeleteNumberResponse }            from '@models/api/delete-number-response.model';
import { PatchNumberTagRequest }           from '@models/api/patch-number-tag-request.model';
import { PatchNumberTagResponse }          from '@models/api/patch-number-tag-response.model';
import { DeleteNumberTagRequest }          from '@models/api/delete-number-tag-request.model';
import { DeleteNumberTagResponse }         from '@models/api/delete-number-tag-response.model';
import { ExportNumbersRequest }            from '@models/api/number/export-numbers-request.model';
import { ExportNumbersResponse }           from '@models/api/number/export-numbers-response.model';
import { FetchLocationsRequest }           from '@models/api/number/fetch-locations-request.model';
import { FetchLocationsResponse }          from '@models/api/number/fetch-locations-response.model';
import { FetchLocationRequest }            from '@models/api/number/fetch-location-request.model';
import { FetchLocationResponse }           from '@models/api/number/fetch-location-response.model';
import { DeleteLocationRequest }           from '@models/api/number/delete-location-request.model';
import { DeleteLocationResponse }          from '@models/api/number/delete-location-response.model';
import { PostLocationRequest }             from '@models/api/number/post-location-request.model';
import { PostLocationResponse }            from '@models/api/number/post-location-response.model';
import { PatchLocationRequest }            from '@models/api/number/patch-location-request.model';
import { PatchLocationResponse }           from '@models/api/number/patch-location-response.model';
import {
  FetchLocationNameAvailabilityRequest,
}                                          from '@models/api/number/fetch-location-name-availability-request.model';
import {
  FetchLocationNameAvailabilityResponse,
}                                          from '@models/api/number/fetch-location-name-availability-response.model';
import {
  FetchBulkCountByAssignedRequest,
}                                          from '@models/api/number/fetch-bulk-count-by-assigned-request.model';
import {
  FetchBulkCountByAssignedResponse,
}                                          from '@models/api/number/fetch-bulk-count-by-assigned-response.model';
import {
  NumberReservationRemoveRequest,
}                                          from '@models/api/number/number-reservation-remove-request.model';
import {
  NumberReservationRemoveResponse,
}                                          from '@models/api/number/number-reservation-remove-response.model';
import { BulkNumberUpdateRequest }         from '@models/api/number/bulk-number-update-request.model';
import { BulkNumberUpdateResponse }        from '@models/api/number/bulk-number-update-response.model';
import {
  FetchNumbersServiceMetadataRequest,
}                                          from '@models/api/number/fetch-numbers-service-metadata-request.model';
import {
  FetchNumbersServiceMetadataResponse,
}                                          from '@models/api/number/fetch-numbers-service-metadata-response.model';
import {
  FetchInventoryAreaListRequest,
}                                          from '@models/api/number/fetch-inventory-area-list-request.model';
import {
  FetchInventoryAreaListResponse,
}                                          from '@models/api/number/fetch-inventory-area-list-response.model';
import { ImportNumbersRequest }            from '@models/api/number/import-numbers-request.model';
import { ImportNumbersResponse }           from '@models/api/number/import-numbers-response.model';
import { BaseRequest }                     from '@redux/helpers/reducer.helper';
import {
  FetchNumberCompatibilityRequest,
}                                          from '@models/api/number/fetch-number-compatibility-request.model';
import {
  FetchNumberCompatibilityResponse,
} from '@models/api/number/fetch-number-compatibility-response.model';
import {
  FetchRangeCountByExhaustionStatusRequest,
} from '@models/api/number/fetch-range-count-by-exhaustion-status-request.model';
import {
  FetchRangeCountByExhaustionStatusResponse,
} from '@models/api/number/fetch-range-count-by-exhaustion-status-response.model';

export const FetchNumberRangeListRequestAction  = createAction(FETCH_NUMBER_RANGE_LIST_REQUEST, props<FetchNumberRangeListRequest>());
export const FetchNumberRangeListResponseAction = createAction(FETCH_NUMBER_RANGE_LIST_RESPONSE, props<FetchNumberRangeListResponse>());

export const AddNumberRangeRequestAction  = createAction(ADD_NUMBER_RANGE_REQUEST, props<AddNumberRangeRequest>());
export const AddNumberRangeResponseAction = createAction(ADD_NUMBER_RANGE_RESPONSE, props<AddNumberRangeResponse>());

export const SetNumberRangeRoutesRequestAction  = createAction(SET_NUMBER_RANGE_ROUTES_REQUEST, props<SetNumberRangeRoutesRequest>());
export const SetNumberRangeRoutesResponseAction = createAction(SET_NUMBER_RANGE_ROUTES_RESPONSE, props<SetNumberRangeRoutesResponse>());

export const FetchNumberRangeRequestAction  = createAction(FETCH_NUMBER_RANGE_REQUEST, props<FetchNumberRangeRequest>());
export const FetchNumberRangeResponseAction = createAction(FETCH_NUMBER_RANGE_RESPONSE, props<FetchNumberRangeResponse>());

export const DeleteNumberRangeRequestAction  = createAction(DELETE_NUMBER_RANGE_REQUEST, props<DeleteNumberRangeRequest>());
export const DeleteNumberRangeResponseAction = createAction(DELETE_NUMBER_RANGE_RESPONSE, props<DeleteNumberRangeResponse>());

export const FetchNumberCountsRequestAction  = createAction(FETCH_NUMBER_COUNTS_REQUEST, props<FetchNumberCountsRequest>());
export const FetchNumberCountsResponseAction = createAction(FETCH_NUMBER_COUNTS_RESPONSE, props<FetchNumberCountsResponse>());

export const FetchNumberListRequestAction  = createAction(FETCH_NUMBER_LIST_REQUEST, props<FetchNumberListRequest>());
export const FetchNumberListResponseAction = createAction(FETCH_NUMBER_LIST_RESPONSE, props<FetchNumberListResponse>());

export const FetchNumberRequestAction  = createAction(FETCH_NUMBER_REQUEST, props<FetchNumberRequest>());
export const FetchNumberResponseAction = createAction(FETCH_NUMBER_RESPONSE, props<FetchNumberResponse>());

export const PatchNumberRequestAction  = createAction(PATCH_NUMBER_REQUEST, props<PatchNumberRequest>());
export const PatchNumberResponseAction = createAction(PATCH_NUMBER_RESPONSE, props<PatchNumberResponse>());

export const AssignInboundNumberRequestAction  = createAction(ASSIGN_INBOUND_NUMBER_REQUEST, props<AssignInboundNumberRequest>());
export const AssignInboundNumberResponseAction = createAction(ASSIGN_INBOUND_NUMBER_RESPONSE, props<AssignInboundNumberResponse>());

export const FetchNumberTagsRequestAction  = createAction(FETCH_NUMBER_TAGS_REQUEST, props<FetchNumberTagsRequest>());
export const FetchNumberTagsResponseAction = createAction(FETCH_NUMBER_TAGS_RESPONSE, props<FetchNumberTagsResponse>());

export const FetchInventoryProviderRequestAction  = createAction(FETCH_INVENTORY_PROVIDER_LIST_REQUEST, props<BaseRequest>());
export const FetchInventoryProviderResponseAction = createAction(FETCH_INVENTORY_PROVIDER_LIST_RESPONSE, props<FetchInventoryProvidersResponse>());

export const BulkProvisionRequestAction  = createAction(BULK_PROVISION_REQUEST, props<BulkProvisionRequest>());
export const BulkProvisionResponseAction = createAction(BULK_PROVISION_RESPONSE, props<BulkProvisionResponse>());

export const ClearSelectedNumberAction = createAction(CLEAR_SELECTED_NUMBER);

export const PatchNumberRangeRequestAction  = createAction(PATCH_NUMBER_RANGE_REQUEST, props<PatchNumberRangeRequest>());
export const PatchNumberRangeResponseAction = createAction(PATCH_NUMBER_RANGE_RESPONSE, props<PatchNumberRangeResponse>());

export const DeleteNumberRequestAction  = createAction(DELETE_NUMBER_REQUEST, props<DeleteNumberRequest>());
export const DeleteNumberResponseAction = createAction(DELETE_NUMBER_RESPONSE, props<DeleteNumberResponse>());

export const PatchNumberTagRequestAction  = createAction(PATCH_NUMBER_TAG_REQUEST, props<PatchNumberTagRequest>());
export const PatchNumberTagResponseAction = createAction(PATCH_NUMBER_TAG_RESPONSE, props<PatchNumberTagResponse>());

export const DeleteNumberTagRequestAction  = createAction(DELETE_NUMBER_TAG_REQUEST, props<DeleteNumberTagRequest>());
export const DeleteNumberTagResponseAction = createAction(DELETE_NUMBER_TAG_RESPONSE, props<DeleteNumberTagResponse>());

export const FetchAllNumberTagsRequestAction  = createAction(FETCH_ALL_NUMBER_TAGS_REQUEST, props<FetchNumberTagsRequest>());
export const FetchAllNumberTagsResponseAction = createAction(FETCH_ALL_NUMBER_TAGS_RESPONSE, props<FetchNumberTagsResponse>());

export const FetchInventoryAreaListRequestAction  = createAction(FETCH_INVENTORY_AREA_LIST_REQUEST, props<FetchInventoryAreaListRequest>());
export const FetchInventoryAreaListResponseAction = createAction(FETCH_INVENTORY_AREA_LIST_RESPONSE, props<FetchInventoryAreaListResponse>());

export const ExportNumbersRequestAction  = createAction(EXPORT_NUMBERS_REQUEST, props<ExportNumbersRequest>());
export const ExportNumbersResponseAction = createAction(EXPORT_NUMBERS_RESPONSE, props<ExportNumbersResponse>());

export const FetchLocationsRequestAction  = createAction(FETCH_LOCATIONS_REQUEST, props<FetchLocationsRequest>());
export const FetchLocationsResponseAction = createAction(FETCH_LOCATIONS_RESPONSE, props<FetchLocationsResponse>());

export const FetchLocationRequestAction  = createAction(FETCH_LOCATION_REQUEST, props<FetchLocationRequest>());
export const FetchLocationResponseAction = createAction(FETCH_LOCATION_RESPONSE, props<FetchLocationResponse>());

export const DeleteLocationRequestAction  = createAction(DELETE_LOCATION_REQUEST, props<DeleteLocationRequest>());
export const DeleteLocationResponseAction = createAction(DELETE_LOCATION_RESPONSE, props<DeleteLocationResponse>());

export const PostLocationRequestAction  = createAction(POST_LOCATION_REQUEST, props<PostLocationRequest>());
export const PostLocationResponseAction = createAction(POST_LOCATION_RESPONSE, props<PostLocationResponse>());

export const PatchLocationRequestAction  = createAction(PATCH_LOCATION_REQUEST, props<PatchLocationRequest>());
export const PatchLocationResponseAction = createAction(PATCH_LOCATION_RESPONSE, props<PatchLocationResponse>());

export const FetchLocationNameAvailabilityRequestAction  = createAction(FETCH_LOCATION_NAME_AVAILABILITY_REQUEST, props<FetchLocationNameAvailabilityRequest>());
export const FetchLocationNameAvailabilityResponseAction = createAction(FETCH_LOCATION_NAME_AVAILABILITY_RESPONSE, props<FetchLocationNameAvailabilityResponse>());

export const FetchBulkCountByAssignedRequestAction  = createAction(FETCH_BULK_COUNT_BY_ASSIGNED_REQUEST, props<FetchBulkCountByAssignedRequest>());
export const FetchBulkCountByAssignedResponseAction = createAction(FETCH_BULK_COUNT_BY_ASSIGNED_RESPONSE, props<FetchBulkCountByAssignedResponse>());

export const NumberLocationRemoveRequestAction  = createAction(NUMBER_LOCATION_REMOVE_REQUEST, props<PatchNumberRequest>());
export const NumberLocationRemoveResponseAction = createAction(NUMBER_LOCATION_REMOVE_RESPONSE, props<PatchNumberResponse>());

export const NumberReservationRemoveRequestAction  = createAction(NUMBER_RESERVATION_REMOVE_REQUEST, props<NumberReservationRemoveRequest>());
export const NumberReservationRemoveResponseAction = createAction(NUMBER_RESERVATION_REMOVE_RESPONSE, props<NumberReservationRemoveResponse>());

export const BulkNumberUpdateRequestAction  = createAction(BULK_NUMBER_UPDATE_REQUEST, props<BulkNumberUpdateRequest>());
export const BulkNumberUpdateResponseAction = createAction(BULK_NUMBER_UPDATE_RESPONSE, props<BulkNumberUpdateResponse>());

export const FetchNumbersServiceMetadataRequestAction  = createAction(FETCH_NUMBERS_SERVICE_METADATA_REQUEST, props<FetchNumbersServiceMetadataRequest>());
export const FetchNumbersServiceMetadataResponseAction = createAction(FETCH_NUMBERS_SERVICE_METADATA_RESPONSE, props<FetchNumbersServiceMetadataResponse>());

export const ImportNumbersRequestAction  = createAction(IMPORT_NUMBERS_REQUEST, props<ImportNumbersRequest>());
export const ImportNumbersResponseAction = createAction(IMPORT_NUMBERS_RESPONSE, props<ImportNumbersResponse>());

export const FetchNumberCompatibilityRequestAction  = createAction(FETCH_NUMBER_COMPATIBILITY_REQUEST, props<FetchNumberCompatibilityRequest>());
export const FetchNumberCompatibilityResponseAction = createAction(FETCH_NUMBER_COMPATIBILITY_RESPONSE, props<FetchNumberCompatibilityResponse>());

export const FetchRangeCountByExhaustionStatusRequestAction  = createAction(FETCH_RANGE_COUNT_BY_EXHAUSTION_STATUS_REQUEST, props<FetchRangeCountByExhaustionStatusRequest>());
export const FetchRangeCountByExhaustionStatusResponseAction = createAction(FETCH_RANGE_COUNT_BY_EXHAUSTION_STATUS_RESPONSE, props<FetchRangeCountByExhaustionStatusResponse>());
