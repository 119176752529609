<section class="text-align-center">
  <h1 class="mat-display-2 fw-bolder">
    We've updated our Terms and Conditions
  </h1>
  <p>Please review and accept the latest terms and conditions</p>
  <a href="{{url}}" target="_blank" rel="nofollow noopener">
    <button mat-button type="button">
      <span class="color-primary">Click to view</span>&nbsp;<mat-icon class="color-primary"
                                                                   svgIcon="cr-open-new"></mat-icon>
    </button>
  </a>
  <mat-dialog-actions align="center" class="accept-terms-container">
    <mat-checkbox [formControl]="termsControl"
                  data-cy="form-terms"
                  class="mar-right-16"
                  color="primary">
      I have read and accepted the terms and conditions
    </mat-checkbox>
    <button class="button primary accept-btn" data-cy="submit" type="button" (click)="accept()"
            [disabled]="!termsControl.value">
      Done
    </button>
  </mat-dialog-actions>
</section>
