import { RoleRaw } from '@models/api/role-raw.model';
import { Rule }    from '@models/entity/rule.model';

export class Role {
  id: string;
  recordCount?: number;
  name: string;
  description: string;
  rules?: Rule[];
  isSystem?: boolean;

  fromApiData?(apiData: RoleRaw): Role {

    Object.assign(this, apiData);
    this.isSystem = apiData.is_system;

    return this;
  }
}
