import { ListParamModel }       from '@models/form/list-param-model';
import { ListParams }           from '@models/form/list-params.model';
import { ListResponseMetadata } from '@models/api/list-response-metadata.model';

export class SecurityGroupQueryParams extends ListParams implements ListParamModel<SecurityGroupQueryParams> {
  search?: string;
  id?: string[];

  constructor(private data?: SecurityGroupQueryParams) {
    super();
    Object.assign(this, { ...(this.data || {}) });
  }

  static constructQueryString(searchParams: SecurityGroupQueryParams): string {
    return ListParams.constructQueryString(searchParams, {
      search:  'filter[search]',
      id:      'filter[id][]',
      include: 'include[]',
    });
  }

  constructParams?(metaData: ListResponseMetadata): SecurityGroupQueryParams {
    super.constructParams(metaData);
    if (!metaData) {
      return this;
    }
    if (metaData?.filter?.search) {
      this.search = metaData.filter.search;
    }
    if (metaData?.filter?.id) {
      this.id = metaData.filter.id;
    }
    return this;
  }
}
