import { Component, Inject, Renderer2 }  from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceCarrier }                from '@models/entity/carrier-service.model';

@Component({
  selector:    'ngx-carrier-information-modal',
  templateUrl: './carrier-information-modal.component.html',
  styleUrls:   ['./carrier-information-modal.component.scss'],
})
export class CarrierInformationModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ServiceCarrier,
              private dialog: MatDialogRef<CarrierInformationModalComponent>,
              private renderer2: Renderer2) { }

  download(): void {
    const a    = this.renderer2.createElement('a') as HTMLAnchorElement;
    a.href     = 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.data.information);
    a.download = `${ this.data.label || this.data.name }.txt`;
    // start download
    a.click();
  }

  close(): void {
    this.dialog.close();
  }
}
