import { StoreState }     from '../store';
import { createSelector } from '@ngrx/store';
import { NumberState }    from './number.reducer';


export const selectNumber = (state: StoreState): NumberState => state.numberReducer;

export const selectNumberPendingTasks           = createSelector(selectNumber, numberState => numberState.pendingTasks);
export const selectNumberTags                   = createSelector(selectNumber, numberState => numberState.tags);
export const selectNumberTagsQueryParams        = createSelector(selectNumber, numberState => numberState.numberTagsQueryParams);
export const selectNumberQueryParams            = createSelector(selectNumber, numberState => numberState.numberQueryParams);
export const selectNumberCounts                 = createSelector(selectNumber, numberState => numberState.numberCounts);
export const selectNumbers                      = createSelector(selectNumber, numberState => numberState.numbers);
export const selectNumberRanges                 = createSelector(selectNumber, numberState => numberState.numberRanges);
export const selectNumberRange                  = createSelector(selectNumber, numberState => numberState.selectedNumberRange);
export const selectNumberRangeQueryParams       = createSelector(selectNumber, numberState => numberState.numberRangeQueryParams);
export const selectNumberError                  = createSelector(selectNumber, numberState => numberState.error);
export const selectNumberMessage                = createSelector(selectNumber, numberState => numberState.message);
export const selectAllNumberTags                = createSelector(selectNumber, numberState => numberState.allNumberTags);
export const selectLocations                    = createSelector(selectNumber, numberState => numberState.locations);
export const selectLocationQueryParams          = createSelector(selectNumber, numberState => numberState.locationQueryParams);
export const selectLocation                     = createSelector(selectNumber, numberState => numberState.selectedLocation);
export const selectLocationNameAvailability     = createSelector(selectNumber, numberState => numberState.locationNameAvailability);
export const selectBulkCountByAssigned          = createSelector(selectNumber, numberState => numberState.bulkCountByAssigned);
export const selectNumbersServiceMetadata       = createSelector(selectNumber, numberState => numberState.numberServiceMetadata);
export const selectNumberItem                   = createSelector(selectNumber, numberState => numberState.selectedNumber);
export const selectNumberResponses              = createSelector(selectNumber, numberState => numberState.responses);
export const selectRangeCountByExhaustionStatus = createSelector(selectNumber, numberState => numberState.rangeExhaustionStatusCount);
