import { AccountInvitationRaw } from '../api/account-invitation-raw.model';

export class AccountInvitation {
  companyName: string;
  firstName: string;
  lastName: string;
  senderFirstName: string;
  senderLastName: string;
  email: string;

  static fromApiData(apiData: AccountInvitationRaw): AccountInvitation {
    const i: AccountInvitation = new AccountInvitation();

    i.companyName     = apiData.company_name;
    i.firstName       = apiData.first_name;
    i.lastName        = apiData.last_name;
    i.senderFirstName = apiData.sender_first_name;
    i.senderLastName  = apiData.sender_last_name;
    i.email           = apiData.email;

    return i;
  }
}
