import { ReportRaw } from '@models/api/report-raw.model';

export class Report {
  id: string;
  auditId: string;
  createdDate: string;
  name: string;
  description: string;
  author: string;
  uri: string;

  constructor(private data?: Report) {
    Object.assign(this, { ...(this.data || {}) });
  }

  fromApiData?(data: ReportRaw): Report {
    this.id          = data.id;
    this.name        = data.name;
    this.description = data.description;
    this.author      = data.author;
    this.createdDate = data.created_date;
    this.uri         = data.uri;
    this.auditId     = data.audit_id;
    return this;
  }
}
