import { TokenIntrospectMetadataRaw } from '@models/api/identity/token-introspect-metadata-raw.model';

export class TokenIntrospectMetadata {
  clientId: string;
  active: boolean;
  tokenType: string;
  scope: string;

  constructor(private data?: TokenIntrospectMetadata) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: TokenIntrospectMetadataRaw): TokenIntrospectMetadata {
    this.clientId  = data.client_id;
    this.active    = data.active;
    this.tokenType = data.token_type;
    this.scope     = data.scope;

    return this;
  }
}
