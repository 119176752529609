import { BaseRequest }       from '@redux/helpers/reducer.helper';
import { CallQueueGroupRaw } from '@models/api/microsoft-teams/call-queue-group-raw.model';

export class PatchCallQueueGroupRequest extends BaseRequest {
  id: string;
  serviceId: string;
  name: string;
  description: string;
  callQueueIds: string[];
  confirm?: boolean;

  constructor(private data?: PatchCallQueueGroupRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): CallQueueGroupRaw {
    return {
      name:           this.name,
      description:    this.description,
      call_queue_ids: this.callQueueIds,
    };
  }
}
