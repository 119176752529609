import { Injectable, Renderer2 } from '@angular/core';
import { filter, pluck, take }   from 'rxjs/operators';
import { StoreState }            from '@redux/store';
import { Action, Store }         from '@ngrx/store';
import { HttpClient }            from '@angular/common/http';
import { ApiService }            from '@services/api.service';
import { splitURLByPath }        from '@util/url.helper';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {

  constructor(private store: Store<StoreState>,
              private apiService: ApiService,
              private httpClient: HttpClient) { }

  async downloadS3(downloadAction: Action,
                   renderer2: Renderer2,
                   fileName: string,
                   reducer: keyof StoreState,
                   waitForVal: string): Promise<void> {
    this.store.dispatch(downloadAction);
    const uri = await this.waitForState<string>(reducer, waitForVal);
    return this.downloadS3Uri(uri, renderer2, fileName);
  }

  private waitForState<T>(reducer: keyof StoreState, waitForVal: string): Promise<T> {
    return this.store.select(reducer)
      .pipe(pluck(waitForVal), filter(url => !!url), take(1))
      .toPromise() as Promise<T>;
  }

  async downloadAPI(downloadAction: Action,
                    renderer2: Renderer2,
                    fileName: string,
                    reducer: keyof StoreState,
                    waitForVal: string): Promise<void> {
    this.store.dispatch(downloadAction);
    const response = await this.waitForState<string>(reducer, waitForVal);
    const a        = renderer2.createElement('a') as HTMLAnchorElement;
    a.href         = 'data:text/plain;charset=utf-8,' + encodeURIComponent(response);
    a.download     = fileName;
    // start download
    a.click();
    return;
  }

  async downloadS3Uri(uri: string, renderer2: Renderer2, fileName: string): Promise<void> {
    await this.httpClient.get(uri, { responseType: 'blob' })
      .pipe(take(1))
      .toPromise()
      .then((res: Blob) => {
        const a    = renderer2.createElement('a') as HTMLAnchorElement;
        a.href     = URL.createObjectURL(res);
        a.download = fileName.concat('-', splitURLByPath(uri)
          .path
          .split('/')
          .pop());
        // start download
        a.click();
        return;
      })
      .catch(() => null);
  }

  async downloadUri(uri: string, renderer2: Renderer2, fileName: string): Promise<void> {
    await this.apiService.apiGet$<Blob>(uri, { authRequired: true, responseType: 'blob' })
      .pipe(take(1))
      .toPromise()
      .then((res: Blob) => {
        const a    = renderer2.createElement('a') as HTMLAnchorElement;
        a.href     = URL.createObjectURL(res);
        a.download = fileName;
        // start download
        a.click();
        return;
      })
      .catch(() => null);
  }
}
