import { NgModule }                                                                 from '@angular/core';
import {
  PasswordStrengthComponent,
}                                                                                   from '@core/components/general/password-strength/password-strength.component';
import {
  ViewStepperComponent,
}                                                                                   from '@core/components/general/view-stepper/view-stepper.component';
import { MatButtonModule }                                                          from '@angular/material/button';
import { MatIconModule }                                                            from '@angular/material/icon';
import { MatDialogModule }                                                          from '@angular/material/dialog';
import { MatCheckboxModule }                                                        from '@angular/material/checkbox';
import { MatRippleModule }                                                          from '@angular/material/core';
import {
  MatProgressSpinnerModule,
}                                                                                   from '@angular/material/progress-spinner';
import {
  QuickDatePickerComponent,
}                                                                                   from './quick-date-picker.component';
import { MatDatepickerModule }                                                      from '@angular/material/datepicker';
import {
  CompanyFormComponent,
}                                                                                   from '@core/components/account/company-form/company-form.component';
import {
  AddressSummaryComponent,
}                                                                                   from '@core/components/account/address-summary/address-summary.component';
import {
  MatAutocompleteModule,
}                                                                                   from '@angular/material/autocomplete';
import { MatListModule }                                                            from '@angular/material/list';
import {
  TelValidatorComponent,
}                                                                                   from './tel-validator/tel-validator.component';
import { MatFormFieldModule }                                                       from '@angular/material/form-field';
import { MatInputModule }                                                           from '@angular/material/input';
import { ButtonModule }                                                             from '../button/button.module';
import {
  NotificationSharedModule,
}                                                                                   from '../notification-shared/notification-shared.module';
import { AsyncPipe, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { FormsModule, ReactiveFormsModule }                                         from '@angular/forms';
import { FlexLayoutModule }                                                         from '@angular/flex-layout';


@NgModule({
  declarations: [
    PasswordStrengthComponent,
    ViewStepperComponent,
    QuickDatePickerComponent,
    CompanyFormComponent,
    AddressSummaryComponent,
    TelValidatorComponent,
  ],
  imports:      [
    NgIf,
    NgStyle,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    NgFor,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    ButtonModule,
    NotificationSharedModule,
    AsyncPipe,
  ],
  exports:      [
    PasswordStrengthComponent,
    ViewStepperComponent,
    QuickDatePickerComponent,
    CompanyFormComponent,
    TelValidatorComponent,
  ],
})
export class CRFormsModule {}
