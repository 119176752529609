import { Injectable }                      from '@angular/core';
import { CanLoad, Route, Router, UrlTree } from '@angular/router';
import { AuthService }                     from '@services/auth.service';
import { StoreState }                      from '@redux/store';
import { Store }                           from '@ngrx/store';
import { splitURLByPath }                  from '@util/url.helper';
import { LogoutAction }                    from '@redux/auth/auth.actions';
import { Location }                        from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CompanyInviteGuard implements CanLoad {
  constructor(private authService: AuthService,
              private store: Store<StoreState>,
              private router: Router,
              private location: Location) {}

  async canLoad(route: Route): Promise<boolean | UrlTree> {
    const hasAuth     = await this.authService.hasAuth();
    const queryParams = splitURLByPath(this.location.path()).queryParams;
    const token       = queryParams?.token;
    const inviteEmail = queryParams?.email;
    const redirectUrl = `${ route.path }?email=${ encodeURIComponent(inviteEmail) }&token=${ token }`;
    if (hasAuth) {
      return true;
    }

    this.store.dispatch(LogoutAction({redirectUrl, skipNavigation: true}));
    return this.router.parseUrl('/login');
  }
}
