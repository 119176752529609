import { createAction, props }           from '@ngrx/store';
import {
  CANCEL_TASK_REQUEST,
  CANCEL_TASK_RESPONSE,
  FETCH_AUDIT_CONTEXT_LIST_REQUEST,
  FETCH_AUDIT_CONTEXT_LIST_RESPONSE,
  FETCH_AUDIT_LIST_REQUEST,
  FETCH_AUDIT_LIST_RESPONSE,
  FETCH_AUDIT_OBJECT_LIST_REQUEST,
  FETCH_AUDIT_OBJECT_LIST_RESPONSE,
  FETCH_TASK_COUNT_REQUEST,
  FETCH_TASK_COUNT_RESPONSE,
  FETCH_TASK_LIST_REQUEST,
  FETCH_TASK_LIST_RESPONSE,
  FETCH_TASK_REQUEST,
  FETCH_TASK_RESPONSE,
}                                        from '@redux/audit/audit.types';
import { FetchTaskListRequest }          from '@models/api/fetch-task-list-request.model';
import { FetchTaskListResponse }         from '@models/api/fetch-task-list-response.model';
import { FetchTaskRequest }              from '@models/api/audit/fetch-task-request.model';
import { FetchTaskResponse }             from '@models/api/audit/fetch-task-response.model';
import { FetchTaskCountRequest }         from '@models/api/audit/fetch-task-count-request.model';
import { FetchTaskCountResponse }        from '@models/api/audit/fetch-task-count-response.model';
import { CancelTaskRequest }             from '@models/api/audit/cancel-task-request.model';
import { CancelTaskResponse }            from '@models/api/audit/cancel-task-response.model';
import { FetchAuditListRequest }         from '@models/api/fetch-audit-list-request.model';
import { FetchAuditListResponse }        from '@models/api/fetch-audit-list-response.model';
import { FetchAuditContextListRequest }  from '@models/api/audit/fetch-audit-context-list-request.model';
import { FetchAuditContextListResponse } from '@models/api/audit/fetch-audit-context-list-response.model';
import { FetchAuditObjectListRequest }   from '@models/api/audit/fetch-audit-object-list-request.model';
import { FetchAuditObjectListResponse }  from '@models/api/audit/fetch-audit-object-list-response.model';

export const FetchAuditListRequestAction         = createAction(FETCH_AUDIT_LIST_REQUEST, props<FetchAuditListRequest>());
export const FetchAuditListResponseAction        = createAction(FETCH_AUDIT_LIST_RESPONSE, props<FetchAuditListResponse>());
export const FetchTaskListRequestAction          = createAction(FETCH_TASK_LIST_REQUEST, props<FetchTaskListRequest>());
export const FetchTaskListResponseAction         = createAction(FETCH_TASK_LIST_RESPONSE, props<FetchTaskListResponse>());
export const FetchAuditContextListRequestAction  = createAction(FETCH_AUDIT_CONTEXT_LIST_REQUEST, props<FetchAuditContextListRequest>());
export const FetchAuditContextListResponseAction = createAction(FETCH_AUDIT_CONTEXT_LIST_RESPONSE, props<FetchAuditContextListResponse>());
export const FetchAuditObjectListRequestAction   = createAction(FETCH_AUDIT_OBJECT_LIST_REQUEST, props<FetchAuditObjectListRequest>());
export const FetchAuditObjectListResponseAction  = createAction(FETCH_AUDIT_OBJECT_LIST_RESPONSE, props<FetchAuditObjectListResponse>());
export const FetchTaskRequestAction              = createAction(FETCH_TASK_REQUEST, props<FetchTaskRequest>());
export const FetchTaskResponseAction             = createAction(FETCH_TASK_RESPONSE, props<FetchTaskResponse>());
export const FetchTaskCountRequestAction         = createAction(FETCH_TASK_COUNT_REQUEST, props<FetchTaskCountRequest>());
export const FetchTaskCountResponseAction        = createAction(FETCH_TASK_COUNT_RESPONSE, props<FetchTaskCountResponse>());
export const CancelTaskRequestAction             = createAction(CANCEL_TASK_REQUEST, props<CancelTaskRequest>());
export const CancelTaskResponseAction            = createAction(CANCEL_TASK_RESPONSE, props<CancelTaskResponse>());
