import { Alert }                from '@models/entity/alert.model';
import * as ConfigurationAction from './configuration.actions';

import { StateTask }                                from '@models/entity/state-task.model';
import { BaseState, requestReduce, responseReduce } from '../helpers/reducer.helper';
import { Action, createReducer, on }                from '@ngrx/store';
import {
  ACCEPT_TERMS_CONDITIONS_REQUEST,
  FETCH_CALL_RECORDING_ALLOWED_REQUEST,
  FETCH_CALL_RECORDING_ANALYSIS_ENABLED_REQUEST,
  FETCH_CALL_RECORDING_ENABLED_REQUEST,
  FETCH_CARRIERS_CONFIG_REQUEST,
  FETCH_CURRENCY_CODE_REQUEST,
  FETCH_CUSTOMER_COSTS_TOGGLE_REQUEST,
  FETCH_DEMO_MODE_REQUEST,
  FETCH_GLOBAL_UI_BANNER_REQUEST,
  FETCH_GLOBAL_UI_BANNER_TOGGLE_REQUEST,
  FETCH_LOW_BALANCE_THRESHOLD_DEFAULT_REQUEST,
  FETCH_LOW_BALANCE_THRESHOLD_REQUEST,
  FETCH_NUMBER_REPORTS_TOGGLE_REQUEST,
  FETCH_NUMBER_TOMBSTONE_DURATION_REQUEST,
  FETCH_PENDING_TERMS_CONDITIONS_REQUEST,
  FETCH_RANGE_USAGE_THRESHOLD_REQUEST,
  FETCH_RANGE_USAGE_TOGGLE_REQUEST,
  FETCH_REPORTING_CONFIG_REQUEST,
  FETCH_SERVICES_CONFIG_REQUEST,
  FETCH_SSO_ALLOW_IDENTITY_CREATION_REQUEST,
  FETCH_SSO_ALLOW_PASSWORD_RESET_REQUEST,
  FETCH_TEAMS_SETTINGS_REQUEST,
  FETCH_TOPUP_ENABLED_REQUEST,
  FETCH_TRIAL_IDENTIFIER_REQUEST,
  FETCH_USER_SYNC_CRON_ENABLED_RESPONSE,
  FETCH_USER_SYNC_VERSION_REQUEST,
  SET_CARRIERS_CONFIG_REQUEST,
  SET_CUSTOMER_COSTS_TOGGLE_REQUEST,
  SET_DEMO_MODE_REQUEST,
  SET_GLOBAL_UI_BANNER_REQUEST,
  SET_GLOBAL_UI_BANNER_TOGGLE_REQUEST,
  SET_LOW_BALANCE_THRESHOLD_REQUEST,
  SET_MICROSOFT_TEAMS_SETTINGS_REQUEST,
  SET_NUMBER_TOMBSTONE_DURATION_REQUEST,
  SET_RANGE_USAGE_THRESHOLD_REQUEST,
  SET_RANGE_USAGE_TOGGLE_REQUEST,
  SET_REPORTING_CONFIG_REQUEST,
  SET_SERVICES_CONFIG_REQUEST,
  SET_SSO_ALLOW_IDENTITY_CREATION_REQUEST,
  SET_SSO_ALLOW_PASSWORD_RESET_REQUEST,
  TOGGLE_CALL_RECORDING_ANALYSIS_REQUEST,
  TOGGLE_CALL_RECORDING_REQUEST,
}                                                   from './configuration.types';
import * as AuthAction                              from '../auth/auth.actions';
import * as UIAction                                from '../ui/ui.actions';
import { createEntityAdapter, EntityAdapter }       from '@ngrx/entity';
import { MicrosoftTeamsSettings }                   from '@models/entity/microsoft-teams-settings.model';
import { ReportingConfig }                          from '@models/entity/reporting-config.model';
import { SyncMode }                                 from '@enums/sync-mode.enum';
import { UserSyncVersion }                          from '@enums/user-sync-version.enum';
import { NumberSettings }                           from '@models/entity/number-settings.model';

export interface ConfigurationState extends BaseState<never> {
  error: Alert;
  message: Alert;
  pendingTermsConditionsID: string;
  pending: boolean;
  pendingTasks: StateTask[];
  callRecordingEnabled: boolean;
  callRecordingAnalysisEnabled: boolean;
  callRecordingCanEdit: boolean;
  topupEnabled: boolean;
  currencyCode: string;
  trialLicenseIdentifier: string;
  userSyncVersion: UserSyncVersion;
  lowBalanceThreshold: number;
  lowBalanceThresholdDefault: number;
  demoMode: boolean;
  microsoftTeamsSettings: MicrosoftTeamsSettings;
  numberSettings: NumberSettings;
  reportingConfig: ReportingConfig;
  carriersConfig: { [identifier: string]: boolean };
  servicesConfig: { [name: string]: boolean };
  userSyncCronEnabled: boolean;
  allowSSOUserCreation: boolean;
  allowSSOPasswordReset: boolean;
  rangeUsageThreshold: number;
  customerCostsToggle: boolean;
  numberReportsToggle: boolean;
  rangeUsageToggle: boolean;
  globalUIBanner: string;
  globalUIBannerToggle: boolean;
}

export const adapter: EntityAdapter<never> = createEntityAdapter<never>();

const initialState: ConfigurationState = adapter.getInitialState({
  error:                        null,
  message:                      null,
  pendingTermsConditionsID:     null,
  pending:                      false,
  pendingTasks:                 [],
  callRecordingEnabled:         false,
  callRecordingAnalysisEnabled: false,
  callRecordingCanEdit:         false,
  topupEnabled:                 false,
  trialLicenseIdentifier:       null,
  currencyCode:                 'GBP',
  lowBalanceThreshold:          null,
  lowBalanceThresholdDefault:   null,
  integrations:                 null,
  userSyncCronEnabled:          false,
  microsoftTeamsSettings:       {
    sync:      {
      fullSync:           false,
      includeUnavailable: false,
      mode:               SyncMode.VoiceOnly,
      autoProvision:      true,
      inboundAssignment:  false,
    },
    configure: {
      magicExtension: false,
    },
  },
  numberSettings:               new NumberSettings({ TTL: null }),
  demoMode:                     false,
  userSyncVersion:              UserSyncVersion.v2,
  reportingConfig:              null,
  carriersConfig:               null,
  servicesConfig:               null,
  allowSSOUserCreation:         null,
  allowSSOPasswordReset:        null,
  rangeUsageThreshold:          null,
  customerCostsToggle:          null,
  numberReportsToggle:          null,
  rangeUsageToggle:             null,
  globalUIBanner:               null,
  globalUIBannerToggle:         null,
});

const _configurationReducer = createReducer(initialState,
  on(ConfigurationAction.FetchCurrencyCodeRequestAction, requestReduce),
  on(ConfigurationAction.FetchCurrencyCodeResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CURRENCY_CODE_REQUEST, res => ({
      error:        res.error,
      currencyCode: res.currencyCode || state.currencyCode,
    }))),
  on(ConfigurationAction.FetchCallRecordingEnabledRequestAction, requestReduce),
  on(ConfigurationAction.FetchCallRecordingEnabledResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_RECORDING_ENABLED_REQUEST, res => ({
      callRecordingEnabled: res.error ? state.callRecordingEnabled : res.enabled,
    }))),
  on(ConfigurationAction.FetchUserSyncVersionRequestAction, requestReduce),
  on(ConfigurationAction.FetchUserSyncVersionResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_USER_SYNC_VERSION_REQUEST, res => ({
      userSyncVersion: res.error ? state.userSyncVersion : res.value,
    }))),
  on(ConfigurationAction.FetchCallRecordingAnalysisEnabledRequestAction, requestReduce),
  on(ConfigurationAction.FetchCallRecordingAnalysisEnabledResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_RECORDING_ANALYSIS_ENABLED_REQUEST, res => ({
      callRecordingAnalysisEnabled: res.error ? state.callRecordingAnalysisEnabled : res.enabled,
    }))),
  on(ConfigurationAction.FetchTrialLicenseIdentifierRequestAction, requestReduce),
  on(ConfigurationAction.FetchTrialLicenseIdentifierResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TRIAL_IDENTIFIER_REQUEST, res => ({
      trialLicenseIdentifier: res.error ? state.trialLicenseIdentifier : res.identifier,
    }))),
  on(ConfigurationAction.FetchLowBalanceThresholdDefaultRequestAction, requestReduce),
  on(ConfigurationAction.FetchLowBalanceThresholdDefaultResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LOW_BALANCE_THRESHOLD_DEFAULT_REQUEST, res => ({
      lowBalanceThresholdDefault: res.error ? state.lowBalanceThresholdDefault : res.amount,
    }))),
  on(ConfigurationAction.FetchLowBalanceThresholdRequestAction, requestReduce),
  on(ConfigurationAction.FetchLowBalanceThresholdResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_LOW_BALANCE_THRESHOLD_REQUEST, res => ({
      lowBalanceThreshold: res.error ? state.lowBalanceThreshold : res.amount,
    }))),
  on(ConfigurationAction.SetLowBalanceThresholdRequestAction, requestReduce),
  on(ConfigurationAction.SetLowBalanceThresholdResponseAction, (state, action) =>
    responseReduce(state, action, SET_LOW_BALANCE_THRESHOLD_REQUEST, res => ({
      lowBalanceThreshold: res.error ? state.lowBalanceThreshold : res.amount,
    }))),
  on(ConfigurationAction.FetchUserSyncCronEnabledRequestAction, requestReduce),
  on(ConfigurationAction.FetchUserSyncCronEnabledResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_USER_SYNC_CRON_ENABLED_RESPONSE, res => ({
      userSyncCronEnabled: res.error ? state.userSyncCronEnabled : res.value,
    }))),
  on(ConfigurationAction.FetchDemoModeRequestAction, requestReduce),
  on(ConfigurationAction.FetchDemoModeResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_DEMO_MODE_REQUEST, res => ({
      demoMode: res.error ? state.demoMode : res.value,
    }))),
  on(ConfigurationAction.SetDemoModeRequestAction, requestReduce),
  on(ConfigurationAction.SetDemoModeResponseAction, (state, action) =>
    responseReduce(state, action, SET_DEMO_MODE_REQUEST, res => ({
      demoMode: res.error ? state.demoMode : res.value,
    }))),
  on(ConfigurationAction.FetchTopUpEnabledRequestAction, requestReduce),
  on(ConfigurationAction.FetchTopUpEnabledResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TOPUP_ENABLED_REQUEST, res => ({
      topupEnabled: res.error ? state.topupEnabled : res.enabled,
    }))),
  on(ConfigurationAction.FetchCallRecordingAllowedRequestAction, requestReduce),
  on(ConfigurationAction.FetchCallRecordingAllowedResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALL_RECORDING_ALLOWED_REQUEST, res => ({
      callRecordingCanEdit: res.allowed,
    }))),
  on(ConfigurationAction.ToggleCallRecordingRequestAction, requestReduce),
  on(ConfigurationAction.ToggleCallRecordingResponseAction, (state, action) =>
    responseReduce(state, action, TOGGLE_CALL_RECORDING_REQUEST, res => ({
      callRecordingEnabled: res.error ? state.callRecordingEnabled : res.enabled,
    }))),
  on(ConfigurationAction.ToggleCallRecordingAnalysisRequestAction, requestReduce),
  on(ConfigurationAction.ToggleCallRecordingAnalysisResponseAction, (state, action) =>
    responseReduce(state, action, TOGGLE_CALL_RECORDING_ANALYSIS_REQUEST, res => ({
      callRecordingAnalysisEnabled: res.error ? state.callRecordingAnalysisEnabled : res.enabled,
    }))),
  on(ConfigurationAction.FetchPendingTermsConditionsRequestAction, requestReduce),
  on(ConfigurationAction.FetchPendingTermsConditionsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_PENDING_TERMS_CONDITIONS_REQUEST, res => ({
      pendingTermsConditionsID: res.pendingVersion,
    }))),
  on(ConfigurationAction.AcceptTermsConditionsRequestAction, requestReduce),
  on(ConfigurationAction.AcceptTermsConditionsResponseAction, (state, action) =>
    responseReduce(state, action, ACCEPT_TERMS_CONDITIONS_REQUEST, () => ({
      pendingTermsConditionsID: null,
    }))),
  on(ConfigurationAction.FetchTeamsSettingsRequestAction, requestReduce),
  on(ConfigurationAction.FetchTeamsSettingsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_TEAMS_SETTINGS_REQUEST,
      res => ({
        microsoftTeamsSettings: res.error ?
                                  state.microsoftTeamsSettings :
                                  res.settings,
      }))),
  on(ConfigurationAction.SetMicrosoftTeamsSettingsRequestAction, requestReduce),
  on(ConfigurationAction.SetMicrosoftTeamsSettingsResponseAction, (state, action) =>
    responseReduce(state, action, SET_MICROSOFT_TEAMS_SETTINGS_REQUEST,
      res => ({
        microsoftTeamsSettings: res.error ?
                                  state.microsoftTeamsSettings :
                                  res.settings,
      }))),
  on(ConfigurationAction.FetchCarriersConfigRequestAction, requestReduce),
  on(ConfigurationAction.FetchCarriersConfigResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CARRIERS_CONFIG_REQUEST,
      res => ({
        carriersConfig: res.error ?
                          state.carriersConfig :
                          res.config,
      }))),
  on(ConfigurationAction.SetCarriersConfigRequestAction, requestReduce),
  on(ConfigurationAction.SetCarriersConfigResponseAction, (state, action) =>
    responseReduce(state, action, SET_CARRIERS_CONFIG_REQUEST,
      res => ({
        carriersConfig: res.error ?
                          state.carriersConfig :
                          res.config,
      }))),
  on(ConfigurationAction.FetchServicesConfigRequestAction, requestReduce),
  on(ConfigurationAction.FetchServicesConfigResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SERVICES_CONFIG_REQUEST,
      res => ({
        servicesConfig: res.error ?
                          state.servicesConfig :
                          res.config,
      }))),
  on(ConfigurationAction.SetServicesConfigRequestAction, requestReduce),
  on(ConfigurationAction.SetServicesConfigResponseAction, (state, action) =>
    responseReduce(state, action, SET_SERVICES_CONFIG_REQUEST,
      res => ({
        servicesConfig: res.error ?
                          state.servicesConfig :
                          res.config,
      }))),
  on(ConfigurationAction.FetchNumberTombstoneDurationRequestAction, requestReduce),
  on(ConfigurationAction.FetchNumberTombstoneDurationResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NUMBER_TOMBSTONE_DURATION_REQUEST,
      res => ({
        numberSettings: res.error ?
                          state.numberSettings :
                          res.data,
      }))),
  on(ConfigurationAction.SetNumberTombstoneDurationRequestAction, requestReduce),
  on(ConfigurationAction.SetNumberTombstoneDurationResponseAction, (state, action) =>
    responseReduce(state, action, SET_NUMBER_TOMBSTONE_DURATION_REQUEST,
      res => ({
        numberSettings: res.error ?
                          state.numberSettings :
                          res.data,
      }))),
  on(ConfigurationAction.FetchSSOAllowIdentityCreationRequestAction, requestReduce),
  on(ConfigurationAction.FetchSSOAllowIdentityCreationResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SSO_ALLOW_IDENTITY_CREATION_REQUEST,
      res => ({
        allowSSOUserCreation: res.error ?
                                state.allowSSOUserCreation :
                                res.value,
      }))),
  on(ConfigurationAction.SetSSOAllowIdentityCreationRequestAction, requestReduce),
  on(ConfigurationAction.SetSSOAllowIdentityCreationResponseAction, (state, action) =>
    responseReduce(state, action, SET_SSO_ALLOW_IDENTITY_CREATION_REQUEST,
      res => ({
        allowSSOUserCreation: res.error ?
                                state.allowSSOUserCreation :
                                res.value,
      }))),
  on(ConfigurationAction.FetchSSOAllowPasswordResetRequestAction, requestReduce),
  on(ConfigurationAction.FetchSSOAllowPasswordResetResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SSO_ALLOW_PASSWORD_RESET_REQUEST,
      res => ({
        allowSSOPasswordReset: res.error ?
                                 state.allowSSOPasswordReset :
                                 res.value,
      }))),
  on(ConfigurationAction.SetSSOAllowPasswordResetRequestAction, requestReduce),
  on(ConfigurationAction.SetSSOAllowPasswordResetResponseAction, (state, action) =>
    responseReduce(state, action, SET_SSO_ALLOW_PASSWORD_RESET_REQUEST,
      res => ({
        allowSSOPasswordReset: res.error ?
                                 state.allowSSOPasswordReset :
                                 res.value,
      }))),
  on(ConfigurationAction.FetchReportingConfigRequestAction, requestReduce),
  on(ConfigurationAction.FetchReportingConfigResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_REPORTING_CONFIG_REQUEST,
      res => ({
        reportingConfig: res.error ?
                           state.reportingConfig :
                           res.config,
      }))),
  on(ConfigurationAction.SetReportingConfigRequestAction, requestReduce),
  on(ConfigurationAction.SetReportingConfigResponseAction, (state, action) =>
    responseReduce(state, action, SET_REPORTING_CONFIG_REQUEST,
      res => ({
        reportingConfig: res.error ?
                           state.reportingConfig :
                           res.config,
      }))),
  on(ConfigurationAction.FetchRangeUsageThresholdRequestAction, requestReduce),
  on(ConfigurationAction.FetchRangeUsageThresholdResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_RANGE_USAGE_THRESHOLD_REQUEST, res => ({
      rangeUsageThreshold: res.error ? state.rangeUsageThreshold : res.value,
    }))),
  on(ConfigurationAction.SetRangeUsageThresholdRequestAction, requestReduce),
  on(ConfigurationAction.SetRangeUsageThresholdResponseAction, (state, action) =>
    responseReduce(state, action, SET_RANGE_USAGE_THRESHOLD_REQUEST, res => ({
      rangeUsageThreshold: res.error ? state.rangeUsageThreshold : res.value,
    }))),
  on(ConfigurationAction.FetchRangeUsageToggleRequestAction, requestReduce),
  on(ConfigurationAction.FetchRangeUsageToggleResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_RANGE_USAGE_TOGGLE_REQUEST, res => ({
      rangeUsageToggle: res.error ? state.rangeUsageToggle : res.value,
    }))),
  on(ConfigurationAction.SetRangeUsageToggleRequestAction, requestReduce),
  on(ConfigurationAction.SetRangeUsageToggleResponseAction, (state, action) =>
    responseReduce(state, action, SET_RANGE_USAGE_TOGGLE_REQUEST, res => ({
      rangeUsageToggle: res.error ? state.rangeUsageToggle : res.value,
    }))),
  on(ConfigurationAction.FetchGlobalUIBannerRequestAction, requestReduce),
  on(ConfigurationAction.FetchGlobalUIBannerResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_GLOBAL_UI_BANNER_REQUEST, res => ({
      globalUIBanner: res.error ? state.globalUIBanner : res.value,
    }))),
  on(ConfigurationAction.SetGlobalUIBannerRequestAction, requestReduce),
  on(ConfigurationAction.SetGlobalUIBannerResponseAction, (state, action) =>
    responseReduce(state, action, SET_GLOBAL_UI_BANNER_REQUEST, res => ({
      globalUIBanner: res.error ? state.globalUIBanner : res.value,
    }))),
  on(ConfigurationAction.FetchGlobalUIBannerToggleRequestAction, requestReduce),
  on(ConfigurationAction.FetchGlobalUIBannerToggleResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_GLOBAL_UI_BANNER_TOGGLE_REQUEST, res => ({
      globalUIBannerToggle: res.error ? state.globalUIBannerToggle : res.value,
    }))),
  on(ConfigurationAction.SetGlobalUIBannerToggleRequestAction, requestReduce),
  on(ConfigurationAction.SetGlobalUIBannerToggleResponseAction, (state, action) =>
    responseReduce(state, action, SET_GLOBAL_UI_BANNER_TOGGLE_REQUEST, res => ({
      globalUIBannerToggle: res.error ? state.globalUIBannerToggle : res.value,
    }))),
  on(ConfigurationAction.FetchCustomerCostsToggleRequestAction, requestReduce),
  on(ConfigurationAction.FetchCustomerCostsToggleResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CUSTOMER_COSTS_TOGGLE_REQUEST, res => ({
      customerCostsToggle: res.error ? state.customerCostsToggle : res.value,
    }))),
  on(ConfigurationAction.SetCustomerCostsToggleRequestAction, requestReduce),
  on(ConfigurationAction.SetCustomerCostsToggleResponseAction, (state, action) =>
    responseReduce(state, action, SET_CUSTOMER_COSTS_TOGGLE_REQUEST, res => ({
      customerCostsToggle: res.error ? state.customerCostsToggle : res.value,
    }))),
  on(ConfigurationAction.FetchNumberReportsToggleRequestAction, requestReduce),
  on(ConfigurationAction.FetchNumberReportsToggleResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NUMBER_REPORTS_TOGGLE_REQUEST, res => ({
      numberReportsToggle: res.error ? state.numberReportsToggle : res.value,
    }))),
  on(UIAction.DismissErrorAction, (state) =>
    ({ ...state, error: null })),
  on(UIAction.DismissMessageAction, (state) =>
    ({ ...state, message: null })),
  on(AuthAction.LogoutAction, () =>
    ({ ...initialState })),
);


export function configurationReducer(state: ConfigurationState, action: Action): ConfigurationState {
  return _configurationReducer(state, action);
}
