import { Injectable }                                       from '@angular/core';
import { environment }                                      from '../../environments/environment';
import { ApiService }                                       from '@services/api.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ServiceType }                                      from '@enums/service-type.enum';
import { PostServiceRequest }                               from '@models/api/post-service-request.model';
import { Observable, of }                                   from 'rxjs';
import { PostServiceResponse }                              from '@models/api/post-service-response.model';
import { ServiceItemRaw }                                   from '@models/api/service-api-response.model';
import { catchError, map }                                  from 'rxjs/operators';
import { Alert }                                            from '@models/entity/alert.model';
import { HttpErrorResponse }                                from '@angular/common/http';
import { PatchServiceRequest }                              from '@models/api/patch-service-request.model';
import { PatchServiceResponse }                             from '@models/api/patch-service-response.model';
import { CallForwarding }                                   from '@models/entity/call-forwarding.model';
import { CallForwardingRaw }                                from '@models/api/call-forwarding-raw.model';

@Injectable({
  providedIn: 'root',
})
export class CallForwardingService {

  private baseUrl: string = environment.api.serviceBaseUrl;

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  constructor(private apiService: ApiService) { }

  getNewForm(data: CallForwarding): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        serviceType: new UntypedFormControl(ServiceType.CallForwarding),
        label:       new UntypedFormControl(data?.label || '', [Validators.maxLength(30)]),
      },
    );
  }

  postCallForwarding$(req: PostServiceRequest<CallForwarding>): Observable<PostServiceResponse<CallForwarding>> {
    return this.apiService.apiPost$<{ data: ServiceItemRaw }>(
      this.buildUri(`services`),
      new CallForwarding(req.serviceItem).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: ServiceItemRaw }): PostServiceResponse<CallForwarding> => {
          return {
            error:       null,
            serviceItem: new CallForwarding().fromApiData(res.data as CallForwardingRaw),
            message:     new Alert().fromApiMessage({
              message:   'Call forwarding service created successfully.',
              color:     'green',
              url:       null,
              isSuccess: true,
            }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostServiceResponse<CallForwarding>> => {
          return of({
            error:       new Alert().fromApiError(err, req.formFields),
            serviceItem: null,
            message:     null,
          });
        }),
      );
  }

  patchCallForwarding$(req: PatchServiceRequest<CallForwarding>): Observable<PatchServiceResponse<CallForwarding>> {

    return this.apiService.apiPatch$<{ data: ServiceItemRaw }>(
      this.buildUri(`services/${ req.serviceItem.id }`),
      new CallForwarding(req.serviceItem).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: ServiceItemRaw }): PatchServiceResponse<CallForwarding> => {
          return {
            error:       null,
            serviceItem: new CallForwarding().fromApiData(res.data as CallForwardingRaw),
            message:     new Alert().fromApiMessage({
              message:   'Call forwarding service updated successfully.',
              color:     'green',
              url:       null,
              isSuccess: true,
            }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchServiceResponse<CallForwarding>> => {
          return of({
            error:       new Alert().fromApiError(err, req.formFields),
            serviceItem: null,
            message:     null,
          });
        }),
      );
  }
}
