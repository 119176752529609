import { Observable, ObservedValueOf }             from 'rxjs';
import { filter, map, skipWhile, switchMap, take } from 'rxjs/operators';
import { AuthScope }                               from '@enums/auth-scope.enum';
import { BaseRequest }                             from '@redux/helpers/reducer.helper';
import { RestrictedInclude }                       from '@models/entity/restricted-params.model';

/**
 * Uses rxjs/withLatestFrom, filter, map
 * Injects current user scopes, and optional restrictedIncludes into request
 * Checks user has all required scopes (will not emit if not)
 *
 * @returns (source: Observable<T>) => Observable<ObservedValueOf<Observable<T>>>
 *
 * @example
 * ```ts
 *  fetchCompanyUserList$ = createEffect(() => this.actions$.pipe(
 ofType(FetchCompanyUserListRequestAction),
 withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyRead], {role: [AuthScope.UserRoleRead]}));
 * ```
 * @param scopes$
 * @param required
 * @param restrictedInclude
 */

export function withScopes<T extends BaseRequest>(
  scopes$: Observable<AuthScope[]>,
  required: AuthScope[],
  restrictedInclude?: RestrictedInclude): (source: Observable<T>) => Observable<ObservedValueOf<Observable<T>>> {

  return (source: Observable<T>) => source.pipe(
    switchMap((request: T) => scopes$.pipe(
      skipWhile(scopes => required?.length && !scopes?.length),
      take(1),
      map((scopes: AuthScope[]) => [request, scopes] as [T, AuthScope[]])
    )),
    filter(([_, scopes]: [T, AuthScope[]]) => {
      if (!required?.length) {
        return true;
      }
      return required.every(r => scopes?.includes(r));
    }),
    map(([request, scopes]: [T, AuthScope[]]) => {
      return {...(request as T), scopes, restrictedInclude} as T;
    }));

}
