import { Injectable }                                                         from '@angular/core';
import { AbstractControl }                                                    from '@angular/forms';
import { BehaviorSubject, Observable }                                        from 'rxjs';
import { debounceTime, takeUntil, withLatestFrom }                            from 'rxjs/operators';
import { StoreState }                                                         from '@redux/store';
import { Store }                                                              from '@ngrx/store';
import {
  ServiceQueryParams,
}                                                                             from '@models/form/service-query-params.model';
import { FetchAvailableCarriersRequestAction, FetchCarrierListRequestAction } from '@redux/service/service.actions';
import { selectAvailableCarriersQueryParams, selectCarriersQueryParams }      from '@redux/service/service.selectors';
import { ServiceType }                                                        from '@enums/service-type.enum';
import { PaginationFormModel }                                                from '@models/form/pagination-form.model';
import {
  CarrierQueryParams,
}                                                                             from '@models/form/carrier-query-params.model';
import { FilterOption }                                                       from '@models/form/filter-option.model';

@Injectable({
  providedIn: 'root',
})
export class CarrierQueryService {

  static carrierStatusFilterOptions: FilterOption[] = [
    { label: 'Coming soon', key: 'comingSoon', value: true },
    { label: 'Available', key: 'available', value: true },
  ];

  hasFiltered  = new BehaviorSubject<boolean>(false);
  hasFiltered$ = this.hasFiltered.asObservable();

  constructor(private store: Store<StoreState>) { }

  setupAvailableCarrierSearchListener(searchCtrl: AbstractControl, destroy$: Observable<void>): void {
    searchCtrl
      .valueChanges
      .pipe(
        takeUntil(destroy$),
        debounceTime(500),
        withLatestFrom(this.store.select(selectAvailableCarriersQueryParams)),
      )
      .subscribe(([search, currentSearchParams]) => {
        const queryParams = (currentSearchParams ? this.getCarrierQueryParams(
          search,
          currentSearchParams.pageSize,
          1,
          currentSearchParams.sort,
        ) : null);
        this.fetchAvailableList(true, queryParams);
      });
  }

  setupCarrierSearchListener(searchCtrl: AbstractControl, destroy$: Observable<void>): void {
    searchCtrl
      .valueChanges
      .pipe(
        takeUntil(destroy$),
        debounceTime(500),
        withLatestFrom(this.store.select(selectCarriersQueryParams)),
      )
      .subscribe(([search, currentSearchParams]) => {
        const queryParams = (currentSearchParams ? this.getCarrierServiceQueryParams(
          search,
          currentSearchParams.pageSize,
          1,
          currentSearchParams.sort,
        ) : null);
        this.hasFiltered.next(!!queryParams.search);
        this.fetchList(true, queryParams);
      });
  }

  getCarrierServiceQueryParams(search: string, pageSize: number, pageNumber: number, sort: string): ServiceQueryParams {
    const s: ServiceQueryParams = new ServiceQueryParams();

    s.pageSize   = pageSize;
    s.pageNumber = pageNumber;
    s.sort       = sort;
    s.type       = ServiceType.Carrier;
    s.search     = search;

    return s;
  }

  getCarrierQueryParams(search: string, pageSize: number, pageNumber: number, sort: string): CarrierQueryParams {
    const s: CarrierQueryParams = new CarrierQueryParams();

    s.pageSize   = pageSize;
    s.pageNumber = pageNumber;
    s.sort       = sort;
    s.search     = search;

    return s;
  }

  updatePageQuery(params: PaginationFormModel, curParams: ServiceQueryParams): void {
    setTimeout(() => {
      window.scroll({
        top:      0,
        left:     0,
        behavior: 'smooth',
      });
    }, 100);

    const queryParams = (curParams ? this.getCarrierServiceQueryParams(
      curParams.search,
      params.pageSize,
      params.pageNumber,
      curParams ? curParams.sort : null,
    ) : null);

    this.fetchList(true, queryParams);
  }

  fetchList(emitEvent = true, queryParams = new ServiceQueryParams({ pageSize: 100, pageNumber: 1 })): void {
    this.store.dispatch(FetchCarrierListRequestAction({ queryParams, emitEvent }));
  }

  fetchAvailableList(emitEvent = true, queryParams = new CarrierQueryParams({ pageSize: 100, pageNumber: 1 })): void {
    this.store.dispatch(FetchAvailableCarriersRequestAction({ queryParams, emitEvent }));
  }
}
