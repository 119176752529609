import { ReservationType }      from '@enums/reservation-type.enum';
import { NumberReservationRaw } from '@models/api/number/number-reservation-raw.model';

export class NumberReservation {
  id: string;
  description: string;
  type: ReservationType;
  createdDate: string;
  expiryDate: string;
  updatedDate: string;

  constructor(private data?: NumberReservation) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(data: NumberReservationRaw): NumberReservation {
    this.id          = data.id;
    this.description = data.description;
    this.type        = data.type;
    this.createdDate = data.created_date;
    this.expiryDate  = data.expiry_date;
    this.updatedDate = data.updated_date;

    return this;
  }
}
