import { Injectable }                  from '@angular/core';
import { ListFactoryService }          from '@services/abstract/list-factory.service';
import { MicrosoftTeamsUser }          from '@models/entity/microsoft-teams-user.model';
import { ListItem }                    from '@models/ui/list-item.model';
import { ListItemButton }              from '@models/ui/flex-item-button.model';
import { MicrosoftTeamsActionService } from '@services/microsoft-teams-action.service';
import { TokenStatus }                 from '@enums/token-status.enum';
import { Token }                       from '@models/entity/token-state.model';
import { StatusItem }                  from '@models/entity/status-item.model';
import { MicrosoftTeams }              from '@models/entity/microsoft-teams.model';
import { NumberTag }                   from '@models/entity/number-tag.model';
import { MicrosoftTeamsSettings }      from '@models/entity/microsoft-teams-settings.model';
import { CallingProfileActionService } from '@services/calling-profile-action.service';
import { UserSyncVersion }             from '@enums/user-sync-version.enum';

@Injectable({
  providedIn: 'root',
})
export class MicrosoftTeamsUserFactoryService implements ListFactoryService<MicrosoftTeamsUser> {

  static authTooltip(tokens: Token[]): string {
    if (!tokens?.length) {
      return null;
    }
    if (tokens.every(token => token?.status !== TokenStatus.Active)) {
      return 'Requires re-authorisation (1/2)';
    } else if (tokens.some(token => token?.status !== TokenStatus.Active) &&
      tokens.some(token => token?.lastAuth && token?.status === TokenStatus.Active)) {
      return 'Requires re-authorisation (2/2)';
    } else {
      return tokens.some(token => token?.status !== TokenStatus.Active) ? 'Requires re-authorisation' : null;
    }
  }

  static getProcessingModeText(isSyncing: boolean): string {
    if (isSyncing) {
      return 'Syncing';
    }
    return 'Configuring';
  }

  constructor(private microsoftTeamsActionService: MicrosoftTeamsActionService,
              private callingProfileActionService: CallingProfileActionService) {}

  newListItem(item: MicrosoftTeamsUser,
              serviceItem: MicrosoftTeams,
              settings: MicrosoftTeamsSettings,
              tokens: Token[],
              tokensPending: boolean,
              hasServiceWrite: boolean,
              version: UserSyncVersion,
              hasAutoProvisioning: boolean,
              findNumberTagFn?: (tag: string, allTags: NumberTag[]) => NumberTag,
              allTags?: NumberTag[]): ListItem<MicrosoftTeamsUser> {
    const buttons: ListItemButton[] = [];
    const isHealthy                 = StatusItem.isSuccess(serviceItem.healthStatus.status);

    buttons.push(
      {
        isIconBtn:   true,
        icon:        'cr-settings',
        loadingIcon: true,
        loading:     item.isProcessing,
        dataCy:      'config-btn',
        action:      () => this.microsoftTeamsActionService.goToServiceUserProfile(serviceItem, item),
      },
      {
        text:        'Sync',
        disabled:    item.isSyncDisabled(tokensPending, isHealthy, hasServiceWrite),
        tooltip:     item.getSyncTooltip(isHealthy, tokens, hasServiceWrite),
        loading:     item.isSyncing,
        loadingIcon: true,
        icon:        'cr-sync',
        isIconBtn:   true,
        type:        null,
        dataCy:      'sync-btn',
        ngClass:     'mr-24',
        action:      () => this.microsoftTeamsActionService.syncUser(serviceItem.id, item.upn, settings, version),
      });

    let statusColor = item.isProcessing ? 'yellow' : item.statusColor;
    if (item.isFailed) {
      statusColor = 'red';
    }

    return {
      id:             item.id,
      statusColor,
      additionalData: item,
      icon:           {
        name:     item.isResourceAccount ? 'cr-agent' : 'cr-person',
        tooltip:  item.isResourceAccount ? 'Resource account' : 'User account',
        asFilter: true,
      },
      minHeight:      '80px',
      expandable:     true,
      buttons,
      openSm:         () => isHealthy && this.microsoftTeamsActionService.goToServiceUserProfile(serviceItem, item),
      columns:        [
        {
          title:   item.displayName || ((item.firstName || '').concat(' ', item.lastName || '')),
          tooltip: item.upn,
          chip:    item.profile ? {
            icon:    'cr-id-card',
            text:    item.profile?.name,
            ngClass: 'background-primary color-white',
            action:  () => this.callingProfileActionService.openConfigureCallingProfile(item.profile, serviceItem.id),
          } : null,
          width:   '18%',
          widthSm: '33%',
        },
        {
          width:   '18%',
          widthSm: '27%',
          dataCy:  'cli',
          hideXs:  false,
          hideSm:  false,
          hideMd:  false,
        },
        {
          width:   '15%',
          widthSm: '15%',
          dataCy:  'capabilities',
          hideXs:  true,
          hideSm:  true,
          hideMd:  false,
        },
        {
          width:   '15%',
          widthMd: '15%',
          widthSm: '25%',
          hideXs:  true,
          hideSm:  true,
          hideMd:  true,
          status:  item.getStatus(),
        },
        {
          width:     '15%',
          widthMd:   '15%',
          widthSm:   '25%',
          icon:      item.isManaged ? 'cr-tick-circle' : 'cr-dash',
          iconClass: item.isManaged ? 'color-green ml-48' : 'color-near-black ml-48',
          hideXs:    true,
          hideSm:    true,
          hideMd:    true,
        },
      ],
      subItems:       [
        {
          header:   'Edit',
          width:    '25%',
          subItems: [
                      {
                        title:  'Configure',
                        icon:   'cr-settings',
                        action: () => this.microsoftTeamsActionService.goToServiceUserProfile(serviceItem, item),
                      },
                      {
                        title:    'Assign license group(s)',
                        icon:     'cr-license',
                        disabled: !hasServiceWrite,
                        tooltip:  !hasServiceWrite ? 'You do not have the required access to assign license groups. Please contact your systems administrator for more information.' : 'Assign license group(s)',
                        action:   () => this.microsoftTeamsActionService.openLicenseAssignment(item, serviceItem.id),
                      },
                      hasAutoProvisioning && item.hasVoiceLicense() && {
                        title:    'Assign team group(s)',
                        icon:     'cr-team',
                        disabled: !hasServiceWrite,
                        tooltip:  !hasServiceWrite ? 'You do not have the required access to assign team groups. Please contact your systems administrator for more information' : 'Assign team group(s)',
                        action:   () => this.microsoftTeamsActionService.openTeamAssignment(item, serviceItem.id),
                      },
                      hasAutoProvisioning && item.hasVoiceLicense() && {
                        title:    'Assign call queue group(s)',
                        icon:     'cr-arrow-progress',
                        disabled: !hasServiceWrite,
                        tooltip:  !hasServiceWrite ? 'You do not have the required access to assign call queue groups. Please contact your systems administrator for more information' : 'Assign team group(s)',
                        action:   () => this.microsoftTeamsActionService.openCallQueueAssignment(item, serviceItem.id),
                      },
                    ].filter(i => !!i),
        },
        {
          header: 'Number tags',
          width:  '25%',
          list:   item.numberTags?.map(t => {
            const tag = findNumberTagFn(t, allTags);
            return {
              text:        tag?.name,
              icon:        'cr-label',
              ngClass:     'background-white color-near-black',
              iconColor:   tag?.colour,
              borderColor: tag?.colour,
            };
          }),
        },
        {
          header:   'User persona',
          width:    '25%',
          subItems: [
            {
              icon:   item.profile && 'cr-id-card',
              title:  item.profile?.name || '',
              action: () => this.callingProfileActionService.openConfigureCallingProfile(item.profile, serviceItem.id),
            },
          ],
        },
        {
          header:   'Additional details',
          width:    '25%',
          subItems: [
            { title: `First name: ${ item.firstName || '-' }` },
            { title: `Last name: ${ item.lastName || '-' }` },
            { title: `Email: ${ item.upn }` },
            { title: `LineURI: ${ item.getLineURI() }` },
            { title: `Number provider: ${ item.numberProvider?.name || '-' }` },
          ],
        },
      ],
    };
  }

}
