import { BaseRequest }                        from '@redux/helpers/reducer.helper';
import { FetchNumberCompatibilityRequestRaw } from '@models/api/number/fetch-number-compatibility-request-raw.model';
import { ServiceUserCapability }              from '@enums/service-user-capability.enum';

export class FetchNumberCompatibilityRequest extends BaseRequest {
  rangeIds: string[];
  locationIds: string[];
  tagIds: string[];
  serviceUserCapabilities: ServiceUserCapability[];
  isResourceAccount: boolean;

  constructor(private data?: FetchNumberCompatibilityRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): FetchNumberCompatibilityRequestRaw {
    const serviceUserCapabilities      = this.serviceUserCapabilities?.filter(capability => capability !== ServiceUserCapability.Teams) || [];
    const serviceUserVoiceCapabilities = [
      ServiceUserCapability.DirectRouting,
      ServiceUserCapability.MicrosoftCallingPlan,
      ServiceUserCapability.MicrosoftOperatorConnect];

    return {
      range_ids:                 this.rangeIds.length ? this.rangeIds : undefined,
      location_ids:              this.locationIds.length ? this.locationIds : undefined,
      service_user_capabilities: serviceUserCapabilities.length ?
                                   serviceUserCapabilities :
                                   serviceUserVoiceCapabilities,
      tag_ids:                   this.tagIds.length ? this.tagIds : undefined,
      is_resource_account:       this.isResourceAccount,
    };
  }
}
