import { StoreState }     from '../store';
import { ReportState }    from './report.reducer';
import { createSelector } from '@ngrx/store';

export const selectReport                               = (state: StoreState): ReportState => state.reportReducer;
export const selectCallLogs                             = createSelector(selectReport, report => report.callLogArray);
export const selectChannelUsageData                     = createSelector(selectReport, report => report.channelUsageData);
export const selectCountryData                          = createSelector(selectReport, report => report.countryData);
export const selectDirectionData                        = createSelector(selectReport, report => report.directionData);
export const selectSummaryData                          = createSelector(selectReport, report => report.summaryData);
export const selectFrequentCallsData                    = createSelector(selectReport, report => report.frequentCallsData);
export const selectDestinationSummaryData               = createSelector(selectReport, report => report.destinationSummaryData);
export const selectReportPendingTasks                   = createSelector(selectReport, report => report.pendingTasks);
export const selectReportPending                        = createSelector(selectReport, report => report.pending);
export const selectCallLogSummary                       = createSelector(selectReport, report => report.callLogSummaryArray);
export const selectCallLogQueryParams                   = createSelector(selectReport, report => report.callLogQueryParams);
export const selectCallMinutesData                      = createSelector(selectReport, report => report.callMinutesData);
export const selectCallMinutesRawData                   = createSelector(selectReport, report => report.callMinutesDataRaw);
export const selectCallCostData                         = createSelector(selectReport, report => report.callCostData);
export const selectCallCostRawData                      = createSelector(selectReport, report => report.callCostDataRaw);
export const selectCallCostSummaryByBandData            = createSelector(selectReport, report => report.callCostSummaryByBandData);
export const selectCallsBySIPCode                       = createSelector(selectReport, report => {
  const summary = report.callsBySIPCodeData;
  if (!summary) {
    return null;
  }
  return Object.keys(summary)
    .map(key => {
      return {
        name:  summary[key].code ? summary[key].description.concat(' (', summary[key].code.toString(), ')') : summary[key].description || 'Unclassified',
        value: summary[key].count,
      };
    });
});
export const selectCallsBySIPType                       = createSelector(selectReport, report => {
  const summary = report.callsBySIPTypeData;
  if (!summary) {
    return null;
  }
  return Object.keys(summary)
    .map(key => {
      return {
        name:  summary[key].codes?.length ? summary[key].description.concat(' (', summary[key].codes.join(', '), ')') : summary[key].description,
        value: summary[key].count,
      };
    });
});
export const selectCountByCapability                    = createSelector(selectReport, report => report.countByCapability);
export const selectReportList                           = createSelector(selectReport, report => report.reports);
export const selectReportListQueryParams                = createSelector(selectReport, report => report.reportListQueryParams);
export const selectTotalNumbersByCountry                = createSelector(selectReport, report => report.totalNumbersPerCountry);
export const selectTotalNumbersByType                   = createSelector(selectReport, report => report.totalNumbersPerType);
export const selectAssignedNumbersByRange               = createSelector(selectReport, report => report.assignedNumbersByRange);
export const selectAssignedNumbersByCountry             = createSelector(selectReport, report => report.assignedNumbersByCountry);
export const selectAssignedNumbersByTag                 = createSelector(selectReport, report => report.assignedNumbersByTag);
export const selectAssignedNumbersByLocation            = createSelector(selectReport, report => report.assignedNumbersByLocation);
export const selectAssignedNumbersTotal                 = createSelector(selectReport, report => report.assignedNumbersTotal);
export const selectRangeUsageTop                        = createSelector(selectReport, report => report.rangeUsageTop);
export const selectTotalNumbersByCountryQueryParams     = createSelector(selectReport, report => report.totalNumbersPerCountryQueryParams);
export const selectTotalNumbersByTypeQueryParams        = createSelector(selectReport, report => report.totalNumbersPerTypeQueryParams);
export const selectAssignedNumbersByRangeQueryParams    = createSelector(selectReport, report => report.assignedNumbersByRangeQueryParams);
export const selectAssignedNumbersByLocationQueryParams = createSelector(selectReport, report => report.assignedNumbersByLocationQueryParams);
export const selectAssignedNumbersByTagQueryParams      = createSelector(selectReport, report => report.assignedNumbersByTagQueryParams);
export const selectRangeExhaustion                      = createSelector(selectReport, report => report.rangeExhaustionData);
export const selectSipResponseTypes                     = createSelector(selectReport, report => report.sipResponseTypes);
