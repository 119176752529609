import { Injectable }                    from '@angular/core';
import { Observable, of }                from 'rxjs';
import { RequirementsState }             from '@models/api/service.model';
import { environment }                   from '../../environments/environment';
import { catchError, map }               from 'rxjs/operators';
import { snakeToCamelCase }              from '@redux/helpers/reducer.helper';
import { HttpErrorResponse }             from '@angular/common/http';
import { Alert }                         from '@models/entity/alert.model';
import { ApiService }                    from './api.service';
import { ServiceHealthCheckName }        from '@enums/health-check.enum';
import { ServiceRequirementCheckName }   from '@enums/service-requirement.enum';
import { TokenStatus }                   from '@enums/token-status.enum';
import { MicrosoftTeams }                from '@models/entity/microsoft-teams.model';
import { ServiceItemRaw }                from '@models/api/service-api-response.model';
import { PatchServiceRequest }           from '@models/api/patch-service-request.model';
import { PostServiceResponse }           from '@models/api/post-service-response.model';
import { PatchServiceResponse }          from '@models/api/patch-service-response.model';
import { PostServiceRequest }            from '@models/api/post-service-request.model';
import { MicrosoftTeamsRaw }             from '@models/api/microsoftTeamsRaw.model';
import { RequirementInfo }               from '@models/api/requirement-info.model';
import { HealthInfo }                    from '@models/api/health-info.model';
import { HealthCheckResponse }           from '@models/api/health-check-response.model';
import { RequirementCheckResponse }      from '@models/api/requirement-check-response.model';
import { HealthCheckResponseRaw }        from '@models/api/health-check-response-raw.model';
import { RequirementCheckResponseRaw }   from '@models/api/requirement-check-response-raw.model';
import { HealthCheckRequest }            from '@models/api/health-check-request.model';
import { FetchLicenseGroupListRequest }  from '@models/api/fetch-license-group-list-request.model';
import { FetchLicenseGroupListResponse } from '@models/api/fetch-license-group-list-response.model';
import { ListModelResponse }             from '@models/api/list-response.model';
import { LicenseGroup }                  from '@models/entity/license-group.model';
import { LicenseGroupQueryParams }       from '@models/form/license-group-query-params.model';
import { ListService }                   from '@services/list.service';
import { LicenseGroupFactory }           from '@models/factory/license-group.factory';
import { LicenseGroupParamsFactory }     from '@models/factory/license-group-params.factory';
import { FetchLicenseGroupRequest }      from '@models/api/fetch-license-group-request.model';
import { FetchLicenseGroupResponse }     from '@models/api/fetch-license-group-response.model';
import { LicenseGroupRaw }               from '@models/api/license-group-raw.model';
import { DeleteLicenseGroupRequest }     from '@models/api/delete-license-group-request.model';
import { DeleteLicenseGroupResponse }    from '@models/api/delete-license-group-response.model';
import { PostLicenseGroupRequest }       from '@models/api/post-license-group-request.model';
import { PostLicenseGroupResponse }      from '@models/api/post-license-group-response.model';
import { PatchLicenseGroupRequest }      from '@models/api/patch-license-group-request.model';
import { PatchLicenseGroupResponse }     from '@models/api/patch-license-group-response.model';
import { FetchLicensesRequest }          from '@models/api/fetch-licenses-request.model';
import { FetchLicensesResponse }         from '@models/api/fetch-licenses-response.model';
import { License }                       from '@models/entity/license.model';
import {
  FetchLicenseGroupNameAvailabilityRequest,
}                                        from '@models/api/fetch-license-group-name-availability-request.model';
import {
  FetchLicenseGroupNameAvailabilityResponse,
}                                        from '@models/api/fetch-license-group-name-availability-response.model';
import { AvailabilityCheckRaw }          from '@models/api/availability-check-raw.model';
import { Availability }                  from '@enums/availability.enum';
import {
  FetchTeamGroupListRequest,
}                                        from '@models/api/microsoft-teams/fetch-teams-group-list-request.model';
import {
  FetchTeamGroupListResponse,
}                                        from '@models/api/microsoft-teams/fetch-teams-group-list-response.model';
import { TeamGroupQueryParams }          from '@models/form/teams-group-query-params.model';
import { TeamGroupFactory }              from '@models/factory/teams-group.factory';
import { TeamGroupParamsFactory }        from '@models/factory/teams-group-params.factory';
import { TeamGroup }                     from '@models/entity/teams-group.model';
import { FetchTeamsRequest }             from '@models/api/microsoft-teams/fetch-teams-request.model';
import { FetchTeamsResponse }            from '@models/api/microsoft-teams/fetch-teams-response.model';
import { Team }                          from '@models/entity/team.model';
import {
  DeleteTeamGroupRequest,
}                                        from '@models/api/microsoft-teams/delete-teams-group-request.model';
import {
  DeleteTeamGroupResponse,
}                                        from '@models/api/microsoft-teams/delete-teams-group-response.model';
import {
  PatchTeamGroupRequest,
}                                        from '@models/api/microsoft-teams/patch-teams-group-request.model';
import {
  PatchTeamGroupResponse,
}                                        from '@models/api/microsoft-teams/patch-teams-group-response.model';
import { TeamGroupRaw }                  from '@models/api/microsoft-teams/teams-group-raw.model';
import {
  PostTeamGroupRequest,
}                                        from '@models/api/microsoft-teams/post-teams-group-request.model';
import {
  PostTeamGroupResponse,
}                                        from '@models/api/microsoft-teams/post-teams-group-response.model';
import {
  FetchTeamGroupNameAvailabilityRequest,
}                                        from '@models/api/microsoft-teams/fetch-team-group-name-availability-request.model';
import {
  FetchTeamGroupNameAvailabilityResponse,
}                                        from '@models/api/microsoft-teams/fetch-team-group-name-availability-response.model';
import {
  FetchTeamGroupRequest,
}                                        from '@models/api/microsoft-teams/fetch-team-group-request.model';
import {
  FetchTeamGroupResponse,
}                                        from '@models/api/microsoft-teams/fetch-team-group-response.model';
import { LicenseFactory }                from '@models/factory/license.factory';
import { LicenseParamsFactory }          from '@models/factory/license-params.factory';
import { LicenseQueryParams }            from '@models/form/license-query-params.model';
import { TeamFactory }                   from '@models/factory/team.factory';
import { TeamParamsFactory }             from '@models/factory/team-params.factory';
import { TeamsQueryParams }              from '@models/form/teams-query-params.model';
import { CallQueueGroupQueryParams }     from '@models/form/call-queue-group-query-params.model';
import { CallQueueQueryParams }          from '@models/form/call-queue-query-params.model';
import { CallQueue }                     from '@models/entity/call-queue.model';
import { CallQueueFactory }              from '@models/factory/call-queue.factory';
import { CallQueueParamsFactory }        from '@models/factory/call-queue-params.factory';
import { CallQueueGroup }                from '@models/entity/call-queue-group.model';
import { CallQueueGroupFactory }         from '@models/factory/call-queue-group.factory';
import { CallQueueGroupParamsFactory }   from '@models/factory/call-queue-group-params.factory';
import {
  FetchCallQueueGroupListRequest,
}                                        from '@models/api/microsoft-teams/fetch-call-queue-group-list-request.model';
import {
  FetchCallQueueGroupListResponse,
}                                        from '@models/api/microsoft-teams/fetch-call-queue-group-list-response.model';
import {
  FetchCallQueueGroupRequest,
}                                        from '@models/api/microsoft-teams/fetch-call-queue-group-request.model';
import {
  FetchCallQueueGroupResponse,
}                                        from '@models/api/microsoft-teams/fetch-call-queue-group-response.model';
import { CallQueueGroupRaw }             from '@models/api/microsoft-teams/call-queue-group-raw.model';
import {
  FetchCallQueuesRequest,
}                                        from '@models/api/microsoft-teams/fetch-call-queues-request.model';
import {
  FetchCallQueuesResponse,
}                                        from '@models/api/microsoft-teams/fetch-call-queues-response.model';
import {
  DeleteCallQueueGroupRequest,
}                                        from '@models/api/microsoft-teams/delete-call-queue-group-request.model';
import {
  DeleteCallQueueGroupResponse,
}                                        from '@models/api/microsoft-teams/delete-call-queue-group-response.model';
import {
  FetchCallQueueGroupNameAvailabilityRequest,
}                                        from '@models/api/microsoft-teams/fetch-call-queue-group-name-availability-request.model';
import {
  FetchCallQueueGroupNameAvailabilityResponse,
}                                        from '@models/api/microsoft-teams/fetch-call-queue-group-name-availability-response.model';
import {
  PostCallQueueGroupRequest,
}                                        from '@models/api/microsoft-teams/post-call-queue-group-request.model';
import {
  PostCallQueueGroupResponse,
}                                        from '@models/api/microsoft-teams/post-call-queue-group-response.model';
import {
  PatchCallQueueGroupRequest,
}                                        from '@models/api/microsoft-teams/patch-call-queue-group-request.model';
import {
  PatchCallQueueGroupResponse,
}                                        from '@models/api/microsoft-teams/patch-call-queue-group-response.model';
import { FetchAdGroupsRequest }          from '@models/api/service/fetch-ad-groups-request.model';
import { FetchAdGroupsResponse }         from '@models/api/service/fetch-ad-groups-response.model';
import { ADGroup }                       from '@models/entity/ad-group.model';
import { ADGroupFactory }                from '@models/factory/ad-group.factory';
import { ADGroupQueryParams }            from '@models/form/ad-group-query-params.model';
import { ADGroupQueryParamsFactory }     from '@models/factory/ad-group-query-params.factory';
import { RequirementCheckRequest }       from '@models/api/requirement-check-request.model';
import { MicrosoftToken }                from '@enums/microsoft-token.enum';
import { Token }                         from '@models/entity/token-state.model';

@Injectable({
  providedIn: 'root',
})
export class MicrosoftTeamsService {
  static getDefaultToken         = (identifier: MicrosoftToken, serviceId: string): Token => {
    return {
      id:       identifier,
      serviceId,
      status:   TokenStatus.Pending,
      lastAuth: false,
    };
  };
  private baseUrl: string        = environment.api.microsoftTeamsUrl;
  private baseServiceUrl: string = environment.api.serviceBaseUrl;

  private licenseFactory: LicenseFactory;
  private licenseParamsFactory: LicenseParamsFactory;
  private teamFactory: TeamFactory;
  private teamParamsFactory: TeamParamsFactory;
  private licenseGroupFactory: LicenseGroupFactory;
  private licenseGroupParamsFactory: LicenseGroupParamsFactory;
  private teamGroupFactory: TeamGroupFactory;
  private teamGroupParamsFactory: TeamGroupParamsFactory;
  private callQueueGroupFactory: CallQueueGroupFactory;
  private callQueueGroupParamsFactory: CallQueueGroupParamsFactory;
  private callQueueFactory: CallQueueFactory;
  private callQueueParamsFactory: CallQueueParamsFactory;
  private adGroupFactory: ADGroupFactory;
  private adGroupQueryParamsFactory: ADGroupQueryParamsFactory;

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  private buildServiceUri(uriSuffix: string): string {
    return `${ this.baseServiceUrl }${ uriSuffix }`;
  }

  private buildHealthCheckUri(serviceId: string): string {
    return this.buildUri(`service/${ serviceId }/check-health`);
  }

  private buildRequirementsCheckUri(serviceId: string): string {
    return this.buildUri(`service/${ serviceId }/check-requirements`);
  }

  private buildLicenseGroupListUri(serviceId: string, queryParams: LicenseGroupQueryParams): string {
    return this.buildUri(`service/${ serviceId }/license-groups${ LicenseGroupQueryParams.constructQueryString(queryParams) }`);
  }

  private buildCallQueueGroupListUri(serviceId: string, queryParams: LicenseGroupQueryParams): string {
    return this.buildUri(`service/${ serviceId }/call-queue-groups${ CallQueueGroupQueryParams.constructQueryString(queryParams) }`);
  }

  private buildTeamGroupListUri(serviceId: string, queryParams: TeamGroupQueryParams): string {
    return this.buildUri(`service/${ serviceId }/team-groups${ TeamGroupQueryParams.constructQueryString(queryParams) }`);
  }

  private buildADGroupListUri(serviceId: string, queryParams: ADGroupQueryParams): string {
    return this.buildUri(`service/${ serviceId }/ad-groups${ ADGroupQueryParams.constructQueryString(queryParams) }`);
  }

  private buildLicenseGroupUri(serviceId: string, id?: string): string {
    return this.buildUri(`service/${ serviceId }/license-groups${ id ? `/${ id }` : '' }`);
  }

  private buildCallQueueGroupUri(serviceId: string, id?: string): string {
    return this.buildUri(`service/${ serviceId }/call-queue-groups${ id ? `/${ id }` : '' }`);
  }

  private buildTeamGroupUri(serviceId: string, id?: string): string {
    return this.buildUri(`service/${ serviceId }/team-groups${ id ? `/${ id }` : '' }`);
  }

  private buildLicensesUri(serviceId: string, queryParams: LicenseQueryParams): string {
    return this.buildUri(`service/${ serviceId }/licenses${ LicenseQueryParams.constructQueryString(queryParams) }`);
  }

  private buildCallQueuesUri(serviceId: string, queryParams: LicenseQueryParams): string {
    return this.buildUri(`service/${ serviceId }/call-queues${ CallQueueQueryParams.constructQueryString(queryParams) }`);
  }

  private buildTeamsUri(serviceId: string, queryParams: TeamsQueryParams): string {
    return this.buildUri(`service/${ serviceId }/teams${ TeamsQueryParams.constructQueryString(queryParams) }`);
  }

  private buildLicenseGroupNameAvailabilityUri(serviceId: string, name: string): string {
    return this.buildUri(`service/${ serviceId }/license-groups/availability?name=${ name }`);
  }

  private buildTeamGroupNameAvailabilityUri(serviceId: string, name: string): string {
    return this.buildUri(`service/${ serviceId }/team-groups/availability?name=${ name }`);
  }

  private buildCallQueueGroupNameAvailabilityUri(serviceId: string, name: string): string {
    return this.buildUri(`service/${ serviceId }/call-queue-groups/availability?name=${ name }`);
  }

  constructor(private apiService: ApiService,
              private licenseGroupListService: ListService<LicenseGroup, LicenseGroupFactory, LicenseGroupQueryParams, LicenseGroupParamsFactory>,
              private licenseListService: ListService<License, LicenseFactory, LicenseQueryParams, LicenseParamsFactory>,
              private teamGroupListService: ListService<TeamGroup, TeamGroupFactory, TeamGroupQueryParams, TeamGroupParamsFactory>,
              private adGroupListService: ListService<ADGroup, ADGroupFactory, ADGroupQueryParams, ADGroupQueryParamsFactory>,
              private teamListService: ListService<Team, TeamFactory, TeamsQueryParams, TeamParamsFactory>,
              private callQueueListService: ListService<CallQueue, CallQueueFactory, CallQueueQueryParams, CallQueueParamsFactory>,
              private callQueueGroupListService: ListService<CallQueueGroup, CallQueueGroupFactory, CallQueueGroupQueryParams, CallQueueGroupParamsFactory>) {
    this.licenseGroupFactory         = new LicenseGroupFactory();
    this.licenseGroupParamsFactory   = new LicenseGroupParamsFactory();
    this.licenseFactory              = new LicenseFactory();
    this.licenseParamsFactory        = new LicenseParamsFactory();
    this.teamGroupFactory            = new TeamGroupFactory();
    this.teamGroupParamsFactory      = new TeamGroupParamsFactory();
    this.teamFactory                 = new TeamFactory();
    this.teamParamsFactory           = new TeamParamsFactory();
    this.callQueueGroupFactory       = new CallQueueGroupFactory();
    this.callQueueGroupParamsFactory = new CallQueueGroupParamsFactory();
    this.callQueueFactory            = new CallQueueFactory();
    this.callQueueParamsFactory      = new CallQueueParamsFactory();
    this.adGroupFactory              = new ADGroupFactory();
    this.adGroupQueryParamsFactory   = new ADGroupQueryParamsFactory();
  }

  requirementCheck$(req: RequirementCheckRequest): Observable<RequirementCheckResponse> {
    return this.apiService.apiGet$<RequirementCheckResponseRaw>(
      this.buildRequirementsCheckUri(req.serviceId),
      { authRequired: true })
      .pipe(
        map((res: RequirementCheckResponseRaw): RequirementCheckResponse => {

          const data = snakeToCamelCase(res.data) as RequirementInfo;

          const requirementState: RequirementsState = new Map<ServiceRequirementCheckName, boolean>([
            [ServiceRequirementCheckName.PhoneLicense, data.hasPhoneLicense],
            [ServiceRequirementCheckName.HasAdminRole, data.hasAdminRole],
            [ServiceRequirementCheckName.UserLicense, data.hasUserLicense],
            [ServiceRequirementCheckName.AccessTokenValidity, data.hasRequiredWids],
            [ServiceRequirementCheckName.TenantUnique, data.isTenantUnique],
          ]);
          return {
            error:   null,
            message: null,
            data:    {
              state:  requirementState,
              values: data,
            },
          };
        }),
        catchError((err: HttpErrorResponse): Observable<RequirementCheckResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: new Alert().fromApiMessage({ message: err.message }),
            data:    null,
          });
        }),
      );
  }

  healthCheck$(req: HealthCheckRequest): Observable<HealthCheckResponse> {
    const tokens                   = req.tokens;
    const healthStatus             = new Map<ServiceHealthCheckName, boolean>([
      [ServiceHealthCheckName.Auth, false],
      [ServiceHealthCheckName.HasAdminRole, false],
      [ServiceHealthCheckName.HasCallrouteDomain, false],
      [ServiceHealthCheckName.PhoneLicense, false],
      [ServiceHealthCheckName.UserLicense, false],
      [ServiceHealthCheckName.AccessTokenValidity, false],
      [ServiceHealthCheckName.HasRecentSync, false],
    ]);
    const healthCheckErrorResponse = (err?: HttpErrorResponse) => of({
      error:     err ? new Alert().fromApiError(err) : null,
      message:   null,
      serviceId: req.serviceId,
      data:      {
        state:        healthStatus,
        values:       {
          callrouteDomain:        null,
          initialDomain:          null,
          hasPhoneLicense:        false,
          hasUserLicense:         false,
          hasCallrouteDomain:     false,
          hasPhoneLicenseWarning: false,
          hasUserLicenseWarning:  false,
          tokenDataMatch:         false,
          tokenDataMessage:       null,
          hasRequiredWids:        false,
          hasAdminRole:           false,
          hasRecentSync:          false,
          syncWarning:            null,
          widsInfoUrl:            '',
          widsAssigned:           [],
          phoneLicenseMessages:   [],
          userLicenseMessages:    [],
        },
        connectedUpn: null,
      },
    });
    if (tokens.some(token => token.status !== TokenStatus.Active)) {
      return healthCheckErrorResponse();
    }
    return this.apiService.apiGet$<HealthCheckResponseRaw>(
      this.buildHealthCheckUri(req.serviceId),
      { authRequired: true })
      .pipe(
        map((res: HealthCheckResponseRaw): HealthCheckResponse => {
          const data = snakeToCamelCase(res.data) as HealthInfo;

          healthStatus.set(ServiceHealthCheckName.Auth, true);
          healthStatus.set(ServiceHealthCheckName.HasAdminRole, data.hasAdminRole);
          healthStatus.set(ServiceHealthCheckName.HasCallrouteDomain, data.hasCallrouteDomain);
          healthStatus.set(ServiceHealthCheckName.PhoneLicense, data.hasPhoneLicense);
          healthStatus.set(ServiceHealthCheckName.AccessTokenValidity, data.hasRequiredWids && data.tokenDataMatch);
          healthStatus.set(ServiceHealthCheckName.HasRecentSync, data.hasRecentSync);
          healthStatus.set(ServiceHealthCheckName.UserLicense, data.hasUserLicense);

          return {
            error:     null,
            message:   null,
            serviceId: req.serviceId,
            data:      {
              state:        healthStatus,
              values:       data,
              connectedUpn: res.data.access_token_upn,
            },
          };
        }),
        catchError((err: HttpErrorResponse): Observable<HealthCheckResponse> => {
          return healthCheckErrorResponse(err);
        }),
      );
  }

  postMicrosoftTeams$(req: PostServiceRequest<MicrosoftTeams>): Observable<PostServiceResponse<MicrosoftTeams>> {
    return this.apiService.apiPost$<{ data: ServiceItemRaw }>(
      this.buildServiceUri('services'),
      { type: req.serviceItem.serviceType, capabilities: req.capabilities },
      { authRequired: true })
      .pipe(
        map((res: { data: ServiceItemRaw }): PostServiceResponse<MicrosoftTeams> => {
          return {
            error:       null,
            serviceItem: new MicrosoftTeams().fromApiData(res.data as MicrosoftTeamsRaw),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostServiceResponse<MicrosoftTeams>> => {
          return of({
            error:       new Alert().fromApiError(err),
            serviceItem: null,
          });
        }),
      );
  }

  patchMSTeams$(req: PatchServiceRequest<MicrosoftTeams>): Observable<PatchServiceResponse<MicrosoftTeams>> {
    return this.apiService.apiPatch$<{ data: MicrosoftTeamsRaw }>(
      this.buildServiceUri(`services/${ req.serviceItem.id }`),
      new MicrosoftTeams(req.serviceItem).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: MicrosoftTeamsRaw }): PatchServiceResponse<MicrosoftTeams> => {
          return {
            error:       null,
            serviceItem: new MicrosoftTeams().fromApiData(res.data as MicrosoftTeamsRaw),
            message:     new Alert().fromApiMessage({
              message:   'Microsoft Teams service updated successfully.',
              color:     'green',
              url:       null,
              isSuccess: true,
            }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchServiceResponse<MicrosoftTeams>> => {
          return of({
            error:       new Alert().fromApiError(err, req.formFields),
            serviceItem: null,
            message:     null,
          });
        }),
      );
  }

  fetchCallQueueGroupList$(req: FetchCallQueueGroupListRequest): Observable<FetchCallQueueGroupListResponse> {
    return this.callQueueGroupListService.fetchListModel$(
      this.buildCallQueueGroupListUri(req.serviceId, req.queryParams),
      this.callQueueGroupFactory,
      this.callQueueGroupParamsFactory)
      .pipe(
        map((res: ListModelResponse<CallQueueGroup, CallQueueGroupQueryParams>): FetchCallQueueGroupListResponse => {
          return {
            data:         res.models,
            searchParams: res.searchParams,
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallQueueGroupListResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchLicenseGroupList$(req: FetchLicenseGroupListRequest): Observable<FetchLicenseGroupListResponse> {
    return this.licenseGroupListService.fetchListModel$(
      this.buildLicenseGroupListUri(req.serviceId, req.queryParams),
      this.licenseGroupFactory,
      this.licenseGroupParamsFactory)
      .pipe(
        map((res: ListModelResponse<LicenseGroup, LicenseGroupQueryParams>): FetchLicenseGroupListResponse => {
          return {
            data:         res.models,
            searchParams: res.searchParams,
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchLicenseGroupListResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchTeamGroupList$(req: FetchTeamGroupListRequest): Observable<FetchTeamGroupListResponse> {
    return this.teamGroupListService.fetchListModel$(
      this.buildTeamGroupListUri(req.serviceId, req.queryParams),
      this.teamGroupFactory,
      this.teamGroupParamsFactory)
      .pipe(
        map((res: ListModelResponse<TeamGroup, TeamGroupQueryParams>): FetchTeamGroupListResponse => {
          return {
            data:         res.models,
            searchParams: res.searchParams,
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchTeamGroupListResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchADGroupList$(req: FetchAdGroupsRequest): Observable<FetchAdGroupsResponse> {
    return this.adGroupListService.fetchListModel$(
      this.buildADGroupListUri(req.serviceId, req.queryParams),
      this.adGroupFactory,
      this.adGroupQueryParamsFactory)
      .pipe(
        map((res: ListModelResponse<ADGroup, ADGroupQueryParams>): FetchAdGroupsResponse => {
          return {
            data:         res.models,
            searchParams: res.searchParams,
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchAdGroupsResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchLicenseGroup$(req: FetchLicenseGroupRequest): Observable<FetchLicenseGroupResponse> {
    return this.apiService.apiGet$<{ data: LicenseGroupRaw }>(this.buildLicenseGroupUri(req.serviceId, req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: LicenseGroupRaw }): FetchLicenseGroupResponse => {
          return {
            error: null,
            data:  new LicenseGroup().fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchLicenseGroupResponse> => {
          return of({
            error: new Alert().fromApiError(err, null, true, req.serviceId),
            data:  null,
          });
        }),
      );
  }

  fetchCallQueueGroup$(req: FetchCallQueueGroupRequest): Observable<FetchCallQueueGroupResponse> {
    return this.apiService.apiGet$<{ data: CallQueueGroupRaw }>(this.buildCallQueueGroupUri(req.serviceId, req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: CallQueueGroupRaw }): FetchCallQueueGroupResponse => {
          return {
            error: null,
            data:  new CallQueueGroup().fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallQueueGroupResponse> => {
          return of({
            error: new Alert().fromApiError(err, null, true, req.serviceId),
            data:  null,
          });
        }),
      );
  }

  fetchTeamGroup$(req: FetchTeamGroupRequest): Observable<FetchTeamGroupResponse> {
    return this.apiService.apiGet$<{ data: TeamGroupRaw }>(this.buildTeamGroupUri(req.serviceId, req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: TeamGroupRaw }): FetchTeamGroupResponse => {
          return {
            error: null,
            data:  new TeamGroup().fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchTeamGroupResponse> => {
          return of({
            error: new Alert().fromApiError(err, null, true, req.serviceId),
            data:  null,
          });
        }),
      );
  }

  deleteLicenseGroup$(req: DeleteLicenseGroupRequest): Observable<DeleteLicenseGroupResponse> {
    return this.apiService.apiDelete$(this.buildLicenseGroupUri(req.serviceId, req.id),
      { authRequired: true })
      .pipe(
        map((): DeleteLicenseGroupResponse => {
          return {
            error:        null,
            id:           req.id,
            serviceId:    req.serviceId,
            isLastOnPage: req.isLastOnPage,
            message:      new Alert().fromApiMessage({ message: `Successfully deleted ${ req.name }!` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteLicenseGroupResponse> => {
          return of({
            error:        new Alert().fromApiError(err, null, true, req.serviceId),
            id:           req.id,
            serviceId:    req.serviceId,
            isLastOnPage: req.isLastOnPage,
          });
        }),
      );
  }

  deleteTeamGroup$(req: DeleteTeamGroupRequest): Observable<DeleteTeamGroupResponse> {
    return this.apiService.apiDelete$(this.buildTeamGroupUri(req.serviceId, req.id),
      { authRequired: true })
      .pipe(
        map((): DeleteTeamGroupResponse => {
          return {
            error:        null,
            id:           req.id,
            serviceId:    req.serviceId,
            isLastOnPage: req.isLastOnPage,
            message:      new Alert().fromApiMessage({ message: `Successfully deleted ${ req.name }!` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteTeamGroupResponse> => {
          return of({
            error:        new Alert().fromApiError(err, null, true, req.serviceId),
            id:           req.id,
            serviceId:    req.serviceId,
            isLastOnPage: req.isLastOnPage,
          });
        }),
      );
  }

  deleteCallQueueGroup$(req: DeleteCallQueueGroupRequest): Observable<DeleteCallQueueGroupResponse> {
    return this.apiService.apiDelete$(this.buildCallQueueGroupUri(req.serviceId, req.id),
      { authRequired: true })
      .pipe(
        map((): DeleteCallQueueGroupResponse => {
          return {
            error:        null,
            id:           req.id,
            serviceId:    req.serviceId,
            isLastOnPage: req.isLastOnPage,
            message:      new Alert().fromApiMessage({ message: `Successfully deleted ${ req.name }!` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteCallQueueGroupResponse> => {
          return of({
            error:        new Alert().fromApiError(err, null, true, req.serviceId),
            id:           req.id,
            serviceId:    req.serviceId,
            isLastOnPage: req.isLastOnPage,
          });
        }),
      );
  }

  postLicenseGroup$(req: PostLicenseGroupRequest): Observable<PostLicenseGroupResponse> {
    return this.apiService.apiPost$<{ data: LicenseGroupRaw }>(
      this.buildLicenseGroupUri(req.serviceId),
      { ...new PostLicenseGroupRequest(req).toApiData() },
      { authRequired: true })
      .pipe(
        map((res: { data: LicenseGroupRaw }): PostLicenseGroupResponse => {
          return {
            data:      new LicenseGroup().fromApiData({ ...res.data, requestId: req.requestId }),
            message:   new Alert().fromApiMessage({ message: `Successfully created ${ res.data.name }!` }),
            serviceId: req.serviceId,
            error:     null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostLicenseGroupResponse> => {
          return of({
            error:     new Alert().fromApiError(err),
            serviceId: req.serviceId,
            data:      null,
          });
        }),
      );
  }

  patchLicenseGroup$(req: PatchLicenseGroupRequest): Observable<PatchLicenseGroupResponse> {
    return this.apiService.apiPatch$<{ data: LicenseGroupRaw }>(
      this.buildLicenseGroupUri(req.serviceId, req.id),
      { ...new PatchLicenseGroupRequest(req).toApiData() },
      { authRequired: true })
      .pipe(
        map((res: { data: LicenseGroupRaw }): PatchLicenseGroupResponse => {
          return {
            data:    new LicenseGroup().fromApiData({ ...res.data, requestId: req.requestId }),
            message: new Alert().fromApiMessage({ message: `Successfully updated ${ res.data.name }!` }),
            error:   null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchLicenseGroupResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  postTeamGroup$(req: PostTeamGroupRequest): Observable<PostTeamGroupResponse> {
    return this.apiService.apiPost$<{ data: TeamGroupRaw }>(
      this.buildTeamGroupUri(req.serviceId),
      { ...new PostTeamGroupRequest(req).toApiData() },
      { authRequired: true })
      .pipe(
        map((res: { data: TeamGroupRaw }): PostTeamGroupResponse => {
          return {
            data:      new TeamGroup().fromApiData({ ...res.data, requestId: req.requestId }),
            message:   new Alert().fromApiMessage({ message: `Successfully created ${ res.data.name }!` }),
            serviceId: req.serviceId,
            error:     null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostTeamGroupResponse> => {
          return of({
            error:     new Alert().fromApiError(err),
            serviceId: req.serviceId,
            data:      null,
          });
        }),
      );
  }

  postCallQueueGroup$(req: PostCallQueueGroupRequest): Observable<PostCallQueueGroupResponse> {
    return this.apiService.apiPost$<{ data: CallQueueGroupRaw }>(
      this.buildCallQueueGroupUri(req.serviceId),
      { ...new PostCallQueueGroupRequest(req).toApiData() },
      { authRequired: true })
      .pipe(
        map((res: { data: CallQueueGroupRaw }): PostCallQueueGroupResponse => {
          return {
            data:      new CallQueueGroup().fromApiData({ ...res.data, requestId: req.requestId }),
            message:   new Alert().fromApiMessage({ message: `Successfully created ${ res.data.name }!` }),
            serviceId: req.serviceId,
            error:     null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostCallQueueGroupResponse> => {
          return of({
            error:     new Alert().fromApiError(err),
            serviceId: req.serviceId,
            data:      null,
          });
        }),
      );
  }

  patchTeamGroup$(req: PatchTeamGroupRequest): Observable<PatchTeamGroupResponse> {
    return this.apiService.apiPatch$<{ data: TeamGroupRaw }>(
      this.buildTeamGroupUri(req.serviceId, req.id),
      { ...new PatchTeamGroupRequest(req).toApiData() },
      { authRequired: true })
      .pipe(
        map((res: { data: TeamGroupRaw }): PatchTeamGroupResponse => {
          return {
            data:    new TeamGroup().fromApiData({ ...res.data, requestId: req.requestId }),
            message: new Alert().fromApiMessage({ message: `Successfully updated ${ res.data.name }!` }),
            error:   null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchTeamGroupResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  patchCallQueueGroup$(req: PatchCallQueueGroupRequest): Observable<PatchCallQueueGroupResponse> {
    return this.apiService.apiPatch$<{ data: CallQueueGroupRaw }>(
      this.buildCallQueueGroupUri(req.serviceId, req.id),
      { ...new PatchCallQueueGroupRequest(req).toApiData() },
      { authRequired: true })
      .pipe(
        map((res: { data: CallQueueGroupRaw }): PatchCallQueueGroupResponse => {
          return {
            data:    new CallQueueGroup().fromApiData({ ...res.data, requestId: req.requestId }),
            message: new Alert().fromApiMessage({ message: `Successfully updated ${ res.data.name }!` }),
            error:   null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchCallQueueGroupResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchLicenses$(req: FetchLicensesRequest): Observable<FetchLicensesResponse> {
    return this.licenseListService.fetchListModel$(
      this.buildLicensesUri(req.serviceId, req.queryParams),
      this.licenseFactory,
      this.licenseParamsFactory)
      .pipe(map((res: ListModelResponse<License, LicenseQueryParams>): FetchLicensesResponse => {
          return {
            data:         res.models,
            searchParams: res.searchParams,
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchLicensesResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }));
  }

  fetchTeams$(req: FetchTeamsRequest): Observable<FetchTeamsResponse> {
    return this.teamListService.fetchListModel$(
      this.buildTeamsUri(req.serviceId, req.queryParams),
      this.teamFactory,
      this.teamParamsFactory)
      .pipe(
        map((res: ListModelResponse<Team, TeamsQueryParams>): FetchTeamsResponse => {
          return {
            data:         res.models,
            searchParams: res.searchParams,
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchTeamsResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchCallQueues$(req: FetchCallQueuesRequest): Observable<FetchCallQueuesResponse> {
    return this.callQueueListService.fetchListModel$(
      this.buildCallQueuesUri(req.serviceId, req.queryParams),
      this.callQueueFactory,
      this.callQueueParamsFactory)
      .pipe(
        map((res: ListModelResponse<CallQueue, CallQueueQueryParams>): FetchCallQueuesResponse => {
          return {
            data:         res.models,
            searchParams: res.searchParams,
            error:        null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallQueuesResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            data:         null,
            searchParams: null,
          });
        }),
      );
  }

  fetchLicenseGroupNameAvailability$(req: FetchLicenseGroupNameAvailabilityRequest): Observable<FetchLicenseGroupNameAvailabilityResponse> {
    if (!req.name || req.name.length < 2) {
      return of({
        error:        null,
        availability: null,
      });
    }
    return this.apiService.apiGet$<{ data: AvailabilityCheckRaw }>(
      this.buildLicenseGroupNameAvailabilityUri(req.serviceId, req.name),
      { authRequired: false })
      .pipe(
        map((res: { data: AvailabilityCheckRaw }): FetchLicenseGroupNameAvailabilityResponse => {
          return {
            error:        null,
            availability: res?.data?.is_available ? Availability.Available : Availability.Unavailable,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchLicenseGroupNameAvailabilityResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            availability: null,
          });
        }),
      );
  }

  fetchTeamGroupNameAvailability$(req: FetchTeamGroupNameAvailabilityRequest): Observable<FetchTeamGroupNameAvailabilityResponse> {
    if (!req.name || req.name.length < 2) {
      return of({
        error:        null,
        availability: null,
      });
    }
    return this.apiService.apiGet$<{ data: AvailabilityCheckRaw }>(
      this.buildTeamGroupNameAvailabilityUri(req.serviceId, req.name),
      { authRequired: false })
      .pipe(
        map((res: { data: AvailabilityCheckRaw }): FetchTeamGroupNameAvailabilityResponse => {
          return {
            error:        null,
            availability: res?.data?.is_available ? Availability.Available : Availability.Unavailable,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchTeamGroupNameAvailabilityResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            availability: null,
          });
        }),
      );
  }

  fetchCallQueueGroupNameAvailability$(req: FetchCallQueueGroupNameAvailabilityRequest): Observable<FetchCallQueueGroupNameAvailabilityResponse> {
    if (!req.name || req.name.length < 2) {
      return of({
        error:        null,
        availability: null,
      });
    }
    return this.apiService.apiGet$<{ data: AvailabilityCheckRaw }>(
      this.buildCallQueueGroupNameAvailabilityUri(req.serviceId, req.name),
      { authRequired: false })
      .pipe(
        map((res: { data: AvailabilityCheckRaw }): FetchCallQueueGroupNameAvailabilityResponse => {
          return {
            error:        null,
            availability: res?.data?.is_available ? Availability.Available : Availability.Unavailable,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchCallQueueGroupNameAvailabilityResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            availability: null,
          });
        }),
      );
  }

}
