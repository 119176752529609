import { Injectable }                       from '@angular/core';
import { MicrosoftTeamsUser }               from '@models/entity/microsoft-teams-user.model';
import { SyncServiceUserListRequestAction } from '@redux/service/service.actions';
import { StoreState }                       from '@redux/store';
import { Store }                            from '@ngrx/store';
import { ServiceItem }                      from '@models/entity/service-item.model';
import { SyncMode }                         from '@enums/sync-mode.enum';
import { UserSyncVersion }                  from '@enums/user-sync-version.enum';
import { RouterService }                    from '@services/router.service';
import { ServiceUserTab }                   from '@enums/service-user-tab.enum';
import { MicrosoftTeamsSettings }           from '@models/entity/microsoft-teams-settings.model';

@Injectable({
  providedIn: 'root',
})
export class MicrosoftTeamsActionService {

  constructor(private store: Store<StoreState>,
              private routerService: RouterService) { }

  goToServiceUserProfile(serviceItem: ServiceItem, user: MicrosoftTeamsUser): Promise<boolean> {
    return this.routerService.navigate(['/services', 'microsoft-teams', serviceItem.id, 'service-user', user.id], undefined, {
      fragment: ServiceUserTab.Profile,
    });
  }

  openLicenseAssignment(user: MicrosoftTeamsUser, serviceId: string): Promise<boolean> {
    return this.routerService.navigate(['/services', 'microsoft-teams', serviceId, 'service-user', user.id], undefined, { fragment: ServiceUserTab.Microsoft365Licensing });
  }

  openTeamAssignment(user: MicrosoftTeamsUser, serviceId: string): Promise<boolean> {
    return this.routerService.navigate(['/services', 'microsoft-teams', serviceId, 'service-user', user.id], undefined, { fragment: ServiceUserTab.TeamMemberships });
  }

  openCallQueueAssignment(user: MicrosoftTeamsUser, serviceId: string): Promise<boolean> {
    return this.routerService.navigate(['/services', 'microsoft-teams', serviceId, 'service-user', user.id], undefined, { fragment: ServiceUserTab.CallQueues });
  }

  syncUser(serviceId: string, email: string, teamsSettings: MicrosoftTeamsSettings, version: UserSyncVersion): void {
    this.store.dispatch(SyncServiceUserListRequestAction({
      serviceId,
      isFullSync:         false,
      includeUnavailable: true,
      autoProvision:      teamsSettings.sync.autoProvision,
      version,
      mode:               SyncMode.Identities,
      identities:         [email],
      inboundAssignment:  teamsSettings.sync.inboundAssignment,
    }));
  }

}
