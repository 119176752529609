import { Injectable }                                                             from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { Observable }                                                             from 'rxjs';
import { StoreState }                                                             from '@redux/store';
import { Store }                                                                  from '@ngrx/store';
import { filter, map, take }                                                      from 'rxjs/operators';
import { selectAuth }                                                             from '@redux/auth/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class ViewCompanyGuard implements CanActivate, CanActivateChild {
  constructor(private store: Store<StoreState>,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.queryParams?.company) {
      return true;
    }
    return this.fromData();
  }

  canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.queryParams?.company) {
      return true;
    }
    return this.fromData();
  }

  private fromData(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(selectAuth)
      .pipe(
        filter(auth => !!auth.user),
        map(auth => !!(auth.viewCompanyUUID || !auth.user?.isPartner)),
        take(1))
      .toPromise()
      .then(canAccess => canAccess || this.router.parseUrl('/customers'));
  }

}
