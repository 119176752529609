import { BaseResponse } from '@redux/helpers/reducer.helper';
import { Alert }        from '@models/entity/alert.model';
import { ListParams }   from '@models/form/list-params.model';

export function updateItemInList<T extends { id: string }>(list: T[], updatedItem: T, cancelled?: boolean): T[] {
  if (cancelled) {
    return list;
  }
  return list?.map(item => {
    if (item?.id !== updatedItem?.id) {
      return item;
    }
    return updatedItem;
  }) || [];
}

export function removeItemFromList<T extends { id: string }>(list: T[], res: BaseResponse & {
  id: string,
  cancelled?: boolean
}): T[] {
  if (res.error || res.cancelled) {
    return list;
  }
  return list
    ?.filter(item => item.id !== res.id) || [];
}

export function addItemToList<T>(list: T[], item: T, pageSize: number, error: Alert): T[] {
  return error || list?.length && (list.length >= (pageSize || 0)) ?
    list : (list || []).concat(item);
}

export function postItem<State, Item extends { id: string }>(
  state: State,
  item: Item,
  error: Alert,
  listStateKey: keyof State,
  itemStateKey: keyof State,
  queryParamsStateKey: keyof State): Partial<State> {

  const newState = { ...state };

  const listState: Item[]            = addItemToList(state[listStateKey] as Item[], item, (state[queryParamsStateKey] as ListParams)?.pageSize, error) as Item[];
  (newState[listStateKey] as Item[]) = listState;

  const itemState: Item            = !error ? item : state[itemStateKey] as Item;
  (newState[itemStateKey] as Item) = itemState;
  
  return newState;
}

export function patchItem<State, Item extends { id: string }>(
  state: State,
  item: Item,
  error: Alert,
  cancelled: boolean,
  listStateKey: keyof State,
  itemStateKey: keyof State): Partial<State> {

  const newState = { ...state };

  const listState: Item[]            = updateItemInList(state[listStateKey] as Item[], item, cancelled) as Item[];
  (newState[listStateKey] as Item[]) = listState;

  const itemState: Item            = !error ? item : state[itemStateKey] as Item;
  (newState[itemStateKey] as Item) = itemState;

  return newState;
}

export function deleteItem<State, Item extends { id: string }>(
  state: State,
  res: BaseResponse & { id: string, cancelled?: boolean },
  listStateKey: keyof State,
  itemStateKey: keyof State): Partial<State> {

  const newState = { ...state };

  const listState: Item[]            = removeItemFromList(state[listStateKey] as Item[], res) as Item[];
  (newState[listStateKey] as Item[]) = listState;

  const itemState: Item            = !res.error ? null : state[itemStateKey] as Item;
  (newState[itemStateKey] as Item) = itemState;

  return newState;
}
