import { Injectable, OnDestroy }         from '@angular/core';
import { Store }                         from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  concatMap,
  debounceTime,
  filter,
  map,
  mapTo,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { NumberService } from '@services/number.service';

import * as ActionTypes                      from './number.actions';
import {
  AddNumberRangeRequestAction,
  AddNumberRangeResponseAction,
  AssignInboundNumberRequestAction,
  AssignInboundNumberResponseAction,
  BulkNumberUpdateRequestAction,
  BulkNumberUpdateResponseAction,
  BulkProvisionRequestAction,
  BulkProvisionResponseAction,
  DeleteLocationRequestAction,
  DeleteLocationResponseAction,
  DeleteNumberRangeRequestAction,
  DeleteNumberRangeResponseAction,
  DeleteNumberRequestAction,
  DeleteNumberResponseAction,
  DeleteNumberTagRequestAction,
  DeleteNumberTagResponseAction,
  ExportNumbersRequestAction,
  ExportNumbersResponseAction,
  FetchAllNumberTagsRequestAction,
  FetchBulkCountByAssignedRequestAction,
  FetchBulkCountByAssignedResponseAction,
  FetchInventoryAreaListRequestAction,
  FetchInventoryAreaListResponseAction,
  FetchInventoryProviderRequestAction,
  FetchInventoryProviderResponseAction,
  FetchLocationNameAvailabilityRequestAction,
  FetchLocationRequestAction,
  FetchLocationResponseAction,
  FetchLocationsRequestAction,
  FetchLocationsResponseAction,
  FetchNumberCompatibilityRequestAction,
  FetchNumberCompatibilityResponseAction,
  FetchNumberCountsRequestAction,
  FetchNumberCountsResponseAction,
  FetchNumberListRequestAction,
  FetchNumberRangeListRequestAction,
  FetchNumberRangeListResponseAction,
  FetchNumberRangeRequestAction,
  FetchNumberRangeResponseAction,
  FetchNumberRequestAction,
  FetchNumbersServiceMetadataRequestAction,
  FetchNumbersServiceMetadataResponseAction,
  FetchNumberTagsRequestAction,
  FetchRangeCountByExhaustionStatusRequestAction,
  FetchRangeCountByExhaustionStatusResponseAction,
  ImportNumbersRequestAction,
  ImportNumbersResponseAction,
  NumberLocationRemoveRequestAction,
  NumberLocationRemoveResponseAction,
  NumberReservationRemoveRequestAction,
  NumberReservationRemoveResponseAction,
  PatchLocationRequestAction,
  PatchLocationResponseAction,
  PatchNumberRangeRequestAction,
  PatchNumberRangeResponseAction,
  PatchNumberRequestAction,
  PatchNumberResponseAction,
  PatchNumberTagRequestAction,
  PatchNumberTagResponseAction,
  PostLocationRequestAction,
  PostLocationResponseAction,
  SetNumberRangeRoutesRequestAction,
  SetNumberRangeRoutesResponseAction,
}                                            from './number.actions';
import { StoreState }                        from '@redux/store';
import { RouterService }                     from '@services/router.service';
import { PatchNumberResponse }               from '@models/api/patch-number-response.model';
import { NumberQueryParams }                 from '@models/form/number-query-params.model';
import { DeleteNumberRangeResponse }         from '@models/api/delete-number-range-response.model';
import { PatchNumberRangeResponse }          from '@models/api/patch-number-range-response.model';
import { FetchNumberRangeRequest }           from '@models/api/fetch-number-range-request.model';
import { withScopes }                        from '@rxjs/with-scopes.operator';
import { FetchNumberListRequest }            from '@models/api/fetch-number-list-request';
import {
  selectLocationQueryParams,
  selectNumber,
  selectNumberQueryParams,
  selectNumberRangeQueryParams,
  selectNumberTagsQueryParams,
}                                            from '@redux/number/number.selectors';
import { FetchNumberRequest }                from '@models/api/fetch-number-request.model';
import { selectUserScopes }                  from '@redux/auth/auth.selectors';
import { NumberState }                       from '@redux/number/number.reducer';
import { PatchNumberRangeRequest }           from '@models/api/patch-number-range-request.model';
import { PatchNumberRequest }                from '@models/api/patch-number-request.model';
import { FetchNumberTagsResponse }           from '@models/api/fetch-number-tags-response.model';
import { FetchNumberRangeResponse }          from '@models/api/fetch-number-range-response.model';
import { FetchNumberRangeListRequest }       from '@models/api/fetch-number-range-list-request.model';
import { FetchNumberCountsResponse }         from '@models/api/fetch-number-counts-response.model';
import { withThrottle }                      from '@rxjs/action-throttle.operator';
import { interval, Observable, of, Subject } from 'rxjs';
import { SetNumberRangeRoutesResponse }      from '@models/api/set-number-range-routes-response.model';
import { FetchNumberRangeListResponse }      from '@models/api/fetch-number-range-list-response.model';
import { AddNumberRangeRequest }             from '@models/api/add-number-range-request.model';
import { BulkProvisionResponse }             from '@models/api/bulk-provision-response.model';
import { FetchNumberResponse }               from '@models/api/fetch-number-response.model';
import { AssignInboundNumberRequest }        from '@models/api/assign-inbound-number-request.model';
import { AuthScope }                         from '@enums/auth-scope.enum';
import { FetchNumberTagsRequest }            from '@models/api/fetch-number-tags-request.model';
import { AssignInboundNumberResponse }       from '@models/api/assign-inbound-number-response.model';
import { NumberRangeQueryParams }            from '@models/form/number-range-query-params.model';
import { AddNumberRangeResponse }            from '@models/api/add-number-range-response.model';
import { SetNumberRangeRoutesRequest }       from '@models/api/set-number-range-routes-request.model';
import { DeleteNumberRangeRequest }          from '@models/api/delete-number-range-request.model';
import { Location as AngularLocation }       from '@angular/common';
import { Location }                          from '@models/entity/location.model';
import { FetchNumberListResponse }           from '@models/api/fetch-number-list-response.model';
import { FetchInventoryProvidersResponse }   from '@models/api/fetch-inventory-providers-response.model';
import { ConfirmModalComponent }             from '@dialog/general/confirm-modal/confirm-modal.component';
import { MatDialog }                         from '@angular/material/dialog';
import { NumberRange }                       from '@models/entity/number-range.model';
import { NumberRangeStatus }                 from '@models/entity/number-status.model';
import { DeleteNumberRequest }               from '@models/api/delete-number-request.model';
import { DeleteNumberResponse }              from '@models/api/delete-number-response.model';
import { PatchNumberTagResponse }            from '@models/api/patch-number-tag-response.model';
import { PatchNumberTagRequest }             from '@models/api/patch-number-tag-request.model';
import { DeleteNumberTagRequest }            from '@models/api/delete-number-tag-request.model';
import { DeleteNumberTagResponse }           from '@models/api/delete-number-tag-response.model';
import { NumberTagQueryParams }              from '@models/form/number-tag-query-params.model';
import { ExportNumbersResponse }             from '@models/api/number/export-numbers-response.model';
import { ConfirmModalData }                  from '@models/ui/confirm-modal-data.model';
import { FetchTaskRequestAction }            from '@redux/audit/audit.actions';
import { FetchLocationsRequest }             from '@models/api/number/fetch-locations-request.model';
import { FetchLocationsResponse }            from '@models/api/number/fetch-locations-response.model';
import { FetchLocationRequest }              from '@models/api/number/fetch-location-request.model';
import { FetchLocationResponse }             from '@models/api/number/fetch-location-response.model';
import { DeleteLocationRequest }             from '@models/api/number/delete-location-request.model';
import { DeleteLocationResponse }            from '@models/api/number/delete-location-response.model';
import { LocationQueryParams }               from '@models/form/location-query-params.model';
import { PatchLocationRequest }              from '@models/api/number/patch-location-request.model';
import { PatchLocationResponse }             from '@models/api/number/patch-location-response.model';
import { PostLocationRequest }               from '@models/api/number/post-location-request.model';
import { PostLocationResponse }              from '@models/api/number/post-location-response.model';
import {
  FetchLocationNameAvailabilityResponse,
}                                            from '@models/api/number/fetch-location-name-availability-response.model';
import {
  FetchLocationNameAvailabilityRequest,
}                                            from '@models/api/number/fetch-location-name-availability-request.model';
import {
  FetchBulkCountByAssignedRequest,
}                                            from '@models/api/number/fetch-bulk-count-by-assigned-request.model';
import {
  FetchBulkCountByAssignedResponse,
}                                            from '@models/api/number/fetch-bulk-count-by-assigned-response.model';
import { FetchNumberCountsRequest }          from '@models/api/fetch-number-counts-request.model';
import { NumberReservationRemoveRequest }    from '@models/api/number/number-reservation-remove-request.model';
import {
  NumberReservationRemoveResponse,
}                                            from '@models/api/number/number-reservation-remove-response.model';
import { BulkNumberUpdateRequest }           from '@models/api/number/bulk-number-update-request.model';
import { BulkNumberUpdateResponse }          from '@models/api/number/bulk-number-update-response.model';
import {
  FetchNumbersServiceMetadataResponse,
}                                            from '@models/api/number/fetch-numbers-service-metadata-response.model';
import { FetchInventoryAreaListRequest }     from '@models/api/number/fetch-inventory-area-list-request.model';
import {
  FetchInventoryAreaListResponse,
}                                            from '@models/api/number/fetch-inventory-area-list-response.model';
import { ImportNumbersRequest }              from '@models/api/number/import-numbers-request.model';
import { ImportNumbersResponse }             from '@models/api/number/import-numbers-response.model';
import { CarrierType }                       from '@enums/carrier-type.enum';
import {
  FetchNumberCompatibilityRequest,
}                                            from '@models/api/number/fetch-number-compatibility-request.model';
import {
  FetchNumberCompatibilityResponse,
}                                            from '@models/api/number/fetch-number-compatibility-response.model';
import {
  FetchRangeCountByExhaustionStatusResponse,
}                                            from '@models/api/number/fetch-range-count-by-exhaustion-status-response.model';
import { ExportNumbersRequest }              from '@models/api/number/export-numbers-request.model';


@Injectable()
export class NumberEffects implements OnDestroy {

  killNumberListPoll       = new Subject<void>();
  killNumberListPoll$      = this.killNumberListPoll.asObservable();
  killNumberRangeListPoll  = new Subject<void>();
  killNumberRangeListPoll$ = this.killNumberRangeListPoll.asObservable();

  private static shouldPollRanges(numberRanges: NumberRange[]): boolean {
    return numberRanges?.some(range => range.status === NumberRangeStatus.Deleted);
  }

  constructor(
    private actions$: Actions,
    private numberService: NumberService,
    private store: Store<StoreState>,
    private location: AngularLocation,
    private router: RouterService,
    private dialog: MatDialog,
  ) {}

  fetchNumberRange$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNumberRangeRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    switchMap((req: FetchNumberRangeRequest) => this.numberService.fetchNumberRange$(req)),
    map((res: FetchNumberRangeResponse) => FetchNumberRangeResponseAction(res)),
  ));

  addNumberRange$ = createEffect(() => this.actions$.pipe(
    ofType(AddNumberRangeRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberWrite]),
    switchMap((req: AddNumberRangeRequest) => this.numberService.addNumberRange$(req)),
    map((res: AddNumberRangeResponse) => AddNumberRangeResponseAction(res)),
  ));

  postNumberRangeFetch$ = createEffect(() => this.actions$.pipe(
    ofType(AddNumberRangeResponseAction),
    tap(() => {
      this.store.dispatch(FetchNumberRangeListRequestAction({}));
      this.store.dispatch(FetchNumbersServiceMetadataRequestAction({}));
    }),
  ), { dispatch: false });

  setNumberRangeRoutes$ = createEffect(() => this.actions$.pipe(
    ofType(SetNumberRangeRoutesRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberWrite]),
    switchMap((req: SetNumberRangeRoutesRequest) => this.numberService.setNumberRangeRoutes$(req)),
    map((res: SetNumberRangeRoutesResponse) => SetNumberRangeRoutesResponseAction(res)),
  ));

  fetchNumberRanges$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNumberRangeListRequestAction),
    tap(() => this.killNumberRangeListPoll.next()),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    withLatestFrom(this.store.select(selectNumberRangeQueryParams)),
    switchMap(([req, queryParams]: [FetchNumberRangeListRequest, NumberRangeQueryParams]) => {
      return this.numberService.fetchNumberRangeList$({ ...(req || {}), queryParams: req.queryParams || queryParams });
    }),
    tap(res => {
      if (NumberEffects.shouldPollRanges(res.models)) {
        this.pollNumberRanges();
      }
    }),
    map((res: FetchNumberRangeListResponse) => FetchNumberRangeListResponseAction(res)),
  ));

  deleteNumberRange$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteNumberRangeRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberWrite]),
    switchMap((req: DeleteNumberRangeRequest) => {
      return this.numberService.fetchBulkCountByAssigned$({ ids: [req.rangeId], mode: 'RANGE' })
        .pipe(
          concatMap(res => {
            let confirm$: Observable<boolean>;
            const counts = res.data?.[req.rangeId];
            if (!counts) {
              return of({ error: null, message: null, id: null });
            }

            if (counts.ASSIGNED > 0 && req.rangeCarrierType !== CarrierType.BYON) {
              confirm$ = this.dialog.open(ConfirmModalComponent, {
                panelClass: 'cr-dialog',
                maxWidth:   '700px',
                data:       {
                  title:          `Unable to delete '${ req.rangeName }'`,
                  content:        `<p>The number range contains numbers that have services assigned. 
Please unassign services from all numbers within this range to proceed with deletion.</p>`,
                  confirmBtnText: 'Close',
                },
              })
                .afterClosed()
                .pipe(mapTo(false));
            } else {
              confirm$ = this.dialog.open(ConfirmModalComponent, {
                panelClass: 'cr-dialog',
                maxWidth:   '700px',
                data:       {
                  title:          `Delete ${ req.rangeName }`,
                  content:        `<p>You are about to delete a number range. Click 'delete' to continue with the deletion.</p>`,
                  confirmBtnText: 'Delete',
                  showCancel:     true,
                  typeConfirm:    true,
                },
              })
                .afterClosed();
            }
            return confirm$.pipe(concatMap((confirm: boolean) => {
              if (!confirm) {
                return of({ error: null, message: null, id: null });
              }
              return this.numberService.deleteNumberRange$(req);
            }));
          }));
    }),
    tap((res: DeleteNumberRangeResponse) => {
      if (!res.error && res.id && this.location.path()
        .includes(res.id)) {
        return this.router.navigate(['/numbers']);
      }
    }),
    map((res: DeleteNumberRangeResponse) => DeleteNumberRangeResponseAction(res)),
  ));

  deleteLocation$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteLocationRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberWrite]),
    switchMap((req: DeleteLocationRequest) => {
      const confirm$: Observable<boolean> = this.dialog.open(ConfirmModalComponent, {
        panelClass: 'cr-dialog',
        maxWidth:   '700px',
        data:       {
          title:          `Delete ${ req.locationName }`,
          content:        `<p>You are about to delete a location. Click 'delete' to continue with the deletion.</p>`,
          confirmBtnText: 'Delete',
          showCancel:     true,
          typeConfirm:    true,
        },
      })
        .afterClosed();
      return confirm$.pipe(concatMap((confirm: boolean) => {
        if (!confirm) {
          return of({ error: null, message: null, id: null });
        }
        return this.numberService.deleteLocation$(req);
      }));
    }),
    tap((res: DeleteLocationResponse) => {
      if (!res.error && res.id && this.location.path()
        .includes(res.id)) {
        return this.router.navigate(['/numbers']);
      }
    }),
    map((res: DeleteLocationResponse) => DeleteLocationResponseAction(res)),
  ));

  removeNumberFromLocation$ = createEffect(() => this.actions$.pipe(
    ofType(NumberLocationRemoveRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberWrite]),
    switchMap((req: PatchNumberRequest) => {
      const confirm$: Observable<boolean> = this.dialog.open(ConfirmModalComponent, {
        panelClass: 'cr-dialog',
        maxWidth:   '700px',
        data:       {
          title:          `Remove from ${ req.locationName }`,
          content:        `<p>You are about to remove this number from ${ req.locationName }. Click 'delete' to continue with removal.</p>`,
          confirmBtnText: 'Delete',
          showCancel:     true,
          typeConfirm:    true,
        },
      })
        .afterClosed();
      return confirm$.pipe(concatMap((confirm: boolean) => {
        if (!confirm) {
          return of({ error: null, message: null, number: null });
        }
        return this.numberService.patchNumber$(req);
      }));
    }),
    withLatestFrom(this.store.select(selectNumber)),
    tap(([_, numberState]: [PatchNumberResponse, NumberState]) => {
      const rangeId    = numberState.selectedNumberRange?.id;
      const locationId = numberState.selectedLocation?.id;

      setTimeout(() => {
        const isViewingRange = this.location.path()
          .includes('/range');

        if ((isViewingRange && !rangeId) || (!isViewingRange && !locationId)) {
          return;
        }

        this.store.dispatch(FetchBulkCountByAssignedRequestAction({
          ids:   [isViewingRange ? rangeId : locationId],
          token: numberState.numberQueryParams.token,
          mode:  isViewingRange ? 'RANGE' : 'LOCATION',
        }));
      }, 500);

    }),
    map(([res, _]) => res),
    map((res: PatchNumberResponse) => NumberLocationRemoveResponseAction(res)),
  ));

  deleteNumberTagRefresh$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteNumberTagResponseAction),
    debounceTime(1_000),
    tap(() => {
      this.store.dispatch(FetchNumberTagsRequestAction(null));
    }),
  ), { dispatch: false });

  deleteRangeRefresh$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteNumberRangeResponseAction),
    debounceTime(1_000),
    tap(() => {
      this.store.dispatch(FetchNumberRangeListRequestAction(null));
      this.store.dispatch(FetchNumbersServiceMetadataRequestAction({}));
      this.store.dispatch(FetchNumberCountsRequestAction({}));
    }),
  ), { dispatch: false });

  fetchNumberCounts$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNumberCountsRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    switchMap((req: FetchNumberCountsRequest) => this.numberService.fetchNumberCounts$(req)),
    map((res: FetchNumberCountsResponse) => FetchNumberCountsResponseAction(res)),
  ));

  fetchBulkCountByAssigned$ = createEffect(() => this.actions$.pipe(
    ofType(FetchBulkCountByAssignedRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    switchMap((req: FetchBulkCountByAssignedRequest) => this.numberService.fetchBulkCountByAssigned$(req)),
    map((res: FetchBulkCountByAssignedResponse) => FetchBulkCountByAssignedResponseAction(res)),
  ));

  fetchLocations$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLocationsRequestAction),
    withLatestFrom(this.store.select(selectLocationQueryParams)),
    switchMap(([req, queryParams]: [FetchLocationsRequest, LocationQueryParams]) =>
      this.numberService.fetchLocations$({ ...(req || {}), queryParams: req.queryParams || queryParams })),
    map((res: FetchLocationsResponse) => FetchLocationsResponseAction(res)),
  ));

  fetchLocation$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLocationRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    switchMap((req: FetchLocationRequest) => this.numberService.fetchLocation$(req)),
    map((res: FetchLocationResponse) => FetchLocationResponseAction(res)),
  ));

  fetchNumber$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNumberRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    switchMap((req: FetchNumberRequest) =>
      this.numberService.fetchNumber$(req)
        .pipe(
          map((res: FetchNumberResponse) =>
            ActionTypes.FetchNumberResponseAction(res),
          ),
        )),
  ));

  fetchInventoryArea$ = createEffect(() => this.actions$.pipe(
    ofType(FetchInventoryAreaListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyRead]),
    switchMap((req: FetchInventoryAreaListRequest) => this.numberService.fetchInventoryAreaList$(req)),
    map((res: FetchInventoryAreaListResponse) => FetchInventoryAreaListResponseAction(res)),
  ));

  fetchNumberList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNumberListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    withThrottle(500, { leading: true, trailing: true }),
    tap(() => this.killNumberListPoll.next()),
    withLatestFrom(this.store.select(selectNumberQueryParams)),
    switchMap(([req, queryParams]: [FetchNumberListRequest, NumberQueryParams]) =>
      this.numberService.fetchNumberList$({
        queryParams,
        ...req,
      })
        .pipe(
          tap((res: FetchNumberListResponse) => {
            if (!res?.models?.some(m => m.isProcessing)) {
              return;
            }
            return interval(5_000)
              .pipe(
                withLatestFrom(this.store.select(selectNumber)),
                map(([_, numbers]: [number, NumberState]) => ({
                  numbers:     numbers.numbers,
                  queryParams: numbers.numberQueryParams,
                })),
                takeUntil(this.killNumberListPoll$))
              .subscribe(({ queryParams }: { queryParams: NumberQueryParams }) =>
                this.store.dispatch(FetchNumberListRequestAction({ queryParams, emitEvent: false })));
          }),
          map((res: FetchNumberListResponse) =>
            ActionTypes.FetchNumberListResponseAction(res),
          ),
        ),
    ),
  ));

  fetchNumberTagsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNumberTagsRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    withLatestFrom(this.store.select(selectNumberTagsQueryParams)),
    switchMap(([req, query]: [FetchNumberTagsRequest, NumberTagQueryParams]) =>
      this.numberService.fetchNumberTags$({ ...req, queryParams: req.queryParams || query })
        .pipe(
          map((res: FetchNumberTagsResponse) =>
            ActionTypes.FetchNumberTagsResponseAction(res),
          ),
        ),
    ),
  ));

  fetchAllNumberTagsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAllNumberTagsRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    switchMap(() =>
      this.numberService.fetchNumberTags$({ queryParams: { pageSize: 1000, pageNumber: 1 } })
        .pipe(
          map((res: FetchNumberTagsResponse) =>
            ActionTypes.FetchAllNumberTagsResponseAction(res),
          ),
        ),
    ),
  ));

  checkLocationNameAvailable$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLocationNameAvailabilityRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    switchMap(
      (req: FetchLocationNameAvailabilityRequest) =>
        this.numberService.fetchLocationNameAvailable$(req)
          .pipe(
            map((res: FetchLocationNameAvailabilityResponse) => {
              return ActionTypes.FetchLocationNameAvailabilityResponseAction(res);
            }),
          ),
    ),
  ));

  patchLocation$ = createEffect(() => this.actions$.pipe(
    ofType(PatchLocationRequestAction),
    switchMap((req: PatchLocationRequest) => this.numberService.patchLocation$(req)),
    map((res: PatchLocationResponse) => PatchLocationResponseAction(res)),
  ));

  postLocation$ = createEffect(() => this.actions$.pipe(
    ofType(PostLocationRequestAction),
    switchMap((req: PostLocationRequest) => this.numberService.postLocation$(req)),
    map((res: PostLocationResponse) => PostLocationResponseAction(res)),
  ));

  patchNumberTag$ = createEffect(() => this.actions$.pipe(
    ofType(PatchNumberTagRequestAction),
    switchMap((req: PatchNumberTagRequest) => this.numberService.patchNumberTag$(req)),
    map((res: PatchNumberTagResponse) => ActionTypes.PatchNumberTagResponseAction(res)),
  ));

  patchNumberTagRefresh$ = createEffect(() => this.actions$.pipe(
    ofType(PatchNumberTagResponseAction),
    debounceTime(1_000),
    tap(() => {
      this.store.dispatch(FetchNumberTagsRequestAction(null));
    }),
  ), { dispatch: false });

  deleteNumberTag$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteNumberTagRequestAction),
    switchMap((req: DeleteNumberTagRequest) => {
      return this.dialog.open(ConfirmModalComponent, {
        panelClass: 'cr-dialog',
        maxWidth:   '700px',
        data:       {
          title:          `Delete ${ req.name }`,
          content:        `<p>You are about to delete ${ req.name }. Click 'delete' to continue with the deletion.</p>`,
          confirmBtnText: 'Delete',
          showCancel:     true,
        },
      })
        .afterClosed()
        .pipe(concatMap((confirm: boolean) => {
          if (!confirm) {
            return of({ error: null, message: null, id: null });
          }
          return this.numberService.deleteNumberTag$(req);
        }));
    }),
    map((res: DeleteNumberTagResponse) => ActionTypes.DeleteNumberTagResponseAction(res)),
  ));

  patchNumber$ = createEffect(() => this.actions$.pipe(
    ofType(PatchNumberRequestAction))
    .pipe(
      switchMap((req: PatchNumberRequest) =>
        this.numberService.patchNumber$(req)
          .pipe(
            concatMap((res: PatchNumberResponse) => {
              if (!res.number?.locationId) {
                return of(res);
              }
              return this.numberService.fetchNumberLocations$([res.number.locationId])
                .pipe(
                  map((location: Location[]) => {
                    res.number.location = location?.[0];
                    return res;
                  }),
                );
            }),
            map((res: PatchNumberResponse) =>
              ActionTypes.PatchNumberResponseAction(res),
            ),
          ),
      ),
    ),
  );

  patchNumberRefresh$ = createEffect(() => this.actions$.pipe(
    ofType(PatchNumberResponseAction),
    debounceTime(1_000),
    tap(() => {
      this.store.dispatch(FetchAllNumberTagsRequestAction(null));
    })), { dispatch: false });

  patchNumberRange$ = createEffect(() => this.actions$.pipe(
    ofType(PatchNumberRangeRequestAction),
    switchMap((req: PatchNumberRangeRequest) =>
      this.numberService.patchNumberRange$(req)
        .pipe(
          map((res: PatchNumberRangeResponse) =>
            ActionTypes.PatchNumberRangeResponseAction(res),
          ),
        ),
    ),
  ));

  patchNumberRangeRefresh$ = createEffect(() => this.actions$.pipe(
    ofType(PatchNumberRangeResponseAction),
    debounceTime(1_000),
    tap((res: PatchNumberRangeResponse) => {
      if (res.numberRange.status === NumberRangeStatus.Deleted) {
        this.router.navigate(['/numbers']);
      } else {
        this.store.dispatch(FetchNumberListRequestAction({}));
      }
    }),
  ), { dispatch: false });

  assignInboundNumber$ = createEffect(() => this.actions$.pipe(
    ofType(AssignInboundNumberRequestAction),
    concatMap((req: AssignInboundNumberRequest): Observable<AssignInboundNumberRequest> => {
      if (!req.confirm) {
        return of(req);
      }
      const modalText = `<p>You are about to remove all assigned routes for ${ req.num }. Click 'confirm' to continue with the un-assignment.</p>`;
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:          `Un-assign all routes`,
          content:        modalText,
          confirmBtnText: 'Confirm',
          showCancel:     true,
          typeConfirm:    false,
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(map((confirmed: boolean) => {
          if (!confirmed) {
            return null;
          }
          return req;
        }));
    }),
    filter(req => !!req),
    switchMap((req: AssignInboundNumberRequest) =>
      this.numberService.assignInboundNumber$(req)
        .pipe(
          map((res: AssignInboundNumberResponse) =>
            ActionTypes.AssignInboundNumberResponseAction(res),
          ),
        ),
    ),
  ));

  assignInboundNumberResponse$ = createEffect(() => this.actions$.pipe(
    ofType(AssignInboundNumberResponseAction),
    withLatestFrom(this.store.select(selectNumber)),
    tap(([_, numberState]: [AssignInboundNumberResponse, NumberState]) => {
      const rangeId    = numberState.selectedNumberRange?.id;
      const locationId = numberState.selectedLocation?.id;

      setTimeout(() => {
        this.store.dispatch(FetchNumberListRequestAction({
          queryParams: numberState.numberQueryParams,
          emitEvent:   false,
        }));
        this.store.dispatch(FetchNumbersServiceMetadataRequestAction({}));

        const isViewingRange = this.location.path()
          .includes('/range');

        if ((isViewingRange && !rangeId) || (!isViewingRange && !locationId)) {
          return;
        }

        this.store.dispatch(FetchBulkCountByAssignedRequestAction({
          ids:   [isViewingRange ? rangeId : locationId],
          token: numberState.numberQueryParams.token,
          mode:  isViewingRange ? 'RANGE' : 'LOCATION',
        }));
      }, 500);
    }),
  ), { dispatch: false });

  fetchProviderList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchInventoryProviderRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyRead]),
    switchMap(() => this.numberService.fetchInventoryProviders$()
      .pipe(map(
        (res: FetchInventoryProvidersResponse) =>
          FetchInventoryProviderResponseAction(res),
      ))),
  ));

  removeNumberReservation$ = createEffect(() => this.actions$.pipe(
    ofType(NumberReservationRemoveRequestAction),
    switchMap((req: NumberReservationRemoveRequest) => this.numberService.removeNumberReservation$(req)),
    withLatestFrom(this.store.select(selectNumber)),
    tap(([_, numberState]: [NumberReservationRemoveResponse, NumberState]) => {
      const rangeId    = numberState.selectedNumberRange?.id;
      const locationId = numberState.selectedLocation?.id;

      setTimeout(() => {
        this.store.dispatch(FetchNumberListRequestAction({
          queryParams: numberState.numberQueryParams,
          emitEvent:   false,
        }));
        this.store.dispatch(FetchNumbersServiceMetadataRequestAction({}));

        const isViewingRange = this.location.path()
          .includes('/range');

        if ((isViewingRange && !rangeId) || (!isViewingRange && !locationId)) {
          return;
        }

        this.store.dispatch(FetchBulkCountByAssignedRequestAction({
          ids:   [isViewingRange ? rangeId : locationId],
          token: numberState.numberQueryParams?.token,
          mode:  isViewingRange ? 'RANGE' : 'LOCATION',
        }));
      }, 1_000);
    }),
    map(([res, _]: [NumberReservationRemoveResponse, NumberState]) => NumberReservationRemoveResponseAction(res)),
  ));

  bulkProvision$ = createEffect(() => this.actions$.pipe(
    ofType(BulkProvisionRequestAction),
    switchMap((req) => this.numberService.bulkProvision$(req)
      .pipe(
        map(
          (res: BulkProvisionResponse) =>
            BulkProvisionResponseAction(res),
        ))),
  ));

  deleteNumber$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteNumberRequestAction),
    switchMap((req: DeleteNumberRequest) => {
      return this.dialog.open(ConfirmModalComponent, {
        panelClass: 'cr-dialog',
        maxWidth:   '700px',
        data:       {
          title:          `Delete ${ req.number }`,
          content:        `<p>You are about to delete ${ req.number } and all inbound assignments. Click 'delete' to continue with the deletion.</p>`,
          confirmBtnText: 'Delete',
          showCancel:     true,
        },
      })
        .afterClosed()
        .pipe(concatMap((confirm: boolean) => {
          if (!confirm) {
            return of({ error: null, message: null, id: null });
          }
          return this.numberService.deleteNumber$(req);
        }));
    }),
    tap((res: DeleteNumberResponse) => {
      if (res.error) {
        return;
      }
      this.store.dispatch(FetchNumbersServiceMetadataRequestAction({}));
    }),
    map((res: DeleteNumberResponse) => DeleteNumberResponseAction(res)),
  ));

  bulkNumberUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(BulkNumberUpdateRequestAction),
    switchMap((req: BulkNumberUpdateRequest) => this.numberService.bulkNumberUpdate$(req)),
    map((res: BulkNumberUpdateResponse) => BulkNumberUpdateResponseAction(res)),
    tap(() => setTimeout(() => this.store.dispatch(FetchNumberListRequestAction({})), 2_000)),
  ));

  fetchNumbersServiceMetadata$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNumbersServiceMetadataRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NumberRead]),
    switchMap(() => this.numberService.fetchNumbersServiceMetadata$()),
    map((res: FetchNumbersServiceMetadataResponse) => FetchNumbersServiceMetadataResponseAction(res)),
  ));

  exportNumbers$ = createEffect(() => this.actions$.pipe(
    ofType(ExportNumbersRequestAction),
    switchMap((req: ExportNumbersRequest) => this.numberService.exportNumbers$(req)),
    tap((res: ExportNumbersResponse) => {
      if (res.taskId) {
        this.store.dispatch(FetchTaskRequestAction({ id: res.taskId }));
      }
    }),
    map((res: ExportNumbersResponse) => ExportNumbersResponseAction(res)),
  ));

  importNumbers$ = createEffect(() => this.actions$.pipe(
    ofType(ImportNumbersRequestAction),
    switchMap((req: ImportNumbersRequest) => this.numberService.importNumbers$(req)),
    tap((res: ImportNumbersResponse) => {
      if (res.taskId) {
        this.store.dispatch(FetchTaskRequestAction({ id: res.taskId }));
      }
    }),
    map((res: ImportNumbersResponse) => ImportNumbersResponseAction(res)),
  ));

  fetchNumberCompatibility$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNumberCompatibilityRequestAction),
    switchMap((req: FetchNumberCompatibilityRequest) => this.numberService.fetchNumberCompatibility$(req)
      .pipe(switchMap(res => {
        if (res.error || !res.number_ids.length) {
          return of(res);
        }
        return this.numberService.fetchNumberList$({
          queryParams: {
            id:         res.number_ids,
            pageNumber: 1,
            pageSize:   res.number_ids.length,
          },
        })
          .pipe(map(listResponse => {
            return {
              ...res,
              numbers: res.number_ids.map(id => listResponse.models.find(m => m.id === id))
                         .filter(num => !!num),
            } as FetchNumberCompatibilityResponse;
          }));
      }))),
    map((res: FetchNumberCompatibilityResponse) => FetchNumberCompatibilityResponseAction(res)),
  ));

  fetchRangeCountByExhaustionStatus$ = createEffect(() => this.actions$.pipe(
    ofType(FetchRangeCountByExhaustionStatusRequestAction),
    switchMap(() => this.numberService.fetchRangeCountByExhaustionStatus$()),
    map((res: FetchRangeCountByExhaustionStatusResponse) => FetchRangeCountByExhaustionStatusResponseAction(res)),
  ));

  ngOnDestroy(): void {
    this.killNumberListPoll.next();
  }

  private pollNumberRanges(): void {
    interval(10_000)
      .pipe(takeUntil(this.killNumberRangeListPoll$))
      .subscribe(() => {
        this.store.dispatch(FetchNumberRangeListRequestAction(null));
      });
  }
}
