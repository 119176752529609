<div class="header background-orange shadow-soft" fxLayout="row" fxLayoutAlign="space-between center"
     *ngIf="show">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon svgIcon="cr-circle-exclamation" class="mr-12" *ngIf="!helpUri"></mat-icon>
    <span class="mt-2">{{bannerText}}</span>
  </div>
  <a href="{{helpUri}}" target="_blank" *ngIf="helpUri" matTooltip="{{helpAltText}}">
    <button mat-icon-button class="ml-16 color-white">
      <mat-icon svgIcon="cr-info-outline"></mat-icon>
    </button>
  </a>
  <button mat-icon-button (click)="closeBanner()" class="ml-16">
    <mat-icon svgIcon="cr-close"></mat-icon>
  </button>
</div>
