<div class="notification shadow-md" [style.top]="isViewingCompany ? '56px': '12px'" fxLayout="row"
     fxLayoutAlign="start center" (click)="$event.stopPropagation()">
  <mat-icon class="notification__icon color-white" svgIcon="cr-bell-exclamation"></mat-icon>
  <mat-divider [vertical]="true" class="notification__divider"></mat-divider>
  <ng-container *ngIf="notification.data?.help_uri; else noLink">
    <a href="{{notification.data.help_uri}}" target="_blank" fxLayout="row"
       class="notification__message"
       fxLayoutAlign="start center">
      <ng-container *ngIf="true; then inner"></ng-container>
    </a>
  </ng-container>
  <ng-template #noLink>
    <div class="notification__message" fxLayout="row" fxLayoutAlign="start center">
      <ng-container *ngIf="true; then inner"></ng-container>
    </div>
  </ng-template>
  <ng-template #inner>
    <p class="mat-body-1 color-white mb-0" fxLayout="row" fxLayoutAlign="start center">
      <span>{{notification.message}}</span>
      <a href="{{notification.data.help_uri}}" *ngIf="notification.data?.help_uri" target="_blank"
         class="ml-16 mb-2">
        <button mat-icon-button matTooltip="See more information">
          <mat-icon svgIcon="cr-help-outline" class="color-white"></mat-icon>
        </button>
      </a>
    </p>
  </ng-template>
  <div class="notification__action" fxLayout="row" fxLayoutAlign="center center">
    <button mat-icon-button class="button" type="button"
            (click)="handleCloseNotification($event)">
      <mat-icon svgIcon="cr-close" class="color-white"></mat-icon>
    </button>
  </div>
</div>

