import { Injectable }      from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskManagerService {
  toggle  = new BehaviorSubject<boolean>(false);
  toggle$ = this.toggle.asObservable();

  constructor() { }
}
