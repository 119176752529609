import { CallCostSummaryByBandRaw } from '@models/api/reporting/call-cost-summary-by-band-raw.model';
import { ChartSeries }              from '@models/chart/chart-series.model';

export class CallCostSummaryByBandData {
  counts: CallCostSummaryByBandRaw;
  total: number;

  constructor(private data?: CallCostSummaryByBandData) {
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  fromApiData?(apiData: CallCostSummaryByBandRaw): CallCostSummaryByBandData {
    this.counts = apiData;
    this.total  = Object.values(apiData)
      .reduce((a, b) => a + b.total, 0);

    return this;
  }

  toChartSeries?(): ChartSeries[] {
    if (!this.counts) {
      return null;
    }
    return Object.keys(this.counts)
      .map(key =>
        ({ name: this.counts[key].description, value: this.counts[key].total }));
  }
}
