<mat-list-item class="item" attr.data-cy="task-id-{{task.id}}">
  <mat-icon mat-list-icon svgIcon="cr-microsoft-teams"></mat-icon>
  <div mat-line fxLayout="row" fxLayoutAlign="space-between center" class="mb-4">
    <p class="mat-body-1 fw-500 mb-0">{{task.getName()}}</p>
    <span class="mat-body-1 fw-500 color-gray" data-cy="task-status">{{status}}</span>
  </div>
  <div mat-line fxLayout="column" data-cy="task-step" *ngFor="let s of task.summary; trackBy: trackBy">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span class="mat-body-1--smaller mr-8 color-light-gray" data-cy="task-step-name">{{s.name}}</span>
        <mat-icon svgIcon="cr-warning" data-cy="task-failure" *ngIf="getFailures(s.name) as failures"
                  class="item__warning item__warning--failure"
                  matTooltip="{{failures}}"
                  matTooltipClass="multi-line"></mat-icon>
        <mat-icon svgIcon="cr-info-outline" data-cy="task-info" *ngIf="getInfo(s.name) as info"
                  class="item__warning item__warning--info"
                  matTooltip="{{info}}"
                  matTooltipClass="multi-line"></mat-icon>
        <mat-icon svgIcon="cr-warning-no-color" data-cy="task-warning" *ngIf="getWarnings(s.name) as warnings"
                  class="item__warning color-orange"
                  matTooltip="{{warnings}}"
                  matTooltipClass="multi-line"></mat-icon>
        <ng-container *ngIf="showDebug">
          <mat-icon svgIcon="cr-exclamation" *ngIf="getDebugWarnings(s.name) as debug"
                    class="item__warning"
                    matTooltip="{{debug}}"
                    matTooltipClass="multi-line"></mat-icon>
        </ng-container>
      </div>
      <span>
        <ng-container *ngIf="getDescription(s.description) as description">
           <span class="mat-body-1--smaller color-light-gray">{{description}}</span>&nbsp;&middot;&nbsp;
        </ng-container>
        <span class="mat-body-1--smaller color-gray fw-600" *ngIf="s.totalCount !== null">{{getProgress(s)}}%</span>
      </span>
    </div>
    <mat-progress-bar [value]="getProgress(s)" class="item__progress__bar" data-cy="progress-bar"
                      [color]="task.getBarColor()"></mat-progress-bar>
  </div>
  <div mat-line>
    <span
      class="mat-body-1--smaller mt-16 color-light-gray"><span
      class="color-near-black">Last updated:</span> {{(task.activityDate || task.updatedDate) | localeDate:'medium'}}</span>
  </div>
  <div mat-line fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end center">
    <button mat-button
            *ngIf="!isIncomplete && hasExportedReport"
            [routerLink]="['/reporting', 'reports']"
            (click)="close.emit()"
            [disabled]="!hasReportRead"
            queryParamsHandling="preserve"
            fxLayout="row" fxLayoutAlign="start center"
            matTooltip="You do not have the required access to view exported reports."
            [matTooltipDisabled]="hasReportRead"
    >
      <mat-icon svgIcon="cr-view"></mat-icon>
      <span class="ml-6">View reports</span>
    </button>
    <button mat-button
            *ngIf="isIncomplete"
            (click)="cancel.emit({id: task.id, name: task.getName()})"
            fxLayout="row" fxLayoutAlign="start center"
            [disabled]="cancelPending || !canCancel"
            matTooltip="You do not have the required access to cancel this task."
            [matTooltipDisabled]="canCancel"
    >
      <mat-icon svgIcon="cr-cancel-filled"></mat-icon>
      <span class="ml-6">Cancel</span>
    </button>
  </div>
</mat-list-item>
