<ng-container *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <ngx-modal-head headerIcon="cr-settings" header="Provisioning settings"></ngx-modal-head>
    <ngx-modal-content>
      <div fxLayout="column">
        <div class="mb-24">
          <div class="mt-16">
            <mat-slide-toggle class="mat-body-1 color-near-black" color="primary" labelPosition="after"
                              formControlName="autoProvision">
              Auto-provisioning enabled
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </ngx-modal-content>
    <ngx-modal-actions>
      <ngx-cr-button text="Save" [disabled]="!(hasCompanyConfigurationWrite$ | async)"
                     matTooltip="{{saveTooltip$ | async}}" [matTooltipDisabled]="!(saveTooltip$ | async)"
                     (onClick)="done()"></ngx-cr-button>
    </ngx-modal-actions>
  </form>
</ng-container>
