import { Injectable }                                                                   from '@angular/core';
import { Store }                                                                        from '@ngrx/store';
import { Actions, createEffect, ofType }                                                from '@ngrx/effects';
import { concatMap, filter, map, switchMap, takeUntil, takeWhile, tap, withLatestFrom } from 'rxjs/operators';

import { CompanyService } from '@services/company.service';
import { RouterService }  from '@services/router.service';

import * as ActionTypes from './company.actions';
import {
  DeleteUserRequestAction,
  FetchCompanyNameAvailabilityRequestAction,
  FetchCompanyRequestAction,
  FetchCompanyResponseAction,
  FetchCompanySubordinateListRequestAction,
  FetchCompanyUserListRequestAction,
  FetchCompanyUsersCountRequestAction,
  FetchRoleUsersListRequestAction,
  FetchSecurityGroupUsersListRequestAction,
  PatchCompanyRequestAction,
  PostCompanyRequestAction,
  PostCompanyResponseAction,
  PostCompanyUserRequestAction,
  PostCompanyUserResponseAction,
}                       from './company.actions';

import { StoreState }                           from '../store';
import { FetchUserProfileRequestAction }        from '../auth/auth.actions';
import { FetchCompanyNameAvailabilityRequest }  from '@models/api/fetch-company-name-availability-request.model';
import { FetchCompanyNameAvailabilityResponse } from '@models/api/fetch-company-name-availability-response.model';
import { PostCompanyUserRequest }               from '@models/api/post-company-user.request';
import { PostCompanyUserResponse }              from '@models/api/post-company-user.response';
import { FetchCompanyUserListResponse }         from '@models/api/fetch-company-user-list.response';
import { FetchCompanyResponse }                 from '@models/api/fetch-company.response';
import { FetchCompanySubordinateListResponse }  from '@models/api/fetch-company-subordinate-list.response';
import { FetchCompanySubordinateListRequest }   from '@models/api/fetch-company-subordinate-list.request';
import { FetchCompanyUserListRequest }          from '@models/api/fetch-company-user-list.request';
import { FetchCompanyRequest }                  from '@models/api/fetch-company.request';
import { PostCompanyResponse }                  from '@models/api/post-company.response';
import { PostCompanyRequest }                   from '@models/api/post-company.request';
import { withThrottle }                         from '@rxjs/action-throttle.operator';
import { splitURLByPath }                       from '@util/url.helper';
import { Location }                             from '@angular/common';
import { FetchRoleUsersRequest }                from '@models/api/fetch-role-users-request.model';
import { FetchRoleUsersResponse }               from '@models/api/fetch-role-users-response.model';
import { selectUserQueryParams, selectUsers }   from '@redux/company/company.selectors';
import { UserQueryParams }                      from '@models/form/user-query-params.model';
import { withScopes }                           from '@rxjs/with-scopes.operator';
import { selectUserScopes }                     from '@redux/auth/auth.selectors';
import { AuthScope }                            from '@enums/auth-scope.enum';
import { DeleteUserRequest }                    from '@models/api/delete-user-request.model';
import { ConfirmModalComponent }                from '@dialog/general/confirm-modal/confirm-modal.component';
import { Observable, of, Subject, timer }       from 'rxjs';
import { MatDialog }                            from '@angular/material/dialog';
import { PatchCompanyRequest }                  from '@models/api/patch-company-request.model';
import { PatchCompanyResponse }                 from '@models/api/patch-company-response.model';
import { FetchCompanyUserCountsResponse }       from '@models/api/fetch-company-user-counts-response.model';
import { DeleteUserResponse }                   from '@models/api/delete-user-response.model';
import { User }                                 from '@models/entity/user.model';
import { UserStatus }                           from '@enums/user-status.enum';
import {
  FetchSecurityGroupUsersListRequest,
}                                               from '@models/api/identity/fetch-security-group-users-list-request.model';
import {
  FetchSecurityGroupUsersListResponse,
}                                               from '@models/api/identity/fetch-security-group-users-list-response.model';

@Injectable()
export class CompanyEffects {
  private static shouldPollUserItems(userItems: User[]): boolean {
    return userItems?.some(user => user.status === UserStatus.Deleted);
  }

  killUserPoll                    = new Subject<void>();
  killUserPoll$: Observable<void> = this.killUserPoll.asObservable();


  constructor(
    private location: Location,
    private actions$: Actions,
    private companyService: CompanyService,
    private router: RouterService,
    private dialog: MatDialog,
    private store: Store<StoreState>,
  ) {}

  checkCompanyNameAvailable$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCompanyNameAvailabilityRequestAction),
    withThrottle(),
    switchMap(
      (req: FetchCompanyNameAvailabilityRequest) =>
        this.companyService.fetchCompanyNameAvailable$(req)
          .pipe(
            map((res: FetchCompanyNameAvailabilityResponse) => {
              return ActionTypes.FetchCompanyNameAvailabilityResponseAction(res);
            }),
          ),
    ),
  ));


  postCompany$ = createEffect(() => this.actions$.pipe(
    ofType(PostCompanyRequestAction),
    switchMap((req: PostCompanyRequest) =>
      this.companyService.postCompany$(req)
        .pipe(
          map((res: PostCompanyResponse) => {
            return ActionTypes.PostCompanyResponseAction(res);
          }),
        ),
    ),
  ));

  patchCompany$ = createEffect(() => this.actions$.pipe(
    ofType(PatchCompanyRequestAction),
    switchMap((req: PatchCompanyRequest) =>
      this.companyService.patchCompany$(req)
        .pipe(
          map((res: PatchCompanyResponse) => {
            return ActionTypes.PatchCompanyResponseAction(res);
          }),
        ),
    ),
    tap(() => this.store.dispatch(FetchUserProfileRequestAction({}))),
  ));

  postCompanyResponse$ = createEffect(() => this.actions$.pipe(
    ofType(PostCompanyResponseAction),
    filter((req: PostCompanyResponse) => !!req.email),
    map((req: PostCompanyResponse) => {
      this.store.dispatch(FetchUserProfileRequestAction({}));
      return req.shouldNavigate && this.router.navigate(['/overview']);
    }),
  ), { dispatch: false });

  fetchCompany$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCompanyRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyRead]),
    switchMap((req: FetchCompanyRequest) =>
      this.companyService.fetchCompany$(req)
        .pipe(
          map((res: FetchCompanyResponse) => {
            return ActionTypes.FetchCompanyResponseAction(res);
          }),
        ),
    ),
  ));

  fetchCompanyRedirect$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCompanyResponseAction),
    tap((res: FetchCompanyResponse) => {
      const companyId = splitURLByPath(this.location.path()).queryParams?.company;
      if ([403, 400].includes(res.error?.code) && companyId === res.companyId) {
        return this.router.navigate(['/customers'], { company: null }, { queryParamsHandling: 'merge' });
      }
    }),
  ), { dispatch: false });

  fetchRoleUserList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchRoleUsersListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.UserRoleRead]),
    switchMap((req: FetchRoleUsersRequest) =>
      this.companyService.fetchCompanyUserList$({ queryParams: { role: req.id, pageSize: 100, pageNumber: 1 } })
        .pipe(
          map((res: FetchRoleUsersResponse) => {
            return ActionTypes.FetchRoleUsersListResponseAction(res);
          }),
        ),
    ),
  ));

  fetchSecurityGroupUserList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchSecurityGroupUsersListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.UserRoleRead]),
    switchMap((req: FetchSecurityGroupUsersListRequest) =>
      this.companyService.fetchCompanyUserList$({
        queryParams: {
          securityGroupIds: [req.id],
          pageSize:         100,
          pageNumber:       1,
        },
      })
        .pipe(
          map((res: FetchSecurityGroupUsersListResponse) => {
            return ActionTypes.FetchSecurityGroupUsersListResponseAction(res);
          }),
        ),
    ),
  ));

  fetchCompanyUserList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCompanyUserListRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.CompanyRead, AuthScope.UserRead], { role: [AuthScope.UserRoleRead] }),
    withLatestFrom(this.store.select(selectUserQueryParams)),
    switchMap(([req, queryParams]: [FetchCompanyUserListRequest, UserQueryParams]) => {
        const defaultIncludes: string[] = ['roles', 'idp_identities'];
        return this.companyService.fetchCompanyUserList$({ queryParams: { include: defaultIncludes, ...queryParams }, ...req });
      },
    ),
    map((res: FetchCompanyUserListResponse) => {
      return ActionTypes.FetchCompanyUserListResponseAction(res);
    }),
  ));

  fetchCompanyUserCounts$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCompanyUsersCountRequestAction),
    switchMap(() =>
      this.companyService.fetchCompanyUserCounts$()
        .pipe(
          map((res: FetchCompanyUserCountsResponse) => {
            return ActionTypes.FetchCompanyUsersCountResponseAction(res);
          }),
        ),
    ),
  ));

  postCompanyUser$ = createEffect(() => this.actions$.pipe(
    ofType(PostCompanyUserRequestAction),
    switchMap((req: PostCompanyUserRequest) =>
      this.companyService.postCompanyUser$(req)
        .pipe(
          map((res: PostCompanyUserResponse) => {
            return ActionTypes.PostCompanyUserResponseAction(res);
          }),
        ),
    ),
  ));

  postCompanyUserResponse$ = createEffect(() => this.actions$.pipe(
    ofType(PostCompanyUserResponseAction),
    withLatestFrom(this.store),
    map(([req, storeState]: [PostCompanyUserResponse, StoreState]): void => {
      if (!req.error && storeState.authReducer && storeState.authReducer.user) {
        this.store.dispatch(ActionTypes.FetchCompanyUserListRequestAction(null));
      }
    }),
  ), { dispatch: false });

  fetchCompanySubordinateList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCompanySubordinateListRequestAction),
    switchMap((req: FetchCompanySubordinateListRequest) =>
      this.companyService.fetchCompanySubordinateList$(req)
        .pipe(
          map((res: FetchCompanySubordinateListResponse) => ActionTypes.FetchCompanySubordinateListResponseAction(res)),
        ),
    ),
  ));

  deleteUser$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteUserRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.UserWrite]),
    switchMap((req: DeleteUserRequest) => {
      return this.dialog.open(ConfirmModalComponent, {
        panelClass: 'cr-dialog',
        minWidth:   '40vw',
        data:       {
          title:          `Deleting ${ req.name }`,
          content:        'Are you sure? This cannot be undone.',
          confirmBtnText: 'Delete',
          typeConfirm:    true,
          showCancel:     false,
        },
      })
        .afterClosed()
        .pipe(
          concatMap(confirm => {
            if (!confirm) {
              return of(ActionTypes.DeleteUserResponseAction(null));
            }
            return this.companyService.deleteUser$(req)
              .pipe(
                tap(() => {
                  this.store.dispatch(FetchCompanyUserListRequestAction({}));
                  this.pollUserItems();
                }),
                map((res: DeleteUserResponse) =>
                  ActionTypes.DeleteUserResponseAction(res)),
              );
          }),
        );
    }),
  ));

  private pollUserItems(): void {
    timer(10_000)
      .pipe(
        takeUntil(this.killUserPoll$),
        withLatestFrom(
          this.store.select(selectUsers),
        ),
        map(([_, userList]: [number, User[]]) =>
          (userList as User[])),
        takeWhile((userList: User[]) => CompanyEffects.shouldPollUserItems(userList)))
      .subscribe(() => {
        this.store.dispatch(FetchCompanyUserListRequestAction({}));
      });
  }
}
