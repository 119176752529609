export const FETCH_TOPUP_ENABLED_REQUEST  = 'FETCH_TOPUP_ENABLED_REQUEST';
export const FETCH_TOPUP_ENABLED_RESPONSE = 'FETCH_TOPUP_ENABLED_RESPONSE';

export const FETCH_CURRENCY_CODE_REQUEST  = 'FETCH_CURRENCY_CODE_REQUEST';
export const FETCH_CURRENCY_CODE_RESPONSE = 'FETCH_CURRENCY_CODE_RESPONSE';

export const FETCH_TRIAL_IDENTIFIER_REQUEST  = 'FETCH_TRIAL_IDENTIFIER_REQUEST';
export const FETCH_TRIAL_IDENTIFIER_RESPONSE = 'FETCH_TRIAL_IDENTIFIER_RESPONSE';

export const FETCH_CALL_RECORDING_ALLOWED_REQUEST  = 'FETCH_CALL_RECORDING_ALLOWED_REQUEST';
export const FETCH_CALL_RECORDING_ALLOWED_RESPONSE = 'FETCH_CALL_RECORDING_ALLOWED_RESPONSE';

export const FETCH_CALL_RECORDING_ENABLED_REQUEST  = 'FETCH_CALL_RECORDING_ENABLED_REQUEST';
export const FETCH_CALL_RECORDING_ENABLED_RESPONSE = 'FETCH_CALL_RECORDING_ENABLED_RESPONSE';

export const TOGGLE_CALL_RECORDING_REQUEST  = 'TOGGLE_CALL_RECORDING_REQUEST';
export const TOGGLE_CALL_RECORDING_RESPONSE = 'TOGGLE_CALL_RECORDING_RESPONSE';

export const FETCH_CALL_RECORDING_ANALYSIS_ENABLED_REQUEST  = 'FETCH_CALL_RECORDING_ANALYSIS_ENABLED_REQUEST';
export const FETCH_CALL_RECORDING_ANALYSIS_ENABLED_RESPONSE = 'FETCH_CALL_RECORDING_ANALYSIS_ENABLED_RESPONSE';

export const TOGGLE_CALL_RECORDING_ANALYSIS_REQUEST  = 'TOGGLE_CALL_RECORDING_ANALYSIS_REQUEST';
export const TOGGLE_CALL_RECORDING_ANALYSIS_RESPONSE = 'TOGGLE_CALL_RECORDING_ANALYSIS_RESPONSE';

export const FETCH_LOW_BALANCE_THRESHOLD_DEFAULT_REQUEST  = 'FETCH_LOW_BALANCE_THRESHOLD_DEFAULT_REQUEST';
export const FETCH_LOW_BALANCE_THRESHOLD_DEFAULT_RESPONSE = 'FETCH_LOW_BALANCE_THRESHOLD_DEFAULT_RESPONSE';

export const FETCH_LOW_BALANCE_THRESHOLD_REQUEST  = 'FETCH_LOW_BALANCE_THRESHOLD_REQUEST';
export const FETCH_LOW_BALANCE_THRESHOLD_RESPONSE = 'FETCH_LOW_BALANCE_THRESHOLD_RESPONSE';

export const SET_LOW_BALANCE_THRESHOLD_REQUEST  = 'SET_LOW_BALANCE_THRESHOLD_REQUEST';
export const SET_LOW_BALANCE_THRESHOLD_RESPONSE = 'SET_LOW_BALANCE_THRESHOLD_RESPONSE';

export const FETCH_DEMO_MODE_REQUEST  = 'FETCH_DEMO_MODE_REQUEST';
export const FETCH_DEMO_MODE_RESPONSE = 'FETCH_DEMO_MODE_RESPONSE';

export const SET_DEMO_MODE_REQUEST  = 'SET_DEMO_MODE_REQUEST';
export const SET_DEMO_MODE_RESPONSE = 'SET_DEMO_MODE_RESPONSE';

export const FETCH_PENDING_TERMS_CONDITIONS_REQUEST  = 'FETCH_PENDING_TERMS_CONDITIONS_REQUEST';
export const FETCH_PENDING_TERMS_CONDITIONS_RESPONSE = 'FETCH_PENDING_TERMS_CONDITIONS_RESPONSE';

export const ACCEPT_TERMS_CONDITIONS_REQUEST  = 'ACCEPT_TERMS_CONDITIONS_REQUEST';
export const ACCEPT_TERMS_CONDITIONS_RESPONSE = 'ACCEPT_TERMS_CONDITIONS_RESPONSE';

export const SET_MICROSOFT_TEAMS_SETTINGS_REQUEST  = 'SET_MICROSOFT_TEAMS_SETTINGS_REQUEST';
export const SET_MICROSOFT_TEAMS_SETTINGS_RESPONSE = 'SET_MICROSOFT_TEAMS_SETTINGS_RESPONSE';

export const FETCH_TEAMS_SETTINGS_REQUEST  = 'FETCH_TEAMS_SETTINGS_REQUEST';
export const FETCH_TEAMS_SETTINGS_RESPONSE = 'FETCH_TEAMS_SETTINGS_RESPONSE';

export const SET_REPORTING_CONFIG_REQUEST  = 'SET_REPORTING_CONFIG_REQUEST';
export const SET_REPORTING_CONFIG_RESPONSE = 'SET_REPORTING_CONFIG_RESPONSE';

export const FETCH_REPORTING_CONFIG_REQUEST  = 'FETCH_REPORTING_CONFIG_REQUEST';
export const FETCH_REPORTING_CONFIG_RESPONSE = 'FETCH_REPORTING_CONFIG_RESPONSE';

export const FETCH_CARRIERS_CONFIG_REQUEST  = 'FETCH_CARRIERS_CONFIG_REQUEST';
export const FETCH_CARRIERS_CONFIG_RESPONSE = 'FETCH_CARRIERS_CONFIG_RESPONSE';

export const SET_CARRIERS_CONFIG_REQUEST  = 'SET_CARRIERS_CONFIG_REQUEST';
export const SET_CARRIERS_CONFIG_RESPONSE = 'SET_CARRIERS_CONFIG_RESPONSE';

export const FETCH_SERVICES_CONFIG_REQUEST  = 'FETCH_SERVICES_CONFIG_REQUEST';
export const FETCH_SERVICES_CONFIG_RESPONSE = 'FETCH_SERVICES_CONFIG_RESPONSE';

export const SET_SERVICES_CONFIG_REQUEST  = 'SET_SERVICES_CONFIG_REQUEST';
export const SET_SERVICES_CONFIG_RESPONSE = 'SET_SERVICES_CONFIG_RESPONSE';

export const FETCH_USER_SYNC_VERSION_REQUEST  = 'FETCH_USER_SYNC_VERSION_REQUEST';
export const FETCH_USER_SYNC_VERSION_RESPONSE = 'FETCH_USER_SYNC_VERSION_RESPONSE';

export const FETCH_USER_SYNC_CRON_ENABLED_REQUEST  = 'FETCH_USER_SYNC_CRON_ENABLED_REQUEST';
export const FETCH_USER_SYNC_CRON_ENABLED_RESPONSE = 'FETCH_USER_SYNC_CRON_ENABLED_RESPONSE';

export const SET_NUMBER_TOMBSTONE_DURATION_REQUEST  = 'SET_NUMBER_TOMBSTONE_DURATION_REQUEST';
export const SET_NUMBER_TOMBSTONE_DURATION_RESPONSE = 'SET_NUMBER_TOMBSTONE_DURATION_RESPONSE';

export const FETCH_NUMBER_TOMBSTONE_DURATION_REQUEST  = 'FETCH_NUMBER_TOMBSTONE_DURATION_REQUEST';
export const FETCH_NUMBER_TOMBSTONE_DURATION_RESPONSE = 'FETCH_NUMBER_TOMBSTONE_DURATION_RESPONSE';

export const SET_SSO_ALLOW_IDENTITY_CREATION_REQUEST  = 'SET_SSO_ALLOW_IDENTITY_CREATION_REQUEST';
export const SET_SSO_ALLOW_IDENTITY_CREATION_RESPONSE = 'SET_SSO_ALLOW_IDENTITY_CREATION_RESPONSE';

export const FETCH_SSO_ALLOW_IDENTITY_CREATION_REQUEST  = 'FETCH_SSO_ALLOW_IDENTITY_CREATION_REQUEST';
export const FETCH_SSO_ALLOW_IDENTITY_CREATION_RESPONSE = 'FETCH_SSO_ALLOW_IDENTITY_CREATION_RESPONSE';

export const SET_SSO_ALLOW_PASSWORD_RESET_REQUEST  = 'SET_SSO_ALLOW_PASSWORD_RESET_REQUEST';
export const SET_SSO_ALLOW_PASSWORD_RESET_RESPONSE = 'SET_SSO_ALLOW_PASSWORD_RESET_RESPONSE';

export const FETCH_SSO_ALLOW_PASSWORD_RESET_REQUEST  = 'FETCH_SSO_ALLOW_PASSWORD_RESET_REQUEST';
export const FETCH_SSO_ALLOW_PASSWORD_RESET_RESPONSE = 'FETCH_SSO_ALLOW_PASSWORD_RESET_RESPONSE';

export const FETCH_RANGE_USAGE_THRESHOLD_REQUEST  = 'FETCH_RANGE_USAGE_THRESHOLD_REQUEST';
export const FETCH_RANGE_USAGE_THRESHOLD_RESPONSE = 'FETCH_RANGE_USAGE_THRESHOLD_RESPONSE';

export const SET_RANGE_USAGE_THRESHOLD_REQUEST  = 'SET_RANGE_USAGE_THRESHOLD_REQUEST';
export const SET_RANGE_USAGE_THRESHOLD_RESPONSE = 'SET_RANGE_USAGE_THRESHOLD_RESPONSE';

export const SET_CUSTOMER_COSTS_TOGGLE_REQUEST  = 'SET_CUSTOMER_COSTS_TOGGLE_REQUEST';
export const SET_CUSTOMER_COSTS_TOGGLE_RESPONSE = 'SET_CUSTOMER_COSTS_TOGGLE_RESPONSE';

export const FETCH_CUSTOMER_COSTS_TOGGLE_REQUEST  = 'FETCH_CUSTOMER_COSTS_TOGGLE_REQUEST';
export const FETCH_CUSTOMER_COSTS_TOGGLE_RESPONSE = 'FETCH_CUSTOMER_COSTS_TOGGLE_RESPONSE';

export const FETCH_NUMBER_REPORTS_TOGGLE_REQUEST  = 'FETCH_NUMBER_REPORTS_TOGGLE_REQUEST';
export const FETCH_NUMBER_REPORTS_TOGGLE_RESPONSE = 'FETCH_NUMBER_REPORTS_TOGGLE_RESPONSE';

export const FETCH_RANGE_USAGE_TOGGLE_REQUEST  = 'FETCH_RANGE_USAGE_TOGGLE_REQUEST';
export const FETCH_RANGE_USAGE_TOGGLE_RESPONSE = 'FETCH_RANGE_USAGE_TOGGLE_RESPONSE';

export const SET_RANGE_USAGE_TOGGLE_REQUEST  = 'SET_RANGE_USAGE_TOGGLE_REQUEST';
export const SET_RANGE_USAGE_TOGGLE_RESPONSE = 'SET_RANGE_USAGE_TOGGLE_RESPONSE';

export const FETCH_GLOBAL_UI_BANNER_REQUEST  = 'FETCH_GLOBAL_UI_BANNER_REQUEST';
export const FETCH_GLOBAL_UI_BANNER_RESPONSE = 'FETCH_GLOBAL_UI_BANNER_RESPONSE';

export const SET_GLOBAL_UI_BANNER_REQUEST  = 'SET_GLOBAL_UI_BANNER_REQUEST';
export const SET_GLOBAL_UI_BANNER_RESPONSE = 'SET_GLOBAL_UI_BANNER_RESPONSE';

export const FETCH_GLOBAL_UI_BANNER_TOGGLE_REQUEST  = 'FETCH_GLOBAL_UI_BANNER_TOGGLE_REQUEST';
export const FETCH_GLOBAL_UI_BANNER_TOGGLE_RESPONSE = 'FETCH_GLOBAL_UI_BANNER_TOGGLE_RESPONSE';

export const SET_GLOBAL_UI_BANNER_TOGGLE_REQUEST  = 'SET_GLOBAL_UI_BANNER_TOGGLE_REQUEST';
export const SET_GLOBAL_UI_BANNER_TOGGLE_RESPONSE = 'SET_GLOBAL_UI_BANNER_TOGGLE_RESPONSE';
