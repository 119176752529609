import { DateService }    from '@services/date.service';
import moment             from 'moment';
import { CallLogDataRaw } from '@models/api/call-log-data-raw.model';
import 'moment-duration-format';
import { Disposition }    from '@enums/disposition.enum';
import { Direction }      from '@enums/direction.enum';
import { SipResponse }    from '@enums/sip-response.enum';
import sipResponseColor   from '@consts/sip-response-color.const';

export class CallLogItem {
  id: string;
  date: string;
  time: string;
  direction: string;
  directionLabel: string;
  name: string;
  extension: string;
  sourceCli: string;
  destinationCli: string;
  duration: string;
  statusLabel: string;
  statusColor: string;
  iconName: string;
  recordingUri: string;
  transcriptId: string;
  disposition: Disposition;
  adjectives: string[];
  cost: string;
  isAnswered: boolean;
  sipResponseCode: number;
  sipResponseReason: SipResponse;

  fromApiData?(apiData: CallLogDataRaw, dateService: DateService): CallLogItem {
    this.id                = apiData.id;
    this.date              = dateService.UTCtoDate(apiData.date);
    this.time              = dateService.UTCtoTime(apiData.date);
    this.direction         = apiData.direction;
    this.extension         = apiData.extension;
    this.sourceCli         = apiData.source;
    this.destinationCli    = apiData.destination;
    this.cost              = apiData.cost;
    this.recordingUri      = apiData.recording_uri;
    this.disposition       = apiData.recording_analysis?.disposition;
    this.adjectives        = apiData.recording_analysis?.adjectives;
    this.transcriptId      = apiData.recording_analysis?.transcript_id;
    this.isAnswered        = apiData.is_answered;
    this.sipResponseCode   = apiData.sip_response_code || 0;
    this.sipResponseReason = apiData.sip_response_reason || SipResponse.Unclassified;

    if (!apiData.duration) {
      this.duration = '00:00:00';
    } else {
      const asSeconds = moment
        .duration(apiData.duration, 'seconds');
      this.duration   = asSeconds.format('HH:mm:ss', { trim: false });
    }
    this.statusColor = sipResponseColor.get(apiData.sip_response_reason);

    switch (apiData.direction) {
      case Direction.Internal:
        this.directionLabel = 'Internal';
        this.iconName       = 'cr-arrows-repeat';
        this.statusLabel    = 'Internal';
        break;
      case Direction.Inbound:
        this.directionLabel = 'Inbound';
        this.iconName       = 'cr-phone-inbound';
        this.statusLabel    = 'Received';
        break;
      case Direction.Outbound:
        this.directionLabel = 'Outbound';
        this.iconName       = 'cr-phone-outbound';
        this.statusLabel    = 'Placed';
        break;
    }

    return this;
  }

  getSentimentIcon(): 'cr-happy' | 'cr-unhappy' | 'cr-neutral' {
    switch (this.disposition) {
      case Disposition.Negative:
        return 'cr-unhappy';
      case Disposition.Positive:
        return 'cr-happy';
      case Disposition.Neutral:
        return 'cr-neutral';
    }
  }

  getSentimentIconClass(): string {
    switch (this.disposition) {
      case Disposition.Negative:
        return 'color-red';
      case Disposition.Positive:
        return 'color-green';
      case Disposition.Neutral:
        return 'color-light-gray';
    }
  }

  getSentimentTooltip(): string {
    const adjectives = this.adjectives ? ` Overall tone was described as ${ this.adjectives.join(', ') }.` : '';
    switch (this.disposition) {
      case Disposition.Negative:
        return `The sentiment of the call was determined to be negative.${ adjectives } Click to download the transcript.`;
      case Disposition.Positive:
        return `The sentiment of the call was determined to be positive.${ adjectives } Click to download the transcript.`;
      case Disposition.Neutral:
        return `The sentiment of the call was determined to be neutral.${ adjectives } Click to download the transcript.`;
    }
  }
}
