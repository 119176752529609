import { Injectable }                                                   from '@angular/core';
import { Actions, createEffect, ofType }                                from '@ngrx/effects';
import { concatMap, debounceTime, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MicrosoftTeamsService }                                        from '@services/microsoft-teams.service';
import {
  DeleteCallQueueGroupRequestAction,
  DeleteCallQueueGroupResponseAction,
  DeleteLicenseGroupRequestAction,
  DeleteLicenseGroupResponseAction,
  DeleteTeamGroupRequestAction,
  DeleteTeamGroupResponseAction,
  FetchAdGroupsRequestAction,
  FetchAdGroupsResponseAction,
  FetchCallQueueGroupListRequestAction,
  FetchCallQueueGroupListResponseAction,
  FetchCallQueueGroupNameAvailabilityRequestAction,
  FetchCallQueueGroupNameAvailabilityResponseAction,
  FetchCallQueueGroupRequestAction,
  FetchCallQueueGroupResponseAction,
  FetchCallQueuesRequestAction,
  FetchCallQueuesResponseAction,
  FetchLicenseGroupListRequestAction,
  FetchLicenseGroupListResponseAction,
  FetchLicenseGroupNameAvailabilityRequestAction,
  FetchLicenseGroupNameAvailabilityResponseAction,
  FetchLicenseGroupRequestAction,
  FetchLicenseGroupResponseAction,
  FetchLicensesRequestAction,
  FetchLicensesResponseAction,
  FetchTeamGroupListRequestAction,
  FetchTeamGroupListResponseAction,
  FetchTeamGroupNameAvailabilityRequestAction,
  FetchTeamGroupNameAvailabilityResponseAction,
  FetchTeamGroupRequestAction,
  FetchTeamGroupResponseAction,
  FetchTeamsRequestAction,
  FetchTeamsResponseAction,
  HealthCheckRequestAction,
  HealthCheckResponseAction,
  PatchCallQueueGroupRequestAction,
  PatchCallQueueGroupResponseAction,
  PatchLicenseGroupRequestAction,
  PatchLicenseGroupResponseAction,
  PatchTeamGroupRequestAction,
  PatchTeamGroupResponseAction,
  PostCallQueueGroupRequestAction,
  PostCallQueueGroupResponseAction,
  PostLicenseGroupRequestAction,
  PostLicenseGroupResponseAction,
  PostTeamGroupRequestAction,
  PostTeamGroupResponseAction,
  RequirementCheckRequestAction,
  RequirementCheckResponseAction,
}                                                                       from './microsoft-teams.actions';
import { StoreState }                                                   from '../store';
import { Store }                                                        from '@ngrx/store';
import { HealthCheckResponse }                                          from '@models/api/health-check-response.model';
import {
  RequirementCheckResponse,
}                                                                       from '@models/api/requirement-check-response.model';
import { withThrottle }                                                 from '@rxjs/action-throttle.operator';
import { ServiceService }                                               from '@services/service.service';
import {
  RefreshAuthStatus,
}                                                                       from '@redux/access-broker/access-broker.actions';
import { MicrosoftToken }                                               from '@enums/microsoft-token.enum';
import { HealthCheckRequest }                                           from '@models/api/health-check-request.model';
import {
  FetchLicenseGroupListRequest,
}                                                                       from '@models/api/fetch-license-group-list-request.model';
import {
  LicenseGroupQueryParams,
}                                                                       from '@models/form/license-group-query-params.model';
import {
  FetchLicenseGroupListResponse,
}                                                                       from '@models/api/fetch-license-group-list-response.model';
import {
  selectADGroupQueryParams,
  selectCallQueueGroupQueryParams,
  selectCallQueueQueryParams,
  selectLicenseGroupQueryParams,
  selectLicenseQueryParams,
  selectTeamGroupQueryParams,
  selectTeamsQueryParams,
}                                                                       from '@redux/microsoft-teams/microsoft-teams.selectors';
import {
  FetchLicenseGroupRequest,
}                                                                       from '@models/api/fetch-license-group-request.model';
import {
  FetchLicenseGroupResponse,
}                                                                       from '@models/api/fetch-license-group-response.model';
import {
  DeleteLicenseGroupRequest,
}                                                                       from '@models/api/delete-license-group-request.model';
import {
  ConfirmModalComponent,
}                                                                       from '@dialog/general/confirm-modal/confirm-modal.component';
import { ConfirmModalData }                                             from '@models/ui/confirm-modal-data.model';
import { Observable, of }                                               from 'rxjs';
import { MatDialog }                                                    from '@angular/material/dialog';
import {
  DeleteLicenseGroupResponse,
}                                                                       from '@models/api/delete-license-group-response.model';
import {
  PostLicenseGroupRequest,
}                                                                       from '@models/api/post-license-group-request.model';
import {
  PostLicenseGroupResponse,
}                                                                       from '@models/api/post-license-group-response.model';
import {
  PatchLicenseGroupRequest,
}                                                                       from '@models/api/patch-license-group-request.model';
import {
  PatchLicenseGroupResponse,
}                                                                       from '@models/api/patch-license-group-response.model';
import { FetchLicensesRequest }                                         from '@models/api/fetch-licenses-request.model';
import {
  FetchLicensesResponse,
}                                                                       from '@models/api/fetch-licenses-response.model';
import {
  FetchLicenseGroupNameAvailabilityRequest,
}                                                                       from '@models/api/fetch-license-group-name-availability-request.model';
import {
  FetchLicenseGroupNameAvailabilityResponse,
}                                                                       from '@models/api/fetch-license-group-name-availability-response.model';
import {
  FetchTeamGroupListRequest,
}                                                                       from '@models/api/microsoft-teams/fetch-teams-group-list-request.model';
import {
  TeamGroupQueryParams,
}                                                                       from '@models/form/teams-group-query-params.model';
import {
  FetchTeamGroupListResponse,
}                                                                       from '@models/api/microsoft-teams/fetch-teams-group-list-response.model';
import {
  FetchTeamsRequest,
}                                                                       from '@models/api/microsoft-teams/fetch-teams-request.model';
import {
  FetchTeamsResponse,
}                                                                       from '@models/api/microsoft-teams/fetch-teams-response.model';
import {
  DeleteTeamGroupRequest,
}                                                                       from '@models/api/microsoft-teams/delete-teams-group-request.model';
import {
  DeleteTeamGroupResponse,
}                                                                       from '@models/api/microsoft-teams/delete-teams-group-response.model';
import {
  PatchTeamGroupRequest,
}                                                                       from '@models/api/microsoft-teams/patch-teams-group-request.model';
import {
  PatchTeamGroupResponse,
}                                                                       from '@models/api/microsoft-teams/patch-teams-group-response.model';
import {
  PostTeamGroupRequest,
}                                                                       from '@models/api/microsoft-teams/post-teams-group-request.model';
import {
  PostTeamGroupResponse,
}                                                                       from '@models/api/microsoft-teams/post-teams-group-response.model';
import {
  FetchTeamGroupNameAvailabilityResponse,
}                                                                       from '@models/api/microsoft-teams/fetch-team-group-name-availability-response.model';
import {
  FetchTeamGroupNameAvailabilityRequest,
}                                                                       from '@models/api/microsoft-teams/fetch-team-group-name-availability-request.model';
import { LicenseGroup }                                                 from '@models/entity/license-group.model';
import {
  FetchTeamGroupRequest,
}                                                                       from '@models/api/microsoft-teams/fetch-team-group-request.model';
import {
  FetchTeamGroupResponse,
}                                                                       from '@models/api/microsoft-teams/fetch-team-group-response.model';
import { TeamGroup }                                                    from '@models/entity/teams-group.model';
import { Alert }                                                        from '@models/entity/alert.model';
import { TeamsQueryParams }                                             from '@models/form/teams-query-params.model';
import { LicenseQueryParams }                                           from '@models/form/license-query-params.model';
import {
  FetchCallQueueGroupListRequest,
}                                                                       from '@models/api/microsoft-teams/fetch-call-queue-group-list-request.model';
import {
  CallQueueGroupQueryParams,
}                                                                       from '@models/form/call-queue-group-query-params.model';
import {
  FetchCallQueueGroupListResponse,
}                                                                       from '@models/api/microsoft-teams/fetch-call-queue-group-list-response.model';
import {
  FetchCallQueueGroupResponse,
}                                                                       from '@models/api/microsoft-teams/fetch-call-queue-group-response.model';
import { CallQueueGroup }                                               from '@models/entity/call-queue-group.model';
import {
  FetchCallQueueGroupRequest,
}                                                                       from '@models/api/microsoft-teams/fetch-call-queue-group-request.model';
import {
  FetchCallQueuesRequest,
}                                                                       from '@models/api/microsoft-teams/fetch-call-queues-request.model';
import {
  CallQueueQueryParams,
}                                                                       from '@models/form/call-queue-query-params.model';
import {
  FetchCallQueuesResponse,
}                                                                       from '@models/api/microsoft-teams/fetch-call-queues-response.model';
import {
  DeleteCallQueueGroupRequest,
}                                                                       from '@models/api/microsoft-teams/delete-call-queue-group-request.model';
import {
  DeleteCallQueueGroupResponse,
}                                                                       from '@models/api/microsoft-teams/delete-call-queue-group-response.model';
import {
  FetchCallQueueGroupNameAvailabilityRequest,
}                                                                       from '@models/api/microsoft-teams/fetch-call-queue-group-name-availability-request.model';
import {
  FetchCallQueueGroupNameAvailabilityResponse,
}                                                                       from '@models/api/microsoft-teams/fetch-call-queue-group-name-availability-response.model';
import {
  PostCallQueueGroupRequest,
}                                                                       from '@models/api/microsoft-teams/post-call-queue-group-request.model';
import {
  PostCallQueueGroupResponse,
}                                                                       from '@models/api/microsoft-teams/post-call-queue-group-response.model';
import {
  PatchCallQueueGroupRequest,
}                                                                       from '@models/api/microsoft-teams/patch-call-queue-group-request.model';
import {
  PatchCallQueueGroupResponse,
}                                                                       from '@models/api/microsoft-teams/patch-call-queue-group-response.model';
import {
  FetchAdGroupsRequest,
}                                                                       from '@models/api/service/fetch-ad-groups-request.model';
import { ADGroupQueryParams }                                           from '@models/form/ad-group-query-params.model';
import {
  FetchAdGroupsResponse,
}                                                                       from '@models/api/service/fetch-ad-groups-response.model';
import { selectServiceItem }                                            from '@redux/service/service.selectors';
import { ServiceItem }                                                  from '@models/entity/service-item.model';
import {
  RequirementCheckRequest,
}                                                                       from '@models/api/requirement-check-request.model';

@Injectable({ providedIn: 'root' })
export class MicrosoftTeamsEffects {
  constructor(private microsoftTeamsService: MicrosoftTeamsService,
              private serviceService: ServiceService,
              private actions$: Actions,
              private store: Store<StoreState>,
              private dialog: MatDialog) {
  }

  healthCheck$ = createEffect(() => this.actions$.pipe(
    ofType(HealthCheckRequestAction),
    withThrottle(2_000),
    switchMap((req: HealthCheckRequest) => {
      return this.microsoftTeamsService.healthCheck$(req)
        .pipe(
          map((res: HealthCheckResponse) =>
            HealthCheckResponseAction(res)));
    }),
  ));

  requirementCheck$ = createEffect(() => this.actions$.pipe(
    ofType(RequirementCheckRequestAction),
    withThrottle(),
    withLatestFrom(this.store.select(selectServiceItem)),
    tap(async ([_, service]: [RequirementCheckRequest, ServiceItem]) => {
      this.store.dispatch(RefreshAuthStatus({ serviceId: service.id }));
      await Promise.all([this.serviceService.waitForProcessing(service.id, MicrosoftToken.MicrosoftLync), this.serviceService.waitForProcessing(service.id, MicrosoftToken.MicrosoftAzure)]);
      await this.serviceService.waitForFetched(service.id);
    }),
    switchMap(([req, _]: [RequirementCheckRequest, ServiceItem]) => {
      return this.microsoftTeamsService.requirementCheck$(req)
        .pipe(
          map((res: RequirementCheckResponse) =>
            RequirementCheckResponseAction(res)));
    }),
  ));

  fetchCallQueueGroupList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallQueueGroupListRequestAction),
    withLatestFrom(this.store.select(selectCallQueueGroupQueryParams)),
    switchMap(([req, queryParams]: [FetchCallQueueGroupListRequest, CallQueueGroupQueryParams]) => {
      return this.microsoftTeamsService.fetchCallQueueGroupList$({ queryParams, ...req })
        .pipe(concatMap((res: FetchCallQueueGroupListResponse) => {
              if (!res.data?.length) {
                return of(res);
              }
              const callQueueIds = res.data?.map(team => team.callQueueIds)
                .reduce((a: string[], b: string[]) => a.concat(b));
              const callQueues$  = this.microsoftTeamsService.fetchCallQueues$({
                serviceId:   req.serviceId,
                queryParams: {
                  pageSize:   1000,
                  pageNumber: 1,
                  id:         callQueueIds,
                },
              });
              return callQueues$.pipe(map(callQueues => {
                return {
                  ...res,
                  data: res.data.map(d => {
                    d.callQueues = callQueues.data.filter(team => d.callQueueIds.includes(team.id));
                    return d;
                  }),
                };
              }));
            },
          ),
          map((res: FetchCallQueueGroupListResponse) => FetchCallQueueGroupListResponseAction(res)),
        );
    })));

  fetchCallQueueGroup$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallQueueGroupRequestAction),
    switchMap((req: FetchCallQueueGroupRequest) => this.microsoftTeamsService.fetchCallQueueGroup$(req)
      .pipe(
        concatMap((res: FetchCallQueueGroupResponse) => {
          return this.resolveCallQueuesForCallQueueGroup$(res, req.serviceId);
        }))),
    map((res: FetchCallQueueGroupResponse) => FetchCallQueueGroupResponseAction(res)),
  ));

  fetchCallQueues$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallQueuesRequestAction),
    withLatestFrom(this.store.select(selectCallQueueQueryParams)),
    switchMap(([req, queryParams]: [FetchCallQueuesRequest, CallQueueQueryParams]) => this.microsoftTeamsService.fetchCallQueues$({
      ...req,
      queryParams: req.queryParams || queryParams,
    })),
    map((res: FetchCallQueuesResponse) => FetchCallQueuesResponseAction(res)),
  ));

  fetchLicenseGroupList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLicenseGroupListRequestAction),
    withLatestFrom(this.store.select(selectLicenseGroupQueryParams)),
    switchMap(([req, queryParams]: [FetchLicenseGroupListRequest, LicenseGroupQueryParams]) => {
      return this.microsoftTeamsService.fetchLicenseGroupList$({ queryParams, ...req })
        .pipe(concatMap((res: FetchLicenseGroupListResponse) => {
              if (!res.data?.length) {
                return of(res);
              }
              const licenseIds = res.data?.map(team => team.licenseIds)
                .reduce((a: string[], b: string[]) => a.concat(b));
              const licenses$  = this.microsoftTeamsService.fetchLicenses$({
                serviceId:   req.serviceId,
                queryParams: {
                  pageSize:   1000,
                  pageNumber: 1,
                  id:         licenseIds,
                },
              });
              return licenses$.pipe(map(licenses => {
                return {
                  ...res,
                  data: res.data.map(d => {
                    d.licenses = licenses.data.filter(team => d.licenseIds.includes(team.id));
                    return d;
                  }),
                };
              }));
            },
          ),
          map((res: FetchLicenseGroupListResponse) => FetchLicenseGroupListResponseAction(res)),
        );
    })));

  fetchLicenseGroup$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLicenseGroupRequestAction),
    switchMap((req: FetchLicenseGroupRequest) => this.microsoftTeamsService.fetchLicenseGroup$(req)
      .pipe(
        concatMap((res: FetchLicenseGroupResponse) => {
          return this.resolveLicensesForLicenseGroup$(res, req.serviceId);
        }))),
    map((res: FetchLicenseGroupResponse) => FetchLicenseGroupResponseAction(res)),
  ));

  fetchTeamGroupList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTeamGroupListRequestAction),
    withLatestFrom(this.store.select(selectTeamGroupQueryParams)),
    switchMap(([req, queryParams]: [FetchTeamGroupListRequest, TeamGroupQueryParams]) =>
      this.microsoftTeamsService.fetchTeamGroupList$({ queryParams, ...req })
        .pipe(concatMap((res: FetchTeamGroupListResponse) => {
          if (!res.data?.length) {
            return of(res);
          }
          const teamIds = res.data?.map(team => team.teamIds)
            .reduce((a: string[], b: string[]) => a.concat(b));
          const teams$  = this.microsoftTeamsService.fetchTeams$({
            serviceId:   req.serviceId,
            queryParams: {
              pageSize:   1000,
              pageNumber: 1,
              id:         teamIds,
            },
          });
          return teams$.pipe(map(teams => {
            return {
              ...res,
              data: res.data.map(d => {
                d.teams = teams.data.filter(team => d.teamIds.includes(team.id));
                return d;
              }),
            };
          }));
        }))),
    map((res: FetchTeamGroupListResponse) => FetchTeamGroupListResponseAction(res)),
  ));

  fetchAdGroupList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchAdGroupsRequestAction),
    withLatestFrom(this.store.select(selectADGroupQueryParams)),
    switchMap(([req, queryParams]: [FetchAdGroupsRequest, ADGroupQueryParams]) =>
      this.microsoftTeamsService.fetchADGroupList$({ queryParams, ...req })),
    map((res: FetchAdGroupsResponse) => FetchAdGroupsResponseAction(res)),
  ));

  postLicenseGroup$ = createEffect(() => this.actions$.pipe(
    ofType(PostLicenseGroupRequestAction),
    switchMap((req: PostLicenseGroupRequest) => this.microsoftTeamsService.postLicenseGroup$(req)
      .pipe(
        concatMap((res: PostLicenseGroupResponse) => {
          return this.resolveLicensesForLicenseGroup$(res, req.serviceId);
        }))),
    tap((res: PostLicenseGroupResponse) => this.store.dispatch(FetchLicenseGroupListRequestAction({
      serviceId:   res.serviceId,
      queryParams: null,
    }))),
    map((res: PostLicenseGroupResponse) => PostLicenseGroupResponseAction(res)),
  ));

  patchLicenseGroup$ = createEffect(() => this.actions$.pipe(
    ofType(PatchLicenseGroupRequestAction),
    concatMap((req: PatchLicenseGroupRequest): Observable<PatchLicenseGroupRequest> => {
      if (!req.confirm) {
        return of(req);
      }
      const modalText = `<p>You are about to save and update a license group. 
This action will update all users assigned to this group to match the current saved setting. 
Are you sure you want to save? Click 'confirm' to continue with the update.</p>`;
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:          `Save & update ${ req.name }`,
          content:        modalText,
          confirmBtnText: 'Confirm',
          showCancel:     true,
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(map((confirmed: boolean) => {
          if (!confirmed) {
            return null;
          }
          return req;
        }));
    }),
    switchMap((req: PatchLicenseGroupRequest) => {
        if (!req) {
          return of({
            data:      null,
            error:     null,
            message:   null,
            cancelled: true,
          });
        }
        return this.microsoftTeamsService.patchLicenseGroup$(req)
          .pipe(
            concatMap((res: PatchLicenseGroupResponse) => {
              return this.resolveLicensesForLicenseGroup$(res, req.serviceId);
            }));
      },
    ),
    map((res: PatchLicenseGroupResponse) => PatchLicenseGroupResponseAction(res)),
  ));

  patchTeamGroup$ = createEffect(() => this.actions$.pipe(
    ofType(PatchTeamGroupRequestAction),
    concatMap((req: PatchTeamGroupRequest): Observable<PatchTeamGroupRequest> => {
      if (!req.confirm) {
        return of(req);
      }
      const modalText = `<p>You are about to save and update a team group. 
This action will update all users assigned to this group to match the current saved setting. 
Are you sure you want to save? Click 'confirm' to continue with the update.</p>`;
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:          `Save & update ${ req.name }`,
          content:        modalText,
          confirmBtnText: 'Confirm',
          showCancel:     true,
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(map((confirmed: boolean) => {
          if (!confirmed) {
            return null;
          }
          return req;
        }));
    }),
    switchMap((req: PatchTeamGroupRequest) => {
      if (!req) {
        return of({
          data:      null,
          error:     null,
          message:   null,
          cancelled: true,
        });
      }
      return this.microsoftTeamsService.patchTeamGroup$(req)
        .pipe(
          concatMap((res: FetchTeamGroupResponse) => {
            return this.resolveTeamsForTeamGroup$(res, req.serviceId);
          }));
    }),
    map((res: PatchTeamGroupResponse) => PatchTeamGroupResponseAction(res)),
  ));

  postTeamGroup$ = createEffect(() => this.actions$.pipe(
    ofType(PostTeamGroupRequestAction),
    switchMap((req: PostTeamGroupRequest) => this.microsoftTeamsService.postTeamGroup$(req)
      .pipe(
        concatMap((res: PostTeamGroupResponse) => {
          return this.resolveTeamsForTeamGroup$(res, req.serviceId);
        })),
    ),
    tap((res: PostTeamGroupResponse) => this.store.dispatch(FetchTeamGroupListRequestAction({
      serviceId:   res.serviceId,
      queryParams: null,
    }))),
    map((res: PostTeamGroupResponse) => PostTeamGroupResponseAction(res)),
  ));

  postCallQueueGroup$ = createEffect(() => this.actions$.pipe(
    ofType(PostCallQueueGroupRequestAction),
    switchMap((req: PostCallQueueGroupRequest) => this.microsoftTeamsService.postCallQueueGroup$(req)
      .pipe(
        concatMap((res: PostCallQueueGroupResponse) => {
          return this.resolveCallQueuesForCallQueueGroup$(res, req.serviceId);
        })),
    ),
    tap((res: PostCallQueueGroupResponse) => this.store.dispatch(FetchCallQueueGroupListRequestAction({
      serviceId:   res.serviceId,
      queryParams: null,
    }))),
    map((res: PostCallQueueGroupResponse) => PostCallQueueGroupResponseAction(res)),
  ));

  patchCallQueueGroup$ = createEffect(() => this.actions$.pipe(
    ofType(PatchCallQueueGroupRequestAction),
    concatMap((req: PatchCallQueueGroupRequest): Observable<PatchCallQueueGroupRequest> => {
      if (!req.confirm) {
        return of(req);
      }
      const modalText = `<p>You are about to save and update a call queue group. 
This action will update all users assigned to this group to match the current saved setting. 
Are you sure you want to save? Click 'confirm' to continue with the update.</p>`;
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:          `Save & update ${ req.name }`,
          content:        modalText,
          confirmBtnText: 'Confirm',
          showCancel:     true,
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(map((confirmed: boolean) => {
          if (!confirmed) {
            return null;
          }
          return req;
        }));
    }),
    switchMap((req: PatchCallQueueGroupRequest) => {
      if (!req) {
        return of({
          data:      null,
          error:     null,
          message:   null,
          cancelled: true,
        });
      }
      return this.microsoftTeamsService.patchCallQueueGroup$(req)
        .pipe(
          concatMap((res: PatchCallQueueGroupResponse) => {
            return this.resolveCallQueuesForCallQueueGroup$(res, req.serviceId);
          }));
    }),
    map((res: PatchCallQueueGroupResponse) => PatchCallQueueGroupResponseAction(res)),
  ));

  deleteLicenseGroup$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteLicenseGroupRequestAction),
    switchMap((req: DeleteLicenseGroupRequest) => {
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:          `Delete ${ req.name }`,
          content:        `<p>You are about to delete a license group. This will remove licenses from users currently assigned to this group. Click 'delete' to continue with the deletion.</p>`,
          confirmBtnText: 'Delete',
          showCancel:     true,
          typeConfirm:    true,
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(concatMap((confirmed: boolean) => {
          if (!confirmed) {
            return of(DeleteLicenseGroupResponseAction({
              cancelled:    true,
              error:        null,
              id:           req.id,
              serviceId:    req.serviceId,
              isLastOnPage: false,
            }));
          }
          return this.microsoftTeamsService.deleteLicenseGroup$(req)
            .pipe(map((res: DeleteLicenseGroupResponse) => DeleteLicenseGroupResponseAction(res)));
        }));
    })));

  deleteLicenseGroupRefresh$ = createEffect(() => this.actions$.pipe(
      ofType(DeleteLicenseGroupResponseAction),
      debounceTime(1_000),
      withLatestFrom(this.store.select(selectLicenseGroupQueryParams)),
      tap(([res, queryParams]: [DeleteLicenseGroupResponse, LicenseGroupQueryParams]) => {
        if (res.cancelled) {
          return;
        }
        this.store.dispatch(FetchLicenseGroupListRequestAction({
          serviceId:   res.serviceId,
          queryParams: queryParams ?
                         {
                           ...queryParams,
                           pageNumber: res.isLastOnPage && queryParams.pageNumber !== 1 ?
                                         queryParams.pageNumber - 1 :
                                         queryParams.pageNumber,
                         } : null,
        }));
      }),
    ),
    { dispatch: false });

  deleteTeamGroup$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteTeamGroupRequestAction),
    switchMap((req: DeleteTeamGroupRequest) => {
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:          `Delete ${ req.name }`,
          content:        `<p>You are about to delete a teams group. Click 'delete' to continue with the deletion.</p>`,
          confirmBtnText: 'Delete',
          showCancel:     true,
          typeConfirm:    true,
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(concatMap((confirmed: boolean) => {
          if (!confirmed) {
            return of(DeleteTeamGroupResponseAction({
              cancelled:    true,
              error:        null,
              id:           req.id,
              serviceId:    req.serviceId,
              isLastOnPage: false,
            }));
          }
          return this.microsoftTeamsService.deleteTeamGroup$(req)
            .pipe(map((res: DeleteTeamGroupResponse) => DeleteTeamGroupResponseAction(res)));
        }));
    })));

  deleteCallQueueGroup$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteCallQueueGroupRequestAction),
    switchMap((req: DeleteCallQueueGroupRequest) => {
      return this.dialog.open<ConfirmModalComponent, ConfirmModalData, boolean>(ConfirmModalComponent, {
        data:       {
          title:          `Delete ${ req.name }`,
          content:        `<p>You are about to delete a call queue group. Click 'delete' to continue with the deletion.</p>`,
          confirmBtnText: 'Delete',
          showCancel:     true,
          typeConfirm:    true,
        },
        panelClass: 'cr-dialog',
        maxWidth:   '640px',
        maxHeight:  'calc(100vh - 140px)',
        width:      '100%',
      })
        .afterClosed()
        .pipe(concatMap((confirmed: boolean) => {
          if (!confirmed) {
            return of(DeleteCallQueueGroupResponseAction({
              cancelled:    true,
              error:        null,
              id:           req.id,
              serviceId:    req.serviceId,
              isLastOnPage: false,
            }));
          }
          return this.microsoftTeamsService.deleteCallQueueGroup$(req)
            .pipe(map((res: DeleteCallQueueGroupResponse) => DeleteCallQueueGroupResponseAction(res)));
        }));
    })));

  deleteCallQueueGroupRefresh$ = createEffect(() => this.actions$.pipe(
      ofType(DeleteCallQueueGroupResponseAction),
      debounceTime(1_000),
      withLatestFrom(this.store.select(selectCallQueueGroupQueryParams)),
      tap(([res, queryParams]: [DeleteCallQueueGroupResponse, CallQueueGroupQueryParams]) => {
        if (res.cancelled) {
          return;
        }
        this.store.dispatch(FetchCallQueueGroupListRequestAction({
          serviceId:   res.serviceId,
          queryParams: queryParams ?
                         {
                           ...queryParams,
                           pageNumber: res.isLastOnPage && queryParams.pageNumber !== 1 ?
                                         queryParams.pageNumber - 1 :
                                         queryParams.pageNumber,
                         } : null,
        }));
      }),
    ),
    { dispatch: false });

  deleteTeamGroupRefresh$ = createEffect(() => this.actions$.pipe(
      ofType(DeleteTeamGroupResponseAction),
      debounceTime(1_000),
      withLatestFrom(this.store.select(selectTeamGroupQueryParams)),
      tap(([res, queryParams]: [DeleteTeamGroupResponse, TeamGroupQueryParams]) => {
        if (res.cancelled) {
          return;
        }
        this.store.dispatch(FetchTeamGroupListRequestAction({
          serviceId:   res.serviceId,
          queryParams: queryParams ?
                         {
                           ...queryParams,
                           pageNumber: res.isLastOnPage && queryParams.pageNumber !== 1 ?
                                         queryParams.pageNumber - 1 :
                                         queryParams.pageNumber,
                         } : null,
        }));
      }),
    ),
    { dispatch: false });

  fetchLicenses$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLicensesRequestAction),
    withLatestFrom(this.store.select(selectLicenseQueryParams)),
    switchMap(([req, queryParams]: [FetchLicensesRequest, LicenseQueryParams]) => this.microsoftTeamsService.fetchLicenses$({
      ...req,
      queryParams: req.queryParams || queryParams,
    })),
    map((res: FetchLicensesResponse) => FetchLicensesResponseAction(res)),
  ));

  fetchTeams$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTeamsRequestAction),
    withLatestFrom(this.store.select(selectTeamsQueryParams)),
    switchMap(([req, queryParams]: [FetchTeamsRequest, TeamsQueryParams]) => this.microsoftTeamsService.fetchTeams$({
      ...req,
      queryParams: req.queryParams || queryParams,
    })),
    map((res: FetchTeamsResponse) => FetchTeamsResponseAction(res)),
  ));

  fetchLicenseGroupNameAvailability$ = createEffect(() => this.actions$.pipe(
    ofType(FetchLicenseGroupNameAvailabilityRequestAction),
    switchMap((req: FetchLicenseGroupNameAvailabilityRequest) => this.microsoftTeamsService.fetchLicenseGroupNameAvailability$(req)),
    map((res: FetchLicenseGroupNameAvailabilityResponse) => FetchLicenseGroupNameAvailabilityResponseAction(res)),
  ));

  fetchTeamGroupNameAvailability$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTeamGroupNameAvailabilityRequestAction),
    switchMap((req: FetchTeamGroupNameAvailabilityRequest) => this.microsoftTeamsService.fetchTeamGroupNameAvailability$(req)),
    map((res: FetchTeamGroupNameAvailabilityResponse) => FetchTeamGroupNameAvailabilityResponseAction(res)),
  ));

  fetchCallQueueGroupNameAvailability$ = createEffect(() => this.actions$.pipe(
    ofType(FetchCallQueueGroupNameAvailabilityRequestAction),
    switchMap((req: FetchCallQueueGroupNameAvailabilityRequest) => this.microsoftTeamsService.fetchCallQueueGroupNameAvailability$(req)),
    map((res: FetchCallQueueGroupNameAvailabilityResponse) => FetchCallQueueGroupNameAvailabilityResponseAction(res)),
  ));

  fetchTeamGroup$ = createEffect(() => this.actions$.pipe(
    ofType(FetchTeamGroupRequestAction),
    switchMap((req: FetchTeamGroupRequest) => this.microsoftTeamsService.fetchTeamGroup$(req)
      .pipe(
        concatMap((res: FetchTeamGroupResponse) => {
          return this.resolveTeamsForTeamGroup$(res, req.serviceId);
        }))),
    map((res: FetchTeamGroupResponse) => FetchTeamGroupResponseAction(res)),
  ));

  private resolveLicensesForLicenseGroup$<T extends { data: LicenseGroup, error?: Alert }>(res: T, serviceId: string): Observable<T> {
    if (res.error || !res.data?.licenseIds?.length) {
      return of(res);
    }
    const licenseIds = res.data.licenseIds;
    const licenses$  = this.microsoftTeamsService.fetchLicenses$({
      serviceId,
      queryParams: {
        pageSize:   1000,
        pageNumber: 1,
        id:         licenseIds,
      },
    });
    return licenses$.pipe(map(licenses => {
      if (licenses.error) {
        return res;
      }
      const data    = res.data;
      data.licenses = licenses.data;
      return {
        ...res,
        data,
      };
    }));
  }

  private resolveCallQueuesForCallQueueGroup$<T extends { data: CallQueueGroup, error?: Alert }>(res: T, serviceId: string): Observable<T> {
    if (res.error || !res.data?.callQueueIds?.length) {
      return of(res);
    }
    const callQueueIds = res.data.callQueueIds;
    const callQueues$  = this.microsoftTeamsService.fetchCallQueues$({
      serviceId,
      queryParams: {
        pageSize:   1000,
        pageNumber: 1,
        id:         callQueueIds,
      },
    });
    return callQueues$.pipe(map(callQueues => {
      if (callQueues.error) {
        return res;
      }
      const data      = res.data;
      data.callQueues = callQueues.data;
      return {
        ...res,
        data,
      };
    }));
  }

  private resolveTeamsForTeamGroup$<T extends { data: TeamGroup, error?: Alert }>(res: T, serviceId: string): Observable<T> {
    if (res.error || !res.data.teamIds?.length) {
      return of(res);
    }
    const teamIds = res.data?.teamIds;
    const teams$  = this.microsoftTeamsService.fetchTeams$({
      serviceId,
      queryParams: {
        pageSize:   1000,
        pageNumber: 1,
        id:         teamIds,
      },
    });
    return teams$.pipe(map(teams => {
      if (teams.error) {
        return res;
      }
      const data = res.data;
      data.teams = teams.data;
      return {
        ...res,
        data,
      };
    }));
  }

}
