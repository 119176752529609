import { NgModule }                                     from '@angular/core';
import {
  PanelColumnComponent,
}                                                       from '@core/components/general/panel-column/panel-column.component';
import { FeatureCardComponent }                         from './feature-card/feature-card.component';
import { UpgradeNoticeComponent }                       from './upgrade-notice/upgrade-notice.component';
import { VideoComponent }                               from './video/video.component';
import { TagChipComponent }                             from './tag-chip/tag-chip.component';
import { MatButtonModule }                              from '@angular/material/button';
import { MatTooltipModule }                             from '@angular/material/tooltip';
import { MatIconModule }                                from '@angular/material/icon';
import { RouterModule }                                 from '@angular/router';
import { MatDividerModule }                             from '@angular/material/divider';
import { MatChipsModule }                               from '@angular/material/chips';
import { MatListModule }                                from '@angular/material/list';
import { MatProgressSpinnerModule }                     from '@angular/material/progress-spinner';
import { PaginatorComponent }                           from '@core/components/general/paginator/paginator.component';
import { AsyncPipe, DecimalPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { SafePipe }                                     from '@pipes/safe.pipe';
import { LocaleNumberPipe }                             from '@pipes/locale-number.pipe';
import { FlexLayoutModule }                             from '@angular/flex-layout';
import { ReactiveFormsModule }                          from '@angular/forms';


@NgModule({
  declarations: [
    PanelColumnComponent,
    FeatureCardComponent,
    UpgradeNoticeComponent,
    VideoComponent,
    TagChipComponent,
    PaginatorComponent,
  ],
  imports:      [
    NgIf,
    NgClass,
    NgFor,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    RouterModule,
    MatDividerModule,
    MatChipsModule,
    MatListModule,
    MatProgressSpinnerModule,
    DecimalPipe,
    SafePipe,
    AsyncPipe,
    LocaleNumberPipe,
  ],
  exports:      [
    PanelColumnComponent,
    FeatureCardComponent,
    UpgradeNoticeComponent,
    VideoComponent,
    TagChipComponent,
    PaginatorComponent,
  ],
})
export class LayoutModule {}
