import { Component, EventEmitter, Input, Output }                                             from '@angular/core';
import { AbstractControl, FormArray, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  FieldCheckType,
}                                                                                             from '@enums/field-check-type.enum';
import {
  MatAutocompleteSelectedEvent,
}                                                                                             from '@angular/material/autocomplete';
import {
  NumberItem,
}                                                                                             from '@models/entity/number-item.model';
import {
  NumberState,
}                                                                                             from '@redux/number/number.reducer';
import { BehaviorSubject }                                                                    from 'rxjs';
import { map, tap }                                                                           from 'rxjs/operators';
import {
  CarrierService,
}                                                                                             from '@services/carrier.service';
import {
  AssetsService,
}                                                                                             from '@services/assets.service';

@Component({
  selector:    'ngx-carrier-form',
  templateUrl: './carrier-form.component.html',
  styleUrls:   ['./carrier-form.component.scss'],
})
export class CarrierFormComponent {
  @Input() adapter: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() editMode                         = false;
  @Input() dialogMode: boolean;
  @Input() numberReducer: NumberState;
  @Output() addressSpacesHasDuplicateChange = new EventEmitter<boolean>();

  get showSecondaryGateway(): boolean {
    return this.formGroup &&
      !this.formGroup.get('useDNSSRV')?.value &&
      (this.formGroup.get('gateways') as FormArray)?.controls?.length === 2;
  }

  protocols      = ['TLS', 'UDP'];
  FieldCheckType = FieldCheckType;

  currentAddressSpace  = new BehaviorSubject<string>(null);
  currentAddressSpace$ = this.currentAddressSpace.asObservable();
  containsDuplicates$  = this.currentAddressSpace$.pipe(map(addressSpace => {
    const arr = (this.addressSpacesFormArray.value as string[]).slice(0, this.addressSpacesFormArray.length - 1)
      .concat(addressSpace);
    return new Set([...arr]).size !== arr.length;
  }), tap(dupes => this.addressSpacesHasDuplicateChange.emit(dupes)));

  get existingNumber(): NumberItem {
    return this.formGroup?.get('number')?.value;
  }

  get primaryGatewayAddressLabel(): string {
    if (this.adapter === 'byod-international') {
      return 'SBC IP Address or FQDN (e.g. sbc.domain.com)';
    }
    return 'Primary gateway address (e.g. 192.168.100.1)';
  }

  get primaryGatewayProtocolLabel(): string {
    if (this.adapter === 'byod-international') {
      return 'Signalling Protocol';
    }
    return 'Primary gateway protocol';
  }

  get primaryGatewayPort(): string {
    if (this.adapter === 'byod-international') {
      return 'Signalling port (e.g. 5060-5061)';
    }
    return 'Primary gateway port (e.g. 1-65535)';
  }

  get addressSpacesLabel(): string {
    if (this.adapter === 'byod-international') {
      return 'SIP IP range (e.g. 30.120.113.100/32)';
    }
    return 'Address spaces (e.g. 192.168.0.0/8)';
  }

  get sipOptionsLabel(): string {
    if (this.adapter === 'byod-international') {
      return 'Does your SBC support SIP options?';
    }
    return 'Does the Gateway support SIP options?';
  }

  gatewayCtrl(priority: number, key: 'address' | 'port' | 'protocol'): UntypedFormControl {
    return (this.formGroup?.get('gateways') as UntypedFormArray)?.at(priority - 1)
      ?.get(key) as UntypedFormControl;
  }

  gbIcon = AssetsService.getFlag('gb.svg');

  constructor(private carrierService: CarrierService) {}

  fieldInvalid(control: AbstractControl, bSubmitted: boolean): boolean {
    return control.value && !control.valid && (bSubmitted || control.dirty);
  }

  setNumberId(e: MatAutocompleteSelectedEvent): void {
    if (e.option && e.option.value && (e.option.value as NumberItem).id) {
      this.formGroup.get('numberId')
        .setValue((e.option.value as NumberItem).id);
      this.formGroup.get('numberId')
        .updateValueAndValidity();
    }
  }

  getNumberName(numberItem: NumberItem): string {
    return numberItem ? numberItem.didNumber : '';
  }

  removeExistingNumber(numbers: Array<NumberItem>): Array<NumberItem> {
    if (!this.existingNumber) {
      return numbers;
    } else {
      return numbers?.filter(n => n.didNumber !== this.existingNumber.didNumber);
    }
  }

  togglePasswordVisibility(passwordCtrl: HTMLInputElement): void {
    if (passwordCtrl.type === 'password') {
      passwordCtrl.type = 'text';
      return;
    }

    passwordCtrl.type = 'password';
  }

  get addressSpacesFormArray(): UntypedFormArray {
    return (this.formGroup?.get('addressSpaces') as UntypedFormArray);
  }

  get addressSpacesCtrls(): AbstractControl[] {
    return this.addressSpacesFormArray?.controls || [];
  }

  async addAddressSpace(): Promise<void> {
    this.addressSpacesFormArray.push(this.carrierService.newCIDRAddrCtrl(null));
  }

  getAddressSpaceCtrl(i: number): UntypedFormControl {
    return this.addressSpacesFormArray.at(i) as UntypedFormControl;
  }

  removeAddressSpace(i: number): void {
    this.addressSpacesFormArray.removeAt(i);
    this.currentAddressSpace.next(null);
    this.formGroup.markAsDirty();
    this.formGroup.updateValueAndValidity();
  }

}
