import { BaseState, requestReduce, responseReduce } from '../helpers/reducer.helper';
import { Action, createReducer, on }                from '@ngrx/store';
import * as AccessBrokerAction                      from './access-broker.actions';
import {
  AUTHORISE_REDIRECT_REQUEST,
  FETCH_COMBINED_TOKENS_STATUS_REQUEST,
  MS_OAUTH_CALLBACK,
}                                                   from './access-broker.types';
import {
  MicrosoftToken,
}                                                   from '@enums/microsoft-token.enum';
import * as AuthAction                              from '../auth/auth.actions';
import {
  Token,
}                                                   from '@models/entity/token-state.model';
import * as UIAction                                from '../ui/ui.actions';
import { createEntityAdapter, EntityAdapter }       from '@ngrx/entity';
import {
  DELETE_TOKENS_REQUEST,
}                                                   from '@redux/service/service.types';
import {
  TokenStatus,
}                                                   from '@enums/token-status.enum';
import {
  MicrosoftTeamsService,
}                                                   from '@services/microsoft-teams.service';

export interface AccessBrokerState extends BaseState<Token> {
  redirectURI: string;
  tokens: {
    [serviceId: string]: Token[];
  };
}

export const adapter: EntityAdapter<Token> = createEntityAdapter<Token>(
  { selectId: token => token.id },
);

const initialState: AccessBrokerState = adapter.getInitialState({
  redirectURI:  null,
  pending:      false,
  pendingTasks: [],
  error:        null,
  message:      null,
  tokens:       {},
  ids:          [MicrosoftToken.MicrosoftLync, MicrosoftToken.MicrosoftAzure],
});

const _accessBrokerReducer = createReducer(initialState,
  on(AccessBrokerAction.AuthoriseRedirectRequest, (state, action) => requestReduce(state, action, req => {
    const tokens = { ...state.tokens };
    if (!tokens[req.serviceId]?.find(token => token.id === req.token)) {
      tokens[req.serviceId] = (tokens[req.serviceId] || [
        MicrosoftTeamsService.getDefaultToken(
          req.token === MicrosoftToken.MicrosoftLync ?
            MicrosoftToken.MicrosoftAzure :
            MicrosoftToken.MicrosoftLync,
          req.serviceId),
      ]).concat({
        id:        req.token,
        serviceId: req.serviceId,
        status:    TokenStatus.Processing,
        lastAuth:  true,
      });
    } else {
      tokens[req.serviceId] = tokens[req.serviceId]?.map(token => token.id === req.token ?
        { ...token, lastAuth: true, status: TokenStatus.Processing } :
        {
          ...(token || MicrosoftTeamsService.getDefaultToken(
            req.token === MicrosoftToken.MicrosoftLync ?
              MicrosoftToken.MicrosoftAzure :
              MicrosoftToken.MicrosoftLync,
            req.serviceId)), lastAuth: false,
        });
    }
    return {
      tokens,
    };
  })),
  on(AccessBrokerAction.AuthoriseRedirectResponse, (state, action) =>
    responseReduce(state, action, AUTHORISE_REDIRECT_REQUEST, (res) => {
      return {
        cookie:      res.cookie,
        redirectURI: res.redirectURI,
      };
    })),
  on(AccessBrokerAction.MSOAuthCallback, (state, action) =>
    responseReduce(state, action, MS_OAUTH_CALLBACK, res => {
      const tokens = { ...state.tokens };
      if (!tokens[res.serviceId]) {
        return {};
      }
      tokens[res.serviceId] = tokens[res.serviceId].map(token => {
        if (token.lastAuth) {
          return {
            ...token,
            lastAuth: true,
            status:   res.status === 'success' ? TokenStatus.Active : TokenStatus.Error,
          };
        }
        return token;
      });
      return {
        tokens,
      };
    })),
  on(AccessBrokerAction.DeleteTokensRequestAction, requestReduce),
  on(AccessBrokerAction.DeleteTokensResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_TOKENS_REQUEST, res => {
      if (res.error || res.cancelled) {
        return {};
      }
      const tokens          = { ...state.tokens };
      tokens[res.serviceId] = tokens[res.serviceId]?.map(token => ({
        ...token,
        lastAuth: false,
        status:   TokenStatus.Inactive,
      }));
      return {
        tokens,
      };
    })),
  on(AccessBrokerAction.FetchCombinedTokensStatusRequestAction, (state, action) =>
    requestReduce(state, action, req => {
      const tokens          = { ...state.tokens };
      tokens[req.serviceId] = tokens[req.serviceId]?.map(token => {
        return {
          ...token,
          lastAuth: token.lastAuth,
          status:   token.status === TokenStatus.Pending ? TokenStatus.Processing : token.status,
        };
      }) || [
        MicrosoftTeamsService.getDefaultToken(MicrosoftToken.MicrosoftAzure, req.serviceId),
        MicrosoftTeamsService.getDefaultToken(MicrosoftToken.MicrosoftLync, req.serviceId),
      ];
      return {
        tokens,
      };
    })),
  on(AccessBrokerAction.FetchCombinedTokensStatusResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_COMBINED_TOKENS_STATUS_REQUEST, res => {
      const tokens = { ...state.tokens };

      tokens[res.serviceId] = tokens[res.serviceId]
        ?.map(token => {

          const updatedToken: Token = res.error ?
            { status: TokenStatus.Inactive, message: null, id: null, serviceId: null, lastAuth: null } :
            res.tokens?.find(t => t.id === token.id);

          return {
            ...token,
            lastAuth: token.lastAuth,
            message:  updatedToken.message,
            status:   updatedToken.status,
          };
        });
      return {
        tokens,
      };
    })),
  on(UIAction.DismissErrorAction, (state) =>
    ({ ...state, error: null })),
  on(UIAction.DismissMessageAction, (state) =>
    ({ ...state, message: null })),
  on(AuthAction.LogoutAction, () =>
    ({ ...initialState })),
);

export function accessBrokerReducer(state: AccessBrokerState, action: Action): AccessBrokerState {
  return _accessBrokerReducer(state, action);
}
