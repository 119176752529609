import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  SimpleChanges,
}                                                              from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  ServiceState,
}                                                              from '@redux/service/service.reducer';
import {
  UntypedFormGroup,
}                                                              from '@angular/forms';
import { Store }                                               from '@ngrx/store';
import { StoreState }                                          from '@redux/store';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
}                                                              from '@angular/material/dialog';
import {
  selectService,
}                                                              from '@redux/service/service.selectors';
import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  startWith,
  take,
  takeUntil,
  tap,
  throttleTime,
}                                                              from 'rxjs/operators';
import {
  DismissErrorAction,
  DismissMessageAction,
}                                                              from '@redux/ui/ui.actions';
import {
  SIPPhone,
}                                                              from '@models/entity/sip-phone.model';
import {
  SipPhoneService,
}                                                              from '@services/sip-phone.service';
import {
  SIPPhoneRaw,
}                                                              from '@models/api/sip-phone-raw.model';
import {
  PATCH_SERVICE_REQUEST,
  POST_SERVICE_REQUEST,
}                                                              from '@redux/service/service.types';
import {
  PatchServiceRequestAction,
  PostServiceRequestAction,
}                                                              from '@redux/service/service.actions';
import {
  ServiceItemRaw,
}                                                              from '@models/api/service-api-response.model';
import {
  NumberState,
}                                                              from '@redux/number/number.reducer';
import {
  selectNumber,
}                                                              from '@redux/number/number.selectors';
import {
  ServiceType,
}                                                              from '@enums/service-type.enum';
import {
  FormService,
}                                                              from '@services/form.service';
import {
  ScopeService,
}                                                              from '@services/scope.service';
import {
  AuthScope,
}                                                              from '@enums/auth-scope.enum';
import {
  ButtonType,
}                                                              from '@enums/button-type.enum';
import {
  NumberRange,
}                                                              from '@models/entity/number-range.model';
import {
  NumberRangeQueryParams,
}                                                              from '@models/form/number-range-query-params.model';
import {
  ProviderIdentifier,
}                                                              from '@enums/provider-identifier.enum';
import {
  FetchNumberListRequestAction,
  FetchNumberRangeListRequestAction,
}                                                              from '@redux/number/number.actions';
import {
  NumberQueryParams,
}                                                              from '@models/form/number-query-params.model';

@Component({
  selector:    'ngx-sip-phone-modal',
  templateUrl: './sip-phone-modal.component.html',
  styleUrls:   ['./sip-phone-modal.component.scss'],
})
export class SipPhoneModalComponent implements OnDestroy, OnChanges {

  @Input() service: SIPPhone;
  serviceReducer$: Observable<ServiceState>;
  numberReducer$: Observable<NumberState>;
  formGroup: UntypedFormGroup;
  editMode       = new BehaviorSubject<boolean>(false);
  editMode$: Observable<boolean>;
  destroy        = new Subject<void>();
  destroy$       = this.destroy.asObservable();
  containsDupes  = new BehaviorSubject(false);
  containsDupes$ = this.containsDupes.asObservable();
  submitDisabled$: Observable<boolean>;
  btnDisabled$: Observable<boolean>;
  isPending$: Observable<boolean>;
  ButtonType     = ButtonType;

  private sipPhoneData: SIPPhone;
  private formFields = new Map<keyof SIPPhoneRaw, string>([
    ['gateway_protocol', 'Protocol'],
    ['gateway_address', 'Fully qualified domain name'],
    ['label', 'Label'],
  ]);
  private formValueChanged$: Observable<boolean>;
  existingRange: NumberRange;

  constructor(
    private store: Store<StoreState>,
    private changeDetectorRef: ChangeDetectorRef,
    private sipPhoneService: SipPhoneService,
    private scopeService: ScopeService,
    @Optional() public dialogRef: MatDialogRef<SipPhoneModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { service: SIPPhone },
  ) {
    this.serviceReducer$ = this.store.select(selectService);
    this.numberReducer$  = this.store.select(selectNumber);

    this.isPending$ = this.serviceReducer$.pipe(map(reducer =>
      reducer.pendingTasks.some(task => [POST_SERVICE_REQUEST, PATCH_SERVICE_REQUEST].includes(task.id))));

    this.btnDisabled$ = this.scopeService.hasScopes$(AuthScope.ServiceWrite)
      .pipe(map(hasScope => !hasScope));

    if (this.dialogRef) {
      this.initForm(this.data.service);
    }
  }

  private initForm(data: SIPPhone): void {
    if (!this.service) {
      this.service = data;
    }
    this.editMode$ = this.editMode.asObservable()
      .pipe(
        map(edit => edit || this.isSetup()),
        tap(edit => {
          if (edit) {
            this.formGroup?.enable();
            ['username', 'password', 'fqdn'].forEach(ctrl => this.formGroup.get(ctrl)
              .disable());
            this.changeDetectorRef.detectChanges();
            return;
          }
          this.formGroup?.disable();
          this.changeDetectorRef.detectChanges();
        }));

    this.sipPhoneData = this.service as SIPPhone;

    this.formGroup = this.sipPhoneService.getNewForm(this.sipPhoneData);

    if (this.sipPhoneData?.number) {
      this.existingRange               = new NumberRange();
      this.existingRange.id            = this.sipPhoneData.number.range.id;
      this.existingRange.name          = this.sipPhoneData.number.range.name;
      this.existingRange.country       = { name: null, code: this.sipPhoneData.number.range.country.code };
      this.existingRange.outboundRoute = this.sipPhoneData.number.range.outboundRoute;
      this.existingRange.provider      = this.sipPhoneData.number.range.provider;
    }

    this.fetchRangeList();

    this.formGroup.get('rangeId')
      .valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        throttleTime(500))
      .subscribe(() => {
        this.formGroup.get('numberQuery')
          .setValue('');
        this.formGroup.get('numberQuery')
          .updateValueAndValidity();
        this.fetchNumberList();
      });

    this.formGroup.get('rangeQuery')
      .valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        throttleTime(500))
      .subscribe(() => this.fetchRangeList());

    this.setupValidators();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.service?.currentValue && !changes.service?.previousValue) {
      this.initForm(changes.service.currentValue);
    }
  }

  fetchNumberList(query?: string): void {
    const q: NumberQueryParams = new NumberQueryParams();

    q.pageSize   = 50;
    q.pageNumber = 1;
    q.sort       = 'number_e164';

    if (((query as string) || '').length >= 3) {
      q.search = query;
    }
    q.status = ['active'];

    if (this.formGroup.get('rangeId').value) {
      q.rangeId = [this.formGroup.get('rangeId').value];
    }

    this.store.dispatch(FetchNumberListRequestAction({ queryParams: q }));
  }

  fetchRangeList(): void {
    const queryParams: NumberRangeQueryParams = new NumberRangeQueryParams();

    queryParams.pageSize   = 50;
    queryParams.pageNumber = 1;

    if (this.formGroup.get('rangeQuery').value && (this.formGroup.get('rangeQuery').value as string).length >= 3) {
      queryParams.search = this.formGroup.get('rangeQuery')
        .value;
    }

    queryParams.provider = [ProviderIdentifier.Sipsynergy, ProviderIdentifier.Byo];

    this.store.dispatch(FetchNumberRangeListRequestAction({ queryParams }));
  }

  isSetup(): boolean {
    if (!this.sipPhoneData) {
      return true;
    }
    return Object.values(this.sipPhoneData)
      .every(val => !val);
  }

  editClick(): void {
    if (this.editMode.value) {
      this.revertForm();
    }
    this.editMode.next(!this.editMode.value);
  }

  revertForm(): void {
    const data = this.sipPhoneData;
    this.formGroup.reset({
      serviceType:                 ServiceType.SIPPhone,
      protocol:                    data?.protocol,
      fqdn:                        data?.fqdn,
      username:                    data?.username,
      password:                    data?.password,
      number:                      data?.number,
      numberQuery:                 data?.number,
      rangeQuery:                  data?.number.range,
      rangeId:                     data?.number?.range?.id,
      certificatePem:              data?.deviceCertificate?.certificatePem,
      privateKeyPem:               data?.deviceCertificate?.privateKeyPem,
      deviceCertificateExpiryDate: data?.deviceCertificateExpiryDate,
      numberId:                    data?.numberId,
      label:                       data?.label || '',
    });
  }

  async submitForm(): Promise<void> {
    if (this.formGroup.valid || !this.containsDupes.value) {
      this.store.dispatch(DismissErrorAction());
      this.store.dispatch(DismissMessageAction());
      const value      = this.formGroup.value;
      const formFields = this.formFields as unknown as Map<keyof ServiceItemRaw, string>;

      if (this.service) {
        this.store.dispatch(PatchServiceRequestAction({
          serviceItem: { ...value, id: this.service.id },
          formFields,
        }));
        this.formGroup.get('number')
          .setValue(value.numberQuery);
        this.formGroup.get('rangeQuery')
          .setValue(value.rangeQuery);
        this.formGroup.get('rangeId')
          .setValue(value.rangeId);
        this.sipPhoneData = new SIPPhone({
          ...this.sipPhoneData,
          label:    value.label,
          protocol: value.protocol,
          number:   value.numberQuery,
        } as SIPPhone);
        this.setupValidators();
        this.formGroup.markAsUntouched();
        this.editMode.next(false);
      } else {
        this.store.dispatch(PostServiceRequestAction({
          serviceItem: value,
          formFields,
        }));
        await this.serviceReducer$.pipe(
          pluck('message'),
          filter(message => !!message?.isSuccess),
          take(1),
          takeUntil(this.destroy$))
          .toPromise();
        this.dialogRef?.close();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  private setupValidators(): void {
    this.formValueChanged$ = FormService.formValueChanged$(
      this.formGroup,
      this.sipPhoneData,
      [
        'protocol',
        'numberId',
        'label',
      ]);

    this.submitDisabled$ = combineLatest([
      this.containsDupes$,
      this.isPending$,
      FormService.isValid$(this.formGroup),
      this.formGroup.valueChanges.pipe(startWith(this.formGroup.value)),
      this.formValueChanged$])
      .pipe(map(([containsDupes, isPending, valid, _, changed]) => {
        return isPending || containsDupes || !valid || this.formGroup.pristine || !changed;
      }));

  }
}
