import * as CompanyAction from './company.actions';

import { Alert }                                                         from '@models/entity/alert.model';
import { StateTask }                                                     from '@models/entity/state-task.model';
import { BaseState, requestReduce, responseReduce }                      from '../helpers/reducer.helper';
import { Action, createReducer, on }                                     from '@ngrx/store';
import {
  CLEAR_COMPANY_ERROR_AND_MESSAGE,
  DELETE_USER_REQUEST,
  FETCH_COMPANY_NAME_AVAILABILITY_REQUEST,
  FETCH_COMPANY_REQUEST,
  FETCH_COMPANY_SUBORDINATE_LIST_REQUEST,
  FETCH_COMPANY_USER_LIST_REQUEST,
  FETCH_COMPANY_USERS_COUNT_REQUEST,
  FETCH_ROLE_USERS_REQUEST,
  FETCH_SECURITY_GROUP_USERS_LIST_REQUEST,
  PATCH_COMPANY_REQUEST,
  POST_COMPANY_REQUEST,
  POST_COMPANY_USER_REQUEST,
}                                                                        from './company.types';
import * as AuthAction                                                   from '../auth/auth.actions';
import { PatchUserProfileRequestAction, PatchUserProfileResponseAction } from '../auth/auth.actions';
import { Availability }                                                  from '@enums/availability.enum';
import * as UIAction                                                     from '../ui/ui.actions';
import { User }                                                          from '@models/entity/user.model';
import { Company }                                                       from '@models/entity/company.model';
import {
  CustomerQueryParams,
}                                                                        from '@models/form/customer-query-params.model';
import { UserQueryParams }                                               from '@models/form/user-query-params.model';
import { createEntityAdapter, EntityAdapter }                            from '@ngrx/entity';
import { DeleteUserResponse }                                            from '@models/api/delete-user-response.model';
import { UserStatusCounts }                                              from '@models/entity/user-status-counts.model';
import { PATCH_USER_PROFILE_REQUEST }                                    from '@redux/auth/auth.types';

export interface CompanyState extends BaseState<Company> {
  error: Alert;
  message: Alert;
  pending: boolean;
  pendingTasks: Array<StateTask>;
  userQueryParams: UserQueryParams;
  customerQueryParams: CustomerQueryParams;
  ownCompanyData: Company;
  ownCompanyUsers: Array<User>;
  roleUsers: User[];
  roleUsersQuery: UserQueryParams;
  securityGroupUsers: User[];
  securityGroupUsersQuery: UserQueryParams;
  ownCompanySubordinates: Array<Company>;
  user: User;
  userCounts: UserStatusCounts;
  companyCreateResponse: { message: Alert, error: Alert, sessionID: string };
  companyNameAvailability: Availability,
}

export function selectCompanyId(a: Company): string {
  return a.id;
}

export const adapter: EntityAdapter<Company> = createEntityAdapter<Company>({
  selectId: selectCompanyId,
});

const initialState: CompanyState = adapter.getInitialState({
  ids:                     [],
  entities:                {},
  error:                   null,
  message:                 null,
  pending:                 false,
  pendingTasks:            [],
  userQueryParams:         null,
  customerQueryParams:     null,
  ownCompanyData:          null,
  ownCompanyUsers:         null,
  ownCompanySubordinates:  null,
  userCounts:              null,
  user:                    null,
  roleUsers:               null,
  roleUsersQuery:          null,
  securityGroupUsers:      null,
  securityGroupUsersQuery: null,
  companyCreateResponse:   null,
  companyNameAvailability: null,
});

export function deleteUser(res: DeleteUserResponse, state: CompanyState): Partial<CompanyState> {
  if (!res || res.error) {
    return {
      ownCompanyUsers: state.ownCompanyUsers,
    };
  }
  return {
    ownCompanyUsers: state.ownCompanyUsers?.filter(user => user.id !== res.id) || [],
  };
}

const _companyReducer = createReducer(initialState,
  on(CompanyAction.FetchCompanyNameAvailabilityRequestAction, (state, action) =>
    requestReduce(state, action, () => ({
      companyNameAvailability: Availability.Checking,
    }))),
  on(CompanyAction.FetchCompanyNameAvailabilityResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_COMPANY_NAME_AVAILABILITY_REQUEST, (res) =>
      ({ companyNameAvailability: res.availability }))),
  on(CompanyAction.PostCompanyRequestAction, requestReduce),
  on(CompanyAction.PostCompanyResponseAction, (state, action) =>
    responseReduce(state, action, POST_COMPANY_REQUEST, res => ({
      companyNameAvailability: null,
      ownCompanyData:          state.ownCompanyData ? state.ownCompanyData : res.companyData,
      user:                    res.error ? state.user : {
        ...state.user,
        companyId: !state.ownCompanyData && res.companyData ? res.companyData.id : state.user?.companyId,
      } as User,
      companyCreateResponse:   { message: res.message, error: res.error, sessionID: res.sessionID },
    }))),
  on(CompanyAction.FetchCompanyRequestAction, requestReduce),
  on(CompanyAction.FetchCompanyResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_COMPANY_REQUEST, res => ({
      error:          res.error,
      ownCompanyData: res.companyData,
    }))),
  on(CompanyAction.PatchCompanyRequestAction, requestReduce),
  on(CompanyAction.PatchCompanyResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_COMPANY_REQUEST, res => ({
      error:          res.error,
      ownCompanyData: res.error ? state.ownCompanyData : new Company({
        ...res.companyData,
        brand:     state.ownCompanyData.brand,
        requestId: res.requestId,
      }),
    }))),
  on(CompanyAction.FetchRoleUsersListRequestAction, requestReduce),
  on(CompanyAction.FetchRoleUsersListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_ROLE_USERS_REQUEST, res => ({
      roleUsers:      res.models,
      roleUsersQuery: res.error ? state.roleUsersQuery : res.searchParams,
    }))),
  on(CompanyAction.FetchSecurityGroupUsersListRequestAction, requestReduce),
  on(CompanyAction.FetchSecurityGroupUsersListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SECURITY_GROUP_USERS_LIST_REQUEST, res => ({
      securityGroupUsers:      res.models,
      securityGroupUsersQuery: res.error ? state.securityGroupUsersQuery : res.searchParams,
    }))),
  on(CompanyAction.FetchCompanyUsersCountRequestAction, requestReduce),
  on(CompanyAction.FetchCompanyUsersCountResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_COMPANY_USERS_COUNT_REQUEST, res => ({
      userCounts: res.counts,
    }))),
  on(CompanyAction.FetchCompanyUserListRequestAction, requestReduce),
  on(CompanyAction.FetchCompanyUserListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_COMPANY_USER_LIST_REQUEST, res => ({
      ownCompanyUsers: res.models,
      userQueryParams: res.error ? state.userQueryParams : res.searchParams,
    }))),
  on(PatchUserProfileRequestAction, requestReduce),
  on(PatchUserProfileResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_USER_PROFILE_REQUEST, res => ({
      ownCompanyUsers: res.error ? state.ownCompanyUsers : state.ownCompanyUsers?.map(user => {
        if (user.id !== res.user.id) {
          return user;
        }
        return new User({
          ...user,
          ...res.user,
        });
      }) || [],
    }))),
  on(CompanyAction.PostCompanyUserRequestAction, requestReduce),
  on(CompanyAction.PostCompanyUserResponseAction, (state, action) =>
    responseReduce(state, action, POST_COMPANY_USER_REQUEST, res => ({
      message: res.error ? null :
                 new Alert().fromApiMessage(
                   { message: `Invitation sent ${ res.user ? ('to ' + res.user.email) : '' } successfully` },
                 ),
    }))),
  on(CompanyAction.ClearCompanyErrorAndMessageAction, (state, action) =>
    responseReduce(state, action, CLEAR_COMPANY_ERROR_AND_MESSAGE)),
  on(CompanyAction.FetchCompanySubordinateListRequestAction, requestReduce),
  on(CompanyAction.FetchCompanySubordinateListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_COMPANY_SUBORDINATE_LIST_REQUEST, res => ({
      ownCompanySubordinates: res.models,
      customerQueryParams:    res.error ? state.customerQueryParams : res.searchParams,
    }))),
  on(CompanyAction.DeleteUserRequestAction, requestReduce),
  on(CompanyAction.DeleteUserResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_USER_REQUEST, res => deleteUser(res, state))),
  on(CompanyAction.UpdateCompany, (state, { update }) => adapter.updateOne(update, state)),
  on(CompanyAction.AddCompany, (state, { item }) => adapter.addOne(item, state)),
  on(CompanyAction.AddManyCompanies, (state, { items }) => adapter.addMany(items, state)),
  on(CompanyAction.SetAllCompanies, (state, { updates }) => adapter.setAll(updates, state)),
  on(CompanyAction.UpdateManyCompanies, (state, { updates }) => adapter.updateMany(updates, state)),
  on(UIAction.DismissErrorAction, (state) =>
    ({ ...state, error: null })),
  on(UIAction.DismissMessageAction, (state) =>
    ({ ...state, message: null })),
  on(AuthAction.LogoutAction, () =>
    ({ ...initialState })),
);

export function companyReducer(state: CompanyState, action: Action): CompanyState {
  return _companyReducer(state, action);
}
