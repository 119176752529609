import { Injectable }                                             from '@angular/core';
import { Store }                                                  from '@ngrx/store';
import { Actions, createEffect, ofType }                          from '@ngrx/effects';
import { concatMap, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { NotificationService } from '@services/notification.service';

import * as ActionTypes from './notification.actions';
import {
  FetchNotificationCountRequestAction,
  FetchNotificationListRequestAction,
  MarkAllAsReadRequestAction,
  MarkAsReadRequestAction,
  MarkAsReadResponseAction,
  OpenFeedbackRequestAction,
  PollNotificationListRequestAction,
  PollNotificationListResponseAction,
  SubmitFeedbackRequestAction,
  SubmitFeedbackResponseAction,
}                       from './notification.actions';

import {
  FetchNotificationCountResponse,
  FetchNotificationListRequest,
  FetchNotificationListResponse,
  MarkAsReadRequest,
  MarkAsReadResponse,
  NotificationSearchParams,
  PollNotificationListResponse,
}                                            from '@models/entity/notification.model';
import { StoreState }                        from '../store';
import { selectNotificationQuery }           from '@redux/notification/notification.selectors';
import { BaseResponse }                      from '@redux/helpers/reducer.helper';
import { selectCompanyId, selectUserScopes } from '@redux/auth/auth.selectors';
import { withThrottle }                      from '@rxjs/action-throttle.operator';
import { SubmitFeedbackRequest }             from '@models/api/submit-feedback-request.model';
import { SubmitFeedbackResponse }            from '@models/api/submit-feedback-response.model';
import { SubmitFeedbackModalComponent }      from '@dialog/submit-feedback-modal/submit-feedback-modal.component';
import { MatDialog }                         from '@angular/material/dialog';
import { withScopes }                        from '@rxjs/with-scopes.operator';
import { AuthScope }                         from '@enums/auth-scope.enum';


@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions,
    private notificationService: NotificationService,
    private store: Store<StoreState>,
    private dialog: MatDialog,
  ) {}

  openFeedbackRequest$ = createEffect(() => this.actions$.pipe(
    ofType(OpenFeedbackRequestAction),
    tap(() => {
      this.dialog.open(SubmitFeedbackModalComponent, {
        panelClass: 'cr-dialog',
        maxWidth:   '700px',
        minWidth:   '40vw',
      })
        .afterClosed()
        .subscribe((res: { improvements: string, highlights: string, rating: number } | null) => {
          if (!res) {
            return;
          }
          this.store.dispatch(SubmitFeedbackRequestAction(new SubmitFeedbackRequest(res)));
        });
    }),
  ), { dispatch: false });

  submitFeedback$ = createEffect(() => this.actions$.pipe(
    ofType(SubmitFeedbackRequestAction),
    switchMap((req: SubmitFeedbackRequest) => this.notificationService.submitFeedback$(req)),
    map((res: SubmitFeedbackResponse) => SubmitFeedbackResponseAction(res)),
  ));

  fetchNotificationCount$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNotificationCountRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NotificationRead]),
    switchMap(() =>
      this.notificationService.fetchNotificationCount$()
        .pipe(
          map((res: FetchNotificationCountResponse) =>
            ActionTypes.FetchNotificationCountResponseAction(res),
          ),
        ),
    ),
  ));

  fetchNotificationList$ = createEffect(() => this.actions$.pipe(
    ofType(FetchNotificationListRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NotificationRead]),
    withLatestFrom(this.store.select(selectNotificationQuery)),
    switchMap(([req, queryParams]: [FetchNotificationListRequest, NotificationSearchParams]) =>
      this.notificationService.fetchNotificationList$({ ...req, queryParams: req.queryParams || queryParams })),
    map((res: FetchNotificationListResponse) => ActionTypes.FetchNotificationListResponseAction(res)),
  ));

  pollNotificationList$ = createEffect(() => this.actions$.pipe(
    ofType(PollNotificationListRequestAction),
    withThrottle(),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NotificationRead]),
    withLatestFrom(this.store.select(selectCompanyId)),
    filter((_, companyId) => !!companyId),
    switchMap(() =>
      this.notificationService.pollNotificationList$()
        .pipe(
          map((res: PollNotificationListResponse) =>
            ActionTypes.PollNotificationListResponseAction(res),
          ),
        ),
    ),
  ));

  pollNotificationListResponse$ = createEffect(() => this.actions$.pipe(
    ofType(PollNotificationListResponseAction),
    tap((req: PollNotificationListResponse) => {
      if (req.models && req.models.length) {
        this.store.dispatch(ActionTypes.FetchNotificationCountRequestAction({}));
        this.store.dispatch(ActionTypes.FetchNotificationListRequestAction({}));
      }
    }),
  ), { dispatch: false });

  markAllAsRead$ = createEffect(() => this.actions$.pipe(
    ofType(MarkAllAsReadRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NotificationWrite]),
    concatMap(() =>
      this.notificationService.markAllAsRead$()
        .pipe(
          map((res: BaseResponse) =>
            ActionTypes.MarkAllAsReadResponseAction(res),
          ),
        ),
    ),
  ));

  markAsRead$ = createEffect(() => this.actions$.pipe(
    ofType(MarkAsReadRequestAction),
    withScopes(this.store.select(selectUserScopes), [AuthScope.NotificationWrite]),
    concatMap((req: MarkAsReadRequest) =>
      this.notificationService.markAsRead$(req)
        .pipe(
          map((res: MarkAsReadResponse) =>
            ActionTypes.MarkAsReadResponseAction(res),
          ),
        ),
    ),
    tap(() => this.store.dispatch(FetchNotificationListRequestAction({}))),
  ));

  markNotificationResponse$ = createEffect(() => this.actions$.pipe(
    ofType(MarkAsReadResponseAction),
    tap((req: MarkAsReadResponse) => {
      if (!req.error) {
        this.store.dispatch(ActionTypes.FetchNotificationCountRequestAction({}));
      }
    }),
  ), { dispatch: false });
}
