import { StoreState }     from '../store';
import { CompanyState }   from './company.reducer';
import { createSelector } from '@ngrx/store';
import { CountryCode }    from '@models/entity/country-code.model';

export const selectCompany                       = (state: StoreState): CompanyState => state.companyReducer;
export const selectUserQueryParams               = createSelector(selectCompany, state => state.userQueryParams);
export const selectUsers                         = createSelector(selectCompany, state => state.ownCompanyUsers);
export const selectRoleUsers                     = createSelector(selectCompany, state => state.roleUsers);
export const selectRoleUsersQuery                = createSelector(selectCompany, state => state.roleUsersQuery);
export const selectSecurityGroupUsers            = createSelector(selectCompany, state => state.securityGroupUsers);
export const selectSecurityGroupUsersQueryParams = createSelector(selectCompany, state => state.securityGroupUsersQuery);
export const selectCompanyPendingTasks           = createSelector(selectCompany, state => state.pendingTasks);
export const selectCurrencyCode                  = createSelector(selectCompany, state => state.ownCompanyData?.metadata?.balance_summaries?.MONETARY?.symbol || '£');
export const selectUserCompany                   = createSelector(selectCompany, state => state.ownCompanyData);
export const selectUserCompanyCountryCode        = createSelector(selectCompany, state => {
  if (!state.ownCompanyData?.address) {
    return null;
  }
  return new CountryCode().countryISOMapping[state.ownCompanyData.address.countryCode];
});
export const selectCompanyNameAvailability       = createSelector(selectCompany, state => state.companyNameAvailability);
export const selectUserCounts                    = createSelector(selectCompany, state => state.userCounts);
export const selectCompanyName                   = createSelector(selectCompany, state => state.ownCompanyData?.name);
export const selectBranding                      = createSelector(selectCompany, state => state.ownCompanyData?.brand);
