import { Injectable }                from '@angular/core';
import { BreakpointObserver }        from '@angular/cdk/layout';
import { Observable }                from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ViewportService {
  ltXs$: Observable<boolean>;
  ltSm$: Observable<boolean>;
  ltMd$: Observable<boolean>;
  ltLg$: Observable<boolean>;
  ltSmH$: Observable<boolean>;
  gtMd$: Observable<boolean>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.ltXs$  = this.breakpointObserver.observe('(max-width: 599px)')
      .pipe(map(query => query.matches), distinctUntilChanged());
    this.ltSm$  = this.breakpointObserver.observe('(max-width: 959px)')
      .pipe(map(query => query.matches), distinctUntilChanged());
    this.ltMd$  = this.breakpointObserver.observe('(max-width: 1279px)')
      .pipe(map(query => query.matches), distinctUntilChanged());
    this.ltLg$  = this.breakpointObserver.observe('(max-width: 1919px)')
      .pipe(map(query => query.matches), distinctUntilChanged());
    this.ltSmH$ = this.breakpointObserver.observe('(max-height: 601px)')
      .pipe(map(query => query.matches), distinctUntilChanged());
    this.gtMd$  = this.breakpointObserver.observe('(min-width: 1920px)')
      .pipe(map(query => query.matches), distinctUntilChanged());
  }
}
