export const AUTHORISE_RESPONSE = 'AUTHORISE_RESPONSE';

export const LOGIN_WITH_REDIRECT = 'LOGIN_WITH_REDIRECT';

export const OAUTH2_CALLBACK = 'OAUTH2_CALLBACK';

export const VALIDATE_TOKEN_REQUEST  = 'VALIDATE_TOKEN_REQUEST';
export const VALIDATE_TOKEN_RESPONSE = 'VALIDATE_TOKEN_RESPONSE';

export const CREATE_ROLE_REQUEST  = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_RESPONSE = 'CREATE_ROLE_RESPONSE';

export const PUT_ROLE_REQUEST  = 'PUT_ROLE_REQUEST';
export const PUT_ROLE_RESPONSE = 'PUT_ROLE_RESPONSE';

export const FETCH_SCOPES_REQUEST  = 'FETCH_SCOPES_REQUEST';
export const FETCH_SCOPES_RESPONSE = 'FETCH_SCOPES_RESPONSE';

export const FETCH_ROLES_REQUEST  = 'FETCH_ROLES_REQUEST';
export const FETCH_ROLES_RESPONSE = 'FETCH_ROLES_RESPONSE';

export const FETCH_API_KEYS_REQUEST  = 'FETCH_API_KEYS_REQUEST';
export const FETCH_API_KEYS_RESPONSE = 'FETCH_API_KEYS_RESPONSE';

export const FETCH_API_KEY_REQUEST  = 'FETCH_API_KEY_REQUEST';
export const FETCH_API_KEY_RESPONSE = 'FETCH_API_KEY_RESPONSE';

export const DELETE_ROLE_REQUEST  = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_RESPONSE = 'DELETE_ROLE_RESPONSE';

export const FETCH_ROLE_ASSIGNMENT_COUNT_REQUEST  = 'FETCH_ROLE_ASSIGNMENT_COUNT_REQUEST';
export const FETCH_ROLE_ASSIGNMENT_COUNT_RESPONSE = 'FETCH_ROLE_ASSIGNMENT_COUNT_RESPONSE';

export const FETCH_USER_PROFILE_REQUEST  = 'FETCH_USER_PROFILE_REQUEST';
export const FETCH_USER_PROFILE_RESPONSE = 'FETCH_USER_PROFILE_RESPONSE';

export const PATCH_OWN_PROFILE_REQUEST  = 'PATCH_OWN_PROFILE_REQUEST';
export const PATCH_OWN_PROFILE_RESPONSE = 'PATCH_OWN_PROFILE_RESPONSE';

export const PATCH_USER_PROFILE_REQUEST  = 'PATCH_USER_PROFILE_REQUEST';
export const PATCH_USER_PROFILE_RESPONSE = 'PATCH_USER_PROFILE_RESPONSE';

export const ASSIGN_USER_ROLE_REQUEST  = 'ASSIGN_USER_ROLE_REQUEST';
export const ASSIGN_USER_ROLE_RESPONSE = 'ASSIGN_USER_ROLE_RESPONSE';

export const REQUEST_RESET_PASSWORD_REQUEST  = 'REQUEST_RESET_PASSWORD_REQUEST';
export const REQUEST_RESET_PASSWORD_RESPONSE = 'REQUEST_RESET_PASSWORD_RESPONSE';

export const RESET_PASSWORD_REQUEST  = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_RESPONSE = 'RESET_PASSWORD_RESPONSE';

export const REFRESH_SESSION_DATA_REQUEST  = 'REFRESH_SESSION_DATA_REQUEST';
export const REFRESH_SESSION_DATA_RESPONSE = 'REFRESH_SESSION_DATA_RESPONSE';

export const FETCH_COMPANY_INVITE_DESCRIPTION_REQUEST  = 'FETCH_COMPANY_INVITE_DESCRIPTION_REQUEST';
export const FETCH_COMPANY_INVITE_DESCRIPTION_RESPONSE = 'FETCH_COMPANY_INVITE_DESCRIPTION_RESPONSE';

export const ACCEPT_COMPANY_INVITE_REQUEST  = 'ACCEPT_COMPANY_INVITE_REQUEST';
export const ACCEPT_COMPANY_INVITE_RESPONSE = 'ACCEPT_COMPANY_INVITE_RESPONSE';

export const SET_COMPANY_HEADER = 'SET_COMPANY_HEADER';

export const MANUAL_UPDATE_TOKEN_DATA = 'MANUAL_UPDATE_TOKEN_DATA';

export const ENABLE_OTP_REQUEST  = 'ENABLE_OTP_REQUEST';
export const ENABLE_OTP_RESPONSE = 'ENABLE_OTP_RESPONSE';

export const ENABLE_OTP_CONFIRM_REQUEST  = 'ENABLE_OTP_CONFIRM_REQUEST';
export const ENABLE_OTP_CONFIRM_RESPONSE = 'ENABLE_OTP_CONFIRM_RESPONSE';

export const DISABLE_OTP_REQUEST  = 'DISABLE_OTP_REQUEST';
export const DISABLE_OTP_RESPONSE = 'DISABLE_OTP_RESPONSE';

export const CREATE_API_KEY_REQUEST  = 'CREATE_API_KEY_REQUEST';
export const CREATE_API_KEY_RESPONSE = 'CREATE_API_KEY_RESPONSE';

export const PATCH_API_KEY_REQUEST  = 'PATCH_API_KEY_REQUEST';
export const PATCH_API_KEY_RESPONSE = 'PATCH_API_KEY_RESPONSE';

export const DELETE_API_KEY_REQUEST  = 'DELETE_API_KEY_REQUEST';
export const DELETE_API_KEY_RESPONSE = 'DELETE_API_KEY_RESPONSE';

export const CLEAR_SCRATCH_CODE = 'CLEAR_SCRATCH_CODE';

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export const SHOW_SESSION_EXPIRY_MODAL = 'SHOW_SESSION_EXPIRY_MODAL';

export const MARK_SESSION_EXPIRY_MODAL_OPEN = 'MARK_SESSION_EXPIRY_MODAL_OPEN';

export const CLOSE_SESSION_EXPIRY_MODAL = 'CLOSE_SESSION_EXPIRY_MODAL';

export const CLEAR_REDIRECT_URL = 'CLEAR_REDIRECT_URL';

export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';

export const REFRESH_ACCESS_TOKEN_REQUEST  = 'REFRESH_ACCESS_TOKEN_REQUEST';
export const REFRESH_ACCESS_TOKEN_RESPONSE = 'REFRESH_ACCESS_TOKEN_RESPONSE';

export const AUTHORISE_MICROSOFT_SSO_REQUEST  = 'AUTHORISE_MICROSOFT_SSO_REQUEST';
export const AUTHORISE_MICROSOFT_SSO_RESPONSE = 'AUTHORISE_MICROSOFT_SSO_RESPONSE';

export const FETCH_COMPANY_DOMAIN_LIST_REQUEST  = 'FETCH_COMPANY_DOMAIN_LIST_REQUEST';
export const FETCH_COMPANY_DOMAIN_LIST_RESPONSE = 'FETCH_COMPANY_DOMAIN_LIST_RESPONSE';

export const UPDATE_COMPANY_DOMAIN_REQUEST  = 'UPDATE_COMPANY_DOMAIN_REQUEST';
export const UPDATE_COMPANY_DOMAIN_RESPONSE = 'UPDATE_COMPANY_DOMAIN_RESPONSE';

export const FETCH_USER_IDP_IDENTITIES_REQUEST  = 'FETCH_USER_IDP_IDENTITIES_REQUEST';
export const FETCH_USER_IDP_IDENTITIES_RESPONSE = 'FETCH_USER_IDP_IDENTITIES_RESPONSE';

export const FETCH_APPROVAL_LIST_REQUEST  = 'FETCH_APPROVAL_LIST_REQUEST';
export const FETCH_APPROVAL_LIST_RESPONSE = 'FETCH_APPROVAL_LIST_RESPONSE';

export const USER_APPROVAL_REQUEST  = 'USER_APPROVAL_REQUEST';
export const USER_APPROVAL_RESPONSE = 'USER_APPROVAL_RESPONSE';

export const FETCH_USER_COUNTS_REQUEST  = 'FETCH_USER_COUNTS_REQUEST';
export const FETCH_USER_COUNTS_RESPONSE = 'FETCH_USER_COUNTS_RESPONSE';

export const USERS_BULK_REQUEST  = 'USERS_BULK_REQUEST';
export const USERS_BULK_RESPONSE = 'USERS_BULK_RESPONSE';

export const FETCH_TOKEN_INTROSPECT_REQUEST  = 'FETCH_TOKEN_INTROSPECT_REQUEST';
export const FETCH_TOKEN_INTROSPECT_RESPONSE = 'FETCH_TOKEN_INTROSPECT_RESPONSE';

export const RESEND_USER_INVITE_REQUEST  = 'RESEND_USER_INVITE_REQUEST';
export const RESEND_USER_INVITE_RESPONSE = 'RESEND_USER_INVITE_RESPONSE';

export const FETCH_SECURITY_GROUP_LIST_REQUEST  = 'FETCH_SECURITY_GROUP_LIST_REQUEST';
export const FETCH_SECURITY_GROUP_LIST_RESPONSE = 'FETCH_SECURITY_GROUP_LIST_RESPONSE';

export const FETCH_SECURITY_GROUP_REQUEST  = 'FETCH_SECURITY_GROUP_REQUEST';
export const FETCH_SECURITY_GROUP_RESPONSE = 'FETCH_SECURITY_GROUP_RESPONSE';

export const POST_SECURITY_GROUP_REQUEST  = 'POST_SECURITY_GROUP_REQUEST';
export const POST_SECURITY_GROUP_RESPONSE = 'POST_SECURITY_GROUP_RESPONSE';

export const PATCH_SECURITY_GROUP_REQUEST  = 'PATCH_SECURITY_GROUP_REQUEST';
export const PATCH_SECURITY_GROUP_RESPONSE = 'PATCH_SECURITY_GROUP_RESPONSE';

export const DELETE_SECURITY_GROUP_REQUEST  = 'DELETE_SECURITY_GROUP_REQUEST';
export const DELETE_SECURITY_GROUP_RESPONSE = 'DELETE_SECURITY_GROUP_RESPONSE';

export const ASSIGN_USER_SECURITY_GROUP_REQUEST  = 'ASSIGN_USER_SECURITY_GROUP_REQUEST';
export const ASSIGN_USER_SECURITY_GROUP_RESPONSE = 'ASSIGN_USER_SECURITY_GROUP_RESPONSE';

export const LOGOUT = 'LOGOUT';
