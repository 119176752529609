import { Component, Inject, OnDestroy }                                  from '@angular/core';
import { FormControl, FormGroup, Validators }                            from '@angular/forms';
import {
  ProfileMigrationForm,
}                                                                        from '@models/form/profile-migration-form.model';
import { CallingProfile }                                                from '@models/entity/calling-profile.model';
import { combineLatest, Observable, Subject }                            from 'rxjs';
import { selectCallingProfilesSearchResults }                            from '@redux/service/service.selectors';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import {
  CallingProfileQueryParams,
}                                                                        from '@models/form/calling-profile-query-params.model';
import { SearchCallingProfilesRequestAction }                            from '@redux/service/service.actions';
import { StoreState }                                                    from '@redux/store';
import { Store }                                                         from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef }                                 from '@angular/material/dialog';
import {
  MigrateProfilesRequest,
}                                                                        from '@models/api/migrate-profiles-request.model';

@Component({
  selector:    'ngx-profile-migration-modal',
  templateUrl: './profile-migration-modal.component.html',
  styleUrls:   ['./profile-migration-modal.component.scss'],
})
export class ProfileMigrationModalComponent implements OnDestroy {
  formGroup: FormGroup<ProfileMigrationForm>;
  profiles$: Observable<CallingProfile[]>;
  fromProfiles$: Observable<CallingProfile[]>;
  toProfiles$: Observable<CallingProfile[]>;
  destroy  = new Subject<void>();
  destroy$ = this.destroy.asObservable();
  isInvalid$: Observable<boolean>;

  constructor(@Inject(MAT_DIALOG_DATA) private modalData: { serviceId: string, fromProfile: CallingProfile },
              private store: Store<StoreState>,
              private dialogRef: MatDialogRef<ProfileMigrationModalComponent>) {
    this.formGroup = new FormGroup<ProfileMigrationForm>({
      fromProfile: new FormControl<CallingProfile>(this.modalData.fromProfile, Validators.required),
      toProfile:   new FormControl<CallingProfile>(null, Validators.required),
    });
    if (this.modalData.fromProfile) {
      this.formGroup.get('fromProfile')
        .disable();
    }
    this.isInvalid$ = this.formGroup.valueChanges.pipe(
      startWith(this.formGroup.value),
      map(value => {
        return this.formGroup.invalid || typeof value.fromProfile === 'string' || typeof value.toProfile === 'string';
      }));

    this.profiles$ = this.store.select(selectCallingProfilesSearchResults);

    this.fromProfiles$ = combineLatest([this.profiles$, this.formGroup.get('toProfile')
      .valueChanges
      .pipe(startWith(this.formGroup.get('toProfile').value))])
      .pipe(map(([profiles, toProfile]) => profiles?.filter(p => p.id !== toProfile?.id) || []));

    this.toProfiles$ = combineLatest([this.profiles$, this.formGroup.get('fromProfile')
      .valueChanges
      .pipe(startWith(this.formGroup.get('fromProfile').value))])
      .pipe(map(([profiles, fromProfile]) => profiles?.filter(p => p.id !== fromProfile?.id) || []));

    ['fromProfile', 'toProfile'].forEach(ctrl => this.formGroup.get(ctrl)
      .valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        debounceTime(500))
      .subscribe(value => {
        if (typeof value !== 'string') {
          return;
        }
        const q: CallingProfileQueryParams = new CallingProfileQueryParams();

        q.pageSize   = 50;
        q.pageNumber = 1;

        if (value && (value as string).length >= 3) {
          q.search = value;
        }

        q.sort = '-is_system,name';
        this.store.dispatch(SearchCallingProfilesRequestAction({
          serviceId:   this.modalData.serviceId,
          queryParams: q,
        }));
      }));
    this.store.dispatch(SearchCallingProfilesRequestAction({
      serviceId:   this.modalData.serviceId,
      queryParams: { pageSize: 10, pageNumber: 1 },
    }));
  }

  getProfileName(profile: CallingProfile): string {
    return profile?.name || '';
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  submit(): void {
    this.dialogRef.close({
      fromProfileId: this.modalData.fromProfile?.id || this.formGroup.get('fromProfile').value.id,
      toProfileId:   this.formGroup.get('toProfile').value.id,
      serviceId:     this.modalData.serviceId,
    } as MigrateProfilesRequest);
  }

}
