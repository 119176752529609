<div class="header background-orange shadow-soft" fxLayout="column" fxLayoutAlign="center center"
     [style.top]="isViewingCompany ? '58px' : '16px'">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-icon svgIcon="cr-circle-exclamation" class="mr-12"></mat-icon>
    <span>You don't appear to have a breakglass account.
      If SSO fails, you won't be able to login. We recommend creating at least 1 breakglass local account.</span>
    <a class="color-white fw-500"
       target="_blank"
       href="https://help.callroute.com/space/CKB/995459076/Microsoft+Single+Sign+On"
       fxLayout="row"
       fxLayoutAlign="center center" matTooltip="Learn more">
      <mat-icon svgIcon="cr-help-outline"></mat-icon>
    </a>
  </div>
</div>
