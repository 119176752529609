import * as NotificationAction from './notification.actions';

import { Alert }                                      from '@models/entity/alert.model';
import { StateTask }                                  from '@models/entity/state-task.model';
import { NotificationItem, NotificationSearchParams } from '@models/entity/notification.model';
import { Action, createReducer, on }                  from '@ngrx/store';
import { BaseState, requestReduce, responseReduce }   from '../helpers/reducer.helper';
import {
  FETCH_NOTIFICATION_COUNT_REQUEST,
  FETCH_NOTIFICATION_LIST_REQUEST,
  MARK_ALL_AS_READ_REQUEST,
  POLL_NOTIFICATION_LIST_REQUEST,
}                                                     from './notification.types';
import * as AuthAction                                from '../auth/auth.actions';
import * as UIAction                                  from '../ui/ui.actions';
import { createEntityAdapter, EntityAdapter }         from '@ngrx/entity';

export interface NotificationState extends BaseState<NotificationItem> {
  error: Alert;
  message: Alert;
  pending: boolean;
  pendingTasks: Array<StateTask>;
  unreadCount: number;
  notificationQuery: NotificationSearchParams;
  notifications: NotificationItem[];
}

export function selectNotificationId(a: NotificationItem): string {
  return a.id;
}

export const adapter: EntityAdapter<NotificationItem> = createEntityAdapter<NotificationItem>({
  selectId: selectNotificationId,
});

const initialState: NotificationState = adapter.getInitialState({
  error:             null,
  message:           null,
  pending:           false,
  pendingTasks:      [],
  unreadCount:       null,
  notificationQuery: null,
  notifications:     null,
});

export function getMarkNotificationEventType(notificationId: string): string {
  return `MARK_AS_READ_${ notificationId }`;
}

const _notificationReducer = createReducer(initialState,
  on(NotificationAction.FetchNotificationCountRequestAction, requestReduce),
  on(NotificationAction.FetchNotificationCountResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NOTIFICATION_COUNT_REQUEST, res => ({ unreadCount: res.unreadCount }))),
  on(NotificationAction.FetchNotificationListRequestAction, requestReduce),
  on(NotificationAction.FetchNotificationListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_NOTIFICATION_LIST_REQUEST, res => {
      return {
        notificationQuery: res.error ? state.notificationQuery : res.searchParams,
        notifications:     res.error ? state.notifications : res.models,
      };
    })),
  on(NotificationAction.PollNotificationListRequestAction, requestReduce),
  on(NotificationAction.PollNotificationListResponseAction, (state, action) =>
    responseReduce(state, action, POLL_NOTIFICATION_LIST_REQUEST, res =>
      ({
        notifications: res.error || !res.models?.length ? state.notifications : (state.notifications || []).concat(...res.models.map(m => (new NotificationItem({
          ...m,
          isNew: true,
        })))),
      }))),
  on(NotificationAction.MarkAsReadRequestAction, (state, action) =>
    requestReduce(state, {
      ...action,
      type: getMarkNotificationEventType(action.notificationId),
    })),
  on(NotificationAction.MarkAsReadResponseAction, (state, action) =>
    responseReduce(state, action, getMarkNotificationEventType(action.notificationId), res => ({
      notifications: res.error ? state.notifications : state.notifications?.map(val => {
        if (val.id !== res.notificationId) {
          return val;
        }
        return new NotificationItem({
          ...val,
          statusColor: 'light-gray',
          read:        true,
          isNew:       false,
        });
      }),
    }))),
  on(NotificationAction.MarkAllAsReadRequestAction, requestReduce),
  on(NotificationAction.MarkAllAsReadResponseAction, (state, action) =>
    responseReduce(state, action, MARK_ALL_AS_READ_REQUEST, res => ({
      notifications: res.error ? state.notifications : state.notifications?.map(val => (new NotificationItem({
        ...val,
        statusColor: 'light-gray',
        read:        true,
        isNew:       false,
      }))),
    }))),
  on(UIAction.DismissErrorAction, (state) =>
    ({ ...state, error: null })),
  on(UIAction.DismissMessageAction, (state) =>
    ({ ...state, message: null })),
  on(AuthAction.LogoutAction, () =>
    ({ ...initialState })),
);

export function notificationReducer(state: NotificationState, action: Action): NotificationState {
  return _notificationReducer(state, action);
}
