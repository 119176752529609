import {
  ASSIGN_CALL_QUEUE_GROUP_REQUEST,
  ASSIGN_LICENSE_GROUP_REQUEST,
  ASSIGN_SERVICE_USER_NUMBER_REQUEST,
  ASSIGN_TEAM_GROUP_REQUEST,
  CONFIGURE_SERVICE_REQUEST,
  DELETE_AUTOMATION_REQUEST,
  DELETE_CALLING_PROFILE_REQUEST,
  DELETE_SERVICE_REQUEST,
  FETCH_AUTOMATION_LIST_REQUEST,
  FETCH_AUTOMATION_NAME_AVAILABILITY_REQUEST,
  FETCH_AUTOMATION_REQUEST,
  FETCH_AVAILABLE_CARRIERS_REQUEST,
  FETCH_CALLING_PROFILE_REQUEST,
  FETCH_CALLING_PROFILES_REQUEST,
  FETCH_CARRIERS_LIST_REQUEST,
  FETCH_EMERGENCY_LOCATIONS_LIST_REQUEST,
  FETCH_GATEWAY_STATUS_EVENTS_REQUEST,
  FETCH_PERSONA_NAME_AVAILABILITY_REQUEST,
  FETCH_POLICY_LIST_REQUEST,
  FETCH_PROVISIONING_SERVICES_REQUEST,
  FETCH_PSTN_SERVICE_UUID_REQUEST,
  FETCH_SERVICE_COUNTS_REQUEST,
  FETCH_SERVICE_LIST_REQUEST,
  FETCH_SERVICE_REQUEST,
  FETCH_SERVICE_SCHEMA_REQUEST,
  FETCH_SERVICE_USER_CLI_AVAILABILITY_REQUEST,
  FETCH_SERVICE_USER_COUNTS_BY_VOICE_TYPE_REQUEST,
  FETCH_SERVICE_USER_LIST_REQUEST,
  MIGRATE_PROFILES_REQUEST,
  PATCH_AUTOMATION_REQUEST,
  PATCH_SERVICE_REQUEST,
  POST_AUTOMATION_REQUEST,
  POST_SERVICE_REQUEST,
  PUT_CALLING_PROFILE_REQUEST,
  SEARCH_CALLING_PROFILES_REQUEST,
  SEND_CUSTOM_CARRIER_REQUEST,
  SYNC_AD_GROUPS_REQUEST,
  SYNC_ALL_MICROSOFT_ASSETS_REQUEST,
  SYNC_CALL_QUEUES_REQUEST,
  SYNC_DOMAINS_REQUEST,
  SYNC_LICENSES_REQUEST,
  SYNC_NUMBERS_REQUEST,
  SYNC_POLICIES_REQUEST,
  SYNC_SERVICE_USER_LIST_REQUEST,
  SYNC_TEAMS_REQUEST,
}                                                   from '@redux/service/service.types';
import { StateTask }                                from '@models/entity/state-task.model';
import { BaseState, requestReduce, responseReduce } from '@redux/helpers/reducer.helper';
import { Action, createReducer, on }                from '@ngrx/store';
import { ProvisioningService }                      from '@services/provisioning.service';
import { Alert }                                    from '@models/entity/alert.model';
import { ServiceItem }                              from '@models/entity/service-item.model';
import { MicrosoftTeams }                           from '@models/entity/microsoft-teams.model';
import * as ServiceAction                           from '@redux/service/service.actions';
import * as UIAction                                from '@redux/ui/ui.actions';
import * as AuthAction                              from '@redux/auth/auth.actions';
import { ServiceStatus }                            from '@enums/service-status.enum';
import { StatusItem }                               from '@models/entity/status-item.model';
import { ProvisionStep }                            from '@enums/provisioning-step.enum';
import { createEntityAdapter, EntityAdapter }       from '@ngrx/entity';
import { ServiceQueryParams }                       from '@models/form/service-query-params.model';
import { ServiceCounts }                            from '@models/entity/service-counts.model';
import { ServiceUserStatusCounts }                  from '@models/api/service-user-status-counts';
import { MicrosoftTeamsUser }                       from '@models/entity/microsoft-teams-user.model';
import { ServiceType }                              from '@enums/service-type.enum';
import { Carrier }                                  from '@models/entity/carrier.model';
import { ServiceCarrier }                           from '@models/entity/carrier-service.model';
import { GatewayStatusEvent }                       from '@models/entity/gateway-status-event.model';
import { GatewayStatusQueryParams }                 from '@models/form/gateway-status-query-params.model';
import { getServiceIcon }                           from '@models/api/service.model';
import { CallingProfile }                           from '@models/entity/calling-profile.model';
import { CallingProfileQueryParams }                from '@models/form/calling-profile-query-params.model';
import { PolicyList }                               from '@models/entity/policy-list.model';
import { TeamsUserQueryParams }                     from '@models/form/teams-user-query-params.model';
import { Availability }                             from '@enums/availability.enum';
import { Automation }                               from '@models/entity/automation.model';
import { AutomationQueryParams }                    from '@models/form/automation-query-params.model';
import { ServiceSchema }                            from '@models/entity/service-schema.model';
import {
  deleteService,
  markAsProcessing,
  patchAutomation,
  patchCallingProfile,
  patchService,
  postAutomation,
  postCallingProfile,
  postService,
  updateSelectedServiceItem,
  updateServiceItemFromList,
  updateServiceUserViaList,
  updateUserSyncStatus,
}                                                   from './service.helper';
import { removeItemFromList, updateItemInList }     from '@redux/helpers';
import { CarrierQueryParams }                       from '@models/form/carrier-query-params.model';
import { ServiceCapabilityIdentifier }              from '@enums/service-capability-identifier.enum';
import {
  FetchServiceUserCliAvailabilityResponse,
}                                                   from '@models/api/service/fetch-service-user-cli-availability-response.model';
import { ServiceUIStatus }                          from '@enums/service-ui-status';
import { EmergencyLocation }                        from '@models/entity/emergency-location.model';
import { EmergencyLocationQueryParams }             from '@models/form/emergency-location-query-params.model';

export interface ServiceState extends BaseState<ServiceItem> {
  error: Alert;
  message: Alert;
  pending: boolean;
  counts: ServiceCounts;
  pendingTasks: StateTask[];
  serviceItems: ServiceItem[];
  serviceQueryParams: ServiceQueryParams;
  selectedServiceItem: ServiceItem;
  serviceUserCount: { [serviceId: string]: ServiceUserStatusCounts };
  selectedServiceUserList: MicrosoftTeamsUser[];
  selectedServiceUserQueryParams: TeamsUserQueryParams;
  selectedServiceUser: MicrosoftTeamsUser;
  provisioningServices: ServiceItem[];
  serviceUserAlerted: boolean;
  availableCarriers: Carrier[];
  availableCarriersQueryParams: CarrierQueryParams;
  carriers: ServiceCarrier[];
  PSTNServiceUuid: string;
  carriersQueryParams: ServiceQueryParams;
  customCarrierResult: {
    id: string;
    success: boolean;
  };
  gatewayStatusEvents: GatewayStatusEvent[];
  gatewayStatusEventsQueryParams: GatewayStatusQueryParams;
  policyList: PolicyList;
  callingProfiles: CallingProfile[];
  callingProfilesSearchResult: CallingProfile[];
  selectedCallingProfile: CallingProfile;
  callingProfilesQueryParams: CallingProfileQueryParams;
  personaNameAvailability: Availability;
  automationNameAvailability: Availability;
  automations: Automation[];
  selectedAutomation: Automation;
  selectedServiceSchema: ServiceSchema;
  automationQueryParams: AutomationQueryParams;
  availableServices: {
    label: string;
    type: ServiceType,
    icon: string,
    capabilities?: ServiceCapabilityIdentifier[]
  }[];
  serviceUserCLIAvailability: FetchServiceUserCliAvailabilityResponse;
  emergencyLocations: EmergencyLocation[];
  emergencyLocationQueryParams: EmergencyLocationQueryParams;
}

export function selectServiceId(a: ServiceItem): string {
  return a.id;
}

export const adapter: EntityAdapter<ServiceItem> = createEntityAdapter<ServiceItem>({
  selectId: selectServiceId,
});

const defaultState: ServiceState = adapter.getInitialState({
  error:                          null,
  message:                        null,
  pending:                        false,
  pendingTasks:                   [],
  counts:                         null,
  serviceItems:                   null,
  selectedServiceItem:            null,
  serviceQueryParams:             null,
  selectedServiceUserList:        null,
  selectedServiceUserQueryParams: null,
  selectedServiceUser:            null,
  serviceUserCount:               {},
  provisioningServices:           [],
  serviceUserAlerted:             false,
  availableCarriers:              null,
  availableCarriersQueryParams:   null,
  carriers:                       null,
  carriersQueryParams:            null,
  PSTNServiceUuid:                null,
  customCarrierResult:            null,
  gatewayStatusEvents:            null,
  gatewayStatusEventsQueryParams: null,
  policyList:                     null,
  selectedAutomation:             null,
  callingProfiles:                null,
  callingProfilesSearchResult:    null,
  selectedCallingProfile:         null,
  selectedServiceSchema:          null,
  automations:                    null,
  automationQueryParams:          null,
  callingProfilesQueryParams:     null,
  personaNameAvailability:        null,
  automationNameAvailability:     null,
  availableServices:              [
    {
      label:        'Callroute for Microsoft Teams',
      type:         ServiceType.MicrosoftTeams,
      icon:         getServiceIcon(ServiceType.MicrosoftTeams, true),
      capabilities: [ServiceCapabilityIdentifier.Voice, ServiceCapabilityIdentifier.Orto],
    },
    {
      label:        'Orto for Microsoft Teams',
      type:         ServiceType.MicrosoftTeams,
      icon:         getServiceIcon(ServiceType.MicrosoftTeams, null, true),
      capabilities: [ServiceCapabilityIdentifier.Orto],
    },
    { label: 'Webex Calling', type: ServiceType.WebexCalling, icon: getServiceIcon(ServiceType.WebexCalling, true) },
    {
      label: 'Connect a PBX, ATA or Contact Centre',
      type:  ServiceType.SIPTrunk,
      icon:  getServiceIcon(ServiceType.SIPTrunk, true),
    },
    { label: 'SIP Phone', type: ServiceType.SIPPhone, icon: getServiceIcon(ServiceType.SIPPhone, true) },
  ],
  serviceUserCLIAvailability:     null,
  emergencyLocations:             null,
  emergencyLocationQueryParams:   null,
});

const _serviceReducer = createReducer(defaultState,
  on(ServiceAction.FetchServiceRequestAction, requestReduce),
  on(ServiceAction.FetchServiceResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SERVICE_REQUEST, res => ({
      selectedServiceItem: res.serviceItem,
      serviceItems:        updateItemInList(state.serviceItems, res.serviceItem),
    }))),
  on(ServiceAction.FetchPersonaNameAvailabilityRequestAction, (state, action) =>
    requestReduce(state, action, () => ({
      personaNameAvailability: Availability.Checking,
    }))),
  on(ServiceAction.FetchPersonaNameAvailabilityResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_PERSONA_NAME_AVAILABILITY_REQUEST, (res) =>
      ({ personaNameAvailability: res.availability }))),
  on(ServiceAction.FetchAutomationNameAvailabilityRequestAction, (state, action) =>
    requestReduce(state, action, () => ({
      automationNameAvailability: Availability.Checking,
    }))),
  on(ServiceAction.FetchAutomationNameAvailabilityResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_AUTOMATION_NAME_AVAILABILITY_REQUEST, (res) =>
      ({ automationNameAvailability: res.availability }))),
  on(ServiceAction.FetchServiceUserCLIAvailabilityRequestAction, requestReduce),
  on(ServiceAction.FetchServiceUserCLIAvailabilityResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SERVICE_USER_CLI_AVAILABILITY_REQUEST, res => {
      return {
        serviceUserCLIAvailability: res.error ? state.serviceUserCLIAvailability : {
          cli:                    res.cli,
          extension:              res.extension,
          hasExtensionAssignment: res.hasExtensionAssignment,
          hasDirectAssignment:    res.hasDirectAssignment,
          hasExactMatch:          res.hasExactMatch,
          exactMatchDisplayName:  res.exactMatchDisplayName,
        },
      };
    })),
  on(ServiceAction.SendCustomCarrierRequestAction, requestReduce),
  on(ServiceAction.SendCustomCarrierResponseAction, (state, action) =>
    responseReduce(state, action, SEND_CUSTOM_CARRIER_REQUEST, (res) => {
      return {
        customCarrierResult: {
          id:      res.requestId,
          success: !res.error,
        },
      };
    })),
  on(ServiceAction.FetchAvailableCarriersRequestAction, requestReduce),
  on(ServiceAction.FetchAvailableCarriersResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_AVAILABLE_CARRIERS_REQUEST, res => ({
      availableCarriers:            res.error ? state.availableCarriers : res.models,
      availableCarriersQueryParams: res.error ? state.availableCarriersQueryParams : res.searchParams,
    }))),
  on(ServiceAction.MigrateProfilesRequestAction, requestReduce),
  on(ServiceAction.MigrateProfilesResponseAction, (state, action) =>
    responseReduce(state, action, MIGRATE_PROFILES_REQUEST)),
  on(ServiceAction.FetchCarrierListRequestAction, (state, action) =>
    requestReduce(state, action)),
  on(ServiceAction.FetchCarrierListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CARRIERS_LIST_REQUEST, res => ({
      carriers:            res.serviceItems as ServiceCarrier[],
      selectedServiceItem: res.serviceItems?.find(item => item.id === state.selectedServiceItem?.id) || state.selectedServiceItem,
      carriersQueryParams: res.error ? state.carriersQueryParams : res.searchParams,
    }))),
  on(ServiceAction.FetchServiceListRequestAction, (state, action) =>
    requestReduce(state, action)),
  on(ServiceAction.FetchServiceListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SERVICE_LIST_REQUEST, res => ({
      serviceItems:        res.serviceItems,
      selectedServiceItem: res.serviceItems?.find(item => item.id === state.selectedServiceItem?.id) || state.selectedServiceItem,
      serviceQueryParams:  res.error ? state.serviceQueryParams : res.searchParams,
    }))),
  on(ServiceAction.SyncNumbersRequestAction, requestReduce),
  on(ServiceAction.SyncNumbersResponseAction, (state, action) =>
    responseReduce(state, action, SYNC_NUMBERS_REQUEST)),
  on(ServiceAction.FetchPSTNServiceUuidRequestAction, (state, action) =>
    requestReduce(state, action)),
  on(ServiceAction.FetchPSTNServiceUuidResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_PSTN_SERVICE_UUID_REQUEST, res => ({
      PSTNServiceUuid: res.serviceItems?.[0]?.id,
    }))),
  on(ServiceAction.FetchProvisioningServicesRequestAction, (state, action) =>
    requestReduce(state, action)),
  on(ServiceAction.FetchProvisioningServicesResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_PROVISIONING_SERVICES_REQUEST, res => ({
      provisioningServices: res.serviceItems?.filter(item => item.serviceType !== ServiceType.CallForwarding) || [],
    }))),
  on(ServiceAction.FetchServiceCountsRequestAction, requestReduce),
  on(ServiceAction.FetchServiceCountsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SERVICE_COUNTS_REQUEST, res => ({
      counts: res.counts,
    }))),
  on(ServiceAction.FetchGatewayStatusEventsRequestAction, requestReduce),
  on(ServiceAction.FetchGatewayStatusEventsResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_GATEWAY_STATUS_EVENTS_REQUEST, res => ({
      gatewayStatusEvents:            res.events,
      gatewayStatusEventsQueryParams: res.searchParams,
    }))),
  on(ServiceAction.SearchCallingProfilesRequestAction, requestReduce),
  on(ServiceAction.SearchCallingProfilesResponseAction, (state, action) =>
    responseReduce(state, action, SEARCH_CALLING_PROFILES_REQUEST, res => ({
      callingProfilesSearchResult: res.data,
    }))),
  on(ServiceAction.FetchCallingProfilesRequestAction, requestReduce),
  on(ServiceAction.FetchCallingProfilesResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALLING_PROFILES_REQUEST, res => ({
      callingProfiles:            res.data,
      callingProfilesQueryParams: res.searchParams,
    }))),
  on(ServiceAction.FetchCallingProfileRequestAction, requestReduce),
  on(ServiceAction.FetchCallingProfileResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_CALLING_PROFILE_REQUEST, res => ({
      selectedCallingProfile: res.data,
    }))),
  on(ServiceAction.FetchAutomationListRequestAction, requestReduce),
  on(ServiceAction.FetchAutomationListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_AUTOMATION_LIST_REQUEST, res => ({
      automations:           res.data,
      automationQueryParams: res.searchParams,
    }))),
  on(ServiceAction.FetchEmergencyLocationListRequestAction, requestReduce),
  on(ServiceAction.FetchEmergencyLocationListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_EMERGENCY_LOCATIONS_LIST_REQUEST, res => ({
      emergencyLocations:           res.data,
      emergencyLocationQueryParams: res.queryParams,
    }))),
  on(ServiceAction.FetchAutomationRequestAction, requestReduce),
  on(ServiceAction.FetchAutomationResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_AUTOMATION_REQUEST, res => ({
      selectedAutomation: res.data,
    }))),
  on(ServiceAction.FetchServiceSchemaRequestAction, requestReduce),
  on(ServiceAction.FetchServiceSchemaResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SERVICE_SCHEMA_REQUEST, res => ({
      selectedServiceSchema: res.data,
    }))),
  on(ServiceAction.PutCallingProfileRequestAction, requestReduce),
  on(ServiceAction.PutCallingProfileResponseAction, (state, action) =>
    responseReduce(state, action, PUT_CALLING_PROFILE_REQUEST, res => {
      if (action.isPut) {
        return patchCallingProfile(state, res.error, res.data);
      }
      return postCallingProfile(state, res.error, res.data);
    })),
  on(ServiceAction.PostAutomationRequestAction, requestReduce),
  on(ServiceAction.PostAutomationResponseAction, (state, action) =>
    responseReduce(state, action, POST_AUTOMATION_REQUEST, res => {
      return postAutomation(state, res.error, res.data);
    })),
  on(ServiceAction.PatchAutomationRequestAction, requestReduce),
  on(ServiceAction.PatchAutomationResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_AUTOMATION_REQUEST, res => {
      return patchAutomation(state, res.error, res.data, res.updatedList);
    })),
  on(ServiceAction.DeleteServiceRequestAction, requestReduce),
  on(ServiceAction.DeleteServiceResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_SERVICE_REQUEST, res => deleteService(state, res))),
  on(ServiceAction.DeleteCallingProfileRequestAction, requestReduce),
  on(ServiceAction.DeleteCallingProfileResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_CALLING_PROFILE_REQUEST, res => ({
      selectedCallingProfile: !res.error && state.selectedCallingProfile?.id === res.id ? new CallingProfile({
        ...state.selectedCallingProfile,
        status: ServiceStatus.Deleted,
      }) : state.selectedCallingProfile,
      callingProfiles:        !res.error ? state.callingProfiles?.map(profile => {
        if (profile.id === res.id) {
          return new CallingProfile({ ...profile, status: ServiceStatus.Deleted });
        }
        return profile;
      }) : state.callingProfiles,
    }))),
  on(ServiceAction.DeleteAutomationRequestAction, requestReduce),
  on(ServiceAction.DeleteAutomationResponseAction, (state, action) =>
    responseReduce(state, action, DELETE_AUTOMATION_REQUEST, res => ({
      selectedAutomation: !res.error && state.selectedAutomation?.id === res.id ? null : state.selectedAutomation,
      automations:        removeItemFromList(state.automations, res),
    }))),
  on(ServiceAction.ConfigureServiceRequestAction, (state, action) =>
    requestReduce(state, action, (req) => ({
      serviceItems:        updateServiceItemFromList(state.serviceItems, req.id, null,
        (service) => ({
          provisionStatus: new StatusItem(
            service.provisionStatus.status === ServiceUIStatus.Success ? service.provisionStatus.status : ServiceStatus.Provisioning,
            ProvisionStep.Configure, service.serviceType),
        })),
      selectedServiceItem: updateSelectedServiceItem(state, null,
        (service) => ({
          provisionStatus: new StatusItem(
            service.provisionStatus.status === ServiceUIStatus.Success ? service.provisionStatus.status : ServiceStatus.Provisioning,
            ProvisionStep.Configure, service.serviceType),
        })),
    }))),
  on(ServiceAction.ConfigureServiceResponseAction, (state, action) =>
    responseReduce(state, action, CONFIGURE_SERVICE_REQUEST, (res) => {
      if (res.cancelled) {
        return {};
      }
      const teamsService: MicrosoftTeams = state.selectedServiceItem as MicrosoftTeams;
      if (res.authFailed && teamsService.isActive()) {
        return {};
      }
      const serviceItem  = res.error ? ProvisioningService.setProvisioningError(teamsService, res.error.message) : teamsService;
      const serviceItems = (state.serviceItems?.filter((item: ServiceItem) => !ServiceItem.isMicrosoft(item)) || []).concat(serviceItem);
      return {
        serviceItems,
        selectedServiceItem: serviceItem,
      };
    })),
  on(ServiceAction.PostServiceRequestAction, (state, action) =>
    requestReduce(state, action)),
  on(ServiceAction.PostServiceResponseAction, (state, action) =>
    responseReduce(state, action, POST_SERVICE_REQUEST, res => postService(state, res.error, res.serviceItem))),
  on(ServiceAction.PatchServiceRequestAction, (state, action) =>
    requestReduce(state, action)),
  on(ServiceAction.PatchServiceResponseAction, (state, action) =>
    responseReduce(state, action, PATCH_SERVICE_REQUEST, res => patchService(state, res.error, res.serviceItem))),
  on(ServiceAction.FetchServiceUserCountsByVoiceTypeRequestAction, requestReduce),
  on(ServiceAction.FetchServiceUserCountsByVoiceTypeResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SERVICE_USER_COUNTS_BY_VOICE_TYPE_REQUEST, res => {
      if (res.error) {
        return {};
      }
      const serviceUserCount          = { ...(state.serviceUserCount || {}) };
      serviceUserCount[res.serviceId] = res.data;
      return {
        serviceUserCount,
      };
    }),
  ),
  on(ServiceAction.FetchServiceUserListRequestAction, (state, action) =>
    requestReduce(state, action)),
  on(ServiceAction.FetchServiceUserListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_SERVICE_USER_LIST_REQUEST, res => {
      return {
        selectedServiceUserList:        res.error ? state.selectedServiceUserList : res.models.map(u => {
          const existingUser = state.selectedServiceUserList?.find(user => user.id === u.id);
          if (!existingUser) {
            return u;
          }
          if (!u.isProcessing) {
            return new MicrosoftTeamsUser({
              userDataSource: existingUser.userDataSource,
              ...u,
              isFailed:       existingUser.isFailed,
              failureMessage: existingUser.failureMessage,
            });
          }
          return new MicrosoftTeamsUser({
            userDataSource: existingUser.userDataSource,
            ...u,
            isFailed:       existingUser.isFailed,
            failureMessage: existingUser.failureMessage,
            isSyncing:      existingUser.isSyncing && u.isProcessing,
          });
        }),
        selectedServiceUserQueryParams: res.searchParams,
        selectedServiceUser:            updateServiceUserViaList(res, state.selectedServiceUser),
      };
    })),
  on(ServiceAction.FetchPolicyListRequestAction, requestReduce),
  on(ServiceAction.FetchPolicyListResponseAction, (state, action) =>
    responseReduce(state, action, FETCH_POLICY_LIST_REQUEST, res => ({
      policyList: res.policies,
    }))),
  on(ServiceAction.SyncServiceUserListRequestAction, requestReduce),
  on(ServiceAction.SyncServiceUserListResponseAction, (state, action) =>
    responseReduce(state, action, SYNC_SERVICE_USER_LIST_REQUEST, res => updateUserSyncStatus(state, res))),
  on(ServiceAction.FetchServiceUserRequestAction, requestReduce),
  on(ServiceAction.FetchServiceUserResponseAction, (state, action) =>
    responseReduce(state, action, SYNC_SERVICE_USER_LIST_REQUEST, res => ({
      selectedServiceUser: res.error ? state.selectedServiceUser : res.data,
    }))),
  on(ServiceAction.SyncPoliciesRequestAction, requestReduce),
  on(ServiceAction.SyncPoliciesResponseAction, (state, action) =>
    responseReduce(state, action, SYNC_POLICIES_REQUEST, res => markAsProcessing(state, res))),
  on(ServiceAction.SyncLicensesRequestAction, requestReduce),
  on(ServiceAction.SyncLicensesResponseAction, (state, action) =>
    responseReduce(state, action, SYNC_LICENSES_REQUEST, res => markAsProcessing(state, res))),
  on(ServiceAction.SyncTeamsRequestAction, requestReduce),
  on(ServiceAction.SyncTeamsResponseAction, (state, action) =>
    responseReduce(state, action, SYNC_TEAMS_REQUEST, res => markAsProcessing(state, res))),
  on(ServiceAction.SyncAdGroupsRequestAction, requestReduce),
  on(ServiceAction.SyncAdGroupsResponseAction, (state, action) =>
    responseReduce(state, action, SYNC_AD_GROUPS_REQUEST, res => markAsProcessing(state, res))),
  on(ServiceAction.SyncAllMicrosoftAssetsRequestAction, requestReduce),
  on(ServiceAction.SyncAllMicrosoftAssetsResponseAction, (state, action) =>
    responseReduce(state, action, SYNC_ALL_MICROSOFT_ASSETS_REQUEST, res => markAsProcessing(state, res))),
  on(ServiceAction.SyncCallQueuesRequestAction, requestReduce),
  on(ServiceAction.SyncCallQueuesResponseAction, (state, action) =>
    responseReduce(state, action, SYNC_CALL_QUEUES_REQUEST, res => markAsProcessing(state, res))),
  on(ServiceAction.SyncDomainsRequestAction, requestReduce),
  on(ServiceAction.SyncDomainsResponseAction, (state, action) =>
    responseReduce(state, action, SYNC_DOMAINS_REQUEST, res => markAsProcessing(state, res))),
  on(ServiceAction.AssignServiceUserNumberRequestAction, (state, action) =>
    requestReduce(state, action, req => {
      return {
        selectedServiceUserList: state.selectedServiceUserList?.map(user => {
          if (user.id !== req.userId) {
            return user;
          }
          return new MicrosoftTeamsUser({ ...user, isFailed: false, failureMessage: null });
        }) || [],
        selectedServiceUser:     state.selectedServiceUser ? new MicrosoftTeamsUser({
          ...state.selectedServiceUser,
          isFailed:       false,
          failureMessage: null,
        }) : state.selectedServiceUser,
      };
    })),
  on(ServiceAction.AssignServiceUserNumberResponseAction, (state, action) =>
    responseReduce(state, action, ASSIGN_SERVICE_USER_NUMBER_REQUEST, res => ({
      selectedServiceUserList: !res.error && res.userArray && res.userArray.length &&
                               state.selectedServiceUserList?.length ? state.selectedServiceUserList.map(existingUser => {
        const user = res.userArray.find(f => f.id === existingUser.id);
        return user ? user : existingUser;
      }) : state.selectedServiceUserList?.map(existingUser => {
        if (existingUser.id === action.userId) {
          return new MicrosoftTeamsUser({
            ...existingUser,
            isFailed:       true,
            failureMessage: res.error.message,
          });
        }
        return existingUser;
      }) || [],
      selectedServiceUser:     res.error ? new MicrosoftTeamsUser({
        ...state.selectedServiceUser,
        isFailed:       true,
        failureMessage: res.error.message,
      }) : res.userArray?.[0] || state.selectedServiceUser,
    }))),
  on(ServiceAction.AssignLicenseGroupRequestAction, requestReduce),
  on(ServiceAction.AssignLicenseGroupResponseAction, (state, action) => responseReduce(state, action, ASSIGN_LICENSE_GROUP_REQUEST, res => ({
    selectedServiceUserList: res.error ? state.selectedServiceUserList : state.selectedServiceUserList?.map(user => {
      if (user.id !== res.userId) {
        return user;
      }
      return new MicrosoftTeamsUser({
        ...user,
        licenseGroupIds: res.licenseGroupIds,
        licenseGroups:   res.licenseGroups,
        requestId:       res.requestId,
        usageLocation:   res.usageLocation,
      });
    }) || [],
    selectedServiceUser:     !res.error && state.selectedServiceUser ? new MicrosoftTeamsUser({
      ...state.selectedServiceUser,
      licenseGroupIds: res.licenseGroupIds,
      licenseGroups:   res.licenseGroups,
      requestId:       res.requestId,
      usageLocation:   res.usageLocation,
    }) : state.selectedServiceUser,
  }))),
  on(ServiceAction.AssignTeamGroupRequestAction, requestReduce),
  on(ServiceAction.AssignTeamGroupResponseAction, (state, action) => responseReduce(state, action, ASSIGN_TEAM_GROUP_REQUEST, res => ({
    selectedServiceUserList: res.error ? state.selectedServiceUserList : state.selectedServiceUserList?.map(user => {
      if (user.id !== res.userId) {
        return user;
      }
      return new MicrosoftTeamsUser({
        ...user,
        teamGroupIds:   res.teamGroupIds,
        teamGroups:     res.teamGroups,
        teamGroupRoles: res.teamGroupRoles,
        requestId:      res.requestId,
      });
    }) || [],
    selectedServiceUser:     !res.error && state.selectedServiceUser ? new MicrosoftTeamsUser({
      ...state.selectedServiceUser,
      teamGroupIds:   res.teamGroupIds,
      teamGroups:     res.teamGroups,
      teamGroupRoles: res.teamGroupRoles,
      requestId:      res.requestId,
    }) : state.selectedServiceUser,
  }))),
  on(ServiceAction.AssignCallQueueGroupRequestAction, requestReduce),
  on(ServiceAction.AssignCallQueueGroupResponseAction, (state, action) => responseReduce(state, action, ASSIGN_CALL_QUEUE_GROUP_REQUEST, res => ({
    selectedServiceUserList: res.error ? state.selectedServiceUserList : state.selectedServiceUserList?.map(user => {
      if (user.id !== res.userId) {
        return user;
      }
      return new MicrosoftTeamsUser({
        ...user,
        callQueueGroupIds: res.callQueueGroupIds,
        callQueueGroups:   res.callQueueGroups,
        requestId:         res.requestId,
      });
    }) || [],
    selectedServiceUser:     !res.error && state.selectedServiceUser ? new MicrosoftTeamsUser({
      ...state.selectedServiceUser,
      callQueueGroupIds: res.callQueueGroupIds,
      callQueueGroups:   res.callQueueGroups,
      requestId:         res.requestId,
    }) : state.selectedServiceUser,
  }))),
  on(ServiceAction.PromptServiceUserAction,
    (state) => ({
      ...state,
      serviceUserAlerted: true,
    })),
  on(ServiceAction.ClearServiceUserListAction,
    (state) => ({
      ...state,
      selectedServiceUserList: null,
    })),
  on(UIAction.DismissErrorAction, (state) =>
    ({ ...state, error: null })),
  on(UIAction.DismissMessageAction, (state) =>
    ({ ...state, message: null })),
  on(AuthAction.LogoutAction,
    () => ({ ...defaultState })),
);

export function serviceReducer(state: ServiceState, action: Action): ServiceState {
  return _serviceReducer(state, action);
}
