import { Injectable }           from '@angular/core';
import { ServiceAction }        from '@services/abstract/service-action.class';
import { RouterService }        from '@services/router.service';
import { ServiceActionService } from '@services/service-action.service';
import { ServiceCarrier }       from '@models/entity/carrier-service.model';
import { AuthScope }            from '@enums/auth-scope.enum';
import { ServiceTab }           from '@enums/service-tab.enum';
import { ServiceType }          from '@enums/service-type.enum';
import { MatDialog }            from '@angular/material/dialog';
import {
  CarrierInformationModalComponent,
}                               from '@dialog/carrier-information-modal/carrier-information-modal.component';
import { Carrier }              from '@models/entity/carrier.model';

@Injectable({
  providedIn: 'root',
})
export class CarrierActionService implements ServiceAction {

  constructor(private router: RouterService,
              private serviceActionService: ServiceActionService,
              private dialog: MatDialog) { }

  view(serviceItem: ServiceCarrier, scopes: AuthScope[], hash?: ServiceTab): Promise<boolean> {
    return this.serviceActionService.view(serviceItem, scopes, hash);
  }

  viewList(hasScope: boolean, serviceCount: number): Promise<boolean> {
    if (!hasScope || !serviceCount) {
      return;
    }
    return this.router.navigate(['/carriers', 'my-carriers']);
  }

  async create(carrier: Carrier, hasScope: boolean): Promise<void> {
    const result: ServiceCarrier | void = await this.serviceActionService.create<ServiceCarrier, Carrier>(ServiceType.Carrier, hasScope, carrier);
    if (!result) {
      return;
    }
    return this.openConfigInformationModal(result)
      .then(() => {
        this.router.navigate(['/carriers']);
      });
  }

  delete(carrier: ServiceCarrier, hasScope: boolean): Promise<boolean> {
    return this.serviceActionService.delete(carrier, hasScope);
  }

  openConfigInformationModal(data: ServiceCarrier): Promise<void> {
    return this.dialog.open(CarrierInformationModalComponent, {
      panelClass: 'cr-dialog',
      maxWidth:   '847px',
      maxHeight:  'calc(100vh - 20px)',
      width:      '100%',
      data,
      position:   {
        top: '10px',
      },
    })
      .afterClosed()
      .toPromise();
  }
}
