import { APIKeyRaw }    from '@models/api/identity/api-key-raw.model';
import { Scope }        from '@models/entity/scope.model';
import { ApiKeyStatus } from '@enums/api-key-status.enum';

export class APIKey {
  id: string;
  description: string;
  createdDate: string;
  expiryDate: string;
  scopes: Scope[];
  ttl: number;
  status: ApiKeyStatus;
  isActive: boolean;
  requestId?: string;
  token?: string;

  constructor(private data?: APIKey) {
    Object.assign(this, { ...(this.data || {}) });
  }

  getStatusColor?(): string {
    switch (this.status) {
      case ApiKeyStatus.Active:
        return 'green';
      case ApiKeyStatus.Inactive:
        return 'gray';
      case ApiKeyStatus.Revoked:
        return 'red';
    }
  }

  fromApiData?(data: APIKeyRaw, requestId?: string): APIKey {
    this.id          = data.id;
    this.createdDate = data.created_date;
    this.expiryDate  = data.expiry_date;
    this.scopes      = data.scopes as Scope[];
    this.status      = data.status;
    this.description = data.description;
    this.ttl         = data.ttl;
    this.token       = data.token;
    this.requestId   = requestId;

    return this;
  }

  toApiData?(): APIKeyRaw {
    return {
      scopes:      this.scopes?.map(scope => scope.identifier),
      status:      this.status,
      is_active:   this.isActive,
      description: this.description,
      ttl:         this.ttl,
    } as APIKeyRaw;
  }
}
