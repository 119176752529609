import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Alert }                                  from '@models/entity/alert.model';

@Component({
  selector:    'ngx-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls:   ['./modal-content.component.scss'],
})
export class ModalContentComponent {
  @Input() error: Alert;
  @Input() info: Alert;
  @Input() success: Alert;
  @Input() icon: string;
  @Input() iconColor: string;
  @Input() iconBgColor: string;
  @Input() noPadding                               = false;
  @Output() errorDismissed                         = new EventEmitter<void>();
  @Output() infoDismissed                          = new EventEmitter<void>();
  @Output() successDismissed                       = new EventEmitter<void>();
  @Input() background                              = true;
  @Input() overflow: 'visible' | 'hidden' | 'auto' = 'auto';
}
