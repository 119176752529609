import { forkJoin, Observable, of, zip }                         from 'rxjs';
import { catchError, concatMap, defaultIfEmpty, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ApiService }     from './api.service';
import { ListService }    from './list.service';
import { ServiceService } from './service.service';
import { environment }    from '../../environments/environment';

import { Alert }                              from '@models/entity/alert.model';
import { HttpErrorResponse }                  from '@angular/common/http';
import { MicrosoftTeamsUser }                 from '@models/entity/microsoft-teams-user.model';
import { NumberQueryParams }                  from '@models/form/number-query-params.model';
import { NumberItemRaw }                      from '@models/api/number-item-raw.model';
import { NumberItem }                         from '@models/entity/number-item.model';
import { NumberSearchParamsFactory }          from '@models/entity/number-search-params-factory.model';
import { NumberItemFactory }                  from '@models/entity/number-item.factory';
import { FetchNumberListResponse }            from '@models/api/fetch-number-list-response.model';
import { PatchNumberRequest }                 from '@models/api/patch-number-request.model';
import { AssignInboundNumberRequest }         from '@models/api/assign-inbound-number-request.model';
import { AssignInboundNumberResponse }        from '@models/api/assign-inbound-number-response.model';
import { FetchInventoryProvidersResponseRaw } from '@models/api/fetch-inventory-providers-response-raw.model';
import { BulkProvisionRequest }               from '@models/api/bulk-provision-request.model';
import { BulkProvisionRequestRaw }            from '@models/api/bulk-provision-request-raw.model';
import { BulkProvisionResponse }              from '@models/api/bulk-provision-response.model';
import { PatchNumberResponse }                from '@models/api/patch-number-response.model';
import { NumberTagRaw }                       from '@models/api/number-tag-raw.model';
import { FetchNumberTagsResponse }            from '@models/api/fetch-number-tags-response.model';
import { FetchNumberTagsRequest }             from '@models/api/fetch-number-tags-request.model';
import { FetchNumberRequest }                 from '@models/api/fetch-number-request.model';
import { FetchNumberResponse }                from '@models/api/fetch-number-response.model';
import { ServiceItem }                        from '@models/entity/service-item.model';
import { FetchNumberCountsResponse }          from '@models/api/fetch-number-counts-response.model';
import { NumberCountsRaw }                    from '@models/api/number-counts-raw.model';
import { NumberRange }                        from '@models/entity/number-range.model';
import { NumberRangeFactory }                 from '@models/factory/number-range.factory';
import { NumberRangeQueryParams }             from '@models/form/number-range-query-params.model';
import { FetchNumberRangeListRequest }        from '@models/api/fetch-number-range-list-request.model';
import { FetchNumberRangeListResponse }       from '@models/api/fetch-number-range-list-response.model';
import { NumberRangeParamFactory }            from '@models/factory/number-range-param.factory';
import { AddNumberRangeRequest }              from '@models/api/add-number-range-request.model';
import { AddNumberRangeResponse }             from '@models/api/add-number-range-response.model';
import { NumberRangeRaw }                     from '@models/api/number-range-raw.model';
import { SetNumberRangeRoutesRequest }        from '@models/api/set-number-range-routes-request.model';
import { SetNumberRangeRoutesResponse }       from '@models/api/set-number-range-routes-response.model';
import { FetchNumberRangeRequest }            from '@models/api/fetch-number-range-request.model';
import { FetchNumberRangeResponse }           from '@models/api/fetch-number-range-response.model';
import { FetchNumberListRequest }             from '@models/api/fetch-number-list-request';
import { RouteRule }                          from '@models/entity/route-rule.model';
import { ServiceType }                        from '@enums/service-type.enum';
import { CarrierService }                     from './carrier.service';
import { ServiceCarrier }                     from '@models/entity/carrier-service.model';
import { PatchNumberRangeResponse }           from '@models/api/patch-number-range-response.model';
import { PatchNumberRangeRequest }            from '@models/api/patch-number-range-request.model';
import { MicrosoftTeams }                     from '@models/entity/microsoft-teams.model';
import { DeleteNumberRangeResponse }          from '@models/api/delete-number-range-response.model';
import { DeleteNumberRangeRequest }           from '@models/api/delete-number-range-request.model';
import { FetchInventoryProvidersResponse }    from '@models/api/fetch-inventory-providers-response.model';
import { DeleteNumberRequest }                from '@models/api/delete-number-request.model';
import { DeleteNumberResponse }               from '@models/api/delete-number-response.model';
import { ListResponseMetadata }               from '@models/api/list-response-metadata.model';
import { PatchNumberTagResponse }             from '@models/api/patch-number-tag-response.model';
import { PatchNumberTagRequest }              from '@models/api/patch-number-tag-request.model';
import { DeleteNumberTagRequest }             from '@models/api/delete-number-tag-request.model';
import { DeleteNumberTagResponse }            from '@models/api/delete-number-tag-response.model';
import { NumberTagQueryParams }               from '@models/form/number-tag-query-params.model';
import { CallingProfile }                     from '@models/entity/calling-profile.model';
import { NumberTag }                          from '@models/entity/number-tag.model';
import { ExportNumbersResponse }              from '@models/api/number/export-numbers-response.model';
import { Location }                           from '@models/entity/location.model';
import { LocationFactory }                    from '@models/factory/location.factory';
import { LocationQueryParams }                from '@models/form/location-query-params.model';
import { LocationQueryParamsFactory }         from '@models/factory/location-query-params.factory';
import { FetchLocationsResponse }             from '@models/api/number/fetch-locations-response.model';
import { FetchLocationsRequest }              from '@models/api/number/fetch-locations-request.model';
import { FetchLocationRequest }               from '@models/api/number/fetch-location-request.model';
import { FetchLocationResponse }              from '@models/api/number/fetch-location-response.model';
import { LocationRaw }                        from '@models/api/number/location-raw.model';
import { DeleteLocationRequest }              from '@models/api/number/delete-location-request.model';
import { DeleteLocationResponse }             from '@models/api/number/delete-location-response.model';
import { PatchLocationRequest }               from '@models/api/number/patch-location-request.model';
import { PatchLocationResponse }              from '@models/api/number/patch-location-response.model';
import { PostLocationRequest }                from '@models/api/number/post-location-request.model';
import { PostLocationResponse }               from '@models/api/number/post-location-response.model';
import { AvailabilityCheckRaw }               from '@models/api/availability-check-raw.model';
import { Availability }                       from '@enums/availability.enum';
import {
  FetchLocationNameAvailabilityRequest,
}                                             from '@models/api/number/fetch-location-name-availability-request.model';
import {
  FetchLocationNameAvailabilityResponse,
}                                             from '@models/api/number/fetch-location-name-availability-response.model';
import {
  FetchBulkCountByAssignedRequest,
}                                             from '@models/api/number/fetch-bulk-count-by-assigned-request.model';
import {
  FetchBulkCountByAssignedResponse,
}                                             from '@models/api/number/fetch-bulk-count-by-assigned-response.model';
import { NumberAssignedCounts }               from '@models/entity/number-counts.model';
import { FetchNumberCountsRequest }           from '@models/api/fetch-number-counts-request.model';
import { NumberCountByAssignedQueryParams }   from '@models/form/number-count-by-assigned-query-params.model';
import { NumberReservationRemoveRequest }     from '@models/api/number/number-reservation-remove-request.model';
import {
  NumberReservationRemoveResponse,
}                                             from '@models/api/number/number-reservation-remove-response.model';
import { BulkNumberUpdateRequest }            from '@models/api/number/bulk-number-update-request.model';
import { BulkNumberUpdateResponse }           from '@models/api/number/bulk-number-update-response.model';
import { PluralPipe }                         from '@pipes/plural.pipe';
import {
  FetchNumbersServiceMetadataResponse,
}                                             from '@models/api/number/fetch-numbers-service-metadata-response.model';
import { NumberServiceMetadata }              from '@models/entity/number-service-metadata.model';
import { NumberServiceMetadataRaw }           from '@models/entity/number-service-metadata-raw.model';
import { FetchInventoryAreaListRequest }      from '@models/api/number/fetch-inventory-area-list-request.model';
import {
  FetchInventoryAreaListResponse,
}                                             from '@models/api/number/fetch-inventory-area-list-response.model';
import { InventoryArea }                      from '@models/entity/inventory-area.model';
import { InventoryAreaRaw }                   from '@models/api/number/inventory-area-raw.model';
import { ImportNumbersResponse }              from '@models/api/number/import-numbers-response.model';
import { ImportNumbersRequest }               from '@models/api/number/import-numbers-request.model';
import {
  FetchNumberCompatibilityRequest,
}                                             from '@models/api/number/fetch-number-compatibility-request.model';
import {
  FetchNumberCompatibilityResponse,
}                                             from '@models/api/number/fetch-number-compatibility-response.model';
import { NumberCompatibilityRaw }             from '@models/api/number/number-compatibility-raw.model';
import {
  FetchRangeCountByExhaustionStatusResponse,
}                                             from '@models/api/number/fetch-range-count-by-exhaustion-status-response.model';
import { RangeExhaustionStatusCount }         from '@models/entity/range-exhaustion-status-count.model';
import { ExportNumbersRequest }               from '@models/api/number/export-numbers-request.model';

@Injectable({
  providedIn: 'root',
})
export class NumberService {

  static validationErrors                = ['Invalid number', 'Invalid country code', 'Phone number is too short', 'Phone number is too long', 'Invalid number'];
  private cache: { [uuid: string]: any } = {};

  static addNumbersDisabled(features: { identifier: string, name: string }[], scopes: string[]): boolean {
    if (!features?.length || !scopes?.length) {
      return true;
    }
    return !scopes.includes('Number.Write') ||
      !features.find((feature: { identifier: string, name: string }) => feature.identifier === 'NUMBER_CREATION');
  }

  private static getLocationIds(numbers: NumberItem[]): string[] {
    return numbers?.map(num => num.locationId)
      .filter(location => !!location) || [];
  }

  private static getServiceIds(numbers: (NumberItem | NumberRange)[]): string[] {
    const serviceIds: string[] = [];
    for (const num of numbers) {
      if (num instanceof NumberItem) {
        const ids = num.destinations.map(dest => dest.serviceId)
          .filter(id => !!id);
        serviceIds.push(...ids);
      } else {
        const inbound  = num.inboundRoute?.rules?.map(dest => dest.serviceId)
          ?.filter(id => !!id) || [];
        const outbound = num.outboundRoute?.rules?.map(dest => dest.serviceId)
          ?.filter(id => !!id) || [];
        serviceIds.push(...inbound, ...outbound);
      }
    }
    return serviceIds;
  }

  private static getServiceUserIds(numbers: (NumberItem | NumberRange)[], serviceId: string): string[] {
    const ids: string[] = [];
    for (const num of numbers) {
      if (num instanceof NumberItem) {
        const serviceIds = num.destinations.filter(dest => dest?.serviceUser?.id && dest?.serviceId === serviceId)
          .map(dest => dest.serviceUser?.id);
        ids.push(...serviceIds);
      } else {
        const inbound  = num.inboundRoute?.rules?.filter(dest => dest?.serviceUser?.id && dest?.serviceId === serviceId)
          ?.map(dest => dest.serviceUser?.id) || [];
        const outbound = num.outboundRoute?.rules?.filter(dest => dest?.serviceUser?.id && dest?.serviceId === serviceId)
          ?.map(dest => dest.serviceUser?.id) || [];
        ids.push(...inbound, ...outbound);
      }
    }
    return ids;
  }

  private baseUrl: string = environment.api.numberBaseUrl;

  private readonly numberItemFactory: NumberItemFactory;
  private readonly numberParamFactory: NumberSearchParamsFactory;
  private readonly numberRangeFactory: NumberRangeFactory;
  private readonly numberRangeParamFactory: NumberRangeParamFactory;
  private readonly locationFactory: LocationFactory;
  private readonly locationQueryParamsFactory: LocationQueryParamsFactory;

  private buildBulkProvisionUri(): string {
    return this.buildUri(`numbers/bulk-provision`);
  }

  private buildInventoryProvidersUri(): string {
    return this.buildUri(`inventory/providers`);
  }

  private buildLocationsUri(queryParams?: LocationQueryParams): string {
    return this.buildUri(`locations${ LocationQueryParams.constructQueryString(queryParams) }`);
  }

  private buildNumberRoutesUri(numberId: string): string {
    return this.buildUri(`numbers/${ numberId }/routes`);
  }

  private buildNumberRangeUri(id?: string): string {
    return this.buildUri(`ranges${ id ? `/${ id }` : '' }`);
  }

  private buildLocationUri(id?: string): string {
    return this.buildUri(`locations${ id ? `/${ id }` : '' }`);
  }

  private buildReservationUri(id: string): string {
    return this.buildUri(`numbers/${ id }/reservation`);
  }

  private buildLocationAvailabilityUri(name: string): string {
    return this.buildUri(`locations/availability?name=${ name }`);
  }

  private buildNumberTagUri(id: string): string {
    return this.buildUri(`tags/${ id }`);
  }

  private buildNumberUri(id?: string): string {
    return this.buildUri(`numbers${ id ? ('/' + id) : '' }`);
  }

  private buildBulkUpdateBySearchTokenUri(token: string): string {
    return this.buildUri(`numbers/searches/${ token }`);
  }

  private buildNumbersServiceMetadataUri(): string {
    return this.buildUri(`numbers/service-metadata`);
  }

  private buildNumberExport(forImport: boolean): string {
    return this.buildUri(forImport ? `numbers/export-for-import` : `numbers/export`);
  }

  private buildNumberImport(): string {
    return this.buildUri(`numbers/import`);
  }

  private buildNumberCompatibilityUri(): string {
    return this.buildUri(`numbers/compatibility`);
  }

  private buildRangeCountByExhaustionStatusUri(): string {
    return this.buildUri(`ranges/count-by-exhaustion-status`);
  }

  private buildNumberListUri(queryParams: NumberQueryParams): string {
    return this.buildUri(`numbers${ NumberQueryParams.constructQueryString(queryParams) }`);
  }

  private buildNumberRangeRoutesUri(rangeId: string): string {
    return this.buildUri(`ranges/${ rangeId }/routes`);
  }

  private buildBulkCountByAssignedUri(mode: 'RANGE' | 'LOCATION', token?: string): string {
    return this.buildUri(`${ mode === 'RANGE' ? 'ranges' : 'locations' }/count-by-assigned${ token ? ('?' + 'filter[search_token]=' + token) : '' }`);
  }

  private buildNumberRangeListUri(queryParams: NumberRangeQueryParams): string {
    return this.buildUri(`ranges${ NumberRangeQueryParams.constructQueryString(queryParams) }`);
  }

  private buildNumberTagListUri(queryParams: NumberTagQueryParams): string {
    return this.buildUri(`tags${ NumberTagQueryParams.constructQueryString(queryParams) }`);
  }

  private buildNumberCountsUri(queryParams: NumberCountByAssignedQueryParams): string {
    return this.buildUri(`numbers/count-by-assigned${ NumberCountByAssignedQueryParams.constructQueryString(queryParams) }`);
  }

  constructor(
    private pluralPipe: PluralPipe,
    private apiService: ApiService,
    private serviceService: ServiceService,
    private carrierService: CarrierService,
    private listService: ListService<NumberItem, NumberItemFactory,
      NumberQueryParams, NumberSearchParamsFactory>,
    private numberRangeListService: ListService<NumberRange, NumberRangeFactory, NumberRangeQueryParams, NumberRangeParamFactory>,
    private locationListService: ListService<Location, LocationFactory, LocationQueryParams, LocationQueryParamsFactory>,
  ) {
    this.numberItemFactory          = new NumberItemFactory();
    this.numberParamFactory         = new NumberSearchParamsFactory();
    this.numberRangeFactory         = new NumberRangeFactory();
    this.numberRangeParamFactory    = new NumberRangeParamFactory();
    this.locationFactory            = new LocationFactory();
    this.locationQueryParamsFactory = new LocationQueryParamsFactory();
  }

  private buildUri(uriSuffix: string): string {
    return `${ this.baseUrl }${ uriSuffix }`;
  }

  fetchNumberCounts$(req: FetchNumberCountsRequest): Observable<FetchNumberCountsResponse> {
    return this.apiService.apiGet$<{ data: NumberCountsRaw }>(
      this.buildNumberCountsUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: NumberCountsRaw }): FetchNumberCountsResponse => {
          const ASSIGNED: number   = res.data?.['ASSIGNED']?.count || 0;
          const UNASSIGNED: number = res.data?.['UNASSIGNED']?.count || 0;
          const RESERVED: number   = res.data?.['RESERVED']?.count || 0;

          const TOTAL = ASSIGNED + UNASSIGNED + RESERVED;
          return {
            error:  null,
            counts: {
              ASSIGNED,
              UNASSIGNED,
              RESERVED,
              TOTAL,
            },
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchNumberCountsResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            counts: null,
          });
        }),
      );
  }

  fetchLocations$(req: FetchLocationsRequest): Observable<FetchLocationsResponse> {
    return this.locationListService.fetchListModel$(
      this.buildLocationsUri(req.queryParams),
      this.locationFactory,
      this.locationQueryParamsFactory,
    )
      .pipe(concatMap((response: FetchLocationsResponse) => {
        if (req.excludeCounts) {
          return of(response);
        }
        return this.mergeLocationListCounts$(response);
      }));
  }

  fetchLocation$(req: FetchLocationRequest): Observable<FetchLocationResponse> {
    return this.apiService.apiGet$<{ data: LocationRaw }>(
      this.buildUri(`locations/${ req.id }`),
      { authRequired: true },
    )
      .pipe(
        map((res: { data: LocationRaw }): FetchLocationResponse => {
          return {
            data:  new Location().fromApiData(res.data),
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchLocationResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }));
  }

  fetchInventoryAreaList$(req: FetchInventoryAreaListRequest): Observable<FetchInventoryAreaListResponse> {
    return this.apiService.apiGet$<{ data: InventoryAreaRaw[] }>(
      this.buildUri(`inventory/areas?filter[search]=${ encodeURIComponent(req.searchQuery) }&page_size=15`),
      { authRequired: true })
      .pipe(
        map((res: { data: InventoryAreaRaw[] }): FetchInventoryAreaListResponse => {
          return {
            error: null,
            data:  res.data ? res.data.map(r => InventoryArea.fromApiData(r)) : [],
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchInventoryAreaListResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  fetchNumberTags$(req: FetchNumberTagsRequest): Observable<FetchNumberTagsResponse> {
    return this.apiService.apiGet$<{ data: Array<NumberTagRaw>, meta: ListResponseMetadata }>(
      this.buildNumberTagListUri(req.queryParams),
      { authRequired: true })
      .pipe(
        map((res: { data: Array<NumberTagRaw>, meta: ListResponseMetadata }): FetchNumberTagsResponse => {
          const totalCount = res.data?.length || 0;
          return {
            error:        null,
            tags:         res.data,
            totalCount,
            searchParams: new NumberTagQueryParams().constructParams(res.meta),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchNumberTagsResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            tags:         null,
            totalCount:   0,
            searchParams: null,
          });
        }),
      );
  }

  fetchNumberTagsByIds$(ids: string[]): Observable<NumberTag[]> {
    const params = [];
    for (const item of ids) {
      params.push(`filter[id][]=${ item }`);
    }
    const query = '?'.concat(params.join('&'));
    return this.apiService.apiGet$<{ data: NumberTagRaw[] }>(
      this.buildUri(`tags${ query }`),
      { authRequired: true })
      .pipe(
        map((res: { data: NumberTagRaw[] }): NumberTag[] => {
          return res.data.map(d => new NumberTag(d));
        }),
        catchError((): Observable<NumberTag[]> => {
          return of(null);
        }),
      );
  }

  fetchNumberRangeById$(id: string): Observable<NumberRange> {
    return this.apiService.apiGet$<{ data: NumberRangeRaw }>(
      this.buildNumberRangeUri(id),
      { authRequired: true })
      .pipe(
        map((res: { data: NumberRangeRaw }): NumberRange => {
          return new NumberRange().fromApiData(res.data);
        }),
        catchError((): Observable<NumberRange> => {
          return of(null);
        }),
      );
  }

  fetchNumber$(req: FetchNumberRequest): Observable<FetchNumberResponse> {
    return this.apiService.apiGet$<{ data: NumberItemRaw }>(
      this.buildNumberUri(req.id),
      { authRequired: true })
      .pipe(
        map((res: { data: NumberItemRaw }): FetchNumberResponse => {
          return {
            error:  null,
            number: new NumberItem().fromApiData(res.data),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchNumberResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            number: null,
          });
        }),
      );
  }

  fetchNumberRangeList$(req: FetchNumberRangeListRequest): Observable<FetchNumberRangeListResponse> {
    return this.numberRangeListService.fetchListModel$(
      this.buildNumberRangeListUri(req.queryParams),
      this.numberRangeFactory,
      this.numberRangeParamFactory,
    )
      .pipe(switchMap((res: FetchNumberRangeListResponse) => {
        if (res.error) {
          return of(res);
        }

        const serviceIds = Array.from(new Set(NumberService.getServiceIds(res.models)));

        return this.mergeRangeListServices$(serviceIds, res)
          .pipe(
            concatMap((response: FetchNumberRangeListResponse) => {
              return this.mergeRangeListServiceUsers$(serviceIds, response);
            }),
            concatMap((response: FetchNumberRangeListResponse) => {
              if (req.excludeCounts) {
                return of(response);
              }
              return this.mergeRangeListCounts$(response);
            }));
      }));
  }

  fetchNumberRange$(req: FetchNumberRangeRequest): Observable<FetchNumberRangeResponse> {
    return this.apiService.apiGet$<{ data: NumberRangeRaw }>(
      this.buildNumberRangeUri(req.id),
      { authRequired: true })
      .pipe(
        switchMap((res: { data: NumberRangeRaw }): Observable<FetchNumberRangeResponse> => {
          return this.resolveRangeData$(res.data, req.requestId)
            .pipe(map((numberRange: NumberRange) => {
              return {
                error:   null,
                message: null,
                range:   numberRange,
                storeAs: req.storeAs,
              };
            }));
        }),
        catchError((err: HttpErrorResponse): Observable<FetchNumberRangeResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
            range:   null,
            storeAs: req.storeAs,
          });
        }),
      );
  }

  exportNumbers$(req: ExportNumbersRequest): Observable<ExportNumbersResponse> {
    return this.apiService.apiPost$<{ data: { task_id: string } }>(
      this.buildNumberExport(req.forImport),
      {},
      { authRequired: true })
      .pipe(
        map((res: { data: { task_id: string } }): ExportNumbersResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: `We are processing your number export request.` }),
            taskId:  res.data.task_id,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<ExportNumbersResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
            taskId:  null,
          });
        }),
      );
  }

  importNumbers$(req: ImportNumbersRequest): Observable<ImportNumbersResponse> {
    return this.apiService.apiPost$<{ data: { task_id: string } }>(
      this.buildNumberImport(),
      new ImportNumbersRequest(req).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: { task_id: string } }): ImportNumbersResponse => {
          return {
            error:     null,
            message:   new Alert().fromApiMessage({ message: `We are processing your number import request.` }),
            requestId: req.requestId,
            taskId:    res.data.task_id,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<ImportNumbersResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
            taskId:  null,
          });
        }),
      );
  }

  fetchNumberCompatibility$(req: FetchNumberCompatibilityRequest): Observable<FetchNumberCompatibilityResponse> {
    return this.apiService.apiPost$<{ data: NumberCompatibilityRaw }>(
      this.buildNumberCompatibilityUri(),
      new FetchNumberCompatibilityRequest(req).toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: NumberCompatibilityRaw }): FetchNumberCompatibilityResponse => {
          return {
            number_ids: res.data.numbers,
            requestId:  req.requestId,
            error:      null,
            message:    null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchNumberCompatibilityResponse> => {
          return of({
            number_ids: null,
            requestId:  null,
            error:      new Alert().fromApiError(err),
            message:    null,
          });
        }),
      );
  }

  fetchRangeCountByExhaustionStatus$(): Observable<FetchRangeCountByExhaustionStatusResponse> {
    return this.apiService.apiGet$<{ data: RangeExhaustionStatusCount }>(
      this.buildRangeCountByExhaustionStatusUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: RangeExhaustionStatusCount }): FetchRangeCountByExhaustionStatusResponse => {
          return {
            data:    res.data,
            error:   null,
            message: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchRangeCountByExhaustionStatusResponse> => {
          return of({
            data:    null,
            error:   new Alert().fromApiError(err),
            message: null,
          });
        }),
      );
  }

  bulkNumberUpdate$(req: BulkNumberUpdateRequest): Observable<BulkNumberUpdateResponse> {
    let response$: Observable<unknown>;
    if (req.searchToken) {
      response$ = this.apiService.apiPost$(
        req.searchToken ? this.buildBulkUpdateBySearchTokenUri(req.searchToken) : this.buildNumberUri(),
        req.searchToken ?
          {
            changes:
              {
                ...{ location_id: req.locationId, description: req.description, tags: req.tags },
              },
          } :
          {
            ...{ ids: req.ids, location_id: req.locationId, description: req.description, tags: req.tags },
          },
        { authRequired: true });
    } else {
      response$ = this.apiService.apiPatch$(
        this.buildNumberUri(),
        {
          ...{ ids: req.ids, location_id: req.locationId, description: req.description },
        },
        { authRequired: true });
    }

    const count = req.searchToken ? req.recordCount : req.ids.length;

    return response$
      .pipe(
        map((): BulkNumberUpdateResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: `Updating ${ count } ${ this.pluralPipe.transform('number', count) }.` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<BulkNumberUpdateResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
          });
        }),
      );
  }

  fetchNumbersServiceMetadata$(): Observable<FetchNumbersServiceMetadataResponse> {
    return this.apiService.apiGet$<{ data: NumberServiceMetadataRaw[] }>(
      this.buildNumbersServiceMetadataUri(),
      { authRequired: true })
      .pipe(
        map((res: { data: NumberServiceMetadataRaw[] }): FetchNumbersServiceMetadataResponse => {
          return {
            data:  res.data.map(d => new NumberServiceMetadata().fromApiData(d)),
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchNumbersServiceMetadataResponse> => {
          return of({
            data:  null,
            error: new Alert().fromApiError(err),
          });
        }),
      );
  }

  deleteNumber$(req: DeleteNumberRequest): Observable<DeleteNumberResponse> {
    return this.apiService.apiDelete$(
      this.buildNumberUri(req.numberId),
      { authRequired: true })
      .pipe(
        map((): DeleteNumberResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: `Successfully deleted '${ req.number }'.` }),
            id:      req.numberId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteNumberResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
            id:      req.numberId,
          });
        }),
      );
  }

  deleteNumberTag$(req: DeleteNumberTagRequest): Observable<DeleteNumberTagResponse> {
    return this.apiService.apiDelete$(
      this.buildNumberTagUri(req.id),
      { authRequired: true })
      .pipe(
        map((): DeleteNumberTagResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: `Successfully deleted '${ req.name }'.` }),
            id:      req.id,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteNumberTagResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
            id:      req.id,
          });
        }),
      );
  }

  deleteNumberRange$(req: DeleteNumberRangeRequest): Observable<DeleteNumberRangeResponse> {
    return this.apiService.apiDelete$(
      this.buildNumberRangeUri(req.rangeId),
      { authRequired: true })
      .pipe(
        map((): DeleteNumberRangeResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: `Successfully deleted '${ req.rangeName }'.` }),
            id:      req.rangeId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteNumberRangeResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
            id:      req.rangeId,
          });
        }),
      );
  }

  deleteLocation$(req: DeleteLocationRequest): Observable<DeleteLocationResponse> {
    return this.apiService.apiDelete$(
      this.buildLocationUri(req.locationId),
      { authRequired: true },
    )
      .pipe(
        map((): DeleteLocationResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: `Successfully deleted '${ req.locationName }'.` }),
            id:      req.locationId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<DeleteLocationResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
            id:      req.locationId,
          });
        }),
      );
  }


  removeNumberReservation$(req: NumberReservationRemoveRequest): Observable<NumberReservationRemoveResponse> {
    return this.apiService.apiDelete$(
      this.buildReservationUri(req.numberId),
      { authRequired: true },
    )
      .pipe(
        map((): NumberReservationRemoveResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: `Successfully removed number quarantine.` }),
            id:      req.numberId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<NumberReservationRemoveResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
            id:      req.numberId,
          });
        }),
      );
  }

  fetchBulkCountByAssigned$(req: FetchBulkCountByAssignedRequest): Observable<FetchBulkCountByAssignedResponse> {
    return this.apiService.apiPost$<{ data: { [rangeId: string]: NumberCountsRaw } }>(
      this.buildBulkCountByAssignedUri(req.mode, req.token),
      req.mode === 'RANGE' ? { range_ids: req.ids } : { location_ids: req.ids },
      { authRequired: true })
      .pipe(
        map((res: { data: { [id: string]: NumberCountsRaw } }): FetchBulkCountByAssignedResponse => {
          const counts: { [id: string]: NumberAssignedCounts } = {};
          for (const [key, count] of Object.entries(res.data)) {
            const ASSIGNED: number   = count?.['ASSIGNED']?.count || 0;
            const UNASSIGNED: number = count?.['UNASSIGNED']?.count || 0;
            const RESERVED: number   = count?.['RESERVED']?.count || 0;

            const TOTAL = ASSIGNED + UNASSIGNED + RESERVED;

            counts[key] = {
              ASSIGNED,
              UNASSIGNED,
              RESERVED,
              TOTAL,
            };
          }
          return {
            data:  counts,
            error: null,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchBulkCountByAssignedResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            data:  null,
          });
        }),
      );
  }

  private resolveRangeData$(data: NumberRangeRaw, requestId: string): Observable<NumberRange> {
    const range      = new NumberRange().fromApiData(data, requestId);
    const serviceIds = Array.from(new Set(NumberService.getServiceIds([range])));

    return this.mergeRangeServices$(serviceIds, range)
      .pipe(
        concatMap((response: NumberRange) => {
          return this.mergeRangeServiceUsers$(serviceIds, response)
            .pipe(map((numberRange: NumberRange) => {
              return numberRange;
            }));
        }),
        concatMap((response: NumberRange) => {
          return this.mergeRangeCounts$(response);
        }));
  }

  addNumberRange$(req: AddNumberRangeRequest): Observable<AddNumberRangeResponse> {
    return this.apiService.apiPost$<{ data: NumberRangeRaw, status: number }>(
      this.buildNumberRangeUri(),
      new AddNumberRangeRequest(req).toApiData(),
      { authRequired: true, timeoutMS: 60_000, includeStatus: true })
      .pipe(
        switchMap((res: { data: NumberRangeRaw, status: number }): Observable<AddNumberRangeResponse> => {
          return this.resolveRangeData$(res.data, req.requestId)
            .pipe(map((numberRange: NumberRange) => {
              numberRange.requestId = req._id;
              return {
                error:   null,
                message: new Alert().fromApiMessage({
                  message: res.status === 201 ?
                             `Successfully created '${ res.data?.name }'.` :
                             `Successfully added numbers to '${ res.data?.name }'.`,
                }),
                data:    numberRange,
              };
            }));
        }),
        catchError((err: HttpErrorResponse): Observable<AddNumberRangeResponse> => {
          return of({
            error:   new Alert().withContext('Creating a number range')
                       .fromApiError(err),
            message: null,
            data:    null,
          });
        }),
      );
  }

  setNumberRangeRoutes$(req: SetNumberRangeRoutesRequest): Observable<SetNumberRangeRoutesResponse> {
    return this.apiService.apiPut$<{ data: NumberRangeRaw }>(
      this.buildNumberRangeRoutesUri(req.rangeId),
      new SetNumberRangeRoutesRequest(req).toApiData(),
      { authRequired: true })
      .pipe(
        switchMap((res: { data: NumberRangeRaw }): Observable<SetNumberRangeRoutesResponse> => {
          return this.resolveRangeData$(res.data, req.requestId)
            .pipe(map((numberRange: NumberRange) => {
              return {
                error:   null,
                message: new Alert().fromApiMessage({ message: `Successfully assigned default route for ${ req.name }.` }),
                data:    numberRange,
              };
            }));
        }),
        catchError((err: HttpErrorResponse): Observable<SetNumberRangeRoutesResponse> => {
          return of({
            error:   new Alert().withContext('Assigning default routes for a number range')
                       .fromApiError(err),
            message: null,
            data:    null,
          });
        }),
      );
  }

  fetchNumberList$(req: FetchNumberListRequest): Observable<FetchNumberListResponse> {
    return this.listService.fetchListModel$(
      this.buildNumberListUri(req.queryParams || { pageSize: 25, pageNumber: 1 }),
      this.numberItemFactory,
      this.numberParamFactory,
    )
      .pipe(
        switchMap((numberListResponse: FetchNumberListResponse): Observable<FetchNumberListResponse> => {

          numberListResponse.storeAs = req.storeAs;

          if (numberListResponse.error) {
            return of(numberListResponse);
          }

          const serviceIds = Array.from(new Set(NumberService.getServiceIds(numberListResponse.models)));

          const locationIds = Array.from(new Set(NumberService.getLocationIds(numberListResponse.models)));

          return this.mergeNumberServiceUsers$(serviceIds, numberListResponse)
            .pipe(
              concatMap((response: FetchNumberListResponse) => {
                return this.mergeNumberServices$(serviceIds, response);
              }),
              concatMap((response: FetchNumberListResponse) => {
                return this.mergeNumberLocations$(locationIds, response);
              }));
        }));
  }

  private mergeRangeServiceUsers$(serviceIds: string[], numberRange: NumberRange): Observable<NumberRange> {
    return this.fetchAssignedUsers$(serviceIds, [numberRange])
      .pipe(
        map((users: MicrosoftTeamsUser[]) => {
          if (!users?.length) {
            return numberRange;
          }

          return this.appendRangeServiceUsers(numberRange, users);
        }));
  }

  private mergeRangeListCounts$(numberRangeListResponse: FetchNumberRangeListResponse): Observable<FetchNumberRangeListResponse> {
    return this.mergeBulkCounts$(numberRangeListResponse.models, 'RANGE')
      .pipe(
        defaultIfEmpty([]),
        map(models => {
          return { ...numberRangeListResponse, models };
        }));
  }

  private mergeLocationListCounts$(locationListResponse: FetchLocationsResponse): Observable<FetchLocationsResponse> {
    return this.mergeBulkCounts$(locationListResponse.models, 'LOCATION')
      .pipe(
        defaultIfEmpty([]),
        map(models => {
          return { ...locationListResponse, models };
        }));
  }

  private mergeRangeCounts$(numberRange: NumberRange): Observable<NumberRange> {
    return this.fetchBulkCountByAssigned$({ ids: [numberRange.id], mode: 'RANGE' })
      .pipe(
        map((response: FetchBulkCountByAssignedResponse) => {
          numberRange.counts = response.data?.[numberRange.id];
          return numberRange;
        }));
  }

  private mergeBulkCounts$<T extends {
    id: string,
    counts?: NumberAssignedCounts
  }>(items: T[], mode: 'RANGE' | 'LOCATION'): Observable<T[]> {
    if (!items?.length) {
      return of([]);
    }
    return this.fetchBulkCountByAssigned$({ ids: items.map(item => item.id), mode })
      .pipe(
        map((response: FetchBulkCountByAssignedResponse) => {
          if (response.error) {
            return items;
          }
          return items.map(item => {
            item.counts = response.data?.[item.id];
            return item;
          });
        }));
  }

  private mergeRangeListServiceUsers$(serviceIds: string[], numberRangeListResponse: FetchNumberRangeListResponse): Observable<FetchNumberRangeListResponse> {
    return this.fetchAssignedUsers$(serviceIds, numberRangeListResponse.models)
      .pipe(
        map((users: MicrosoftTeamsUser[]) => {
          if (!users?.length) {
            return numberRangeListResponse;
          }
          const models = numberRangeListResponse.models.map(model => {
            return this.appendRangeServiceUsers(model, users);
          });
          return { ...numberRangeListResponse, models };
        }));
  }

  private mergeNumberServiceUsers$(serviceIds: string[], numberListResponse: FetchNumberListResponse): Observable<FetchNumberListResponse> {
    if (!serviceIds?.length) {
      return of(numberListResponse);
    }

    return this.fetchAssignedUsers$(serviceIds, numberListResponse.models)
      .pipe(
        switchMap((users: MicrosoftTeamsUser[]) => {
          if (!users?.length) {
            return of(numberListResponse);
          }

          const models$: Observable<NumberItem>[] = numberListResponse.models.map(model => {
            const destinations$: Observable<RouteRule>[] = model.destinations.map(dest => {
              const serviceUser = users.find((user: MicrosoftTeamsUser) => user.id === dest.serviceUser?.id);
              if (serviceUser?.profileId) {
                return this.serviceService.fetchCallingProfileById$(serviceUser.profileId, dest.serviceId)
                  .pipe(map(profile => {
                    serviceUser.profile = profile;
                    return {
                      ...dest,
                      serviceUser,
                    };
                  }));
              }
              return of({ ...dest, serviceUser });
            });
            return zip(...destinations$)
              .pipe(defaultIfEmpty([]), map(destinations => new NumberItem({
                ...model,
                destinations,
              })));
          });
          return zip(...models$)
            .pipe(defaultIfEmpty([]), map(models => {
              return { ...numberListResponse, models };
            }));
        }));
  }

  private mergeNumberServices$(serviceIds: string[], numberListResponse: FetchNumberListResponse): Observable<FetchNumberListResponse> {
    if (!serviceIds?.length) {
      return of(numberListResponse);
    }

    return this.fetchServices$(serviceIds)
      .pipe(
        map((services: ServiceItem[]) => {
          if (!services?.length) {
            return numberListResponse;
          }
          const models = numberListResponse.models.map(model => {
            return new NumberItem({
              ...model,
              destinations: model.destinations.map(dest => {
                const service = services.find((s: ServiceItem) => s.id === dest.serviceId);
                if (service) {
                  return {
                    ...dest,
                    serviceType:   service.serviceType,
                    serviceLabel:  service.label,
                    serviceName:   service.name,
                    serviceStatus: service.provisionStatus.status,
                    serviceIcon:   (service as MicrosoftTeams).colorIcon || service.icon,
                  };
                }
                return dest;
              }),
            });
          });
          return { ...numberListResponse, models };
        }));

  }

  private mergeNumberLocations$(locationIds: string[], numberListResponse: FetchNumberListResponse): Observable<FetchNumberListResponse> {
    if (!locationIds?.length) {
      return of(numberListResponse);
    }
    return this.fetchNumberLocations$(locationIds)
      .pipe(
        map((locations: Location[]) => {
          if (!locations?.length) {
            return numberListResponse;
          }
          const models = numberListResponse.models.map(model => {
            return new NumberItem({
              ...model,
              location: locations?.find(location => location.id === model.locationId),
            });
          });
          return { ...numberListResponse, models };
        }));


  }

  private mergeRangeServices$(serviceIds: string[], numberRange: NumberRange): Observable<NumberRange> {
    if (!serviceIds?.length) {
      return of(numberRange);
    }

    return this.fetchServices$(serviceIds)
      .pipe(
        map((services: ServiceItem[]) => {
          if (!services?.length) {
            return numberRange;
          }

          return this.appendRangeServices(numberRange, services);
        }));
  }

  private mergeRangeListServices$(serviceIds: string[], numberRangeListResponse: FetchNumberRangeListResponse): Observable<FetchNumberRangeListResponse> {
    if (!serviceIds?.length) {
      return of(numberRangeListResponse);
    }
    return this.fetchServices$(serviceIds)
      .pipe(
        map((services: ServiceItem[]) => {
          if (!services?.length) {
            return numberRangeListResponse;
          }
          const models = numberRangeListResponse.models.map(model => {
            return this.appendRangeServices(model, services);
          });
          return { ...numberRangeListResponse, models };
        }));


  }

  private appendRangeServiceUsers(model: NumberRange, users: MicrosoftTeamsUser[]): NumberRange {
    const inboundRoute = model.inboundRoute;

    if (inboundRoute) {
      inboundRoute.rules = model.inboundRoute?.rules?.map(dest => {
        const serviceUser = users.find((user: MicrosoftTeamsUser) => user.id === dest.serviceUser?.id);
        if (serviceUser) {
          return new RouteRule({
            ...dest,
            serviceUser,
          });
        }
        return dest;
      });
    }

    const outboundRoute = model.outboundRoute;
    if (outboundRoute) {
      outboundRoute.rules = model.outboundRoute?.rules?.map(dest => {
        const serviceUser = users.find((user: MicrosoftTeamsUser) => user.id === dest.serviceUser?.id);
        if (serviceUser) {
          return new RouteRule({
            ...dest,
            serviceUser,
          });
        }
        return dest;
      });
    }
    model.inboundRoute  = inboundRoute;
    model.outboundRoute = outboundRoute;

    return model;
  }

  private appendRangeServices(model: NumberRange, services: ServiceItem[]): NumberRange {
    const inboundRoute = model.inboundRoute;

    if (inboundRoute) {
      inboundRoute.rules = model.inboundRoute?.rules.map(dest => {
        const service = services.find((s: ServiceItem) => s.id === dest.serviceId);
        if (service) {
          return new RouteRule({
            ...dest,
            serviceType:   service.serviceType,
            serviceLabel:  service.label,
            serviceName:   service.name,
            serviceStatus: service.provisionStatus.status,
            serviceIcon:   (service as MicrosoftTeams).colorIcon || service.icon,
            carrierLogo:   (service as ServiceCarrier).carrierData?.logoUri,
          });
        }
        return dest;
      });
    }

    const outboundRoute = model.outboundRoute;

    if (outboundRoute) {
      outboundRoute.rules = model.outboundRoute?.rules.map(dest => {
        const service = services.find((s: ServiceItem) => s.id === dest.serviceId);
        if (service) {
          return new RouteRule({
            ...dest,
            serviceType:   service.serviceType,
            serviceLabel:  service.label,
            serviceName:   service.name,
            serviceStatus: service.provisionStatus.status,
            serviceIcon:   service.icon,
            carrierLogo:   (service as ServiceCarrier).carrierData?.logoUri,
          });
        }
        return dest;
      });
    }
    model.inboundRoute  = inboundRoute;
    model.outboundRoute = outboundRoute;

    return model;
  }

  private fetchServices$(serviceIds: string[]): Observable<ServiceItem[]> {
    if (!serviceIds?.length) {
      return of([]);
    }

    return this.serviceService.fetchServiceList$({
      queryParams: { id: serviceIds, pageNumber: 1, pageSize: 1000 },
    })
      .pipe(
        switchMap(res => {
          if (!res.serviceItems?.length) {
            return of([]);
          }
          return zip(...res.serviceItems.map(service => {
            if (service.serviceType !== ServiceType.Carrier) {
              return of(service);
            }
            return this.carrierService.resolveCarrierForService$(service as ServiceCarrier);
          }));
        }))
      .pipe(
        map(services => services?.filter(service => !!service)),
        defaultIfEmpty([]));
  }

  fetchNumberLocations$(locationIds: string[]): Observable<Location[]> {
    if (!locationIds?.length) {
      return of([]);
    }
    return this.fetchLocations$({
      queryParams: { id: locationIds, pageNumber: 1, pageSize: 1000 },
    })
      .pipe(
        map(res => res.models || []),
        map(locations => locations?.filter(service => !!service)),
        defaultIfEmpty([]));
  }

  private fetchAssignedUsers$(serviceIds: string[], items: (NumberRange | NumberItem)[]): Observable<MicrosoftTeamsUser[]> {
    if (!serviceIds?.length) {
      return of([]);
    }
    return zip(...serviceIds.map(serviceId => {
      const serviceUserIdArray = Array.from(new Set(NumberService.getServiceUserIds(items, serviceId)));

      if (!serviceUserIdArray?.length) {
        return of([]);
      }

      const batchSize = 50;

      const batchedServiceUsers$: Observable<MicrosoftTeamsUser[]> = serviceUserIdArray.length
        ? forkJoin(
          Array.from({ length: Math.ceil(serviceUserIdArray.length / batchSize) }, (_, index) => {
            const batch = serviceUserIdArray.slice(index * batchSize, (index + 1) * batchSize);
            return this.serviceService.fetchServiceUserList$({
              serviceId,
              queryParams: {
                id:         batch,
                pageSize:   batch.length,
                pageNumber: 1,
              },
            })
              .pipe(map(res => res?.models || []));
          }),
        )
          .pipe(map(batches => batches.reduce((acc, batch) => acc.concat(batch), []))) // Combine results from all batches into a single array
        : of([]);

      return batchedServiceUsers$
        .pipe(
          concatMap(users => {
            return this.serviceService.mergeEmergencyLocations$(serviceId, users);
          }),
        );
    }))
      .pipe(
        defaultIfEmpty([]),
        map((userArrays: MicrosoftTeamsUser[][]) =>
          userArrays?.length ?
            userArrays.reduce((a: MicrosoftTeamsUser[], b: MicrosoftTeamsUser[]) => (a || []).concat(...b)) :
            []));
  }

  patchLocation$(req: PatchLocationRequest): Observable<PatchLocationResponse> {
    return this.apiService.apiPatch$<{ data: LocationRaw }>(
      this.buildLocationUri(req.data.id),
      req.data.toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: LocationRaw }): PatchLocationResponse => {
          return {
            error:     null,
            data:      new Location().fromApiData(res.data, req.requestId),
            requestId: req.requestId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchLocationResponse> => {
          return of({
            error:     new Alert().fromApiError(err),
            requestId: req.requestId,
            data:      null,
          });
        }),
      );
  }

  fetchLocationNameAvailable$(req: FetchLocationNameAvailabilityRequest): Observable<FetchLocationNameAvailabilityResponse> {
    if (!req.name || req.name.length < 2) {
      return of({
        error:        null,
        availability: null,
      });
    }
    return this.apiService.apiGet$<{ data: AvailabilityCheckRaw }>(
      this.buildLocationAvailabilityUri(req.name),
      { authRequired: false })
      .pipe(
        map((res: { data: AvailabilityCheckRaw }): FetchLocationNameAvailabilityResponse => {
          return {
            error:        null,
            availability: res?.data?.is_available ? Availability.Available : Availability.Unavailable,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchLocationNameAvailabilityResponse> => {
          return of({
            error:        new Alert().fromApiError(err),
            availability: null,
          });
        }),
      );
  }

  postLocation$(req: PostLocationRequest): Observable<PostLocationResponse> {
    return this.apiService.apiPost$<{ data: LocationRaw }>(
      this.buildLocationUri(req.data.id),
      req.data.toApiData(),
      { authRequired: true })
      .pipe(
        map((res: { data: LocationRaw }): PostLocationResponse => {
          return {
            error:     null,
            data:      new Location().fromApiData(res.data, req.requestId),
            requestId: req.requestId,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PostLocationResponse> => {
          return of({
            error:     new Alert().fromApiError(err),
            requestId: req.requestId,
            data:      null,
          });
        }),
      );
  }

  patchNumber$(req: PatchNumberRequest): Observable<PatchNumberResponse> {
    return this.apiService.apiPatch$<{ data: NumberItemRaw }>(
      this.buildNumberUri(req.id),
      { description: req.description, tags: req.tags, location_id: req.locationId },
      { authRequired: true })
      .pipe(
        map((res: { data: NumberItemRaw }): PatchNumberResponse => {
          return {
            error:  null,
            number: new NumberItem().fromApiData(res.data, req.requestId),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchNumberResponse> => {
          return of({
            error:  new Alert().fromApiError(err),
            number: null,
          });
        }),
      );
  }

  patchNumberTag$(req: PatchNumberTagRequest): Observable<PatchNumberTagResponse> {
    return this.apiService.apiPatch$<{ data: NumberTagRaw }>(
      this.buildNumberTagUri(req.tag.id),
      { ...req.tag },
      { authRequired: true })
      .pipe(
        map((res: { data: NumberTagRaw }): PatchNumberTagResponse => {
          return {
            error: null,
            tag:   res.data,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<PatchNumberTagResponse> => {
          return of({
            error: new Alert().fromApiError(err),
            tag:   null,
          });
        }),
      );
  }

  patchNumberRange$(req: PatchNumberRangeRequest): Observable<PatchNumberRangeResponse> {
    return this.apiService.apiPatch$<{ data: NumberRangeRaw }>(
      this.buildNumberRangeUri(req.id),
      new PatchNumberRangeRequest(req).toApiData(),
      { authRequired: true })
      .pipe(
        switchMap((res: { data: NumberRangeRaw }): Observable<PatchNumberRangeResponse> => {
          return this.resolveRangeData$(res.data, req.requestId)
            .pipe(map((numberRange: NumberRange) => {
              numberRange.requestId = req.requestId;
              return {
                error:   null,
                message: new Alert().fromApiMessage({
                  message: req.serviceId ?
                             `Successfully changed carrier (outbound) for '${ numberRange.name }'.` :
                             `Successfully edited '${ numberRange.name }'.`,
                }),
                numberRange,
              };
            }));
        }),
        catchError((err: HttpErrorResponse): Observable<PatchNumberRangeResponse> => {
          return of({
            error:       new Alert().fromApiError(err),
            numberRange: null,
          });
        }),
      );
  }

  assignInboundNumber$(req: AssignInboundNumberRequest): Observable<AssignInboundNumberResponse> {
    return this.apiService.apiPut$<{ data: NumberItemRaw }>(
      this.buildNumberRoutesUri(req.numberId),
      AssignInboundNumberRequest.toApiData(req),
      { authRequired: true })
      .pipe(
        map((): AssignInboundNumberResponse => {
          return {
            error:   null,
            message: new Alert().fromApiMessage({ message: `We are configuring ${ req.num }, this may take a few moments.` }),
          };
        }),
        catchError((err: HttpErrorResponse): Observable<AssignInboundNumberResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
          });
        }),
      );
  }

  fetchInventoryProviders$(): Observable<FetchInventoryProvidersResponse> {
    return this.apiService.apiGet$<FetchInventoryProvidersResponseRaw>(
      this.buildInventoryProvidersUri(),
      { authRequired: true })
      .pipe(
        map((res: FetchInventoryProvidersResponseRaw): FetchInventoryProvidersResponse => {
          return {
            error:     null,
            message:   null,
            providers: res.data,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<FetchInventoryProvidersResponse> => {
          return of({
            error:     new Alert().fromApiError(err),
            message:   null,
            providers: [],
          });
        }),
      );
  }

  bulkProvision$(req: BulkProvisionRequest): Observable<BulkProvisionResponse> {
    const {
            includeCore:   include_core,
            portReference: port_reference,
            areaID:        area_id,
            tags,
            numbers,
            providerIdentifier: provider_identifier,
            sessionID,
          } = req as BulkProvisionRequest;
    return this.apiService.apiPost$<BulkProvisionResponse>(
      this.buildBulkProvisionUri(),
      {
        include_core,
        port_reference,
        area_id,
        provider_identifier,
        tags,
        numbers,
      } as BulkProvisionRequestRaw,
      { authRequired: true, timeoutMS: 60_000 })
      .pipe(
        map((res: BulkProvisionResponse): BulkProvisionResponse => {
          return {
            error:   null,
            message: null,
            data:    res.data,
            meta:    res.meta,
            sessionID,
          };
        }),
        catchError((err: HttpErrorResponse): Observable<BulkProvisionResponse> => {
          return of({
            error:   new Alert().fromApiError(err),
            message: null,
            data:    null,
            meta:    null,
            sessionID,
          });
        }),
      );
  }

  resolveTagsForProfile$(profile: CallingProfile): Observable<CallingProfile> {
    if (!profile?.numberTagIds?.length) {
      return of(profile);
    }
    const tags$: Observable<NumberTag[]> = this.fetchNumberTagsByIds$(profile.numberTagIds);

    return tags$
      .pipe(map(tags => {
        profile.numberTags = tags?.filter(tag => !!tag) || [];
        return profile;
      }));

  }

  resolveRangesForProfile$(profile: CallingProfile): Observable<CallingProfile> {
    if (!profile?.numberRangeIds?.length) {
      return of(profile);
    }
    const ranges$: Observable<NumberRange>[] = profile.numberRangeIds?.map(t => {
      let range$: Observable<NumberRange>;
      if (!this.cache[t]) {
        range$ = this.fetchNumberRangeById$(t)
          .pipe(map(res => {
            this.cache[t] = res; // so we don't fetch the same number from network multiple times
            return res;
          }));
      } else {
        range$ = of(this.cache[t]);
      }
      return range$;
    });

    return zip(...ranges$)
      .pipe(map(ranges => {
        profile.numberRanges = ranges.filter(range => !!range);
        return profile;
      }));

  }

  resolveLocationsForProfile$(profile: CallingProfile): Observable<CallingProfile> {
    if (!profile?.locationIds?.length) {
      return of(profile);
    }

    return this.fetchLocations$({ queryParams: { id: profile.locationIds, pageNumber: 1, pageSize: 100 } })
      .pipe(map(res => {
        if (res.error) {
          return profile;
        }
        profile.locations = res.models;
        return profile;
      }));

  }

}
