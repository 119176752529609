<ng-container *ngIf="formGroup">
  <form [formGroup]="formGroup">
    <ngx-modal-head headerIcon="cr-settings" header="Microsoft Teams settings"></ngx-modal-head>
    <ngx-modal-content>
      <div fxLayout="column">
        <div class="mb-24">
          <div class="input-field-container mb-24 mt-16 custom-margin">
            <label class="field-label field-label--bold mb-0" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon svgIcon="cr-sync" class="mr-4"></mat-icon>
              <span class="ml-8 mt-3">Sync settings</span>
            </label>
          </div>
          <ng-container *ngIf="data.userSyncVersion === 'v1'; else mode">
            <mat-slide-toggle class="mat-body-1 color-near-black" color="primary" labelPosition="after"
                              formControlName="includeUnavailable">
              Include users without a voice license
            </mat-slide-toggle>
            <mat-hint *ngIf="formGroup.get('includeUnavailable').value" fxLayout="row"
                      fxLayoutAlign="start center" class="warning">
              <div>
                <mat-icon svgIcon="cr-warning-no-color" class="color-orange warning__icon"></mat-icon>
              </div>
              <span>
                Callroute will sync all users in your Active Directory.
                This can have an adverse effect on sync times if your tenant has a high user count.
              </span>
            </mat-hint>
          </ng-container>
          <ng-template #mode>
            <mat-form-field appearance="outline">
              <mat-label>User sync mode</mat-label>
              <mat-select formControlName="mode">
                <mat-option *ngFor="let option of modeOptions" [value]="option.value">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-hint *ngIf="formGroup.get('mode').value === SyncMode.All" fxLayout="row"
                      fxLayoutAlign="start center" class="warning">
              <div>
                <mat-icon svgIcon="cr-warning-no-color" class="color-orange warning__icon"></mat-icon>
              </div>
              <span>
                Callroute will sync all users in your Active Directory.
                This can have an adverse effect on sync times if your tenant has a high user count.
              </span>
            </mat-hint>
          </ng-template>
        </div>
        <div class="mb-24">
          <mat-slide-toggle class="mat-body-1 color-near-black" color="primary" labelPosition="after"
                            formControlName="fullSync">
            Include users unchanged since last synchronisation
          </mat-slide-toggle>
          <mat-hint *ngIf="formGroup.get('fullSync').value" fxLayout="row" class="warning" fxLayoutAlign="start center">
            <div>
              <mat-icon svgIcon="cr-warning-no-color" class="color-orange warning__icon"></mat-icon>
            </div>
            <span>
              Callroute will sync users that have not changed since last sync. This can have an adverse effect on
              sync times if your tenant has a high user count. It is recommended this setting is toggled off.
            </span>
          </mat-hint>
        </div>
        <div *ngIf="!data.isOrto">
          <mat-slide-toggle class="mat-body-1 color-near-black" color="primary" labelPosition="after"
                            formControlName="inboundAssignment">
            Always assign matching inbound route during user synchronisation
          </mat-slide-toggle>
          <mat-hint *ngIf="formGroup.get('inboundAssignment').value" class="warning" fxLayout="row"
                    fxLayoutAlign="start center">
            <div>
              <mat-icon svgIcon="cr-warning-no-color" class="color-orange warning__icon"></mat-icon>
            </div>
            <span>
              This will overwrite any existing inbound assignments for the matching number. However, this setting will be ignored if auto-provisioning is enabled.
            </span>
          </mat-hint>
        </div>
        <div class="input-field-container mb-24 mt-46 custom-margin">
          <label class="field-label field-label--bold mb-0" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon svgIcon="cr-phone-settings" class="mr-4"></mat-icon>
            <span class="ml-8 mt-3">User configuration settings</span>
          </label>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-slide-toggle class="mat-body-1 color-near-black mr-8" color="primary" labelPosition="after"
                            formControlName="magicExtension">
            Use the last 4 digits of the CLI as the extension number
          </mat-slide-toggle>
        </div>
      </div>
    </ngx-modal-content>
    <ngx-modal-actions>
      <ngx-cr-button text="Save" [disabled]="!(hasCompanyConfigurationWrite$ | async)"
                     matTooltip="{{saveTooltip$ | async}}" [matTooltipDisabled]="!(saveTooltip$ | async)"
                     (onClick)="done()"></ngx-cr-button>
    </ngx-modal-actions>
  </form>
</ng-container>
