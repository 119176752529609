import { BaseRequest } from '@redux/helpers/reducer.helper';

export class SubmitFeedbackRequest extends BaseRequest {
  improvements: string;
  highlights: string;
  rating: number;

  constructor(private data?: SubmitFeedbackRequest) {
    super();
    Object.assign(this, { ...(this.data || {}) });
    delete this['data'];
  }

  toApiData?(): {
    improvements: string;
    highlights: string;
    rating: number;
  } {
    return {
      improvements: this.improvements,
      highlights:   this.highlights,
      rating:       this.rating,
    };
  }
}
