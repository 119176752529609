import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export interface SlideOptions {
  value: boolean;
  params: { translate: string; from: string, to: string, delay?: string };
}

export function slide(): AnimationTriggerMetadata {
  return trigger('slide', [
    transition(':enter', [
      style({transform: '{{translate}}({{from}})'}),
      animate('.3s {{delay}} ease-out', style({transform: '{{translate}}({{to}})'}))
    ], {params: {delay: '0s'}}),
    transition(':leave', [
      style({transform: '{{translate}}({{to}})'}),
      animate('.1s {{delay}} ease-in', style({transform: '{{translate}}({{from}})'}))
    ], {params: {delay: '0s'}})
  ]);
}
